export const matHang = [
  {
    HsCode: '0101',
    Description: 'Ngựa, lừa, la sống',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Ngựa:',
    Unit: '',
  },
  {
    HsCode: '01012100',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '01012900',
    Description: 'Loại khác',
    Unit: '',
  },
  {
    HsCode: '010130',
    Description: 'Lừa:',
    Unit: '',
  },
  {
    HsCode: '01013010',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '01013090',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '01019000',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '0102',
    Description: 'Động vật sống họ trâu bò',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Gia súc:',
    Unit: '',
  },
  {
    HsCode: '01022100',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '010229',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Gia súc đực:',
    Unit: '',
  },
  {
    HsCode: '01022911',
    Description: 'Bò thiến (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01022919',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '01022990',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Trâu:',
    Unit: '',
  },
  {
    HsCode: '01023100',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '01023900',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '010290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '01029010',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '01029090',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '0103',
    Description: 'Lợn sống',
    Unit: '',
  },
  {
    HsCode: '01031000',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '01039100',
    Description: 'Khối lượng dưới 50 kg',
    Unit: 'kg/con',
  },
  {
    HsCode: '01039200',
    Description: 'Khối lượng từ 50 kg trở lên',
    Unit: 'kg/con',
  },
  {
    HsCode: '0104',
    Description: 'Cừu, dê sống',
    Unit: '',
  },
  {
    HsCode: '010410',
    Description: 'Cừu:',
    Unit: '',
  },
  {
    HsCode: '01041010',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '01041090',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '010420',
    Description: 'Dê:',
    Unit: '',
  },
  {
    HsCode: '01042010',
    Description: 'Loại thuần chủng để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '01042090',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '0105',
    Description: 'Gia cầm sống, gồm các loại gà thuộc loài Gallus domesticus, vịt, ngan, ngỗng, gà tây và gà lôi',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Loại khối lượng không quá 185 g:',
    Unit: '',
  },
  {
    HsCode: '010511',
    Description: 'Gà thuộc loài Gallus domesticus:',
    Unit: '',
  },
  {
    HsCode: '01051110',
    Description: 'Để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01051190',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '010512',
    Description: 'Gà tây:',
    Unit: '',
  },
  {
    HsCode: '01051210',
    Description: 'Để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01051290',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '010513',
    Description: 'Vịt, ngan:',
    Unit: '',
  },
  {
    HsCode: '01051310',
    Description: 'Để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01051390',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '010514',
    Description: 'Ngỗng:',
    Unit: '',
  },
  {
    HsCode: '01051410',
    Description: 'Để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01051490',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '010515',
    Description: 'Gà lôi:',
    Unit: '',
  },
  {
    HsCode: '01051510',
    Description: 'Để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01051590',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '010594',
    Description: 'Gà thuộc loài Gallus domesticus:',
    Unit: '',
  },
  {
    HsCode: '01059410',
    Description: 'Để nhân giống (SEN), trừ gà chọi',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Gà chọi:',
    Unit: '',
  },
  {
    HsCode: '01059441',
    Description: 'Khối lượng không quá 2 kg',
    Unit: 'kg/con',
  },
  {
    HsCode: '01059449',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '01059491',
    Description: 'Khối lượng không quá 2 kg',
    Unit: 'kg/con',
  },
  {
    HsCode: '01059499',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '010599',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '01059910',
    Description: 'Vịt, ngan để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01059920',
    Description: 'Vịt, ngan loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '01059930',
    Description: 'Ngỗng, gà tây và gà lôi để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01059940',
    Description: 'Ngỗng, gà tây và gà lôi loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '0106',
    Description: 'Động vật sống khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Động vật có vú:',
    Unit: '',
  },
  {
    HsCode: '01061100',
    Description: 'Bộ động vật linh trưởng',
    Unit: 'kg/con',
  },
  {
    HsCode: '010612',
    Description:
      'Cá voi, cá heo chuột và cá heo (động vật có vú thuộc bộ cá voi Cetacea); lợn biển và cá nược (động vật có vú thuộc bộ Sirenia); hải cẩu, sư tử biển và hải mã (con moóc) (động vật có vú thuộc phân bộ Pinnipedia):',
    Unit: '',
  },
  {
    HsCode: '01061210',
    Description:
      'Cá voi, cá heo chuột và cá heo (động vật có vú thuộc bộ cá voi Cetacea); lợn biển và cá nược (động vật có vú thuộc bộ Sirenia)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01061220',
    Description: 'Hải cẩu, sư tử biển và hải mã (con moóc) (động vật có vú thuộc phân bộ Pinnipedia)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01061300',
    Description: 'Lạc đà và họ lạc đà (Camelidae)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01061400',
    Description: 'Thỏ (Rabbits và hares)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01061900',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '01062000',
    Description: 'Loài bò sát (kể cả rắn và rùa)',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Các loại chim:',
    Unit: '',
  },
  {
    HsCode: '01063100',
    Description: 'Chim săn mồi ',
    Unit: 'kg/con',
  },
  {
    HsCode: '01063200',
    Description: 'Vẹt (kể cả vẹt lớn châu Mỹ (parrots), vẹt nhỏ đuôi dài (parakeets), vẹt đuôi dài và vẹt có mào)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01063300',
    Description: 'Đà điểu; đà điểu châu Úc (Dromaius novaehollandiae)',
    Unit: 'kg/con',
  },
  {
    HsCode: '01063900',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Côn trùng:',
    Unit: '',
  },
  {
    HsCode: '01064100',
    Description: 'Các loại ong',
    Unit: 'kg/con',
  },
  {
    HsCode: '01064900',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '01069000',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 2',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thịt và phụ phẩm dạng thịt ăn được sau giết mổ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Những sản phẩm thuộc loại đã được mô tả trong các nhóm 02.01 đến 02.08 hoặc 02.10, nhưng không thích hợp làm thức ăn cho người;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Côn trùng không còn sống, ăn được (nhóm 04.10);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Ruột, bong bóng hoặc dạ dày của động vật (nhóm 05.04) hoặc tiết động vật (nhóm 05.11 hoặc 30.02); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Mỡ động vật, trừ các sản phẩm của nhóm 02.09 (Chương 15).',
    Unit: '',
  },
  {
    HsCode: '0201',
    Description: 'Thịt của động vật họ trâu bò, tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '02011000',
    Description: 'Thịt cả con và nửa con',
    Unit: 'kg',
  },
  {
    HsCode: '02012000',
    Description: 'Thịt pha có xương khác',
    Unit: 'kg',
  },
  {
    HsCode: '02013000',
    Description: 'Thịt lọc không xương',
    Unit: 'kg',
  },
  {
    HsCode: '0202',
    Description: 'Thịt của động vật họ trâu bò, đông lạnh',
    Unit: '',
  },
  {
    HsCode: '02021000',
    Description: 'Thịt cả con và nửa con',
    Unit: 'kg',
  },
  {
    HsCode: '02022000',
    Description: 'Thịt pha có xương khác',
    Unit: 'kg',
  },
  {
    HsCode: '02023000',
    Description: 'Thịt lọc không xương',
    Unit: 'kg',
  },
  {
    HsCode: '0203',
    Description: 'Thịt lợn, tươi, ướp lạnh hoặc đông lạnh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '02031100',
    Description: 'Thịt cả con và nửa con',
    Unit: 'kg',
  },
  {
    HsCode: '02031200',
    Description: 'Thịt mông đùi (hams), thịt vai và các mảnh của chúng, có xương',
    Unit: 'kg',
  },
  {
    HsCode: '02031900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02032100',
    Description: 'Thịt cả con và nửa con',
    Unit: 'kg',
  },
  {
    HsCode: '02032200',
    Description: 'Thịt mông đùi (hams), thịt vai và các mảnh của chúng, có xương',
    Unit: 'kg',
  },
  {
    HsCode: '02032900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0204',
    Description: 'Thịt cừu hoặc dê, tươi, ướp lạnh hoặc đông lạnh',
    Unit: '',
  },
  {
    HsCode: '02041000',
    Description: 'Thịt cừu non cả con và nửa con, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Thịt cừu khác, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '02042100',
    Description: 'Thịt cả con và nửa con',
    Unit: 'kg',
  },
  {
    HsCode: '02042200',
    Description: 'Thịt pha có xương khác',
    Unit: 'kg',
  },
  {
    HsCode: '02042300',
    Description: 'Thịt lọc không xương',
    Unit: 'kg',
  },
  {
    HsCode: '02043000',
    Description: 'Thịt cừu non, cả con và nửa con, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Thịt cừu khác, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02044100',
    Description: 'Thịt cả con và nửa con',
    Unit: 'kg',
  },
  {
    HsCode: '02044200',
    Description: 'Thịt pha có xương khác',
    Unit: 'kg',
  },
  {
    HsCode: '02044300',
    Description: 'Thịt lọc không xương',
    Unit: 'kg',
  },
  {
    HsCode: '02045000',
    Description: 'Thịt dê',
    Unit: 'kg',
  },
  {
    HsCode: '02050000',
    Description: 'Thịt ngựa, lừa, la, tươi, ướp lạnh hoặc đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '0206',
    Description:
      'Phụ phẩm ăn được sau giết mổ của lợn, động vật họ trâu bò, cừu, dê, ngựa, la, lừa, tươi, ướp lạnh hoặc đông lạnh',
    Unit: '',
  },
  {
    HsCode: '02061000',
    Description: 'Của động vật họ trâu bò, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Của động vật họ trâu bò, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02062100',
    Description: 'Lưỡi',
    Unit: 'kg',
  },
  {
    HsCode: '02062200',
    Description: 'Gan',
    Unit: 'kg',
  },
  {
    HsCode: '02062900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '02063000',
    Description: 'Của lợn, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Của lợn, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02064100',
    Description: 'Gan',
    Unit: 'kg',
  },
  {
    HsCode: '02064900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '02068000',
    Description: 'Loại khác, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02069000',
    Description: 'Loại khác, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '0207',
    Description: 'Thịt và phụ phẩm ăn được sau giết mổ, của gia cầm thuộc nhóm 01.05, tươi, ướp lạnh hoặc đông lạnh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Của gà thuộc loài Gallus domesticus:',
    Unit: '',
  },
  {
    HsCode: '02071100',
    Description: 'Chưa chặt mảnh, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02071200',
    Description: 'Chưa chặt mảnh, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02071300',
    Description: 'Đã chặt mảnh và phụ phẩm sau giết mổ, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '020714',
    Description: 'Đã chặt mảnh và phụ phẩm sau giết mổ, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02071410',
    Description: 'Cánh',
    Unit: 'kg',
  },
  {
    HsCode: '02071420',
    Description: 'Đùi',
    Unit: 'kg',
  },
  {
    HsCode: '02071430',
    Description: 'Gan',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '02071491',
    Description: 'Thịt đã được lọc hoặc tách khỏi xương bằng phương pháp cơ học (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '02071499',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Của gà tây:',
    Unit: '',
  },
  {
    HsCode: '02072400',
    Description: 'Chưa chặt mảnh, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02072500',
    Description: 'Chưa chặt mảnh, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02072600',
    Description: 'Đã chặt mảnh và phụ phẩm sau giết mổ, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '020727',
    Description: 'Đã chặt mảnh và phụ phẩm sau giết mổ, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02072710',
    Description: 'Gan',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '02072791',
    Description: 'Thịt đã được lọc hoặc tách khỏi xương bằng phương pháp cơ học (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '02072799',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Của vịt, ngan:',
    Unit: '',
  },
  {
    HsCode: '02074100',
    Description: 'Chưa chặt mảnh, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02074200',
    Description: 'Chưa chặt mảnh, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02074300',
    Description: 'Gan béo, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02074400',
    Description: 'Loại khác, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '020745',
    Description: 'Loại khác, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02074510',
    Description: 'Gan béo',
    Unit: 'kg',
  },
  {
    HsCode: '02074590',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Của ngỗng:',
    Unit: '',
  },
  {
    HsCode: '02075100',
    Description: 'Chưa chặt mảnh, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02075200',
    Description: 'Chưa chặt mảnh, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02075300',
    Description: 'Gan béo, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02075400',
    Description: 'Loại khác, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '020755',
    Description: 'Loại khác, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '02075510',
    Description: 'Gan béo',
    Unit: 'kg',
  },
  {
    HsCode: '02075590',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '020760',
    Description: 'Của gà lôi: ',
    Unit: '',
  },
  {
    HsCode: '02076010',
    Description: 'Chưa chặt mảnh, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02076020',
    Description: 'Chưa chặt mảnh, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02076030',
    Description: 'Đã chặt mảnh và phụ phẩm sau giết mổ, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '02076040',
    Description: 'Đã chặt mảnh và phụ phẩm sau giết mổ, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '0208',
    Description: 'Thịt và phụ phẩm dạng thịt ăn được sau giết mổ của động vật khác, tươi, ướp lạnh hoặc đông lạnh',
    Unit: '',
  },
  {
    HsCode: '02081000',
    Description: 'Của thỏ hoặc thỏ rừng',
    Unit: 'kg',
  },
  {
    HsCode: '02083000',
    Description: 'Của bộ động vật linh trưởng',
    Unit: 'kg',
  },
  {
    HsCode: '020840',
    Description:
      'Của cá voi, cá heo chuột và cá heo (động vật có vú thuộc bộ Cetacea); của lợn biển và cá nược (động vật có vú thuộc bộ Sirenia); của hải cẩu, sư tử biển và con moóc (hải mã) (động vật có vú thuộc phân bộ Pinnipedia):',
    Unit: '',
  },
  {
    HsCode: '02084010',
    Description:
      'Của cá voi, cá heo chuột và cá heo (động vật có vú thuộc bộ Cetacea); của lợn biển và cá nược (động vật có vú thuộc bộ Sirenia)',
    Unit: 'kg',
  },
  {
    HsCode: '02084090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '02085000',
    Description: 'Của loài bò sát (kể cả rắn và rùa)',
    Unit: 'kg',
  },
  {
    HsCode: '02086000',
    Description: 'Của lạc đà và họ lạc đà (Camelidae)',
    Unit: 'kg',
  },
  {
    HsCode: '020890',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '02089010',
    Description: 'Đùi ếch',
    Unit: 'kg',
  },
  {
    HsCode: '02089090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0209',
    Description:
      'Mỡ lợn không dính nạc và mỡ gia cầm, chưa nấu chảy hoặc chiết xuất cách khác, tươi, ướp lạnh, đông lạnh, muối, ngâm nước muối, làm khô hoặc hun khói',
    Unit: '',
  },
  {
    HsCode: '02091000',
    Description: 'Của lợn',
    Unit: 'kg',
  },
  {
    HsCode: '02099000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0210',
    Description:
      'Thịt và phụ phẩm dạng thịt ăn được sau giết mổ, muối, ngâm nước muối, làm khô hoặc hun khói; bột mịn và bột thô ăn được làm từ thịt hoặc phụ phẩm dạng thịt sau giết mổ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thịt lợn:',
    Unit: '',
  },
  {
    HsCode: '02101100',
    Description: 'Thịt mông đùi (hams), thịt vai và các mảnh của chúng, có xương',
    Unit: 'kg',
  },
  {
    HsCode: '02101200',
    Description: 'Thịt dọi (ba chỉ) và các mảnh của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '021019',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '02101930',
    Description: 'Thịt lợn muối xông khói (bacon); thịt mông đùi (hams) không xương',
    Unit: 'kg',
  },
  {
    HsCode: '02101990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '02102000',
    Description: 'Thịt động vật họ trâu bò',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, kể cả bột mịn và bột thô ăn được làm từ thịt hoặc phụ phẩm dạng thịt sau giết mổ:',
    Unit: '',
  },
  {
    HsCode: '02109100',
    Description: 'Của bộ động vật linh trưởng',
    Unit: 'kg',
  },
  {
    HsCode: '021092',
    Description:
      'Của cá voi, cá heo chuột và cá heo (động vật có vú thuộc bộ Cetacea); của lợn biển và cá nược (động vật có vú thuộc bộ Sirenia); của hải cẩu, sư tử biển và con moóc (hải mã) (động vật có vú thuộc phân bộ Pinnipedia):',
    Unit: '',
  },
  {
    HsCode: '02109210',
    Description:
      'Của cá voi, cá heo chuột và cá heo (động vật có vú thuộc bộ Cetacea); của lợn biển và cá nược (động vật có vú thuộc bộ Sirenia)',
    Unit: 'kg',
  },
  {
    HsCode: '02109290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '02109300',
    Description: 'Của loài bò sát (kể cả rắn và rùa)',
    Unit: 'kg',
  },
  {
    HsCode: '021099',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '02109910',
    Description: 'Thịt gà thái miếng đã được làm khô đông lạnh (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '02109920',
    Description: 'Da lợn khô ',
    Unit: 'kg',
  },
  {
    HsCode: '02109990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 3',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá và động vật giáp xác, động vật thân mềm và động vật thủy sinh không xương sống khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Động vật có vú thuộc nhóm 01.06;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Thịt của động vật có vú thuộc nhóm 01.06 (nhóm 02.08 hoặc 02.10);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Cá (kể cả gan, sẹ và bọc trứng cá) hoặc động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, đã chết và không thích hợp dùng làm thức ăn cho người hoặc vì lý do chủng loại hoặc vì trạng thái của chúng (Chương 5); các loại bột mịn, bột thô hoặc viên làm từ cá hoặc động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, không thích hợp dùng làm thức ăn cho người (nhóm 23.01); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Trứng cá tầm muối hoặc các sản phẩm thay thế trứng cá tầm muối từ trứng cá (nhóm 16.04).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong Chương này khái niệm ""viên"" (pellets) có nghĩa là các sản phẩm được liên kết hoặc bằng cách nén trực tiếp hoặc bằng cách cho thêm một lượng nhỏ chất kết dính.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các nhóm từ 03.05 đến 03.08 không bao gồm bột mịn, bột thô và viên, thích hợp dùng làm thức ăn cho người (nhóm 03.09).',
    Unit: '',
  },
  {
    HsCode: '0301',
    Description: 'Cá sống',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá cảnh:',
    Unit: '',
  },
  {
    HsCode: '030111',
    Description: 'Cá nước ngọt:',
    Unit: '',
  },
  {
    HsCode: '03011110',
    Description: 'Cá bột',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03011191',
    Description: 'Cá chép Koi (Cyprinus carpio)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03011192',
    Description: 'Cá vàng (Carassius auratus)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03011193',
    Description: 'Cá chọi Thái Lan (Beta splendens)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03011195',
    Description: 'Cá rồng (Scleropages formosus)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03011199',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '030119',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03011910',
    Description: 'Cá bột',
    Unit: 'kg/con',
  },
  {
    HsCode: '03011990',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Cá sống khác:',
    Unit: '',
  },
  {
    HsCode: '03019100',
    Description:
      'Cá hồi chấm (trout) (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache và Oncorhynchus chrysogaster)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019200',
    Description: 'Cá chình (Anguilla spp.)',
    Unit: 'kg/con',
  },
  {
    HsCode: '030193',
    Description:
      'Cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.):',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus):',
    Unit: '',
  },
  {
    HsCode: '03019321',
    Description: 'Để nhân giống, trừ cá bột (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019322',
    Description: 'Cá bột',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019329',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Cá chép (Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.):',
    Unit: '',
  },
  {
    HsCode: '03019331',
    Description: 'Để nhân giống, trừ cá bột (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019332',
    Description: 'Cá bột',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019339',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019400',
    Description: 'Cá ngừ vây xanh Đại Tây Dương và Thái Bình Dương (Thunnus thynnus, Thunnus orientalis)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019500',
    Description: 'Cá ngừ vây xanh phương Nam (Thunnus maccoyii)',
    Unit: 'kg/con',
  },
  {
    HsCode: '030199',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá bột của cá măng biển và của cá mú:',
    Unit: '',
  },
  {
    HsCode: '03019911',
    Description: 'Để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019919',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Cá bột loại khác:',
    Unit: '',
  },
  {
    HsCode: '03019922',
    Description: 'Cá chép khác, để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019923',
    Description: 'Cá chép loại khác (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019924',
    Description: 'Loại khác, để nhân giống',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019929',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Cá măng biển và cá mú, trừ cá bột:',
    Unit: '',
  },
  {
    HsCode: '03019931',
    Description: 'Cá măng biển để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019932',
    Description: 'Cá măng biển, loại khác (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019933',
    Description: 'Cá mú chấm nhỏ (Plectropomus leopardus)(SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019934',
    Description: 'Cá mú hoa nâu/cá mú cọp (Epinephelus fuscoguttatus) (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019935',
    Description: 'Cá mú dẹt (Cromileptes altivelis) (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019936',
    Description: 'Cá mú loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '',
    Description: 'Cá nước ngọt khác:',
    Unit: '',
  },
  {
    HsCode: '03019941',
    Description: 'Cá rô phi (Oreochromis spp.)(SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019942',
    Description: 'Cá chép khác, để nhân giống (SEN)',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019949',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019950',
    Description: 'Cá biển khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '03019990',
    Description: 'Loại khác',
    Unit: 'kg/con',
  },
  {
    HsCode: '0302',
    Description: 'Cá, tươi hoặc ướp lạnh, trừ phi-lê cá (fillets) và các loại thịt cá khác thuộc nhóm 03.04',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá hồi, trừ phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0302.91 đến 0302.99:',
    Unit: '',
  },
  {
    HsCode: '03021100',
    Description:
      'Cá hồi chấm (trout) (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache và Oncorhynchus chrysogaster)',
    Unit: 'kg',
  },
  {
    HsCode: '03021300',
    Description:
      'Cá hồi Thái Bình Dương (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou và Oncorhynchus rhodurus)',
    Unit: 'kg',
  },
  {
    HsCode: '03021400',
    Description: 'Cá hồi Đại Tây Dương (Salmo salar) và cá hồi sông Đa-nuýp (Hucho hucho)',
    Unit: 'kg',
  },
  {
    HsCode: '03021900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá bơn (Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae và Citharidae), trừ phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0302.91 đến 0302.99:',
    Unit: '',
  },
  {
    HsCode: '03022100',
    Description:
      'Cá bơn lưỡi ngựa(Halibut) (Reinhardtius hippoglossoides, Hippoglossus hippoglossus, Hippoglossus stenolepis)',
    Unit: 'kg',
  },
  {
    HsCode: '03022200',
    Description: 'Cá bơn sao (Pleuronectes platessa)',
    Unit: 'kg',
  },
  {
    HsCode: '03022300',
    Description: 'Cá bơn sole (Solea spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03022400',
    Description: 'Cá bơn turbots (Psetta maxima)',
    Unit: 'kg',
  },
  {
    HsCode: '03022900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá ngừ đại dương (thuộc giống Thunnus), cá ngừ vằn (cá ngừ sọc dưa) (Katsuwonus pelamis), trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0302.91 đến 0302.99:',
    Unit: '',
  },
  {
    HsCode: '03023100',
    Description: 'Cá ngừ vây dài (Thunnus alalunga)',
    Unit: 'kg',
  },
  {
    HsCode: '03023200',
    Description: 'Cá ngừ vây vàng (Thunnus albacares)',
    Unit: 'kg',
  },
  {
    HsCode: '03023300',
    Description: 'Cá ngừ vằn (cá ngừ sọc dưa) (Katsuwonus pelamis)',
    Unit: 'kg',
  },
  {
    HsCode: '03023400',
    Description: 'Cá ngừ mắt to (Thunnus obesus)',
    Unit: 'kg',
  },
  {
    HsCode: '03023500',
    Description: 'Cá ngừ vây xanh Đại Tây Dương và Thái Bình Dương (Thunnus thynnus, Thunnus orientalis)',
    Unit: 'kg',
  },
  {
    HsCode: '03023600',
    Description: 'Cá ngừ vây xanh phương Nam (Thunnus maccoyii)',
    Unit: 'kg',
  },
  {
    HsCode: '03023900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá trích nước lạnh (Clupea harengus, Clupea pallasii), cá cơm (cá trỏng) (Engraulis spp.), cá trích dầu (Sardina pilchardus, Sardinops spp.), cá trích xương (Sardinella spp.), cá trích kê hoặc cá trích cơm (Sprattus sprattus), cá nục hoa (Scomber scombrus, Scomber australasicus, Scomber japonicus), cá bạc má (Rastrelliger spp.), cá thu (Scomberomorus spp.), cá nục gai và cá sòng (Trachurus spp.), cá khế jacks, cá khế crevalles (Caranx spp.), cá giò (Rachycentron canadum), cá chim trắng (Pampus spp.), cá thu đao (Cololabis saira), cá nục (Decapterus spp.), cá trứng (Mallotus villosus), cá kiếm (Xiphias gladius), cá ngừ chấm (Euthynnus affinis), cá ngừ ba chấm (Sarda spp.), cá cờ marlin, cá cờ lá (sailfish), cá cờ spearfish (Istiophoridae), trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0302.91 đến 0302.99:',
    Unit: '',
  },
  {
    HsCode: '03024100',
    Description: 'Cá trích nước lạnh (Clupea harengus, Clupea pallasii)',
    Unit: 'kg',
  },
  {
    HsCode: '03024200',
    Description: 'Cá cơm (cá trỏng) (Engraulis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03024300',
    Description:
      'Cá trích dầu (Sardina pilchardus, Sardinops spp.), cá trích xương (Sardinella spp.), cá trích kê hoặc cá trích cơm (Sprattus sprattus)',
    Unit: 'kg',
  },
  {
    HsCode: '03024400',
    Description: 'Cá nục hoa (Scomber scombrus, Scomber australasicus, Scomber japonicus)',
    Unit: 'kg',
  },
  {
    HsCode: '03024500',
    Description: 'Cá nục gai và cá sòng (Trachurus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03024600',
    Description: 'Cá giò (Rachycentron canadum) ',
    Unit: 'kg',
  },
  {
    HsCode: '03024700',
    Description: 'Cá kiếm (Xiphias gladius)',
    Unit: 'kg',
  },
  {
    HsCode: '03024900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae, trừ phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0302.91 đến 0302.99:',
    Unit: '',
  },
  {
    HsCode: '03025100',
    Description: 'Cá tuyết (Gadus morhua, Gadus ogac, Gadus macrocephalus)',
    Unit: 'kg',
  },
  {
    HsCode: '03025200',
    Description: 'Cá tuyết chấm đen (Melanogrammus aeglefinus)',
    Unit: 'kg',
  },
  {
    HsCode: '03025300',
    Description: 'Cá tuyết đen (Pollachius virens)',
    Unit: 'kg',
  },
  {
    HsCode: '03025400',
    Description: 'Cá tuyết hake (Merluccius spp., Urophycis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03025500',
    Description: 'Cá Minh Thái (Alaska Pollock) (Theragra chalcogramma)',
    Unit: 'kg',
  },
  {
    HsCode: '03025600',
    Description: 'Cá tuyết lam (Micromesistius poutassou, Micromesistius australis)',
    Unit: 'kg',
  },
  {
    HsCode: '03025900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile(Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.), trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0302.91 đến 0302.99:',
    Unit: '',
  },
  {
    HsCode: '03027100',
    Description: 'Cá rô phi (Oreochromis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '030272',
    Description: 'Cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.):',
    Unit: '',
  },
  {
    HsCode: '03027210',
    Description: 'Cá tra đuôi vàng (Pangasius pangasius)',
    Unit: 'kg',
  },
  {
    HsCode: '03027290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '03027300',
    Description:
      'Cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03027400',
    Description: 'Cá chình (Anguilla spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03027900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Cá khác, trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0302.91 đến 0302.99:',
    Unit: '',
  },
  {
    HsCode: '03028100',
    Description: 'Cá nhám góc và cá mập khác',
    Unit: 'kg',
  },
  {
    HsCode: '03028200',
    Description: 'Cá đuối (Rajidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03028300',
    Description: 'Cá răng cưa (Dissostichus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03028400',
    Description: 'Cá vược (hoặc cá vược Châu Âu) (Dicentrarchus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03028500',
    Description: 'Cá tráp biển (Sparidae)',
    Unit: 'kg',
  },
  {
    HsCode: '030289',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá biển:',
    Unit: '',
  },
  {
    HsCode: '03028911',
    Description: 'Cá mú ',
    Unit: 'kg',
  },
  {
    HsCode: '03028912',
    Description: 'Cá bạc (Pentaprion longimanus)',
    Unit: 'kg',
  },
  {
    HsCode: '03028913',
    Description: 'Cá mối hoa (Trachinocephalus myops)',
    Unit: 'kg',
  },
  {
    HsCode: '03028914',
    Description:
      'Cá hố savalai (Lepturacanthus savala), cá đù Belanger (Johnius belangerii), cá đù Reeve (Chrysochir aureus) và cá đù mắt to (Pennahia anea)',
    Unit: 'kg',
  },
  {
    HsCode: '03028915',
    Description: 'Cá nhụ Ấn Độ (Polynemus indicus)',
    Unit: 'kg',
  },
  {
    HsCode: '03028916',
    Description:
      'Cá sòng gió (Megalaspis cordyla), cá hiên chấm (Drepane punctata) và cá nhồng lớn (Sphyraena barracuda)',
    Unit: 'kg',
  },
  {
    HsCode: '03028917',
    Description: 'Cá chim đen (Parastromatus niger)',
    Unit: 'kg',
  },
  {
    HsCode: '03028918',
    Description: 'Cá hồng bạc (Lutjanus argentimaculatus)',
    Unit: 'kg',
  },
  {
    HsCode: '03028919',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03028922',
    Description: 'Cá đòng đong đầm lầy (Puntius chola)',
    Unit: 'kg',
  },
  {
    HsCode: '03028923',
    Description: 'Cá sạo bạc (Pomadasys argenteus)',
    Unit: 'kg',
  },
  {
    HsCode: '03028927',
    Description: 'Cá mòi Hilsa (Tenualosa ilisha)',
    Unit: 'kg',
  },
  {
    HsCode: '03028928',
    Description: 'Cá leo (Wallago attu) và cá tra dầu (Sperata seenghala)',
    Unit: 'kg',
  },
  {
    HsCode: '03028929',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Gan, sẹ và bọc trứng cá, vây, đầu, đuôi, bong bóng và các phụ phẩm ăn được sau giết mổ khác của cá:',
    Unit: '',
  },
  {
    HsCode: '03029100',
    Description: 'Gan, sẹ và bọc trứng cá',
    Unit: 'kg',
  },
  {
    HsCode: '03029200',
    Description: 'Vây cá mập',
    Unit: 'kg',
  },
  {
    HsCode: '03029900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0303',
    Description: 'Cá, đông lạnh, trừ phi-lê cá (fillets) và các loại thịt cá khác thuộc nhóm 03.04',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá hồi, trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0303.91 đến 0303.99:',
    Unit: '',
  },
  {
    HsCode: '03031100',
    Description: 'Cá hồi đỏ (Oncorhynchus nerka)',
    Unit: 'kg',
  },
  {
    HsCode: '03031200',
    Description:
      'Cá hồi Thái Bình Dương khác (Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou và Oncorhynchus rhodurus)',
    Unit: 'kg',
  },
  {
    HsCode: '03031300',
    Description: 'Cá hồi Đại Tây Dương (Salmo salar) và cá hồi sông Đa-nuýp (Hucho hucho)',
    Unit: 'kg',
  },
  {
    HsCode: '03031400',
    Description:
      'Cá hồi chấm (trout) (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache và Oncorhynchus chrysogaster)',
    Unit: 'kg',
  },
  {
    HsCode: '03031900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá lóc hoặc cá chuối) (Channa spp.), trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0303.91 đến 0303.99:',
    Unit: '',
  },
  {
    HsCode: '03032300',
    Description: 'Cá rô phi (Oreochromis spp.) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03032400',
    Description: 'Cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03032500',
    Description:
      'Cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03032600',
    Description: 'Cá chình (Anguilla spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03032900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá bơn (Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae và Citharidae), trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0303.91 đến 0303.99:',
    Unit: '',
  },
  {
    HsCode: '03033100',
    Description:
      'Cá bơn lưỡi ngựa (Halibut) (Reinhardtius hippoglossoides, Hippoglossus hippoglossus, Hippoglossus stenolepis)',
    Unit: 'kg',
  },
  {
    HsCode: '03033200',
    Description: 'Cá bơn sao (Pleuronectes platessa)',
    Unit: 'kg',
  },
  {
    HsCode: '03033300',
    Description: 'Cá bơn sole (Solea spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03033400',
    Description: 'Cá bơn turbot (Psetta maxima)',
    Unit: 'kg',
  },
  {
    HsCode: '03033900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá ngừ đại dương (thuộc giống Thunus), cá ngừ vằn (cá ngừ sọc dưa) (Katsuwonus pelamis), trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0303.91 đến 0303.99:',
    Unit: '',
  },
  {
    HsCode: '03034100',
    Description: 'Cá ngừ vây dài (Thunnus alalunga)',
    Unit: 'kg',
  },
  {
    HsCode: '03034200',
    Description: 'Cá ngừ vây vàng (Thunnus albacares)',
    Unit: 'kg',
  },
  {
    HsCode: '03034300',
    Description: 'Cá ngừ vằn (cá ngừ sọc dưa) (Katsuwonus pelamis)',
    Unit: 'kg',
  },
  {
    HsCode: '03034400',
    Description: 'Cá ngừ mắt to (Thunnus obesus)',
    Unit: 'kg',
  },
  {
    HsCode: '030345',
    Description: 'Cá ngừ vây xanh Đại Tây Dương và Thái Bình Dương (Thunnus thynnus, Thunnus orientalis):',
    Unit: '',
  },
  {
    HsCode: '03034510',
    Description: 'Cá ngừ vây xanh Đại Tây Dương (Thunnus thynnus)',
    Unit: 'kg',
  },
  {
    HsCode: '03034590',
    Description: 'Cá ngừ vây xanh Thái Bình Dương (Thunnus orientalis)',
    Unit: 'kg',
  },
  {
    HsCode: '03034600',
    Description: 'Cá ngừ vây xanh phương Nam (Thunnus maccoyii)',
    Unit: 'kg',
  },
  {
    HsCode: '030349',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03034910',
    Description: 'Cá ngừ bò (Thunnus tonggol) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03034990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá trích nước lạnh (Clupea harengus, Clupea pallasii), cá cơm (cá trỏng) (Engraulis spp.), cá trích dầu (Sardina pilchardus, Sardinops spp.), cá trích xương (Sardinella spp.), cá trích kê hoặc cá trích cơm (Sprattus sprattus), cá nục hoa (Scomber scombrus, Scomber australasicus, Scomber japonicus), cá bạc má (Rastrelliger spp.), cá thu (Scomberomorus spp.), cá nục gai và cá sòng (Trachurus spp.), cá khế jacks, cá khế crevalles (Caranx spp.), cá giò (Rachycentron canadum), cá chim trắng (Pampus spp.), cá thu đao (Cololabis saira), cá nục (Decapterus spp.), cá trứng (Mallotus villosus), cá kiếm (Xiphias gladius), cá ngừ chấm (Euthynnus affinis), cá ngừ ba chấm (Sarda spp.), cá cờ marlin, cá cờ lá (sailfishes), cá cờ spearfish (Istiophoridae), trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0303.91 đến 0303.99:',
    Unit: '',
  },
  {
    HsCode: '03035100',
    Description: 'Cá trích nước lạnh (Clupea harengus, Clupea pallasii)',
    Unit: 'kg',
  },
  {
    HsCode: '03035300',
    Description:
      'Cá trích dầu (Sardina pilchardus, Sardinops spp.), cá trích xương (Sardinella spp.), cá trích kê hoặc cá trích cơm (Sprattus sprattus)',
    Unit: 'kg',
  },
  {
    HsCode: '030354',
    Description: 'Cá nục hoa (Scomber scombrus, Scomber australasicus, Scomber japonicus):',
    Unit: '',
  },
  {
    HsCode: '03035410',
    Description: 'Cá nục hoa (Scomber scombrus, Scomber australasicus) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03035420',
    Description: 'Cá thu ngừ Thái Bình Dương (sa ba) (Scomber japonicus) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03035500',
    Description: 'Cá nục gai và cá sòng (Trachurus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03035600',
    Description: 'Cá giò (Rachycentron canadum) ',
    Unit: 'kg',
  },
  {
    HsCode: '03035700',
    Description: 'Cá kiếm (Xiphias gladius)',
    Unit: 'kg',
  },
  {
    HsCode: '030359',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03035910',
    Description: 'Cá bạc má (Rastrelliger kanagurta); cá bạc má đảo (Rastrelliger faughni)',
    Unit: 'kg',
  },
  {
    HsCode: '03035920',
    Description: 'Cá chim trắng (Pampus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03035990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae, trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0303.91 đến 0303.99:',
    Unit: '',
  },
  {
    HsCode: '03036300',
    Description: 'Cá tuyết (Gadus morhua, Gadus ogac, Gadus macrocephalus)',
    Unit: 'kg',
  },
  {
    HsCode: '03036400',
    Description: 'Cá tuyết chấm đen (Melanogrammus aeglefinus)',
    Unit: 'kg',
  },
  {
    HsCode: '03036500',
    Description: 'Cá tuyết đen (Pollachius virens)',
    Unit: 'kg',
  },
  {
    HsCode: '03036600',
    Description: 'Cá tuyết hake (Merluccius spp., Urophycis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03036700',
    Description: 'Cá Minh Thái (Alaska Pollock) (Theragra chalcogramma)',
    Unit: 'kg',
  },
  {
    HsCode: '03036800',
    Description: 'Cá tuyết lam (Micromesistius poutassou, Micromesistius australis)',
    Unit: 'kg',
  },
  {
    HsCode: '03036900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Loại cá khác, trừ các phụ phẩm ăn được sau giết mổ của cá thuộc các phân nhóm từ 0303.91 đến 0303.99:',
    Unit: '',
  },
  {
    HsCode: '03038100',
    Description: 'Cá nhám góc và cá mập khác',
    Unit: 'kg',
  },
  {
    HsCode: '03038200',
    Description: 'Cá đuối (Rajidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03038300',
    Description: 'Cá răng cưa (Dissostichus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03038400',
    Description: 'Cá vược (hoặc cá vược Châu Âu) (Dicentrarchus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '030389',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá biển:',
    Unit: '',
  },
  {
    HsCode: '03038911',
    Description: 'Cá mú',
    Unit: 'kg',
  },
  {
    HsCode: '03038913',
    Description: 'Cá mối hoa (Trachinocephalus myops)',
    Unit: 'kg',
  },
  {
    HsCode: '03038914',
    Description:
      'Cá hố savalai (Lepturacanthus savala), cá đù Belanger (Johnius belangerii), cá đù Reeve (Chrysochir aureus) và cá đù mắt to (Pennahia anea)',
    Unit: 'kg',
  },
  {
    HsCode: '03038915',
    Description: 'Cá nhụ Ấn Độ (Polynemus indicus) ',
    Unit: 'kg',
  },
  {
    HsCode: '03038916',
    Description:
      'Cá sòng gió (Megalaspis cordyla), cá hiên chấm (Drepane punctata) và cá nhồng lớn (Sphyraena barracuda)',
    Unit: 'kg',
  },
  {
    HsCode: '03038917',
    Description: 'Cá chim đen (Parastromatus niger)',
    Unit: 'kg',
  },
  {
    HsCode: '03038918',
    Description: 'Cá hồng bạc (Lutjanus argentimaculatus)',
    Unit: 'kg',
  },
  {
    HsCode: '03038919',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03038922',
    Description: 'Cá đòng đong đầm lầy (Puntius chola)',
    Unit: 'kg',
  },
  {
    HsCode: '03038923',
    Description: 'Cá măng biển (Chanos chanos) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03038924',
    Description: 'Cá sạo bạc (Pomadasys argenteus)',
    Unit: 'kg',
  },
  {
    HsCode: '03038927',
    Description: 'Cá mòi Hilsa (Tenualosa ilisha)',
    Unit: 'kg',
  },
  {
    HsCode: '03038928',
    Description: 'Cá leo (Wallago attu) và cá tra dầu (Sperata seenghala)',
    Unit: 'kg',
  },
  {
    HsCode: '03038929',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Gan, sẹ và bọc trứng cá, vây, đầu, đuôi, bong bóng và các phụ phẩm ăn được sau giết mổ khác của cá:',
    Unit: '',
  },
  {
    HsCode: '03039100',
    Description: 'Gan, sẹ và bọc trứng cá',
    Unit: 'kg',
  },
  {
    HsCode: '03039200',
    Description: 'Vây cá mập',
    Unit: 'kg',
  },
  {
    HsCode: '03039900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0304',
    Description: 'Phi-lê cá và các loại thịt cá khác (đã hoặc chưa xay, nghiền, băm), tươi, ướp lạnh hoặc đông lạnh',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Phi-lê cá tươi hoặc ướp lạnh của cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.):',
    Unit: '',
  },
  {
    HsCode: '03043100',
    Description: 'Cá rô phi (Oreochromis spp.) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03043200',
    Description: 'Cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03043300',
    Description: 'Cá rô sông Nile (Lates niloticus)',
    Unit: 'kg',
  },
  {
    HsCode: '03043900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Phi-lê cá tươi hoặc ướp lạnh của các loại cá khác:',
    Unit: '',
  },
  {
    HsCode: '03044100',
    Description:
      'Cá hồi Thái Bình Dương (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou và Oncorhynchus rhodurus), cá hồi Đại Tây Dương (Salmo salar) và cá hồi sông Đa-nuýp (Hucho hucho)',
    Unit: 'kg',
  },
  {
    HsCode: '03044200',
    Description:
      'Cá hồi chấm (trout) (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache và Oncorhynchus chrysogaster)',
    Unit: 'kg',
  },
  {
    HsCode: '03044300',
    Description: 'Cá bơn (Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae và Citharidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03044400',
    Description:
      'Cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae',
    Unit: 'kg',
  },
  {
    HsCode: '03044500',
    Description: 'Cá kiếm (Xiphias gladius)',
    Unit: 'kg',
  },
  {
    HsCode: '03044600',
    Description: 'Cá răng cưa (Dissostichus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03044700',
    Description: 'Cá nhám góc và cá mập khác',
    Unit: 'kg',
  },
  {
    HsCode: '03044800',
    Description: 'Cá đuối (Rajidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03044900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03045100',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03045200',
    Description: 'Cá hồi',
    Unit: 'kg',
  },
  {
    HsCode: '03045300',
    Description:
      'Cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae',
    Unit: 'kg',
  },
  {
    HsCode: '03045400',
    Description: 'Cá kiếm (Xiphias gladius)',
    Unit: 'kg',
  },
  {
    HsCode: '03045500',
    Description: 'Cá răng cưa (Dissostichus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03045600',
    Description: 'Cá nhám góc và cá mập khác',
    Unit: 'kg',
  },
  {
    HsCode: '03045700',
    Description: 'Cá đuối (Rajidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03045900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Phi-lê đông lạnh của cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.):',
    Unit: '',
  },
  {
    HsCode: '03046100',
    Description: 'Cá rô phi (Oreochromis spp.) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03046200',
    Description: 'Cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03046300',
    Description: 'Cá rô sông Nile (Lates niloticus)',
    Unit: 'kg',
  },
  {
    HsCode: '03046900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Phi-lê đông lạnh của cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae:',
    Unit: '',
  },
  {
    HsCode: '03047100',
    Description: 'Cá tuyết (Gadus morhua, Gadus ogac, Gadus macrocephalus)',
    Unit: 'kg',
  },
  {
    HsCode: '03047200',
    Description: 'Cá tuyết chấm đen (Melanogrammus aeglefinus)',
    Unit: 'kg',
  },
  {
    HsCode: '03047300',
    Description: 'Cá tuyết đen (Pollachius virens)',
    Unit: 'kg',
  },
  {
    HsCode: '03047400',
    Description: 'Cá tuyết hake (Merluccius spp., Urophycis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03047500',
    Description: 'Cá Minh Thái (Alaska Pollock) (Theragra chalcogramma)',
    Unit: 'kg',
  },
  {
    HsCode: '03047900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Phi-lê đông lạnh của các loại cá khác:',
    Unit: '',
  },
  {
    HsCode: '03048100',
    Description:
      'Cá hồi Thái Bình Dương (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou và Oncorhynchus rhodurus), cá hồi Đại Tây Dương (Salmo salar) và cá hồi sông Đa-nuýp (Hucho hucho)',
    Unit: 'kg',
  },
  {
    HsCode: '03048200',
    Description:
      'Cá hồi chấm (trout) (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache và Oncorhynchus chrysogaster)',
    Unit: 'kg',
  },
  {
    HsCode: '03048300',
    Description: 'Cá bơn (Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae và Citharidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03048400',
    Description: 'Cá kiếm (Xiphias gladius)',
    Unit: 'kg',
  },
  {
    HsCode: '03048500',
    Description: 'Cá răng cưa (Dissostichus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03048600',
    Description: 'Cá trích nước lạnh (Clupea harengus, Clupea pallasii)',
    Unit: 'kg',
  },
  {
    HsCode: '03048700',
    Description: 'Cá ngừ đại dương (thuộc giống Thunus), cá ngừ vằn (cá ngừ sọc dưa) (Katsuwonus pelamis)',
    Unit: 'kg',
  },
  {
    HsCode: '03048800',
    Description: 'Cá nhám góc, cá mập khác, cá đuối (Rajidae)',
    Unit: 'kg',
  },
  {
    HsCode: '030489',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03048910',
    Description: 'Cá nục heo cờ (Coryphaena hippurus) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03048990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '03049100',
    Description: 'Cá kiếm (Xiphias gladius)',
    Unit: 'kg',
  },
  {
    HsCode: '03049200',
    Description: 'Cá răng cưa (Dissostichus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03049300',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03049400',
    Description: 'Cá Minh Thái (Alaska Pollock) (Theragra chalcogramma)',
    Unit: 'kg',
  },
  {
    HsCode: '03049500',
    Description:
      'Cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae, trừ cá Minh Thái (Alaska Pollock) (Theragra chalcogramma)',
    Unit: 'kg',
  },
  {
    HsCode: '03049600',
    Description: 'Cá nhám góc và cá mập khác',
    Unit: 'kg',
  },
  {
    HsCode: '03049700',
    Description: 'Cá đuối (Rajidae)',
    Unit: 'kg',
  },
  {
    HsCode: '030499',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03049910',
    Description: 'Surimi (thịt cá xay) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03049990',
    Description: 'Loại khác ',
    Unit: 'kg',
  },
  {
    HsCode: '0305',
    Description:
      'Cá, làm khô, muối hoặc ngâm nước muối; cá hun khói, đã hoặc chưa làm chín trước hoặc trong quá trình hun khói',
    Unit: '',
  },
  {
    HsCode: '030520',
    Description: 'Gan, sẹ và bọc trứng cá, làm khô, hun khói, muối hoặc ngâm nước muối:',
    Unit: '',
  },
  {
    HsCode: '03052010',
    Description: 'Của cá nước ngọt, làm khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03052090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Phi-lê cá, làm khô, muối hoặc ngâm nước muối, nhưng không hun khói:',
    Unit: '',
  },
  {
    HsCode: '03053100',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03053200',
    Description:
      'Cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae',
    Unit: 'kg',
  },
  {
    HsCode: '030539',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03053910',
    Description:
      'Cá nhói nước ngọt (Xenentodon cancila), cá phèn dải vàng (Upeneus vittatus) và cá khế lược mang dài (Ulua mentalis)',
    Unit: 'kg',
  },
  {
    HsCode: '03053920',
    Description:
      'Cá hố savalai (Lepturacanthus savala), cá đù Belanger (Johnius belangerii), cá đù Reeve (Chrysochir aureus) và cá đù mắt to (Pennahia anea)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03053991',
    Description: 'Của cá nước ngọt',
    Unit: 'kg',
  },
  {
    HsCode: '03053992',
    Description: 'Của cá biển',
    Unit: 'kg',
  },
  {
    HsCode: '03053999',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Cá hun khói, kể cả phi-lê cá, trừ phụ phẩm ăn được sau giết mổ:',
    Unit: '',
  },
  {
    HsCode: '03054100',
    Description:
      'Cá hồi Thái Bình Dương (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou và Oncorhynchus rhodurus), cá hồi Đại Tây Dương (Salmo salar) và cá hồi sông Đa-nuýp (Hucho hucho)',
    Unit: 'kg',
  },
  {
    HsCode: '03054200',
    Description: 'Cá trích nước lạnh (Clupea harengus, Clupea pallasii)',
    Unit: 'kg',
  },
  {
    HsCode: '03054300',
    Description:
      'Cá hồi chấm (trout) (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache và Oncorhynchus chrysogaster)',
    Unit: 'kg',
  },
  {
    HsCode: '03054400',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '030549',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03054910',
    Description: 'Cá ngừ vằn (cá ngừ sọc dưa) (Katsuwonus pelamis)',
    Unit: 'kg',
  },
  {
    HsCode: '03054990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Cá khô, trừ phụ phẩm ăn được sau giết mổ, có hoặc không muối nhưng không hun khói:',
    Unit: '',
  },
  {
    HsCode: '03055100',
    Description: 'Cá tuyết (Gadus morhua, Gadus ogac, Gadus macrocephalus)',
    Unit: 'kg',
  },
  {
    HsCode: '03055200',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03055300',
    Description:
      'Cá thuộc các họ Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae và Muraenolepididae, trừ cá tuyết (Gadus morhua, Gadus ogac, Gadus macrocephalus)',
    Unit: 'kg',
  },
  {
    HsCode: '03055400',
    Description:
      'Cá trích nước lạnh (Clupea harengus, Clupea pallasii), cá cơm (cá trỏng) (Engraulis spp.), cá trích dầu (Sardina pilchardus, Sardinops spp.), cá trích xương (Sardinella spp.), cá trích kê hoặc cá trích cơm (Sprattus sprattus), cá nục hoa (Scomber scombrus, Scomber australasicus, Scomber japonicus), cá bạc má (Rastrelliger spp.), cá thu (Scomberomorus spp.), cá nục gai và cá sòng (Trachurus spp.), cá khế jacks, cá khế crevalles (Caranx spp.), cá giò (Rachycentron canadum), cá chim trắng (Pampus spp.), cá thu đao (Cololabis saira), cá nục (Decapterus spp.), cá trứng (Mallotus villosus), cá kiếm (Xiphias gladius), cá ngừ chấm (Euthynnus affinis), cá ngừ ba chấm (Sarda spp.), cá cờ gòn, cá cờ lá (sailfishes), cá cờ spearfish (Istiophoridae)',
    Unit: 'kg',
  },
  {
    HsCode: '030559',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá biển:',
    Unit: '',
  },
  {
    HsCode: '03055921',
    Description:
      'Cá cơm (cá trỏng) (Stolephorus spp., Coilia spp., Setipinna spp., Lycothrissa spp., Thryssa spp. và Encrasicholina spp.) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03055929',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '03055990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Cá, muối nhưng không làm khô hoặc không hun khói và cá ngâm nước muối, trừ phụ phẩm ăn được sau giết mổ:',
    Unit: '',
  },
  {
    HsCode: '03056100',
    Description: 'Cá trích nước lạnh (Clupea harengus, Clupea pallasii)',
    Unit: 'kg',
  },
  {
    HsCode: '03056200',
    Description: 'Cá tuyết (Gadus morhua, Gadus ogac, Gadus macrocephalus)',
    Unit: 'kg',
  },
  {
    HsCode: '03056300',
    Description: 'Cá cơm (cá trỏng) (Engraulis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03056400',
    Description:
      'Cá rô phi (Oreochromis spp.), cá da trơn (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), cá chép (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), cá chình (Anguilla spp.), cá rô sông Nile (Lates niloticus) và cá quả (cá chuối hoặc cá lóc) (Channa spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '030569',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03056910',
    Description: 'Cá biển',
    Unit: 'kg',
  },
  {
    HsCode: '03056990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Vây cá, đầu, đuôi, bong bóng và phụ phẩm khác ăn được sau giết mổ của cá:',
    Unit: '',
  },
  {
    HsCode: '030571',
    Description: 'Vây cá mập:',
    Unit: '',
  },
  {
    HsCode: '03057110',
    Description: 'Khô hoặc hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03057190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030572',
    Description: 'Đầu cá, đuôi và bong bóng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Bong bóng cá:',
    Unit: '',
  },
  {
    HsCode: '03057211',
    Description: 'Của cá tuyết (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03057219',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03057291',
    Description: 'Của cá tuyết',
    Unit: 'kg',
  },
  {
    HsCode: '03057299',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030579',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03057910',
    Description: 'Của cá tuyết',
    Unit: 'kg',
  },
  {
    HsCode: '03057990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0306',
    Description:
      'Động vật giáp xác, đã hoặc chưa bóc mai, vỏ, sống, tươi, ướp lạnh, đông lạnh, khô, muối hoặc ngâm nước muối; động vật giáp xác hun khói, đã hoặc chưa bóc mai, vỏ, đã hoặc chưa làm chín trước hoặc trong quá trình hun khói; động vật giáp xác chưa bóc mai, vỏ, đã hấp chín hoặc luộc chín trong nước, đã hoặc chưa ướp lạnh, đông lạnh, khô, muối, hoặc ngâm nước muối',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '030611',
    Description: 'Tôm hùm đá và các loại tôm biển khác (Palinurus spp., Panulirus spp., Jasus spp.):',
    Unit: '',
  },
  {
    HsCode: '03061110',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03061190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030612',
    Description: 'Tôm hùm (Homarus spp.):',
    Unit: '',
  },
  {
    HsCode: '03061210',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03061290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030614',
    Description: 'Cua, ghẹ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hun khói:',
    Unit: '',
  },
  {
    HsCode: '03061411',
    Description: 'Cua, ghẹ vỏ mềm',
    Unit: 'kg',
  },
  {
    HsCode: '03061419',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03061491',
    Description: 'Ghẹ (thuộc họ Portunidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03061492',
    Description: 'Cua hoàng đế/cua vua Alaska (thuộc họ Lithodidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03061493',
    Description: 'Cua tuyết (thuộc họ Origoniidae)',
    Unit: 'kg',
  },
  {
    HsCode: '03061499',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '03061500',
    Description: 'Tôm hùm Na Uy (Nephrops norvegicus)',
    Unit: 'kg',
  },
  {
    HsCode: '03061600',
    Description: 'Tôm shrimps và tôm prawn nước lạnh (Pandalus spp., Crangon crangon)',
    Unit: 'kg',
  },
  {
    HsCode: '030617',
    Description: 'Tôm shrimps và tôm prawn khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tôm sú (Penaeus monodon):',
    Unit: '',
  },
  {
    HsCode: '03061711',
    Description: 'Đã bỏ đầu',
    Unit: 'kg',
  },
  {
    HsCode: '03061719',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Tôm thẻ chân trắng (Litopenaeus vannamei):',
    Unit: '',
  },
  {
    HsCode: '03061721',
    Description: 'Đã bỏ đầu, còn đuôi',
    Unit: 'kg',
  },
  {
    HsCode: '03061722',
    Description: 'Đã bỏ đầu, bỏ đuôi',
    Unit: 'kg',
  },
  {
    HsCode: '03061729',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '03061730',
    Description: 'Tôm càng xanh (Macrobrachium rosenbergii)',
    Unit: 'kg',
  },
  {
    HsCode: '03061790',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '03061900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '030631',
    Description: 'Tôm hùm đá và các loại tôm biển khác (Palinurus spp., Panulirus spp., Jasus spp.):',
    Unit: '',
  },
  {
    HsCode: '03063110',
    Description: 'Để nhân giống',
    Unit: 'kg',
  },
  {
    HsCode: '03063120',
    Description: 'Loại khác, sống',
    Unit: 'kg',
  },
  {
    HsCode: '03063130',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030632',
    Description: 'Tôm hùm (Homarus spp.):',
    Unit: '',
  },
  {
    HsCode: '03063210',
    Description: 'Để nhân giống',
    Unit: 'kg',
  },
  {
    HsCode: '03063220',
    Description: 'Loại khác, sống',
    Unit: 'kg',
  },
  {
    HsCode: '03063230',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030633',
    Description: 'Cua, ghẹ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Ghẹ xanh/ghẹ xanh Đại Tây Dương (Callinectes spp.) và cua tuyết (thuộc họ Oregoniidae):',
    Unit: '',
  },
  {
    HsCode: '03063311',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03063312',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03063391',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03063392',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03063400',
    Description: 'Tôm hùm Na Uy (Nephrops norvegicus)',
    Unit: 'kg',
  },
  {
    HsCode: '030635',
    Description: 'Tôm shrimps và tôm prawn nước lạnh (Pandalus spp., Crangon crangon):',
    Unit: '',
  },
  {
    HsCode: '03063510',
    Description: 'Để nhân giống (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03063520',
    Description: 'Loại khác, sống',
    Unit: 'kg',
  },
  {
    HsCode: '03063530',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030636',
    Description: 'Tôm shrimps và tôm prawn khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Để nhân giống:',
    Unit: '',
  },
  {
    HsCode: '03063611',
    Description: 'Tôm sú (Penaeus monodon) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03063612',
    Description: 'Tôm thẻ chân trắng (Litopenaeus vannamei) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03063613',
    Description: 'Tôm càng xanh (Macrobrachium rosenbergii) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '03063619',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, sống:',
    Unit: '',
  },
  {
    HsCode: '03063621',
    Description: 'Tôm sú (Penaeus monodon)',
    Unit: 'kg',
  },
  {
    HsCode: '03063622',
    Description: 'Tôm thẻ chân trắng (Litopenaeus vannamei)',
    Unit: 'kg',
  },
  {
    HsCode: '03063623',
    Description: 'Tôm càng xanh (Macrobrachium rosenbergii)',
    Unit: 'kg',
  },
  {
    HsCode: '03063629',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03063631',
    Description: 'Tôm sú (Penaeus monodon)',
    Unit: 'kg',
  },
  {
    HsCode: '03063632',
    Description: 'Tôm thẻ chân trắng (Litopenaeus vannamei)',
    Unit: 'kg',
  },
  {
    HsCode: '03063633',
    Description: 'Tôm càng xanh (Macrobrachium rosenbergii)',
    Unit: 'kg',
  },
  {
    HsCode: '03063639',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030639',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03063910',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03063920',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '030691',
    Description: 'Tôm hùm đá và các loại tôm biển khác (Palinurus spp., Panulirus spp., Jasus spp.):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '03069121',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069129',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03069131',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069139',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030692',
    Description: 'Tôm hùm (Homarus spp.):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '03069221',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069229',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03069231',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069239',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030693',
    Description: 'Cua, ghẹ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '03069321',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069329',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03069331',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069339',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030694',
    Description: 'Tôm hùm Na Uy (Nephrops norvegicus):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '03069421',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069429',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03069431',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069439',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030695',
    Description: 'Tôm shrimps và tôm prawn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '03069521',
    Description: 'Còn vỏ, đã hấp chín hoặc luộc chín trong nước',
    Unit: 'kg',
  },
  {
    HsCode: '03069529',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '03069530',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030699',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '03069921',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069929',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03069931',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '03069939',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0307',
    Description:
      'Động vật thân mềm, đã hoặc chưa bóc mai, vỏ, sống, tươi, ướp lạnh, đông lạnh, khô, muối hoặc ngâm nước muối; động vật thân mềm hun khói, đã hoặc chưa bóc mai, vỏ, đã hoặc chưa làm chín trước hoặc trong quá trình hun khói',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hàu:',
    Unit: '',
  },
  {
    HsCode: '030711',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03071110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03071120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03071200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030719',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03071920',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03071930',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Điệp và các loài động vật thân mềm khác thuộc họ Pectinidae: ',
    Unit: '',
  },
  {
    HsCode: '030721',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03072110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03072120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03072200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030729',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03072930',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03072940',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Vẹm (Mytilus spp., Perna spp.):',
    Unit: '',
  },
  {
    HsCode: '030731',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03073110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03073120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03073200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030739',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03073930',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03073940',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Mực nang và mực ống:',
    Unit: '',
  },
  {
    HsCode: '030742',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sống:',
    Unit: '',
  },
  {
    HsCode: '03074211',
    Description:
      'Mực nang (Sepia officinalis, Rossia macrosoma, Sepiola spp.) và mực ống (Ommastrephes spp., Loligo spp., Nototodarus spp., Sepioteuthis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03074219',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03074221',
    Description:
      'Mực nang (Sepia officinalis, Rossia macrosoma, Sepiola spp.) và mực ống (Ommastrephes spp., Loligo spp., Nototodarus spp., Sepioteuthis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03074229',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030743',
    Description: 'Đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '03074310',
    Description:
      'Mực nang (Sepia officinalis, Rossia macrosoma, Sepiola spp.) và mực ống (Ommastrephes spp., Loligo spp., Nototodarus spp., Sepioteuthis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03074390',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '030749',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Khô, muối hoặc ngâm nước muối:',
    Unit: '',
  },
  {
    HsCode: '03074921',
    Description:
      'Mực nang (Sepia officinalis, Rossia macrosoma, Sepiola spp.) và mực ống (Ommastrephes spp., Loligo spp., Nototodarus spp., Sepioteuthis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03074929',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hun khói:',
    Unit: '',
  },
  {
    HsCode: '03074931',
    Description:
      'Mực nang (Sepia officinalis, Rossia macrosoma, Sepiola spp.) và mực ống (Ommastrephes spp., Loligo spp., Nototodarus spp., Sepioteuthis spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '03074939',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Bạch tuộc (Octopus spp.):',
    Unit: '',
  },
  {
    HsCode: '030751',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03075110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03075120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03075200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030759',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03075920',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03075930',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '030760',
    Description: 'Ốc, trừ ốc biển:',
    Unit: '',
  },
  {
    HsCode: '03076010',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03076020',
    Description: 'Tươi, ướp lạnh hoặc đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03076040',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03076050',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Nghêu (ngao), sò (thuộc các họ Arcidae, Arcticidae, Cardiidae, Donacidae, Hiatellidae, Mactridae, Mesodesmatidae, Myidae, Semelidae, Solecurtidae, Solenidae, Tridacnidae và Veneridae):',
    Unit: '',
  },
  {
    HsCode: '030771',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03077110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03077120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03077200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030779',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03077930',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03077940',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Bào ngư (Haliotis spp.) và ốc nhảy (Strombus spp.):',
    Unit: '',
  },
  {
    HsCode: '030781',
    Description: 'Bào ngư (Haliotis spp.) sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03078110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03078120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030782',
    Description: 'Ốc nhảy (Strombus spp.) sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03078210',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03078220',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03078300',
    Description: 'Bào ngư (Haliotis spp.) đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03078400',
    Description: 'Ốc nhảy (Strombus spp.) đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030787',
    Description: 'Bào ngư (Haliotis spp.) ở dạng khác:',
    Unit: '',
  },
  {
    HsCode: '03078710',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03078720',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '030788',
    Description: 'Ốc nhảy (Strombus spp.) ở dạng khác:',
    Unit: '',
  },
  {
    HsCode: '03078810',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03078820',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '030791',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03079110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03079120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03079200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030799',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03079930',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03079940',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '0308',
    Description:
      'Động vật thủy sinh không xương sống trừ động vật giáp xác và động vật thân mềm, sống, tươi, ướp lạnh, đông lạnh, khô, muối hoặc ngâm nước muối; động vật thủy sinh không xương sống hun khói trừ động vật giáp xác và động vật thân mềm, đã hoặc chưa làm chín trước hoặc trong quá trình hun khói',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hải sâm (Stichopus japonicus, Holothurioidea):',
    Unit: '',
  },
  {
    HsCode: '030811',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03081110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03081120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03081200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030819',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03081920',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03081930',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Cầu gai (Strongylocentrotus spp., Paracentrotus lividus, Loxechinus albus, Echinus esculentus):',
    Unit: '',
  },
  {
    HsCode: '030821',
    Description: 'Sống, tươi hoặc ướp lạnh:',
    Unit: '',
  },
  {
    HsCode: '03082110',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03082120',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03082200',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '030829',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03082920',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03082930',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '030830',
    Description: 'Sứa (Rhopilema spp.):',
    Unit: '',
  },
  {
    HsCode: '03083010',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03083020',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03083030',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03083040',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03083050',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '030890',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '03089010',
    Description: 'Sống',
    Unit: 'kg',
  },
  {
    HsCode: '03089020',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03089030',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03089040',
    Description: 'Khô, muối hoặc ngâm nước muối',
    Unit: 'kg',
  },
  {
    HsCode: '03089050',
    Description: 'Hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '0309',
    Description:
      'Bột mịn, bột thô và viên của cá, động vật giáp xác, động vật thân mềm và động vật thủy sinh không xương sống khác, thích hợp dùng làm thức ăn cho người',
    Unit: '',
  },
  {
    HsCode: '03091000',
    Description: 'Của cá',
    Unit: 'kg',
  },
  {
    HsCode: '030990',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Của động vật giáp xác:',
    Unit: '',
  },
  {
    HsCode: '03099011',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03099012',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03099019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Của động vật thân mềm:',
    Unit: '',
  },
  {
    HsCode: '03099021',
    Description: 'Tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03099022',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '03099029',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '03099090',
    Description: 'Của động vật thủy sinh không xương sống khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 4',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Sản phẩm bơ sữa; trứng chim và trứng gia cầm; mật ong tự nhiên; sản phẩm ăn được gốc động vật, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Khái niệm ""sữa"" được hiểu là sữa còn nguyên kem hoặc sữa đã tách kem một phần hoặc toàn bộ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của nhóm 04.03, sữa chua có thể được cô đặc hoặc được tạo hương và có thể chứa thêm đường hoặc chất làm ngọt khác, trái cây, quả hạch, ca cao, sô cô la, gia vị, cà phê hoặc chiết xuất cà phê, thực vật, các bộ phận của thực vật, ngũ cốc hoặc các loại bánh, với điều kiện chất được thêm vào không được sử dụng cho mục đích thay thế, toàn bộ hoặc một phần, bất kỳ thành phần nào của sữa, và sản phẩm vẫn giữ được đặc tính cơ bản của sữa chua.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Theo mục đích của nhóm 04.05:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Khái niệm ""bơ"" được hiểu là bơ tự nhiên, bơ whey hoặc bơ phối chế lại (tươi, muối hoặc bơ trở mùi, kể cả bơ đã đóng hộp) được tách từ sữa, với hàm lượng chất béo sữa từ 80% trở lên nhưng không quá 95% tính theo khối lượng, có hàm lượng chất khô không có chất béo tối đa là 2% và hàm lượng nước tối đa là 16% tính theo khối lượng. Bơ không chứa chất nhũ hoá, nhưng có thể chứa natri clorua, chất màu thực phẩm, muối làm trung hòa và vi khuẩn vô hại nuôi cấy để tạo ra axit lactic.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Khái niệm ""chất phết từ bơ sữa"" (dairy spreads) nghĩa là chất ở dạng nhũ tương nước trong dầu có thể phết lên bánh, chứa chất béo sữa như là chất béo duy nhất trong sản phẩm, với hàm lượng chất béo sữa từ 39% trở lên nhưng dưới 80% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Các sản phẩm thu được từ quá trình cô đặc whey có pha thêm sữa hoặc chất béo của sữa được phân loại như pho mát trong nhóm 04.06 nếu có đủ ba tiêu chuẩn sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) hàm lượng chất béo của sữa, chiếm từ 5% trở lên, tính theo khối lượng ở thể khô;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) hàm lượng chất khô, tối thiểu là 70% nhưng không quá 85%, tính theo khối lượng; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) sản phẩm được đóng khuôn hoặc có thể được đóng khuôn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '5. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Côn trùng không còn sống, không thích hợp sử dụng cho người (nhóm 05.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các sản phẩm thu được từ whey, có hàm lượng lactoza khan chiếm trên 95%, tính theo khối lượng ở thể khô (nhóm 17.02);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các sản phẩm thu được từ sữa bằng cách thay thế một hoặc nhiều thành phần tự nhiên của sữa (ví dụ, chất béo butyric) bởi chất khác (ví dụ, chất béo oleic) (nhóm 19.01 hoặc 21.06); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Các albumin (kể cả dịch cô đặc từ hai hoặc nhiều whey protein, có hàm lượng whey protein chiếm trên 80% tính theo khối lượng ở thể khô) (nhóm 35.02) hoặc globulin (nhóm 35.04).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của nhóm 04.10, thuật ngữ “côn trùng” có nghĩa là côn trùng không còn sống ăn được, toàn bộ hoặc từng phần, tươi, ướp lạnh, đông lạnh, làm khô, hun khói, muối hoặc ngâm nước muối, cũng như bột mịn và bột thô của côn trùng, thích hợp dùng làm thức ăn cho người. Tuy nhiên, nhóm này không bao gồm các loại côn trùng không còn sống ăn được, đã chế biến hoặc bảo quản bằng cách khác (thường thuộc Phần IV).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 0404.10, khái niệm ""whey đã được cải biến"" là các sản phẩm bao gồm các thành phần whey, nghĩa là whey đã tách toàn bộ hoặc một phần lactoza, protein hoặc chất khoáng, whey đã thêm các thành phần whey tự nhiên, và các sản phẩm thu được bằng cách pha trộn các thành phần whey tự nhiên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của phân nhóm 0405.10, khái niệm ""bơ"" không bao gồm bơ khử nước hoặc ghee (phân nhóm 0405.90).',
    Unit: '',
  },
  {
    HsCode: '0401',
    Description: 'Sữa và kem, chưa cô đặc và chưa pha thêm đường hoặc chất tạo ngọt khác',
    Unit: '',
  },
  {
    HsCode: '040110',
    Description: 'Có hàm lượng chất béo không quá 1% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '04011010',
    Description: 'Dạng lỏng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '04011090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '040120',
    Description: 'Có hàm lượng chất béo trên 1% nhưng không quá 6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '04012010',
    Description: 'Dạng lỏng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '04012090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '040140',
    Description: 'Có hàm lượng chất béo trên 6% nhưng không quá 10% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '04014010',
    Description: 'Sữa dạng lỏng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '04014020',
    Description: 'Sữa dạng đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '04014090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '040150',
    Description: 'Có hàm lượng chất béo trên 10% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '04015010',
    Description: 'Dạng lỏng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '04015090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0402',
    Description: 'Sữa và kem, đã cô đặc hoặc đã pha thêm đường hoặc chất tạo ngọt khác',
    Unit: '',
  },
  {
    HsCode: '040210',
    Description: 'Dạng bột, hạt hoặc các thể rắn khác, có hàm lượng chất béo không quá 1,5% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chưa pha thêm đường hoặc chất tạo ngọt khác:',
    Unit: '',
  },
  {
    HsCode: '04021041',
    Description: 'Đóng gói với khối lượng tịnh từ 20 kg trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '04021042',
    Description: 'Đóng gói với khối lượng tịnh từ 2 kg trở xuống',
    Unit: 'kg',
  },
  {
    HsCode: '04021049',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04021091',
    Description: 'Đóng gói với khối lượng tịnh từ 20 kg trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '04021092',
    Description: 'Đóng gói với khối lượng tịnh từ 2 kg trở xuống',
    Unit: 'kg',
  },
  {
    HsCode: '04021099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Dạng bột, hạt hoặc các thể rắn khác, có hàm lượng chất béo trên 1,5% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '040221',
    Description: 'Chưa pha thêm đường hoặc chất tạo ngọt khác:',
    Unit: '',
  },
  {
    HsCode: '04022120',
    Description: 'Đóng gói với khối lượng tịnh từ 20 kg trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '04022130',
    Description: 'Đóng gói với khối lượng tịnh từ 2 kg trở xuống',
    Unit: 'kg',
  },
  {
    HsCode: '04022190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '040229',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04022920',
    Description: 'Đóng gói với khối lượng tịnh từ 20 kg trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '04022930',
    Description: 'Đóng gói với khối lượng tịnh từ 2 kg trở xuống',
    Unit: 'kg',
  },
  {
    HsCode: '04022990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04029100',
    Description: 'Chưa pha thêm đường hoặc chất tạo ngọt khác',
    Unit: 'kg',
  },
  {
    HsCode: '04029900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0403',
    Description:
      'Sữa chua; buttermilk, sữa đông và kem đông, kephir và sữa, kem khác đã lên men hoặc axit hóa, đã hoặc chưa cô đặc hoặc pha thêm đường hoặc chất tạo ngọt khác hoặc hương liệu hoặc bổ sung thêm hoa quả, quả hạch (nuts) hoặc ca cao',
    Unit: '',
  },
  {
    HsCode: '040320',
    Description: 'Sữa chua:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng lỏng, đã hoặc chưa cô đặc:',
    Unit: '',
  },
  {
    HsCode: '04032011',
    Description: 'Đã thêm hương liệu hoặc thêm hoa quả (kể cả thịt quả và mứt), quả hạch (nuts) hoặc ca cao',
    Unit: 'kg/lít',
  },
  {
    HsCode: '04032019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04032091',
    Description: 'Đã thêm hương liệu hoặc thêm hoa quả (kể cả thịt quả và mứt), quả hạch (nuts) hoặc ca cao',
    Unit: 'kg/lít',
  },
  {
    HsCode: '04032099',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '040390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04039010',
    Description: 'Buttermilk',
    Unit: 'kg/lít',
  },
  {
    HsCode: '04039090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '0404',
    Description:
      'Whey, đã hoặc chưa cô đặc hoặc pha thêm đường hoặc chất tạo ngọt khác; các sản phẩm có chứa thành phần tự nhiên của sữa, đã hoặc chưa pha thêm đường hoặc chất tạo ngọt khác, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '040410',
    Description: 'Whey và whey đã cải biến, đã hoặc chưa cô đặc hoặc pha thêm đường hoặc chất tạo ngọt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng bột:',
    Unit: '',
  },
  {
    HsCode: '04041011',
    Description: 'Whey, thích hợp sử dụng cho người (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '04041019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04041091',
    Description: 'Whey, thích hợp sử dụng cho người (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '04041099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '04049000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0405',
    Description: 'Bơ và các chất béo và các loại dầu khác tách từ sữa; chất phết từ bơ sữa (dairy spreads)',
    Unit: '',
  },
  {
    HsCode: '04051000',
    Description: 'Bơ',
    Unit: 'kg',
  },
  {
    HsCode: '04052000',
    Description: 'Chất phết từ bơ sữa ',
    Unit: 'kg',
  },
  {
    HsCode: '040590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04059010',
    Description: 'Chất béo khan của bơ',
    Unit: 'kg',
  },
  {
    HsCode: '04059020',
    Description: 'Dầu bơ (butteroil)',
    Unit: 'kg',
  },
  {
    HsCode: '04059030',
    Description: 'Ghee',
    Unit: 'kg',
  },
  {
    HsCode: '04059090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0406',
    Description: 'Pho mát và curd',
    Unit: '',
  },
  {
    HsCode: '040610',
    Description: 'Pho mát tươi (chưa ủ chín hoặc chưa xử lý), kể cả pho mát whey, và curd:',
    Unit: '',
  },
  {
    HsCode: '04061010',
    Description: 'Pho mát tươi (chưa ủ chín hoặc chưa xử lý), kể cả pho mát whey',
    Unit: 'kg',
  },
  {
    HsCode: '04061020',
    Description: 'Curd',
    Unit: 'kg',
  },
  {
    HsCode: '040620',
    Description: 'Pho mát đã xát nhỏ hoặc đã làm thành bột, của tất cả các loại:',
    Unit: '',
  },
  {
    HsCode: '04062010',
    Description: 'Đóng gói với trọng lượng cả bì trên 20 kg',
    Unit: 'kg',
  },
  {
    HsCode: '04062090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '04063000',
    Description: 'Pho mát đã chế biến, chưa xát nhỏ hoặc chưa làm thành bột',
    Unit: 'kg',
  },
  {
    HsCode: '04064000',
    Description: 'Pho mát vân xanh và pho mát khác có vân được sản xuất từ men Penicillium roqueforti',
    Unit: 'kg',
  },
  {
    HsCode: '04069000',
    Description: 'Pho mát loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0407',
    Description: 'Trứng chim và trứng gia cầm, nguyên vỏ, sống, đã bảo quản hoặc đã làm chín',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Trứng đã thụ tinh để ấp:',
    Unit: '',
  },
  {
    HsCode: '040711',
    Description: 'Của gà thuộc loài Gallus domesticus:',
    Unit: '',
  },
  {
    HsCode: '04071110',
    Description: 'Để nhân giống',
    Unit: 'quả/kg',
  },
  {
    HsCode: '04071190',
    Description: 'Loại khác',
    Unit: 'quả/kg',
  },
  {
    HsCode: '040719',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Của vịt, ngan:',
    Unit: '',
  },
  {
    HsCode: '04071911',
    Description: 'Để nhân giống',
    Unit: 'quả/kg',
  },
  {
    HsCode: '04071919',
    Description: 'Loại khác',
    Unit: 'quả/kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04071991',
    Description: 'Để nhân giống',
    Unit: 'quả/kg',
  },
  {
    HsCode: '04071999',
    Description: 'Loại khác',
    Unit: 'quả/kg',
  },
  {
    HsCode: '',
    Description: 'Trứng sống khác:',
    Unit: '',
  },
  {
    HsCode: '04072100',
    Description: 'Của gà thuộc loài Gallus domesticus ',
    Unit: 'quả/kg',
  },
  {
    HsCode: '040729',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04072910',
    Description: 'Của vịt, ngan',
    Unit: 'quả/kg',
  },
  {
    HsCode: '04072990',
    Description: 'Loại khác',
    Unit: 'quả/kg',
  },
  {
    HsCode: '040790',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04079010',
    Description: 'Của gà thuộc loài Gallus domesticus',
    Unit: 'quả/kg',
  },
  {
    HsCode: '04079020',
    Description: 'Của vịt, ngan',
    Unit: 'quả/kg',
  },
  {
    HsCode: '04079090',
    Description: 'Loại khác',
    Unit: 'quả/kg',
  },
  {
    HsCode: '0408',
    Description:
      'Trứng chim và trứng gia cầm, đã bóc vỏ, và lòng đỏ trứng, sống, làm khô, hấp chín hoặc luộc chín trong nước, đóng bánh, đông lạnh hoặc bảo quản cách khác, đã hoặc chưa thêm đường hoặc chất tạo ngọt khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lòng đỏ trứng:',
    Unit: '',
  },
  {
    HsCode: '04081100',
    Description: 'Đã làm khô',
    Unit: 'kg',
  },
  {
    HsCode: '04081900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04089100',
    Description: 'Đã làm khô',
    Unit: 'kg',
  },
  {
    HsCode: '04089900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '04090000',
    Description: 'Mật ong tự nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '0410',
    Description: 'Côn trùng và sản phẩm ăn được gốc động vật, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '04101000',
    Description: 'Côn trùng',
    Unit: 'kg',
  },
  {
    HsCode: '041090',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '04109010',
    Description: 'Tổ yến',
    Unit: 'kg',
  },
  {
    HsCode: '04109020',
    Description: 'Trứng rùa',
    Unit: 'kg',
  },
  {
    HsCode: '04109090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 5',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sản phẩm gốc động vật, chưa được chi tiết hoặc ghi                             ở các nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các sản phẩm ăn được (trừ ruột, bong bóng và dạ dày của động vật, nguyên dạng và các mảnh của chúng, và tiết động vật, ở dạng lỏng hoặc khô); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Da hoặc da sống (kể cả da lông), trừ các hàng hóa thuộc nhóm 05.05 và các đầu mẩu và phế liệu tương tự từ da hoặc da sống thô thuộc nhóm 05.11 (Chương 41 hoặc 43);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Vật liệu dệt gốc động vật, trừ lông đuôi hoặc bờm ngựa và phế liệu lông đuôi hoặc bờm ngựa (Phần XI); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Túm hoặc búi đã chuẩn bị sẵn để làm chổi hoặc bàn chải (nhóm 96.03).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của nhóm 05.01, việc phân loại tóc theo độ dài (với điều kiện là chưa sắp xếp theo đầu và đuôi với nhau) sẽ không được coi là gia công.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Trong toàn bộ Danh mục này, ngà voi, sừng hà mã, sừng con moóc (hải mã), sừng kỳ lân biển và răng nanh lợn lòi đực, sừng tê giác và răng của các loài động vật đều được coi là ""ngà"".',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Trong toàn bộ Danh mục này, khái niệm ""lông đuôi hoặc bờm ngựa"" để chỉ lông đuôi hoặc bờm của ngựa hoặc động vật họ trâu bò. Không kể những đề cập khác, nhóm 05.11 còn bao gồm lông đuôi hoặc bờm ngựa và phế liệu lông đuôi hoặc bờm ngựa, đã hoặc chưa xếp thành lớp mà lớp này có hoặc không có vật liệu phụ trợ.',
    Unit: '',
  },
  {
    HsCode: '05010000',
    Description: 'Tóc người, chưa xử lý, đã hoặc chưa rửa sạch hoặc gột tẩy; phế liệu tóc người',
    Unit: 'kg',
  },
  {
    HsCode: '0502',
    Description:
      'Lông và lông cứng của lợn hoặc lợn lòi; lông dùng làm chổi và bàn chải khác; phế liệu từ các loại lông trên',
    Unit: '',
  },
  {
    HsCode: '05021000',
    Description: 'Lông và lông cứng của lợn hoặc lợn lòi và phế liệu của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '05029000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '05040000',
    Description:
      'Ruột, bong bóng và dạ dày động vật (trừ cá), nguyên dạng và các mảnh của chúng, tươi, ướp lạnh, đông lạnh, muối, ngâm nước muối, làm khô hoặc hun khói',
    Unit: 'kg',
  },
  {
    HsCode: '0505',
    Description:
      'Da và các bộ phận khác của loài chim và gia cầm, có lông vũ hoặc lông tơ, lông vũ và các phần của lông vũ (đã hoặc chưa cắt tỉa) và lông tơ, mới chỉ được làm sạch, khử trùng hoặc xử lý để bảo quản; bột và phế liệu từ lông vũ hoặc các phần của lông vũ',
    Unit: '',
  },
  {
    HsCode: '050510',
    Description: 'Lông vũ dùng để nhồi; lông tơ:',
    Unit: '',
  },
  {
    HsCode: '05051010',
    Description: 'Lông vũ của vịt, ngan',
    Unit: 'kg',
  },
  {
    HsCode: '05051090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '050590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '05059010',
    Description: 'Lông vũ của vịt, ngan',
    Unit: 'kg',
  },
  {
    HsCode: '05059090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0506',
    Description:
      'Xương và lõi sừng, chưa xử lý, đã khử mỡ, sơ chế (nhưng chưa cắt thành hình), đã xử lý bằng axit hoặc khử gelatin; bột và phế liệu từ các sản phẩm trên',
    Unit: '',
  },
  {
    HsCode: '05061000',
    Description: 'Ossein và xương đã xử lý bằng axit',
    Unit: 'kg',
  },
  {
    HsCode: '05069000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0507',
    Description:
      'Ngà, mai động vật họ rùa, lược cá voi (phiến sừng hàm trên) và hàm răng lược cá voi, sừng, gạc, móng guốc, móng, vuốt và mỏ, chưa xử lý hoặc đã sơ chế nhưng chưa cắt thành hình; bột và phế liệu từ các sản phẩm trên',
    Unit: '',
  },
  {
    HsCode: '05071000',
    Description: 'Ngà; bột và phế liệu từ ngà',
    Unit: 'kg',
  },
  {
    HsCode: '050790',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '05079020',
    Description: 'Mai động vật họ rùa',
    Unit: 'kg',
  },
  {
    HsCode: '05079090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0508',
    Description:
      'San hô và các chất liệu tương tự, chưa xử lý hoặc đã sơ chế nhưng chưa gia công thêm; mai, vỏ động vật thân mềm, động vật giáp xác hoặc động vật da gai và mai mực, chưa xử lý hoặc đã sơ chế nhưng chưa cắt thành hình, bột và phế liệu từ các sản phẩm trên',
    Unit: '',
  },
  {
    HsCode: '05080020',
    Description: 'Mai, vỏ động vật thân mềm, động vật giáp xác hoặc động vật da gai',
    Unit: 'kg',
  },
  {
    HsCode: '05080090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '05100000',
    Description:
      'Long diên hương, hương hải ly, chất xạ hương (từ cầy hương và hươu xạ); côn trùng cánh cứng cantharides; mật, đã hoặc chưa được làm khô; các tuyến và các sản phẩm động vật khác dùng để điều chế dược phẩm, tươi, ướp lạnh, đông lạnh hoặc bảo quản tạm thời dưới hình thức khác',
    Unit: 'kg',
  },
  {
    HsCode: '0511',
    Description:
      'Các sản phẩm động vật khác chưa được chi tiết hoặc ghi ở nơi khác; động vật chết thuộc Chương 1 hoặc Chương 3, không thích hợp sử dụng cho người',
    Unit: '',
  },
  {
    HsCode: '05111000',
    Description: 'Tinh dịch động vật họ trâu, bò',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '051191',
    Description:
      'Sản phẩm từ cá hoặc động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác; động vật đã chết thuộc Chương 3:',
    Unit: '',
  },
  {
    HsCode: '05119110',
    Description: 'Sẹ và bọc trứng',
    Unit: 'kg',
  },
  {
    HsCode: '05119120',
    Description: 'Trứng Artemia (trứng Brine shrimp)',
    Unit: 'kg',
  },
  {
    HsCode: '05119130',
    Description: 'Da cá',
    Unit: 'kg',
  },
  {
    HsCode: '05119190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '051199',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '05119910',
    Description: 'Tinh dịch động vật nuôi',
    Unit: 'kg',
  },
  {
    HsCode: '05119920',
    Description: 'Trứng tằm',
    Unit: 'kg',
  },
  {
    HsCode: '05119930',
    Description: 'Bọt biển thiên nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '05119990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHẦN II',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'CÁC SẢN PHẨM THỰC VẬT',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong Phần này khái niệm “viên” có nghĩa là những sản phẩm được liên kết bằng cách nén trực tiếp hoặc thêm chất dính với hàm lượng không quá 3% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 6',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cây sống và các loại cây trồng khác; củ, rễ và loại tương tự; cành hoa và cành lá trang trí',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Ngoài phần 2 của tên nhóm 06.01, Chương này chỉ bao gồm cây sống và các sản phẩm (kể cả cây giống từ hạt) thường được cung cấp bởi người trồng hoa hoặc người làm vườn ươm cây để trồng hoặc trang trí; tuy nhiên Chương này không bao gồm khoai tây, hành tây, hành, hẹ, tỏi hoặc các sản phẩm khác thuộc Chương 7.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Khi xem xét một mặt hàng nào đó trong nhóm 06.03 hoặc 06.04 phải kể đến những mặt hàng như lẵng hoa, bó hoa, vòng hoa và các sản phẩm tương tự được làm toàn bộ hoặc một phần bằng các vật liệu nêu trong nhóm, không tính đến những đồ phụ trợ làm bằng vật liệu khác. Tuy nhiên, các nhóm này không bao gồm các tác phẩm ghép hoặc phù điêu trang trí tương tự thuộc nhóm 97.01.',
    Unit: '',
  },
  {
    HsCode: '0601',
    Description:
      'Củ, thân củ, rễ củ, thân ống và thân rễ, ở dạng ngủ, dạng sinh trưởng hoặc ở dạng hoa; cây và rễ rau diếp xoăn, trừ các loại rễ thuộc nhóm 12.12',
    Unit: '',
  },
  {
    HsCode: '06011000',
    Description: 'Củ, thân củ, rễ củ, thân ống và thân rễ, ở dạng ngủ',
    Unit: 'kg/củ',
  },
  {
    HsCode: '060120',
    Description: 'Củ, thân củ, rễ củ, thân ống, thân rễ, dạng sinh trưởng hoặc dạng hoa; cây và rễ rau diếp xoăn:',
    Unit: '',
  },
  {
    HsCode: '06012010',
    Description: 'Cây rau diếp xoăn',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06012020',
    Description: 'Rễ rau diếp xoăn',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06012090',
    Description: 'Loại khác',
    Unit: 'kg/cây',
  },
  {
    HsCode: '0602',
    Description: 'Cây sống khác (kể cả rễ), cành giâm và cành ghép; hệ sợi nấm',
    Unit: '',
  },
  {
    HsCode: '060210',
    Description: 'Cành giâm và cành ghép không có rễ:',
    Unit: '',
  },
  {
    HsCode: '06021010',
    Description: 'Của cây phong lan',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06021020',
    Description: 'Của cây cao su',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06021090',
    Description: 'Loại khác',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06022000',
    Description: 'Cây, cây bụi, đã hoặc không ghép cành, thuộc loại có quả hoặc quả hạch (nuts) ăn được',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06023000',
    Description: 'Cây đỗ quyên và cây azalea (cây khô họ đỗ quyên), đã hoặc không ghép cành',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06024000',
    Description: 'Cây hoa hồng, đã hoặc không ghép cành',
    Unit: 'kg/cây',
  },
  {
    HsCode: '060290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '06029010',
    Description: 'Cành giâm và cành ghép phong lan có rễ',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06029020',
    Description: 'Cây phong lan giống',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06029040',
    Description: 'Gốc cây cao su có chồi (SEN)',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06029050',
    Description: 'Cây cao su giống (SEN)',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06029060',
    Description: 'Chồi mọc từ gỗ cây cao su (SEN)',
    Unit: 'kg/cây',
  },
  {
    HsCode: '06029090',
    Description: 'Loại khác',
    Unit: 'kg/cây',
  },
  {
    HsCode: '0603',
    Description:
      'Cành hoa và nụ hoa dùng làm hoa bó hoặc để trang trí, tươi, khô, đã nhuộm, tẩy, thấm tẩm hoặc xử lý cách khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tươi:',
    Unit: '',
  },
  {
    HsCode: '06031100',
    Description: 'Hoa hồng',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06031200',
    Description: 'Hoa cẩm chướng',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06031300',
    Description: 'Phong lan',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06031400',
    Description: 'Hoa cúc',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06031500',
    Description: 'Họ hoa ly (Lilium spp.)',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06031900',
    Description: 'Loại khác',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06039000',
    Description: 'Loại khác',
    Unit: 'kg/cành',
  },
  {
    HsCode: '0604',
    Description:
      'Tán lá, cành và các phần khác của cây, không có hoa hoặc nụ hoa, các loại cỏ, rêu và địa y, phù hợp để bó hoa hoặc trang trí, tươi, khô, đã nhuộm, tẩy, thấm tẩm hoặc xử lý cách khác',
    Unit: '',
  },
  {
    HsCode: '060420',
    Description: 'Tươi:',
    Unit: '',
  },
  {
    HsCode: '06042010',
    Description: 'Rêu và địa y',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06042090',
    Description: 'Loại khác',
    Unit: 'kg/cành',
  },
  {
    HsCode: '060490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '06049010',
    Description: 'Rêu và địa y',
    Unit: 'kg/cành',
  },
  {
    HsCode: '06049090',
    Description: 'Loại khác',
    Unit: 'kg/cành',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 7',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rau và một số loại củ, thân củ và rễ ăn được',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm các sản phẩm thức ăn cho gia súc thuộc nhóm 12.14.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong các nhóm 07.09, 07.10, 07.11 và 07.12, từ “rau” bao gồm các loại ăn được: nấm, nấm cục (nấm củ), ôliu, nụ bạch hoa, bí, bí ngô, cà tím, ngô ngọt (Zea mays var. saccharata), quả ớt thuộc chi Capsicum hoặc thuộc chi Pimenta, rau thì là, rau mùi tây, rau mùi, rau ngải giấm, cải xoong và kinh giới ngọt (Majorana hortensis hoặc Origanum majorana).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Nhóm 07.12 bao gồm tất cả các loại rau khô từ các loại rau thuộc các nhóm từ 07.01 đến 07.11, trừ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) các loại rau đậu khô, đã bóc vỏ (nhóm 07.13);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) ngô ngọt ở các dạng được nêu trong các nhóm từ 11.02 đến 11.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) bột, bột thô, bột mịn, mảnh lát, hạt và viên của khoai tây (nhóm 11.05);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) bột, bột thô và bột mịn của các loại rau đậu khô thuộc nhóm 07.13 (nhóm 11.06).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Tuy nhiên, Chương này không bao gồm các loại ớt thuộc chi Capsicum hoặc chi Pimenta được làm khô hoặc xay hoặc nghiền (nhóm 09.04).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Nhóm 07.11 áp dụng cho các loại rau đã được xử lý chỉ để bảo quản tạm thời trong quá trình vận chuyển hoặc lưu trữ trước khi sử dụng (ví dụ: bảo quản bằng khí sunphurơ, ngâm nước muối, ngâm nước lưu huỳnh hoặc ngâm trong dung dịch bảo quản khác), với điều kiện chúng không phù hợp dùng ngay được khi ở dạng đó.',
    Unit: '',
  },
  {
    HsCode: '0701',
    Description: 'Khoai tây, tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '07011000',
    Description: 'Để làm giống',
    Unit: 'kg',
  },
  {
    HsCode: '070190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07019010',
    Description: 'Loại thường dùng để làm khoai tây chiên (chipping potatoes) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '07019090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '07020000',
    Description: 'Cà chua, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '0703',
    Description: 'Hành tây, hành, hẹ, tỏi, tỏi tây và các loại rau họ hành, tỏi khác, tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '070310',
    Description: 'Hành tây và hành, hẹ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hành tây:',
    Unit: '',
  },
  {
    HsCode: '07031011',
    Description: 'Củ giống',
    Unit: 'kg',
  },
  {
    HsCode: '07031019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hành, hẹ:',
    Unit: '',
  },
  {
    HsCode: '07031021',
    Description: 'Củ giống',
    Unit: 'kg',
  },
  {
    HsCode: '07031029',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '070320',
    Description: 'Tỏi:',
    Unit: '',
  },
  {
    HsCode: '07032010',
    Description: 'Củ giống',
    Unit: 'kg',
  },
  {
    HsCode: '07032090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '070390',
    Description: 'Tỏi tây và các loại rau họ hành, tỏi khác:',
    Unit: '',
  },
  {
    HsCode: '07039010',
    Description: 'Củ giống',
    Unit: 'kg',
  },
  {
    HsCode: '07039090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0704',
    Description: 'Bắp cải, súp lơ (1), su hào, cải xoăn và cây họ bắp cải ăn được tương tự, tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '070410',
    Description: 'Súp lơ (1) và súp lơ xanh:',
    Unit: '',
  },
  {
    HsCode: '07041010',
    Description: 'Súp lơ (1) (Caulifower)',
    Unit: 'kg',
  },
  {
    HsCode: '07041020',
    Description: 'Đầu súp lơ xanh (headed broccoli)',
    Unit: 'kg',
  },
  {
    HsCode: '07041090',
    Description: 'Súp lơ xanh khác',
    Unit: 'kg',
  },
  {
    HsCode: '07042000',
    Description: 'Cải Bruc-xen',
    Unit: 'kg',
  },
  {
    HsCode: '070490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07049010',
    Description: 'Bắp cải cuộn (cuộn tròn) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '07049020',
    Description: 'Cải bẹ xanh (Chinese mustard) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '07049030',
    Description: 'Bắp cải khác',
    Unit: 'kg',
  },
  {
    HsCode: '07049090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0705',
    Description: 'Rau diếp, xà lách (Lactuca sativa) và rau diếp xoăn (Cichorium spp.), tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rau diếp, xà lách:',
    Unit: '',
  },
  {
    HsCode: '07051100',
    Description: 'Xà lách cuộn (head lettuce)',
    Unit: 'kg',
  },
  {
    HsCode: '07051900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Rau diếp xoăn:',
    Unit: '',
  },
  {
    HsCode: '07052100',
    Description: 'Rau diếp xoăn rễ củ (Cichorium intybus var. foliosum)',
    Unit: 'kg',
  },
  {
    HsCode: '07052900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0706',
    Description:
      'Cà rốt, củ cải, củ dền làm salát, diếp củ (salsify), cần củ (celeriac), củ cải ri (radish) và các loại củ rễ ăn được tương tự, tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '070610',
    Description: 'Cà rốt và củ cải:',
    Unit: '',
  },
  {
    HsCode: '07061010',
    Description: 'Cà rốt',
    Unit: 'kg',
  },
  {
    HsCode: '07061020',
    Description: 'Củ cải',
    Unit: 'kg',
  },
  {
    HsCode: '07069000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '07070000',
    Description: 'Dưa chuột và dưa chuột ri, tươi hoặc ướp lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '0708',
    Description: 'Rau đậu, đã hoặc chưa bóc vỏ, tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '07081000',
    Description: 'Đậu Hà Lan (Pisum sativum)',
    Unit: 'kg',
  },
  {
    HsCode: '070820',
    Description: 'Đậu hạt (Vigna spp., Phaseolus spp.):',
    Unit: '',
  },
  {
    HsCode: '07082010',
    Description: 'Đậu Pháp (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '07082020',
    Description: 'Đậu dài',
    Unit: 'kg',
  },
  {
    HsCode: '07082090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '07089000',
    Description: 'Các loại rau đậu khác',
    Unit: 'kg',
  },
  {
    HsCode: '0709',
    Description: 'Rau khác, tươi hoặc ướp lạnh',
    Unit: '',
  },
  {
    HsCode: '07092000',
    Description: 'Măng tây',
    Unit: 'kg',
  },
  {
    HsCode: '07093000',
    Description: 'Cà tím',
    Unit: 'kg',
  },
  {
    HsCode: '07094000',
    Description: 'Cần tây trừ loại cần củ (celeriac)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Nấm và nấm cục (truffles):',
    Unit: '',
  },
  {
    HsCode: '07095100',
    Description: 'Nấm thuộc chi Agaricus',
    Unit: 'kg',
  },
  {
    HsCode: '07095200',
    Description: 'Nấm thuộc chi Boletus',
    Unit: 'kg',
  },
  {
    HsCode: '07095300',
    Description: 'Nấm thuộc chi Cantharellus',
    Unit: 'kg',
  },
  {
    HsCode: '07095400',
    Description: 'Nấm hương (Lentinus edodes)',
    Unit: 'kg',
  },
  {
    HsCode: '07095500',
    Description:
      'Nấm Tùng Nhung (Tricholoma matsutake, Tricholoma magnivelare, Tricholoma anatolicum, Tricholoma dulciolens, Tricholoma caligatum)',
    Unit: 'kg',
  },
  {
    HsCode: '07095600',
    Description: 'Nấm cục (Tuber spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '070959',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07095920',
    Description: 'Nấm cục trừ loại Tuber spp.',
    Unit: 'kg',
  },
  {
    HsCode: '07095990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '070960',
    Description: 'Quả ớt thuộc chi Capsicum hoặc chi Pimenta:',
    Unit: '',
  },
  {
    HsCode: '07096010',
    Description: 'Quả ớt (chillies) (quả thuộc chi Capsicum)',
    Unit: 'kg',
  },
  {
    HsCode: '07096090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '07097000',
    Description: 'Rau chân vịt, rau chân vịt New Zealand, rau chân vịt lê (rau chân vịt trồng trong vườn)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07099100',
    Description: 'Hoa a-ti-sô',
    Unit: 'kg',
  },
  {
    HsCode: '07099200',
    Description: 'Ô liu',
    Unit: 'kg',
  },
  {
    HsCode: '07099300',
    Description: 'Quả bí ngô, quả bí và quả bầu (Cucurbita spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '070999',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07099910',
    Description: 'Ngô ngọt',
    Unit: 'kg',
  },
  {
    HsCode: '07099920',
    Description: 'Đậu bắp (Okra)',
    Unit: 'kg',
  },
  {
    HsCode: '07099990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0710',
    Description: 'Rau các loại (đã hoặc chưa hấp chín hoặc luộc chín trong nước), đông lạnh',
    Unit: '',
  },
  {
    HsCode: '07101000',
    Description: 'Khoai tây',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Rau đậu các loại, đã hoặc chưa bóc vỏ:',
    Unit: '',
  },
  {
    HsCode: '07102100',
    Description: 'Đậu Hà Lan (Pisum sativum)',
    Unit: 'kg',
  },
  {
    HsCode: '07102200',
    Description: 'Đậu hạt (Vigna spp., Phaseolus spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '07102900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '07103000',
    Description: 'Rau chân vịt, rau chân vịt New Zealand, rau chân vịt lê (rau chân vịt trồng trong vườn)',
    Unit: 'kg',
  },
  {
    HsCode: '07104000',
    Description: 'Ngô ngọt',
    Unit: 'kg',
  },
  {
    HsCode: '07108000',
    Description: 'Rau khác',
    Unit: 'kg',
  },
  {
    HsCode: '07109000',
    Description: 'Hỗn hợp các loại rau',
    Unit: 'kg',
  },
  {
    HsCode: '0711',
    Description: 'Rau các loại đã bảo quản tạm thời, nhưng không phù hợp dùng ngay được',
    Unit: '',
  },
  {
    HsCode: '071120',
    Description: 'Ôliu: ',
    Unit: '',
  },
  {
    HsCode: '07112010',
    Description: 'Đã bảo quản bằng khí sunphurơ',
    Unit: 'kg',
  },
  {
    HsCode: '07112090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071140',
    Description: 'Dưa chuột và dưa chuột ri:',
    Unit: '',
  },
  {
    HsCode: '07114010',
    Description: 'Đã bảo quản bằng khí sunphurơ',
    Unit: 'kg',
  },
  {
    HsCode: '07114090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Nấm và nấm cục (truffles):',
    Unit: '',
  },
  {
    HsCode: '071151',
    Description: 'Nấm thuộc chi Agaricus:',
    Unit: '',
  },
  {
    HsCode: '07115110',
    Description: 'Đã bảo quản bằng khí sunphurơ',
    Unit: 'kg',
  },
  {
    HsCode: '07115190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071159',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07115910',
    Description: 'Đã bảo quản bằng khí sunphurơ',
    Unit: 'kg',
  },
  {
    HsCode: '07115990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071190',
    Description: 'Rau khác; hỗn hợp các loại rau:',
    Unit: '',
  },
  {
    HsCode: '07119010',
    Description: 'Ngô ngọt',
    Unit: 'kg',
  },
  {
    HsCode: '07119020',
    Description: 'Quả ớt (chillies) (quả thuộc chi Capsicum)',
    Unit: 'kg',
  },
  {
    HsCode: '07119030',
    Description: 'Nụ bạch hoa',
    Unit: 'kg',
  },
  {
    HsCode: '07119040',
    Description: 'Hành tây, đã được bảo quản bằng khí sunphurơ',
    Unit: 'kg',
  },
  {
    HsCode: '07119050',
    Description: 'Hành tây, đã được bảo quản trừ loại được bảo quản bằng khí sunphurơ',
    Unit: 'kg',
  },
  {
    HsCode: '07119060',
    Description: 'Loại khác, đã được bảo quản bằng khí sunphurơ',
    Unit: 'kg',
  },
  {
    HsCode: '07119090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0712',
    Description: 'Rau khô, ở dạng nguyên, cắt, thái lát, vụn hoặc ở dạng bột, nhưng chưa chế biến thêm',
    Unit: '',
  },
  {
    HsCode: '07122000',
    Description: 'Hành tây',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Nấm, mộc nhĩ (Auricularia spp.), nấm tuyết (Tremella spp.) và nấm cục (truffles):',
    Unit: '',
  },
  {
    HsCode: '07123100',
    Description: 'Nấm thuộc chi Agaricus',
    Unit: 'kg',
  },
  {
    HsCode: '07123200',
    Description: 'Mộc nhĩ (Auricularia spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '07123300',
    Description: 'Nấm tuyết (Tremella spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '07123400',
    Description: 'Nấm hương (Lentinus edodes)',
    Unit: 'kg',
  },
  {
    HsCode: '071239',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07123910',
    Description: 'Nấm cục (truffles)',
    Unit: 'kg',
  },
  {
    HsCode: '07123990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071290',
    Description: 'Rau khác; hỗn hợp các loại rau:',
    Unit: '',
  },
  {
    HsCode: '07129010',
    Description: 'Tỏi ',
    Unit: 'kg',
  },
  {
    HsCode: '07129020',
    Description: 'Ngô ngọt',
    Unit: 'kg',
  },
  {
    HsCode: '07129090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0713',
    Description: 'Các loại rau đậu khô, đã bóc vỏ quả, đã hoặc chưa bóc vỏ hạt hoặc làm vỡ hạt',
    Unit: '',
  },
  {
    HsCode: '071310',
    Description: 'Đậu Hà Lan (Pisum sativum):',
    Unit: '',
  },
  {
    HsCode: '07131010',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07131090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071320',
    Description: 'Đậu gà (chickpeas) (garbanzos):',
    Unit: '',
  },
  {
    HsCode: '07132010',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07132090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Đậu hạt (Vigna spp., Phaseolus spp.):',
    Unit: '',
  },
  {
    HsCode: '071331',
    Description: 'Đậu thuộc loài Vigna mungo (L.) Hepper hoặc Vigna radiata (L.) Wilczek:',
    Unit: '',
  },
  {
    HsCode: '07133110',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07133190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071332',
    Description: 'Đậu hạt đỏ nhỏ (Adzuki) (Phaseolus hoặc Vigna angularis):',
    Unit: '',
  },
  {
    HsCode: '07133210',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07133290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071333',
    Description: 'Đậu tây, kể cả đậu trắng (Phaseolus vulgaris):',
    Unit: '',
  },
  {
    HsCode: '07133310',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07133390',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071334',
    Description: 'Đậu bambara (Vigna subterranea hoặc Voandzeia subterranea):',
    Unit: '',
  },
  {
    HsCode: '07133410',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07133490',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071335',
    Description: 'Đậu đũa (Vigna unguiculata):',
    Unit: '',
  },
  {
    HsCode: '07133510',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07133590',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071339',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07133910',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07133990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071340',
    Description: 'Đậu lăng:',
    Unit: '',
  },
  {
    HsCode: '07134010',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07134090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071350',
    Description: 'Đậu tằm (Vicia faba var. major) và đậu ngựa (Vicia faba var. equina, Vicia faba var. minor):',
    Unit: '',
  },
  {
    HsCode: '07135010',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07135090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071360',
    Description: 'Đậu triều, đậu săng (Cajanus cajan):',
    Unit: '',
  },
  {
    HsCode: '07136010',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07136090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07139010',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '07139090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0714',
    Description:
      'Sắn, củ dong, củ lan, a-ti-sô Jerusalem, khoai lang và các loại củ và rễ tương tự có hàm lượng tinh bột hoặc inulin cao, tươi, ướp lạnh, đông lạnh hoặc khô, đã hoặc chưa thái lát hoặc làm thành dạng viên; lõi cây cọ sago',
    Unit: '',
  },
  {
    HsCode: '071410',
    Description: 'Sắn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thái lát hoặc đã làm thành dạng viên:',
    Unit: '',
  },
  {
    HsCode: '07141011',
    Description: 'Lát đã được làm khô',
    Unit: 'kg',
  },
  {
    HsCode: '07141019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07141091',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '07141099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071420',
    Description: 'Khoai lang:',
    Unit: '',
  },
  {
    HsCode: '07142010',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '07142090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071430',
    Description: 'Củ từ, khoai mỡ, khoai tím (Dioscorea spp.):',
    Unit: '',
  },
  {
    HsCode: '07143010',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '07143090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071440',
    Description: 'Khoai sọ, khoai môn (Colacasia spp.):',
    Unit: '',
  },
  {
    HsCode: '07144010',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '07144090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071450',
    Description: 'Khoai sáp, khoai mùng (Xanthosoma spp.):',
    Unit: '',
  },
  {
    HsCode: '07145010',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '07145090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '071490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lõi cây cọ sago:',
    Unit: '',
  },
  {
    HsCode: '07149011',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '07149019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '07149091',
    Description: 'Đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '07149099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 10341:2015',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 8',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quả và quả hạch (nuts) ăn được; vỏ quả thuộc họ cam quýt hoặc các loại dưa',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm các loại quả hạch (nuts) hoặc quả không ăn được.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Quả và quả hạch (nuts) ướp lạnh được xếp cùng nhóm với quả và quả hạch (nuts) tươi tương ứng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Quả khô hoặc quả hạch (nuts) khô thuộc Chương này có thể được hydrat hóa lại một phần, hoặc xử lý nhằm các mục đích sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Tăng cường bảo quản hoặc làm ổn định (ví dụ, xử lý bằng nhiệt vừa phải, bằng lưu huỳnh, bằng cách cho thêm axit sorbic hoặc kali sorbat),',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Cải thiện hoặc duy trì bề ngoài của chúng (ví dụ, bằng cách cho thêm dầu thực vật hoặc một lượng nhỏ xirô glucoza), với điều kiện là chúng vẫn giữ được đặc tính của quả và quả hạch (nuts) khô.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 08.12 áp dụng cho những loại quả và quả hạch (nut) đã qua xử lý chỉ để bảo quản tạm thời trong quá trình vận chuyển hoặc lưu trữ trước khi đưa vào sử dụng (ví dụ: bảo quản bằng khí sunphurơ, ngâm nước muối, nước lưu huỳnh hoặc trong các dung dịch bảo quản khác), với điều kiện chúng không phù hợp để dùng ngay khi ở dạng đó.',
    Unit: '',
  },
  {
    HsCode: '0801',
    Description: 'Dừa, quả hạch Brazil (Brazil nuts) và hạt điều, tươi hoặc khô, đã hoặc chưa bóc vỏ hoặc lột vỏ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dừa:',
    Unit: '',
  },
  {
    HsCode: '08011100',
    Description: 'Đã qua công đoạn làm khô',
    Unit: 'kg',
  },
  {
    HsCode: '08011200',
    Description: 'Dừa còn nguyên sọ',
    Unit: 'kg',
  },
  {
    HsCode: '080119',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '08011910',
    Description: 'Quả dừa non (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08011990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Quả hạch Brazil (Brazil nuts):',
    Unit: '',
  },
  {
    HsCode: '08012100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08012200',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hạt điều:',
    Unit: '',
  },
  {
    HsCode: '08013100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08013200',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '0802',
    Description: 'Quả hạch (nuts) khác, tươi hoặc khô, đã hoặc chưa bóc vỏ hoặc lột vỏ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quả hạnh nhân:',
    Unit: '',
  },
  {
    HsCode: '08021100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '080212',
    Description: 'Đã bóc vỏ:',
    Unit: '',
  },
  {
    HsCode: '08021210',
    Description: 'Đã chần (blanched) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08021290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Quả phỉ hoặc hạt phỉ (Corylus spp.):',
    Unit: '',
  },
  {
    HsCode: '08022100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08022200',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Quả óc chó:',
    Unit: '',
  },
  {
    HsCode: '08023100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08023200',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hạt dẻ (Castanea spp.):',
    Unit: '',
  },
  {
    HsCode: '08024100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08024200',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Quả hồ trăn (Hạt dẻ cười):',
    Unit: '',
  },
  {
    HsCode: '08025100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08025200',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hạt mắc-ca (Macadamia nuts):',
    Unit: '',
  },
  {
    HsCode: '08026100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08026200',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08027000',
    Description: 'Hạt cây côla (Cola spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '08028000',
    Description: 'Quả cau ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '08029100',
    Description: 'Hạt thông, chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08029200',
    Description: 'Hạt thông, đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08029900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0803',
    Description: 'Chuối, kể cả chuối lá, tươi hoặc khô',
    Unit: '',
  },
  {
    HsCode: '080310',
    Description: 'Chuối lá:',
    Unit: '',
  },
  {
    HsCode: '08031010',
    Description: 'Tươi',
    Unit: 'kg',
  },
  {
    HsCode: '08031020',
    Description: 'Khô',
    Unit: 'kg',
  },
  {
    HsCode: '080390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '08039010',
    Description: "Chuối ngự (Lady's finger banana) (SEN)",
    Unit: 'kg',
  },
  {
    HsCode: '08039020',
    Description: 'Chuối Cavendish (Musa acuminata) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08039030',
    Description: 'Chuối Chestnut (Chestnut banana) (lai giữa Musa acuminata và Musa balbisiana, giống Berangan) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08039090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0804',
    Description: 'Quả chà là, sung, vả, dứa, bơ, ổi, xoài và măng cụt, tươi hoặc khô',
    Unit: '',
  },
  {
    HsCode: '08041000',
    Description: 'Quả chà là',
    Unit: 'kg',
  },
  {
    HsCode: '08042000',
    Description: 'Quả sung, vả',
    Unit: 'kg',
  },
  {
    HsCode: '08043000',
    Description: 'Quả dứa',
    Unit: 'kg',
  },
  {
    HsCode: '08044000',
    Description: 'Quả bơ',
    Unit: 'kg',
  },
  {
    HsCode: '080450',
    Description: 'Quả ổi, xoài và măng cụt:',
    Unit: '',
  },
  {
    HsCode: '08045010',
    Description: 'Quả ổi',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Quả xoài:',
    Unit: '',
  },
  {
    HsCode: '08045021',
    Description: 'Tươi',
    Unit: 'kg',
  },
  {
    HsCode: '08045022',
    Description: 'Khô',
    Unit: 'kg',
  },
  {
    HsCode: '08045030',
    Description: 'Quả măng cụt',
    Unit: 'kg',
  },
  {
    HsCode: '0805',
    Description: 'Quả thuộc chi cam quýt, tươi hoặc khô',
    Unit: '',
  },
  {
    HsCode: '080510',
    Description: 'Quả cam:',
    Unit: '',
  },
  {
    HsCode: '08051010',
    Description: 'Tươi',
    Unit: 'kg',
  },
  {
    HsCode: '08051020',
    Description: 'Khô',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Quả quýt các loại (kể cả quất); cam nhỏ (clementines) và các loại giống lai chi cam quýt tương tự:',
    Unit: '',
  },
  {
    HsCode: '08052100',
    Description: 'Quả quýt các loại (kể cả quất)',
    Unit: 'kg',
  },
  {
    HsCode: '08052200',
    Description: 'Cam nhỏ (Clementines)',
    Unit: 'kg',
  },
  {
    HsCode: '08052900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '08054000',
    Description: 'Bưởi chùm (Grapefruit) và bưởi (pomelos) ',
    Unit: 'kg',
  },
  {
    HsCode: '080550',
    Description:
      'Quả chanh vàng (lemon) (Citrus limon, Citrus limonum) và quả chanh xanh (chanh ta Citrus aurantifolia, chanh không hạt Citrus latifolia):',
    Unit: '',
  },
  {
    HsCode: '08055010',
    Description: 'Quả chanh vàng (lemon) (Citrus limon, Citrus limonum) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08055020',
    Description: 'Quả chanh xanh (chanh ta Citrus aurantifolia, chanh không hạt Citrus latifolia)(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08059000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0806',
    Description: 'Quả nho, tươi hoặc khô',
    Unit: '',
  },
  {
    HsCode: '08061000',
    Description: 'Tươi',
    Unit: 'kg',
  },
  {
    HsCode: '08062000',
    Description: 'Khô',
    Unit: 'kg',
  },
  {
    HsCode: '0807',
    Description: 'Các loại quả họ dưa (kể cả dưa hấu) và đu đủ, tươi',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các loại quả họ dưa (kể cả dưa hấu):',
    Unit: '',
  },
  {
    HsCode: '08071100',
    Description: 'Quả dưa hấu',
    Unit: 'kg',
  },
  {
    HsCode: '08071900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '08072000',
    Description: 'Quả đu đủ',
    Unit: 'kg',
  },
  {
    HsCode: '0808',
    Description: 'Quả táo (apples), lê và quả mộc qua, tươi',
    Unit: '',
  },
  {
    HsCode: '08081000',
    Description: 'Quả táo (apples)',
    Unit: 'kg',
  },
  {
    HsCode: '08083000',
    Description: 'Quả lê',
    Unit: 'kg',
  },
  {
    HsCode: '08084000',
    Description: 'Quả mộc qua',
    Unit: 'kg',
  },
  {
    HsCode: '0809',
    Description: 'Quả mơ, anh đào, đào (kể cả xuân đào), mận và mận gai, tươi',
    Unit: '',
  },
  {
    HsCode: '08091000',
    Description: 'Quả mơ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Quả anh đào:',
    Unit: '',
  },
  {
    HsCode: '08092100',
    Description: 'Quả anh đào chua (Prunus cerasus)',
    Unit: 'kg',
  },
  {
    HsCode: '08092900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '08093000',
    Description: 'Quả đào, kể cả xuân đào',
    Unit: 'kg',
  },
  {
    HsCode: '080940',
    Description: 'Quả mận và quả mận gai:',
    Unit: '',
  },
  {
    HsCode: '08094010',
    Description: 'Quả mận',
    Unit: 'kg',
  },
  {
    HsCode: '08094020',
    Description: 'Quả mận gai',
    Unit: 'kg',
  },
  {
    HsCode: '0810',
    Description: 'Quả khác, tươi',
    Unit: '',
  },
  {
    HsCode: '08101000',
    Description: 'Quả dâu tây',
    Unit: 'kg',
  },
  {
    HsCode: '08102000',
    Description: 'Quả mâm xôi (Raspberries, blackberries), dâu tằm và dâu đỏ (loganberries)',
    Unit: 'kg',
  },
  {
    HsCode: '08103000',
    Description: 'Quả lý gai và quả lý chua, đen, trắng hoặc đỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08104000',
    Description: 'Quả nam việt quất, quả việt quất và các loại quả khác thuộc chi Vaccinium',
    Unit: 'kg',
  },
  {
    HsCode: '08105000',
    Description: 'Quả kiwi',
    Unit: 'kg',
  },
  {
    HsCode: '08106000',
    Description: 'Quả sầu riêng',
    Unit: 'kg',
  },
  {
    HsCode: '08107000',
    Description: 'Quả hồng (Persimmons)',
    Unit: 'kg',
  },
  {
    HsCode: '081090',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '08109010',
    Description: 'Quả nhãn; Quả nhãn Mata Kucing (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08109020',
    Description: 'Quả vải',
    Unit: 'kg',
  },
  {
    HsCode: '08109030',
    Description: 'Quả chôm chôm',
    Unit: 'kg',
  },
  {
    HsCode: '08109040',
    Description: 'Quả bòn bon (Lanzones)',
    Unit: 'kg',
  },
  {
    HsCode: '08109050',
    Description: 'Quả mít (kể cả mít Cempedak và mít Nangka)',
    Unit: 'kg',
  },
  {
    HsCode: '08109060',
    Description: 'Quả me',
    Unit: 'kg',
  },
  {
    HsCode: '08109070',
    Description: 'Quả khế',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '08109091',
    Description: 'Salacca (quả da rắn)',
    Unit: 'kg',
  },
  {
    HsCode: '08109092',
    Description: 'Quả thanh long',
    Unit: 'kg',
  },
  {
    HsCode: '08109093',
    Description: 'Quả hồng xiêm (quả sapôchê)',
    Unit: 'kg',
  },
  {
    HsCode: '08109094',
    Description:
      'Quả lựu (Punica spp.), quả mãng cầu hoặc quả na (Annona spp.), quả roi (bell fruit) (Syzygium spp., Eugenia spp.), quả thanh trà (Bouea spp.), quả chanh leo (dây) (Passiflora spp.), quả sấu đỏ (Sandoricum spp.), quả táo ta (Ziziphus spp.) và quả dâu da đất (Baccaurea spp.) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '08109099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0811',
    Description:
      'Quả và quả hạch (nuts), đã hoặc chưa hấp chín hoặc luộc chín trong nước, đông lạnh, đã hoặc chưa thêm đường hoặc chất tạo ngọt khác',
    Unit: '',
  },
  {
    HsCode: '08111000',
    Description: 'Quả dâu tây',
    Unit: 'kg',
  },
  {
    HsCode: '08112000',
    Description:
      'Quả mâm xôi (Raspberries, blackberries), dâu tằm, dâu đỏ (loganberries), quả lý chua đen, trắng hoặc đỏ và quả lý gai',
    Unit: 'kg',
  },
  {
    HsCode: '08119000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0812',
    Description: 'Quả và quả hạch (nuts) được bảo quản tạm thời, nhưng không phù hợp dùng ngay được',
    Unit: '',
  },
  {
    HsCode: '08121000',
    Description: 'Quả anh đào',
    Unit: 'kg',
  },
  {
    HsCode: '081290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '08129010',
    Description: 'Quả dâu tây',
    Unit: 'kg',
  },
  {
    HsCode: '08129090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0813',
    Description:
      'Quả, khô, trừ các loại quả thuộc nhóm 08.01 đến 08.06; hỗn hợp các loại quả hạch (nuts) hoặc quả khô thuộc Chương này',
    Unit: '',
  },
  {
    HsCode: '08131000',
    Description: 'Quả mơ',
    Unit: 'kg',
  },
  {
    HsCode: '08132000',
    Description: 'Quả mận đỏ',
    Unit: 'kg',
  },
  {
    HsCode: '08133000',
    Description: 'Quả táo (apples)',
    Unit: 'kg',
  },
  {
    HsCode: '081340',
    Description: 'Quả khác:',
    Unit: '',
  },
  {
    HsCode: '08134010',
    Description: 'Quả nhãn',
    Unit: 'kg',
  },
  {
    HsCode: '08134020',
    Description: 'Quả me',
    Unit: 'kg',
  },
  {
    HsCode: '08134090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '081350',
    Description: 'Hỗn hợp các loại quả hạch (nuts) hoặc quả khô thuộc Chương này:',
    Unit: '',
  },
  {
    HsCode: '08135010',
    Description: 'Hạt điều hoặc quả hạch Brazil (Brazil nuts) chiếm đa số về khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '08135020',
    Description: 'Quả hạch (nuts) khác chiếm đa số về khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '08135030',
    Description: 'Quả chà là chiếm đa số về khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '08135040',
    Description: 'Quả bơ hoặc quả cam hoặc quả quýt các loại (kể cả quất) chiếm đa số về khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '08135090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '08140000',
    Description:
      'Vỏ các loại quả thuộc chi cam quýt hoặc các loại dưa (kể cả dưa hấu), tươi, đông lạnh, khô hoặc bảo quản tạm thời trong nước muối, nước lưu huỳnh hoặc trong các dung dịch bảo quản khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 9',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cà phê, chè, chè Paragoay và các loại gia vị',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Hỗn hợp của các sản phẩm thuộc các nhóm từ 09.04 đến 09.10 được phân loại như sau: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hỗn hợp của hai hoặc nhiều sản phẩm thuộc cùng một nhóm cũng được xếp trong nhóm đó;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hỗn hợp của hai hoặc nhiều sản phẩm thuộc các nhóm khác nhau cũng được xếp trong nhóm 09.10.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Việc pha thêm các chất khác vào các sản phẩm thuộc các nhóm từ 09.04 đến 09.10 (hoặc vào hỗn hợp đã nêu trong mục (a) hoặc (b) trên đây) sẽ không ảnh hưởng tới việc phân loại sản phẩm nếu như hỗn hợp mới vẫn giữ được đặc tính cơ bản của các mặt hàng thuộc các nhóm đó. Trong các trường hợp khác các hỗn hợp mới đó không được phân loại trong Chương này; gia vị hỗn hợp hoặc bột canh hỗn hợp được phân loại trong nhóm 21.03.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm hạt tiêu Cubeb (Piper cubeba) hoặc các sản phẩm khác thuộc nhóm 12.11.',
    Unit: '',
  },
  {
    HsCode: '0901',
    Description:
      'Cà phê, rang hoặc chưa rang, đã hoặc chưa khử chất caffeine; vỏ quả và vỏ lụa cà phê; các chất thay thế cà phê có chứa cà phê theo tỷ lệ nào đó',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cà phê, chưa rang:',
    Unit: '',
  },
  {
    HsCode: '090111',
    Description: 'Chưa khử chất caffeine:',
    Unit: '',
  },
  {
    HsCode: '09011120',
    Description: 'Arabica (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '09011130',
    Description: 'Robusta (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '09011190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '090112',
    Description: 'Đã khử chất caffeine:',
    Unit: '',
  },
  {
    HsCode: '09011220',
    Description: 'Arabica (SEN) hoặc Robusta (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '09011290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Cà phê, đã rang:',
    Unit: '',
  },
  {
    HsCode: '090121',
    Description: 'Chưa khử chất caffeine:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chưa nghiền:',
    Unit: '',
  },
  {
    HsCode: '09012111',
    Description: 'Arabica (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '09012112',
    Description: 'Robusta (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '09012119',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '09012120',
    Description: 'Đã xay',
    Unit: 'kg',
  },
  {
    HsCode: '090122',
    Description: 'Đã khử chất caffeine:',
    Unit: '',
  },
  {
    HsCode: '09012210',
    Description: 'Chưa xay',
    Unit: 'kg',
  },
  {
    HsCode: '09012220',
    Description: 'Đã xay',
    Unit: 'kg',
  },
  {
    HsCode: '090190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '09019010',
    Description: 'Vỏ quả và vỏ lụa cà phê',
    Unit: 'kg',
  },
  {
    HsCode: '09019020',
    Description: 'Các chất thay thế có chứa cà phê',
    Unit: 'kg',
  },
  {
    HsCode: '0902',
    Description: 'Chè, đã hoặc chưa pha hương liệu',
    Unit: '',
  },
  {
    HsCode: '090210',
    Description: 'Chè xanh (chưa ủ men) đóng gói sẵn trọng lượng không quá 3 kg:',
    Unit: '',
  },
  {
    HsCode: '09021010',
    Description: 'Lá chè',
    Unit: 'kg',
  },
  {
    HsCode: '09021090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '090220',
    Description: 'Chè xanh khác (chưa ủ men): ',
    Unit: '',
  },
  {
    HsCode: '09022010',
    Description: 'Lá chè',
    Unit: 'kg',
  },
  {
    HsCode: '09022090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '090230',
    Description: 'Chè đen (đã ủ men) và chè đã ủ men một phần, đóng gói sẵn trọng lượng không quá 3kg: ',
    Unit: '',
  },
  {
    HsCode: '09023010',
    Description: 'Lá chè',
    Unit: 'kg',
  },
  {
    HsCode: '09023090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '090240',
    Description: 'Chè đen khác (đã ủ men) và chè khác đã ủ men một phần:',
    Unit: '',
  },
  {
    HsCode: '09024010',
    Description: 'Lá chè',
    Unit: 'kg',
  },
  {
    HsCode: '09024090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '09030000',
    Description: 'Chè Paragoay (Maté)',
    Unit: 'kg',
  },
  {
    HsCode: '0904',
    Description: 'Hạt tiêu thuộc chi Piper; quả ớt thuộc chi Capsicum hoặc chi Pimenta, khô hoặc xay hoặc nghiền',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hạt tiêu:',
    Unit: '',
  },
  {
    HsCode: '090411',
    Description: 'Chưa xay hoặc chưa nghiền:',
    Unit: '',
  },
  {
    HsCode: '09041110',
    Description: 'Trắng',
    Unit: 'kg',
  },
  {
    HsCode: '09041120',
    Description: 'Đen',
    Unit: 'kg',
  },
  {
    HsCode: '09041190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '090412',
    Description: 'Đã xay hoặc nghiền:',
    Unit: '',
  },
  {
    HsCode: '09041210',
    Description: 'Trắng',
    Unit: 'kg',
  },
  {
    HsCode: '09041220',
    Description: 'Đen',
    Unit: 'kg',
  },
  {
    HsCode: '09041290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Quả ớt thuộc chi Capsicum hoặc chi Pimenta:',
    Unit: '',
  },
  {
    HsCode: '090421',
    Description: 'Đã làm khô, chưa xay hoặc chưa nghiền:',
    Unit: '',
  },
  {
    HsCode: '09042110',
    Description: 'Quả ớt (chillies) (quả thuộc chi Capsicum)',
    Unit: 'kg',
  },
  {
    HsCode: '09042190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '090422',
    Description: 'Đã xay hoặc nghiền:',
    Unit: '',
  },
  {
    HsCode: '09042210',
    Description: 'Quả ớt (chillies) (quả thuộc chi Capsicum)',
    Unit: 'kg',
  },
  {
    HsCode: '09042290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0905',
    Description: 'Vani',
    Unit: '',
  },
  {
    HsCode: '09051000',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09052000',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '0906',
    Description: 'Quế và hoa quế',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chưa xay hoặc chưa nghiền:',
    Unit: '',
  },
  {
    HsCode: '09061100',
    Description: 'Quế (Cinnamomum zeylanicum Blume)',
    Unit: 'kg',
  },
  {
    HsCode: '09061900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '09062000',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '0907',
    Description: 'Đinh hương (cả quả, thân và cành)',
    Unit: '',
  },
  {
    HsCode: '09071000',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09072000',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '0908',
    Description: 'Hạt nhục đậu khấu, vỏ nhục đậu khấu và bạch đậu khấu',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hạt nhục đậu khấu:',
    Unit: '',
  },
  {
    HsCode: '09081100',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09081200',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Vỏ nhục đậu khấu:',
    Unit: '',
  },
  {
    HsCode: '09082100',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09082200',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Bạch đậu khấu:',
    Unit: '',
  },
  {
    HsCode: '09083100',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09083200',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '0909',
    Description:
      'Hạt của hoa hồi anise (tiểu hồi), hoa hồi badian (đại hồi), thì là, rau mùi, thì là Ai Cập hoặc ca-rum (caraway); hạt bách xù (juniper berries)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hạt của cây rau mùi:',
    Unit: '',
  },
  {
    HsCode: '09092100',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09092200',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hạt cây thì là Ai Cập:',
    Unit: '',
  },
  {
    HsCode: '09093100',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09093200',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Hạt của hoa hồi anise (tiểu hồi), hoa hồi badian (đại hồi), cây ca-rum (caraway) hoặc hạt cây thì là; hạt cây bách xù (juniper berries):',
    Unit: '',
  },
  {
    HsCode: '090961',
    Description: 'Chưa xay hoặc chưa nghiền:',
    Unit: '',
  },
  {
    HsCode: '09096110',
    Description: 'Của hoa hồi anise (tiểu hồi)',
    Unit: 'kg',
  },
  {
    HsCode: '09096120',
    Description: 'Của hoa hồi badian (đại hồi)',
    Unit: 'kg',
  },
  {
    HsCode: '09096130',
    Description: 'Của cây ca-rum (caraway)',
    Unit: 'kg',
  },
  {
    HsCode: '09096190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '090962',
    Description: 'Đã xay hoặc nghiền:',
    Unit: '',
  },
  {
    HsCode: '09096210',
    Description: 'Của hoa hồi anise (tiểu hồi)',
    Unit: 'kg',
  },
  {
    HsCode: '09096220',
    Description: 'Của hoa hồi badian (đại hồi)',
    Unit: 'kg',
  },
  {
    HsCode: '09096230',
    Description: 'Của cây ca-rum (caraway)',
    Unit: 'kg',
  },
  {
    HsCode: '09096290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '0910',
    Description:
      'Gừng, nghệ tây, nghệ (curcuma), húng tây, cỏ xạ hương, lá nguyệt quế, cà ri (curry) và các loại gia vị khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Gừng:',
    Unit: '',
  },
  {
    HsCode: '09101100',
    Description: 'Chưa xay hoặc chưa nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09101200',
    Description: 'Đã xay hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '09102000',
    Description: 'Nghệ tây',
    Unit: 'kg',
  },
  {
    HsCode: '09103000',
    Description: 'Nghệ (curcuma)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Gia vị khác:',
    Unit: '',
  },
  {
    HsCode: '091091',
    Description: 'Hỗn hợp đã nêu trong Chú giải 1(b) của Chương này:',
    Unit: '',
  },
  {
    HsCode: '09109110',
    Description: 'Cà ri (curry)',
    Unit: 'kg',
  },
  {
    HsCode: '09109190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '091099',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '09109910',
    Description: 'Húng tây, cỏ xạ hương; lá nguyệt quế',
    Unit: 'kg',
  },
  {
    HsCode: '09109990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 10',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Ngũ cốc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. (A) Các sản phẩm kể tên trong các nhóm của Chương này chỉ được phân loại trong các nhóm đó khi ở dạng hạt, còn hoặc không còn ở trên bông hoặc trên thân cây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Chương này không bao gồm các loại hạt đã xát vỏ hoặc chế biến cách khác. Tuy nhiên, thóc được xay để bỏ trấu, gạo được xát, đánh bóng, hồ (glazed), gạo đồ(1) hoặc gạo tấm vẫn được phân loại trong nhóm 10.06. Tương tự, hạt diêm mạch (quinoa) đã được loại bỏ toàn bộ hoặc một phần vỏ lụa để tách saponin, nhưng không trải qua bất kỳ quá trình chế biến nào khác, vẫn được phân loại trong nhóm 10.08.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Nhóm 10.05 không bao gồm ngô ngọt (Chương 7).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Khái niệm ""lúa mì durum"" có nghĩa là loại lúa mì thuộc loài Triticum durum và các giống lai tạo từ việc kết hợp giữa các loài của Triticum durum có cùng số nhiễm sắc thể (28) như loài đó.',
    Unit: '',
  },
  {
    HsCode: '1001',
    Description: 'Lúa mì và meslin',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lúa mì Durum:',
    Unit: '',
  },
  {
    HsCode: '10011100',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '10011900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '10019100',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '100199',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thích hợp sử dụng cho người:',
    Unit: '',
  },
  {
    HsCode: '10019911',
    Description: 'Meslin (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10019912',
    Description: 'Hạt lúa mì đã bỏ lớp vỏ ngoài cùng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10019919',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '10019991',
    Description: 'Meslin',
    Unit: 'kg',
  },
  {
    HsCode: '10019999',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '1002',
    Description: 'Lúa mạch đen',
    Unit: '',
  },
  {
    HsCode: '10021000',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '10029000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1003',
    Description: 'Lúa đại mạch',
    Unit: '',
  },
  {
    HsCode: '10031000',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '10039000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1004',
    Description: 'Yến mạch',
    Unit: '',
  },
  {
    HsCode: '10041000',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '10049000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1005',
    Description: 'Ngô',
    Unit: '',
  },
  {
    HsCode: '10051000',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '100590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '10059010',
    Description: 'Loại dùng để rang nổ (popcorn) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '10059091',
    Description: 'Thích hợp sử dụng cho người (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10059099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1006',
    Description: 'Lúa gạo',
    Unit: '',
  },
  {
    HsCode: '100610',
    Description: 'Thóc: ',
    Unit: '',
  },
  {
    HsCode: '10061010',
    Description: 'Phù hợp để gieo trồng',
    Unit: 'kg',
  },
  {
    HsCode: '10061090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '100620',
    Description: 'Gạo lứt:',
    Unit: '',
  },
  {
    HsCode: '10062010',
    Description: 'Gạo Hom Mali (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10062090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '100630',
    Description: 'Gạo đã xát toàn bộ hoặc sơ bộ, đã hoặc chưa được đánh bóng hoặc hồ (glazed):',
    Unit: '',
  },
  {
    HsCode: '10063030',
    Description: 'Gạo nếp (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10063040',
    Description: 'Gạo Hom Mali (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10063050',
    Description: 'Gạo Basmati (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10063060',
    Description: 'Gạo Malys (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10063070',
    Description: 'Gạo thơm khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '10063091',
    Description: 'Gạo đồ (1)',
    Unit: 'kg',
  },
  {
    HsCode: '10063099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '100640',
    Description: 'Tấm:',
    Unit: '',
  },
  {
    HsCode: '10064010',
    Description: 'Loại dùng làm thức ăn chăn nuôi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '10064090',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '1007',
    Description: 'Lúa miến',
    Unit: '',
  },
  {
    HsCode: '10071000',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '10079000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1008',
    Description: 'Kiều mạch, kê, hạt cây thóc chim; các loại ngũ cốc khác',
    Unit: '',
  },
  {
    HsCode: '10081000',
    Description: 'Kiều mạch',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Kê:',
    Unit: '',
  },
  {
    HsCode: '10082100',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '10082900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '10083000',
    Description: 'Hạt cây thóc chim (họ lúa)',
    Unit: 'kg',
  },
  {
    HsCode: '10084000',
    Description: 'Hạt kê Fonio (Digitaria spp.)',
    Unit: 'kg',
  },
  {
    HsCode: '10085000',
    Description: 'Hạt diêm mạch (Chenopodium quinoa) ',
    Unit: 'kg',
  },
  {
    HsCode: '10086000',
    Description: 'Lúa mì lai lúa mạch đen (Triticale)',
    Unit: 'kg',
  },
  {
    HsCode: '10089000',
    Description: '- Ngũ cốc loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 5643:1999',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 11',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các sản phẩm xay xát; malt; tinh bột; inulin; gluten lúa mì',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Malt đã rang dùng như chất thay thế cà phê (nhóm 09.01 hoặc nhóm 21.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Bột mịn, tấm, bột thô hoặc tinh bột đã chế biến thuộc nhóm 19.01;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Mảnh ngô chế biến từ bột ngô (corn flakes) hoặc các sản phẩm khác thuộc nhóm 19.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các loại rau, đã chế biến hoặc bảo quản, thuộc nhóm 20.01, 20.04 hoặc 20.05;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Dược phẩm (Chương 30); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Tinh bột có đặc tính của nước hoa, mỹ phẩm hoặc chế phẩm vệ sinh (Chương 33).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. (A) Các sản phẩm thu được từ quá trình xay xát ngũ cốc liệt kê trong bảng dưới đây được xếp vào Chương này, nếu tính theo khối lượng trên sản phẩm khô các sản phẩm này có:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) hàm lượng tinh bột (được xác định theo phương pháp phân cực Ewer cải tiến) vượt quá chỉ dẫn ghi trong Cột (2); và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) hàm lượng tro (sau khi loại trừ các khoáng chất bổ sung) không vượt quá chỉ dẫn ghi trong Cột (3).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm không giống như vậy sẽ được xếp vào nhóm 23.02. Tuy nhiên, mầm ngũ cốc nguyên dạng, đã được xay, vỡ mảnh hoặc nghiền, luôn luôn được phân loại vào nhóm 11.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Các sản phẩm thuộc Chương này theo những quy định trên đây sẽ được xếp vào nhóm 11.01 hoặc 11.02 nếu có tỷ lệ phần trăm lọt qua sàng bằng lưới kim loại với kích thước mắt sàng ghi trong Cột (4) hoặc (5) không thấp hơn tỷ lệ ghi cho từng loại ngũ cốc, tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các trường hợp khác sẽ được xếp vào nhóm 11.03 hoặc 11.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 1',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 11.03, khái niệm ""tấm"" và ""bột thô"" được hiểu là các sản phẩm thu được từ quá trình xay vỡ hạt ngũ cốc, trong đó:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) đối với sản phẩm ngô, tối thiểu 95% tính theo khối lượng lọt qua sàng bằng lưới kim loại với kích thước mắt sàng là 2 mm;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) đối với sản phẩm ngũ cốc khác, tối thiểu 95% tính theo khối lượng lọt qua sàng có lưới bằng kim loại với kích thước mắt sàng là 1,25 mm.',
    Unit: '',
  },
  {
    HsCode: '1101',
    Description: 'Bột mì hoặc bột meslin',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Bột mì:',
    Unit: '',
  },
  {
    HsCode: '11010011',
    Description: 'Tăng cường vi chất dinh dưỡng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '11010019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '11010020',
    Description: 'Bột meslin',
    Unit: 'kg',
  },
  {
    HsCode: '1102',
    Description: 'Bột ngũ cốc, trừ bột mì hoặc bột meslin',
    Unit: '',
  },
  {
    HsCode: '11022000',
    Description: 'Bột ngô',
    Unit: 'kg',
  },
  {
    HsCode: '110290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '11029010',
    Description: 'Bột gạo',
    Unit: 'kg',
  },
  {
    HsCode: '11029020',
    Description: 'Bột lúa mạch đen',
    Unit: 'kg',
  },
  {
    HsCode: '11029090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1103',
    Description: 'Ngũ cốc dạng tấm, dạng bột thô và viên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng tấm và bột thô:',
    Unit: '',
  },
  {
    HsCode: '11031100',
    Description: 'Của lúa mì',
    Unit: 'kg',
  },
  {
    HsCode: '11031300',
    Description: 'Của ngô',
    Unit: 'kg',
  },
  {
    HsCode: '110319',
    Description: 'Của ngũ cốc khác:',
    Unit: '',
  },
  {
    HsCode: '11031910',
    Description: 'Của meslin',
    Unit: 'kg',
  },
  {
    HsCode: '11031920',
    Description: 'Của gạo',
    Unit: 'kg',
  },
  {
    HsCode: '11031990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '11032000',
    Description: 'Dạng viên',
    Unit: 'kg',
  },
  {
    HsCode: '1104',
    Description:
      'Hạt ngũ cốc được chế biến theo cách khác (ví dụ, xát vỏ, xay, vỡ mảnh, nghiền vụn, cắt lát hoặc nghiền thô), trừ gạo thuộc nhóm 10.06; mầm ngũ cốc, nguyên dạng, xay, vỡ mảnh hoặc nghiền',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Ngũ cốc xay hoặc vỡ mảnh:',
    Unit: '',
  },
  {
    HsCode: '11041200',
    Description: 'Của yến mạch',
    Unit: 'kg',
  },
  {
    HsCode: '110419',
    Description: 'Của ngũ cốc khác:',
    Unit: '',
  },
  {
    HsCode: '11041910',
    Description: 'Của ngô',
    Unit: 'kg',
  },
  {
    HsCode: '11041990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Ngũ cốc đã chế biến cách khác (ví dụ, xát vỏ, nghiền vụn, cắt lát hoặc nghiền thô):',
    Unit: '',
  },
  {
    HsCode: '11042200',
    Description: 'Của yến mạch',
    Unit: 'kg',
  },
  {
    HsCode: '11042300',
    Description: 'Của ngô',
    Unit: 'kg',
  },
  {
    HsCode: '110429',
    Description: 'Của ngũ cốc khác:',
    Unit: '',
  },
  {
    HsCode: '11042920',
    Description: 'Của lúa mạch',
    Unit: 'kg',
  },
  {
    HsCode: '11042990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '11043000',
    Description: 'Mầm ngũ cốc, nguyên dạng, xay, vỡ mảnh hoặc nghiền',
    Unit: 'kg',
  },
  {
    HsCode: '1105',
    Description: 'Bột, bột thô, bột mịn, mảnh lát, hạt và viên từ khoai tây',
    Unit: '',
  },
  {
    HsCode: '11051000',
    Description: 'Bột, bột thô và bột mịn',
    Unit: 'kg',
  },
  {
    HsCode: '11052000',
    Description: 'Dạng mảnh lát, hạt và viên',
    Unit: 'kg',
  },
  {
    HsCode: '1106',
    Description:
      'Bột, bột thô và bột mịn, chế biến từ các loại rau đậu khô thuộc nhóm 07.13, từ cọ sago hoặc từ rễ, củ hoặc thân củ thuộc nhóm 07.14 hoặc từ các sản phẩm thuộc Chương 8',
    Unit: '',
  },
  {
    HsCode: '11061000',
    Description: 'Từ các loại rau đậu khô thuộc nhóm 07.13',
    Unit: 'kg',
  },
  {
    HsCode: '110620',
    Description: 'Từ cọ sago hoặc từ rễ hoặc thân củ thuộc nhóm 07.14:',
    Unit: '',
  },
  {
    HsCode: '11062010',
    Description: 'Từ sắn',
    Unit: 'kg',
  },
  {
    HsCode: '11062020',
    Description: 'Từ cọ sago',
    Unit: 'kg',
  },
  {
    HsCode: '11062090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '11063000',
    Description: 'Từ các sản phẩm thuộc Chương 8',
    Unit: 'kg',
  },
  {
    HsCode: '1107',
    Description: 'Malt, rang hoặc chưa rang',
    Unit: '',
  },
  {
    HsCode: '11071000',
    Description: 'Chưa rang',
    Unit: 'kg',
  },
  {
    HsCode: '11072000',
    Description: 'Đã rang',
    Unit: 'kg',
  },
  {
    HsCode: '1108',
    Description: 'Tinh bột; inulin',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tinh bột:',
    Unit: '',
  },
  {
    HsCode: '11081100',
    Description: 'Tinh bột mì',
    Unit: 'kg',
  },
  {
    HsCode: '11081200',
    Description: 'Tinh bột ngô',
    Unit: 'kg',
  },
  {
    HsCode: '11081300',
    Description: 'Tinh bột khoai tây',
    Unit: 'kg',
  },
  {
    HsCode: '11081400',
    Description: 'Tinh bột sắn',
    Unit: 'kg',
  },
  {
    HsCode: '110819',
    Description: 'Tinh bột khác:',
    Unit: '',
  },
  {
    HsCode: '11081910',
    Description: 'Tinh bột cọ sago',
    Unit: 'kg',
  },
  {
    HsCode: '11081990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '11082000',
    Description: 'Inulin',
    Unit: 'kg',
  },
  {
    HsCode: '11090000',
    Description: 'Gluten lúa mì, đã hoặc chưa làm khô',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 12',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Hạt dầu và quả có dầu; các loại hạt, hạt giống và quả khác; cây công nghiệp hoặc cây dược liệu; rơm, rạ và cỏ khô',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Không kể những đề cập khác, nhóm 12.07 áp dụng cho hạt và nhân hạt cọ, hạt bông, hạt thầu dầu, hạt vừng, hạt mù tạt, hạt rum, hạt thuốc phiện và hạt mỡ (hạt karite). Không áp dụng đối với các sản phẩm thuộc nhóm 08.01 hoặc 08.02 hoặc ôliu (Chương 7 hoặc Chương 20).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 12.08 không chỉ áp dụng cho tất cả bột mịn và bột thô chưa khử chất béo mà còn áp dụng cho bột mịn và bột thô đã đuợc khử một phần hoặc toàn bộ chất béo và bột mịn và bột thô sau khi khử chất béo được bổ sung lại toàn phần hoặc một phần bằng dầu lấy từ chính các bột ấy. Tuy nhiên, nhóm này không áp dụng cho các loại phế liệu thuộc các nhóm 23.04 đến 23.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 12.09, hạt củ cải đường, hạt cây cỏ và hạt cây dạng cỏ khác, hạt cây hoa trang trí, hạt rau, hạt cây rừng, hạt cây ăn quả, hạt đậu tằm (trừ hạt cây thuộc loài Vicia faba) hoặc hạt đậu lupin được coi là ""hạt để gieo trồng"".',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tuy nhiên, nhóm 12.09 không áp dụng đối với những loại sau, cho dù dùng để gieo trồng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các loại rau đậu hoặc ngô ngọt (Chương 7);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các loại gia vị hoặc các sản phẩm khác thuộc Chương 9;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Ngũ cốc (Chương 10); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các sản phẩm thuộc các nhóm từ 12.01 đến 12.07 hoặc 12.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Không kể những đề cập khác, nhóm 12.11 áp dụng với các loại cây hoặc các phần của các loại cây sau đây: húng quế, cây borage (cây lá nhám, hoa xanh), cây nhân sâm, cây bài hương, cây cam thảo, các loại cây bạc hà, cây hương thảo, cây cửu lý hương, cây xả thơm và cây ngải tây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tuy nhiên, nhóm 12.11 không áp dụng đối với những loại sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Dược phẩm thuộc Chương 30;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Nước hoa, mỹ phẩm hoặc chế phẩm vệ sinh thuộc Chương 33; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Thuốc diệt côn trùng, thuốc diệt nấm, thuốc diệt cỏ, thuốc khử trùng hoặc các loại tương tự thuộc nhóm 38.08.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '5. Theo mục đích của nhóm 12.12, khái niệm ""rong biển và các loại tảo khác"" không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các vi sinh đơn bào đã chết thuộc nhóm 21.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các vi sinh nuôi cấy thuộc nhóm 30.02; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Phân bón thuộc nhóm 31.01 hoặc 31.05.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 1205.10, cụm từ ""hạt cải dầu có hàm lượng axit eruxic thấp"" có nghĩa là hạt cải dầu có chứa một lượng dầu không bay hơi với hàm lượng axit eruxic chiếm dưới 2% tính theo khối lượng và thành phần rắn chứa dưới 30 micromol glucosinolate trên 1 gram.',
    Unit: '',
  },
  {
    HsCode: '1201',
    Description: 'Đậu tương, đã hoặc chưa vỡ mảnh',
    Unit: '',
  },
  {
    HsCode: '12011000',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '12019000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1202',
    Description: 'Lạc chưa rang, hoặc chưa làm chín cách khác, đã hoặc chưa bóc vỏ hoặc vỡ mảnh',
    Unit: '',
  },
  {
    HsCode: '12023000',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12024100',
    Description: 'Chưa bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '12024200',
    Description: 'Lạc nhân, đã hoặc chưa vỡ mảnh',
    Unit: 'kg',
  },
  {
    HsCode: '12030000',
    Description: 'Cùi (cơm) dừa khô',
    Unit: 'kg',
  },
  {
    HsCode: '12040000',
    Description: 'Hạt lanh, đã hoặc chưa vỡ mảnh',
    Unit: 'kg',
  },
  {
    HsCode: '1205',
    Description: 'Hạt cải dầu đã hoặc chưa vỡ mảnh',
    Unit: '',
  },
  {
    HsCode: '12051000',
    Description: 'Hạt cải dầu có hàm lượng axit eruxic thấp',
    Unit: 'kg',
  },
  {
    HsCode: '12059000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12060000',
    Description: 'Hạt hướng dương, đã hoặc chưa vỡ mảnh',
    Unit: 'kg',
  },
  {
    HsCode: '1207',
    Description: 'Quả và hạt có dầu khác, đã hoặc chưa vỡ mảnh',
    Unit: '',
  },
  {
    HsCode: '120710',
    Description: 'Hạt cọ và nhân hạt cọ:',
    Unit: '',
  },
  {
    HsCode: '12071010',
    Description: 'Hạt cọ phù hợp để gieo/trồng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '12071030',
    Description: 'Nhân hạt cọ',
    Unit: 'kg',
  },
  {
    HsCode: '12071090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hạt bông:',
    Unit: '',
  },
  {
    HsCode: '12072100',
    Description: 'Hạt giống',
    Unit: 'kg',
  },
  {
    HsCode: '12072900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12073000',
    Description: 'Hạt thầu dầu',
    Unit: 'kg',
  },
  {
    HsCode: '120740',
    Description: 'Hạt vừng:',
    Unit: '',
  },
  {
    HsCode: '12074010',
    Description: 'Loại ăn được',
    Unit: 'kg',
  },
  {
    HsCode: '12074090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12075000',
    Description: 'Hạt mù tạt',
    Unit: 'kg',
  },
  {
    HsCode: '12076000',
    Description: 'Hạt rum (Carthamus tinctorius) ',
    Unit: 'kg',
  },
  {
    HsCode: '12077000',
    Description: 'Hạt dưa (melon seeds)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12079100',
    Description: 'Hạt thuốc phiện',
    Unit: 'kg',
  },
  {
    HsCode: '120799',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12079940',
    Description: 'Hạt illipe (quả hạch illipe)',
    Unit: 'kg',
  },
  {
    HsCode: '12079950',
    Description: 'Chùm quả tươi của cây cọ dầu',
    Unit: 'kg',
  },
  {
    HsCode: '12079990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1208',
    Description: 'Bột mịn và bột thô từ các loại hạt hoặc quả có dầu, trừ bột mịn và bột thô từ hạt mù tạt',
    Unit: '',
  },
  {
    HsCode: '12081000',
    Description: 'Từ đậu tương',
    Unit: 'kg',
  },
  {
    HsCode: '12089000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1209',
    Description: 'Hạt, quả và mầm, dùng để gieo trồng',
    Unit: '',
  },
  {
    HsCode: '12091000',
    Description: 'Hạt củ cải đường (sugar beet)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hạt của các loại cây dùng làm thức ăn gia súc:',
    Unit: '',
  },
  {
    HsCode: '12092100',
    Description: 'Hạt cỏ linh lăng (alfalfa)',
    Unit: 'kg',
  },
  {
    HsCode: '12092200',
    Description: 'Hạt cỏ ba lá (Trifolium spp.) ',
    Unit: 'kg',
  },
  {
    HsCode: '12092300',
    Description: 'Hạt cỏ đuôi trâu',
    Unit: 'kg',
  },
  {
    HsCode: '12092400',
    Description: 'Hạt cỏ kentucky màu xanh da trời (Poa pratensis L.)',
    Unit: 'kg',
  },
  {
    HsCode: '12092500',
    Description: 'Hạt cỏ mạch đen (Lolium multiflorum Lam., Lolium perenne L.) ',
    Unit: 'kg',
  },
  {
    HsCode: '120929',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12092910',
    Description: 'Hạt cỏ đuôi mèo (Phleum pratense)',
    Unit: 'kg',
  },
  {
    HsCode: '12092990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12093000',
    Description: 'Hạt của các loại cây thân cỏ trồng chủ yếu để lấy hoa',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '120991',
    Description: 'Hạt rau:',
    Unit: '',
  },
  {
    HsCode: '12099110',
    Description: 'Hạt hành tây',
    Unit: 'kg',
  },
  {
    HsCode: '12099190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '120999',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12099920',
    Description: 'Hạt cây cao su',
    Unit: 'kg',
  },
  {
    HsCode: '12099930',
    Description: 'Hạt cây kenaf',
    Unit: 'kg',
  },
  {
    HsCode: '12099990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1210',
    Description: 'Hublong (hoa bia), tươi hoặc khô, đã hoặc chưa nghiền, xay thành bột hoặc ở dạng viên; phấn hoa bia',
    Unit: '',
  },
  {
    HsCode: '12101000',
    Description: 'Hublong, chưa nghiền và chưa xay thành bột và chưa ở dạng viên',
    Unit: 'kg',
  },
  {
    HsCode: '12102000',
    Description: 'Hublong, đã nghiền, đã xay thành bột hoặc ở dạng viên; phấn hoa bia',
    Unit: 'kg',
  },
  {
    HsCode: '1211',
    Description:
      'Các loại cây và các bộ phận của cây (kể cả hạt và quả), chủ yếu dùng làm nước hoa, làm dược phẩm hoặc thuốc trừ sâu, thuốc diệt nấm hoặc các mục đích tương tự, tươi, ướp lạnh, đông lạnh hoặc khô, đã hoặc chưa cắt, nghiền hoặc xay thành bột',
    Unit: '',
  },
  {
    HsCode: '121120',
    Description: 'Rễ cây nhân sâm:',
    Unit: '',
  },
  {
    HsCode: '12112010',
    Description: 'Tươi hoặc khô',
    Unit: 'kg',
  },
  {
    HsCode: '12112090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12113000',
    Description: 'Lá coca',
    Unit: 'kg',
  },
  {
    HsCode: '12114000',
    Description: 'Thân cây anh túc',
    Unit: 'kg',
  },
  {
    HsCode: '12115000',
    Description: 'Cây ma hoàng',
    Unit: 'kg',
  },
  {
    HsCode: '12116000',
    Description: 'Vỏ cây anh đào Châu Phi (Prunus africana)',
    Unit: 'kg',
  },
  {
    HsCode: '121190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Loại chủ yếu dùng làm dược liệu:',
    Unit: '',
  },
  {
    HsCode: '12119011',
    Description: 'Cây gai dầu, đã cắt, nghiền hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '12119012',
    Description: 'Cây gai dầu, ở dạng khác',
    Unit: 'kg',
  },
  {
    HsCode: '12119013',
    Description: 'Rễ cây ba gạc hoa đỏ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '12119015',
    Description: 'Rễ cây cam thảo',
    Unit: 'kg',
  },
  {
    HsCode: '12119017',
    Description: 'Loại khác, tươi hoặc khô, đã cắt, nghiền hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '12119018',
    Description: 'Loại khác, đã cắt, nghiền hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '12119019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12119091',
    Description: 'Cây kim cúc, đã cắt, nghiền hoặc dạng bột ',
    Unit: 'kg',
  },
  {
    HsCode: '12119092',
    Description: 'Cây kim cúc, ở dạng khác',
    Unit: 'kg',
  },
  {
    HsCode: '12119094',
    Description: 'Mảnh gỗ đàn hương',
    Unit: 'kg',
  },
  {
    HsCode: '12119095',
    Description: 'Mảnh gỗ trầm hương (Gaharu) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '12119097',
    Description: 'Vỏ cây persea (Persea Kurzii Kosterm) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '12119098',
    Description: 'Loại khác, đã cắt, nghiền hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '12119099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1212',
    Description:
      'Quả minh quyết (1), rong biển và các loại tảo khác, củ cải đường (sugar beet) và mía đường, tươi, ướp lạnh, đông lạnh hoặc khô, đã hoặc chưa nghiền; hạt và nhân của hạt và các sản phẩm thực vật khác (kể cả rễ rau diếp xoăn chưa rang thuộc loài Cichorium intybus satibium) chủ yếu dùng làm thức ăn cho người, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rong biển và các loại tảo khác:',
    Unit: '',
  },
  {
    HsCode: '121221',
    Description: 'Thích hợp sử dụng cho người:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đã sấy khô nhưng chưa nghiền:',
    Unit: '',
  },
  {
    HsCode: '12122111',
    Description: 'Eucheuma spinosum(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '12122112',
    Description: 'Eucheuma cottonii (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '12122113',
    Description: 'Gracilaria spp.',
    Unit: 'kg',
  },
  {
    HsCode: '12122119',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12122190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '121229',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tươi, ướp lạnh hoặc khô, dùng cho công nghệ nhuộm, thuộc da, làm nước hoa, làm dược phẩm, hoặc làm thuốc trừ sâu, thuốc diệt nấm hoặc các mục đích tương tự:',
    Unit: '',
  },
  {
    HsCode: '12122911',
    Description: 'Loại dùng làm dược phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '12122919',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12122920',
    Description: 'Loại khác, tươi, ướp lạnh hoặc khô',
    Unit: 'kg',
  },
  {
    HsCode: '12122930',
    Description: 'Loại khác, đông lạnh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12129100',
    Description: 'Củ cải đường',
    Unit: 'kg',
  },
  {
    HsCode: '12129200',
    Description: 'Quả minh quyết (carob) (1)',
    Unit: 'kg',
  },
  {
    HsCode: '121293',
    Description: 'Mía đường:',
    Unit: '',
  },
  {
    HsCode: '12129310',
    Description: 'Phù hợp để làm giống',
    Unit: 'kg',
  },
  {
    HsCode: '12129390',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12129400',
    Description: 'Rễ rau diếp xoăn',
    Unit: 'kg',
  },
  {
    HsCode: '121299',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '12129910',
    Description: 'Hạt và nhân hạt của quả mơ, đào (kể cả xuân đào) hoặc mận (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '12129990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '12130000',
    Description: 'Rơm, rạ và trấu từ cây ngũ cốc, chưa xử lý, đã hoặc chưa băm, nghiền, ép hoặc làm thành dạng viên',
    Unit: 'kg',
  },
  {
    HsCode: '1214',
    Description:
      'Cải củ Thụy Điển, cải cầu vồng (mangold), rễ cỏ khô, cỏ khô, cỏ linh lăng, cỏ ba lá, cây hồng đậu, cải xoăn, đậu lu-pin, đậu tằm và các sản phẩm tương tự dùng làm thức ăn cho gia súc, đã hoặc chưa làm thành viên',
    Unit: '',
  },
  {
    HsCode: '12141000',
    Description: 'Bột thô và viên cỏ linh lăng (alfalfa)',
    Unit: 'kg',
  },
  {
    HsCode: '12149000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 4843:2007',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 13',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nhựa cánh kiến đỏ; gôm, nhựa cây, các chất nhựa và các chất chiết xuất từ thực vật khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Không kể những đề cập khác, nhóm 13.02 áp dụng cho các chất chiết xuất từ cây cam thảo và cây kim cúc, cây hublong (hoa bia), cây lô hội và cây thuốc phiện.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nhóm này không áp dụng cho:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Chất chiết xuất từ cây cam thảo có hàm lượng trên 10% tính theo khối lượng là đường sucroza hoặc được đóng gói như kẹo (nhóm 17.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Chất chiết xuất từ malt (nhóm 19.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Chất chiết xuất từ cà phê, chè hoặc chè paragoay (nhóm 21.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các chất nhựa hoặc chất chiết xuất từ thực vật để làm đồ uống có cồn (Chương 22);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Long não (camphor), glycyrrhizin hoặc các sản phẩm khác thuộc nhóm 29.14 hoặc 29.38;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Cao thuốc phiện có chứa hàm lượng alkaloid từ 50% trở lên tính theo khối lượng (nhóm 29.39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Dược phẩm thuộc nhóm 30.03 hoặc 30.04 hoặc thuốc thử nhóm máu (nhóm 38.22); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Chất chiết xuất làm thuốc nhuộm hoặc thuộc da (nhóm 32.01 hoặc 32.03);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Tinh dầu, thể rắn, nguyên chất, chất tựa nhựa, nhựa dầu, nước cất tinh dầu hoặc dung dịch nước của tinh dầu hoặc các chế phẩm dựa trên các chất thơm sử dụng để sản xuất đồ uống (Chương 33); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(k) Cao su tự nhiên, nhựa cây balata, nhựa két, nhựa cây cúc cao su, nhựa chicle hoặc các loại nhựa tự nhiên tương tự (nhóm 40.01).',
    Unit: '',
  },
  {
    HsCode: '1301',
    Description:
      'Nhựa cánh kiến đỏ; gôm tự nhiên, nhựa cây, nhựa gôm và nhựa dầu tự nhiên (ví dụ, nhựa thơm từ cây balsam)',
    Unit: '',
  },
  {
    HsCode: '13012000',
    Description: 'Gôm Ả rập',
    Unit: 'kg',
  },
  {
    HsCode: '130190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '13019030',
    Description: 'Nhựa cây gai dầu',
    Unit: 'kg',
  },
  {
    HsCode: '13019040',
    Description: 'Nhựa cánh kiến đỏ',
    Unit: 'kg',
  },
  {
    HsCode: '13019090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1302',
    Description:
      'Nhựa và các chiết xuất từ thực vật; chất pectic, muối của axit pectinic và muối của axit pectic; thạch rau câu (agar-agar) và các chất nhầy và các chất làm đặc, làm dày khác, đã hoặc chưa cải biến, thu được từ các sản phẩm thực vật',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nhựa và các chất chiết xuất từ thực vật:',
    Unit: '',
  },
  {
    HsCode: '130211',
    Description: 'Thuốc phiện:',
    Unit: '',
  },
  {
    HsCode: '13021110',
    Description: 'Bột thuốc phiện (Pulvis opii)',
    Unit: 'kg',
  },
  {
    HsCode: '13021190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '13021200',
    Description: 'Từ cam thảo',
    Unit: 'kg',
  },
  {
    HsCode: '13021300',
    Description: 'Từ hoa bia (hublong)',
    Unit: 'kg',
  },
  {
    HsCode: '13021400',
    Description: 'Từ cây ma hoàng',
    Unit: 'kg',
  },
  {
    HsCode: '130219',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '13021920',
    Description: 'Chiết xuất và cồn thuốc của cây gai dầu',
    Unit: 'kg',
  },
  {
    HsCode: '13021940',
    Description: 'Nhựa và các chiết xuất thực vật từ hoa cúc hoặc rễ cây có chứa rotenone',
    Unit: 'kg',
  },
  {
    HsCode: '13021950',
    Description: 'Sơn mài (sơn mài tự nhiên)',
    Unit: 'kg',
  },
  {
    HsCode: '13021990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '13022000',
    Description: 'Chất pectic, muối của axit pectinic và muối của axit pectic',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Chất nhầy và chất làm đặc, làm dày, đã hoặc chưa biến đổi, thu được từ các sản phẩm thực vật:',
    Unit: '',
  },
  {
    HsCode: '13023100',
    Description: 'Thạch rau câu (agar-agar)',
    Unit: 'kg',
  },
  {
    HsCode: '13023200',
    Description:
      'Chất nhầy và chất làm đặc, làm dày, đã hoặc chưa biến đổi, thu được từ quả minh quyết (1), hạt minh quyết (1) hoặc hạt guar',
    Unit: 'kg',
  },
  {
    HsCode: '130239',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Làm từ tảo carrageen (carrageenan):',
    Unit: '',
  },
  {
    HsCode: '13023911',
    Description: 'Dạng bột, tinh chế một phần (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '13023912',
    Description: 'Dạng bột, đã tinh chế (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '13023913',
    Description: 'Carrageen dạng mảnh đã xử lý kiềm (ATCC) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '13023919',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '13023990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 4843:2007',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 14',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Vật liệu thực vật dùng để tết bện; các sản phẩm thực vật chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Chương này không bao gồm các sản phẩm sau đây đã được xếp trong Phần XI: vật liệu thực vật hoặc xơ từ nguyên liệu thực vật đã chế biến chủ yếu dùng cho ngành dệt, hoặc các vật liệu thực vật khác, đã xử lý thích hợp chỉ để dùng làm vật liệu dệt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Không kể những đề cập khác, nhóm 14.01 áp dụng cho các loại tre (đã hoặc chưa chẻ, xẻ dọc, cắt thành từng đoạn, tiện tròn đầu, tẩy trắng, chống cháy, đánh bóng hoặc nhuộm), liễu gai, sậy và các loại tương tự đã tách, lõi song mây hoặc song mây chẻ. Nhóm này không áp dụng cho nan gỗ (nhóm 44.04).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Nhóm 14.04 không áp dụng cho sợi gỗ (nhóm 44.05) và túm hoặc búi đã làm sẵn dùng để làm chổi hoặc bàn chải (nhóm 96.03).',
    Unit: '',
  },
  {
    HsCode: '1401',
    Description:
      'Nguyên liệu thực vật chủ yếu dùng để tết bện (như: tre, song, mây, sậy, liễu gai, cây bấc, cọ sợi, các loại rơm, rạ ngũ cốc đã làm sạch, tẩy trắng hoặc đã nhuộm và vỏ cây đoạn)',
    Unit: '',
  },
  {
    HsCode: '14011000',
    Description: 'Tre',
    Unit: 'kg',
  },
  {
    HsCode: '140120',
    Description: 'Song, mây:',
    Unit: '',
  },
  {
    HsCode: '14012010',
    Description: 'Nguyên cây',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Lõi cây đã tách:',
    Unit: '',
  },
  {
    HsCode: '14012021',
    Description: 'Đường kính không quá 12 mm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '14012029',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '14012030',
    Description: 'Vỏ (cật) đã tách (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '14012090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '14019000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1404',
    Description: 'Các sản phẩm từ thực vật chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '14042000',
    Description: 'Xơ của cây bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '140490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '14049020',
    Description: 'Loại dùng chủ yếu trong công nghệ thuộc da hoặc nhuộm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '14049030',
    Description: 'Bông gòn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '14049091',
    Description: 'Vỏ hạt cọ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '14049092',
    Description: 'Chùm không quả của cây cọ dầu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '14049099',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN III',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'CHẤT BÉO VÀ DẦU CÓ NGUỒN GỐC TỪ ĐỘNG VẬT, THỰC VẬT HOẶC VI SINH VẬT VÀ CÁC SẢN PHẨM TÁCH TỪ CHÚNG; CHẤT BÉO ĂN ĐƯỢC ĐÃ CHẾ BIẾN; CÁC LOẠI SÁP ĐỘNG VẬT HOẶC THỰC VẬT',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 15',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Chất béo và dầu có nguồn gốc từ động vật, thực vật hoặc vi sinh vật và các sản phẩm tách từ chúng; chất béo ăn được đã chế biến; các loại sáp động vật hoặc thực vật',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Mỡ lợn hoặc mỡ gia cầm thuộc nhóm 02.09;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Bơ ca cao, chất béo hoặc dầu cacao (nhóm 18.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các chế phẩm ăn được có chứa hàm lượng trên 15% tính theo khối lượng các sản phẩm của nhóm 04.05 (thường thuộc Chương 21);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Tóp mỡ (nhóm 23.01) hoặc phế liệu thuộc các nhóm từ 23.04 đến 23.06;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Axit béo, sáp đã chế biến, dược phẩm, sơn, vecni, xà phòng, nước hoa, mỹ phẩm hoặc chế phẩm vệ sinh, dầu đã được sulphonat hóa hoặc các mặt hàng khác thuộc Phần VI; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Chất thay thế cao su được điều chế từ dầu (nhóm 40.02).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 15.09 không áp dụng cho các loại dầu ô liu thu được bằng phương pháp tách chiết dung môi (nhóm 15.10).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Nhóm 15.18 không bao gồm chất béo hoặc dầu hoặc phân đoạn của chúng, mới chỉ làm biến chất, những loại đó được phân loại vào nhóm thích hợp tương ứng với các loại chất béo và dầu và phân đoạn của chúng chưa bị biến chất.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nguyên liệu sản xuất xà phòng, cặn dầu, hắc ín stearin, hắc ín glyxerin và phế liệu mỡ lông (wool grease) được phân loại vào nhóm 15.22.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 1509.30, dầu ô liu nguyên chất (virgin olive oil) có chỉ số axit tự do tính theo axit oleic không quá 2,0g/100g và được phân biệt với các loại dầu ô liu nguyên chất khác (other virgin olive oils) tùy thuộc vào đặc tính theo chỉ dẫn Tiêu chuẩn Codex Alimentarius 33-1981. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của các phân nhóm 1514.11 và 1514.19, khái niệm ""dầu cây cải dầu (rape oil hoặc colza oil) có hàm lượng axit eruxic thấp"" có nghĩa là dầu không bay hơi với hàm lượng axit eruxic dưới 2% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '1501',
    Description: 'Mỡ lợn (kể cả mỡ từ mỡ lá và mỡ khổ) và mỡ gia cầm, trừ các loại thuộc nhóm 02.09 hoặc 15.03',
    Unit: '',
  },
  {
    HsCode: '15011000',
    Description: 'Mỡ lợn từ mỡ lá và mỡ khổ',
    Unit: 'kg',
  },
  {
    HsCode: '15012000',
    Description: 'Mỡ lợn khác',
    Unit: 'kg',
  },
  {
    HsCode: '15019000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1502',
    Description: 'Mỡ của động vật họ trâu bò, cừu hoặc dê, trừ các loại mỡ thuộc nhóm 15.03',
    Unit: '',
  },
  {
    HsCode: '15021000',
    Description: 'Mỡ tallow',
    Unit: 'kg',
  },
  {
    HsCode: '150290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15029010',
    Description: 'Loại ăn được',
    Unit: 'kg',
  },
  {
    HsCode: '15029090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1503',
    Description:
      'Stearin mỡ lợn, dầu mỡ lợn, oleostearin, dầu oleo và dầu tallow, chưa nhũ hóa hoặc chưa pha trộn hoặc chưa chế biến cách khác',
    Unit: '',
  },
  {
    HsCode: '15030010',
    Description: 'Stearin mỡ lợn hoặc oleostearin',
    Unit: 'kg',
  },
  {
    HsCode: '15030090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1504',
    Description:
      'Mỡ và dầu và các phần phân đoạn của chúng, từ cá hoặc các loài động vật có vú sống ở biển, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '150410',
    Description: 'Dầu gan cá và các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '15041020',
    Description: 'Các phần phân đoạn thể rắn',
    Unit: 'kg',
  },
  {
    HsCode: '15041090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '150420',
    Description: 'Mỡ và dầu và các phần phân đoạn của chúng, từ cá, trừ dầu gan cá:',
    Unit: '',
  },
  {
    HsCode: '15042010',
    Description: 'Các phần phân đoạn thể rắn',
    Unit: 'kg',
  },
  {
    HsCode: '15042090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '15043000',
    Description: 'Mỡ và dầu và các phần phân đoạn của chúng, từ động vật có vú sống ở biển',
    Unit: 'kg',
  },
  {
    HsCode: '1505',
    Description: 'Mỡ lông và chất béo thu được từ mỡ lông (kể cả lanolin)',
    Unit: '',
  },
  {
    HsCode: '15050010',
    Description: 'Lanolin',
    Unit: 'kg',
  },
  {
    HsCode: '15050090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '15060000',
    Description:
      'Mỡ và dầu động vật khác và các phần phân đoạn của chúng, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '1507',
    Description:
      'Dầu đậu tương và các phần phân đoạn của dầu đậu tương, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '15071000',
    Description: 'Dầu thô, đã hoặc chưa khử chất nhựa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '150790',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15079010',
    Description: 'Các phần phân đoạn của dầu đậu tương chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15079020',
    Description: 'Dầu đậu tương đã tinh chế, tẩy và khử mùi (RBD)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15079090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1508',
    Description:
      'Dầu lạc và các phần phân đoạn của dầu lạc, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '15081000',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15089000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1509',
    Description:
      'Dầu ô liu và các phần phân đoạn của dầu ô liu, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '150920',
    Description: 'Dầu ô liu nguyên chất đặc biệt (Extra virgin olive oil) (1):',
    Unit: '',
  },
  {
    HsCode: '15092010',
    Description: 'Đóng gói với khối lượng tịnh không quá 30 kg',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15092090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15093000',
    Description: 'Dầu ô liu nguyên chất (Virgin olive oil) (1)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15094000',
    Description: 'Dầu ô liu nguyên chất khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '150990',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế:',
    Unit: '',
  },
  {
    HsCode: '15099011',
    Description: 'Đóng gói với khối lượng tịnh không quá 30 kg',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15099019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15099091',
    Description: 'Đóng gói với khối lượng tịnh không quá 30 kg',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15099099',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1510',
    Description:
      'Dầu khác và các phần phân đoạn của chúng, thu được duy nhất từ ô liu, đã hoặc chưa tinh chế, nhưng chưa thay đổi về mặt hóa học, kể cả hỗn hợp của các loại dầu này hoặc các phần phân đoạn của các loại dầu này với dầu hoặc các phần phân đoạn của dầu thuộc nhóm 15.09',
    Unit: '',
  },
  {
    HsCode: '15101000',
    Description: 'Dầu bã ô liu (olive pomace oil) (1) thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151090',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15109010',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15109020',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15109090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1511',
    Description:
      'Dầu cọ và các phần phân đoạn của dầu cọ, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học. (SEN)',
    Unit: '',
  },
  {
    HsCode: '15111000',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15119020',
    Description: 'Dầu tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các phần phân đoạn của dầu tinh chế:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các phần phân đoạn thể rắn:',
    Unit: '',
  },
  {
    HsCode: '15119031',
    Description: 'Có chỉ số iốt từ 30 trở lên, nhưng dưới 40',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15119032',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các phần phân đoạn thể lỏng:',
    Unit: '',
  },
  {
    HsCode: '15119036',
    Description: 'Đóng gói với khối lượng tịnh không quá 25kg',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15119037',
    Description: 'Loại khác, có chỉ số iốt từ 55 trở lên, nhưng dưới 60',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15119039',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế:',
    Unit: '',
  },
  {
    HsCode: '15119041',
    Description: 'Các phần phân đoạn thể rắn (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15119042',
    Description: 'Loại khác, đóng gói với khối lượng tịnh không quá 25kg (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15119049',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1512',
    Description:
      'Dầu hạt hướng dương, dầu cây rum hoặc dầu hạt bông và các phần phân đoạn của chúng, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dầu hạt hướng dương hoặc dầu cây rum và các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '15121100',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151219',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15121910',
    Description: 'Các phần phân đoạn của dầu hướng dương hoặc dầu cây rum chưa tinh chế ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15121920',
    Description: 'Đã tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15121990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dầu hạt bông và các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '15122100',
    Description: 'Dầu thô, đã hoặc chưa khử gossypol ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151229',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15122910',
    Description: 'Các phần phân đoạn của dầu hạt bông chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15122990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1513',
    Description:
      'Dầu dừa (copra), dầu hạt cọ hoặc dầu cọ ba-ba-su và các phần phân đoạn của chúng, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dầu dừa (copra) và các phân đoạn của dầu dừa:',
    Unit: '',
  },
  {
    HsCode: '151311',
    Description: 'Dầu thô:',
    Unit: '',
  },
  {
    HsCode: '15131110',
    Description: 'Dầu dừa nguyên chất (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15131190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151319',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15131910',
    Description: 'Các phần phân đoạn của dầu dừa chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15131990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dầu hạt cọ hoặc dầu cọ ba-ba-su và các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '151321',
    Description: 'Dầu thô:',
    Unit: '',
  },
  {
    HsCode: '15132110',
    Description: 'Dầu hạt cọ (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151329',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các phần phân đoạn của dầu hạt cọ hoặc dầu cọ ba-ba-su chưa tinh chế:',
    Unit: '',
  },
  {
    HsCode: '15132911',
    Description: 'Các phần phân đoạn thể rắn của dầu hạt cọ chưa tinh chế (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132912',
    Description: 'Các phần phân đoạn thể rắn của dầu cọ ba-ba-su chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132913',
    Description: 'Loại khác, của dầu hạt cọ chưa tinh chế (olein hạt cọ) (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132914',
    Description: 'Loại khác, của dầu cọ ba-ba-su chưa tinh chế ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15132991',
    Description: 'Các phần phân đoạn thể rắn của dầu hạt cọ (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132992',
    Description: 'Các phần phân đoạn thể rắn của dầu cọ ba-ba-su',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132994',
    Description: 'Olein hạt cọ, đã tinh chế, tẩy và khử mùi (RBD)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132995',
    Description: 'Dầu hạt cọ, đã tinh chế, tẩy và khử mùi (RBD) (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132996',
    Description: 'Loại khác, của dầu hạt cọ (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15132997',
    Description: 'Loại khác, của dầu cọ ba-ba-su',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1514',
    Description:
      'Dầu cây cải dầu (rape oil hoặc colza oil) hoặc dầu mù tạt và các phần phân đoạn của chúng, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dầu cây cải dầu hàm lượng axit eruxic thấp và các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '15141100',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151419',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15141910',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15141920',
    Description: 'Đã tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15141990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15149100',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151499',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15149910',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15149990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1515',
    Description:
      'Chất béo và dầu không bay hơi khác của thực vật hoặc vi sinh vật (kể cả dầu jojoba) và các phần phân đoạn của chúng, đã hoặc chưa tinh chế, nhưng không thay đổi về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dầu hạt lanh và các phần phân đoạn của dầu hạt lanh:',
    Unit: '',
  },
  {
    HsCode: '15151100',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15151900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dầu hạt ngô và các phần phân đoạn của dầu hạt ngô:',
    Unit: '',
  },
  {
    HsCode: '15152100',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151529',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế:',
    Unit: '',
  },
  {
    HsCode: '15152911',
    Description: 'Các phần phân đoạn thể rắn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15152919',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15152991',
    Description: 'Các phần phân đoạn thể rắn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15152999',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151530',
    Description: 'Dầu thầu dầu và các phần phân đoạn của dầu thầu dầu:',
    Unit: '',
  },
  {
    HsCode: '15153010',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15153090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151550',
    Description: 'Dầu hạt vừng và các phần phân đoạn của dầu hạt vừng:',
    Unit: '',
  },
  {
    HsCode: '15155010',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15155020',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15155090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15156000',
    Description: 'Chất béo và dầu vi sinh vật và các phần phân đoạn của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dầu hạt illipe:',
    Unit: '',
  },
  {
    HsCode: '15159011',
    Description: 'Dầu thô (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159012',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159019',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dầu Tung:',
    Unit: '',
  },
  {
    HsCode: '15159021',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159022',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159029',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dầu Jojoba:',
    Unit: '',
  },
  {
    HsCode: '15159031',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159032',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159039',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15159091',
    Description: 'Dầu thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159092',
    Description: 'Các phần phân đoạn của dầu chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15159099',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1516',
    Description:
      'Chất béo và dầu động vật, thực vật hoặc vi sinh vật và các phần phân đoạn của chúng, đã qua hydro hóa, este hóa liên hợp, tái este hóa hoặc elaiđin hóa toàn bộ hoặc một phần, đã hoặc chưa tinh chế, nhưng chưa chế biến thêm',
    Unit: '',
  },
  {
    HsCode: '151610',
    Description: 'Mỡ và dầu động vật và các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '15161020',
    Description: 'Đã tái este hóa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15161090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151620',
    Description: 'Chất béo và dầu thực vật và các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đã tái este hóa, trừ của cọ dầu:',
    Unit: '',
  },
  {
    HsCode: '15162011',
    Description: 'Của đậu tương',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162012',
    Description: 'Của ngô (maize)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162014',
    Description: 'Của dừa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162017',
    Description: 'Của lạc',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162018',
    Description: 'Của hạt lanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Đã tái este hóa, của cọ dầu:',
    Unit: '',
  },
  {
    HsCode: '15162021',
    Description: 'Của quả cọ dầu, dạng thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162022',
    Description: 'Của quả cọ dầu, trừ dạng thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162023',
    Description: 'Của hạt cọ, dạng thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162024',
    Description: 'Của hạt cọ, đã tinh chế, tẩy và khử mùi (RBD)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162025',
    Description: 'Của olein hạt cọ, dạng thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162026',
    Description: 'Của olein hạt cọ, đã tinh chế, tẩy và khử mùi (RBD) ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162029',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Đã qua hydro hóa, dạng vảy, mảnh:',
    Unit: '',
  },
  {
    HsCode: '15162031',
    Description: 'Của lạc; của dừa; của đậu tương',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162032',
    Description: 'Của hạt lanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162033',
    Description: 'Của ô liu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162034',
    Description: 'Của quả cọ dầu (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162035',
    Description: 'Của hạt cọ (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162039',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Đã qua hydro hóa, dạng khác:',
    Unit: '',
  },
  {
    HsCode: '15162041',
    Description: 'Của hạt thầu dầu (sáp opal)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162042',
    Description: 'Của dừa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162043',
    Description: 'Của lạc',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162044',
    Description: 'Của hạt lanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162045',
    Description: 'Của ô liu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162046',
    Description: 'Của quả cọ dầu (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162047',
    Description: 'Của hạt cọ (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162048',
    Description: 'Của đậu tương',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162049',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Đã este hóa liên hợp:',
    Unit: '',
  },
  {
    HsCode: '15162051',
    Description: 'Của hạt lanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162052',
    Description: 'Của ô liu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162053',
    Description: 'Của đậu tương',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162054',
    Description: 'Của lạc, cọ dầu hoặc dừa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162059',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Elaiđin hóa, stearin cọ, có chỉ số iốt không quá 48:',
    Unit: '',
  },
  {
    HsCode: '15162061',
    Description: 'Dạng thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162062',
    Description: 'Đã tinh chế, tẩy và khử mùi (RBD)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162069',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Elaiđin hóa, loại khác:',
    Unit: '',
  },
  {
    HsCode: '15162091',
    Description: 'Stearin cọ, có chỉ số iốt trên 48',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162092',
    Description: 'Của hạt lanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162093',
    Description: 'Của ô liu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162094',
    Description: 'Của đậu tương',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162096',
    Description: 'Stearin hạt cọ đã tinh chế, tẩy và khử mùi (RBD)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162098',
    Description: 'Của lạc, của cọ dầu hoặc của dừa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15162099',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15163000',
    Description: 'Chất béo và dầu vi sinh vật và các phần phân đoạn của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1517',
    Description:
      'Margarin; các hỗn hợp hoặc các chế phẩm ăn được của chất béo hoặc dầu động vật, thực vật hoặc vi sinh vật hoặc các phần phân đoạn của các loại chất béo hoặc dầu khác nhau thuộc Chương này, trừ chất béo và dầu hoặc các phần phân đoạn của chúng ăn được thuộc nhóm 15.16',
    Unit: '',
  },
  {
    HsCode: '151710',
    Description: 'Margarin, trừ loại margarin lỏng:',
    Unit: '',
  },
  {
    HsCode: '15171010',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15171090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '151790',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15179010',
    Description: 'Chế phẩm giả ghee',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179020',
    Description: 'Margarin lỏng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179030',
    Description: 'Của loại sử dụng như chế phẩm tách khuôn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Chế phẩm giả mỡ lợn; shortening:',
    Unit: '',
  },
  {
    HsCode: '15179043',
    Description: 'Shortening',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179044',
    Description: 'Chế phẩm giả mỡ lợn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179050',
    Description:
      'Hỗn hợp hoặc chế phẩm ở dạng rắn khác của chất béo hoặc dầu thực vật hoặc của các phần phân đoạn của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Hỗn hợp hoặc chế phẩm ở dạng lỏng khác của chất béo hoặc dầu thực vật hoặc của các phần phân đoạn của chúng:',
    Unit: '',
  },
  {
    HsCode: '15179061',
    Description: 'Thành phần chủ yếu là dầu lạc',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179062',
    Description: 'Thành phần chủ yếu là dầu cọ thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179063',
    Description: 'Thành phần chủ yếu là dầu cọ khác, đóng gói với khối lượng tịnh không quá 25kg',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179064',
    Description: 'Thành phần chủ yếu là dầu cọ khác, đóng gói với khối lượng tịnh trên 25kg',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179065',
    Description: 'Thành phần chủ yếu là dầu hạt cọ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179066',
    Description: 'Thành phần chủ yếu là olein hạt cọ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179067',
    Description: 'Thành phần chủ yếu là dầu đậu tương hoặc dầu dừa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179068',
    Description: 'Thành phần chủ yếu là dầu hạt illipe',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179069',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179080',
    Description: 'Của hỗn hợp hoặc chế phẩm từ mỡ hoặc dầu động vật hoặc từ các phần phân đoạn của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15179090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1518',
    Description:
      'Chất béo và dầu động vật, thực vật hoặc vi sinh vật và các phần phân đoạn của chúng, đã đun sôi, oxy hóa, khử nước, sulphua hóa, thổi khô, polyme hóa bằng cách đun nóng trong chân không hoặc trong khí trơ hoặc bằng biện pháp thay đổi về mặt hóa học khác, trừ loại thuộc nhóm 15.16; các hỗn hợp hoặc các chế phẩm không ăn được từ chất béo hoặc dầu động vật, thực vật hoặc vi sinh vật hoặc từ các phần phân đoạn của các loại chất béo hoặc dầu khác nhau thuộc Chương này, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Chất béo và dầu động vật, thực vật hoặc vi sinh vật và các phần phân đoạn của chúng, đã đun sôi, oxy hóa, khử nước, sulphua hóa, thổi khô, polyme hóa bằng cách đun nóng trong chân không hoặc trong khí trơ hoặc bằng biện pháp thay đổi về mặt hóa học khác trừ loại thuộc nhóm 15.16: ',
    Unit: '',
  },
  {
    HsCode: '15180012',
    Description: 'Mỡ và dầu động vật',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180014',
    Description: 'Dầu lạc, dầu đậu tương, dầu cọ hoặc dầu dừa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180015',
    Description: 'Dầu hạt lanh và các phần phân đoạn của dầu hạt lanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180016',
    Description: 'Dầu ô liu và các phần phân đoạn của dầu ô liu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180020',
    Description:
      'Các hỗn hợp hoặc các chế phẩm không ăn được từ mỡ hoặc dầu động vật hoặc từ các phần phân đoạn của các loại mỡ hoặc dầu động vật khác nhau',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Các hỗn hợp hoặc các chế phẩm không ăn được từ chất béo hoặc dầu thực vật hoặc từ các phần phân đoạn của các loại chất béo hoặc dầu thực vật khác nhau:',
    Unit: '',
  },
  {
    HsCode: '15180032',
    Description:
      'Của dầu cọ hoặc olein hạt cọ, đã trung hòa hóa, tẩy và khử mùi (NBD) hoặc đã tinh chế, tẩy và khử mùi (RBD) ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180033',
    Description: 'Của hạt lanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180034',
    Description: 'Của ô liu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180035',
    Description: 'Của lạc',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180036',
    Description: 'Của đậu tương hoặc dừa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180037',
    Description: 'Của hạt bông',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180038',
    Description: 'Của quả cọ dầu hoặc của hạt cọ, loại khác ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180039',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180060',
    Description:
      'Các hỗn hợp hoặc các chế phẩm không ăn được từ mỡ hoặc dầu động vật hoặc từ các phần phân đoạn của chúng và chất béo hoặc dầu thực vật hoặc các phần phân đoạn của chúng ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15180090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1520',
    Description: 'Glyxerin, thô; nước glyxerin và dung dịch kiềm glyxerin.',
    Unit: '',
  },
  {
    HsCode: '15200010',
    Description: 'Glyxerin thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15200090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '1521',
    Description:
      'Sáp thực vật (trừ triglyxerit), sáp ong, sáp côn trùng khác và sáp cá nhà táng, đã hoặc chưa tinh chế hoặc pha màu',
    Unit: '',
  },
  {
    HsCode: '15211000',
    Description: 'Sáp thực vật',
    Unit: 'kg',
  },
  {
    HsCode: '152190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '15219010',
    Description: 'Sáp ong và sáp côn trùng khác',
    Unit: 'kg',
  },
  {
    HsCode: '15219020',
    Description: 'Sáp cá nhà táng',
    Unit: 'kg',
  },
  {
    HsCode: '1522',
    Description: 'Chất nhờn; bã, cặn còn lại sau quá trình xử lý các chất béo hoặc sáp động vật hoặc thực vật',
    Unit: '',
  },
  {
    HsCode: '15220010',
    Description: 'Chất nhờn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '15220090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 6312:2020 và TCVN 7597:2018',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN IV',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'THỰC PHẨM ĐÃ CHẾ BIẾN; ĐỒ UỐNG, RƯỢU MẠNH VÀ GIẤM; THUỐC LÁ VÀ NGUYÊN LIỆU THAY THẾ LÁ THUỐC LÁ ĐÃ CHẾ BIẾN; CÁC SẢN PHẨM CHỨA HOẶC KHÔNG CHỨA NICOTIN, DÙNG ĐỂ HÚT MÀ KHÔNG CẦN ĐỐT CHÁY; CÁC SẢN PHẨM CHỨA NICOTIN KHÁC DÙNG ĐỂ NẠP NICOTIN VÀO CƠ THỂ CON NGƯỜI ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong Phần này khái niệm ""viên"" chỉ các sản phẩm được liên kết bằng phương pháp ép trực tiếp hoặc bằng cách pha thêm chất kết dính theo tỷ lệ không quá 3% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 16',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các chế phẩm từ thịt, cá, động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, hoặc từ côn trùng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Chương này không bao gồm các loại thịt, các phụ phẩm dạng thịt sau giết mổ, cá, động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, cũng như côn trùng, đã chế biến hoặc bảo quản theo các quy trình đã ghi trong Chương 2 hoặc Chương 3, Chú giải 6 Chương 4 hoặc nhóm 05.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Chế phẩm thực phẩm được xếp vào Chương này với điều kiện các chế phẩm này chứa trên 20% tính theo khối lượng là xúc xích, thịt, các phụ phẩm dạng thịt sau giết mổ, tiết, côn trùng, cá hoặc động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, hoặc hỗn hợp bất kỳ của các loại trên. Nếu chế phẩm có chứa hai hoặc nhiều thành phần trên, thì xếp vào nhóm tương ứng với thành phần hoặc những thành phần chiếm trọng lượng lớn hơn trong chế phẩm đó thuộc Chương 16. Những quy định này không áp dụng với các sản phẩm nhồi thuộc nhóm 19.02 hoặc các chế phẩm thuộc nhóm 21.03 hoặc 21.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 1602.10, khái niệm ""chế phẩm đồng nhất"" được hiểu là chế phẩm từ thịt, các phụ phẩm dạng thịt sau giết mổ, tiết hoặc côn trùng, được làm đồng nhất mịn, phù hợp làm thực phẩm cho trẻ sơ sinh hoặc trẻ nhỏ hoặc để ăn kiêng, đóng gói để bán lẻ với khối lượng tịnh không quá 250g. Khi áp dụng định nghĩa này không cần quan tâm đến những thành phần có hàm lượng nhỏ thêm vào làm gia vị, để bảo quản hoặc các mục đích khác. Các chế phẩm này có thể chứa một lượng nhỏ mảnh vụn thịt hoặc phụ phẩm dạng thịt sau giết mổ hoặc côn trùng có thể nhìn thấy được. Phân nhóm này được ưu tiên xem xét trước trong các phân nhóm của nhóm 16.02.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Cá, động vật giáp xác, động vật thân mềm và động vật thủy sinh không xương sống khác được ghi theo tên thông thường trong các phân nhóm của nhóm 16.04 hoặc 16.05, là các loài cùng tên tương ứng được chi tiết ở Chương 3.',
    Unit: '',
  },
  {
    HsCode: '1601',
    Description:
      'Xúc xích và các sản phẩm tương tự làm từ thịt, từ phụ phẩm dạng thịt sau giết mổ, tiết hoặc côn trùng; các chế phẩm thực phẩm từ các sản phẩm đó',
    Unit: '',
  },
  {
    HsCode: '16010010',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16010090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1602',
    Description: 'Thịt, các phụ phẩm dạng thịt sau giết mổ, tiết hoặc côn trùng, đã chế biến hoặc bảo quản khác',
    Unit: '',
  },
  {
    HsCode: '160210',
    Description: 'Chế phẩm đồng nhất:',
    Unit: '',
  },
  {
    HsCode: '16021010',
    Description: 'Chứa thịt lợn, đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16021090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '16022000',
    Description: 'Từ gan động vật',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Từ gia cầm thuộc nhóm 01.05:',
    Unit: '',
  },
  {
    HsCode: '160231',
    Description: 'Từ gà tây:',
    Unit: '',
  },
  {
    HsCode: '16023110',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16023191',
    Description: 'Từ thịt đã được lọc hoặc tách khỏi xương bằng phương pháp cơ học (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '16023199',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160232',
    Description: 'Từ gà thuộc loài Gallus domesticus:',
    Unit: '',
  },
  {
    HsCode: '16023210',
    Description: 'Ca-ri gà, đóng bao bì kín khí để bán lẻ ',
    Unit: 'kg',
  },
  {
    HsCode: '16023290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '16023900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Từ lợn:',
    Unit: '',
  },
  {
    HsCode: '160241',
    Description: 'Thịt mông đùi (ham) và các mảnh của chúng:',
    Unit: '',
  },
  {
    HsCode: '16024110',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16024190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160242',
    Description: 'Thịt vai nguyên miếng và các mảnh của chúng:',
    Unit: '',
  },
  {
    HsCode: '16024210',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16024290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160249',
    Description: 'Loại khác, kể cả các hỗn hợp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thịt nguội:',
    Unit: '',
  },
  {
    HsCode: '16024911',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16024919',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16024991',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16024999',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160250',
    Description: 'Từ động vật họ trâu bò:',
    Unit: '',
  },
  {
    HsCode: '16025010',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16025090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160290',
    Description: 'Loại khác, kể cả sản phẩm chế biến từ tiết động vật:',
    Unit: '',
  },
  {
    HsCode: '16029010',
    Description: 'Ca-ri cừu, đóng bao bì kín khí để bán lẻ ',
    Unit: 'kg',
  },
  {
    HsCode: '16029020',
    Description: 'Các chế phẩm từ tiết',
    Unit: 'kg',
  },
  {
    HsCode: '16029090',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '1603',
    Description:
      'Sản phẩm chiết xuất và nước ép từ thịt, cá hoặc từ động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác',
    Unit: '',
  },
  {
    HsCode: '16030010',
    Description: 'Từ thịt',
    Unit: 'kg',
  },
  {
    HsCode: '16030090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1604',
    Description:
      'Cá đã được chế biến hoặc bảo quản; trứng cá tầm muối và sản phẩm thay thế trứng cá tầm muối chế biến từ trứng cá',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cá, nguyên con hoặc dạng miếng, nhưng chưa cắt nhỏ:',
    Unit: '',
  },
  {
    HsCode: '160411',
    Description: 'Từ cá hồi:',
    Unit: '',
  },
  {
    HsCode: '16041110',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160412',
    Description: 'Từ cá trích nước lạnh:',
    Unit: '',
  },
  {
    HsCode: '16041210',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160413',
    Description: 'Từ cá trích dầu, cá trích xương và cá trích kê hoặc cá trích cơm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Từ cá trích dầu:',
    Unit: '',
  },
  {
    HsCode: '16041311',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041319',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16041391',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041399',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160414',
    Description: 'Từ cá ngừ đại dương, cá ngừ vằn và cá ngừ ba chấm (Sarda spp.):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '16041411',
    Description: 'Từ cá ngừ đại dương',
    Unit: 'kg',
  },
  {
    HsCode: '16041419',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16041491',
    Description: 'Cá ngừ đã làm chín sơ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '16041499',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160415',
    Description: 'Từ cá nục hoa:',
    Unit: '',
  },
  {
    HsCode: '16041510',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041590',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160416',
    Description: 'Từ cá cơm (cá trỏng):',
    Unit: '',
  },
  {
    HsCode: '16041610',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041690',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160417',
    Description: 'Cá chình:',
    Unit: '',
  },
  {
    HsCode: '16041710',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041790',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160418',
    Description: 'Vây cá mập:',
    Unit: '',
  },
  {
    HsCode: '16041810',
    Description: 'Đã chế biến để sử dụng ngay',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16041891',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041899',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160419',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16041920',
    Description: 'Cá sòng (horse mackerel), đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041930',
    Description: 'Loại khác, đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16041990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '160420',
    Description: 'Cá đã được chế biến hoặc bảo quản cách khác:',
    Unit: '',
  },
  {
    HsCode: '16042020',
    Description: 'Xúc xích cá',
    Unit: 'kg',
  },
  {
    HsCode: '16042030',
    Description: 'Cá viên',
    Unit: 'kg',
  },
  {
    HsCode: '16042040',
    Description: 'Cá dạng bột nhão',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16042091',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16042099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Trứng cá tầm muối và sản phẩm thay thế trứng cá tầm muối:',
    Unit: '',
  },
  {
    HsCode: '16043100',
    Description: 'Trứng cá tầm muối',
    Unit: 'kg',
  },
  {
    HsCode: '16043200',
    Description: 'Sản phẩm thay thế trứng cá tầm muối',
    Unit: 'kg',
  },
  {
    HsCode: '1605',
    Description:
      'Động vật giáp xác, động vật thân mềm và động vật thủy sinh không xương sống khác, đã được chế biến hoặc bảo quản',
    Unit: '',
  },
  {
    HsCode: '160510',
    Description: 'Cua, ghẹ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '16051011',
    Description: 'Ghẹ (thuộc họ Portunidae)',
    Unit: 'kg',
  },
  {
    HsCode: '16051012',
    Description: 'Cua hoàng đế/cua vua Alaska (cua thuộc họ Lithodidae)',
    Unit: 'kg',
  },
  {
    HsCode: '16051013',
    Description: 'Cua tuyết (cua thuộc họ Oregoniidae)',
    Unit: 'kg',
  },
  {
    HsCode: '16051014',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '16051090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Tôm shrimp và tôm prawn:',
    Unit: '',
  },
  {
    HsCode: '16052100',
    Description: 'Không đóng bao bì kín khí ',
    Unit: 'kg',
  },
  {
    HsCode: '160529',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '16052920',
    Description: 'Tôm dạng viên',
    Unit: 'kg',
  },
  {
    HsCode: '16052930',
    Description: 'Tôm tẩm bột',
    Unit: 'kg',
  },
  {
    HsCode: '16052990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '16053000',
    Description: 'Tôm hùm',
    Unit: 'kg',
  },
  {
    HsCode: '16054000',
    Description: 'Động vật giáp xác khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Động vật thân mềm:',
    Unit: '',
  },
  {
    HsCode: '16055100',
    Description: 'Hàu',
    Unit: 'kg',
  },
  {
    HsCode: '16055200',
    Description: 'Điệp, kể cả điệp nữ hoàng',
    Unit: 'kg',
  },
  {
    HsCode: '16055300',
    Description: 'Vẹm (Mussels)',
    Unit: 'kg',
  },
  {
    HsCode: '160554',
    Description: 'Mực nang và mực ống:',
    Unit: '',
  },
  {
    HsCode: '16055410',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16055490',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '16055500',
    Description: 'Bạch tuộc',
    Unit: 'kg',
  },
  {
    HsCode: '16055600',
    Description: 'Nghêu (ngao), sò',
    Unit: 'kg',
  },
  {
    HsCode: '160557',
    Description: 'Bào ngư:',
    Unit: '',
  },
  {
    HsCode: '16055710',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '16055790',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '16055800',
    Description: 'Ốc, trừ ốc biển',
    Unit: 'kg',
  },
  {
    HsCode: '16055900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Động vật thủy sinh không xương sống khác:',
    Unit: '',
  },
  {
    HsCode: '16056100',
    Description: 'Hải sâm',
    Unit: 'kg',
  },
  {
    HsCode: '16056200',
    Description: 'Cầu gai',
    Unit: 'kg',
  },
  {
    HsCode: '16056300',
    Description: 'Sứa',
    Unit: 'kg',
  },
  {
    HsCode: '16056900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 17',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đường và các loại kẹo đường',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các loại kẹo đường chứa ca cao (nhóm 18.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các loại đường tinh khiết về mặt hóa học (trừ sucroza, lactoza, maltoza, glucoza và fructoza) hoặc các sản phẩm khác của nhóm 29.40; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Thuốc hoặc các sản phẩm khác của Chương 30. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của các phân nhóm 1701.12, 1701.13 và 1701.14, khái niệm ""đường thô"" có nghĩa là các loại đường có hàm lượng sucroza tính theo khối lượng, ở thể khô, tương ứng với kết quả dưới 99,5o trên phân cực kế.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Phân nhóm 1701.13 chỉ bao gồm đường mía, thu được không qua quá trình ly tâm, trong đó hàm lượng đường sucroza tính theo khối lượng, ở thể khô, tương ứng với kết quả trên phân cực kế từ 69o đến dưới 93o. Sản phẩm này chỉ chứa các vi tinh thể anhedral tự nhiên, có hình dạng không đều, không thể quan sát được bằng mắt thường, được bao phủ bằng phế liệu từ mật mía và các thành phần khác của cây mía đường.',
    Unit: '',
  },
  {
    HsCode: '1701',
    Description: 'Đường mía hoặc đường củ cải và đường sucroza tinh khiết về mặt hóa học, ở thể rắn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đường thô chưa pha thêm hương liệu hoặc chất màu:',
    Unit: '',
  },
  {
    HsCode: '17011200',
    Description: 'Đường củ cải',
    Unit: 'kg',
  },
  {
    HsCode: '17011300',
    Description: 'Đường mía đã nêu trong Chú giải phân nhóm 2 của Chương này',
    Unit: 'kg',
  },
  {
    HsCode: '17011400',
    Description: 'Các loại đường mía khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '17019100',
    Description: 'Đã pha thêm hương liệu hoặc chất màu',
    Unit: 'kg',
  },
  {
    HsCode: '170199',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '17019910',
    Description: 'Đường đã tinh luyện',
    Unit: 'kg',
  },
  {
    HsCode: '17019990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1702',
    Description:
      'Đường khác, kể cả đường lactoza, mantoza, glucoza và fructoza, tinh khiết về mặt hóa học, ở thể rắn; xirô đường chưa pha thêm hương liệu hoặc chất màu; mật ong nhân tạo, đã hoặc chưa pha trộn với mật ong tự nhiên; đường caramen',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lactoza và xirô lactoza:',
    Unit: '',
  },
  {
    HsCode: '17021100',
    Description: 'Có hàm lượng lactoza khan từ 99% trở lên, tính theo khối lượng chất khô',
    Unit: 'kg',
  },
  {
    HsCode: '17021900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '17022000',
    Description: 'Đường từ cây thích (maple) và xirô từ cây thích',
    Unit: 'kg',
  },
  {
    HsCode: '170230',
    Description:
      'Glucoza và xirô glucoza, không chứa hoặc có chứa hàm lượng fructoza dưới 20% tính theo khối lượng ở thể khô:',
    Unit: '',
  },
  {
    HsCode: '17023010',
    Description: 'Glucoza',
    Unit: 'kg',
  },
  {
    HsCode: '17023020',
    Description: 'Xirô glucoza',
    Unit: 'kg',
  },
  {
    HsCode: '17024000',
    Description:
      'Glucoza và xirô glucoza, chứa hàm lượng fructoza ít nhất là 20% nhưng dưới 50% tính theo khối lượng ở thể khô, trừ đường nghịch chuyển',
    Unit: 'kg',
  },
  {
    HsCode: '17025000',
    Description: 'Fructoza tinh khiết về mặt hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '170260',
    Description:
      'Fructoza và xirô fructoza khác, chứa hàm lượng fructoza trên 50% tính theo khối lượng ở thể khô, trừ đường nghịch chuyển:',
    Unit: '',
  },
  {
    HsCode: '17026010',
    Description: 'Fructoza',
    Unit: 'kg',
  },
  {
    HsCode: '17026020',
    Description: 'Xirô fructoza',
    Unit: 'kg',
  },
  {
    HsCode: '170290',
    Description:
      'Loại khác, kể cả đường nghịch chuyển và đường khác và hỗn hợp xirô đường có chứa hàm lượng fructoza là 50% tính theo khối lượng ở thể khô:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Mantoza và xirô mantoza:',
    Unit: '',
  },
  {
    HsCode: '17029011',
    Description: 'Mantoza tinh khiết về mặt hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '17029019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '17029020',
    Description: 'Mật ong nhân tạo, đã hoặc chưa pha trộn với mật ong tự nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '17029030',
    Description: 'Đường đã pha hương liệu hoặc chất màu (trừ mantoza)',
    Unit: 'kg',
  },
  {
    HsCode: '17029040',
    Description: 'Đường caramen',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Đường từ cây họ cọ (Palm sugar):',
    Unit: '',
  },
  {
    HsCode: '17029051',
    Description: 'Đường sáp dừa (Coconut sap sugar) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '17029059',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '17029091',
    Description: 'Xi rô đường',
    Unit: 'kg',
  },
  {
    HsCode: '17029099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1703',
    Description: 'Mật thu được từ chiết xuất hoặc tinh chế đường',
    Unit: '',
  },
  {
    HsCode: '170310',
    Description: 'Mật mía:',
    Unit: '',
  },
  {
    HsCode: '17031010',
    Description: 'Đã pha thêm hương liệu hoặc chất màu',
    Unit: 'kg',
  },
  {
    HsCode: '17031090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '170390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '17039010',
    Description: 'Đã pha thêm hương liệu hoặc chất màu',
    Unit: 'kg',
  },
  {
    HsCode: '17039090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1704',
    Description: 'Các loại kẹo đường (kể cả sô cô la trắng), không chứa ca cao',
    Unit: '',
  },
  {
    HsCode: '17041000',
    Description: 'Kẹo cao su, đã hoặc chưa bọc đường',
    Unit: 'kg',
  },
  {
    HsCode: '170490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '17049010',
    Description: 'Kẹo và viên ngậm ho ',
    Unit: 'kg',
  },
  {
    HsCode: '17049020',
    Description: 'Sô cô la trắng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '17049091',
    Description: 'Dẻo, có chứa gelatin (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '17049099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 18',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Ca cao và các chế phẩm từ ca cao',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Chế phẩm thực phẩm có hàm lượng trên 20% tính theo khối lượng là xúc xích, thịt, các phụ phẩm dạng thịt sau giết mổ, tiết, côn trùng, cá hoặc động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, hoặc hỗn hợp bất kỳ của các loại trên (Chương 16);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các chế phẩm thuộc các nhóm 04.03, 19.01, 19.02, 19.04, 19.05, 21.05, 22.02, 22.08, 30.03 hoặc 30.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 18.06 bao gồm các loại kẹo đường có chứa ca cao và các loại chế phẩm thực phẩm khác chứa ca cao, trừ các chế phẩm thuộc các nhóm đã ghi trong Chú giải 1 Chương này.',
    Unit: '',
  },
  {
    HsCode: '1801',
    Description: 'Hạt ca cao, đã hoặc chưa vỡ mảnh, sống hoặc đã rang',
    Unit: '',
  },
  {
    HsCode: '18010010',
    Description: 'Đã lên men (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '18010090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '18020000',
    Description: 'Vỏ quả, vỏ hạt, vỏ lụa và phế liệu ca cao khác',
    Unit: 'kg',
  },
  {
    HsCode: '1803',
    Description: 'Bột ca cao nhão, đã hoặc chưa khử chất béo',
    Unit: '',
  },
  {
    HsCode: '18031000',
    Description: 'Chưa khử chất béo',
    Unit: 'kg',
  },
  {
    HsCode: '18032000',
    Description: 'Đã khử một phần hoặc toàn bộ chất béo',
    Unit: 'kg',
  },
  {
    HsCode: '18040000',
    Description: 'Bơ ca cao, chất béo và dầu ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '18050000',
    Description: 'Bột ca cao, chưa pha thêm đường hoặc chất tạo ngọt khác',
    Unit: 'kg',
  },
  {
    HsCode: '1806',
    Description: 'Sô cô la và các chế phẩm thực phẩm khác có chứa ca cao',
    Unit: '',
  },
  {
    HsCode: '18061000',
    Description: 'Bột ca cao, đã pha thêm đường hoặc chất tạo ngọt khác',
    Unit: 'kg',
  },
  {
    HsCode: '180620',
    Description:
      'Chế phẩm khác ở dạng khối, miếng hoặc thanh có khối lượng trên 2 kg hoặc ở dạng lỏng, dạng nhão, bột mịn, dạng hạt hoặc dạng rời khác đóng trong bao bì hoặc gói sẵn, khối lượng trên 2 kg:',
    Unit: '',
  },
  {
    HsCode: '18062010',
    Description: 'Kẹo sô cô la ở dạng khối, miếng hoặc thanh',
    Unit: 'kg',
  },
  {
    HsCode: '18062090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, ở dạng khối, miếng hoặc thanh:',
    Unit: '',
  },
  {
    HsCode: '18063100',
    Description: 'Có nhân',
    Unit: 'kg',
  },
  {
    HsCode: '18063200',
    Description: 'Không có nhân',
    Unit: 'kg',
  },
  {
    HsCode: '180690',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '18069010',
    Description: 'Kẹo sô cô la ở dạng viên (tablets) hoặc viên ngậm (pastilles)',
    Unit: 'kg',
  },
  {
    HsCode: '18069030',
    Description:
      'Các chế phẩm thực phẩm từ bột, bột thô, tinh bột hoặc chiết xuất malt, có chứa từ 40% trở lên nhưng không quá 50% tính theo khối lượng là ca cao đã khử toàn bộ chất béo',
    Unit: 'kg',
  },
  {
    HsCode: '18069040',
    Description:
      'Các chế phẩm thực phẩm làm từ sản phẩm thuộc các nhóm từ 04.01 đến 04.04, có chứa từ 5% trở lên nhưng không quá 10% tính theo khối lượng là ca cao đã khử toàn bộ chất béo, được chế biến đặc biệt cho trẻ sơ sinh hoặc trẻ nhỏ, chưa đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '18069090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 19',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chế phẩm từ ngũ cốc, bột, tinh bột hoặc sữa; các loại bánh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các chế phẩm thực phẩm có chứa trên 20% tính theo khối lượng là xúc xích, thịt, phụ phẩm dạng thịt sau giết mổ, tiết, côn trùng, cá hoặc động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, hoặc hỗn hợp bất kỳ của các loại trên (Chương 16), trừ các sản phẩm được nhồi thuộc nhóm 19.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Bánh bích quy hoặc các sản phẩm khác làm từ bột hoặc tinh bột, được chế biến đặc biệt dùng để chăn nuôi động vật (nhóm 23.09); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Thuốc hoặc các sản phẩm khác của Chương 30. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Theo mục đích của nhóm 19.01:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Thuật ngữ ""tấm"" có nghĩa là tấm từ ngũ cốc thuộc Chương 11; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Thuật ngữ ""bột"" và ""bột thô"" có nghĩa là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(1) Bột và bột thô từ ngũ cốc thuộc Chương 11, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) Bột, bột thô và bột mịn nguồn gốc thực vật ở bất kỳ Chương nào, trừ bột, bột thô hoặc bột mịn của rau khô (nhóm 07.12), của khoai tây (nhóm 11.05) hoặc của các loại rau đậu khô (nhóm 11.06).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Nhóm 19.04 không bao gồm các chế phẩm có chứa trên 6% tính theo khối lượng là ca cao đã được khử toàn bộ chất béo hoặc được phủ sô cô la hoặc các chế phẩm thực phẩm khác có chứa ca cao thuộc nhóm 18.06 (nhóm 18.06).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo mục đích nhóm 19.04, thuật ngữ ""chế biến cách khác"" có nghĩa là được chế biến hoặc xử lý ngoài phạm vi quy định tại các nhóm hoặc các Chú giải của các Chương 10 hoặc 11.',
    Unit: '',
  },
  {
    HsCode: '1901',
    Description:
      'Chiết xuất malt; chế phẩm thực phẩm từ bột, tấm, bột thô, tinh bột hoặc từ chiết xuất malt, không chứa ca cao hoặc chứa dưới 40% tính theo khối lượng là ca cao đã khử toàn bộ chất béo, chưa được chi tiết hoặc ghi ở nơi khác; chế phẩm thực phẩm từ sản phẩm thuộc các nhóm 04.01 đến 04.04, không chứa ca cao hoặc chứa dưới 5% tính theo khối lượng là ca cao đã khử toàn bộ chất béo, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '190110',
    Description: 'Các chế phẩm phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ, đã đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '19011010',
    Description: 'Từ chiết xuất malt',
    Unit: 'kg',
  },
  {
    HsCode: '19011020',
    Description: 'Từ sản phẩm thuộc các nhóm từ 04.01 đến 04.04',
    Unit: 'kg',
  },
  {
    HsCode: '19011030',
    Description: 'Từ bột đỗ tương',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '19011091',
    Description: 'Sản phẩm dinh dưỡng y tế(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '19011092',
    Description: 'Loại khác, dùng cho trẻ trên một tuổi nhưng không quá ba tuổi',
    Unit: 'kg',
  },
  {
    HsCode: '19011099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '190120',
    Description: 'Bột trộn và bột nhào để chế biến thành các loại bánh thuộc nhóm 19.05:',
    Unit: '',
  },
  {
    HsCode: '19012010',
    Description: 'Từ bột, tấm, bột thô, tinh bột hoặc chiết xuất malt, không chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '19012020',
    Description: 'Từ bột, tấm, bột thô, tinh bột hoặc chiết xuất malt, chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '19012030',
    Description: 'Loại khác, không chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '19012040',
    Description: 'Loại khác, chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '190190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các chế phẩm phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ, chưa đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '19019011',
    Description: 'Sản phẩm dinh dưỡng y tế (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '19019019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '19019020',
    Description: 'Chiết xuất malt',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, làm từ sản phẩm thuộc nhóm từ 04.01 đến 04.04:',
    Unit: '',
  },
  {
    HsCode: '19019031',
    Description: 'Chứa sữa (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '19019032',
    Description: 'Loại khác, chứa bột ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '19019039',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các chế phẩm khác từ đỗ tương:',
    Unit: '',
  },
  {
    HsCode: '19019041',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '19019049',
    Description: 'Dạng khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '19019091',
    Description: 'Sản phẩm dinh dưỡng y tế (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '19019099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1902',
    Description:
      'Sản phẩm từ bột nhào (pasta), đã hoặc chưa làm chín hoặc nhồi (thịt hoặc các chất khác) hoặc chế biến cách khác, như spaghetti, macaroni, mì sợi (noodle), mì dẹt (lasagne), gnocchi, ravioli, cannelloni; couscous, đã hoặc chưa chế biến',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sản phẩm từ bột nhào chưa làm chín, chưa nhồi hoặc chưa chế biến cách khác:',
    Unit: '',
  },
  {
    HsCode: '19021100',
    Description: 'Có chứa trứng',
    Unit: 'kg',
  },
  {
    HsCode: '190219',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '19021920',
    Description: 'Mì, bún làm từ gạo (kể cả bee hoon)',
    Unit: 'kg',
  },
  {
    HsCode: '19021930',
    Description: 'Miến ',
    Unit: 'kg',
  },
  {
    HsCode: '19021940',
    Description: 'Mì khác',
    Unit: 'kg',
  },
  {
    HsCode: '19021990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '190220',
    Description: 'Sản phẩm từ bột nhào đã được nhồi, đã hoặc chưa nấu chín hoặc chế biến cách khác:',
    Unit: '',
  },
  {
    HsCode: '19022010',
    Description: 'Được nhồi thịt hoặc phụ phẩm dạng thịt sau giết mổ',
    Unit: 'kg',
  },
  {
    HsCode: '19022030',
    Description: 'Được nhồi cá, động vật giáp xác hoặc động vật thân mềm',
    Unit: 'kg',
  },
  {
    HsCode: '19022090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '190230',
    Description: 'Sản phẩm từ bột nhào khác:',
    Unit: '',
  },
  {
    HsCode: '19023020',
    Description: 'Mì, bún làm từ gạo (kể cả bee hoon)',
    Unit: 'kg',
  },
  {
    HsCode: '19023030',
    Description: 'Miến ',
    Unit: 'kg',
  },
  {
    HsCode: '19023040',
    Description: 'Mì ăn liền khác',
    Unit: 'kg',
  },
  {
    HsCode: '19023090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '19024000',
    Description: 'Couscous',
    Unit: 'kg',
  },
  {
    HsCode: '19030000',
    Description:
      'Sản phẩm từ tinh bột sắn và sản phẩm thay thế chế biến từ tinh bột, ở dạng mảnh, hạt, bột xay, bột rây hoặc các dạng tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '1904',
    Description:
      'Thực phẩm chế biến thu được từ quá trình rang hoặc nổ ngũ cốc hoặc các sản phẩm ngũ cốc (ví dụ mảnh ngô được chế biến từ bột ngô (corn flakes)); ngũ cốc (trừ ngô), ở dạng hạt hoặc dạng mảnh hoặc đã làm thành dạng hạt khác (trừ bột, tấm và bột thô), đã làm chín sơ hoặc chế biến cách khác, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '190410',
    Description: 'Thực phẩm chế biến thu được từ quá trình rang hoặc nổ ngũ cốc hoặc các sản phẩm ngũ cốc:',
    Unit: '',
  },
  {
    HsCode: '19041010',
    Description: 'Chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '19041090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '190420',
    Description:
      'Thực phẩm chế biến từ mảnh ngũ cốc chưa rang hoặc từ hỗn hợp của mảnh ngũ cốc chưa rang và mảnh ngũ cốc đã rang hoặc ngũ cốc đã nổ:',
    Unit: '',
  },
  {
    HsCode: '19042010',
    Description: 'Thực phẩm chế biến từ mảnh ngũ cốc chưa rang',
    Unit: 'kg',
  },
  {
    HsCode: '19042090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '19043000',
    Description: 'Lúa mì bulgur (1)',
    Unit: 'kg',
  },
  {
    HsCode: '190490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '19049010',
    Description: 'Các chế phẩm từ gạo, kể cả gạo đã nấu chín sơ',
    Unit: 'kg',
  },
  {
    HsCode: '19049090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '1905',
    Description:
      'Bánh mì, bánh bột nhào (pastry), bánh ga tô (cakes), bánh quy và các loại bánh khác, có hoặc không chứa ca cao; bánh thánh, vỏ viên nhộng dùng trong ngành dược, bánh xốp sealing wafers, bánh đa và các sản phẩm tương tự',
    Unit: '',
  },
  {
    HsCode: '19051000',
    Description: 'Bánh mì giòn',
    Unit: 'kg',
  },
  {
    HsCode: '19052000',
    Description: 'Bánh mì có gừng và loại tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Bánh quy ngọt; bánh waffles và bánh xốp wafers:',
    Unit: '',
  },
  {
    HsCode: '190531',
    Description: 'Bánh quy ngọt:',
    Unit: '',
  },
  {
    HsCode: '19053110',
    Description: 'Không chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '19053120',
    Description: 'Chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '190532',
    Description: 'Bánh waffles và bánh xốp wafers:',
    Unit: '',
  },
  {
    HsCode: '19053210',
    Description: 'Bánh waffles (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '19053220',
    Description: 'Bánh xốp wafers (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '190540',
    Description: 'Bánh bít cốt (rusks), bánh mì nướng và các loại bánh nướng tương tự:',
    Unit: '',
  },
  {
    HsCode: '19054010',
    Description: 'Chưa thêm đường, mật ong, trứng, chất béo, pho mát hoặc trái cây',
    Unit: 'kg',
  },
  {
    HsCode: '19054090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '190590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '19059010',
    Description: 'Bánh quy không ngọt dùng cho trẻ mọc răng hoặc thay răng',
    Unit: 'kg',
  },
  {
    HsCode: '19059020',
    Description: 'Bánh quy không ngọt khác',
    Unit: 'kg',
  },
  {
    HsCode: '19059030',
    Description: 'Bánh ga tô (cakes)',
    Unit: 'kg',
  },
  {
    HsCode: '19059040',
    Description: 'Bánh bột nhào (pastry)',
    Unit: 'kg',
  },
  {
    HsCode: '19059050',
    Description: 'Các loại bánh không bột',
    Unit: 'kg',
  },
  {
    HsCode: '19059060',
    Description: 'Vỏ viên nhộng và sản phẩm tương tự dùng trong dược phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '19059070',
    Description: 'Bánh thánh, bánh xốp sealing wafers, bánh đa và các sản phẩm tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '19059080',
    Description: 'Các sản phẩm thực phẩm giòn có hương liệu khác',
    Unit: 'kg',
  },
  {
    HsCode: '19059090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1) Tham khảo TCVN 4995:2016',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 20',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các chế phẩm từ rau, quả, quả hạch (nuts) hoặc các phần khác của cây',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các loại rau, quả hoặc quả hạch (nuts), được chế biến hoặc bảo quản theo quy trình đã ghi trong Chương 7, 8 hoặc 11;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Chất béo và dầu thực vật (Chương 15);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các chế phẩm thực phẩm chứa trên 20% tính theo khối lượng là xúc xích, thịt, các phụ phẩm dạng thịt sau giết mổ, tiết, côn trùng, cá hoặc động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, hoặc hỗn hợp bất kỳ của các loại trên (Chương 16);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các loại bánh và sản phẩm khác thuộc nhóm 19.05; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các chế phẩm thực phẩm tổng hợp đồng nhất thuộc nhóm 21.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Các nhóm 20.07 và 20.08 không áp dụng cho các loại thạch trái cây, bột nhão từ quả, các loại quả hạnh bọc đường hoặc các sản phẩm tương tự chế biến dưới dạng kẹo đường (nhóm 17.04) hoặc kẹo sôcôla (nhóm 18.06).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các nhóm 20.01, 20.04 và 20.05, tùy theo từng trường hợp, chỉ bao gồm những sản phẩm thuộc Chương 7 hoặc nhóm 11.05 hoặc 11.06 (trừ bột, bột thô và bột mịn của các sản phẩm đã ghi trong Chương 8) đã được chế biến hoặc bảo quản theo các quy trình khác với quy trình đã ghi trong Chú giải 1(a).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '4. Nước ép cà chua có hàm lượng chất khô từ 7% trở lên được xếp vào nhóm 20.02.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của nhóm 20.07, khái niệm ""thu được từ quá trình đun nấu"" có nghĩa là thu được bằng cách xử lý nhiệt ở áp suất không khí hoặc giảm áp suất để làm tăng độ dẻo của một sản phẩm thông qua việc giảm lượng nước hoặc thông qua biện pháp khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của nhóm 20.09, khái niệm ""các loại nước ép, chưa lên men và chưa pha rượu"" để chỉ các loại nước ép có nồng độ cồn không quá 0,5% tính theo thể tích (xem Chú giải 2 của Chương 22).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 2005.10, khái niệm ""rau đồng nhất"" chỉ các loại chế phẩm từ rau, đã đồng nhất mịn, phù hợp dùng làm thức ăn cho trẻ sơ sinh hoặc trẻ nhỏ hoặc để ăn kiêng, đóng gói để bán lẻ, trong bao bì với khối lượng tịnh không quá 250 gam. Khi áp dụng định nghĩa này không cần quan tâm đến những thành phần có hàm lượng nhỏ cho thêm vào làm gia vị, để bảo quản hoặc đáp ứng các mục đích khác. Các chế phẩm này có thể chứa một lượng nhỏ mảnh vụn rau có thể nhìn thấy được. Phân nhóm 2005.10 được ưu tiên xem xét trước trong các phân nhóm thuộc nhóm 20.05.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của phân nhóm 2007.10, khái niệm ""chế phẩm đồng nhất"" chỉ các loại chế phẩm của quả, đã đồng nhất mịn, phù hợp dùng làm thức ăn cho trẻ sơ sinh hoặc trẻ nhỏ hoặc để ăn kiêng, đóng gói để bán lẻ trong bao bì với khối lượng tịnh không quá 250 gam. Khi áp dụng định nghĩa này không cần quan tâm đến những thành phần có hàm lượng nhỏ cho thêm vào làm gia vị, để bảo quản hoặc đáp ứng các mục đích khác. Các chế phẩm này có thể chứa một lượng nhỏ mảnh vụn quả có thể nhìn thấy được. Phân nhóm 2007.10 được ưu tiên xem xét trước trong các phân nhóm thuộc nhóm 20.07.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của các phân nhóm 2009.12, 2009.21, 2009.31, 2009.41, 2009.61 và 2009.71, khái niệm ""giá trị Brix"" có nghĩa là đọc trực tiếp độ Brix từ tỷ trọng kế Brix hoặc của chỉ số khúc xạ được biểu thị theo khái niệm tỷ lệ phần trăm thành phần sucroza đo bằng khúc xạ kế, ở nhiệt độ 20°C hoặc được hiệu chỉnh về 20°C nếu phép đo được tiến hành ở một nhiệt độ khác.',
    Unit: '',
  },
  {
    HsCode: '2001',
    Description:
      'Rau, quả, quả hạch (nuts) và các phần ăn được khác của cây, đã chế biến hoặc bảo quản bằng giấm hoặc axit axetic',
    Unit: '',
  },
  {
    HsCode: '20011000',
    Description: 'Dưa chuột và dưa chuột ri',
    Unit: 'kg',
  },
  {
    HsCode: '200190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20019010',
    Description: 'Hành tây',
    Unit: 'kg',
  },
  {
    HsCode: '20019090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2002',
    Description: 'Cà chua đã chế biến hoặc bảo quản bằng cách khác trừ loại bảo quản bằng giấm hoặc axit axetic',
    Unit: '',
  },
  {
    HsCode: '20021000',
    Description: 'Cà chua, nguyên quả hoặc dạng miếng',
    Unit: 'kg',
  },
  {
    HsCode: '200290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20029010',
    Description: 'Bột cà chua dạng sệt (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '20029020',
    Description: 'Bột cà chua',
    Unit: 'kg',
  },
  {
    HsCode: '20029090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2003',
    Description:
      'Nấm và nấm cục (truffles), đã chế biến hoặc bảo quản bằng cách khác trừ bảo quản bằng giấm hoặc axit axetic',
    Unit: '',
  },
  {
    HsCode: '20031000',
    Description: 'Nấm thuộc chi Agaricus',
    Unit: 'kg',
  },
  {
    HsCode: '200390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20039010',
    Description: 'Nấm cục (truffles)',
    Unit: 'kg',
  },
  {
    HsCode: '20039090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2004',
    Description:
      'Rau khác, đã chế biến hoặc bảo quản bằng cách khác trừ bảo quản bằng giấm hoặc axit axetic, đã đông lạnh, trừ các sản phẩm thuộc nhóm 20.06',
    Unit: '',
  },
  {
    HsCode: '20041000',
    Description: 'Khoai tây',
    Unit: 'kg',
  },
  {
    HsCode: '200490',
    Description: 'Rau khác và hỗn hợp các loại rau:',
    Unit: '',
  },
  {
    HsCode: '20049010',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ',
    Unit: 'kg',
  },
  {
    HsCode: '20049090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2005',
    Description:
      'Rau khác đã chế biến hoặc bảo quản bằng cách khác trừ bảo quản bằng giấm hoặc axit axetic, không đông lạnh, trừ các sản phẩm thuộc nhóm 20.06',
    Unit: '',
  },
  {
    HsCode: '200510',
    Description: 'Rau đồng nhất:',
    Unit: '',
  },
  {
    HsCode: '20051020',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ',
    Unit: 'kg',
  },
  {
    HsCode: '20051030',
    Description: 'Phù hợp dùng cho mục đích ăn kiêng',
    Unit: 'kg',
  },
  {
    HsCode: '200520',
    Description: 'Khoai tây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng thanh và que:',
    Unit: '',
  },
  {
    HsCode: '20052011',
    Description: 'Đóng bao bì kín khí để bán lẻ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '20052019',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20052091',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '20052099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '20054000',
    Description: 'Đậu Hà Lan (Pisum sativum)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Đậu hạt (Vigna spp., Phaseolus spp.):',
    Unit: '',
  },
  {
    HsCode: '20055100',
    Description: 'Đã bóc vỏ',
    Unit: 'kg',
  },
  {
    HsCode: '200559',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20055910',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '20055990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '20056000',
    Description: 'Măng tây',
    Unit: 'kg',
  },
  {
    HsCode: '20057000',
    Description: 'Ô liu',
    Unit: 'kg',
  },
  {
    HsCode: '20058000',
    Description: 'Ngô ngọt (Zea mays var. saccharata)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Rau khác và hỗn hợp các loại rau:',
    Unit: '',
  },
  {
    HsCode: '20059100',
    Description: 'Măng tre',
    Unit: 'kg',
  },
  {
    HsCode: '200599',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20059910',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '20059990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '20060000',
    Description:
      'Rau, quả, quả hạch (nuts), vỏ quả và các phần khác của cây, được bảo quản bằng đường (dạng khô có tẩm đường, ngâm trong nước đường hoặc bọc đường)',
    Unit: 'kg',
  },
  {
    HsCode: '2007',
    Description:
      'Mứt, thạch trái cây, mứt từ quả thuộc chi cam quýt, quả hoặc quả hạch (nuts) dạng nghiền (purée) và dạng nhão, thu được từ quá trình đun nấu, đã hoặc chưa pha thêm đường hoặc chất tạo ngọt khác',
    Unit: '',
  },
  {
    HsCode: '20071000',
    Description: 'Chế phẩm đồng nhất',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20079100',
    Description: 'Từ quả thuộc chi cam quýt',
    Unit: 'kg',
  },
  {
    HsCode: '200799',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20079910',
    Description: 'Trái cây dạng nhão, trừ xoài, dứa hoặc dâu tây dạng nhão',
    Unit: 'kg',
  },
  {
    HsCode: '20079920',
    Description: 'Mứt và thạch trái cây',
    Unit: 'kg',
  },
  {
    HsCode: '20079930',
    Description: 'Xoài nghiền (Mango purée) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '20079990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2008',
    Description:
      'Quả, quả hạch (nuts) và các phần ăn được khác của cây, đã chế biến hoặc bảo quản bằng cách khác, đã hoặc chưa pha thêm đường hoặc chất tạo ngọt khác hoặc rượu, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quả hạch (nuts), lạc và hạt khác, đã hoặc chưa pha trộn với nhau:',
    Unit: '',
  },
  {
    HsCode: '200811',
    Description: 'Lạc:',
    Unit: '',
  },
  {
    HsCode: '20081110',
    Description: 'Đã rang',
    Unit: 'kg',
  },
  {
    HsCode: '20081120',
    Description: 'Bơ lạc',
    Unit: 'kg',
  },
  {
    HsCode: '20081190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '200819',
    Description: 'Loại khác, kể cả các hỗn hợp:',
    Unit: '',
  },
  {
    HsCode: '20081910',
    Description: 'Hạt điều',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20081991',
    Description: 'Đã rang',
    Unit: 'kg',
  },
  {
    HsCode: '20081999',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '200820',
    Description: 'Dứa:',
    Unit: '',
  },
  {
    HsCode: '20082010',
    Description: 'Đóng bao bì kín khí để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '20082090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '200830',
    Description: 'Quả thuộc chi cam quýt:',
    Unit: '',
  },
  {
    HsCode: '20083010',
    Description: 'Đã pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20083090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '20084000',
    Description: 'Lê',
    Unit: 'kg',
  },
  {
    HsCode: '20085000',
    Description: 'Mơ',
    Unit: 'kg',
  },
  {
    HsCode: '200860',
    Description: 'Anh đào (Cherries):',
    Unit: '',
  },
  {
    HsCode: '20086010',
    Description: 'Đã pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20086090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '200870',
    Description: 'Đào, kể cả quả xuân đào:',
    Unit: '',
  },
  {
    HsCode: '20087010',
    Description: 'Đã pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20087090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '20088000',
    Description: 'Dâu tây',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, kể cả dạng hỗn hợp trừ loại thuộc phân nhóm 2008.19:',
    Unit: '',
  },
  {
    HsCode: '20089100',
    Description: 'Lõi cây cọ',
    Unit: 'kg',
  },
  {
    HsCode: '200893',
    Description:
      'Quả nam việt quất (Vaccinium macrocarpon, Vaccinium oxycoccos); quả lingonberries (Vaccinium Vitis-idaea):',
    Unit: '',
  },
  {
    HsCode: '20089310',
    Description: 'Đã pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20089390',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '200897',
    Description: 'Dạng hỗn hợp:',
    Unit: '',
  },
  {
    HsCode: '20089710',
    Description:
      'Từ thân cây, rễ cây và các phần ăn được khác của cây, không bao gồm quả hoặc quả hạch (nuts) đã hoặc chưa pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20089720',
    Description: 'Loại khác, đã pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20089790',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '200899',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20089910',
    Description: 'Quả vải',
    Unit: 'kg',
  },
  {
    HsCode: '20089920',
    Description: 'Quả nhãn',
    Unit: 'kg',
  },
  {
    HsCode: '20089930',
    Description:
      'Từ thân cây, rễ cây và các phần ăn được khác của cây, không bao gồm quả hoặc quả hạch (nuts) đã hoặc chưa pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20089940',
    Description: 'Loại khác, đã pha thêm đường hoặc chất tạo ngọt khác hoặc rượu',
    Unit: 'kg',
  },
  {
    HsCode: '20089990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2009',
    Description:
      'Các loại nước ép trái cây hoặc nước ép từ quả hạch (nut) (kể cả hèm nho và nước dừa) và nước rau ép, chưa lên men và chưa pha thêm rượu, đã hoặc chưa pha thêm đường hoặc chất tạo ngọt khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nước cam ép:',
    Unit: '',
  },
  {
    HsCode: '20091100',
    Description: 'Đông lạnh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20091200',
    Description: 'Không đông lạnh, với trị giá Brix không quá 20',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20091900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Nước ép quả bưởi chùm; nước ép quả bưởi: ',
    Unit: '',
  },
  {
    HsCode: '20092100',
    Description: 'Với trị giá Brix không quá 20',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20092900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Nước ép từ một loại quả thuộc chi cam quýt khác:',
    Unit: '',
  },
  {
    HsCode: '20093100',
    Description: 'Với trị giá Brix không quá 20',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20093900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Nước dứa ép:',
    Unit: '',
  },
  {
    HsCode: '20094100',
    Description: 'Với trị giá Brix không quá 20',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20094900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20095000',
    Description: 'Nước cà chua ép',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Nước nho ép (kể cả hèm nho):',
    Unit: '',
  },
  {
    HsCode: '20096100',
    Description: 'Với trị giá Brix không quá 30',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20096900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Nước táo ép:',
    Unit: '',
  },
  {
    HsCode: '20097100',
    Description: 'Với trị giá Brix không quá 20',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20097900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Nước ép từ một loại quả, quả hạch (nut) hoặc rau khác:',
    Unit: '',
  },
  {
    HsCode: '200981',
    Description:
      'Nước quả nam việt quất ép (Vaccinium macrocarpon, Vaccinium oxycoccos); nước quả lingonberry ép (Vaccinium Vitis-idaea):',
    Unit: '',
  },
  {
    HsCode: '20098110',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20098190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '200989',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20098910',
    Description: 'Nước ép từ quả lý chua đen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20098920',
    Description: 'Nước dừa (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20098930',
    Description: 'Nước dừa cô đặc (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20098940',
    Description: 'Nước xoài ép cô đặc ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20098991',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20098999',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '200990',
    Description: 'Nước ép hỗn hợp:',
    Unit: '',
  },
  {
    HsCode: '20099010',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '20099091',
    Description: 'Dùng ngay được',
    Unit: 'kg/lít',
  },
  {
    HsCode: '20099099',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 21',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các chế phẩm ăn được khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các loại rau hỗn hợp thuộc nhóm 07.12;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các chất thay thế cà phê rang có chứa cà phê theo tỷ lệ bất kỳ (nhóm 09.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Chè đã pha hương liệu (nhóm 09.02);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các gia vị hoặc các sản phẩm khác thuộc các nhóm từ 09.04 đến 09.10;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Các chế phẩm thực phẩm, trừ các sản phẩm ghi trong nhóm 21.03 hoặc 21.04, chứa trên 20% tính theo khối lượng là xúc xích, thịt, phụ phẩm dạng thịt sau giết mổ, tiết, côn trùng, cá hoặc động vật giáp xác, động vật thân mềm hoặc các loại động vật thủy sinh không xương sống khác, hoặc hỗn hợp bất kỳ của các loại trên (Chương 16);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các sản phẩm của nhóm 24.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Men được đóng gói như dược phẩm hoặc các sản phẩm khác thuộc nhóm 30.03 hoặc 30.04; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Enzyme đã chế biến thuộc nhóm 35.07.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Các chiết xuất từ các chất thay thế ghi trong Chú giải 1(b) trên đây được xếp vào nhóm 21.01.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 21.04, khái niệm ""chế phẩm thực phẩm tổng hợp đồng nhất"" có nghĩa là các loại chế phẩm bao gồm một hỗn hợp đồng nhất mịn của hai hoặc nhiều thành phần cơ bản như thịt, cá, rau, quả hoặc quả hạch (nuts), được đóng gói với khối lượng tịnh không quá 250g để bán lẻ, phù hợp dùng làm thức ăn cho trẻ sơ sinh hoặc trẻ nhỏ hoặc ăn kiêng. Khi áp dụng khái niệm này, không cần quan tâm đến những thành phần có hàm lượng nhỏ cho thêm vào làm gia vị, để bảo quản hoặc cho các mục đích khác. Các chế phẩm này có thể chứa một lượng nhỏ những mẩu vụn có thể nhìn thấy được của các thành phần nói trên.',
    Unit: '',
  },
  {
    HsCode: '2101',
    Description:
      'Chất chiết xuất, tinh chất và chất cô đặc, từ cà phê, chè hoặc chè Paragoay, và các chế phẩm có thành phần cơ bản là các sản phẩm này hoặc có thành phần cơ bản là cà phê, chè, chè Paragoay; rau diếp xoăn rang và các chất thay thế cà phê rang khác, và các chiết xuất, tinh chất và chất cô đặc của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Chất chiết xuất, tinh chất và các chất cô đặc, từ cà phê, và các chế phẩm có thành phần cơ bản là các chất chiết xuất, tinh chất hoặc các chất cô đặc này hoặc có thành phần cơ bản là cà phê:',
    Unit: '',
  },
  {
    HsCode: '210111',
    Description: 'Chất chiết xuất, tinh chất và các chất cô đặc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cà phê hòa tan (1):',
    Unit: '',
  },
  {
    HsCode: '21011111',
    Description: 'Được đóng gói với khối lượng tịnh không dưới 20kg',
    Unit: 'kg',
  },
  {
    HsCode: '21011119',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '21011190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '210112',
    Description:
      'Các chế phẩm có thành phần cơ bản là các chất chiết xuất, tinh chất hoặc các chất cô đặc hoặc có thành phần cơ bản là cà phê:',
    Unit: '',
  },
  {
    HsCode: '21011210',
    Description: 'Hỗn hợp dạng bột nhão có thành phần cơ bản là cà phê rang xay, có chứa chất béo thực vật',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '21011291',
    Description:
      'Các chế phẩm cà phê có thành phần cơ bản là các chất chiết xuất, tinh chất hoặc chất cô đặc có chứa đường, có chứa hoặc không chứa kem',
    Unit: 'kg',
  },
  {
    HsCode: '21011292',
    Description:
      'Các chế phẩm cà phê có thành phần cơ bản là cà phê rang xay có chứa đường, có chứa hoặc không chứa kem',
    Unit: 'kg',
  },
  {
    HsCode: '21011299',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '210120',
    Description:
      'Chất chiết xuất, tinh chất và các chất cô đặc, từ chè hoặc chè Paragoay, và các chế phẩm có thành phần cơ bản là các chất chiết xuất, tinh chất hoặc các chất cô đặc này hoặc có thành phần cơ bản là chè hoặc chè Paragoay:',
    Unit: '',
  },
  {
    HsCode: '21012020',
    Description: 'Chiết xuất từ chè để sản xuất các chế phẩm chè, dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '21012030',
    Description: 'Các chế phẩm chè gồm hỗn hợp của chè, sữa bột và đường',
    Unit: 'kg',
  },
  {
    HsCode: '21012090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '21013000',
    Description:
      'Rau diếp xoăn rang và các chất thay thế cà phê rang khác, và chất chiết xuất, tinh chất và các chất cô đặc của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '2102',
    Description:
      'Men (sống hoặc ỳ); các vi sinh đơn bào khác, chết (nhưng không bao gồm các loại vắc xin thuộc nhóm 30.02); bột nở đã pha chế',
    Unit: '',
  },
  {
    HsCode: '21021000',
    Description: 'Men sống',
    Unit: 'kg',
  },
  {
    HsCode: '210220',
    Description: 'Men ỳ; các vi sinh đơn bào khác, chết:',
    Unit: '',
  },
  {
    HsCode: '21022010',
    Description: 'Loại dùng trong chăn nuôi động vật',
    Unit: 'kg',
  },
  {
    HsCode: '21022090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '21023000',
    Description: 'Bột nở đã pha chế',
    Unit: 'kg',
  },
  {
    HsCode: '2103',
    Description:
      'Nước xốt và các chế phẩm làm nước xốt; đồ gia vị hỗn hợp và bột canh hỗn hợp; bột mịn và bột thô từ hạt mù tạt và mù tạt đã chế biến',
    Unit: '',
  },
  {
    HsCode: '21031000',
    Description: 'Nước xốt đậu tương',
    Unit: 'kg',
  },
  {
    HsCode: '21032000',
    Description: 'Ketchup cà chua và nước xốt cà chua khác',
    Unit: 'kg',
  },
  {
    HsCode: '21033000',
    Description: 'Bột mù tạt mịn, bột mù tạt thô và mù tạt đã chế biến',
    Unit: 'kg',
  },
  {
    HsCode: '210390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nước xốt và các chế phẩm làm nước xốt:',
    Unit: '',
  },
  {
    HsCode: '21039011',
    Description: 'Tương ớt',
    Unit: 'kg',
  },
  {
    HsCode: '21039012',
    Description: 'Nước mắm',
    Unit: 'kg',
  },
  {
    HsCode: '21039013',
    Description: 'Nước xốt loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '21039019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Gia vị hỗn hợp và bột canh hỗn hợp:',
    Unit: '',
  },
  {
    HsCode: '21039021',
    Description: 'Mắm tôm (mắm ruốc) kể cả belachan (blachan) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21039029',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2104',
    Description: 'Súp và nước xuýt (broths) và chế phẩm để làm súp và nước xuýt; chế phẩm thực phẩm tổng hợp đồng nhất',
    Unit: '',
  },
  {
    HsCode: '210410',
    Description: 'Súp và nước xuýt (broths) và chế phẩm để làm súp và nước xuýt:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chứa thịt:',
    Unit: '',
  },
  {
    HsCode: '21041011',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21041019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '21041091',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21041099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '210420',
    Description: 'Chế phẩm thực phẩm tổng hợp đồng nhất:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chứa thịt:',
    Unit: '',
  },
  {
    HsCode: '21042011',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ',
    Unit: 'kg',
  },
  {
    HsCode: '21042019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '21042091',
    Description: 'Phù hợp dùng cho trẻ sơ sinh hoặc trẻ nhỏ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21042099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '21050000',
    Description: 'Kem lạnh và sản phẩm ăn được tương tự khác, có hoặc không chứa ca cao',
    Unit: 'kg',
  },
  {
    HsCode: '2106',
    Description: 'Các chế phẩm thực phẩm chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '21061000',
    Description: 'Protein cô đặc và chất protein được làm rắn (textured protein substances)',
    Unit: 'kg',
  },
  {
    HsCode: '210690',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đậu phụ:',
    Unit: '',
  },
  {
    HsCode: '21069011',
    Description: 'Váng đậu khô và váng đậu khô dạng thanh ',
    Unit: 'kg',
  },
  {
    HsCode: '21069012',
    Description: 'Đậu phụ tươi (tofu)',
    Unit: 'kg',
  },
  {
    HsCode: '21069019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '21069020',
    Description: 'Cồn dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '21069030',
    Description: 'Kem không sữa',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Chất chiết nấm men tự phân:',
    Unit: '',
  },
  {
    HsCode: '21069041',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '21069049',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, các chế phẩm không chứa cồn được sử dụng để chế biến hoặc sản xuất đồ uống:',
    Unit: '',
  },
  {
    HsCode: '21069053',
    Description: 'Sản phẩm từ sâm',
    Unit: 'kg',
  },
  {
    HsCode: '21069054',
    Description: 'Các chế phẩm khác được sử dụng làm nguyên liệu thô để sản xuất chất cô đặc tổng hợp',
    Unit: 'kg',
  },
  {
    HsCode: '21069055',
    Description: 'Loại khác, chất cô đặc tổng hợp để pha đơn giản với nước làm đồ uống',
    Unit: 'kg',
  },
  {
    HsCode: '21069059',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, các chế phẩm chứa cồn được sử dụng để chế biến hoặc sản xuất đồ uống:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chế phẩm được sử dụng làm nguyên liệu thô để sản xuất chất cô đặc tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '21069061',
    Description: 'Loại sử dụng để sản xuất đồ uống có cồn, dạng lỏng',
    Unit: 'kg',
  },
  {
    HsCode: '21069062',
    Description: 'Loại sử dụng để sản xuất đồ uống có cồn, dạng khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Chất cô đặc tổng hợp dùng để pha đơn giản với nước làm đồ uống:',
    Unit: '',
  },
  {
    HsCode: '21069064',
    Description: 'Loại sử dụng để sản xuất đồ uống có cồn, dạng lỏng',
    Unit: 'kg',
  },
  {
    HsCode: '21069065',
    Description: 'Loại sử dụng để sản xuất đồ uống có cồn, dạng khác',
    Unit: 'kg',
  },
  {
    HsCode: '21069066',
    Description: 'Loại khác, sử dụng để sản xuất đồ uống có cồn, dạng lỏng',
    Unit: 'kg',
  },
  {
    HsCode: '21069067',
    Description: 'Loại khác, sử dụng để sản xuất đồ uống có cồn, dạng khác',
    Unit: 'kg',
  },
  {
    HsCode: '21069069',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Thực phẩm bảo vệ sức khỏe (food supplements) khác; hỗn hợp vi chất để bổ sung vào thực phẩm:',
    Unit: '',
  },
  {
    HsCode: '21069071',
    Description: 'Thực phẩm bảo vệ sức khỏe từ sâm',
    Unit: 'kg',
  },
  {
    HsCode: '21069072',
    Description: 'Thực phẩm bảo vệ sức khỏe khác',
    Unit: 'kg',
  },
  {
    HsCode: '21069073',
    Description: 'Hỗn hợp vi chất để bổ sung vào thực phẩm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các chế phẩm thực phẩm dùng cho trẻ sơ sinh hoặc trẻ nhỏ:',
    Unit: '',
  },
  {
    HsCode: '21069081',
    Description: 'Các chế phẩm thực phẩm dùng cho trẻ sơ sinh hoặc trẻ nhỏ thiếu lactaza',
    Unit: 'kg',
  },
  {
    HsCode: '21069089',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '21069091',
    Description:
      'Hỗn hợp khác của hóa chất với thực phẩm hoặc các chất khác có giá trị dinh dưỡng, dùng để chế biến thực phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '21069092',
    Description: 'Xirô đã pha màu hoặc hương liệu',
    Unit: 'kg',
  },
  {
    HsCode: '21069093',
    Description: 'Nước cốt dừa, đã hoặc chưa làm thành bột (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21069095',
    Description: 'Seri kaya (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21069096',
    Description: 'Sản phẩm dinh dưỡng y tế khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21069097',
    Description: 'Tempeh (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21069098',
    Description: 'Các chế phẩm hương liệu khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '21069099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 9702:2013',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 22',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đồ uống, rượu và giấm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các sản phẩm của Chương này (trừ các sản phẩm của nhóm 22.09) được chế biến cho mục đích nấu nướng và không phù hợp để làm đồ uống (thường ở nhóm 21.03);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Nước biển (nhóm 25.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Nước cất hoặc nước khử độ dẫn hoặc nước có độ tinh khiết tương tự (nhóm 28.53);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Axit axetic có nồng độ axit axetic trên 10% tính theo khối lượng (nhóm 29.15);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Dược phẩm thuộc nhóm 30.03 hoặc 30.04; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các chế phẩm nước hoa hoặc chế phẩm vệ sinh (Chương 33).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của Chương này và các Chương 20 và 21, ""nồng độ cồn tính theo thể tích"" sẽ được xác định ở nhiệt độ 20oC.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 22.02, khái niệm ""đồ uống không chứa cồn"" có nghĩa là các loại đồ uống có nồng độ cồn không quá 0,5% tính theo thể tích. Đồ uống có cồn được xếp vào các nhóm thích hợp từ 22.03 đến 22.06 hoặc nhóm 22.08.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 2204.10, khái niệm ""rượu vang nổ""(1) là loại rượu khi bảo quản ở nhiệt độ 20oC trong thùng kín, có mức áp suất vượt từ 3 bars trở lên.',
    Unit: '',
  },
  {
    HsCode: '2201',
    Description:
      'Nước, kể cả nước khoáng tự nhiên hoặc nhân tạo và nước có ga, chưa pha thêm đường hoặc chất tạo ngọt khác hoặc hương liệu; nước đá và tuyết',
    Unit: '',
  },
  {
    HsCode: '220110',
    Description: 'Nước khoáng và nước có ga:',
    Unit: '',
  },
  {
    HsCode: '22011010',
    Description: 'Nước khoáng',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22011020',
    Description: 'Nước có ga',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '22019010',
    Description: 'Nước đá và tuyết',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22019090',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '2202',
    Description:
      'Nước, kể cả nước khoáng và nước có ga, đã pha thêm đường hoặc chất tạo ngọt khác hoặc hương liệu, và đồ uống không chứa cồn khác, không bao gồm nước quả ép, nước ép từ quả hạch (nut) hoặc nước rau ép thuộc nhóm 20.09',
    Unit: '',
  },
  {
    HsCode: '220210',
    Description: 'Nước, kể cả nước khoáng và nước có ga, đã pha thêm đường hoặc chất tạo ngọt khác hoặc hương liệu: ',
    Unit: '',
  },
  {
    HsCode: '22021020',
    Description: 'Nước tăng lực có hoặc không có ga(SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22021030',
    Description: 'Nước khoáng xô đa hoặc nước có ga, loại khác, có hương liệu',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22021090',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '22029100',
    Description: 'Bia không cồn',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220299',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '22029910',
    Description: 'Đồ uống chứa sữa tiệt trùng có hương liệu',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22029920',
    Description: 'Đồ uống sữa đậu nành',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22029930',
    Description: 'Đồ uống từ nước dừa (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22029940',
    Description: 'Đồ uống có chứa cà phê hoặc đồ uống có hương liệu cà phê',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22029950',
    Description: 'Đồ uống không có ga khác dùng ngay được không cần pha loãng',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22029990',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '2203',
    Description: 'Bia sản xuất từ malt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Bia đen hoặc bia nâu:',
    Unit: '',
  },
  {
    HsCode: '22030011',
    Description: 'Có nồng độ cồn không quá 5,8% tính theo thể tích (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22030019',
    Description: 'Loại khác (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, kể cả bia ale:',
    Unit: '',
  },
  {
    HsCode: '22030091',
    Description: 'Có nồng độ cồn không quá 5,8% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22030099',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '2204',
    Description: 'Rượu vang làm từ nho tươi, kể cả rượu vang cao độ; hèm nho trừ loại thuộc nhóm 20.09',
    Unit: '',
  },
  {
    HsCode: '22041000',
    Description: 'Rượu vang nổ (1)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Rượu vang khác; hèm nho đã pha cồn để ngăn ngừa hoặc cản sự lên men:',
    Unit: '',
  },
  {
    HsCode: '220421',
    Description: 'Loại trong đồ đựng không quá 2 lít:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rượu vang:',
    Unit: '',
  },
  {
    HsCode: '22042111',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042113',
    Description: 'Có nồng độ cồn trên 15% nhưng không quá 23% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042114',
    Description: 'Có nồng độ cồn trên 23% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Hèm nho đã pha cồn để ngăn ngừa hoặc cản sự lên men:',
    Unit: '',
  },
  {
    HsCode: '22042121',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042122',
    Description: 'Có nồng độ cồn trên 15% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220422',
    Description: 'Loại trong đồ đựng trên 2 lít nhưng không quá 10 lít:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rượu vang:',
    Unit: '',
  },
  {
    HsCode: '22042211',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042212',
    Description: 'Có nồng độ cồn trên 15% nhưng không quá 23% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042213',
    Description: 'Có nồng độ cồn trên 23% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Hèm nho đã pha cồn để ngăn ngừa hoặc cản sự lên men:',
    Unit: '',
  },
  {
    HsCode: '22042221',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042222',
    Description: 'Có nồng độ cồn trên 15% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220429',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rượu vang:',
    Unit: '',
  },
  {
    HsCode: '22042911',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042912',
    Description: 'Có nồng độ cồn trên 15% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Hèm nho đã pha cồn để ngăn ngừa hoặc cản sự lên men:',
    Unit: '',
  },
  {
    HsCode: '22042921',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22042922',
    Description: 'Có nồng độ cồn trên 15% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220430',
    Description: 'Hèm nho khác:',
    Unit: '',
  },
  {
    HsCode: '22043010',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22043020',
    Description: 'Có nồng độ cồn trên 15% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '2205',
    Description: 'Rượu Vermouth và rượu vang khác làm từ nho tươi đã pha thêm hương liệu từ thảo mộc hoặc chất thơm',
    Unit: '',
  },
  {
    HsCode: '220510',
    Description: 'Loại trong đồ đựng không quá 2 lít:',
    Unit: '',
  },
  {
    HsCode: '22051010',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22051020',
    Description: 'Có nồng độ cồn trên 15% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '22059010',
    Description: 'Có nồng độ cồn không quá 15% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22059020',
    Description: 'Có nồng độ cồn trên 15% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '2206',
    Description:
      'Đồ uống đã lên men khác (ví dụ, vang táo, vang lê, vang mật ong, rượu sa kê); hỗn hợp của đồ uống đã lên men và hỗn hợp của đồ uống đã lên men với đồ uống không chứa cồn, chưa chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '22060010',
    Description: 'Vang táo hoặc vang lê',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22060020',
    Description: 'Rượu sa kê (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Toddy cọ dừa:',
    Unit: '',
  },
  {
    HsCode: '22060031',
    Description: 'Loại trong đồ đựng không quá 2 lít (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22060039',
    Description: 'Loại khác (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Shandy:',
    Unit: '',
  },
  {
    HsCode: '22060041',
    Description: 'Có nồng độ cồn không quá 1,14% tính theo thể tích (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22060049',
    Description: 'Loại khác (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22060050',
    Description: 'Vang mật ong',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22060060',
    Description: 'Rượu thu được từ việc lên men nước ép rau hoặc nước ép quả, trừ nước ép nho tươi (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '22060091',
    Description: 'Rượu gạo khác (kể cả rượu gạo bổ)(SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22060099',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '2207',
    Description:
      'Cồn etylic chưa biến tính có nồng độ cồn từ 80% trở lên tính theo thể tích; cồn etylic và rượu mạnh khác, đã biến tính, ở mọi nồng độ',
    Unit: '',
  },
  {
    HsCode: '22071000',
    Description: 'Cồn etylic chưa biến tính có nồng độ cồn từ 80% trở lên tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220720',
    Description: 'Cồn etylic và rượu mạnh khác, đã biến tính, ở mọi nồng độ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cồn etylic đã biến tính, kể cả rượu mạnh đã methyl hoá:',
    Unit: '',
  },
  {
    HsCode: '22072011',
    Description: 'Cồn etylic có nồng độ trên 99% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22072019',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22072090',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '2208',
    Description:
      'Cồn etylic chưa biến tính có nồng độ cồn dưới 80% tính theo thể tích; rượu mạnh, rượu mùi và đồ uống có rượu khác',
    Unit: '',
  },
  {
    HsCode: '220820',
    Description: 'Rượu mạnh thu được từ cất rượu vang nho hoặc bã nho:',
    Unit: '',
  },
  {
    HsCode: '22082050',
    Description: 'Rượu brandy',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22082090',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220830',
    Description: 'Rượu whisky:',
    Unit: '',
  },
  {
    HsCode: '22083010',
    Description: 'Được đựng trong đồ chứa trên 5 lít',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22083090',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22084000',
    Description: 'Rượu rum và rượu mạnh khác được cất từ các sản phẩm mía đường lên men',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22085000',
    Description: 'Rượu gin và rượu Geneva',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22086000',
    Description: 'Rượu vodka',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220870',
    Description: 'Rượu mùi:',
    Unit: '',
  },
  {
    HsCode: '22087010',
    Description: 'Có nồng độ cồn không quá 57% tính theo thể tích ',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22087090',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '220890',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '22089010',
    Description: 'Rượu bổ sam-su có nồng độ cồn không quá 40% tính theo thể tích (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089020',
    Description: 'Rượu bổ sam-su có nồng độ cồn trên 40% tính theo thể tích(SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089030',
    Description: 'Rượu sam-su khác có nồng độ cồn không quá 40% tính theo thể tích (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089040',
    Description: 'Rượu sam-su khác có nồng độ cồn trên 40% tính theo thể tích (SEN)',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089050',
    Description: 'Rượu arrack hoặc rượu dứa có nồng độ cồn không quá 40% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089060',
    Description: 'Rượu arrack hoặc rượu dứa có nồng độ cồn trên 40% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089070',
    Description: 'Rượu đắng và các loại đồ uống tương tự có nồng độ cồn không quá 57% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089080',
    Description: 'Rượu đắng và các loại đồ uống tương tự có nồng độ cồn trên 57% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '22089091',
    Description: 'Có nồng độ cồn không quá 1,14% tính theo thể tích',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22089099',
    Description: 'Loại khác',
    Unit: 'lít/kg',
  },
  {
    HsCode: '22090000',
    Description: 'Giấm và chất thay thế giấm làm từ axit axetic',
    Unit: 'lít/kg',
  },
  {
    HsCode: '',
    Description: '(1) Tham khảo TCVN 7045:2013',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 23',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Phế liệu và phế thải từ ngành công nghiệp thực phẩm; thức ăn gia súc đã chế biến',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Nhóm 23.09 kể cả các sản phẩm dùng trong chăn nuôi động vật, chưa được ghi hoặc chi tiết ở nơi khác, thu được từ quá trình chế biến nguyên liệu gốc thực vật hoặc động vật đến mức các nguyên liệu đó mất hết tính chất cơ bản của nguyên liệu ban đầu, trừ các phế liệu thực vật, phế thải thực vật và các sản phẩm phụ từ quá trình chế biến đó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 2306.41, khái niệm “hạt cải dầu (rape seeds hoặc colza seeds) có hàm lượng axit eruxic thấp” có nghĩa là các loại hạt đã được định nghĩa trong Chú giải phân nhóm 1 của Chương 12.',
    Unit: '',
  },
  {
    HsCode: '2301',
    Description:
      'Bột mịn, bột thô và viên, từ thịt hoặc phụ phẩm dạng thịt sau giết mổ, từ cá hoặc từ động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác, không thích hợp dùng làm thức ăn cho người; tóp mỡ',
    Unit: '',
  },
  {
    HsCode: '23011000',
    Description: 'Bột mịn, bột thô và viên, từ thịt hoặc phụ phẩm dạng thịt sau giết mổ; tóp mỡ',
    Unit: 'kg',
  },
  {
    HsCode: '230120',
    Description:
      'Bột mịn, bột thô và viên, từ cá hoặc từ động vật giáp xác, động vật thân mềm hoặc động vật thủy sinh không xương sống khác:',
    Unit: '',
  },
  {
    HsCode: '23012010',
    Description: 'Từ cá, có hàm lượng protein dưới 60% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '23012020',
    Description: 'Từ cá, có hàm lượng protein từ 60% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '23012090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2302',
    Description:
      'Cám, tấm và phế liệu khác, ở dạng viên hoặc không ở dạng viên, thu được từ quá trình giần, sàng, rây, xay xát hoặc chế biến cách khác từ các loại ngũ cốc hoặc các loại cây họ đậu',
    Unit: '',
  },
  {
    HsCode: '23021000',
    Description: 'Từ ngô',
    Unit: 'kg',
  },
  {
    HsCode: '230230',
    Description: 'Từ lúa mì:',
    Unit: '',
  },
  {
    HsCode: '23023010',
    Description: 'Cám và cám mịn (pollard)(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '23023090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '230240',
    Description: 'Từ ngũ cốc khác:',
    Unit: '',
  },
  {
    HsCode: '23024010',
    Description: 'Từ thóc gạo',
    Unit: 'kg',
  },
  {
    HsCode: '23024090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '23025000',
    Description: 'Từ cây họ đậu',
    Unit: 'kg',
  },
  {
    HsCode: '2303',
    Description:
      'Phế liệu từ quá trình sản xuất tinh bột và phế liệu tương tự, bã ép củ cải đường, bã mía và phế liệu khác từ quá trình sản xuất đường, bã và phế liệu từ quá trình ủ hoặc chưng cất, ở dạng viên hoặc không ở dạng viên',
    Unit: '',
  },
  {
    HsCode: '230310',
    Description: 'Phế liệu từ quá trình sản xuất tinh bột và phế liệu tương tự:',
    Unit: '',
  },
  {
    HsCode: '23031010',
    Description: 'Từ sắn hoặc cọ sago',
    Unit: 'kg',
  },
  {
    HsCode: '23031090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '23032000',
    Description: 'Bã ép củ cải đường, bã mía và phế liệu khác từ quá trình sản xuất đường',
    Unit: 'kg',
  },
  {
    HsCode: '23033000',
    Description: 'Bã và phế liệu từ quá trình ủ hoặc chưng cất',
    Unit: 'kg',
  },
  {
    HsCode: '2304',
    Description:
      'Khô dầu và phế liệu rắn khác, đã hoặc chưa xay hoặc ở dạng viên, thu được từ quá trình chiết xuất dầu đậu tương',
    Unit: '',
  },
  {
    HsCode: '23040010',
    Description: 'Bột đậu tương đã được khử chất béo, thích hợp dùng làm thức ăn cho người(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Bột đậu tương thô:',
    Unit: '',
  },
  {
    HsCode: '23040021',
    Description: 'Thích hợp dùng làm thức ăn cho người(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '23040029',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '23040090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '23050000',
    Description:
      'Khô dầu và phế liệu rắn khác, đã hoặc chưa xay hoặc ở dạng viên, thu được từ quá trình chiết xuất dầu lạc',
    Unit: 'kg',
  },
  {
    HsCode: '2306',
    Description:
      'Khô dầu và phế liệu rắn khác, đã hoặc chưa xay hoặc ở dạng viên, thu được từ quá trình chiết xuất chất béo hoặc dầu thực vật hoặc vi sinh vật, trừ các loại thuộc nhóm 23.04 hoặc 23.05',
    Unit: '',
  },
  {
    HsCode: '23061000',
    Description: 'Từ hạt bông',
    Unit: 'kg',
  },
  {
    HsCode: '23062000',
    Description: 'Từ hạt lanh',
    Unit: 'kg',
  },
  {
    HsCode: '23063000',
    Description: 'Từ hạt hướng dương',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Từ hạt cải dầu (rape seeds hoặc colza seeds):',
    Unit: '',
  },
  {
    HsCode: '230641',
    Description: 'Từ hạt cải dầu (rape seeds hoặc colza seeds) có hàm lượng axit eruxic thấp:',
    Unit: '',
  },
  {
    HsCode: '23064110',
    Description: 'Từ hạt cải dầu rape có hàm lượng axit eruxic thấp',
    Unit: 'kg',
  },
  {
    HsCode: '23064120',
    Description: 'Từ hạt cải dầu colza có hàm lượng axit eruxic thấp',
    Unit: 'kg',
  },
  {
    HsCode: '230649',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '23064910',
    Description: 'Từ hạt cải dầu rape khác',
    Unit: 'kg',
  },
  {
    HsCode: '23064920',
    Description: 'Từ hạt cải dầu colza khác',
    Unit: 'kg',
  },
  {
    HsCode: '23065000',
    Description: 'Từ dừa hoặc cùi dừa',
    Unit: 'kg',
  },
  {
    HsCode: '230660',
    Description: 'Từ hạt hoặc nhân hạt cọ:',
    Unit: '',
  },
  {
    HsCode: '23066010',
    Description: 'Dạng xay hoặc dạng viên',
    Unit: 'kg',
  },
  {
    HsCode: '23066090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '230690',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '23069010',
    Description: 'Từ mầm ngô',
    Unit: 'kg',
  },
  {
    HsCode: '23069090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '23070000',
    Description: 'Bã rượu vang; cặn rượu',
    Unit: 'kg',
  },
  {
    HsCode: '23080000',
    Description:
      'Nguyên liệu thực vật và phế liệu thực vật, phế liệu và sản phẩm phụ từ thực vật, ở dạng viên hoặc không ở dạng viên, loại dùng trong chăn nuôi động vật, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: 'kg',
  },
  {
    HsCode: '2309',
    Description: 'Chế phẩm dùng trong chăn nuôi động vật',
    Unit: '',
  },
  {
    HsCode: '230910',
    Description: 'Thức ăn cho chó hoặc mèo, đã đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '23091010',
    Description: 'Chứa thịt',
    Unit: 'kg',
  },
  {
    HsCode: '23091090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '230990',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thức ăn hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '23099011',
    Description: 'Loại dùng cho gia cầm',
    Unit: 'kg',
  },
  {
    HsCode: '23099012',
    Description: 'Loại dùng cho lợn',
    Unit: 'kg',
  },
  {
    HsCode: '23099013',
    Description: 'Loại dùng cho tôm',
    Unit: 'kg',
  },
  {
    HsCode: '23099014',
    Description: 'Loại dùng cho động vật linh trưởng',
    Unit: 'kg',
  },
  {
    HsCode: '23099019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '23099020',
    Description: 'Chất tổng hợp, chất bổ sung thức ăn hoặc phụ gia thức ăn',
    Unit: 'kg',
  },
  {
    HsCode: '23099090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 24',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Thuốc lá và nguyên liệu thay thế lá thuốc lá đã chế biến; các sản phẩm, chứa hoặc không chứa nicotin, dùng để hút mà không cần đốt cháy; các sản phẩm chứa nicotin khác dùng để nạp nicotin vào cơ thể con người',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm các loại thuốc lá dược phẩm (Chương 30).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Bất kì sản phẩm nào có thể phân loại được vào nhóm 24.04 và bất kỳ nhóm nào khác thuộc Chương này, thì được phân loại vào nhóm 24.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 24.04, thuật ngữ “hút mà không cần đốt cháy” có nghĩa việc hút thông qua thiết bị làm nóng hoặc thiết bị khác, không cần đốt cháy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 2403.11, thuật ngữ ""thuốc lá để sử dụng với tẩu nước"" có nghĩa là thuốc lá hút sử dụng bằng tẩu nước và thuốc lá này bao gồm hỗn hợp thuốc lá và glyxerin, có hoặc không chứa dầu thơm và tinh dầu, mật mía hoặc đường, và có hoặc không có hương thơm từ quả. Tuy nhiên, những sản phẩm không chứa thuốc lá dùng để hút sử dụng tẩu nước thì bị loại trừ ra khỏi phân nhóm này.',
    Unit: '',
  },
  {
    HsCode: '2401',
    Description: 'Lá thuốc lá chưa chế biến; phế liệu lá thuốc lá',
    Unit: '',
  },
  {
    HsCode: '240110',
    Description: 'Lá thuốc lá chưa tước cọng:',
    Unit: '',
  },
  {
    HsCode: '24011010',
    Description: 'Loại Virginia, đã sấy bằng không khí nóng (flue-cured)',
    Unit: 'kg',
  },
  {
    HsCode: '24011020',
    Description: 'Loại Virginia, trừ loại sấy bằng không khí nóng ',
    Unit: 'kg',
  },
  {
    HsCode: '24011040',
    Description: 'Loại Burley',
    Unit: 'kg',
  },
  {
    HsCode: '24011050',
    Description: 'Loại khác, được sấy bằng không khí nóng',
    Unit: 'kg',
  },
  {
    HsCode: '24011090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '240120',
    Description: 'Lá thuốc lá, đã tước cọng một phần hoặc toàn bộ:',
    Unit: '',
  },
  {
    HsCode: '24012010',
    Description: 'Loại Virginia, đã sấy bằng không khí nóng (flue-cured)',
    Unit: 'kg',
  },
  {
    HsCode: '24012020',
    Description: 'Loại Virginia, trừ loại sấy bằng không khí nóng ',
    Unit: 'kg',
  },
  {
    HsCode: '24012030',
    Description: 'Loại Oriental',
    Unit: 'kg',
  },
  {
    HsCode: '24012040',
    Description: 'Loại Burley',
    Unit: 'kg',
  },
  {
    HsCode: '24012050',
    Description: 'Loại khác, được sấy bằng không khí nóng (flue-cured)',
    Unit: 'kg',
  },
  {
    HsCode: '24012090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '240130',
    Description: 'Phế liệu lá thuốc lá:',
    Unit: '',
  },
  {
    HsCode: '24013010',
    Description: 'Cọng thuốc lá',
    Unit: 'kg',
  },
  {
    HsCode: '24013090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2402',
    Description:
      'Xì gà, xì gà xén hai đầu, xì gà nhỏ và thuốc lá điếu, từ lá thuốc lá hoặc từ các nguyên liệu thay thế lá thuốc lá',
    Unit: '',
  },
  {
    HsCode: '24021000',
    Description: 'Xì gà, xì gà xén hai đầu và xì gà nhỏ, có chứa lá thuốc lá ',
    Unit: 'kg',
  },
  {
    HsCode: '240220',
    Description: 'Thuốc lá điếu có chứa lá thuốc lá:',
    Unit: '',
  },
  {
    HsCode: '24022010',
    Description: 'Thuốc lá Bi-đi (Beedies) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24022020',
    Description: 'Thuốc lá điếu, có chứa thành phần từ đinh hương (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24022090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '240290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '24029010',
    Description: 'Xì gà, xì gà xén hai đầu và xì gà nhỏ làm từ các nguyên liệu thay thế lá thuốc lá',
    Unit: 'kg',
  },
  {
    HsCode: '24029020',
    Description: 'Thuốc lá điếu làm từ các nguyên liệu thay thế lá thuốc lá ',
    Unit: 'kg',
  },
  {
    HsCode: '2403',
    Description:
      'Lá thuốc lá đã chế biến khác và các nguyên liệu thay thế lá thuốc lá đã chế biến; thuốc lá ""thuần nhất"" hoặc thuốc lá ""hoàn nguyên""; chiết xuất và tinh chất lá thuốc lá',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lá thuốc lá để hút (smoking), có hoặc không chứa chất thay thế lá thuốc lá với tỷ lệ bất kỳ:',
    Unit: '',
  },
  {
    HsCode: '240311',
    Description: 'Thuốc lá sử dụng tẩu nước đã được chi tiết ở Chú giải phân nhóm 1 Chương này:',
    Unit: '',
  },
  {
    HsCode: '24031110',
    Description: 'Đã được đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '24031190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '240319',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đã được đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '24031911',
    Description: 'Ang Hoon (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24031919',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '24031920',
    Description: 'Lá thuốc lá đã chế biến khác để sản xuất thuốc lá điếu',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '24031991',
    Description: 'Ang Hoon (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24031999',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '240391',
    Description: 'Thuốc lá ""thuần nhất"" hoặc ""hoàn nguyên"":',
    Unit: '',
  },
  {
    HsCode: '24039110',
    Description: 'Đã được đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '24039190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '240399',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '24039910',
    Description: 'Chiết xuất và tinh chất lá thuốc lá ',
    Unit: 'kg',
  },
  {
    HsCode: '24039930',
    Description: 'Các nguyên liệu thay thế lá thuốc lá đã chế biến',
    Unit: 'kg',
  },
  {
    HsCode: '24039940',
    Description: 'Thuốc lá bột để hít, ngậm, khô hoặc không khô (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24039950',
    Description: 'Thuốc lá dạng nhai và dạng ngậm (sucking)',
    Unit: 'kg',
  },
  {
    HsCode: '24039990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2404',
    Description:
      'Sản phẩm chứa lá thuốc lá, thuốc lá hoàn nguyên, nicotin, hoặc các nguyên liệu thay thế lá thuốc lá hoặc các nguyên liệu thay thế nicotin, dùng để hút mà không cần đốt cháy; các sản phẩm chứa nicotin khác dùng để nạp nicotin vào cơ thể con người',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các sản phẩm dùng để hút mà không cần đốt cháy:',
    Unit: '',
  },
  {
    HsCode: '24041100',
    Description: 'Chứa lá thuốc lá hoặc thuốc lá hoàn nguyên',
    Unit: 'kg',
  },
  {
    HsCode: '240412',
    Description: 'Loại khác, chứa nicotin:',
    Unit: '',
  },
  {
    HsCode: '24041210',
    Description: 'Dạng lỏng hoặc gel dùng cho thuốc lá điện tử (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24041290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '240419',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '24041910',
    Description: 'Chứa các nguyên liệu thay thế lá thuốc lá',
    Unit: 'kg',
  },
  {
    HsCode: '24041920',
    Description: 'Chứa các nguyên liệu thay thế nicotin',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '240491',
    Description: 'Loại dùng qua đường miệng:',
    Unit: '',
  },
  {
    HsCode: '24049110',
    Description: 'Kẹo cao su có nicotin (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24049190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '240492',
    Description: 'Loại thẩm thấu qua da:',
    Unit: '',
  },
  {
    HsCode: '24049210',
    Description: 'Miếng dán nicotin (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '24049290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '24049900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN V',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'KHOÁNG SẢN',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 25',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Muối; lưu huỳnh; đất và đá; thạch cao, vôi và xi măng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trừ một số trường hợp hoặc Chú giải 4 của Chương này có những yêu cầu khác, các nhóm của Chương này chỉ bao gồm các sản phẩm ở dạng thô hoặc mới chỉ được làm sạch (thậm chí có thể dùng hóa chất để loại bỏ tạp chất nhưng không làm thay đổi cấu trúc sản phẩm), đã nghiền, ép, nghiền thành bột, bột mịn, đã rây, sàng, tuyển bằng phương pháp tuyển nổi, tách bằng từ hoặc các quy trình cơ hoặc lý khác (trừ sự kết tinh), nhưng không phải là các sản phẩm đã nung, tôi, thu được từ việc trộn lẫn hoặc từ quy trình khác với quy trình đã ghi trong từng nhóm hàng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm của Chương này có thể chứa chất chống bụi với điều kiện các phụ gia đó không làm cho sản phẩm đặc biệt phù hợp cho công dụng riêng hơn là công dụng chung.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Lưu huỳnh thăng hoa, lưu huỳnh kết tủa hoặc lưu huỳnh dạng keo (nhóm 28.02);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Chất màu từ đất có hàm lượng sắt hóa hợp Fe2O3 chiếm từ 70% trở lên tính theo khối lượng (nhóm 28.21);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Dược phẩm hoặc các sản phẩm khác thuộc Chương 30;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Nước hoa, mỹ phẩm hoặc các chế phẩm vệ sinh (Chương 33);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Hỗn hợp dolomite ramming (nhóm 38.16);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Đá lát, đá lát lề đường hoặc phiến đá lát đường (nhóm 68.01); đá khối dùng để khảm hoặc các loại tương tự (nhóm 68.02); đá lợp mái, đá ốp mặt hoặc đá kè đập (nhóm 68.03);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Đá quý hoặc đá bán quý (nhóm 71.02 hoặc 71.03);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Tinh thể nuôi cấy (trừ các bộ phận quang học), mỗi tinh thể nặng từ 2,5g trở lên bằng natri clorua hoặc oxit magiê, thuộc nhóm 38.24; các bộ phận quang học làm từ natri clorua hoặc oxit magiê (nhóm 90.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Phấn xoa đầu gậy bi-a (nhóm 95.04); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Phấn vẽ hoặc phấn viết hoặc phấn dùng cho thợ may (nhóm 96.09).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Bất kỳ sản phẩm nào có thể xếp vào nhóm 25.17 và vào bất kỳ nhóm nào khác của Chương này thì phải được xếp vào nhóm 25.17.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Không kể những đề cập khác, nhóm 25.30 bao gồm: đá vermiculite, đá trân châu và clorit, chưa giãn nở; đất màu đã hoặc chưa nung, hoặc pha trộn với nhau; các loại oxit sắt mica tự nhiên; đá bọt biển (đã hoặc chưa ở dạng miếng được đánh bóng); hổ phách; đá bọt biển kết tụ và hổ phách kết tụ, ở dạng phiến, thanh, que hoặc các dạng tương tự, chưa gia công sau khi đúc; hạt huyền; strontianit (đã hoặc chưa nung), trừ oxit stronti; mảnh vỡ của đồ gốm, gạch hoặc bê tông.',
    Unit: '',
  },
  {
    HsCode: '2501',
    Description:
      'Muối (kể cả muối thực phẩm và muối đã bị làm biến tính) và natri clorua tinh khiết, có hoặc không ở trong dung dịch nước hoặc có chứa chất chống đóng bánh hoặc chất làm tăng độ chẩy; nước biển',
    Unit: '',
  },
  {
    HsCode: '25010010',
    Description: 'Muối thực phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '25010020',
    Description: 'Muối mỏ chưa chế biến',
    Unit: 'kg',
  },
  {
    HsCode: '25010050',
    Description: 'Nước biển',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '25010091',
    Description:
      'Có hàm lượng natri clorua trên 60% nhưng dưới 97%, tính theo hàm lượng khô, đã bổ sung thêm i ốt (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '25010093',
    Description: 'Có hàm lượng natri clorua từ 97% trở lên, tính theo hàm lượng khô (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '25010099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '25020000',
    Description: 'Pirít sắt chưa nung',
    Unit: 'kg',
  },
  {
    HsCode: '25030000',
    Description: 'Lưu huỳnh các loại, trừ lưu huỳnh thăng hoa, lưu huỳnh kết tủa và lưu huỳnh dạng keo',
    Unit: 'kg',
  },
  {
    HsCode: '2504',
    Description: 'Graphit tự nhiên',
    Unit: '',
  },
  {
    HsCode: '25041000',
    Description: 'Ở dạng bột hoặc dạng mảnh',
    Unit: 'kg',
  },
  {
    HsCode: '25049000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2505',
    Description: 'Các loại cát tự nhiên, đã hoặc chưa nhuộm màu, trừ cát chứa kim loại thuộc Chương 26',
    Unit: '',
  },
  {
    HsCode: '25051000',
    Description: 'Cát oxit silic và cát thạch anh',
    Unit: 'kg/m3',
  },
  {
    HsCode: '25059000',
    Description: 'Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '2506',
    Description:
      'Thạch anh (trừ cát tự nhiên); quartzite, đã hoặc chưa đẽo thô hoặc mới chỉ được cắt, bằng cưa hoặc cách khác, thành khối hoặc tấm hình chữ nhật (kể cả hình vuông)',
    Unit: '',
  },
  {
    HsCode: '25061000',
    Description: 'Thạch anh',
    Unit: 'kg',
  },
  {
    HsCode: '25062000',
    Description: 'Quartzite',
    Unit: 'kg',
  },
  {
    HsCode: '25070000',
    Description: 'Cao lanh và đất sét cao lanh khác, đã hoặc chưa nung',
    Unit: 'kg',
  },
  {
    HsCode: '2508',
    Description:
      'Đất sét khác (không kể đất sét trương nở thuộc nhóm 68.06), andalusite, kyanite và sillimanite, đã hoặc chưa nung; mullite; đất chịu lửa (chamotte) hoặc đất dinas',
    Unit: '',
  },
  {
    HsCode: '25081000',
    Description: 'Bentonite',
    Unit: 'kg',
  },
  {
    HsCode: '25083000',
    Description: 'Đất sét chịu lửa',
    Unit: 'kg',
  },
  {
    HsCode: '250840',
    Description: 'Đất sét khác:',
    Unit: '',
  },
  {
    HsCode: '25084010',
    Description: 'Đất hồ (đất tẩy màu)',
    Unit: 'kg',
  },
  {
    HsCode: '25084090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '25085000',
    Description: '- Andalusite, kyanite và sillimanite',
    Unit: 'kg',
  },
  {
    HsCode: '25086000',
    Description: 'Mullite',
    Unit: 'kg',
  },
  {
    HsCode: '25087000',
    Description: 'Đất chịu lửa hoặc đất dinas',
    Unit: 'kg',
  },
  {
    HsCode: '25090000',
    Description: 'Đá phấn',
    Unit: 'kg',
  },
  {
    HsCode: '2510',
    Description: 'Canxi phosphat tự nhiên, canxi phosphat nhôm tự nhiên và đá phấn có chứa phosphat',
    Unit: '',
  },
  {
    HsCode: '251010',
    Description: 'Chưa nghiền:',
    Unit: '',
  },
  {
    HsCode: '25101010',
    Description: 'Apatít (apatite)',
    Unit: 'kg',
  },
  {
    HsCode: '25101090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '251020',
    Description: 'Đã nghiền:',
    Unit: '',
  },
  {
    HsCode: '25102010',
    Description: 'Apatít (apatite)',
    Unit: 'kg',
  },
  {
    HsCode: '25102090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2511',
    Description:
      'Bari sulphat tự nhiên (barytes); bari carbonat tự nhiên (witherite), đã hoặc chưa nung, trừ bari oxit thuộc nhóm 28.16',
    Unit: '',
  },
  {
    HsCode: '25111000',
    Description: 'Bari sulphat tự nhiên (barytes)',
    Unit: 'kg',
  },
  {
    HsCode: '25112000',
    Description: 'Bari carbonat tự nhiên (witherite)',
    Unit: 'kg',
  },
  {
    HsCode: '25120000',
    Description:
      'Bột hóa thạch silic (ví dụ, đất tảo cát, tripolite và diatomite) và đất silic tương tự, đã hoặc chưa nung, có trọng lượng riêng biểu kiến không quá 1',
    Unit: 'kg',
  },
  {
    HsCode: '2513',
    Description:
      'Đá bọt; đá nhám; corundum tự nhiên, đá garnet tự nhiên và đá mài tự nhiên khác, đã hoặc chưa qua xử lý nhiệt',
    Unit: '',
  },
  {
    HsCode: '25131000',
    Description: 'Đá bọt',
    Unit: 'kg',
  },
  {
    HsCode: '25132000',
    Description: 'Đá nhám, corundum tự nhiên, đá garnet tự nhiên và đá mài tự nhiên khác',
    Unit: 'kg',
  },
  {
    HsCode: '25140000',
    Description:
      'Đá phiến, đã hoặc chưa đẽo thô hoặc mới chỉ cắt, bằng cưa hoặc cách khác, thành khối hoặc tấm hình chữ nhật (kể cả hình vuông)',
    Unit: 'kg',
  },
  {
    HsCode: '2515',
    Description:
      'Đá hoa (marble), đá travertine, ecaussine và đá vôi khác để làm tượng đài hoặc đá xây dựng có trọng lượng riêng biểu kiến từ 2,5 trở lên, và thạch cao tuyết hoa, đã hoặc chưa đẽo thô hoặc mới chỉ cắt, bằng cưa hoặc bằng cách khác, thành các khối hoặc tấm hình chữ nhật (kể cả hình vuông)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đá hoa (marble) và đá travertine:',
    Unit: '',
  },
  {
    HsCode: '25151100',
    Description: 'Thô hoặc đã đẽo thô',
    Unit: 'kg',
  },
  {
    HsCode: '251512',
    Description: 'Mới chỉ cắt, bằng cưa hoặc cách khác, thành các khối hoặc tấm hình chữ nhật (kể cả hình vuông):',
    Unit: '',
  },
  {
    HsCode: '25151210',
    Description: 'Dạng khối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '25151220',
    Description: 'Dạng tấm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '25152000',
    Description: 'Ecaussine và đá vôi khác để làm tượng đài hoặc đá xây dựng; thạch cao tuyết hoa',
    Unit: 'kg',
  },
  {
    HsCode: '2516',
    Description:
      'Đá granit, đá pocfia, bazan, đá cát kết (sa thạch) và đá khác để làm tượng đài hoặc đá xây dựng, đã hoặc chưa đẽo thô hoặc mới chỉ cắt bằng cưa hoặc cách khác, thành khối hoặc tấm hình chữ nhật (kể cả hình vuông)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Granit:',
    Unit: '',
  },
  {
    HsCode: '25161100',
    Description: 'Thô hoặc đã đẽo thô',
    Unit: 'kg',
  },
  {
    HsCode: '251612',
    Description: 'Mới chỉ cắt, bằng cưa hoặc cách khác, thành các khối hoặc tấm hình chữ nhật (kể cả hình vuông):',
    Unit: '',
  },
  {
    HsCode: '25161210',
    Description: 'Dạng khối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '25161220',
    Description: 'Dạng tấm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '251620',
    Description: 'Đá cát kết:',
    Unit: '',
  },
  {
    HsCode: '25162010',
    Description: 'Thô hoặc đã đẽo thô',
    Unit: 'kg',
  },
  {
    HsCode: '25162020',
    Description: 'Mới chỉ cắt, bằng cưa hoặc cách khác, thành khối hoặc tấm hình chữ nhật (kể cả hình vuông)',
    Unit: 'kg',
  },
  {
    HsCode: '25169000',
    Description: 'Đá khác để làm tượng đài hoặc làm đá xây dựng',
    Unit: 'kg',
  },
  {
    HsCode: '2517',
    Description:
      'Đá cuội, sỏi, đá đã vỡ hoặc nghiền, chủ yếu để làm cốt bê tông, để rải đường bộ hoặc đường sắt hoặc đá ballast, đá cuội nhỏ và đá lửa tự nhiên (flint) khác, đã hoặc chưa qua xử lý nhiệt; đá dăm từ xỉ, từ xỉ luyện kimhoặc từ phế thải công nghiệp tương tự, có hoặc không kết hợp với các vật liệu trong phần đầu của nhóm này; đá dăm trộn nhựa đường, đá ở dạng viên, mảnh và bột, làm từ các loại đá thuộc nhóm 25.15 hoặc 25.16, đã hoặc chưa qua xử lý nhiệt',
    Unit: '',
  },
  {
    HsCode: '25171000',
    Description:
      'Đá cuội, sỏi, đá đã vỡ hoặc nghiền, chủ yếu để làm cốt bê tông, để rải đường bộ hoặc đường sắt hoặc đá ballast khác, đá cuội nhỏ và đá lửa tự nhiên (flint) khác, đã hoặc chưa qua xử lý nhiệt',
    Unit: 'kg/m3',
  },
  {
    HsCode: '25172000',
    Description:
      'Đá dăm từ xỉ, từ xỉ luyện kim hoặc từ phế thải công nghiệp tương tự, có hoặc không kết hợp với các vật liệu của phân nhóm 2517.10',
    Unit: 'kg/m3',
  },
  {
    HsCode: '25173000',
    Description: 'Đá dăm trộn nhựa đường',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description:
      'Đá ở dạng viên, mảnh và bột, làm từ các loại đá thuộc nhóm 25.15 hoặc 25.16, đã hoặc chưa qua xử lý nhiệt:',
    Unit: '',
  },
  {
    HsCode: '25174100',
    Description: 'Từ đá hoa (marble)',
    Unit: 'kg/m3',
  },
  {
    HsCode: '25174900',
    Description: 'Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '2518',
    Description:
      'Dolomite, đã hoặc chưa nung hoặc thiêu kết, kể cả dolomite đã đẽo thô hoặc mới chỉ cắt bằng cưa hoặc các cách khác, thành các khối hoặc tấm hình chữ nhật (kể cả hình vuông)',
    Unit: '',
  },
  {
    HsCode: '25181000',
    Description: 'Dolomite, chưa nung hoặc thiêu kết',
    Unit: 'kg',
  },
  {
    HsCode: '25182000',
    Description: 'Dolomite đã nung hoặc thiêu kết',
    Unit: 'kg',
  },
  {
    HsCode: '2519',
    Description:
      'Magiê carbonat tự nhiên (magiesite); magiê ôxít nấu chảy; magiê ôxít nung trơ (thiêu kết), có hoặc không thêm một lượng nhỏ ôxít khác trước khi thiêu kết; magiê ôxít khác, tinh khiết hoặc không tinh khiết',
    Unit: '',
  },
  {
    HsCode: '25191000',
    Description: 'Magiê carbonat tự nhiên (magnesite)',
    Unit: 'kg',
  },
  {
    HsCode: '251990',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '25199010',
    Description: 'Magiê ôxít nấu chảy; magiê ôxít nung trơ (thiêu kết)',
    Unit: 'kg',
  },
  {
    HsCode: '25199090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2520',
    Description:
      'Thạch cao; thạch cao khan; thạch cao plaster (bao gồm thạch cao nung hoặc canxi sulphat đã nung), đã hoặc chưa nhuộm màu, có hoặc không thêm một lượng nhỏ chất xúc tác hoặc chất ức chế',
    Unit: '',
  },
  {
    HsCode: '25201000',
    Description: 'Thạch cao; thạch cao khan',
    Unit: 'kg',
  },
  {
    HsCode: '252020',
    Description: 'Thạch cao plaster:',
    Unit: '',
  },
  {
    HsCode: '25202010',
    Description: 'Loại phù hợp dùng trong nha khoa',
    Unit: 'kg',
  },
  {
    HsCode: '25202090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '25210000',
    Description: 'Chất gây chảy gốc đá vôi; đá vôi và đá có chứa canxi khác, dùng để sản xuất vôi hoặc xi măng',
    Unit: 'kg',
  },
  {
    HsCode: '2522',
    Description: 'Vôi sống, vôi tôi và vôi thủy lực, trừ oxit canxi và hydroxit canxi thuộc nhóm 28.25',
    Unit: '',
  },
  {
    HsCode: '25221000',
    Description: 'Vôi sống',
    Unit: 'kg',
  },
  {
    HsCode: '25222000',
    Description: 'Vôi tôi',
    Unit: 'kg',
  },
  {
    HsCode: '25223000',
    Description: 'Vôi thủy lực',
    Unit: 'kg',
  },
  {
    HsCode: '2523',
    Description:
      'Xi măng poóc lăng (1), xi măng nhôm, xi măng xỉ (xốp), xi măng super sulphat và xi măng thủy lực tương tự, đã hoặc chưa pha màu hoặc ở dạng clanhke',
    Unit: '',
  },
  {
    HsCode: '252310',
    Description: 'Clanhke xi măng (1):',
    Unit: '',
  },
  {
    HsCode: '25231010',
    Description: 'Loại dùng để sản xuất xi măng trắng',
    Unit: 'kg',
  },
  {
    HsCode: '25231090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Xi măng poóc lăng (1):',
    Unit: '',
  },
  {
    HsCode: '25232100',
    Description: 'Xi măng trắng, đã hoặc chưa pha màu nhân tạo',
    Unit: 'kg',
  },
  {
    HsCode: '252329',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '25232910',
    Description: 'Xi măng màu',
    Unit: 'kg',
  },
  {
    HsCode: '25232990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '25233000',
    Description: 'Xi măng nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '25239000',
    Description: 'Xi măng thủy lực khác',
    Unit: 'kg',
  },
  {
    HsCode: '2524',
    Description: 'Amiăng.',
    Unit: '',
  },
  {
    HsCode: '25241000',
    Description: 'Crocidolite',
    Unit: 'kg',
  },
  {
    HsCode: '25249000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2525',
    Description: 'Mi ca, kể cả mi ca tách lớp; phế liệu mi ca',
    Unit: '',
  },
  {
    HsCode: '25251000',
    Description: 'Mi ca thô và mi ca đã tách thành tấm hoặc lớp',
    Unit: 'kg',
  },
  {
    HsCode: '25252000',
    Description: 'Bột mi ca',
    Unit: 'kg',
  },
  {
    HsCode: '25253000',
    Description: 'Phế liệu mi ca',
    Unit: 'kg',
  },
  {
    HsCode: '2526',
    Description:
      'Quặng steatit tự nhiên, đã hoặc chưa đẽo thô hoặc mới chỉ cắt, bằng cưa hoặc các cách khác, thành các khối hoặc tấm hình chữ nhật (kể cả hình vuông); talc',
    Unit: '',
  },
  {
    HsCode: '25261000',
    Description: 'Chưa nghiền, chưa làm thành bột',
    Unit: 'kg',
  },
  {
    HsCode: '252620',
    Description: 'Đã nghiền hoặc làm thành bột:',
    Unit: '',
  },
  {
    HsCode: '25262010',
    Description: 'Bột talc',
    Unit: 'kg',
  },
  {
    HsCode: '25262090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '25280000',
    Description:
      'Quặng borat tự nhiên và tinh quặng borat (đã hoặc chưa nung), nhưng không kể borat tách từ nước biển tự nhiên; axit boric tự nhiên chứa không quá 85% H3BO3 tính theo trọng lượng khô',
    Unit: 'kg',
  },
  {
    HsCode: '2529',
    Description: 'Tràng thạch (đá bồ tát); lơxit (leucite), nephelin và nephelin xienit; khoáng fluorite',
    Unit: '',
  },
  {
    HsCode: '252910',
    Description: 'Tràng thạch (đá bồ tát):',
    Unit: '',
  },
  {
    HsCode: '25291010',
    Description: 'Potash tràng thạch; soda tràng thạch(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '25291090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Khoáng fluorite:',
    Unit: '',
  },
  {
    HsCode: '25292100',
    Description: 'Có chứa canxi florua không quá 97% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '25292200',
    Description: 'Có chứa canxi florua trên 97% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '25293000',
    Description: 'Lơxit; nephelin và nephelin xienit',
    Unit: 'kg',
  },
  {
    HsCode: '2530',
    Description: 'Các chất khoáng chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '25301000',
    Description: 'Vermiculite, đá trân châu và clorit, chưa giãn nở',
    Unit: 'kg',
  },
  {
    HsCode: '253020',
    Description: 'Kiezerit, epsomit (magiê sulphat tự nhiên):',
    Unit: '',
  },
  {
    HsCode: '25302010',
    Description: 'Kiezerit',
    Unit: 'kg',
  },
  {
    HsCode: '25302020',
    Description: 'Epsomite (magiê sulphat tự nhiên)',
    Unit: 'kg',
  },
  {
    HsCode: '253090',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '25309010',
    Description: 'Cát zircon cỡ hạt micron (zircon silicat) loại dùng làm chất cản quang',
    Unit: 'kg',
  },
  {
    HsCode: '25309090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1) Tham khảo TCVN 2682:2020, TCVN 7024:2013',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 26',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quặng, xỉ và tro',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Xỉ hoặc các phế liệu công nghiệp tương tự đã được gia công như đá dăm (nhóm 25.17);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Magiê carbonat tự nhiên (magnesite) đã hoặc chưa nung (nhóm 25.19);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Cặn từ thùng chứa dầu có nguồn gốc từ dầu mỏ, chứa chủ yếu là các loại dầu này (nhóm 27.10); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Xỉ bazơ thuộc Chương 31;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Sợi xỉ, bông len đá (rock wool) hoặc các loại sợi khoáng tương tự (nhóm 68.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Phế liệu hoặc mảnh vụn của kim loại quý hoặc kim loại dát phủ bằng kim loại quý; phế liệu hoặc phế thải khác chứa kim loại quý hoặc hỗn hợp kim loại quý, thuộc loại sử dụng chủ yếu cho việc thu hồi kim loại quý (nhóm 71.12 hoặc 85.49); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Đồng, niken hoặc coban sten sản xuất bằng quy trình nấu chảy (Phần XV).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của các nhóm từ 26.01 đến 26.17, thuật ngữ ""quặng"" là khoáng sản của nhiều thực thể khoáng chất thường được dùng trong công nghiệp luyện kim để tách thủy ngân, kim loại thuộc nhóm 28.44 hoặc các kim loại thuộc Phần XIV hoặc XV, ngay cả khi các quặng này không dùng để luyện kim. Tuy nhiên, các nhóm từ 26.01 đến 26.17 không bao gồm các loại khoáng đã qua các quy trình không thông thường đối với ngành công nghiệp luyện kim.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Nhóm 26.20 chỉ áp dụng đối với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Xỉ, tro và cặn dùng trong công nghiệp tách kim loại hoặc dùng để sản xuất các hợp kim hóa học, trừ tro và cặn từ quá trình đốt rác thải đô thị (nhóm 26.21); và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Xỉ, tro và cặn chứa arsen, có chứa hoặc không chứa kim loại, là loại dùng để tách arsen hoặc kim loại hoặc dùng để sản xuất các hợp chất hóa học của chúng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 2620.21, ""cặn của xăng pha chì và cặn của hợp chất chì chống kích nổ"" nghĩa là cặn thu được từ các thùng chứa xăng pha chì và các hợp chất chì chống kích nổ (ví dụ, chì tetraethyl), và bao gồm chủ yếu là chì, hợp chất chì và ôxit sắt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Xỉ, tro và cặn chứa arsen, thủy ngân, tali hoặc các hỗn hợp của chúng, thuộc loại dùng để tách arsen hoặc những kim loại trên hoặc dùng để sản xuất các hợp chất hóa học của chúng, được phân loại vào phân nhóm 2620.60.',
    Unit: '',
  },
  {
    HsCode: '2601',
    Description: 'Quặng sắt và tinh quặng sắt, kể cả pirit sắt đã nung',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quặng sắt và tinh quặng sắt, trừ pirit sắt đã nung:',
    Unit: '',
  },
  {
    HsCode: '260111',
    Description: 'Chưa nung kết:',
    Unit: '',
  },
  {
    HsCode: '26011110',
    Description: 'Hematite và tinh quặng hematite(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '26011190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '260112',
    Description: 'Đã nung kết:',
    Unit: '',
  },
  {
    HsCode: '26011210',
    Description: 'Hematite và tinh quặng hematite(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '26011290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '26012000',
    Description: 'Pirit sắt đã nung',
    Unit: 'kg',
  },
  {
    HsCode: '26020000',
    Description:
      'Quặng mangan và tinh quặng mangan, kể cả quặng mangan chứa sắt và tinh quặng mangan chứa sắt với hàm lượng mangan từ 20% trở lên, tính theo trọng lượng khô',
    Unit: 'kg',
  },
  {
    HsCode: '26030000',
    Description: 'Quặng đồng và tinh quặng đồng',
    Unit: 'kg',
  },
  {
    HsCode: '26040000',
    Description: 'Quặng niken và tinh quặng niken',
    Unit: 'kg',
  },
  {
    HsCode: '26050000',
    Description: 'Quặng coban và tinh quặng coban',
    Unit: 'kg',
  },
  {
    HsCode: '26060000',
    Description: 'Quặng nhôm và tinh quặng nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '26070000',
    Description: 'Quặng chì và tinh quặng chì',
    Unit: 'kg',
  },
  {
    HsCode: '26080000',
    Description: 'Quặng kẽm và tinh quặng kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '26090000',
    Description: 'Quặng thiếc và tinh quặng thiếc',
    Unit: 'kg',
  },
  {
    HsCode: '26100000',
    Description: 'Quặng crôm và tinh quặng crôm',
    Unit: 'kg',
  },
  {
    HsCode: '26110000',
    Description: 'Quặng vonfram và tinh quặng vonfram',
    Unit: 'kg',
  },
  {
    HsCode: '2612',
    Description: 'Quặng urani hoặc quặng thori và tinh quặng urani hoặc tinh quặng thori',
    Unit: '',
  },
  {
    HsCode: '26121000',
    Description: 'Quặng urani và tinh quặng urani ',
    Unit: 'kg',
  },
  {
    HsCode: '26122000',
    Description: 'Quặng thori và tinh quặng thori',
    Unit: 'kg',
  },
  {
    HsCode: '2613',
    Description: 'Quặng molipden và tinh quặng molipden',
    Unit: '',
  },
  {
    HsCode: '26131000',
    Description: 'Đã nung ',
    Unit: 'kg',
  },
  {
    HsCode: '26139000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2614',
    Description: 'Quặng titan và tinh quặng titan',
    Unit: '',
  },
  {
    HsCode: '26140010',
    Description: 'Quặng inmenit và tinh quặng inmenit(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '26140090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2615',
    Description: 'Quặng niobi, tantali, vanadi hoặc zircon và tinh quặng của các loại quặng đó ',
    Unit: '',
  },
  {
    HsCode: '26151000',
    Description: 'Quặng zircon và tinh quặng zircon',
    Unit: 'kg',
  },
  {
    HsCode: '26159000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2616',
    Description: 'Quặng kim loại quý và tinh quặng kim loại quý',
    Unit: '',
  },
  {
    HsCode: '26161000',
    Description: 'Quặng bạc và tinh quặng bạc ',
    Unit: 'kg',
  },
  {
    HsCode: '26169000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2617',
    Description: 'Các quặng khác và tinh quặng của các quặng đó',
    Unit: '',
  },
  {
    HsCode: '26171000',
    Description: 'Quặng antimon và tinh quặng antimon ',
    Unit: 'kg',
  },
  {
    HsCode: '26179000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '26180000',
    Description: 'Xỉ hạt (xỉ cát) từ công nghiệp luyện sắt hoặc thép',
    Unit: 'kg',
  },
  {
    HsCode: '26190000',
    Description: 'Xỉ, xỉ luyện kim (trừ xỉ hạt), vụn xỉ và các phế thải khác từ công nghiệp luyện sắt hoặc thép',
    Unit: 'kg',
  },
  {
    HsCode: '2620',
    Description:
      'Xỉ, tro và cặn (trừ loại thu được từ quá trình sản xuất sắt hoặc thép), có chứa kim loại, arsen hoặc các hợp chất của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chứa chủ yếu là kẽm:',
    Unit: '',
  },
  {
    HsCode: '26201100',
    Description: 'Kẽm tạp chất cứng (sten tráng kẽm)',
    Unit: 'kg',
  },
  {
    HsCode: '26201900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Chứa chủ yếu là chì:',
    Unit: '',
  },
  {
    HsCode: '26202100',
    Description: 'Cặn của xăng pha chì và cặn của hợp chất chì chống kích nổ ',
    Unit: 'kg',
  },
  {
    HsCode: '26202900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '26203000',
    Description: 'Chứa chủ yếu là đồng',
    Unit: 'kg',
  },
  {
    HsCode: '26204000',
    Description: 'Chứa chủ yếu là nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '26206000',
    Description:
      'Chứa arsen, thủy ngân, tali hoặc hỗn hợp của chúng, là loại dùng để tách arsen hoặc những kim loại trên hoặc dùng để sản xuất các hợp chất hóa học của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '26209100',
    Description: 'Chứa antimon, berily, cađimi, crom hoặc các hỗn hợp của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '262099',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '26209910',
    Description: 'Xỉ và phần chưa cháy hết (hardhead) của thiếc(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '26209990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2621',
    Description: 'Xỉ và tro khác, kể cả tro tảo biển (tảo bẹ); tro và cặn từ quá trình đốt rác thải đô thị',
    Unit: '',
  },
  {
    HsCode: '26211000',
    Description: 'Tro và cặn từ quá trình đốt rác thải đô thị ',
    Unit: 'kg',
  },
  {
    HsCode: '262190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '26219010',
    Description: 'Các muối kali thô thu được từ cặn mật củ cải đường trong công nghiệp sản xuất đường (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '26219090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 27',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nhiên liệu khoáng, dầu khoáng và các sản phẩm  chưng cất từ chúng; các chất chứa bi-tum; các loại sáp khoáng chất',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các hợp chất hữu cơ đã được xác định về mặt hóa học riêng biệt, trừ metan và propan tinh khiết đã được phân loại ở nhóm 27.11;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dược phẩm thuộc nhóm 30.03 hoặc 30.04; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Hỗn hợp hydrocarbon chưa no thuộc nhóm 33.01, 33.02 hoặc 38.05.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong nhóm 27.10, khái niệm ""dầu có nguồn gốc từ dầu mỏ và các loại dầu thu được từ khoáng bi-tum"" không chỉ bao gồm dầu có nguồn gốc từ dầu mỏ và dầu thu được từ khoáng bi-tum mà còn bao gồm các loại dầu tương tự, cũng như loại dầu khác chủ yếu chứa hỗn hợp hydrocarbon chưa no, thu được bằng quá trình bất kỳ, với điều kiện trọng lượng cấu tử không thơm cao hơn cấu tử thơm. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, khái niệm này không bao gồm các polyolefin tổng hợp lỏng, loại dưới 60% thể tích chưng cất ở 300°C, sau khi sử dụng phương pháp chưng cất giảm áp suất sẽ chuyển đổi sang 1.013 millibar (Chương 39).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 27.10, ""dầu thải"" có nghĩa là các chất thải chứa chủ yếu là dầu có nguồn gốc từ dầu mỏ và các loại dầu thu từ khoáng bi-tum (theo mô tả Chú giải 2 của Chương này), có hoặc không có nước. Bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các loại dầu không còn dùng được như là sản phẩm ban đầu (ví dụ, dầu bôi trơn đã sử dụng, dầu thủy lực đã sử dụng và dầu dùng cho máy biến điện đã sử dụng);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Dầu cặn từ bể chứa dầu có nguồn gốc từ dầu mỏ, chứa chủ yếu là dầu này và nồng độ chất phụ gia cao (ví dụ, hóa chất) dùng để sản xuất các sản phẩm gốc; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các loại dầu này ở dạng nhũ tương trong nước hoặc hòa lẫn với nước, như dầu thu hồi từ dầu tràn, từ rửa bể chứa dầu, hoặc từ dầu cắt để chạy máy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 2701.11 ""anthracite"" có nghĩa là loại than có giới hạn chất bốc (trong điều kiện khô, không có khoáng chất) không quá 14%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của phân nhóm 2701.12, ""than bi-tum"" là loại than có giới hạn chất bốc (trong điều kiện khô, không có khoáng chất) trên 14% và giới hạn nhiệt lượng từ 5.833 kcal/kg trở lên (trong điều kiện ẩm, không có khoáng chất).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của các phân nhóm 2707.10, 2707.20, 2707.30 và 2707.40 khái niệm “benzol (benzen)”, “toluol (toluen)”, “xylol (xylen)” và ""naphthalen"" áp dụng cho các sản phẩm chứa hơn 50% tính theo trọng lượng tương ứng là benzen, toluen, xylen hoặc naphthalen.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo mục đích của phân nhóm 2710.12, ""dầu nhẹ và các chế phẩm"" là các loại dầu có thể tích thành phần cất từ 90% trở lên (kể cả hao hụt) ở nhiệt độ 210oC theo phương pháp ISO 3405 (tương đương với phương pháp ASTM D 86).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của các phân nhóm thuộc nhóm 27.10, thuật ngữ ""diesel sinh học"" có nghĩa là mono-alkyl este của các axit béo, loại sử dụng làm nhiên liệu, được làm từ chất béo và dầu động vật, thực vật hoặc vi sinh vật đã hoặc chưa qua sử dụng.',
    Unit: '',
  },
  {
    HsCode: '2701',
    Description: 'Than đá; than bánh, than quả bàng và nhiên liệu rắn tương tự sản xuất từ than đá',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Than đá, đã hoặc chưa nghiền thành bột, nhưng chưa đóng bánh:',
    Unit: '',
  },
  {
    HsCode: '27011100',
    Description: 'Anthracite',
    Unit: 'kg',
  },
  {
    HsCode: '270112',
    Description: 'Than bi-tum:',
    Unit: '',
  },
  {
    HsCode: '27011210',
    Description: 'Than để luyện cốc (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '27011290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '27011900',
    Description: 'Than đá loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '27012000',
    Description: 'Than bánh, than quả bàng và nhiên liệu rắn tương tự sản xuất từ than đá',
    Unit: 'kg',
  },
  {
    HsCode: '2702',
    Description: 'Than non, đã hoặc chưa đóng bánh, trừ than huyền',
    Unit: '',
  },
  {
    HsCode: '27021000',
    Description: 'Than non, đã hoặc chưa nghiền thành bột, nhưng chưa đóng bánh',
    Unit: 'kg',
  },
  {
    HsCode: '27022000',
    Description: 'Than non đã đóng bánh',
    Unit: 'kg',
  },
  {
    HsCode: '2703',
    Description: 'Than bùn (kể cả bùn rác), đã hoặc chưa đóng bánh',
    Unit: '',
  },
  {
    HsCode: '27030010',
    Description: 'Than bùn, đã hoặc chưa ép thành kiện, nhưng chưa đóng bánh',
    Unit: 'kg',
  },
  {
    HsCode: '27030020',
    Description: 'Than bùn đã đóng bánh',
    Unit: 'kg',
  },
  {
    HsCode: '2704',
    Description:
      'Than cốc và than nửa cốc luyện từ than đá, than non hoặc than bùn, đã hoặc chưa đóng bánh; muội bình chưng than đá',
    Unit: '',
  },
  {
    HsCode: '27040010',
    Description: 'Than cốc và than nửa cốc luyện từ than đá',
    Unit: 'kg',
  },
  {
    HsCode: '27040020',
    Description: 'Than cốc và than nửa cốc luyện từ than non hoặc than bùn',
    Unit: 'kg',
  },
  {
    HsCode: '27040030',
    Description: 'Muội bình chưng than đá',
    Unit: 'kg',
  },
  {
    HsCode: '27050000',
    Description:
      'Khí than đá, khí than ướt, khí than và các loại khí tương tự, trừ các loại khí dầu mỏ và khí hydrocarbon khác',
    Unit: 'kg',
  },
  {
    HsCode: '27060000',
    Description:
      'Hắc ín chưng cất từ than đá, than non hoặc than bùn, và các loại hắc ín khoáng chất khác, đã hoặc chưa khử nước hoặc chưng cất từng phần, kể cả hắc ín tái chế',
    Unit: 'kg',
  },
  {
    HsCode: '2707',
    Description:
      'Dầu và các sản phẩm khác từ chưng cất hắc ín than đá ở nhiệt độ cao; các sản phẩm tương tự có khối lượng cấu tử thơm lớn hơn cấu tử không thơm',
    Unit: '',
  },
  {
    HsCode: '27071000',
    Description: 'Benzen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27072000',
    Description: 'Toluen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27073000',
    Description: 'Xylen ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27074000',
    Description: 'Naphthalen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27075000',
    Description:
      'Các hỗn hợp hydrocarbon thơm khác có từ 65% thể tích trở lên (kể cả lượng hao hụt) được chưng cất ở nhiệt độ 250oC theo phương pháp ISO 3405 (tương đương phương pháp ASTM D 86)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '27079100',
    Description: 'Dầu creosote',
    Unit: 'kg/lít',
  },
  {
    HsCode: '270799',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '27079910',
    Description: 'Nguyên liệu để sản xuất than đen(SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27079990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2708',
    Description: 'Nhựa chưng (hắc ín) và than cốc nhựa chưng, thu được từ hắc ín than đá hoặc hắc ín khoáng chất khác',
    Unit: '',
  },
  {
    HsCode: '27081000',
    Description: 'Nhựa chưng (hắc ín)',
    Unit: 'kg',
  },
  {
    HsCode: '27082000',
    Description: 'Than cốc nhựa chưng',
    Unit: 'kg',
  },
  {
    HsCode: '2709',
    Description: 'Dầu mỏ và các loại dầu thu được từ các khoáng bi-tum, ở dạng thô',
    Unit: '',
  },
  {
    HsCode: '27090010',
    Description: 'Dầu mỏ thô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27090020',
    Description: 'Condensate ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27090090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2710',
    Description:
      'Dầu có nguồn gốc từ dầu mỏ và các loại dầu thu được từ các khoáng bi-tum, trừ dầu thô; các chế phẩm chưa được chi tiết hoặc ghi ở nơi khác, có chứa hàm lượng từ 70% trở lên là dầu có nguồn gốc từ dầu mỏ hoặc các loại dầu thu được từ các khoáng bi-tum, những loại dầu này là thành phần cơ bản của các chế phẩm đó; dầu thải. (SEN) ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Dầu có nguồn gốc từ dầu mỏ và các loại dầu thu được từ các khoáng bi-tum (trừ dầu thô) và các chế phẩm chưa được chi tiết hoặc ghi ở nơi khác, có chứa hàm lượng từ 70% trở lên là dầu có nguồn gốc từ dầu mỏ hoặc các loại dầu thu được từ các khoáng bi-tum, những loại dầu này là thành phần cơ bản của các chế phẩm đó, trừ loại chứa dầu diesel sinh học và trừ dầu thải:',
    Unit: '',
  },
  {
    HsCode: '271012',
    Description: 'Dầu nhẹ và các chế phẩm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Xăng động cơ, có pha chì:',
    Unit: '',
  },
  {
    HsCode: '27101211',
    Description: 'RON 97 và cao hơn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101212',
    Description: 'RON 90 và cao hơn nhưng dưới RON 97',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101213',
    Description: 'RON khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Xăng động cơ, không pha chì:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'RON 97 và cao hơn:',
    Unit: '',
  },
  {
    HsCode: '27101221',
    Description: 'Chưa pha chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101222',
    Description: 'Pha chế với ethanol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101223',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'RON 90 và cao hơn nhưng dưới RON 97:',
    Unit: '',
  },
  {
    HsCode: '27101224',
    Description: 'Chưa pha chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101225',
    Description: 'Pha chế với ethanol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101226',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'RON khác:',
    Unit: '',
  },
  {
    HsCode: '27101227',
    Description: 'Chưa pha chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101228',
    Description: 'Pha chế với ethanol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101229',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Xăng máy bay, loại sử dụng cho động cơ máy bay kiểu piston:',
    Unit: '',
  },
  {
    HsCode: '27101231',
    Description: 'Octane 100 và cao hơn(SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101239',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101240',
    Description: 'Tetrapropylene',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101250',
    Description: 'Dung môi trắng (white spirit)(SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101260',
    Description: 'Dung môi có hàm lượng cấu tử thơm thấp dưới 1% tính theo trọng lượng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101270',
    Description: 'Dung môi nhẹ khác (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101280',
    Description: 'Naphtha, reformate (SEN) và các chế phẩm khác để pha chế xăng động cơ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '27101291',
    Description: 'Alpha olefins',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101292',
    Description: 'Loại khác, spirit dầu mỏ(1), có độ chớp cháy dưới 23oC',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101299',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '271019',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '27101920',
    Description: 'Dầu thô đã tách phần nhẹ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101930',
    Description: 'Nguyên liệu để sản xuất than đen(SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dầu và mỡ bôi trơn:',
    Unit: '',
  },
  {
    HsCode: '27101941',
    Description: 'Dầu khoáng đã tinh chế để sản xuất dầu bôi trơn (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101942',
    Description: 'Dầu bôi trơn cho động cơ máy bay',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101944',
    Description: 'Mỡ bôi trơn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101945',
    Description: 'Dầu bôi trơn cho nguyên liệu dệt(SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101946',
    Description: 'Dầu bôi trơn khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101950',
    Description: 'Dầu dùng trong bộ hãm thủy lực (dầu phanh)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101960',
    Description: 'Dầu dùng cho máy biến điện (máy biến áp và máy biến dòng) và dầu dùng cho bộ phận ngắt mạch ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Nhiên liệu diesel; các loại dầu nhiên liệu:',
    Unit: '',
  },
  {
    HsCode: '27101971',
    Description: 'Nhiên liệu diesel cho ô tô',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101972',
    Description: 'Nhiên liệu diesel khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101979',
    Description: 'Dầu nhiên liệu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101981',
    Description: 'Nhiên liệu động cơ máy bay (nhiên liệu phản lực) có độ chớp cháy từ 23oC trở lên',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101982',
    Description: 'Nhiên liệu động cơ máy bay (nhiên liệu phản lực) có độ chớp cháy dưới 23oC',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101983',
    Description: 'Các kerosine khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101989',
    Description: 'Dầu trung khác và các chế phẩm (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27101990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27102000',
    Description:
      'Dầu mỏ và các loại dầu thu được từ các khoáng bi-tum (trừ dầu thô) và các chế phẩm chưa được chi tiết hoặc ghi ở nơi khác, có chứa từ 70% trọng lượng trở lên là dầu mỏ hoặc các loại dầu thu được từ các khoáng bi-tum, những loại dầu này là thành phần cơ bản của các chế phẩm đó, có chứa dầu diesel sinh học, trừ dầu thải',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dầu thải:',
    Unit: '',
  },
  {
    HsCode: '27109100',
    Description:
      '- Chứa polychlorinated biphenyls (PCBs), polychlorinated terphenyls (PCTs) hoặc polybrominated biphenyls (PBBs)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27109900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2711',
    Description: 'Khí dầu mỏ và các loại khí hydrocarbon khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng hóa lỏng:',
    Unit: '',
  },
  {
    HsCode: '27111100',
    Description: 'Khí tự nhiên',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27111200',
    Description: 'Propan',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27111300',
    Description: 'Butan',
    Unit: 'kg/lít',
  },
  {
    HsCode: '271114',
    Description: 'Etylen, propylen, butylen và butadien:',
    Unit: '',
  },
  {
    HsCode: '27111410',
    Description: 'Etylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27111490',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27111900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Dạng khí:',
    Unit: '',
  },
  {
    HsCode: '271121',
    Description: 'Khí tự nhiên:',
    Unit: '',
  },
  {
    HsCode: '27112110',
    Description: 'Loại sử dụng làm nhiên liệu động cơ(SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27112190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27112900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2712',
    Description:
      'Vazơlin (petroleum jelly); sáp parafin, sáp dầu lửa vi tinh thể, sáp than cám, ozokerite, sáp than non, sáp than bùn, sáp khoáng khác, và sản phẩm tương tự thu được từ quy trình tổng hợp hoặc quy trình khác, đã hoặc chưa nhuộm màu',
    Unit: '',
  },
  {
    HsCode: '27121000',
    Description: 'Vazơlin (petroleum jelly)',
    Unit: 'kg',
  },
  {
    HsCode: '27122000',
    Description: 'Sáp parafin có hàm lượng dầu dưới 0,75% tính theo trọng lượng',
    Unit: 'kg',
  },
  {
    HsCode: '271290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '27129010',
    Description: 'Sáp parafin',
    Unit: 'kg',
  },
  {
    HsCode: '27129090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2713',
    Description:
      'Cốc dầu mỏ, bi-tum dầu mỏ và các cặn khác từ dầu có nguồn gốc từ dầu mỏ hoặc từ các loại dầu thu được từ các khoáng bi-tum',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cốc dầu mỏ:',
    Unit: '',
  },
  {
    HsCode: '27131100',
    Description: 'Chưa nung',
    Unit: 'kg',
  },
  {
    HsCode: '27131200',
    Description: 'Đã nung',
    Unit: 'kg',
  },
  {
    HsCode: '27132000',
    Description: 'Bi-tum dầu mỏ',
    Unit: 'kg',
  },
  {
    HsCode: '27139000',
    Description: 'Cặn khác từ dầu có nguồn gốc từ dầu mỏ hoặc từ các loại dầu thu được từ các khoáng bi-tum',
    Unit: 'kg',
  },
  {
    HsCode: '2714',
    Description:
      'Bi-tum và nhựa đường (asphalt), ở dạng tự nhiên; đá phiến sét dầu hoặc đá phiến sét bi-tum và cát hắc ín; asphaltit và đá chứa asphaltic',
    Unit: '',
  },
  {
    HsCode: '27141000',
    Description: 'Đá phiến sét dầu hoặc đá phiến sét bi-tum và cát hắc ín',
    Unit: 'kg',
  },
  {
    HsCode: '27149000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2715',
    Description:
      'Hỗn hợp chứa bi-tum dựa trên asphalt tự nhiên, bi-tum tự nhiên, bi-tum dầu mỏ, hắc ín khoáng chất hoặc nhựa hắc ín khoáng chất (ví dụ, matít có chứa bi-tum, cut-backs (*))',
    Unit: '',
  },
  {
    HsCode: '27150010',
    Description: 'Chất phủ hắc ín polyurethan (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27150090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '27160000',
    Description: 'Năng lượng điện.',
    Unit: 'kWh',
  },
  {
    HsCode: '',
    Description: '(1) Tham khảo TCVN 3575-81',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(*) Cut-backs: thường chứa hàm lượng bitum hòa trong dung môi từ 60% trở lên. Chúng được sử dụng cho rải mặt đường.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN VI',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'SẢN PHẨM CỦA NGÀNH CÔNG NGHIỆP HÓA CHẤT HOẶC CÁC NGÀNH CÔNG NGHIỆP LIÊN QUAN',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. (A) Các mặt hàng (trừ quặng phóng xạ) đáp ứng các mô tả trong nhóm 28.44 hoặc 28.45 phải được xếp vào các nhóm đó và không được đưa vào nhóm nào khác của Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Theo Chú giải mục (A) trên, các mặt hàng đáp ứng các mô tả trong nhóm 28.43, 28.46 hoặc 28.52 được xếp vào các nhóm đó và không được đưa vào nhóm nào khác của Phần này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo Chú giải 1 ở trên, các mặt hàng xếp vào các nhóm 30.04, 30.05, 30.06, 32.12, 33.03, 33.04, 33.05, 33.06, 33.07, 35.06, 37.07 hoặc 38.08 vì đã được đóng gói theo liều lượng hoặc đóng gói để bán lẻ được xếp vào các nhóm đó và không xếp vào bất cứ nhóm nào khác của Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các mặt hàng đóng gói thành bộ gồm từ hai hoặc nhiều phần cấu thành riêng biệt, trong đó một vài hoặc tất cả các phần cấu thành của các mặt hàng đó nằm trong Phần này và chúng được trộn với nhau để tạo ra một sản phẩm của Phần VI hoặc VII, phải được xếp vào nhóm phù hợp với sản phẩm đó, với điều kiện là các phần cấu thành phải:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) theo cách thức đóng gói của chúng cho thấy rõ ràng là chúng được sử dụng cùng nhau mà không cần phải đóng gói lại;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) được trình bày cùng nhau; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) có thể nhận biết là chúng nhằm bổ sung cho nhau, thông qua bản chất hoặc tỷ lệ tương ứng của chúng trong sản phẩm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Trường hợp sản phẩm đáp ứng mô tả tại một hoặc nhiều nhóm trong Phần VI do được mô tả theo tên hoặc nhóm chức và đáp ứng nhóm 38.27, thì sản phẩm đó được phân loại vào nhóm tương ứng theo tên hoặc nhóm chức và không phân loại vào nhóm 38.27.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 28',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Hóa chất vô cơ; các hợp chất vô cơ hoặc hữu cơ của kim loại quý, kim loại đất hiếm, các nguyên tố phóng xạ hoặc các chất đồng vị',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trừ khi có yêu cầu khác, các nhóm thuộc Chương này chỉ bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các nguyên tố hóa học riêng biệt và các hợp chất được xác định về mặt hóa học riêng biệt, có hoặc không chứa tạp chất;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các sản phẩm được nêu ở mục (a) trên đây đã được hoà tan trong nước;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các sản phẩm nêu ở mục (a) trên đây hoà tan trong các dung môi khác miễn là sự hoà tan chỉ là một phương pháp thông thường và cần thiết để đóng gói những sản phẩm nhằm mục đích duy nhất là bảo đảm an toàn hoặc để vận chuyển và dung môi ấy không làm cho sản phẩm có công dụng đặc biệt ngoài công dụng thông thường của nó;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Các sản phẩm được đề cập ở mục (a), (b) hoặc (c) trên đây có thêm một chất ổn định (kể cả chất chống đóng cứng) cần thiết cho sự bảo quản hoặc vận chuyển;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Các sản phẩm được đề cập ở mục (a), (b), (c) hoặc (d) trên đây có thêm chất chống bụi hoặc chất màu để dễ nhận biết hoặc để đảm bảo an toàn miễn là sự pha thêm này không làm cho sản phẩm có công dụng đặc biệt ngoài công dụng thông thường của nó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Ngoài các dithionit và sulphoxylat, đã được làm ổn định bằng các chất hữu cơ (nhóm 28.31), carbonat và peroxocarbonat của các bazơ vô cơ (nhóm 28.36), xyanua, oxit xyanua và xyanua phức của các bazơ vô cơ (nhóm 28.37), fulminat, xyanat và thioxyanat, của các bazơ vô cơ (nhóm 28.42), các sản phẩm hữu cơ thuộc các nhóm từ 28.43 đến 28.46 và 28.52 và carbua (nhóm 28.49), chỉ các hợp chất carbon sau đây là được xếp vào Chương này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Oxit carbon, hydroxyanua và axit funminic, isoxyanic, thioxyanic và các axit xyanic đơn hoặc phức khác (nhóm 28.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các oxit halogenua của carbon (nhóm 28.12);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Carbon disulphua (nhóm 28.13);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Thiocarbonat, selenocarbonat, tellurocarbonat, selenoxyanat, telluroxyanat, tetrathio-cyanatodiamminochromates (reineckates) và các xyanat phức khác, của các bazơ vô cơ (nhóm 28.42);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Hydro peoxit, được làm rắn bằng urê (nhóm 28.47), oxysulphua carbon, halogenua thiocarbonyl, xyanogen, halogenua xyanogen và xyanamit và các dẫn xuất kim loại của chúng (nhóm 28.53) trừ xyanamit canxi, dạng tinh khiết hoặc không tinh khiết (Chương 31).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Theo Chú giải 1 của Phần VI, Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Natri clorua hoặc magiê oxit, tinh khiết hoặc không tinh khiết, hoặc các sản phẩm khác của Phần V;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các hợp chất hữu cơ vô cơ trừ các hợp chất đã nêu tại Chú giải 2 trên đây;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các sản phẩm nêu tại Chú giải 2, 3, 4 hoặc 5 của Chương 31;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Các sản phẩm vô cơ sử dụng như chất phát quang, thuộc nhóm 32.06; frit thủy tinh và thủy tinh khác ở dạng bột, hạt hoặc mảnh, thuộc nhóm 32.07;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Graphit nhân tạo (nhóm 38.01); các sản phẩm dùng như chất dập lửa, để nạp cho bình cứu hỏa hoặc lựu đạn dập lửa, thuộc nhóm 38.13; chất tẩy mực đóng gói để bán lẻ, thuộc nhóm 38.24; tinh thể nuôi cấy (trừ các bộ phận quang học) halogenua kim loại kiềm hoặc kiềm thổ được tạo ra nặng không dưới 2,5g mỗi tinh thể, thuộc nhóm 38.24;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo) hoặc bụi hoặc bột của các loại đá đó (nhóm 71.02 đến 71.05), hoặc kim loại quý hoặc hợp kim kim loại quý thuộc Chương 71; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Kim loại, nguyên chất hoặc không nguyên chất, các hợp kim hoặc gốm kim loại, kể cả carbua kim loại được thiêu kết (carbua kim loại được thiêu kết với một kim loại), thuộc Phần XV; hoặc ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Các bộ phận quang học, ví dụ, loại làm bằng halogenua kim loại kiềm hoặc kiềm thổ (nhóm 90.01).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Các axit phức đã được xác định về mặt hóa học bao gồm một axit phi kim loại thuộc phân Chương II và một axit kim loại thuộc phân Chương IV phải được xếp vào nhóm 28.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '5. Các nhóm 28.26 đến 28.42 chỉ áp dụng cho muối kim loại hoặc muối amoni hoặc muối peroxy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Loại trừ có yêu cầu khác, các muối kép hoặc phức được phân loại trong nhóm 28.42.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '6. Nhóm 28.44 chỉ áp dụng cho: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Tecneti (nguyên tố số 43), prometi (nguyên tố số 61), poloni (nguyên tố số 84) và tất cả các nguyên tố có số thứ tự trong bảng tuần hoàn lớn hơn 84;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các chất đồng vị phóng xạ tự nhiên hoặc nhân tạo (kể cả các chất đồng vị phóng xạ của kim loại quý hoặc kim loại cơ bản thuộc Phần XIV và XV), đã hoặc chưa pha trộn với nhau;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Hợp chất, vô cơ hoặc hữu cơ, của các nguyên tố hoặc các chất đồng vị của chúng, đã hoặc chưa xác định về mặt hóa học, đã hoặc chưa trộn với nhau;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Hợp kim, các chất phân tán (kể cả gốm kim loại), các sản phẩm gốm và hỗn hợp chứa các nguyên tố hoặc chất đồng vị hoặc các hợp chất vô cơ hoặc hữu cơ từ các chất đó, có nồng độ phóng xạ riêng (đặc trưng) trên 74 Bq/g (0,002 μCi/g);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Ống (cartridges) nhiên liệu đã bức xạ của lò phản ứng hạt nhân;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Chất thải phóng xạ còn sử dụng được hoặc không.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Theo mục đích của Chú giải này và cách diễn đạt của các nhóm 28.44 và 28.45, thuật ngữ “chất đồng vị”, đề cập tới:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'các hạt nhân riêng lẻ, không kể những hạt nhân tồn tại trong thiên nhiên dưới dạng chất đồng vị đơn;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'hỗn hợp các chất đồng vị của một nguyên tố và nguyên tố đó, đã được làm giàu bằng một hoặc vài chất đồng vị nói trên, nghĩa là các nguyên tố mà thành phần đồng vị tự nhiên đã được làm thay đổi một cách nhân tạo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Nhóm 28.53 bao gồm đồng phosphua (phosphor copper) có chứa trên 15% tính theo trọng lượng của phospho.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Các nguyên tố hóa học (ví dụ, silic và selen) đã được kích tạp dùng trong ngành điện tử phải xếp vào Chương này, nhưng chúng phải ở dạng chưa gia công như kéo, hoặc ở dạng hình trụ hoặc dạng que. Khi được cắt thành hình đĩa, miếng hoặc hình dáng tương tự, chúng phải được xếp vào nhóm 38.18.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 2852.10, thuật ngữ ""xác định về mặt hóa học"" có nghĩa là tất cả các hợp chất vô cơ hoặc hữu cơ của thủy ngân đáp ứng yêu cầu của đoạn (a) tới (e) của Chú giải 1 Chương 28 hoặc đoạn (a) tới (h) của Chú giải 1 chương 29.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG I',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'CÁC NGUYÊN TỐ HÓA HỌC',
    Unit: '',
  },
  {
    HsCode: '2801',
    Description: 'Flo, clo, brom và iot',
    Unit: '',
  },
  {
    HsCode: '28011000',
    Description: 'Clo',
    Unit: 'kg',
  },
  {
    HsCode: '28012000',
    Description: 'Iot',
    Unit: 'kg',
  },
  {
    HsCode: '28013000',
    Description: 'Flo; brom',
    Unit: 'kg',
  },
  {
    HsCode: '28020000',
    Description: 'Lưu huỳnh, thăng hoa hoặc kết tủa; lưu huỳnh dạng keo',
    Unit: 'kg',
  },
  {
    HsCode: '2803',
    Description: 'Carbon (muội carbon và các dạng khác của carbon chưa được chi tiết hoặc ghi ở nơi khác)',
    Unit: '',
  },
  {
    HsCode: '28030020',
    Description: 'Muội axetylen',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Muội carbon khác:',
    Unit: '',
  },
  {
    HsCode: '28030041',
    Description: 'Loại sử dụng để sản xuất cao su (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '28030049',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28030090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2804',
    Description: 'Hydro, khí hiếm và các phi kim loại khác',
    Unit: '',
  },
  {
    HsCode: '28041000',
    Description: 'Hydro',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: 'Khí hiếm:',
    Unit: '',
  },
  {
    HsCode: '28042100',
    Description: 'Argon',
    Unit: 'kg/m3',
  },
  {
    HsCode: '28042900',
    Description: 'Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '28043000',
    Description: 'Nitơ',
    Unit: 'kg/m3',
  },
  {
    HsCode: '28044000',
    Description: 'Oxy',
    Unit: 'kg/m3',
  },
  {
    HsCode: '28045000',
    Description: 'Bo; telu',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Silic:',
    Unit: '',
  },
  {
    HsCode: '28046100',
    Description: 'Có hàm lượng silic không dưới 99,99% tính theo trọng lượng',
    Unit: 'kg',
  },
  {
    HsCode: '28046900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28047000',
    Description: 'Phospho',
    Unit: 'kg',
  },
  {
    HsCode: '28048000',
    Description: 'Arsen',
    Unit: 'kg',
  },
  {
    HsCode: '28049000',
    Description: 'Selen',
    Unit: 'kg',
  },
  {
    HsCode: '2805',
    Description:
      'Kim loại kiềm hoặc kim loại kiềm thổ; kim loại đất hiếm, scandi và ytri, đã hoặc chưa pha trộn hoặc tạo hợp kim với nhau; thủy ngân',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Kim loại kiềm hoặc kim loại kiềm thổ:',
    Unit: '',
  },
  {
    HsCode: '28051100',
    Description: 'Natri',
    Unit: 'kg',
  },
  {
    HsCode: '28051200',
    Description: 'Canxi',
    Unit: 'kg',
  },
  {
    HsCode: '28051900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28053000',
    Description: 'Kim loại đất hiếm, scandi và ytri đã hoặc chưa pha trộn hoặc tạo hợp kim với nhau',
    Unit: 'kg',
  },
  {
    HsCode: '28054000',
    Description: 'Thủy ngân',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG II',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'AXIT VÔ CƠ VÀ CÁC HỢP CHẤT VÔ CƠ CÓ CHỨA OXY CỦA CÁC PHI KIM LOẠI',
    Unit: '',
  },
  {
    HsCode: '2806',
    Description: 'Hydro clorua (axit hydrocloric); axit clorosulphuric',
    Unit: '',
  },
  {
    HsCode: '28061000',
    Description: 'Hydro clorua (axit hydrocloric)',
    Unit: 'kg',
  },
  {
    HsCode: '28062000',
    Description: 'Axit clorosulphuric',
    Unit: 'kg',
  },
  {
    HsCode: '2807',
    Description: 'Axit sulphuric; axit sulphuric bốc khói (oleum)',
    Unit: '',
  },
  {
    HsCode: '28070010',
    Description: 'Axit sulphuric chứa H2SO4 trên 80% tính theo trọng lượng',
    Unit: 'kg',
  },
  {
    HsCode: '28070090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28080000',
    Description: 'Axit nitric; axit sulphonitric.',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2809',
    Description: 'Diphospho pentaoxit; axit phosphoric; axit polyphosphoric, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '28091000',
    Description: 'Diphospho pentaoxit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '280920',
    Description: 'Axit phosphoric và axit polyphosphoric:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Loại dùng cho thực phẩm:',
    Unit: '',
  },
  {
    HsCode: '28092031',
    Description: 'Axit hypophosphoric (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28092032',
    Description: 'Axit phosphoric (SEN) ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28092039',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28092091',
    Description: 'Axit hypophosphoric',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28092092',
    Description: 'Axit phosphoric',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28092099',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2810',
    Description: 'Oxit bo; axit boric',
    Unit: '',
  },
  {
    HsCode: '28100010',
    Description: 'Oxit bo',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28100020',
    Description: 'Axit boric',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2811',
    Description: 'Axit vô cơ khác và các hợp chất vô cơ chứa oxy khác của các phi kim loại',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Axit vô cơ khác:',
    Unit: '',
  },
  {
    HsCode: '28111100',
    Description: 'Hydro florua (axit hydrofloric)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28111200',
    Description: 'Hydro xyanua (axit hydroxyanic)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '281119',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28111910',
    Description: 'Axit arsenic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28111920',
    Description: 'Axit aminosulphonic (axit sulphamic)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28111990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Hợp chất vô cơ chứa oxy khác của phi kim loại:',
    Unit: '',
  },
  {
    HsCode: '28112100',
    Description: 'Carbon dioxit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '281122',
    Description: 'Silic dioxit:',
    Unit: '',
  },
  {
    HsCode: '28112210',
    Description: 'Dạng bột',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28112290',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '281129',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28112910',
    Description: 'Diarsenic pentaoxit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28112920',
    Description: 'Dioxit lưu huỳnh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28112990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG III',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'HỢP CHẤT HALOGEN HOẶC HỢP CHẤT SULPHUA CỦA PHI KIM LOẠI',
    Unit: '',
  },
  {
    HsCode: '2812',
    Description: 'Halogenua và oxit halogenua của phi kim loại',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Clorua và oxit clorua:',
    Unit: '',
  },
  {
    HsCode: '28121100',
    Description: 'Carbonyl diclorua (phosgene)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28121200',
    Description: 'Phospho oxyclorua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28121300',
    Description: 'Phospho triclorua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28121400',
    Description: 'Phospho pentaclorua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28121500',
    Description: 'Lưu huỳnh monoclorua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28121600',
    Description: 'Lưu huỳnh diclorua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28121700',
    Description: '-Thionyl clorua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28121900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '28129000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2813',
    Description: 'Sulphua của phi kim loại; phospho trisulphua thương phẩm',
    Unit: '',
  },
  {
    HsCode: '28131000',
    Description: 'Carbon disulphua',
    Unit: 'kg',
  },
  {
    HsCode: '28139000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG IV',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'BAZƠ VÀ OXIT VÔ CƠ, HYDROXIT VÀ PEROXIT KIM LOẠI',
    Unit: '',
  },
  {
    HsCode: '2814',
    Description: 'Amoniac, dạng khan hoặc dạng dung dịch nước',
    Unit: '',
  },
  {
    HsCode: '28141000',
    Description: 'Dạng khan',
    Unit: 'kg',
  },
  {
    HsCode: '28142000',
    Description: 'Dạng dung dịch nước',
    Unit: 'kg',
  },
  {
    HsCode: '2815',
    Description: 'Natri hydroxit (xút ăn da); kali hydroxit (potash ăn da); natri peroxit hoặc kali peroxit',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Natri hydroxit (xút ăn da):',
    Unit: '',
  },
  {
    HsCode: '28151100',
    Description: 'Dạng rắn',
    Unit: 'kg',
  },
  {
    HsCode: '28151200',
    Description: 'Dạng dung dịch nước (soda kiềm hoặc soda lỏng)',
    Unit: 'kg',
  },
  {
    HsCode: '28152000',
    Description: 'Kali hydroxit (potash ăn da)',
    Unit: 'kg',
  },
  {
    HsCode: '28153000',
    Description: 'Natri hoặc kali peroxit ',
    Unit: 'kg',
  },
  {
    HsCode: '2816',
    Description: 'Magie hydroxit và magie peroxit; oxit, hydroxit và peroxit, của stronti hoặc bari',
    Unit: '',
  },
  {
    HsCode: '28161000',
    Description: 'Magie hydroxit và magie peroxit ',
    Unit: 'kg',
  },
  {
    HsCode: '28164000',
    Description: 'Oxit, hydroxit và peroxit, của stronti hoặc bari',
    Unit: 'kg',
  },
  {
    HsCode: '2817',
    Description: 'Kẽm oxit; kẽm peroxit',
    Unit: '',
  },
  {
    HsCode: '28170010',
    Description: 'Kẽm oxit',
    Unit: 'kg',
  },
  {
    HsCode: '28170020',
    Description: 'Kẽm peroxit ',
    Unit: 'kg',
  },
  {
    HsCode: '2818',
    Description: 'Corundum nhân tạo, đã hoặc chưa xác định về mặt hóa học; oxit nhôm; hydroxit nhôm',
    Unit: '',
  },
  {
    HsCode: '28181000',
    Description: 'Corundum nhân tạo, đã hoặc chưa xác định về mặt hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '28182000',
    Description: 'Oxit nhôm, trừ corundum nhân tạo',
    Unit: 'kg',
  },
  {
    HsCode: '28183000',
    Description: 'Nhôm hydroxit ',
    Unit: 'kg',
  },
  {
    HsCode: '2819',
    Description: 'Crom oxit và hydroxit',
    Unit: '',
  },
  {
    HsCode: '28191000',
    Description: 'Crom trioxit ',
    Unit: 'kg',
  },
  {
    HsCode: '28199000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2820',
    Description: 'Mangan oxit',
    Unit: '',
  },
  {
    HsCode: '28201000',
    Description: 'Mangan dioxit',
    Unit: 'kg',
  },
  {
    HsCode: '28209000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2821',
    Description:
      'Oxit sắt và hydroxit sắt; chất màu từ đất có hàm lượng sắt hóa hợp Fe2O3 chiếm từ 70% trở lên tính theo trọng lượng',
    Unit: '',
  },
  {
    HsCode: '28211000',
    Description: 'Hydroxit và oxit sắt',
    Unit: 'kg',
  },
  {
    HsCode: '28212000',
    Description: 'Chất màu từ đất',
    Unit: 'kg',
  },
  {
    HsCode: '28220000',
    Description: 'Coban oxit và hydroxit; coban oxit thương phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '28230000',
    Description: 'Titan oxit',
    Unit: 'kg',
  },
  {
    HsCode: '2824',
    Description: 'Chì oxit; chì đỏ và chì da cam',
    Unit: '',
  },
  {
    HsCode: '28241000',
    Description: 'Chì monoxit (litharge, maxicot)',
    Unit: 'kg',
  },
  {
    HsCode: '28249000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2825',
    Description:
      'Hydrazin và hydroxilamin và các muối vô cơ của chúng; các loại bazơ vô cơ khác; các oxit, hydroxit và peroxit kim loại khác',
    Unit: '',
  },
  {
    HsCode: '28251000',
    Description: 'Hydrazin và hydroxilamin và các muối vô cơ của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '28252000',
    Description: 'Hydroxit và oxit liti ',
    Unit: 'kg',
  },
  {
    HsCode: '28253000',
    Description: 'Hydroxit và oxit vanađi ',
    Unit: 'kg',
  },
  {
    HsCode: '28254000',
    Description: 'Hydroxit và oxit niken ',
    Unit: 'kg',
  },
  {
    HsCode: '28255000',
    Description: 'Hydroxit và oxit đồng ',
    Unit: 'kg',
  },
  {
    HsCode: '28256000',
    Description: 'Germani oxit và zircon dioxit',
    Unit: 'kg',
  },
  {
    HsCode: '28257000',
    Description: 'Hydroxit và oxit molipđen ',
    Unit: 'kg',
  },
  {
    HsCode: '28258000',
    Description: 'Antimon oxit',
    Unit: 'kg',
  },
  {
    HsCode: '28259000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG V',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'MUỐI VÀ MUỐI PEROXIT, CỦA CÁC AXIT VÔ CƠ VÀ CÁC KIM LOẠI',
    Unit: '',
  },
  {
    HsCode: '2826',
    Description: 'Florua; florosilicat, floroaluminat và các loại muối flo phức khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Florua:',
    Unit: '',
  },
  {
    HsCode: '28261200',
    Description: 'Của nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '28261900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28263000',
    Description: 'Natri hexafloroaluminat (cryolit tổng hợp)',
    Unit: 'kg',
  },
  {
    HsCode: '28269000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2827',
    Description: 'Clorua, clorua oxit và clorua hydroxit; bromua và oxit bromua; iođua và iođua oxit',
    Unit: '',
  },
  {
    HsCode: '28271000',
    Description: 'Amoni clorua ',
    Unit: 'kg',
  },
  {
    HsCode: '282720',
    Description: 'Canxi clorua:',
    Unit: '',
  },
  {
    HsCode: '28272010',
    Description: 'Chứa từ 73% đến 80% tính theo trọng lượng',
    Unit: 'kg',
  },
  {
    HsCode: '28272090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Clorua khác:',
    Unit: '',
  },
  {
    HsCode: '28273100',
    Description: 'Của magiê',
    Unit: 'kg',
  },
  {
    HsCode: '28273200',
    Description: 'Của nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '28273500',
    Description: 'Của niken',
    Unit: 'kg',
  },
  {
    HsCode: '282739',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28273910',
    Description: 'Của bari hoặc của coban',
    Unit: 'kg',
  },
  {
    HsCode: '28273920',
    Description: 'Của sắt',
    Unit: 'kg',
  },
  {
    HsCode: '28273930',
    Description: 'Của kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '28273990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Clorua oxit và clorua hydroxit:',
    Unit: '',
  },
  {
    HsCode: '28274100',
    Description: 'Của đồng',
    Unit: 'kg',
  },
  {
    HsCode: '28274900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Bromua và oxit bromua:',
    Unit: '',
  },
  {
    HsCode: '28275100',
    Description: 'Natri bromua hoặc kali bromua ',
    Unit: 'kg',
  },
  {
    HsCode: '28275900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28276000',
    Description: 'Iođua và iođua oxit',
    Unit: 'kg',
  },
  {
    HsCode: '2828',
    Description: 'Hypoclorit; canxi hypoclorit thương phẩm; clorit; hypobromit',
    Unit: '',
  },
  {
    HsCode: '28281000',
    Description: 'Canxi hypoclorit thương phẩm và canxi hypoclorit khác',
    Unit: 'kg',
  },
  {
    HsCode: '282890',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28289010',
    Description: 'Natri hypoclorit',
    Unit: 'kg',
  },
  {
    HsCode: '28289090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2829',
    Description: 'Clorat và perclorat; bromat và perbromat; iodat và periodat',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Clorat:',
    Unit: '',
  },
  {
    HsCode: '28291100',
    Description: 'Của natri',
    Unit: 'kg',
  },
  {
    HsCode: '28291900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '282990',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28299010',
    Description: 'Natri perclorat',
    Unit: 'kg',
  },
  {
    HsCode: '28299090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2830',
    Description: 'Sulphua; polysulphua, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '28301000',
    Description: 'Natri sulphua',
    Unit: 'kg',
  },
  {
    HsCode: '283090',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28309010',
    Description: 'Sulphua cađimi hoặc sulphua kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '28309090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2831',
    Description: 'Dithionit và sulphoxylat',
    Unit: '',
  },
  {
    HsCode: '28311000',
    Description: 'Của natri',
    Unit: 'kg',
  },
  {
    HsCode: '28319000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2832',
    Description: 'Sulphit; thiosulphat',
    Unit: '',
  },
  {
    HsCode: '28321000',
    Description: 'Natri sulphit ',
    Unit: 'kg',
  },
  {
    HsCode: '28322000',
    Description: 'Sulphit khác',
    Unit: 'kg',
  },
  {
    HsCode: '28323000',
    Description: 'Thiosulphat',
    Unit: 'kg',
  },
  {
    HsCode: '2833',
    Description: 'Sulphat; phèn (alums); peroxosulphat (persulphat)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Natri sulphat:',
    Unit: '',
  },
  {
    HsCode: '28331100',
    Description: 'Dinatri sulphat ',
    Unit: 'kg',
  },
  {
    HsCode: '28331900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Sulphat loại khác:',
    Unit: '',
  },
  {
    HsCode: '28332100',
    Description: 'Của magiê',
    Unit: 'kg',
  },
  {
    HsCode: '283322',
    Description: 'Của nhôm:',
    Unit: '',
  },
  {
    HsCode: '28332210',
    Description: 'Loại thương phẩm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '28332290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28332400',
    Description: 'Của niken',
    Unit: 'kg',
  },
  {
    HsCode: '28332500',
    Description: 'Của đồng',
    Unit: 'kg',
  },
  {
    HsCode: '28332700',
    Description: 'Của bari',
    Unit: 'kg',
  },
  {
    HsCode: '283329',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28332920',
    Description: 'Chì sulphat tribasic',
    Unit: 'kg',
  },
  {
    HsCode: '28332930',
    Description: 'Của crôm',
    Unit: 'kg',
  },
  {
    HsCode: '28332940',
    Description: 'Của kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '28332990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28333000',
    Description: 'Phèn',
    Unit: 'kg',
  },
  {
    HsCode: '28334000',
    Description: 'Peroxosulphat (persulphat)',
    Unit: 'kg',
  },
  {
    HsCode: '2834',
    Description: 'Nitrit; nitrat',
    Unit: '',
  },
  {
    HsCode: '28341000',
    Description: 'Nitrit',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Nitrat:',
    Unit: '',
  },
  {
    HsCode: '28342100',
    Description: 'Của kali',
    Unit: 'kg',
  },
  {
    HsCode: '283429',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28342910',
    Description: 'Của bismut',
    Unit: 'kg',
  },
  {
    HsCode: '28342990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2835',
    Description:
      'Phosphinat (hypophosphit), phosphonat (phosphit) và phosphat; polyphosphat, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '28351000',
    Description: 'Phosphinat (hypophosphit) và phosphonat (phosphit)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Phosphat:',
    Unit: '',
  },
  {
    HsCode: '28352200',
    Description: 'Của monohoặc dinatri',
    Unit: 'kg',
  },
  {
    HsCode: '28352400',
    Description: 'Của kali',
    Unit: 'kg',
  },
  {
    HsCode: '283525',
    Description: 'Canxi hydroorthophosphat (“dicanxi phosphat”):',
    Unit: '',
  },
  {
    HsCode: '28352510',
    Description: 'Loại dùng làm thức ăn chăn nuôi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '28352590',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28352600',
    Description: 'Các phosphat khác của canxi ',
    Unit: 'kg',
  },
  {
    HsCode: '283529',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28352910',
    Description: 'Của trinatri',
    Unit: 'kg',
  },
  {
    HsCode: '28352990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Polyphosphat:',
    Unit: '',
  },
  {
    HsCode: '283531',
    Description: 'Natri triphosphat (natri tripolyphosphat):',
    Unit: '',
  },
  {
    HsCode: '28353110',
    Description: 'Loại dùng cho thực phẩm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '28353190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '283539',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28353910',
    Description: 'Tetranatri pyrophosphat',
    Unit: 'kg',
  },
  {
    HsCode: '28353990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2836',
    Description: 'Carbonat; peroxocarbonat (percarbonat); amoni carbonat thương phẩm có chứa amoni carbamat',
    Unit: '',
  },
  {
    HsCode: '28362000',
    Description: 'Dinatri carbonat ',
    Unit: 'kg',
  },
  {
    HsCode: '28363000',
    Description: 'Natri hydrocarbonat (natri bicarbonat)',
    Unit: 'kg',
  },
  {
    HsCode: '28364000',
    Description: 'Kali carbonat',
    Unit: 'kg',
  },
  {
    HsCode: '283650',
    Description: 'Canxi carbonat:',
    Unit: '',
  },
  {
    HsCode: '28365010',
    Description: 'Loại dùng cho thực phẩm hoặc dược phẩm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '28365090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28366000',
    Description: 'Bari carbonat ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28369100',
    Description: 'Liti carbonat',
    Unit: 'kg',
  },
  {
    HsCode: '28369200',
    Description: 'Stronti carbonat',
    Unit: 'kg',
  },
  {
    HsCode: '283699',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28369910',
    Description: 'Amoni carbonat thương phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '28369920',
    Description: 'Chì carbonat',
    Unit: 'kg',
  },
  {
    HsCode: '28369990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2837',
    Description: 'Xyanua, xyanua oxit và xyanua phức ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Xyanua và xyanua oxit:',
    Unit: '',
  },
  {
    HsCode: '28371100',
    Description: 'Của natri',
    Unit: 'kg',
  },
  {
    HsCode: '28371900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28372000',
    Description: 'Xyanua phức',
    Unit: 'kg',
  },
  {
    HsCode: '2839',
    Description: 'Silicat; silicat kim loại kiềm thương phẩm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Của natri:',
    Unit: '',
  },
  {
    HsCode: '28391100',
    Description: 'Natri metasilicat ',
    Unit: 'kg',
  },
  {
    HsCode: '283919',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28391920',
    Description: 'Natri orthosilicat; natri pyrosilicat',
    Unit: 'kg',
  },
  {
    HsCode: '28391990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28399000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2840',
    Description: 'Borat; peroxoborat (perborat)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dinatri tetraborat (borat tinh chế hàn the):',
    Unit: '',
  },
  {
    HsCode: '28401100',
    Description: 'Dạng khan',
    Unit: 'kg',
  },
  {
    HsCode: '28401900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28402000',
    Description: 'Borat khác',
    Unit: 'kg',
  },
  {
    HsCode: '28403000',
    Description: 'Peroxoborat (perborat)',
    Unit: 'kg',
  },
  {
    HsCode: '2841',
    Description: 'Muối của axit oxometalic hoặc axit peroxometalic',
    Unit: '',
  },
  {
    HsCode: '28413000',
    Description: 'Natri dicromat ',
    Unit: 'kg',
  },
  {
    HsCode: '28415000',
    Description: 'Cromat và dicromat khác; peroxocromat',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Manganit, manganat và permanganat:',
    Unit: '',
  },
  {
    HsCode: '28416100',
    Description: 'Kali permanganat ',
    Unit: 'kg',
  },
  {
    HsCode: '28416900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28417000',
    Description: 'Molipdat',
    Unit: 'kg',
  },
  {
    HsCode: '28418000',
    Description: 'Vonframat',
    Unit: 'kg',
  },
  {
    HsCode: '28419000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2842',
    Description:
      'Muối khác của axit vô cơ hoặc peroxoaxit (kể cả nhôm silicat đã hoặc chưa xác định về mặt hóa học), trừ các chất azit',
    Unit: '',
  },
  {
    HsCode: '28421000',
    Description: 'Silicat kép hoặc phức, kể cả nhôm silicat đã hoặc chưa xác định về mặt hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '284290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28429010',
    Description: 'Natri arsenit ',
    Unit: 'kg',
  },
  {
    HsCode: '28429020',
    Description: 'Muối của đồng hoặc crom',
    Unit: 'kg',
  },
  {
    HsCode: '28429030',
    Description: 'Fulminat, xyanat và thioxyanat khác',
    Unit: 'kg',
  },
  {
    HsCode: '28429090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG VI',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'LOẠI KHÁC',
    Unit: '',
  },
  {
    HsCode: '2843',
    Description:
      'Kim loại quý dạng keo; hợp chất hữu cơ hoặc vô cơ của kim loại quý, đã hoặc chưa xác định về mặt hóa học; hỗn hống của kim loại quý',
    Unit: '',
  },
  {
    HsCode: '28431000',
    Description: 'Kim loại quý dạng keo',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hợp chất bạc:',
    Unit: '',
  },
  {
    HsCode: '28432100',
    Description: 'Nitrat bạc',
    Unit: 'kg',
  },
  {
    HsCode: '28432900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28433000',
    Description: 'Hợp chất vàng',
    Unit: 'kg',
  },
  {
    HsCode: '28439000',
    Description: 'Hợp chất khác; hỗn hống',
    Unit: 'kg',
  },
  {
    HsCode: '2844',
    Description:
      'Các nguyên tố hóa học phóng xạ và các đồng vị phóng xạ (kể cả các nguyên tố hóa học và các đồng vị có khả năng phân hạch hoặc làm giầu) và các hợp chất của chúng; hỗn hợp và các phế liệu có chứa các sản phẩm trên',
    Unit: '',
  },
  {
    HsCode: '284410',
    Description:
      'Urani tự nhiên và các hợp chất của nó; hợp kim, các chất phân tán (kể cả gốm kim loại), sản phẩm gốm và hỗn hợp có chứa urani tự nhiên hoặc các hợp chất urani tự nhiên:',
    Unit: '',
  },
  {
    HsCode: '28441010',
    Description: 'Urani tự nhiên và các hợp chất của nó',
    Unit: 'kg',
  },
  {
    HsCode: '28441090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '284420',
    Description:
      'Urani đã làm giàu thành U 235 và hợp chất của nó; plutoni và hợp chất của nó; hợp kim, các chất phân tán (kể cả gốm kim loại), các sản phẩm gốm và các hỗn hợp có chứa urani đã được làm giàu thành U 235, plutoni hoặc hợp chất của các sản phẩm này:',
    Unit: '',
  },
  {
    HsCode: '28442010',
    Description: 'Urani đã làm giàu thành U 235 và hợp chất của nó; plutoni và hợp chất của nó',
    Unit: 'kg',
  },
  {
    HsCode: '28442090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '284430',
    Description:
      'Urani đã được làm nghèo thành U 235 và các hợp chất của nó; thori và các hợp chất của nó; hợp kim, các chất phân tán (kể cả gốm kim loại), sản phẩm gốm kim loại và các hỗn hợp chứa urani đã được làm nghèo thành U 235, thori hoặc các hợp chất của các sản phẩm trên:',
    Unit: '',
  },
  {
    HsCode: '28443010',
    Description: 'Urani đã làm giàu thành U 235 và hợp chất của nó; thori và hợp chất của nó',
    Unit: 'kg',
  },
  {
    HsCode: '28443090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Nguyên tố phóng xạ và đồng vị phóng xạ và các hợp chất trừ loại thuộc phân nhóm 2844.10, 2844.20 hoặc 2844.30; hợp kim, các chất phân tán (kể cả gốm kim loại), các sản phẩm gốm và các hỗn hợp có chứa các nguyên tố, đồng vị hoặc các hợp chất trên; chất thải phóng xạ:',
    Unit: '',
  },
  {
    HsCode: '28444100',
    Description:
      'Triti và các hợp chất của nó; hợp kim, chất phân tán (kể cả gốm kim loại), các sản phẩm gốm và các hỗn hợp chứa triti hoặc các hợp chất của nó',
    Unit: 'kg',
  },
  {
    HsCode: '28444200',
    Description:
      'Actini-225, actini-227, californi-253, curi-240, curi-241, curi-242, curi-243, curi-244, einsteini-253, einsteini-254, gadolini-148, poloni-208, poloni209, poloni-210, radi-223, urani-230 hoặc urani-232, và các hợp chất của chúng; hợp kim, chất phân tán (kể cả gốm kim loại), các sản phẩm gốm và các hỗn hợp chứa các nguyên tố hoặc các hợp chất này',
    Unit: 'kg',
  },
  {
    HsCode: '28444300',
    Description:
      'Các nguyên tố, đồng vị và hợp chất phóng xạ khác; hợp kim khác, chất phân tán khác (kể cả gốm kim loại), các sản phẩm gốm và các hỗn hợp khác chứa các nguyên tố, đồng vị hoặc hợp chất này',
    Unit: 'kg',
  },
  {
    HsCode: '28444400',
    Description: 'Phế liệu phóng xạ',
    Unit: 'kg',
  },
  {
    HsCode: '28445000',
    Description: 'Hộp (cartridges) nhiên liệu đã bức xạ của lò phản ứng hạt nhân',
    Unit: 'kg',
  },
  {
    HsCode: '2845',
    Description:
      'Chất đồng vị trừ các đồng vị thuộc nhóm 28.44; các hợp chất, vô cơ hoặc hữu cơ, của các đồng vị này, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '28451000',
    Description: 'Nước nặng (deuterium oxide)',
    Unit: 'kg',
  },
  {
    HsCode: '28452000',
    Description: 'Bo được làm giàu bo-10 và các hợp chất của nó',
    Unit: 'kg',
  },
  {
    HsCode: '28453000',
    Description: 'Liti được làm giàu liti-6 và các hợp chất của nó',
    Unit: 'kg',
  },
  {
    HsCode: '28454000',
    Description: 'Heli-3',
    Unit: 'kg',
  },
  {
    HsCode: '28459000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2846',
    Description:
      'Các hợp chất, vô cơ hoặc hữu cơ, của kim loại đất hiếm, của ytri hoặc của scandi hoặc của hỗn hợp các kim loại này',
    Unit: '',
  },
  {
    HsCode: '28461000',
    Description: 'Hợp chất xeri',
    Unit: 'kg',
  },
  {
    HsCode: '28469000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2847',
    Description: 'Hydro peroxit, đã hoặc chưa làm rắn bằng ure.',
    Unit: '',
  },
  {
    HsCode: '28470010',
    Description: 'Dạng lỏng',
    Unit: 'kg',
  },
  {
    HsCode: '28470090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2849',
    Description: 'Carbua, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '28491000',
    Description: 'Của canxi ',
    Unit: 'kg',
  },
  {
    HsCode: '28492000',
    Description: 'Của silic ',
    Unit: 'kg',
  },
  {
    HsCode: '28499000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '28500000',
    Description:
      'Hydrua, nitrua, azit, silicua và borua, đã hoặc chưa xác định về mặt hóa học, trừ các hợp chất carbua của nhóm 28.49',
    Unit: 'kg',
  },
  {
    HsCode: '2852',
    Description: 'Các hợp chất vô cơ hoặc hữu cơ của thủy ngân, đã hoặc chưa xác định về mặt hóa học, trừ hỗn hống',
    Unit: '',
  },
  {
    HsCode: '285210',
    Description: 'Được xác định về mặt hóa học:',
    Unit: '',
  },
  {
    HsCode: '28521010',
    Description: 'Thủy ngân sulphat',
    Unit: 'kg',
  },
  {
    HsCode: '28521020',
    Description: 'Các hợp chất của thủy ngân được dùng như chất phát quang',
    Unit: 'kg',
  },
  {
    HsCode: '28521090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '285290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28529010',
    Description: 'Thủy ngân tanat',
    Unit: 'kg',
  },
  {
    HsCode: '28529020',
    Description:
      'Thủy ngân sulphua; thủy ngân polysulphua; thủy ngân polyphosphat; thủy ngân carbua; hợp chất thủy ngân dị vòng thuộc 2934.99.90; dẫn xuất của pepton thủy ngân; các dẫn xuất protein khác của thủy ngân',
    Unit: 'kg',
  },
  {
    HsCode: '28529090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '2853',
    Description:
      'Phosphua, đã hoặc chưa xác định về mặt hóa học, trừ phosphua sắt; các hợp chất vô cơ khác (bao gồm nước cất hoặc nước khử độ dẫn hoặc các loại nước tinh khiết tương tự); khí hóa lỏng (đã hoặc chưa loại bỏ khí hiếm); khí nén; hỗn hống, trừ hỗn hống của kim loại quý',
    Unit: '',
  },
  {
    HsCode: '28531000',
    Description: 'Cyanogen chloride (chlorcyan)',
    Unit: 'kg',
  },
  {
    HsCode: '285390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '28539010',
    Description: 'Nước khử khoáng',
    Unit: 'kg',
  },
  {
    HsCode: '28539090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 29',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hóa chất hữu cơ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trừ khi có yêu cầu khác, các nhóm thuộc Chương này chỉ bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (a) Các hợp chất hữu cơ đã được xác định về mặt hóa học riêng biệt, có hoặc không chứa tạp chất;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các hỗn hợp của hai hoặc nhiều chất đồng phân của cùng một hợp chất hữu cơ (có hoặc không chứa tạp chất), trừ hỗn hợp các chất đồng phân hydro carbon mạch hở (trừ các chất đồng phân lập thể), no hoặc chưa no (Chương 27);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các sản phẩm thuộc các nhóm từ 29.36 đến 29.39 hoặc ete đường, axetal đường và este đường, và các muối của chúng, của nhóm 29.40, hoặc các sản phẩm của nhóm 29.41, đã hoặc chưa xác định về mặt hóa học;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các sản phẩm được nêu ở mục (a), (b) hoặc (c) đã hòa tan trong nước;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Các sản phẩm được nêu ở (a), (b) hoặc (c) trên đây đã hoà tan trong các dung môi khác nhưng sự hoà tan chỉ là một phương pháp thông thường và cần thiết để đóng gói những sản phẩm nhằm mục đích duy nhất là bảo đảm an toàn hoặc để vận chuyển và dung môi này không tạo cho sản phẩm một công dụng đặc biệt nào khác ngoài công dụng thông thường của chúng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Các sản phẩm đã nêu ở (a), (b), (c), (d) hoặc (e) trên đây đã pha thêm chất ổn định (kể cả chất chống đóng cứng/chất chống đông vón) cần thiết để bảo quản hoặc vận chuyển;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Các sản phẩm được nêu ở (a), (b), (c), (d), (e) hoặc (f) trên đây đã pha thêm tác nhân chống bụi hoặc chất màu hoặc chất có mùi thơm hoặc chất gây nôn để dễ nhận biết hoặc vì lý do an toàn, với điều kiện là việc pha thêm đó không làm cho sản phẩm có công dụng đặc biệt ngoài công dụng thông thường của nó;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Các sản phẩm dưới đây, pha loãng theo nồng độ tiêu chuẩn, để sản xuất thuốc nhuộm azo: muối diazoni, các chất tiếp hợp dùng cho loại muối này và các chất amin có khả năng diazo hóa và các muối của chúng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hàng hóa thuộc nhóm 15.04 hoặc glyxerol thô thuộc nhóm 15.20;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Cồn etylic (nhóm 22.07 hoặc 22.08);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Methan hoặc propan (nhóm 27.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Hợp chất carbon được nêu ở Chú giải 2 của Chương 28;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Sản phẩm miễn dịch thuộc nhóm 30.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Urê (nhóm 31.02 hoặc 31.05);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Chất màu gốc động vật hoặc thực vật (nhóm 32.03), chất màu hữu cơ tổng hợp, các sản phẩm hữu cơ tổng hợp được sử dụng như tác nhân tăng sáng huỳnh quang hoặc chất phát quang (nhóm 32.04) hoặc thuốc nhuộm hoặc chất màu khác được làm thành một dạng nhất định hoặc đóng gói để bán lẻ (nhóm 32.12);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Enzym (nhóm 35.07);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Metaldehyt, hexamethylenetetramine hoặc chất tương tự, làm thành dạng nhất định (ví dụ, viên, que hoặc các dạng tương tự) để sử dụng như nhiên liệu, hoặc nhiên liệu lỏng hoặc khí hóa lỏng đựng trong đồ chứa có dung tích không quá 300 cm3 dùng để bơm hoặc bơm lại ga bật lửa hút thuốc hoặc các loại bật lửa tương tự (nhóm 36.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(k) Các sản phẩm dùng như chất dập lửa để nạp cho các bình cứu hoả hoặc các loại lựu đạn dập lửa, thuộc nhóm 38.13; các chất tẩy mực đóng gói để bán lẻ, thuộc nhóm 38.24; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các bộ phận quang học, ví dụ, làm bằng ethylenediamine tartrate (nhóm 90.01).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các mặt hàng có thể xếp vào hai hoặc nhiều nhóm thuộc Chương này phải xếp vào nhóm có số thứ tự sau cùng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Đối với các nhóm từ 29.04 đến 29.06, từ 29.08 đến 29.11 và từ 29.13 đến 29.20, khi đề cập đến các dẫn xuất halogen hóa, sulphonat hóa, nitrat hóa hoặc nitroso hóa đều đề cập đến các dẫn xuất kết hợp, như các dẫn xuất  sulpho-halogen hóa, nitro-halogen hóa, nitro-sulphonat hóa hoặc nitro-sulpho-halogen hóa.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' Theo mục đích của nhóm 29.29, các nhóm nitro hóa hoặc nitroso hóa không được coi là có ""chức nitơ"".',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Theo mục đích của các nhóm 29.11, 29.12, 29.14, 29.18 và 29.22, ""chức oxy"", nhóm chứa oxy hữu cơ đặc trưng của các nhóm tương ứng trên, được giới hạn trong các chức oxy đề cập trong các nhóm từ 29.05 đến 29.20.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. (A) Este của hợp chất hữu cơ chức axit thuộc phân Chương I đến VII với các hợp chất hữu cơ của các phân Chương này phải xếp cùng nhóm với các hợp chất ấy có nghĩa là được xếp vào nhóm có số thứ tự sau cùng trong các phân Chương này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' (B) Este của cồn etylic với các hợp chất hữu cơ có chức axit trong phân Chương từ I đến VII phải xếp vào cùng nhóm với các hợp chất có chức axit tương ứng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(C) Theo Chú giải 1 của Phần VI và Chú giải 2 của Chương 28 thì:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' (1) Muối vô cơ của các hợp chất hữu cơ như các hợp chất có chức axit-, phenolhoặc enolhoặc các bazơ hữu cơ, của các phân Chương từ I đến X hoặc nhóm 29.42, phải được xếp vào trong nhóm tương ứng với hợp chất hữu cơ; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) Các muối được tạo nên giữa các hợp chất hữu cơ của các phân Chương I đến X hoặc nhóm 29.42 phải được xếp vào nhóm tương ứng với bazơ hoặc axit (kể cả các hợp chất có chức phenol hoặc enol) mà chính từ đó chúng được tạo thành, phải xếp vào nhóm có số thứ tự sau cùng trong Chương; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' (3) Hợp chất phối trí, trừ các sản phẩm có thể được phân loại trong phân Chương XI hoặc nhóm 29.41, được phân loại trong nhóm có thứ tự sau cùng trong Chương 29, trong số những phân đoạn phù hợp được tạo bởi “quá trình bẻ gãy” của tất cả các liên kết kim loại, trừ liên kết carbon – kim loại.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (D) Alcoholate kim loại phải xếp vào cùng nhóm với rượu tương ứng trừ rượu ethanol (nhóm 29.05).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (E) Halogenua của axit carboxylic phải xếp vào cùng nhóm với axit tương đương.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Các hợp chất của các nhóm 29.30 và 29.31 là các hợp chất hữu cơ mà trong đó các phân tử chứa, ngoài các nguyên tử hydro, oxy hoặc nitơ, còn có chứa các nguyên tử của các phi kim loại khác hoặc của kim loại (như lưu huỳnh, arsen hoặc chì) liên kết trực tiếp với các nguyên tử carbon.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nhóm 29.30 (hợp chất lưu huỳnh hữu cơ) và nhóm 29.31 (hợp chất vô cơ – hữu cơ khác) không bao gồm các dẫn xuất đã sulphonat hóa hoặc halogen hóa (kể cả các dẫn xuất kết hợp), ngoài hydro, oxy và nitơ, chỉ có nguyên tử của lưu huỳnh hoặc halogen trực tiếp liên kết với carbon, điều này làm cho các hợp chất trên mang tính chất của dẫn xuất halogen hóa hoặc sulphonat hóa (hoặc các dẫn xuất kết hợp).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Nhóm 29.32, 29.33 và 29.34 không bao gồm epoxit có vòng 3 cạnh (có 3 nguyên tử ở trên vòng), xeton peroxides, polyme vòng của aldehyt hoặc của thioaldehyt, anhydrit của các axit carboxilic polybasic, este vòng của rượu đa chức hoặc phenols đa chức với axit polybasic, hoặc imide của axit polybasic.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' Các quy định này chỉ áp dụng khi các dị tố loại ở trên vòng là kết quả duy nhất của chức năng đóng vòng hoặc các chức năng kể đến ở đây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '8. Theo mục đích của nhóm 29.37:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' (a) thuật ngữ “hormon” bao gồm các yếu tố làm tiết hormon hoặc kích thích hormon, các chất ức chế và kháng hormon (anti-hormon);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' (b) khái niệm “được sử dụng chủ yếu như hormon” không chỉ bao gồm những dẫn xuất hormon và những chất có cấu trúc tương tự được sử dụng chủ yếu vì tác dụng hormon của chúng, mà còn bao gồm những dẫn xuất hormon và những chất có cấu trúc tương tự được sử dụng chủ yếu như chất trung gian trong quá trình tổng hợp các sản phẩm thuộc nhóm này. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong bất kỳ nhóm nào của Chương này, các dẫn xuất của một hợp chất hóa học (hoặc một nhóm các hợp chất hóa học) cần được xếp vào cùng một phân nhóm với hợp chất ấy (hoặc nhóm hợp chất) với điều kiện là không có sự miêu tả đặc trưng hơn trong các phân nhóm khác và không có phân nhóm nào mang tên “Loại khác” trong những phân nhóm có liên quan.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chú giải 3 Chương 29 không áp dụng cho các phân nhóm của Chương này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG I',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'HYDROCARBON VÀ CÁC DẪN XUẤT HALOGEN HÓA, SULPHONAT HÓA, NITRO HÓA HOẶC NITROSO HÓA CỦA CHÚNG ',
    Unit: '',
  },
  {
    HsCode: '2901',
    Description: 'Hydrocarbon mạch hở',
    Unit: '',
  },
  {
    HsCode: '29011000',
    Description: 'No',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Chưa no:',
    Unit: '',
  },
  {
    HsCode: '29012100',
    Description: 'Etylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29012200',
    Description: 'Propen (propylen)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29012300',
    Description: 'Buten (butylen) và các đồng phân của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29012400',
    Description: '1,3 – butadien và isopren',
    Unit: 'kg/lít',
  },
  {
    HsCode: '290129',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29012910',
    Description: 'Axetylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29012920',
    Description: 'Hexen và các đồng phân của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29012990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2902',
    Description: 'Hydrocarbon mạch vòng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' Cyclanes, cyclenes và cycloterpenes:',
    Unit: '',
  },
  {
    HsCode: '29021100',
    Description: 'Cyclohexane',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29021900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29022000',
    Description: 'Benzen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29023000',
    Description: 'Toluen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Xylenes:',
    Unit: '',
  },
  {
    HsCode: '29024100',
    Description: 'o-Xylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29024200',
    Description: 'm-Xylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29024300',
    Description: 'p-Xylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29024400',
    Description: 'Hỗn hợp các đồng phân của xylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29025000',
    Description: 'Styren',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29026000',
    Description: 'Etylbenzen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29027000',
    Description: 'Cumen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '290290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29029010',
    Description: 'Dodecylbenzen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29029020',
    Description: 'Các loại alkylbenzen khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29029090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2903',
    Description: 'Dẫn xuất halogen hóa của hydrocarbon',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất clo hóa của hydrocarbon mạch hở, no:',
    Unit: '',
  },
  {
    HsCode: '290311',
    Description: 'Clorometan (clorua metyl) và cloroetan (clorua etyl):',
    Unit: '',
  },
  {
    HsCode: '29031110',
    Description: 'Clorometan (clorua metyl)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29031190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29031200',
    Description: 'Diclorometan (metylen clorua)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29031300',
    Description: 'Cloroform (triclorometan)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29031400',
    Description: 'Carbon tetraclorua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29031500',
    Description: 'Etylen diclorua (ISO) (1,2-dicloroetan)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '290319',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29031920',
    Description: '1,1,1-Tricloroetan (metyl cloroform)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29031990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất clo hóa của hydrocarbon mạch hở, chưa no:',
    Unit: '',
  },
  {
    HsCode: '29032100',
    Description: 'Vinyl clorua (cloroetylen)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29032200',
    Description: 'Tricloroetylen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29032300',
    Description: 'Tetracloroetylen (percloroetylen)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29032900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất flo hóa của hydrocarbon mạch hở, no:',
    Unit: '',
  },
  {
    HsCode: '29034100',
    Description: 'Triflorometan (HFC-23)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034200',
    Description: 'Diflorometan (HFC-32)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034300',
    Description: 'Florometan (HFC-41), 1,2-difloroetan (HFC-152) và 1,1-difloroetan (HFC-152a)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034400',
    Description: 'Pentafloroetan (HFC-125), 1,1,1-trifloroetan (HFC-143a) và 1,1,2-trifloroetan (HFC-143)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034500',
    Description: '1,1,1,2-Tetrafloroetan (HFC-134a) và 1,1,2,2-tetrafloroetan (HFC-134)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034600',
    Description:
      '1,1,1,2,3,3,3-Heptafloropropan (HFC-227ea), 1,1,1,2,2,3-hexafloropropan (HFC-236cb), 1,1,1,2,3,3-hexafloropropan (HFC-236ea) và 1,1,1,3,3,3-hexafloropropan (HFC-236fa)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034700',
    Description: '1,1,1,3,3-Pentafloropropan (HFC-245fa) và 1,1,2,2,3-pentafloropropan (HFC-245ca)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034800',
    Description: '1,1,1,3,3-Pentaflorobutan (HFC-365mfc) và 1,1,1,2,2,3,4,5,5,5-decafloropentan (HFC-43-10mee)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29034900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất flo hóa của hydrocarbon mạch hở, chưa no:',
    Unit: '',
  },
  {
    HsCode: '29035100',
    Description:
      '2,3,3,3-Tetrafloropropen (HFO-1234yf), 1,3,3,3-tetrafloropropen (HFO-1234ze) và (Z)-1,1,1,4,4,4-hexafloro-2-buten (HFO-1336mzz)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29035900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất brom hóa hoặc iot hóa của hydrocarbon mạch hở:',
    Unit: '',
  },
  {
    HsCode: '29036100',
    Description: 'Metyl bromua (bromometan)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29036200',
    Description: 'Etylen dibromua (ISO) (1,2dibromoetan)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29036900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất halogen hóa của hydrocarbon mạch hở chứa hai hoặc nhiều halogen khác nhau:',
    Unit: '',
  },
  {
    HsCode: '29037100',
    Description: 'Clorodiflorometan (HCFC-22)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037200',
    Description: 'Diclorotrifloroetan (HCFC-123)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037300',
    Description: 'Diclorofloroetan (HCFC-141, 141b)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037400',
    Description: 'Clorodifloroetan (HCFC-142, 142b)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037500',
    Description: 'Dicloropentafloropropan (HCFC-225, 225ca, 225cb)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037600',
    Description:
      'Bromoclorodiflorometan (Halon-1211), bromotriflorometan (Halon-1301) và dibromotetrafloroetan (Halon-2402)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037700',
    Description: 'Loại khác, perhalogen hóa chỉ với flo và clo',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037800',
    Description: 'Các dẫn xuất perhalogen hóa khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29037900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất halogen hóa của hydrocarbon cyclanic, cyclenic hoặc cycloterpenic:',
    Unit: '',
  },
  {
    HsCode: '29038100',
    Description: '1,2,3,4,5,6-Hexachlorocyclohexane (HCH (ISO)), kể cả lindane (ISO, INN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29038200',
    Description: 'Aldrin (ISO), chlordane (ISO) và heptachlor (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29038300',
    Description: 'Mirex (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29038900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất halogen hóa của hydrocarbon thơm:',
    Unit: '',
  },
  {
    HsCode: '29039100',
    Description: 'Chlorobenzene, o-dichlorobenzene và p-dichlorobenzene',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29039200',
    Description:
      'Hexachlorobenzene (ISO) và DDT (ISO) (clofenotane (INN),1,1,1-trichloro-2,2-bis(p-chlorophenyl)ethane)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29039300',
    Description: 'Pentachlorobenzene (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29039400',
    Description: 'Hexabromobiphenyls',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29039900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2904',
    Description: 'Dẫn xuất sulphonat hóa, nitro hóa hoặc nitroso hóa của hydrocarbon, đã hoặc chưa halogen hóa',
    Unit: '',
  },
  {
    HsCode: '29041000',
    Description: 'Các dẫn xuất chỉ chứa các nhóm sulpho, muối  và etyl este của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '290420',
    Description: 'Các dẫn xuất chỉ chứa các nhóm nitro hoặc chỉ chứa các nhóm nitroso:',
    Unit: '',
  },
  {
    HsCode: '29042010',
    Description: 'Trinitrotoluen',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29042090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Perfluorooctane sulphonic axit, muối của nó và perfluorooctane sulphonyl fluoride:',
    Unit: '',
  },
  {
    HsCode: '29043100',
    Description: 'Perfluorooctane sulphonic axit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29043200',
    Description: 'Ammonium perfluorooctane sulphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29043300',
    Description: 'Lithium perfluorooctane sulphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29043400',
    Description: 'Kali perfluorooctane sulphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29043500',
    Description: 'Muối khác của perfluorooctane sulphonic axit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29043600',
    Description: 'Perfluorooctane sulphonyl fluoride',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29049100',
    Description: 'Trichloronitromethane (chloropicrin)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29049900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG II',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'RƯỢU VÀ CÁC DẪN XUẤT HALOGEN HÓA, SULPHONAT HÓA, NITRO HÓA HOẶC NITROSO HÓA CỦA CHÚNG',
    Unit: '',
  },
  {
    HsCode: '2905',
    Description: 'Rượu mạch hở và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rượu no đơn chức:',
    Unit: '',
  },
  {
    HsCode: '29051100',
    Description: 'Metanol (rượu metylic)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29051200',
    Description: 'Propan-1-ol (rượu propylic) và propan-2-ol (rượu isopropylic)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29051300',
    Description: 'Butan-1-ol (rượu n-butylic)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29051400',
    Description: 'Butanol khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29051600',
    Description: 'Octanol (rượu octylic) và đồng phân của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29051700',
    Description: 'Dodecan-1-ol (lauryl alcohol), hexadecan-1-ol (cetyl alcohol) và octadecan-1-ol (stearyl alcohol)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29051900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Rượu đơn chức chưa no:',
    Unit: '',
  },
  {
    HsCode: '29052200',
    Description: 'Rượu tecpen mạch hở ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29052900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Rượu hai chức:',
    Unit: '',
  },
  {
    HsCode: '29053100',
    Description: 'Etylen glycol (ethanediol)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29053200',
    Description: 'Propylen glycol (propan-1,2-diol)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29053900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Rượu đa chức khác:',
    Unit: '',
  },
  {
    HsCode: '29054100',
    Description: '2-Ethyl-2-(hydroxymethyl) propane-1,3-diol (trimethylolpropane)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29054200',
    Description: 'Pentaerythritol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29054300',
    Description: 'Mannitol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29054400',
    Description: 'D-glucitol (sorbitol)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29054500',
    Description: 'Glyxerin',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29054900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của rượu mạch hở:',
    Unit: '',
  },
  {
    HsCode: '29055100',
    Description: 'Ethchlorvynol (INN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29055900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2906',
    Description: 'Rượu mạch vòng và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cyclanic, cyclenic hoặc cycloterpenic:',
    Unit: '',
  },
  {
    HsCode: '29061100',
    Description: 'Menthol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29061200',
    Description: 'Cyclohexanol, methylcyclohexanols và dimethylcyclohexanols',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29061300',
    Description: 'Sterols và inositols',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29061900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại thơm:',
    Unit: '',
  },
  {
    HsCode: '29062100',
    Description: 'Rượu benzyl',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29062900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG III',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'PHENOLS, RƯỢU-PHENOL, VÀ CÁC DẪN XUẤT HALOGEN HÓA, SULPHONAT HÓA, NITRO HÓA HOẶC NITROSO HÓA CỦA CHÚNG',
    Unit: '',
  },
  {
    HsCode: '2907',
    Description: 'Phenols; rượu-phenol',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Monophenols:',
    Unit: '',
  },
  {
    HsCode: '29071100',
    Description: 'Phenol (hydroxybenzene) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29071200',
    Description: 'Cresols và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29071300',
    Description: 'Octylphenol, nonylphenol và các đồng phân của chúng; muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29071500',
    Description: 'Naphthols và các muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29071900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Polyphenols; rượu-phenol:',
    Unit: '',
  },
  {
    HsCode: '29072100',
    Description: 'Resorcinol và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29072200',
    Description: 'Hydroquinone (quinol) và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29072300',
    Description: '4,4’-Isopropylidenediphenol (bisphenol A, diphenylolpropane) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '290729',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29072910',
    Description: 'Rượu-phenol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29072990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2908',
    Description: 'Dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của phenols hoặc của rượu-phenol',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất chỉ chứa các nguyên tử halogen thay thế và muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29081100',
    Description: 'Pentachlorophenol (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29081900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29089100',
    Description: 'Dinoseb (ISO) và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29089200',
    Description: '4,6-Dinitro-o-cresol (DNOC (ISO)) và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29089900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG IV',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' ETE, PEROXIT RƯỢU, PEROXIT ETE,  PEROXIT AXETAL VÀ  PEROXIT HEMIAXETAL, PEROXIT XETON, EPOXIT CÓ VÒNG BA CẠNH, AXETAL VÀ HEMIAXETAL VÀ CÁC DẪN XUẤT HALOGEN HÓA, SULPHONAT HÓA, NITRO HÓA HOẶC NITROSO HÓA CỦA CÁC CHẤT TRÊN',
    Unit: '',
  },
  {
    HsCode: '2909',
    Description:
      'Ete, rượu-ete, phenol-ete, phenol-rượu-ete, peroxit rượu, peroxit ete, peroxit axetal và peroxit hemiaxetal, peroxit xeton (đã hoặc chưa xác định về mặt hóa học), và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của các chất trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Ete mạch hở và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng:',
    Unit: '',
  },
  {
    HsCode: '29091100',
    Description: 'Dietyl ete',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29091900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29092000',
    Description:
      'Ete cyclanic, cyclenic hoặc cycloterpenic và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29093000',
    Description: 'Ete thơm và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Rượu ete và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng:',
    Unit: '',
  },
  {
    HsCode: '29094100',
    Description: '2,2’-Oxydietanol (dietylen glycol, digol)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29094300',
    Description: 'Ete monobutyl của etylen glycol hoặc của dietylen glycol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29094400',
    Description: 'Ete monoalkyl khác của etylen glycol hoặc của dietylen glycol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29094900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29095000',
    Description:
      'Phenol-ete, phenol-rượu-ete và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29096000',
    Description:
      'Peroxit rượu, peroxit ete, peroxit axetal và peroxit hemiaxetal, peroxit xeton và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2910',
    Description:
      'Epoxit, rượu epoxy, phenol epoxy và ete epoxy, có một vòng ba cạnh và các dẫn xuất đã halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: '',
  },
  {
    HsCode: '29101000',
    Description: 'Oxiran (etylen oxit)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29102000',
    Description: 'Metyloxiran (propylen oxit)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29103000',
    Description: '1-Chloro-2,3-epoxypropane (epichlorohydrin)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29104000',
    Description: 'Dieldrin (ISO, INN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29105000',
    Description: 'Endrin (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29109000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29110000',
    Description:
      'Các axetal và hemiaxetal, có hoặc không có chức oxy khác, và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG V ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'HỢP CHẤT CHỨC ALDEHYT',
    Unit: '',
  },
  {
    HsCode: '2912',
    Description: 'Aldehyt, có hoặc không có chức oxy khác; polyme mạch vòng của aldehyt; paraformaldehyt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Aldehyt mạch hở không có chức oxy khác:',
    Unit: '',
  },
  {
    HsCode: '29121100',
    Description: 'Metanal (formaldehyt)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29121200',
    Description: 'Etanal (axetaldehyt)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29121900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Aldehyt mạch vòng không có chức oxy khác:',
    Unit: '',
  },
  {
    HsCode: '29122100',
    Description: 'Benzaldehyt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29122900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Rượu-aldehyt, ete-aldehyt, phenol-aldehyt và aldehyt có chức oxy khác:',
    Unit: '',
  },
  {
    HsCode: '29124100',
    Description: 'Vanillin (4hydroxy3methoxybenzaldehyt)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29124200',
    Description: 'Ethylvanillin (3-ethoxy-4-hydroxybenzaldehyt)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291249',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29124910',
    Description: 'Aldehyt rượu khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29124990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29125000',
    Description: 'Polyme mạch vòng của aldehyt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29126000',
    Description: 'Paraformaldehyt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29130000',
    Description: 'Dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của các sản phẩm thuộc nhóm 29.12',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG VI ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'HỢP CHẤT CHỨC XETON VÀ HỢP CHẤT CHỨC QUINON',
    Unit: '',
  },
  {
    HsCode: '2914',
    Description:
      'Xeton và quinon, có hoặc không có chức oxy khác, và các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' Xeton mạch hở không có chức oxy khác:',
    Unit: '',
  },
  {
    HsCode: '29141100',
    Description: 'Axeton',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29141200',
    Description: 'Butanon (metyl etyl xeton)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29141300',
    Description: '4Metylpentan-2-one (metyl isobutyl xeton)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29141900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Xeton cyclanic, xeton cyclenic hoặc xeton cycloterpenic không có chức oxy khác:',
    Unit: '',
  },
  {
    HsCode: '29142200',
    Description: 'Cyclohexanon và metylcyclohexanon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29142300',
    Description: 'Ionon và metylionon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291429',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29142910',
    Description: 'Long não ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29142990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Xeton thơm không có chức oxy khác:',
    Unit: '',
  },
  {
    HsCode: '29143100',
    Description: 'Phenylaxeton (phenylpropan -2one)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29143900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29144000',
    Description: 'Rượu-xeton và aldehyt-xeton',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29145000',
    Description: 'Phenol-xeton và xeton có chức oxy khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Quinones:',
    Unit: '',
  },
  {
    HsCode: '29146100',
    Description: 'Anthraquinon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29146200',
    Description: 'Coenzyme Q10 (ubidecarenone (INN))',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29146900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa:',
    Unit: '',
  },
  {
    HsCode: '29147100',
    Description: 'Chlordecone (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29147900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG VII',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' AXIT CARBOXYLIC VÀ CÁC ANHYDRIT, HALOGENUA, PEROXIT VÀ PEROXYAXIT CỦA CHÚNG VÀ CÁC DẪN XUẤT HALOGEN HÓA, SULPHONAT HÓA, NITRO HÓA HOẶC NITROSO HÓA CỦA CÁC CHẤT TRÊN',
    Unit: '',
  },
  {
    HsCode: '2915',
    Description:
      'Axit carboxylic đơn chức no mạch hở và các anhydrit, halogenua, peroxit và peroxyaxit của chúng; các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của các chất trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Axit formic, muối và este của nó:',
    Unit: '',
  },
  {
    HsCode: '29151100',
    Description: 'Axit formic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29151200',
    Description: 'Muối của axit formic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29151300',
    Description: 'Este của axit formic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Axit axetic và muối của nó; anhydrit axetic:',
    Unit: '',
  },
  {
    HsCode: '29152100',
    Description: 'Axit axetic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29152400',
    Description: 'Anhydrit axetic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291529',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29152910',
    Description: 'Natri axetat; các coban axetat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29152990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Este của axit axetic:',
    Unit: '',
  },
  {
    HsCode: '29153100',
    Description: 'Etyl axetat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29153200',
    Description: 'Vinyl axetat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29153300',
    Description: 'n-Butyl axetat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29153600',
    Description: 'Dinoseb(ISO) axetat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291539',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29153910',
    Description: 'Isobutyl axetat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29153920',
    Description: '2Ethoxyetyl axetat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29153990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29154000',
    Description: 'Axit mono-, dihoặc tricloroaxetic, muối và este của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29155000',
    Description: 'Axit propionic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29156000',
    Description: 'Axit butanoic, axit pentanoic, muối và este của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291570',
    Description: 'Axit palmitic, axit stearic, muối và este của chúng:',
    Unit: '',
  },
  {
    HsCode: '29157010',
    Description: 'Axit palmitic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29157020',
    Description: 'Axit stearic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29157030',
    Description: 'Muối và este của axit stearic ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29159020',
    Description: 'Axit lauric, axit myristic, muối và este của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29159030',
    Description: 'Axit caprylic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29159040',
    Description: 'Axit capric, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29159090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2916',
    Description:
      'Axit carboxylic đơn chức mạch hở chưa no, axit carboxylic đơn chức mạch vòng, các anhydrit, halogenua, peroxit và peroxyaxit của chúng; các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Axit carboxylic đơn chức mạch hở chưa no, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên:',
    Unit: '',
  },
  {
    HsCode: '29161100',
    Description: 'Axit acrylic và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29161200',
    Description: 'Este của axit acrylic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29161300',
    Description: 'Axit metacrylic và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291614',
    Description: 'Este của axit metacrylic:',
    Unit: '',
  },
  {
    HsCode: '29161410',
    Description: 'Metyl metacrylat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29161490',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29161500',
    Description: 'Axit oleic, axit linoleic hoặc axit linolenic, muối và este của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29161600',
    Description: 'Binapacryl (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29161900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29162000',
    Description:
      'Axit carboxylic đơn chức của cyclanic, cyclenic hoặc cycloterpenic, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Axit carboxylic thơm đơn chức, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên:',
    Unit: '',
  },
  {
    HsCode: '29163100',
    Description: 'Axit benzoic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291632',
    Description: 'Benzoyl peroxit và benzoyl clorua:',
    Unit: '',
  },
  {
    HsCode: '29163210',
    Description: 'Benzoyl peroxit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29163220',
    Description: 'Benzoyl clorua ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29163400',
    Description: 'Axit phenylaxetic và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291639',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29163910',
    Description: 'Axit axetic 2,4Diclorophenyl và muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29163920',
    Description: 'Este của axit phenylaxetic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29163990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2917',
    Description:
      'Axit carboxylic đa chức, các anhydrit, halogenua, peroxit và peroxyaxit của chúng; các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của các chất trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Axit carboxylic đa chức mạch hở, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên:',
    Unit: '',
  },
  {
    HsCode: '29171100',
    Description: 'Axit oxalic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291712',
    Description: 'Axit adipic, muối và este của nó:',
    Unit: '',
  },
  {
    HsCode: '29171210',
    Description: 'Dioctyl adipat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29171290',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29171300',
    Description: 'Axit azelaic, axit sebacic, muối và este của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29171400',
    Description: 'Anhydrit maleic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29171900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29172000',
    Description:
      'Axit carboxylic đa chức của cyclanic, cyclenic hoặc cycloterpenic, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Axit carboxylic thơm đa chức, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên:',
    Unit: '',
  },
  {
    HsCode: '29173200',
    Description: 'Dioctyl orthophthalates',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29173300',
    Description: 'Dinonyl hoặc didecyl orthophthalates',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291734',
    Description: 'Các este khác của axit orthophthalic:',
    Unit: '',
  },
  {
    HsCode: '29173410',
    Description: 'Dibutyl orthophthalates',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29173490',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29173500',
    Description: ' Phthalic anhydrit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29173600',
    Description: 'Axit terephthalic và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29173700',
    Description: 'Dimetyl terephthalat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291739',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29173910',
    Description: 'Trioctyltrimellitate ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29173920',
    Description:
      'Các hợp chất phthalic khác của loại được sử dụng như chất hóa dẻo và este của anhydrit phthalic (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29173990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2918',
    Description:
      'Axit carboxylic có thêm chức oxy và các anhydrit, halogenua, peroxit và peroxyaxit của chúng; các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của các chất trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Axit carboxylic có chức rượu nhưng không có chức oxy khác, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên:',
    Unit: '',
  },
  {
    HsCode: '29181100',
    Description: 'Axit lactic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181200',
    Description: 'Axit tartaric',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181300',
    Description: 'Muối và este của axit tartaric',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181400',
    Description: 'Axit citric',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291815',
    Description: 'Muối và este của axit citric:',
    Unit: '',
  },
  {
    HsCode: '29181510',
    Description: 'Canxi citrat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181590',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181600',
    Description: 'Axit gluconic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181700',
    Description: 'Axit 2,2-Diphenyl-2-hydroxyacetic (axit benzilic)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181800',
    Description: 'Chlorobenzilate (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29181900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Axit carboxylic có chức phenol nhưng không có chức oxy khác, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên:',
    Unit: '',
  },
  {
    HsCode: '29182100',
    Description: 'Axit salicylic và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29182200',
    Description: 'Axit o-Axetylsalicylic, muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29182300',
    Description: 'Este khác của axit salicylic và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '291829',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29182910',
    Description: 'Este sulphonic alkyl của phenol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29182990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29183000',
    Description:
      'Axit carboxylic có chức aldehyt hoặc chức xeton nhưng không có chức oxy khác, các anhydrit, halogenua, peroxit, peroxyaxit của chúng và các dẫn xuất của các chất trên',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29189100',
    Description: '2,4,5-T (ISO) (axit 2,4,5-triclorophenoxyaxetic), muối và este của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29189900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG VIII',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'ESTE CỦA CÁC AXIT VÔ CƠ CỦA CÁC PHI KIM LOẠI VÀ MUỐI CỦA CHÚNG, CÁC DẪN XUẤT HALOGEN HÓA, SULPHONAT HÓA, NITRO HÓA, HOẶC NITROSO HÓA CỦA CÁC CHẤT TRÊN',
    Unit: '',
  },
  {
    HsCode: '2919',
    Description:
      'Este phosphoric và muối của chúng, kể cả lacto phosphat; các dẫn xuất đã halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng.',
    Unit: '',
  },
  {
    HsCode: '29191000',
    Description: 'Tris(2,3-dibromopropyl) phosphat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29199000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2920',
    Description:
      'Este của axit vô cơ khác của các phi kim loại (trừ este của hydro halogenua) và muối của chúng; các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của các chất trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Este thiophosphoric (phosphorothioates) và muối của chúng; các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng:',
    Unit: '',
  },
  {
    HsCode: '29201100',
    Description: 'Parathion (ISO) và parathion-methyl (ISO) (methylparathion)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29201900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Phosphite este và muối của chúng; các dẫn xuất halogen hóa, sulphonat hóa, nitro hóa hoặc nitroso hóa của chúng:',
    Unit: '',
  },
  {
    HsCode: '29202100',
    Description: 'Dimethyl phosphite',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29202200',
    Description: 'Diethyl phosphite',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29202300',
    Description: 'Trimethyl phosphite',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29202400',
    Description: 'Triethyl phosphite',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29202900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29203000',
    Description: 'Endosulfan (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29209000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG IX',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'HỢP CHẤT CHỨC NITƠ',
    Unit: '',
  },
  {
    HsCode: '2921',
    Description: 'Hợp chất chức amin',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Amin đơn chức mạch hở và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29211100',
    Description: 'Metylamin, dihoặc trimetylamin và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29211200',
    Description: '2-(N,N-Dimethylamino) ethylchloride hydrochloride',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29211300',
    Description: '2-(N,N-Diethylamino)ethylchloride hydrochloride',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29211400',
    Description: '2-(N,N-Diisopropylamino) ethylchloride hydrochloride',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29211900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Amin đa chức mạch hở và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29212100',
    Description: 'Etylendiamin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29212200',
    Description: 'Hexametylendiamin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29212900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29213000',
    Description:
      'Amin đơn hoặc đa chức của cyclanic, cyclenic hoặc cycloterpenic, và các dẫn xuất của chúng; muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Amin thơm đơn chức và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29214100',
    Description: 'Anilin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29214200',
    Description: 'Các dẫn xuất anilin và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29214300',
    Description: 'Toluidines và các dẫn xuất của chúng; muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29214400',
    Description: 'Diphenylamin và các dẫn xuất của nó; muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29214500',
    Description:
      '1Naphthylamine (alpha-naphthylamine), 2-naphthylamine (beta-naphthylamine) và các dẫn xuất của chúng; muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29214600',
    Description:
      'Amfetamine (INN), benzfetamine (INN), dexamfetamine (INN), etilamfetamine (INN), fencamfamin (INN), lefetamine (INN), levamfetamine (INN), mefenorex (INN) và phentermine (INN); muối của chúng ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29214900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Amin thơm đa chức và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29215100',
    Description: 'o-, m-, pPhenylenediamine, diaminotoluenes và các dẫn xuất của chúng; muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29215900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2922',
    Description: 'Hợp chất amino chức oxy',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Rượu amino, trừ loại chứa 2 chức oxy trở lên, ete và este của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29221100',
    Description: 'Monoetanolamin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221200',
    Description: 'Dietanolamin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221400',
    Description: 'Dextropropoxyphene (INN) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221500',
    Description: 'Triethanolamine',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221600',
    Description: 'Diethanolammonium perfluorooctane sulphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221700',
    Description: 'Methyldiethanolamine và ethyldiethanolamine',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221800',
    Description: '2-(N,N-Diisopropylamino)ethanol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292219',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29221910',
    Description: 'Ethambutol và muối của nó, este và các dẫn xuất khác ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221920',
    Description: 'Rượu n-butyl D-2-Amino (D-2-Amino-n-Butyl-alcohol)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29221990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Amino-naphthols và amino-phenols khác, trừ loại chứa hai chức oxy trở lên, ete và este của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29222100',
    Description: 'Axit aminohydroxynaphthalensulphonic và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29222900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Amino aldehyt, amino-xeton và amino-quinon, trừ loại chứa hai chức oxy trở lên; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29223100',
    Description: 'Amfepramone (INN), methadone (INN) và normethadone (INN); muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29223900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Axit amino, trừ loại chứa 2 chức oxy trở lên, và este của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29224100',
    Description: 'Lysin và este của nó; muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292242',
    Description: 'Axit glutamic và muối của nó:',
    Unit: '',
  },
  {
    HsCode: '29224210',
    Description: 'Axit glutamic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29224220',
    Description: 'Muối natri của axit glutamic (MSG)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29224290',
    Description: 'Muối khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29224300',
    Description: 'Axit anthranilic và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29224400',
    Description: 'Tilidine (INN) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29224900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292250',
    Description: 'Phenol-rượu-amino, phenol-axit-amino và các hợp chất amino khác có chức oxy:',
    Unit: '',
  },
  {
    HsCode: '29225010',
    Description: 'p-Aminosalicylic axit và muối, este và các dẫn xuất khác của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29225090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2923',
    Description:
      'Muối và hydroxit amoni bậc 4; lecithins và các phosphoaminolipid khác, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '29231000',
    Description: 'Cholin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292320',
    Description: 'Lecithins và các phosphoaminolipid khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lecithins:',
    Unit: '',
  },
  {
    HsCode: '29232011',
    Description: 'Từ thực vật',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29232019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29232090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29233000',
    Description: 'Tetraethylammonium perfluorooctane sulphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29234000',
    Description: 'Didecyldimethylammonium perfluorooctane sulphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29239000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2924',
    Description: 'Hợp chất chức carboxyamit; hợp chất chức amit của axit carbonic',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Amit mạch hở (kể cả carbamates mạch hở) và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29241100',
    Description: 'Meprobamate (INN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292412',
    Description: 'Fluoroacetamide (ISO), monocrotophos (ISO) và phosphamidon (ISO):',
    Unit: '',
  },
  {
    HsCode: '29241210',
    Description: 'Fluoroacetamide (ISO) và phosphamidon (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29241220',
    Description: 'Monocrotophos (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292419',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29241910',
    Description: 'Carisoprodol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29241990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Amit mạch vòng (kể cả carbamates mạch vòng) và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '292421',
    Description: 'Ureines và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29242110',
    Description: '4Ethoxyphenylurea (dulcin)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242120',
    Description: 'Diuron và monuron',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242300',
    Description: 'Axit 2-acetamidobenzoic (axit N-acetylanthranilic) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242400',
    Description: 'Ethinamate (INN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242500',
    Description: 'Alachlor (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292429',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29242910',
    Description: 'Aspartame',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242920',
    Description: 'Butylphenylmethyl carbamate; methyl isopropyl phenyl carbamate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242930',
    Description: 'Acetaminophen (paracetamol); salicylamide; ethoxybenzamide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29242990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2925',
    Description: 'Hợp chất chức carboxyimit (kể cả saccharin và muối của nó) và các hợp chất chức imin',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Imit và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29251100',
    Description: 'Saccharin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29251200',
    Description: 'Glutethimide (INN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29251900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Imin và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29252100',
    Description: 'Chlordimeform (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29252900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2926',
    Description: 'Hợp chất chức nitril',
    Unit: '',
  },
  {
    HsCode: '29261000',
    Description: 'Acrylonitril',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29262000',
    Description: '1-Cyanoguanidine (dicyandiamide)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29263000',
    Description:
      'Fenproporex (INN) và muối của nó; methadone (INN) intermediate   (4-cyano-2-dimethylamino-4,               4-diphenylbutane) ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29264000',
    Description: 'alpha-Phenylacetoacetonitrile',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29269000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2927',
    Description: 'Hợp chất diazo-, azohoặc azoxy',
    Unit: '',
  },
  {
    HsCode: '29270010',
    Description: 'Azodicarbonamide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29270090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2928',
    Description: 'Dẫn xuất hữu cơ của hydrazin hoặc của hydroxylamin',
    Unit: '',
  },
  {
    HsCode: '29280010',
    Description: 'Linuron',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29280090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2929',
    Description: 'Hợp chất chức nitơ khác',
    Unit: '',
  },
  {
    HsCode: '292910',
    Description: '- Isocyanates:',
    Unit: '',
  },
  {
    HsCode: '29291010',
    Description: 'Diphenylmetan diisoxyanat (MDI)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29291020',
    Description: 'Toluen diisoxyanat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29291090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '292990',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29299010',
    Description: 'Natri xyclamat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29299020',
    Description: 'Các xyclamat khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29299090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG X',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'HỢP CHẤT HỮU CƠ VÔ CƠ, HỢP CHẤT DỊ VÒNG, AXIT NUCLEIC VÀ CÁC MUỐI CỦA CHÚNG, VÀ CÁC SULPHONAMIT',
    Unit: '',
  },
  {
    HsCode: '2930',
    Description: 'Hợp chất lưu huỳnh-hữu cơ',
    Unit: '',
  },
  {
    HsCode: '29301000',
    Description: '2-(N,N-Dimethylamino) ethanethiol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29302000',
    Description: 'Thiocarbamates và dithiocarbamates',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29303000',
    Description: 'Thiuram mono-, dihoặc tetrasulphua',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29304000',
    Description: 'Methionin',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29306000',
    Description: '2-(N,N-Diethylamino)ethanethiol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29307000',
    Description: 'Bis(2-hydroxyethyl)sulfide (thiodiglycol (INN))',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29308000',
    Description: 'Aldicarb (ISO), captafol (ISO) và methamidophos (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293090',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29309010',
    Description: 'Dithiocarbonates',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29309090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2931',
    Description: 'Hợp chất vô cơ hữu cơ khác',
    Unit: '',
  },
  {
    HsCode: '293110',
    Description: ' Chì tetrametyl và chì tetraetyl:',
    Unit: '',
  },
  {
    HsCode: '29311010',
    Description: 'Chì tetrametyl',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29311020',
    Description: 'Chì tetraetyl',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29312000',
    Description: ' Hợp chất tributyltin',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất phospho hữu cơ không halogen hóa:',
    Unit: '',
  },
  {
    HsCode: '29314100',
    Description: 'Dimethyl methylphosphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314200',
    Description: 'Dimethyl propylphosphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314300',
    Description: 'Diethyl ethylphosphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314400',
    Description: 'Axit methylphosphonic',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314500',
    Description: 'Muối của axit methylphosphonic và (aminoiminomethyl)urea (1: 1)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314600',
    Description: '2,4,6-Tripropyl-1,3,5,2,4,6-trioxatriphosphinane 2,4,6trioxide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314700',
    Description: '(5-Ethyl-2-methyl-2-oxido-1,3,2-dioxaphosphinan-5-yl) methyl methylphosphonate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314800',
    Description: '3,9-Dimethyl-2,4,8,10-tetraoxa-3,9-diphosphaspiro[5.5] undecane 3,9-dioxide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293149',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29314910',
    Description: 'N-(phosphonomethyl) glycine',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314920',
    Description: 'Muối của N-(phosphonomethyl) glycine',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29314990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các dẫn xuất phospho hữu cơ halogen hóa:',
    Unit: '',
  },
  {
    HsCode: '29315100',
    Description: 'Methylphosphonic dichloride',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29315200',
    Description: 'Propylphosphonic dichloride',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29315300',
    Description: 'O-(3-chloropropyl) O-[4-nitro-3-(trifluoromethyl)phenyl] methylphosphonothionate',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29315400',
    Description: 'Trichlorfon (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293159',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29315910',
    Description: 'Ethephon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29315990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các hợp chất arsen hữu cơ:',
    Unit: '',
  },
  {
    HsCode: '29319041',
    Description: 'Dạng lỏng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29319049',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29319050',
    Description: 'Dimethyltin dichloride',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29319090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2932',
    Description: 'Hợp chất dị vòng chỉ chứa (các) dị tố oxy',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hợp chất có chứa một vòng furan chưa ngưng tụ (đã hoặc chưa hydro hóa) trong cấu trúc:',
    Unit: '',
  },
  {
    HsCode: '29321100',
    Description: 'Tetrahydrofuran',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29321200',
    Description: '2-Furaldehyde (furfuraldehyde)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29321300',
    Description: 'Furfuryl alcohol và tetrahydrofurfuryl alcohol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29321400',
    Description: 'Sucralose',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29321900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293220',
    Description: 'Lactones:',
    Unit: '',
  },
  {
    HsCode: '29322010',
    Description: 'Coumarin (1,2-Benzopyrone), methylcoumarins và ethylcoumarins',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29322090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29329100',
    Description: 'Isosafrole',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29329200',
    Description: '1-(1,3-Benzodioxol-5-yl)propan-2-one',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29329300',
    Description: 'Piperonal',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29329400',
    Description: 'Safrole',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29329500',
    Description: 'Tetrahydrocannabinols (tất cả các đồng phân)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29329600',
    Description: 'Carbofuran (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29329900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2933',
    Description: 'Hợp chất dị vòng chỉ chứa (các) dị tố ni tơ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hợp chất có chứa một vòng pyrazol chưa ngưng tụ (đã hoặc chưa hydro hóa) trong cấu trúc:',
    Unit: '',
  },
  {
    HsCode: '29331100',
    Description: 'Phenazon (antipyrin) và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29331900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Hợp chất có chứa một vòng imidazol chưa ngưng tụ (đã hoặc chưa hydro hóa) trong cấu trúc:',
    Unit: '',
  },
  {
    HsCode: '29332100',
    Description: 'Hydantoin và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29332900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Hợp chất có chứa một vòng pyridin chưa ngưng tụ (đã hoặc chưa hydro hóa) trong cấu trúc:',
    Unit: '',
  },
  {
    HsCode: '29333100',
    Description: 'Piridin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333200',
    Description: 'Piperidin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333300',
    Description:
      'Alfentanil (INN), anileridine (INN), bezitramide (INN), bromazepam (INN), carfentanil (INN), difenoxin (INN), diphenoxylate (INN), dipipanone (INN), fentanyl (INN), ketobemidone (INN), methylphenidate (INN), pentazocine (INN), pethidine (INN), pethidine (INN) intermediate A, phencyclidine (INN) (PCP), phenoperidine (INN), pipradrol (INN), piritramide (INN), propiram (INN), remifentanil (INN) và trimeperidine (INN); các muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333400',
    Description: 'Các fentanyl khác và các dẫn xuất của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333500',
    Description: '3-Quinuclidinol',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333600',
    Description: '4-Anilino-N-phenethylpiperidine (ANPP)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333700',
    Description: 'N-Phenethyl-4-piperidone (NPP)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293339',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29333910',
    Description: 'Clopheniramin và isoniazid',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333930',
    Description: 'Muối paraquat',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29333990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Hợp chất chứa trong cấu trúc 1 vòng quinolin hoặc hệ vòng isoquinolin (đã hoặc chưa hydro hóa), chưa ngưng tụ thêm:',
    Unit: '',
  },
  {
    HsCode: '29334100',
    Description: 'Levorphanol (INN) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293349',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29334910',
    Description: 'Dextromethorphan',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29334990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Hợp chất có chứa 1 vòng pyrimidin (đã hoặc chưa hydro hóa) hoặc vòng piperazin trong cấu trúc:',
    Unit: '',
  },
  {
    HsCode: '29335200',
    Description: 'Malonylurea (axit barbituric) và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29335300',
    Description:
      'Allobarbital (INN), amobarbital (INN), barbital (INN), butalbital (INN), butobarbital, cyclobarbital (INN), methylphenobarbital (INN), pentobarbital (INN), phenobarbital (INN), secbutabarbital (INN), secobarbital (INN) và vinylbital (INN); các muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29335400',
    Description: 'Các dẫn xuất khác của malonylurea (axit barbituric); muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29335500',
    Description: 'Loprazolam (INN), mecloqualone (INN), methaqualone (INN) và zipeprol (INN); các muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293359',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29335910',
    Description: 'Diazinon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29335990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Hợp chất chứa một vòng triazin chưa ngưng tụ (đã hoặc chưa hydro hóa) trong cấu trúc:',
    Unit: '',
  },
  {
    HsCode: '29336100',
    Description: 'Melamin',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29336900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Lactams:',
    Unit: '',
  },
  {
    HsCode: '29337100',
    Description: '6-Hexanelactam (epsilon-caprolactam)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29337200',
    Description: 'Clobazam (INN) và methyprylon (INN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29337900',
    Description: 'Lactam khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29339100',
    Description:
      'Alprazolam (INN), camazepam (INN), chlordiazepoxide (INN), clonazepam (INN), clorazepate, delorazepam (INN), diazepam (INN), estazolam (INN), ethyl loflazepate (INN), fludiazepam (INN), flunitrazepam (INN), flurazepam (INN), halazepam (INN), lorazepam (INN), lormetazepam (INN), mazindol (INN), medazepam (INN), midazolam (INN), nimetazepam (INN), nitrazepam (INN), nordazepam (INN), oxazepam (INN), pinazepam (INN), prazepam (INN), pyrovalerone (INN), temazepam (INN), tetrazepam (INN) và triazolam (INN); các muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29339200',
    Description: 'Azinphos-methyl (ISO)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293399',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29339910',
    Description: 'Mebendazole và parbendazole',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29339990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2934',
    Description: 'Các axit nucleic và muối của chúng, đã hoặc chưa xác định về mặt hóa học; hợp chất dị vòng khác',
    Unit: '',
  },
  {
    HsCode: '29341000',
    Description: 'Hợp chất có chứa 1 vòng thiazol chưa ngưng tụ (đã hoặc chưa hydro hóa) trong cấu trúc',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29342000',
    Description: 'Hợp chất có chứa trong cấu trúc 1 hệ vòng benzothiazol (đã hoặc chưa hydro hóa), chưa ngưng tụ thêm',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29343000',
    Description: 'Hợp chất có chứa trong cấu trúc 1 hệ vòng phenothiazin (đã hoặc chưa hydro hóa), chưa ngưng tụ thêm',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29349100',
    Description:
      'Aminorex (INN), brotizolam (INN), clotiazepam (INN), cloxazolam (INN), dextromoramide (INN), haloxazolam (INN), ketazolam (INN), mesocarb (INN), oxazolam (INN), pemoline (INN), phendimetrazine (INN), phenmetrazine (INN) và sufentanil (INN); muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29349200',
    Description: 'Các fentanyl khác và các dẫn xuất của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293499',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29349910',
    Description: 'Các axit nucleic và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29349920',
    Description: 'Sultones; sultams; diltiazem',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29349930',
    Description: 'Axit 6-Aminopenicillanic ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29349940',
    Description: '3-Azido-3-deoxythymidine',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29349950',
    Description: 'Oxadiazon, với độ tinh khiết tối thiểu là 94%',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29349990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2935',
    Description: 'Sulphonamides',
    Unit: '',
  },
  {
    HsCode: '29351000',
    Description: 'N-Methylperfluorooctane sulphonamide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29352000',
    Description: 'N-Ethylperfluorooctane sulphonamide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29353000',
    Description: 'N-Ethyl-N-(2-hydroxyethyl) perfluorooctane sulphonamide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29354000',
    Description: 'N-(2-Hydroxyethyl)-N-methylperfluorooctane sulphonamide',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29355000',
    Description: 'Các perfluorooctane sulphonamide khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29359000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG XI',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'TIỀN VITAMIN, VITAMIN VÀ HORMON',
    Unit: '',
  },
  {
    HsCode: '2936',
    Description:
      'Tiền vitamin và vitamin các loại, tự nhiên hoặc tái tạo bằng phương pháp tổng hợp (kể cả các chất cô đặc tự nhiên), các dẫn xuất của chúng sử dụng chủ yếu như vitamin, và hỗn hợp của các chất trên, có hoặc không có bất kỳ loại dung môi nào',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Vitamin và các dẫn xuất của chúng, chưa pha trộn:',
    Unit: '',
  },
  {
    HsCode: '29362100',
    Description: 'Vitamin A và các dẫn xuất của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362200',
    Description: 'Vitamin B1 và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362300',
    Description: 'Vitamin B2 và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362400',
    Description: 'Axit Dhoặc DL-Pantothenic (Vitamin B5) và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362500',
    Description: 'Vitamin B6 và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362600',
    Description: 'Vitamin B12 và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362700',
    Description: 'Vitamin C và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362800',
    Description: 'Vitamin E và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29362900',
    Description: 'Vitamin khác và các dẫn xuất của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29369000',
    Description: 'Loại khác, kể cả các chất cô đặc tự nhiên',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2937',
    Description:
      'Các hormon, prostaglandins, thromboxanes và leukotrienes, tự nhiên hoặc tái tạo bằng phương pháp tổng hợp; các dẫn xuất và các chất có cấu trúc tương tự của chúng, kể cả chuỗi polypeptit cải biến, được sử dụng chủ yếu như hormon',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các hormon polypeptit, các hormon protein và các hormon glycoprotein, các dẫn xuất và các chất có cấu trúc tương tự của chúng: ',
    Unit: '',
  },
  {
    HsCode: '29371100',
    Description: 'Somatotropin, các dẫn xuất và các chất có cấu trúc tương tự của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29371200',
    Description: 'Insulin và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29371900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Các hormon steroit, các dẫn xuất và các chất có cấu trúc tương tự của chúng:',
    Unit: '',
  },
  {
    HsCode: '29372100',
    Description: 'Cortisone, hydrocortisone, prednisone (dehydrocortisone) và prednisolone (dehydrohydrocortisone)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29372200',
    Description: 'Các dẫn xuất halogen hóa của các hormon corticosteroit (corticosteroidal hormones)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29372300',
    Description: 'Oestrogens và progestogens',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29372900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29375000',
    Description:
      'Prostaglandins, thromboxanes và leukotrienes, các dẫn xuất và các chất có cấu trúc tương tự của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293790',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '29379010',
    Description: 'Hợp chất amino chức oxy',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29379020',
    Description: 'Epinephrine; các dẫn xuất của amino axit',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29379090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG XII',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'GLYCOSIT VÀ ALKALOIT THỰC VẬT, TỰ NHIÊN HOẶC TÁI TẠO BẰNG PHƯƠNG PHÁP TỔNG HỢP, VÀ CÁC MUỐI, ETE, ESTE VÀ CÁC DẪN XUẤT KHÁC CỦA CHÚNG',
    Unit: '',
  },
  {
    HsCode: '2938',
    Description:
      'Glycosit, tự nhiên hoặc tái tạo bằng phương pháp tổng hợp, và các muối, ete, este và các dẫn xuất khác của chúng',
    Unit: '',
  },
  {
    HsCode: '29381000',
    Description: 'Rutosit (rutin) và các dẫn xuất của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29389000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '2939',
    Description:
      'Alkaloit, tự nhiên hoặc tái tạo bằng phương pháp tổng hợp, và các muối, ete, este và các dẫn xuất khác của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Alkaloit từ thuốc phiện và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '293911',
    Description:
      'Cao thuốc phiện; buprenorphine (INN), codeine, dihydrocodeine (INN), ethylmorphine, etorphine (INN), heroin, hydrocodone (INN), hydromorphone (INN), morphine, nicomorphine (INN), oxycodone (INN), oxymorphone (INN), pholcodine (INN), thebacon (INN) và thebaine; các muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29391110',
    Description: 'Cao thuốc phiện và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29391190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29391900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '293920',
    Description: 'Alkaloit của cây canh-ki-na và dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29392010',
    Description: 'Quinine và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29392090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29393000',
    Description: 'Cafein và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Alkaloit của ephedra và dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29394100',
    Description: 'Ephedrine và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29394200',
    Description: 'Pseudoephedrine (INN) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29394300',
    Description: 'Cathine (INN) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29394400',
    Description: 'Norephedrine và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29394500',
    Description: 'Levometamfetamine, metamfetamine (INN), metamfetamine racemate và muối của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29394900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      '-  Theophylline và aminophylline (theophyllineethylenediamine) và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29395100',
    Description: 'Fenetylline (INN) và muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29395900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description:
      'Alkaloit của hạt cựa (mầm) lúa mạch (alkaloids of rye ergot) và các dẫn xuất của chúng; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '29396100',
    Description: 'Ergometrine (INN) và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29396200',
    Description: 'Ergotamine (INN) và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29396300',
    Description: 'Axit lysergic và các muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29396900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác, có nguồn gốc thực vật:',
    Unit: '',
  },
  {
    HsCode: '29397200',
    Description: 'Cocaine, ecgonine; các muối, este và các dẫn xuất khác của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29397900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '29398000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG XIII',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'HỢP CHẤT HỮU CƠ KHÁC',
    Unit: '',
  },
  {
    HsCode: '29400000',
    Description:
      'Đường, tinh khiết về mặt hóa học, trừ sucroza, lactoza, mantoza, glucoza và fructoza; ete đường, axetal đường và este đường, và muối của chúng, trừ các sản phẩm thuộc nhóm 29.37, 29.38 hoặc 29.39',
    Unit: 'kg',
  },
  {
    HsCode: '2941',
    Description: 'Kháng sinh',
    Unit: '',
  },
  {
    HsCode: '294110',
    Description: 'Các penicillin và các dẫn xuất của chúng có cấu trúc là axit penicillanic; muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Amoxicillin và muối của nó:',
    Unit: '',
  },
  {
    HsCode: '29411011',
    Description: 'Không tiệt trùng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '29411019',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '29411020',
    Description: 'Ampicillin và muối của nó',
    Unit: 'kg',
  },
  {
    HsCode: '29411090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '29412000',
    Description: 'Các streptomycin và dẫn xuất của chúng; muối của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '29413000',
    Description: 'Các tetracyline và dẫn xuất của chúng; muối của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '29414000',
    Description: 'Cloramphenicol và các dẫn xuất của nó; muối của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '29415000',
    Description: 'Erythromycin và các dẫn xuất của nó; muối của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '29419000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '29420000',
    Description: 'Hợp chất hữu cơ khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 30',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dược Phẩm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Đồ ăn hoặc đồ uống (như đồ ăn theo chế độ ăn kiêng, tiểu đường hoặc đồ ăn để bồi dưỡng sức khỏe, thực phẩm bảo vệ sức khỏe (food supplements), đồ uống tăng lực và nước khoáng), trừ các chế phẩm dinh dưỡng để truyền tĩnh mạch (Phần IV);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các sản phẩm, như ở dạng viên (tablet), kẹo cao su hoặc dạng miếng (thẩm thấu qua da), chứa nicotin và nhằm hỗ trợ cai nghiện thuốc lá (nhóm 24.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Thạch cao đã nung hoặc nghiền mịn dùng trong nha khoa (nhóm 25.20);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Nước cất hoặc dung dịch nước của các loại tinh dầu, dùng cho y tế (nhóm 33.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Các chế phẩm thuộc các nhóm từ 33.03 đến 33.07, ngay cả khi các chế phẩm đó có tính năng phòng bệnh hoặc chữa bệnh;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Xà phòng hoặc các sản phẩm khác thuộc nhóm 34.01 có chứa thêm dược phẩm;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Các chế phẩm dùng trong nha khoa với thành phần cơ bản là thạch cao (nhóm 34.07);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Albumin máu không được điều chế cho mục đích phòng bệnh hoặc chữa bệnh (nhóm 35.02); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Các chất thử chẩn đoán của nhóm 38.22.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của nhóm 30.02, cụm từ ""các sản phẩm miễn dịch"" áp dụng cho peptit và protein (trừ hàng hóa của nhóm 29.37) mà liên quan trực tiếp đến sự điều chỉnh các quá trình miễn dịch, như kháng thể đơn dòng (MAB), mảng kháng thể, tiếp hợp kháng thể và tiếp hợp mảng kháng thể, interleukins, interferons (IFN), chemokines và các nhân tố tiêu diệt u bướu (TNF), nhân tố phát triển (GF), hematopoietins và các nhân tố kích thích khuẩn lạc (CSF).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 30.03 và 30.04 và Chú giải 4(d) của Chương này, những mục dưới đây cần hiểu như sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Những sản phẩm không pha trộn gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(1) Sản phẩm không pha trộn đã hòa tan trong nước;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(2) Toàn bộ các mặt hàng thuộc Chương 28 hoặc 29; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(3) Các chiết xuất thực vật bằng phương pháp đơn giản thuộc nhóm 13.02, mới chỉ được chuẩn hóa hoặc hòa tan trong dung môi bất kỳ;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Những sản phẩm đã pha trộn: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(1) Các dung dịch keo và huyền phù (trừ lưu huỳnh dạng keo);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(2) Các chiết xuất thực vật thu được từ việc xử lý hỗn hợp các nguyên liệu thực vật; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(3) Muối và chất cô đặc thu được từ việc làm bay hơi nước khoáng tự nhiên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 30.06 chỉ áp dụng cho các chất sau đây, những chất này phải xếp vào nhóm này và không thể đưa vào nhóm nào khác của Danh mục:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Chỉ catgut phẫu thuật vô trùng, các vật liệu khâu (suture) vô trùng tương tự (kể cả chỉ tự tiêu vô trùng dùng cho nha khoa hoặc phẫu thuật) và keo tạo màng vô trùng dùng khép miệng vết thương trong phẫu thuật;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Tảo nong vô trùng và nút tảo nong vô trùng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c)  Các sản phẩm cầm máu tự tiêu vô trùng dùng cho phẫu thuật hoặc nha khoa; các miếng chắn dính vô trùng dùng trong phẫu thuật hoặc nha khoa, có hoặc không tự tiêu;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Chế phẩm cản quang dùng trong việc kiểm tra bằng tia X và các thuốc thử chẩn đoán bệnh cho bệnh nhân, là các sản phẩm không pha trộn đóng gói theo liều lượng hoặc các sản phẩm gồm hai thành phần trở lên đã pha trộn dùng cho mục đích trên;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Giả dược (placebos) và bộ dụng cụ thử nghiệm lâm sàng mù (hoặc mù đôi) để sử dụng trong các thử nghiệm lâm sàng đã công nhận, được đóng gói theo liều lượng, ngay cả khi chúng có thể chứa dược tính;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Xi măng hàn răng và các chất hàn răng khác; xi măng gắn xương;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Hộp và bộ dụng cụ cấp cứu;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Chế phẩm hóa học tránh thai dựa trên hormon, trên các sản phẩm khác thuộc nhóm 29.37 hoặc trên các chất diệt tinh trùng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Các chế phẩm gel được sản xuất để sử dụng cho người hoặc thú y như chất bôi trơn cho các bộ phận của cơ thể khi tiến hành phẫu thuật hoặc khám bệnh hoặc như một chất gắn kết giữa cơ thể và các thiết bị y tế;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(k) Phế thải dược phẩm, đó là, các loại dược phẩm không thích hợp đối với mục đích dự kiến ban đầu của nó, ví dụ, hết hạn sử dụng; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(l) Dụng cụ chuyên dụng cho mổ tạo hậu môn giả, đã được cắt thành hình dạng nhất định và gắn với các viên nhện hoặc tấm mặt của chúng dùng cho thông ruột, hồi tràng và mở niệu đạo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Theo mục đích của phân nhóm 3002.13 và 3002.14, các mục dưới đây được xem xét:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các sản phẩm chưa được pha trộn, các sản phẩm tinh khiết, có hoặc không chứa tạp chất;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các sản phẩm đã được pha trộn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(1) Các sản phẩm được đề cập ở mục (a) nêu trên hòa tan trong nước hoặc trong dung môi khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) Các sản phẩm được đề cập ở mục (a) và (b) (1) nêu trên với một chất ổn định được thêm vào cần thiết cho việc bảo quản hoặc vận chuyển; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(3) Các sản phẩm được đề cập ở mục (a), (b) (1) và (b) (2) nêu trên với phụ gia bất kỳ khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Các phân nhóm   3003.60 và 3004.60  bao gồm thuốc chứa artemisinin (INN) để uống được kết hợp với các thành phần có hoạt tính dược khác, hoặc chứa bất kỳ hoạt chất chính nào dưới đây, có hoặc không kết hợp với các thành phần hoạt tính dược khác: amodiaquine (INN); artelinic axit hoặc các muối của nó; artenimol (INN); artemotil (INN); artemether (INN);    artesunate (INN); chloroquine (INN); dihydroartemisinin (INN);  lumefantrine    (INN);    mefloquine    (INN);    piperaquine    (INN); pyrimethamine (INN) hoặc sulfadoxine (INN).',
    Unit: '',
  },
  {
    HsCode: '3001',
    Description:
      'Các tuyến và các bộ phận cơ thể khác dùng để chữa bệnh, ở dạng khô, đã hoặc chưa làm thành dạng bột; chiết xuất từ các tuyến hoặc các bộ phận cơ thể khác hoặc từ các dịch tiết của chúng dùng để chữa bệnh; heparin và các muối của nó; các chất khác từ người hoặc động vật được điều chế dùng cho phòng bệnh hoặc chữa bệnh, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '30012000',
    Description: 'Chiết xuất từ các tuyến hoặc các bộ phận cơ thể khác hoặc từ các dịch tiết của chúng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30019000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3002',
    Description:
      'Máu người; máu động vật đã điều chế dùng cho chữa bệnh, phòng bệnh hoặc chẩn đoán bệnh; kháng huyết thanh, các phần phân đoạn khác của máu và các sản phẩm miễn dịch, có hoặc không được cải biến hoặc thu được từ quy trình công nghệ sinh học; vắc xin, độc tố, vi sinh nuôi cấy (trừ các loại men) và các sản phẩm tương tự; tế bào nuôi cấy, có hoặc không cải biến',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Kháng huyết thanh, các phần phân đoạn khác của máu và các sản phẩm miễn dịch, có hoặc không cải biến hoặc thu được từ quy trình công nghệ sinh học:',
    Unit: '',
  },
  {
    HsCode: '300212',
    Description: 'Kháng huyết thanh và các phần phân đoạn khác của máu:',
    Unit: '',
  },
  {
    HsCode: '30021210',
    Description: 'Kháng huyết thanh; dung dịch đạm huyết thanh; bột hemoglobin',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30021290',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30021300',
    Description:
      'Các sản phẩm miễn dịch, chưa được pha trộn, chưa đóng gói theo liều lượng hoặc làm thành dạng nhất định hoặc đóng gói để bán lẻ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30021400',
    Description:
      'Các sản phẩm miễn dịch, được pha trộn, chưa đóng gói theo liều lượng hoặc làm thành dạng nhất định hoặc đóng gói để bán lẻ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30021500',
    Description:
      'Các sản phẩm miễn dịch, đóng gói theo liều lượng hoặc làm thành dạng nhất định hoặc đóng gói để bán lẻ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Vắc xin, độc tố, vi sinh nuôi cấy (trừ các loại men) và các sản phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '300241',
    Description: 'Vắc xin cho người:',
    Unit: '',
  },
  {
    HsCode: '30024110',
    Description: 'Vắc xin uốn ván',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30024120',
    Description: 'Vắc xin ho gà, sởi, viêm màng não hoặc bại liệt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30024190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30024200',
    Description: 'Vắc xin thú y',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30024900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Tế bào nuôi cấy, có hoặc không cải biến:',
    Unit: '',
  },
  {
    HsCode: '30025100',
    Description: 'Các sản phẩm liệu pháp tế bào',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30025900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30029000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3003',
    Description:
      'Thuốc (trừ các mặt hàng thuộc nhóm 30.02, 30.05 hoặc 30.06) gồm từ hai hoặc nhiều thành phần trở lên đã pha trộn với nhau dùng cho phòng bệnh hoặc chữa bệnh, chưa được đóng gói theo liều lượng hoặc làm thành hình dạng nhất định hoặc đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '300310',
    Description:
      'Chứa penicillins hoặc dẫn xuất của chúng, có cấu trúc axit penicillanic, hoặc streptomycins hoặc các dẫn xuất của chúng:',
    Unit: '',
  },
  {
    HsCode: '30031010',
    Description: 'Chứa amoxicillin (INN) hoặc muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30031020',
    Description: 'Chứa ampicillin (INN) hoặc muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30031090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30032000',
    Description: 'Loại khác, chứa kháng sinh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác, chứa hormon hoặc các sản phẩm khác thuộc nhóm 29.37:',
    Unit: '',
  },
  {
    HsCode: '30033100',
    Description: 'Chứa insulin',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30033900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác, chứa alkaloit hoặc các dẫn xuất của chúng:',
    Unit: '',
  },
  {
    HsCode: '30034100',
    Description: 'Chứa ephedrine hoặc muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30034200',
    Description: 'Chứa pseudoephedrine (INN) hoặc muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30034300',
    Description: 'Chứa norephedrine hoặc muối của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30034900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30036000',
    Description: 'Loại khác, chứa hoạt chất chống sốt rét mô tả ở Chú giải Phân nhóm 2 của Chương này',
    Unit: 'kg/lít',
  },
  {
    HsCode: '30039000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3004',
    Description:
      'Thuốc (trừ các mặt hàng thuộc nhóm 30.02, 30.05 hoặc 30.06) gồm các sản phẩm đã hoặc chưa pha trộn dùng cho phòng bệnh hoặc chữa bệnh, đã được đóng gói theo liều lượng (kể cả các sản phẩm thuộc loại dùng để hấp thụ qua da) hoặc làm thành dạng nhất định hoặc đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '300410',
    Description:
      'Chứa penicillins hoặc dẫn xuất của chúng, có cấu trúc axit penicillanic, hoặc streptomycins hoặc các dẫn xuất của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chứa penicillins hoặc các dẫn xuất của chúng:',
    Unit: '',
  },
  {
    HsCode: '30041015',
    Description: 'Chứa penicillin G (trừ penicillin G benzathin), phenoxymethyl penicillin hoặc muối của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30041016',
    Description: 'Chứa ampicillin, amoxycillin hoặc muối của chúng, dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30041019',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30041020',
    Description: 'Chứa các streptomycin hoặc các dẫn xuất của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300420',
    Description: 'Loại khác, chứa kháng sinh:',
    Unit: '',
  },
  {
    HsCode: '30042010',
    Description:
      'Chứa gentamycin, lincomycin, sulphamethoxazole hoặc các dẫn xuất của chúng, dạng uống (*) hoặc dạng mỡ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chứa erythromycin hoặc các dẫn xuất của chúng:',
    Unit: '',
  },
  {
    HsCode: '30042031',
    Description: 'Dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30042032',
    Description: 'Dạng mỡ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30042039',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chứa tetracylin hoặc chloramphenicol hoặc các dẫn xuất của chúng:',
    Unit: '',
  },
  {
    HsCode: '30042071',
    Description: 'Dạng uống (*) hoặc dạng mỡ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30042079',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30042091',
    Description: 'Dạng uống (*) hoặc dạng mỡ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30042099',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác, chứa hormon hoặc các sản phẩm khác thuộc nhóm 29.37:',
    Unit: '',
  },
  {
    HsCode: '30043100',
    Description: 'Chứa insulin',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300432',
    Description: 'Chứa hormon tuyến thượng thận, các dẫn xuất hoặc cấu trúc tương tự của chúng:',
    Unit: '',
  },
  {
    HsCode: '30043210',
    Description: 'Chứa dexamethasone hoặc các dẫn xuất của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30043240',
    Description: 'Chứa hydrocortisone natri succinate hoặc fluocinolone acetonide',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30043290',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30043900',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác, chứa alkaloit hoặc các dẫn xuất của chúng:',
    Unit: '',
  },
  {
    HsCode: '30044100',
    Description: 'Chứa ephedrine hoặc muối của nó',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044200',
    Description: 'Chứa pseudoephedrine (INN) hoặc muối của nó',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044300',
    Description: 'Chứa norephedrine hoặc muối của nó',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300449',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chứa morphine hoặc các dẫn xuất của nó:',
    Unit: '',
  },
  {
    HsCode: '30044911',
    Description: 'Dạng uống (*) hoặc tiêm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044919',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chứa papaverine hoặc berberine:',
    Unit: '',
  },
  {
    HsCode: '30044951',
    Description: 'Dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044959',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044960',
    Description: 'Chứa theophylline, dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044970',
    Description: 'Chứa atropine sulphate',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044980',
    Description:
      'Chứa quinine hydrochloride hoặc dihydroquinine chloride, dùng để tiêm; Chứa quinine sulphate hoặc bisulphate, dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30044990',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300450',
    Description: 'Loại khác, chứa các vitamin hoặc các sản phẩm khác của nhóm 29.36:',
    Unit: '',
  },
  {
    HsCode: '30045010',
    Description: 'Loại phù hợp cho trẻ em, dạng xirô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác, chứa nhiều hơn một loại vitamin:',
    Unit: '',
  },
  {
    HsCode: '30045021',
    Description: 'Dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30045029',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30045091',
    Description: 'Chứa vitamin A, B hoặc C',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30045099',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300460',
    Description: 'Loại khác, chứa hoạt chất chống sốt rét được mô tả ở Chú giải Phân nhóm 2 của Chương này:',
    Unit: '',
  },
  {
    HsCode: '30046010',
    Description: 'Chứa artemisinin kết hợp với các thành phần có hoạt tính dược khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30046020',
    Description: 'Chứa artesunate hoặc chloroquine',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30046090',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30049010',
    Description: 'Miếng thẩm thấu qua da dùng điều trị bệnh ung thư hoặc bệnh tim',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049020',
    Description: 'Nước vô trùng đóng kín để xông, loại dược phẩm (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049030',
    Description: 'Thuốc sát trùng (Antiseptics)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chất gây tê, gây mê (Anaesthetics):',
    Unit: '',
  },
  {
    HsCode: '30049041',
    Description: 'Chứa procain hydroclorua',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049049',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description:
      'Thuốc giảm đau, thuốc hạ sốt và các loại dược phẩm khác dùng để điều trị ho hoặc cảm lạnh, có hoặc không chứa chất kháng histamin:',
    Unit: '',
  },
  {
    HsCode: '30049051',
    Description: 'Chứa axit acetylsalicylic, paracetamol hoặc dipyrone (INN), dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049053',
    Description: 'Chứa diclofenac, dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049054',
    Description: 'Chứa piroxicam (INN) hoặc ibuprofen',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049055',
    Description: 'Loại khác, dạng dầu xoa bóp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049059',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Thuốc chống sốt rét:',
    Unit: '',
  },
  {
    HsCode: '30049062',
    Description: 'Chứa primaquine',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049064',
    Description: 'Chứa artemisinin trừ các loại thuộc phân nhóm 3004.60.10',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30049065',
    Description: 'Thuốc đông y từ thảo dược (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049069',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Thuốc tẩy giun:',
    Unit: '',
  },
  {
    HsCode: '30049071',
    Description: 'Chứa piperazine hoặc mebendazole (INN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30049072',
    Description: 'Thuốc đông y từ thảo dược (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049079',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Các thuốc khác điều trị ung thư, HIV/AIDS hoặc các bệnh khó chữa khác:',
    Unit: '',
  },
  {
    HsCode: '30049081',
    Description: 'Chứa deferoxamine, dạng tiêm (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049082',
    Description: 'Thuốc chống HIV/AIDS (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049089',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30049091',
    Description: 'Chứa natri clorua hoặc glucose, dạng truyền',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049092',
    Description: 'Chứa sorbitol hoặc salbutamol, dạng truyền',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049093',
    Description: 'Chứa sorbitol hoặc salbutamol, ở dạng khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049094',
    Description: 'Chứa cimetidine (INN) hoặc ranitidine (INN) trừ dạng tiêm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049095',
    Description: 'Chứa phenobarbital, diazepam hoặc chlorpromazine, trừ dạng tiêm hoặc dạng truyền',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049096',
    Description: 'Thuốc nhỏ mũi có chứa naphazoline, xylometazoline hoặc oxymetazoline',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30049098',
    Description: 'Thuốc đông y từ thảo dược (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30049099',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '3005',
    Description:
      'Bông, gạc, băng và các sản phẩm tương tự (ví dụ, băng để băng bó, cao dán, thuốc đắp), đã thấm tẩm hoặc tráng phủ dược chất hoặc làm thành dạng nhất định hoặc đóng gói để bán lẻ dùng cho y tế, phẫu thuật, nha khoa hoặc thú y',
    Unit: '',
  },
  {
    HsCode: '300510',
    Description: 'Băng dán và các sản phẩm khác có một lớp dính:',
    Unit: '',
  },
  {
    HsCode: '30051010',
    Description: 'Đã tráng phủ hoặc thấm tẩm dược chất',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30051090',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30059010',
    Description: 'Băng (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30059020',
    Description: 'Gạc (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30059090',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '3006',
    Description: 'Các mặt hàng dược phẩm ghi trong Chú giải 4 của Chương này',
    Unit: '',
  },
  {
    HsCode: '300610',
    Description:
      'Chỉ catgut phẫu thuật vô trùng, các vật liệu khâu (suture) vô trùng tương tự (kể cả chỉ tự tiêu vô trùng dùng cho nha khoa hoặc phẫu thuật) và keo tạo màng vô trùng dùng để khép miệng vết thương trong phẫu thuật; tảo nong vô trùng và nút tảo nong vô trùng; sản phẩm cầm máu tự tiêu vô trùng trong phẫu thuật hoặc nha khoa; miếng chắn dính vô trùng dùng trong nha khoa hoặc phẫu thuật, có hoặc không tự tiêu:',
    Unit: '',
  },
  {
    HsCode: '30061010',
    Description:
      'Chỉ tự tiêu vô trùng dùng cho nha khoa hoặc phẫu thuật; miếng chắn dính vô trùng dùng trong nha khoa hoặc phẫu thuật, có hoặc không tự tiêu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30061090',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300630',
    Description:
      'Chế phẩm cản quang dùng trong việc kiểm tra bằng tia X; các chất thử chẩn đoán bệnh được chỉ định dùng cho bệnh nhân:',
    Unit: '',
  },
  {
    HsCode: '30063010',
    Description: 'Bari sulphat, dạng uống (*)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30063020',
    Description: 'Các chất thử nguồn gốc vi sinh, loại phù hợp để chẩn đoán sinh học trong thú y',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30063030',
    Description: 'Các chất thử chẩn đoán vi sinh khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30063090',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300640',
    Description: 'Xi măng hàn răng và các chất hàn răng khác; xi măng gắn xương:',
    Unit: '',
  },
  {
    HsCode: '30064010',
    Description: 'Xi măng hàn răng và các chất hàn răng khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30064020',
    Description: 'Xi măng gắn xương',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30065000',
    Description: 'Hộp và bộ dụng cụ sơ cứu',
    Unit: 'kg/bộ',
  },
  {
    HsCode: '30066000',
    Description:
      'Các chế phẩm hóa học dùng để tránh thai dựa trên hormon, dựa trên các sản phẩm khác thuộc nhóm 29.37 hoặc dựa trên các chất diệt tinh trùng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30067000',
    Description:
      'Các chế phẩm gel được sản xuất để dùng cho người hoặc thú y như chất bôi trơn cho các bộ phận của cơ thể khi tiến hành phẫu thuật hoặc khám bệnh hoặc như một chất gắn kết giữa cơ thể và thiết bị y tế ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '30069100',
    Description: 'Dụng cụ chuyên dụng cho mổ tạo hậu môn giả ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '300692',
    Description: 'Phế thải dược phẩm:',
    Unit: '',
  },
  {
    HsCode: '30069210',
    Description: 'Của thuốc điều trị ung thư, HIV/AIDS hoặc các bệnh khó chữa khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30069290',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '30069300',
    Description:
      'Giả dược (placebo) và bộ dụng cụ thử nghiệm lâm sàng mù (hoặc mù đôi) để sử dụng trong các thử nghiệm lâm sàng được công nhận, được đóng gói theo liều lượng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(*): Dạng uống là dạng sử dụng qua đường miệng (ví dụ, nuốt, nhai, ngậm, đặt dưới lưỡi…)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 31',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Phân bón',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Máu động vật thuộc nhóm 05.11;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các hợp chất đã được xác định về mặt hóa học riêng biệt (trừ những mặt hàng đã ghi trong các Chú giải 2(a), 3(a), 4(a) hoặc 5 dưới đây); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các tinh thể kali clorua nuôi cấy (trừ các bộ phận quang học) mỗi tinh thể nặng từ 2,5g trở lên, thuộc nhóm 38.24; các bộ phận quang học bằng kali clorua (nhóm 90.01).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 31.02 chỉ áp dụng cho các mặt hàng sau đây, với điều kiện không được tạo thành các hình dạng hoặc đóng gói theo cách thức đã được mô tả trong nhóm 31.05:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Mặt hàng đáp ứng một trong các mô tả sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Natri nitrat, tinh khiết hoặc không tinh khiết;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Amoni nitrat, tinh khiết hoặc không tinh khiết;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) Muối kép, tinh khiết hoặc không tinh khiết, của amoni sulphat và amoni nitrat;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iv) Amoni sulphat, tinh khiết hoặc không tinh khiết;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(v) Muối kép (tinh khiết hoặc không tinh khiết) hoặc hỗn hợp canxi nitrat và amoni nitrat;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(vi) Muối kép (tinh khiết hoặc không tinh khiết) hoặc hỗn hợp canxi nitrat và magiê nitrat;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(vii) Canxi xyanamit, tinh khiết hoặc không tinh khiết hoặc được xử lý với dầu;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(viii) Urê, tinh khiết hoặc không tinh khiết.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Phân bón gồm bất kỳ các loại nào đã mô tả trong mục (a) trên được pha trộn với nhau.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Phân bón chứa amoni clorua hoặc các loại bất kỳ đã mô tả trong mục (a) hoặc (b) trên đây được pha trộn với đá phấn, thạch cao hoặc các chất vô cơ không phải là phân bón.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Phân bón dạng lỏng gồm những mặt hàng ghi trong mục (a) (ii) hoặc (viii) kể trên, hoặc hỗn hợp của những mặt hàng này, ở dạng dung dịch nước hoặc dạng dung dịch amoniac.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Nhóm 31.03 chỉ áp dụng cho các mặt hàng dưới đây, với điều kiện không được tạo thành các hình dạng hoặc đóng gói theo cách thức đã được mô tả trong nhóm 31.05:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Mặt hàng đáp ứng một trong các mô tả sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Xỉ bazơ;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Phosphat tự nhiên thuộc nhóm 25.10, đã nung hoặc xử lý thêm bằng nhiệt để loại bỏ tạp chất;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) Supephosphat (đơn, kép hoặc ba); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iv) Canxi hydro orthophosphat có hàm lượng flo từ 0,2% trở lên tính trên trọng lượng sản phẩm khô khan nước.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Phân bón gồm bất kỳ các loại được mô tả ở mục (a) trên đây đã pha trộn với nhau, nhưng không xem xét đến giới hạn về hàm lượng flo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Phân bón chứa bất kỳ các loại được mô tả trong mục (a) hoặc (b) trên đây, nhưng không xem xét đến giới hạn về hàm lượng flo, được trộn với đá phấn, thạch cao hoặc các chất vô cơ khác không phải là phân bón.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 31.04 chỉ áp dụng cho các loại mặt hàng dưới đây, với điều kiện là chúng không được tạo thành các hình dạng hoặc đóng gói theo cách thức được mô tả ở nhóm 31.05:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Mặt hàng đáp ứng một trong các mô tả sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (i) Muối kali tự nhiên dạng thô (ví dụ, carnallite,  kainite và sylvite);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (ii) Kali clorua, tinh khiết hoặc không tinh khiết, trừ loại ghi trong Chú giải 1(c) ở trên;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (iii) Kali sulphat, tinh khiết hoặc không tinh khiết;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (iv) Magiê kali sulphat, tinh khiết hoặc không tinh khiết.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Phân bón gồm bất kỳ các loại nào đã mô tả trong mục (a) trên được pha trộn với nhau.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Amoni dihydroorthophosphat (monoamoni phosphat) và diamoni hydroorthophosphat (diamoni phosphat), tinh khiết hoặc không tinh khiết, và hỗn hợp của 2 loại đó, được xếp vào nhóm 31.05.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của nhóm 31.05, khái niệm ""phân bón khác"" chỉ áp dụng cho các sản phẩm được sử dụng như phân bón và chứa ít nhất một trong các nguyên tố cấu thành cơ bản của phân bón như nitơ, phospho hoặc kali.',
    Unit: '',
  },
  {
    HsCode: '3101',
    Description:
      'Phân bón gốc thực vật hoặc động vật, đã hoặc chưa pha trộn với nhau hoặc qua xử lý hóa học; phân bón sản xuất bằng cách pha trộn hoặc xử lý hóa học các sản phẩm động vật hoặc thực vật',
    Unit: '',
  },
  {
    HsCode: '31010010',
    Description: 'Nguồn gốc chỉ từ thực vật',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '31010092',
    Description: 'Nguồn gốc từ động vật (trừ phân chim, dơi), đã qua xử lý hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '31010099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3102',
    Description: 'Phân khoáng hoặc phân hóa học, có chứa nitơ',
    Unit: '',
  },
  {
    HsCode: '31021000',
    Description: 'Urê, có hoặc không ở trong dung dịch nước',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Amoni sulphat; muối kép và hỗn hợp của amoni sulphat và amoni nitrat:',
    Unit: '',
  },
  {
    HsCode: '31022100',
    Description: 'Amoni sulphat',
    Unit: 'kg',
  },
  {
    HsCode: '31022900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '31023000',
    Description: 'Amoni nitrat, có hoặc không ở trong dung dịch nước',
    Unit: 'kg',
  },
  {
    HsCode: '31024000',
    Description: 'Hỗn hợp của amoni nitrat với canxi carbonat hoặc các chất vô cơ khác không phải phân bón',
    Unit: 'kg',
  },
  {
    HsCode: '31025000',
    Description: 'Natri nitrat',
    Unit: 'kg',
  },
  {
    HsCode: '31026000',
    Description: 'Muối kép và hỗn hợp của canxi nitrat và amoni nitrat ',
    Unit: 'kg',
  },
  {
    HsCode: '31028000',
    Description: 'Hỗn hợp urê và amoni nitrat ở trong dung dịch nước hoặc dung dịch amoniac',
    Unit: 'kg',
  },
  {
    HsCode: '31029000',
    Description: 'Loại khác, kể cả hỗn hợp chưa được chi tiết trong các phân nhóm trước',
    Unit: 'kg',
  },
  {
    HsCode: '3103',
    Description: 'Phân khoáng hoặc phân hóa học, có chứa phosphat (phân lân)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Supephosphat:',
    Unit: '',
  },
  {
    HsCode: '310311',
    Description: 'Chứa diphosphorus pentaoxide (P2O5) từ 35% trở lên tính theo trọng lượng:',
    Unit: '',
  },
  {
    HsCode: '31031110',
    Description: 'Loại dùng làm thức ăn chăn nuôi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '31031190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '310319',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '31031910',
    Description: 'Loại dùng làm thức ăn chăn nuôi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '31031990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '310390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '31039010',
    Description: 'Phân phosphat đã nung (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '31039090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3104',
    Description: 'Phân khoáng hoặc phân hóa học, có chứa kali',
    Unit: '',
  },
  {
    HsCode: '31042000',
    Description: 'Kali clorua ',
    Unit: 'kg',
  },
  {
    HsCode: '31043000',
    Description: 'Kali sulphat',
    Unit: 'kg',
  },
  {
    HsCode: '31049000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3105',
    Description:
      'Phân khoáng hoặc phân hóa học chứa hai hoặc ba nguyên tố cấu thành phân bón là nitơ, phospho và ka li; phân bón khác; các mặt hàng của Chương này ở dạng viên (tablet) hoặc các dạng tương tự hoặc đóng gói với trọng lượng cả bì không quá 10 kg',
    Unit: '',
  },
  {
    HsCode: '310510',
    Description:
      'Các mặt hàng của Chương này ở dạng viên (tablet) hoặc các dạng tương tự hoặc đóng gói với trọng lượng cả bì không quá 10 kg:',
    Unit: '',
  },
  {
    HsCode: '31051010',
    Description: ' Supephosphat và phân phosphat đã nung',
    Unit: 'kg',
  },
  {
    HsCode: '31051020',
    Description:
      'Phân khoáng hoặc phân hóa học có chứa hai hoặc ba trong số các nguyên tố cấu thành phân bón là nitơ, phospho và kali',
    Unit: 'kg',
  },
  {
    HsCode: '31051090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '31052000',
    Description: ' Phân khoáng hoặc phân hóa học có chứa ba nguyên tố cấu thành phân bón là nitơ, phospho và kali',
    Unit: 'kg',
  },
  {
    HsCode: '31053000',
    Description: ' Diamoni hydro orthophosphat (diamoni phosphat)',
    Unit: 'kg',
  },
  {
    HsCode: '31054000',
    Description:
      'Amoni dihydro orthophosphat (monoamoni phosphat) và hỗn hợp của nó với diamoni hydro orthophosphat (diamoni phosphat)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Phân khoáng hoặc phân hóa học khác có chứa hai nguyên tố cấu thành phân bón là nitơ và phospho:',
    Unit: '',
  },
  {
    HsCode: '31055100',
    Description: 'Chứa nitrat và phosphat',
    Unit: 'kg',
  },
  {
    HsCode: '31055900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '31056000',
    Description: 'Phân khoáng hoặc phân hóa học có chứa hai nguyên tố cấu thành phân bón là phospho và kali',
    Unit: 'kg',
  },
  {
    HsCode: '31059000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 32',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các chất chiết xuất làm thuốc nhuộm hoặc thuộc da; ta nanh và các chất dẫn xuất của chúng; thuốc nhuộm, thuốc màu và các chất màu khác; sơn và véc ni; chất gắn và các loại ma tít khác; các loại mực',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các nguyên tố hoặc hợp chất đã được xác định về mặt hóa học riêng biệt, (trừ loại thuộc nhóm 32.03 hoặc 32.04, các sản phẩm vô cơ của loại được sử dụng như chất phát quang (nhóm 32.06), thủy tinh thu được từ thạch anh nấu chảy hoặc silica nấu chảy khác ở các dạng đã nêu trong nhóm 32.07, và kể cả thuốc nhuộm và các loại chất màu khác đã làm thành dạng nhất định hoặc đóng gói để bán lẻ, thuộc nhóm 32.12);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Tanat hoặc các dẫn xuất ta nanh khác của các sản phẩm thuộc các nhóm từ 29.36 đến 29.39, 29.41 hoặc 35.01 đến 35.04; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Matit của asphalt hoặc matit có chứa bi-tum khác (nhóm 27.15).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 32.04 kể cả các hỗn hợp của muối diazoni ổn định và các chất tiếp hợp để sản xuất các thuốc nhuộm azo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các nhóm 32.03, 32.04, 32.05 và 32.06 cũng áp dụng cho các chế phẩm dựa trên các chất màu (kể cả trường hợp thuộc nhóm 32.06, các chất màu thuộc nhóm 25.30 hoặc Chương 28, vảy kim loại và bột kim loại), loại sử dụng để tạo màu mọi chất liệu hoặc dùng như là nguyên liệu trong việc sản xuất các chế phẩm màu. Tuy nhiên các nhóm này không áp dụng cho các thuốc màu phân tán trong môi trường không chứa nước, ở dạng lỏng hoặc dạng nhão, loại dùng để sản xuất sơn, kể cả men tráng (nhóm 32.12), hoặc cho các chế phẩm khác thuộc nhóm 32.07, 32.08, 32.09, 32.10, 32.12, 32.13 hoặc 32.15.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 32.08 kể cả các dung dịch (trừ collodions) gồm có sản phẩm bất kỳ đã mô tả trong các nhóm từ 39.01 đến 39.13 trong các dung môi hữu cơ dễ bay hơi khi trọng lượng dung môi trên 50% tính theo trọng lượng dung dịch.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Khái niệm ""chất màu"" trong Chương này không bao gồm các sản phẩm dùng như chất phụ trợ (extenders) trong các loại sơn dầu, mặc dù có hoặc không phù hợp cho các chất keo màu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Khái niệm ""lá phôi dập"" trong nhóm 32.12 chỉ áp dụng cho các tấm mỏng thuộc loại dùng để in, ví dụ, bìa sách hoặc dải băng mũ, và làm bằng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Bột kim loại (kể cả bột của các kim loại quý) hoặc thuốc màu, đông kết bằng keo, gelatin hoặc chất kết dính khác; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Kim loại (kể cả kim loại quý) hoặc thuốc màu, kết tủa trên một tấm làm nền bằng vật liệu bất kỳ.',
    Unit: '',
  },
  {
    HsCode: '3201',
    Description:
      'Chất chiết xuất để thuộc da có nguồn gốc từ thực vật; ta nanh và các muối, ete, este và các dẫn xuất khác của chúng',
    Unit: '',
  },
  {
    HsCode: '32011000',
    Description: 'Chất chiết xuất từ cây mẻ rìu (Quebracho)',
    Unit: 'kg',
  },
  {
    HsCode: '32012000',
    Description: 'Chất chiết xuất từ cây keo (Wattle)',
    Unit: 'kg',
  },
  {
    HsCode: '32019000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3202',
    Description:
      'Chất thuộc da hữu cơ tổng hợp; chất thuộc da vô cơ; các chế phẩm thuộc da, có hoặc không chứa chất thuộc da tự nhiên; các chế phẩm chứa enzym dùng cho tiền thuộc da',
    Unit: '',
  },
  {
    HsCode: '32021000',
    Description: 'Chất thuộc da hữu cơ tổng hợp ',
    Unit: 'kg',
  },
  {
    HsCode: '32029000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3203',
    Description:
      'Các chất màu có nguồn  gốc từ thực vật hoặc động vật (kể cả các chất chiết xuất nhuộm nhưng trừ muội than động vật), đã hoặc chưa xác định về mặt hóa học; các chế phẩm đã được ghi trong Chú giải 3 của Chương này dựa trên chất màu có nguồn gốc từ thực vật hoặc động vật',
    Unit: '',
  },
  {
    HsCode: '32030010',
    Description: 'Loại sử dụng trong công nghiệp thực phẩm hoặc đồ uống',
    Unit: 'kg',
  },
  {
    HsCode: '32030090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3204',
    Description:
      'Chất màu hữu cơ tổng hợp, đã hoặc chưa xác định về mặt hóa học; các chế phẩm đã được ghi trong Chú giải 3 của Chương này dựa trên chất màu hữu cơ tổng hợp; các sản phẩm hữu cơ tổng hợp được dùng như tác nhân tăng sáng huỳnh quang hoặc như các chất phát quang, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chất màu hữu cơ tổng hợp và các chế phẩm từ chúng như đã ghi trong Chú giải 3 của Chương này:',
    Unit: '',
  },
  {
    HsCode: '320411',
    Description: 'Thuốc nhuộm phân tán và các chế phẩm từ chúng:',
    Unit: '',
  },
  {
    HsCode: '32041110',
    Description: 'Dạng thô (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '32041190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '320412',
    Description:
      'Thuốc nhuộm axit, có hoặc không tạo phức kim loại, và các chế phẩm từ chúng; thuốc nhuộm cầm màu và các chế phẩm từ chúng:',
    Unit: '',
  },
  {
    HsCode: '32041210',
    Description: 'Thuốc nhuộm axit ',
    Unit: 'kg',
  },
  {
    HsCode: '32041290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '32041300',
    Description: 'Thuốc nhuộm bazơ và các chế phẩm từ chúng',
    Unit: 'kg',
  },
  {
    HsCode: '32041400',
    Description: 'Thuốc nhuộm trực tiếp và các chế phẩm từ chúng',
    Unit: 'kg',
  },
  {
    HsCode: '32041500',
    Description: 'Thuốc nhuộm chàm (kể cả loại có thể dùng như thuốc màu) và các chế phẩm từ chúng',
    Unit: 'kg',
  },
  {
    HsCode: '32041600',
    Description: 'Thuốc nhuộm hoạt tính và các chế phẩm từ chúng',
    Unit: 'kg',
  },
  {
    HsCode: '320417',
    Description: 'Thuốc màu và các chế phẩm từ chúng:',
    Unit: '',
  },
  {
    HsCode: '32041710',
    Description: 'Thuốc màu hữu cơ tổng hợp ở dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '32041790',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '32041800',
    Description: ' Các chất màu carotenoit và các chế phẩm từ chúng',
    Unit: 'kg',
  },
  {
    HsCode: '32041900',
    Description: 'Loại khác, kể cả hỗn hợp chất màu từ hai phân nhóm trở lên của các phân nhóm từ 3204.11 đến 3204.19',
    Unit: 'kg',
  },
  {
    HsCode: '32042000',
    Description: 'Các sản phẩm hữu cơ tổng hợp được dùng như tác nhân tăng sáng huỳnh quang',
    Unit: 'kg',
  },
  {
    HsCode: '32049000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '32050000',
    Description:
      'Các chất màu nền (colour lakes); các chế phẩm dựa trên các chất màu nền như đã ghi trong Chú giải 3 của Chương này',
    Unit: 'kg',
  },
  {
    HsCode: '3206',
    Description:
      'Chất màu khác; các chế phẩm như đã ghi trong Chú giải 3 của Chương này, trừ các loại thuộc nhóm 32.03, 32.04 hoặc 32.05; các sản phẩm vô cơ được dùng như chất phát quang, đã hoặc chưa xác định về mặt hóa học',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thuốc màu và các chế phẩm từ dioxit titan:',
    Unit: '',
  },
  {
    HsCode: '320611',
    Description: 'Chứa hàm lượng dioxit titan từ 80% trở lên tính theo trọng lượng khô:',
    Unit: '',
  },
  {
    HsCode: '32061110',
    Description: 'Thuốc màu',
    Unit: 'kg',
  },
  {
    HsCode: '32061190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '320619',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '32061910',
    Description: 'Thuốc màu',
    Unit: 'kg',
  },
  {
    HsCode: '32061990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '320620',
    Description: 'Thuốc màu và các chế phẩm từ hợp chất crom:',
    Unit: '',
  },
  {
    HsCode: '32062010',
    Description: 'Màu vàng crom, màu xanh crom và màu da cam molybdat hoặc màu đỏ molybdat từ hợp chất crom',
    Unit: 'kg',
  },
  {
    HsCode: '32062090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Chất màu khác và các chế phẩm khác:',
    Unit: '',
  },
  {
    HsCode: '320641',
    Description: 'Chất màu xanh nước biển và các chế phẩm từ chúng:',
    Unit: '',
  },
  {
    HsCode: '32064110',
    Description: 'Các chế phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '32064190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '320642',
    Description: 'Litopon và các thuốc màu khác và các chế phẩm từ kẽm sulphua:',
    Unit: '',
  },
  {
    HsCode: '32064210',
    Description: 'Các chế phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '32064290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '320649',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các chế phẩm:',
    Unit: '',
  },
  {
    HsCode: '32064911',
    Description: 'Của các chất màu vô cơ từ hợp chất cađimi hoặc hexacyanoferrates (ferrocyanides và ferricyanides)',
    Unit: 'kg',
  },
  {
    HsCode: '32064919',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '32064990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '320650',
    Description: 'Các sản phẩm vô cơ được dùng như chất phát quang:',
    Unit: '',
  },
  {
    HsCode: '32065010',
    Description: 'Các chế phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '32065090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3207',
    Description:
      'Thuốc màu đã pha chế, các chất cản quang đã pha chế và các loại màu đã pha chế, các chất men kính và men sứ, men sành (slips), các chất láng bóng dạng lỏng và các chế phẩm tương tự, loại dùng trong công nghiệp gốm sứ, tráng men hoặc thủy tinh; frit thủy tinh và thủy tinh khác, ở dạng bột, hạt hoặc vẩy',
    Unit: '',
  },
  {
    HsCode: '32071000',
    Description: 'Thuốc màu đã pha chế, chất cản quang đã pha chế, các loại màu đã pha chế và các chế phẩm tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '320720',
    Description: 'Men kính và men sứ, men sành (slips) và các chế phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '32072010',
    Description: 'Frit men (phối liệu men) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '32072090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '32073000',
    Description: 'Các chất láng bóng dạng lỏng và các chế phẩm tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '32074000',
    Description: 'Frit thủy tinh và thủy tinh khác, ở dạng bột, hạt hoặc vẩy',
    Unit: 'kg',
  },
  {
    HsCode: '3208',
    Description:
      'Sơn và vecni (kể cả men tráng (enamels) và dầu bóng) làm từ các loại polyme tổng hợp hoặc các polyme tự nhiên đã biến đổi về mặt hóa học, đã phân tán hoặc hòa tan trong môi trường không chứa nước; các dung dịch như đã ghi trong Chú giải 4 của Chương này',
    Unit: '',
  },
  {
    HsCode: '320810',
    Description: 'Từ polyeste:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Vecni (kể cả dầu bóng):',
    Unit: '',
  },
  {
    HsCode: '32081011',
    Description: 'Dùng trong nha khoa (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32081019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32081020',
    Description: 'Sơn chống hà và/hoặc chống ăn mòn dùng để sơn vỏ tàu thủy (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32081090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '320820',
    Description: 'Từ polyme acrylic hoặc polyme vinyl:',
    Unit: '',
  },
  {
    HsCode: '32082040',
    Description: 'Sơn chống hà và/hoặc chống ăn mòn dùng để sơn vỏ tàu thủy (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32082070',
    Description: 'Vecni (kể cả dầu bóng), loại dùng trong nha khoa (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32082090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '320890',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Vecni (kể cả dầu bóng), loại chịu được nhiệt trên 100°C:',
    Unit: '',
  },
  {
    HsCode: '32089011',
    Description: 'Dùng trong nha khoa (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32089019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Vecni (kể cả dầu bóng), loại chịu nhiệt không quá 100°C:',
    Unit: '',
  },
  {
    HsCode: '32089021',
    Description: 'Dùng trong nha khoa (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32089029',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32089030',
    Description: 'Sơn chống hà và/hoặc chống ăn mòn dùng để sơn vỏ tàu thủy (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32089090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3209',
    Description:
      'Sơn và vecni (kể cả các loại men tráng (enamels) và dầu bóng) làm từ các loại polyme tổng hợp hoặc các polyme tự nhiên đã biến đổi về mặt hóa học, đã phân tán hoặc hòa tan trong môi trường nước',
    Unit: '',
  },
  {
    HsCode: '320910',
    Description: 'Từ polyme acrylic hoặc polyme vinyl:',
    Unit: '',
  },
  {
    HsCode: '32091010',
    Description: 'Vecni (kể cả dầu bóng)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32091040',
    Description: ' Sơn cho da thuộc (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32091050',
    Description: 'Sơn chống hà và/hoặc chống ăn mòn dùng để sơn vỏ tàu thủy (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32091090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32099000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3210',
    Description:
      'Sơn và vecni khác (kể cả các loại men tráng (enamels), dầu bóng và màu keo); các loại thuốc màu nước đã pha chế dùng để hoàn thiện da',
    Unit: '',
  },
  {
    HsCode: '32100010',
    Description: 'Vecni (kể cả dầu bóng)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32100020',
    Description: ' Màu keo',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32100030',
    Description: 'Thuốc màu nước đã pha chế dùng để hoàn thiện da',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '32100091',
    Description: 'Sơn chống hà và/hoặc chống ăn mòn dùng để sơn vỏ tàu thủy (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32100099',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32110000',
    Description: 'Chất làm khô đã điều chế',
    Unit: 'kg',
  },
  {
    HsCode: '3212',
    Description:
      'Thuốc màu (pigments) (kể cả bột và vẩy kim loại) được phân tán trong môi trường không có nước, ở dạng lỏng hoặc dạng nhão, dùng để sản xuất sơn (kể cả men tráng); lá phôi dập; thuốc nhuộm và các chất màu khác đã làm thành dạng nhất định hoặc đã đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '32121000',
    Description: 'Lá phôi dập',
    Unit: 'kg/lít',
  },
  {
    HsCode: '321290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Thuốc màu (kể cả bột và vẩy kim loại) được phân tán trong môi trường không có nước, dạng lỏng hoặc dạng nhão, dùng để sản xuất sơn (kể cả men tráng):',
    Unit: '',
  },
  {
    HsCode: '32129011',
    Description: 'Bột nhão nhôm',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32129013',
    Description: 'Loại chì trắng phân tán trong dầu',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32129014',
    Description: 'Loại khác, để sản xuất sơn cho da thuộc',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32129019',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Thuốc nhuộm và chất màu khác đã làm thành dạng nhất định hoặc đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '32129021',
    Description: 'Loại sử dụng trong công nghiệp thực phẩm hoặc đồ uống',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32129022',
    Description: 'Thuốc nhuộm khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32129029',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3213',
    Description:
      'Chất màu dùng cho nghệ thuật hội họa, học đường hoặc sơn bảng hiệu, chất màu pha (modifying tints), màu trang trí và các loại màu tương tự, ở dạng viên, tuýp, hộp, lọ, khay hoặc các dạng hoặc đóng gói tương tự',
    Unit: '',
  },
  {
    HsCode: '32131000',
    Description: 'Bộ màu vẽ',
    Unit: 'kg/bộ',
  },
  {
    HsCode: '32139000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3214',
    Description:
      'Ma tít để gắn kính, để ghép nối, các chất gắn nhựa (resin cements), các hợp chất dùng để trát, gắn và các loại ma tít khác; các chất bả bề mặt trước khi sơn; các vật liệu phủ bề mặt không chịu nhiệt, dùng để phủ bề mặt chính nhà, tường trong nhà, sàn, trần nhà hoặc tương tự',
    Unit: '',
  },
  {
    HsCode: '32141000',
    Description:
      'Ma tít để gắn kính, ma tít để ghép nối, các chất gắn nhựa (resin cements), các hợp chất dùng để trát, gắn và các loại ma tít khác; chất bả bề mặt trước khi sơn',
    Unit: 'kg',
  },
  {
    HsCode: '32149000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3215',
    Description: 'Mực in, mực viết hoặc mực vẽ và các loại mực khác, đã hoặc chưa cô đặc hoặc làm thành thể rắn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Mực in:',
    Unit: '',
  },
  {
    HsCode: '321511',
    Description: 'Màu đen:',
    Unit: '',
  },
  {
    HsCode: '32151110',
    Description: 'Mực in được làm khô bằng tia cực tím (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32151120',
    Description:
      'Mực thể rắn ở các hình dạng được thiết kế để đưa vào các thiết bị thuộc phân nhóm 8443.31, 8443.32 hoặc 8443.39 (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32151190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '321519',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '32151910',
    Description:
      'Mực thể rắn ở các hình dạng được thiết kế để đưa vào các thiết bị thuộc phân nhóm 8443.31, 8443.32 hoặc 8443.39 (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32151990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '321590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '32159010',
    Description: 'Khối carbon loại dùng để sản xuất giấy than',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32159060',
    Description: 'Mực vẽ hoặc mực viết',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32159070',
    Description: 'Mực dùng cho máy nhân bản thuộc nhóm 84.72 (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '32159090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 33',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tinh dầu và các chất tựa nhựa; nước hoa, mỹ phẩm hoặc các chế phẩm dùng cho vệ sinh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Nhựa dầu tự nhiên hoặc chiết xuất thực vật thuộc nhóm 13.01 hoặc 13.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Xà phòng hoặc các sản phẩm khác thuộc nhóm 34.01; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Dầu turpentine gôm, dầu turpentine gỗ hoặc dầu sunphat turpentine hoặc các sản phẩm khác thuộc nhóm 38.05.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Khái niệm ""chất thơm"" trong nhóm 33.02 chỉ liên quan tới các chất thuộc nhóm 33.01, các thành phần thơm tách từ các chất đó hoặc chất thơm tổng hợp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các nhóm từ 33.03 đến 33.07 áp dụng, không kể những đề cập khác, cho các sản phẩm, đã hoặc chưa pha trộn (trừ nước cất tinh dầu và dung dịch nước của tinh dầu), phù hợp dùng cho các hàng hóa đã ghi trong các nhóm này và được đóng gói để bán lẻ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Khái niệm “nước hoa, mỹ phẩm hoặc các chế phẩm dùng cho vệ sinh” của nhóm 33.07 áp dụng, không kể những đề cập khác, cho các sản phẩm sau đây: các túi nhỏ đựng chất thơm; các chế phẩm có hương thơm khi đốt; giấy thơm và các loại giấy đã thấm tẩm hoặc tráng phủ mỹ phẩm; dung dịch dùng cho kính áp tròng hoặc mắt nhân tạo; mền xơ, phớt và các sản phẩm không dệt, đã thấm tẩm, tráng hoặc phủ nước hoa hoặc mỹ phẩm; các chế phẩm vệ sinh dùng cho động vật.',
    Unit: '',
  },
  {
    HsCode: '3301',
    Description:
      'Tinh dầu (đã hoặc chưa khử terpen), kể cả tinh dầu sáp và tinh dầu nguyên chất; chất tựa nhựa; nhựa dầu đã chiết; tinh dầu cô đặc trong chất béo, trong các loại dầu không bay hơi, trong các loại sáp hoặc các chất tương tự, thu được bằng phương pháp tách hương liệu hoặc ngâm tẩm; sản phẩm phụ terpen từ quá trình khử terpen các loại tinh dầu; nước cất tinh dầu và dung dịch nước của các loại tinh dầu',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tinh dầu của các loại quả chi cam quýt:',
    Unit: '',
  },
  {
    HsCode: '33011200',
    Description: 'Của cam',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33011300',
    Description: 'Của chanh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33011900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Tinh dầu khác trừ tinh dầu của các loại quả chi cam quýt:',
    Unit: '',
  },
  {
    HsCode: '33012400',
    Description: 'Của cây bạc hà cay (Mantha piperita)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33012500',
    Description: 'Của cây bạc hà khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '330129',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '33012920',
    Description: 'Của cây đàn hương',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33012930',
    Description: 'Của cây sả (citronella) (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33012940',
    Description: 'Của cây nhục đậu khấu (nutmeg)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33012950',
    Description: 'Của cây đinh hương (clove)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33012960',
    Description: 'Của cây hoắc hương (parchouli)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33012970',
    Description:
      'Của cây sả chanh (lemon grass), quế (cinnamon), gừng (ginger), bạch đậu khấu (cardamom) hoặc thì là (fennel)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33012990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33013000',
    Description: 'Chất tựa nhựa',
    Unit: 'kg/lít',
  },
  {
    HsCode: '330190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '33019010',
    Description: 'Nước cất và dung dịch nước của các loại tinh dầu phù hợp dùng để làm thuốc ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33019020',
    Description: 'Nhựa dầu đã chiết',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33019090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3302',
    Description:
      'Hỗn hợp các chất thơm và các hỗn hợp (kể cả dung dịch có cồn) với thành phần chủ yếu gồm một hoặc nhiều các chất thơm này, dùng làm nguyên liệu thô trong công nghiệp; các chế phẩm khác làm từ các chất thơm, dùng cho sản xuất đồ uống',
    Unit: '',
  },
  {
    HsCode: '330210',
    Description: 'Loại dùng trong công nghiệp thực phẩm hoặc đồ uống:',
    Unit: '',
  },
  {
    HsCode: '33021010',
    Description: 'Chế phẩm rượu thơm, loại dùng để sản xuất đồ uống có cồn, ở dạng lỏng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33021020',
    Description: 'Chế phẩm rượu thơm, loại dùng để sản xuất đồ uống có cồn, ở dạng khác ',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33021030',
    Description: 'Loại khác, không chứa cồn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33021090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33029000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33030000',
    Description: 'Nước hoa và nước thơm',
    Unit: 'kg/lít/chiếc',
  },
  {
    HsCode: '3304',
    Description:
      'Mỹ phẩm hoặc các chế phẩm để trang điểm và các chế phẩm dưỡng da (trừ dược phẩm), kể cả các chế phẩm chống nắng hoặc bắt nắng; các chế phẩm dùng cho móng tay hoặc móng chân',
    Unit: '',
  },
  {
    HsCode: '33041000',
    Description: 'Chế phẩm trang điểm môi',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '33042000',
    Description: 'Chế phẩm trang điểm mắt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '33043000',
    Description: 'Chế phẩm dùng cho móng tay hoặc móng chân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '33049100',
    Description: 'Phấn, đã hoặc chưa nén',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '330499',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '33049920',
    Description: 'Chế phẩm ngăn ngừa mụn trứng cá ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '33049930',
    Description: 'Kem và dung dịch (lotion) bôi mặt hoặc bôi da khác ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '33049990',
    Description: 'Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '3305',
    Description: 'Chế phẩm dùng cho tóc',
    Unit: '',
  },
  {
    HsCode: '330510',
    Description: 'Dầu gội đầu:',
    Unit: '',
  },
  {
    HsCode: '33051010',
    Description: 'Có tính chất chống nấm',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33051090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33052000',
    Description: 'Chế phẩm uốn tóc hoặc làm duỗi tóc',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33053000',
    Description: 'Keo xịt tóc (hair lacquers)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33059000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3306',
    Description:
      'Chế phẩm dùng cho vệ sinh răng hoặc miệng, kể cả bột và bột nhão làm chặt chân răng; chỉ tơ nha khoa làm sạch kẽ răng (dental floss), đã đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '330610',
    Description: 'Sản phẩm đánh răng:',
    Unit: '',
  },
  {
    HsCode: '33061010',
    Description: 'Bột và bột nhão dùng ngừa bệnh cho răng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '33061090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '33062000',
    Description: 'Chỉ tơ nha khoa làm sạch kẽ răng (dental floss)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '33069000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3307',
    Description:
      'Các chế phẩm dùng trước, trong hoặc sau khi cạo, các chất khử mùi cơ thể, các chế phẩm dùng để tắm, chế phẩm làm rụng lông và các chế phẩm nước hoa, mỹ phẩm hoặc vệ sinh khác, chưa được chi tiết hoặc ghi ở nơi khác; các chất khử mùi phòng đã được pha chế, có hoặc không có mùi thơm hoặc có đặc tính khử trùng',
    Unit: '',
  },
  {
    HsCode: '33071000',
    Description: 'Các chế phẩm dùng trước, trong hoặc sau khi cạo',
    Unit: 'kg',
  },
  {
    HsCode: '33072000',
    Description: 'Chất khử mùi cá nhân và chất chống ra nhiều mồ hôi',
    Unit: 'kg',
  },
  {
    HsCode: '33073000',
    Description: 'Muối thơm dùng để tắm và các chế phẩm dùng để tắm khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Các chế phẩm dùng để làm thơm hoặc khử mùi phòng, kể cả các chế phẩm có mùi thơm dùng trong nghi lễ tôn giáo:',
    Unit: '',
  },
  {
    HsCode: '330741',
    Description: '""Nhang, hương"" và các chế phẩm có mùi thơm khi đốt cháy:',
    Unit: '',
  },
  {
    HsCode: '33074110',
    Description: 'Bột thơm (hương) sử dụng trong nghi lễ tôn giáo',
    Unit: 'kg',
  },
  {
    HsCode: '33074190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '330749',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '33074910',
    Description: 'Các chế phẩm làm thơm phòng, có hoặc không có đặc tính khử trùng',
    Unit: 'kg',
  },
  {
    HsCode: '33074990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '330790',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '33079010',
    Description: 'Chế phẩm vệ sinh động vật',
    Unit: 'kg',
  },
  {
    HsCode: '33079030',
    Description: 'Khăn và giấy, đã được thấm hoặc phủ nước hoa hoặc mỹ phẩm ',
    Unit: 'kg',
  },
  {
    HsCode: '33079040',
    Description: 'Nước hoa hoặc mỹ phẩm khác, kể cả chế phẩm làm rụng lông',
    Unit: 'kg/lít',
  },
  {
    HsCode: '33079050',
    Description: 'Dung dịch dùng cho kính áp tròng hoặc mắt nhân tạo (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '33079090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 34',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Xà phòng, các chất hữu cơ hoạt động bề mặt, các chế phẩm dùng để giặt, rửa, các chế phẩm bôi trơn, các loại sáp nhân tạo, sáp đã được chế biến, các chế phẩm dùng để đánh bóng hoặc tẩy sạch, nến và các sản phẩm tương tự, bột nhão dùng làm hình mẫu, ""sáp dùng trong nha khoa"" và các chế phẩm dùng trong nha khoa có                           thành phần cơ bản là thạch cao',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Hỗn hợp hoặc chế phẩm ăn được từ chất béo hoặc dầu của động vật, thực vật hoặc vi sinh vật được dùng như chế phẩm tách khuôn (nhóm 15.17);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các hợp chất đã xác định về mặt hóa học riêng biệt; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Dầu gội đầu, sản phẩm đánh răng, kem cạo và bọt cạo, hoặc các chế phẩm dùng để tắm, chứa xà phòng hoặc chất hữu cơ hoạt động bề mặt khác (nhóm 33.05, 33.06 hoặc 33.07).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của nhóm 34.01, khái niệm ""xà phòng"" chỉ áp dụng cho loại xà phòng hòa tan trong nước. Xà phòng và các sản phẩm khác thuộc nhóm 34.01 có thể chứa các chất phụ trợ (ví dụ chất khử trùng, bột mài mòn, chất độn hoặc dược phẩm). Các sản phẩm có chứa bột mài mòn phải xếp vào nhóm 34.01 nếu ở dạng thanh, bánh hoặc được nén thành miếng hoặc hình dạng nhất định. Các dạng khác sẽ được xếp vào nhóm 34.05 như ""bột cọ rửa và các chế phẩm tương tự"".',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 34.02, ""các chất hữu cơ hoạt động bề mặt"" là các sản phẩm khi trộn với nước với nồng độ 0,5% ở nhiệt độ 20oC, để trong một giờ ở nhiệt độ như trên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Cho một chất lỏng trong suốt hoặc trắng mờ hoặc thể sữa mà không bị phân tách các chất không hòa tan; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Giảm sức căng bề mặt nước xuống 4,5 x 10-2 N/m (45 dyne/cm) hoặc thấp hơn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Trong nhóm 34.03 khái niệm ""dầu có nguồn gốc từ dầu mỏ và dầu thu được từ khoáng bi-tum"" áp dụng với các sản phẩm như đã định nghĩa tại Chú giải 2 Chương 27. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Trong nhóm 34.04, căn cứ vào giới hạn quy định dưới đây, khái niệm “sáp nhân tạo và sáp chế biến"" chỉ áp dụng cho:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các sản phẩm hữu cơ có tính chất sáp được sản xuất bằng phương pháp hóa học, có hoặc không hòa tan trong nước;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các sản phẩm thu được bằng phương pháp pha trộn các loại sáp khác nhau;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các sản phẩm có tính chất sáp với thành phần cơ bản là một hoặc nhiều loại sáp và chứa chất béo, nhựa, khoáng chất hoặc nguyên liệu khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nhóm này không áp dụng với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các sản phẩm thuộc nhóm 15.16, 34.02 hoặc 38.23 ngay cả khi các sản phẩm đó có tính chất sáp;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Sáp động vật hoặc thực vật không pha trộn, đã hoặc chưa tinh chế hoặc pha màu của nhóm 15.21;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Sáp khoáng chất hoặc sản phẩm tương tự thuộc nhóm 27.12, đã hoặc chưa trộn lẫn hoặc mới chỉ pha màu; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Sáp pha trộn, phân tán hoặc hòa tan trong dung môi lỏng (nhóm 34.05, 38.09...).',
    Unit: '',
  },
  {
    HsCode: '3401',
    Description:
      'Xà phòng; các sản phẩm và các chế phẩm hữu cơ hoạt động bề mặt dùng như xà phòng, ở dạng thanh, bánh, được nén thành miếng hoặc hình dạng nhất định, có hoặc không chứa xà phòng; các sản phẩm và các chế phẩm hữu cơ hoạt động bề mặt dùng để làm sạch da, ở dạng lỏng hoặc ở dạng kem và đã được đóng gói để bán lẻ, có hoặc không chứa xà phòng; giấy, mền xơ, nỉ và sản phẩm không dệt, đã thấm tẩm, tráng hoặc phủ xà phòng hoặc chất tẩy',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Xà phòng và các sản phẩm và các chế phẩm hữu cơ hoạt động bề mặt, ở dạng thanh, bánh, được nén thành miếng hoặc hình dạng nhất định, và giấy, mền xơ, nỉ và sản phẩm không dệt, đã thấm tẩm, tráng hoặc phủ xà phòng hoặc chất tẩy:',
    Unit: '',
  },
  {
    HsCode: '340111',
    Description: 'Dùng cho vệ sinh (kể cả các sản phẩm đã tẩm thuốc):',
    Unit: '',
  },
  {
    HsCode: '34011140',
    Description: 'Xà phòng đã tẩm thuốc kể cả xà phòng sát khuẩn',
    Unit: 'kg',
  },
  {
    HsCode: '34011150',
    Description: 'Xà phòng khác kể cả xà phòng tắm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác, bằng nỉ hoặc sản phẩm không dệt, đã được thấm tẩm, tráng hoặc phủ xà phòng hoặc chất tẩy:',
    Unit: '',
  },
  {
    HsCode: '34011161',
    Description: 'Bằng sản phẩm không dệt được đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '34011169',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '34011170',
    Description: 'Loại khác, bằng giấy, đã được thấm tẩm, tráng hoặc phủ xà phòng hoặc chất tẩy ',
    Unit: 'kg',
  },
  {
    HsCode: '34011190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '340119',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34011910',
    Description: 'Bằng nỉ hoặc sản phẩm không dệt, đã được thấm tẩm, tráng hoặc phủ xà phòng hoặc chất tẩy',
    Unit: 'kg',
  },
  {
    HsCode: '34011920',
    Description: 'Bằng giấy, đã được thấm tẩm, tráng hoặc phủ xà phòng hoặc chất tẩy ',
    Unit: 'kg',
  },
  {
    HsCode: '34011990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '340120',
    Description: 'Xà phòng ở dạng khác:',
    Unit: '',
  },
  {
    HsCode: '34012020',
    Description: 'Phôi xà phòng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34012091',
    Description: 'Dùng để tẩy mực, khử mực giấy tái chế',
    Unit: 'kg',
  },
  {
    HsCode: '34012099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '34013000',
    Description:
      'Các sản phẩm và chế phẩm hữu cơ hoạt động bề mặt dùng để làm sạch da, dạng lỏng hoặc dạng kem và đã được đóng gói để bán lẻ, có hoặc không chứa xà phòng',
    Unit: 'kg',
  },
  {
    HsCode: '3402',
    Description:
      'Chất hữu cơ hoạt động bề mặt (trừ xà phòng); các chế phẩm hoạt động bề mặt, các chế phẩm dùng để giặt, rửa (kể cả các chế phẩm dùng để giặt, rửa phụ trợ) và các chế phẩm làm sạch, có hoặc không chứa xà phòng, trừ các loại thuộc nhóm 34.01',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các chất hữu cơ hoạt động bề mặt dạng anion, đã hoặc chưa đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '340231',
    Description: 'Các axit alkylbenzen sulphonic cấu trúc thẳng và muối của chúng:',
    Unit: '',
  },
  {
    HsCode: '34023110',
    Description: 'Alkylbenzen đã sulphonat hóa (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '34023190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '340239',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34023910',
    Description: 'Cồn béo đã sulphat hóa (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '34023990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các chất hữu cơ hoạt động bề mặt khác, đã hoặc chưa đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '34024100',
    Description: 'Dạng cation',
    Unit: 'kg',
  },
  {
    HsCode: '340242',
    Description: 'Dạng không phân ly (non ionic):',
    Unit: '',
  },
  {
    HsCode: '34024210',
    Description: 'Hydroxyl-terminated polybutadiene',
    Unit: 'kg',
  },
  {
    HsCode: '34024290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '340249',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34024910',
    Description: 'Loại thích hợp để sử dụng trong các chế phẩm chữa cháy',
    Unit: 'kg',
  },
  {
    HsCode: '34024990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '340250',
    Description: 'Chế phẩm đã đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng lỏng:',
    Unit: '',
  },
  {
    HsCode: '34025011',
    Description: 'Chế phẩm hoạt động bề mặt',
    Unit: 'kg',
  },
  {
    HsCode: '34025012',
    Description:
      'Chế phẩm giặt, rửa hoặc chế phẩm làm sạch dạng anion, kể cả các chế phẩm tẩy trắng, làm sạch hoặc tẩy nhờn',
    Unit: 'kg',
  },
  {
    HsCode: '34025019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34025091',
    Description: 'Chế phẩm hoạt động bề mặt',
    Unit: 'kg',
  },
  {
    HsCode: '34025092',
    Description:
      'Chế phẩm giặt, rửa hoặc chế phẩm làm sạch dạng anion, kể cả các chế phẩm tẩy trắng, làm sạch hoặc tẩy nhờn',
    Unit: 'kg',
  },
  {
    HsCode: '34025099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '340290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng lỏng:',
    Unit: '',
  },
  {
    HsCode: '34029011',
    Description: 'Tác nhân thấm ướt dạng anion',
    Unit: 'kg',
  },
  {
    HsCode: '34029012',
    Description:
      'Chế phẩm giặt, rửa hoặc chế phẩm làm sạch dạng anion, kể cả các chế phẩm tẩy trắng, làm sạch hoặc tẩy nhờn',
    Unit: 'kg',
  },
  {
    HsCode: '34029013',
    Description: 'Chế phẩm giặt, rửa hoặc chế phẩm làm sạch khác, kể cả các chế phẩm tẩy trắng, làm sạch hoặc tẩy nhờn',
    Unit: 'kg',
  },
  {
    HsCode: '34029014',
    Description: 'Chế phẩm hoạt động bề mặt dạng anion khác',
    Unit: 'kg',
  },
  {
    HsCode: '34029019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34029091',
    Description: 'Tác nhân thấm ướt dạng anion',
    Unit: 'kg',
  },
  {
    HsCode: '34029092',
    Description:
      'Chế phẩm giặt, rửa hoặc chế phẩm làm sạch dạng anion, kể cả các chế phẩm tẩy trắng, làm sạch hoặc tẩy nhờn',
    Unit: 'kg',
  },
  {
    HsCode: '34029093',
    Description: 'Chế phẩm giặt, rửa hoặc chế phẩm làm sạch khác, kể cả các chế phẩm tẩy trắng, làm sạch hoặc tẩy nhờn',
    Unit: 'kg',
  },
  {
    HsCode: '34029094',
    Description: 'Chế phẩm hoạt động bề mặt dạng anion khác',
    Unit: 'kg',
  },
  {
    HsCode: '34029099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3403',
    Description:
      'Các chế phẩm bôi trơn (kể cả các chế phẩm dầu cắt, các chế phẩm dùng cho việc tháo bu lông hoặc đai ốc, các chế phẩm chống gỉ hoặc chống mài mòn và các chế phẩm dùng cho việc tách khuôn đúc, có thành phần cơ bản là dầu bôi trơn) và các chế phẩm dùng để xử lý bằng dầu hoặc mỡ cho các vật liệu dệt, da thuộc, da lông hoặc các vật liệu khác, nhưng trừ các chế phẩm có thành phần cơ bản chứa từ 70% trở lên tính theo trọng lượng là dầu có nguồn gốc từ dầu mỏ hoặc các loại dầu thu được từ khoáng bi-tum',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Có chứa các loại dầu có nguồn gốc từ dầu mỏ hoặc dầu thu được từ khoáng bi-tum:',
    Unit: '',
  },
  {
    HsCode: '340311',
    Description: 'Chế phẩm dùng để xử lý các vật liệu dệt, da thuộc, da lông hoặc các vật liệu khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng lỏng:',
    Unit: '',
  },
  {
    HsCode: '34031111',
    Description: 'Chế phẩm dầu bôi trơn',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34031119',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34031190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '340319',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng lỏng:',
    Unit: '',
  },
  {
    HsCode: '34031911',
    Description: 'Chế phẩm dùng cho động cơ của phương tiện bay',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34031912',
    Description: 'Chế phẩm khác chứa dầu silicon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34031919',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34031990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '340391',
    Description: 'Chế phẩm dùng để xử lý các vật liệu dệt, da thuộc, da lông hoặc các vật liệu khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng lỏng:',
    Unit: '',
  },
  {
    HsCode: '34039111',
    Description: 'Chế phẩm chứa dầu silicon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34039119',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34039190',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '340399',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng lỏng:',
    Unit: '',
  },
  {
    HsCode: '34039911',
    Description: 'Chế phẩm dùng cho động cơ của phương tiện bay',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34039912',
    Description: 'Chế phẩm khác chứa dầu silicon',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34039919',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '34039990',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3404',
    Description: 'Sáp nhân tạo và sáp đã được chế biến',
    Unit: '',
  },
  {
    HsCode: '34042000',
    Description: 'Từ poly(oxyetylen) (polyetylen glycol)',
    Unit: 'kg',
  },
  {
    HsCode: '340490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34049010',
    Description: 'Của than non đã biến đổi hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '34049090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3405',
    Description:
      'Chất đánh bóng và các loại kem, dùng cho giày dép, đồ nội thất, sàn nhà, thân xe (coachwork), kính hoặc kim loại, các loại bột nhão và bột khô để cọ rửa và chế phẩm tương tự (có hoặc không ở dạng giấy, mền xơ, nỉ, tấm không dệt, plastic xốp hoặc cao su xốp, đã được thấm tẩm, tráng hoặc phủ bằng các chế phẩm trên), trừ các loại sáp thuộc nhóm 34.04',
    Unit: '',
  },
  {
    HsCode: '34051000',
    Description: 'Chất đánh bóng, kem và các chế phẩm tương tự dùng cho giày dép hoặc da thuộc',
    Unit: 'kg',
  },
  {
    HsCode: '34052000',
    Description:
      'Chất đánh bóng, kem và các chế phẩm tương tự dùng để bảo quản đồ nội thất bằng gỗ, sàn gỗ hoặc các hàng hóa khác bằng gỗ',
    Unit: 'kg',
  },
  {
    HsCode: '34053000',
    Description:
      'Chất đánh bóng và các chế phẩm tương tự dùng để đánh bóng thân xe (coachwork), trừ các chất đánh bóng kim loại',
    Unit: 'kg',
  },
  {
    HsCode: '34054000',
    Description: ' Bột nhão và bột khô để cọ rửa và các chế phẩm cọ rửa khác',
    Unit: 'kg',
  },
  {
    HsCode: '340590',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '34059010',
    Description: 'Chất đánh bóng kim loại',
    Unit: 'kg',
  },
  {
    HsCode: '34059090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '34060000',
    Description: 'Nến, nến cây và các loại tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '3407',
    Description:
      'Bột nhão dùng để làm hình mẫu, kể cả đất nặn dùng cho trẻ em; các chế phẩm được coi như ""sáp dùng trong nha khoa"" hoặc như ""các hợp chất tạo khuôn răng"", đã đóng gói thành bộ để bán lẻ hoặc ở dạng phiến, dạng móng ngựa, dạng thanh hoặc các dạng tương tự; các chế phẩm khác dùng trong nha khoa, với thành phần cơ bản là thạch cao plaster (thạch cao nung hoặc canxi sulphat nung)',
    Unit: '',
  },
  {
    HsCode: '34070010',
    Description: 'Bột nhão dùng để làm hình mẫu, kể cả đất nặn dùng cho trẻ em',
    Unit: 'kg',
  },
  {
    HsCode: '34070020',
    Description:
      'Các chế phẩm được coi như ""sáp dùng trong nha khoa"" hoặc như ""hợp chất tạo khuôn răng"", đã đóng gói thành bộ để bán lẻ hoặc ở dạng phiến, dạng móng ngựa, dạng thanh hoặc các dạng tương tự ',
    Unit: 'kg',
  },
  {
    HsCode: '34070030',
    Description:
      'Chế phẩm khác dùng trong nha khoa có thành phần cơ bản là thạch cao plaster (thạch cao nung hoặc canxi sulphat nung)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 35',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các chất chứa albumin; các dạng tinh bột biến tính;               keo hồ; enzym',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Men (nhóm 21.02);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các phần phân đoạn của máu (trừ albumin máu chưa điều chế dùng cho phòng bệnh hoặc chữa bệnh), thuốc hoặc các sản phẩm khác thuộc Chương 30;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các chế phẩm chứa enzym dùng trong quá trình tiền thuộc da (nhóm 32.02);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các chế phẩm có chứa enzym dùng để ngâm hoặc giặt, rửa hoặc các sản phẩm khác thuộc Chương 34;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các loại protein đã làm cứng (nhóm 39.13); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các sản phẩm gelatin thuộc ngành công nghiệp in (Chương 49).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của nhóm 35.05, khái niệm ""dextrin"" chỉ các sản phẩm tinh bột đã phân giải với hàm lượng đường khử, tính theo hàm lượng chất khô dextroza, không quá 10%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các sản phẩm như vậy có hàm lượng đường khử trên 10% sẽ thuộc nhóm 17.02.',
    Unit: '',
  },
  {
    HsCode: '3501',
    Description: 'Casein, các muối của casein và các dẫn xuất casein khác; keo casein',
    Unit: '',
  },
  {
    HsCode: '35011000',
    Description: 'Casein',
    Unit: 'kg',
  },
  {
    HsCode: '350190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '35019010',
    Description: 'Các muối của casein và các dẫn xuất casein khác',
    Unit: 'kg',
  },
  {
    HsCode: '35019020',
    Description: '- Keo casein',
    Unit: 'kg',
  },
  {
    HsCode: '3502',
    Description:
      'Albumin (kể cả các chất cô đặc của hai hoặc nhiều whey protein, chứa trên 80% whey protein tính theo trọng lượng khô), các muối của albumin và các dẫn xuất albumin khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Albumin trứng:',
    Unit: '',
  },
  {
    HsCode: '35021100',
    Description: 'Đã làm khô',
    Unit: 'kg',
  },
  {
    HsCode: '35021900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '35022000',
    Description: 'Albumin sữa, kể cả các chất cô đặc của hai hoặc nhiều whey protein',
    Unit: 'kg',
  },
  {
    HsCode: '35029000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3503',
    Description:
      'Gelatin (kể cả gelatin ở dạng tấm hình chữ nhật (kể cả hình vuông), đã hoặc chưa gia công bề mặt hoặc tạo màu) và các dẫn xuất gelatin; keo điều chế từ bong bóng cá; các loại keo khác có nguồn gốc động vật, trừ keo casein thuộc nhóm 35.01',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Gelatin và các dẫn xuất gelatin:',
    Unit: '',
  },
  {
    HsCode: '35030041',
    Description: 'Dạng bột có độ trương nở từ A-250 hoặc B-230 trở lên theo hệ thống thang đo Bloom (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '35030049',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '35030050',
    Description: 'Loại điều chế từ bong bóng cá (Isinglass)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các loại keo khác có nguồn gốc động vật:',
    Unit: '',
  },
  {
    HsCode: '35030061',
    Description: 'Các loại keo có nguồn gốc từ cá',
    Unit: 'kg',
  },
  {
    HsCode: '35030069',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '35040000',
    Description:
      'Pepton và các dẫn xuất của chúng; protein khác và các dẫn xuất của chúng, chưa được chi tiết hoặc ghi ở nơi khác; bột da sống, đã hoặc chưa crom hóa',
    Unit: 'kg',
  },
  {
    HsCode: '3505',
    Description:
      'Dextrin và các dạng tinh bột biến tính khác (ví dụ, tinh bột đã tiền gelatin hóa hoặc este hóa); các loại keo dựa trên tinh bột, hoặc dextrin hoặc các dạng tinh bột biến tính khác',
    Unit: '',
  },
  {
    HsCode: '350510',
    Description: 'Dextrin và các dạng tinh bột biến tính khác:',
    Unit: '',
  },
  {
    HsCode: '35051010',
    Description: 'Dextrin; tinh bột tan hoặc tinh bột đã rang',
    Unit: 'kg',
  },
  {
    HsCode: '35051090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '35052000',
    Description: 'Keo',
    Unit: 'kg',
  },
  {
    HsCode: '3506',
    Description:
      'Keo đã điều chế và các chất kết dính đã điều chế khác, chưa được chi tiết hoặc ghi ở nơi khác; các sản phẩm phù hợp dùng như keo hoặc các chất kết dính, đã đóng gói để bán lẻ như keo hoặc như các chất kết dính, khối lượng tịnh không quá 1 kg',
    Unit: '',
  },
  {
    HsCode: '35061000',
    Description:
      'Các sản phẩm phù hợp dùng như keo hoặc như các chất kết dính, đã đóng gói để bán lẻ như keo hoặc như các chất kết dính, khối lượng tịnh không quá 1kg',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '350691',
    Description: 'Chất kết dính làm từ các polyme thuộc các nhóm từ 39.01 đến 39.13 hoặc từ cao su:',
    Unit: '',
  },
  {
    HsCode: '35069110',
    Description:
      'Chất kết dính dạng free-film trong suốt quang học và chất kết dính dạng lỏng có thể đóng rắn trong suốt quang học chỉ được sử dụng hoặc được sử dụng chủ yếu để sản xuất màn hình dẹt hoặc màn hình cảm ứng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '35069190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '35069900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3507',
    Description: 'Enzym; enzym đã chế biến chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '35071000',
    Description: 'Rennet và dạng cô đặc của nó',
    Unit: 'kg/lít',
  },
  {
    HsCode: '35079000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 36',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chất nổ; các sản phẩm pháo; diêm; các hợp kim tự cháy; các chế phẩm dễ cháy khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Chương này không bao gồm các hợp chất đã được xác định về mặt hóa học riêng biệt trừ những loại được mô tả ở Chú giải 2(a) hoặc 2(b) dưới đây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Khái niệm “các sản phẩm làm từ vật liệu dễ cháy” thuộc nhóm 36.06 chỉ áp dụng cho:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Metaldehyt, hexamethylenetetramine và các chất tương tự, đã làm thành dạng nhất định (ví dụ, viên, que hoặc các dạng tương tự) dùng làm nhiên liệu; nhiên liệu có thành phần chủ yếu là cồn, và nhiên liệu đã điều chế tương tự, ở dạng rắn hoặc bán rắn;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Nhiên liệu lỏng hoặc nhiên liệu khí hóa lỏng đựng trong các vật chứa loại sử dụng để nạp hoặc nạp lại ga bật lửa châm thuốc hoặc các bật lửa tương tự và có dung tích không quá 300 cm3; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Đuốc nhựa cây, các vật tạo sáng bằng lửa và loại tương tự.',
    Unit: '',
  },
  {
    HsCode: '36010000',
    Description: 'Bột nổ đẩy',
    Unit: 'kg',
  },
  {
    HsCode: '36020000',
    Description: 'Thuốc nổ đã điều chế, trừ bột nổ đẩy',
    Unit: 'kg',
  },
  {
    HsCode: '3603',
    Description: 'Dây cháy chậm; dây nổ; nụ xòe hoặc kíp nổ; bộ phận đánh lửa; kíp nổ điện',
    Unit: '',
  },
  {
    HsCode: '36031000',
    Description: 'Dây cháy chậm',
    Unit: 'kg',
  },
  {
    HsCode: '36032000',
    Description: 'Dây nổ',
    Unit: 'kg',
  },
  {
    HsCode: '36033000',
    Description: 'Nụ xòe',
    Unit: 'kg',
  },
  {
    HsCode: '36034000',
    Description: 'Kíp nổ',
    Unit: 'kg',
  },
  {
    HsCode: '36035000',
    Description: 'Bộ phận đánh lửa',
    Unit: 'kg',
  },
  {
    HsCode: '36036000',
    Description: 'Kíp nổ điện',
    Unit: 'kg',
  },
  {
    HsCode: '3604',
    Description: 'Pháo hoa, pháo hiệu, pháo mưa, pháo hiệu sương mù và các sản phẩm pháo khác',
    Unit: '',
  },
  {
    HsCode: '36041000',
    Description: 'Pháo hoa',
    Unit: 'kg',
  },
  {
    HsCode: '360490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '36049020',
    Description: 'Pháo hoa nhỏ và kíp nổ dùng làm đồ chơi',
    Unit: 'kg',
  },
  {
    HsCode: '36049040',
    Description: 'Pháo hiệu (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '36049050',
    Description: 'Pháo thăng thiên (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '36049090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '36050000',
    Description: 'Diêm, trừ các sản phẩm pháo thuộc nhóm 36.04',
    Unit: 'kg',
  },
  {
    HsCode: '3606',
    Description:
      'Hợp kim xeri-sắt và các hợp kim tự cháy khác ở tất cả các dạng; các sản phẩm làm từ vật liệu dễ cháy như đã nêu trong Chú giải 2 của Chương này',
    Unit: '',
  },
  {
    HsCode: '36061000',
    Description:
      'Nhiên liệu lỏng hoặc nhiên liệu khí hóa lỏng trong các vật chứa loại sử dụng để nạp hoặc nạp lại ga bật lửa và có dung tích không quá 300 cm3',
    Unit: 'kg/lít',
  },
  {
    HsCode: '360690',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '36069010',
    Description: 'Nhiên liệu rắn hoặc bán rắn, cồn rắn và các nhiên liệu được điều chế tương tự ',
    Unit: 'kg',
  },
  {
    HsCode: '36069020',
    Description: 'Đá lửa dùng cho bật lửa',
    Unit: 'kg',
  },
  {
    HsCode: '36069030',
    Description: 'Hợp kim xeri-sắt và các hợp kim tự cháy khác ở tất cả các dạng',
    Unit: 'kg',
  },
  {
    HsCode: '36069090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 37',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Vật liệu ảnh hoặc điện ảnh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm các phế liệu hoặc phế thải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong Chương này từ “tạo ảnh” liên quan đến quá trình tạo các ảnh có thể nhìn thấy được, bằng cách chiếu tia sáng hoặc các dạng tia phát xạ khác lên bề mặt nhạy sáng, kể cả bề mặt cảm ứng nhiệt, trực tiếp hoặc gián tiếp.',
    Unit: '',
  },
  {
    HsCode: '3701',
    Description:
      'Các tấm và phim để tạo ảnh ở dạng phẳng, có phủ lớp chất nhạy, chưa phơi sáng, bằng vật liệu bất kỳ trừ giấy, bìa hoặc vật liệu dệt; phim in ngay dạng phẳng, có phủ lớp chất nhạy, chưa phơi sáng, đã hoặc chưa đóng gói',
    Unit: '',
  },
  {
    HsCode: '37011000',
    Description: 'Dùng cho chụp X quang',
    Unit: 'm2',
  },
  {
    HsCode: '37012000',
    Description: 'Phim in ngay',
    Unit: 'kg',
  },
  {
    HsCode: '37013000',
    Description: 'Tấm và phim loại khác, có từ một chiều bất kỳ trên 255 mm',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '370191',
    Description: 'Dùng cho ảnh màu (đa màu):',
    Unit: '',
  },
  {
    HsCode: '37019110',
    Description: 'Loại phù hợp dùng cho công nghiệp in',
    Unit: 'kg',
  },
  {
    HsCode: '37019190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '370199',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '37019910',
    Description: 'Loại phù hợp dùng cho công nghiệp in',
    Unit: 'm2',
  },
  {
    HsCode: '37019990',
    Description: 'Loại khác',
    Unit: 'm2',
  },
  {
    HsCode: '3702',
    Description:
      'Phim để tạo ảnh ở dạng cuộn, có phủ lớp chất nhạy, chưa phơi sáng, bằng vật liệu bất kỳ trừ giấy, bìa hoặc vật liệu dệt; phim in ngay ở dạng cuộn, có phủ lớp chất nhạy, chưa phơi sáng',
    Unit: '',
  },
  {
    HsCode: '37021000',
    Description: 'Dùng cho chụp X quang',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: 'Phim loại khác, không có dãy lỗ kéo phim, có chiều rộng không quá 105 mm:',
    Unit: '',
  },
  {
    HsCode: '37023100',
    Description: 'Dùng cho ảnh màu (đa màu)',
    Unit: 'cuộn',
  },
  {
    HsCode: '37023200',
    Description: 'Loại khác, có tráng nhũ tương bạc halogenua',
    Unit: 'm2',
  },
  {
    HsCode: '37023900',
    Description: 'Loại khác',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: 'Phim loại khác, không có dãy lỗ kéo phim, có chiều rộng trên 105 mm:',
    Unit: '',
  },
  {
    HsCode: '37024100',
    Description: 'Loại chiều rộng trên 610 mm và chiều dài trên 200 m, dùng cho ảnh màu (đa màu)',
    Unit: 'm2',
  },
  {
    HsCode: '370242',
    Description: 'Loại chiều rộng trên 610 mm và chiều dài trên 200 m, trừ loại dùng cho ảnh màu:',
    Unit: '',
  },
  {
    HsCode: '37024210',
    Description: 'Loại phù hợp để dùng trong y tế, phẫu thuật, nha khoa hoặc thú y hoặc trong công nghiệp in',
    Unit: 'm2',
  },
  {
    HsCode: '37024290',
    Description: 'Loại khác',
    Unit: 'm2',
  },
  {
    HsCode: '37024300',
    Description: 'Loại chiều rộng trên 610 mm và chiều dài không quá 200 m',
    Unit: 'm2',
  },
  {
    HsCode: '37024400',
    Description: 'Loại chiều rộng trên 105 mm nhưng không quá 610 mm',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: 'Phim loại khác, dùng cho ảnh màu (đa màu): ',
    Unit: '',
  },
  {
    HsCode: '370252',
    Description: 'Loại chiều rộng không quá 16 mm: ',
    Unit: '',
  },
  {
    HsCode: '37025220',
    Description: 'Loại phù hợp dùng cho điện ảnh',
    Unit: 'm',
  },
  {
    HsCode: '37025290',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '37025300',
    Description: 'Loại chiều rộng trên 16 mm nhưng không quá 35 mm và chiều dài không quá 30 m, dùng làm phim chiếu',
    Unit: 'm',
  },
  {
    HsCode: '370254',
    Description:
      'Loại chiều rộng trên 16 mm nhưng không quá 35 mm và chiều dài không quá 30 m, trừ loại dùng làm phim chiếu:',
    Unit: '',
  },
  {
    HsCode: '37025440',
    Description: 'Loại phù hợp để dùng trong y tế, phẫu thuật, nha khoa hoặc thú y hoặc trong công nghiệp in',
    Unit: 'm',
  },
  {
    HsCode: '37025490',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '370255',
    Description: 'Loại chiều rộng trên 16 mm nhưng không quá 35 mm và chiều dài trên 30 m:',
    Unit: '',
  },
  {
    HsCode: '37025520',
    Description: 'Loại phù hợp dùng cho điện ảnh',
    Unit: 'm',
  },
  {
    HsCode: '37025550',
    Description: 'Loại phù hợp để dùng trong y tế, phẫu thuật, nha khoa hoặc thú y hoặc trong công nghiệp in',
    Unit: 'm',
  },
  {
    HsCode: '37025590',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '370256',
    Description: 'Loại chiều rộng trên 35 mm:',
    Unit: '',
  },
  {
    HsCode: '37025620',
    Description: 'Loại phù hợp dùng cho điện ảnh',
    Unit: 'm',
  },
  {
    HsCode: '37025690',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '370296',
    Description: 'Loại chiều rộng không quá 35 mm và chiều dài không quá 30 m:',
    Unit: '',
  },
  {
    HsCode: '37029610',
    Description: 'Loại phù hợp dùng cho điện ảnh',
    Unit: 'm',
  },
  {
    HsCode: '37029690',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '370297',
    Description: 'Loại chiều rộng không quá 35 mm và chiều dài trên 30 m:',
    Unit: '',
  },
  {
    HsCode: '37029710',
    Description: 'Loại phù hợp dùng cho điện ảnh',
    Unit: 'm',
  },
  {
    HsCode: '37029790',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '370298',
    Description: 'Loại chiều rộng trên 35 mm:',
    Unit: '',
  },
  {
    HsCode: '37029810',
    Description: 'Loại phù hợp dùng cho điện ảnh',
    Unit: 'm',
  },
  {
    HsCode: '37029830',
    Description: 'Loại khác, chiều dài từ 120 m trở lên',
    Unit: 'm',
  },
  {
    HsCode: '37029890',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '3703',
    Description: 'Giấy, bìa và vật liệu dệt để tạo ảnh, có phủ lớp chất nhạy, chưa phơi sáng',
    Unit: '',
  },
  {
    HsCode: '370310',
    Description: 'Ở dạng cuộn, có chiều rộng trên 610 mm:',
    Unit: '',
  },
  {
    HsCode: '37031010',
    Description: 'Chiều rộng không quá 1.000 mm',
    Unit: 'kg',
  },
  {
    HsCode: '37031090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '37032000',
    Description: 'Loại khác, dùng cho ảnh màu (đa màu)',
    Unit: 'kg',
  },
  {
    HsCode: '37039000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3704',
    Description: 'Tấm, phim, giấy, bìa và vật liệu dệt để tạo ảnh, đã phơi sáng nhưng chưa tráng',
    Unit: '',
  },
  {
    HsCode: '37040010',
    Description: 'Tấm hoặc phim dùng cho chụp X quang',
    Unit: 'kg',
  },
  {
    HsCode: '37040090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3705',
    Description: 'Tấm và phim để tạo ảnh, đã phơi sáng và đã tráng, trừ phim dùng trong điện ảnh',
    Unit: '',
  },
  {
    HsCode: '37050010',
    Description: 'Dùng cho chụp X quang',
    Unit: 'kg',
  },
  {
    HsCode: '37050020',
    Description: 'Vi phim (microfilm)',
    Unit: 'kg',
  },
  {
    HsCode: '37050030',
    Description: 'Dùng để tái tạo bản in offset',
    Unit: 'kg',
  },
  {
    HsCode: '37050090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3706',
    Description:
      'Phim dùng trong điện ảnh, đã phơi sáng và đã tráng, đã hoặc chưa có rãnh tiếng hoặc chỉ có duy nhất rãnh tiếng',
    Unit: '',
  },
  {
    HsCode: '370610',
    Description: 'Loại chiều rộng từ 35 mm trở lên:',
    Unit: '',
  },
  {
    HsCode: '37061010',
    Description: 'Phim thời sự, phim du lịch, phim kỹ thuật và phim khoa học (SEN)',
    Unit: 'm',
  },
  {
    HsCode: '37061030',
    Description: 'Phim tài liệu khác',
    Unit: 'm',
  },
  {
    HsCode: '37061040',
    Description: 'Loại khác, chỉ có duy nhất rãnh tiếng',
    Unit: 'm',
  },
  {
    HsCode: '37061090',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '370690',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '37069010',
    Description: 'Phim thời sự, phim du lịch, phim kỹ thuật và phim khoa học (SEN)',
    Unit: 'm',
  },
  {
    HsCode: '37069030',
    Description: 'Phim tài liệu khác',
    Unit: 'm',
  },
  {
    HsCode: '37069040',
    Description: 'Loại khác, chỉ có duy nhất rãnh tiếng',
    Unit: 'm',
  },
  {
    HsCode: '37069090',
    Description: 'Loại khác',
    Unit: 'm',
  },
  {
    HsCode: '3707',
    Description:
      'Chế phẩm hóa chất để tạo ảnh (trừ vecni, keo, chất kết dính và các chế phẩm tương tự); các sản phẩm chưa pha trộn dùng để tạo ảnh, đã đóng gói theo định lượng hoặc đóng gói để bán lẻ ở dạng sử dụng được ngay',
    Unit: '',
  },
  {
    HsCode: '37071000',
    Description: 'Dạng nhũ tương nhạy',
    Unit: 'kg',
  },
  {
    HsCode: '370790',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '37079010',
    Description: '- Vật liệu phát sáng',
    Unit: 'kg',
  },
  {
    HsCode: '37079090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 38',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các sản phẩm hóa chất khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các nguyên tố hoặc các hợp chất được xác định về mặt hóa học riêng biệt trừ những trường hợp sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(1) Graphit nhân tạo (nhóm 38.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) Thuốc trừ côn trùng, thuốc diệt loài gặm nhấm, thuốc trừ nấm, thuốc diệt cỏ, thuốc chống nảy mầm và thuốc điều hòa sinh trưởng cây trồng, thuốc khử trùng và các sản phẩm tương tự, đã đóng gói theo cách thức được mô tả ở nhóm 38.08;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(3) Các sản phẩm dùng như vật liệu nạp cho bình dập lửa hoặc lựu đạn dập lửa (nhóm 38.13);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(4) Các mẫu chuẩn được chứng nhận (1) nêu tại Chú giải 2 dưới đây;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(5) Các sản phẩm ghi trong Chú giải 3(a) hoặc 3(c) dưới đây;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Hỗn hợp hóa chất với thực phẩm hoặc các chất có giá trị dinh dưỡng khác, dùng trong quá trình chế biến thực phẩm cho người (thường thuộc nhóm 21.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các sản phẩm của nhóm 24.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Xỉ, tro và cặn (kể cả bùn cặn, trừ bùn cặn của nước thải), chứa kim loại, arsen hoặc hỗn hợp của chúng và thỏa mãn các yêu cầu của Chú giải 3(a) hoặc 3(b) thuộc Chương 26 (nhóm 26.20); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Dược phẩm (nhóm 30.03 hoặc 30.04); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Chất xúc tác đã qua sử dụng của loại dùng cho tách kim loại cơ bản hoặc dùng cho sản xuất các hợp chất hóa học của kim loại cơ bản (nhóm 26.20), chất xúc tác đã qua sử dụng của loại dùng chủ yếu cho việc thu hồi kim loại quý (nhóm 71.12) hoặc chất xúc tác gồm các kim loại hoặc các hợp kim kim loại ở dạng, ví dụ, dạng bột mịn hoặc dạng lưới dệt (Phần XIV hoặc XV).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2.(A) Theo mục đích của nhóm 38.22, thuật ngữ “mẫu chuẩn được chứng nhận” (1) có nghĩa là các mẫu chuẩn (1) được cấp một giấy chứng nhận công bố các giá trị của các tính chất được chứng nhận, các phương pháp để xác định những giá trị đó, độ tin cậy của mỗi giá trị phù hợp cho mục đích phân tích, kiểm định hoặc quy chiếu. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Ngoại trừ các sản phẩm của Chương 28 hoặc 29, để phân loại các mẫu chuẩn được chứng nhận (1), nhóm 38.22 sẽ được ưu tiên trước so với bất cứ một nhóm nào khác trong Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Nhóm 38.24 kể cả những hàng hóa được nêu dưới đây, những hàng hóa này không được xếp vào bất kỳ nhóm nào trong Danh mục:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Tinh thể nuôi cấy (trừ các bộ phận quang học) có trọng lượng mỗi tinh thể không dưới 2,5 g, bằng oxit magiê hoặc các muối halogen của kim loại kiềm hoặc kiềm thổ;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dầu rượu tạp; dầu dippel;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Chất tẩy mực đã đóng gói để bán lẻ;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Chất sửa giấy nến (stencil correctors), các chất lỏng dùng để xóa và băng để xóa khác (trừ các sản phẩm thuộc nhóm 96.12), được đóng gói để bán lẻ; và ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Chất thử gia nhiệt gốm sứ, nóng chảy được (ví dụ, nón Seger).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Trong toàn bộ Danh mục, “rác thải đô thị” có nghĩa là rác thải được thu hồi từ các hộ gia đình, khách sạn, nhà hàng, bệnh viện, cửa hàng, văn phòng,… rác thải thu được do quét vỉa hè và đường phố, cũng như rác thải thu được do phá hủy và xây dựng. Nhìn chung rác thải đô thị chứa một lượng lớn các vật liệu như nhựa, cao su, gỗ, giấy, vật liệu dệt, thủy tinh, kim loại, thức ăn, đồ nội thất bị hỏng và các vật dụng bị hỏng hoặc thải loại khác. Tuy nhiên, thuật ngữ “rác thải đô thị”, không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Vật liệu hoặc vật phẩm riêng biệt đã được phân loại từ rác thải, như phế thải nhựa, cao su, gỗ, giấy, vật liệu dệt, thủy tinh hoặc kim loại, phế liệu và phế thải điện và điện tử (kể cả pin đã qua sử dụng), được đưa vào các nhóm phù hợp của chúng trong Danh mục; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Phế thải công nghiệp;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Phế thải dược phẩm, như được ghi trong Chú giải 4 (k) của Chương 30; hoặc ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Rác thải bệnh viện, như được nêu trong Chú giải 6 (a) dưới đây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của nhóm 38.25, “bùn cặn của nước thải” có nghĩa là bùn cặn thu được từ nhà máy xử lý nước thải đô thị và kể cả phế thải trước khi xử lý, các chất phát sinh do cọ rửa và bùn cặn chưa làm ổn định. Bùn cặn đã làm ổn định khi thích hợp để sử dụng như phân bón bị loại trừ (Chương 31).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '6. Theo mục đích của nhóm 38.25, khái niệm “chất thải khác” áp dụng đối với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Rác thải bệnh viện, là rác thải bẩn từ việc nghiên cứu y khoa, chẩn đoán, điều trị hoặc từ các khâu y khoa khác, phẫu thuật, nha khoa hoặc thú y, thường chứa các mầm bệnh và các chất dược liệu và đòi hỏi quy trình xử lý đặc biệt (ví dụ, băng bẩn, găng tay đã sử dụng và ống tiêm đã sử dụng);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dung môi hữu cơ thải;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Chất thải từ dung dịch tẩy sạch kim loại, chất lỏng thủy lực, chất lỏng dùng cho phanh và chất lỏng chống đông; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Chất thải khác từ ngành công nghiệp hóa chất hoặc các ngành công nghiệp có liên quan.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, khái niệm “chất thải khác” không bao gồm chất thải chứa chủ yếu dầu có nguồn gốc từ dầu mỏ hoặc dầu thu được từ các khoáng bitum (nhóm 27.10).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Theo mục đích nhóm 38.26, thuật ngữ ""diesel sinh học"" nghĩa là các este mono-alkyl của các axit béo loại được sử dụng làm nhiên liệu, có nguồn gốc từ chất béo và dầu động vật hoặc thực vật hoặc vi sinh vật, đã hoặc chưa qua sử dụng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Phân nhóm 3808.52 và 3808.59 chỉ bao gồm những hàng hóa thuộc nhóm 38.08, chứa một hoặc nhiều các chất sau đây: alachlor (ISO); adicarb (ISO); aldrin (ISO); azinphos-methyl (ISO); binapacryl (ISO); camphechlor (ISO) (toxaphene); captafol (ISO); chlordane (ISO); carbofuran (ISO); chlordimeform (ISO); chlorobenzilate (ISO); DDT (ISO) (clofenotane (INN), 1,1,1-trichloro-2,2-bis(p-chlorophenyl) ethane); dieldrin (ISO, INN); 4,6-dinitro-o-cresol (DNOC (ISO)) hoặc muối của nó; dinoseb (ISO), các muối hoặc các este của nó; endosulfan (ISO); ethylene dibromide (ISO) (1,2-dibromoethane); ethylene dichloride (ISO) (1,2-dichloroethane); fluoroacetamide (ISO); heptachlor (ISO); hexachlorobenzene (ISO); 1,2,3,4,5,6-hexachlorocyclohexane (HCH (ISO)), kể cả lindane (ISO, INN); các hợp chất của thủy ngân; methamidophos (ISO); monocrotophos (ISO); oxirane (ethylene oxide); parathion (ISO); parathion-methyl (ISO) (methyl-parathion); pentachlorophenol (ISO), các muối hoặc các este của nó; perfluorooctane sulphonic axit và các muối của nó; perfluorooctane sulphonamides; perfluorooctane sulphonyl fluoride; phosphamidon (ISO); 2,4,5-T (ISO) (2,4,5-trichlorophenoxyacetic axit), các muối của nó hoặc các este của nó; các hợp chất tributyltin; trichlorfon (ISO).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Phân nhóm 3808.61 đến 3808.69 chỉ bao gồm hàng hóa thuộc nhóm 38.08, chứa alpha-cypermethrin (ISO), bendiocarb (ISO), bifendthrin (ISO), chlorfenapyr (ISO), cyfluthrin (ISO), deltamethrin (INN, ISO), etofenprox (INN), fenitrothion (ISO), lambda-cyhalothrin (ISO), malathion (ISO), pirimiphos-methyl (ISO) hoặc propoxur (ISO).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các phân nhóm từ 3824.81 đến 3824.89 chỉ bao gồm các hỗn hợp và các chế phẩm chứa một hoặc nhiều chất sau: oxirane (ethylene oxide); polybrominated biphenyls (PBBs); polychlorinated biphenyls (PCBs); polychlorinated terphenyls (PCTs); tris(2,3-dibromopropyl) phosphate; aldrin (ISO); camphechlor (ISO) (toxaphene); chlordane (ISO); chlordecone (ISO); DDT (ISO) (clofenotane (INN); 1,1,1-trichloro-2,2-bis(p-chlorophenyl)ethane); dieldrin (ISO, INN); endosulfan (ISO); endrin (ISO); heptachlor (ISO); mirex (ISO); 1,2,3,4,5,6-hexachlorocyclohexane (HCH (ISO)), kể cả lindane (ISO, INN); pentachlorobenzene (ISO); hexachlorobenzene (ISO); axit perfluorooctane sulphonic, các muối của nó; các perfluorooctane sulphonamide; perfluorooctane sulphonyl fluoride; các ete tetra-, penta-, hexa-, heptahoặc octabromodiphenyl; các paraffin đã clo hóa mạch ngắn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các paraffin đã clo hóa mạch ngắn là các hỗn hợp của các hợp chất, với mức độ clo hóa hơn 48% tính theo trọng lượng, với công thức phân tử sau: CxH(2x-y+2)Cly, trong đó x = 10 13 và y = 1 13.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo mục đích của các phân nhóm 3825.41 và 3825.49, “dung môi hữu cơ thải” là các chất thải có chứa chủ yếu dung môi hữu cơ, không còn phù hợp để sử dụng tiếp như các sản phẩm ban đầu, được hoặc không được sử dụng chomục đích thu hồi dung môi.',
    Unit: '',
  },
  {
    HsCode: '3801',
    Description:
      'Graphit nhân tạo; graphit dạng keo hoặc dạng bán keo; các chế phẩm làm từ graphit hoặc carbon khác ở dạng bột nhão, khối, tấm hoặc ở dạng bán thành phẩm khác',
    Unit: '',
  },
  {
    HsCode: '38011000',
    Description: 'Graphit nhân tạo',
    Unit: 'kg',
  },
  {
    HsCode: '38012000',
    Description: 'Graphit dạng keo hoặc dạng bán keo',
    Unit: 'kg',
  },
  {
    HsCode: '38013000',
    Description: 'Bột nhão carbon làm điện cực và các dạng bột nhão tương tự dùng để lót lò nung',
    Unit: 'kg',
  },
  {
    HsCode: '38019000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3802',
    Description:
      'Carbon hoạt tính; các sản phẩm khoáng chất tự nhiên hoạt tính; muội động vật, kể cả tàn muội động vật',
    Unit: '',
  },
  {
    HsCode: '380210',
    Description: 'Carbon hoạt tính:',
    Unit: '',
  },
  {
    HsCode: '38021010',
    Description: 'Từ than gáo dừa (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38021090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '380290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38029010',
    Description: 'Bauxit hoạt tính',
    Unit: 'kg',
  },
  {
    HsCode: '38029020',
    Description: 'Đất sét hoạt tính hoặc đất hoạt tính',
    Unit: 'kg',
  },
  {
    HsCode: '38029090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38030000',
    Description: 'Dầu tall, đã hoặc chưa tinh chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3804',
    Description:
      'Dung dịch kiềm thải ra trong quá trình sản xuất bột giấy từ gỗ, đã hoặc chưa cô đặc, khử đường hoặc xử lý hóa học, kể cả lignin sulphonates, nhưng trừ dầu tall thuộc nhóm 38.03',
    Unit: '',
  },
  {
    HsCode: '38040010',
    Description: 'Dung dịch kiềm sulphit cô đặc',
    Unit: 'kg',
  },
  {
    HsCode: '38040020',
    Description: 'Chất kết dính calcium lignin sulphonates (Ca2LS) được dùng trong sản xuất gạch chịu lửa',
    Unit: 'kg',
  },
  {
    HsCode: '38040090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3805',
    Description:
      'Dầu turpentine gôm, dầu turpentine gỗ hoặc dầu turpentine sulphate và các loại dầu terpenic khác được sản xuất bằng phương pháp chưng cất hoặc xử lý cách khác từ gỗ cây lá kim; dipentene thô; turpentine sulphit và para-cymene thô khác; dầu thông có chứa alpha-terpineol như thành phần chủ yếu',
    Unit: '',
  },
  {
    HsCode: '38051000',
    Description: 'Dầu turpentine gôm, dầu turpentine gỗ hoặc dầu turpentine sulphate',
    Unit: 'kg',
  },
  {
    HsCode: '38059000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3806',
    Description:
      'Colophan và axit nhựa cây, và các dẫn xuất của chúng; tinh dầu colophan và dầu colophan; gôm nấu chảy lại',
    Unit: '',
  },
  {
    HsCode: '38061000',
    Description: 'Colophan và axit nhựa cây',
    Unit: 'kg',
  },
  {
    HsCode: '38062000',
    Description:
      'Muối colophan, muối của axit nhựa cây hoặc muối của các dẫn xuất của colophan hoặc axit nhựa cây, trừ các muối của sản phẩm cộng của colophan',
    Unit: 'kg',
  },
  {
    HsCode: '380630',
    Description: 'Gôm este:',
    Unit: '',
  },
  {
    HsCode: '38063010',
    Description: 'Dạng khối',
    Unit: 'kg',
  },
  {
    HsCode: '38063090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '380690',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38069010',
    Description: 'Gôm nấu chảy lại ở dạng khối',
    Unit: 'kg',
  },
  {
    HsCode: '38069090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38070000',
    Description:
      'Hắc ín gỗ; dầu hắc ín gỗ; creosote gỗ; naphtha gỗ; hắc ín thực vật; hắc ín cho quá trình ủ rượu, bia và các chế phẩm tương tự dựa trên colophan, axit nhựa cây hoặc các hắc ín thực vật',
    Unit: 'kg',
  },
  {
    HsCode: '3808',
    Description:
      'Thuốc trừ côn trùng, thuốc diệt loài gặm nhấm, thuốc trừ nấm, thuốc diệt cỏ, thuốc chống nẩy mầm và thuốc điều hòa sinh trưởng cây trồng, thuốc khử trùng và các sản phẩm tương tự, được làm thành dạng nhất định hoặc đóng gói để bán lẻ hoặc như các chế phẩm hoặc sản phẩm (ví dụ, băng, bấc và nến đã xử lý lưu huỳnh và giấy bẫy ruồi)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hàng hóa đã nêu trong Chú giải phân nhóm 1 của Chương này:',
    Unit: '',
  },
  {
    HsCode: '380852',
    Description: 'DDT (ISO) (clofenotane (INN)), đã đóng gói với khối lượng tịnh không quá 300 g:',
    Unit: '',
  },
  {
    HsCode: '38085210',
    Description: 'Thuốc bảo quản gỗ, là chế phẩm chứa chất trừ côn trùng hoặc trừ nấm, trừ chất phủ bề mặt',
    Unit: 'kg',
  },
  {
    HsCode: '38085220',
    Description: 'Thuốc trừ nấm và thuốc trừ côn trùng dạng bình xịt',
    Unit: 'kg',
  },
  {
    HsCode: '38085290',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '380859',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thuốc trừ côn trùng:',
    Unit: '',
  },
  {
    HsCode: '38085911',
    Description: 'Dạng bình xịt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38085919',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Thuốc trừ nấm:',
    Unit: '',
  },
  {
    HsCode: '38085921',
    Description: 'Dạng bình xịt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38085929',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Thuốc diệt cỏ:',
    Unit: '',
  },
  {
    HsCode: '38085931',
    Description: 'Dạng bình xịt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38085939',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38085940',
    Description: 'Thuốc chống nảy mầm',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38085950',
    Description: 'Thuốc điều hòa sinh trưởng cây trồng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38085960',
    Description: 'Thuốc khử trùng (Disinfectants)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38085991',
    Description: 'Thuốc bảo quản gỗ, là chế phẩm chứa chất trừ côn trùng hoặc trừ nấm, trừ chất phủ bề mặt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38085999',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Hàng hóa đã nêu trong Chú giải phân nhóm 2 của Chương này:',
    Unit: '',
  },
  {
    HsCode: '380861',
    Description: 'Đóng gói với khối lượng tịnh không quá 300 g:',
    Unit: '',
  },
  {
    HsCode: '38086110',
    Description: 'Hương vòng chống muỗi',
    Unit: 'kg',
  },
  {
    HsCode: '38086120',
    Description: 'Tấm thuốc diệt muỗi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38086130',
    Description: 'Dạng bình xịt',
    Unit: 'kg',
  },
  {
    HsCode: '38086140',
    Description: 'Loại khác, dạng lỏng',
    Unit: 'kg',
  },
  {
    HsCode: '38086150',
    Description: 'Loại khác, có chức năng khử mùi',
    Unit: 'kg',
  },
  {
    HsCode: '38086190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '380862',
    Description: 'Đóng gói với khối lượng tịnh trên 300 g nhưng không quá 7,5 kg:',
    Unit: '',
  },
  {
    HsCode: '38086210',
    Description: 'Bột dùng làm hương vòng chống muỗi',
    Unit: 'kg',
  },
  {
    HsCode: '38086220',
    Description: 'Hương vòng chống muỗi',
    Unit: 'kg',
  },
  {
    HsCode: '38086230',
    Description: 'Tấm thuốc diệt muỗi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38086240',
    Description: 'Dạng bình xịt',
    Unit: 'kg',
  },
  {
    HsCode: '38086250',
    Description: 'Loại khác, dạng lỏng',
    Unit: 'kg',
  },
  {
    HsCode: '38086290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '380869',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38086910',
    Description: 'Bột dùng làm hương vòng chống muỗi',
    Unit: 'kg',
  },
  {
    HsCode: '38086990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '380891',
    Description: 'Thuốc trừ côn trùng:',
    Unit: '',
  },
  {
    HsCode: '38089110',
    Description: 'Các chế phẩm trung gian chứa 2-(methylpropyl-phenol methylcarbamate)',
    Unit: 'kg',
  },
  {
    HsCode: '38089120',
    Description: 'Loại khác, dạng bột dùng làm hương vòng chống muỗi',
    Unit: 'kg',
  },
  {
    HsCode: '38089130',
    Description: 'Dạng bình xịt',
    Unit: 'kg',
  },
  {
    HsCode: '38089140',
    Description: 'Hương vòng chống muỗi',
    Unit: 'kg',
  },
  {
    HsCode: '38089150',
    Description: 'Tấm thuốc diệt muỗi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38089191',
    Description: 'Có chức năng khử mùi (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38089199',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '380892',
    Description: 'Thuốc trừ nấm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng bình xịt:',
    Unit: '',
  },
  {
    HsCode: '38089211',
    Description: 'Với hàm lượng validamycin không quá 3% tính theo khối lượng tịnh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38089219',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38089290',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '380893',
    Description: '- Thuốc diệt cỏ, thuốc chống nẩy mầm và thuốc điều hòa sinh trưởng cây trồng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thuốc diệt cỏ:',
    Unit: '',
  },
  {
    HsCode: '38089311',
    Description: 'Dạng bình xịt',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38089319',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38089320',
    Description: 'Thuốc chống nảy mầm',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38089330',
    Description: 'Thuốc điều hòa sinh trưởng cây trồng',
    Unit: 'kg/lít',
  },
  {
    HsCode: '380894',
    Description: 'Thuốc khử trùng:',
    Unit: '',
  },
  {
    HsCode: '38089410',
    Description: 'Có chứa hỗn hợp các axit hắc ín than đá và các chất kiềm',
    Unit: 'kg',
  },
  {
    HsCode: '38089420',
    Description: 'Loại khác, dạng bình xịt',
    Unit: 'kg',
  },
  {
    HsCode: '38089490',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '380899',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38089910',
    Description: 'Thuốc bảo quản gỗ, chứa chất diệt côn trùng hoặc trừ nấm',
    Unit: 'kg',
  },
  {
    HsCode: '38089990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3809',
    Description:
      'Tác nhân để hoàn tất, các chế phẩm tải thuốc nhuộm để làm tăng tốc độ nhuộm màu hoặc để hãm màu và các sản phẩm và chế phẩm khác (ví dụ, chất xử lý hoàn tất vải và thuốc gắn màu), dùng trong ngành dệt, giấy, thuộc da hoặc các ngành công nghiệp tương tự, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '38091000',
    Description: 'Dựa trên thành phần cơ bản là tinh bột',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '380991',
    Description: 'Loại dùng trong công nghiệp dệt hoặc các ngành công nghiệp tương tự:',
    Unit: '',
  },
  {
    HsCode: '38099110',
    Description: 'Tác nhân làm mềm (softening agents)',
    Unit: 'kg',
  },
  {
    HsCode: '38099190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38099200',
    Description: 'Loại dùng trong công nghiệp giấy hoặc các ngành công nghiệp tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '38099300',
    Description: 'Loại dùng trong công nghiệp thuộc da hoặc các ngành công nghiệp tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '3810',
    Description:
      'Chế phẩm tẩy sạch cho bề mặt kim loại; chất giúp chảy và các chế phẩm phụ trợ khác dùng cho hàn thiếc, hàn hơi hoặc hàn điện; bột và bột nhão gồm kim loại và các vật liệu khác dùng để hàn thiếc, hàn hơi hoặc hàn điện; các chế phẩm dùng làm lõi hoặc vỏ cho điện cực hàn hoặc que hàn',
    Unit: '',
  },
  {
    HsCode: '38101000',
    Description:
      'Các chế phẩm tẩy sạch cho bề mặt kim loại; bột và bột nhão gồm kim loại và các vật liệu khác dùng để hàn thiếc, hàn hơi hoặc hàn điện',
    Unit: 'kg',
  },
  {
    HsCode: '38109000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3811',
    Description:
      'Chế phẩm chống kích nổ, chất ức chế quá trình oxy hóa, chất chống dính, chất làm tăng độ nhớt, chế phẩm chống ăn mòn và các chất phụ gia đã điều chế khác, dùng cho dầu khoáng (kể cả xăng) hoặc cho các loại chất lỏng dùng như dầu khoáng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chế phẩm chống kích nổ:',
    Unit: '',
  },
  {
    HsCode: '38111100',
    Description: 'Từ hợp chất chì',
    Unit: 'kg',
  },
  {
    HsCode: '38111900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các phụ gia cho dầu bôi trơn:',
    Unit: '',
  },
  {
    HsCode: '381121',
    Description: 'Có chứa các loại dầu có nguồn gốc từ dầu mỏ hoặc dầu thu được từ khoáng bi-tum:',
    Unit: '',
  },
  {
    HsCode: '38112110',
    Description: 'Đã đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '38112190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38112900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '381190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38119010',
    Description: 'Chế phẩm chống gỉ hoặc chống ăn mòn',
    Unit: 'kg',
  },
  {
    HsCode: '38119090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3812',
    Description:
      'Hỗn hợp xúc tiến lưu hóa cao su đã điều chế; các hợp chất hóa dẻo cao su hoặc plastic, chưa được chi tiết hoặc ghi ở nơi khác; các chế phẩm chống oxy hóa và các hợp chất khác làm ổn định cao su hoặc plastic',
    Unit: '',
  },
  {
    HsCode: '38121000',
    Description: 'Hỗn hợp xúc tiến lưu hóa cao su đã điều chế',
    Unit: 'kg',
  },
  {
    HsCode: '38122000',
    Description: 'Hợp chất hóa dẻo dùng cho cao su hoặc plastic',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các chế phẩm chống oxy hóa và các hợp chất khác làm ổn định cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '38123100',
    Description: 'Hỗn hợp oligome của 2,2,4-trimethyl-1,2-dihydroquinoline (TMQ)',
    Unit: 'kg',
  },
  {
    HsCode: '38123900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38130000',
    Description: 'Các chế phẩm và các vật liệu nạp cho bình dập lửa; lựu đạn dập lửa đã nạp',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38140000',
    Description:
      'Hỗn hợp dung môi hữu cơ và các chất pha loãng, chưa được chi tiết hoặc ghi ở nơi khác; các chất tẩy sơn hoặc tẩy vecni đã pha chế',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3815',
    Description:
      'Các chất khơi mào phản ứng, các chất xúc tiến phản ứng và các chế phẩm xúc tác, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chất xúc tác có nền:',
    Unit: '',
  },
  {
    HsCode: '38151100',
    Description: 'Chứa niken hoặc hợp chất niken như chất hoạt tính',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38151200',
    Description: 'Chứa kim loại quý hoặc hợp chất kim loại quý như chất hoạt tính',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38151900',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38159000',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3816',
    Description:
      'Xi măng, vữa, bê tông chịu lửa và các loại vật liệu kết cấu tương tự, kể cả hỗn hợp dolomite ramming, trừ các sản phẩm thuộc nhóm 38.01',
    Unit: '',
  },
  {
    HsCode: '38160010',
    Description: 'Xi măng chịu lửa',
    Unit: 'kg',
  },
  {
    HsCode: '38160020',
    Description: 'Hỗn hợp dolomite ramming',
    Unit: 'kg',
  },
  {
    HsCode: '38160090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38170000',
    Description:
      'Các loại alkylbenzen hỗn hợp và các loại alkylnaphthalene hỗn hợp, trừ các chất thuộc nhóm 27.07 hoặc nhóm 29.02',
    Unit: 'kg',
  },
  {
    HsCode: '38180000',
    Description:
      'Các nguyên tố hóa học đã được kích tạp dùng trong điện tử, ở dạng đĩa, tấm mỏng hoặc các dạng tương tự; các hợp chất hóa học đã được kích tạp dùng trong điện tử',
    Unit: 'kg',
  },
  {
    HsCode: '38190000',
    Description:
      'Chất lỏng dùng trong bộ hãm thủy lực và các chất lỏng đã được điều chế khác dùng cho sự truyền động thủy lực, không chứa hoặc chứa dưới 70% tính theo trọng lượng dầu có nguồn gốc từ dầu mỏ hoặc các loại dầu thu được từ khoáng bi-tum',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38200000',
    Description: 'Chế phẩm chống đông và chất lỏng khử đóng băng đã điều chế',
    Unit: 'kg',
  },
  {
    HsCode: '3821',
    Description:
      'Môi trường nuôi cấy đã điều chế để phát triển hoặc nuôi các vi sinh vật (kể cả các virút và các loại tương tự) hoặc tế bào của thực vật, người hoặc động vật',
    Unit: '',
  },
  {
    HsCode: '38210010',
    Description: 'Môi trường nuôi cấy đã điều chế để phát triển các vi sinh vật',
    Unit: 'kg',
  },
  {
    HsCode: '38210090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3822',
    Description:
      'Chất thử chẩn đoán bệnh hoặc chất thử thí nghiệm có lớp bồi, chất thử chẩn đoán hoặc chất thử thí nghiệm được điều chế có hoặc không có lớp bồi, có hoặc không đóng gói ở dạng bộ, trừ loại thuộc nhóm 30.06; các mẫu chuẩn được chứng nhận (1)',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Chất thử chẩn đoán bệnh hoặc chất thử thí nghiệm có lớp bồi, chất thử chẩn đoán hoặc chất thử thí nghiệm được điều chế có hoặc không có lớp bồi, có hoặc không đóng gói ở dạng bộ:',
    Unit: '',
  },
  {
    HsCode: '38221100',
    Description: 'Cho bệnh sốt rét',
    Unit: 'kg',
  },
  {
    HsCode: '38221200',
    Description: 'Cho Zika và các bệnh khác truyền qua muỗi thuộc chi Aedes',
    Unit: 'kg',
  },
  {
    HsCode: '38221300',
    Description: 'Để thử nhóm máu',
    Unit: 'kg',
  },
  {
    HsCode: '38221900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '382290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38229010',
    Description: 'Dải và băng chỉ thị màu dùng cho nồi hấp khử trùng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38229090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3823',
    Description: 'Axit béo monocarboxylic công nghiệp; dầu axit từ quá trình tinh lọc; cồn béo công nghiệp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Axit béo monocarboxylic công nghiệp; dầu axit từ quá trình tinh lọc:',
    Unit: '',
  },
  {
    HsCode: '38231100',
    Description: 'Axit stearic',
    Unit: 'kg',
  },
  {
    HsCode: '38231200',
    Description: 'Axit oleic',
    Unit: 'kg',
  },
  {
    HsCode: '38231300',
    Description: 'Axit béo dầu tall',
    Unit: 'kg',
  },
  {
    HsCode: '382319',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dầu axit từ quá trình tinh lọc:',
    Unit: '',
  },
  {
    HsCode: '38231911',
    Description: 'Dầu axit dừa (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38231919',
    Description: 'Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38231920',
    Description: 'Axit béo chưng cất từ cọ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38231930',
    Description: 'Axit béo chưng cất từ nhân hạt cọ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38231990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '382370',
    Description: 'Cồn béo công nghiệp:',
    Unit: '',
  },
  {
    HsCode: '38237010',
    Description: 'Dạng sáp ',
    Unit: 'kg',
  },
  {
    HsCode: '38237090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3824',
    Description:
      'Chất gắn đã điều chế dùng cho các loại khuôn đúc hoặc lõi đúc; các sản phẩm và chế phẩm hóa học của ngành công nghiệp hóa chất hoặc các ngành công nghiệp có liên quan (kể cả các sản phẩm và chế phẩm chứa hỗn hợp các sản phẩm tự nhiên), chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '38241000',
    Description: 'Các chất gắn đã điều chế dùng cho khuôn đúc hoặc lõi đúc',
    Unit: 'kg',
  },
  {
    HsCode: '38243000',
    Description: 'Carbua kim loại không kết tụ trộn với nhau hoặc trộn với các chất gắn kim loại',
    Unit: 'kg',
  },
  {
    HsCode: '38244000',
    Description: 'Phụ gia đã điều chế dùng cho xi măng, vữa hoặc bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '38245000',
    Description: 'Vữa và bê tông không chịu lửa',
    Unit: 'kg',
  },
  {
    HsCode: '38246000',
    Description: 'Sorbitol trừ loại thuộc phân nhóm 2905.44',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Hàng hóa đã nêu trong Chú giải phân nhóm 3 của Chương này:',
    Unit: '',
  },
  {
    HsCode: '38248100',
    Description: '- Chứa oxirane (ethylene oxide)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38248200',
    Description:
      '- Chứa polychlorinated biphenyls (PCBs), polychlorinated terphenyls (PCTs) hoặc polybrominated biphenyls (PBBs)',
    Unit: 'kg',
  },
  {
    HsCode: '38248300',
    Description: '- Chứa tris(2,3-dibromopropyl) phosphate',
    Unit: 'kg',
  },
  {
    HsCode: '38248400',
    Description:
      'Chứa aldrin (ISO), camphechlor (ISO) (toxaphene), chlordane (ISO), chlordecone (ISO), DDT (ISO) (clofenotane (INN), 1,1,1-trichloro-2,2-bis(p-chlorophenyl)ethane), dieldrin (ISO, INN), endosulfan (ISO), endrin (ISO), heptachlor (ISO) hoặc mirex (ISO))',
    Unit: 'kg',
  },
  {
    HsCode: '38248500',
    Description: 'Chứa 1,2,3,4,5,6-hexachlorocyclohexane (HCH (ISO)), kể cả lindane (ISO, INN)',
    Unit: 'kg',
  },
  {
    HsCode: '38248600',
    Description: 'Chứa pentachlorobenzene (ISO) hoặc hexachlorobenzene (ISO)',
    Unit: 'kg',
  },
  {
    HsCode: '38248700',
    Description:
      'Chứa perfluorooctane sulphonic axit, muối của nó, perfluorooctane sulphonamides, hoặc perfluorooctane sulphonyl fluoride',
    Unit: 'kg',
  },
  {
    HsCode: '38248800',
    Description: 'Chứa tetra-, penta-, hexa-, heptahoặc octabromodiphenyl ethers',
    Unit: 'kg',
  },
  {
    HsCode: '38248900',
    Description: 'Chứa các paraffin đã clo hóa mạch ngắn',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38249100',
    Description:
      'Hỗn hợp và chế phẩm chủ yếu chứa (5-ethyl-2methyl-2-oxido-1,3,2-dioxaphosphinan-5-yl)methyl methyl methylphosphonate và bis[(5-ethyl-2-methyl-2-oxido-1,3,2dioxaphosphinan-5-yl)methyl] methylphosphonate',
    Unit: 'kg',
  },
  {
    HsCode: '38249200',
    Description: 'Các este polyglycol của axit methylphosphonic',
    Unit: 'kg',
  },
  {
    HsCode: '382499',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38249910',
    Description:
      'Các chất tẩy mực, chất sửa giấy nến (stencil correctors), chất lỏng dùng để xóa và các băng để xóa khác (trừ những hàng hóa thuộc nhóm 96.12), đã đóng gói để bán lẻ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '38249930',
    Description:
      'Bột nhão để sao in với thành phần cơ bản là gelatin, ở dạng rời hoặc dùng ngay (ví dụ, bồi trên giấy hoặc trên vật liệu dệt)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '38249940',
    Description: 'Hỗn hợp dung môi vô cơ (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38249950',
    Description: 'Dầu acetone (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38249960',
    Description: 'Các chế phẩm hóa chất có chứa bột ngọt (monosodium glutamate)',
    Unit: 'kg',
  },
  {
    HsCode: '38249970',
    Description: 'Các chế phẩm hóa chất khác, dùng trong chế biến thực phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38249991',
    Description: 'Naphthenic axit, muối không tan trong nước của chúng và este của chúng',
    Unit: 'kg',
  },
  {
    HsCode: '38249999',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3825',
    Description:
      'Các sản phẩm còn lại của ngành công nghiệp hóa chất hoặc các ngành công nghiệp có liên quan, chưa được chi tiết hoặc ghi ở nơi khác; rác thải đô thị; bùn cặn của nước thải; các chất thải khác được nêu ở Chú giải 6 của Chương này',
    Unit: '',
  },
  {
    HsCode: '38251000',
    Description: 'Rác thải đô thị',
    Unit: 'kg',
  },
  {
    HsCode: '38252000',
    Description: 'Bùn cặn của nước thải',
    Unit: 'kg',
  },
  {
    HsCode: '382530',
    Description: 'Rác thải bệnh viện:',
    Unit: '',
  },
  {
    HsCode: '38253010',
    Description: 'Bơm tiêm, kim tiêm, ống dẫn lưu và các loại tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '38253090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Dung môi hữu cơ thải:',
    Unit: '',
  },
  {
    HsCode: '38254100',
    Description: 'Đã halogen hóa',
    Unit: 'kg',
  },
  {
    HsCode: '38254900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38255000',
    Description:
      'Chất thải từ dung dịch tẩy kim loại, chất lỏng thủy lực, chất lỏng dùng cho phanh và chất lỏng chống đông',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Chất thải khác từ ngành công nghiệp hóa chất hoặc các ngành công nghiệp có liên quan:',
    Unit: '',
  },
  {
    HsCode: '38256100',
    Description: 'Chủ yếu chứa các hợp chất hữu cơ',
    Unit: 'kg',
  },
  {
    HsCode: '38256900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38259000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3826',
    Description:
      'Diesel sinh học và hỗn hợp của chúng, không chứa hoặc chứa dưới 70% trọng lượng là dầu có nguồn gốc từ dầu mỏ hoặc dầu thu được từ các khoáng bi-tum',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Diesel sinh học, không chứa dầu có nguồn gốc từ dầu mỏ:',
    Unit: '',
  },
  {
    HsCode: '38260010',
    Description: 'Este metyl từ dừa (CME) (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'Este metyl từ cọ (kể cả methyl este từ hạt cọ):',
    Unit: '',
  },
  {
    HsCode: '38260021',
    Description: 'Có hàm lượng este metyl từ 96,5% trở lên nhưng không quá 98% (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38260022',
    Description: 'Có hàm lượng este metyl trên 98% (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38260029',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38260030',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '38260090',
    Description: 'Loại khác',
    Unit: 'kg/lít',
  },
  {
    HsCode: '3827',
    Description:
      'Các hỗn hợp chứa các dẫn xuất đã halogen hóa của metan, etan hoặc propan, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Chứa chlorofluorocarbons (CFCs), chứa hoặc không chứa hydrochlorofluorocarbons (HCFCs), perfluorocarbons (PFCs) hoặc hydrofluorocarbons (HFCs); chứa hydrobromofluorocarbons (HBFCs); chứa carbon tetrachloride; chứa 1,1,1-trichloroethane (methyl chloroform):',
    Unit: '',
  },
  {
    HsCode: '382711',
    Description:
      'Chứa chlorofluorocarbons (CFCs), chứa hoặc không chứa hydrochlorofluorocarbons (HCFCs), perfluorocarbons (PFCs) hoặc hydrofluorocarbons (HFCs):',
    Unit: '',
  },
  {
    HsCode: '38271110',
    Description:
      'Dầu dùng cho máy biến điện (máy biến áp và máy biến dòng) và bộ phận ngắt mạch, có hàm lượng nhỏ hơn 70% tính theo trọng lượng là dầu có nguồn gốc từ dầu mỏ hoặc các loại dầu thu được từ các khoáng bi-tum (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38271190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38271200',
    Description: 'Chứa hydrobromofluorocarbons (HBFCs)',
    Unit: 'kg',
  },
  {
    HsCode: '38271300',
    Description: 'Chứa carbon tetrachloride',
    Unit: 'kg',
  },
  {
    HsCode: '38271400',
    Description: 'Chứa 1,1,1-trichloroethane (methyl chloroform)',
    Unit: 'kg',
  },
  {
    HsCode: '38272000',
    Description:
      'Chứa bromochlorodifluoromethane (Halon-1211), bromotrifluoromethane (Halon-1301) hoặc dibromotetrafluoroethanes (Halon-2402)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Chứa hydrochlorofluorocarbons (HCFCs), chứa hoặc không chứa perfluorocarbons (PFCs) hoặc hydrofluorocarbons (HFCs), nhưng không chứa chlorofluorocarbons (CFCs):',
    Unit: '',
  },
  {
    HsCode: '38273100',
    Description: 'Chứa các chất của các phân nhóm từ 2903.41 đến 2903.48',
    Unit: 'kg',
  },
  {
    HsCode: '38273200',
    Description: 'Loại khác, chứa các chất của các phân nhóm từ 2903.71 đến 2903.75',
    Unit: 'kg',
  },
  {
    HsCode: '382739',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '38273910',
    Description:
      'Dầu dùng cho máy biến điện (máy biến áp và máy biến dòng) và bộ phận ngắt mạch, có hàm lượng nhỏ hơn 70% tính theo trọng lượng là dầu có nguồn gốc từ dầu mỏ hoặc các loại dầu thu được từ các khoáng bi-tum (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '38273990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38274000',
    Description: 'Chứa methyl bromide (bromomethane) hoặc bromochloromethane',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Chứa trifluoromethane (HFC-23) hoặc perfluorocarbons (PFCs) nhưng không chứa chlorofluorocarbons (CFCs) hoặc hydrochlorofluorocarbons (HCFCs):',
    Unit: '',
  },
  {
    HsCode: '38275100',
    Description: 'Chứa trifluoromethane (HFC-23)',
    Unit: 'kg',
  },
  {
    HsCode: '38275900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      'Chứa các hydrofluorocarbons (HFCs) khác nhưng không chứa chlorofluorocarbons (CFCs) hoặc hydrochlorofluorocarbons (HCFCs):',
    Unit: '',
  },
  {
    HsCode: '382761',
    Description: 'Chứa từ 15% trở lên tính theo khối lượng là 1,1,1-trifluoroethane (HFC-143a):',
    Unit: '',
  },
  {
    HsCode: '38276110',
    Description: 'Chứa hỗn hợp của HFC-125, HFC-143a và HFC-134a (HFC-404a)',
    Unit: 'kg',
  },
  {
    HsCode: '38276120',
    Description: 'Chứa hỗn hợp của HFC-125 và HFC-143a (HFC-507a)',
    Unit: 'kg',
  },
  {
    HsCode: '38276190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38276200',
    Description:
      'Loại khác, chưa được chi tiết tại phân nhóm trên, chứa từ 55% trở lên tính theo khối lượng là pentafluoroethane (HFC-125) nhưng không chứa dẫn xuất flo hóa chưa no của các hydrocarbons mạch hở (HFOs)',
    Unit: 'kg',
  },
  {
    HsCode: '382763',
    Description:
      'Loại khác, chưa được chi tiết tại các phân nhóm trên, chứa từ 40% trở lên tính theo khối lượng là pentafluoroethane (HFC-125):',
    Unit: '',
  },
  {
    HsCode: '38276310',
    Description: 'Chứa hỗn hợp của HFC-32 và HFC-125 (HFC-410a)',
    Unit: 'kg',
  },
  {
    HsCode: '38276390',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38276400',
    Description:
      'Loại khác, chưa được chi tiết tại các phân nhóm trên, chứa từ 30% trở lên tính theo khối lượng là 1,1,1,2-tetrafluoroethane (HFC-134a) nhưng không chứa dẫn xuất flo hóa chưa no của các hydrocarbons mạch hở (HFOs)',
    Unit: 'kg',
  },
  {
    HsCode: '38276500',
    Description:
      'Loại khác, chưa được chi tiết tại các phân nhóm trên, chứa từ 20% trở lên tính theo khối lượng là difluoromethane (HFC-32) và từ 20% trở lên tính theo khối lượng là pentafluoroethane (HFC-125)',
    Unit: 'kg',
  },
  {
    HsCode: '38276800',
    Description:
      'Loại khác, chưa được chi tiết tại các phân nhóm trên, chứa các chất thuộc các phân nhóm từ 2903.41 đến 2903.48',
    Unit: 'kg',
  },
  {
    HsCode: '38276900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '38279000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 8890:2017',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN VII',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PLASTIC VÀ CÁC SẢN PHẨM BẰNG PLASTIC; CAO SU VÀ CÁC SẢN PHẨM BẰNG CAO SU',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Các mặt hàng xếp thành bộ từ hai hoặc nhiều phần cấu thành riêng biệt, trong đó một số hoặc tất cả các phần cấu thành của các mặt hàng nằm trong Phần này và được nhằm trộn với nhau để tạo ra một sản phẩm của Phần VI hoặc VII, phải được xếp vào nhóm phù hợp với sản phẩm đó, với điều kiện các phần cấu thành đó thỏa mãn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) theo cách thức đóng gói của chúng cho thấy rõ ràng là chúng được sử dụng cùng nhau mà không cần phải đóng gói lại;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) được trình bày cùng nhau; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) có thể nhận biết là chúng nhằm bổ sung cho nhau, thông qua bản chất hoặc tỷ lệ tương ứng của chúng trong sản phẩm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trừ những mặt hàng trong nhóm 39.18 hoặc 39.19, plastic, cao su và các sản phẩm của chúng, đã in hoa văn, các ký tự hoặc biểu tượng tranh ảnh, mà không chỉ đơn thuần là phụ trợ cho công dụng chính của sản phẩm đó, được xếp vào Chương 49.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 39',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Plastic và các sản phẩm bằng plastic',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong toàn bộ Danh mục khái niệm “plastic” có nghĩa là các loại nguyên liệu thuộc các nhóm 39.01 đến 39.14 có khả năng tạo thành hình dạng dưới tác động bên ngoài (thường là nhiệt độ và áp suất, nếu cần có thể thêm dung môi hoặc chất hóa dẻo) tại thời điểm polyme hóa hoặc tại các giai đoạn tiếp theo bằng phương pháp đúc, đổ khuôn, đùn, cán hoặc các quá trình tạo hình khác và giữ nguyên hình dạng khi không còn tác động bên ngoài.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' Trong toàn bộ Danh mục, bất kỳ nội dung nào liên quan đến “plastic” cũng bao gồm sợi lưu hóa. Tuy nhiên, thuật ngữ này không áp dụng đối với các vật liệu được coi là vật liệu dệt thuộc Phần XI.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các chế phẩm bôi trơn của nhóm 27.10 hoặc 34.03;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các loại sáp thuộc nhóm 27.12 hoặc 34.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các hợp chất hữu cơ đã được xác định về mặt hóa học riêng biệt (Chương 29);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Heparin hoặc các muối của nó (nhóm 30.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Các dung dịch (trừ các dung dịch keo) gồm các sản phẩm bất kỳ được mô tả trong các nhóm 39.01 đến 39.13 trong các dung môi hữu cơ dễ bay hơi khi trọng lượng dung môi trên 50% tính theo trọng lượng dung dịch (nhóm 32.08); lá phôi dập thuộc nhóm 32.12;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các chất hoặc các chế phẩm hữu cơ hoạt động bề mặt thuộc nhóm 34.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Gôm nấu chảy hoặc gôm este (nhóm 38.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Các chất phụ gia đã điều chế dùng cho dầu khoáng (kể cả xăng) hoặc cho các loại chất lỏng khác sử dụng như dầu khoáng (nhóm 38.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Các chất lỏng thủy lực đã được điều chế từ các polyglycol, silicon hoặc polyme khác thuộc Chương 39 (nhóm 38.19);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Chất thử chẩn đoán bệnh hoặc chất thử thí nghiệm có lớp bồi bằng plastic (nhóm 38.22);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Cao su tổng hợp, như định nghĩa của Chương 40, hoặc các sản phẩm của chúng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(m) Yên cương hoặc bộ đồ yên cương (nhóm 42.01) hoặc các loại hòm, vali, túi xách tay hoặc các vật đựng khác thuộc nhóm 42.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Dây tết bện, các sản phẩm bằng liễu gai hoặc các sản phẩm khác thuộc Chương 46;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(o) Các loại tấm phủ tường thuộc nhóm 48.14;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(p) Các mặt hàng thuộc Phần XI (nguyên liệu dệt và các sản phẩm dệt);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(q) Các mặt hàng thuộc Phần XII (ví dụ, hàng giày dép, mũ và các vật đội đầu khác, dù, ô che nắng, ba toong, roi da, roi điều khiển súc vật kéo hoặc các bộ phận của các mặt hàng trên);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(r) Đồ trang sức làm bằng chất liệu khác thuộc nhóm 71.17;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(s) Các mặt hàng thuộc Phần XVI (máy và các thiết bị cơ khí hoặc điện);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(t) Phụ tùng phương tiện bay hoặc xe thuộc Phần XVII;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(u) Các mặt hàng thuộc Chương 90 (ví dụ, các bộ phận quang học, gọng kính đeo, dụng cụ vẽ);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(v) Các mặt hàng thuộc Chương 91 (ví dụ, vỏ đồng hồ thời gian hoặc vỏ đồng hồ cá nhân);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(w) Các mặt hàng thuộc Chương 92 (ví dụ, nhạc cụ hoặc các bộ phận của nhạc cụ);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(x) Các mặt hàng thuộc Chương 94 (ví dụ, đồ nội thất, đèn (luminaires) và bộ đèn, biển hiệu được chiếu sáng, nhà lắp ghép);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(y) Các mặt hàng thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(z) Các mặt hàng thuộc Chương 96 (ví dụ, bàn chải, khuy, khóa kéo, lược, ống tẩu hút thuốc lá, đót thuốc lá hoặc các loại tương tự, bộ phận bình chân không hoặc các loại tương tự, bút, bút chì bấm, và chân đế loại một chân (monopods), hai chân (bipods), ba chân (tripods) và các sản phẩm tương tự).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các nhóm 39.01 đến 39.11 chỉ áp dụng đối với các mặt hàng được sản xuất bằng phương pháp tổng hợp hóa học, thuộc các loại sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các loại polyolefin tổng hợp dạng lỏng có hàm lượng dưới 60% tính theo thể tích cất, thu được bằng phương pháp chưng cất giảm áp ở nhiệt độ 300oC, áp suất 1.013 milibar (các nhóm 39.01 và 39.02);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các loại nhựa, chưa được polyme hóa ở mức độ cao, thuộc loại comarone-indene (nhóm 39.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các loại polyme tổng hợp khác trung bình có tối thiểu 5 đơn vị monome trong mạch;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các silicon (nhóm 39.10);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các resol (nhóm 39.09) và các tiền polyme khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Thuật ngữ “copolyme” dùng để chỉ tất cả các loại polyme trong thành phần của nó không chứa loại monome đơn nào chiếm từ 95% trở lên tính theo trọng lượng của tổng hàm lượng polyme. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Theo mục đích của Chương này, trừ khi có yêu cầu khác, các copolyme (kể cả các copolyme ngưng tụ, các sản phẩm copolyme cộng hợp, các copolyme khối và các copolyme ghép) và các hỗn hợp polyme pha trộn được xếp vào nhóm các loại polyme của đơn vị comonome nào chiếm tỷ trọng cao hơn các đơn vị comonome đơn khác. Theo mục đích của Chú giải này, các đơn vị comonome cấu thành các polyme thuộc cùng nhóm sẽ cùng được xem xét.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nếu không có loại comonome đơn nào chiếm tỷ trọng cao hơn, các copolyme hoặc các hỗn hợp polyme pha trộn trong trường hợp này sẽ được xếp vào nhóm cuối cùng theo số thứ tự trong các nhóm tương đương được xem xét.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Các loại polyme đã biến đổi về mặt hóa học, đó là những polyme mà chỉ có phần thêm vào trong mạch chính polyme bị biến đổi do phản ứng hóa học, chúng được xếp vào nhóm cùng với các loại polyme chưa biến đổi. Quy định này không áp dụng đối với các copolyme ghép.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '6. Trong các nhóm từ 39.01 đến 39.14, thuật ngữ “dạng nguyên sinh” chỉ áp dụng với những dạng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Dạng lỏng và dạng nhão, kể cả dạng phân tán (dạng nhũ tương và huyền phù) và dạng hòa tan;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các khối với hình dạng không đều, cục, bột (kể cả bột đúc khuôn), hạt, mảnh và các dạng khối tương tự.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Nhóm 39.15 không áp dụng đối với các loại phế liệu, phế thải và mảnh vụn của một loại vật liệu nhựa nhiệt dẻo đơn, đã được chuyển sang dạng nguyên sinh (các nhóm từ 39.01 đến 39.14).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Theo mục đích của nhóm 39.17, thuật ngữ “ống, ống dẫn và ống vòi” dùng để chỉ các sản phẩm rỗng nòng ở dạng bán thành phẩm hoặc thành phẩm, loại thường dùng để vận chuyển, dẫn hoặc phân phối khí hoặc chất lỏng (ví dụ, ống vòi có gân tưới trong vườn, ống đục lỗ). Thuật ngữ này cũng bao gồm các vỏ xúc xích và các loại ống cuộn phẳng dẹt (lay-flat tubing) khác. Tuy nhiên, trừ những loại nêu trong phần cuối này, những sản phẩm có mặt cắt trong không phải hình tròn, ô van, chữ nhật (chiều dài không quá 1,5 lần chiều rộng) hoặc dạng hình đa giác đều sẽ không được xem là ống, ống dẫn và ống vòi, mà phải xem như là ở dạng hình. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Theo mục đích của nhóm 39.18, thuật ngữ “tấm phủ tường hoặc phủ trần bằng plastic” áp dụng đối với các sản phẩm ở dạng cuộn, có chiều rộng từ 45 cm trở lên, thích hợp cho việc trang trí tường hoặc trần, gồm plastic được gắn cố định trên lớp nền bằng vật liệu bất kỳ trừ giấy, lớp plastic (trên bề mặt) được sơn giả vân, rập nổi, tạo màu, in các mẫu thiết kế hoặc được trang trí bằng cách khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '10. Trong các nhóm 39.20 và 39.21, thuật ngữ “tấm, phiến, màng, lá và dải"" chỉ áp dụng cho các loại tấm, phiến, màng, lá và dải (trừ loại thuộc Chương 54) và cho các dạng khối hình học đều, đã hoặc chưa in hoặc được gia công bề mặt bằng cách khác, chưa cắt hoặc được cắt thành hình chữ nhật (kể cả hình vuông) nhưng chưa được gia công thêm (thậm chí khi cắt như vậy chúng trở thành các sản phẩm có thể dùng ngay được).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '11. Nhóm 39.25 chỉ áp dụng đối với các sản phẩm sau đây, những sản phẩm không thuộc các nhóm trước của phân Chương II:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các loại thùng, bể (kể cả bể tự hoại), vại và các loại đồ chứa tương tự, có dung tích trên 300 lít;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các cấu kiện dùng trong xây dựng, ví dụ, làm sàn, tường hoặc vách ngăn, trần hoặc mái nhà;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Ống máng và các phụ kiện của chúng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Cửa ra vào, cửa sổ và bộ khung của chúng và ngưỡng cửa ra vào;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Ban công, lan can, hàng rào, cổng và các loại hàng rào chắn tương tự;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Cửa chớp, rèm che (kể cả mành chớp lật) và các sản phẩm tương tự và các bộ phận và phụ kiện của chúng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Các giá, kệ có kích thước lớn để lắp ráp và lắp đặt cố định, ví dụ, lắp trong cửa hiệu, công xưởng, nhà kho;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Các cấu kiện kiến trúc trang trí, ví dụ, nếp máng cong, vòm nhà, chuồng chim câu; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Các phụ kiện và khung giá để lắp cố định ở trong hoặc trên cửa ra vào, cửa sổ, cầu thang, tường hoặc các bộ phận khác của nhà, ví dụ, tay cầm, quả đấm, bản lề cửa, công-xon, tay vịn lan can, thanh vắt khăn mặt, tấm bảo vệ công tắc và các loại tấm bảo vệ khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong bất kỳ nhóm nào thuộc Chương này, các polyme (kể cả các copolyme) và các polyme đã biến đổi về mặt hóa học được phân loại theo các nguyên tắc sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Khi có phân nhóm “Loại khác” trong các phân nhóm cùng cấp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(1) Việc gọi tên một polyme trong 1 phân nhóm với tiếp đầu ngữ “poly” (ví dụ, polyetylen và polyamit -6,6) nghĩa là đơn vị monome cấu thành hoặc các đơn vị monome của polyme đã được gọi tên phải chiếm 95% trở lên tính theo trọng lượng trong tổng thành phần polyme.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) Các copolyme được gọi tên trong các phân nhóm 3901.30, 3901.40, 3903.20, 3903.30 và 3904.30 đuợc phân loại vào các phân nhóm đó, với điều kiện là các đơn vị comonome của copolyme được gọi tên chiếm 95% trở lên tính theo trọng lượng trong tổng thành phần polyme.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(3) Các polyme đã biến đổi hóa học được xếp vào phân nhóm “Loại khác”, với điều kiện các polyme đã biến đổi hóa học không được mô tả chi tiết hơn trong một phân nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(4) Các polyme không thỏa mãn các điều kiện (1), (2) hoặc (3) ở trên, sẽ được xếp vào phân nhóm, trong số phân nhóm còn lại của nhóm, bao gồm các polyme của đơn vị monome có trọng lượng cao hơn tất cả các đơn vị comonome đơn khác. Do đó, các đơn vị monome cấu thành các polyme thuộc cùng một phân nhóm sẽ được xem xét cùng nhau. Chỉ có các đơn vị comonome cấu thành các polyme trong các phân nhóm cùng cấp/trong cấp độ các phân nhóm đang xem xét mới được so sánh.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Khi không có phân nhóm “Loại khác” trong các phân nhóm cùng cấp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(1) Các polyme được phân loại vào phân nhóm bao gồm các polyme của đơn vị monome có trọng lượng cao hơn tất cả các đơn vị comonome đơn khác. Do đó, các đơn vị monome cấu thành polyme thuộc cùng một phân nhóm cũng sẽ được xem xét cùng nhau. Chỉ có các đơn vị comonome cấu thành các polyme trong các nhóm được xem xét mới được so sánh.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(2) Các polyme đã biến đổi hóa học được phân loại vào phân nhóm tương ứng với polyme chưa biến đổi.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' Các hỗn hợp polyme pha trộn được phân loại vào cùng một phân nhóm như các polyme của cùng các đơn vị monome theo các tỷ lệ giống nhau.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Theo mục đích của phân nhóm 3920.43, thuật ngữ “chất hóa dẻo” kể cả chất hóa dẻo thứ cấp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG I',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'DẠNG NGUYÊN SINH',
    Unit: '',
  },
  {
    HsCode: '3901',
    Description: 'Các polyme từ etylen, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '390110',
    Description: 'Polyetylen có trọng lượng riêng dưới 0,94:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dạng lỏng hoặc dạng nhão:',
    Unit: '',
  },
  {
    HsCode: '39011012',
    Description: 'Polyetylen chứa các monomer alpha-olefin từ 5% trở xuống (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39011019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39011092',
    Description: 'Polyetylen chứa các monomer alpha-olefin từ 5% trở xuống (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39011099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39012000',
    Description: 'Polyetylen có trọng lượng riêng từ 0,94 trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '39013000',
    Description: 'Các copolyme etylen-vinyl axetat',
    Unit: 'kg',
  },
  {
    HsCode: '39014000',
    Description: 'Các copolyme etylene-alpha-olefin, có trọng lượng riêng dưới 0,94',
    Unit: 'kg',
  },
  {
    HsCode: '390190',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39019040',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39019090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3902',
    Description: 'Các polyme từ propylen hoặc từ các olefin khác, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '390210',
    Description: 'Polypropylen:',
    Unit: '',
  },
  {
    HsCode: '39021030',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39021040',
    Description: 'Dạng hạt, viên, hạt cườm, vẩy, mảnh và các dạng tương tự (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39021090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39022000',
    Description: 'Polyisobutylen',
    Unit: 'kg',
  },
  {
    HsCode: '390230',
    Description: 'Các copolyme propylen:',
    Unit: '',
  },
  {
    HsCode: '39023030',
    Description: 'Dạng lỏng hoặc dạng nhão',
    Unit: 'kg',
  },
  {
    HsCode: '39023090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39029010',
    Description: 'Polypropylen đã clo hóa dùng để sản xuất mực in(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39029090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3903',
    Description: 'Các polyme từ styren, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Polystyren:',
    Unit: '',
  },
  {
    HsCode: '390311',
    Description: 'Loại giãn nở được:',
    Unit: '',
  },
  {
    HsCode: '39031110',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39031190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390319',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39031910',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39031920',
    Description: 'Dạng hạt, viên, hạt cườm, vẩy, mảnh và các dạng tương tự (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39031990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390320',
    Description: 'Các copolyme styren-acrylonitril (SAN):',
    Unit: '',
  },
  {
    HsCode: '39032040',
    Description: 'Dạng phân tán trong môi trường nước',
    Unit: 'kg',
  },
  {
    HsCode: '39032050',
    Description: 'Dạng phân tán trong môi trường không chứa nước',
    Unit: 'kg',
  },
  {
    HsCode: '39032090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390330',
    Description: 'Các copolyme acrylonitril-butadien-styren (ABS):',
    Unit: '',
  },
  {
    HsCode: '39033040',
    Description: 'Dạng phân tán trong môi trường nước',
    Unit: 'kg',
  },
  {
    HsCode: '39033050',
    Description: 'Dạng phân tán trong môi trường không chứa nước',
    Unit: 'kg',
  },
  {
    HsCode: '39033060',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39033090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39039030',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39039091',
    Description: 'Các polyme từ styren chịu lực, có độ bền va đập bằng phương pháp Izod nhỏ hơn 80 J/m ở 23oC (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39039099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3904',
    Description: 'Các polyme từ vinyl clorua hoặc từ các olefin đã halogen hóa khác, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '390410',
    Description: 'Poly (vinyl clorua), chưa pha trộn với bất kỳ chất nào khác:',
    Unit: '',
  },
  {
    HsCode: '39041010',
    Description: 'Các polyme đồng nhất, sản xuất theo công nghệ huyền phù',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39041091',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39041092',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39041099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Poly (vinyl clorua) khác:',
    Unit: '',
  },
  {
    HsCode: '390421',
    Description: 'Chưa hóa dẻo:',
    Unit: '',
  },
  {
    HsCode: '39042110',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39042120',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39042190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390422',
    Description: 'Đã hóa dẻo:',
    Unit: '',
  },
  {
    HsCode: '39042210',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39042220',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39042230',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39042290',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390430',
    Description: 'Các copolyme vinyl clorua-vinyl axetat: ',
    Unit: '',
  },
  {
    HsCode: '39043010',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39043020',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39043090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390440',
    Description: 'Các copolyme vinyl clorua khác:',
    Unit: '',
  },
  {
    HsCode: '39044010',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39044020',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39044090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390450',
    Description: 'Các polyme vinyliden clorua:',
    Unit: '',
  },
  {
    HsCode: '39045040',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39045050',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39045060',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39045090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các floro-polyme:',
    Unit: '',
  },
  {
    HsCode: '390461',
    Description: 'Polytetrafloroetylen:',
    Unit: '',
  },
  {
    HsCode: '39046110',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39046120',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39046190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390469',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39046930',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39046940',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39046950',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39046990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390490',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39049030',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39049040',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39049050',
    Description: 'Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '39049090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3905',
    Description:
      'Các polyme từ vinyl axetat hoặc từ các vinyl este khác, dạng nguyên sinh; các polyme vinyl khác ở dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Poly (vinyl axetat):',
    Unit: '',
  },
  {
    HsCode: '39051200',
    Description: 'Dạng phân tán trong môi trường nước',
    Unit: 'kg',
  },
  {
    HsCode: '390519',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39051910',
    Description: 'Dạng lỏng hoặc dạng nhão',
    Unit: 'kg',
  },
  {
    HsCode: '39051990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các copolyme vinyl axetat:',
    Unit: '',
  },
  {
    HsCode: '39052100',
    Description: 'Dạng phân tán trong môi trường nước',
    Unit: 'kg',
  },
  {
    HsCode: '39052900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390530',
    Description: 'Poly (vinyl alcohol), có hoặc không chứa các nhóm axetat chưa thủy phân:',
    Unit: '',
  },
  {
    HsCode: '39053010',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39053090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '390591',
    Description: 'Các copolyme:',
    Unit: '',
  },
  {
    HsCode: '39059110',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39059190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390599',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39059910',
    Description: 'Dạng phân tán trong môi trường nước',
    Unit: 'kg',
  },
  {
    HsCode: '39059920',
    Description: 'Dạng phân tán trong môi trường không chứa nước',
    Unit: 'kg',
  },
  {
    HsCode: '39059990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3906',
    Description: 'Các polyme acrylic dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '390610',
    Description: 'Poly (metyl metacrylat):',
    Unit: '',
  },
  {
    HsCode: '39061010',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39061090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390690',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39069020',
    Description: 'Dạng phân tán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39069092',
    Description: 'Natri polyacrylat',
    Unit: 'kg',
  },
  {
    HsCode: '39069099',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3907',
    Description:
      'Các polyaxetal, các polyete khác và nhựa epoxit, dạng nguyên sinh; các polycarbonat, nhựa alkyd, các este polyallyl và các polyeste khác, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '39071000',
    Description: 'Các polyaxetal',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các polyete khác:',
    Unit: '',
  },
  {
    HsCode: '39072100',
    Description: 'Bis(polyoxyethylene) methylphosphonate',
    Unit: 'kg',
  },
  {
    HsCode: '390729',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39072910',
    Description: 'Polytetrametylen ete glycol',
    Unit: 'kg',
  },
  {
    HsCode: '39072990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390730',
    Description: 'Nhựa epoxit:',
    Unit: '',
  },
  {
    HsCode: '39073020',
    Description: 'Loại dùng để phủ, dạng bột(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39073030',
    Description: 'Dạng lỏng hoặc dạng nhão',
    Unit: 'kg',
  },
  {
    HsCode: '39073090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39074000',
    Description: 'Các polycarbonat',
    Unit: 'kg',
  },
  {
    HsCode: '390750',
    Description: 'Nhựa alkyd:',
    Unit: '',
  },
  {
    HsCode: '39075010',
    Description: 'Dạng lỏng hoặc dạng nhão',
    Unit: 'kg',
  },
  {
    HsCode: '39075090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Poly (etylen terephthalat):',
    Unit: '',
  },
  {
    HsCode: '39076100',
    Description: 'Có chỉ số độ nhớt từ 78 ml/g trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '390769',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39076910',
    Description: 'Dạng hạt và các dạng tương tự (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39076990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39077000',
    Description: 'Poly(lactic axit)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các polyeste khác:',
    Unit: '',
  },
  {
    HsCode: '390791',
    Description: 'Chưa no:',
    Unit: '',
  },
  {
    HsCode: '39079120',
    Description: 'Dạng hạt và các dạng tương tự (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39079130',
    Description: 'Dạng lỏng hoặc dạng nhão',
    Unit: 'kg',
  },
  {
    HsCode: '39079190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390799',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39079940',
    Description: 'Loại dùng để phủ, dạng bột(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39079950',
    Description: 'Các copolyme polyeste thơm tinh thể lỏng nhiệt dẻo (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39079960',
    Description: 'Polybutylene succinate (PBS)(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39079990',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3908',
    Description: 'Các polyamide dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '390810',
    Description: 'Polyamide-6, -11, -12, -6,6, -6,9, -6,10 hoặc -6,12:',
    Unit: '',
  },
  {
    HsCode: '39081010',
    Description: 'Polyamide-6',
    Unit: 'kg',
  },
  {
    HsCode: '39081090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39089000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3909',
    Description: 'Nhựa amino, nhựa phenolic và các polyurethan, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '390910',
    Description: 'Nhựa ure; nhựa thioure:',
    Unit: '',
  },
  {
    HsCode: '39091010',
    Description: 'Hợp chất dùng để đúc',
    Unit: 'kg',
  },
  {
    HsCode: '39091090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390920',
    Description: 'Nhựa melamin:',
    Unit: '',
  },
  {
    HsCode: '39092010',
    Description: 'Hợp chất dùng để đúc',
    Unit: 'kg',
  },
  {
    HsCode: '39092090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Nhựa amino khác:',
    Unit: '',
  },
  {
    HsCode: '390931',
    Description: 'Poly(methylene phenyl isocyanate) (MDI thô, polymeric MDI) :',
    Unit: '',
  },
  {
    HsCode: '39093110',
    Description: 'Loại dùng để đúc',
    Unit: 'kg',
  },
  {
    HsCode: '39093190',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390939',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39093910',
    Description: 'Hợp chất dùng để đúc',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39093991',
    Description: 'Nhựa glyoxal monourein(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39093999',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '390940',
    Description: 'Nhựa phenolic:',
    Unit: '',
  },
  {
    HsCode: '39094010',
    Description: 'Hợp chất dùng để đúc trừ phenol formaldehyt',
    Unit: 'kg',
  },
  {
    HsCode: '39094090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39095000',
    Description: 'Các polyurethan',
    Unit: 'kg',
  },
  {
    HsCode: '3910',
    Description: 'Các silicon dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '39100020',
    Description: 'Dạng phân tán và dạng hòa tan(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39100090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3911',
    Description:
      'Nhựa từ dầu mỏ, nhựa cumaron-inden, polyterpen, polysulphua, polysulphon và các sản phẩm khác đã nêu trong Chú giải 3 của Chương này, chưa được chi tiết hoặc ghi ở nơi khác, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '39111000',
    Description: 'Nhựa từ dầu mỏ, nhựa cumaron, nhựa inden hoặc nhựa cumaron-inden và polyterpen',
    Unit: 'kg',
  },
  {
    HsCode: '39112000',
    Description: 'Poly(1,3-phenylene methylphosphonate)',
    Unit: 'kg',
  },
  {
    HsCode: '39119000',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3912',
    Description: 'Xenlulo và các dẫn xuất hóa học của nó, chưa được chi tiết hoặc ghi ở nơi khác, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các axetat xenlulo:',
    Unit: '',
  },
  {
    HsCode: '39121100',
    Description: 'Chưa hóa dẻo',
    Unit: 'kg',
  },
  {
    HsCode: '39121200',
    Description: 'Đã hóa dẻo',
    Unit: 'kg',
  },
  {
    HsCode: '391220',
    Description: 'Các nitrat xenlulo (bao gồm cả dung dịch dạng keo):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chưa hóa dẻo:',
    Unit: '',
  },
  {
    HsCode: '39122011',
    Description: 'Nitroxenlulo đã ngâm nước (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39122012',
    Description: 'Nitroxenlulo đã ngâm cồn',
    Unit: 'kg',
  },
  {
    HsCode: '39122019',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39122020',
    Description: 'Đã hóa dẻo',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Các ete xenlulo:',
    Unit: '',
  },
  {
    HsCode: '39123100',
    Description: 'Carboxymethylcellulose và các muối của nó',
    Unit: 'kg',
  },
  {
    HsCode: '39123900',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '391290',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39129020',
    Description: 'Dạng hạt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '39129090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3913',
    Description:
      'Polyme tự nhiên (ví dụ, axit alginic) và các polyme tự nhiên đã biến đổi (ví dụ, protein đã làm cứng, các dẫn xuất hóa học của cao su tự nhiên), chưa được chi tiết hoặc ghi ở nơi khác, dạng nguyên sinh',
    Unit: '',
  },
  {
    HsCode: '39131000',
    Description: 'Axit alginic, các muối và este của nó',
    Unit: 'kg',
  },
  {
    HsCode: '391390',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39139010',
    Description: 'Các protein đã làm cứng',
    Unit: 'kg',
  },
  {
    HsCode: '39139020',
    Description: 'Các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '39139030',
    Description: 'Các polyme từ tinh bột',
    Unit: 'kg',
  },
  {
    HsCode: '39139090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '39140000',
    Description: 'Chất trao đổi ion làm từ các polyme thuộc các nhóm từ 39.01 đến 39.13, dạng nguyên sinh',
    Unit: 'kg/lít',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG II',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẾ LIỆU, PHẾ THẢI VÀ MẨU VỤN; BÁN THÀNH PHẨM; THÀNH PHẨM',
    Unit: '',
  },
  {
    HsCode: '3915',
    Description: 'Phế liệu, phế thải và mẩu vụn, của plastic',
    Unit: '',
  },
  {
    HsCode: '391510',
    Description: 'Từ các polyme từ etylen:',
    Unit: '',
  },
  {
    HsCode: '39151010',
    Description: 'Dạng xốp, không cứng',
    Unit: 'kg',
  },
  {
    HsCode: '39151090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '391520',
    Description: 'Từ các polyme từ styren:',
    Unit: '',
  },
  {
    HsCode: '39152010',
    Description: 'Dạng xốp, không cứng',
    Unit: 'kg',
  },
  {
    HsCode: '39152090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '391530',
    Description: 'Từ các polyme từ vinyl clorua:',
    Unit: '',
  },
  {
    HsCode: '39153010',
    Description: 'Dạng xốp, không cứng',
    Unit: 'kg',
  },
  {
    HsCode: '39153090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '391590',
    Description: 'Từ plastic khác:',
    Unit: '',
  },
  {
    HsCode: '39159010',
    Description: 'Từ poly(etylene terephthalate)',
    Unit: 'kg',
  },
  {
    HsCode: '39159020',
    Description: 'Từ polypropylene',
    Unit: 'kg',
  },
  {
    HsCode: '39159030',
    Description: 'Từ polycarbonate',
    Unit: 'kg',
  },
  {
    HsCode: '39159040',
    Description: 'Từ các polyvinyl Acetal',
    Unit: 'kg',
  },
  {
    HsCode: '39159050',
    Description:
      'Từ các nhựa phenolic; từ các nhựa amino; từ các protein đã được làm cứng; từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '39159090',
    Description: 'Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '3916',
    Description:
      'Plastic dạng sợi monofilament có kích thước mặt cắt ngang bất kỳ trên 1 mm, dạng thanh, que và các dạng hình, đã hoặc chưa gia công bề mặt, nhưng chưa gia công cách khác',
    Unit: '',
  },
  {
    HsCode: '391610',
    Description: 'Từ các polyme từ etylen:',
    Unit: '',
  },
  {
    HsCode: '39161010',
    Description: 'Sợi monofilament',
    Unit: 'kg/m',
  },
  {
    HsCode: '39161020',
    Description: 'Dạng thanh, que và các dạng hình',
    Unit: 'kg/m',
  },
  {
    HsCode: '391620',
    Description: 'Từ các polyme từ vinyl clorua:',
    Unit: '',
  },
  {
    HsCode: '39162010',
    Description: 'Sợi monofilament',
    Unit: 'kg/m',
  },
  {
    HsCode: '39162020',
    Description: 'Dạng thanh, que và các dạng hình',
    Unit: 'kg/m',
  },
  {
    HsCode: '391690',
    Description: 'Từ plastic khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Từ các polyme trùng hợp:',
    Unit: '',
  },
  {
    HsCode: '39169011',
    Description: 'Từ polypropylen',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169012',
    Description: 'Từ polystyren',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169019',
    Description: 'Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '',
    Description: 'Từ các polyme trùng ngưng hoặc tái sắp xếp:',
    Unit: '',
  },
  {
    HsCode: '39169021',
    Description: 'Từ các nhựa phenolic; từ các nhựa amino',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169022',
    Description: 'Từ các polyamide',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169029',
    Description: 'Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169030',
    Description:
      'Từ xenlulo tái sinh; từ xenlulo nitrat, xenlulo acetat và các este xenlulo, ete xenlulo và các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169040',
    Description: 'Từ các protein đã được làm cứng',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169050',
    Description: 'Từ sợi lưu hóa',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169060',
    Description: 'Từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39169091',
    Description: 'Sợi monofilament',
    Unit: 'kg/m',
  },
  {
    HsCode: '39169092',
    Description: 'Dạng thanh, que và các dạng hình',
    Unit: 'kg/m',
  },
  {
    HsCode: '3917',
    Description:
      'Các loại ống, ống dẫn và ống vòi, và các phụ kiện dùng để ghép nối chúng (ví dụ, các đoạn nối, khuỷu, vành đệm), bằng plastic',
    Unit: '',
  },
  {
    HsCode: '391710',
    Description: 'Ruột nhân tạo (vỏ xúc xích) bằng protein đã làm cứng hoặc bằng chất liệu xenlulo:',
    Unit: '',
  },
  {
    HsCode: '39171010',
    Description: 'Từ protein đã được làm cứng',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39171090',
    Description: 'Loại khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '',
    Description: 'Ống, ống dẫn và ống vòi, loại cứng:',
    Unit: '',
  },
  {
    HsCode: '39172100',
    Description: 'Bằng các polyme từ etylen',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172200',
    Description: 'Bằng các polyme từ propylen',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172300',
    Description: 'Bằng các polyme từ vinyl clorua',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '391729',
    Description: 'Bằng plastic khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Được gia công hơn mức gia công bề mặt đơn thuần:',
    Unit: '',
  },
  {
    HsCode: '39172911',
    Description: 'Từ các polyme trùng hợp khác (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172919',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39172921',
    Description: 'Từ các polyme trùng hợp khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172922',
    Description: 'Từ nhựa phenolic',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172923',
    Description: 'Từ nhựa amino; từ protein đã được làm cứng; từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172924',
    Description:
      'Từ nitrat xenlulo, các acetat xenlulo và các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo; từ sợi lưu hóa',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172925',
    Description: 'Từ các polyme trùng ngưng hoặc tái sắp xếp khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39172929',
    Description: 'Loại khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '',
    Description: 'Ống, ống dẫn và ống vòi khác:',
    Unit: '',
  },
  {
    HsCode: '391731',
    Description: 'Ống, ống dẫn và ống vòi, loại mềm, có áp suất bục tối thiểu là 27,6 MPa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Được gia công hơn mức gia công bề mặt đơn thuần:',
    Unit: '',
  },
  {
    HsCode: '39173111',
    Description: 'Từ các polyme trùng hợp(SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173112',
    Description: 'Từ nhựa amino; từ nhựa phenolic; từ sợi lưu hóa; từ các dẫn xuất hóa học của cao su tự nhiên (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173119',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39173121',
    Description: 'Từ các polyme trùng hợp',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173123',
    Description: 'Từ nhựa amino; từ nhựa phenolic; từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173124',
    Description: 'Từ sợi lưu hóa',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173125',
    Description:
      'Từ các polyme trùng ngưng hoặc tái sắp xếp khác; từ nitrat xenlulo, các acetat xenlulo và các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173129',
    Description: 'Loại khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '391732',
    Description: 'Loại khác, chưa gia cố hoặc kết hợp với vật liệu khác, không kèm các phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '39173210',
    Description: 'Vỏ xúc xích hoặc vỏ giăm bông',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173220',
    Description: 'Ống nhựa nhiệt dẻo dùng cho bếp ga(SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Được gia công hơn mức gia công bề mặt đơn thuần:',
    Unit: '',
  },
  {
    HsCode: '39173291',
    Description:
      'Từ các polyme trùng hợp; từ nhựa phenolic hoặc nhựa amino; từ các dẫn xuất hóa học của cao su tự nhiên (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173292',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39173293',
    Description: 'Từ các polyme trùng hợp',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173294',
    Description: 'Từ nhựa amino hoặc nhựa phenolic; từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173295',
    Description:
      'Từ các polyme trùng ngưng hoặc tái sắp xếp khác; từ sợi lưu hóa; từ nitrat xenlulo, các acetat xenlulo và các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173299',
    Description: 'Loại khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '391733',
    Description: 'Loại khác, chưa được gia cố hoặc kết hợp với các vật liệu khác, có kèm các phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '39173310',
    Description: 'Loại khác, được gia công hơn mức gia công bề mặt đơn thuần(SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173390',
    Description: 'Loại khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '391739',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Được gia công hơn mức gia công bề mặt đơn thuần:',
    Unit: '',
  },
  {
    HsCode: '39173911',
    Description: 'Từ các polyme trùng hợp; từ sợi lưu hóa (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173912',
    Description: 'Từ nhựa phenolic hoặc nhựa amino; từ các dẫn xuất hóa học của cao su tự nhiên (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173919',
    Description: 'Loại khác (SEN)',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39173991',
    Description: 'Từ các polyme trùng hợp; từ sợi lưu hóa ',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173992',
    Description: 'Từ nhựa phenolic hoặc nhựa amino; từ các dẫn xuất hóa học của cao su tự nhiên ',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173993',
    Description: 'Từ các polyme trùng ngưng hoặc tái sắp xếp khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173994',
    Description: 'Từ nitrat xenlulo, các acetat xenlulo và các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39173999',
    Description: 'Loại khác',
    Unit: 'kg/m/ chiếc',
  },
  {
    HsCode: '39174000',
    Description: 'Các phụ kiện',
    Unit: 'kg/ chiếc',
  },
  {
    HsCode: '3918',
    Description:
      'Tấm trải sàn bằng plastic, có hoặc không tự dính, dạng cuộn hoặc dạng tấm rời để ghép; tấm phủ tường hoặc phủ trần bằng plastic, như đã nêu trong Chú giải 9 của Chương này',
    Unit: '',
  },
  {
    HsCode: '391810',
    Description: 'Từ các polyme từ vinyl clorua:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tấm trải sàn:',
    Unit: '',
  },
  {
    HsCode: '39181011',
    Description: 'Dạng tấm rời để ghép',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39181019',
    Description: 'Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39181090',
    Description: 'Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '391890',
    Description: 'Từ plastic khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tấm trải sàn:',
    Unit: '',
  },
  {
    HsCode: '39189011',
    Description: 'Dạng tấm rời để ghép, bằng polyetylen',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189013',
    Description: 'Loại khác, bằng polyetylen',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189014',
    Description: 'Từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189015',
    Description:
      'Từ các polyme trùng hợp khác; từ các polyme trùng ngưng hoặc tái sắp xếp; từ nitrat xenlulo, acetat xenlulo hoặc các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189016',
    Description: 'Từ sợi lưu hóa',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189019',
    Description: 'Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39189091',
    Description: 'Từ polyetylen',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189092',
    Description: 'Từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189093',
    Description:
      'Từ các polyme trùng hợp khác; từ các polyme trùng ngưng hoặc tái sắp xếp; từ nitrat xenlulo, acetat xenlulo hoặc các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189094',
    Description: 'Từ sợi lưu hóa',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39189099',
    Description: 'Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '3919',
    Description:
      'Tấm, phiến, màng, lá, băng, dải và các hình dạng phẳng khác tự dính, bằng plastic, có hoặc không ở dạng cuộn',
    Unit: '',
  },
  {
    HsCode: '391910',
    Description: 'Ở dạng cuộn có chiều rộng không quá 20 cm:',
    Unit: '',
  },
  {
    HsCode: '39191010',
    Description: 'Từ các polyme từ vinyl clorua',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39191020',
    Description: 'Từ polyetylen',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39191091',
    Description: 'Từ các protein đã làm cứng hoặc các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39191092',
    Description:
      'Từ các polyme trùng hợp; từ các polyme trùng ngưng hoặc tái sắp xếp; từ nitrat xenlulo, các acetat xenlulo và các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39191099',
    Description: 'Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '391990',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39199010',
    Description: 'Từ các polyme từ vinyl clorua',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39199020',
    Description: 'Từ protein đã được làm cứng',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: 'Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39199091',
    Description: 'Từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39199092',
    Description:
      'Từ các polyme trùng hợp; từ các polyme trùng ngưng hoặc tái sắp xếp; từ nitrat xenlulo, các acetat xenlulo và các dẫn xuất hóa học khác của xenlulo, đã hóa dẻo',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39199099',
    Description: 'Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '3920',
    Description:
      'Tấm, phiến, màng, lá và dải khác, bằng plastic, không xốp và chưa được gia cố, chưa gắn lớp mặt, chưa được bổ trợ hoặc chưa được kết hợp tương tự với các vật liệu khác',
    Unit: '',
  },
  {
    HsCode: '392010',
    Description: 'Từ các polyme từ etylen:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Dạng tấm và phiến (SEN):',
    Unit: '',
  },
  {
    HsCode: '39201011',
    Description: 'Loại cứng (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39201019',
    Description: 'Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39201090',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392020',
    Description: '- Từ các polyme từ propylen:',
    Unit: '',
  },
  {
    HsCode: '39202010',
    Description: '- - Màng polypropylen định hướng hai chiều (BOPP) (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39202091',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39202099',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392030',
    Description: '- Từ các polyme từ styren:',
    Unit: '',
  },
  {
    HsCode: '39203020',
    Description: '- - Tấm Acrylonitril butadien styren (ABS) sử dụng trong sản xuất tủ lạnh',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39203091',
    Description: '- - - Dạng tấm và phiến, loại cứng(SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39203092',
    Description: '- - - Loại khác, dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39203099',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ các polyme từ vinyl clorua:',
    Unit: '',
  },
  {
    HsCode: '392043',
    Description: '- - Có hàm lượng chất hóa dẻo không dưới 6% tính theo trọng lượng:',
    Unit: '',
  },
  {
    HsCode: '39204310',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39204390',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39204900',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ các polyme acrylic:',
    Unit: '',
  },
  {
    HsCode: '392051',
    Description: '- - Từ poly(metyl metacrylat):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng tấm và phiến (SEN):',
    Unit: '',
  },
  {
    HsCode: '39205111',
    Description: '- - - - Loại cứng(SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39205119',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39205190',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392059',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng tấm và phiến (SEN):',
    Unit: '',
  },
  {
    HsCode: '39205911',
    Description: '- - - - Loại cứng(SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39205919',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39205990',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ các polycarbonat, nhựa alkyd, các este polyallyl hoặc các polyeste khác:',
    Unit: '',
  },
  {
    HsCode: '392061',
    Description: '- - Từ các polycarbonat:',
    Unit: '',
  },
  {
    HsCode: '39206110',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39206190',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392062',
    Description: '- - Từ poly(etylen terephtalat):',
    Unit: '',
  },
  {
    HsCode: '39206210',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39206291',
    Description: '- - - - Màng bảo vệ chắn ánh sáng mặt trời (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39206299',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392063',
    Description: '- - Từ các polyeste chưa no:',
    Unit: '',
  },
  {
    HsCode: '39206310',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39206390',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392069',
    Description: '- - Từ các polyeste khác:',
    Unit: '',
  },
  {
    HsCode: '39206910',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39206990',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ xenlulo hoặc các dẫn xuất hóa học của nó:',
    Unit: '',
  },
  {
    HsCode: '392071',
    Description: '- - Từ xenlulo tái sinh:',
    Unit: '',
  },
  {
    HsCode: '39207110',
    Description: '- - - Màng xenlophan',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39207191',
    Description: '- - - - Dạng phiến (sheets) đã in',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39207199',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39207300',
    Description: '- - Từ xenlulo axetat',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392079',
    Description: '- - Từ các dẫn xuất xenlulo khác:',
    Unit: '',
  },
  {
    HsCode: '39207910',
    Description: '- - - Từ nitrocellulose (thuốc nổ bông)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39207920',
    Description: '- - - Từ sợi lưu hóa',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39207991',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39207999',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ plastic khác:',
    Unit: '',
  },
  {
    HsCode: '392091',
    Description: '- - Từ poly(vinyl butyral):',
    Unit: '',
  },
  {
    HsCode: '39209110',
    Description:
      '- - - Màng dùng làm kính an toàn, độ dày trên 0,38 mm nhưng không quá 0,76 mm, và chiều rộng không quá 2 m',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39209191',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39209199',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392092',
    Description: '- - Từ các polyamide:',
    Unit: '',
  },
  {
    HsCode: '39209210',
    Description: '- - - Từ polyamide-6',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39209291',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39209299',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392093',
    Description: '- - Từ nhựa amino:',
    Unit: '',
  },
  {
    HsCode: '39209310',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39209390',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392094',
    Description: '- - Từ nhựa phenolic:',
    Unit: '',
  },
  {
    HsCode: '39209410',
    Description: '- - - Dạng tấm phenol formaldehyt (bakelit)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39209491',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39209499',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392099',
    Description: '- - Từ plastic khác:',
    Unit: '',
  },
  {
    HsCode: '39209910',
    Description: '- - - Từ protein đã được làm cứng; từ dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Từ các polyme trùng hợp:',
    Unit: '',
  },
  {
    HsCode: '39209921',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39209929',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Từ các polyme trùng ngưng hoặc tái sắp xếp:',
    Unit: '',
  },
  {
    HsCode: '39209931',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39209939',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39209990',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '3921',
    Description: 'Tấm, phiến, màng, lá và dải khác, bằng plastic',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Loại xốp:',
    Unit: '',
  },
  {
    HsCode: '392111',
    Description: '- - Từ các polyme từ styren:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Cứng:',
    Unit: '',
  },
  {
    HsCode: '39211121',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211129',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39211191',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211192',
    Description: '- - - - Dạng màng (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211199',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211200',
    Description: '- - Từ các polyme từ vinyl clorua',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392113',
    Description: '- - Từ các polyurethan:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Cứng:',
    Unit: '',
  },
  {
    HsCode: '39211311',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211319',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39211391',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211392',
    Description: '- - - - Dạng màng (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211399',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392114',
    Description: '- - Từ xenlulo tái sinh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Cứng:',
    Unit: '',
  },
  {
    HsCode: '39211421',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211429',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39211491',
    Description: '- - - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211492',
    Description: '- - - - Dạng màng (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211499',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392119',
    Description: '- - Từ plastic khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng tấm và phiến (SEN) từ các sản phẩm polyme trùng hợp:',
    Unit: '',
  },
  {
    HsCode: '39211911',
    Description: '- - - - Từ polypropylen ',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211912',
    Description: '- - - - Từ polyetylen',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211919',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dạng tấm và phiến(SEN) từ các sản phẩm polyme trùng ngưng hoặc tái sắp xếp:',
    Unit: '',
  },
  {
    HsCode: '39211931',
    Description: '- - - - Từ các polycarbonat',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211939',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211940',
    Description: '- - - Dạng tấm và phiến(SEN) từ xenlulo hoặc từ các dẫn xuất hóa học của chúng, hoặc từ sợi lưu hóa',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211950',
    Description:
      '- - - Dạng tấm và phiến(SEN) từ các protein được làm cứng, hoặc từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dạng màng và lá (SEN):',
    Unit: '',
  },
  {
    HsCode: '39211961',
    Description: '- - - - Từ nitrocellulose (thuốc nổ bông)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211962',
    Description: '- - - - Từ các protein đã làm cứng; từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211969',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39211993',
    Description: '- - - - Từ nitrocellulose (thuốc nổ bông)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211994',
    Description: '- - - - Từ các protein đã làm cứng; từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39211999',
    Description: '- - - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '392190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39219010',
    Description: '- - Từ sợi lưu hóa',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219020',
    Description: '- - Từ các protein đã được làm cứng',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219030',
    Description: '- - Từ các dẫn xuất hóa học của cao su tự nhiên',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Từ các polyme trùng ngưng hoặc tái sắp xếp:',
    Unit: '',
  },
  {
    HsCode: '39219041',
    Description: '- - - Dạng tấm và phiến (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219042',
    Description: '- - - Dạng màng (SEN)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219043',
    Description: '- - - Dạng dải có ép vật liệu dệt',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219049',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219050',
    Description: '- - Từ xenlulo tái sinh',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219060',
    Description: '- - Từ xenlulo khác hoặc các dẫn xuất hóa học của nó',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219070',
    Description: '- - Từ các polyme trùng hợp',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '39219090',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '3922',
    Description:
      'Bồn tắm, bồn tắm vòi sen, bồn rửa, chậu rửa, bệ rửa vệ sinh (bidets), bệ và nắp xí bệt, bình xả nước và các thiết bị vệ sinh tương tự, bằng plastic',
    Unit: '',
  },
  {
    HsCode: '392210',
    Description: '- Bồn tắm, bồn tắm vòi sen, bồn rửa và chậu rửa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bồn tắm:',
    Unit: '',
  },
  {
    HsCode: '39221011',
    Description: '- - - Bồn tắm có hình dạng bên trong là hình chữ nhật hoặc hình thuôn (oblong)',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39221019',
    Description: '- - - Loại khác',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39221090',
    Description: '- - Loại khác',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39222000',
    Description: '- Bệ và nắp xí bệt',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '392290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bệ rửa, bình xả nước và bệ tiểu:',
    Unit: '',
  },
  {
    HsCode: '39229011',
    Description: '- - - Bộ phận của bình xả nước(SEN)',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39229012',
    Description: '- - - Bình xả nước đã lắp sẵn các bộ phận(SEN)',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39229019',
    Description: '- - - Loại khác',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39229090',
    Description: '- - Loại khác',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '3923',
    Description:
      'Các sản phẩm dùng trong vận chuyển hoặc đóng gói hàng hóa, bằng plastic; nút, nắp, mũ van và các loại nút đậy khác, bằng plastic',
    Unit: '',
  },
  {
    HsCode: '392310',
    Description: '- Hộp, hòm, thùng thưa và các loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '39231010',
    Description: '- - Hộp đựng phim, băng từ và đĩa quang',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39231020',
    Description:
      '- - Loại khác, có hình dạng đặc biệt hoặc thích hợp để vận chuyển hoặc đóng gói các tấm bán dẫn mỏng, màn hoặc lưới quang (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39231090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Bao và túi (kể cả loại hình nón): ',
    Unit: '',
  },
  {
    HsCode: '392321',
    Description: '- - Từ các polyme từ etylen:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Túi vô trùng được gia cố bằng lá nhôm (trừ túi đáy nở-retort):',
    Unit: '',
  },
  {
    HsCode: '39232111',
    Description: '- - - - Có chiều rộng từ 315 mm trở lên và có chiều dài từ 410 mm trở lên, gắn với nắp đệm kín',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39232119',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39232191',
    Description:
      '- - - - Túi vô trùng không được gia cố bằng lá nhôm (trừ túi đáy nở-retort), chiều rộng từ 315 mm trở lên và chiều dài từ 410 mm trở lên, gắn với nắp đệm kín(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39232199',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '392329',
    Description: '- - Từ plastic khác:',
    Unit: '',
  },
  {
    HsCode: '39232910',
    Description:
      '- - - Túi vô trùng đã hoặc chưa gia cố bằng lá nhôm (trừ túi đáy nở-retort), chiều rộng từ 315 mm trở lên và chiều dài từ 410 mm trở lên, gắn với nắp đệm kín',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39232990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '392330',
    Description: '- Bình, chai, lọ, bình thót cổ và các sản phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '39233020',
    Description: '- - Bình chứa nhiều lớp được gia cố bằng sợi thủy tinh, dùng cho khí nén hoặc khí hóa lỏng(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39233090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '392340',
    Description: '- Suốt chỉ, ống chỉ, lõi và các vật phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '39234010',
    Description: '- - Loại phù hợp sử dụng với máy móc thuộc nhóm 84.44, 84.45 hoặc 84.48(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39234090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39235000',
    Description: '- Nút, nắp, mũ van và các nút đậy khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '392390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39239010',
    Description: '- - Tuýp để đựng kem đánh răng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39239090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '3924',
    Description:
      'Bộ đồ ăn, bộ đồ dùng nhà bếp, các sản phẩm gia dụng khác và các sản phẩm phục vụ vệ sinh, bằng plastic',
    Unit: '',
  },
  {
    HsCode: '392410',
    Description: '- Bộ đồ ăn và bộ đồ dùng nhà bếp:',
    Unit: '',
  },
  {
    HsCode: '39241010',
    Description: '- - Từ melamin',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39241091',
    Description: '- - - Bình cho trẻ em ăn',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39241099',
    Description: '- - - Loại khác',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '392490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39249010',
    Description: '- - Bô để giường bệnh, bô đi tiểu (loại xách tay được) hoặc bô để phòng ngủ(SEN)',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39249020',
    Description: '- - Miếng tạo dáng núm vú, miếng bảo vệ đầu ngực, núm trợ ti, phễu vắt sữa bằng tay(SEN)',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39249030',
    Description: '- - Bộ câu sữa',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39249090',
    Description: '- - Loại khác',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '3925',
    Description: 'Đồ vật bằng plastic dùng trong xây lắp, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '39251000',
    Description: '- Thùng chứa, bể và các loại đồ chứa đựng tương tự, dung tích trên 300 lít',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39252000',
    Description: '- Cửa ra vào, cửa sổ và khung của chúng và ngưỡng cửa ra vào',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39253000',
    Description: '- Cửa chớp, mành che (kể cả mành chớp lật) và các sản phẩm tương tự và các bộ phận của nó',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '39259000',
    Description: '- Loại khác',
    Unit: 'bộ/chiếc',
  },
  {
    HsCode: '3926',
    Description:
      'Các sản phẩm khác bằng plastic và các sản phẩm bằng các vật liệu khác của các nhóm từ 39.01 đến 39.14',
    Unit: '',
  },
  {
    HsCode: '39261000',
    Description: '- Đồ dùng trong văn phòng hoặc trường học',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '392620',
    Description: '- Hàng may mặc và đồ phụ trợ may mặc (kể cả găng tay, găng hở ngón và găng bao tay): ',
    Unit: '',
  },
  {
    HsCode: '39262010',
    Description: '- - Găng tay; tạp dề; yếm cho trẻ em (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39262020',
    Description: '- - Miếng đệm và miếng bảo vệ vai (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39262030',
    Description: '- - Lót, đệm khác cho các sản phẩm may mặc hoặc cho các đồ phụ trợ của quần áo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39262060',
    Description: '- - Hàng may mặc dùng để chống các chất hóa học, phóng xạ hoặc chống cháy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39262090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39263000',
    Description: '- Phụ kiện lắp vào đồ nội thất, trên thân xe (coachwork) hoặc các loại tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39264000',
    Description: '- Tượng nhỏ và các đồ trang trí khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '392690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39269010',
    Description: '- - Phao cho lưới đánh cá',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269020',
    Description: '- - Quạt và màn che kéo bằng tay, khung và tay cầm, quai kèm theo, và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Các sản phẩm vệ sinh, y tế và phẫu thuật:',
    Unit: '',
  },
  {
    HsCode: '39269032',
    Description: '- - - Khuôn plastic lấy dấu răng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269039',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Các thiết bị, dụng cụ an toàn và bảo vệ:',
    Unit: '',
  },
  {
    HsCode: '39269041',
    Description: '- - - Lá chắn bảo vệ của cảnh sát',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269042',
    Description: '- - - Mặt nạ bảo hộ sử dụng khi hàn và trong các công việc tương tự(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269044',
    Description: '- - - Đệm cứu sinh dùng để bảo vệ người ngã từ trên cao xuống',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269049',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Các sản phẩm dùng cho công nghiệp:',
    Unit: '',
  },
  {
    HsCode: '39269053',
    Description: '- - - Băng truyền (dây curoa) hoặc đai truyền hoặc băng tải hoặc đai tải',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269055',
    Description: '- - - Móc hình chữ J hoặc khối chùm bằng plastic dùng cho ngòi nổ, kíp nổ(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269059',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269060',
    Description: '- - Dụng cụ cho gia cầm ăn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Tấm thẻ để trình bày đồ nữ trang hoặc những đồ vật nhỏ để trang điểm cá nhân; chuỗi hạt; phom giày:',
    Unit: '',
  },
  {
    HsCode: '39269081',
    Description: '- - - Khuôn (phom) giày',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269082',
    Description: '- - - Chuỗi hạt cầu nguyện(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269089',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '39269091',
    Description: '- - - Loại dùng để chứa ngũ cốc(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269092',
    Description: '- - - Vỏ viên nhộng loại dùng để làm thuốc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269093',
    Description: '- - - Khóa, khóa điều chỉnh, móc treo và nút chặn dây (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '39269099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 40',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Cao su và các sản phẩm bằng cao su',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trừ khi có yêu cầu khác, trong toàn bộ Danh mục, khái niệm “cao su” chỉ những sản phẩm dưới đây, đã hoặc chưa lưu hóa hoặc ở dạng cứng: cao su tự nhiên, nhựa cây balata, nhựa két, nhựa cây cúc cao su, nhựa chicle và các loại nhựa tự nhiên tương tự, cao su tổng hợp, các chất thay thế cao su thu được từ các loại dầu, và những chất như vậy được tái sinh.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các loại hàng hóa trong Phần XI (nguyên liệu dệt và các sản phẩm dệt);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Giày dép hoặc các bộ phận của giày dép thuộc Chương 64;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Mũ và các vật đội đầu khác hoặc các bộ phận của chúng (kể cả mũ tắm) thuộc Chương 65;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Dụng cụ điện hoặc cơ khí hoặc các bộ phận của chúng thuộc Phần XVI (kể cả đồ điện các loại), làm bằng cao su cứng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Sản phẩm thuộc các Chương 90, 92, 94 hoặc 96; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Sản phẩm thuộc Chương 95 (trừ găng tay thể thao, găng hở ngón và găng bao tay và các sản phẩm thuộc các nhóm từ 40.11 đến 40.13).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Trong các nhóm 40.01 đến 40.03 và 40.05, khái niệm “dạng nguyên sinh” chỉ áp dụng cho các dạng dưới đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Dạng lỏng và dạng nhão (kể cả mủ cao su (latex), đã hoặc chưa tiền lưu hóa, và các dạng phân tán và dạng hòa tan khác);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các khối có hình dạng không đều, cục, bành (phiến), bột, hạt, miếng, mảnh và dạng khối tương tự.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '4. Theo Chú giải 1 của Chương này và nhóm 40.02, khái niệm “cao su tổng hợp” áp dụng đối với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các chất tổng hợp chưa no mà chúng có thể chuyển đổi một chiều bằng quá trình lưu hóa với lưu huỳnh thành loại không phải nhiệt dẻo (non-thermoplastic), khi ở khoảng nhiệt độ từ 18oC và 29oC, sẽ không đứt dù cho kéo dãn đến ba lần chiều dài ban đầu của chúng, và sau khi bị kéo giãn tới 2 lần chiều dài ban đầu trong vòng 5 phút, nó sẽ co lại không lớn hơn 1,5 lần chiều dài ban đầu. Theo mục đích của phương pháp thử này, các chất cần thiết để tạo liên kết ngang, như các chất xúc tác hoặc các chất kích hoạt lưu hóa, có thể được thêm vào; sự có mặt của các chất theo quy định của Chú giải 5(B)(ii) và (iii) cũng được chấp nhận. Tuy nhiên, sự có mặt của bất kỳ một chất nào không cần thiết để tạo liên kết ngang, như chất độn, chất hóa dẻo và chất trương nở, là không được chấp nhận;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Chất dẻo có chứa lưu huỳnh (TM); và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Cao su tự nhiên đã biến đổi do ghép hoặc pha trộn với plastic, cao su tự nhiên đã khử trùng hợp, hỗn hợp của các chất tổng hợp chưa no với các polyme cao phân tử tổng hợp no với điều kiện là tất cả các sản phẩm kể trên đáp ứng các yêu cầu về lưu hóa, kéo giãn và phục hồi như đã nêu ở mục (a) trên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. (A) Các nhóm 40.01 và 40.02 không áp dụng cho bất kỳ loại cao su hoặc hỗn hợp cao su nào đã kết hợp trước hoặc sau khi đông tụ với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) các chất lưu hóa, chất xúc tác, chất hãm hoặc các chất kích hoạt (trừ các chất được thêm vào để chuẩn bị quá trình tiền lưu hóa mủ cao su);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) thuốc màu hoặc các chất màu khác, trừ trường hợp những chất này thêm vào chỉ để nhận biết;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iii) các chất hóa dẻo hoặc chất trương nở (trừ dầu khoáng trong trường hợp cao su đã được trương nở bởi dầu), chất độn, tác nhân gia cố, các dung môi hữu cơ hoặc bất kỳ chất nào khác, trừ những chất được cho phép trong mục (B);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Sự có mặt của những chất dưới đây trong bất kỳ một loại cao su hoặc hỗn hợp cao su nào sẽ không ảnh hưởng đến việc phân loại mặt hàng đó trong nhóm 40.01 hoặc 40.02, miễn là cao su hoặc hỗn hợp cao su đó vẫn giữ được đặc trưng cơ bản như một nguyên liệu thô, có thể trong những trường hợp sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) các chất nhũ hóa hoặc chất chống dính;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) lượng nhỏ các sản phẩm phân nhỏ của chất nhũ hóa;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iii) một lượng rất nhỏ các chất sau đây: chất nhạy nhiệt (thông thường để sản xuất mủ cao su nhạy nhiệt), tác nhân hoạt động bề mặt cation (thông thường để sản xuất mủ cao su có điện dương), chất chống oxy hóa, chất làm đông tụ, chất làm bở, chất chịu lạnh, chất bảo quản, chất pepti hóa, chất ổn định, chất điều chỉnh độ nhớt hoặc các chất phụ gia với mục đích đặc biệt tương tự.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của nhóm 40.04, khái niệm “phế liệu, phế thải và mảnh vụn"" chỉ các chất thải cao su, chất phế liệu, mảnh vụn từ quá trình sản xuất hoặc gia công cao su và các sản phẩm cao su không còn sử dụng được như mục đích ban đầu của chúng vì cắt ra thành từng mảnh, mài mòn hoặc các lý do khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Chỉ sợi hoàn toàn bằng cao su lưu hóa, có kích thước mặt cắt ngang bất kỳ trên 5 mm, được phân loại như các loại dải, thanh hoặc dạng hình khác, thuộc nhóm 40.08.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Nhóm 40.10 gồm cả băng tải, đai tải, băng truyền (dây curoa) hoặc đai truyền bằng vải dệt đã ngâm tẩm, tráng, phủ, hoặc ép với cao su hoặc làm từ sợi dệt hoặc dây bện đã ngâm tẩm, tráng, phủ hoặc bao ngoài bằng cao su.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Trong các nhóm 40.01, 40.02, 40.03, 40.05 và 40.08 khái niệm “tấm”, “tờ” và “dải” chỉ áp dụng cho các loại tấm, tờ và dải và khối hình học đều, chưa cắt hoặc mới chỉ cắt đơn giản thành hình chữ nhật (kể cả hình vuông), có hoặc chưa có tính chất của sản phẩm và đã hoặc chưa in hoặc gia công bề mặt theo cách khác, nhưng chưa cắt thành hình hoặc gia công thêm theo cách khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Trong nhóm 40.08 khái niệm “thanh” và “dạng hình” chỉ áp dụng cho các sản phẩm như đã mô tả, đã hoặc chưa cắt thành từng đoạn hoặc gia công bề mặt nhưng chưa gia công cách khác.',
    Unit: '',
  },
  {
    HsCode: '4001',
    Description:
      'Cao su tự nhiên, nhựa cây balata, nhựa két, nhựa cây cúc cao su, nhựa chicle và các loại nhựa tự nhiên tương tự, ở dạng nguyên sinh hoặc dạng tấm, tờ hoặc dải',
    Unit: '',
  },
  {
    HsCode: '400110',
    Description: '- Mủ cao su tự nhiên, đã hoặc chưa tiền lưu hóa: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Chứa trên 0,5% hàm lượng amoniac tính theo thể tích:',
    Unit: '',
  },
  {
    HsCode: '40011011',
    Description: '- - - Mủ cao su cô đặc bằng phương pháp ly tâm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40011019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Chứa không quá 0,5% hàm lượng amoniac tính theo thể tích:',
    Unit: '',
  },
  {
    HsCode: '40011021',
    Description: '- - - Mủ cao su cô đặc bằng phương pháp ly tâm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40011029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Cao su tự nhiên ở dạng khác:',
    Unit: '',
  },
  {
    HsCode: '400121',
    Description: '- - Tờ cao su xông khói:',
    Unit: '',
  },
  {
    HsCode: '40012110',
    Description: '- - - RSS hạng 1 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012120',
    Description: '- - - RSS hạng 2 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012130',
    Description: '- - - RSS hạng 3 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012140',
    Description: '- - - RSS hạng 4 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012150',
    Description: '- - - RSS hạng 5 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400122',
    Description: '- - Cao su tự nhiên được định chuẩn về kỹ thuật (TSNR):',
    Unit: '',
  },
  {
    HsCode: '40012210',
    Description: '- - - TSNR 10 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012220',
    Description: '- - - TSNR 20 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012230',
    Description: '- - - TSNR L (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012240',
    Description: '- - - TSNR CV (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012250',
    Description: '- - - TSNR GP (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012260',
    Description: '- - - TSNR 5 (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400129',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40012910',
    Description: '- - - Cao su dạng tờ được làm khô bằng không khí(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012920',
    Description: '- - - Crếp từ mủ cao su (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012930',
    Description: '- - - Crếp làm đế giày (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012950',
    Description: '- - - Crếp loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '40012960',
    Description: '- - - Cao su chế biến cao cấp',
    Unit: 'kg',
  },
  {
    HsCode: '40012970',
    Description: '- - - Váng cao su',
    Unit: 'kg',
  },
  {
    HsCode: '40012980',
    Description: '- - - Loại tận thu (trên cây, dưới đất hoặc loại đã xông khói) và phần thừa lại trên chén(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, dạng nguyên sinh:',
    Unit: '',
  },
  {
    HsCode: '40012994',
    Description: '- - - - Cao su tự nhiên đã khử protein (DPNR) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '40012996',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '40012999',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400130',
    Description: '- Nhựa cây balata, nhựa két, nhựa cây cúc cao su, nhựa chicle và các loại nhựa tự nhiên tương tự:',
    Unit: '',
  },
  {
    HsCode: '40013020',
    Description: '- - Dạng nguyên sinh',
    Unit: 'kg',
  },
  {
    HsCode: '40013090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4002',
    Description:
      'Cao su tổng hợp và các chất thay thế cao su dẫn xuất từ dầu, ở dạng nguyên sinh hoặc dạng tấm, tờ hoặc dải; hỗn hợp của một sản phẩm bất kỳ của nhóm 40.01 với một sản phẩm bất kỳ của nhóm này, ở dạng nguyên sinh hoặc dạng tấm, tờ hoặc dải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Cao su styren-butadien (SBR); cao su styren-butadien đã được carboxyl hóa (XSBR):',
    Unit: '',
  },
  {
    HsCode: '40021100',
    Description: '- - Dạng latex (dạng mủ cao su)',
    Unit: 'kg',
  },
  {
    HsCode: '400219',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40021910',
    Description: '- - - Dạng nguyên sinh hoặc dạng tấm, tờ hoặc dải chưa lưu hóa, chưa pha trộn',
    Unit: 'kg',
  },
  {
    HsCode: '40021990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400220',
    Description: '- Cao su butadien (BR):',
    Unit: '',
  },
  {
    HsCode: '40022010',
    Description: '- - Dạng nguyên sinh',
    Unit: 'kg',
  },
  {
    HsCode: '40022090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Cao su isobuten-isopren (butyl) (IIR); cao su halo-isobuten-isopren (CIIR hoặc BIIR):',
    Unit: '',
  },
  {
    HsCode: '400231',
    Description: '- - Cao su isobuten-isopren (butyl) (IIR):',
    Unit: '',
  },
  {
    HsCode: '40023110',
    Description: '- - - Dạng tấm, tờ hoặc dải chưa lưu hóa, chưa pha trộn',
    Unit: 'kg',
  },
  {
    HsCode: '40023190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400239',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40023910',
    Description: '- - - Dạng tấm, tờ hoặc dải chưa lưu hóa, chưa pha trộn',
    Unit: 'kg',
  },
  {
    HsCode: '40023990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Cao su chloroprene (chlorobutadiene) (CR):',
    Unit: '',
  },
  {
    HsCode: '40024100',
    Description: '- - Dạng latex (dạng mủ cao su)',
    Unit: 'kg',
  },
  {
    HsCode: '400249',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40024910',
    Description: '- - - Dạng nguyên sinh',
    Unit: 'kg',
  },
  {
    HsCode: '40024990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Cao su acrylonitril-butadien (NBR):',
    Unit: '',
  },
  {
    HsCode: '40025100',
    Description: '- - Dạng latex (dạng mủ cao su)',
    Unit: 'kg',
  },
  {
    HsCode: '400259',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40025910',
    Description: '- - - Dạng nguyên sinh',
    Unit: 'kg',
  },
  {
    HsCode: '40025990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400260',
    Description: '- Cao su isopren (IR):',
    Unit: '',
  },
  {
    HsCode: '40026010',
    Description: '- - Dạng nguyên sinh',
    Unit: 'kg',
  },
  {
    HsCode: '40026090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400270',
    Description: '- Cao su diene chưa liên hợp- etylen- propylen (EPDM):',
    Unit: '',
  },
  {
    HsCode: '40027010',
    Description: '- - Dạng nguyên sinh',
    Unit: 'kg',
  },
  {
    HsCode: '40027090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400280',
    Description: '- Hỗn hợp của sản phẩm bất kỳ thuộc nhóm 40.01 với sản phẩm bất kỳ của nhóm này:',
    Unit: '',
  },
  {
    HsCode: '40028010',
    Description: '- - Hỗn hợp mủ cao su tự nhiên với mủ cao su tổng hợp',
    Unit: 'kg',
  },
  {
    HsCode: '40028090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40029100',
    Description: '- - Dạng latex (dạng mủ cao su)',
    Unit: 'kg',
  },
  {
    HsCode: '400299',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40029930',
    Description: '- - - Cao su tự nhiên đã epoxy hóa (ENR)',
    Unit: 'kg',
  },
  {
    HsCode: '40029940',
    Description: '- - - Loại khác, dạng nguyên sinh hoặc dạng tấm, tờ hoặc dải chưa lưu hóa, chưa pha trộn',
    Unit: 'kg',
  },
  {
    HsCode: '40029990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '40030000',
    Description: 'Cao su tái sinh ở dạng nguyên sinh hoặc dạng tấm, tờ hoặc dải.',
    Unit: 'kg',
  },
  {
    HsCode: '40040000',
    Description: 'Phế liệu, phế thải và mảnh vụn từ cao su (trừ cao su cứng) và bột và hạt thu được từ chúng',
    Unit: 'kg',
  },
  {
    HsCode: '4005',
    Description: 'Cao su hỗn hợp, chưa lưu hóa, ở dạng nguyên sinh hoặc dạng tấm, tờ hoặc dải',
    Unit: '',
  },
  {
    HsCode: '400510',
    Description: '- Hỗn hợp với muội carbon hoặc silica:',
    Unit: '',
  },
  {
    HsCode: '40051010',
    Description: '- - Của nhựa tự nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '40051090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '40052000',
    Description: '- Dạng hòa tan; dạng phân tán trừ các sản phẩm thuộc phân nhóm 4005.10',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '400591',
    Description: '- - Dạng tấm, tờ và dải:',
    Unit: '',
  },
  {
    HsCode: '40059110',
    Description: '- - - Của nhựa tự nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '40059190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '400599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40059910',
    Description: '- - - Dạng latex (dạng mủ cao su)',
    Unit: 'kg',
  },
  {
    HsCode: '40059920',
    Description: '- - - Cao su tự nhiên được pha trộn với các chất trừ carbon hoặc silica',
    Unit: 'kg',
  },
  {
    HsCode: '40059990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4006',
    Description:
      'Các dạng khác (ví dụ, thanh, ống và dạng hình) và các sản phẩm khác (ví dụ, đĩa và vòng), bằng cao su chưa lưu hóa',
    Unit: '',
  },
  {
    HsCode: '40061000',
    Description: '- Dải ""camel-back"" dùng để đắp lại lốp cao su',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '400690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của nhựa tự nhiên:',
    Unit: '',
  },
  {
    HsCode: '40069011',
    Description: '- - - Các sản phẩm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40069019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40069090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40070000',
    Description: 'Chỉ sợi và dây bện bằng cao su lưu hóa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4008',
    Description: 'Tấm, tờ, dải, thanh và dạng hình, bằng cao su lưu hóa trừ cao su cứng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ cao su xốp:',
    Unit: '',
  },
  {
    HsCode: '400811',
    Description: '- - Dạng tấm, tờ và dải:',
    Unit: '',
  },
  {
    HsCode: '40081110',
    Description: '- - - Chiều dày trên 5 mm, có một mặt được lót vải dệt',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '40081120',
    Description: '- - - Loại khác, miếng, tấm ghép với nhau để lát nền và ốp tường',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '40081190',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '40081900',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ cao su không xốp:',
    Unit: '',
  },
  {
    HsCode: '400821',
    Description: '- - Dạng tấm, tờ và dải:',
    Unit: '',
  },
  {
    HsCode: '40082110',
    Description: '- - - Chiều dày trên 5 mm, có một mặt được lót vải dệt',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '40082120',
    Description: '- - - Loại khác, miếng, tấm ghép với nhau để lát nền và ốp tường',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '40082130',
    Description: '- - - Băng chắn nước (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40082140',
    Description: '- - - Tờ cao su dùng làm đế giày',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40082190',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '40082900',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '4009',
    Description:
      'Các loại ống, ống dẫn và ống vòi, bằng cao su lưu hóa trừ cao su cứng, có hoặc không kèm theo các phụ kiện để ghép nối (ví dụ, các đoạn nối, khớp, khuỷu, vành đệm)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa gia cố hoặc kết hợp với các vật liệu khác:',
    Unit: '',
  },
  {
    HsCode: '40091100',
    Description: '- - Không kèm phụ kiện ghép nối',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '400912',
    Description: '- - Có kèm phụ kiện ghép nối:',
    Unit: '',
  },
  {
    HsCode: '40091210',
    Description: '- - - Ống hút và xả bùn dùng trong khai thác mỏ(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40091290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Đã gia cố hoặc kết hợp duy nhất với kim loại:',
    Unit: '',
  },
  {
    HsCode: '400921',
    Description: '- - Không kèm phụ kiện ghép nối:',
    Unit: '',
  },
  {
    HsCode: '40092110',
    Description: '- - - Ống hút và xả bùn dùng trong khai thác mỏ(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40092190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '400922',
    Description: '- - Có kèm phụ kiện ghép nối:',
    Unit: '',
  },
  {
    HsCode: '40092210',
    Description: '- - - Ống hút và xả bùn dùng trong khai thác mỏ(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40092290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Đã gia cố hoặc kết hợp duy nhất với vật liệu dệt:',
    Unit: '',
  },
  {
    HsCode: '400931',
    Description: '- - Không kèm phụ kiện ghép nối:',
    Unit: '',
  },
  {
    HsCode: '40093110',
    Description: '- - - Ống hút và xả bùn dùng trong khai thác mỏ(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40093191',
    Description:
      '- - - - Ống dẫn nhiên liệu, ống dẫn nhiệt và ống dẫn nước, dùng cho xe gắn động cơ thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40093199',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '400932',
    Description: '- - Có kèm phụ kiện ghép nối:',
    Unit: '',
  },
  {
    HsCode: '40093210',
    Description: '- - - Ống hút và xả bùn dùng trong khai thác mỏ(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40093290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Đã gia cố hoặc kết hợp với vật liệu khác:',
    Unit: '',
  },
  {
    HsCode: '400941',
    Description: '- - Không kèm phụ kiện ghép nối:',
    Unit: '',
  },
  {
    HsCode: '40094110',
    Description: '- - - Ống vòi cao su dùng cho bếp ga (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40094190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '400942',
    Description: '- - Có kèm phụ kiện ghép nối:',
    Unit: '',
  },
  {
    HsCode: '40094210',
    Description: '- - - Ống hút và xả bùn dùng trong khai thác mỏ(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40094220',
    Description: '- - - Ống vòi cao su dùng cho bếp ga (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40094290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4010',
    Description: 'Băng tải hoặc đai tải hoặc băng truyền (dây cu roa) hoặc đai truyền, bằng cao su lưu hóa',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Băng tải hoặc đai tải:',
    Unit: '',
  },
  {
    HsCode: '40101100',
    Description: '- - Chỉ được gia cố bằng kim loại',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40101200',
    Description: '- - Chỉ được gia cố bằng vật liệu dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40101900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Băng truyền hoặc đai truyền:',
    Unit: '',
  },
  {
    HsCode: '40103100',
    Description:
      '- - Băng truyền liên tục có mặt cắt ngang hình thang (băng chữ V), có gân hình chữ V, với chu vi ngoài trên 60 cm nhưng không quá 180 cm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40103200',
    Description:
      '- - Băng truyền liên tục có mặt cắt ngang hình thang (băng chữ V), trừ băng truyền có gân hình chữ V, với chu vi ngoài trên 60 cm nhưng không quá 180 cm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40103300',
    Description:
      '- - Băng truyền liên tục có mặt cắt ngang hình thang (băng chữ V), có gân hình chữ V, với chu vi ngoài trên 180 cm nhưng không quá 240 cm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40103400',
    Description:
      '- - Băng truyền liên tục có mặt cắt ngang hình thang (băng chữ V), trừ băng truyền có gân hình chữ V, với chu vi ngoài trên 180 cm nhưng không quá 240 cm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40103500',
    Description: '- - Băng truyền đồng bộ liên tục, có chu vi ngoài trên 60 cm nhưng không quá 150 cm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40103600',
    Description: '- - Băng truyền đồng bộ liên tục, có chu vi ngoài trên 150 cm nhưng không quá 198 cm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40103900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4011',
    Description: 'Lốp bằng cao su loại bơm hơi, chưa qua sử dụng',
    Unit: '',
  },
  {
    HsCode: '40111000',
    Description:
      '- Loại sử dụng cho ô tô con (motor car) (kể cả loại ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua)',
    Unit: 'chiếc',
  },
  {
    HsCode: '401120',
    Description: '- Loại dùng cho ô tô khách (buses) (1) hoặc ô tô chở hàng (lorries):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Chiều rộng không quá 450 mm:',
    Unit: '',
  },
  {
    HsCode: '40112011',
    Description: '- - - Có chiều rộng không quá 230 mm và lắp vành có đường kính không quá 16 inch (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '40112012',
    Description: '- - - Có chiều rộng không quá 230 mm và lắp vành có đường kính trên 16 inch (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '40112013',
    Description: '- - - Có chiều rộng trên 230 mm nhưng không quá 385 mm (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '40112019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40112090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40113000',
    Description: '- Loại sử dụng cho phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '40114000',
    Description: '- Loại dùng cho xe môtô',
    Unit: 'chiếc',
  },
  {
    HsCode: '40115000',
    Description: '- Loại dùng cho xe đạp',
    Unit: 'chiếc',
  },
  {
    HsCode: '40117000',
    Description: '- Loại dùng cho xe và máy nông nghiệp hoặc lâm nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '401180',
    Description: '- Loại dùng cho xe và máy xây dựng, khai thác mỏ hoặc xếp dỡ công nghiệp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' - - Lắp vành có đường kính không quá 24 inch:',
    Unit: '',
  },
  {
    HsCode: '40118011',
    Description:
      '- - - Loại dùng cho xe kéo, máy thuộc nhóm 84.29 hoặc 84.30, xe nâng hàng, xe cút kít hoặc xe và máy xếp dỡ công nghiệp khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40118019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Lắp vành có đường kính trên 24 inch, loại dùng cho xe kéo, máy thuộc nhóm 84.29 hoặc 84.30, xe nâng hàng hoặc xe và máy xếp dỡ công nghiệp khác:',
    Unit: '',
  },
  {
    HsCode: '40118031',
    Description: '- - - Loại dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '40118039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40118040',
    Description: '- - Lắp vành có đường kính trên 24 inch, loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '401190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40119010',
    Description: '- - Loại dùng cho xe thuộc Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '40119020',
    Description: '- - Loại dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '40119090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '4012',
    Description:
      'Lốp bằng cao su loại bơm hơi đã qua sử dụng hoặc đắp lại; lốp đặc hoặc nửa đặc, hoa lốp và lót vành, bằng cao su',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Lốp đắp lại:',
    Unit: '',
  },
  {
    HsCode: '40121100',
    Description:
      '- - Loại sử dụng cho ô tô con (motor car) (kể cả loại ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua)',
    Unit: 'chiếc',
  },
  {
    HsCode: '401212',
    Description: '- - Loại dùng cho ô tô khách (buses) (1) hoặc ô tô chở hàng (lorries):',
    Unit: '',
  },
  {
    HsCode: '40121210',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40121290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40121300',
    Description: '- - Loại sử dụng cho phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '401219',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40121920',
    Description: '- - - Loại dùng cho máy thuộc nhóm 84.27',
    Unit: 'chiếc',
  },
  {
    HsCode: '40121930',
    Description: '- - - Loại dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng cho xe thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '40121941',
    Description: '- - - - Loại dùng cho máy kéo nông nghiệp hoặc lâm nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '40121949',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40121990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '401220',
    Description: '- Lốp loại bơm hơi đã qua sử dụng: ',
    Unit: '',
  },
  {
    HsCode: '40122010',
    Description:
      '- - Loại sử dụng cho ô tô con (motor car) (kể cả loại ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng cho ô tô khách (buses) (1) hoặc ô tô chở hàng (lorries):',
    Unit: '',
  },
  {
    HsCode: '40122021',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122030',
    Description: '- - Loại sử dụng cho phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122040',
    Description: '- - Loại dùng cho xe môtô',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122050',
    Description: '- - Loại dùng cho xe đạp',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122060',
    Description: '- - Loại dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng cho các xe khác thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '40122071',
    Description: '- - - Loại dùng cho máy kéo nông nghiệp hoặc lâm nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122079',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122080',
    Description: '- - Loại dùng cho máy thuộc nhóm 84.27',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40122091',
    Description: '- - - Lốp trơn (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '40122099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '401290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Lốp đặc:',
    Unit: '',
  },
  {
    HsCode: '40129014',
    Description: '- - - Lốp đặc có đường kính ngoài trên 250 mm, chiều rộng không quá 450 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40129017',
    Description: '- - - Lốp đặc có đường kính ngoài trên 250 mm, chiều rộng trên 450 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40129019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Lốp nửa đặc:',
    Unit: '',
  },
  {
    HsCode: '40129021',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40129022',
    Description: '- - - Có chiều rộng trên 450 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Hoa lốp đắp lại:',
    Unit: '',
  },
  {
    HsCode: '40129071',
    Description: '- - - Chiều rộng không quá 450 mm (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40129072',
    Description: '- - - Có chiều rộng trên 450 mm (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40129080',
    Description: '- - Lót vành',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40129090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4013',
    Description: 'Săm các loại, bằng cao su',
    Unit: '',
  },
  {
    HsCode: '401310',
    Description:
      '- Loại dùng cho ô tô con (motor car) (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua), ô tô khách (1) hoặc ô tô chở hàng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Loại dùng cho ô tô con (motor car) (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua):',
    Unit: '',
  },
  {
    HsCode: '40131011',
    Description: '- - - Dùng cho loại lốp có chiều rộng không quá 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40131019',
    Description: '- - - Dùng cho loại lốp có chiều rộng trên 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng cho ô tô khách (buses) (1) hoặc ô tô chở hàng (lorries):',
    Unit: '',
  },
  {
    HsCode: '40131021',
    Description: '- - - Dùng cho loại lốp có chiều rộng không quá 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40131029',
    Description: '- - - Dùng cho loại lốp có chiều rộng trên 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40132000',
    Description: '- Loại dùng cho xe đạp',
    Unit: 'chiếc',
  },
  {
    HsCode: '401390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng cho máy thuộc nhóm 84.29 hoặc 84.30:',
    Unit: '',
  },
  {
    HsCode: '40139011',
    Description: '- - - Dùng cho loại lốp có chiều rộng không quá 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40139019',
    Description: '- - - Dùng cho loại lốp có chiều rộng trên 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40139020',
    Description: '- - Loại dùng cho xe môtô',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng cho các xe khác thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '40139031',
    Description: '- - - Dùng cho loại lốp có chiều rộng không quá 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40139039',
    Description: '- - - Dùng cho loại lốp có chiều rộng trên 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40139040',
    Description: '- - Loại sử dụng cho phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40139091',
    Description: '- - - Dùng cho loại lốp có chiều rộng không quá 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '40139099',
    Description: '- - - Dùng cho loại lốp có chiều rộng trên 450 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '4014',
    Description:
      'Sản phẩm vệ sinh hoặc y tế (kể cả núm vú cao su), bằng cao su lưu hóa trừ cao su cứng, có hoặc không kèm theo các phụ kiện để ghép nối bằng cao su cứng',
    Unit: '',
  },
  {
    HsCode: '40141000',
    Description: '- Bao tránh thai',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '401490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40149010',
    Description: '- - Núm vú của bình cho trẻ em ăn và các loại tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40149040',
    Description: '- - Nút đậy dùng cho dược phẩm ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40149050',
    Description: '- - Bao ngón tay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40149090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4015',
    Description:
      'Sản phẩm may mặc và đồ phụ trợ may mặc (kể cả găng tay, găng hở ngón và găng bao tay), dùng cho mọi mục đích, bằng cao su lưu hóa trừ cao su cứng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Găng tay, găng tay hở ngón và găng bao tay:',
    Unit: '',
  },
  {
    HsCode: '401512',
    Description: '- - Loại dùng cho các mục đích y tế, phẫu thuật, nha khoa hoặc thú y:',
    Unit: '',
  },
  {
    HsCode: '40151210',
    Description: '- - - Bằng cao su tự nhiên lưu hóa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40151290',
    Description: '- - - Bằng cao su lưu hóa khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '401519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40151910',
    Description: '- - - Bằng cao su tự nhiên lưu hóa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40151990',
    Description: '- - - Bằng cao su lưu hóa khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '401590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40159010',
    Description: '- - Tạp dề chì để chống phóng xạ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40159020',
    Description: '- - Bộ đồ của thợ lặn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40159090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4016',
    Description: 'Các sản phẩm khác bằng cao su lưu hóa trừ cao su cứng',
    Unit: '',
  },
  {
    HsCode: '401610',
    Description: '- Từ cao su xốp:',
    Unit: '',
  },
  {
    HsCode: '40161010',
    Description: '- - Lót, đệm cho các sản phẩm may mặc hoặc cho các đồ phụ trợ của quần áo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40161020',
    Description: '- - Tấm, miếng ghép với nhau để lát nền và ốp tường',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40161090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '401691',
    Description: '- - Tấm phủ sàn và tấm, đệm trải sàn (mat):',
    Unit: '',
  },
  {
    HsCode: '40169110',
    Description: '- - - Tấm, đệm (mat)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169120',
    Description: '- - - Dạng tấm rời để ghép',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '401692',
    Description: '- - Tẩy:',
    Unit: '',
  },
  {
    HsCode: '40169210',
    Description: '- - - Đầu tẩy (eraser tips)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '401693',
    Description: '- - Miếng đệm, vòng đệm và các miếng chèn khác:',
    Unit: '',
  },
  {
    HsCode: '40169310',
    Description: '- - - Loại dùng để cách điện cho các chân tụ điện (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169320',
    Description:
      '- - - Miếng đệm và vành khung tròn, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169400',
    Description: '- - Đệm chống va cho tàu thuyền hoặc ụ tàu, có hoặc không thể bơm hơi',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169500',
    Description: '- - Các sản phẩm có thể bơm hơi khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '401699',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Bộ phận và phụ kiện dùng cho xe thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '40169911',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.05, trừ viền cao su quanh kính cửa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169912',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.11',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169913',
    Description: '- - - - Viền cao su quanh kính cửa, dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169915',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.09, 87.13, 87.15 hoặc 87.16',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169916',
    Description: '- - - - Chắn bùn xe đạp (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169917',
    Description: '- - - - Bộ phận của xe đạp (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169918',
    Description: '- - - - Phụ kiện khác của xe đạp (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169919',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169920',
    Description: '- - - Bộ phận và phụ kiện của dù xoay thuộc nhóm 88.04',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169930',
    Description: '- - - Dải cao su',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169940',
    Description: '- - - Tấm, miếng rời ghép với nhau để ốp tường',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Các sản phẩm khác sử dụng cho máy hoặc các thiết bị điện hoặc cơ khí, hoặc cho các mục đích kỹ thuật khác:',
    Unit: '',
  },
  {
    HsCode: '40169951',
    Description: '- - - - Trục lăn cao su',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169952',
    Description: '- - - - Cốt làm lốp (Tyre mould bladders)(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169953',
    Description: '- - - - Nắp chụp cách điện',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169954',
    Description: '- - - - Vòng và vỏ bọc bằng cao su sử dụng cho hệ thống dây điện của ô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169959',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169960',
    Description: '- - - Lót đường ray (rail pad)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169970',
    Description: '- - - Loại chịu lực xây dựng kể cả lực cầu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '40169991',
    Description: '- - - - Khăn trải bàn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40169999',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4017',
    Description: 'Cao su cứng (ví dụ, ebonit) ở các dạng, kể cả phế liệu và phế thải; các sản phẩm bằng cao su cứng',
    Unit: '',
  },
  {
    HsCode: '40170010',
    Description: '- Tấm, miếng ghép với nhau để lát nền và ốp tường',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40170020',
    Description: '- Các sản phẩm bằng cao su cứng khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '40170090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 9384:2012 và TCVN 7271:2003',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN VIII',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'DA SỐNG, DA THUỘC, DA LÔNG VÀ CÁC SẢN PHẨM TỪ DA; YÊN CƯƠNG VÀ BỘ ĐỒ YÊN CƯƠNG; HÀNG DU LỊCH, TÚI XÁCH TAY VÀ CÁC LOẠI ĐỒ CHỨA TƯƠNG TỰ; CÁC MẶT HÀNG TỪ RUỘT ĐỘNG VẬT (TRỪ RUỘT CON TẰM)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 41',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Da sống (trừ da lông) và da thuộc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Da vụn hoặc phế liệu tương tự từ da sống (nhóm 05.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Da chim hoặc các phần da chim, còn lông vũ hoặc lông tơ, thuộc nhóm 05.05 hoặc nhóm 67.01; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Da sống, còn lông, đã thuộc hoặc chuội (Chương 43); tuy nhiên, những sản phẩm sau vẫn được xếp vào Chương 41, cụ thể, các loại da sống còn lông, của động vật họ trâu bò (kể cả trâu), động vật họ ngựa, cừu hoặc cừu non (trừ các loại cừu Astrakhan, cừu Broadtail, Caracul, cừu Ba Tư hoặc loại tương tự, cừu Ấn Độ, cừu Trung Quốc, cừu Mông cổ hoặc cừu Tây Tạng) hoặc thuộc loài dê hoặc dê non (trừ dê và dê non Yemen, Mông Cổ hoặc Tây Tạng), lợn (kể cả lợn lòi Pecari), sơn dương, linh dương gazen, lạc đà (kể cả lạc đà một bướu), tuần lộc, nai Anxet, hươu, hoẵng hoặc chó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. (A) Các nhóm từ 41.04 đến 41.06 không bao gồm da sống đã qua quy trình thuộc (kể cả chuẩn bị thuộc da) có thể lộn được (các nhóm từ 41.01 đến 41.03, trong trường hợp có thể).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Theo mục đích của các nhóm từ 41.04 đến 41.06, thuật ngữ “da mộc” kể cả da sống đã được thuộc lại, tạo màu hoặc được thấm nhũ tương dầu (thêm chất béo) trước khi làm khô.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Trong toàn bộ Danh mục khái niệm “da tổng hợp” chỉ các chất liệu quy định trong nhóm 41.15.',
    Unit: '',
  },
  {
    HsCode: '4101',
    Description:
      'Da sống của động vật họ trâu bò (kể cả trâu) hoặc động vật họ ngựa (tươi, hoặc muối, khô, ngâm vôi, axit hóa hoặc được bảo quản cách khác, nhưng chưa thuộc, chưa làm thành da giấy hoặc gia công thêm), đã hoặc chưa khử lông hoặc lạng xẻ',
    Unit: '',
  },
  {
    HsCode: '41012000',
    Description:
      '- Da sống nguyên con, chưa xẻ, khối lượng da một con không quá 8 kg khi làm khô đơn giản, 10 kg khi muối khô, hoặc 16 kg ở dạng tươi, dạng muối ướt hoặc được bảo quản cách khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '41015000',
    Description: '- Da sống nguyên con, khối lượng trên 16 kg',
    Unit: 'kg/m',
  },
  {
    HsCode: '410190',
    Description: '- Loại khác, kể cả da mông, khuỷu và bụng:',
    Unit: '',
  },
  {
    HsCode: '41019010',
    Description: '- - Đã được chuẩn bị để thuộc',
    Unit: 'kg/m',
  },
  {
    HsCode: '41019090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '4102',
    Description:
      'Da sống của cừu hoặc cừu non (tươi, hoặc muối, khô, ngâm vôi, axit hóa hoặc được bảo quản cách khác, nhưng chưa thuộc, chưa làm thành da giấy hoặc gia công thêm), có hoặc không còn lông hoặc lạng xẻ, trừ các loại đã ghi ở Chú giải 1(c) của Chương này',
    Unit: '',
  },
  {
    HsCode: '41021000',
    Description: '- Loại còn lông',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại không còn lông:',
    Unit: '',
  },
  {
    HsCode: '41022100',
    Description: '- - Đã được axit hóa',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41022900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4103',
    Description:
      'Da sống của loài động vật khác (tươi, hoặc muối, khô, ngâm vôi, axit hóa hoặc được bảo quản cách khác, nhưng chưa thuộc, chưa làm thành da giấy hoặc gia công thêm), đã hoặc chưa khử lông hoặc lạng xẻ, trừ các loại đã loại trừ trong Chú giải 1(b) hoặc 1(c) của Chương này',
    Unit: '',
  },
  {
    HsCode: '41032000',
    Description: '- Của loài bò sát',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41033000',
    Description: '- Của lợn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41039000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4104',
    Description:
      'Da thuộc hoặc da mộc của động vật họ trâu bò (kể cả trâu) hoặc động vật họ ngựa, không có lông, đã hoặc chưa lạng xẻ, nhưng chưa được gia công thêm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ở dạng ướt (kể cả phèn xanh):',
    Unit: '',
  },
  {
    HsCode: '410411',
    Description: '- - Da cật, chưa xẻ; da váng có mặt cật (da lộn):',
    Unit: '',
  },
  {
    HsCode: '41041110',
    Description: '- - - Của động vật họ trâu bò, đã thuộc thực vật',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41041190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41041900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Ở dạng khô (mộc):',
    Unit: '',
  },
  {
    HsCode: '41044100',
    Description: '- - Da cật, chưa xẻ; da váng có mặt cật (da lộn)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41044900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4105',
    Description:
      'Da thuộc hoặc da mộc của cừu hoặc cừu non, không có lông, đã hoặc chưa lạng xẻ, nhưng chưa được gia công thêm',
    Unit: '',
  },
  {
    HsCode: '41051000',
    Description: '- Ở dạng ướt (kể cả phèn xanh)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41053000',
    Description: '- Ở dạng khô (mộc)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4106',
    Description:
      'Da thuộc hoặc da mộc của các loài động vật khác, không có lông, đã hoặc chưa xẻ, nhưng chưa được gia công thêm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Của dê hoặc dê non:',
    Unit: '',
  },
  {
    HsCode: '41062100',
    Description: '- - Ở dạng ướt (kể cả phèn xanh)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41062200',
    Description: '- - Ở dạng khô (mộc)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Của lợn:',
    Unit: '',
  },
  {
    HsCode: '41063100',
    Description: '- - Ở dạng ướt (kể cả phèn xanh)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41063200',
    Description: '- - Ở dạng khô (mộc)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41064000',
    Description: '- Của loài bò sát',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '41069100',
    Description: '- - Ở dạng ướt (kể cả phèn xanh)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41069200',
    Description: '- - Ở dạng khô (mộc)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4107',
    Description:
      'Da thuộc đã được gia công thêm sau khi thuộc hoặc làm mộc, kể cả da giấy, của động vật họ trâu bò (kể cả trâu) hoặc của động vật họ ngựa, không có lông, đã hoặc chưa xẻ, trừ da thuộc nhóm 41.14',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Da nguyên con:',
    Unit: '',
  },
  {
    HsCode: '41071100',
    Description: '- - Da cật, chưa xẻ',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41071200',
    Description: '- - Da váng có mặt cật (da lộn)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41071900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác, kể cả nửa con:',
    Unit: '',
  },
  {
    HsCode: '41079100',
    Description: '- - Da cật, chưa xẻ',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41079200',
    Description: '- - Da váng có mặt cật (da lộn)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41079900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41120000',
    Description:
      'Da thuộc đã được gia công thêm sau khi thuộc hoặc làm mộc, kể cả da giấy, của cừu hoặc cừu non, không có lông, đã hoặc chưa xẻ, trừ da thuộc nhóm 41.14',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4113',
    Description:
      'Da thuộc đã được gia công thêm sau khi thuộc hoặc làm mộc, kể cả da giấy, của các loài động vật khác, không có lông, đã hoặc chưa xẻ, trừ da thuộc nhóm 41.14',
    Unit: '',
  },
  {
    HsCode: '41131000',
    Description: '- Của dê hoặc dê non',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41132000',
    Description: '- Của lợn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41133000',
    Description: '- Của loài bò sát',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41139000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4114',
    Description:
      'Da thuộc dầu (kể cả da thuộc dầu kết hợp); da láng và da láng bằng màng mỏng được tạo trước; da nhũ kim loại',
    Unit: '',
  },
  {
    HsCode: '41141000',
    Description: '- Da thuộc dầu (kể cả da thuộc dầu kết hợp)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41142000',
    Description: '- Da láng và da láng bằng màng mỏng được tạo trước; da nhũ kim loại',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '4115',
    Description:
      'Da thuộc tổng hợp với thành phần cơ bản là da thuộc hoặc sợi da thuộc, dạng tấm, tấm mỏng hoặc dạng dải, có hoặc không ở dạng cuộn; da vụn và phế liệu khác từ da thuộc hoặc da tổng hợp, không phù hợp dùng cho sản xuất hàng da; bụi da và các loại bột da',
    Unit: '',
  },
  {
    HsCode: '41151000',
    Description:
      '- Da thuộc tổng hợp với thành phần cơ bản là da thuộc hoặc sợi da thuộc, dạng tấm, tấm mỏng hoặc dạng dải, có hoặc không ở dạng cuộn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '41152000',
    Description:
      '- Da vụn và phế liệu khác từ da thuộc hoặc da tổng hợp, không phù hợp dùng cho sản xuất hàng da; bụi da, các loại bột da',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: 'Chương 42',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm bằng da thuộc; yên cương và bộ yên cương; các mặt hàng du lịch, túi xách và các loại đồ chứa tương tự; các sản phẩm làm từ ruột động vật (trừ ruột con tằm)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của chương này, thuật ngữ ""da thuộc"" gồm cả da thuộc dầu (bao gồm cả da thuộc dầu kết hợp), da láng, da láng bằng màng mỏng được tạo trước và da nhũ kim loại.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Chỉ catgut vô trùng dùng trong phẫu thuật hoặc các vật liệu khâu vô trùng tương tự dùng trong phẫu thuật (nhóm 30.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Hàng may mặc hoặc đồ phụ trợ quần áo (trừ găng tay, găng hở ngón và găng bao tay), được lót bằng da lông hoặc da lông nhân tạo hoặc gắn da lông hoặc da lông nhân tạo ở mặt ngoài trừ đồ trang trí thuần túy (nhóm 43.03 hoặc 43.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các loại lưới hoàn chỉnh (nhóm 56.08);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các mặt hàng thuộc Chương 64;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Mũ và các vật đội đầu khác hoặc các bộ phận của chúng thuộc Chương 65;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Roi da, roi điều khiển súc vật hoặc các mặt hàng khác thuộc nhóm 66.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Khuy măng sét, vòng tay hoặc các đồ trang sức làm bằng chất liệu khác (nhóm 71.17);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Các đồ gắn trang trí cho bộ đồ yên cương, như bàn đạp ở yên cương ngựa, hàm thiếc ngựa, yếm ngựa và khóa yên ngựa, được trình bày riêng (thường thuộc Phần XV);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Dây da, da để làm trống hoặc loại tương tự, hoặc các bộ phận khác của nhạc cụ (nhóm 92.09);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các sản phẩm thuộc Chương 94 (ví dụ, đồ nội thất, đèn (luminaires) và bộ đèn);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(l) Các sản phẩm thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(m) Khuy các loại, khuy bấm, khóa bấm, khuy tán bấm, lõi khuy (loại khuy bọc) hoặc bộ phận khác của các mặt hàng này, khuy chưa hoàn chỉnh, thuộc nhóm 96.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. (A) Ngoài các quy định của Chú giải 2 nói trên, nhóm 42.02 không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Túi làm bằng các tấm plastic, đã hoặc chưa in, có tay cầm, không được thiết kế để sử dụng lâu dài (nhóm 39.23);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Sản phẩm bằng vật liệu tết bện (nhóm 46.02).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Các sản phẩm của nhóm 42.02 và 42.03 có các bộ phận làm bằng kim loại quý hoặc kim loại mạ kim loại quý, ngọc trai tự nhiên hoặc nuôi cấy, đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo) cũng được phân loại vào nhóm này ngay cả khi các bộ phận như vậy không chỉ ghép nối hoặc trang trí đơn giản, miễn là chúng không làm cho sản phẩm có thêm đặc tính khác. Trong trường hợp, các bộ phận đó tạo ra đặc tính cho sản phẩm thì sản phẩm đó được xếp vào Chương 71.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo mục đích của nhóm 42.03, không kể những đề cập khác, thuật ngữ “hàng may mặc và đồ phụ trợ quần áo” áp dụng đối với các loại găng tay, găng hở ngón và găng bao tay (kể cả các loại găng dùng trong thể thao và bảo hộ), tạp dề và các loại quần áo bảo hộ khác, thắt lưng, dây đeo quần, dây đeo súng và đai da cổ tay, nhưng trừ dây đeo đồng hồ (nhóm 91.13).',
    Unit: '',
  },
  {
    HsCode: '42010000',
    Description:
      'Yên cương và bộ yên cương dùng cho các loại động vật (kể cả dây kéo, dây dắt, miếng đệm đầu gối, đai hoặc rọ bịt mõm, vải lót yên, túi yên, áo chó và các loại tương tự), làm bằng vật liệu bất kỳ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '4202',
    Description:
      'Hòm, vali, xắc đựng đồ nữ trang, cặp tài liệu, cặp sách, túi, cặp đeo vai cho học sinh, bao kính, bao ống nhòm, hộp camera, hộp nhạc cụ, bao súng, bao súng ngắn và các loại đồ chứa tương tự; túi du lịch, túi đựng đồ ăn hoặc đồ uống có phủ lớp cách, túi đựng đồ vệ sinh cá nhân, ba lô, túi xách tay, túi đi chợ, xắc cốt, ví, túi đựng bản đồ, hộp đựng thuốc lá điếu, hộp đựng thuốc lá sợi, túi đựng dụng cụ, túi thể thao, túi đựng chai rượu, hộp đựng đồ trang sức, hộp đựng phấn, hộp đựng dao kéo và các loại đồ chứa tương tự bằng da thuộc hoặc da thuộc tổng hợp, bằng tấm plastic, bằng vật liệu dệt, bằng sợi lưu hóa hoặc bằng bìa, hoặc được phủ toàn bộ hoặc chủ yếu bằng các vật liệu trên hoặc bằng giấy',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Hòm, vali, xắc đựng đồ nữ trang, cặp tài liệu, cặp sách, túi, cặp đeo vai cho học sinh và các loại đồ chứa tương tự:',
    Unit: '',
  },
  {
    HsCode: '420211',
    Description: '- - Mặt ngoài bằng da thuộc hoặc da thuộc tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '42021110',
    Description: '- - - Vali hoặc túi, cặp có kích thước tối đa 56cm x 45cm x 25cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '42021190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '420212',
    Description: '- - Mặt ngoài bằng plastic hoặc vật liệu dệt:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Túi, cặp đeo vai cho học sinh:',
    Unit: '',
  },
  {
    HsCode: '42021211',
    Description: '- - - - Mặt ngoài bằng sợi lưu hóa(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '42021219',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '42021291',
    Description: '- - - - Mặt ngoài bằng sợi lưu hóa',
    Unit: 'chiếc',
  },
  {
    HsCode: '42021299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '420219',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '42021920',
    Description: '- - - Mặt ngoài bằng bìa',
    Unit: 'chiếc',
  },
  {
    HsCode: '42021990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Túi xách tay, có hoặc không có quai đeo vai, kể cả loại không có tay cầm:',
    Unit: '',
  },
  {
    HsCode: '42022100',
    Description: '- - Mặt ngoài bằng da thuộc hoặc da thuộc tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '420222',
    Description: '- - Mặt ngoài bằng tấm plastic hoặc vật liệu dệt:',
    Unit: '',
  },
  {
    HsCode: '42022210',
    Description: '- - - Mặt ngoài bằng tấm plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '42022220',
    Description: '- - - Mặt ngoài bằng vật liệu dệt',
    Unit: 'chiếc',
  },
  {
    HsCode: '42022900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các đồ vật thuộc loại thường mang theo trong túi hoặc trong túi xách tay:',
    Unit: '',
  },
  {
    HsCode: '42023100',
    Description: '- - Mặt ngoài bằng da thuộc hoặc da thuộc tổng hợp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42023200',
    Description: '- - Mặt ngoài bằng tấm plastic hoặc vật liệu dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42023900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '420291',
    Description: '- - Mặt ngoài bằng da thuộc hoặc da thuộc tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Túi đựng đồ thể thao:',
    Unit: '',
  },
  {
    HsCode: '42029111',
    Description: '- - - - Túi đựng đồ Bowling',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42029119',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42029190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '420292',
    Description: '- - Mặt ngoài bằng tấm plastic hoặc vật liệu dệt:',
    Unit: '',
  },
  {
    HsCode: '42029210',
    Description: '- - - Túi đựng đồ vệ sinh cá nhân, bằng tấm plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42029220',
    Description: '- - - Túi đựng đồ Bowling',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42029290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '420299',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '42029910',
    Description: '- - - Mặt ngoài bằng sợi lưu hóa hoặc bìa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42029990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4203',
    Description: 'Hàng may mặc và đồ phụ trợ quần áo, bằng da thuộc hoặc bằng da thuộc tổng hợp',
    Unit: '',
  },
  {
    HsCode: '42031000',
    Description: '- Hàng may mặc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Găng tay, găng tay hở ngón và găng bao tay:',
    Unit: '',
  },
  {
    HsCode: '420321',
    Description: '- - Loại thiết kế đặc biệt dùng cho thể thao:',
    Unit: '',
  },
  {
    HsCode: '42032110',
    Description: '- - - Găng tay bóng chày và găng tay bóng mềm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42032190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '420329',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '42032910',
    Description: '- - - Găng tay bảo hộ lao động',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42032990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42033000',
    Description: '- Thắt lưng và dây đeo súng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42034000',
    Description: '- Đồ phụ trợ quần áo khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4205',
    Description: 'Sản phẩm khác bằng da thuộc hoặc da thuộc tổng hợp',
    Unit: '',
  },
  {
    HsCode: '42050010',
    Description: '- Dây buộc giày; tấm lót (mats)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42050020',
    Description: '- Dây đai an toàn và dây bảo vệ dùng trong công nghiệp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42050030',
    Description: '- Dây hoặc dây tết bằng da thuộc dùng cho đồ trang sức hoặc sản phẩm trang điểm cá nhân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42050040',
    Description: '- Sản phẩm khác dùng cho máy móc hoặc thiết bị cơ khí hoặc mục đích kỹ thuật khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42050090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '42060000',
    Description:
      'Sản phẩm làm bằng ruột động vật (trừ ruột con tằm), bằng màng ruột già (dùng trong kỹ thuật dát vàng), bằng bong bóng hoặc bằng gân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 43',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Da lông và da lông nhân tạo; các sản phẩm làm từ da lông và da lông nhân tạo',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong toàn bộ Danh mục những nội dung liên quan đến “da lông”, trừ da lông sống thuộc nhóm 43.01, áp dụng cho da sống còn lông của các loài động vật, đã thuộc hoặc chuội.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Da chim hoặc các phần của da chim, có lông vũ hoặc lông tơ (nhóm 05.05 hoặc 67.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Da sống, còn lông, thuộc Chương 41 (xem Chú giải 1(c) Chương đó);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Găng tay, găng hở ngón và găng bao tay, bằng da thuộc và da lông hoặc bằng da thuộc và da lông nhân tạo (nhóm 42.03);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các mặt hàng thuộc Chương 64;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Mũ và các vật đội đầu khác hoặc các bộ phận của chúng thuộc Chương 65; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các mặt hàng thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Nhóm 43.03 kể cả da lông và các phần da lông, được ghép cùng với vật liệu khác, và da lông và các phần da lông, được may lại với nhau tạo thành quần áo hoặc các chi tiết hoặc phụ trợ quần áo hoặc dưới dạng sản phẩm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Hàng may mặc và phụ trợ quần áo (trừ các sản phẩm mà phần Chú giải 2 đã loại trừ) được lót bằng da lông hoặc da lông nhân tạo hoặc ở mặt ngoài được đính da lông hoặc da lông nhân tạo trừ đồ trang trí thuần túy được xếp vào nhóm 43.03 hoặc 43.04 tùy theo từng trường hợp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Trong toàn bộ Danh mục cụm từ “da lông nhân tạo” là loại giả da lông gồm lông cừu, lông động vật hoặc các loại sợi khác được gắn hoặc may lên da thuộc, lên vải dệt hoặc lên các loại vật liệu khác, nhưng không bao gồm giả da lông làm bằng cách dệt thoi hoặc dệt kim (thông thường, thuộc nhóm 58.01 hoặc 60.01).',
    Unit: '',
  },
  {
    HsCode: '4301',
    Description:
      'Da lông sống (kể cả đầu, đuôi, bàn chân và các mẩu hoặc các mảnh cắt khác, thích hợp cho việc thuộc da lông), trừ da sống trong nhóm 41.01, 41.02 hoặc 41.03',
    Unit: '',
  },
  {
    HsCode: '43011000',
    Description: '- Của loài chồn vizôn, da nguyên con, có hoặc không có đầu, đuôi hoặc bàn chân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '43013000',
    Description:
      '- Của các giống cừu như: Astrakhan, Broadtail, Caracul, Ba Tư và các giống cừu tương tự, cừu Ấn Độ, Trung Quốc, Mông Cổ hoặc Tây Tạng, da nguyên con, có hoặc không có đầu, đuôi hoặc bàn chân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '43016000',
    Description: '- Của loài cáo, da nguyên con, có hoặc không có đầu, đuôi hoặc bàn chân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '43018000',
    Description: '- Của loài động vật khác, da nguyên con, có hoặc không có đầu, đuôi hoặc bàn chân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '43019000',
    Description: '- Đầu, đuôi, bàn chân và các mẩu hoặc các mảnh cắt khác, thích hợp cho việc thuộc da lông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4302',
    Description:
      'Da lông đã thuộc hoặc chuội (kể cả đầu, đuôi, bàn chân và các mẩu hoặc các mảnh cắt khác), đã hoặc chưa ghép nối (không có thêm các vật liệu phụ trợ khác) trừ loại thuộc nhóm 43.03',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Loại da nguyên con, có hoặc không có đầu, đuôi hoặc bàn chân, chưa ghép nối:',
    Unit: '',
  },
  {
    HsCode: '43021100',
    Description: '- - Của loài chồn vizôn',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '43021900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '43022000',
    Description: '- Đầu, đuôi, bàn chân và các mẩu hoặc các mảnh cắt, chưa ghép nối',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '43023000',
    Description: '- Loại da nguyên con và các mẩu hoặc các mảnh cắt của chúng, đã ghép nối',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '4303',
    Description: 'Hàng may mặc, đồ phụ trợ quần áo và các vật phẩm khác bằng da lông',
    Unit: '',
  },
  {
    HsCode: '43031000',
    Description: '- Hàng may mặc và đồ phụ trợ quần áo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '430390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '43039020',
    Description: '- - Sản phẩm dùng cho mục đích công nghiệp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '43039090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4304',
    Description: 'Da lông nhân tạo và các sản phẩm làm bằng da lông nhân tạo',
    Unit: '',
  },
  {
    HsCode: '43040010',
    Description: '- Da lông nhân tạo',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '43040020',
    Description: '- Sản phẩm dùng cho mục đích công nghiệp',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '43040091',
    Description: '- - Túi thể thao',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '43040099',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/m2',
  },
  {
    HsCode: '',
    Description: 'PHẦN IX',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'GỖ VÀ CÁC MẶT HÀNG BẰNG GỖ; THAN TỪ GỖ; LIE VÀ CÁC SẢN PHẨM BẰNG LIE; CÁC SẢN PHẨM TỪ RƠM, CỎ GIẤY HOẶC CÁC VẬT LIỆU TẾT BỆN KHÁC; CÁC SẢN PHẨM BẰNG LIỄU GAI VÀ SONG MÂY',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 44',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Gỗ và các mặt hàng bằng gỗ; than từ gỗ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Gỗ, dưới dạng mảnh, mẩu vụn, vỏ bào, được nghiền hoặc tán nhỏ thành bột, dùng chủ yếu trong công nghệ làm nước hoa, dược phẩm, hoặc thuốc diệt côn trùng, diệt nấm hoặc các mục đích tương tự (nhóm 12.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Tre hoặc các vật liệu khác có tính chất như gỗ loại chủ yếu dùng để tết bện, dạng thô, đã hoặc chưa chẻ, xẻ theo chiều dài hoặc cắt thành từng đoạn (nhóm 14.01);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Gỗ, dưới dạng mảnh, mẩu vụn, vỏ bào, đã được nghiền hoặc tán nhỏ thành bột, dùng chủ yếu cho công nghệ nhuộm hoặc thuộc da (nhóm 14.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Than hoạt tính (nhóm 38.02);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các sản phẩm thuộc nhóm 42.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các mặt hàng thuộc Chương 46;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Giày dép hoặc các bộ phận của chúng thuộc Chương 64;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Các mặt hàng thuộc Chương 66 (ví dụ, ô dù và batoong và các bộ phận của chúng);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Các mặt hàng thuộc nhóm 68.08;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Đồ trang sức làm bằng chất liệu khác thuộc nhóm 71.17;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(l) Các mặt hàng thuộc Phần XVI hoặc Phần XVII (ví dụ, các bộ phận của máy móc, hòm, vỏ, hộp dùng cho máy móc và thiết bị và đồ dùng của thợ đóng xe);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Các mặt hàng thuộc Phần XVIII (ví dụ, vỏ đồng hồ thời gian và nhạc cụ và các bộ phận của chúng);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Các bộ phận của súng (nhóm 93.05);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(o) Các mặt hàng thuộc Chương 94 (ví dụ, đồ nội thất, đèn (luminaires) và bộ đèn, nhà lắp ghép);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(p) Các mặt hàng thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(q) Các mặt hàng thuộc Chương 96 (ví dụ, tẩu hút thuốc và bộ phận của chúng, khuy, bút chì, và chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các sản phẩm tương tự) trừ thân và tay cầm, bằng gỗ, dùng cho các sản phẩm thuộc nhóm 96.03; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(r) Các mặt hàng thuộc Chương 97 (ví dụ, các tác phẩm nghệ thuật).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong Chương này, khái niệm ""gỗ đã được làm tăng độ rắn"" chỉ loại gỗ đã qua xử lý về mặt hóa học hoặc lý học (trong trường hợp loại gỗ này, được cấu tạo bằng các lớp được liên kết với nhau, việc xử lý thêm chỉ để đảm bảo sự liên kết tốt giữa các lớp), và do đó đã tăng tỷ trọng (mật độ) hoặc độ cứng cũng như tăng sức bền cơ học hoặc độ bền khi có tác động hóa học hoặc điện.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các nhóm từ 44.14 đến 44.21 áp dụng đối với các sản phẩm có mô tả tương ứng từ các loại ván dăm hoặc ván tương tự, ván sợi ép, ván ghép hoặc gỗ đã được làm tăng độ rắn, áp dụng tương tự đối với các sản phẩm này từ gỗ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Các mặt hàng thuộc nhóm 44.10, 44.11 hoặc 44.12 có thể được gia công tạo hình như đã đưa ra trong nhóm 44.09, đã được uốn cong, làm sóng, đục lỗ, được cắt hoặc tạo dáng thành các hình dạng khác nhau trừ hình chữ nhật hoặc hình vuông hoặc trải qua bất kỳ thao tác nào khác với điều kiện là không tạo cho chúng những đặc trưng của các mặt hàng thuộc các nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Nhóm 44.17 không áp dụng đối với các dụng cụ có lưỡi, gờ, bề mặt hoặc bộ phận làm việc khác được tạo ra từ vật liệu bất kỳ được chi tiết ở Chú giải 1 của Chương 82.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo Chú giải 1 ở trên và trừ khi có yêu cầu khác, khái niệm ""gỗ"" trong một nhóm của Chương này cũng áp dụng đối với các loại tre và các vật liệu khác có tính chất gỗ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 4401.31, thuật ngữ ""viên gỗ"" có nghĩa là các sản phẩm phụ như vỏ bào, mùn cưa (cutter shavings, sawdust hoặc chips), của công nghiệp chế biến gỗ cơ học, công nghiệp làm đồ nội thất hoặc các quá trình chế biến gỗ khác, đã được đóng thành khối bằng cách ép trực tiếp hoặc thêm chất kết dính với hàm lượng không quá 3% tính theo khối lượng. Những viên gỗ như vậy có hình trụ, với đường kính không quá 25 mm và chiều dài không quá 100 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của phân nhóm 4401.32, thuật ngữ “gỗ đóng bánh” (wood briquettes) có nghĩa là các sản phẩm phụ như vỏ bào, mùn cưa (cutter shavings, sawdust hoặc chips) của công nghiệp chế biến gỗ cơ học, sản xuất đồ nội thất hoặc các quá trình chế biến gỗ khác, đã được đóng thành khối bằng cách ép trực tiếp hoặc thêm chất kết dính với hàm lượng không quá 3% tính theo khối lượng. Những bánh gỗ này (wood briquettes) có dạng khối lập phương, khối đa diện hoặc hình trụ với kích thước mặt cắt ngang tối thiểu lớn hơn 25 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của phân nhóm 4407.13, “S-P-F” dùng để chỉ gỗ có nguồn gốc hỗn hợp từ vân sam, thông và linh sam, trong đó tỷ lệ của mỗi loài khác nhau và chưa được xác định.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo mục đích của phân nhóm 4407.14, “Hem-fir” dùng để chỉ gỗ có nguồn gốc từ hỗn hợp của Western hemlock và linh sam (fir), nơi tỷ lệ của mỗi loài khác nhau và chưa được xác định.',
    Unit: '',
  },
  {
    HsCode: '4401',
    Description:
      'Gỗ nhiên liệu, dạng khúc, thanh nhỏ, cành, bó hoặc các dạng tương tự; vỏ bào hoặc dăm gỗ; mùn cưa, phế liệu và mảnh vụn gỗ, đã hoặc chưa đóng thành khối, bánh (briquettes), viên hoặc các dạng tương tự',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Gỗ nhiên liệu, dạng khúc, thanh nhỏ, cành, bó hoặc các dạng tương tự:',
    Unit: '',
  },
  {
    HsCode: '44011100',
    Description: '- - Từ cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '44011200',
    Description: '- - Từ cây không thuộc loài lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Vỏ bào hoặc dăm gỗ:',
    Unit: '',
  },
  {
    HsCode: '44012100',
    Description: '- - Từ cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '44012200',
    Description: '- - Từ cây không thuộc loài lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- Mùn cưa, phế liệu và mảnh vụn gỗ, đã đóng thành dạng khúc, bánh (briquettes), viên hoặc các dạng tương tự:',
    Unit: '',
  },
  {
    HsCode: '44013100',
    Description: '- - Viên gỗ',
    Unit: 'kg',
  },
  {
    HsCode: '44013200',
    Description: '- - Đóng thành bánh (briquettes)',
    Unit: 'kg',
  },
  {
    HsCode: '44013900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Mùn cưa, phế liệu và mảnh vụn gỗ, chưa đóng thành khối:',
    Unit: '',
  },
  {
    HsCode: '44014100',
    Description: '- - Mùn cưa',
    Unit: 'kg',
  },
  {
    HsCode: '44014900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4402',
    Description: 'Than củi (kể cả than đốt từ vỏ quả hoặc hạt), đã hoặc chưa đóng thành khối',
    Unit: '',
  },
  {
    HsCode: '44021000',
    Description: '- Của tre',
    Unit: 'kg',
  },
  {
    HsCode: '440220',
    Description: '- Của vỏ quả hoặc hạt:',
    Unit: '',
  },
  {
    HsCode: '44022010',
    Description: '- - Than gáo dừa',
    Unit: 'kg',
  },
  {
    HsCode: '44022090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '44029000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4403',
    Description: 'Gỗ cây dạng thô, đã hoặc chưa bóc vỏ hoặc dác gỗ hoặc đẽo vuông thô',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đã xử lý bằng sơn, chất màu, chất creozot hoặc các chất bảo quản khác:',
    Unit: '',
  },
  {
    HsCode: '440311',
    Description: '- - Từ cây lá kim:',
    Unit: '',
  },
  {
    HsCode: '44031110',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44031190',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440312',
    Description: '- - Từ cây không thuộc loài lá kim:',
    Unit: '',
  },
  {
    HsCode: '44031210',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44031290',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Loại khác, từ cây lá kim:',
    Unit: '',
  },
  {
    HsCode: '440321',
    Description: '- - Từ cây thông (Pinus spp.), có kích thước mặt cắt ngang nhỏ nhất từ 15 cm trở lên:',
    Unit: '',
  },
  {
    HsCode: '44032110',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44032190',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440322',
    Description: '- - Từ cây thông (Pinus spp.), loại khác:',
    Unit: '',
  },
  {
    HsCode: '44032210',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44032290',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440323',
    Description:
      '- - Từ cây linh sam (Abies spp.) và vân sam (Picea spp.), có kích thước mặt cắt ngang nhỏ nhất từ 15 cm trở lên:',
    Unit: '',
  },
  {
    HsCode: '44032310',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44032390',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440324',
    Description: '- - Từ cây linh sam (Abies spp.) và cây vân sam (Picea spp.), loại khác:',
    Unit: '',
  },
  {
    HsCode: '44032410',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44032490',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440325',
    Description: '- - Loại khác, có kích thước mặt cắt ngang nhỏ nhất từ 15 cm trở lên:',
    Unit: '',
  },
  {
    HsCode: '44032510',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44032590',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440326',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44032610',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44032690',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Loại khác, từ gỗ nhiệt đới:',
    Unit: '',
  },
  {
    HsCode: '440341',
    Description: '- - Gỗ Meranti đỏ sẫm, gỗ Meranti đỏ nhạt và gỗ Meranti Bakau:',
    Unit: '',
  },
  {
    HsCode: '44034110',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44034190',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440342',
    Description: '- - Gỗ Tếch (Teak):',
    Unit: '',
  },
  {
    HsCode: '44034210',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44034290',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440349',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44034910',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44034990',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '440391',
    Description: '- - Gỗ sồi (Quercus spp.):',
    Unit: '',
  },
  {
    HsCode: '44039110',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039190',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440393',
    Description: '- - Từ cây dẻ gai (Fagus spp.), có kích thước mặt cắt ngang nhỏ nhất từ 15 cm trở lên:',
    Unit: '',
  },
  {
    HsCode: '44039310',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039390',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440394',
    Description: '- - Từ cây dẻ gai (Fagus spp.), loại khác:',
    Unit: '',
  },
  {
    HsCode: '44039410',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039490',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440395',
    Description: '- - Từ cây Bạch dương (Betula spp.), có kích thước mặt cắt ngang nhỏ nhất từ 15 cm trở lên:',
    Unit: '',
  },
  {
    HsCode: '44039510',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039590',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440396',
    Description: '- - Từ cây bạch dương (Betula spp.), loại khác:',
    Unit: '',
  },
  {
    HsCode: '44039610',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039690',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440397',
    Description: '- - Từ cây dương (poplar and aspen) (Populus spp.):',
    Unit: '',
  },
  {
    HsCode: '44039710',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039790',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440398',
    Description: '- - Từ bạch đàn (Eucalyptus spp.):',
    Unit: '',
  },
  {
    HsCode: '44039810',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039890',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440399',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44039910',
    Description: '- - - Cột sào, khúc gỗ xẻ và gỗ lạng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44039990',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '4404',
    Description:
      'Gỗ đai thùng; cọc chẻ; sào, cột và cọc bằng gỗ, vót nhọn nhưng không xẻ dọc; gậy gỗ, đã cắt thô nhưng chưa tiện, uốn cong hoặc gia công cách khác, phù hợp cho sản xuất ba toong, cán ô, chuôi, tay cầm dụng cụ hoặc tương tự; nan gỗ (chipwood) và các dạng tương tự',
    Unit: '',
  },
  {
    HsCode: '44041000',
    Description: '- Từ cây lá kim ',
    Unit: 'kg/m3',
  },
  {
    HsCode: '440420',
    Description: '- Từ cây không thuộc loài lá kim:',
    Unit: '',
  },
  {
    HsCode: '44042010',
    Description: '- - Nan gỗ (Chipwood)',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44042090',
    Description: '- - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '4405',
    Description: 'Sợi gỗ; bột gỗ',
    Unit: '',
  },
  {
    HsCode: '44050010',
    Description: '- Sợi gỗ',
    Unit: 'kg',
  },
  {
    HsCode: '44050020',
    Description: '- Bột gỗ',
    Unit: 'kg',
  },
  {
    HsCode: '4406',
    Description: 'Tà vẹt đường sắt hoặc đường xe điện (thanh ngang) bằng gỗ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Loại chưa được ngâm tẩm:',
    Unit: '',
  },
  {
    HsCode: '44061100',
    Description: '- - Từ cây lá kim ',
    Unit: 'm3/chiếc',
  },
  {
    HsCode: '44061200',
    Description: '- - Từ cây không thuộc loài lá kim ',
    Unit: 'm3/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44069100',
    Description: '- - Từ cây lá kim ',
    Unit: 'm3/chiếc',
  },
  {
    HsCode: '44069200',
    Description: '- - Từ cây không thuộc loài lá kim ',
    Unit: 'm3/chiếc',
  },
  {
    HsCode: '4407',
    Description:
      'Gỗ đã cưa hoặc xẻ theo chiều dọc, lạng hoặc bóc, đã hoặc chưa bào, chà nhám hoặc ghép nối đầu, có độ dày trên 6 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ cây lá kim:',
    Unit: '',
  },
  {
    HsCode: '440711',
    Description: '- - Từ cây thông (Pinus spp.): ',
    Unit: '',
  },
  {
    HsCode: '44071110',
    Description: '- - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44071190',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '44071200',
    Description: '- - Từ cây linh sam (Abies spp.) và cây vân sam (Picea spp.)',
    Unit: 'm3',
  },
  {
    HsCode: '44071300',
    Description:
      '- - Từ cây thuộc nhóm S-P-F (cây vân sam (Picea spp.), cây thông (Pinus spp.) và cây linh sam (Abies spp.))',
    Unit: 'm3',
  },
  {
    HsCode: '44071400',
    Description: '- - Từ cây Độc cần (Western hemlock (Tsuga heterophylla) và linh sam (Abies spp.))',
    Unit: 'm3',
  },
  {
    HsCode: '440719',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44071910',
    Description: '- - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44071990',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- Từ gỗ nhiệt đới:',
    Unit: '',
  },
  {
    HsCode: '440721',
    Description: '- - Gỗ Mahogany (Swietenia spp.):',
    Unit: '',
  },
  {
    HsCode: '44072110',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072190',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440722',
    Description: '- - Gỗ Virola, Imbuia và Balsa:',
    Unit: '',
  },
  {
    HsCode: '44072210',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072290',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440723',
    Description: '- - Gỗ Tếch (Teak):',
    Unit: '',
  },
  {
    HsCode: '44072310',
    Description: '- - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072320',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072390',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440725',
    Description: '- - Gỗ Meranti đỏ sẫm, gỗ Meranti đỏ nhạt và gỗ Meranti Bakau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Meranti đỏ sẫm hoặc gỗ Meranti đỏ nhạt:',
    Unit: '',
  },
  {
    HsCode: '44072512',
    Description: '- - - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072513',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072519',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Meranti Bakau:',
    Unit: '',
  },
  {
    HsCode: '44072521',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072529',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440726',
    Description: '- - Gỗ Lauan trắng, gỗ Meranti trắng, gỗ Seraya trắng, gỗ Meranti vàng và gỗ Alan:',
    Unit: '',
  },
  {
    HsCode: '44072620',
    Description: '- - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072630',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072690',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440727',
    Description: '- - Gỗ Sapelli: ',
    Unit: '',
  },
  {
    HsCode: '44072720',
    Description: '- - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072730',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072790',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440728',
    Description: '- - Gỗ Iroko:',
    Unit: '',
  },
  {
    HsCode: '44072810',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072890',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440729',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Jelutong (Dyera spp.):',
    Unit: '',
  },
  {
    HsCode: '44072912',
    Description: '- - - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072913',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072919',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Kapur (Dryobalanops spp.):',
    Unit: '',
  },
  {
    HsCode: '44072922',
    Description: '- - - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072923',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072929',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Kempas (Koompassia spp.):',
    Unit: '',
  },
  {
    HsCode: '44072932',
    Description: '- - - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072933',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072939',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Keruing (Dipterocarpus spp.):',
    Unit: '',
  },
  {
    HsCode: '44072942',
    Description: '- - - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072943',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072949',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Ramin (Gonystylus spp.):',
    Unit: '',
  },
  {
    HsCode: '44072951',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072959',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Balau (Shorea spp.):',
    Unit: '',
  },
  {
    HsCode: '44072972',
    Description: '- - - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072973',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072979',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Gỗ Mengkulang (Heritiera spp.):',
    Unit: '',
  },
  {
    HsCode: '44072982',
    Description: '- - - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44072983',
    Description: '- - - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072989',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44072991',
    Description:
      '- - - - Gỗ Jongkong (Dactylocladus spp.) và gỗ Merbau (Intsia spp.), đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072992',
    Description: '- - - - Gỗ Jongkong (Dactylocladus spp.) và gỗ Merbau (Intsia spp.), loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '44072994',
    Description: '- - - - Gỗ Albizia (Paraserianthes falcataria), đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072995',
    Description: '- - - - Gỗ Albizia (Paraserianthes falcataria), loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '44072996',
    Description: '- - - - Gỗ cao su (Hevea Brasiliensis), đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072997',
    Description: '- - - - Gỗ cao su (Hevea Brasiliensis), loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '44072998',
    Description: '- - - - Loại khác, đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44072999',
    Description: '- - - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '440791',
    Description: '- - Gỗ sồi (Quercus spp.):',
    Unit: '',
  },
  {
    HsCode: '44079120',
    Description: '- - - Đã xẻ dọc, chưa gia công thêm',
    Unit: 'm3',
  },
  {
    HsCode: '44079130',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079190',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440792',
    Description: '- - Gỗ dẻ gai (Fagus spp.):',
    Unit: '',
  },
  {
    HsCode: '44079210',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079290',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440793',
    Description: '- - Gỗ thích (Acer spp.):',
    Unit: '',
  },
  {
    HsCode: '44079310',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079390',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440794',
    Description: '- - Gỗ anh đào (Prunus spp.):',
    Unit: '',
  },
  {
    HsCode: '44079410',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079490',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440795',
    Description: '- - Gỗ tần bì (Fraxinus spp.):',
    Unit: '',
  },
  {
    HsCode: '44079510',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079590',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440796',
    Description: '- - Gỗ bạch dương (Betula spp.):',
    Unit: '',
  },
  {
    HsCode: '44079610',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079690',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440797',
    Description: '- - Gỗ cây dương (poplar and aspen) (Populus spp.):',
    Unit: '',
  },
  {
    HsCode: '44079710',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079790',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '440799',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44079910',
    Description: '- - - Đã bào, đã chà nhám hoặc nối đầu',
    Unit: 'm3',
  },
  {
    HsCode: '44079990',
    Description: '- - - Loại khác',
    Unit: 'm3',
  },
  {
    HsCode: '4408',
    Description:
      'Tấm gỗ để làm lớp mặt (kể cả những tấm thu được bằng cách lạng gỗ ghép), để làm gỗ dán hoặc để làm gỗ ghép tương tự khác và gỗ khác, đã được xẻ dọc, lạng hoặc bóc tách, đã hoặc chưa bào, chà nhám, ghép hoặc nối đầu, có độ dày không quá 6 mm',
    Unit: '',
  },
  {
    HsCode: '440810',
    Description: '- Từ cây lá kim:',
    Unit: '',
  },
  {
    HsCode: '44081010',
    Description:
      '- - Thanh mỏng bằng gỗ tuyết tùng loại sử dụng để sản xuất bút chì (SEN); gỗ thông Radiata loại sử dụng để sản xuất ván ghép (blockboard)(SEN)',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '44081030',
    Description: '- - Làm lớp mặt (face veneer sheets)',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '44081090',
    Description: '- - Loại khác',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ gỗ nhiệt đới:',
    Unit: '',
  },
  {
    HsCode: '44083100',
    Description: '- - Gỗ Meranti đỏ sẫm, gỗ Meranti đỏ nhạt và gỗ Meranti Bakau',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '440839',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44083910',
    Description: '- - - Thanh mỏng bằng gỗ Jelutong loại sử dụng để sản xuất bút chì (SEN)',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '44083920',
    Description: '- - - Làm lớp mặt (face veneer sheets)',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '44083990',
    Description: '- - - Loại khác',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '440890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44089010',
    Description: '- - Làm lớp mặt (face veneer sheets)',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '44089090',
    Description: '- - Loại khác',
    Unit: 'kg/m3/ chiếc',
  },
  {
    HsCode: '4409',
    Description:
      'Gỗ (kể cả gỗ thanh và viền dải gỗ trang trí (friezes) để làm sàn packê (parquet flooring), chưa lắp ghép) được tạo dáng liên tục (làm mộng, soi rãnh, bào rãnh, vát cạnh, ghép chữ V, tạo gân, gờ dạng chuỗi hạt, tạo khuôn hình, tiện tròn hoặc gia công tương tự) dọc theo các cạnh, đầu hoặc bề mặt, đã hoặc chưa bào, chà nhám hoặc nối đầu',
    Unit: '',
  },
  {
    HsCode: '44091000',
    Description: '- Từ cây lá kim ',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Từ cây không thuộc loài lá kim:',
    Unit: '',
  },
  {
    HsCode: '44092100',
    Description: '- - Của tre',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44092200',
    Description: '- - Từ gỗ nhiệt đới',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44092900',
    Description: '- - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '4410',
    Description:
      'Ván dăm, ván dăm định hướng (OSB) và các loại ván tương tự (ví dụ, ván xốp) bằng gỗ hoặc bằng các loại vật liệu có chất gỗ khác, đã hoặc chưa liên kết bằng keo hoặc bằng các chất kết dính hữu cơ khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng gỗ:',
    Unit: '',
  },
  {
    HsCode: '44101100',
    Description: '- - Ván dăm',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44101200',
    Description: '- - Ván dăm định hướng (OSB)',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44101900',
    Description: '- - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44109000',
    Description: '- Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '4411',
    Description:
      'Ván sợi bằng gỗ hoặc bằng các loại vật liệu có chất gỗ khác, đã hoặc chưa ghép lại bằng keo hoặc bằng các chất kết dính hữu cơ khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ván sợi có tỷ trọng trung bình (MDF):',
    Unit: '',
  },
  {
    HsCode: '44111200',
    Description: '- - Loại có chiều dày không quá 5 mm',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44111300',
    Description: '- - Loại có chiều dày trên 5 mm nhưng không quá 9 mm',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44111400',
    Description: '- - Loại có chiều dày trên 9 mm',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44119200',
    Description: '- - Có tỷ trọng trên 0,8 g/cm³',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44119300',
    Description: '- - Có tỷ trọng trên 0,5 g/cm³ nhưng không quá 0,8 g/cm³',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44119400',
    Description: '- - Có tỷ trọng không quá 0,5 g/cm³ ',
    Unit: 'kg/m3',
  },
  {
    HsCode: '4412',
    Description: 'Gỗ dán, tấm gỗ dán veneer và các loại gỗ ghép tương tự',
    Unit: '',
  },
  {
    HsCode: '44121000',
    Description: '- Của tre',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Gỗ dán khác, chỉ bao gồm những lớp gỗ (trừ tre), mỗi lớp có chiều dày không quá 6 mm:',
    Unit: '',
  },
  {
    HsCode: '44123100',
    Description: '- - Với ít nhất một lớp ngoài(1) bằng gỗ nhiệt đới',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44123300',
    Description:
      '- - Loại khác, với ít nhất một lớp mặt ngoài bằng gỗ không thuộc loài cây lá kim thuộc các loài cây trăn (Alnus spp.), cây tần bì (Fraxinus spp.), cây dẻ gai (Fagus spp.), cây bạch dương (Betula spp.), cây anh đào (Prunus spp.), cây hạt dẻ (Castanea spp.), cây du (Ulmus spp.), cây bạch đàn (Eucalyptus spp.), cây mại châu (Carya spp.), cây hạt dẻ ngựa (Aesculus spp.), cây đoạn (Tilia spp.), cây thích (Acer spp.), cây sồi (Quercus spp.), cây tiêu huyền (Platanus spp.), cây dương (poplar và aspen) (Populus spp.), cây dương hòe (Robinia spp.), cây hoàng dương (Liriodendron spp.) hoặc cây óc chó (Juglans spp.)',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44123400',
    Description:
      '- - Loại khác, với ít nhất một lớp mặt ngoài từ gỗ không thuộc loài cây lá kim chưa được chi tiết tại phân nhóm 4412.33',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44123900',
    Description: '- - Loại khác, với cả hai lớp mặt ngoài từ gỗ thuộc loài cây lá kim',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Gỗ veneer nhiều lớp (LVL):',
    Unit: '',
  },
  {
    HsCode: '441241',
    Description: '- - Với ít nhất một lớp ngoài(1) bằng gỗ nhiệt đới:',
    Unit: '',
  },
  {
    HsCode: '44124110',
    Description: '- - - Với ít nhất một lớp ngoài bằng gỗ tếch ',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44124190',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44124200',
    Description: '- - Loại khác, với ít nhất một lớp mặt ngoài từ gỗ không thuộc loài cây lá kim',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44124900',
    Description: '- - Loại khác, với cả hai lớp mặt ngoài từ gỗ thuộc loài cây lá kim',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Tấm khối, tấm mỏng và tấm lót:',
    Unit: '',
  },
  {
    HsCode: '44125100',
    Description: '- - Với ít nhất một lớp ngoài(1) bằng gỗ nhiệt đới',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44125200',
    Description: '- - Loại khác, với ít nhất một lớp mặt ngoài từ gỗ không thuộc loài cây lá kim',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44125900',
    Description: '- - Loại khác, với cả hai lớp mặt ngoài từ gỗ thuộc loài cây lá kim',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '441291',
    Description: '- - Với ít nhất một lớp ngoài(1) bằng gỗ nhiệt đới: ',
    Unit: '',
  },
  {
    HsCode: '44129110',
    Description: '- - - Với ít nhất một lớp ngoài bằng gỗ tếch ',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44129190',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44129200',
    Description: '- - Loại khác, với ít nhất một lớp mặt ngoài từ gỗ không thuộc loài cây lá kim',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44129900',
    Description: '- - Loại khác, với cả hai lớp mặt ngoài từ gỗ thuộc loài cây lá kim',
    Unit: 'kg/m3',
  },
  {
    HsCode: '44130000',
    Description: 'Gỗ đã được làm tăng độ rắn, ở dạng khối, tấm, thanh hoặc các dạng hình',
    Unit: 'kg/m3',
  },
  {
    HsCode: '4414',
    Description: 'Khung tranh, khung ảnh, khung gương bằng gỗ hoặc các sản phẩm bằng gỗ tương tự',
    Unit: '',
  },
  {
    HsCode: '44141000',
    Description: '- Từ gỗ nhiệt đới',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44149000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4415',
    Description:
      'Hòm, hộp, thùng thưa, thùng hình trống và các loại bao bì tương tự, bằng gỗ; tang cuốn cáp bằng gỗ; giá kệ để kê hàng, giá để hàng kiểu thùng và các loại giá để hàng khác, bằng gỗ; kệ có thể tháo lắp linh hoạt (pallet collars) bằng gỗ',
    Unit: '',
  },
  {
    HsCode: '44151000',
    Description: '- Hòm, hộp, thùng thưa, thùng hình trống và các loại bao bì tương tự; tang cuốn cáp',
    Unit: 'chiếc',
  },
  {
    HsCode: '44152000',
    Description:
      '- Giá kệ để kê hàng, giá kệ để hàng kiểu thùng và các loại giá để hàng khác; kệ có thể tháo lắp linh hoạt (pallet collars)',
    Unit: 'chiếc',
  },
  {
    HsCode: '4416',
    Description:
      'Thùng tô nô, thùng tròn, thùng hình trống, hình trụ, có đai, các loại thùng có đai khác và các bộ phận của chúng, bằng gỗ, kể cả các loại tấm ván cong',
    Unit: '',
  },
  {
    HsCode: '44160010',
    Description: '- Tấm ván cong',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44160090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4417',
    Description:
      'Dụng cụ các loại, thân dụng cụ, tay cầm dụng cụ, thân và cán chổi hoặc bàn chải, bằng gỗ; cốt và khuôn giày hoặc ủng, bằng gỗ',
    Unit: '',
  },
  {
    HsCode: '44170010',
    Description: '- Khuôn (phom) của giày hoặc ủng',
    Unit: 'kg/chiếc/ đôi',
  },
  {
    HsCode: '44170090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc/ đôi',
  },
  {
    HsCode: '4418',
    Description:
      'Đồ mộc dùng trong xây dựng, kể cả tấm gỗ có lõi xốp, tấm lát sàn đã lắp ghép và ván lợp (shingles and shakes)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Cửa sổ, cửa sổ kiểu Pháp và khung cửa sổ:',
    Unit: '',
  },
  {
    HsCode: '44181100',
    Description: '- - Từ gỗ nhiệt đới',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44181900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Cửa ra vào và khung cửa ra vào và ngưỡng cửa của chúng:',
    Unit: '',
  },
  {
    HsCode: '44182100',
    Description: '- - Từ gỗ nhiệt đới',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44182900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44183000',
    Description: '- Trụ và dầm ngoài các sản phẩm thuộc phân nhóm 4418.81 đến 4418.89',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44184000',
    Description: '- Ván cốp pha xây dựng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44185000',
    Description: '- Ván lợp (shingles and shakes)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Tấm lát sàn đã lắp ghép:',
    Unit: '',
  },
  {
    HsCode: '441873',
    Description: '- - Từ tre hoặc có ít nhất lớp trên cùng (lớp phủ) từ tre:',
    Unit: '',
  },
  {
    HsCode: '44187310',
    Description: '- - - Cho sàn khảm (mosaic floors)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44187320',
    Description: '- - - Loại khác, nhiều lớp từ tre',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44187390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44187400',
    Description: '- - Loại khác, cho sàn khảm (mosaic floors)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44187500',
    Description: '- - Loại khác, nhiều lớp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44187900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Sản phẩm gỗ kết cấu kỹ thuật:',
    Unit: '',
  },
  {
    HsCode: '441881',
    Description: '- - Gỗ ghép nhiều lớp bằng keo (glulam):',
    Unit: '',
  },
  {
    HsCode: '44188110',
    Description: '- - - Hình khối (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44188190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44188200',
    Description: '- - Gỗ ghép nhiều lớp xếp vuông góc (CLT hoặc X-lam)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44188300',
    Description: '- - Dầm chữ I',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44188900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44189100',
    Description: '- - Của tre',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44189200',
    Description: '- - Tấm gỗ có lõi xốp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44189900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4419',
    Description: 'Bộ đồ ăn và bộ đồ làm bếp, bằng gỗ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ tre:',
    Unit: '',
  },
  {
    HsCode: '44191100',
    Description: '- - Thớt cắt bánh mì, thớt chặt và các loại thớt tương tự',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '44191200',
    Description: '- - Đũa',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '44191900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '44192000',
    Description: '- Từ gỗ nhiệt đới',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '44199000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '4420',
    Description:
      'Gỗ khảm và dát; tráp và các loại hộp đựng đồ trang sức hoặc đựng dao kéo, và các sản phẩm tương tự, bằng gỗ; tượng nhỏ và đồ trang trí khác, bằng gỗ; các loại đồ nội thất bằng gỗ không thuộc Chương 94',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tượng nhỏ và đồ trang trí khác:',
    Unit: '',
  },
  {
    HsCode: '44201100',
    Description: '- - Từ gỗ nhiệt đới',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44201900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '442090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44209010',
    Description: '- - Các đồ nội thất bằng gỗ không thuộc Chương 94',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44209020',
    Description:
      '- - Hộp và các vật chứa nhỏ khác dùng và thích hợp để mang trong túi (pocket), trong túi xách hoặc bên người',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44209090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4421',
    Description: 'Các sản phẩm bằng gỗ khác',
    Unit: '',
  },
  {
    HsCode: '44211000',
    Description: '- Mắc treo quần áo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44212000',
    Description: '- Quan tài',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '442191',
    Description: '- - Từ tre:',
    Unit: '',
  },
  {
    HsCode: '44219110',
    Description: '- - - Lõi cuộn chỉ, ống sợi và suốt sợi, guồng cuốn chỉ may và các sản phẩm tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219120',
    Description: '- - - Thanh gỗ để làm diêm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219130',
    Description: '- - - Que kẹo, que kem và thìa xúc kem',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219140',
    Description: '- - - Quạt và màn che kéo bằng tay, khung và tay cầm, quai kèm theo, và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219150',
    Description: '- - - Chuỗi hạt cầu nguyện',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219160',
    Description: '- - - Tăm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219170',
    Description: '- - - Các loại que dùng để làm nhang (nén hương)(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '442199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44219910',
    Description: '- - - Lõi cuộn chỉ, ống sợi và suốt sợi, guồng cuốn chỉ may và các sản phẩm tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219920',
    Description: '- - - Thanh gỗ để làm diêm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219930',
    Description: '- - - Móc gỗ hoặc ghim gỗ dùng cho giày, dép',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219940',
    Description: '- - - Que kẹo, que kem và thìa xúc kem',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219970',
    Description: '- - - Quạt và màn che kéo bằng tay, khung và tay cầm, quai kèm theo, và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219980',
    Description: '- - - Tăm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '44219993',
    Description: '- - - - Chuỗi hạt cầu nguyện',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219994',
    Description: '- - - - Chuỗi hạt khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219995',
    Description: '- - - - Các loại que dùng để làm nhang (nén hương) (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219996',
    Description: '- - - - Lõi gỗ ghép (barecore) (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '44219999',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 7752:2017',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 45',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lie và các sản phẩm bằng lie',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Giày dép hoặc các bộ phận của giày dép thuộc Chương 64;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Mũ và các vật đội đầu khác hoặc các bộ phận của chúng thuộc Chương 65; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các mặt hàng thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao).',
    Unit: '',
  },
  {
    HsCode: '4501',
    Description: 'Lie tự nhiên, thô hoặc đã sơ chế; lie phế liệu; lie đã ép, nghiền thành hạt hoặc thành bột',
    Unit: '',
  },
  {
    HsCode: '45011000',
    Description: '- Lie tự nhiên, thô hoặc đã sơ chế',
    Unit: 'kg',
  },
  {
    HsCode: '45019000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '45020000',
    Description:
      'Lie tự nhiên, đã bóc vỏ hoặc đã đẽo thô thành hình vuông, hoặc ở dạng khối, tấm, lá, hình chữ nhật (kể cả hình vuông) hoặc dải (kể cả dạng phôi lie đã cắt cạnh dùng làm nút hoặc nắp đậy)',
    Unit: 'kg',
  },
  {
    HsCode: '4503',
    Description: 'Các sản phẩm bằng lie tự nhiên',
    Unit: '',
  },
  {
    HsCode: '45031000',
    Description: '- Nút và nắp đậy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '45039000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4504',
    Description: 'Lie kết dính (có hoặc không có chất gắn) và các sản phẩm bằng lie kết dính',
    Unit: '',
  },
  {
    HsCode: '45041000',
    Description: '- Dạng khối, tấm, lá và dải; tấm lát ở mọi hình dạng; dạng hình trụ đặc, kể cả dạng đĩa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '45049000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 46',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Sản phẩm làm từ rơm, cỏ giấy hoặc từ các loại vật liệu tết bện khác; các sản phẩm bằng liễu gai và song mây',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong Chương này khái niệm ""vật liệu tết bện"" dùng để chỉ các loại vật liệu ở trạng thái hoặc hình dạng thích hợp cho việc tết bện hoặc gia công tương tự; kể cả rơm, liễu gai hoặc liễu, tre, song, mây, bấc, sậy, dải gỗ mỏng, dải từ vật liệu gốc thực vật khác (ví dụ, dải vỏ cây, các lá dài hẹp chiều ngang và cây cọ sợi hoặc các dải khác thu được từ các lá to), sợi dệt tự nhiên chưa se, sợi monofilamen và dải và dạng tương tự bằng plastic và dải giấy, nhưng không bao gồm dải bằng da thuộc hoặc da thuộc tổng hợp hoặc dải bằng nỉ hoặc sản phẩm không dệt, tóc người, lông đuôi hoặc bờm ngựa, sợi dệt thô hoặc sợi dệt, hoặc sợi monofilament và dải và dạng tương tự thuộc Chương 54.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Tấm phủ tường thuộc nhóm 48.14;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dây xe, chão bện (cordage), thừng hoặc cáp, đã hoặc chưa tết bện (nhóm 56.07);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Giày, dép hoặc mũ và các vật đội đầu khác hoặc các bộ phận của chúng thuộc Chương 64 hoặc 65;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Xe hoặc thân xe bằng song mây (thuộc Chương 87); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các mặt hàng thuộc Chương 94 (ví dụ, đồ nội thất, các loại đèn (luminaires) và bộ đèn).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 46.01, khái niệm ""vật liệu tết bện, dây bện và các sản phẩm tương tự bằng vật liệu tết bện, đã kết lại với nhau thành các tao dây song song"" dùng để chỉ các vật liệu tết bện, các dây bện và các sản phẩm tương tự bằng vật liệu tết bện, đã được xếp kề cạnh và liên kết với nhau, thành dạng tấm, có hoặc không có vật liệu kết nối là vật liệu dệt đã se.',
    Unit: '',
  },
  {
    HsCode: '4601',
    Description:
      'Dây tết bện và các sản phẩm tương tự làm bằng vật liệu tết bện, đã hoặc chưa ghép thành dải; các vật liệu tết bện, các dây bện và các sản phẩm tương tự bằng vật liệu tết bện, đã kết lại với nhau trong các tao dây song song hoặc đã được dệt, ở dạng tấm, có hoặc không ở dạng thành phẩm (ví dụ, chiếu, thảm, mành)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chiếu, thảm và mành bằng vật liệu thực vật:',
    Unit: '',
  },
  {
    HsCode: '46012100',
    Description: '- - Của tre',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46012200',
    Description: '- - Từ song mây',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46012900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '460192',
    Description: '- - Từ tre:',
    Unit: '',
  },
  {
    HsCode: '46019210',
    Description: '- - - Dây tết bện và các sản phẩm tương tự làm bằng vật liệu tết bện, đã hoặc chưa ghép thành dải',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019220',
    Description: '- - - Quạt và màn che kéo bằng tay, khung và tay cầm, quai kèm theo, và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '460193',
    Description: '- - Từ song mây:',
    Unit: '',
  },
  {
    HsCode: '46019310',
    Description: '- - - Dây tết bện và các sản phẩm tương tự làm bằng vật liệu tết bện, đã hoặc chưa ghép thành dải',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019320',
    Description: '- - - Quạt và màn che kéo bằng tay, khung và tay cầm, quai kèm theo, và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '460194',
    Description: '- - Từ vật liệu thực vật khác:',
    Unit: '',
  },
  {
    HsCode: '46019410',
    Description: '- - - Dây tết bện và các sản phẩm tương tự làm bằng vật liệu tết bện, đã hoặc chưa ghép thành dải',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019420',
    Description: '- - - Quạt và màn che kéo bằng tay, khung và tay cầm, quai kèm theo, và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019490',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '460199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '46019910',
    Description: '- - - Chiếu và thảm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019920',
    Description: '- - - Dây tết bện và các sản phẩm tương tự làm bằng vật liệu tết bện, đã hoặc chưa ghép thành dải',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019930',
    Description: '- - - Quạt và màn che kéo bằng tay, khung và tay cầm, quai kèm theo, và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46019990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4602',
    Description:
      'Hàng mây tre, liễu gai và các mặt hàng khác, làm trực tiếp từ vật liệu tết bện hoặc làm từ các mặt hàng thuộc nhóm 46.01; các sản phẩm từ cây họ mướp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng vật liệu thực vật:',
    Unit: '',
  },
  {
    HsCode: '460211',
    Description: '- - Từ tre:',
    Unit: '',
  },
  {
    HsCode: '46021110',
    Description: '- - - Túi và vali du lịch',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46021120',
    Description: '- - - Giỏ đựng chai',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46021190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '460212',
    Description: '- - Từ song mây:',
    Unit: '',
  },
  {
    HsCode: '46021210',
    Description: '- - - Túi và vali du lịch',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46021220',
    Description: '- - - Giỏ đựng chai',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46021290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '460219',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '46021910',
    Description: '- - - Túi và vali du lịch',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46021920',
    Description: '- - - Giỏ đựng chai',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46021990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '460290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '46029010',
    Description: '- - Túi và vali du lịch',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46029020',
    Description: '- - Giỏ đựng chai',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '46029090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'PHẦN X',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'BỘT GIẤY TỪ GỖ HOẶC TỪ NGUYÊN LIỆU XƠ SỢI XENLULO KHÁC; GIẤY LOẠI HOẶC BÌA LOẠI THU HỒI (PHẾ LIỆU VÀ VỤN THỪA); GIẤY VÀ BÌA VÀ CÁC SẢN PHẨM CỦA CHÚNG',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 47',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Bột giấy từ gỗ hoặc từ nguyên liệu xơ xenlulo khác; giấy loại hoặc bìa loại thu hồi (phế liệu và vụn thừa)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của nhóm 47.02, khái niệm ""bột giấy hóa học từ gỗ, loại hòa tan"" có nghĩa là bột giấy hóa học từ gỗ có hàm lượng phần không hòa tan từ 92% trở lên đối với bột giấy sản xuất bằng phương pháp sulphat hoặc kiềm hoặc 88% trở lên đối với bột giấy sản xuất bằng phương pháp sulphit sau khi ngâm một giờ trong dung dịch natri hydroxit (NaOH) nồng độ 18% ở nhiệt độ 20°C, và đối với bột giấy sản xuất bằng phương pháp sulphit hàm lượng tro không được lớn hơn 0,15% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '47010000',
    Description: 'Bột giấy cơ học từ gỗ',
    Unit: 'kg',
  },
  {
    HsCode: '4702',
    Description: 'Bột giấy hóa học từ gỗ, loại hòa tan',
    Unit: '',
  },
  {
    HsCode: '47020010',
    Description: '- Từ gỗ cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '47020020',
    Description: '- Từ gỗ cây không thuộc loài cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '4703',
    Description: 'Bột giấy hóa học từ gỗ, sản xuất bằng phương pháp sulphat hoặc kiềm, trừ loại hòa tan',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '47031100',
    Description: '- - Từ gỗ cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '47031900',
    Description: '- - Từ gỗ cây không thuộc loài cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Đã qua bán tẩy trắng hoặc tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '47032100',
    Description: '- - Từ gỗ cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '47032900',
    Description: '- - Từ gỗ cây không thuộc loài cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '4704',
    Description: 'Bột giấy hóa học từ gỗ, sản xuất bằng phương pháp sulphite, trừ loại hòa tan',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '47041100',
    Description: '- - Từ gỗ cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '47041900',
    Description: '- - Từ gỗ cây không thuộc loài cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Đã qua bán tẩy trắng hoặc tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '47042100',
    Description: '- - Từ cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '47042900',
    Description: '- - Từ gỗ cây không thuộc loài cây lá kim ',
    Unit: 'kg',
  },
  {
    HsCode: '47050000',
    Description: 'Bột giấy từ gỗ thu được bằng việc kết hợp các phương pháp nghiền cơ học và hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '4706',
    Description:
      'Bột giấy từ xơ, sợi thu được từ việc tái chế giấy loại hoặc bìa loại (phế liệu và vụn thừa) hoặc từ vật liệu xơ sợi xenlulo khác',
    Unit: '',
  },
  {
    HsCode: '47061000',
    Description: '- Bột giấy từ xơ bông vụn',
    Unit: 'kg',
  },
  {
    HsCode: '47062000',
    Description: '- Bột giấy từ xơ, sợi thu được từ việc tái chế giấy loại hoặc bìa loại (phế liệu và vụn thừa)',
    Unit: 'kg',
  },
  {
    HsCode: '47063000',
    Description: '- Loại khác, từ tre',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '47069100',
    Description: '- - Thu được từ quá trình cơ học',
    Unit: 'kg',
  },
  {
    HsCode: '47069200',
    Description: '- - Thu được từ quá trình hóa học',
    Unit: 'kg',
  },
  {
    HsCode: '470693',
    Description: '- - Thu được từ quá trình kết hợp cơ học và hóa học: ',
    Unit: '',
  },
  {
    HsCode: '47069310',
    Description: '- - - Bột giấy abaca (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '47069390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4707',
    Description: 'Giấy loại hoặc bìa loại thu hồi (phế liệu và vụn thừa)',
    Unit: '',
  },
  {
    HsCode: '47071000',
    Description: '- Giấy kraft hoặc bìa kraft hoặc giấy hoặc bìa sóng, chưa tẩy trắng',
    Unit: 'kg',
  },
  {
    HsCode: '47072000',
    Description:
      '- Giấy hoặc bìa khác được làm chủ yếu bằng bột giấy thu được từ quá trình hóa học đã tẩy trắng, chưa nhuộm màu toàn bộ',
    Unit: 'kg',
  },
  {
    HsCode: '47073000',
    Description:
      '- Giấy hoặc bìa được làm chủ yếu bằng bột giấy thu được từ quá trình cơ học (ví dụ, giấy in báo, tạp chí và các ấn phẩm tương tự) ',
    Unit: 'kg',
  },
  {
    HsCode: '47079000',
    Description: '- Loại khác, kể cả phế liệu và vụn thừa chưa phân loại',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'Chương 48',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Giấy và bìa; các sản phẩm làm bằng bột giấy, bằng giấy hoặc bằng bìa',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của Chương này, trừ khi có yêu cầu khác, khi đề cập đến “giấy” đều kể đến bìa (bất kể độ dày hoặc định lượng tính trên m2). ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các sản phẩm thuộc Chương 30;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Lá phôi dập của nhóm 32.12;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các loại giấy thơm hoặc các loại giấy đã thấm tẩm hoặc tráng bằng các loại mỹ phẩm (Chương 33);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Giấy hoặc tấm lót xenlulo đã thấm tẩm, tráng hoặc phủ bằng xà phòng hoặc chất tẩy (nhóm 34.01), hoặc bằng các chất đánh bóng, kem hoặc các chế phẩm tương tự (nhóm 34.05);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Giấy hoặc bìa có phủ lớp chất nhạy thuộc các nhóm từ 37.01 đến 37.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Giấy được thấm tẩm chất thử chẩn đoán bệnh hoặc chất thử dùng trong thí nghiệm (nhóm 38.22);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Tấm plastic phân tầng được gia cố bằng giấy, hoặc một lớp giấy hoặc bìa được tráng hoặc phủ một lớp plastic, lớp plastic này dày hơn 1/2 tổng bề dày, hoặc các sản phẩm làm bằng vật liệu như vậy, trừ các tấm phủ tường thuộc nhóm 48.14 (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Các sản phẩm thuộc nhóm 42.02 (ví dụ, hàng du lịch);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Các sản phẩm thuộc Chương 46 (các sản phẩm làm bằng vật liệu tết bện);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Sợi giấy hoặc các sản phẩm dệt bằng sợi giấy (Phần XI);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các sản phẩm thuộc Chương 64 hoặc Chương 65;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(m) Bìa hoặc giấy ráp (nhóm 68.05) hoặc mica được bồi giấy hoặc bìa (nhóm 68.14) (tuy nhiên, bìa hoặc giấy tráng phủ với bột mica, được xếp vào Chương này);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Lá kim loại bồi trên giấy hoặc bìa (thường thuộc Phần XIV hoặc XV);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(o) Các sản phẩm thuộc nhóm 92.09; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(p) Các sản phẩm thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao); hoặc ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(q) Các sản phẩm thuộc Chương 96 (ví dụ, các loại khuy, cúc, băng vệ sinh (miếng) và băng vệ sinh dạng ống (tampon), khăn (bỉm) và tã lót).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo nội dung Chú giải 7, các nhóm từ 48.01 đến 48.05 kể cả giấy và bìa được cán láng, cán láng cao cấp, làm bóng hoặc hoàn thiện bằng các phương pháp tương tự, làm giả hình bóng nước hoặc gia keo bề mặt, và cũng kể cả các loại giấy, bìa, tấm xenlulo và màng xơ sợi xenlulo, đã nhuộm màu hoặc tạo vân toàn bộ bằng phương pháp bất kỳ. Trừ khi nhóm 48.03 có yêu cầu khác, các nhóm này không áp dụng cho giấy, bìa, màng xơ sợi xenlulo hoặc súc xơ sợi xenlulo được xử lý theo các phương pháp khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Trong Chương này ""giấy in báo"" có nghĩa là loại giấy không tráng sử dụng để in báo, có hàm lượng bột gỗ thu được từ quá trình cơ học hoặc quá trình hóa cơ-hóa học không nhỏ hơn 50% so với tổng lượng bột giấy, không gia keo hoặc được gia keo nhẹ, có độ nhám bề mặt Parker Print Surf (1MPa) trên mỗi mặt trên 2,5 micromet (microns), định lượng từ 40 g/m2 đến 65 g/m2, và chỉ áp dụng đối với giấy: (a) ở dạng dải hoặc dạng cuộn có chiều rộng lớn hơn 28 cm; hoặc (b) ở dạng tờ hình chữ nhật (kể cả hình vuông) với một chiều lớn hơn 28 cm và chiều khác lớn hơn 15 cm ở dạng không gấp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của nhóm 48.02, các thuật ngữ “giấy và bìa, loại dùng để viết, in hoặc các mục đích đồ bản khác” và “giấy làm thẻ và giấy băng chưa đục lỗ” nghĩa là giấy và bìa được làm chủ yếu từ bột giấy tẩy trắng hoặc bột giấy thu được từ quá trình cơ học hoặc quá trình hóa học-cơ học và thỏa mãn một trong những tiêu chí sau đây: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(A) Đối với loại giấy hoặc bìa định lượng không quá 150 g/m2:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) có hàm lượng bột giấy thu được từ quá trình cơ học hoặc quá trình hóa học-cơ học từ 10% trở lên so với tổng lượng bột giấy, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. định lượng không quá 80 g/m2, hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. được nhuộm màu toàn bộ; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) có hàm lượng tro trên 8%, và ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. định lượng không quá 80 g/m2, hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. được nhuộm màu toàn bộ; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) có hàm lượng tro trên 3% và độ trắng từ 60% trở lên; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) có hàm lượng tro trên 3% nhưng không quá 8%, độ trắng dưới 60%, và chỉ số bục từ 2,5 kPa∙ m2/g trở xuống; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) có hàm lượng tro từ 3% trở xuống, độ trắng từ 60% trở lên và chỉ số bục từ 2,5 kPa∙ m2/g trở xuống.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Đối với loại giấy hoặc bìa có định lượng trên 150 g/m2:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) được nhuộm màu toàn bộ; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) có độ trắng từ 60% trở lên, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. độ dày từ 225 micromet (microns) trở xuống, hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. độ dày trên 225 micromet (microns) nhưng không quá 508 micromet và hàm lượng tro trên 3%; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) có độ trắng dưới 60%, độ dày từ 254 micromet (microns) trở xuống và hàm lượng tro trên 8%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, nhóm 48.02 không bao gồm giấy hoặc bìa lọc (kể cả giấy làm túi lọc chè) hoặc giấy nỉ hoặc bìa nỉ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Trong Chương này ""giấy và bìa kraft"" có nghĩa là loại giấy và bìa có hàm lượng bột giấy sản xuất bằng quá trình sulphat hoặc kiềm hóa học không dưới 80% so với tổng lượng bột giấy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Trừ khi nội dung của nhóm có yêu cầu khác, giấy, bìa, tấm xenlulo và màng xơ sợi xenlulo đáp ứng mô tả trong hai hoặc nhiều nhóm từ 48.01 đến 48.11 được phân loại vào nhóm có số thứ tự cuối cùng trong Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '8. Các nhóm 48.03 đến 48.09 chỉ áp dụng đối với giấy, bìa, tấm xenlulo và màng xơ sợi xenlulo:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) ở dạng dải hoặc cuộn có chiều rộng trên 36 cm; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) ở dạng tờ hình chữ nhật (kể cả hình vuông) với một chiều trên 36 cm và chiều kia trên 15 cm ở dạng không gấp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Theo mục đích của nhóm 48.14, khái niệm ""giấy dán tường và các loại tấm phủ tường tương tự"" chỉ áp dụng đối với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Giấy ở dạng cuộn, chiều rộng từ 45 cm và không quá 160 cm, thích hợp với trang trí tường hoặc trần nhà:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) Giả vân, rập nổi, tạo màu bề mặt, in theo thiết kế hoặc trang trí bề mặt bằng cách khác (ví dụ, bằng vụn xơ dệt), có hoặc không tráng hoặc phủ bằng lớp plastic bảo vệ trong suốt;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Mặt gồ ghề do gắn trang trí bằng vụn rơm, vụn gỗ...;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iii) Tráng hoặc phủ bề mặt bằng plastic, lớp plastic được làm giả vân, rập nổi, tạo màu, in theo thiết kế hoặc trang trí kiểu khác; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iv) Phủ bề mặt bằng vật liệu tết bện, có hoặc không gắn với nhau theo kiểu các tao song song hoặc dệt thoi;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các loại diềm và băng trang trí, bằng giấy, được xử lý như trên, có hoặc không ở dạng cuộn, thích hợp cho việc trang trí tường hoặc trần nhà;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Giấy phủ tường đã làm thành các tấm panel, ở dạng cuộn hoặc tờ, đã được in để ghép làm phông cảnh, mẫu thiết kế hoặc môtip khi phủ lên tường.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm bằng giấy hoặc bìa, thích hợp cho cả việc dùng để trải sàn và phủ tường, được phân loại trong nhóm 48.23.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '10. Nhóm 48.20 không bao gồm các loại tờ rời hoặc thiếp, thẻ rời, đã cắt theo cỡ, đã hoặc chưa in, rập nổi hoặc đục lỗ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '11. Không kể những đề cập khác, nhóm 48.23 áp dụng cho các loại thiếp, thẻ đã đục lỗ, làm bằng giấy hoặc bìa dùng cho máy Jacquard hoặc các loại máy tương tự và ren giấy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '12. Trừ các hàng hóa thuộc nhóm 48.14 hoặc 48.21, giấy, bìa, tấm xơ sợi xenlulo và các sản phẩm của chúng, đã in các motip, các ký tự hoặc biểu tượng tranh ảnh, mà không chỉ đơn thuần bổ trợ cho công dụng chính của sản phẩm đó, được xếp vào Chương 49.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của các phân nhóm 4804.11 và 4804.19, ""kraft lớp mặt"" có nghĩa là loại giấy và bìa được sản xuất hoàn chỉnh trực tiếp trên máy hoặc làm bóng bề mặt trên máy, có từ 80% trở lên tính theo khối lượng trên tổng hàm lượng bột giấy là bột gỗ thu được từ quá trình sulphat hoặc kiềm hóa học, ở dạng cuộn, định lượng lớn hơn 115 g/m2 và có độ bục Mullen tối thiểu như đã chỉ ra trong bảng dưới đây hoặc giá trị tương đương nội suy hoặc ngoại suy tuyến tính cho các định lượng khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 2',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của các phân nhóm 4804.21 và 4804.29, ""giấy kraft làm bao"" có nghĩa là loại giấy được sản xuất hoàn chỉnh trực tiếp trên máy, có hàm lượng bột giấy sản xuất theo quá trình sulphat hoặc kiềm từ 80% trở lên so với tổng lượng bột giấy tính theo khối lượng, ở dạng cuộn, định lượng từ 60 g/m2 nhưng không quá 115 g/m2 và thỏa mãn một trong các bộ tiêu chí sau đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Chỉ số bục Mullen không dưới 3,7 kPa∙m2/g và hệ số giãn dài trên 4,5% theo chiều ngang và trên 2% theo chiều dọc. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Có độ bền xé và độ bền kéo tối thiểu được chỉ ra trong bảng sau đây hoặc giá trị tương đương nội suy tuyến tính cho các định lượng khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 3',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của phân nhóm 4805.11, ""giấy để tạo lớp sóng sản xuất từ bột giấy bán hóa học"" có nghĩa là giấy, ở dạng cuộn, có từ 65% trở lên tính theo khối lượng trên tổng hàm lượng bột giấy là bột gỗ cứng chưa tẩy trắng thu từ việc kết hợp quá trình xử lý bột giấy cơ học và hóa học, và có độ bền nén CMT 30 (phép thử độ bền nén phẳng sau khi đã làm sóng trong phòng thí nghiệm với thời gian để điều hoà mẫu 30 phút) lớn hơn 1,8 N/g/m2 trong điều kiện thử nghiệm có độ ẩm tương đối là 50%, nhiệt độ 23oC.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Phân nhóm 4805.12 bao gồm giấy, dạng cuộn, được làm chủ yếu từ bột giấy rơm rạ thu được từ việc kết hợp quá trình sản xuất cơ học và hóa học, định lượng từ 130 g/m2 trở lên, và có độ bền nén CMT 30 (phép thử độ bền nén phẳng sau khi đã làm sóng trong phòng thí nghiệm với thời gian để điều hòa mẫu 30 phút) trên 1,4 N/g/m2 trong điều kiện thử nghiệm có độ ẩm tương đối là 50%, nhiệt độ 23oC.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Các phân nhóm 4805.24 và 4805.25 bao gồm giấy và bìa được làm toàn bộ hoặc chủ yếu bằng bột giấy tái chế từ giấy loại và bìa loại (phế liệu và mảnh vụn). Bìa lớp mặt có thể có một lớp mặt bằng giấy đã nhuộm hoặc bằng giấy làm từ bột giấy không phải là bột giấy tái chế đã tẩy trắng hoặc không tẩy trắng. Các sản phẩm này có chỉ số bục Mullen không nhỏ hơn 2 kPa·m2/g.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của phân nhóm 4805.30, ""giấy bao gói làm từ bột giấy sulphit"" có nghĩa là loại giấy được làm bóng trên máy, có trên 40% tính theo khối lượng trên tổng hàm lượng bột giấy là bột gỗ thu được từ quá trình sulphit hóa học, hàm lượng tro không quá 8% và chỉ số bục Mullen không dưới 1,47 kPa·m2/g.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Theo mục đích của phân nhóm 4810.22, ""giấy tráng nhẹ"" là loại giấy, được tráng 2 mặt, có tổng định lượng không quá 72 g/m2, định lượng chất tráng trên mỗi mặt không quá 15 g/m2, trên giấy nền có từ 50% trở lên tính theo khối lượng trên tổng hàm lượng bột giấy là bột gỗ thu được từ quá trình cơ học.',
    Unit: '',
  },
  {
    HsCode: '4801',
    Description: 'Giấy in báo, dạng cuộn hoặc tờ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có định lượng không quá 55 g/m2:',
    Unit: '',
  },
  {
    HsCode: '48010011',
    Description: '- - Ở dạng cuộn, có chiều rộng trên 28 cm nhưng không quá 36 cm',
    Unit: 'kg',
  },
  {
    HsCode: '48010012',
    Description: '- - Ở dạng cuộn, loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48010013',
    Description:
      '- - Ở dạng tờ, hình vuông hoặc hình chữ nhật, có một chiều trên 28 cm nhưng không quá 36 cm, và chiều kia trên 15 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48010014',
    Description:
      '- - Ở dạng tờ, hình vuông hoặc hình chữ nhật, có một chiều trên 36 cm, và chiều kia trên 15 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Có định lượng trên 55 g/m2:',
    Unit: '',
  },
  {
    HsCode: '48010021',
    Description: '- - Ở dạng cuộn, có chiều rộng trên 28 cm nhưng không quá 36 cm',
    Unit: 'kg',
  },
  {
    HsCode: '48010022',
    Description: '- - Ở dạng cuộn, loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48010023',
    Description:
      '- - Ở dạng tờ, hình vuông hoặc hình chữ nhật, có một chiều trên 28 cm nhưng không quá 36 cm, và chiều kia trên 15 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48010024',
    Description:
      '- - Ở dạng tờ, hình vuông hoặc hình chữ nhật, có một chiều trên 36 cm, và chiều kia trên 15 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '4802',
    Description:
      'Giấy và bìa không tráng, loại dùng để in, viết hoặc dùng cho các mục đích đồ bản khác, và giấy làm thẻ và giấy băng chưa đục lỗ, dạng cuộn hoặc tờ hình chữ nhật (kể cả hình vuông), kích thước bất kỳ, trừ giấy thuộc nhóm 48.01 hoặc 48.03; giấy và bìa sản xuất thủ công',
    Unit: '',
  },
  {
    HsCode: '48021000',
    Description: '- Giấy và bìa sản xuất thủ công',
    Unit: 'kg',
  },
  {
    HsCode: '480220',
    Description: '- Giấy và bìa sử dụng làm nền cho sản xuất giấy và bìa nhạy sáng, nhạy nhiệt hoặc nhạy điện:',
    Unit: '',
  },
  {
    HsCode: '48022010',
    Description:
      '- - Ở dạng cuộn có chiều rộng không quá 15cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48022090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480240',
    Description: '- Giấy làm nền sản xuất giấy dán tường:',
    Unit: '',
  },
  {
    HsCode: '48024010',
    Description:
      '- - Ở dạng cuộn có chiều rộng không quá 15cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48024090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- Giấy và bìa khác, không chứa bột giấy thu được từ quá trình cơ học hoặc quá trình hóa cơ hoặc có hàm lượng các bột giấy này không quá 10% so với tổng lượng bột giấy tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '480254',
    Description: '- - Có định lượng dưới 40 g/m2:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Giấy làm nền sản xuất giấy carbon, có định lượng dưới 20g/m2:',
    Unit: '',
  },
  {
    HsCode: '48025411',
    Description:
      '- - - - Ở dạng cuộn có chiều rộng không quá 15cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025419',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Giấy làm nền sản xuất giấy carbon khác:',
    Unit: '',
  },
  {
    HsCode: '48025421',
    Description:
      '- - - - Ở dạng cuộn có chiều rộng không quá 15cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025429',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025430',
    Description: '- - - Giấy làm nền sản xuất giấy tráng nhôm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025440',
    Description:
      '- - - Loại dùng để viết, in và các mục đích đồ bản khác, dạng cuộn có chiều rộng không quá 15 cm hoặc dạng tờ hình chữ nhật (kể cả hình vuông) không có cạnh nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48025450',
    Description: '- - - Giấy và bìa nhiều lớp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025490',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480255',
    Description: '- - Có định lượng từ 40 g/m2 trở lên nhưng không quá 150g/m2, dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Giấy và bìa trang trí:',
    Unit: '',
  },
  {
    HsCode: '48025521',
    Description: '- - - - Có chiều rộng không quá 15 cm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025529',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025540',
    Description: '- - - Giấy làm nền sản xuất giấy tráng nhôm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025550',
    Description: '- - - Giấy làm nền sản xuất giấy chống dính (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng để viết, in và các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '48025561',
    Description: '- - - - Có chiều rộng không quá 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '48025569',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48025570',
    Description: '- - - Giấy nhiều lớp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025590',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480256',
    Description:
      '- - Có định lượng từ 40g/m2 trở lên nhưng không quá 150g/m2, dạng tờ với một chiều không quá 435 mm và chiều kia không quá 297 mm ở dạng không gấp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Giấy và bìa trang trí:',
    Unit: '',
  },
  {
    HsCode: '48025621',
    Description:
      '- - - - Ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025629',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng để viết, in và các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '48025641',
    Description: '- - - - Có chiều rộng không quá 36 cm ở dạng tờ hình chữ nhật (kể cả hình vuông) và ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48025649',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48025650',
    Description: '- - - Giấy nhiều lớp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025690',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480257',
    Description: '- - Loại khác, định lượng từ 40 g/m2 trở lên nhưng không quá 150 g/m2:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng để viết, in và các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '48025721',
    Description: '- - - - Không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48025729',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48025730',
    Description: '- - - Giấy nhiều lớp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025790',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480258',
    Description: '- - Định lượng trên 150 g/m2:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Giấy và bìa trang trí:',
    Unit: '',
  },
  {
    HsCode: '48025821',
    Description:
      '- - - - Ở dạng cuộn có chiều rộng từ 15 cm trở xuống hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) với một chiều từ 36 cm trở xuống và chiều kia từ 15 cm trở xuống ở dạng không gấp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48025829',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng để viết, in và các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '48025831',
    Description:
      '- - - - Ở dạng cuộn có chiều rộng không quá 15cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48025839',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48025891',
    Description: '- - - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2',
    Unit: 'kg',
  },
  {
    HsCode: '48025899',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- Giấy và bìa khác, có hàm lượng bột giấy thu được từ quá trình cơ học hoặc cơ - hóa trên 10% so với tổng bột giấy tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '480261',
    Description: '- - Dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Giấy và bìa trang trí:',
    Unit: '',
  },
  {
    HsCode: '48026131',
    Description: '- - - - Có chiều rộng không quá 15 cm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48026139',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Giấy làm nền sản xuất giấy tráng nhôm:',
    Unit: '',
  },
  {
    HsCode: '48026141',
    Description: '- - - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48026149',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng để viết, in và các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '48026151',
    Description: '- - - - Có chiều rộng không quá 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '48026159',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48026160',
    Description: '- - - Giấy nhiều lớp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48026191',
    Description: '- - - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2',
    Unit: 'kg',
  },
  {
    HsCode: '48026199',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480262',
    Description: '- - Dạng tờ với một chiều không quá 435 mm và chiều kia không quá 297 mm ở dạng không gấp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - - Giấy và bìa trang trí, dạng tờ hình chữ nhật (kể cả hình vuông) với một chiều từ 36 cm trở xuống và chiều kia từ 15 cm trở xuống ở dạng không gấp: ',
    Unit: '',
  },
  {
    HsCode: '48026211',
    Description: '- - - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48026219',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Giấy và bìa trang trí khác: ',
    Unit: '',
  },
  {
    HsCode: '48026221',
    Description: '- - - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48026229',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng để viết, in và các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '48026231',
    Description: '- - - - Ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48026239',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48026291',
    Description: '- - - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2',
    Unit: 'kg',
  },
  {
    HsCode: '48026299',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480269',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng để viết, in và các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '48026911',
    Description: '- - - - Ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48026919',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48026991',
    Description: '- - - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2',
    Unit: 'kg',
  },
  {
    HsCode: '48026999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4803',
    Description:
      'Giấy để làm giấy vệ sinh hoặc lau mặt, khăn giấy, khăn ăn và các loại giấy tương tự dùng trong gia đình hoặc vệ sinh, tấm xenlulo và màng xơ sợi xenlulo, đã hoặc chưa làm chun, làm nhăn, rập nổi, đục lỗ, nhuộm màu bề mặt, trang trí hoặc in bề mặt, ở dạng cuộn hoặc tờ',
    Unit: '',
  },
  {
    HsCode: '48030030',
    Description: '- Tấm xenlulo hoặc màng xơ sợi xenlulo',
    Unit: 'kg',
  },
  {
    HsCode: '48030090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4804',
    Description: 'Giấy và bìa kraft không tráng, ở dạng cuộn hoặc tờ, trừ loại thuộc nhóm 48.02 hoặc 48.03',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Kraft lớp mặt:',
    Unit: '',
  },
  {
    HsCode: '48041100',
    Description: '- - Chưa tẩy trắng',
    Unit: 'kg',
  },
  {
    HsCode: '48041900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy kraft làm bao:',
    Unit: '',
  },
  {
    HsCode: '480421',
    Description: '- - Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '48042110',
    Description: '- - - Loại dùng làm bao xi măng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48042190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48042910',
    Description: '- - - Loại dùng làm bao xi măng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48042990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy và bìa kraft khác có định lượng từ 150 g/m2 trở xuống:',
    Unit: '',
  },
  {
    HsCode: '480431',
    Description: '- - Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '48043110',
    Description: '- - - Giấy kraft cách điện (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48043130',
    Description: '- - - Có độ bền ướt từ 40 g đến 60 g, dùng để sản xuất băng dán gỗ dán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48043140',
    Description: '- - - Giấy làm nền sản xuất giấy ráp (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48043150',
    Description: '- - - Loại dùng làm bao xi măng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48043190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480439',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48043910',
    Description: '- - - Có độ bền ướt từ 40 g đến 60 g, dùng để sản xuất băng dán gỗ dán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48043920',
    Description: '- - - Giấy và bìa dùng để làm bao bì thực phẩm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48043990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy và bìa kraft khác có định lượng trên 150 g/m2 nhưng dưới 225 g/m2:',
    Unit: '',
  },
  {
    HsCode: '480441',
    Description: '- - Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '48044110',
    Description: '- - - Giấy kraft cách điện (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48044190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480442',
    Description:
      '- - Loại đã được tẩy trắng toàn bộ và có hàm lượng bột gỗ thu được từ quá trình hóa học trên 95% so với tổng lượng bột giấy tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '48044210',
    Description: '- - - Giấy và bìa dùng để làm bao bì thực phẩm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48044290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480449',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48044910',
    Description: '- - - Giấy và bìa dùng để làm bao bì thực phẩm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48044990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy và bìa kraft khác có định lượng từ 225 g/m2 trở lên:',
    Unit: '',
  },
  {
    HsCode: '480451',
    Description: '- - Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '48045110',
    Description: '- - - Giấy kraft cách điện (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48045120',
    Description: '- - - Bìa ép có định lượng từ 600g/m2 trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '48045130',
    Description: '- - - Có độ bền ướt từ 40 g đến 60 g, dùng để sản xuất băng dán gỗ dán(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48045190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480452',
    Description:
      '- - Loại đã được tẩy trắng toàn bộ và có hàm lượng bột gỗ thu được từ quá trình hóa học trên 95% so với tổng lượng bột giấy tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '48045210',
    Description: '- - - Giấy và bìa dùng để làm bao bì thực phẩm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48045290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480459',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48045910',
    Description: '- - - Giấy và bìa dùng để làm bao bì thực phẩm(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48045990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4805',
    Description:
      'Giấy và bìa không tráng khác, ở dạng cuộn hoặc tờ, chưa được gia công hoặc xử lý hơn mức đã chi tiết trong Chú giải 3 của Chương này',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Giấy để tạo lớp sóng:',
    Unit: '',
  },
  {
    HsCode: '48051100',
    Description: '- - Từ bột giấy bán hóa ',
    Unit: 'kg',
  },
  {
    HsCode: '48051200',
    Description: '- - Từ bột giấy rơm rạ',
    Unit: 'kg',
  },
  {
    HsCode: '480519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48051910',
    Description: '- - - Định lượng trên 150 g/m2 nhưng dưới 225 g/m2',
    Unit: 'kg',
  },
  {
    HsCode: '48051990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bìa lớp mặt (chủ yếu hoặc toàn bộ từ bột giấy tái chế):',
    Unit: '',
  },
  {
    HsCode: '48052400',
    Description: '- - Có định lượng từ 150 g/m2 trở xuống',
    Unit: 'kg',
  },
  {
    HsCode: '480525',
    Description: '- - Định lượng trên 150 g/m2:',
    Unit: '',
  },
  {
    HsCode: '48052510',
    Description: '- - - Có định lượng dưới 225 g/m2',
    Unit: 'kg',
  },
  {
    HsCode: '48052590',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480530',
    Description: '- Giấy bao gói làm từ bột giấy sulphit:',
    Unit: '',
  },
  {
    HsCode: '48053010',
    Description: '- - Giấy đã nhuộm màu dùng để bao quanh hộp diêm bằng gỗ(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48053090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48054000',
    Description: '- Giấy lọc và bìa lọc',
    Unit: 'kg',
  },
  {
    HsCode: '48055000',
    Description: '- Giấy nỉ và bìa nỉ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '480591',
    Description: '- - Có định lượng từ 150 g/m2 trở xuống:',
    Unit: '',
  },
  {
    HsCode: '48059110',
    Description:
      '- - - Giấy dùng để lót vào giữa các sản phẩm thủy tinh phẳng khi đóng gói, có hàm lượng nhựa không quá 0,6% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '48059120',
    Description: '- - - Loại dùng để sản xuất giấy vàng mã(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48059190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480592',
    Description: '- - Có định lượng trên 150 g/m2 nhưng dưới 225 g/m2:',
    Unit: '',
  },
  {
    HsCode: '48059210',
    Description: '- - - Giấy và bìa nhiều lớp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48059290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '480593',
    Description: '- - Có định lượng từ 225 g/m2 trở lên:',
    Unit: '',
  },
  {
    HsCode: '48059310',
    Description: '- - - Giấy và bìa nhiều lớp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48059320',
    Description: '- - - Giấy thấm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48059390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4806',
    Description:
      'Giấy giả da (parchment) gốc thực vật, giấy không thấm dầu mỡ, giấy can, giấy bóng mờ và giấy bóng trong hoặc các loại giấy trong khác, dạng cuộn hoặc tờ',
    Unit: '',
  },
  {
    HsCode: '48061000',
    Description: '- Giấy giả da gốc thực vật',
    Unit: 'kg',
  },
  {
    HsCode: '48062000',
    Description: '- Giấy không thấm dầu mỡ',
    Unit: 'kg',
  },
  {
    HsCode: '48063000',
    Description: '- Giấy can',
    Unit: 'kg',
  },
  {
    HsCode: '48064000',
    Description: '- Giấy bóng mờ và giấy bóng trong hoặc các loại giấy trong khác',
    Unit: 'kg',
  },
  {
    HsCode: '48070000',
    Description:
      'Giấy và bìa bồi (được làm bằng cách dán các lớp giấy phẳng hoặc bìa phẳng với nhau bằng chất kết dính), chưa tráng hoặc chưa thấm tẩm bề mặt, đã hoặc chưa được gia cố bên trong, ở dạng cuộn hoặc tờ',
    Unit: 'kg',
  },
  {
    HsCode: '4808',
    Description:
      'Giấy và bìa, đã tạo sóng (có hoặc không dán các lớp mặt phẳng), đã làm chun, làm nhăn, rập nổi hoặc đục lỗ, ở dạng cuộn hoặc tờ, trừ các loại thuộc nhóm 48.03',
    Unit: '',
  },
  {
    HsCode: '48081000',
    Description: '- Giấy và bìa sóng, đã hoặc chưa đục lỗ',
    Unit: 'kg',
  },
  {
    HsCode: '48084000',
    Description: '- Giấy kraft, đã làm chun hoặc làm nhăn, có hoặc không rập nổi hoặc đục lỗ ',
    Unit: 'kg',
  },
  {
    HsCode: '480890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48089020',
    Description: '- - Đã làm chun hoặc làm nhăn',
    Unit: 'kg',
  },
  {
    HsCode: '48089030',
    Description: '- - Đã rập nổi',
    Unit: 'kg',
  },
  {
    HsCode: '48089090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4809',
    Description:
      'Giấy than, giấy tự nhân bản và các loại giấy sao chép hoặc giấy chuyển khác (kể cả giấy đã tráng hoặc thấm tẩm dùng cho giấy nến nhân bản hoặc các tấm in offset), đã hoặc chưa in, dạng cuộn hoặc tờ',
    Unit: '',
  },
  {
    HsCode: '48092000',
    Description: '- Giấy tự nhân bản',
    Unit: 'kg',
  },
  {
    HsCode: '480990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48099010',
    Description: '- - Giấy than và các loại giấy sao chép tương tự',
    Unit: 'kg',
  },
  {
    HsCode: '48099090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4810',
    Description:
      'Giấy và bìa, đã tráng một hoặc cả hai mặt bằng cao lanh (China clay) hoặc bằng các chất vô cơ khác, có hoặc không có chất kết dính, và không có lớp phủ tráng nào khác, có hoặc không nhuộm màu bề mặt, có hoặc không trang trí hoặc in bề mặt, ở dạng cuộn hoặc tờ hình chữ nhật (kể cả hình vuông), với mọi kích cỡ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Giấy và bìa loại dùng để viết, in hoặc các mục đích đồ bản khác, không chứa bột giấy thu được từ quá trình cơ học hoặc quá trình hóa cơ hoặc có hàm lượng các bột giấy này không quá 10% so với tổng lượng bột giấy tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '481013',
    Description: '- - Dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '48101310',
    Description: '- - - Đã in, loại dùng cho máy ghi tự động, có chiều rộng từ 150 mm trở xuống(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48101391',
    Description: '- - - - Có chiều rộng từ 150 mm trở xuống',
    Unit: 'kg',
  },
  {
    HsCode: '48101399',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481014',
    Description: '- - Dạng tờ với một chiều không quá 435 mm và chiều kia không quá 297 mm ở dạng không gấp:',
    Unit: '',
  },
  {
    HsCode: '48101410',
    Description: '- - - Đã in, dùng cho máy ghi tự động, không có chiều nào trên 360 mm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48101491',
    Description: '- - - - Không có chiều nào trên 360 mm',
    Unit: 'kg',
  },
  {
    HsCode: '48101499',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481019',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48101910',
    Description: '- - - Đã in, loại dùng cho máy ghi tự động, không có chiều nào trên 360 mm ở dạng không gấp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48101990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- Giấy và bìa loại dùng để viết, in hoặc các mục đích đồ bản khác, có hàm lượng bột giấy thu được từ quá trình cơ học hoặc quá trình hóa cơ trên 10% so với tổng lượng bột giấy tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '481022',
    Description: '- - Giấy tráng nhẹ:',
    Unit: '',
  },
  {
    HsCode: '48102210',
    Description:
      '- - - Đã in, loại dùng cho máy ghi tự động, ở dạng cuộn có chiều rộng không quá 150 mm, hoặc ở dạng tờ không có chiều nào trên 360 mm ở dạng không gấp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48102290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481029',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48102910',
    Description:
      '- - - Đã in, loại dùng cho máy ghi tự động, ở dạng cuộn có chiều rộng không quá 150 mm, hoặc ở dạng tờ không có chiều nào trên 360 mm ở dạng không gấp(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48102991',
    Description:
      '- - - - Dạng cuộn có chiều rộng không quá 150 mm, hoặc dạng tờ không có chiều nào trên 360 mm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48102999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy kraft và bìa kraft, trừ loại dùng để viết, in hoặc dùng cho các mục đích đồ bản khác:',
    Unit: '',
  },
  {
    HsCode: '481031',
    Description:
      '- - Loại đã được tẩy trắng toàn bộ và có hàm lượng bột gỗ thu được từ quá trình hóa học trên 95% so với tổng lượng bột giấy tính theo khối lượng, và có định lượng từ 150g/m2 trở xuống:',
    Unit: '',
  },
  {
    HsCode: '48103130',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 150 mm hoặc ở dạng tờ không có chiều nào trên 360 mm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48103190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481032',
    Description:
      '- - Loại đã được tẩy trắng toàn bộ và có hàm lượng bột gỗ thu được từ quá trình hóa học trên 95% so với tổng lượng bột giấy tính theo khối lượng, và có định lượng trên 150 g/m2:',
    Unit: '',
  },
  {
    HsCode: '48103230',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 150 mm hoặc ở dạng tờ không có chiều nào trên 360 mm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48103290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481039',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48103930',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 150 mm hoặc ở dạng tờ không có chiều nào trên 360 mm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48103990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy và bìa khác:',
    Unit: '',
  },
  {
    HsCode: '481092',
    Description: '- - Loại nhiều lớp(SEN):',
    Unit: '',
  },
  {
    HsCode: '48109240',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 150 mm hoặc ở dạng tờ không có chiều nào trên 360 mm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48109290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481099',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48109940',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 150 mm hoặc ở dạng tờ không có chiều nào trên 360 mm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48109990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4811',
    Description:
      'Giấy, bìa, tấm xenlulo và màng xơ sợi xenlulo, đã tráng, thấm tẩm, phủ, nhuộm màu bề mặt, trang trí hoặc in bề mặt, ở dạng cuộn hoặc tờ hình chữ nhật (kể cả hình vuông), với kích thước bất kỳ, trừ các loại thuộc các nhóm 48.03, 48.09 hoặc 48.10',
    Unit: '',
  },
  {
    HsCode: '481110',
    Description: '- Giấy và bìa đã quét hắc ín, chất bi-tum hoặc nhựa đường:',
    Unit: '',
  },
  {
    HsCode: '48111020',
    Description:
      '- - Dạng cuộn có chiều rộng không quá 15cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48111090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy và bìa dính hoặc đã quét chất kết dính:',
    Unit: '',
  },
  {
    HsCode: '481141',
    Description: '- - Loại tự dính:',
    Unit: '',
  },
  {
    HsCode: '48114120',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 15 cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '48114190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48114900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Giấy và bìa đã tráng, thấm tẩm hoặc phủ bằng plastic (trừ chất kết dính):',
    Unit: '',
  },
  {
    HsCode: '481151',
    Description: '- - Loại đã tẩy trắng, định lượng trên 150 g/m2:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 15 cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp:',
    Unit: '',
  },
  {
    HsCode: '48115131',
    Description: '- - - - Tấm phủ sàn',
    Unit: 'kg',
  },
  {
    HsCode: '48115139',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48115191',
    Description: '- - - - Tấm phủ sàn',
    Unit: 'kg',
  },
  {
    HsCode: '48115199',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481159',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48115920',
    Description:
      '- - - Giấy và bìa đã phủ cả hai mặt bằng màng plastic trong suốt và được lót trong bằng một lớp nhôm mỏng, sử dụng để đóng gói thực phẩm dạng lỏng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- - - Dạng cuộn có chiều rộng không quá 15 cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp:',
    Unit: '',
  },
  {
    HsCode: '48115941',
    Description: '- - - - Tấm phủ sàn',
    Unit: 'kg',
  },
  {
    HsCode: '48115949',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48115991',
    Description: '- - - - Tấm phủ sàn',
    Unit: 'kg',
  },
  {
    HsCode: '48115999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481160',
    Description: '- Giấy và bìa, đã tráng, thấm, tẩm hoặc phủ bằng sáp, sáp parafin, stearin, dầu hoặc glyxerin:',
    Unit: '',
  },
  {
    HsCode: '48116020',
    Description:
      '- - Dạng cuộn có chiều rộng không quá 15cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48116091',
    Description: '- - - Tấm phủ sàn',
    Unit: 'kg',
  },
  {
    HsCode: '48116099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481190',
    Description: '- Giấy, bìa, tấm xenlulo và màng xơ sợi xenlulo khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Dạng cuộn có chiều rộng không quá 15 cm hoặc ở dạng tờ hình chữ nhật (kể cả hình vuông) không có chiều nào trên 36 cm ở dạng không gấp:',
    Unit: '',
  },
  {
    HsCode: '48119041',
    Description: '- - - Tấm phủ sàn',
    Unit: 'kg',
  },
  {
    HsCode: '48119042',
    Description: '- - - Giấy tạo vân(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48119049',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48119091',
    Description: '- - - Tấm phủ sàn',
    Unit: 'kg',
  },
  {
    HsCode: '48119092',
    Description: '- - - Giấy tạo vân(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48119093',
    Description: '- - - Loại khác, tấm xenlulo hoặc màng xơ sợi xenlulo',
    Unit: 'kg',
  },
  {
    HsCode: '48119099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '48120000',
    Description: 'Khối, miếng và tấm lọc, bằng bột giấy',
    Unit: 'kg',
  },
  {
    HsCode: '4813',
    Description: 'Giấy cuốn thuốc lá điếu, đã hoặc chưa cắt theo cỡ hoặc ở dạng tập nhỏ hoặc cuốn sẵn thành ống',
    Unit: '',
  },
  {
    HsCode: '48131000',
    Description: '- Dạng tập hoặc cuốn sẵn thành ống',
    Unit: 'kg',
  },
  {
    HsCode: '481320',
    Description: '- Dạng cuộn với chiều rộng không quá 5 cm:',
    Unit: '',
  },
  {
    HsCode: '48132010',
    Description: '- - Giấy sáp ghép đầu lọc thuốc lá điếu (cigarette tipping paper) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, không phủ:',
    Unit: '',
  },
  {
    HsCode: '48132021',
    Description: '- - - Giấy bọc thuốc lá (Tobacco wrapping paper) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48132022',
    Description: '- - - Giấy cuốn đầu lọc có độ xốp hơn 12 cm3 (min-1. cm-2) trong đơn vị thấm khí CORESTA (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48132023',
    Description: '- - - Giấy cuốn đầu lọc khác (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48132029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, đã phủ:',
    Unit: '',
  },
  {
    HsCode: '48132031',
    Description: '- - - Giấy bọc thuốc lá (Tobacco wrapping paper) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48132032',
    Description: '- - - Giấy cuốn đầu lọc (Plug wrap paper) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48132039',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng cuộn với chiều rộng trên 5 cm, đã phủ:',
    Unit: '',
  },
  {
    HsCode: '48139011',
    Description: '- - - Giấy bọc thuốc lá (Tobacco wrapping paper) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48139012',
    Description: '- - - Giấy sáp ghép đầu lọc thuốc lá điếu (cigarette tipping paper) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48139019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48139091',
    Description: '- - - Giấy bọc thuốc lá (Tobacco wrapping paper) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '48139099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4814',
    Description: 'Giấy dán tường và các loại tấm phủ tường tương tự; tấm che cửa sổ trong suốt bằng giấy',
    Unit: '',
  },
  {
    HsCode: '481420',
    Description:
      '- Giấy dán tường và các loại tấm phủ tường tương tự, bao gồm giấy đã được tráng hoặc phủ, trên bề mặt, bằng một lớp plastic có hạt nổi, rập nổi, nhuộm màu, in hình hoặc trang trí cách khác:',
    Unit: '',
  },
  {
    HsCode: '48142010',
    Description: '- - Có chiều rộng không quá 60 cm',
    Unit: 'kg/m',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48142091',
    Description: '- - - Giấy dán tường dạng tranh (Photo murals) (SEN)',
    Unit: 'kg/m',
  },
  {
    HsCode: '48142099',
    Description: '- - - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '48149000',
    Description: '- Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '4816',
    Description:
      'Giấy than, giấy tự nhân bản và các loại giấy dùng để sao chụp khác hoặc giấy chuyển (trừ các loại thuộc nhóm 48.09), giấy nến nhân bản và các tấm in offset, bằng giấy, đã hoặc chưa đóng hộp',
    Unit: '',
  },
  {
    HsCode: '481620',
    Description: '- Giấy tự nhân bản:',
    Unit: '',
  },
  {
    HsCode: '48162010',
    Description: '- - Dạng cuộn với chiều rộng trên 15 cm nhưng không quá 36 cm',
    Unit: 'kg',
  },
  {
    HsCode: '48162090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '481690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48169010',
    Description: '- - Giấy than',
    Unit: 'kg',
  },
  {
    HsCode: '48169020',
    Description: '- - Giấy dùng để sao chụp khác',
    Unit: 'kg',
  },
  {
    HsCode: '48169030',
    Description: '- - Tấm in offset',
    Unit: 'kg',
  },
  {
    HsCode: '48169040',
    Description: '- - Giấy chuyển nhiệt',
    Unit: 'kg',
  },
  {
    HsCode: '48169050',
    Description: '- - Loại khác, dạng cuộn có chiều rộng trên 15 cm nhưng không quá 36 cm',
    Unit: 'kg',
  },
  {
    HsCode: '48169090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4817',
    Description:
      'Phong bì, bưu thiếp dạng phong bì (letter cards), bưu thiếp trơn và bưu thiếp dạng thư tín (correspondence cards), bằng giấy hoặc bìa; các loại hộp, túi ví, cặp tài liệu và cặp hồ sơ in sẵn, bằng giấy hoặc bìa, có chứa văn phòng phẩm bằng giấy',
    Unit: '',
  },
  {
    HsCode: '48171000',
    Description: '- Phong bì',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48172000',
    Description:
      '- Bưu thiếp dạng phong bì (letter cards), bưu thiếp trơn và bưu thiếp dạng thư tín (correspondence cards)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48173000',
    Description:
      '- Hộp, túi ví, cặp tài liệu và cặp hồ sơ in sẵn, bằng giấy hoặc bìa, có chứa văn phòng phẩm bằng giấy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4818',
    Description:
      'Giấy vệ sinh và giấy tương tự, tấm xenlulo hoặc màng xơ sợi xenlulo, dùng trong gia đình hoặc vệ sinh, dạng cuộn có chiều rộng không quá 36 cm, hoặc cắt theo hình dạng hoặc kích thước; khăn tay, giấy lụa lau, khăn lau, khăn trải bàn, khăn ăn (serviettes), khăn trải giường và các đồ dùng nội trợ, vệ sinh hoặc các vật phẩm dùng cho bệnh viện tương tự, các vật phẩm trang trí và đồ phụ kiện may mặc, bằng bột giấy, giấy, tấm xenlulo hoặc màng xơ sợi xenlulo',
    Unit: '',
  },
  {
    HsCode: '48181000',
    Description: '- Giấy vệ sinh',
    Unit: 'kg',
  },
  {
    HsCode: '48182000',
    Description: '- Khăn tay, giấy lụa lau chùi hoặc lau mặt và khăn lau',
    Unit: 'kg',
  },
  {
    HsCode: '481830',
    Description: '- Khăn trải bàn và khăn ăn:',
    Unit: '',
  },
  {
    HsCode: '48183010',
    Description: '- - Khăn trải bàn',
    Unit: 'kg',
  },
  {
    HsCode: '48183020',
    Description: '- - Khăn ăn (serviettes)',
    Unit: 'kg',
  },
  {
    HsCode: '48185000',
    Description: '- Các vật phẩm dùng cho trang trí và đồ phụ kiện may mặc',
    Unit: 'kg',
  },
  {
    HsCode: '48189000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '4819',
    Description:
      'Thùng, hộp, vỏ chứa, túi xách và các loại bao bì đựng khác, bằng giấy, bìa, tấm xenlulo hoặc màng xơ sợi xenlulo; hộp đựng hồ sơ (files), khay thư, và các vật phẩm tương tự, bằng giấy hoặc bìa dùng cho văn phòng, cửa hàng hoặc những nơi tương tự',
    Unit: '',
  },
  {
    HsCode: '48191000',
    Description: '- Thùng, hộp và vỏ chứa, bằng giấy sóng hoặc bìa sóng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48192000',
    Description: '- Thùng, hộp và vỏ chứa gấp lại được, bằng giấy hoặc bìa không sóng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48193000',
    Description: '- Bao và túi xách, có đáy rộng từ 40 cm trở lên',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48194000',
    Description: '- Bao và túi xách loại khác, kể cả loại hình nón cụt (cones)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48195000',
    Description: '- Bao bì đựng khác, kể cả túi đựng đĩa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48196000',
    Description:
      '- Hộp đựng hồ sơ (box files), khay thư, hộp lưu trữ và các vật phẩm tương tự, loại dùng trong văn phòng, cửa hàng hoặc những nơi tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4820',
    Description:
      'Sổ đăng ký, sổ sách kế toán, vở ghi chép, sổ đặt hàng, quyển biên lai, tập viết thư, tập ghi nhớ, sổ nhật ký và các ấn phẩm tương tự, vở bài tập, quyển giấy thấm, bìa đóng hồ sơ (loại tờ rời hoặc loại khác), bìa kẹp hồ sơ, vỏ bìa kẹp hồ sơ, biểu mẫu thương mại các loại, tập giấy ghi chép có chèn giấy than và các vật phẩm văn phòng khác, bằng giấy hoặc bìa; album để mẫu hoặc để bộ sưu tập và các loại bìa sách, bằng giấy hoặc bìa',
    Unit: '',
  },
  {
    HsCode: '48201000',
    Description:
      '- Sổ đăng ký, sổ kế toán, vở ghi chép, sổ đặt hàng, quyển biên lai, tập viết thư, tập ghi nhớ, sổ nhật ký và các ấn phẩm tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48202000',
    Description: '- Vở bài tập',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48203000',
    Description: '- Bìa đóng hồ sơ (trừ bìa đóng sách), bìa kẹp hồ sơ và vỏ bìa kẹp hồ sơ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48204000',
    Description: '- Biểu mẫu thương mại và tập giấy ghi chép có chèn giấy than',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48205000',
    Description: '- Album để mẫu hoặc để bộ sưu tập',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48209000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4821',
    Description: 'Các loại nhãn mác bằng giấy hoặc bìa, đã hoặc chưa in',
    Unit: '',
  },
  {
    HsCode: '482110',
    Description: '- Đã in:',
    Unit: '',
  },
  {
    HsCode: '48211010',
    Description:
      '- - Nhãn mác dùng cho đồ trang sức, kể cả loại cho đồ trang sức cá nhân hoặc các vật dụng cá nhân được để trong ví, túi xách hoặc mang trên người(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48211090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '482190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48219010',
    Description:
      '- - Nhãn mác dùng cho đồ trang sức, kể cả loại cho đồ trang sức cá nhân hoặc các vật dụng cá nhân được để trong ví, túi xách hoặc mang trên người(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48219090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4822',
    Description:
      'Ống lõi, suốt, cúi và các loại lõi tương tự bằng bột giấy, giấy hoặc bìa (đã hoặc chưa đục lỗ hoặc làm cứng)',
    Unit: '',
  },
  {
    HsCode: '482210',
    Description: '- Loại dùng để cuốn sợi dệt:',
    Unit: '',
  },
  {
    HsCode: '48221010',
    Description: '- - Hình nón cụt (cones)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48221090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '482290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48229010',
    Description: '- - Hình nón cụt (cones)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48229090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4823',
    Description:
      'Giấy, bìa, tấm xenlulo và màng xơ sợi xenlulo khác, đã cắt theo kích cỡ hoặc hình dạng; các vật phẩm khác bằng bột giấy, giấy, bìa, tấm xenlulo hoặc màng xơ sợi xenlulo',
    Unit: '',
  },
  {
    HsCode: '482320',
    Description: '- Giấy lọc và bìa lọc:',
    Unit: '',
  },
  {
    HsCode: '48232010',
    Description: '- - Dạng dải, cuộn hoặc tờ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48232090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '482340',
    Description: '- Dạng cuộn, tờ và đĩa, đã in dùng cho máy ghi tự động:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho máy điện ghi trong ngành y:',
    Unit: '',
  },
  {
    HsCode: '48234021',
    Description: '- - - Giấy ghi điện tâm đồ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48234029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48234090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Khay, bát, đĩa, cốc, chén và các sản phẩm tương tự, bằng giấy hoặc bìa:',
    Unit: '',
  },
  {
    HsCode: '48236100',
    Description: '- - Từ tre (bamboo)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48236900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48237000',
    Description: '- Các sản phẩm đúc hoặc nén bằng bột giấy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '482390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48239010',
    Description: '- - Khung kén tằm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239020',
    Description:
      '- - Thẻ trưng bày đồ trang sức, kể cả loại cho đồ trang sức cá nhân hoặc các vật dụng cá nhân được để trong ví, túi xách hoặc mang trên người',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239030',
    Description: '- - Bìa tráng polyetylen đã cắt dập thành hình để sản xuất cốc giấy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239040',
    Description: '- - Bộ ống giấy để sản xuất pháo hoa(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Giấy kraft ở dạng cuộn có chiều rộng 209 mm, loại sử dụng làm bao gói cho thỏi dynamit:',
    Unit: '',
  },
  {
    HsCode: '48239051',
    Description: '- - - Có định lượng từ 150 g/m2 trở xuống',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239059',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239060',
    Description: '- - Thẻ jacquard đã đục lỗ ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239070',
    Description: '- - Quạt và màn che kéo bằng tay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '48239091',
    Description: '- - - Giấy silicon(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239092',
    Description: '- - - Giấy vàng mã(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239094',
    Description: '- - - Tấm xenlulo và màng xơ sợi xenlulo, được tạo màu hoặc tạo vân toàn bộ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239095',
    Description: '- - - Tấm phủ sàn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239096',
    Description: '- - - Loại khác, đã cắt thành hình trừ hình chữ nhật hoặc hình vuông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '48239099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 49',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Sách, báo, tranh ảnh và các sản phẩm khác của công nghiệp in; các loại bản thảo viết bằng tay, đánh máy                và sơ đồ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Phim tạo ảnh âm bản hoặc dương bản (Chương 37);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Bản đồ, sơ đồ hoặc quả địa cầu, dạng nổi, đã hoặc chưa in (nhóm 90.23);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Bộ bài để chơi hoặc hàng hóa khác thuộc Chương 95; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Bản khắc, bản in và bản in lytô gốc (nhóm 97.02), tem bưu chính hoặc tem thuế, dấu in cước thay tem bưu chính, phong bì có tem đóng dấu ngày phát hành đầu tiên, ấn phẩm bưu chính hoặc những loại hàng hóa tương tự thuộc nhóm 97.04, đồ cổ có tuổi trên 100 năm hoặc các loại hàng hóa khác thuộc Chương 97.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của Chương 49, khái niệm ""đã in"" cũng có nghĩa là đã được tái bản bằng máy nhân bản, được tạo ra nhờ một máy xử lý dữ liệu tự động, được rập nổi, được chụp lại, được photocopy, được copy nhiệt hoặc được đánh máy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các loại báo, tạp chí chuyên ngành và các xuất bản phẩm định kỳ được đóng bìa không phải bìa giấy mềm, và các bộ báo, tạp chí chuyên ngành hoặc xuất bản phẩm định kỳ được đóng thành tập có từ 2 số trở lên trong một bìa thì phải được phân loại vào nhóm 49.01, có hoặc không chứa tư liệu quảng cáo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '4. Nhóm 49.01 cũng bao gồm: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Bộ sưu tập các tái bản, ví dụ, của các tác phẩm nghệ thuật hoặc tranh vẽ, có kèm theo phần lời đề mục, chủ đề, với cách đánh số trang phù hợp để đóng gói thành một hoặc nhiều tập;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Tập tranh ảnh minh họa, và phụ lục cho một cuốn sách; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các phần đã in của sách hoặc sách nhỏ, ở dạng đã xếp thành tập hoặc tờ rời hoặc đã có ký hiệu trang để đóng gói thành bộ hoàn chỉnh hoặc từng phần của tác phẩm hoàn chỉnh và được thiết kế để đóng quyển.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, các loại tranh ảnh đã in hoặc minh họa không kèm theo lời đề mục, hoặc ở dạng tập có số trang hoặc ở dạng tờ rời, thì được xếp vào nhóm 49.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo Chú giải 3 của Chương này, nhóm 49.01 không bao gồm các xuất bản phẩm chỉ dành riêng cho việc quảng cáo (ví dụ, các loại sách gấp, sách chuyên đề (pamphlet), sách mỏng, tờ rơi, catalogue quảng cáo thương mại, niên giám do các tổ chức thương mại, cơ quan tuyên truyền du lịch xuất bản). Các ấn phẩm này được phân loại trong nhóm 49.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của nhóm 49.03, khái niệm ""sách tranh ảnh cho trẻ em"" có nghĩa là loại sách dành cho trẻ em trong đó chủ yếu là tranh ảnh và lời chỉ là phụ.',
    Unit: '',
  },
  {
    HsCode: '4901',
    Description:
      'Các loại sách in, sách gấp, sách mỏng và các ấn phẩm in tương tự, dạng tờ đơn hoặc không phải dạng tờ đơn',
    Unit: '',
  },
  {
    HsCode: '49011000',
    Description: '- Dạng tờ đơn, có hoặc không gấp',
    Unit: 'kg/cuốn',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '49019100',
    Description: '- - Từ điển và bộ bách khoa toàn thư, và các phụ trương của chúng',
    Unit: 'kg/cuốn',
  },
  {
    HsCode: '490199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '49019910',
    Description: '- - - Sách giáo dục, kỹ thuật, khoa học, lịch sử hoặc văn hóa ',
    Unit: 'cuốn',
  },
  {
    HsCode: '49019990',
    Description: '- - - Loại khác',
    Unit: 'cuốn',
  },
  {
    HsCode: '4902',
    Description:
      'Báo, tạp chí chuyên ngành và các ấn phẩm định kỳ, có hoặc không có minh họa tranh ảnh hoặc chứa nội dung quảng cáo',
    Unit: '',
  },
  {
    HsCode: '49021000',
    Description: '- Phát hành ít nhất 4 lần trong một tuần',
    Unit: 'cuốn',
  },
  {
    HsCode: '490290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '49029010',
    Description: '- - Tạp chí và ấn phẩm định kỳ về giáo dục, kỹ thuật, khoa học, lịch sử hoặc văn hóa (SEN)',
    Unit: 'cuốn',
  },
  {
    HsCode: '49029090',
    Description: '- - Loại khác',
    Unit: 'cuốn',
  },
  {
    HsCode: '49030000',
    Description: 'Sách tranh ảnh, sách vẽ hoặc sách tô màu cho trẻ em',
    Unit: 'cuốn',
  },
  {
    HsCode: '49040000',
    Description: 'Bản nhạc, in hoặc viết tay, đã hoặc chưa đóng thành quyển hoặc minh họa tranh ảnh',
    Unit: 'cuốn',
  },
  {
    HsCode: '4905',
    Description:
      'Bản đồ và biểu đồ thủy văn hoặc các loại biểu đồ tương tự, kể cả tập bản đồ, bản đồ treo tường, bản đồ địa hình và quả địa cầu, đã in',
    Unit: '',
  },
  {
    HsCode: '49052000',
    Description: '- Dạng quyển',
    Unit: 'cuốn',
  },
  {
    HsCode: '49059000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49060000',
    Description:
      'Các loại sơ đồ và bản vẽ cho kiến trúc, kỹ thuật, công nghiệp, thương mại, địa hình hoặc các mục đích tương tự, là bản gốc vẽ tay; văn bản viết tay; các bản sao chụp lại bằng giấy có phủ lớp chất nhạy và bằng giấy than của các loại kể trên',
    Unit: 'kg/cuốn',
  },
  {
    HsCode: '4907',
    Description:
      'Các loại tem bưu chính, tem thuế hoặc tem tương tự chưa qua sử dụng, loại đang được lưu hành hoặc mới phát hành tại nước mà ở đó chúng có, hoặc sẽ có giá mặt được công nhận; giấy có dấu tem sẵn; các loại giấy bạc ngân hàng (banknotes); mẫu séc; giấy chứng nhận cổ phần, cổ phiếu hoặc trái phiếu và các loại chứng từ sở hữu tương tự',
    Unit: '',
  },
  {
    HsCode: '49070010',
    Description: '- Giấy bạc ngân hàng (banknotes), loại pháp định(*)',
    Unit: 'kg/tờ',
  },
  {
    HsCode: '',
    Description: '- Tem bưu chính, tem thuế hoặc các loại tem tương tự chưa sử dụng:',
    Unit: '',
  },
  {
    HsCode: '49070021',
    Description: '- - Tem bưu chính',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49070029',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49070050',
    Description: '- Mẫu séc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49070060',
    Description: '- Giấy chứng nhận cổ phần, cổ phiếu hoặc trái phiếu và các loại chứng từ sở hữu tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49070090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '4908',
    Description: 'Đề can các loại (decalcomanias)',
    Unit: '',
  },
  {
    HsCode: '49081000',
    Description: '- Đề can các loại (decalcomanias), dùng cho các sản phẩm thủy tinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49089000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49090000',
    Description:
      'Bưu thiếp in hoặc bưu ảnh; các loại thiếp in sẵn chứa lời chúc, thông điệp hoặc thông báo, có hoặc không có minh họa, có hoặc không có phong bì kèm theo hoặc phụ kiện trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49100000',
    Description: 'Các loại lịch in, kể cả bloc lịch',
    Unit: 'cuốn',
  },
  {
    HsCode: '4911',
    Description: 'Các ấn phẩm in khác, kể cả tranh và ảnh in',
    Unit: '',
  },
  {
    HsCode: '491110',
    Description: '- Các ấn phẩm quảng cáo thương mại, các catalog thương mại và các ấn phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '49111010',
    Description:
      '- - Catalog chỉ liệt kê tên sách và các ấn phẩm về giáo dục, kỹ thuật, khoa học, lịch sử hoặc văn hóa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49111090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '491191',
    Description: '- - Tranh, bản thiết kế và ảnh các loại:',
    Unit: '',
  },
  {
    HsCode: '49119110',
    Description: '- - - Bản thiết kế',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, thuộc loại để gắn, dùng cho mục đích hướng dẫn:',
    Unit: '',
  },
  {
    HsCode: '49119121',
    Description: '- - - - Giải phẫu học và thực vật học',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49119129',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '49119191',
    Description: '- - - - Giải phẫu học và thực vật học',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49119199',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '491199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '49119910',
    Description:
      '- - - Thẻ in sẵn cho đồ trang sức hoặc cho các đồ tư trang nhỏ trang điểm cá nhân hoặc đồ dùng cá nhân thường được mang theo trong ví, túi sách tay hoặc mang theo người (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49119920',
    Description: '- - - Nhãn đã được in để báo nguy hiểm dễ nổ trừ loại thuộc nhóm 48.21 (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49119930',
    Description: '- - - Bộ thẻ đã được in nội dung về giáo dục, kỹ thuật, khoa học, lịch sử hoặc văn hóa (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49119940',
    Description:
      '- - - Ấn phẩm in cấp quyền truy cập, cài đặt, tái bản hoặc ấn phẩm in khác sử dụng phần mềm (bao gồm cả trò chơi), dữ liệu, nội dung internet (bao gồm cả nội dung trong trò chơi hoặc trong ứng dụng) hoặc các dịch vụ, hoặc dịch vụ viễn thông (bao gồm cả dịch vụ di động) (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '49119990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(*): Trong phạm vi nhóm 49.07 và 71.18, khái niệm ""loại pháp định"" được hiểu là công cụ thanh toán phát hành bởi ngân hàng được pháp luật công nhận',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XI',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'NGUYÊN LIỆU DỆT VÀ CÁC SẢN PHẨM DỆT',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Phần này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Lông cứng hoặc lông động vật dùng làm bàn chải (nhóm 05.02); lông đuôi hoặc bờm ngựa hoặc phế liệu lông đuôi hoặc bờm ngựa (nhóm 05.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Tóc người hoặc các sản phẩm bằng tóc người',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(nhóm 05.01, 67.03 hoặc 67.04), trừ vải lọc dùng trong công nghệ ép dầu hoặc tương tự (nhóm 59.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Xơ của cây bông hoặc các vật liệu thực vật khác thuộc Chương 14;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Amiăng (asbestos) thuộc nhóm 25.24 hoặc các sản phẩm từ amiăng hoặc các sản phẩm khác thuộc nhóm 68.12 hoặc 68.13;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Các sản phẩm thuộc nhóm 30.05 hoặc 30.06; chỉ sử dụng để làm sạch các kẽ răng (chỉ tơ nha khoa), đóng gói để bán lẻ, thuộc nhóm 33.06;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các loại vải dệt có phủ lớp chất nhạy thuộc các nhóm từ 37.01 đến 37.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Sợi monofilament có kích thước mặt cắt ngang bất kỳ trên 1 mm hoặc dải hoặc dạng tương tự (ví dụ, sợi rơm nhân tạo) có chiều rộng biểu kiến trên 5 mm, bằng plastic (Chương 39), hoặc các loại dây tết bện hoặc vải hoặc sản phẩm dạng song mây tre đan khác hoặc liễu gai làm bằng sợi monofilament hoặc dải đó (Chương 46);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Các loại vải dệt thoi, dệt kim hoặc móc, phớt hoặc sản phẩm không dệt, đã ngâm tẩm, tráng, phủ hoặc ép với plastic, hoặc các sản phẩm làm từ các vật liệu đó, thuộc Chương 39;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Các loại vải dệt thoi, dệt kim hoặc móc, phớt hoặc sản phẩm không dệt, đã ngâm tẩm, tráng, phủ hoặc ép với cao su, hoặc các sản phẩm làm từ các vật liệu đó, thuộc Chương 40;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(k) Da sống còn lông (Chương 41 hoặc 43) hoặc sản phẩm da lông, da lông nhân tạo hoặc các sản phẩm làm bằng các vật liệu đó, thuộc nhóm 43.03 hoặc 43.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các loại hàng hóa làm bằng vật liệu dệt thuộc nhóm 42.01 hoặc 42.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Các sản phẩm hoặc hàng hóa thuộc Chương 48 (ví dụ, mền xơ xenlulo);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(n) Giày, dép hoặc các bộ phận của giày dép, ghệt hoặc ống ôm sát chân (leggings) hoặc các mặt hàng tương tự thuộc Chương 64;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(o) Lưới bao tóc hoặc các vật đội đầu khác hoặc các bộ phận của chúng thuộc Chương 65;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(p) Hàng hóa thuộc Chương 67;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(q) Vật liệu dệt đã phủ bột mài (nhóm 68.05) và xơ carbon hoặc các sản phẩm bằng xơ carbon thuộc nhóm 68.15;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(r) Sợi thủy tinh hoặc các sản phẩm bằng sợi thủy tinh, trừ hàng thêu bằng chỉ thủy tinh trên vải lộ nền (Chương 70);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(s) Hàng hóa thuộc Chương 94 (ví dụ, đồ nội thất, bộ đồ giường, đèn (luminaires) và bộ đèn);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(t) Hàng hóa thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao và lưới);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(u) Hàng hóa thuộc Chương 96 (ví dụ, bàn chải, bộ đồ khâu du lịch, khóa kéo và ruy băng máy chữ, băng vệ sinh (miếng) và băng vệ sinh dạng ống (tampon), khăn (bỉm) và tã lót; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(v) Hàng hóa thuộc Chương 97.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. (A) Hàng hóa có thể phân loại vào các Chương từ 50 đến 55 hoặc nhóm 58.09 hoặc 59.02 và được làm từ hỗn hợp của hai hoặc nhiều loại vật liệu dệt được phân loại như sản phẩm đó làm từ vật liệu dệt nào chiếm khối lượng trội hơn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Khi không có một vật liệu dệt nào chiếm khối lượng trội hơn, hàng hóa sẽ được phân loại xem như nó được làm toàn bộ từ một vật liệu dệt của nhóm có thứ tự cuối cùng trong số các nhóm tương đương cùng đưa ra xem xét.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Quy tắc trên được hiểu là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Sợi quấn từ lông đuôi và bờm ngựa (nhóm 51.10) với sợi trộn kim loại (nhóm 56.05) được phân loại như một loại vật liệu dệt đơn có khối lượng bằng tổng khối lượng của các thành phần của nó; để phân loại vải dệt thoi, sợi kim loại được coi như là một vật liệu dệt;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Để chọn nhóm thích hợp trước tiên phải lựa chọn Chương phù hợp và sau đó lựa chọn nhóm thích hợp trong Chương đó, kể cả trong thành phần hàng hóa có loại vật liệu không thuộc Chương đó;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Khi cả hai Chương 54 và 55 đều liên quan đến một Chương khác, thì Chương 54 và 55 được xem như một Chương;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Trường hợp một Chương hoặc một nhóm có các hàng hóa làm bằng vật liệu dệt khác nhau, các vật liệu dệt này được xem như một loại vật liệu đơn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(C) Các nguyên tắc của phần (A) và (B) trên đây cũng áp dụng cho các loại sợi nêu trong Chú giải 3, 4, 5 hoặc 6 dưới đây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. (A) Theo mục đích của Phần này, và những nội dung loại trừ trong phần (B) tiếp theo, các loại sợi (đơn, xe (folded) hoặc cáp) của mô tả dưới đây sẽ được xem như ""sợi xe, chão bện (cordage), thừng và cáp"":',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Từ tơ tằm hoặc phế liệu tơ tằm, độ mảnh trên 20.000 decitex;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Từ các xơ nhân tạo (kể cả sợi từ 2 hoặc nhiều sợi monofilament thuộc Chương 54), độ mảnh trên 10.000 decitex;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Từ gai dầu hoặc lanh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Đã chuốt hoặc làm bóng, độ mảnh từ 1.429 decitex trở lên; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Chưa chuốt hoặc chưa làm bóng, độ mảnh trên 20.000 decitex;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Từ xơ dừa, gồm từ 3 sợi trở lên;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Từ xơ thực vật khác, độ mảnh trên 20.000 decitex; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Được tăng cường bằng sợi kim loại.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Loại trừ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Sợi bằng lông cừu hoặc lông động vật khác và bằng sợi giấy, trừ sợi được tăng cường bằng sợi kim loại;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Tô (tow) filament nhân tạo thuộc Chương 55 và sợi multifilament không xoắn hoặc xoắn dưới 5 vòng xoắn trên mét thuộc Chương 54;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Ruột con tằm thuộc nhóm 50.06, và các sợi monofilament thuộc Chương 54;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Sợi trộn kim loại thuộc nhóm 56.05; sợi được tăng cường bằng sợi kim loại đã nêu trong Phần (A) (f) trên; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Sợi sơnin (chenille), sợi quấn và sợi sùi vòng thuộc nhóm 56.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. (A) Theo mục đích của các Chương 50, 51, 52, 54 và 55, khái niệm sợi ""đóng gói để bán lẻ"" có nghĩa là, lưu ý xem xét cả những loại trừ trong Phần (B) dưới đây, sợi (đơn, xe (folded) hoặc cáp) đóng gói ở các dạng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Trên bìa, guồng gờ, ống tuýp hoặc cuộn có lõi tương tự, với khối lượng (kể cả lõi) không quá:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) 85 g đối với tơ tằm, phế liệu tơ tằm hoặc sợi filament nhân tạo; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) 125 g đối với các loại sợi khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dạng hình cầu, con sợi hoặc cuộn sợi với khối lượng không quá:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) 85 g đối với sợi filament nhân tạo độ mảnh dưới 3.000 decitex, tơ tằm hoặc phế liệu tơ tằm;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) 125 g đối với các loại sợi khác có độ mảnh dưới 2.000 decitex; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) 500 g đối với các loại sợi khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Dạng con sợi hoặc cuộn sợi gồm các con sợi hoặc cuộn sợi nhỏ hơn được phân cách nhau bởi các đường chỉ làm cho chúng độc lập với nhau, mỗi con sợi hoặc cuộn sợi nhỏ có khối lượng đồng nhất không quá:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) 85 g đối với tơ tằm, phế liệu tơ tằm hoặc sợi filament nhân tạo; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) 125 g đối với các loại sợi khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Loại trừ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Sợi đơn bằng nguyên liệu dệt bất kỳ, trừ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Sợi đơn bằng lông cừu hoặc lông động vật loại mịn, chưa tẩy trắng; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) Sợi đơn bằng lông cừu hoặc lông động vật loại mịn, đã tẩy trắng, nhuộm hoặc đã in, độ mảnh trên 5.000 decitex;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Sợi xe (folded) hoặc sợi cáp, chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Bằng tơ tằm hoặc phế liệu tơ tằm, đã đóng gói; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) Bằng nguyên liệu dệt khác trừ lông cừu hoặc lông động vật loại mịn, ở dạng con sợi hoặc cuộn sợi;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Sợi xe (folded) hoặc sợi cáp bằng tơ tằm hoặc phế liệu tơ tằm, đã tẩy trắng, nhuộm hoặc in, độ mảnh từ 133 decitex trở xuống; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Sợi đơn, sợi xe (folded) hoặc sợi cáp bằng nguyên liệu dệt bất kỳ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Ở dạng con sợi hoặc cuộn sợi được guồng chéo; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) Cuộn trên lõi hoặc cuộn cách khác để sử dụng trong công nghiệp dệt (ví dụ, cuộn trên ống sợi con, ống sợi xe, suốt ngang, bobin côn hoặc cọc sợi, hoặc cuốn theo dạng kén tằm dùng cho các máy thêu).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của các nhóm 52.04, 54.01 và 55.08, khái niệm ""chỉ khâu"" có nghĩa là loại sợi xe (folded) hoặc sợi cáp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Cuộn trên lõi (ví dụ, guồng gờ, ống tuýp) khối lượng không quá 1.000 g (kể cả lõi);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Đã hoàn tất để sử dụng làm chỉ khâu; và ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Có xoắn ""Z"" cuối cùng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của Phần này, khái niệm ""sợi có độ bền cao"" có nghĩa là loại sợi có độ bền tương đối đo bằng cN/tex (xen ti newton một tex), lớn hơn các tiêu chuẩn sau đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sợi đơn bằng ni lông hoặc các polyamit khác, hoặc bằng polyeste…... 60cN/tex',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sợi xe (folded) hoặc sợi cáp bằng ni lông hoặc các polyamit khác, hoặc bằng polyeste…... 53 cN/tex ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sợi đơn, sợi xe (folded) hoặc sợi cáp bằng viscose rayon…... 27 cN/tex. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '7. Theo mục đích của Phần này, khái niệm ""hoàn thiện"" nghĩa là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Đã cắt thành hình trừ hình vuông hoặc hình chữ nhật;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Được tạo ra trong công đoạn hoàn thiện, sẵn sàng để sử dụng (hoặc chỉ cần tách ra bằng cách cắt các đường chỉ phân chia) mà không cần phải khâu, may hoặc gia công khác (ví dụ, khăn chuyên lau bụi, khăn lau, khăn trải bàn, khăn vuông, mền chăn);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Cắt theo cỡ và có ít nhất một cạnh được làm kín bằng nhiệt mà đường viền được nén hoặc vuốt thon nhận ra dễ dàng và các cạnh khác được xử lý như đã mô tả trong phần khác bất kỳ của Chú giải này, nhưng trừ các vải có các mép đã được làm cho khỏi sổ bằng cách cắt nóng hoặc bằng các phương pháp đơn giản khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Đã viền lại hoặc cuộn mép, hoặc thắt nút tại bất kỳ mép nào nhưng trừ các loại vải có các mép cắt được làm cho khỏi sổ bằng cách khâu vắt hoặc các cách đơn giản khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Cắt theo cỡ và đã trải qua công đoạn gia công rút chỉ;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Đã ghép bằng cách khâu, may, dán dính hoặc cách khác (trừ loại hàng dệt tấm gồm hai hoặc nhiều tấm trở lên có cùng chất liệu dệt được ghép nối đuôi nhau và những tấm làm từ hai hoặc nhiều loại vật liệu dệt trở lên được ghép thành lớp, có hoặc không có đệm);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Dệt kim hoặc móc thành các hình dạng, hoặc trình bày ở dạng các chi tiết riêng biệt hoặc ở dạng một số các chi tiết gắn thành một dải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '8. Theo mục đích của các Chương từ 50 đến 60:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các Chương từ 50 đến 55 và Chương 60 và, trừ khi có yêu cầu khác, các Chương từ 56 đến 59, không áp dụng cho hàng hóa hoàn thiện đã nêu tại Chú giải 7 trên đây; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các Chương từ 50 đến 55 và Chương 60 không áp dụng cho các hàng hóa của các Chương từ 56 đến 59.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Vải dệt thoi thuộc các Chương từ 50 đến 55 kể cả các loại vải có các lớp sợi dệt song song được đan đặt lên nhau theo góc nhọn hoặc góc vuông. Những lớp này được gắn tại các giao điểm của sợi bằng các chất kết dính hoặc liên kết bằng nhiệt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '10. Sản phẩm có tính đàn hồi bằng những vật liệu dệt kết hợp với sợi cao su cũng được phân loại trong Phần này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '11. Theo mục đích của Phần này, khái niệm ""đã ngâm tẩm"" kể cả ""đã nhúng"".',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '12. Theo mục đích của Phần này, khái niệm ""polyamit"" kể cả ""aramit"".',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '13. Theo mục đích của Phần này và, cũng như một số trường hợp thuộc Danh mục này, khái niệm “sợi đàn hồi” có nghĩa là sợi filament, kể cả monofilament, bằng chất liệu dệt tổng hợp, trừ sợi dún, không bị đứt khi bị kéo dãn ra gấp ba lần độ dài ban đầu và khi kéo dãn ra gấp hai lần độ dài ban đầu trong thời gian năm phút sẽ co lại còn độ dài không lớn hơn 1,5 lần độ dài ban đầu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '14. Trừ khi có quy định khác, hàng dệt may sẵn thuộc các nhóm khác nhau phải được phân loại theo các nhóm phù hợp của từng loại ngay cả khi sắp xếp theo bộ để bán lẻ. Theo mục đích của Chú giải này, khái niệm ""hàng dệt may sẵn"" nghĩa là các hàng hóa của các nhóm từ 61.01 đến 61.14 và từ 62.01 đến 62.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '15. Theo Chú giải 1 của Phần XI, hàng dệt, may và các mặt hàng dệt khác, kết hợp các thành phần hóa học, cơ khí hoặc điện tử để tạo thêm chức năng, cho dù được kết hợp dưới dạng các thành phần tích hợp hoặc bên trong sợi hoặc vải, được phân loại theo các nhóm tương ứng trong Phần XI với điều kiện là chúng vẫn giữ được đặc tính cơ bản của hàng hóa thuộc Phần này. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Phần này cũng như những Phần khác của Danh mục, các khái niệm dưới đây có ý nghĩa là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Sợi chưa tẩy trắng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' Là loại sợi:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) có màu tự nhiên của các loại xơ gốc và chưa tẩy trắng, nhuộm (cả khối hoặc không) hoặc in; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) có màu không xác định được (""sợi mộc (grey yarn)""), được sản xuất từ nguyên liệu tái sinh.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Loại sợi này có thể được xử lý bằng cách hồ không màu hoặc nhuộm không bền màu (mất màu sau khi giặt bình thường bằng xà phòng) và, nếu là xơ nhân tạo thì có thể được xử lý cả khối với tác nhân khử bóng (ví dụ, titan đioxit). ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Sợi đã tẩy trắng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' Là loại sợi:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) đã qua quá trình tẩy trắng, được làm từ các xơ đã tẩy trắng hoặc, trừ khi có yêu cầu khác, đã được nhuộm tăng trắng (cả khối hoặc không) hoặc đã xử lý bằng hồ trắng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) gồm hỗn hợp của xơ đã tẩy trắng và chưa tẩy trắng; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) sợi xe (folded) hoặc sợi cáp được làm từ sợi đã tẩy trắng và chưa tẩy trắng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Sợi màu (đã nhuộm hoặc đã in)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Là loại sợi:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) đã nhuộm (cả khối hoặc không) trừ màu trắng hoặc màu không bền, hoặc đã in, hoặc làm từ các loại xơ đã nhuộm hoặc đã in;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) gồm hỗn hợp của các xơ đã nhuộm từ màu khác nhau hoặc hỗn hợp của xơ chưa tẩy trắng hoặc đã tẩy trắng với các xơ màu (sợi macnơ hoặc sợi hỗn hợp), hoặc được in một hoặc nhiều màu cách khoảng tạo thành các chấm đốm;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) làm từ cúi hoặc sợi thô đã in; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iv) là sợi xe (folded) hoặc sợi cáp và gồm cả sợi đã tẩy trắng hoặc chưa tẩy trắng và sợi màu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Những định nghĩa trên đây cũng áp dụng, một cách tương tự, cho sợi monofilament và dải hoặc dạng tương tự của Chương 54.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Vải dệt thoi chưa tẩy trắng',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Vải dệt thoi được làm từ sợi chưa tẩy trắng và vải đó chưa được tẩy trắng, nhuộm hoặc in. Loại vải này có thể được xử lý bằng cách hồ không màu hoặc nhuộm không bền màu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Vải dệt thoi đã tẩy trắng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Loại vải dệt thoi:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) đã được tẩy trắng hoặc, trừ khi có yêu cầu khác, nhuộm tăng trắng, hoặc xử lý bằng loại hồ tăng trắng, ở dạng mảnh;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) dệt từ sợi đã tẩy trắng; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) dệt từ sợi đã tẩy trắng và sợi chưa tẩy trắng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Vải dệt thoi đã nhuộm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Loại vải dệt thoi:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) đã được nhuộm đồng đều một màu trừ màu trắng (trừ một số trường hợp có yêu cầu khác) hoặc được xử lý bằng sự hoàn thiện màu trừ màu trắng (trừ một số trường hợp có yêu cầu khác), ở dạng mảnh; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) dệt từ sợi được nhuộm đồng đều một màu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Vải dệt thoi bằng các loại sợi màu khác nhau',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Là vải dệt thoi (trừ vải dệt thoi đã in):',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) bằng các loại sợi có màu khác nhau hoặc các loại sợi cùng màu có độ đậm nhạt (shades) khác nhau (trừ màu tự nhiên của các loại xơ hợp thành);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) bằng các loại sợi chưa tẩy trắng hoặc đã tẩy trắng và sợi màu; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) dệt bằng sợi macnơ hoặc sợi hỗn hợp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(Trong mọi trường hợp, các loại sợi dùng làm sợi biên hoặc sợi đầu tấm không được xem xét).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Vải dệt thoi đã in',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Là loại vải dệt thoi đã được in ở dạng mảnh, được dệt hoặc không dệt từ các sợi có màu khác nhau.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(Các loại sau đây cũng được xem như vải dệt thoi đã in: vải dệt thoi có các hình được tạo ra bằng cách, ví dụ, dùng bàn chải hoặc súng phun, dùng giấy chuyển màu, bằng cấy nhung hoặc bằng quá trình batik.)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quá trình kiềm bóng không ảnh hưởng đến việc phân loại của các loại sợi hoặc các loại vải kể trên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các định nghĩa tại các phần từ (d) đến (h) nêu trên cũng được áp dụng tương tự với các loại vải dệt kim hoặc móc.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Vải dệt vân điểm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là loại vải có cấu trúc trong đó mỗi sợi ngang tuần tự đan xen ở trên và ở dưới sợi dọc kế tiếp và mỗi sợi dọc tuần tự đan xen ở trên và ở dưới sợi ngang kế tiếp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. (A) Các sản phẩm thuộc các Chương 56 đến 63 làm từ hai hoặc nhiều vật liệu dệt được xem như làm từ toàn bộ một loại vật liệu dệt mà loại vật liệu dệt đó được lựa chọn theo Chú giải 2 Phần này để phân loại một sản phẩm thuộc các Chương từ 50 đến 55 hoặc thuộc nhóm 58.09 làm từ các vật liệu dệt giống nhau.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Để áp dụng quy tắc này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) trong trường hợp thích hợp, chỉ có phần quyết định việc phân loại theo Quy tắc tổng quát 3 mới được đưa ra xem xét; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) trường hợp sản phẩm dệt làm từ một lớp vải nền và lớp bề mặt có tuyết hoặc vòng thì không cần xem xét đến vải nền;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) trường hợp hàng thêu thuộc nhóm 58.10 và sản phẩm của nó, chỉ cần phân loại theo vải nền. Tuy nhiên, đồ thêu không lộ nền, và cả sản phẩm của nó, được phân loại theo chỉ thêu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 50',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tơ tằm',
    Unit: '',
  },
  {
    HsCode: '50010000',
    Description: 'Kén tằm phù hợp dùng làm tơ',
    Unit: 'kg',
  },
  {
    HsCode: '50020000',
    Description: 'Tơ tằm thô (chưa xe)',
    Unit: 'kg',
  },
  {
    HsCode: '50030000',
    Description: 'Tơ tằm phế phẩm (kể cả kén không thích hợp để quay tơ, xơ sợi phế liệu và xơ sợi tái chế)',
    Unit: 'kg',
  },
  {
    HsCode: '50040000',
    Description: 'Sợi tơ tằm (trừ sợi kéo từ phế liệu tơ tằm) chưa đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '50050000',
    Description: 'Sợi kéo từ phế liệu tơ tằm, chưa đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '50060000',
    Description: 'Sợi tơ tằm và sợi kéo từ phế liệu tơ tằm, đã đóng gói để bán lẻ; ruột con tằm',
    Unit: 'kg',
  },
  {
    HsCode: '5007',
    Description: 'Vải dệt thoi dệt từ tơ tằm hoặc từ phế liệu tơ tằm',
    Unit: '',
  },
  {
    HsCode: '500710',
    Description: '- Vải dệt thoi từ tơ vụn:',
    Unit: '',
  },
  {
    HsCode: '50071020',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '50071030',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '50071090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '500720',
    Description:
      '- Các loại vải dệt thoi khác, có hàm lượng tơ hoặc phế liệu tơ tằm ngoại trừ tơ vụn chiếm 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '50072020',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '50072030',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '50072090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '500790',
    Description: '- Vải dệt khác: ',
    Unit: '',
  },
  {
    HsCode: '50079020',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '50079030',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '50079090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 51',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lông cừu, lông động vật loại mịn hoặc loại thô; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'sợi từ lông đuôi hoặc bờm ngựa và vải dệt thoi từ các nguyên liệu trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong toàn bộ Danh mục:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) ""Lông cừu"" là lông xơ tự nhiên mọc từ con cừu non hoặc con cừu trưởng thành;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) ""Lông động vật loại mịn"" là lông của dê alpaca, lông lạc đà không bướu llama, lông lạc đà không bướu vicuna, lông lạc đà (kể cả lông lạc đà một bướu), lông bò Tây Tạng, lông dê Angora, lông dê Tibetan, lông dê Ca-sơ-mia hoặc lông của các loại dê tương tự (trừ loại dê thông thường), lông thỏ (kể cả lông thỏ Angora), lông thỏ rừng, lông hải ly, lông chuột hải ly hoặc lông chuột nước;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) ""Lông động vật loại thô"" là lông của các loại động vật không kể ở trên, trừ lông cứng và lông dùng làm bàn chải (nhóm 05.02) và lông đuôi hoặc bờm ngựa (nhóm 05.11).',
    Unit: '',
  },
  {
    HsCode: '5101',
    Description: 'Lông cừu, chưa chải thô hoặc chải kỹ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Nhờn, kể cả lông cừu đã rửa sạch:',
    Unit: '',
  },
  {
    HsCode: '51011100',
    Description: '- - Lông cừu đã xén',
    Unit: 'kg',
  },
  {
    HsCode: '51011900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Đã tẩy nhờn, chưa được carbon hóa:',
    Unit: '',
  },
  {
    HsCode: '51012100',
    Description: '- - Lông cừu đã xén',
    Unit: 'kg',
  },
  {
    HsCode: '51012900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '51013000',
    Description: '- Đã được carbon hóa',
    Unit: 'kg',
  },
  {
    HsCode: '5102',
    Description: 'Lông động vật loại thô hoặc mịn, chưa chải thô hoặc chải kỹ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '51021100',
    Description: '- - Của dê Ca-sơ-mia (len ca-sơ-mia)',
    Unit: 'kg',
  },
  {
    HsCode: '51021900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '51022000',
    Description: '- Lông động vật loại thô',
    Unit: 'kg',
  },
  {
    HsCode: '5103',
    Description:
      'Phế liệu lông cừu hoặc lông động vật loại mịn hoặc loại thô, kể cả phế liệu sợi nhưng trừ lông tái chế',
    Unit: '',
  },
  {
    HsCode: '51031000',
    Description: '- Xơ vụn từ lông cừu hoặc từ lông động vật loại mịn',
    Unit: 'kg',
  },
  {
    HsCode: '51032000',
    Description: '- Phế liệu khác từ lông cừu hoặc từ lông động vật loại mịn',
    Unit: 'kg',
  },
  {
    HsCode: '51033000',
    Description: '- Phế liệu từ lông động vật loại thô',
    Unit: 'kg',
  },
  {
    HsCode: '51040000',
    Description: 'Lông cừu hoặc lông động vật loại mịn hoặc thô tái chế',
    Unit: 'kg',
  },
  {
    HsCode: '5105',
    Description:
      'Lông cừu và lông động vật loại mịn hoặc loại thô, đã chải thô hoặc chải kỹ (kể cả lông cừu chải kỹ dạng từng đoạn)',
    Unit: '',
  },
  {
    HsCode: '51051000',
    Description: '- Lông cừu chải thô',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Cúi lông cừu chải kỹ (wool tops) và lông cừu chải kỹ khác:',
    Unit: '',
  },
  {
    HsCode: '51052100',
    Description: '- - Lông cừu chải kỹ dạng từng đoạn',
    Unit: 'kg',
  },
  {
    HsCode: '51052900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Lông động vật loại mịn, đã chải thô hoặc chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '51053100',
    Description: '- - Của dê Ca-sơ-mia (len ca-sơ-mia)',
    Unit: 'kg',
  },
  {
    HsCode: '51053900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '51054000',
    Description: '- Lông động vật loại thô, đã chải thô hoặc chải kỹ',
    Unit: 'kg',
  },
  {
    HsCode: '5106',
    Description: 'Sợi len lông cừu chải thô, chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '51061000',
    Description: '- Có hàm lượng lông cừu chiếm từ 85% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '51062000',
    Description: '- Có hàm lượng lông cừu chiếm dưới 85% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '5107',
    Description: 'Sợi len lông cừu chải kỹ, chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '51071000',
    Description: '- Có hàm lượng lông cừu chiếm từ 85% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '51072000',
    Description: '- Có hàm lượng lông cừu chiếm dưới 85% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '5108',
    Description: 'Sợi lông động vật loại mịn (chải thô hoặc chải kỹ), chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '51081000',
    Description: '- Chải thô',
    Unit: 'kg',
  },
  {
    HsCode: '51082000',
    Description: '- Chải kỹ',
    Unit: 'kg',
  },
  {
    HsCode: '5109',
    Description: 'Sợi len lông cừu hoặc lông động vật loại mịn, đã đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '51091000',
    Description: '- Có hàm lượng lông cừu hoặc lông động vật loại mịn chiếm từ 85% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '51099000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '51100000',
    Description:
      'Sợi làm từ lông động vật loại thô hoặc từ lông đuôi hoặc bờm ngựa (kể cả sợi quấn bọc từ lông đuôi hoặc bờm ngựa), đã hoặc chưa đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '5111',
    Description: 'Vải dệt thoi từ sợi len lông cừu chải thô hoặc từ sợi lông động vật loại mịn chải thô',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng lông cừu hoặc lông động vật loại mịn chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '51111100',
    Description: '- - Định lượng không quá 300 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51111900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51112000',
    Description: '- Loại khác, pha chủ yếu hoặc pha duy nhất với sợi filament nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51113000',
    Description: '- Loại khác, pha chủ yếu hoặc pha duy nhất với xơ staple nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51119000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5112',
    Description: 'Vải dệt thoi từ sợi len lông cừu chải kỹ hoặc từ sợi lông động vật loại mịn chải kỹ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng lông cừu hoặc lông động vật loại mịn chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '511211',
    Description: '- - Định lượng không quá 200 g/m2:',
    Unit: '',
  },
  {
    HsCode: '51121110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51121190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '511219',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '51121910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51121990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51122000',
    Description: '- Loại khác, pha chủ yếu hoặc pha duy nhất với sợi filament nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51123000',
    Description: '- Loại khác, pha chủ yếu hoặc pha duy nhất với xơ staple nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51129000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '51130000',
    Description: 'Vải dệt thoi từ sợi lông động vật loại thô hoặc sợi lông đuôi hoặc bờm ngựa',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 52',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Bông',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của các phân nhóm 5209.42 và 5211.42, khái niệm ""denim"" là vải dệt từ các sợi có các màu khác nhau, kiểu dệt là vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vân chéo gãy, mặt phải của vải có hiệu ứng dọc, các sợi dọc được nhuộm cùng một màu và sợi ngang là sợi không tẩy trắng, đã tẩy trắng, nhuộm màu xám hoặc nhuộm màu nhạt hơn so với màu sợi dọc.',
    Unit: '',
  },
  {
    HsCode: '52010000',
    Description: 'Xơ bông, chưa chải thô hoặc chưa chải kỹ',
    Unit: 'kg',
  },
  {
    HsCode: '5202',
    Description: 'Phế liệu bông (kể cả phế liệu sợi và bông tái chế)',
    Unit: '',
  },
  {
    HsCode: '52021000',
    Description: '- Phế liệu sợi (kể cả phế liệu chỉ)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '52029100',
    Description: '- - Bông tái chế',
    Unit: 'kg',
  },
  {
    HsCode: '52029900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '52030000',
    Description: 'Xơ bông, chải thô hoặc chải kỹ',
    Unit: 'kg',
  },
  {
    HsCode: '5204',
    Description: 'Chỉ khâu làm từ bông, đã hoặc chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa đóng gói để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '520411',
    Description: '- - Có hàm lượng bông chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '52041110',
    Description: '- - - Chưa tẩy trắng',
    Unit: 'kg',
  },
  {
    HsCode: '52041190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '52041900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '52042000',
    Description: '- Đã đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '5205',
    Description:
      'Sợi bông (trừ chỉ khâu), có hàm lượng bông chiếm từ 85% trở lên tính theo khối lượng, chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Sợi đơn, làm từ xơ không chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52051100',
    Description: '- - Sợi có độ mảnh từ 714,29 decitex trở lên (chi số mét không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52051200',
    Description: '- - Sợi có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52051300',
    Description: '- - Sợi có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52051400',
    Description: '- - Sợi có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52051500',
    Description: '- - Sợi có độ mảnh dưới 125 decitex (chi số mét trên 80)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi đơn, làm từ xơ chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52052100',
    Description: '- - Sợi có độ mảnh từ 714,29 decitex trở lên (chi số mét không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52052200',
    Description: '- - Sợi có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52052300',
    Description: '- - Sợi có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52052400',
    Description: '- - Sợi có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52052600',
    Description: '- - Sợi có độ mảnh từ 106,38 decitex đến dưới 125 decitex (chi số mét trên 80 đến 94)',
    Unit: 'kg',
  },
  {
    HsCode: '52052700',
    Description: '- - Sợi có độ mảnh từ 83,33 decitex đến dưới 106,38 decitex (chi số mét trên 94 đến 120)',
    Unit: 'kg',
  },
  {
    HsCode: '52052800',
    Description: '- - Sợi có độ mảnh dưới 83,33 decitex (chi số mét trên 120)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi xe (folded) hoặc sợi cáp, làm từ xơ không chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52053100',
    Description: '- - Từ mỗi sợi đơn có độ mảnh từ 714,29 decitex trở lên (chi số mét sợi đơn không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52053200',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét sợi đơn trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52053300',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét sợi đơn trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52053400',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét sợi đơn trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52053500',
    Description: '- - Từ mỗi sợi đơn có độ mảnh dưới 125 decitex (chi số mét sợi đơn trên 80)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi xe (folded) hoặc sợi cáp, từ xơ chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52054100',
    Description: '- - Từ mỗi sợi đơn có độ mảnh từ 714,29 decitex trở lên (chi số mét sợi đơn không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52054200',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét sợi đơn trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52054300',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét sợi đơn trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52054400',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét sợi đơn trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52054600',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 106,38 decitex đến dưới 125 decitex (chi số mét sợi đơn trên 80 đến 94)',
    Unit: 'kg',
  },
  {
    HsCode: '52054700',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 83,33 decitex đến dưới 106,38 decitex (chi số mét sợi đơn trên 94 đến 120)',
    Unit: 'kg',
  },
  {
    HsCode: '52054800',
    Description: '- - Từ mỗi sợi đơn có độ mảnh dưới 83,33 decitex (chi số mét sợi đơn trên 120)',
    Unit: 'kg',
  },
  {
    HsCode: '5206',
    Description:
      'Sợi bông (trừ chỉ khâu), có hàm lượng bông chiếm dưới 85% tính theo khối lượng, chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Sợi đơn, làm từ xơ không chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52061100',
    Description: '- - Sợi có độ mảnh từ 714,29 decitex trở lên (chi số mét không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52061200',
    Description: '- - Sợi có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52061300',
    Description: '- - Sợi có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52061400',
    Description: '- - Sợi có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52061500',
    Description: '- - Sợi có độ mảnh dưới 125 decitex (chi số mét trên 80)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi đơn, làm từ xơ chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52062100',
    Description: '- - Sợi có độ mảnh từ 714,29 decitex trở lên (chi số mét không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52062200',
    Description: '- - Sợi có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52062300',
    Description: '- - Sợi có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52062400',
    Description: '- - Sợi có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52062500',
    Description: '- - Sợi có độ mảnh dưới 125 decitex (chi số mét trên 80)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi xe (folded) hoặc sợi cáp, làm từ xơ không chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52063100',
    Description: '- - Từ mỗi sợi đơn có độ mảnh từ 714,29 decitex trở lên (chi số mét sợi đơn không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52063200',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét sợi đơn trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52063300',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét sợi đơn trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52063400',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét sợi đơn trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52063500',
    Description: '- - Từ mỗi sợi đơn có độ mảnh dưới 125 decitex (chi số mét sợi đơn trên 80)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi xe (folded) hoặc sợi cáp, từ xơ chải kỹ:',
    Unit: '',
  },
  {
    HsCode: '52064100',
    Description: '- - Từ mỗi sợi đơn có độ mảnh từ 714,29 decitex trở lên (chi số mét sợi đơn không quá 14)',
    Unit: 'kg',
  },
  {
    HsCode: '52064200',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 232,56 decitex đến dưới 714,29 decitex (chi số mét sợi đơn trên 14 đến 43)',
    Unit: 'kg',
  },
  {
    HsCode: '52064300',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 192,31 decitex đến dưới 232,56 decitex (chi số mét sợi đơn trên 43 đến 52)',
    Unit: 'kg',
  },
  {
    HsCode: '52064400',
    Description:
      '- - Từ mỗi sợi đơn có độ mảnh từ 125 decitex đến dưới 192,31 decitex (chi số mét sợi đơn trên 52 đến 80)',
    Unit: 'kg',
  },
  {
    HsCode: '52064500',
    Description: '- - Từ mỗi sợi đơn có độ mảnh dưới 125 decitex (chi số mét sợi đơn trên 80)',
    Unit: 'kg',
  },
  {
    HsCode: '5207',
    Description: 'Sợi bông (trừ chỉ khâu) đã đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '52071000',
    Description: '- Có hàm lượng bông chiếm từ 85% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '52079000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5208',
    Description:
      'Vải dệt thoi từ bông, có hàm lượng bông chiếm từ 85% trở lên tính theo khối lượng, định lượng không quá 200 g/m2',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '52081100',
    Description: '- - Vải vân điểm, định lượng không quá 100 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52081200',
    Description: '- - Vải vân điểm, định lượng trên 100 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52081300',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52081900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '52082100',
    Description: '- - Vải vân điểm, định lượng không quá 100 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52082200',
    Description: '- - Vải vân điểm, định lượng trên 100 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52082300',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52082900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '520831',
    Description: '- - Vải vân điểm, định lượng không quá 100 g/m2:',
    Unit: '',
  },
  {
    HsCode: '52083110',
    Description: '- - - Vải voan (Voile)(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52083190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52083200',
    Description: '- - Vải vân điểm, định lượng trên 100 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52083300',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52083900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ các sợi có các màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '520841',
    Description: '- - Vải vân điểm, định lượng không quá 100 g/m2:',
    Unit: '',
  },
  {
    HsCode: '52084110',
    Description: '- - - Vải Ikat(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52084190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '520842',
    Description: '- - Vải vân điểm, định lượng trên 100 g/m2:',
    Unit: '',
  },
  {
    HsCode: '52084210',
    Description: '- - - Vải Ikat(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52084290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52084300',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52084900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã in:',
    Unit: '',
  },
  {
    HsCode: '520851',
    Description: '- - Vải vân điểm, định lượng không quá 100 g/m2:',
    Unit: '',
  },
  {
    HsCode: '52085110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52085190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '520852',
    Description: '- - Vải vân điểm, định lượng trên 100 g/m2:',
    Unit: '',
  },
  {
    HsCode: '52085210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52085290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '520859',
    Description: '- - Vải dệt khác: ',
    Unit: '',
  },
  {
    HsCode: '52085910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52085920',
    Description: '- - - Loại khác, vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52085990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5209',
    Description:
      'Vải dệt thoi từ bông, có hàm lượng bông chiếm từ 85% trở lên tính theo khối lượng, định lượng trên 200 g/m2',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '520911',
    Description: '- - Vải vân điểm:',
    Unit: '',
  },
  {
    HsCode: '52091110',
    Description: '- - - Vải duck và vải canvas(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52091190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52091200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52091900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '52092100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52092200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52092900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '52093100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52093200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52093900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ các sợi có các màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '52094100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52094200',
    Description: '- - Vải denim',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52094300',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi khác, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52094900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã in:',
    Unit: '',
  },
  {
    HsCode: '520951',
    Description: '- - Vải vân điểm:',
    Unit: '',
  },
  {
    HsCode: '52095110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52095190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '520952',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân:',
    Unit: '',
  },
  {
    HsCode: '52095210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52095290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '520959',
    Description: '- - Vải dệt khác: ',
    Unit: '',
  },
  {
    HsCode: '52095910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52095990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5210',
    Description:
      'Vải dệt thoi từ bông, có hàm lượng bông chiếm dưới 85% tính theo khối lượng, pha chủ yếu hoặc pha duy nhất với xơ sợi nhân tạo, có định lượng không quá 200 g/m2',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '52101100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52101900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '52102100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52102900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '52103100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52103200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52103900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ các sợi có các màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '521041',
    Description: '- - Vải vân điểm:',
    Unit: '',
  },
  {
    HsCode: '52104110',
    Description: '- - - Vải Ikat (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52104190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52104900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã in:',
    Unit: '',
  },
  {
    HsCode: '521051',
    Description: '- - Vải vân điểm:',
    Unit: '',
  },
  {
    HsCode: '52105110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52105190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '521059',
    Description: '- - Vải dệt khác: ',
    Unit: '',
  },
  {
    HsCode: '52105910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52105990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5211',
    Description:
      'Vải dệt thoi từ bông, có hàm lượng bông chiếm dưới 85% tính theo khối lượng, pha chủ yếu hoặc pha duy nhất với xơ sợi nhân tạo, có định lượng trên 200 g/m2',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '52111100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52111200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52111900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52112000',
    Description: '- Đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '52113100',
    Description: '- - Vải vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52113200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52113900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ các sợi có các màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '521141',
    Description: '- - Vải vân điểm:',
    Unit: '',
  },
  {
    HsCode: '52114110',
    Description: '- - - Vải Ikat(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52114190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52114200',
    Description: '- - Vải denim',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52114300',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi khác, kể cả vải vân chéo dấu nhân',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52114900',
    Description: '- - Vải dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã in:',
    Unit: '',
  },
  {
    HsCode: '521151',
    Description: '- - Vải vân điểm:',
    Unit: '',
  },
  {
    HsCode: '52115110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52115190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '521152',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo dấu nhân:',
    Unit: '',
  },
  {
    HsCode: '52115210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52115290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '521159',
    Description: '- - Vải dệt khác: ',
    Unit: '',
  },
  {
    HsCode: '52115910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52115990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5212',
    Description: 'Vải dệt thoi khác từ bông',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Định lượng không quá 200 g/m2:',
    Unit: '',
  },
  {
    HsCode: '52121100',
    Description: '- - Chưa tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52121200',
    Description: '- - Đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52121300',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52121400',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '521215',
    Description: '- - Đã in:',
    Unit: '',
  },
  {
    HsCode: '52121510',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52121590',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Định lượng trên 200 g/m2:',
    Unit: '',
  },
  {
    HsCode: '52122100',
    Description: '- - Chưa tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52122200',
    Description: '- - Đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52122300',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52122400',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '521225',
    Description: '- - Đã in:',
    Unit: '',
  },
  {
    HsCode: '52122510',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '52122590',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 53',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Xơ dệt gốc thực vật khác; sợi giấy và vải dệt thoi từ sợi giấy',
    Unit: '',
  },
  {
    HsCode: '5301',
    Description:
      'Lanh, dạng nguyên liệu thô hoặc đã chế biến nhưng chưa kéo thành sợi; tô (tow) lanh và phế liệu lanh (kể cả phế liệu sợi và sợi tái chế)',
    Unit: '',
  },
  {
    HsCode: '53011000',
    Description: '- Lanh, dạng nguyên liệu thô hoặc đã ngâm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Lanh, đã tách lõi, đã đập, đã chải hoặc gia công bằng cách khác, nhưng chưa kéo thành sợi:',
    Unit: '',
  },
  {
    HsCode: '53012100',
    Description: '- - Đã tách lõi hoặc đã đập',
    Unit: 'kg',
  },
  {
    HsCode: '53012900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '53013000',
    Description: '- Tô (tow) lanh hoặc phế liệu lanh',
    Unit: 'kg',
  },
  {
    HsCode: '5302',
    Description:
      'Gai dầu (Cannabis sativa L.), dạng nguyên liệu thô hoặc đã chế biến nhưng chưa kéo thành sợi; tô (tow) và phế liệu gai dầu (kể cả phế liệu sợi và sợi tái chế)',
    Unit: '',
  },
  {
    HsCode: '53021000',
    Description: '- Gai dầu, dạng nguyên liệu thô hoặc đã ngâm',
    Unit: 'kg',
  },
  {
    HsCode: '53029000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5303',
    Description:
      'Đay và các loại xơ libe dệt khác (trừ lanh, gai dầu và gai ramie), dạng nguyên liệu thô hoặc đã chế biến nhưng chưa kéo thành sợi; tô (tow) và phế liệu của các loại xơ này (kể cả phế liệu sợi và sợi tái chế)',
    Unit: '',
  },
  {
    HsCode: '53031000',
    Description: '- Đay và các loại xơ libe dệt khác, dạng nguyên liệu thô hoặc đã ngâm',
    Unit: 'kg',
  },
  {
    HsCode: '53039000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5305',
    Description:
      'Xơ dừa, xơ chuối abaca (Manila hemp hoặc Musa textilis Nee), xơ gai ramie và xơ dệt gốc thực vật khác, chưa được ghi hoặc chi tiết ở nơi khác hoặc kể cả, thô hoặc đã chế biến nhưng chưa kéo thành sợi; tô (tow), xơ vụn và phế liệu của các loại xơ này (kể cả phế liệu sợi và sợi tái chế)',
    Unit: '',
  },
  {
    HsCode: '53050010',
    Description:
      '- Xơ xidan (sisal) và xơ dệt khác của các cây thuộc chi cây thùa (Agave); tô (tow) và phế liệu của các loại xơ này (kể cả phế liệu sợi và sợi tái chế)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Xơ dừa và xơ chuối abaca:',
    Unit: '',
  },
  {
    HsCode: '53050021',
    Description: '- - Xơ dừa, loại thô',
    Unit: 'kg',
  },
  {
    HsCode: '53050022',
    Description: '- - Xơ dừa khác',
    Unit: 'kg',
  },
  {
    HsCode: '53050023',
    Description: '- - Xơ chuối abaca',
    Unit: 'kg',
  },
  {
    HsCode: '53050090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5306',
    Description: 'Sợi lanh',
    Unit: '',
  },
  {
    HsCode: '53061000',
    Description: '- Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '53062000',
    Description: '- Sợi xe (folded) hoặc sợi cáp',
    Unit: 'kg',
  },
  {
    HsCode: '5307',
    Description: 'Sợi đay hoặc sợi từ các loại xơ libe dệt khác thuộc nhóm 53.03',
    Unit: '',
  },
  {
    HsCode: '53071000',
    Description: '- Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '53072000',
    Description: '- Sợi xe (folded) hoặc sợi cáp',
    Unit: 'kg',
  },
  {
    HsCode: '5308',
    Description: 'Sợi từ các loại xơ dệt gốc thực vật khác; sợi giấy',
    Unit: '',
  },
  {
    HsCode: '53081000',
    Description: '- Sợi dừa',
    Unit: 'kg',
  },
  {
    HsCode: '53082000',
    Description: '- Sợi gai dầu',
    Unit: 'kg',
  },
  {
    HsCode: '530890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '53089010',
    Description: '- - Sợi giấy',
    Unit: 'kg',
  },
  {
    HsCode: '53089090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5309',
    Description: 'Vải dệt thoi từ sợi lanh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng lanh chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '53091100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '53091900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng lanh chiếm dưới 85% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '53092100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '53092900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5310',
    Description: 'Vải dệt thoi từ sợi đay hoặc từ các loại xơ libe dệt khác thuộc nhóm 53.03',
    Unit: '',
  },
  {
    HsCode: '531010',
    Description: '- Chưa tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '53101010',
    Description: '- - Vải dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '53101090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '53109000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5311',
    Description: 'Vải dệt thoi từ các loại sợi dệt gốc thực vật khác; vải dệt thoi từ sợi giấy',
    Unit: '',
  },
  {
    HsCode: '53110010',
    Description: '- Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '53110020',
    Description: '- Vải bố (burlap) từ xơ chuối abaca (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '53110090',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 54',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sợi filament nhân tạo; dải và các dạng tương tự từ nguyên liệu dệt nhân tạo',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong toàn bộ Danh mục, thuật ngữ “sợi nhân tạo” có nghĩa là những loại sợi staple và sợi filament bằng polyme hữu cơ được sản xuất từ một trong hai quá trình sản xuất sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Bằng quá trình polyme hóa các monome hữu cơ để sản xuất polyme như polyamit, polyeste, polyolefin hoặc polyurethan, hoặc quá trình biến đổi hóa học để sản xuất polyme (ví dụ, poly (vinyl alcohol) được điều chế bởi quá trình thủy phân poly (axetat vinyl)); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Bằng quá trình xử lý hóa học hoặc phân hủy các polyme hữu cơ tự nhiên (như, xenlulo) để sản xuất polyme như cupram rayon (cupro) hoặc viscose rayon, hoặc quá trình biến đổi hóa học các polyme hữu cơ tự nhiên (ví dụ, xenlulo, casein và các protein khác, hoặc axit alginic), để sản xuất polyme như axetat xenlulo hoặc alginat.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các thuật ngữ “tổng hợp” và “tái tạo”, liên quan đến các loại sợi, có nghĩa: tổng hợp: các loại xơ, sợi như định nghĩa ở (a); tái tạo: các loại xơ, sợi như định nghĩa ở (b). Dải và các dạng tương tự thuộc nhóm 54.04 hoặc 54.05 không được coi là xơ nhân tạo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các khái niệm “nhân tạo (man-made)”, “tổng hợp (synthetic)” và “tái tạo (artificial)” sẽ có cùng nghĩa như nhau khi sử dụng trong lĩnh vực “nguyên liệu dệt”.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Các nhóm 54.02 và 54.03 không áp dụng cho sợi tô (tow) filament tổng hợp hoặc tái tạo của Chương 55.',
    Unit: '',
  },
  {
    HsCode: '5401',
    Description: 'Chỉ khâu làm từ sợi filament nhân tạo, đã hoặc chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '540110',
    Description: '- Từ sợi filament tổng hợp: ',
    Unit: '',
  },
  {
    HsCode: '54011010',
    Description: '- - Đã đóng gói để bán lẻ',
    Unit: 'kg/m',
  },
  {
    HsCode: '54011090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '540120',
    Description: '- Từ sợi filament tái tạo: ',
    Unit: '',
  },
  {
    HsCode: '54012010',
    Description: '- - Đã đóng gói để bán lẻ',
    Unit: 'kg/m',
  },
  {
    HsCode: '54012090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '5402',
    Description:
      'Sợi filament tổng hợp (trừ chỉ khâu), chưa đóng gói để bán lẻ, kể cả sợi monofilament tổng hợp có độ mảnh dưới 67 decitex',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Sợi có độ bền cao làm từ ni lông hoặc các polyamit khác, đã hoặc chưa làm dún:',
    Unit: '',
  },
  {
    HsCode: '54021100',
    Description: '- - Từ các aramit',
    Unit: 'kg',
  },
  {
    HsCode: '54021900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '54022000',
    Description: '- Sợi có độ bền cao làm từ polyeste, đã hoặc chưa làm dún',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi dún:',
    Unit: '',
  },
  {
    HsCode: '54023100',
    Description: '- - Từ ni lông hoặc các polyamit khác, độ mảnh mỗi sợi đơn không quá 50 tex',
    Unit: 'kg',
  },
  {
    HsCode: '54023200',
    Description: '- - Từ ni lông hoặc các polyamit khác, độ mảnh mỗi sợi đơn trên 50 tex',
    Unit: 'kg',
  },
  {
    HsCode: '540233',
    Description: '- - Từ các polyeste:',
    Unit: '',
  },
  {
    HsCode: '54023310',
    Description: '- - - Loại có màu trừ màu trắng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '54023390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '54023400',
    Description: '- - Từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '54023900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, đơn, không xoắn hoặc xoắn không quá 50 vòng xoắn trên mét:',
    Unit: '',
  },
  {
    HsCode: '540244',
    Description: '- - Từ nhựa đàn hồi:',
    Unit: '',
  },
  {
    HsCode: '54024410',
    Description: '- - - Từ các polyeste',
    Unit: 'kg',
  },
  {
    HsCode: '54024420',
    Description: '- - - Từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '54024490',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '54024500',
    Description: '- - Loại khác, từ ni lông hoặc từ các polyamit khác',
    Unit: 'kg',
  },
  {
    HsCode: '540246',
    Description: '- - Loại khác, từ các polyeste, được định hướng một phần:',
    Unit: '',
  },
  {
    HsCode: '54024610',
    Description: '- - - Loại có màu trừ màu trắng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '54024690',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '540247',
    Description: '- - Loại khác, từ các polyeste :',
    Unit: '',
  },
  {
    HsCode: '54024710',
    Description: '- - - Loại có màu trừ màu trắng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '54024790',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '54024800',
    Description: '- - Loại khác, từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '54024900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, đơn, xoắn trên 50 vòng xoắn trên mét:',
    Unit: '',
  },
  {
    HsCode: '54025100',
    Description: '- - Từ ni lông hoặc các polyamit khác',
    Unit: 'kg',
  },
  {
    HsCode: '54025200',
    Description: '- - Từ các polyeste',
    Unit: 'kg',
  },
  {
    HsCode: '54025300',
    Description: '- - Từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '54025900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, sợi xe (folded) hoặc sợi cáp:',
    Unit: '',
  },
  {
    HsCode: '54026100',
    Description: '- - Từ ni lông hoặc các polyamit khác',
    Unit: 'kg',
  },
  {
    HsCode: '54026200',
    Description: '- - Từ các polyeste',
    Unit: 'kg',
  },
  {
    HsCode: '54026300',
    Description: '- - Từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '54026900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5403',
    Description:
      'Sợi filament tái tạo (trừ chỉ khâu), chưa đóng gói để bán lẻ, kể cả sợi monofilament tái tạo có độ mảnh dưới 67 decitex',
    Unit: '',
  },
  {
    HsCode: '54031000',
    Description: '- Sợi có độ bền cao từ viscose rayon',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, đơn:',
    Unit: '',
  },
  {
    HsCode: '540331',
    Description: '- - Từ viscose rayon, không xoắn hoặc xoắn không quá 120 vòng xoắn trên mét:',
    Unit: '',
  },
  {
    HsCode: '54033110',
    Description: '- - - Sợi dún',
    Unit: 'kg',
  },
  {
    HsCode: '54033190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '540332',
    Description: '- - Từ viscose rayon, xoắn trên 120 vòng xoắn trên mét:',
    Unit: '',
  },
  {
    HsCode: '54033210',
    Description: '- - - Sợi dún',
    Unit: 'kg',
  },
  {
    HsCode: '54033290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '540333',
    Description: '- - Từ xenlulo axetat:',
    Unit: '',
  },
  {
    HsCode: '54033310',
    Description: '- - - Sợi dún',
    Unit: 'kg',
  },
  {
    HsCode: '54033390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '540339',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '54033910',
    Description: '- - - Sợi dún',
    Unit: 'kg',
  },
  {
    HsCode: '54033990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, sợi xe (folded) hoặc sợi cáp:',
    Unit: '',
  },
  {
    HsCode: '540341',
    Description: '- - Từ viscose rayon:',
    Unit: '',
  },
  {
    HsCode: '54034110',
    Description: '- - - Sợi dún',
    Unit: 'kg',
  },
  {
    HsCode: '54034190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '54034200',
    Description: '- - Từ xenlulo axetat',
    Unit: 'kg',
  },
  {
    HsCode: '54034900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5404',
    Description:
      'Sợi monofilament tổng hợp có độ mảnh từ 67 decitex trở lên và kích thước mặt cắt ngang không quá 1 mm; dải và dạng tương tự (ví dụ, sợi rơm nhân tạo) từ vật liệu dệt tổng hợp có chiều rộng bề mặt không quá 5 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Sợi monofilament:',
    Unit: '',
  },
  {
    HsCode: '54041100',
    Description: '- - Từ nhựa đàn hồi',
    Unit: 'kg',
  },
  {
    HsCode: '54041200',
    Description: '- - Loại khác, từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '54041900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '54049000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '54050000',
    Description:
      'Sợi monofilament tái tạo có độ mảnh từ 67 decitex trở lên và kích thước mặt cắt ngang không quá 1 mm; dải và dạng tương tự (ví dụ, sợi rơm nhân tạo) từ vật liệu dệt tái tạo có chiều rộng bề mặt không quá 5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '54060000',
    Description: 'Sợi filament nhân tạo (trừ chỉ khâu), đã đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '5407',
    Description:
      'Vải dệt thoi bằng sợi filament tổng hợp, kể cả vải dệt thoi thu được từ các nguyên liệu thuộc nhóm 54.04',
    Unit: '',
  },
  {
    HsCode: '540710',
    Description: '- Vải dệt thoi từ sợi có độ bền cao bằng ni lông hoặc các polyamit hoặc các polyeste khác:',
    Unit: '',
  },
  {
    HsCode: '54071020',
    Description: '- - Vải làm lốp xe; vải làm băng tải (SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '54071091',
    Description: '- - - Chưa tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54071099',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54072000',
    Description: '- Vải dệt thoi từ dải hoặc dạng tương tự',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54073000',
    Description: '- Vải dệt thoi đã được nêu ở Chú giải 9 Phần XI',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Vải dệt thoi khác, có hàm lượng sợi filament bằng ni lông hoặc các polyamit khác chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '540741',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '54074110',
    Description:
      '- - - Vải lưới ni lông dệt thoi từ sợi filament không xoắn thích hợp để sử dụng như vật liệu gia cố cho vải sơn dầu',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54074190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54074200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54074300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54074400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Vải dệt thoi khác, có hàm lượng sợi filament polyeste dún chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '54075100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54075200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54075300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54075400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Vải dệt thoi khác, có hàm lượng sợi filament polyeste chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '540761',
    Description: '- - Có hàm lượng sợi filament polyeste không dún chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '54076110',
    Description: '- - - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54076190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '540769',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '54076910',
    Description: '- - - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54076990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Vải dệt thoi khác, có hàm lượng sợi filament tổng hợp chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '54077100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54077200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54077300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54077400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Vải dệt thoi khác, có hàm lượng sợi filament tổng hợp chiếm dưới 85% tính theo khối lượng, được pha chủ yếu hoặc pha duy nhất với bông:',
    Unit: '',
  },
  {
    HsCode: '54078100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54078200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54078300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54078400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Vải dệt thoi khác:',
    Unit: '',
  },
  {
    HsCode: '54079100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54079200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54079300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54079400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5408',
    Description:
      'Vải dệt thoi bằng sợi filament tái tạo, kể cả vải dệt thoi thu được từ các nguyên liệu thuộc nhóm 54.05',
    Unit: '',
  },
  {
    HsCode: '540810',
    Description: '- Vải dệt thoi bằng sợi có độ bền cao viscose rayon:',
    Unit: '',
  },
  {
    HsCode: '54081010',
    Description: '- - Chưa tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54081090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Vải dệt thoi khác, có hàm lượng sợi filament tái tạo hoặc dải hoặc dạng tương tự chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '54082100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54082200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54082300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54082400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Vải dệt thoi khác:',
    Unit: '',
  },
  {
    HsCode: '54083100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54083200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54083300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '54083400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 55',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Xơ sợi staple nhân tạo',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Các nhóm 55.01 và 55.02 chỉ áp dụng với tô filament nhân tạo, bao gồm các filament song song có cùng chiều dài tương đương chiều dài của tô (tow), thỏa mãn các chỉ tiêu kỹ thuật dưới đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Chiều dài của tô (tow) trên 2 m;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Xoắn dưới 5 vòng trên mét;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Độ mảnh mỗi filament dưới 67 decitex; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Nếu là tô (tow) filament tổng hợp: tô (tow) phải được kéo duỗi nhưng không thể kéo dài hơn 100% chiều dài của nó;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Tổng độ mảnh của tô (tow) trên 20.000 decitex.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tô (tow) có chiều dài không quá 2 m thì được xếp vào nhóm 55.03 hoặc 55.04.',
    Unit: '',
  },
  {
    HsCode: '5501',
    Description: 'Tô (tow) filament tổng hợp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ ni lông hoặc từ các polyamit khác:',
    Unit: '',
  },
  {
    HsCode: '55011100',
    Description: '- - Từ các aramit',
    Unit: 'kg',
  },
  {
    HsCode: '55011900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '55012000',
    Description: '- Từ các polyeste',
    Unit: 'kg',
  },
  {
    HsCode: '55013000',
    Description: '- Từ acrylic hoặc modacrylic',
    Unit: 'kg',
  },
  {
    HsCode: '55014000',
    Description: '- Từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '55019000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5502',
    Description: 'Tô (tow) filament tái tạo',
    Unit: '',
  },
  {
    HsCode: '55021000',
    Description: '- Từ xenlulo axetat',
    Unit: 'kg',
  },
  {
    HsCode: '55029000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5503',
    Description: 'Xơ staple tổng hợp, chưa chải thô, chưa chải kỹ hoặc chưa gia công cách khác để kéo sợi',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ ni lông hoặc từ các polyamit khác:',
    Unit: '',
  },
  {
    HsCode: '55031100',
    Description: '- - Từ các aramit',
    Unit: 'kg',
  },
  {
    HsCode: '55031900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '550320',
    Description: '- Từ các polyeste:',
    Unit: '',
  },
  {
    HsCode: '55032010',
    Description: '- - Loại có màu trừ màu trắng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '55032090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '55033000',
    Description: '- Từ acrylic hoặc modacrylic',
    Unit: 'kg',
  },
  {
    HsCode: '55034000',
    Description: '- Từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '550390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '55039010',
    Description: '- - Từ polyvinyl alcohol',
    Unit: 'kg',
  },
  {
    HsCode: '55039090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5504',
    Description: 'Xơ staple tái tạo, chưa chải thô, chưa chải kỹ hoặc chưa gia công cách khác để kéo sợi',
    Unit: '',
  },
  {
    HsCode: '55041000',
    Description: '- Từ viscose rayon',
    Unit: 'kg',
  },
  {
    HsCode: '55049000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5505',
    Description: 'Phế liệu (kể cả phế liệu xơ, phế liệu sợi và nguyên liệu tái chế) từ xơ nhân tạo',
    Unit: '',
  },
  {
    HsCode: '55051000',
    Description: '- Từ các xơ tổng hợp',
    Unit: 'kg',
  },
  {
    HsCode: '55052000',
    Description: '- Từ các xơ tái tạo',
    Unit: 'kg',
  },
  {
    HsCode: '5506',
    Description: 'Xơ staple tổng hợp, đã chải thô, chải kỹ hoặc gia công cách khác để kéo sợi',
    Unit: '',
  },
  {
    HsCode: '55061000',
    Description: '- Từ ni lông hoặc các polyamit khác',
    Unit: 'kg',
  },
  {
    HsCode: '55062000',
    Description: '- Từ các polyeste',
    Unit: 'kg',
  },
  {
    HsCode: '55063000',
    Description: '- Từ acrylic hoặc modacrylic',
    Unit: 'kg',
  },
  {
    HsCode: '55064000',
    Description: '- Từ polypropylen',
    Unit: 'kg',
  },
  {
    HsCode: '55069000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '55070000',
    Description: 'Xơ staple tái tạo, đã chải thô, chải kỹ hoặc gia công cách khác để kéo sợi',
    Unit: 'kg',
  },
  {
    HsCode: '5508',
    Description: 'Chỉ khâu làm từ xơ staple nhân tạo, đã hoặc chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '550810',
    Description: '- Từ xơ staple tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '55081010',
    Description: '- - Đã đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '55081090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '550820',
    Description: '- Từ xơ staple tái tạo:',
    Unit: '',
  },
  {
    HsCode: '55082010',
    Description: '- - Đã đóng gói để bán lẻ',
    Unit: 'kg',
  },
  {
    HsCode: '55082090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5509',
    Description: 'Sợi (trừ chỉ khâu) từ xơ staple tổng hợp, chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Có hàm lượng xơ staple bằng ni lông hoặc các polyamit khác chiếm từ 85% trở lên tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55091100',
    Description: '- - Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '55091200',
    Description: '- - Sợi xe (folded) hoặc sợi cáp',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng xơ staple polyeste chiếm từ 85% trở lên tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55092100',
    Description: '- - Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '55092200',
    Description: '- - Sợi xe (folded) hoặc sợi cáp',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng xơ staple bằng acrylic hoặc modacrylic chiếm từ 85% trở lên tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55093100',
    Description: '- - Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '55093200',
    Description: '- - Sợi xe (folded) hoặc sợi cáp',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, có hàm lượng xơ staple tổng hợp chiếm từ 85% trở lên tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55094100',
    Description: '- - Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '55094200',
    Description: '- - Sợi xe (folded) hoặc sợi cáp',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, từ xơ staple polyeste:',
    Unit: '',
  },
  {
    HsCode: '55095100',
    Description: '- - Được pha chủ yếu hoặc pha duy nhất với xơ staple tái tạo',
    Unit: 'kg',
  },
  {
    HsCode: '550952',
    Description: '- - Được pha chủ yếu hoặc pha duy nhất với lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '55095210',
    Description: '- - - Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '55095290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '55095300',
    Description: '- - Được pha chủ yếu hoặc pha duy nhất với bông',
    Unit: 'kg',
  },
  {
    HsCode: '55095900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác, từ xơ staple bằng acrylic hoặc modacrylic:',
    Unit: '',
  },
  {
    HsCode: '55096100',
    Description: '- - Được pha chủ yếu hoặc pha duy nhất với lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg',
  },
  {
    HsCode: '55096200',
    Description: '- - Được pha chủ yếu hoặc pha duy nhất với bông',
    Unit: 'kg',
  },
  {
    HsCode: '55096900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Sợi khác:',
    Unit: '',
  },
  {
    HsCode: '55099100',
    Description: '- - Được pha chủ yếu hoặc pha duy nhất với lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg',
  },
  {
    HsCode: '55099200',
    Description: '- - Được pha chủ yếu hoặc pha duy nhất với bông',
    Unit: 'kg',
  },
  {
    HsCode: '55099900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '5510',
    Description: 'Sợi (trừ chỉ khâu) từ xơ staple tái tạo, chưa đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng xơ staple tái tạo chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '55101100',
    Description: '- - Sợi đơn',
    Unit: 'kg',
  },
  {
    HsCode: '55101200',
    Description: '- - Sợi xe (folded) hoặc sợi cáp',
    Unit: 'kg',
  },
  {
    HsCode: '55102000',
    Description: '- Sợi khác, được pha chủ yếu hoặc duy nhất với lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg',
  },
  {
    HsCode: '55103000',
    Description: '- Sợi khác, được pha chủ yếu hoặc duy nhất với bông',
    Unit: 'kg',
  },
  {
    HsCode: '55109000',
    Description: '- Sợi khác',
    Unit: 'kg',
  },
  {
    HsCode: '5511',
    Description: 'Sợi (trừ chỉ khâu) từ xơ staple nhân tạo, đã đóng gói để bán lẻ',
    Unit: '',
  },
  {
    HsCode: '551110',
    Description: '- Từ xơ staple tổng hợp, có hàm lượng loại xơ này chiếm từ 85% trở lên tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55111010',
    Description: '- - Sợi dệt kim, sợi móc và chỉ thêu',
    Unit: 'kg',
  },
  {
    HsCode: '55111090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '551120',
    Description: '- Từ xơ staple tổng hợp, có hàm lượng loại xơ này chiếm dưới 85% tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55112010',
    Description: '- - Sợi dệt kim, sợi móc và chỉ thêu',
    Unit: 'kg',
  },
  {
    HsCode: '55112090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '55113000',
    Description: '- Từ xơ staple tái tạo',
    Unit: 'kg',
  },
  {
    HsCode: '5512',
    Description:
      'Các loại vải dệt thoi từ xơ staple tổng hợp, có hàm lượng loại xơ này chiếm từ 85% trở lên tính theo khối lượng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng xơ staple polyeste chiếm từ 85% trở lên tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55121100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55121900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng xơ staple bằng acrylic hoặc modacrylic chiếm từ 85% trở lên tính theo khối lượng: ',
    Unit: '',
  },
  {
    HsCode: '55122100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55122900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '55129100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55129900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5513',
    Description:
      'Vải dệt thoi bằng xơ staple tổng hợp, có hàm lượng loại xơ này chiếm dưới 85% tính theo khối lượng, pha chủ yếu hoặc pha duy nhất với bông, định lượng không quá 170 g/m2',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng hoặc đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '55131100',
    Description: '- - Từ xơ staple polyeste, dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55131200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo chữ nhân, từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55131300',
    Description: '- - Vải dệt thoi khác từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55131900',
    Description: '- - Vải dệt thoi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '55132100',
    Description: '- - Từ xơ staple polyeste, dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55132300',
    Description: '- - Vải dệt thoi khác từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55132900',
    Description: '- - Vải dệt thoi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ các sợi có các màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '55133100',
    Description: '- - Từ xơ staple polyeste, dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55133900',
    Description: '- - Vải dệt thoi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã in:',
    Unit: '',
  },
  {
    HsCode: '55134100',
    Description: '- - Từ xơ staple polyeste, dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55134900',
    Description: '- - Vải dệt thoi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5514',
    Description:
      'Vải dệt thoi bằng xơ staple tổng hợp, có hàm lượng loại xơ này chiếm dưới 85% tính theo khối lượng, pha chủ yếu hoặc pha duy nhất với bông, định lượng trên 170 g/m2',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa tẩy trắng hoặc đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '55141100',
    Description: '- - Từ xơ staple polyeste, dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55141200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo chữ nhân, từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55141900',
    Description: '- - Vải dệt thoi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '55142100',
    Description: '- - Từ xơ staple polyeste, dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55142200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo chữ nhân, từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55142300',
    Description: '- - Vải dệt thoi khác từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55142900',
    Description: '- - Vải dệt thoi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55143000',
    Description: '- Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Đã in:',
    Unit: '',
  },
  {
    HsCode: '55144100',
    Description: '- - Từ xơ staple polyeste, dệt vân điểm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55144200',
    Description: '- - Vải vân chéo 3 sợi hoặc vân chéo 4 sợi, kể cả vải vân chéo chữ nhân, từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55144300',
    Description: '- - Vải dệt thoi khác từ xơ staple polyeste',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55144900',
    Description: '- - Vải dệt thoi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5515',
    Description: 'Các loại vải dệt thoi khác từ xơ staple tổng hợp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ xơ staple polyeste:',
    Unit: '',
  },
  {
    HsCode: '55151100',
    Description: '- - Pha chủ yếu hoặc pha duy nhất với xơ staple viscose rayon',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55151200',
    Description: '- - Pha chủ yếu hoặc pha duy nhất với sợi filament nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55151300',
    Description: '- - Pha chủ yếu hoặc pha duy nhất với lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55151900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ xơ staple bằng acrylic hoặc modacrylic:',
    Unit: '',
  },
  {
    HsCode: '55152100',
    Description: '- - Pha chủ yếu hoặc pha duy nhất với sợi filament nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55152200',
    Description: '- - Pha chủ yếu hoặc pha duy nhất với lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55152900',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Vải dệt thoi khác:',
    Unit: '',
  },
  {
    HsCode: '55159100',
    Description: '- - Pha chủ yếu hoặc pha duy nhất với sợi filament nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '551599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '55159910',
    Description: '- - - Pha chủ yếu hoặc pha duy nhất với lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55159990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5516',
    Description: 'Vải dệt thoi từ xơ staple tái tạo',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng xơ staple tái tạo chiếm từ 85% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '55161100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55161200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55161300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55161400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Có hàm lượng xơ staple tái tạo chiếm dưới 85% tính theo khối lượng, được pha chủ yếu hoặc pha duy nhất với sợi filament nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '55162100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55162200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55162300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55162400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Có hàm lượng xơ staple tái tạo chiếm dưới 85% tính theo khối lượng, được pha chủ yếu hoặc pha duy nhất với lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '55163100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55163200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55163300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55163400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Có hàm lượng xơ staple tái tạo chiếm dưới 85% tính theo khối lượng, được pha chủ yếu hoặc pha duy nhất với bông:',
    Unit: '',
  },
  {
    HsCode: '55164100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55164200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55164300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55164400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '55169100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55169200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55169300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '55169400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 56',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Mền xơ, phớt và các sản phẩm không dệt; các loại sợi đặc biệt; sợi xe, chão bện (cordage), thừng và cáp và các sản phẩm của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Mền xơ, phớt hoặc các sản phẩm không dệt, đã ngâm tẩm, tráng hoặc phủ bằng các chất hoặc các chế phẩm (ví dụ, nước hoa hoặc mỹ phẩm thuộc Chương 33, xà phòng hoặc các chất tẩy thuộc nhóm 34.01, các chất đánh bóng, kem hoặc các chế phẩm tương tự thuộc nhóm 34.05, các chất làm mềm vải thuộc nhóm 38.09) ở đó vật liệu dệt chỉ được xem như là vật mang;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các sản phẩm dệt thuộc nhóm 58.11;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Bột mài hoặc hạt mài tự nhiên hoặc nhân tạo, bồi trên nền phớt hoặc trên nền sản phẩm không dệt (nhóm 68.05);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Mica đã liên kết khối hoặc tái chế, bồi trên nền phớt hoặc trên nền vật liệu không dệt (nhóm 68.14);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Lá kim loại bồi trên nền phớt hoặc bồi trên vật liệu không dệt (thường thuộc Phần XIV hoặc XV); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Băng vệ sinh (miếng) và băng vệ sinh dạng ống (tampon), khăn (bỉm) và tã lót và các vật phẩm tương tự thuộc nhóm 96.19.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Thuật ngữ “phớt” kể cả phớt xuyên kim (needleloom) và vải được cấu tạo từ một màng bằng xơ dệt được liên kết tốt hơn do khâu đính chính xơ của màng đó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các nhóm 56.02 và 56.03 bao gồm phớt và các sản phẩm không dệt, đã ngâm tẩm, tráng, phủ hoặc ép với plastic hoặc cao su bất kể tính chất của các vật liệu này (đặc hoặc xốp).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nhóm 56.03 cũng bao gồm cả các sản phẩm không dệt trong đó plastic hoặc cao su tạo thành chất kết dính.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tuy nhiên, các nhóm 56.02 và 56.03 không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Phớt đã ngâm tẩm, tráng, phủ hoặc ép với plastic hoặc cao su, có hàm lượng vật liệu dệt chiếm từ 50% trở xuống tính theo khối lượng hoặc phớt đã bọc hoàn toàn bằng plastic hoặc cao su (Chương 39 hoặc 40);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Sản phẩm không dệt, hoặc bọc hoàn toàn bằng plastic hoặc cao su, hoặc tráng hoặc phủ cả hai mặt bằng vật liệu trên, với điều kiện là việc tráng hoặc phủ như vậy có thể nhìn thấy được bằng mắt thường mà không cần quan tâm đến sự biến đổi về màu sắc (Chương 39 hoặc 40); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Tấm, bản mỏng hoặc dải bằng plastic xốp hoặc cao su xốp kết hợp với phớt hoặc vật liệu không dệt, trong đó vật liệu dệt chỉ đơn thuần là vật liệu để gia cố (Chương 39 hoặc 40). ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 56.04 không bao gồm sợi dệt, hoặc dải hoặc các dạng tương tự thuộc nhóm 54.04 hoặc 54.05, trong đó chất ngâm tẩm, tráng hoặc phủ không thể nhìn được bằng mắt thường (thường thuộc các Chương từ 50 đến 55); theo mục đích của phần này, không cần quan tâm đến bất cứ sự thay đổi nào về màu sắc.',
    Unit: '',
  },
  {
    HsCode: '5601',
    Description:
      'Mền xơ bằng vật liệu dệt và các sản phẩm của nó; các loại xơ dệt, chiều dài không quá 5 mm (xơ vụn), bụi xơ và kết xơ (neps)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Mền xơ từ vật liệu dệt và các sản phẩm của nó: ',
    Unit: '',
  },
  {
    HsCode: '56012100',
    Description: '- - Từ bông',
    Unit: 'kg/m',
  },
  {
    HsCode: '560122',
    Description: '- - Từ xơ nhân tạo: ',
    Unit: '',
  },
  {
    HsCode: '56012210',
    Description: '- - - Tô (tow) đầu lọc thuốc lá (SEN)',
    Unit: 'kg/m',
  },
  {
    HsCode: '56012290',
    Description: '- - - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '56012900',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '560130',
    Description: '- Xơ vụn và bụi xơ và kết xơ:',
    Unit: '',
  },
  {
    HsCode: '56013010',
    Description: '- - Xơ vụn polyamit ',
    Unit: 'kg/m',
  },
  {
    HsCode: '56013020',
    Description: '- - Xơ vụn bằng polypropylen',
    Unit: 'kg/m',
  },
  {
    HsCode: '56013090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '5602',
    Description: 'Phớt, nỉ đã hoặc chưa ngâm tẩm, tráng, phủ hoặc ép lớp',
    Unit: '',
  },
  {
    HsCode: '56021000',
    Description: '- Phớt, nỉ xuyên kim và vải khâu đính',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Phớt, nỉ khác, chưa ngâm tẩm, tráng, phủ hoặc ép lớp:',
    Unit: '',
  },
  {
    HsCode: '56022100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56022900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56029000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5603',
    Description: 'Các sản phẩm không dệt, đã hoặc chưa ngâm tẩm, tráng phủ hoặc ép lớp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng filament nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '56031100',
    Description: '- - Định lượng không quá 25 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56031200',
    Description: '- - Định lượng trên 25 g/m2 nhưng không quá 70 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56031300',
    Description: '- - Định lượng trên 70 g/m2 nhưng không quá 150 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56031400',
    Description: '- - Định lượng trên 150 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '56039100',
    Description: '- - Định lượng không quá 25 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56039200',
    Description: '- - Định lượng trên 25 g/m2 nhưng không quá 70 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56039300',
    Description: '- - Định lượng trên 70 g/m2 nhưng không quá 150 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '56039400',
    Description: '- - Định lượng trên 150 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5604',
    Description:
      'Chỉ cao su và sợi (cord) cao su, được bọc bằng vật liệu dệt; sợi dệt, và dải và dạng tương tự thuộc nhóm 54.04 hoặc 54.05, đã ngâm tẩm, tráng, phủ hoặc bao ngoài bằng cao su hoặc plastic',
    Unit: '',
  },
  {
    HsCode: '56041000',
    Description: '- Chỉ cao su và sợi (cord) cao su, được bọc bằng vật liệu dệt',
    Unit: 'kg/m',
  },
  {
    HsCode: '560490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '56049010',
    Description: '- - Chỉ giả catgut, bằng sợi tơ tằm',
    Unit: 'kg/m',
  },
  {
    HsCode: '56049020',
    Description: '- - Sợi dệt được ngâm tẩm cao su',
    Unit: 'kg/m',
  },
  {
    HsCode: '56049030',
    Description: '- - Sợi có độ bền cao từ polyeste, ni lông hoặc polyamit khác hoặc từ viscose rayon',
    Unit: 'kg/m',
  },
  {
    HsCode: '56049090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '56050000',
    Description:
      'Sợi trộn kim loại, có hoặc không quấn bọc, là loại sợi dệt, hoặc dải hoặc dạng tương tự thuộc nhóm 54.04 hoặc 54.05, được kết hợp với kim loại ở dạng dây, dải hoặc bột hoặc phủ bằng kim loại',
    Unit: 'kg/m',
  },
  {
    HsCode: '56060000',
    Description:
      'Sợi cuốn bọc, và dải và dạng tương tự thuộc nhóm 54.04 hoặc 54.05, đã quấn bọc (trừ các loại thuộc nhóm 56.05 và sợi quấn bọc lông đuôi hoặc bờm ngựa); sợi sơnin (chenille) (kể cả sợi sơnin xù); sợi sùi vòng',
    Unit: 'kg/m',
  },
  {
    HsCode: '5607',
    Description:
      'Dây xe, chão bện (cordage), thừng và cáp, đã hoặc chưa tết hoặc bện và đã hoặc chưa ngâm tẩm, tráng, phủ hoặc bao ngoài bằng cao su hoặc plastic',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ xidan (sisal) hoặc từ xơ dệt khác của các cây thuộc chi cây thùa (Agave):',
    Unit: '',
  },
  {
    HsCode: '56072100',
    Description: '- - Dây xe để buộc hoặc đóng kiện',
    Unit: 'kg/m',
  },
  {
    HsCode: '56072900',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '',
    Description: '- Từ polyetylen hoặc polypropylen:',
    Unit: '',
  },
  {
    HsCode: '56074100',
    Description: '- - Dây xe để buộc hoặc đóng kiện',
    Unit: 'kg/m',
  },
  {
    HsCode: '56074900',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '560750',
    Description: '- Từ xơ tổng hợp khác:',
    Unit: '',
  },
  {
    HsCode: '56075010',
    Description:
      '- - Sợi bện (cord) cho dây đai chữ V làm từ xơ nhân tạo đã xử lý bằng resorcinol formaldehyde; sợi polyamit và sợi polytetrafloro-ethylen có độ mảnh lớn hơn 10.000 decitex, dùng để làm kín các loại bơm, van và các sản phẩm tương tự',
    Unit: 'kg/m',
  },
  {
    HsCode: '56075090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '560790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '56079010',
    Description: '- - Từ các xơ tái tạo',
    Unit: 'kg/m',
  },
  {
    HsCode: '',
    Description: '- - Từ xơ chuối abaca (Manila hemp hoặc Musa textilis Nee) hoặc các xơ (lá thực vật) cứng khác:',
    Unit: '',
  },
  {
    HsCode: '56079021',
    Description: '- - - Từ xơ chuối abaca (Manila hemp hoặc Musa textilis Nee)',
    Unit: 'kg/m',
  },
  {
    HsCode: '56079022',
    Description: '- - - Từ các xơ (lá thực vật) cứng khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '56079030',
    Description: '- - Từ đay hoặc các loại xơ libe dệt khác thuộc nhóm 53.03',
    Unit: 'kg/m',
  },
  {
    HsCode: '56079090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '5608',
    Description:
      'Tấm lưới được thắt nút bằng sợi xe, chão bện (cordage) hoặc thừng; lưới đánh cá và các loại lưới khác đã hoàn thiện, từ vật liệu dệt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ vật liệu dệt nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '56081100',
    Description: '- - Lưới đánh cá thành phẩm',
    Unit: 'kg/m',
  },
  {
    HsCode: '560819',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '56081920',
    Description: '- - - Túi lưới',
    Unit: 'kg/m',
  },
  {
    HsCode: '56081990',
    Description: '- - - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '560890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '56089010',
    Description: '- - Túi lưới',
    Unit: 'kg/m',
  },
  {
    HsCode: '56089090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '56090000',
    Description:
      'Các sản phẩm làm từ sợi, dải hoặc dạng tương tự thuộc nhóm 54.04 hoặc 54.05, dây xe, chão bện (cordage), thừng hoặc cáp, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 57',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thảm và các loại hàng dệt trải sàn khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của Chương này, thuật ngữ “thảm và các loại hàng dệt trải sàn khác” có nghĩa là các loại trải sàn trong đó vật liệu dệt được dùng làm bề mặt ngoài của sản phẩm khi sử dụng và gồm cả các sản phẩm có các đặc tính của hàng dệt trải sàn nhưng được dự định dùng cho các mục đích khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm các loại lót của hàng dệt trải sàn.',
    Unit: '',
  },
  {
    HsCode: '5701',
    Description: 'Thảm và các loại hàng dệt trải sàn khác, thắt nút, đã hoặc chưa hoàn thiện',
    Unit: '',
  },
  {
    HsCode: '570110',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '57011010',
    Description: '- - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57011090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570190',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '57019011',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57019019',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57019020',
    Description: '- - Từ xơ đay',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '57019091',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57019099',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '5702',
    Description:
      'Thảm và các loại hàng dệt trải sàn khác, dệt thoi, không tạo búi hoặc phủ xơ vụn, đã hoặc chưa hoàn thiện, kể cả thảm “Kelem”, “Schumacks”, “Karamanie” và các loại thảm dệt thoi thủ công tương tự ',
    Unit: '',
  },
  {
    HsCode: '57021000',
    Description: '- Thảm “Kelem”, “Schumacks”, “Karamanie” và các loại thảm dệt thoi thủ công tương tự',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57022000',
    Description: '- Hàng trải sàn từ xơ dừa (coir)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác, có cấu tạo nổi vòng, chưa hoàn thiện:',
    Unit: '',
  },
  {
    HsCode: '57023100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57023200',
    Description: '- - Từ các vật liệu dệt nhân tạo',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570239',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '57023910',
    Description: '- - - Từ bông',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57023920',
    Description: '- - - Từ xơ đay',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57023990',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác, có cấu tạo nổi vòng, đã hoàn thiện:',
    Unit: '',
  },
  {
    HsCode: '570241',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '57024110',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57024190',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570242',
    Description: '- - Từ vật liệu dệt nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '57024210',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57024290',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570249',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '57024911',
    Description: '- - - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57024919',
    Description: '- - - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57024920',
    Description: '- - - Từ xơ đay',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '57024991',
    Description: '- - - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57024999',
    Description: '- - - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570250',
    Description: '- Loại khác, không có cấu tạo nổi vòng, chưa hoàn thiện:',
    Unit: '',
  },
  {
    HsCode: '57025010',
    Description: '- - Từ bông',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57025020',
    Description: '- - Từ xơ đay',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57025090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác, không có cấu tạo nổi vòng, đã hoàn thiện:',
    Unit: '',
  },
  {
    HsCode: '570291',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '57029110',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57029190',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570292',
    Description: '- - Từ vật liệu dệt nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '57029210',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57029290',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570299',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '57029911',
    Description: '- - - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57029919',
    Description: '- - - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57029920',
    Description: '- - - Từ xơ đay',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '57029991',
    Description: '- - - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57029999',
    Description: '- - - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '5703',
    Description: 'Thảm và các loại hàng dệt trải sàn khác (kể cả thảm cỏ), tạo búi, đã hoặc chưa hoàn thiện',
    Unit: '',
  },
  {
    HsCode: '570310',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '57031010',
    Description: '- - Thảm lót chân, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04 (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57031020',
    Description: '- - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57031030',
    Description: '- - Thảm trải sàn, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04 (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57031090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ ni lông hoặc từ các polyamit khác:',
    Unit: '',
  },
  {
    HsCode: '57032100',
    Description: '- - Thảm cỏ (turf)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570329',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '57032910',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57032990',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ các vật liệu dệt nhân tạo khác:',
    Unit: '',
  },
  {
    HsCode: '57033100',
    Description: '- - Thảm cỏ (turf)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570339',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '57033910',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57033990',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '570390',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '57039011',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57039019',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Từ xơ đay:',
    Unit: '',
  },
  {
    HsCode: '57039021',
    Description: '- - - Thảm lót chân, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04 (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57039022',
    Description: '- - - Thảm trải sàn, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04 (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57039029',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '57039091',
    Description: '- - - Thảm lót chân, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04 (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57039092',
    Description: '- - - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57039093',
    Description: '- - - Thảm trải sàn, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04 (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57039099',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '5704',
    Description:
      'Thảm và các loại hàng dệt trải sàn khác, từ phớt, không tạo búi hoặc phủ xơ vụn, đã hoặc chưa hoàn thiện',
    Unit: '',
  },
  {
    HsCode: '57041000',
    Description: '- Các tấm để ghép, có diện tích bề mặt tối đa là 0,3 m2',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57042000',
    Description: '- Các tấm để ghép, có diện tích bề mặt tối đa trên 0,3 m2 nhưng không quá 1 m2',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57049000',
    Description: '- Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '5705',
    Description: 'Các loại thảm khác và các loại hàng dệt trải sàn khác, đã hoặc chưa hoàn thiện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '57050011',
    Description: '- - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57050019',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ xơ đay:',
    Unit: '',
  },
  {
    HsCode: '57050021',
    Description: '- - Tấm trải sàn không dệt, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57050029',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '57050091',
    Description: '- - Thảm cầu nguyện (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57050092',
    Description: '- - Tấm trải sàn không dệt, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '57050099',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 58',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các loại vải dệt thoi đặc biệt; các loại vải dệt tạo búi; hàng ren; thảm trang trí; hàng trang trí; hàng thêu',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Chương này không áp dụng cho các loại vải dệt nêu trong Chú giải 1 của Chương 59, đã ngâm tẩm, tráng, phủ hoặc ép lớp, hoặc những mặt hàng khác thuộc Chương 59.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 58.01 cũng bao gồm các loại vải dệt thoi có sợi ngang nổi vòng, chưa được cắt phần nổi, ở trạng thái này chúng không có vòng lông dựng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 58.03, vải “dệt quấn” (gauze) là loại vải có sợi dọc gồm toàn bộ hoặc một phần của sợi dựng hoặc sợi nền và sợi cuốn cả vòng hoặc nửa vòng đi bắt chéo qua sợi dựng hoặc sợi nền tạo thành nửa vòng, một vòng hoàn chỉnh hoặc nhiều hơn để tạo thành các vòng sợi cho sợi ngang đi qua.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 58.04 không áp dụng cho vải lưới thắt nút từ dây xe, chão bện (cordage) hoặc thừng, thuộc nhóm 56.08.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '5. Theo mục đích của nhóm 58.06, khái niệm “vải dệt thoi khổ hẹp” là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Vải dệt thoi có khổ rộng không quá 30 cm, hoặc được dệt sẵn như thế hoặc được cắt từ những tấm rộng hơn, với điều kiện đã tạo biên ở hai mép (dệt thoi, gắn keo hoặc bằng cách khác);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Vải dệt thoi dạng ống có chiều rộng khi trải phẳng không quá 30 cm; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Vải cắt xiên với các mép gấp, có chiều rộng khi chưa gấp mép không quá 30 cm. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Vải dệt thoi khổ hẹp có tua viền được phân loại vào nhóm 58.08.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Trong nhóm 58.10, không kể những đề cập khác, khái niệm “hàng thêu” là hàng thêu bằng chỉ kim loại hoặc chỉ thủy tinh trên vải lộ nền, và hàng được khâu đính trang trí sequin, hạt hoặc các mẫu trang trí bằng vật liệu dệt hoặc vật liệu khác. Nhóm này không bao gồm loại thảm trang trí thêu tay (nhóm 58.05).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Ngoài các sản phẩm của nhóm 58.09, Chương này cũng bao gồm các mặt hàng làm từ sợi kim loại và loại làm trang trí như các loại vải dùng cho nội thất hoặc dùng cho các mục đích tương tự.',
    Unit: '',
  },
  {
    HsCode: '5801',
    Description:
      'Các loại vải dệt nổi vòng và các loại vải sơnin (chenille), trừ các loại vải thuộc nhóm 58.02 hoặc 58.06',
    Unit: '',
  },
  {
    HsCode: '580110',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '58011010',
    Description: '- - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58011090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '580121',
    Description: '- - Vải có sợi ngang nổi vòng không cắt:',
    Unit: '',
  },
  {
    HsCode: '58012110',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58012190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580122',
    Description: '- - Nhung kẻ đã cắt:',
    Unit: '',
  },
  {
    HsCode: '58012210',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58012290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580123',
    Description: '- - Vải có sợi ngang nổi vòng khác:',
    Unit: '',
  },
  {
    HsCode: '58012310',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58012390',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580126',
    Description: '- - Các loại vải sơnin (chenille):',
    Unit: '',
  },
  {
    HsCode: '58012610',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58012690',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580127',
    Description: '- - Vải có sợi dọc nổi vòng:',
    Unit: '',
  },
  {
    HsCode: '58012710',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58012790',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ xơ nhân tạo: ',
    Unit: '',
  },
  {
    HsCode: '580131',
    Description: '- - Vải có sợi ngang nổi vòng không cắt:',
    Unit: '',
  },
  {
    HsCode: '58013110',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58013190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580132',
    Description: '- - Nhung kẻ đã cắt:',
    Unit: '',
  },
  {
    HsCode: '58013210',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58013290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580133',
    Description: '- - Vải có sợi ngang nổi vòng khác:',
    Unit: '',
  },
  {
    HsCode: '58013310',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58013390',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580136',
    Description: '- - Các loại vải sơnin (chenille):',
    Unit: '',
  },
  {
    HsCode: '58013610',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58013690',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580137',
    Description: '- - Vải có sợi dọc nổi vòng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Đã tráng, phủ hoặc ép lớp:',
    Unit: '',
  },
  {
    HsCode: '58013711',
    Description: '- - - - Sợi nổi vòng đã cắt',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58013712',
    Description: '- - - - Sợi nổi vòng không cắt',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '58013791',
    Description: '- - - - Sợi nổi vòng đã cắt',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58013792',
    Description: '- - - - Sợi nổi vòng không cắt',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580190',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '58019011',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58019019',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '58019091',
    Description: '- - - Đã tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58019099',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5802',
    Description:
      'Vải khăn lông (terry) và các loại vải dệt thoi tạo vòng lông tương tự, trừ các loại vải khổ hẹp thuộc nhóm 58.06; các loại vải dệt tạo búi, trừ các sản phẩm thuộc nhóm 57.03',
    Unit: '',
  },
  {
    HsCode: '580210',
    Description: '- Vải khăn lông (terry) và các loại vải dệt thoi tạo vòng lông tương tự, từ bông:',
    Unit: '',
  },
  {
    HsCode: '58021010',
    Description: '- - Chưa tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58021090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580220',
    Description: '- Vải khăn lông (terry) và các loại vải dệt thoi tạo vòng lông tương tự, từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '58022010',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58022090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580230',
    Description: '- Các loại vải dệt tạo búi:',
    Unit: '',
  },
  {
    HsCode: '58023010',
    Description: '- - Đã ngâm tẩm, tráng hoặc phủ',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58023020',
    Description: '- - Dệt thoi, từ bông hoặc sợi nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58023030',
    Description: '- - Dệt thoi, từ vật liệu khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58023090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5803',
    Description: 'Vải dệt quấn, trừ các loại vải khổ hẹp thuộc nhóm 58.06',
    Unit: '',
  },
  {
    HsCode: '58030010',
    Description: '- Từ bông',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58030020',
    Description: '- Từ xơ nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58030030',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58030090',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5804',
    Description:
      'Các loại vải tuyn và các loại vải dệt lưới khác, không bao gồm vải dệt thoi, dệt kim hoặc móc; hàng ren dạng mảnh, dạng dải hoặc dạng mẫu hoa văn, trừ các loại vải thuộc các nhóm từ 60.02 đến 60.06',
    Unit: '',
  },
  {
    HsCode: '580410',
    Description: '- Vải tuyn và vải dệt lưới khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '58041011',
    Description: '- - - Đã ngâm tẩm, tráng, phủ hoặc ép lớp ',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58041019',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '58041021',
    Description: '- - - Đã ngâm tẩm, tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58041029',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '58041091',
    Description: '- - - Đã ngâm tẩm, tráng, phủ hoặc ép lớp ',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58041099',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Ren dệt bằng máy:',
    Unit: '',
  },
  {
    HsCode: '580421',
    Description: '- - Từ xơ nhân tạo: ',
    Unit: '',
  },
  {
    HsCode: '58042110',
    Description: '- - - Đã ngâm tẩm, tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58042190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580429',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '58042910',
    Description: '- - - Đã ngâm tẩm, tráng, phủ hoặc ép lớp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58042990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58043000',
    Description: '- Ren làm bằng tay',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5805',
    Description:
      'Thảm trang trí dệt thoi thủ công theo kiểu Gobelins, Flanders, Aubusson, Beauvais và các kiểu tương tự, và các loại thảm trang trí thêu tay (ví dụ, thêu mũi nhỏ, thêu chữ thập), đã hoặc chưa hoàn thiện',
    Unit: '',
  },
  {
    HsCode: '58050010',
    Description: '- Từ bông',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '58050090',
    Description: '- Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '5806',
    Description:
      'Vải dệt thoi khổ hẹp, trừ các loại hàng thuộc nhóm 58.07; vải khổ hẹp gồm toàn sợi dọc không có sợi ngang liên kết với nhau bằng chất kết dính (bolducs)',
    Unit: '',
  },
  {
    HsCode: '580610',
    Description:
      '- Vải dệt thoi nổi vòng (kể cả vải khăn lông (terry) và các loại vải dệt nổi vòng tương tự) và vải dệt từ sợi sơnin (chenille):',
    Unit: '',
  },
  {
    HsCode: '58061010',
    Description: '- - Từ tơ tằm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58061020',
    Description: '- - Từ bông',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58061090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580620',
    Description:
      '- Vải dệt thoi khác, có hàm lượng sợi đàn hồi (elastomeric) hoặc sợi cao su chiếm từ 5% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '58062010',
    Description: '- - Băng loại dùng để quấn tay cầm hoặc cán của các dụng cụ thể thao',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58062090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Vải dệt thoi khác:',
    Unit: '',
  },
  {
    HsCode: '580631',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '58063110',
    Description:
      '- - - Vải dệt thoi khổ hẹp thích hợp cho sản xuất băng mực dùng cho máy chữ hoặc các loại máy tương tự',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063120',
    Description: '- - - Làm nền cho giấy cách điện',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063130',
    Description: '- - - Ruy băng loại dùng làm khóa kéo và có chiều rộng không quá 12 mm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580632',
    Description: '- - Từ xơ nhân tạo: ',
    Unit: '',
  },
  {
    HsCode: '58063210',
    Description:
      '- - - Vải dệt thoi khổ hẹp thích hợp cho sản xuất băng mực dùng cho máy chữ hoặc các loại máy tương tự; băng vải dùng làm dây đai an toàn ghế ngồi',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063240',
    Description: '- - - Làm nền cho giấy cách điện',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063250',
    Description: '- - - Ruy băng loại dùng làm khóa kéo và có chiều rộng không quá 12 mm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '580639',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '58063910',
    Description: '- - - Từ tơ tằm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '58063991',
    Description: '- - - - Làm nền cho giấy cách điện',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063992',
    Description:
      '- - - - Vải dệt thoi khổ hẹp thích hợp cho sản xuất băng mực dùng cho máy chữ hoặc các loại máy tương tự',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063993',
    Description: '- - - - Ruy băng loại dùng làm khóa kéo và có chiều rộng không quá 12 mm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58063999',
    Description: '- - - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '58064000',
    Description: '- Các loại vải dệt chỉ có sợi dọc không có sợi ngang liên kết với nhau bằng chất kết dính (bolducs)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5807',
    Description:
      'Các loại nhãn, phù hiệu và các mặt hàng tương tự từ vật liệu dệt, dạng chiếc, dạng dải hoặc đã cắt thành hình hoặc kích cỡ, không thêu',
    Unit: '',
  },
  {
    HsCode: '58071000',
    Description: '- Dệt thoi',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '580790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '58079010',
    Description: '- - Vải không dệt',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '58079090',
    Description: '- - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '5808',
    Description:
      'Các dải bện dạng chiếc; dải, mảnh trang trí dạng chiếc, không thêu, trừ loại dệt kim hoặc móc; tua, ngù và các mặt hàng tương tự',
    Unit: '',
  },
  {
    HsCode: '580810',
    Description: '- Các dải bện dạng chiếc:',
    Unit: '',
  },
  {
    HsCode: '58081010',
    Description: '- - Kết hợp với sợi cao su',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '58081090',
    Description: '- - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '580890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '58089010',
    Description: '- - Kết hợp với sợi cao su',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '58089090',
    Description: '- - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '58090000',
    Description:
      'Vải dệt thoi từ sợi kim loại và vải dệt thoi từ sợi trộn kim loại thuộc nhóm 56.05, sử dụng để trang trí, như các loại vải trang trí nội thất hoặc cho các mục đích tương tự, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5810',
    Description: 'Hàng thêu dạng chiếc, dạng dải hoặc dạng theo mẫu hoa văn',
    Unit: '',
  },
  {
    HsCode: '58101000',
    Description: '- Hàng thêu không lộ nền',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- Hàng thêu khác:',
    Unit: '',
  },
  {
    HsCode: '58109100',
    Description: '- - Từ bông',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '58109200',
    Description: '- - Từ xơ nhân tạo',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '58109900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '5811',
    Description:
      'Các sản phẩm dệt đã chần (quilted) dạng chiếc, bao gồm một hoặc nhiều lớp vật liệu dệt kết hợp với lớp đệm bằng cách khâu hoặc cách khác, trừ hàng thêu thuộc nhóm 58.10',
    Unit: '',
  },
  {
    HsCode: '58110010',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn hoặc loại thô',
    Unit: 'm/m2/chiếc',
  },
  {
    HsCode: '58110090',
    Description: '- Loại khác',
    Unit: 'm/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 59',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các loại vải dệt đã được ngâm tẩm, tráng, phủ hoặc ép lớp; các mặt hàng dệt thích hợp dùng trong công nghiệp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trừ khi có yêu cầu khác, theo mục đích của Chương này khái niệm ""vải dệt"" chỉ áp dụng đối với vải dệt thoi thuộc các Chương từ 50 đến 55 và các nhóm 58.03 và 58.06, dải bện và dải, mảnh trang trí dạng chiếc thuộc nhóm 58.08 và vải dệt kim hoặc móc thuộc nhóm 60.02 đến 60.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Nhóm 59.03 áp dụng đối với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các loại vải dệt, đã ngâm tẩm, tráng, phủ hoặc ép với plastic, bất kể khối lượng tính trên 1m2 và bất kể tính chất của vật liệu plastic (đặc hoặc xốp), trừ: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(1) Vải trong đó chất ngâm tẩm, tráng hoặc phủ không thể nhìn được bằng mắt thường (thường ở các Chương từ 50 đến 55, 58 hoặc 60); theo mục đích của phần này, không cần quan tâm đến sự thay đổi về màu sắc;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) Các sản phẩm không thể được quấn bằng tay quanh một trục tròn có đường kính 7mm, ở nhiệt độ từ 15oC đến 30oC mà không bị nứt vỡ (thường thuộc Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(3) Các sản phẩm trong đó vải dệt hoặc được bọc hoàn toàn bằng plastic hoặc được tráng hoặc phủ cả hai mặt bằng vật liệu đó, miễn là việc tráng hoặc phủ có thể nhìn được bằng mắt thường mà không cần quan tâm đến sự thay đổi về màu sắc (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(4) Vải được tráng hoặc phủ từng phần bằng plastic và có họa tiết do việc xử lý đó tạo nên (thường gặp ở các Chương từ 50 đến 55, 58 hoặc 60);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(5) Tấm, lá hoặc dải bằng plastic xốp, kết hợp với vải dệt, mà trong đó vải dệt chỉ đơn thuần nhằm mục đích gia cố (Chương 39); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(6) Các sản phẩm dệt thuộc nhóm 58.11;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Vải dệt từ sợi, dải hoặc từ các dạng tương tự, đã ngâm tẩm, tráng, phủ hoặc bao ngoài bằng plastic, thuộc nhóm 56.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 59.03, khái niệm ""vải dệt được ép với plastic"" nghĩa là các sản phẩm được tạo ra bằng cách ghép một hoặc nhiều lớp vải với một hoặc nhiều tấm hoặc màng plastic và liên kết các lớp với nhau bằng bất kỳ phương pháp nào, cho dù các tấm hoặc màng plastic có hoặc không thể nhìn bằng mắt thường ở tiết diện cắt ngang.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo mục đích của nhóm 59.05, khái niệm ""các loại hàng dệt phủ tường"" áp dụng đối với các sản phẩm ở dạng cuộn, chiều rộng không dưới 45 cm, phù hợp để trang trí trần nhà hoặc tường, có bề mặt dệt được gắn chặt trên lớp bồi hoặc được xử lý mặt sau (ngâm tẩm hoặc tráng để có thể phết hồ).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, nhóm này không áp dụng cho các mặt hàng phủ tường có xơ vụn hoặc bụi xơ dệt gắn trực tiếp trên lớp bồi giấy (nhóm 48.14) hoặc trên lớp bồi vật liệu dệt (thường thuộc nhóm 59.07).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '5. Theo mục đích của nhóm 59.06, khái niệm ""vải dệt đã được cao su hóa"" có nghĩa là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Vải dệt đã ngâm tẩm, tráng, phủ hoặc ép với cao su,',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Định lượng không quá 1.500 g/m2; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Định lượng trên 1.500 g/m2 và có hàm lượng vật liệu dệt chiếm trên 50% tính theo khối lượng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các loại vải làm từ sợi, dải hoặc các dạng tương tự, đã được ngâm tẩm, tráng, phủ hoặc bao ngoài bằng cao su, thuộc nhóm 56.04; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các loại vải gồm sợi dệt đặt song song được liên kết với cao su, bất kể khối lượng tính trên 1m2 của chúng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, nhóm này không áp dụng cho các tấm, tờ hoặc dải bằng cao su xốp, kết hợp với vải dệt, mà trong đó vải dệt chỉ đơn thuần phục vụ cho mục đích gia cố (Chương 40), hoặc các sản phẩm dệt thuộc nhóm 58.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '6. Nhóm 59.07 không áp dụng cho:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các loại vải trong đó việc ngâm tẩm, tráng hoặc phủ không thể nhìn thấy được bằng mắt thường (thông thường thuộc các Chương từ 50 đến 55, 58 hoặc 60); theo mục đích của phần này, không tính đến sự thay đổi về màu sắc;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Vải được vẽ các hoạ tiết (trừ vải canvas đã sơn vẽ để làm phông cảnh cho rạp hát, phông trường quay hoặc các loại tương tự);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Vải được phủ từng phần bằng xơ vụn, bụi xơ, bột cây bần hoặc các loại tương tự và mang họa tiết do việc xử lý đó tạo nên; tuy nhiên, các loại vải giả nổi vòng cũng được phân loại trong nhóm này;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Vải được hoàn thiện bằng cách hồ thông thường có thành phần cơ bản là tinh bột hoặc các chất tương tự;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Gỗ lớp mặt trên lớp vải nền (nhóm 44.08);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Hạt mài hoặc bột mài tự nhiên hoặc nhân tạo, trên lớp vải nền (nhóm 68.05);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Mica liên kết khối hoặc tái chế, trên lớp vải nền (nhóm 68.14); hoặc ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Lá kim loại trên lớp vải nền (thường thuộc Phần XIV hoặc XV).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '7. Nhóm 59.10 không áp dụng đối với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Băng truyền hoặc băng tải, bằng vật liệu dệt, có độ dày dưới 3 mm; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Băng truyền hoặc băng tải hoặc dây curoa bằng vải dệt đã ngâm tẩm, tráng, phủ hoặc ép với cao su hoặc làm từ sợi dệt hoặc sợi bện (cord) đã ngâm tẩm, tráng, phủ hoặc bao ngoài bằng cao su (nhóm 40.10).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Nhóm 59.11 áp dụng đối với các loại hàng hóa dưới đây, những loại hàng hóa này không xếp vào bất kỳ nhóm nào khác của Phần XI:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Sản phẩm dệt dạng tấm, được cắt thành từng đoạn hoặc đơn giản là cắt thành hình chữ nhật (kể cả hình vuông) (trừ các loại sản phẩm có đặc tính của các sản phẩm thuộc các nhóm từ 59.08 đến 59.10), chỉ có các loại sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) Vải dệt, phớt và vải dệt thoi lót phớt, đã được tráng, phủ, bọc hoặc ép với cao su, da thuộc hoặc vật liệu khác, dùng để làm vải nền kim chải, và các loại vải tương tự sử dụng cho các mục đích kỹ thuật khác, kể cả vải nhung khổ hẹp được ngâm tẩm bằng cao su, dùng để bọc các trục dệt (trục cuộn vải dệt);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Vải dùng để rây sàng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iii) Vải lọc dùng trong công nghệ ép dầu hoặc các mục đích tương tự, làm từ vật liệu dệt hoặc làm từ tóc người;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iv) Vải dệt thoi phẳng với nhiều lớp sợi dọc hoặc sợi ngang, có hoặc không tạo phớt, ngâm tẩm hoặc tráng, dùng cho máy móc hoặc cho các mục đích kỹ thuật khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (v) Vải dệt được gia cố bằng kim loại, dùng cho các mục đích kỹ thuật;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(vi) Sợi bện (cord), dải bện hoặc loại tương tự, có hoặc không ngâm tẩm, tráng hoặc gia cố bằng kim loại, dùng trong công nghiệp như vật liệu để đóng gói hoặc vật liệu bôi trơn;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các mặt hàng dệt (trừ các sản phẩm thuộc các nhóm từ 59.08 đến 59.10) loại sử dụng cho các mục đích kỹ thuật (ví dụ, vải dệt và phớt, được dệt vòng liền hoặc kèm theo cơ cấu nối ráp, dùng trong máy sản xuất giấy hoặc các máy tương tự (ví dụ, để chế biến bột giấy hoặc ximăng amiăng), các miếng đệm, gioăng, đĩa đánh bóng hoặc các chi tiết máy khác).',
    Unit: '',
  },
  {
    HsCode: '5901',
    Description:
      'Vải dệt được tráng gôm hoặc hồ tinh bột, dùng để bọc ngoài bìa sách hoặc loại tương tự; vải can; vải canvas đã xử lý để vẽ; vải hồ cứng và các loại vải dệt đã được làm cứng tương tự để làm cốt mũ',
    Unit: '',
  },
  {
    HsCode: '59011000',
    Description: '- Vải dệt được tráng gôm hoặc hồ tinh bột, dùng để bọc ngoài bìa sách hoặc các loại tương tự',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '590190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '59019010',
    Description: '- - Vải can',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59019020',
    Description: '- - Vải canvas đã xử lý để vẽ',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59019090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5902',
    Description:
      'Vải mành dùng làm lốp từ sợi có độ bền cao từ ni lông hoặc các polyamit khác, các polyeste hoặc viscose rayon',
    Unit: '',
  },
  {
    HsCode: '590210',
    Description: '- Từ ni lông hoặc từ các polyamit khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Vải tráng cao su làm mép lốp:',
    Unit: '',
  },
  {
    HsCode: '59021011',
    Description: '- - - Từ sợi ni lông 6 (Nylon-6)(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59021019',
    Description: '- - - Loại khác(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '59021091',
    Description: '- - - Từ sợi ni lông 6 (Nylon-6)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59021099',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '590220',
    Description: '- Từ các polyeste:',
    Unit: '',
  },
  {
    HsCode: '59022020',
    Description: '- - Vải tráng cao su làm mép lốp(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '59022091',
    Description: '- - - Chứa bông',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59022099',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59029000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5903',
    Description: 'Vải dệt đã được ngâm tẩm, tráng, phủ hoặc ép với plastic, trừ các loại thuộc nhóm 59.02',
    Unit: '',
  },
  {
    HsCode: '590310',
    Description: '- Với poly(vinyl clorua):',
    Unit: '',
  },
  {
    HsCode: '59031010',
    Description: '- - Vải lót(SEN)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59031090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59032000',
    Description: '- Với polyurethan',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '590390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '59039010',
    Description: '- - Vải canvas đã được ngâm tẩm, tráng, phủ hoặc ép với nylon hoặc các polyamit khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59039090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5904',
    Description:
      'Vải sơn, đã hoặc chưa cắt thành hình; các loại trải sàn có một lớp tráng hoặc phủ gắn trên lớp bồi là vật liệu dệt, đã hoặc chưa cắt thành hình',
    Unit: '',
  },
  {
    HsCode: '59041000',
    Description: '- Vải sơn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59049000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5905',
    Description: 'Các loại vải dệt phủ tường',
    Unit: '',
  },
  {
    HsCode: '59050010',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn hoặc loại thô',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59050090',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5906',
    Description: 'Vải dệt cao su hóa, trừ các loại thuộc nhóm 59.02',
    Unit: '',
  },
  {
    HsCode: '59061000',
    Description: '- Băng dính có chiều rộng không quá 20 cm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '59069100',
    Description: '- - Vải dệt kim hoặc vải móc',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '590699',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '59069910',
    Description: '- - - Tấm vải cao su phù hợp sử dụng tại bệnh viện',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59069990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5907',
    Description:
      'Các loại vải dệt được ngâm tẩm, tráng hoặc phủ bằng cách khác; vải canvas được vẽ làm phông cảnh cho rạp hát, phông trường quay hoặc loại tương tự',
    Unit: '',
  },
  {
    HsCode: '59070010',
    Description: '- Các loại vải dệt được ngâm tẩm, tráng hoặc phủ với dầu hoặc các chế phẩm từ dầu',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59070030',
    Description: '- Các loại vải dệt được ngâm tẩm, tráng hoặc phủ với hóa chất chịu lửa',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59070040',
    Description:
      '- Các loại vải được ngâm tẩm, tráng hoặc phủ với nhung xơ vụn, toàn bộ bề mặt được phủ với xơ vụn dệt',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59070050',
    Description: '- Các loại vải được ngâm tẩm, tráng hoặc phủ lớp sáp, hắc ín, bi-tum hoặc các sản phẩm tương tự',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59070060',
    Description: '- Các loại vải được ngâm tẩm, tráng hoặc phủ bằng các vật liệu khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59070090',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '5908',
    Description:
      'Các loại bấc dệt thoi, kết, tết hoặc dệt kim, dùng cho đèn, bếp dầu, bật lửa, nến hoặc loại tương tự; mạng đèn măng xông và các loại vải dệt kim hình ống dùng làm mạng đèn măng xông, đã hoặc chưa ngâm tẩm',
    Unit: '',
  },
  {
    HsCode: '59080010',
    Description: '- Bấc; mạng đèn măng xông',
    Unit: 'kg/m',
  },
  {
    HsCode: '59080090',
    Description: '- Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '5909',
    Description:
      'Các loại ống dẫn dệt mềm và các loại ống dệt tương tự có lót hoặc không lót, bọc vỏ cứng hoặc có các phụ kiện từ vật liệu khác',
    Unit: '',
  },
  {
    HsCode: '59090010',
    Description: '- Các loại vòi cứu hỏa',
    Unit: 'm/chiếc/kg',
  },
  {
    HsCode: '59090090',
    Description: '- Loại khác',
    Unit: 'm/chiếc/kg',
  },
  {
    HsCode: '59100000',
    Description:
      'Băng tải hoặc băng truyền hoặc băng đai, bằng vật liệu dệt, đã hoặc chưa ngâm tẩm, tráng, phủ hoặc ép bằng plastic, hoặc gia cố bằng kim loại hoặc vật liệu khác',
    Unit: 'm/chiếc/kg',
  },
  {
    HsCode: '5911',
    Description: 'Các sản phẩm và mặt hàng dệt, phục vụ cho mục đích kỹ thuật, đã nêu tại Chú giải 8 của Chương này',
    Unit: '',
  },
  {
    HsCode: '59111000',
    Description:
      '- Vải dệt, phớt và vải dệt thoi lót phớt đã được tráng, phủ, hoặc ép bằng cao su, da thuộc hoặc vật liệu khác, loại sử dụng cho băng kim máy chải, và vải tương tự phục vụ cho mục đích kỹ thuật khác, kể cả vải khổ hẹp làm từ nhung được ngâm tẩm cao su, để bọc các lõi, trục dệt',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59112000',
    Description: '- Vải dùng để rây sàng (bolting cloth), đã hoặc chưa hoàn thiện',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description:
      '- Vải dệt và phớt, được dệt vòng liền hoặc kèm theo cơ cấu nối ráp, dùng cho máy sản xuất giấy hoặc các máy tương tự (ví dụ, để chế biến bột giấy hoặc xi măng amiăng):',
    Unit: '',
  },
  {
    HsCode: '59113100',
    Description: '- - Định lượng dưới 650 g/m2',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59113200',
    Description: '- - Định lượng từ 650 g/m2 trở lên',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59114000',
    Description: '- Vải lọc dùng trong ép dầu hoặc loại tương tự, kể cả loại làm từ tóc người',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '591190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '59119010',
    Description: '- - Miếng đệm và miếng chèn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '59119090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 60',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các loại hàng dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hàng ren, móc thuộc nhóm 58.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các loại nhãn, phù hiệu hoặc các sản phẩm tương tự, dệt kim hoặc móc, thuộc nhóm 58.07; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Vải dệt kim hoặc móc, đã ngâm tẩm, tráng, phủ hoặc ép lớp, thuộc Chương 59. Tuy nhiên, các loại vải nổi vòng dệt kim hoặc móc, được ngâm tẩm, tráng, phủ hoặc ép lớp, vẫn được phân loại vào nhóm 60.01.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Chương này cũng kể cả các loại vải làm từ sợi kim loại và được sử dụng trong trang trí, như vải trang trí nội thất hoặc dùng cho các mục đích tương tự.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Trong toàn bộ Danh mục, bất kỳ sự liên quan nào đến hàng “dệt kim"" kể cả hàng khâu đính trong đó các mũi khâu móc xích đều được tạo thành bằng sợi dệt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Phân nhóm 6005.35 bao gồm vải từ monofilament polyetylen hoặc từ multifilament polyeste, có định lượng từ 30g/m2 đến 55g/m2, có kích cỡ lưới từ 20 lỗ/cm2 đến 100 lỗ/cm2, và được tẩm hoặc phủ alpha-cypermethrin (ISO), chlorfenapyr (ISO), deltamethrin (INN, ISO), lambda-cyhalothrin (ISO), permethrin (ISO) hoặc pirimiphos-methyl (ISO).',
    Unit: '',
  },
  {
    HsCode: '6001',
    Description:
      'Vải nổi vòng (pile), kể cả các loại vải ""vòng lông dài"" (long pile) và khăn lông (terry), dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '60011000',
    Description: '- Vải ""vòng lông dài"" (long pile)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Vải tạo vòng lông (looped pile):',
    Unit: '',
  },
  {
    HsCode: '60012100',
    Description: '- - Từ bông',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60012200',
    Description: '- - Từ xơ nhân tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60012900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '60019100',
    Description: '- - Từ bông',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600192',
    Description: '- - Từ xơ nhân tạo: ',
    Unit: '',
  },
  {
    HsCode: '60019220',
    Description:
      '- - - Vải nổi vòng (pile) từ xơ staple 100% polyeste, có chiều rộng không nhỏ hơn 63,5 mm nhưng không quá 76,2 mm, phù hợp để sử dụng trong sản xuất con lăn sơn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60019230',
    Description: '- - - Chứa sợi đàn hồi hoặc sợi cao su',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60019290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60019900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '6002',
    Description:
      'Vải dệt kim hoặc móc có khổ rộng không quá 30 cm, có hàm lượng sợi đàn hồi hoặc sợi cao su chiếm từ 5% trở lên tính theo khối lượng, trừ loại thuộc nhóm 60.01',
    Unit: '',
  },
  {
    HsCode: '60024000',
    Description: '- Có hàm lượng sợi đàn hồi chiếm từ 5% trở lên tính theo khối lượng nhưng không có sợi cao su ',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60029000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '6003',
    Description: 'Vải dệt kim hoặc móc có khổ rộng không quá 30 cm, trừ loại thuộc nhóm 60.01 hoặc 60.02',
    Unit: '',
  },
  {
    HsCode: '60031000',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60032000',
    Description: '- Từ bông',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60033000',
    Description: '- Từ các xơ tổng hợp',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60034000',
    Description: '- Từ các xơ tái tạo',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60039000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '6004',
    Description:
      'Vải dệt kim hoặc móc có khổ rộng hơn 30 cm, có hàm lượng sợi đàn hồi hoặc sợi cao su chiếm từ 5% trở lên tính theo khối lượng, trừ các loại thuộc nhóm 60.01',
    Unit: '',
  },
  {
    HsCode: '600410',
    Description: '- Có hàm lượng sợi đàn hồi chiếm từ 5% trở lên tính theo khối lượng nhưng không bao gồm sợi cao su: ',
    Unit: '',
  },
  {
    HsCode: '60041010',
    Description: '- - Có hàm lượng sợi đàn hồi chiếm không quá 20% tính theo khối lượng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60041090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60049000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '6005',
    Description:
      'Vải dệt kim đan dọc (kể cả các loại làm trên máy dệt kim dệt dải trang trí), trừ loại thuộc các nhóm 60.01 đến 60.04',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '60052100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60052200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60052300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60052400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ xơ tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '60053500',
    Description: '- - Các loại vải được chi tiết trong Chú giải phân nhóm 1 Chương này',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600536',
    Description: '- - Loại khác, chưa tẩy trắng hoặc đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '60053610',
    Description:
      '- - - Vải dệt kim làm từ polyeste và polybutylen terephthalat trong đó polyeste chiếm tỷ lệ lớn hơn tính theo khối lượng dùng để làm quần áo bơi',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60053690',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600537',
    Description: '- - Loại khác, đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '60053710',
    Description:
      '- - - Vải dệt kim làm từ polyeste và polybutylen terephthalat trong đó polyeste chiếm tỷ lệ lớn hơn tính theo khối lượng dùng để làm quần áo bơi',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60053790',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600538',
    Description: '- - Loại khác, từ các sợi có màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '60053810',
    Description:
      '- - - Vải dệt kim làm từ polyeste và polybutylen terephthalat trong đó polyeste chiếm tỷ lệ lớn hơn tính theo khối lượng dùng để làm quần áo bơi',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60053890',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600539',
    Description: '- - Loại khác, đã in:',
    Unit: '',
  },
  {
    HsCode: '60053910',
    Description:
      '- - - Vải dệt kim làm từ polyeste và polybutylen terephthalat trong đó polyeste chiếm tỷ lệ lớn hơn tính theo khối lượng dùng để làm quần áo bơi',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60053990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ xơ tái tạo:',
    Unit: '',
  },
  {
    HsCode: '60054100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60054200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60054300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60054400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '60059010',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60059090',
    Description: '- - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '6006',
    Description: 'Vải dệt kim hoặc móc khác',
    Unit: '',
  },
  {
    HsCode: '60061000',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '60062100',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60062200',
    Description: '- - Đã nhuộm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60062300',
    Description: '- - Từ các sợi có các màu khác nhau',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60062400',
    Description: '- - Đã in',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ xơ sợi tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '600631',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '60063110',
    Description: '- - - Tấm lưới từ sợi ni lông dùng làm vật liệu bồi cho các tấm ghép khảm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60063120',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60063190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600632',
    Description: '- - Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '60063210',
    Description: '- - - Tấm lưới từ sợi ni lông dùng làm vật liệu bồi cho các tấm ghép khảm',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60063220',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60063290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600633',
    Description: '- - Từ các sợi có các màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '60063310',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60063390',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600634',
    Description: '- - Đã in:',
    Unit: '',
  },
  {
    HsCode: '60063410',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60063490',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: '- Từ xơ tái tạo:',
    Unit: '',
  },
  {
    HsCode: '600641',
    Description: '- - Chưa tẩy trắng hoặc đã tẩy trắng:',
    Unit: '',
  },
  {
    HsCode: '60064110',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60064190',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600642',
    Description: '- - Đã nhuộm:',
    Unit: '',
  },
  {
    HsCode: '60064210',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60064290',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600643',
    Description: '- - Từ các sợi có các màu khác nhau:',
    Unit: '',
  },
  {
    HsCode: '60064310',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60064390',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '600644',
    Description: '- - Đã in:',
    Unit: '',
  },
  {
    HsCode: '60064410',
    Description: '- - - Đàn hồi (kết hợp với sợi cao su)',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60064490',
    Description: '- - - Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '60069000',
    Description: '- Loại khác',
    Unit: 'kg/m/m2',
  },
  {
    HsCode: '',
    Description: 'Chương 61',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quần áo và hàng may mặc phụ trợ, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này chỉ áp dụng với mặt hàng may mặc sẵn được dệt kim hoặc móc.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Những mặt hàng thuộc nhóm 62.12;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Quần áo hoặc các sản phẩm may mặc đã qua sử dụng khác thuộc nhóm 63.09; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Dụng cụ chỉnh hình, đai thắt phẫu thuật, băng giữ hoặc loại tương tự (nhóm 90.21).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Theo mục đích của các nhóm 61.03 và 61.04:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Khái niệm ""bộ com-lê"" có nghĩa là một bộ quần áo có hai hoặc ba chiếc may sẵn, có lớp ngoài may bằng cùng một loại vải và bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một áo vét hoặc jacket có lớp ngoài, trừ tay áo, gồm từ 4 mảnh trở lên, được thiết kế để che phần trên của cơ thể, có thể kèm theo một áo gilê có hai thân trước may bằng loại vải giống hệt như lớp ngoài của những chiếc khác trong cùng bộ và thân sau may bằng loại vải giống hệt như lớp vải lót của áo vét hoặc jacket; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một bộ trang phục được thiết kế để che phần dưới của cơ thể và bao gồm một quần dài, quần ống chẽn hoặc quần soóc (trừ quần bơi), chân váy hoặc chân váy dạng quần, không có yếm cũng như dây đeo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tất cả các bộ phận cấu thành của một ""bộ com-lê"" phải may bằng cùng một loại vải, cùng màu sắc và thành phần nguyên liệu; chúng cũng phải có cùng kiểu dáng và có kích cỡ tương ứng hoặc phù hợp với nhau. Tuy nhiên, các bộ phận này có thể có các dải viền (dải bằng vải khâu vào đường nối) bằng loại vải khác. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nếu một vài thành phần riêng biệt để che phần dưới của cơ thể được trình bày cùng nhau (ví dụ, hai quần dài hoặc quần dài kèm quần soóc, hoặc chân váy hoặc chân váy dạng quần kèm quần dài), thì bộ phận cấu thành của phần dưới là một quần dài hoặc, đối với trường hợp bộ com-lê của phụ nữ hoặc trẻ em gái, là chân váy hoặc chân váy dạng quần, các hàng may mặc khác được xem xét một cách riêng rẽ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Khái niệm ""bộ com-lê"" kể cả những bộ quần áo dưới đây, dù có hoặc không có đủ các điều kiện nêu trên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- bộ lễ phục ban ngày, gồm một áo jacket trơn (áo khoác dài) có vạt sau tròn trễ xuống và một quần sọc;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- bộ lễ phục buổi tối (áo đuôi tôm) thường bằng vải màu đen, áo jacket tương đối ngắn ở phía trước, không cài khuy, vạt áo hẹp được cắt đến ngang hông và trễ xuống ở phía sau;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- bộ jacket dạ tiệc, trong đó có một jacket giống kiểu jacket thông thường (mặc dù loại áo này có thể để lộ mặt trước áo sơ mi nhiều hơn), nhưng có ve áo làm bằng lụa bóng tơ tằm hoặc giả tơ tằm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Khái niệm ""bộ quần áo đồng bộ"" có nghĩa là bộ quần áo (trừ bộ com-lê và quần áo thuộc nhóm 61.07, 61.08 hoặc 61.09), gồm một số chiếc được may sẵn bằng cùng một loại vải, được xếp bộ để bán lẻ, và bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một áo được thiết kế để che phần trên của cơ thể, trừ áo chui đầu là chiếc áo thứ hai mặc trong của bộ áo kép, và một áo gilê cũng tạo thành một chiếc áo thứ hai, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một hoặc hai loại trang phục khác nhau, được thiết kế để che phần dưới của cơ thể và gồm có quần dài, quần yếm có dây đeo, quần ống chẽn, quần soóc (trừ đồ bơi), chân váy hoặc chân váy dạng quần.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tất cả các bộ phận của một bộ quần áo đồng bộ phải được may bằng cùng một loại vải, cùng kiểu cách, màu sắc và thành phần nguyên liệu; chúng phải có kích cỡ tương ứng hoặc phù hợp với nhau. Khái niệm ""bộ quần áo đồng bộ"" không bao gồm bộ quần áo thể thao hoặc bộ quần áo trượt tuyết, thuộc nhóm 61.12.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Các nhóm 61.05 và 61.06 không bao gồm các loại áo có túi ở phía dưới thắt lưng, có dây thắt lưng kẻ gân nổi hoặc có dây, đai thắt ở gấu áo, hoặc loại áo có bình quân dưới 10 mũi khâu/1 cm dài theo mỗi chiều tính trên một đơn vị diện tích ít nhất là 10 cm x 10 cm. Nhóm 61.05 không bao gồm áo không tay.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '“Sơ mi” và “sơ mi cách điệu” là áo được thiết kế để che phần trên của cơ thể, có tay áo dài hoặc ngắn và có thiết kế để mở toàn bộ hoặc một phần áo từ cổ áo. “Áo blouse” là loại áo rộng cũng được thiết kế để che phần trên của cơ thể nhưng có thể không có tay áo và có hoặc không có phần mở cổ áo. “Sơ mi”, “sơ mi cách điệu” và “áo blouse” cũng có thể có cổ áo. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '5. Nhóm 61.09 không bao gồm áo có dây rút, dây thắt lưng kẻ gân nổi hoặc dây đai khác thắt ở gấu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '6. Theo mục đích của nhóm 61.11:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Khái niệm ""quần áo và phụ kiện may mặc dùng cho trẻ em"" chỉ các sản phẩm dùng cho trẻ có chiều cao không quá 86 cm; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Những mặt hàng mà, thoạt nhìn, có thể vừa xếp vào nhóm 61.11, vừa xếp được vào các nhóm khác của Chương này phải được xếp vào nhóm 61.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Theo mục đích của nhóm 61.12, “bộ quần áo trượt tuyết"" có nghĩa là quần, áo hoặc bộ quần áo mà, theo hình thức và chất vải, chúng có thể nhận biết được qua mục đích sử dụng chủ yếu là mặc khi trượt tuyết (trượt tuyết băng đồng hoặc trượt tuyết đổ dốc). Gồm có:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) một ""bộ đồ trượt tuyết liền quần"", là một bộ đồ liền được thiết kế để che phần trên và dưới của cơ thể; bộ đồ trượt tuyết liền quần có thể có túi hoặc dây đai chân ngoài tay áo và cổ áo; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) một ""bộ đồ trượt tuyết đồng bộ"", là bộ quần áo gồm hai hoặc ba chiếc, được xếp bộ để bán lẻ và bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một áo như kiểu áo khoác có mũ trùm, áo chắn gió, áo jacket chắn gió hoặc loại tương tự, được cài bằng khóa rút (khóa kéo), có thể kèm thêm một áo gilê, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một quần có cạp cao hơn hoặc không cao quá thắt lưng, một quần ống chẽn hoặc một quần yếm và quần có dây đeo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '""Bộ đồ trượt tuyết đồng bộ"" có thể cũng bao gồm một áo liền quần tương tự như loại áo đã nêu ở mục (a) trên và một kiểu áo jacket có lót đệm, không có ống tay mặc ở ngoài bộ áo liền quần.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tất cả các bộ phận của một ""bộ đồ trượt tuyết đồng bộ"" phải được may bằng cùng loại vải, kiểu dáng và thành phần nguyên liệu dù đồng màu hoặc khác màu; chúng cũng phải có kích cỡ tương ứng hoặc phù hợp với nhau.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Loại quần áo mà, thoạt nhìn, có thể vừa xếp được vào nhóm 61.13 và vừa xếp được vào các nhóm khác của Chương này, trừ nhóm 61.11, thì được xếp vào nhóm 61.13.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Quần áo thuộc Chương này được thiết kế để cài thân trước từ trái qua phải được coi là quần áo nam giới hoặc trẻ em trai, và quần áo được thiết kế để cài thân trước từ phải qua trái được coi là quần áo phụ nữ hoặc trẻ em gái. Những quy định này không áp dụng cho những loại quần áo mà cách cắt may của chúng thể hiện rõ ràng là dùng cho nam hoặc nữ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Quần áo không thể nhận biết được là quần áo nam hoặc nữ thì được xếp vào nhóm quần áo phụ nữ hoặc trẻ em gái.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '10. Các mặt hàng của Chương này có thể được làm bằng sợi kim loại.',
    Unit: '',
  },
  {
    HsCode: '6101',
    Description:
      'Áo khoác dài, áo khoác mặc khi đi xe (car-coat), áo khoác không tay, áo choàng không tay, áo khoác có mũ (kể cả áo jacket trượt tuyết), áo gió, áo jacket chống gió và các loại tương tự, dùng cho nam giới hoặc trẻ em trai, dệt kim hoặc móc, trừ các loại thuộc nhóm 61.03',
    Unit: '',
  },
  {
    HsCode: '61012000',
    Description: '- Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61013000',
    Description: '- Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61019000',
    Description: '- Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6102',
    Description:
      'Áo khoác dài, áo khoác mặc khi đi xe (car-coat), áo khoác không tay, áo choàng không tay, áo khoác có mũ trùm (kể cả áo jacket trượt tuyết), áo gió, áo jacket chống gió và các loại tương tự, dùng cho phụ nữ hoặc trẻ em gái, dệt kim hoặc móc, trừ các loại thuộc nhóm 61.04',
    Unit: '',
  },
  {
    HsCode: '61021000',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61022000',
    Description: '- Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61023000',
    Description: '- Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61029000',
    Description: '- Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6103',
    Description:
      'Bộ com-lê, bộ quần áo đồng bộ, áo jacket, áo blazer, quần dài, quần yếm có dây đeo, quần ống chẽn và quần soóc (trừ quần áo bơi), dùng cho nam giới hoặc trẻ em trai, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '61031000',
    Description: '- Bộ com-lê',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Bộ quần áo đồng bộ:',
    Unit: '',
  },
  {
    HsCode: '61032200',
    Description: '- - Từ bông',
    Unit: 'bộ',
  },
  {
    HsCode: '61032300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '61032900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Áo jacket và áo blazer:',
    Unit: '',
  },
  {
    HsCode: '61033100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61033200',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61033300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '610339',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61033910',
    Description: '- - - Từ ramie, sợi lanh hoặc tơ tằm',
    Unit: 'chiếc',
  },
  {
    HsCode: '61033990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Quần dài, quần yếm có dây đeo, quần ống chẽn và quần soóc:',
    Unit: '',
  },
  {
    HsCode: '61034100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61034200',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61034300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '61034900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6104',
    Description:
      'Bộ com-lê, bộ quần áo đồng bộ, áo jacket, áo blazer, váy liền thân (1), chân váy (skirt), chân váy dạng quần, quần dài, quần yếm có dây đeo, quần ống chẽn và quần soóc (trừ quần áo bơi), dùng cho phụ nữ hoặc trẻ em gái, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bộ com-lê:',
    Unit: '',
  },
  {
    HsCode: '61041300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '610419',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61041920',
    Description: '- - - Từ bông',
    Unit: 'bộ',
  },
  {
    HsCode: '61041990',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Bộ quần áo đồng bộ:',
    Unit: '',
  },
  {
    HsCode: '61042200',
    Description: '- - Từ bông',
    Unit: 'bộ',
  },
  {
    HsCode: '61042300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '61042900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Áo jacket và áo blazer:',
    Unit: '',
  },
  {
    HsCode: '61043100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61043200',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61043300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '61043900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Váy liền thân (1):',
    Unit: '',
  },
  {
    HsCode: '61044100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61044200',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61044300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '61044400',
    Description: '- - Từ sợi tái tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61044900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các loại chân váy (skirt) và chân váy dạng quần:',
    Unit: '',
  },
  {
    HsCode: '61045100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61045200',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61045300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '61045900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Quần dài, quần yếm có dây đeo, quần ống chẽn và quần soóc:',
    Unit: '',
  },
  {
    HsCode: '61046100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61046200',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61046300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '61046900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6105',
    Description: 'Áo sơ mi nam giới hoặc trẻ em trai, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '61051000',
    Description: '- Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '610520',
    Description: '- Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '61052010',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '61052020',
    Description: '- - Từ sợi tái tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61059000',
    Description: '- Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6106',
    Description:
      'Áo blouse, sơ mi và sơ mi cách điệu (shirt-blouse), dùng cho phụ nữ hoặc trẻ em gái, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '61061000',
    Description: '- Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61062000',
    Description: '- Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61069000',
    Description: '- Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6107',
    Description:
      'Quần lót (underpants), quần sịp, áo ngủ, bộ pyjama, áo choàng tắm, áo choàng dài mặc trong nhà và các loại tương tự, dùng cho nam giới hoặc trẻ em trai, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Quần lót (underpants) và quần sịp:',
    Unit: '',
  },
  {
    HsCode: '61071100',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61071200',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61071900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các loại áo ngủ và bộ pyjama:',
    Unit: '',
  },
  {
    HsCode: '61072100',
    Description: '- - Từ bông',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '61072200',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '61072900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '61079100',
    Description: '- - Từ bông',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '61079900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '6108',
    Description:
      'Váy lót, váy lót bồng (petticoats), quần xi líp, quần đùi bó, váy ngủ, bộ pyjama, áo ngủ của phụ nữ bằng vải mỏng và trong suốt (negligees), áo choàng tắm, áo choàng dài mặc trong nhà và các loại tương tự, dùng cho phụ nữ hoặc trẻ em gái, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Váy lót và váy lót bồng (petticoats):',
    Unit: '',
  },
  {
    HsCode: '61081100',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '610819',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61081920',
    Description: '- - - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '61081930',
    Description: '- - - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61081940',
    Description: '- - - Từ tơ tằm',
    Unit: 'chiếc',
  },
  {
    HsCode: '61081990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Quần xi líp và quần đùi bó:',
    Unit: '',
  },
  {
    HsCode: '61082100',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61082200',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61082900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Váy ngủ và bộ pyjama:',
    Unit: '',
  },
  {
    HsCode: '61083100',
    Description: '- - Từ bông',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '61083200',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '61083900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '61089100',
    Description: '- - Từ bông',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '61089200',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '61089900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '6109',
    Description: 'Áo phông, áo ba lỗ (singlet) và các loại áo lót khác, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '610910',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '61091010',
    Description: '- - Dùng cho nam giới hoặc trẻ em trai',
    Unit: 'chiếc',
  },
  {
    HsCode: '61091020',
    Description: '- - Dùng cho phụ nữ hoặc trẻ em gái',
    Unit: 'chiếc',
  },
  {
    HsCode: '610990',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61099010',
    Description: '- - Dùng cho nam giới hoặc trẻ em trai, từ ramie, lanh hoặc tơ tằm',
    Unit: 'chiếc',
  },
  {
    HsCode: '61099020',
    Description: '- - Dùng cho nam giới hoặc trẻ em trai, từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '61099030',
    Description: '- - Dùng cho phụ nữ hoặc trẻ em gái',
    Unit: 'chiếc',
  },
  {
    HsCode: '6110',
    Description: 'Áo bó, áo chui đầu, áo cardigan, gi-lê và các mặt hàng tương tự, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '61101100',
    Description: '- - Từ lông cừu',
    Unit: 'chiếc',
  },
  {
    HsCode: '61101200',
    Description: '- - Của dê Ca-sơ-mia (len casơmia)',
    Unit: 'chiếc',
  },
  {
    HsCode: '61101900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '61102000',
    Description: '- Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '61103000',
    Description: '- Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '61109000',
    Description: '- Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6111',
    Description: 'Quần áo và hàng may mặc phụ trợ dùng cho trẻ em, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '61112000',
    Description: '- Từ bông',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61113000',
    Description: '- Từ sợi tổng hợp',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '611190',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61119010',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61119090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6112',
    Description: 'Bộ quần áo thể thao, bộ quần áo trượt tuyết và bộ quần áo bơi, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bộ quần áo thể thao:',
    Unit: '',
  },
  {
    HsCode: '61121100',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61121200',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61121900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61122000',
    Description: '- Bộ quần áo trượt tuyết',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Quần áo bơi cho nam giới hoặc trẻ em trai:',
    Unit: '',
  },
  {
    HsCode: '61123100',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61123900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Quần áo bơi cho phụ nữ hoặc trẻ em gái:',
    Unit: '',
  },
  {
    HsCode: '611241',
    Description: '- - Từ sợi tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '61124110',
    Description: '- - - Đồ bơi độn ngực (dùng cho người sau phẫu thuật ngực)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61124190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '611249',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61124910',
    Description: '- - - Đồ bơi độn ngực (dùng cho người sau phẫu thuật ngực)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61124990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6113',
    Description: 'Quần áo được may từ các loại vải dệt kim hoặc móc thuộc nhóm 59.03, 59.06 hoặc 59.07',
    Unit: '',
  },
  {
    HsCode: '61130010',
    Description: '- Bộ đồ của thợ lặn (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61130030',
    Description: '- Quần áo chống cháy (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61130040',
    Description: '- Quần áo bảo hộ khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61130090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6114',
    Description: 'Các loại quần áo khác, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '61142000',
    Description: '- Từ bông',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '611430',
    Description: '- Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '61143020',
    Description: '- - Quần áo chống cháy (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61143090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '611490',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61149010',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '61149090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6115',
    Description:
      'Quần tất, quần nịt, bít tất dài (trên đầu gối), bít tất ngắn và các loại hàng bít tất dệt kim khác, kể cả nịt chân (ví dụ, dùng cho người giãn tĩnh mạch) và giày, dép không đế, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '611510',
    Description: '- Nịt chân (ví dụ, dùng cho người giãn tĩnh mạch):',
    Unit: '',
  },
  {
    HsCode: '61151010',
    Description: '- - Vớ cho người giãn tĩnh mạch, từ sợi tổng hợp',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61151090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '',
    Description: '- Quần tất và quần nịt khác:',
    Unit: '',
  },
  {
    HsCode: '61152100',
    Description: '- - Từ sợi tổng hợp, có độ mảnh sợi đơn dưới 67 decitex',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61152200',
    Description: '- - Từ sợi tổng hợp, có độ mảnh sợi đơn từ 67 decitex trở lên',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '611529',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '61152910',
    Description: '- - - Từ bông',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61152990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '611530',
    Description: '- Tất dài đến đầu gối hoặc trên đầu gối dùng cho phụ nữ, có độ mảnh mỗi sợi đơn dưới 67 decitex:',
    Unit: '',
  },
  {
    HsCode: '61153010',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61153090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '61159400',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61159500',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61159600',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61159900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '6116',
    Description: 'Găng tay, găng tay hở ngón và găng bao tay, dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '611610',
    Description: '- Được ngâm tẩm, tráng hoặc phủ hoặc ép với plastic hoặc cao su: ',
    Unit: '',
  },
  {
    HsCode: '61161010',
    Description: '- - Găng tay của thợ lặn',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61161090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '61169100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61169200',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61169300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '61169900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '6117',
    Description:
      'Hàng phụ kiện may mặc đã hoàn chỉnh khác, dệt kim hoặc móc; các chi tiết dệt kim hoặc móc của quần áo hoặc của hàng may mặc phụ trợ',
    Unit: '',
  },
  {
    HsCode: '611710',
    Description:
      '- Khăn choàng, khăn quàng cổ, khăn choàng rộng đội đầu, khăn choàng vai, mạng che mặt và các loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '61171010',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '61171090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '611780',
    Description: '- Các phụ kiện may mặc khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Cà vạt, nơ con bướm và cravat:',
    Unit: '',
  },
  {
    HsCode: '61178011',
    Description: '- - - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '61178019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '61178020',
    Description: '- - Băng cổ tay, băng đầu gối, băng mắt cá chân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '61178090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '61179000',
    Description: '- Các chi tiết',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 2108:1977 ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 62',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Quần áo và các hàng may mặc phụ trợ, không dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Chương này chỉ áp dụng đối với những mặt hàng may sẵn bằng vải dệt bất kỳ trừ mền xơ, không bao gồm các sản phẩm dệt kim hoặc móc (trừ các mặt hàng thuộc nhóm 62.12).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Quần áo hoặc hàng may mặc đã qua sử dụng khác thuộc nhóm 63.09; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dụng cụ chỉnh hình, đai thắt phẫu thuật, băng giữ hoặc loại tương tự (nhóm 90.21).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Theo mục đích của các nhóm 62.03 và 62.04:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Khái niệm ""bộ com-lê"" có nghĩa là một bộ quần áo có hai hoặc ba chiếc may sẵn, có lớp ngoài may bằng cùng một loại vải và bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một áo vét hoặc jacket có lớp ngoài, trừ tay áo, gồm từ 4 mảnh trở lên, được thiết kế để che phần trên của cơ thể, có thể kèm theo một áo gilê có thân trước được may bằng loại vải giống hệt như lớp ngoài của những chiếc khác trong cùng bộ và thân sau may bằng loại vải giống hệt như lớp vải lót của áo vét hoặc jacket; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một bộ trang phục được thiết kế để che phần dưới của cơ thể và bao gồm quần dài, quần ống chẽn hoặc quần soóc (trừ quần bơi), chân váy hoặc chân váy dạng quần, không có yếm cũng như dây đeo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tất cả các bộ phận cấu thành của một ""bộ com-lê"" phải may bằng cùng một loại vải, cùng màu sắc và thành phần nguyên liệu; chúng cũng phải có cùng kiểu dáng và có kích cỡ tương ứng hoặc phù hợp với nhau. Tuy nhiên, các bộ phận này có thể có các dải viền (dải bằng vải khâu vào đường nối) bằng một loại vải khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nếu một vài thành phần riêng biệt để che phần dưới của cơ thể được trình bày cùng nhau (ví dụ, hai quần dài hoặc quần dài kèm quần soóc, hoặc chân váy hoặc chân váy dạng quần kèm quần dài), thì bộ phận cấu thành của phần dưới là một quần dài hoặc, đối với trường hợp bộ com-lê của phụ nữ hoặc trẻ em gái, thì phải là chân váy hoặc chân váy dạng quần, các hàng may mặc khác được xem xét một cách riêng rẽ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Khái niệm ""bộ com-lê"" kể cả những bộ quần áo dưới đây, dù có hoặc không có đủ các điều kiện nêu trên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- bộ lễ phục ban ngày, gồm một áo jacket trơn (áo khoác dài) có vạt sau tròn trễ xuống và một quần sọc;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- bộ lễ phục buổi tối (áo đuôi tôm) thường bằng vải màu đen, áo jacket tương đối ngắn ở phía trước, không cài khuy, vạt áo hẹp được cắt đến ngang hông và trễ xuống ở phía sau;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- bộ jacket dạ tiệc, trong đó có một jacket giống kiểu jacket thông thường (mặc dù loại áo này có thể để lộ mặt trước áo sơ mi nhiều hơn), nhưng có ve áo làm bằng lụa bóng tơ tằm hoặc giả tơ tằm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Thuật ngữ ""bộ quần áo đồng bộ"" có nghĩa là bộ quần áo (trừ bộ com-lê và quần áo thuộc nhóm 62.07 hoặc 62.08) gồm một số chiếc được may sẵn bằng cùng một loại vải, được xếp bộ để bán lẻ, và bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một áo được thiết kế để che phần trên của cơ thể, trừ áo gilê cũng có thể tạo thành chiếc áo thứ hai, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một hoặc hai loại trang phục khác nhau, được thiết kế để che phần dưới của cơ thể và gồm có quần dài, quần yếm có dây đeo, quần ống chẽn, quần soóc (trừ đồ bơi), chân váy hoặc chân váy dạng quần.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tất cả các bộ phận của một bộ quần áo đồng bộ phải có cùng một loại vải, cùng kiểu dáng, màu sắc và thành phần nguyên liệu; chúng cũng phải có kích cỡ tương ứng hoặc phù hợp với nhau. Khái niệm “bộ quần áo đồng bộ” không áp dụng cho bộ đồ thể thao hoặc bộ đồ trượt tuyết, thuộc nhóm 62.11.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Các nhóm 62.05 và 62.06 không bao gồm các loại áo có túi ở phía dưới thắt lưng, có dây thắt lưng kẻ gân nổi hoặc có dây, đai thắt ở gấu áo. Nhóm 62.05 không bao gồm áo không tay.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '“Sơ mi” và “sơ mi cách điệu” là áo được thiết kế để che phần trên của cơ thể, có tay áo dài hoặc ngắn và có thiết kế để mở toàn bộ hoặc một phần áo từ cổ áo. “Áo blouse” là loại áo rộng cũng được thiết kế để che phần trên của cơ thể nhưng có thể không có tay áo và có hoặc không có phần mở cổ áo. “Sơ mi”, “sơ mi cách điệu” và “áo blouse” cũng có thể có cổ áo. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '5. Theo mục đích của nhóm 62.09:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Khái niệm ""quần áo và phụ kiện may mặc dùng cho trẻ em"" chỉ các sản phẩm dùng cho trẻ có chiều cao không quá 86 cm; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Những hàng hóa mà, thoạt nhìn, có thể vừa xếp được vào nhóm 62.09 và vừa có thể xếp được vào các nhóm khác của Chương này phải được xếp vào nhóm 62.09.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Các mặt hàng mà, thoạt nhìn, có thể vừa xếp được vào nhóm 62.10 và vừa có thể xếp vào các nhóm khác của Chương này, trừ nhóm 62.09, phải được xếp vào nhóm 62.10.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Theo mục đích của nhóm 62.11, ""bộ quần áo trượt tuyết"" có nghĩa là quần, áo hoặc bộ quần áo, mà xét theo hình thức và chất vải, chúng được sử dụng chủ yếu để mặc cho trượt tuyết (trượt tuyết băng đồng hoặc trượt tuyết đổ dốc). Gồm có:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) một ""bộ đồ trượt tuyết liền quần"", là một bộ đồ liền được thiết kế để che phần trên và dưới của cơ thể; bộ đồ trượt tuyết liền quần có thể có túi hoặc dây đai chân ngoài tay áo và cổ áo; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) một ""bộ đồ trượt tuyết đồng bộ"", là bộ quần áo gồm hai hoặc ba chiếc, được xếp bộ để bán lẻ và bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một áo như kiểu áo khoác có mũ trùm, áo chắn gió, áo jacket chắn gió hoặc loại tương tự, được cài bằng khóa rút (khóa kéo), có thể kèm thêm một áo gilê, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- một quần có cạp cao hơn hoặc không cao quá thắt lưng, một quần ống chẽn hoặc một quần yếm và quần có dây đeo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '""Bộ đồ trượt tuyết đồng bộ"" có thể cũng bao gồm một áo liền quần tương tự như loại áo đã nêu ở mục (a) trên và một kiểu áo jacket có lót đệm, không có ống tay mặc ở ngoài bộ áo liền quần.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tất cả các bộ phận của một ""bộ đồ trượt tuyết đồng bộ"" phải được may bằng cùng loại vải, kiểu dáng và thành phần nguyên liệu dù đồng màu hoặc khác màu; chúng cũng phải có kích cỡ tương ứng hoặc phù hợp với nhau.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Khăn choàng và các mặt hàng thuộc dạng khăn quàng, vuông hoặc gần như vuông, không có cạnh nào trên 60 cm, phải được phân loại như khăn tay (nhóm 62.13). Khăn tay có cạnh trên 60 cm phải được xếp vào nhóm 62.14.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Quần áo của Chương này được thiết kế để cài thân trước từ trái qua phải sẽ được coi là cho nam giới hoặc trẻ em trai, và áo được thiết kế để cài thân trước từ phải qua trái được coi là quần áo dùng cho phụ nữ hoặc trẻ em gái. Quy định này không áp dụng cho những quần áo mà cách cắt may của chúng thể hiện rõ ràng là dùng cho nam hoặc nữ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Quần áo không thể nhận biết được là quần áo nam hoặc nữ thì được xếp vào nhóm quần áo phụ nữ hoặc trẻ em gái.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '10. Các mặt hàng của Chương này có thể được làm bằng sợi kim loại.',
    Unit: '',
  },
  {
    HsCode: '6201',
    Description:
      'Áo khoác ngoài, áo choàng mặc khi đi xe (car-coats), áo khoác không tay, áo choàng không tay, áo khoác có mũ trùm (kể cả áo jacket trượt tuyết), áo gió, áo jacket chống gió và các loại tương tự, dùng cho nam giới hoặc trẻ em trai, trừ các loại thuộc nhóm 62.03',
    Unit: '',
  },
  {
    HsCode: '620120',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '62012010',
    Description:
      '- - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62012090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620130',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62013010',
    Description:
      '- - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62013090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620140',
    Description: '- Từ sợi nhân tạo: ',
    Unit: '',
  },
  {
    HsCode: '62014010',
    Description:
      '- - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62014090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620190',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62019011',
    Description:
      '- - - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62019019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Từ ramie:',
    Unit: '',
  },
  {
    HsCode: '62019021',
    Description:
      '- - - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62019029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62019090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6202',
    Description:
      'Áo khoác ngoài, áo choàng mặc khi đi xe (car-coats), áo khoác không tay, áo choàng không tay, áo khoác có mũ (kể cả áo jacket trượt tuyết), áo gió, áo jacket chống gió và các loại tương tự, dùng cho phụ nữ hoặc trẻ em gái, trừ loại thuộc nhóm 62.04',
    Unit: '',
  },
  {
    HsCode: '620220',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn:',
    Unit: '',
  },
  {
    HsCode: '62022010',
    Description:
      '- - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62022090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620230',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62023010',
    Description:
      '- - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62023090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620240',
    Description: '- Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '62024010',
    Description:
      '- - Áo khoác ngoài, áo mưa, áo choàng mặc khi đi xe, áo choàng không tay, áo khoác không tay, áo choàng ponchos, áo khoác dạ trùm qua hông, áo khoác dạ dáng dài, áo khoác có mũ trùm đầu, áo khoác kiểu duffel, áo khoác mỏng (trench coats), áo khoác bằng vải gabardines và áo khoác ghi lê có đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62024090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620290',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62029010',
    Description: '- - Từ tơ tằm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62029020',
    Description: '- - Từ ramie',
    Unit: 'chiếc',
  },
  {
    HsCode: '62029090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6203',
    Description:
      'Bộ com-lê, bộ quần áo đồng bộ, áo jacket, áo blazer, quần dài, quần yếm có dây đeo, quần ống chẽn và quần soóc (trừ quần áo bơi), dùng cho nam giới hoặc trẻ em trai',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bộ com-lê:',
    Unit: '',
  },
  {
    HsCode: '62031100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'bộ',
  },
  {
    HsCode: '62031200',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '620319',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62031911',
    Description: '- - - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'bộ',
  },
  {
    HsCode: '62031919',
    Description: '- - - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- - - Từ tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62031921',
    Description: '- - - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'bộ',
  },
  {
    HsCode: '62031929',
    Description: '- - - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '62031990',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Bộ quần áo đồng bộ:',
    Unit: '',
  },
  {
    HsCode: '620322',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62032210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'bộ',
  },
  {
    HsCode: '62032290',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '62032300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '620329',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62032910',
    Description: '- - - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'bộ',
  },
  {
    HsCode: '62032990',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Áo jacket và áo blazer:',
    Unit: '',
  },
  {
    HsCode: '62033100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '620332',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62033210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62033290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62033300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '62033900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Quần dài, quần yếm có dây đeo, quần ống chẽn và quần soóc:',
    Unit: '',
  },
  {
    HsCode: '62034100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '620342',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62034210',
    Description: '- - - Quần yếm có dây đeo',
    Unit: 'chiếc',
  },
  {
    HsCode: '62034290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62034300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '620349',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62034910',
    Description: '- - - Từ tơ tằm',
    Unit: 'chiếc',
  },
  {
    HsCode: '62034990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6204',
    Description:
      'Bộ com-lê, bộ quần áo đồng bộ, áo jacket, áo blazer, váy liền thân (1), chân váy (skirt), chân váy dạng quần, quần yếm có dây đeo, quần ống chẽn và quần soóc (trừ quần áo bơi) dùng cho phụ nữ hoặc trẻ em gái',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bộ com-lê:',
    Unit: '',
  },
  {
    HsCode: '62041100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'bộ',
  },
  {
    HsCode: '620412',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62041210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'bộ',
  },
  {
    HsCode: '62041290',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '62041300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '620419',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Từ tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62041911',
    Description: '- - - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'bộ',
  },
  {
    HsCode: '62041919',
    Description: '- - - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '62041990',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Bộ quần áo đồng bộ:',
    Unit: '',
  },
  {
    HsCode: '62042100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'bộ',
  },
  {
    HsCode: '620422',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62042210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'bộ',
  },
  {
    HsCode: '62042290',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '62042300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '620429',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62042910',
    Description: '- - - Từ tơ tằm',
    Unit: 'bộ',
  },
  {
    HsCode: '62042990',
    Description: '- - - Loại khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Áo jacket và áo blazer:',
    Unit: '',
  },
  {
    HsCode: '62043100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '620432',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62043210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62043290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62043300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '620439',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Từ tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62043911',
    Description: '- - - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62043919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62043990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Váy liền thân (1):',
    Unit: '',
  },
  {
    HsCode: '62044100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '620442',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62044210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62044290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62044300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '62044400',
    Description: '- - Từ sợi tái tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '620449',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62044910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62044990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các loại chân váy (skirt) và chân váy dạng quần:',
    Unit: '',
  },
  {
    HsCode: '62045100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '620452',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62045210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62045290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62045300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '620459',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62045910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62045990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Quần dài, quần yếm có dây đeo, quần ống chẽn và quần soóc:',
    Unit: '',
  },
  {
    HsCode: '62046100',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '62046200',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '62046300',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '62046900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6205',
    Description: 'Áo sơ mi nam giới hoặc trẻ em trai',
    Unit: '',
  },
  {
    HsCode: '620520',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62052010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62052020',
    Description: '- - Áo Barong Tagalog (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62052090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620530',
    Description: '- Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '62053010',
    Description: '- - Áo Barong Tagalog (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62053090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '620590',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62059010',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '62059091',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62059092',
    Description: '- - - Áo Barong Tagalog (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62059099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6206',
    Description: 'Áo blouse, sơ mi và sơ mi cách điệu (shirt-blouses) dùng cho phụ nữ hoặc trẻ em gái',
    Unit: '',
  },
  {
    HsCode: '620610',
    Description: '- Từ tơ tằm hoặc phế liệu tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62061010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62061090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62062000',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '620630',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62063010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62063090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62064000',
    Description: '- Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '62069000',
    Description: '- Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6207',
    Description:
      'Áo ba lỗ (singlet) và các loại áo lót khác, quần lót (underpants), quần sịp, áo ngủ, bộ pyjama, áo choàng tắm, áo choàng dài mặc trong nhà và các loại tương tự dùng cho nam giới hoặc trẻ em trai',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Quần lót (underpants) và quần sịp:',
    Unit: '',
  },
  {
    HsCode: '62071100',
    Description: '- - Từ bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '62071900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các loại áo ngủ và bộ pyjama:',
    Unit: '',
  },
  {
    HsCode: '620721',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62072110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62072190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62072200',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '620729',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62072910',
    Description: '- - - Từ tơ tằm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62072990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '62079100',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '620799',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62079910',
    Description: '- - - Từ sợi nhân tạo',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62079990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6208',
    Description:
      'Áo ba lỗ (singlet) và các loại áo lót khác, váy lót, váy lót bồng (petticoats), quần xi líp, quần đùi bó, váy ngủ, bộ pyjama, áo ngủ của phụ nữ bằng vải mỏng và trong suốt (negligees), áo choàng tắm, áo choàng dài mặc trong nhà và các loại tương tự dùng cho phụ nữ hoặc trẻ em gái',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Váy lót và váy lót bồng (petticoats):',
    Unit: '',
  },
  {
    HsCode: '62081100',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '62081900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Váy ngủ và bộ pyjama:',
    Unit: '',
  },
  {
    HsCode: '620821',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62082110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62082190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62082200',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '620829',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62082910',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62082990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '620891',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62089110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62089190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '620892',
    Description: '- - Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '62089210',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62089290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '620899',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62089910',
    Description: '- - - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62089990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6209',
    Description: 'Quần áo may sẵn và phụ kiện may mặc cho trẻ em',
    Unit: '',
  },
  {
    HsCode: '620920',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62092030',
    Description: '- - Áo phông, áo sơ mi, bộ quần áo pyjama và các loại tương tự',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62092040',
    Description: '- - Bộ com-lê, quần và các loại tương tự',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62092090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '620930',
    Description: '- Từ sợi tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '62093010',
    Description: '- - Bộ com-lê, quần và các loại tương tự',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62093030',
    Description: '- - Áo phông, áo sơ mi, bộ quần áo pyjama và các loại tương tự',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62093040',
    Description: '- - Phụ kiện may mặc',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62093090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62099000',
    Description: '- Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6210',
    Description: 'Quần áo may từ các loại vải thuộc nhóm 56.02, 56.03, 59.03, 59.06 hoặc 59.07',
    Unit: '',
  },
  {
    HsCode: '621010',
    Description: '- Từ các loại vải thuộc nhóm 56.02 hoặc 56.03:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Quần áo bảo hộ dùng trong công nghiệp:',
    Unit: '',
  },
  {
    HsCode: '62101011',
    Description: '- - - Quần áo chống các chất hóa học, phóng xạ hoặc chống cháy (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62101019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62101090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '621020',
    Description: '- Quần áo khác, loại được mô tả trong nhóm 62.01: ',
    Unit: '',
  },
  {
    HsCode: '62102020',
    Description: '- - Quần áo chống cháy (SEN)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62102030',
    Description: '- - Quần áo chống các chất hóa học hoặc phóng xạ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62102040',
    Description: '- - Quần áo bảo hộ khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62102090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '621030',
    Description: '- Quần áo khác, loại được mô tả trong nhóm 62.02: ',
    Unit: '',
  },
  {
    HsCode: '62103020',
    Description: '- - Quần áo chống cháy (SEN)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62103030',
    Description: '- - Quần áo chống các chất hóa học hoặc phóng xạ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62103040',
    Description: '- - Quần áo bảo hộ khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62103090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '621040',
    Description: '- Quần áo khác dùng cho nam giới hoặc trẻ em trai:',
    Unit: '',
  },
  {
    HsCode: '62104010',
    Description: '- - Quần áo chống cháy (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62104020',
    Description: '- - Quần áo chống các chất hóa học hoặc phóng xạ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62104090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '621050',
    Description: '- Quần áo khác dùng cho phụ nữ hoặc trẻ em gái:',
    Unit: '',
  },
  {
    HsCode: '62105010',
    Description: '- - Quần áo chống cháy (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62105020',
    Description: '- - Quần áo chống các chất hóa học hoặc phóng xạ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62105090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6211',
    Description: 'Bộ quần áo thể thao, bộ quần áo trượt tuyết và quần áo bơi; quần áo khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Quần áo bơi:',
    Unit: '',
  },
  {
    HsCode: '62111100',
    Description: '- - Dùng cho nam giới hoặc trẻ em trai',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62111200',
    Description: '- - Dùng cho phụ nữ hoặc trẻ em gái',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '62112000',
    Description: '- Bộ quần áo trượt tuyết',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Quần áo khác, dùng cho nam giới hoặc trẻ em trai:',
    Unit: '',
  },
  {
    HsCode: '621132',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62113210',
    Description: '- - - Quần áo đấu kiếm hoặc đấu vật',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113220',
    Description: '- - - Áo choàng hành hương (Ihram)(SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '621133',
    Description: '- - Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '62113310',
    Description: '- - - Quần áo đấu kiếm hoặc đấu vật',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113320',
    Description: '- - - Quần áo chống cháy(SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113330',
    Description: '- - - Quần áo chống các chất hóa học hoặc phóng xạ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113340',
    Description: '- - - Áo choàng hành hương (Ihram)(SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '621139',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62113910',
    Description: '- - - Quần áo đấu kiếm hoặc đấu vật',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113920',
    Description: '- - - Quần áo chống cháy(SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113930',
    Description: '- - - Quần áo chống các chất hóa học hoặc phóng xạ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113940',
    Description: '- - - Áo choàng hành hương (Ihram)(SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62113990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Quần áo khác dùng cho phụ nữ hoặc trẻ em gái:',
    Unit: '',
  },
  {
    HsCode: '621142',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62114210',
    Description: '- - - Quần áo đấu kiếm hoặc đấu vật',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114220',
    Description: '- - - Áo choàng không tay dùng để cầu nguyện (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114230',
    Description: '- - - Sarong loại hình ống (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '621143',
    Description: '- - Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '62114310',
    Description: '- - - Áo phẫu thuật',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114320',
    Description: '- - - Áo choàng không tay dùng để cầu nguyện (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114330',
    Description: '- - - Bộ quần áo bảo hộ chống nổ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114340',
    Description: '- - - Quần áo đấu kiếm hoặc đấu vật',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114350',
    Description: '- - - Quần áo chống các chất hóa học, phóng xạ hoặc chống cháy (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114360',
    Description: '- - - Bộ quần áo nhảy dù liền thân',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114370',
    Description: '- - - Sarong loại hình ống (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '621149',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62114910',
    Description: '- - - Quần áo đấu kiếm hoặc đấu vật',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114920',
    Description: '- - - Quần áo chống các chất hóa học, phóng xạ hoặc chống cháy (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - - Áo choàng không tay dùng để cầu nguyện:',
    Unit: '',
  },
  {
    HsCode: '62114931',
    Description: '- - - - Từ lông cừu hoặc lông động vật mịn(SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114939',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114950',
    Description: '- - - Sarong loại hình ống (SEN)',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114960',
    Description: '- - - Loại khác, từ lông cừu hoặc lông động vật loại mịn ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '62114990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6212',
    Description:
      'Xu chiêng, gen, áo nịt ngực (corset), dây đeo quần, dây móc bít tất, nịt tất, các sản phẩm tương tự và các chi tiết của chúng, được làm hoặc không được làm từ dệt kim hoặc móc',
    Unit: '',
  },
  {
    HsCode: '621210',
    Description: '- Xu chiêng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62121011',
    Description: '- - - Xu chiêng độn ngực (dùng cho người sau phẫu thuật ngực)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62121019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62121091',
    Description: '- - - Xu chiêng độn ngực (dùng cho người sau phẫu thuật ngực)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62121099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '621220',
    Description: '- Gen và quần gen:',
    Unit: '',
  },
  {
    HsCode: '62122010',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62122090',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '621230',
    Description: '- Áo nịt toàn thân (corselette): ',
    Unit: '',
  },
  {
    HsCode: '62123010',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62123090',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '621290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62129011',
    Description: '- - - Hàng may mặc, loại có tính đàn hồi bó chặt, để điều trị mô vết sẹo và ghép da(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62129012',
    Description: '- - - Hàng may mặc hỗ trợ cho các môn điền kinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62129019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62129091',
    Description: '- - - Hàng may mặc, loại có tính đàn hồi bó chặt, để điều trị mô vết sẹo và ghép da(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62129092',
    Description: '- - - Hàng may mặc hỗ trợ cho các môn điền kinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62129099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6213',
    Description: 'Khăn tay và khăn vuông nhỏ quàng cổ',
    Unit: '',
  },
  {
    HsCode: '621320',
    Description: '- Từ bông:',
    Unit: '',
  },
  {
    HsCode: '62132010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62132090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '621390',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Từ tơ tằm hoặc phế liệu tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62139011',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62139019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '62139091',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62139099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6214',
    Description: 'Khăn choàng, khăn quàng cổ, khăn choàng rộng đội đầu, khăn choàng vai, mạng che và các loại tương tự',
    Unit: '',
  },
  {
    HsCode: '621410',
    Description: '- Từ tơ tằm hoặc phế liệu tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62141010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62141090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '62142000',
    Description: '- Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'chiếc',
  },
  {
    HsCode: '621430',
    Description: '- Từ sợi tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '62143010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62143090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '621440',
    Description: '- Từ sợi tái tạo:',
    Unit: '',
  },
  {
    HsCode: '62144010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62144090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '621490',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62149010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '62149090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6215',
    Description: 'Cà vạt, nơ con bướm và cravat',
    Unit: '',
  },
  {
    HsCode: '621510',
    Description: '- Từ tơ tằm hoặc phế liệu tơ tằm:',
    Unit: '',
  },
  {
    HsCode: '62151010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62151090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '621520',
    Description: '- Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '62152010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62152090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '621590',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '62159010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62159090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6216',
    Description: 'Găng tay, găng tay hở ngón và găng bao tay',
    Unit: '',
  },
  {
    HsCode: '62160010',
    Description: '- Găng tay bảo hộ lao động, găng tay hở ngón và găng bao tay',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '62160091',
    Description: '- - Từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '62160092',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '62160099',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/đôi',
  },
  {
    HsCode: '6217',
    Description:
      'Hàng phụ kiện may mặc đã hoàn chỉnh khác; các chi tiết của quần áo hoặc của phụ kiện may mặc, trừ các loại thuộc nhóm 62.12',
    Unit: '',
  },
  {
    HsCode: '621710',
    Description: '- Phụ kiện may mặc:',
    Unit: '',
  },
  {
    HsCode: '62171010',
    Description: '- - Đai Ju đô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62171090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '62179000',
    Description: '- Các chi tiết',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(1) Tham khảo TCVN 2108:1977',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 63',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các mặt hàng dệt đã hoàn thiện khác; bộ vải; quần áo dệt và các loại hàng dệt đã qua sử dụng khác; vải vụn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Phân chương I chỉ áp dụng cho các sản phẩm may sẵn đã hoàn thiện, được làm từ bất cứ loại vải dệt nào.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Phân Chương I không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hàng hóa thuộc các Chương từ 56 đến 62; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Quần áo đã qua sử dụng hoặc các mặt hàng khác đã qua sử dụng thuộc nhóm 63.09.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Nhóm 63.09 chỉ bao gồm những mặt hàng dưới đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các mặt hàng làm bằng vật liệu dệt:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Quần áo và các phụ kiện quần áo, và các chi tiết của chúng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Chăn và chăn du lịch;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) Vỏ ga, vỏ gối, khăn trải giường (bed linen), khăn trải bàn, khăn vệ sinh và khăn nhà bếp;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iv) Các mặt hàng dùng cho nội thất, trừ thảm thuộc các nhóm từ 57.01 đến 57.05 và các thảm thêu trang trí thuộc nhóm 58.05;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Giày, dép, mũ và các vật đội đầu khác làm bằng vật liệu bất kỳ trừ amiăng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Để được xếp vào nhóm này, các mặt hàng đã nêu ở trên phải thỏa mãn cả hai điều kiện sau đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) chúng phải có ký hiệu là hàng còn mặc được, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) chúng phải được thể hiện ở dạng đóng gói cỡ lớn hoặc kiện, bao hoặc các kiểu đóng gói tương tự.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Phân nhóm 6304.20 bao gồm các mặt hàng làm từ vải dệt kim sợi dọc, được ngâm tẩm hoặc phủ alpha-cypermethrin (ISO), chlorfenapyr (ISO), deltamethrin (INN, ISO), lambda-cyhalothrin (ISO), permethrin (ISO) hoặc pirimiphos-methyl (ISO).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG I',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'CÁC MẶT HÀNG DỆT ĐÃ HOÀN THIỆN KHÁC',
    Unit: '',
  },
  {
    HsCode: '6301',
    Description: 'Chăn và chăn du lịch',
    Unit: '',
  },
  {
    HsCode: '63011000',
    Description: '- Chăn điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '63012000',
    Description: '- Chăn (trừ chăn điện) và chăn du lịch, từ lông cừu hoặc lông động vật loại mịn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630130',
    Description: '- Chăn (trừ chăn điện) và chăn du lịch, từ bông:',
    Unit: '',
  },
  {
    HsCode: '63013010',
    Description: '- - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63013090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630140',
    Description: '- Chăn (trừ chăn điện) và chăn du lịch, từ xơ sợi tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '63014010',
    Description: '- - Vải không dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63014090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630190',
    Description: '- Chăn và chăn du lịch khác:',
    Unit: '',
  },
  {
    HsCode: '63019010',
    Description: '- - Vải không dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63019090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6302',
    Description: 'Vỏ ga, vỏ gối, khăn trải giường (bed linen), khăn trải bàn, khăn trong phòng vệ sinh và khăn nhà bếp',
    Unit: '',
  },
  {
    HsCode: '63021000',
    Description: '- Vỏ ga, vỏ gối, khăn trải giường (bed linen), dệt kim hoặc móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Vỏ ga, vỏ gối, khăn trải giường (bed linen) khác, đã in: ',
    Unit: '',
  },
  {
    HsCode: '63022100',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630222',
    Description: '- - Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '63022210',
    Description: '- - - Vải không dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63022290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63022900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Vỏ ga, vỏ gối, khăn trải giường (bed linen) khác:',
    Unit: '',
  },
  {
    HsCode: '63023100',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630232',
    Description: '- - Từ sợi nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '63023210',
    Description: '- - - Vải không dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63023290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63023900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63024000',
    Description: '- Khăn trải bàn, dệt kim hoặc móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Khăn trải bàn khác:',
    Unit: '',
  },
  {
    HsCode: '630251',
    Description: '- - Từ bông:',
    Unit: '',
  },
  {
    HsCode: '63025110',
    Description: '- - - Được in bằng phương pháp batik truyền thống (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63025190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63025300',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630259',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '63025910',
    Description: '- - - Từ lanh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63025990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63026000',
    Description:
      '- Khăn trong phòng vệ sinh và khăn nhà bếp, từ vải dệt vòng lông hoặc các loại vải dệt vòng lông tương tự, từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63029100',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63029300',
    Description: '- - Từ sợi nhân tạo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630299',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '63029910',
    Description: '- - - Từ lanh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63029990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6303',
    Description: 'Màn che (kể cả rèm trang trí) và rèm mờ che phía trong; diềm màn che hoặc diềm giường',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dệt kim hoặc móc:',
    Unit: '',
  },
  {
    HsCode: '63031200',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630319',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '63031910',
    Description: '- - - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63031990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63039100',
    Description: '- - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63039200',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63039900',
    Description: '- - Từ các vật liệu dệt khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6304',
    Description: 'Các sản phẩm trang trí nội thất khác, trừ các loại thuộc nhóm 94.04',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Các bộ khăn phủ giường (bedspreads):',
    Unit: '',
  },
  {
    HsCode: '63041100',
    Description: '- - Dệt kim hoặc móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630419',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63041910',
    Description: '- - - Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63041920',
    Description: '- - - Loại khác, không dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63041990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63042000',
    Description: '- Màn ngủ được chi tiết tại Chú giải Phân nhóm 1 Chương này',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '630491',
    Description: '- - Dệt kim hoặc móc:',
    Unit: '',
  },
  {
    HsCode: '63049110',
    Description: '- - - Màn chống muỗi',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63049190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63049200',
    Description: '- - Không dệt kim hoặc móc, từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63049300',
    Description: '- - Không dệt kim hoặc móc, từ sợi tổng hợp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63049900',
    Description: '- - Không dệt kim hoặc móc, từ các vật liệu dệt khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6305',
    Description: 'Bao và túi, loại dùng để đóng gói hàng',
    Unit: '',
  },
  {
    HsCode: '630510',
    Description: '- Từ đay hoặc các loại xơ libe dệt khác thuộc nhóm 53.03:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Mới:',
    Unit: '',
  },
  {
    HsCode: '63051011',
    Description: '- - - Từ đay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63051019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Đã qua sử dụng:',
    Unit: '',
  },
  {
    HsCode: '63051021',
    Description: '- - - Từ đay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63051029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63052000',
    Description: '- Từ bông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Từ vật liệu dệt nhân tạo:',
    Unit: '',
  },
  {
    HsCode: '630532',
    Description: '- - Túi, bao đựng hàng loại lớn có thể gấp, mở linh hoạt:',
    Unit: '',
  },
  {
    HsCode: '63053210',
    Description: '- - - Từ vải không dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63053220',
    Description: '- - - Dệt kim hoặc móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63053290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630533',
    Description: '- - Loại khác, từ dải polyetylen hoặc dải polypropylen hoặc dạng tương tự:',
    Unit: '',
  },
  {
    HsCode: '63053310',
    Description: '- - - Dệt kim hoặc móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63053320',
    Description: '- - - Bằng sợi dệt dạng dải hoặc tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63053390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630539',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63053910',
    Description: '- - - Từ vải không dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63053920',
    Description: '- - - Dệt kim hoặc móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63053990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630590',
    Description: '- Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '63059010',
    Description: '- - Từ gai dầu thuộc nhóm 53.05',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63059020',
    Description: '- - Từ dừa (xơ dừa) thuộc nhóm 53.05',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63059090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6306',
    Description:
      'Tấm vải chống thấm nước, tấm che mái hiên và tấm che nắng; tăng (lều) (kể cả mái che tạm thời và các vật dụng tương tự); buồm cho tàu thuyền, ván lướt hoặc ván lướt cát; các sản phẩm dùng cho cắm trại',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tấm vải chống thấm nước, tấm che mái hiên và tấm che nắng:',
    Unit: '',
  },
  {
    HsCode: '63061200',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '630619',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '63061910',
    Description: '- - - Từ sợi dệt thực vật thuộc nhóm 53.05',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '63061920',
    Description: '- - - Từ bông',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '63061990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '',
    Description: '- Tăng (lều) (kể cả mái che tạm thời và các vật dụng tương tự):',
    Unit: '',
  },
  {
    HsCode: '63062200',
    Description: '- - Từ sợi tổng hợp',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '630629',
    Description: '- - Từ các vật liệu dệt khác:',
    Unit: '',
  },
  {
    HsCode: '63062910',
    Description: '- - - Từ bông',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '63062990',
    Description: '- - - Loại khác',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '63063000',
    Description: '- Buồm cho tàu thuyền',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '630640',
    Description: '- Đệm hơi:',
    Unit: '',
  },
  {
    HsCode: '63064010',
    Description: '- - Từ bông',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '63064090',
    Description: '- - Loại khác',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '630690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63069010',
    Description: '- - Từ vải không dệt',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63069091',
    Description: '- - - Từ bông',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '63069099',
    Description: '- - - Loại khác',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '6307',
    Description: 'Các mặt hàng đã hoàn thiện khác, kể cả mẫu cắt may',
    Unit: '',
  },
  {
    HsCode: '630710',
    Description: '- Khăn lau sàn, khăn lau bát đĩa, khăn lau bụi và các loại khăn lau tương tự:',
    Unit: '',
  },
  {
    HsCode: '63071010',
    Description: '- - Từ vải không dệt trừ phớt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63071020',
    Description: '- - Từ phớt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63071090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63072000',
    Description: '- Áo cứu sinh và đai cứu sinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '630790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63079030',
    Description: '- - Tấm phủ ô che cắt sẵn hình tam giác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63079040',
    Description: '- - Khẩu trang phẫu thuật',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Các loại đai an toàn:',
    Unit: '',
  },
  {
    HsCode: '63079061',
    Description: '- - - Phù hợp dùng trong công nghiệp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63079069',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63079070',
    Description: '- - Quạt và màn che kéo bằng tay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63079080',
    Description: '- - Dây buộc dùng cho giày, ủng, áo nịt ngực (corset) và các loại tương tự(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '63079090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG II ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'BỘ VẢI KÈM CHỈ TRANG TRÍ',
    Unit: '',
  },
  {
    HsCode: '63080000',
    Description:
      'Bộ vải bao gồm vải dệt thoi và chỉ, có hoặc không có phụ kiện, dùng để làm chăn, thảm trang trí, khăn trải bàn hoặc khăn ăn đã thêu, hoặc các sản phẩm dệt tương tự, đóng gói sẵn để bán lẻ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG III',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'QUẦN ÁO VÀ CÁC SẢN PHẨM DỆT ĐÃ QUA SỬ DỤNG; VẢI VỤN',
    Unit: '',
  },
  {
    HsCode: '63090000',
    Description: 'Quần áo và các sản phẩm dệt may đã qua sử dụng khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6310',
    Description:
      'Vải vụn, mẩu dây xe, chão bện (cordage), thừng và cáp đã qua sử dụng hoặc mới và các phế liệu từ vải vụn, dây xe, chão bện (cordage), thừng hoặc cáp, từ vật liệu dệt',
    Unit: '',
  },
  {
    HsCode: '631010',
    Description: '- Đã được phân loại:',
    Unit: '',
  },
  {
    HsCode: '63101010',
    Description: '- - Vải vụn đã qua sử dụng hoặc mới',
    Unit: 'kg',
  },
  {
    HsCode: '63101090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '631090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '63109010',
    Description: '- - Vải vụn đã qua sử dụng hoặc mới',
    Unit: 'kg',
  },
  {
    HsCode: '63109090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XII',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'GIÀY, DÉP, MŨ VÀ CÁC VẬT ĐỘI ĐẦU KHÁC, Ô, DÙ, BA TOONG, GẬY TAY CẦM CÓ THỂ CHUYỂN THÀNH GHẾ, ROI, GẬY ĐIỀU KHIỂN, ROI ĐIỀU KHIỂN SÚC VẬT THỒ KÉO VÀ CÁC BỘ PHẬN CỦA CÁC LOẠI HÀNG TRÊN; LÔNG VŨ CHẾ BIẾN VÀ CÁC SẢN PHẨM LÀM TỪ LÔNG VŨ CHẾ BIẾN; HOA NHÂN TẠO; CÁC SẢN PHẨM LÀM TỪ TÓC NGƯỜI',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 64',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Giày, dép, ghệt và các sản phẩm tương tự; các bộ phận của các sản phẩm trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Giày hoặc dép đi một lần bằng vật liệu mỏng (ví dụ, giấy, tấm plastic) không gắn đế. Các sản phẩm này được phân loại theo vật liệu làm ra chúng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Giày, dép bằng vật liệu dệt, không có đế ngoài được dán keo, khâu hoặc gắn bằng cách khác vào mũ giày (Phần XI);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Giày, dép đã qua sử dụng thuộc nhóm 63.09;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các sản phẩm bằng amiăng (nhóm 68.12);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Giày, dép chỉnh hình hoặc các thiết bị chỉnh hình khác, hoặc các bộ phận của chúng (nhóm 90.21); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Giày, dép đồ chơi hoặc giày ống có gắn lưỡi trượt băng hoặc trượt patanh; tấm ốp bảo vệ ống chân hoặc các đồ bảo vệ tương tự dùng trong thể thao (Chương 95).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của nhóm 64.06, khái niệm “các bộ phận” không bao gồm các móc gài, bộ phận bảo vệ, khoen, móc cài, khóa cài, vật trang trí, dải viền, đăng ten, búp cài hoặc các vật trang trí khác (được phân loại trong các nhóm thích hợp) hoặc các loại khuy hoặc các hàng hóa khác thuộc nhóm 96.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Theo mục đích của Chương này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) thuật ngữ “cao su” và “plastic” kể cả vải dệt thoi hoặc các sản phẩm dệt khác có lớp ngoài bằng cao su hoặc plastic có thể nhìn thấy được bằng mắt thường; theo mục đích của phần này, không tính đến sự thay đổi về màu sắc; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) thuật ngữ “da thuộc” liên quan đến các mặt hàng thuộc các nhóm 41.07 và từ 41.12 đến 41.14.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '4. Theo Chú giải 3 của Chương này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) vật liệu làm mũ giày được xếp theo loại vật liệu cấu thành có diện tích mặt ngoài lớn nhất, không tính đến các phụ kiện hoặc đồ phụ trợ như những mảng ở mắt cá chân, mép viền, vật trang trí, khóa cài, mác nhãn, khoen xỏ dây giày hoặc các vật gắn lắp tương tự;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) vật liệu cấu thành của đế ngoài được tính là vật liệu có bề mặt rộng nhất trong việc tiếp xúc với đất, không tính đến phụ tùng hoặc các vật phụ trợ như đinh đóng đế giày, đinh, que, các vật bảo vệ hoặc các vật gắn lắp tương tự.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của các phân nhóm 6402.12, 6402.19, 6403.12, 6403.19 và 6404.11, khái niệm “giày, dép thể thao” chỉ áp dụng với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) giày, dép được thiết kế cho hoạt động thể thao và đã có, hoặc chuẩn bị sẵn để gắn, đóng đinh, bịt đầu, ghim kẹp, chốt hoặc loại tương tự;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) giày ống trượt băng, giày ống trượt tuyết và giày trượt tuyết băng đồng, giày ống gắn ván trượt, giày ống đấu vật, giày ống đấu quyền anh và giày đua xe đạp.',
    Unit: '',
  },
  {
    HsCode: '6401',
    Description:
      'Giày, dép không thấm nước có đế ngoài và mũ giày bằng cao su hoặc plastic, mũ giày, dép không gắn hoặc lắp ghép với đế bằng cách khâu, tán đinh, xoáy ốc, cắm đế hoặc các cách tương tự',
    Unit: '',
  },
  {
    HsCode: '64011000',
    Description: '- Giày, dép có mũi gắn kim loại bảo vệ',
    Unit: 'đôi',
  },
  {
    HsCode: '',
    Description: '- Giày, dép khác:',
    Unit: '',
  },
  {
    HsCode: '640192',
    Description: '- - Loại cổ cao quá mắt cá chân nhưng không qua đầu gối:',
    Unit: '',
  },
  {
    HsCode: '64019210',
    Description: '- - - Loại có mũi giày được gắn bảo vệ không phải kim loại (SEN)',
    Unit: 'đôi',
  },
  {
    HsCode: '64019290',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '640199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64019910',
    Description: '- - - Giày cổ cao quá đầu gối',
    Unit: 'đôi',
  },
  {
    HsCode: '64019990',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '6402',
    Description: 'Các loại giày, dép khác có đế ngoài và mũ giày bằng cao su hoặc plastic',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Giày, dép thể thao:',
    Unit: '',
  },
  {
    HsCode: '64021200',
    Description: '- - Giày ống trượt tuyết, giày trượt tuyết băng đồng và giày ống gắn ván trượt',
    Unit: 'đôi',
  },
  {
    HsCode: '640219',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64021910',
    Description: '- - - Giày, dép cho đấu vật',
    Unit: 'đôi',
  },
  {
    HsCode: '64021990',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '64022000',
    Description: '- Giày, dép có đai hoặc dây gắn mũ giày với đế bằng chốt cài',
    Unit: 'đôi',
  },
  {
    HsCode: '',
    Description: '- Giày, dép khác:',
    Unit: '',
  },
  {
    HsCode: '640291',
    Description: '- - Giày cổ cao quá mắt cá chân:',
    Unit: '',
  },
  {
    HsCode: '64029110',
    Description: '- - - Giày lặn',
    Unit: 'đôi',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64029191',
    Description: '- - - - Loại có mũi giày được gắn kim loại để bảo vệ',
    Unit: 'đôi',
  },
  {
    HsCode: '64029192',
    Description: '- - - - Loại có mũi giày được gắn bảo vệ không phải kim loại (SEN)',
    Unit: 'đôi',
  },
  {
    HsCode: '64029199',
    Description: '- - - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '640299',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64029910',
    Description: '- - - Loại có mũi giày được gắn kim loại để bảo vệ',
    Unit: 'đôi',
  },
  {
    HsCode: '64029920',
    Description: '- - - Loại có mũi giày được gắn bảo vệ không phải kim loại (SEN)',
    Unit: 'đôi',
  },
  {
    HsCode: '64029990',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '6403',
    Description: 'Giày, dép có đế ngoài bằng cao su, plastic, da thuộc hoặc da tổng hợp và mũ giày bằng da thuộc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Giày, dép thể thao:',
    Unit: '',
  },
  {
    HsCode: '64031200',
    Description: '- - Giày ống trượt tuyết, giày trượt tuyết băng đồng và giày ống gắn ván trượt',
    Unit: 'đôi',
  },
  {
    HsCode: '640319',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64031910',
    Description: '- - - Giày, dép có gắn đinh, gắn miếng đế chân hoặc các loại tương tự',
    Unit: 'đôi',
  },
  {
    HsCode: '64031920',
    Description: '- - - Ủng để cưỡi ngựa; giày chơi bowling',
    Unit: 'đôi',
  },
  {
    HsCode: '64031930',
    Description: '- - - Giày, dép dùng trong đấu vật, cử tạ hoặc thể dục thể hình',
    Unit: 'đôi',
  },
  {
    HsCode: '64031990',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '64032000',
    Description:
      '- Giày, dép có đế ngoài bằng da thuộc, và mũ giày có đai vòng qua mu bàn chân và quai xỏ ngón chân cái',
    Unit: 'đôi',
  },
  {
    HsCode: '64034000',
    Description: '- Giày, dép khác, có mũi gắn kim loại bảo vệ',
    Unit: 'đôi',
  },
  {
    HsCode: '',
    Description: '- Giày, dép khác có đế ngoài bằng da thuộc:',
    Unit: '',
  },
  {
    HsCode: '64035100',
    Description: '- - Giày cổ cao quá mắt cá chân',
    Unit: 'đôi',
  },
  {
    HsCode: '640359',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64035910',
    Description: '- - - Giày chơi bowling',
    Unit: 'đôi',
  },
  {
    HsCode: '64035990',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '',
    Description: '- Giày, dép khác:',
    Unit: '',
  },
  {
    HsCode: '640391',
    Description: '- - Loại cổ cao quá mắt cá chân:',
    Unit: '',
  },
  {
    HsCode: '64039110',
    Description: '- - - Giày, dép có đế bằng gỗ, không có lót đế bên trong hoặc mũi gắn kim loại bảo vệ',
    Unit: 'đôi',
  },
  {
    HsCode: '64039120',
    Description: '- - - Ủng để cưỡi ngựa',
    Unit: 'đôi',
  },
  {
    HsCode: '64039130',
    Description: '- - - Loại khác, có mũi giày được gắn bảo vệ không phải kim loại (SEN)',
    Unit: 'đôi',
  },
  {
    HsCode: '64039190',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '640399',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64039910',
    Description: '- - - Giày, dép có đế bằng gỗ, không có lót đế bên trong hoặc mũi gắn kim loại bảo vệ',
    Unit: 'đôi',
  },
  {
    HsCode: '64039920',
    Description: '- - - Giày chơi bowling',
    Unit: 'đôi',
  },
  {
    HsCode: '64039930',
    Description: '- - - Loại khác, có mũi giày được gắn bảo vệ không phải kim loại (SEN)',
    Unit: 'đôi',
  },
  {
    HsCode: '64039990',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '6404',
    Description: 'Giày, dép có đế ngoài bằng cao su, plastic, da thuộc hoặc da tổng hợp và mũ giày bằng vật liệu dệt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Giày, dép có đế ngoài bằng cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '640411',
    Description:
      '- - Giày, dép thể thao, giày tennis, giày bóng rổ, giày thể dục, giày luyện tập và các loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '64041110',
    Description: '- - - Giày, dép có gắn đinh, gắn miếng đế chân hoặc các loại tương tự',
    Unit: 'đôi',
  },
  {
    HsCode: '64041120',
    Description: '- - - Giày, dép dùng trong đấu vật, cử tạ hoặc thể dục thể hình',
    Unit: 'đôi',
  },
  {
    HsCode: '64041190',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '640419',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64041910',
    Description: '- - - Loại có mũi giày được gắn bảo vệ',
    Unit: 'đôi',
  },
  {
    HsCode: '64041990',
    Description: '- - - Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '64042000',
    Description: '- Giày, dép có đế ngoài bằng da thuộc hoặc da tổng hợp',
    Unit: 'đôi',
  },
  {
    HsCode: '6405',
    Description: 'Giày, dép khác',
    Unit: '',
  },
  {
    HsCode: '64051000',
    Description: '- Có mũ giày bằng da thuộc hoặc da tổng hợp',
    Unit: 'đôi',
  },
  {
    HsCode: '64052000',
    Description: '- Có mũ giày bằng vật liệu dệt',
    Unit: 'đôi',
  },
  {
    HsCode: '64059000',
    Description: '- Loại khác',
    Unit: 'đôi',
  },
  {
    HsCode: '6406',
    Description:
      'Các bộ phận của giày, dép (kể cả mũ giày đã hoặc chưa gắn đế trừ đế ngoài); miếng lót của giày, dép có thể tháo rời, đệm gót chân và các sản phẩm tương tự; ghệt, ống ôm sát chân (leggings) và các sản phẩm tương tự, và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '640610',
    Description: '- Mũ giày và các bộ phận của chúng, trừ miếng lót bằng vật liệu cứng trong mũ giày:',
    Unit: '',
  },
  {
    HsCode: '64061010',
    Description: '- - Mũi giày bằng kim loại',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64061090',
    Description: '- - Loại khác',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '640620',
    Description: '- Đế ngoài và gót giày, bằng cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '64062010',
    Description: '- - Bằng cao su',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64062020',
    Description: '- - Bằng plastic',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '640690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64069010',
    Description: '- - Bằng gỗ',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '',
    Description: '- - Bằng kim loại:',
    Unit: '',
  },
  {
    HsCode: '64069021',
    Description: '- - - Bằng sắt hoặc thép',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069022',
    Description: '- - - Bằng đồng',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069023',
    Description: '- - - Bằng nhôm',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069029',
    Description: '- - - Loại khác',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '',
    Description: '- - Bằng cao su:',
    Unit: '',
  },
  {
    HsCode: '64069041',
    Description: '- - - Tấm lót giày',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069042',
    Description: '- - - Đế giày đã hoàn thiện (SEN)',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069049',
    Description: '- - - Loại khác',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '',
    Description: '- - Bằng plastic:',
    Unit: '',
  },
  {
    HsCode: '64069051',
    Description: '- - - Tấm lót giày',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069052',
    Description: '- - - Đế giày đã hoàn thiện (SEN)',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069059',
    Description: '- - - Loại khác',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '64069091',
    Description: '- - - Ghệt, ống ôm sát chân (leggings) và các loại tương tự và bộ phận của chúng',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '64069099',
    Description: '- - - Loại khác',
    Unit: 'kg/đôi',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 65',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Mũ và các vật đội đầu khác và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Mũ và các vật đội đầu khác đã qua sử dụng thuộc nhóm 63.09;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Mũ và các vật đội đầu khác, bằng amiăng (nhóm 68.12); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Mũ búp bê, mũ đồ chơi khác, hoặc các mặt hàng dùng trong lễ hội carnival của Chương 95.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 65.02 không bao gồm thân mũ được làm bằng cách khâu, trừ các thân mũ được làm một cách đơn giản bằng cách khâu các dải theo hình xoáy ốc. ',
    Unit: '',
  },
  {
    HsCode: '65010000',
    Description:
      'Các loại thân mũ hình nón, hình chuông bằng phớt (nỉ, dạ), chưa dựng theo khuôn, chưa làm vành; thân mũ chóp bằng và thân mũ hình trụ (kể cả thân mũ hình trụ đa giác), bằng phớt (nỉ, dạ)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '65020000',
    Description:
      'Các loại thân mũ, được làm bằng cách tết hoặc ghép các dải làm bằng vật liệu bất kỳ, chưa dựng theo khuôn, chưa làm vành, chưa có lót, chưa có trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '65040000',
    Description:
      'Các loại mũ và các vật đội đầu khác, được làm bằng cách tết hoặc ghép các dải làm bằng chất liệu bất kỳ, đã hoặc chưa có lót hoặc trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6505',
    Description:
      'Các loại mũ và các vật đội đầu khác, dệt kim hoặc móc, hoặc làm từ ren, nỉ hoặc vải dệt khác, ở dạng mảnh (trừ dạng dải), đã hoặc chưa lót hoặc trang trí; lưới bao tóc bằng vật liệu bất kỳ, đã hoặc chưa có lót hoặc trang trí',
    Unit: '',
  },
  {
    HsCode: '65050010',
    Description: '- Mũ và các vật đội đầu sử dụng cho mục đích tôn giáo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '65050020',
    Description: '- Lưới bao tóc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '65050090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6506',
    Description: 'Mũ và các vật đội đầu khác, đã hoặc chưa lót hoặc trang trí',
    Unit: '',
  },
  {
    HsCode: '650610',
    Description: '- Mũ bảo hộ và các vật đội đầu an toàn khác:',
    Unit: '',
  },
  {
    HsCode: '65061010',
    Description: '- - Mũ bảo hiểm cho người đi xe máy',
    Unit: 'chiếc',
  },
  {
    HsCode: '65061020',
    Description: '- - Mũ bảo hộ công nghiệp và mũ bảo hiểm cho lính cứu hỏa, trừ mũ bảo hộ bằng thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '65061030',
    Description: '- - Mũ bảo hộ bằng thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '65061040',
    Description: '- - Mũ dùng trong chơi water-polo',
    Unit: 'chiếc',
  },
  {
    HsCode: '65061090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '65069100',
    Description: '- - Bằng cao su hoặc plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '650699',
    Description: '- - Bằng các loại vật liệu khác:',
    Unit: '',
  },
  {
    HsCode: '65069910',
    Description: '- - - Bằng da lông',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '65069990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '65070000',
    Description:
      'Băng lót vành trong thân mũ, lớp lót, lớp bọc, cốt, khung, lưỡi trai và quai, dùng để sản xuất mũ và các vật đội đầu khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 66',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Ô, dù che, ba toong, gậy tay cầm có thể chuyển thành ghế, roi, gậy điều khiển, roi điều khiển súc vật thồ kéo và các bộ phận của các sản phẩm trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Gậy chống dùng để đo hoặc loại tương tự (nhóm 90.17);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Báng súng, chuôi kiếm, gậy chống chịu lực hoặc loại tương tự (Chương 93); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Hàng hóa thuộc Chương 95 (ví dụ, ô, dù che, loại dùng làm đồ chơi).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 66.03 không bao gồm các bộ phận, đồ trang trí hoặc các phụ kiện từ vật liệu dệt, hoặc bao bì, tuarua, dây da, hộp đựng ô hoặc các loại tương tự, bằng vật liệu bất kỳ. Những hàng hóa này đi kèm, nhưng không gắn vào các sản phẩm của nhóm 66.01 hoặc 66.02 được phân loại riêng và không được xem như là bộ phận tạo thành của các sản phẩm đó.',
    Unit: '',
  },
  {
    HsCode: '6601',
    Description: 'Các loại ô và dù (kể cả ô có cán là ba toong, dù che trong vườn và các loại ô, dù tương tự)',
    Unit: '',
  },
  {
    HsCode: '66011000',
    Description: '- Dù che trong vườn hoặc các loại ô, dù tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '66019100',
    Description: '- - Có cán kiểu ống lồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '66019900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '66020000',
    Description:
      'Ba toong, gậy tay cầm có thể chuyển thành ghế, roi, gậy điều khiển, roi điều khiển súc vật thồ, kéo và các loại tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '6603',
    Description: 'Các bộ phận, đồ trang trí và đồ phụ trợ cho các mặt hàng thuộc nhóm 66.01 hoặc 66.02',
    Unit: '',
  },
  {
    HsCode: '66032000',
    Description: '- Khung ô, kể cả khung có gắn với cán (thân gậy)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '660390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '66039010',
    Description: '- - Cho hàng hóa thuộc nhóm 66.01',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '66039020',
    Description: '- - Cho hàng hóa thuộc nhóm 66.02',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 67',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Lông vũ và lông tơ chế biến, các sản phẩm bằng lông vũ hoặc lông tơ; hoa nhân tạo; các sản phẩm làm từ                       tóc người',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Vải lọc (filtering hoặc straining) loại làm bằng tóc người (nhóm 59.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các hoa văn trang trí bằng ren, đồ thêu hoặc vải dệt khác (Phần XI);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Giày, dép (Chương 64);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Mũ và các vật đội đầu khác hoặc lưới bao tóc (Chương 65);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Đồ chơi, dụng cụ, thiết bị thể thao hoặc các mặt hàng dùng trong lễ hội carnival (Chương 95); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Chổi phất trần, nùi bông thoa phấn bằng lông vũ hoặc mạng lọc bằng lông (Chương 96).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Nhóm 67.01 không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các sản phẩm mà trong đó lông vũ hoặc lông tơ chỉ dùng làm đệm, lót, nhồi (ví dụ, đệm giường thuộc nhóm 94.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các sản phẩm may mặc hoặc đồ phụ trợ của quần áo mà trong đó lông vũ hoặc lông tơ chỉ dùng như vật trang trí hoặc đệm, lót; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Hoa hoặc cành, lá nhân tạo hoặc các phần của chúng hoặc các sản phẩm làm sẵn thuộc nhóm 67.02.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Nhóm 67.02 không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các sản phẩm bằng thủy tinh (Chương 70); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Hoa, cành, lá, quả nhân tạo bằng gốm, đá, kim loại, gỗ hoặc các vật liệu khác, được làm thành một khối bằng cách đúc, luyện, khắc, dập hoặc cách khác, hoặc gồm các bộ phận được lắp ráp lại với nhau trừ việc ghép, gắn bằng keo, lắp với một vật khác hoặc các phương pháp tương tự.',
    Unit: '',
  },
  {
    HsCode: '67010000',
    Description:
      'Da và các bộ phận khác của loài chim có lông vũ hoặc lông tơ, lông vũ, các phần của lông vũ, lông tơ và các sản phẩm làm từ chúng (trừ các mặt hàng thuộc nhóm 05.05 và các ống, lông cánh, lông đuôi đã chế biến)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6702',
    Description:
      'Hoa, cành, lá, quả nhân tạo và các phần của chúng; các sản phẩm làm bằng hoa, cành, lá hoặc quả nhân tạo',
    Unit: '',
  },
  {
    HsCode: '67021000',
    Description: '- Bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '670290',
    Description: '- Bằng các loại vật liệu khác:',
    Unit: '',
  },
  {
    HsCode: '67029010',
    Description: '- - Bằng giấy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '67029020',
    Description: '- - Bằng vật liệu dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '67029090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '67030000',
    Description:
      'Tóc người, đã được chải, chuốt, tẩy hoặc xử lý bằng cách khác; lông cừu hoặc lông động vật khác hoặc các loại vật liệu dệt khác, được chế biến để dùng làm tóc giả hoặc sản phẩm tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6704',
    Description:
      'Tóc giả, râu, lông mi, lông mày giả, tóc độn và các loại sản phẩm tương tự, bằng tóc người hoặc lông động vật hoặc các loại vật liệu dệt; các sản phẩm bằng tóc người chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng vật liệu dệt tổng hợp:',
    Unit: '',
  },
  {
    HsCode: '67041100',
    Description: '- - Bộ tóc giả hoàn chỉnh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '67041900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '67042000',
    Description: '- Bằng tóc người',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '67049000',
    Description: '- Bằng vật liệu khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'PHẦN XIII',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'SẢN PHẨM BẰNG ĐÁ, THẠCH CAO, XI MĂNG, AMIĂNG, MICA HOẶC CÁC VẬT LIỆU TƯƠNG TỰ; ĐỒ GỐM, SỨ (CERAMIC); THỦY TINH VÀ CÁC SẢN PHẨM BẰNG THỦY TINH',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 68',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sản phẩm làm bằng đá, thạch cao, xi măng, amiăng, mica hoặc các vật liệu tương tự',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hàng hóa thuộc Chương 25;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Giấy và bìa đã được tráng, ngâm tẩm hoặc phủ thuộc nhóm 48.10 hoặc 48.11 (ví dụ, giấy và bìa đã được tráng graphit hoặc bột mica, giấy và bìa đã được bi-tum hóa hoặc asphalt hóa);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Vải dệt đã được tráng, ngâm tẩm hoặc phủ thuộc Chương 56 hoặc 59 (ví dụ, vải đã được tráng hoặc phủ bột mica, vải đã được asphalt hóa hoặc bi-tum hóa);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các sản phẩm của Chương 71;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Dụng cụ hoặc các bộ phận của dụng cụ, thuộc Chương 82;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Đá in ly tô thuộc nhóm 84.42;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Vật liệu cách điện (nhóm 85.46) hoặc các phụ kiện làm bằng vật liệu cách điện thuộc nhóm 85.47;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Đầu mũi khoan, mài dùng trong nha khoa (nhóm 90.18);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Các sản phẩm thuộc Chương 91 (ví dụ, đồng hồ thời gian và vỏ đồng hồ thời gian);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các sản phẩm thuộc Chương 94 (ví dụ, đồ nội thất, đèn (luminaires) và bộ đèn, nhà lắp ghép);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các sản phẩm của Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi và dụng cụ, thiết bị thể thao);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(m) Các sản phẩm của nhóm 96.02, nếu làm bằng các vật liệu đã được ghi trong Chú giải 2(b) của Chương 96, hoặc của nhóm 96.06 (ví dụ, khuy), của nhóm 96.09 (ví dụ, bút chì bằng đá phiến) hoặc nhóm 96.10 (ví dụ, bảng đá phiến dùng để vẽ) hoặc của nhóm 96.20 (chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các sản phẩm tương tự); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Các sản phẩm thuộc Chương 97 (ví dụ, các tác phẩm nghệ thuật).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong nhóm 68.02, khái niệm ""đá xây dựng hoặc đá làm tượng đài đã được gia công"" được áp dụng không chỉ đối với nhiều loại đá đã được nêu ra trong nhóm 25.15 hoặc 25.16 mà còn đối với tất cả các loại đá tự nhiên khác (ví dụ, đá thạch anh, đá lửa, dolomit và steatit) đã được gia công tương tự; tuy nhiên, không áp dụng đối với đá phiến.',
    Unit: '',
  },
  {
    HsCode: '68010000',
    Description: 'Các loại đá lát, đá lát lề đường và phiến đá lát đường, bằng đá tự nhiên (trừ đá phiến)',
    Unit: 'kg',
  },
  {
    HsCode: '6802',
    Description:
      'Đá làm tượng đài hoặc đá xây dựng đã được gia công (trừ đá phiến) và các sản phẩm làm từ các loại đá trên, trừ các sản phẩm thuộc nhóm 68.01; đá khối dùng để khảm và các loại tương tự, bằng đá tự nhiên (kể cả đá phiến), có hoặc không có lớp lót; đá hạt, đá dăm và bột đá đã nhuộm màu nhân tạo, làm bằng đá tự nhiên (kể cả đá phiến)',
    Unit: '',
  },
  {
    HsCode: '68021000',
    Description:
      '- Đá lát, đá khối và các sản phẩm tương tự, có hoặc không ở dạng hình chữ nhật (kể cả dạng hình vuông), mà mặt lớn nhất của nó có thể nằm gọn trong một hình vuông có cạnh nhỏ hơn 7 cm; đá hạt, đá dăm và bột đá đã nhuộm màu nhân tạo ',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description:
      '- Đá làm tượng đài hoặc đá xây dựng khác và các sản phẩm làm từ chúng, mới chỉ cắt hoặc cưa đơn giản, có bề mặt nhẵn hoặc phẳng: ',
    Unit: '',
  },
  {
    HsCode: '68022100',
    Description: '- - Đá hoa (marble), tra-véc-tin và thạch cao tuyết hoa',
    Unit: 'kg/m3',
  },
  {
    HsCode: '68022300',
    Description: '- - Đá granit',
    Unit: 'kg/m3',
  },
  {
    HsCode: '680229',
    Description: '- - Đá khác:',
    Unit: '',
  },
  {
    HsCode: '68022910',
    Description: '- - - Đá vôi khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '68022990',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '680291',
    Description: '- - Đá hoa (marble), tra-véc-tin và thạch cao tuyết hoa:',
    Unit: '',
  },
  {
    HsCode: '68029110',
    Description: '- - - Đá hoa (marble)',
    Unit: 'kg/m3',
  },
  {
    HsCode: '68029190',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '68029200',
    Description: '- - Đá vôi khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '680293',
    Description: '- - Đá granit:',
    Unit: '',
  },
  {
    HsCode: '68029310',
    Description: '- - - Dạng tấm đã được đánh bóng',
    Unit: 'kg/m3',
  },
  {
    HsCode: '68029390',
    Description: '- - - Loại khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '68029900',
    Description: '- - Đá khác',
    Unit: 'kg/m3',
  },
  {
    HsCode: '68030000',
    Description:
      'Đá phiến đã gia công và các sản phẩm làm bằng đá phiến hoặc làm bằng đá phiến kết khối (từ bột đá phiến kết lại thành khối)',
    Unit: 'kg/m3',
  },
  {
    HsCode: '6804',
    Description:
      'Đá nghiền, đá mài, đá mài dạng hình tròn và tương tự, không có cốt, dùng để nghiền, mài, đánh bóng, giũa hoặc cắt, đá mài hoặc đá đánh bóng bằng tay, và các phần của chúng, bằng đá tự nhiên, bằng các vật liệu mài tự nhiên hoặc nhân tạo đã được kết khối, hoặc bằng gốm, có hoặc không kèm theo các bộ phận bằng các vật liệu khác',
    Unit: '',
  },
  {
    HsCode: '68041000',
    Description: '- Đá nghiền (thớt cối xay) và đá mài để nghiền, mài hoặc xay thành bột',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Đá nghiền, đá mài khác, đá mài dạng hình tròn và dạng tương tự:',
    Unit: '',
  },
  {
    HsCode: '68042100',
    Description: '- - Bằng kim cương tự nhiên hoặc kim cương nhân tạo đã được kết khối',
    Unit: 'kg',
  },
  {
    HsCode: '68042200',
    Description: '- - Bằng vật liệu mài đã được kết khối hoặc bằng gốm khác',
    Unit: 'kg',
  },
  {
    HsCode: '68042300',
    Description: '- - Bằng đá tự nhiên',
    Unit: 'kg',
  },
  {
    HsCode: '68043000',
    Description: '- Đá mài hoặc đá đánh bóng bằng tay',
    Unit: 'kg',
  },
  {
    HsCode: '6805',
    Description:
      'Bột mài hoặc hạt mài tự nhiên hoặc nhân tạo, có nền bằng vật liệu dệt, giấy, bìa hoặc các vật liệu khác, đã hoặc chưa cắt thành hình hoặc đã khâu hoặc hoàn thiện bằng cách khác',
    Unit: '',
  },
  {
    HsCode: '68051000',
    Description: '- Trên nền chỉ bằng vải dệt',
    Unit: 'kg',
  },
  {
    HsCode: '68052000',
    Description: '- Trên nền chỉ bằng giấy hoặc bìa',
    Unit: 'kg',
  },
  {
    HsCode: '68053000',
    Description: '- Trên nền bằng vật liệu khác',
    Unit: 'kg',
  },
  {
    HsCode: '6806',
    Description:
      'Sợi xỉ, bông len đá (rock wool) và các loại sợi khoáng tương tự; vermiculite nung nở, đất sét trương nở, xỉ bọt và các loại vật liệu khoáng trương nở tương tự; các hỗn hợp và các sản phẩm cách nhiệt, cách âm hoặc các vật liệu khoáng hấp thụ âm, trừ các sản phẩm thuộc nhóm 68.11 hoặc 68.12 hoặc Chương 69',
    Unit: '',
  },
  {
    HsCode: '68061000',
    Description:
      '- Sợi xỉ, bông len đá (rock wool) và các loại sợi khoáng tương tự (kể cả hỗn hợp của chúng), ở dạng rời, dạng tấm hoặc dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '68062000',
    Description:
      '- Vermiculite nung nở, đất sét trương nở, xỉ bọt và các loại vật liệu quặng khoáng trương nở tương tự (kể cả hỗn hợp của chúng)',
    Unit: 'kg',
  },
  {
    HsCode: '68069000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '6807',
    Description: 'Các sản phẩm bằng asphalt hoặc bằng vật liệu tương tự (ví dụ, bi-tum dầu mỏ hoặc hắc ín than đá)',
    Unit: '',
  },
  {
    HsCode: '68071000',
    Description: '- Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '680790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '68079010',
    Description: '- - Tấm lát (tiles)',
    Unit: 'kg',
  },
  {
    HsCode: '68079090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '6808',
    Description:
      'Panel, tấm, tấm lát (tiles), khối và các sản phẩm tương tự làm bằng xơ thực vật, rơm rạ hoặc bằng phoi bào, mạt gỗ, dăm gỗ, mùn cưa hoặc phế liệu khác, bằng gỗ, đã được kết khối bằng xi măng, thạch cao hoặc chất kết dính khoáng khác',
    Unit: '',
  },
  {
    HsCode: '68080020',
    Description: '- Ngói lợp mái',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68080040',
    Description: '- Tấm lát (tiles) khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '68080091',
    Description: '- - Từ xơ thực vật',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68080099',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '6809',
    Description: 'Các sản phẩm làm bằng thạch cao hoặc bằng các hỗn hợp có thành phần cơ bản là thạch cao',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tấm, lá, panel, tấm lát (tiles) và các sản phẩm tương tự, chưa được trang trí:',
    Unit: '',
  },
  {
    HsCode: '68091100',
    Description: '- - Được phủ mặt hoặc gia cố chỉ bằng giấy hoặc bìa',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '680919',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '68091910',
    Description: '- - - Tấm lát (tiles)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68091990',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '680990',
    Description: '- Các sản phẩm khác:',
    Unit: '',
  },
  {
    HsCode: '68099010',
    Description: '- - Khuôn bằng thạch cao dùng trong nha khoa',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68099090',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '6810',
    Description: 'Các sản phẩm bằng xi măng, bằng bê tông hoặc đá nhân tạo, đã hoặc chưa được gia cố',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tấm lát (tiles), phiến đá lát đường, gạch xây và các sản phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '68101100',
    Description: '- - Gạch và gạch khối xây dựng',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '681019',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '68101910',
    Description: '- - - Tấm lát (tiles)',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68101990',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Các sản phẩm khác:',
    Unit: '',
  },
  {
    HsCode: '681091',
    Description: '- - Các cấu kiện làm sẵn cho xây dựng hoặc kỹ thuật dân dụng:',
    Unit: '',
  },
  {
    HsCode: '68109110',
    Description: '- - - Cọc xây dựng bằng bê tông (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '68109190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '68109900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6811',
    Description: 'Các sản phẩm bằng xi măng-amiăng, bằng xi măng-sợi xenlulô hoặc tương tự',
    Unit: '',
  },
  {
    HsCode: '681140',
    Description: '- Chứa amiăng:',
    Unit: '',
  },
  {
    HsCode: '68114010',
    Description: '- - Tấm làn sóng',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Tấm, panel, tấm lát (tiles) và các sản phẩm tương tự khác:',
    Unit: '',
  },
  {
    HsCode: '68114021',
    Description: '- - - Gạch lát nền hoặc ốp tường chứa plastic',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68114022',
    Description: '- - - Dùng để lợp mái, ốp bề mặt hoặc làm vách ngăn',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68114029',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68114030',
    Description: '- - Ống hoặc ống dẫn',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68114040',
    Description: '- - Các phụ kiện để ghép nối ống hoặc ống dẫn',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68114050',
    Description: '- - Các sản phẩm khác, của loại sử dụng cho xây dựng ',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68114090',
    Description: '- - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Không chứa amiăng:',
    Unit: '',
  },
  {
    HsCode: '68118100',
    Description: '- - Tấm làn sóng',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '681182',
    Description: '- - Tấm, panel, tấm lát (tiles) và các sản phẩm tương tự khác:',
    Unit: '',
  },
  {
    HsCode: '68118210',
    Description: '- - - Gạch lát nền hoặc ốp tường chứa plastic',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68118220',
    Description: '- - - Dùng để lợp mái, ốp bề mặt hoặc làm vách ngăn',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68118290',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '681189',
    Description: '- - Các sản phẩm khác:',
    Unit: '',
  },
  {
    HsCode: '68118910',
    Description: '- - - Ống hoặc ống dẫn',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68118920',
    Description: '- - - Các phụ kiện để ghép nối ống hoặc ống dẫn',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68118930',
    Description: '- - - Các sản phẩm khác, của loại sử dụng cho xây dựng ',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '68118990',
    Description: '- - - Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '6812',
    Description:
      'Sợi amiăng đã được gia công; các hỗn hợp với thành phần cơ bản là amiăng hoặc thành phần cơ bản là amiăng và magie carbonat; các sản phẩm làm từ hỗn hợp đó hoặc làm từ amiăng (ví dụ, chỉ, vải dệt thoi, quần áo, mũ và vật đội đầu khác, giày dép, các miếng đệm), đã hoặc chưa được gia cố, trừ các loại thuộc nhóm 68.11 hoặc 68.13',
    Unit: '',
  },
  {
    HsCode: '681280',
    Description: '- Bằng crocidolite:',
    Unit: '',
  },
  {
    HsCode: '68128020',
    Description: '- - Quần áo',
    Unit: 'kg',
  },
  {
    HsCode: '68128030',
    Description: '- - Giấy, bìa cứng và nỉ',
    Unit: 'kg',
  },
  {
    HsCode: '68128050',
    Description:
      '- - Phụ kiện quần áo, giày dép và vật đội đầu; sợi crocidolite đã được gia công; các hỗn hợp với thành phần cơ bản là crocidolite hoặc có thành phần cơ bản là crocidolite và magie carbonat; sợi và chỉ; sợi bện (cord) và dây, đã hoặc chưa bện; vải dệt kim hoặc dệt thoi',
    Unit: 'kg',
  },
  {
    HsCode: '68128090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '681291',
    Description: '- - Quần áo, phụ kiện quần áo, giày dép và vật đội đầu:',
    Unit: '',
  },
  {
    HsCode: '68129110',
    Description: '- - - Quần áo',
    Unit: 'kg',
  },
  {
    HsCode: '68129190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '681299',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '68129920',
    Description: '- - - Gạch lát nền hoặc ốp tường',
    Unit: 'kg',
  },
  {
    HsCode: '68129930',
    Description: '- - - Giấy, bìa cứng và nỉ',
    Unit: 'kg',
  },
  {
    HsCode: '68129940',
    Description: '- - - Vật liệu ghép nối làm bằng sợi amiăng ép, ở dạng tấm hoặc cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- - - Loại khác, sợi amiăng (trừ loại làm từ crocidolite) đã được gia công; các hỗn hợp với thành phần cơ bản là amiăng (trừ loại làm từ crocidolite) hoặc có thành phần cơ bản là amiăng (trừ loại làm từ crocidolite) và magie carbonat; sợi và chỉ; sợi bện (cord) và dây, đã hoặc chưa bện; vải dệt kim hoặc dệt thoi:',
    Unit: '',
  },
  {
    HsCode: '68129951',
    Description:
      '- - - - Các hỗn hợp với thành phần cơ bản là amiăng hoặc có thành phần cơ bản là amiăng và magie carbonat loại dùng để sản xuất các mặt hàng thuộc nhóm 68.13 ',
    Unit: 'kg',
  },
  {
    HsCode: '68129959',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '68129990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '6813',
    Description:
      'Vật liệu ma sát và các sản phẩm từ vật liệu ma sát (ví dụ, tấm mỏng, cuộn, dải, đoạn, đĩa, vòng đệm, tấm lót), chưa lắp ráp, để làm phanh, côn hoặc các sản phẩm tương tự, với thành phần cơ bản là amiăng, các chất khoáng khác hoặc xenlulo, đã hoặc chưa kết hợp với vật liệu dệt hoặc các vật liệu khác',
    Unit: '',
  },
  {
    HsCode: '681320',
    Description: '- Chứa amiăng:',
    Unit: '',
  },
  {
    HsCode: '68132010',
    Description: '- - Lót và đệm phanh',
    Unit: 'kg',
  },
  {
    HsCode: '68132090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Không chứa amiăng:',
    Unit: '',
  },
  {
    HsCode: '68138100',
    Description: '- - Lót và đệm phanh',
    Unit: 'kg',
  },
  {
    HsCode: '68138900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '6814',
    Description:
      'Mica đã gia công và các sản phẩm làm từ mica, kể cả mica đã được kết khối hoặc tái chế, có hoặc không có lớp nền phụ trợ bằng giấy, bìa hoặc các vật liệu khác',
    Unit: '',
  },
  {
    HsCode: '68141000',
    Description: '- Tấm, tấm mỏng và dải bằng mica đã được kết khối hoặc tái chế, có hoặc không có lớp nền phụ trợ',
    Unit: 'kg',
  },
  {
    HsCode: '68149000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '6815',
    Description:
      'Các sản phẩm bằng đá hoặc bằng các chất liệu khoáng khác (kể cả xơ carbon, các sản phẩm bằng xơ carbon và các sản phẩm làm bằng than bùn), chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Xơ carbon; các sản phẩm từ xơ carbon không phải là các sản phẩm điện; các sản phẩm khác làm từ graphit hoặc carbon khác không phải là các sản phẩm điện:',
    Unit: '',
  },
  {
    HsCode: '68151100',
    Description: '- - Xơ carbon',
    Unit: 'kg',
  },
  {
    HsCode: '68151200',
    Description: '- - Vải dệt từ xơ carbon',
    Unit: 'kg',
  },
  {
    HsCode: '681513',
    Description: '- - Các sản phẩm khác từ xơ carbon:',
    Unit: '',
  },
  {
    HsCode: '68151310',
    Description: '- - - Sợi hoặc chỉ ',
    Unit: 'kg',
  },
  {
    HsCode: '68151390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '681519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '68151910',
    Description: '- - - Sợi hoặc chỉ ',
    Unit: 'kg',
  },
  {
    HsCode: '68151920',
    Description:
      '- - - Gạch xây, phiến lát, gạch lát sàn, các loại vật liệu dùng để lát và các sản phẩm xây dựng tương tự',
    Unit: 'kg/m2',
  },
  {
    HsCode: '68151990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '68152000',
    Description: '- Sản phẩm từ than bùn',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Các loại sản phẩm khác:',
    Unit: '',
  },
  {
    HsCode: '68159100',
    Description: '- - Có chứa magiezit, magiê oxit ở dạng periclase, dolomit kể cả ở dạng dolime, hoặc cromit',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '68159900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 69',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đồ gốm, sứ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này chỉ áp dụng đối với những sản phẩm gốm, sứ đã được nung sau khi tạo hình:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các nhóm từ 69.04 đến 69.14 chỉ áp dụng đối với những sản phẩm như vậy trừ những sản phẩm được phân loại trong các nhóm từ 69.01 đến 69.03;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các sản phẩm được gia nhiệt ở nhiệt độ dưới 800°C cho các mục đích như đóng rắn nhựa, tăng tốc phản ứng hydrat hóa, hoặc để loại bỏ nước hoặc các thành phần dễ bay hơi khác, không được coi là nung. Các sản phẩm như vậy bị loại trừ khỏi Chương 69; và ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các sản phẩm gốm, sứ thu được bằng cách nung các vật liệu phi kim loại, vô cơ đã được chuẩn bị và tạo hình trước đó ở nhiệt độ phòng nói chung. Các nguyên liệu thô bao gồm, không kể những đề cập khác, đất sét, vật liệu silic kể cả silica nung chảy, các vật liệu có nhiệt độ nóng chảy cao, như oxit, carbua, nitrua, graphit hoặc carbon khác, và trong một số trường hợp là chất kết dính như phốt phát hoặc đất sét chịu lửa. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Các sản phẩm thuộc nhóm 28.44;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các sản phẩm thuộc nhóm 68.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các sản phẩm thuộc Chương 71 (ví dụ, đồ trang sức làm bằng chất liệu khác);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Gốm kim loại thuộc nhóm 81.13;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các sản phẩm thuộc Chương 82;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Vật cách điện (nhóm 85.46) hoặc các phụ kiện làm bằng vật liệu cách điện thuộc nhóm 85.47;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Răng giả (nhóm 90.21);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Các sản phẩm thuộc Chương 91 (ví dụ, đồng hồ thời gian và vỏ đồng hồ thời gian);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Các sản phẩm thuộc Chương 94 (ví dụ, đồ nội thất, đèn (luminaires) và bộ đèn, nhà lắp ghép);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các sản phẩm của Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi và dụng cụ, thiết bị thể thao);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các sản phẩm thuộc nhóm 96.06 (ví dụ, khuy) hoặc thuộc nhóm 96.14 (ví dụ, tẩu hút thuốc); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Các sản phẩm thuộc Chương 97 (ví dụ, các tác phẩm nghệ thuật).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG I',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'CÁC LOẠI HÀNG HÓA BẰNG BỘT HÓA THẠCH SILIC HOẶC BẰNG CÁC LOẠI ĐẤT SILIC TƯƠNG TỰ, VÀ CÁC SẢN PHẦM CHỊU LỬA',
    Unit: '',
  },
  {
    HsCode: '69010000',
    Description:
      'Gạch, gạch khối, tấm lát (tiles) và các loại hàng gốm, sứ khác làm từ bột silic hóa thạch (ví dụ, đất tảo cát, đá tảo silic hoặc diatomit) hoặc từ các loại đất silic tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6902',
    Description:
      'Gạch, gạch khối, tấm lát (tiles) chịu lửa và các loại vật liệu xây dựng bằng gốm, sứ chịu lửa tương tự, trừ các sản phẩm làm bằng bột silic hóa thạch hoặc đất silic tương tự',
    Unit: '',
  },
  {
    HsCode: '69021000',
    Description:
      '- Có hàm lượng trên 50% tính theo khối lượng là một hoặc nhiều nguyên tố magie (Mg), canxi (Ca) hoặc crom (Cr), thể hiện ở dạng magie oxit (MgO), canxi oxit (CaO) hoặc crom oxit (Cr2O3)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69022000',
    Description:
      '- Có hàm lượng trên 50% tính theo khối lượng là oxit nhôm (Al2O3), đioxit silic (SiO2) hoặc hỗn hợp hoặc hợp chất của các chất này',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69029000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6903',
    Description:
      'Các loại hàng gốm, sứ chịu lửa khác (ví dụ, bình cổ cong, nồi nấu kim loại, lò muffle, nút, phích cắm, giá đỡ, chén thử vàng bạc, các loại ống, ống dẫn, bao vỏ, tay cầm và cửa trượt), trừ các sản phẩm làm bằng bột silic hóa thạch hoặc đất silic tương tự',
    Unit: '',
  },
  {
    HsCode: '69031000',
    Description: '- Có hàm lượng trên 50% tính theo khối lượng là carbon tự do',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69032000',
    Description:
      '- Có hàm lượng trên 50% tính theo khối lượng là oxit nhôm (Al2O3) hoặc hỗn hợp hoặc hợp chất của oxit nhôm và của đioxit silic (SiO2)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69039000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG II',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'CÁC SẢN PHẨM GỐM, SỨ KHÁC',
    Unit: '',
  },
  {
    HsCode: '6904',
    Description: 'Gạch xây dựng, gạch khối lát nền, tấm đỡ hoặc tấm lót và các loại tương tự bằng gốm, sứ',
    Unit: '',
  },
  {
    HsCode: '69041000',
    Description: '- Gạch xây dựng',
    Unit: 'kg/m2/1000 viên',
  },
  {
    HsCode: '69049000',
    Description: '- Loại khác',
    Unit: 'kg/m2',
  },
  {
    HsCode: '6905',
    Description:
      'Ngói lợp, ống khói, chụp ống khói, lớp lót trong ống khói, hàng trang trí kiến trúc và hàng xây dựng bằng gốm, sứ khác',
    Unit: '',
  },
  {
    HsCode: '69051000',
    Description: '- Ngói lợp mái',
    Unit: 'kg/m2/1000 viên',
  },
  {
    HsCode: '69059000',
    Description: '- Loại khác',
    Unit: 'kg/m2',
  },
  {
    HsCode: '69060000',
    Description: 'Ống dẫn, máng dẫn, máng thoát nước và các phụ kiện để lắp ráp bằng gốm, sứ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6907',
    Description:
      'Các loại phiến lát đường và gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường bằng gốm, sứ; các khối khảm và các loại sản phẩm tương tự bằng gốm, sứ, có hoặc không có lớp nền; các sản phẩm gốm, sứ để hoàn thiện',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Các loại phiến lát đường và gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường trừ loại thuộc phân nhóm 6907.30 và 6907.40:',
    Unit: '',
  },
  {
    HsCode: '690721',
    Description: '- - Của loại có độ hút nước không quá 0,5% tính theo trọng lượng:',
    Unit: '',
  },
  {
    HsCode: '69072110',
    Description: '- - - Tấm lót của loại sử dụng cho máy nghiền, không tráng men(SEN)',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, có mặt lớn nhất có thể nằm gọn trong một hình vuông có cạnh dưới 7 cm:',
    Unit: '',
  },
  {
    HsCode: '69072121',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072122',
    Description: '- - - - Loại khác, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072123',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072124',
    Description: '- - - - Loại khác, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '69072191',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072192',
    Description: '- - - - Loại khác, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072193',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072194',
    Description: '- - - - Loại khác, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '690722',
    Description: '- - Của loại có độ hút nước trên 0,5% nhưng không quá 10% tính theo trọng lượng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có mặt lớn nhất có thể nằm gọn trong một hình vuông có cạnh dưới 7 cm:',
    Unit: '',
  },
  {
    HsCode: '69072211',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072212',
    Description: '- - - - Loại khác, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072213',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072214',
    Description: '- - - - Loại khác, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '69072291',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072292',
    Description: '- - - - Loại khác, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072293',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072294',
    Description: '- - - - Loại khác, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '690723',
    Description: '- - Của loại có độ hút nước trên 10% tính theo trọng lượng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có mặt lớn nhất có thể nằm gọn trong một hình vuông có cạnh dưới 7 cm:',
    Unit: '',
  },
  {
    HsCode: '69072311',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072312',
    Description: '- - - - Loại khác, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072313',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072314',
    Description: '- - - - Loại khác, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '69072391',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072392',
    Description: '- - - - Loại khác, không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072393',
    Description: '- - - - Gạch lát đường, lát nền và lòng lò hoặc gạch ốp tường, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69072394',
    Description: '- - - - Loại khác, đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '690730',
    Description: '- Khối khảm và các sản phẩm tương tự, trừ loại thuộc phân nhóm 6907.40:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Không tráng men:',
    Unit: '',
  },
  {
    HsCode: '69073011',
    Description: '- - - Có mặt lớn nhất có thể nằm gọn trong một hình vuông có cạnh dưới 7 cm',
    Unit: 'm2',
  },
  {
    HsCode: '69073019',
    Description: '- - - Loại khác',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '69073091',
    Description: '- - - Có mặt lớn nhất có thể nằm gọn trong một hình vuông có cạnh dưới 7 cm',
    Unit: 'm2',
  },
  {
    HsCode: '69073099',
    Description: '- - - Loại khác',
    Unit: 'm2',
  },
  {
    HsCode: '690740',
    Description: '- Các sản phẩm gốm, sứ để hoàn thiện:',
    Unit: '',
  },
  {
    HsCode: '69074010',
    Description: '- - Của loại sử dụng để lót máy nghiền, không tráng men',
    Unit: 'kg/m2',
  },
  {
    HsCode: '',
    Description: '- - Các sản phẩm khác, có mặt lớn nhất có thể nhìn thấy sau khi ghép không quá 49 cm2:',
    Unit: '',
  },
  {
    HsCode: '69074021',
    Description: '- - - Không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69074022',
    Description: '- - - Đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '69074091',
    Description: '- - - Không tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '69074092',
    Description: '- - - Đã tráng men',
    Unit: 'm2',
  },
  {
    HsCode: '6909',
    Description:
      'Đồ gốm sứ dùng trong phòng thí nghiệm, dùng trong lĩnh vực hóa học hoặc kỹ thuật khác; máng, chậu và các vật chứa tương tự dùng trong nông nghiệp bằng gốm, sứ; bình, hũ, liễn và các sản phẩm tương tự bằng gốm, sứ dùng trong việc chuyên chở hoặc đóng hàng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đồ gốm sứ dùng trong phòng thí nghiệm, dùng trong hóa học hoặc kỹ thuật khác:',
    Unit: '',
  },
  {
    HsCode: '69091100',
    Description: '- - Bằng sứ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69091200',
    Description: '- - Các sản phẩm có độ cứng tương đương từ 9 trở lên trong thang đo độ cứng Mohs',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69091900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69099000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6910',
    Description:
      'Bồn rửa, chậu rửa, bệ chậu rửa, bồn tắm, bệ rửa vệ sinh (bidets), bệ xí, bình xối nước, bệ đi tiểu và các sản phẩm vệ sinh tương tự bằng gốm, sứ gắn cố định',
    Unit: '',
  },
  {
    HsCode: '69101000',
    Description: '- Bằng sứ',
    Unit: 'chiếc',
  },
  {
    HsCode: '69109000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '6911',
    Description: 'Bộ đồ ăn, bộ đồ nhà bếp, đồ gia dụng và đồ sứ vệ sinh khác, bằng sứ',
    Unit: '',
  },
  {
    HsCode: '69111000',
    Description: '- Bộ đồ ăn và bộ đồ nhà bếp',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '69119000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '69120000',
    Description: 'Bộ đồ ăn, bộ đồ nhà bếp, đồ gia dụng và đồ vệ sinh bằng gốm, trừ loại bằng sứ',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '6913',
    Description: 'Các loại tượng nhỏ và các loại sản phẩm trang trí bằng gốm, sứ khác',
    Unit: '',
  },
  {
    HsCode: '691310',
    Description: '- Bằng sứ:',
    Unit: '',
  },
  {
    HsCode: '69131010',
    Description: '- - Hộp đựng thuốc lá và gạt tàn trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69131090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '691390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '69139010',
    Description: '- - Hộp đựng thuốc lá và gạt tàn trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69139090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '6914',
    Description: 'Các sản phẩm bằng gốm, sứ khác',
    Unit: '',
  },
  {
    HsCode: '69141000',
    Description: '- Bằng sứ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '69149000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 70',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thủy tinh và các sản phẩm bằng thủy tinh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Hàng hóa thuộc nhóm 32.07 (ví dụ, men kính và men sứ, frit thủy tinh, thủy tinh khác ở dạng bột, hạt hoặc vẩy);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các sản phẩm thuộc Chương 71 (ví dụ, đồ trang sức làm bằng chất liệu khác);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Cáp sợi quang thuộc nhóm 85.44, vật cách điện (nhóm 85.46) hoặc các phụ kiện bằng vật liệu cách điện thuộc nhóm 85.47;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Kính chắn gió phía trước (kính chắn gió), cửa sổ phía sau và các cửa sổ khác, có khung, dùng cho các loại phương tiện thuộc Chương 86 đến Chương 88;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Kính chắn gió phía trước (kính chắn gió), cửa sổ phía sau và các cửa sổ khác, có hoặc không có khung, có gắn thiết bị sưởi hoặc các thiết bị điện hoặc điện tử khác, dùng cho các loại phương tiện thuộc Chương 86 đến Chương 88;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Sợi quang học, các bộ phận quang học đã được gia công quang học, ống tiêm dưới da, mắt giả, nhiệt kế, dụng cụ đo khí áp, máy đo tỷ trọng chất lỏng hoặc các sản phẩm khác của Chương 90;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Đèn (luminaires) hoặc bộ đèn, các biển hộp được chiếu sáng, các biển đề tên được chiếu sáng hoặc các loại tương tự, có nguồn sáng cố định thường xuyên, hoặc các bộ phận của chúng thuộc nhóm 94.05;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao, các đồ trang trí cây thông Nô-en hoặc các sản phẩm khác thuộc Chương 95 (trừ mắt thủy tinh không có bộ phận điều khiển, dùng cho búp bê hoặc cho các sản phẩm khác thuộc Chương 95); hoặc ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Khuy, bình chân không, bình xịt dầu thơm hoặc các bình xịt tương tự hoặc các sản phẩm khác thuộc Chương 96.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Theo mục đích của các nhóm 70.03, 70.04 và 70.05:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) thủy tinh không được coi là ""đã gia công"" bởi bất cứ một quá trình nào trước khi tôi luyện;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) việc cắt thành hình không ảnh hưởng đến phân loại thủy tinh dạng tấm;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) khái niệm ""lớp hấp thụ, lớp phản chiếu hoặc không phản chiếu"" có nghĩa là lớp phủ cực mỏng bằng kim loại hoặc bằng hợp chất hóa học (ví dụ oxit kim loại) mà lớp đó có tác dụng hấp thụ, ví dụ, hấp thụ tia hồng ngoại hoặc để tăng tính phản chiếu của kính trong khi vẫn giữ được độ trong suốt hoặc mờ; hoặc không cho ánh sáng phản chiếu từ bề mặt của kính.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Những sản phẩm được kể đến trong nhóm 70.06 vẫn được phân loại trong nhóm đó cho dù có hoặc không có đặc trưng của hàng hóa.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '4. Theo mục đích của nhóm 70.19, khái niệm ""len thủy tinh"" có nghĩa là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Len khoáng vật với hàm lượng dioxit silic (SiO2) không dưới 60% tính theo trọng lượng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Len khoáng vật với hàm lượng dioxit silic (SiO2) dưới 60% tính theo trọng lượng nhưng với một hàm lượng oxit kiềm (K2O hoặc Na2O) trên 5% tính theo trọng lượng hoặc hàm lượng oxit boric (B2O3) trên 2% tính theo trọng lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Len khoáng vật không có những đặc điểm kỹ thuật ở trên được phân loại trong nhóm 68.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Trong bảng Danh mục này, khái niệm ""thủy tinh"" kể cả thạch anh nấu chảy và dioxit silic nấu chảy khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của các phân nhóm 7013.22, 7013.33, 7013.41 và 7013.91, khái niệm ""pha lê chì"" có nghĩa là chỉ loại thủy tinh có hàm lượng chì monoxit (PbO) tối thiểu là 24% theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '70010000',
    Description:
      'Thủy tinh vụn và thủy tinh phế liệu và mảnh vụn khác, trừ thủy tinh từ ống đèn tia ca-tốt hoặc thủy tinh hoạt tính khác thuộc nhóm 85.49; thủy tinh ở dạng khối',
    Unit: 'kg',
  },
  {
    HsCode: '7002',
    Description:
      'Thủy tinh ở dạng hình cầu (trừ loại vi cầu thủy tinh thuộc nhóm 70.18), dạng thanh hoặc ống, chưa gia công',
    Unit: '',
  },
  {
    HsCode: '70021000',
    Description: '- Dạng hình cầu ',
    Unit: 'kg',
  },
  {
    HsCode: '70022000',
    Description: '- Dạng thanh',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Dạng ống:',
    Unit: '',
  },
  {
    HsCode: '700231',
    Description: '- - Bằng thạch anh nung chảy hoặc các dạng dioxit silic nung chảy khác:',
    Unit: '',
  },
  {
    HsCode: '70023110',
    Description: '- - - Loại sử dụng để sản xuất ống chân không(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '70023190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '700232',
    Description:
      '- - Bằng thủy tinh khác có hệ số giãn nở tuyến tính không quá 5 x10-6 độ Kelvin khi ở nhiệt độ từ 0oC đến 300oC:',
    Unit: '',
  },
  {
    HsCode: '70023210',
    Description: '- - - Loại sử dụng để sản xuất ống chân không(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '70023230',
    Description: '- - - Ống thủy tinh borosilicate của loại sử dụng để sản xuất ống dạng phial/ampoule',
    Unit: 'kg',
  },
  {
    HsCode: '70023240',
    Description:
      '- - - Loại khác, của loại thủy tinh borosilicate trung tính trong suốt, có đường kính từ 3 mm đến 22 mm',
    Unit: 'kg',
  },
  {
    HsCode: '70023290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '700239',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70023910',
    Description: '- - - Loại sử dụng để sản xuất ống chân không(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '70023920',
    Description:
      '- - - Loại khác, của loại thủy tinh borosilicate trung tính trong suốt, có đường kính từ 3 mm đến 22 mm',
    Unit: 'kg',
  },
  {
    HsCode: '70023990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7003',
    Description:
      'Thủy tinh đúc và thủy tinh cán, ở dạng tấm hoặc dạng hình, đã hoặc chưa tráng lớp hấp thụ, lớp phản chiếu hoặc không phản chiếu, nhưng chưa gia công cách khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dạng tấm không có cốt lưới:',
    Unit: '',
  },
  {
    HsCode: '700312',
    Description:
      '- - Thủy tinh được phủ màu toàn bộ, mờ, sáng hoặc có tráng lớp hấp thụ, lớp phản chiếu hoặc không phản chiếu:',
    Unit: '',
  },
  {
    HsCode: '70031210',
    Description: '- - - Thủy tinh quang học, chưa được gia công về mặt quang học',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70031220',
    Description: '- - - Loại khác, hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70031290',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '700319',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70031910',
    Description: '- - - Thủy tinh quang học, chưa được gia công về mặt quang học',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70031920',
    Description: '- - - Loại khác, hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70031990',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '700320',
    Description: '- Dạng tấm có cốt lưới:',
    Unit: '',
  },
  {
    HsCode: '70032010',
    Description: '- - Hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70032090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '700330',
    Description: '- Dạng hình:',
    Unit: '',
  },
  {
    HsCode: '70033010',
    Description: '- - Hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70033090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '7004',
    Description:
      'Kính kéo và kính thổi, ở dạng tấm, đã hoặc chưa tráng lớp hấp thụ, lớp phản chiếu hoặc không phản chiếu, nhưng chưa gia công cách khác',
    Unit: '',
  },
  {
    HsCode: '700420',
    Description:
      '- Kính, được phủ màu toàn bộ, mờ, sáng hoặc có tráng lớp hấp thụ, lớp phản chiếu hoặc không phản chiếu:',
    Unit: '',
  },
  {
    HsCode: '70042010',
    Description: '- - Kính quang học, chưa được gia công về mặt quang học',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70042020',
    Description: '- - Loại khác, hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70042090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '700490',
    Description: '- Loại kính khác:',
    Unit: '',
  },
  {
    HsCode: '70049010',
    Description: '- - Kính quang học, chưa được gia công về mặt quang học',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70049020',
    Description: '- - Loại khác, hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70049090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '7005',
    Description:
      'Kính nổi và kính đã mài hoặc đánh bóng bề mặt, ở dạng tấm, đã hoặc chưa tráng lớp hấp thụ, lớp phản chiếu hoặc không phản chiếu, nhưng chưa gia công cách khác',
    Unit: '',
  },
  {
    HsCode: '700510',
    Description: '- Kính không có cốt lưới, có tráng lớp hấp thụ, lớp phản chiếu hoặc không phản chiếu:',
    Unit: '',
  },
  {
    HsCode: '70051010',
    Description: '- - Kính quang học, chưa được gia công về mặt quang học',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70051090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Kính không có cốt lưới khác:',
    Unit: '',
  },
  {
    HsCode: '700521',
    Description: '- - Phủ màu toàn bộ, mờ, sáng hoặc chỉ mài bề mặt:',
    Unit: '',
  },
  {
    HsCode: '70052110',
    Description: '- - - Kính quang học, chưa được gia công về mặt quang học',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70052120',
    Description: '- - - Loại khác, hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70052190',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '700529',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70052910',
    Description: '- - - Kính quang học, chưa được gia công về mặt quang học',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70052920',
    Description: '- - - Loại khác, hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70052990',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '700530',
    Description: '- Kính có cốt lưới:',
    Unit: '',
  },
  {
    HsCode: '70053010',
    Description: '- - Hình vuông hoặc hình chữ nhật, đã hoặc chưa cắt một hoặc nhiều góc (SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70053090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '7006',
    Description:
      'Kính thuộc các nhóm 70.03, 70.04 hoặc 70.05, đã uốn cong, gia công cạnh, khắc, khoan, tráng hoặc gia công cách khác, nhưng chưa làm khung hoặc lắp với các vật liệu khác',
    Unit: '',
  },
  {
    HsCode: '70060010',
    Description: '- Kính quang học, chưa được gia công về mặt quang học',
    Unit: 'kg/m2',
  },
  {
    HsCode: '70060090',
    Description: '- Loại khác',
    Unit: 'kg/m2',
  },
  {
    HsCode: '7007',
    Description: 'Kính an toàn, bao gồm kính tôi hoặc kính dán nhiều lớp (laminated glass)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Kính tôi an toàn:',
    Unit: '',
  },
  {
    HsCode: '700711',
    Description: '- - Có kích cỡ và hình dạng phù hợp với từng loại xe, phương tiện bay, tàu vũ trụ hoặc tàu thuyền:',
    Unit: '',
  },
  {
    HsCode: '70071110',
    Description: '- - - Phù hợp dùng cho xe thuộc Chương 87',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70071120',
    Description: '- - - Phù hợp dùng cho phương tiện bay hoặc tàu vũ trụ thuộc Chương 88',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70071190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '700719',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70071910',
    Description: '- - - Phù hợp dùng cho các máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70071990',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- Kính dán an toàn nhiều lớp:',
    Unit: '',
  },
  {
    HsCode: '700721',
    Description: '- - Có kích cỡ và hình dạng phù hợp với từng loại xe, phương tiện bay, tàu vũ trụ hoặc tàu thuyền:',
    Unit: '',
  },
  {
    HsCode: '70072110',
    Description: '- - - Phù hợp dùng cho xe thuộc Chương 87',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70072120',
    Description: '- - - Phù hợp dùng cho phương tiện bay hoặc tàu vũ trụ thuộc Chương 88',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70072190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '700729',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70072910',
    Description: '- - - Phù hợp dùng cho các máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70072990',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '70080000',
    Description: 'Kính hộp nhiều lớp ',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '7009',
    Description: 'Gương thủy tinh, có hoặc không có khung, kể cả gương chiếu hậu',
    Unit: '',
  },
  {
    HsCode: '70091000',
    Description: '- Gương chiếu hậu dùng cho xe',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70099100',
    Description: '- - Chưa có khung',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70099200',
    Description: '- - Có khung',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7010',
    Description:
      'Bình lớn có vỏ bọc ngoài, chai, bình thót cổ, lọ, ống, ống dạng phial, ống dạng ampoule và các loại đồ chứa khác, bằng thủy tinh, dùng trong vận chuyển hoặc đóng hàng; lọ, bình bảo quản bằng thủy tinh; nút chai, nắp đậy và các loại nắp khác, bằng thủy tinh',
    Unit: '',
  },
  {
    HsCode: '70101000',
    Description: '- Ống dạng ampoule',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70102000',
    Description: '- Nút chai, nắp đậy và các loại nắp khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '701090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70109010',
    Description: '- - Bình lớn có vỏ bọc ngoài và bình thót cổ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70109040',
    Description:
      '- - Chai, lọ và ống dạng phial, để đựng thuốc kháng sinh, huyết thanh và các chất tiêm truyền khác; chai để đựng dung dịch tiêm, truyền tĩnh mạch',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70109091',
    Description: '- - - Có dung tích trên 1 lít',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70109099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7011',
    Description:
      'Vỏ bóng đèn thủy tinh (kể cả bóng dạng bầu và dạng ống), dạng hở, và các bộ phận bằng thủy tinh của vỏ bóng đèn, chưa có các bộ phận lắp ghép, dùng cho đèn điện và các nguồn sáng, ống đèn tia ca-tốt hoặc các loại tương tự',
    Unit: '',
  },
  {
    HsCode: '701110',
    Description: '- Dùng cho đèn điện:',
    Unit: '',
  },
  {
    HsCode: '70111010',
    Description: '- - Trụ (stem) giữ dây tóc bóng đèn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70111090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70112000',
    Description: '- Dùng cho ống đèn tia ca-tốt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70119000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7013',
    Description:
      'Bộ đồ ăn, đồ nhà bếp, đồ vệ sinh, đồ dùng văn phòng, đồ trang trí nội thất hoặc đồ dùng cho các mục đích tương tự bằng thủy tinh (trừ các sản phẩm thuộc nhóm 70.10 hoặc 70.18)',
    Unit: '',
  },
  {
    HsCode: '70131000',
    Description: '- Bằng gốm thủy tinh',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Cốc (ly) có chân, bằng thủy tinh, trừ loại bằng gốm thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '70132200',
    Description: '- - Bằng pha lê chì',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '70132800',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Cốc (ly) bằng thủy tinh khác, trừ loại bằng gốm thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '70133300',
    Description: '- - Bằng pha lê chì',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '70133700',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Bộ đồ ăn (trừ bộ đồ dùng để uống) hoặc đồ nhà bếp bằng thủy tinh, trừ loại bằng gốm thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '70134100',
    Description: '- - Bằng pha lê chì',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '70134200',
    Description:
      '- - Bằng thủy tinh có hệ số giãn nở tuyến tính không quá 5 x 10-6 độ Kelvin khi ở nhiệt độ từ 0oC đến 300oC',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '70134900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Đồ dùng bằng thủy tinh khác:',
    Unit: '',
  },
  {
    HsCode: '70139100',
    Description: '- - Bằng pha lê chì',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '70139900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc/bộ',
  },
  {
    HsCode: '7014',
    Description:
      'Dụng cụ tín hiệu bằng thủy tinh và các bộ phận quang học bằng thủy tinh (trừ những sản phẩm thuộc nhóm 70.15), chưa được gia công về mặt quang học',
    Unit: '',
  },
  {
    HsCode: '70140010',
    Description: '- Loại phù hợp dùng cho xe có động cơ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70140090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7015',
    Description:
      'Kính đồng hồ thời gian hoặc kính đồng hồ cá nhân và các loại kính tương tự, các loại kính đeo để hiệu chỉnh hoặc không hiệu chỉnh, được uốn cong, làm lồi, lõm hoặc tương tự, chưa được gia công về mặt quang học; hạt cầu thủy tinh rỗng và mảnh của chúng, dùng để sản xuất các loại kính trên',
    Unit: '',
  },
  {
    HsCode: '70151000',
    Description: '- Các loại kính hiệu chỉnh dùng cho kính đeo mắt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '701590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70159010',
    Description: '- - Kính đồng hồ thời gian hoặc đồng hồ cá nhân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70159090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7016',
    Description:
      'Khối lát, tấm, gạch, tấm vuông, tấm lát (tiles) và các sản phẩm khác bằng thủy tinh ép hoặc thủy tinh đúc, có hoặc không có cốt lưới, thuộc loại được sử dụng trong xây dựng hoặc mục đích xây dựng; khối thủy tinh nhỏ và đồ thủy tinh nhỏ khác, có hoặc không có lớp lót nền, dùng để khảm hoặc cho các mục đích trang trí tương tự; đèn phủ chì và các loại tương tự; thủy tinh đa phân tử hoặc thủy tinh bọt dạng khối, panel, tấm, lớp, vỏ hoặc các dạng tương tự',
    Unit: '',
  },
  {
    HsCode: '70161000',
    Description:
      '- Thủy tinh dạng khối và đồ thủy tinh nhỏ khác, có hoặc không có lớp lót nền, dùng để khảm hoặc các mục đích trang trí tương tự',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '70169000',
    Description: '- Loại khác',
    Unit: 'kg/m2/chiếc',
  },
  {
    HsCode: '7017',
    Description:
      'Đồ thủy tinh dùng cho phòng thí nghiệm, cho vệ sinh hoặc dược phẩm, đã hoặc chưa được chia độ hoặc định cỡ',
    Unit: '',
  },
  {
    HsCode: '701710',
    Description: '- Bằng thạch anh nung chảy hoặc các dạng dioxit silic nung chảy khác:',
    Unit: '',
  },
  {
    HsCode: '70171010',
    Description:
      '- - Ống thạch anh dùng trong lò phản ứng và vòng kẹp được thiết kế để chèn vào lò luyện khuếch tán và lò ôxi hóa để sản xuất tấm bán dẫn mỏng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70171090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70172000',
    Description:
      '- Bằng thủy tinh khác có hệ số giãn nở tuyến tính không quá 5 x 10-6 độ Kelvin khi ở nhiệt độ từ 0oC đến 300oC',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70179000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7018',
    Description:
      'Hạt bi thủy tinh, thủy tinh giả ngọc trai, thủy tinh giả đá quý hoặc đá bán quý và các đồ vật nhỏ tương tự bằng thủy tinh, và các sản phẩm làm từ các loại trên trừ đồ trang sức làm bằng chất liệu khác; mắt thủy tinh trừ các bộ phận cơ thể giả khác; tượng nhỏ và các đồ trang trí khác bằng thủy tinh được gia công bằng đèn xì (lamp-worked), trừ đồ trang sức làm bằng chất liệu khác; vi cầu thủy tinh có đường kính không quá 1 mm',
    Unit: '',
  },
  {
    HsCode: '701810',
    Description:
      '- Hạt bi thủy tinh, thủy tinh giả ngọc trai, thủy tinh giả đá quý hoặc đá bán quý và các đồ vật nhỏ tương tự bằng thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '70181010',
    Description: '- - Hạt bi thủy tinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70181090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70182000',
    Description: '- Vi cầu thủy tinh có đường kính không quá 1 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '701890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70189010',
    Description: '- - Mắt thủy tinh(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70189090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7019',
    Description:
      'Sợi thủy tinh (kể cả len thủy tinh) và các sản phẩm của nó (ví dụ, sợi xe (yarn), sợi thô, vải dệt thoi)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Cúi sợi (sliver), sợi thô, sợi xe (yarn) và sợi bện đã cắt đoạn và chiếu (mat) làm từ chúng:',
    Unit: '',
  },
  {
    HsCode: '70191100',
    Description: '- - Sợi bện đã cắt đoạn, chiều dài không quá 50 mm',
    Unit: 'kg',
  },
  {
    HsCode: '70191200',
    Description: '- - Sợi thô',
    Unit: 'kg',
  },
  {
    HsCode: '70191300',
    Description: '- - Cúi sợi (sliver), sợi xe (yarn) khác',
    Unit: 'kg',
  },
  {
    HsCode: '70191400',
    Description: '- - Chiếu (mat) được liên kết bằng cơ học',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70191500',
    Description: '- - Chiếu (mat) được liên kết hóa học',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70191900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Vải được liên kết bằng cơ học:',
    Unit: '',
  },
  {
    HsCode: '70196100',
    Description: '- - Vải dệt thoi khổ đóng từ sợi thô',
    Unit: 'kg',
  },
  {
    HsCode: '70196200',
    Description: '- - Vải dệt khổ đóng khác từ sợi thô',
    Unit: 'kg',
  },
  {
    HsCode: '70196300',
    Description: '- - Vải dệt thoi khổ đóng, dệt vân điểm, từ sợi xe, không tráng hoặc ép lớp',
    Unit: 'kg',
  },
  {
    HsCode: '70196400',
    Description: '- - Vải dệt thoi khổ đóng, dệt vân điểm, tù sợi xe, được tráng hoặc ép lớp',
    Unit: 'kg',
  },
  {
    HsCode: '70196500',
    Description: '- - Vải dệt thoi khổ mở có chiều rộng không quá 30 cm',
    Unit: 'kg',
  },
  {
    HsCode: '70196600',
    Description: '- - Vải dệt thoi khổ mở có chiều rộng trên 30 cm',
    Unit: 'kg',
  },
  {
    HsCode: '70196900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Vải dệt được liên kết hóa học:',
    Unit: '',
  },
  {
    HsCode: '70197100',
    Description: '- - Voan (tấm mỏng)',
    Unit: 'kg',
  },
  {
    HsCode: '70197200',
    Description: '- - Vải dệt khổ đóng khác',
    Unit: 'kg',
  },
  {
    HsCode: '70197300',
    Description: '- - Vải dệt khổ mở khác',
    Unit: 'kg',
  },
  {
    HsCode: '701980',
    Description: '- Len thủy tinh và các sản phẩm từ len thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '70198010',
    Description: '- - Len thủy tinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70198020',
    Description: '- - Các sản phẩm từ len thủy tinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '701990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '70199020',
    Description: '- - Rèm (blinds)(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70199030',
    Description: '- - Xơ thủy tinh, trừ len thủy tinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70199040',
    Description:
      '- - Vật liệu bọc ngoài đường ống bằng sợi thủy tinh đã được thấm tẩm nhựa đường hoặc nhựa than đá(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70199050',
    Description: '- - Vải dệt thoi từ sợi thô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70199090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7020',
    Description: 'Các sản phẩm khác bằng thủy tinh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Khuôn bằng thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '70200011',
    Description: '- - Loại dùng để sản xuất các sản phẩm acrylic(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70200019',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70200020',
    Description:
      '- Ống thạch anh dùng trong lò phản ứng và vòng kẹp được thiết kế để chèn vào lò luyện khuếch tán và lò ôxi hóa để sản xuất tấm bán dẫn mỏng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70200030',
    Description: '- Ruột phích hoặc ruột bình chân không khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70200040',
    Description: '- Ống chân không sử dụng trong bình nước nóng năng lượng mặt trời',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '70200090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XIV',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'NGỌC TRAI TỰ NHIÊN HOẶC NUÔI CẤY, ĐÁ QUÝ HOẶC ĐÁ BÁN QUÝ, KIM LOẠI QUÝ, KIM LOẠI ĐƯỢC DÁT PHỦ KIM LOẠI QUÝ, VÀ CÁC SẢN PHẨM CỦA CHÚNG; ĐỒ TRANG SỨC LÀM BẰNG CHẤT LIỆU KHÁC; TIỀN KIM LOẠI',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 71',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Ngọc trai tự nhiên hoặc nuôi cấy, đá quý hoặc đá bán quý, kim loại quý, kim loại được dát phủ kim loại quý, và các sản phẩm của chúng; đồ trang sức làm bằng chất liệu khác; tiền kim loại',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo Chú giải 1 (A) của Phần VI và loại trừ những sản phẩm được liệt kê ở các mục dưới đây, thì tất cả các sản phẩm gồm toàn bộ hoặc từng phần bằng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Ngọc trai tự nhiên hoặc nuôi cấy hoặc bằng đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo), hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Kim loại quý hoặc kim loại dát phủ kim loại quý, được phân loại trong Chương này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. (A) Nhóm 71.13, 71.14 và 71.15 không bao gồm các sản phẩm mà trong đó kim loại quý hoặc kim loại được dát phủ kim loại quý chỉ đóng vai trò như là thành phần phụ, chẳng hạn như các đồ đính kèm hoặc đồ trang trí phụ (ví dụ, chữ lồng, đai và viền), và mục (b) của Chú giải trên không áp dụng cho các sản phẩm này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Nhóm 71.16 không bao gồm các sản phẩm có chứa kim loại quý hoặc kim loại dát phủ kim loại quý (trừ khi chúng được coi như là thành phần phụ).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hỗn hống của kim loại quý, hoặc kim loại quý dạng keo (nhóm 28.43);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Vật liệu khâu vô trùng trong phẫu thuật, các chất hàn răng hoặc các hàng hóa khác thuộc Chương 30;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các sản phẩm thuộc Chương 32 (ví dụ, các chất bóng);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các chất xúc tác có nền (nhóm 38.15);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các sản phẩm thuộc nhóm 42.02 hoặc 42.03 ghi trong Chú giải 3 (B) của Chương 42;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các sản phẩm thuộc nhóm 43.03 hoặc 43.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Hàng hóa thuộc Phần XI (nguyên liệu dệt và các sản phẩm dệt);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Giày dép, mũ và các vật đội đầu khác hoặc các sản phẩm khác thuộc Chương 64 hoặc 65;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Ô dù, batoong, gậy chống hoặc các sản phẩm khác thuộc Chương 66;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(k) Những sản phẩm dùng để mài mòn thuộc nhóm 68.04 hoặc 68.05 hoặc Chương 82, có chứa bụi hoặc bột đá quý hoặc đá bán quý (tự nhiên hoặc tổng hợp); các sản phẩm thuộc Chương 82 có bộ phận làm việc bằng đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo); máy móc, các thiết bị cơ khí hoặc hàng điện tử, hoặc các bộ phận của chúng, thuộc Phần XVI. Tuy nhiên, các sản phẩm và các bộ phận của chúng, toàn bộ bằng đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo) vẫn được phân loại trong Chương này, trừ đá saphia và kim cương đã được gia công nhưng chưa được gắn dùng cho đầu kim máy hát (nhóm 85.22);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(l) Các sản phẩm thuộc Chương 90, 91 hoặc 92 (dụng cụ khoa học, đồng hồ thời gian và đồng hồ cá nhân, nhạc cụ);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Vũ khí hoặc các bộ phận của chúng (thuộc Chương 93);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Các sản phẩm ghi trong Chú giải 2 của Chương 95;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(o) Các sản phẩm được phân loại trong Chương 96 theo Chú giải 4 của Chương đó; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(p) Các tác phẩm điêu khắc hoặc tượng tạc nguyên bản (nhóm 97.03), đồ sưu tập (nhóm 97.05) hoặc đồ cổ trên 100 năm tuổi (nhóm 97.06), trừ ngọc trai tự nhiên hoặc nuôi cấy hoặc đá quý hoặc đá bán quý.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '4. (A) Khái niệm ""kim loại quý"" nghĩa là bạc, vàng và bạch kim.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Khái niệm ""bạch kim"" nghĩa là platin, iridi, osmi, palladi, rodi và rutheni.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(C) Khái niệm ""đá quý hoặc đá bán quý"" không bao gồm bất cứ một loại nào đã được ghi trong Chú giải 2(b) của Chương 96.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của Chương này, hợp kim bất kỳ (kể cả hỗn hợp thiêu kết và hợp chất liên kết kim loại) có chứa kim loại quý được xem như là một hợp kim của kim loại quý nếu kim loại quý đó cấu thành bằng 2% tính theo khối lượng của hợp kim. Các hợp kim của kim loại quý được phân loại theo các quy tắc sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Một hợp kim có chứa 2% trở lên, tính theo khối lượng, là bạch kim thì được coi là một hợp kim của bạch kim;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Một hợp kim có chứa 2% trở lên, tính theo khối lượng, là vàng, nhưng không có bạch kim, hoặc có dưới 2% tính theo khối lượng, là bạch kim, thì được coi là hợp kim vàng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các hợp kim khác chứa 2% trở lên, tính theo khối lượng, là bạc thì được coi là hợp kim bạc.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Trong Danh mục này, trừ khi có yêu cầu khác, khi đề cập đến kim loại quý hoặc bất kỳ một kim loại quý cụ thể cần đề cập đến cả các hợp kim đã được coi như hợp kim của kim loại quý hoặc của kim loại quý cụ thể đó theo quy định đã nêu trong Chú giải 5 trên đây, nhưng không áp dụng với kim loại được dát phủ kim loại quý hoặc kim loại cơ bản hoặc phi kim loại dát phủ kim loại quý.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Trong toàn bộ Danh mục này khái niệm ""kim loại được dát phủ kim loại quý"" có nghĩa là vật liệu có nền là kim loại mà trên một mặt hoặc nhiều mặt của kim loại đó được phủ kim loại quý bằng cách hàn thiếc, hàn hơi, hàn điện, cán nóng hoặc các phương pháp bọc phủ cơ khí tương tự. Trừ khi có yêu cầu khác, thuật ngữ này cũng bao gồm kim loại cơ bản được khảm dát kim loại quý.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Theo Chú giải 1(A) Phần VI, các hàng hóa giống như mô tả của nhóm 71.12 được phân loại vào nhóm đó và không được xếp vào nhóm nào khác của Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '9. Theo mục đích của nhóm 71.13, khái niệm ""đồ trang sức"" có nghĩa là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các đồ vật nhỏ để trang sức cá nhân (ví dụ, nhẫn, vòng đeo tay, dây chuyền, trâm cài, hoa tai, dây đồng hồ, dây đeo đồng hồ bỏ túi, mặt dây chuyền, ghim cài cà vạt, khuy cài cổ tay áo, khuy cúc khác, huy chương và phù hiệu tôn giáo hoặc huy chương và phù hiệu khác); và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các sản phẩm sử dụng cho mục đích cá nhân loại thường bỏ túi, để trong túi xách tay hoặc mang trên người (ví dụ, hộp đựng thuốc lá điếu hoặc hộp đựng xì-gà, hộp đựng thuốc lá bột, hộp đựng kẹo cao su hoặc hộp đựng thuốc viên, hộp phấn, ví tay có dây hoặc chuỗi tràng hạt).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm này có thể được kết hợp hoặc là một bộ, ví dụ, với ngọc trai tự nhiên hoặc ngọc trai nuôi cấy, đá quý hoặc đá bán quý, đá quý hoặc đá bán quý tổng hợp hoặc tái tạo, mai rùa, xà cừ, ngà, hổ phách tự nhiên hoặc tái tạo, hạt huyền hoặc san hô. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '10. Theo mục đích của nhóm 71.14, khái niệm ""đồ kỹ nghệ vàng hoặc bạc"" kể cả các sản phẩm như các đồ vật trang trí, bộ đồ ăn, đồ vệ sinh, đồ dùng của người hút thuốc và các sản phẩm khác sử dụng trong gia đình, văn phòng hoặc tôn giáo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '11. Theo mục đích của nhóm 71.17, khái niệm ""đồ trang sức làm bằng chất liệu khác"" có nghĩa là các sản phẩm trang sức như đã mô tả trong nội dung của mục (a) Chú giải 9 ở trên (nhưng không kể các loại khuy cài hoặc các sản phẩm khác thuộc nhóm 96.06, hoặc lược chải, trâm cài tóc hoặc các loại tương tự, hoặc ghim cài tóc, thuộc nhóm 96.15), không gắn ngọc trai tự nhiên hoặc nuôi cấy, đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo) cũng không gắn kim loại quý hoặc kim loại dát phủ kim loại quý (trừ trường hợp tráng mạ hoặc chỉ là thành phần phụ).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của các phân nhóm 7106.10, 7108.11, 7110.11, 7110.21, 7110.31 và 7110.41, khái niệm ""bột"" và ""dạng bột"" có nghĩa là các sản phẩm có 90% trở lên tính theo khối lượng lọt qua rây (sàng) có đường kính mắt rây (sàng) 0,5 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Mặc dù đã quy định trong Chú giải 4 (B) của Chương này, nhưng theo các phân nhóm 7110.11 và 7110.19, khái niệm ""bạch kim"" không bao gồm iridi, osmi, paladi, rodi hoặc rutheni.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo phân loại các hợp kim trong các phân nhóm của nhóm 71.10, mỗi hợp kim được phân loại theo kim loại, bạch kim, paladi, rodi, iridi, osmi hoặc rutheni có hàm lượng trội hơn so với mỗi kim loại khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG I',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'NGỌC TRAI TỰ NHIÊN HOẶC NUÔI CẤY VÀ ĐÁ QUÝ HOẶC ĐÁ BÁN QUÝ',
    Unit: '',
  },
  {
    HsCode: '7101',
    Description:
      'Ngọc trai, tự nhiên hoặc nuôi cấy, đã hoặc chưa được gia công hoặc phân loại nhưng chưa xâu thành chuỗi, chưa gắn hoặc nạm dát; ngọc trai, tự nhiên hoặc nuôi cấy, đã được xâu thành chuỗi tạm thời để tiện vận chuyển',
    Unit: '',
  },
  {
    HsCode: '71011000',
    Description: '- Ngọc trai tự nhiên',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Ngọc trai nuôi cấy:',
    Unit: '',
  },
  {
    HsCode: '71012100',
    Description: '- - Chưa được gia công',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '71012200',
    Description: '- - Đã gia công',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7102',
    Description: 'Kim cương, đã hoặc chưa được gia công, nhưng chưa được gắn hoặc nạm dát',
    Unit: '',
  },
  {
    HsCode: '71021000',
    Description: '- Kim cương chưa được phân loại',
    Unit: 'carat',
  },
  {
    HsCode: '',
    Description: '- Kim cương công nghiệp:',
    Unit: '',
  },
  {
    HsCode: '71022100',
    Description:
      '- - Chưa gia công hoặc mới chỉ được cắt, tách một cách đơn giản hoặc mới chỉ được chuốt hoặc mài sơ qua',
    Unit: 'carat',
  },
  {
    HsCode: '71022900',
    Description: '- - Loại khác',
    Unit: 'carat',
  },
  {
    HsCode: '',
    Description: '- Kim cương phi công nghiệp:',
    Unit: '',
  },
  {
    HsCode: '71023100',
    Description:
      '- - Chưa gia công hoặc mới chỉ được cắt, tách một cách đơn giản hoặc mới chỉ được chuốt hoặc mài sơ qua',
    Unit: 'carat',
  },
  {
    HsCode: '71023900',
    Description: '- - Loại khác',
    Unit: 'carat',
  },
  {
    HsCode: '7103',
    Description:
      'Đá quý (trừ kim cương) và đá bán quý, đã hoặc chưa được gia công hoặc phân loại nhưng chưa xâu chuỗi, chưa gắn hoặc nạm dát; đá quý (trừ kim cương) và đá bán quý chưa được phân loại, đã được xâu thành chuỗi tạm thời để tiện vận chuyển',
    Unit: '',
  },
  {
    HsCode: '710310',
    Description: '- Chưa gia công hoặc mới chỉ được cắt đơn giản hoặc tạo hình thô:',
    Unit: '',
  },
  {
    HsCode: '71031010',
    Description: '- - Rubi',
    Unit: 'kg',
  },
  {
    HsCode: '71031020',
    Description: '- - Ngọc bích (nephrite và jadeite)',
    Unit: 'kg',
  },
  {
    HsCode: '71031090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Đã gia công cách khác:',
    Unit: '',
  },
  {
    HsCode: '710391',
    Description: '- - Rubi, saphia và ngọc lục bảo:',
    Unit: '',
  },
  {
    HsCode: '71039110',
    Description: '- - - Rubi',
    Unit: 'carat',
  },
  {
    HsCode: '71039190',
    Description: '- - - Loại khác',
    Unit: 'carat',
  },
  {
    HsCode: '71039900',
    Description: '- - Loại khác',
    Unit: 'carat',
  },
  {
    HsCode: '7104',
    Description:
      'Đá quý hoặc đá bán quý tổng hợp hoặc tái tạo, đã hoặc chưa gia công hoặc phân loại nhưng chưa xâu thành chuỗi, chưa gắn hoặc nạm dát; đá quý hoặc đá bán quý tổng hợp hoặc tái tạo chưa phân loại, đã được xâu thành chuỗi tạm thời để tiện vận chuyển',
    Unit: '',
  },
  {
    HsCode: '710410',
    Description: '- Thạch anh áp điện:',
    Unit: '',
  },
  {
    HsCode: '71041010',
    Description: '- - Chưa được gia công',
    Unit: 'kg',
  },
  {
    HsCode: '71041020',
    Description: '- - Đã gia công',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, chưa gia công hoặc mới chỉ được cắt đơn giản hoặc tạo hình thô:',
    Unit: '',
  },
  {
    HsCode: '71042100',
    Description: '- - Kim cương',
    Unit: 'carat',
  },
  {
    HsCode: '71042900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71049100',
    Description: '- - Kim cương',
    Unit: 'carat',
  },
  {
    HsCode: '71049900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7105',
    Description: 'Bụi và bột của đá quý hoặc đá bán quý tự nhiên hoặc tổng hợp',
    Unit: '',
  },
  {
    HsCode: '71051000',
    Description: '- Của kim cương',
    Unit: 'carat',
  },
  {
    HsCode: '71059000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG II ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'KIM LOẠI QUÝ VÀ KIM LOẠI ĐƯỢC DÁT PHỦ KIM LOẠI QUÝ',
    Unit: '',
  },
  {
    HsCode: '7106',
    Description: 'Bạc (kể cả bạc được mạ vàng hoặc bạch kim), chưa gia công hoặc ở dạng bán thành phẩm, hoặc dạng bột',
    Unit: '',
  },
  {
    HsCode: '71061000',
    Description: '- Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71069100',
    Description: '- - Chưa gia công',
    Unit: 'kg',
  },
  {
    HsCode: '71069200',
    Description: '- - Dạng bán thành phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '71070000',
    Description: 'Kim loại cơ bản được dát phủ bạc, chưa gia công quá mức bán thành phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '7108',
    Description: 'Vàng (kể cả vàng mạ bạch kim) chưa gia công hoặc ở dạng bán thành phẩm, hoặc ở dạng bột',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Không phải dạng tiền tệ:',
    Unit: '',
  },
  {
    HsCode: '71081100',
    Description: '- - Dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '710812',
    Description: '- - Dạng chưa gia công khác:',
    Unit: '',
  },
  {
    HsCode: '71081210',
    Description: '- - - Dạng cục, thỏi hoặc thanh đúc',
    Unit: 'kg',
  },
  {
    HsCode: '71081290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '71081300',
    Description: '- - Dạng bán thành phẩm khác',
    Unit: 'kg',
  },
  {
    HsCode: '71082000',
    Description: '- Dạng tiền tệ',
    Unit: 'kg',
  },
  {
    HsCode: '71090000',
    Description: 'Kim loại cơ bản hoặc bạc, dát phủ vàng, chưa được gia công quá mức bán thành phẩm',
    Unit: 'kg',
  },
  {
    HsCode: '7110',
    Description: 'Bạch kim, chưa gia công hoặc ở dạng bán thành phẩm, hoặc dạng bột',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bạch kim:',
    Unit: '',
  },
  {
    HsCode: '711011',
    Description: '- - Chưa gia công hoặc ở dạng bột:',
    Unit: '',
  },
  {
    HsCode: '71101110',
    Description: '- - - Dạng cục, thỏi, thanh đúc hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '71101190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '71101900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Paladi:',
    Unit: '',
  },
  {
    HsCode: '711021',
    Description: '- - Chưa gia công hoặc ở dạng bột:',
    Unit: '',
  },
  {
    HsCode: '71102110',
    Description: '- - - Dạng cục, thỏi, thanh đúc hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '71102190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '71102900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Rodi:',
    Unit: '',
  },
  {
    HsCode: '711031',
    Description: '- - Chưa gia công hoặc ở dạng bột:',
    Unit: '',
  },
  {
    HsCode: '71103110',
    Description: '- - - Dạng cục, thỏi, thanh đúc hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '71103190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '71103900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Iridi, osmi và ruteni:',
    Unit: '',
  },
  {
    HsCode: '711041',
    Description: '- - Chưa gia công hoặc ở dạng bột:',
    Unit: '',
  },
  {
    HsCode: '71104110',
    Description: '- - - Dạng cục, thỏi, thanh đúc hoặc dạng bột',
    Unit: 'kg',
  },
  {
    HsCode: '71104190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '71104900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7111',
    Description: 'Kim loại cơ bản, bạc hoặc vàng, dát phủ bạch kim, chưa gia công quá mức bán thành phẩm',
    Unit: '',
  },
  {
    HsCode: '71110010',
    Description: '- Bạc hoặc vàng, mạ bạch kim',
    Unit: 'kg',
  },
  {
    HsCode: '71110090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7112',
    Description:
      'Phế liệu và mảnh vụn của kim loại quý hoặc kim loại dát phủ kim loại quý; phế liệu và mảnh vụn khác chứa kim loại quý hoặc các hợp chất kim loại quý, loại sử dụng chủ yếu cho việc thu hồi kim loại quý trừ hàng hóa thuộc nhóm 85.49',
    Unit: '',
  },
  {
    HsCode: '71123000',
    Description: '- Tro (xỉ) có chứa kim loại quý hoặc các hợp chất kim loại quý',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71129100',
    Description: '- - Từ vàng, kể cả kim loại dát phủ vàng trừ mảnh vụn có chứa các kim loại quý khác',
    Unit: 'kg',
  },
  {
    HsCode: '71129200',
    Description: '- - Từ bạch kim, kể cả kim loại dát phủ bạch kim trừ mảnh vụn có chứa các kim loại quý khác',
    Unit: 'kg',
  },
  {
    HsCode: '711299',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71129910',
    Description: '- - - Từ bạc, kể cả kim loại dát phủ bạc trừ mảnh vụn có chứa các kim loại quý khác ',
    Unit: 'kg',
  },
  {
    HsCode: '71129990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG III ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' ĐỒ TRANG SỨC, ĐỒ KỸ NGHỆ VÀNG BẠC VÀ CÁC SẢN PHẨM KHÁC',
    Unit: '',
  },
  {
    HsCode: '7113',
    Description:
      'Đồ trang sức và các bộ phận của đồ trang sức, bằng kim loại quý hoặc kim loại được dát phủ kim loại quý',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng kim loại quý đã hoặc chưa mạ hoặc dát phủ kim loại quý:',
    Unit: '',
  },
  {
    HsCode: '711311',
    Description: '- - Bằng bạc, đã hoặc chưa mạ hoặc dát phủ kim loại quý khác:',
    Unit: '',
  },
  {
    HsCode: '71131110',
    Description: '- - - Bộ phận',
    Unit: 'kg',
  },
  {
    HsCode: '71131190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '711319',
    Description: '- - Bằng kim loại quý khác, đã hoặc chưa mạ hoặc dát phủ kim loại quý:',
    Unit: '',
  },
  {
    HsCode: '71131910',
    Description: '- - - Bộ phận',
    Unit: 'kg',
  },
  {
    HsCode: '71131990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '711320',
    Description: '- Bằng kim loại cơ bản dát phủ kim loại quý:',
    Unit: '',
  },
  {
    HsCode: '71132010',
    Description: '- - Bộ phận',
    Unit: 'kg',
  },
  {
    HsCode: '71132090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7114',
    Description:
      'Đồ kỹ nghệ vàng hoặc bạc và các bộ phận của đồ kỹ nghệ vàng bạc, bằng kim loại quý hoặc kim loại dát phủ kim loại quý',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng kim loại quý đã hoặc chưa mạ hoặc dát phủ kim loại quý:',
    Unit: '',
  },
  {
    HsCode: '71141100',
    Description: '- - Bằng bạc, đã hoặc chưa mạ hoặc dát phủ kim loại quý khác',
    Unit: 'kg',
  },
  {
    HsCode: '71141900',
    Description: '- - Bằng kim loại quý khác, đã hoặc chưa mạ hoặc dát phủ kim loại quý',
    Unit: 'kg',
  },
  {
    HsCode: '71142000',
    Description: '- Bằng kim loại cơ bản dát phủ kim loại quý',
    Unit: 'kg',
  },
  {
    HsCode: '7115',
    Description: 'Các sản phẩm khác bằng kim loại quý hoặc kim loại dát phủ kim loại quý',
    Unit: '',
  },
  {
    HsCode: '71151000',
    Description: '- Vật xúc tác ở dạng tấm đan hoặc lưới, bằng bạch kim',
    Unit: 'kg',
  },
  {
    HsCode: '711590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71159010',
    Description: '- - Bằng vàng hoặc bạc ',
    Unit: 'kg',
  },
  {
    HsCode: '71159020',
    Description: '- - Bằng kim loại dát phủ vàng hoặc bạc',
    Unit: 'kg',
  },
  {
    HsCode: '71159090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7116',
    Description:
      'Sản phẩm bằng ngọc trai tự nhiên hoặc nuôi cấy, đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo)',
    Unit: '',
  },
  {
    HsCode: '71161000',
    Description: '- Bằng ngọc trai tự nhiên hoặc nuôi cấy',
    Unit: 'kg',
  },
  {
    HsCode: '71162000',
    Description: '- Bằng đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo)',
    Unit: 'kg',
  },
  {
    HsCode: '7117',
    Description: 'Đồ trang sức làm bằng chất liệu khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng kim loại cơ bản, đã hoặc chưa mạ kim loại quý:',
    Unit: '',
  },
  {
    HsCode: '711711',
    Description: '- - Khuy măng sét và khuy rời:',
    Unit: '',
  },
  {
    HsCode: '71171110',
    Description: '- - - Bộ phận',
    Unit: 'kg',
  },
  {
    HsCode: '71171190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '711719',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71171910',
    Description: '- - - Vòng',
    Unit: 'kg',
  },
  {
    HsCode: '71171920',
    Description: '- - - Các đồ trang sức khác',
    Unit: 'kg',
  },
  {
    HsCode: '71171990',
    Description: '- - - Bộ phận',
    Unit: 'kg',
  },
  {
    HsCode: '711790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71179010',
    Description: '- - Vòng',
    Unit: 'kg',
  },
  {
    HsCode: '71179020',
    Description: '- - Các đồ trang sức khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '71179094',
    Description:
      '- - - Làm toàn bộ bằng plastic, thủy tinh, gỗ, vật liệu khắc từ mai rùa, ngà, xương, sừng, san hô, xà cừ và các loại động vật khác đã gia công, vật liệu khắc từ thực vật đã gia công, vật liệu khắc từ khoáng sản đã gia công, hoặc bằng sứ',
    Unit: 'kg',
  },
  {
    HsCode: '71179099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7118',
    Description: 'Tiền kim loại',
    Unit: '',
  },
  {
    HsCode: '711810',
    Description: '- Tiền kim loại (trừ tiền vàng), không phải loại pháp định:',
    Unit: '',
  },
  {
    HsCode: '71181010',
    Description: '- - Tiền bằng bạc',
    Unit: 'kg',
  },
  {
    HsCode: '71181090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '711890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '71189010',
    Description: '- - Tiền bằng vàng, loại pháp định (*) hoặc không phải loại pháp định',
    Unit: 'kg',
  },
  {
    HsCode: '71189020',
    Description: '- - Tiền bằng bạc, loại pháp định (*)',
    Unit: 'kg',
  },
  {
    HsCode: '71189090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '(*): Trong phạm vi nhóm 49.07 và 71.18, khái niệm ""loại pháp định"" được hiểu là công cụ thanh toán phát hành bởi ngân hàng được pháp luật công nhận.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XV',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'KIM LOẠI CƠ BẢN VÀ CÁC SẢN PHẨM BẰNG KIM LOẠI CƠ BẢN',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Phần này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Sơn, mực hoặc các sản phẩm khác đã được pha chế với chất cơ bản là vảy hoặc bột kim loại (các nhóm từ 32.07 đến 32.10, 32.12, 32.13 hoặc 32.15);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hợp kim ferro-xeri hoặc các hợp kim tự cháy khác (nhóm 36.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Mũ và các vật đội đầu khác hoặc các bộ phận của chúng thuộc nhóm 65.06 hoặc 65.07;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Khung ô hoặc các sản phẩm khác thuộc nhóm 66.03;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Hàng hóa thuộc Chương 71 (ví dụ, hợp kim kim loại quý, kim loại cơ bản dát phủ kim loại quý, đồ trang sức làm bằng chất liệu khác);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Các sản phẩm thuộc Phần XVI (máy móc, các thiết bị cơ khí và đồ điện);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Đường ray đường sắt hoặc đường ray tàu điện đã được lắp ráp (nhóm 86.08) hoặc các sản phẩm khác thuộc Phần XVII (xe, tàu và thuyền, phương tiện bay);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Dụng cụ hoặc thiết bị phụ tùng thuộc Phần XVIII, kể cả dây cót của đồng hồ cá nhân hoặc đồng hồ thời gian khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Các viên chì nhỏ được chế tạo để sản xuất đạn dược (nhóm 93.06) hoặc các sản phẩm khác thuộc Phần XIX (vũ khí và đạn dược);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(k) Các mặt hàng thuộc Chương 94 (ví dụ, đồ nội thất, khung nệm, đèn (luminaires) và bộ đèn, biển hiệu được chiếu sáng, nhà lắp ghép);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các sản phẩm thuộc Chương 95 (ví dụ, đồ chơi, thiết bị trò chơi, dụng cụ, thiết bị thể thao);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(m) Giần, sàng bằng tay, khuy, bút máy, quản bút chì, ngòi bút, chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các sản phẩm tương tự hoặc các sản phẩm khác thuộc Chương 96 (các mặt hàng khác); hoặc ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Các sản phẩm thuộc Chương 97 (ví dụ, các tác phẩm nghệ thuật).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Trong Danh mục này, khái niệm “các bộ phận có công dụng chung” có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các sản phẩm thuộc nhóm 73.07, 73.12, 73.15, 73.17 hoặc 73.18 và các sản phẩm tương tự bằng kim loại cơ bản khác, trừ các sản phẩm được thiết kế đặc biệt để chuyên dùng cấy ghép trong y tế, phẫu thuật, nha khoa hoặc thú y (nhóm 90.21);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Lò xo và lá lò xo, bằng kim loại cơ bản, trừ dây cót dùng cho đồng hồ cá nhân hoặc đồng hồ thời gian khác (nhóm 91.14); và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Sản phẩm thuộc các nhóm 83.01, 83.02, 83.08, 83.10 và khung và gương, bằng kim loại cơ bản, thuộc nhóm 83.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Từ Chương 73 đến Chương 76 và từ Chương 78 đến Chương 82 (nhưng trừ nhóm 73.15) các bộ phận của hàng hóa thuộc các chương này không bao gồm các bộ phận có công dụng chung như đã nêu ở trên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Theo nội dung trên và theo Chú giải 1 của Chương 83, các sản phẩm thuộc Chương 82 hoặc 83 được loại trừ khỏi các Chương từ Chương 72 đến Chương 76 và từ Chương 78 đến Chương 81.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Trong Danh mục này, khái niệm “kim loại cơ bản” có nghĩa là: sắt và thép, đồng, niken, nhôm, chì, kẽm, thiếc, vonfram, molypđen, tantan, magiê, coban, bismut, cađimi, titan, zircon, antimon, mangan, berili, crôm, gemani, vanadi, gali, hafini, indi, niobi (columbi), rheni và tali.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Trong Danh mục này, khái niệm “gốm kim loại” chỉ các sản phẩm có sự kết hợp dị thể của một thành phần kim loại và một thành phần gốm trong cấu trúc tế vi. Khái niệm “gốm kim loại” cũng kể cả carbua kim loại thiêu kết (carbua kim loại thiêu kết với một kim loại). ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Việc phân loại các hợp kim (trừ hợp kim fero và các hợp kim chủ như đã được xác định trong Chương 72 và 74):',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Một hợp kim cấu thành từ các kim loại cơ bản được phân loại vào nhóm hợp kim của kim loại có hàm lượng trội hơn so với từng kim loại khác có trong hợp kim;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Một hợp kim mà thành phần gồm các kim loại cơ bản thuộc Phần này và các nguyên tố không thuộc Phần này thì hợp kim đó sẽ được coi như hợp kim của các kim loại thuộc phần này nếu tổng khối lượng của các kim loại cơ bản đó bằng hoặc lớn hơn tổng khối lượng của các thành phần khác có mặt trong hợp kim;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Trong Phần này khái niệm ""hợp kim"" kể cả hỗn hợp bột kim loại thiêu kết, hỗn hợp dị thể trộn kỹ thu được bằng cách nung chảy (trừ gốm kim loại), và hợp chất liên kết kim loại (thường gọi là hợp chất liên kim).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Trừ khi có những yêu cầu khác, khi việc xem xét có liên quan đến bất cứ một kim loại cơ bản nào trong Danh mục này, cũng cần phải xem xét những hợp kim mà chúng được phân loại như những hợp kim của kim loại đó theo Chú giải 5 ở trên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '7. Việc phân loại các sản phẩm hỗn hợp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Trừ khi các nhóm có yêu cầu khác, các sản phẩm bằng kim loại cơ bản (kể cả các sản phẩm bằng nguyên vật liệu pha trộn được coi như sản phẩm bằng kim loại cơ bản theo các Quy tắc tổng quát giải thích việc phân loại hàng hóa) gồm hai hoặc nhiều kim loại cơ bản thì được coi như sản phẩm của kim loại cơ bản nào có hàm lượng trội hơn tính theo khối lượng so với từng kim loại khác. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Theo mục đích này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Sắt và thép, hoặc các dạng khác của sắt hoặc thép, được coi như là một và là cùng một kim loại;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Một hợp kim được coi như chứa toàn bộ một loại kim loại khi mà hợp kim của kim loại đó được phân loại theo Chú giải 5; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Gốm kim loại của nhóm 81.13 được coi như một loại kim loại cơ bản.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '8. Trong Phần này, các khái niệm sau đây có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Tất cả phế liệu và mảnh vụn kim loại;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) các sản phẩm bằng kim loại chắc chắn không thể sử dụng được vì nguyên nhân bị gãy, cắt ra, bị mài mòn hoặc các nguyên nhân khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Bột',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là sản phẩm có hàm lượng từ 90% trở lên tính theo khối lượng lọt qua được rây (sàng) có đường kính mắt rây bằng 1 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '9. Theo mục đích của các Chương từ 74 đến 76 và 78 đến 81, các khái niệm sau đây có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Thanh và que',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm được cán, ép đùn, kéo hoặc rèn, không ở dạng cuộn, có mặt cắt ngang đông đặc đều nhau dọc theo chiều dài, hình dạng mặt cắt là hình tròn, bầu dục, chữ nhật (kể cả hình vuông), tam giác đều hoặc đa giác đều lồi (kể cả ""hình tròn phẳng"" và ""hình chữ nhật biến dạng"", có hai cạnh đối diện là vòng cung lồi, hai cạnh kia thẳng, chiều dài bằng nhau và song song). Các sản phẩm có mặt cắt ngang là hình chữ nhật (kể cả hình vuông), tam giác hoặc đa giác có thể có góc được làm tròn dọc theo chiều dài. Chiều dày của các sản phẩm có mặt cắt ngang hình chữ nhật (kể cả ""hình chữ nhật biến dạng"") phải trên 1/10 chiều rộng. Khái niệm này cũng áp dụng cho các sản phẩm được đúc hoặc thiêu kết có cùng hình dạng và kích thước đã được gia công thêm sau khi sản xuất (trừ cách cắt gọt đơn giản hoặc cạo gỉ), với điều kiện bằng cách đó chúng không mang đặc tính của các mặt hàng hoặc sản phẩm của các nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, các loại que và thanh dây thuộc Chương 74 có các đầu được làm nhọn hoặc được xử lý đơn giản khác để dễ đưa vào máy cho quá trình gia công tiếp theo, ví dụ, thành thanh kéo (thanh dây) hoặc ống, được đưa vào đồng chưa gia công thuộc nhóm 74.03. Quy định này áp dụng với những sửa đổi thích hợp đối với các sản phẩm thuộc Chương 81.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dạng hình',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm được cán, ép đùn, kéo, rèn hoặc tạo hình, ở dạng cuộn hoặc không, mặt cắt ngang đều nhau dọc theo chiều dài, và khác với định nghĩa về thanh, que, dây, tấm, phiến, dải, lá mỏng, ống hoặc ống dẫn. Khái niệm này cũng kể cả các sản phẩm được đúc hoặc thiêu kết, có cùng hình dạng, đã được gia công thêm sau khi sản xuất (trừ cách cắt gọt đơn giản hoặc cạo gỉ), với điều kiện là bằng cách đó chúng không mang đặc tính của các mặt hàng hoặc sản phẩm của nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Dây',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm được cán, ép đùn hoặc kéo, ở dạng cuộn, có mặt cắt ngang đặc đều nhau dọc theo chiều dài, hình dạng mặt cắt là hình tròn, bầu dục, chữ nhật (kể cả hình vuông), tam giác đều hoặc đa giác đều lồi (kể cả ""hình tròn phẳng"" và ""hình chữ nhật biến dạng"", có hai cạnh đối diện là vòng cung lồi, hai cạnh kia thẳng, chiều dài bằng nhau và song song). Các sản phẩm có mặt cắt ngang hình chữ nhật (kể cả hình vuông), tam giác hoặc đa giác có thể có góc được làm tròn dọc theo chiều dài. Chiều dày của các sản phẩm này có mặt cắt ngang hình chữ nhật (kể cả ""hình chữ nhật biến dạng"") phải trên 1/10 chiều rộng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Tấm, lá, dải và lá mỏng',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm có bề mặt được làm phẳng (trừ các sản phẩm chưa gia công), ở dạng cuộn hoặc không, có mặt cắt ngang đặc hình chữ nhật (trừ hình vuông) có hoặc không có các góc được làm tròn (kể cả ""hình chữ nhật biến dạng"", có 2 cạnh đối diện là vòng cung lồi, hai cạnh kia thẳng, chiều dài bằng nhau và song song) chiều dày bằng nhau, cụ thể:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- với dạng hình chữ nhật (kể cả hình vuông) chiều dày không quá 1/10 chiều rộng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- với các hình dạng khác trừ hình chữ nhật và hình vuông, với mọi loại kích thước, với điều kiện chúng không mang đặc tính của mặt hàng hoặc sản phẩm của nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các nhóm của tấm, lá, dải và lá mỏng, không kể những đề cập khác, áp dụng cho các tấm, lá, dải và lá mỏng đã gia công theo hình mẫu (ví dụ, rãnh, gân, carô, hình giọt nước, hình núm, hình thoi) và các sản phẩm đó được khoan, làm lượn sóng, đánh bóng hoặc tráng với điều kiện là bằng cách đó chúng không mang các đặc tính của mặt hàng hoặc sản phẩm của nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Ống và ống dẫn',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm rỗng, cuộn hoặc không, có mặt cắt ngang rỗng đều nhau dọc theo chiều dài, mặt cắt là hình tròn, bầu dục, chữ nhật (kể cả hình vuông), tam giác đều hoặc đa giác đều lồi, độ dày thành ống bằng nhau. Các sản phẩm có mặt cắt ngang là hình chữ nhật (kể cả hình vuông), tam giác đều hoặc đa giác đều lồi có thể có góc được làm tròn dọc theo chiều dài, cũng được coi là ống hoặc ống dẫn với điều kiện là mặt cắt ngang bên trong và bên ngoài đồng tâm, đồng dạng và đồng hướng. Ống và ống dẫn có mặt cắt ngang như vậy có thể được đánh bóng, tráng, uốn cong, ren, khoan, thắt lại, nở ra, hình côn hoặc nối với các mặt bích, mép, đai hoặc vòng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 72',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Sắt và thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này và, trong các Chú giải (d), (e) và (f) của Danh mục, các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Gang thỏi ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là loại hợp kim sắt-carbon không có tính rèn, có hàm lượng carbon trên 2% tính theo khối lượng và có thể chứa một hoặc nhiều nguyên tố khác trong giới hạn dưới đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Crôm không quá 10%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Mangan không quá 6%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Phospho không quá 3%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Silic không quá 8%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tổng các nguyên tố khác không quá 10%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Gang kính (gang thỏi giàu mangan)',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là loại hợp kim sắt-carbon chứa trên 6% nhưng không quá 30% mangan tính theo khối lượng và các thành phần khác theo giới hạn đã nêu ở điểm (a) nêu trên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Hợp kim fero',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là các hợp kim có dạng thỏi, khối, cục hoặc dạng thô tương tự, các dạng thu được bằng phương pháp đúc liên tục và cũng như dạng hạt hoặc dạng bột, đã hoặc chưa kết tụ, thường được sử dụng như một chất phụ gia cho quá trình sản xuất các hợp kim khác hoặc như tác nhân khử ôxy, khử lưu huỳnh hoặc cho mục đích tương tự trong ngành luyện kim đen và thông thường hợp kim này không có tính rèn, có hàm lượng sắt từ 4% trở lên tính theo khối lượng và một hoặc nhiều nguyên tố khác dưới đây: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Crôm trên 10%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Mangan trên 30%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Phospho trên 3%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Silic trên 8%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tổng các nguyên tố khác trên 10%, trừ carbon, riêng hàm lượng của đồng tối đa là 10%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Thép ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là các vật liệu dễ uốn có chứa sắt trừ các vật liệu thuộc nhóm 72.03 (không kể loại được sản xuất bằng phương pháp đúc) và có hàm lượng carbon không quá 2% tính theo khối lượng. Tuy nhiên, riêng thép crôm có thể có hàm lượng carbon cao hơn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Thép không gỉ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là loại thép hợp kim có hàm lượng carbon không quá 1,2% tính theo khối lượng và crôm từ 10,5% trở lên tính theo khối lượng, có thể chứa hoặc không chứa các nguyên tố khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Thép hợp kim khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là những loại thép có thành phần không tuân theo định nghĩa của ""thép không gỉ"" và chứa ít nhất một trong các nguyên tố với hàm lượng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Nhôm từ 0,3% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bo từ 0,0008% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Crôm từ 0,3% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Coban từ 0,3% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đồng từ 0,4% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chì từ 0,4% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Mangan từ 1,65% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Molybđen từ 0,08% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' - Nikel từ 0,3% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Niobi từ 0,06% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Silic từ 0,6% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Titan từ 0,05% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Vonfram từ 0,3% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Vanadi từ 0,1% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Zircon từ 0,05% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Các nguyên tố khác tính cho mỗi nguyên tố từ 0,1% trở lên (trừ lưu huỳnh, phostpho, carbon và nitơ).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Các thỏi sắt hoặc thép từ phế liệu nấu lại',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm đúc thô dạng thỏi không có đậu rót hoặc đậu ngót, có những khuyết tật bề mặt rõ ràng và thành phần hóa học của chúng không giống với gang thỏi, gang kính hoặc hợp kim fero.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Hạt ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là những sản phẩm có dưới 90% tính theo khối lượng lọt qua mắt sàng 1 mm và 90% trở lên tính theo khối lượng lọt qua mắt sàng 5 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Bán thành phẩm ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các sản phẩm đúc liên tục có mặt cắt đông đặc, đã hoặc chưa qua cán nóng thô; và ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm khác có mặt cắt đông đặc, chưa được gia công quá mức cán nóng thô hoặc được tạo hình thô bằng phương pháp rèn, kể cả phôi để tạo các sản phẩm dạng góc, khuôn hoặc hình. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các sản phẩm này không ở dạng cuộn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các sản phẩm được cán phẳng',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm cán có mặt cắt ngang đông đặc hình chữ nhật (trừ hình vuông), không thích hợp như định nghĩa tại mục (ij) nêu trên ở dạng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- cuộn từ các lớp được chồng lên nhau liên tiếp, hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- đoạn thẳng, nếu chiều dày của nó dưới 4,75 mm thì chiều rộng tối thiểu phải gấp 10 lần chiều dày hoặc nếu chiều dày từ 4,75 mm trở lên thì chiều rộng phải trên 150 mm và tối thiểu phải gấp 2 lần chiều dày. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm cán phẳng kể cả các sản phẩm đó với các hình nổi được tạo ra trực tiếp từ quá trình cán (ví dụ, rãnh, gân, kẻ carô, hình giọt nước, hình núm, hình thoi) và các sản phẩm được khoan, gấp nếp hoặc đánh bóng, với điều kiện là bằng cách đó chúng không mang đặc tính của các mặt hàng hoặc sản phẩm của các nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm cán phẳng có hình dạng khác với dạng hình chữ nhật hoặc hình vuông, với mọi kích thước, được phân loại như các sản phẩm có chiều rộng từ 600 mm trở lên, với điều kiện là chúng không mang đặc tính của các mặt hàng hoặc các sản phẩm thuộc nhóm khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Thanh và que, cán nóng, ở dạng cuộn cuốn không đều',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm cán nóng dạng cuộn cuốn không đều, có mặt cắt ngang đông đặc hình tròn, hình dẻ quạt, bầu dục, chữ nhật (kể cả hình vuông), tam giác hoặc đa giác lồi (kể cả ""hình tròn phẳng"" và ""hình chữ nhật biến dạng"", có 2 cạnh đối diện là vòng cung lồi, 2 cạnh kia thẳng, chiều dài bằng nhau và song song). Các sản phẩm này có thể được khía răng cưa, gân, rãnh hoặc các dạng khác được tạo ra trong quá trình cán (thanh và que gia cố).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Thanh và que khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm không phù hợp với các định nghĩa tại mục (ij), (k) hoặc (l) ở trên hoặc định nghĩa về dây, có mặt cắt ngang đông đặc đều nhau dọc theo chiều dài hình dạng mặt cắt là hình tròn, hình rẻ quạt, bầu dục, chữ nhật (kể cả hình vuông), tam giác hoặc đa giác lồi (kể cả ""hình tròn phẳng"" và ""hình chữ nhật biến dạng"", có 2 cạnh đối diện là vòng cung lồi, 2 cạnh kia thẳng, chiều dài bằng nhau và song song). Các sản phẩm này có thể:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- có khía răng cưa, rãnh, gân hoặc các dạng khác được tạo ra trong quá trình cán (thanh và que gia cố);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- được xoắn sau khi cán.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Góc, khuôn và hình',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm có mặt cắt ngang đông đặc đều nhau dọc chiều dài và không thích hợp với mọi định nghĩa về sản phẩm tại mục (ij), (k), (l) hoặc (m) ở trên hoặc định nghĩa về dây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 72 không kể đến các sản phẩm của nhóm 73.01 hoặc 73.02.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(o) Dây',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các sản phẩm được tạo hình nguội, ở dạng cuộn, có mặt cắt ngang đông đặc đều nhau dọc chiều dài, khác với định nghĩa về sản phẩm được cán phẳng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(p) Thanh và que rỗng ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Thanh và que rỗng với mặt cắt ngang bất kỳ, thích hợp cho các mũi khoan, kích thước bên ngoài lớn nhất của mặt cắt ngang trên 15 mm nhưng không quá 52 mm, và kích thước bên trong lớn nhất của mặt cắt không quá 1/2 kích thước bên ngoài lớn nhất. Thanh và que rỗng bằng sắt hoặc thép không đúng như định nghĩa này được phân loại vào nhóm 73.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Kim loại đen được phủ bằng một kim loại đen khác được phân loại như những sản phẩm từ kim loại đen có khối lượng trội hơn. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Các sản phẩm sắt hoặc thép thu được bằng phương pháp điện phân, bằng đúc áp lực hoặc thiêu kết được phân loại vào các nhóm của Chương này như các sản phẩm được cán nóng tương tự, theo hình dạng, thành phần và mô tả bề ngoài của chúng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Gang thỏi hợp kim ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Gang thỏi chứa 1 hoặc nhiều nguyên tố có phần trăm tính theo khối lượng như sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Crôm trên 0,2%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đồng trên 0,3%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Niken trên 0,3%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Trên 0,1% của một trong các nguyên tố bất kỳ sau: nhôm, molypden, titan, vonfram, vanadi.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Thép không hợp kim dễ cắt gọt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thép không hợp kim chứa 1 hoặc nhiều nguyên tố sau có phần trăm tính theo khối lượng như sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Lưu huỳnh từ 0,08% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chì từ 0,1% trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Selen trên 0,05%',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Telu trên 0,01% ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bismut trên 0,05%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Thép silic kỹ thuật điện ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Thép hợp kim có hàm lượng silic tối thiểu là 0,6% nhưng không quá 6% và chứa hàm lượng carbon không quá 0,08% tính theo khối lượng. Trong thành phần của chúng có thể chứa nhôm không quá 1% tính theo khối lượng và không chứa nguyên tố khác theo một tỷ lệ mà khiến chúng mang đặc tính của thép hợp kim khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Thép gió',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Thép hợp kim chứa ít nhất 2 trong 3 nguyên tố molybden, vonfram và vanadi với hàm lượng tổng cộng từ 7% trở lên tính theo khối lượng, carbon từ 0,6% trở lên tính theo khối lượng và crôm từ 3% đến 6% tính theo khối lượng, có hoặc không có các nguyên tố khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Thép silic-mangan',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thép hợp kim có hàm lượng các nguyên tố sau tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Carbon không quá 0,7%,',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Mangan từ 0,5% đến 1,9%, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Silic từ 0,6% đến 2,3%, nhưng không chứa nguyên tố khác theo một tỷ lệ mà khiến chúng mang đặc tính của thép hợp kim khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Khi phân loại các hợp kim fero trong các phân nhóm của nhóm 72.02 cần lưu ý nguyên tắc sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Một hợp kim fero được coi như 2 nguyên tố và được phân loại vào phân nhóm thích hợp (nếu có) nếu chỉ 1 trong các nguyên tố của hợp kim vượt quá tỷ lệ phần trăm tối thiểu được nêu ở Chú giải 1 (c) của Chương này; tương tự, nó sẽ được coi như 3 hoặc 4 nguyên tố nếu 2 hoặc 3 nguyên tố của hợp kim vượt quá phần trăm tối thiểu trên.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Để áp dụng nguyên tắc này hàm lượng ""mỗi nguyên tố khác"" không được nói rõ trong Chú giải 1 (c) của Chương này phải trên 10% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG I',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'NGUYÊN LIỆU THÔ, CÁC SẢN PHẨM Ở DẠNG HẠT HOẶC DẠNG BỘT',
    Unit: '',
  },
  {
    HsCode: '7201',
    Description: 'Gang thỏi và, gang kính ở dạng thỏi, dạng khối hoặc dạng thô khác',
    Unit: '',
  },
  {
    HsCode: '72011000',
    Description: '- Gang thỏi không hợp kim có hàm lượng phospho chiếm từ 0,5% trở xuống tính theo khối lượng ',
    Unit: 'kg',
  },
  {
    HsCode: '72012000',
    Description: '- Gang thỏi không hợp kim có hàm lượng phospho chiếm trên 0,5% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72015000',
    Description: '- Gang thỏi hợp kim; gang kính',
    Unit: 'kg',
  },
  {
    HsCode: '7202',
    Description: 'Hợp kim fero',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Fero - mangan:',
    Unit: '',
  },
  {
    HsCode: '72021100',
    Description: '- - Có hàm lượng carbon trên 2% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72021900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Fero - silic:',
    Unit: '',
  },
  {
    HsCode: '72022100',
    Description: '- - Có hàm lượng silic trên 55% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72022900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72023000',
    Description: '- Fero - silic - mangan',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Fero - crôm:',
    Unit: '',
  },
  {
    HsCode: '72024100',
    Description: '- - Có hàm lượng carbon trên 4% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72024900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72025000',
    Description: '- Fero - silic - crôm',
    Unit: 'kg',
  },
  {
    HsCode: '72026000',
    Description: '- Fero - niken',
    Unit: 'kg',
  },
  {
    HsCode: '72027000',
    Description: '- Fero - molipđen',
    Unit: 'kg',
  },
  {
    HsCode: '72028000',
    Description: '- Fero - vonfram và fero - silic - vonfram',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72029100',
    Description: '- - Fero - titan và fero - silic - titan',
    Unit: 'kg',
  },
  {
    HsCode: '72029200',
    Description: '- - Fero - vanadi',
    Unit: 'kg',
  },
  {
    HsCode: '72029300',
    Description: '- - Fero - niobi',
    Unit: 'kg',
  },
  {
    HsCode: '72029900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7203',
    Description:
      'Các sản phẩm chứa sắt được hoàn nguyên trực tiếp từ quặng sắt và các sản phẩm sắt xốp khác, dạng tảng, cục hoặc các dạng tương tự; sắt có độ tinh khiết tối thiểu là 99,94%, ở dạng tảng, cục hoặc các dạng tương tự',
    Unit: '',
  },
  {
    HsCode: '72031000',
    Description: '- Các sản phẩm chứa sắt được hoàn nguyên trực tiếp từ quặng sắt',
    Unit: 'kg',
  },
  {
    HsCode: '72039000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7204',
    Description: 'Phế liệu và mảnh vụn sắt; thỏi đúc phế liệu nấu lại từ sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '72041000',
    Description: '- Phế liệu và mảnh vụn của gang đúc',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Phế liệu và mảnh vụn của thép hợp kim:',
    Unit: '',
  },
  {
    HsCode: '72042100',
    Description: '- - Bằng thép không gỉ',
    Unit: 'kg',
  },
  {
    HsCode: '72042900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72043000',
    Description: '- Phế liệu và mảnh vụn của sắt hoặc thép tráng thiếc',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Phế liệu và mảnh vụn khác:',
    Unit: '',
  },
  {
    HsCode: '72044100',
    Description:
      '- - Phoi tiện, phoi bào, mảnh vỡ, vảy cán, mạt cưa, mạt giũa, phoi cắt và bavia, đã hoặc chưa được ép thành khối hoặc đóng thành kiện, bánh, bó',
    Unit: 'kg',
  },
  {
    HsCode: '72044900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72045000',
    Description: '- Thỏi đúc phế liệu nấu lại',
    Unit: 'kg',
  },
  {
    HsCode: '7205',
    Description: 'Hạt và bột, của gang thỏi, gang kính, sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '72051000',
    Description: '- Hạt',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bột:',
    Unit: '',
  },
  {
    HsCode: '72052100',
    Description: '- - Của thép hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '72052900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG II',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'SẮT VÀ THÉP KHÔNG HỢP KIM',
    Unit: '',
  },
  {
    HsCode: '7206',
    Description: 'Sắt và thép không hợp kim dạng thỏi đúc hoặc các dạng thô khác (trừ sắt thuộc nhóm 72.03)',
    Unit: '',
  },
  {
    HsCode: '720610',
    Description: '- Dạng thỏi đúc:',
    Unit: '',
  },
  {
    HsCode: '72061010',
    Description: '- - Có hàm lượng carbon trên 0,6% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72061090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72069000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7207',
    Description: 'Sắt hoặc thép không hợp kim ở dạng bán thành phẩm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có hàm lượng carbon dưới 0,25% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72071100',
    Description: '- - Mặt cắt ngang hình chữ nhật (kể cả hình vuông), có chiều rộng nhỏ hơn hai lần chiều dày',
    Unit: 'kg',
  },
  {
    HsCode: '720712',
    Description: '- - Loại khác, có mặt cắt ngang hình chữ nhật (trừ hình vuông):',
    Unit: '',
  },
  {
    HsCode: '72071210',
    Description: '- - - Phôi dẹt (dạng phiến) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72071290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72071900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '720720',
    Description: '- Có hàm lượng carbon từ 0,25% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72072010',
    Description: '- - - Phôi dẹt (dạng phiến) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72072021',
    Description: '- - - - Dạng khối được tạo hình thô bằng cách rèn; phôi dạng tấm',
    Unit: 'kg',
  },
  {
    HsCode: '72072029',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72072091',
    Description: '- - - Phôi dẹt (dạng phiến) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72072092',
    Description: '- - - - Dạng khối được tạo hình thô bằng cách rèn; phôi dạng tấm',
    Unit: 'kg',
  },
  {
    HsCode: '72072099',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7208',
    Description:
      'Các sản phẩm sắt hoặc thép không hợp kim được cán phẳng, có chiều rộng từ 600mm trở lên, được cán nóng, chưa dát phủ (clad), phủ, mạ (coated) hoặc tráng (plated)',
    Unit: '',
  },
  {
    HsCode: '72081000',
    Description: '- Dạng cuộn, chưa được gia công quá mức cán nóng, có hình dập nổi',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, dạng cuộn, chưa được gia công quá mức cán nóng, đã ngâm tẩy gỉ:',
    Unit: '',
  },
  {
    HsCode: '72082500',
    Description: '- - Chiều dày từ 4,75 mm trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '72082600',
    Description: '- - Chiều dày từ 3 mm đến dưới 4,75 mm',
    Unit: 'kg',
  },
  {
    HsCode: '720827',
    Description: '- - Chiều dày dưới 3mm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Chiều dày dưới 2mm:',
    Unit: '',
  },
  {
    HsCode: '72082711',
    Description: '- - - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72082719',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72082791',
    Description: '- - - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72082799',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, dạng cuộn, chưa được gia công quá mức cán nóng:',
    Unit: '',
  },
  {
    HsCode: '72083600',
    Description: '- - Chiều dày trên 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72083700',
    Description: '- - Chiều dày từ 4,75 mm đến 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72083800',
    Description: '- - Chiều dày từ 3 mm đến dưới 4,75 mm',
    Unit: 'kg',
  },
  {
    HsCode: '720839',
    Description: '- - Chiều dày dưới 3mm:',
    Unit: '',
  },
  {
    HsCode: '72083910',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72083920',
    Description:
      '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày trên 0,17 mm nhưng không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72083930',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng và chiều dày không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72083940',
    Description: '- - - Chiều dày trên 2 mm, độ bền kéo tối đa 550 Mpa và chiều rộng không quá 1.250 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72083990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72084000',
    Description: '- Không ở dạng cuộn, chưa được gia công quá mức cán nóng, có hình dập nổi trên bề mặt',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, không ở dạng cuộn, chưa được gia công quá mức cán nóng:',
    Unit: '',
  },
  {
    HsCode: '72085100',
    Description: '- - Chiều dày trên 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72085200',
    Description: '- - Chiều dày từ 4,75 mm đến 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72085300',
    Description: '- - Chiều dày từ 3 mm đến dưới 4,75 mm',
    Unit: 'kg',
  },
  {
    HsCode: '720854',
    Description: '- - Chiều dày dưới 3mm:',
    Unit: '',
  },
  {
    HsCode: '72085410',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72085490',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '720890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72089010',
    Description: '- - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72089020',
    Description: '- - Loại khác, có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72089090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7209',
    Description:
      'Các sản phẩm sắt hoặc thép không hợp kim được cán phẳng, có chiều rộng từ 600 mm trở lên, cán nguội (ép nguội), chưa dát phủ (clad), phủ, mạ (coated) hoặc tráng (plated)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ở dạng cuộn, chưa được gia công quá mức cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '72091500',
    Description: '- - Có chiều dày từ 3 mm trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '720916',
    Description: '- - Có chiều dày trên 1 mm đến dưới 3 mm:',
    Unit: '',
  },
  {
    HsCode: '72091610',
    Description: '- - - Chiều rộng không quá 1.250 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72091690',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '720917',
    Description: '- - Có chiều dày từ 0,5 mm đến 1 mm:',
    Unit: '',
  },
  {
    HsCode: '72091710',
    Description: '- - - Chiều rộng không quá 1.250 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72091790',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '720918',
    Description: '- - Có chiều dày dưới 0,5 mm:',
    Unit: '',
  },
  {
    HsCode: '72091810',
    Description: '- - - Tấm thép đen (tôn đen) cán để tráng thiếc (Tin - mill blackplate - TMBP)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72091891',
    Description: '- - - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72091899',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Không ở dạng cuộn, chưa được gia công quá mức cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '72092500',
    Description: '- - Có chiều dày từ 3 mm trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '720926',
    Description: '- - Có chiều dày trên 1 mm đến dưới 3 mm:',
    Unit: '',
  },
  {
    HsCode: '72092610',
    Description: '- - - Chiều rộng không quá 1.250 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72092690',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '720927',
    Description: '- - Có chiều dày từ 0,5 mm đến 1 mm:',
    Unit: '',
  },
  {
    HsCode: '72092710',
    Description: '- - - Chiều rộng không quá 1.250 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72092790',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '720928',
    Description: '- - Có chiều dày dưới 0,5 mm:',
    Unit: '',
  },
  {
    HsCode: '72092810',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72092890',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '720990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72099010',
    Description: '- - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72099090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7210',
    Description:
      'Các sản phẩm sắt hoặc thép không hợp kim được cán phẳng, có chiều rộng từ 600mm trở lên, đã dát phủ (clad), phủ, mạ (coated) hoặc tráng (plated)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Được phủ, mạ hoặc tráng thiếc:',
    Unit: '',
  },
  {
    HsCode: '721011',
    Description: '- - Có chiều dày từ 0,5 mm trở lên:',
    Unit: '',
  },
  {
    HsCode: '72101110',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72101190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721012',
    Description: '- - Có chiều dày dưới 0,5 mm:',
    Unit: '',
  },
  {
    HsCode: '72101210',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72101290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721020',
    Description: '- Được phủ, mạ hoặc tráng chì, kể cả hợp kim chì thiếc:',
    Unit: '',
  },
  {
    HsCode: '72102010',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72102090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721030',
    Description: '- Được phủ, mạ hoặc tráng kẽm bằng phương pháp điện phân:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72103011',
    Description: '- - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72103012',
    Description: '- - - Chiều dày trên 1,2 mm nhưng không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72103019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72103091',
    Description: '- - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72103099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Được phủ, mạ hoặc tráng kẽm bằng phương pháp khác:',
    Unit: '',
  },
  {
    HsCode: '721041',
    Description: '- - Dạng lượn sóng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72104111',
    Description: '- - - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104112',
    Description: '- - - - Chiều dày trên 1,2 mm nhưng không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104119',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72104191',
    Description: '- - - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104199',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721049',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72104911',
    Description:
      '- - - - Được phủ, mạ hoặc tráng kẽm bằng phương pháp hợp kim hóa bề mặt, có hàm lượng carbon dưới 0,04% tính theo khối lượng và chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104914',
    Description: '- - - - Được phủ, mạ hoặc tráng bằng hợp kim kẽm – nhôm - magiê, có chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104915',
    Description:
      '- - - - Được phủ, mạ hoặc tráng bằng hợp kim kẽm – nhôm - magiê, có chiều dày trên 1,2 mm nhưng không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104916',
    Description: '- - - - Được phủ, mạ hoặc tráng bằng hợp kim kẽm – nhôm - magiê, có chiều dày trên 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104917',
    Description: '- - - - Loại khác, chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104918',
    Description: '- - - - Loại khác, chiều dày trên 1,2 mm nhưng không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104919',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72104991',
    Description: '- - - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72104999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72105000',
    Description: '- Được phủ, mạ hoặc tráng bằng oxit crom hoặc bằng crom và oxit crom',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Được phủ, mạ hoặc tráng nhôm:',
    Unit: '',
  },
  {
    HsCode: '721061',
    Description: '- - Được phủ, mạ hoặc tráng hợp kim nhôm - kẽm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72106111',
    Description: '- - - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72106112',
    Description: '- - - - Chiều dày trên 1,2 mm nhưng không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72106119',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72106191',
    Description: '- - - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72106192',
    Description: '- - - - Loại khác, dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72106199',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721069',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72106911',
    Description: '- - - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72106919',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72106991',
    Description: '- - - - Chiều dày không quá 1,2 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72106999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721070',
    Description: '- Được sơn, quét vécni hoặc phủ plastic:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 1,5 mm:',
    Unit: '',
  },
  {
    HsCode: '72107012',
    Description: '- - - Được sơn sau khi phủ, mạ kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '72107013',
    Description: '- - - Được sơn sau khi phủ, mạ bằng hợp kim nhôm - kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '72107019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72107021',
    Description: '- - - Được sơn',
    Unit: 'kg',
  },
  {
    HsCode: '72107029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72107091',
    Description: '- - - Được sơn',
    Unit: 'kg',
  },
  {
    HsCode: '72107099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72109010',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72109090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7211',
    Description:
      'Các sản phẩm sắt hoặc thép không hợp kim cán phẳng, có chiều rộng dưới 600mm, chưa dát phủ (clad), phủ, mạ (coated) hoặc tráng (plated)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa được gia công quá mức cán nóng:',
    Unit: '',
  },
  {
    HsCode: '721113',
    Description:
      '- - Được cán 4 mặt hoặc ở dạng khuôn hộp kín, có chiều rộng trên 150 mm và chiều dày không dưới 4 mm, không ở dạng cuộn và không có hình dập nổi:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72111312',
    Description: '- - - - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72111313',
    Description: '- - - - Dạng đai và dải(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111314',
    Description: '- - - - Tấm phổ dụng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111319',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72111392',
    Description: '- - - - Dạng đai và dải(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111393',
    Description: '- - - - Tấm phổ dụng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111399',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721114',
    Description: '- - Loại khác, chiều dày từ 4,75 mm trở lên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72111414',
    Description: '- - - - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72111415',
    Description: '- - - - Dạng cuộn để cán lại (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111416',
    Description: '- - - - Dạng đai và dải(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111417',
    Description: '- - - - Tấm phổ dụng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111419',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72111494',
    Description: '- - - - Dạng đai và dải(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111495',
    Description: '- - - - Tấm phổ dụng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111499',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721119',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72111913',
    Description: '- - - - Dạng đai và dải; tấm phổ dụng(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111914',
    Description: '- - - - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72111919',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72111991',
    Description: '- - - - Dạng đai và dải; tấm phổ dụng(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72111999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Chưa được gia công quá mức cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '721123',
    Description: '- - Có hàm lượng carbon dưới 0,25% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72112310',
    Description: '- - - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72112320',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72112330',
    Description: '- - - Loại khác, chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72112390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721129',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72112910',
    Description: '- - - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72112920',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72112930',
    Description: '- - - Loại khác, chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72112990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72119011',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng không quá 25 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72119012',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng trên 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72119013',
    Description: '- - - Dạng lượn sóng',
    Unit: 'kg',
  },
  {
    HsCode: '72119014',
    Description: '- - - Loại khác, chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72119019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72119091',
    Description: '- - - Chiều dày không quá 0,17 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72119099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7212',
    Description:
      'Các sản phẩm sắt hoặc thép không hợp kim cán phẳng, có chiều rộng dưới 600mm, đã dát phủ (clad), phủ, mạ (coated) hoặc tráng (plated)',
    Unit: '',
  },
  {
    HsCode: '721210',
    Description: '- Được phủ, mạ hoặc tráng thiếc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72121011',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng không quá 25 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72121014',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng trên 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72121019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72121094',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72121099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721220',
    Description: '- Được phủ, mạ hoặc tráng kẽm bằng phương pháp điện phân:',
    Unit: '',
  },
  {
    HsCode: '72122010',
    Description: '- - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72122020',
    Description: '- - Loại khác, có hàm lượng carbon dưới 0,6% tính theo khối lượng và chiều dày không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72122090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721230',
    Description: '- Được phủ, mạ hoặc tráng kẽm bằng phương pháp khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72123011',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng không quá 25 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72123012',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng trên 25 mm nhưng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72123013',
    Description: '- - - Loại khác, chiều dày không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72123014',
    Description:
      '- - - Loại khác, được tráng kẽm bằng phương pháp hợp kim hóa bề mặt có hàm lượng carbon dưới 0,04% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72123019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72123090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721240',
    Description: '- Được sơn, quét vécni hoặc phủ plastic:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72124011',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72124012',
    Description: '- - - Dạng đai và dải khác(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72124013',
    Description: '- - - Loại khác, được sơn sau khi phủ, mạ kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '72124014',
    Description: '- - - Loại khác, được sơn sau khi phủ, mạ hợp kim nhôm - kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '72124019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72124091',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72124099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721250',
    Description: '- Được phủ, mạ hoặc tráng bằng phương pháp khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Được phủ, mạ hoặc tráng bằng oxit crôm hoặc bằng crôm và oxit crôm:',
    Unit: '',
  },
  {
    HsCode: '72125014',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng trên 25 mm; tấm phổ dụng (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72125019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Được phủ, mạ hoặc tráng hợp kim nhôm - kẽm:',
    Unit: '',
  },
  {
    HsCode: '72125023',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng không quá 25 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72125024',
    Description: '- - - Dạng đai và dải khác; tấm phổ dụng(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72125029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72125093',
    Description: '- - - Dạng đai và dải(SEN), có chiều rộng không quá 25 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72125094',
    Description: '- - - Dạng đai và dải khác; tấm phổ dụng(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72125099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721260',
    Description: '- Được dát phủ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72126011',
    Description: '- - - Dạng đai và dải(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72126012',
    Description: '- - - Loại khác, chiều dày không quá 1,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72126019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72126091',
    Description: '- - - Dạng đai và dải(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72126099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7213',
    Description: 'Sắt hoặc thép không hợp kim, dạng thanh và que, ở dạng cuộn cuốn không đều, được cán nóng',
    Unit: '',
  },
  {
    HsCode: '721310',
    Description: '- Có răng khía, rãnh, gân hoặc các dạng khác được tạo thành trong quá trình cán:',
    Unit: '',
  },
  {
    HsCode: '72131010',
    Description: '- - Có đường kính mặt cắt ngang hình tròn không quá 50 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72131090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72132000',
    Description: '- Loại khác, bằng thép dễ cắt gọt',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '721391',
    Description: '- - Có đường kính mặt cắt ngang hình tròn dưới 14 mm:',
    Unit: '',
  },
  {
    HsCode: '72139110',
    Description: '- - - Loại dùng để sản xuất que hàn (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72139120',
    Description: '- - - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '72139130',
    Description:
      '- - - Loại khác, có hàm lượng carbon từ 0,6% trở lên, hàm lượng photpho không quá 0,03% và hàm lượng lưu huỳnh không quá 0,035% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72139190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721399',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72139910',
    Description: '- - - Loại dùng để sản xuất que hàn (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72139920',
    Description: '- - - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '72139990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7214',
    Description:
      'Sắt hoặc thép không hợp kim ở dạng thanh và que khác, chưa được gia công quá mức rèn, cán nóng, kéo nóng hoặc ép đùn nóng, nhưng kể cả những dạng này được xoắn sau khi cán',
    Unit: '',
  },
  {
    HsCode: '721410',
    Description: '- Đã qua rèn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72141011',
    Description: '- - - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72141019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72141021',
    Description: '- - - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72141029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721420',
    Description:
      '- Có răng khía, rãnh, gân hoặc các dạng khác được tạo thành trong quá trình cán hoặc xoắn sau khi cán:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có mặt cắt ngang hình tròn:',
    Unit: '',
  },
  {
    HsCode: '72142031',
    Description: '- - - - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '72142039',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72142041',
    Description: '- - - - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '72142049',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có mặt cắt ngang hình tròn:',
    Unit: '',
  },
  {
    HsCode: '72142051',
    Description: '- - - - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '72142059',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72142061',
    Description: '- - - - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '72142069',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721430',
    Description: '- Loại khác, bằng thép dễ cắt gọt:',
    Unit: '',
  },
  {
    HsCode: '72143010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72143090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '721491',
    Description: '- - Mặt cắt ngang hình chữ nhật (trừ hình vuông):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72149111',
    Description: '- - - - Có hàm lượng carbon từ 0,38% trở lên và hàm lượng mangan dưới 1,15% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72149112',
    Description:
      '- - - - Có hàm lượng carbon từ 0,17% đến 0,46% và hàm lượng mangan từ 1,2% đến dưới 1,65% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72149119',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72149120',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '721499',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng, trừ dạng mặt cắt ngang hình tròn:',
    Unit: '',
  },
  {
    HsCode: '72149911',
    Description: '- - - - Có hàm lượng mangan dưới 1,15% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72149919',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72149991',
    Description:
      '- - - - Có hàm lượng carbon dưới 0,38%, hàm lượng phốt pho không quá 0,05% và hàm lượng lưu huỳnh không quá 0,05% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72149992',
    Description: '- - - - Có hàm lượng carbon từ 0,38% trở lên và hàm lượng mangan dưới 1,15% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72149993',
    Description:
      '- - - - Có hàm lượng carbon từ 0,17% đến dưới 0,46% và hàm lượng mangan từ 1,2% đến dưới 1,65% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72149999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7215',
    Description: 'Sắt hoặc thép không hợp kim ở dạng thanh và que khác',
    Unit: '',
  },
  {
    HsCode: '721510',
    Description: '- Bằng thép dễ cắt gọt, chưa được gia công quá mức tạo hình nguội hoặc gia công kết thúc nguội:',
    Unit: '',
  },
  {
    HsCode: '72151010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72151090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721550',
    Description: '- Loại khác, chưa được gia công quá mức tạo hình nguội hoặc gia công kết thúc nguội:',
    Unit: '',
  },
  {
    HsCode: '72155010',
    Description: '- - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng, trừ dạng mặt cắt ngang hình tròn ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72155091',
    Description: '- - - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '72155099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72159010',
    Description: '- - Thép cốt bê tông',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72159091',
    Description: '- - - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72159099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7216',
    Description: 'Sắt hoặc thép không hợp kim dạng góc, khuôn, hình',
    Unit: '',
  },
  {
    HsCode: '72161000',
    Description:
      '- Hình chữ U, I hoặc H, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn, có chiều cao dưới 80 mm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- Hình chữ L hoặc chữ T, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn, có chiều cao dưới 80 mm:',
    Unit: '',
  },
  {
    HsCode: '721621',
    Description: '- - Hình chữ L:',
    Unit: '',
  },
  {
    HsCode: '72162110',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72162190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72162200',
    Description: '- - Hình chữ T',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- Hình chữ U, I hoặc H, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn có chiều cao từ 80 mm trở lên:',
    Unit: '',
  },
  {
    HsCode: '721631',
    Description: '- - Hình chữ U:',
    Unit: '',
  },
  {
    HsCode: '72163110',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72163190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721632',
    Description: '- - Hình chữ I:',
    Unit: '',
  },
  {
    HsCode: '72163210',
    Description: '- - - Chiều dày từ 5 mm trở xuống',
    Unit: 'kg',
  },
  {
    HsCode: '72163290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721633',
    Description: '- - Hình chữ H:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có hàm lượng carbon dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72163311',
    Description: '- - - - Chiều dày của cạnh (flange) không nhỏ hơn chiều dày của thân (web)',
    Unit: 'kg',
  },
  {
    HsCode: '72163319',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72163390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721640',
    Description:
      '- Hình chữ L hoặc chữ T, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn, có chiều cao từ 80 mm trở lên:',
    Unit: '',
  },
  {
    HsCode: '72164010',
    Description: '- - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72164090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721650',
    Description: '- Góc, khuôn và hình khác, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn qua khuôn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có chiều cao dưới 80 mm:',
    Unit: '',
  },
  {
    HsCode: '72165011',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72165019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72165091',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72165099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Góc, khuôn và hình khác, chưa được gia công quá mức tạo hình nguội hoặc gia công kết thúc nguội:',
    Unit: '',
  },
  {
    HsCode: '72166100',
    Description: '- - Thu được từ các sản phẩm cán phẳng',
    Unit: 'kg',
  },
  {
    HsCode: '72166900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '721691',
    Description: '- - Được tạo hình nguội hoặc gia công kết thúc nguội từ các sản phẩm cán phẳng:',
    Unit: '',
  },
  {
    HsCode: '72169110',
    Description: '- - - Dạng góc, trừ góc đục lỗ, rãnh, có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72169190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72169900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7217',
    Description: 'Dây của sắt hoặc thép không hợp kim',
    Unit: '',
  },
  {
    HsCode: '721710',
    Description: '- Không được phủ, mạ hoặc tráng, đã hoặc chưa được đánh bóng:',
    Unit: '',
  },
  {
    HsCode: '72171010',
    Description: '- - Có hàm lượng carbon dưới 0,25% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon từ 0,25% đến dưới 0,6% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72171022',
    Description: '- - - Dây dẹt cuộn tang; dây loại sử dụng để làm dây tao cho bê tông dự ứng lực; dây thép dễ cắt gọt',
    Unit: 'kg',
  },
  {
    HsCode: '72171029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72171032',
    Description: '- - - Dây làm nan hoa; dây dẹt cuộn tang; dây thép dễ cắt gọt',
    Unit: 'kg',
  },
  {
    HsCode: '72171033',
    Description: '- - - Dây loại sử dụng để làm dây tao cho bê tông dự ứng lực',
    Unit: 'kg',
  },
  {
    HsCode: '72171039',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721720',
    Description: '- Được phủ, mạ hoặc tráng kẽm:',
    Unit: '',
  },
  {
    HsCode: '72172010',
    Description: '- - Có hàm lượng carbon dưới 0,25% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72172020',
    Description: '- - Có hàm lượng carbon từ 0,25% đến dưới 0,45% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Chứa hàm lượng carbon từ 0,45% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72172091',
    Description: '- - - Dây thép dùng làm lõi cho cáp dẫn điện bằng nhôm (ACSR) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '72172099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721730',
    Description: '- Được phủ, mạ hoặc tráng kim loại cơ bản khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon dưới 0,25% tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72173011',
    Description: '- - - Phủ, mạ hoặc tráng thiếc',
    Unit: 'kg',
  },
  {
    HsCode: '72173019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72173020',
    Description: '- - Có hàm lượng carbon từ 0,25% đến dưới 0,6% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72173033',
    Description: '- - - Dây thép phủ đồng thau dùng trong sản xuất lốp cao su loại bơm hơi (dây tanh)',
    Unit: 'kg',
  },
  {
    HsCode: '72173034',
    Description: '- - - Dây thép phủ hợp kim đồng loại khác dùng để làm lốp cao su loại bơm hơi (dây tanh)',
    Unit: 'kg',
  },
  {
    HsCode: '72173035',
    Description: '- - - Loại khác, phủ, mạ hoặc tráng thiếc',
    Unit: 'kg',
  },
  {
    HsCode: '72173039',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '721790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72179010',
    Description: '- - Có hàm lượng carbon dưới 0,25% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72179090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG III',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'THÉP KHÔNG GỈ',
    Unit: '',
  },
  {
    HsCode: '7218',
    Description: 'Thép không gỉ ở dạng thỏi đúc hoặc dạng thô khác; bán thành phẩm của thép không gỉ',
    Unit: '',
  },
  {
    HsCode: '72181000',
    Description: '- Ở dạng thỏi đúc và dạng thô khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72189100',
    Description: '- - Có mặt cắt ngang hình chữ nhật (trừ hình vuông)',
    Unit: 'kg',
  },
  {
    HsCode: '72189900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7219',
    Description: 'Các sản phẩm của thép không gỉ cán phẳng, có chiều rộng từ 600 mm trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa được gia công quá mức cán nóng, ở dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '72191100',
    Description: '- - Chiều dày trên 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72191200',
    Description: '- - Chiều dày từ 4,75 mm đến 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72191300',
    Description: '- - Chiều dày từ 3 mm đến dưới 4,75 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72191400',
    Description: '- - Chiều dày dưới 3 mm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Chưa được gia công quá mức cán nóng, không ở dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '72192100',
    Description: '- - Chiều dày trên 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72192200',
    Description: '- - Chiều dày từ 4,75 mm đến 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72192300',
    Description: '- - Chiều dày từ 3 mm đến dưới 4,75 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72192400',
    Description: '- - Chiều dày dưới 3 mm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Chưa được gia công quá mức cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '72193100',
    Description: '- - Chiều dày từ 4,75 mm trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '72193200',
    Description: '- - Chiều dày từ 3 mm đến dưới 4,75 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72193300',
    Description: '- - Chiều dày trên 1 mm đến dưới 3 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72193400',
    Description: '- - Chiều dày từ 0,5 mm đến 1 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72193500',
    Description: '- - Chiều dày dưới 0,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72199000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7220',
    Description: 'Các sản phẩm thép không gỉ được cán phẳng, có chiều rộng dưới 600 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa được gia công quá mức cán nóng:',
    Unit: '',
  },
  {
    HsCode: '722011',
    Description: '- - Chiều dày từ 4,75 mm trở lên:',
    Unit: '',
  },
  {
    HsCode: '72201110',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72201190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722012',
    Description: '- - Chiều dày dưới 4,75 mm:',
    Unit: '',
  },
  {
    HsCode: '72201210',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72201290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722020',
    Description: '- Chưa được gia công quá mức cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '72202010',
    Description: '- - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72202090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72209010',
    Description: '- - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72209090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72210000',
    Description: 'Thanh và que thép không gỉ được cán nóng, dạng cuộn cuốn không đều',
    Unit: 'kg',
  },
  {
    HsCode: '7222',
    Description: 'Thép không gỉ dạng thanh và que khác; thép không gỉ ở dạng góc, khuôn và hình khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dạng thanh và que, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn qua khuôn:',
    Unit: '',
  },
  {
    HsCode: '72221100',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72221900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722220',
    Description: '- Dạng thanh và que, chưa được gia công quá mức tạo hình nguội hoặc gia công kết thúc nguội:',
    Unit: '',
  },
  {
    HsCode: '72222010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72222090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722230',
    Description: '- Các thanh và que khác:',
    Unit: '',
  },
  {
    HsCode: '72223010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72223090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722240',
    Description: '- Các dạng góc, khuôn và hình:',
    Unit: '',
  },
  {
    HsCode: '72224010',
    Description: '- - Chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn qua khuôn',
    Unit: 'kg',
  },
  {
    HsCode: '72224090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7223',
    Description: 'Dây thép không gỉ',
    Unit: '',
  },
  {
    HsCode: '72230010',
    Description: '- Có mặt cắt ngang trên 13 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72230090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: 'PHÂN CHƯƠNG IV',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'THÉP HỢP KIM KHÁC; CÁC DẠNG THANH                      VÀ QUE RỖNG, BẰNG THÉP HỢP KIM                              HOẶC KHÔNG HỢP KIM',
    Unit: '',
  },
  {
    HsCode: '7224',
    Description: 'Thép hợp kim khác ở dạng thỏi đúc hoặc dạng thô khác; các bán thành phẩm bằng thép hợp kim khác',
    Unit: '',
  },
  {
    HsCode: '72241000',
    Description: '- Ở dạng thỏi đúc và dạng thô khác',
    Unit: 'kg',
  },
  {
    HsCode: '72249000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7225',
    Description: 'Thép hợp kim khác được cán phẳng, có chiều rộng từ 600 mm trở lên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng thép silic kỹ thuật điện:',
    Unit: '',
  },
  {
    HsCode: '72251100',
    Description: '- - Các hạt (cấu trúc tế vi) kết tinh có định hướng',
    Unit: 'kg',
  },
  {
    HsCode: '72251900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722530',
    Description: '- Loại khác, chưa được gia công quá mức cán nóng, ở dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '72253010',
    Description: '- - Thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '72253090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722540',
    Description: '- Loại khác, chưa được gia công quá mức cán nóng, không ở dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '72254010',
    Description: '- - Thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '72254090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722550',
    Description: '- Loại khác, chưa được gia công quá mức cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '72255010',
    Description: '- - Thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '72255090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '722591',
    Description: '- - Được phủ, mạ hoặc tráng kẽm bằng phương pháp điện phân:',
    Unit: '',
  },
  {
    HsCode: '72259110',
    Description: '- - - Thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '72259190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722592',
    Description: '- - Được phủ, mạ hoặc tráng kẽm bằng phương pháp khác:',
    Unit: '',
  },
  {
    HsCode: '72259210',
    Description: '- - - Thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '72259220',
    Description: '- - - Loại khác, được phủ, mạ hoặc tráng bằng hợp kim kẽm - nhôm - magiê',
    Unit: 'kg',
  },
  {
    HsCode: '72259290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72259910',
    Description: '- - - Thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '72259990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7226',
    Description: 'Sản phẩm của thép hợp kim khác được cán phẳng, có chiều rộng dưới 600 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng thép silic kỹ thuật điện:',
    Unit: '',
  },
  {
    HsCode: '722611',
    Description: '- - Các hạt tinh thể (cấu trúc tế vi) có định hướng:',
    Unit: '',
  },
  {
    HsCode: '72261110',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72261190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722619',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72261910',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72261990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722620',
    Description: '- Bằng thép gió:',
    Unit: '',
  },
  {
    HsCode: '72262010',
    Description: '- - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72262090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '722691',
    Description: '- - Chưa được gia công quá mức cán nóng:',
    Unit: '',
  },
  {
    HsCode: '72269110',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72269190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722692',
    Description: '- - Chưa được gia công quá mức cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '72269210',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm',
    Unit: 'kg',
  },
  {
    HsCode: '72269290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722699',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng đai và dải(SEN), chiều rộng không quá 400 mm:',
    Unit: '',
  },
  {
    HsCode: '72269911',
    Description: '- - - - Được phủ, mạ hoặc tráng kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '72269919',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72269991',
    Description: '- - - - Được phủ, mạ hoặc tráng kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '72269999',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7227',
    Description: 'Các dạng thanh và que, của thép hợp kim khác, được cán nóng, dạng cuộn không đều',
    Unit: '',
  },
  {
    HsCode: '72271000',
    Description: '- Bằng thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '72272000',
    Description: '- Bằng thép mangan - silic',
    Unit: 'kg',
  },
  {
    HsCode: '722790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72279010',
    Description: '- - Có hàm lượng crôm từ 0,5% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72279090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7228',
    Description:
      'Các dạng thanh và que khác bằng thép hợp kim khác; các dạng góc, khuôn và hình, bằng thép hợp kim khác; thanh và que rỗng, bằng thép hợp kim hoặc không hợp kim',
    Unit: '',
  },
  {
    HsCode: '722810',
    Description: '- Ở dạng thanh và que, bằng thép gió:',
    Unit: '',
  },
  {
    HsCode: '72281010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72281090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722820',
    Description: '- Ở dạng thanh và que, bằng thép silic-mangan:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có mặt cắt ngang hình tròn:',
    Unit: '',
  },
  {
    HsCode: '72282011',
    Description: '- - - Chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn qua khuôn',
    Unit: 'kg',
  },
  {
    HsCode: '72282019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72282091',
    Description: '- - - Chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn qua khuôn',
    Unit: 'kg',
  },
  {
    HsCode: '72282099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722830',
    Description: '- Dạng thanh và que khác, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn:',
    Unit: '',
  },
  {
    HsCode: '72283010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72283090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722840',
    Description: '- Các loại thanh và que khác, chưa được gia công quá mức rèn:',
    Unit: '',
  },
  {
    HsCode: '72284010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72284090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722850',
    Description:
      '- Các loại thanh và que khác, chưa được gia công quá mức tạo hình nguội hoặc gia công kết thúc nguội:',
    Unit: '',
  },
  {
    HsCode: '72285010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72285090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722860',
    Description: '- Các thanh và que khác:',
    Unit: '',
  },
  {
    HsCode: '72286010',
    Description: '- - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72286090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722870',
    Description: '- Các dạng góc, khuôn và hình:',
    Unit: '',
  },
  {
    HsCode: '72287010',
    Description: '- - Chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn qua khuôn',
    Unit: 'kg',
  },
  {
    HsCode: '72287090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '722880',
    Description: '- Thanh và que rỗng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có hàm lượng carbon từ 0,6% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '72288011',
    Description: '- - - Có mặt cắt ngang hình tròn',
    Unit: 'kg',
  },
  {
    HsCode: '72288019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72288090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7229',
    Description: 'Dây thép hợp kim khác',
    Unit: '',
  },
  {
    HsCode: '72292000',
    Description: '- Bằng thép mangan - silic',
    Unit: 'kg',
  },
  {
    HsCode: '722990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Mặt cắt ngang không quá 5,5 mm:',
    Unit: '',
  },
  {
    HsCode: '72299021',
    Description: '- - - Có hàm lượng crôm từ 0,5% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72299029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '72299030',
    Description: '- - Loại khác, bằng thép gió',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '72299091',
    Description: '- - - Có hàm lượng crôm từ 0,5% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '72299099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 73',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các sản phẩm bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Trong Chương này khái niệm “gang đúc” áp dụng cho các sản phẩm thu được từ quá trình đúc trong đó hàm lượng sắt tính theo khối lượng lớn hơn hàm lượng của từng nguyên tố khác và thành phần hóa học của nó khác với thành phần hóa học của thép theo định nghĩa của Chú giải 1(d) Chương 72.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong chương này từ ""dây"" là các loại sản phẩm được tạo hình nóng hoặc nguội, có hình dạng mặt cắt ngang bất kỳ với kích thước không quá 16 mm.',
    Unit: '',
  },
  {
    HsCode: '7301',
    Description:
      'Cọc cừ (sheet piling) bằng sắt hoặc thép, đã hoặc chưa khoan lỗ, đục lỗ hoặc ghép từ các bộ phận lắp ráp; sắt hoặc thép, ở dạng góc, khuôn và dạng hình, đã được hàn',
    Unit: '',
  },
  {
    HsCode: '73011000',
    Description: '- Cọc cừ',
    Unit: 'kg',
  },
  {
    HsCode: '73012000',
    Description: '- Dạng góc, khuôn và hình',
    Unit: 'kg',
  },
  {
    HsCode: '7302',
    Description:
      'Vật liệu xây dựng đường ray đường sắt hoặc đường ray tàu điện bằng sắt hoặc thép, như: ray, ray dẫn hướng và ray có răng, lưỡi ghi, ghi chéo, cần bẻ ghi và các đoạn nối chéo khác, tà vẹt (dầm ngang), thanh nối ray, gối ray, tấm đệm ray, tấm đế (đế ray), thanh chống xô, bệ đỡ (bedplate), tà vẹt và vật liệu chuyên dùng khác cho việc ghép hoặc định vị đường ray',
    Unit: '',
  },
  {
    HsCode: '73021000',
    Description: '- Ray',
    Unit: 'kg',
  },
  {
    HsCode: '73023000',
    Description: '- Lưỡi ghi, ghi chéo, cần bẻ ghi và các đoạn nối chéo khác',
    Unit: 'kg',
  },
  {
    HsCode: '73024000',
    Description: '- Thanh nối ray và tấm đế',
    Unit: 'kg',
  },
  {
    HsCode: '730290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73029010',
    Description: '- - Tà vẹt (dầm ngang)',
    Unit: 'kg',
  },
  {
    HsCode: '73029090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7303',
    Description: 'Các loại ống, ống dẫn và thanh hình rỗng, bằng gang đúc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Các loại ống và ống dẫn:',
    Unit: '',
  },
  {
    HsCode: '73030011',
    Description: '- - Ống và ống dẫn không có đầu nối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73030019',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73030091',
    Description: '- - Với đường kính ngoài không quá 100 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73030099',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7304',
    Description: 'Các loại ống, ống dẫn và thanh hình rỗng, không nối, bằng sắt (trừ gang đúc) hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ống dẫn sử dụng cho đường ống dẫn dầu hoặc khí:',
    Unit: '',
  },
  {
    HsCode: '73041100',
    Description: '- - Bằng thép không gỉ',
    Unit: 'kg',
  },
  {
    HsCode: '73041900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Ống chống, ống và ống khoan, sử dụng cho khoan dầu hoặc khí:',
    Unit: '',
  },
  {
    HsCode: '730422',
    Description: '- - Ống khoan bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '73042210',
    Description: '- - - Có giới hạn chảy(1) dưới 80.000 psi và không có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '73042290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730423',
    Description: '- - Ống khoan khác:',
    Unit: '',
  },
  {
    HsCode: '73042310',
    Description: '- - - Có giới hạn chảy(1) dưới 80.000 psi và không có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '73042390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730424',
    Description: '- - Loại khác, bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '73042410',
    Description: '- - - Ống chống và ống có giới hạn chảy(1) dưới 80.000 psi và không có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '73042420',
    Description: '- - - Ống chống và ống có giới hạn chảy(1) dưới 80.000 psi và có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '73042430',
    Description: '- - - Ống chống và ống có giới hạn chảy(1) từ 80.000 psi trở lên, có hoặc không có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '730429',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73042910',
    Description: '- - - Ống chống và ống có giới hạn chảy(1) dưới 80.000 psi và không có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '73042920',
    Description: '- - - Ống chống và ống có giới hạn chảy(1) dưới 80.000 psi và có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '73042930',
    Description: '- - - Ống chống và ống có giới hạn chảy(1) từ 80.000 psi trở lên, có hoặc không có ren ở đầu ống',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, có mặt cắt ngang hình tròn, bằng sắt hoặc thép không hợp kim:',
    Unit: '',
  },
  {
    HsCode: '730431',
    Description: '- - Được kéo nguội hoặc cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '73043110',
    Description: '- - - Ống chống và ống nối của cần khoan có ren trong và ren ngoài (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73043120',
    Description: '- - - Ống dẫn chịu áp lực cao có khả năng chịu áp lực không nhỏ hơn 42.000 psi',
    Unit: 'kg',
  },
  {
    HsCode: '73043140',
    Description: '- - - Loại khác, có đường kính ngoài dưới 140 mm và hàm lượng carbon dưới 0,45% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '73043190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730439',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73043920',
    Description: '- - - Ống dẫn chịu áp lực cao có khả năng chịu áp lực không nhỏ hơn 42.000 psi',
    Unit: 'kg',
  },
  {
    HsCode: '73043940',
    Description: '- - - Loại khác, có đường kính ngoài dưới 140 mm và hàm lượng carbon dưới 0,45% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '73043990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, có mặt cắt ngang hình tròn, bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '73044100',
    Description: '- - Được kéo nguội hoặc cán nguội (ép nguội)',
    Unit: 'kg',
  },
  {
    HsCode: '73044900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, có mặt cắt ngang hình tròn, bằng thép hợp kim khác:',
    Unit: '',
  },
  {
    HsCode: '730451',
    Description: '- - Được kéo nguội hoặc cán nguội (ép nguội):',
    Unit: '',
  },
  {
    HsCode: '73045110',
    Description: '- - - Ống chống và ống nối của cần khoan có ren trong và ren ngoài (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73045120',
    Description: '- - - Ống dẫn chịu áp lực cao có giới hạn chảy (1) không nhỏ hơn 42.000 psi',
    Unit: 'kg',
  },
  {
    HsCode: '73045190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730459',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73045910',
    Description: '- - - Ống dẫn chịu áp lực cao có giới hạn chảy (1) không nhỏ hơn 42.000 psi',
    Unit: 'kg',
  },
  {
    HsCode: '73045990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73049010',
    Description: '- - Ống dẫn chịu áp lực cao có giới hạn chảy (1) không nhỏ hơn 42.000 psi',
    Unit: 'kg',
  },
  {
    HsCode: '73049030',
    Description: '- - Loại khác, có đường kính ngoài dưới 140 mm và hàm lượng carbon dưới 0,45% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '73049090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7305',
    Description:
      'Các loại ống và ống dẫn khác bằng sắt hoặc thép (ví dụ, được hàn, tán bằng đinh hoặc ghép với nhau bằng cách tương tự), có mặt cắt ngang hình tròn, đường kính ngoài trên 406,4 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ống dẫn sử dụng cho đường ống dẫn dầu hoặc khí:',
    Unit: '',
  },
  {
    HsCode: '73051100',
    Description: '- - Hàn chìm theo chiều dọc bằng hồ quang',
    Unit: 'kg',
  },
  {
    HsCode: '730512',
    Description: '- - Loại khác, hàn theo chiều dọc:',
    Unit: '',
  },
  {
    HsCode: '73051210',
    Description: '- - - Hàn điện trở (ERW)',
    Unit: 'kg',
  },
  {
    HsCode: '73051290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73051910',
    Description: '- - - Hàn chìm xoắn hoặc xoắn ốc bằng hồ quang ',
    Unit: 'kg',
  },
  {
    HsCode: '73051990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73052000',
    Description: '- Ống chống sử dụng trong khoan dầu hoặc khí',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, được hàn:',
    Unit: '',
  },
  {
    HsCode: '730531',
    Description: '- - Hàn theo chiều dọc:',
    Unit: '',
  },
  {
    HsCode: '73053110',
    Description: '- - - Ống và ống dẫn bằng thép không gỉ',
    Unit: 'kg',
  },
  {
    HsCode: '73053190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730539',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73053910',
    Description: '- - - Ống dẫn chịu áp lực cao có giới hạn chảy (1) không nhỏ hơn 42.000 psi',
    Unit: 'kg',
  },
  {
    HsCode: '73053990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73059000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7306',
    Description:
      'Các loại ống, ống dẫn và thanh hình rỗng khác, bằng sắt hoặc thép (ví dụ, nối hở hoặc hàn, tán đinh hoặc ghép bằng cách tương tự)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ống dẫn sử dụng cho đường ống dẫn dầu hoặc khí:',
    Unit: '',
  },
  {
    HsCode: '730611',
    Description: '- - Hàn, bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '73061110',
    Description: '- - - Hàn điện trở theo chiều dọc (ERW)',
    Unit: 'kg',
  },
  {
    HsCode: '73061190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730619',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73061910',
    Description: '- - - Hàn điện trở theo chiều dọc (ERW)',
    Unit: 'kg',
  },
  {
    HsCode: '73061920',
    Description: '- - - Hàn chìm xoắn hoặc xoắn ốc bằng hồ quang ',
    Unit: 'kg',
  },
  {
    HsCode: '73061990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Ống chống và ống sử dụng cho khoan dầu hoặc khí:',
    Unit: '',
  },
  {
    HsCode: '73062100',
    Description: '- - Hàn, bằng thép không gỉ',
    Unit: 'kg',
  },
  {
    HsCode: '73062900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730630',
    Description: '- Loại khác, được hàn, có mặt cắt ngang hình tròn, bằng sắt hoặc thép không hợp kim:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Ống dùng cho nồi hơi:',
    Unit: '',
  },
  {
    HsCode: '73063011',
    Description: '- - - Với đường kính ngoài dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73063019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description:
      '- - Ống thép được mạ đồng, tráng nhựa flo (fluororesin) hoặc kẽm cromat có đường kính ngoài không quá 15 mm:',
    Unit: '',
  },
  {
    HsCode: '73063021',
    Description: '- - - Với đường kính ngoài dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73063029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73063030',
    Description:
      '- - Ống loại được sử dụng làm ống bọc (ống nhiệt) dùng cho các bộ phận phát nhiệt của bàn là điện phẳng hoặc nồi cơm điện, có đường kính ngoài không quá 12 mm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Ống dẫn chịu áp lực cao có giới hạn chảy (1) không nhỏ hơn 42.000 psi:',
    Unit: '',
  },
  {
    HsCode: '73063041',
    Description: '- - - Với đường kính ngoài dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73063049',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73063091',
    Description:
      '- - - Với đường kính trong từ 12,5 mm trở lên, đường kính ngoài dưới 140 mm và hàm lượng carbon dưới 0,45% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '73063092',
    Description: '- - - Với đường kính trong dưới 12,5 mm ',
    Unit: 'kg',
  },
  {
    HsCode: '73063099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730640',
    Description: '- Loại khác, được hàn, có mặt cắt ngang hình tròn, bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Ống dùng cho nồi hơi:',
    Unit: '',
  },
  {
    HsCode: '73064011',
    Description: '- - - Với đường kính ngoài không quá 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73064019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73064020',
    Description: '- - Ống và ống dẫn bằng thép không gỉ, có đường kính ngoài trên 105 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73064030',
    Description:
      '- - Ống và ống dẫn có chứa hàm lượng niken ít nhất là 30% tính theo khối lượng, với đường kính ngoài không quá 10 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73064090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730650',
    Description: '- Loại khác, được hàn, có mặt cắt ngang hình tròn, bằng thép hợp kim khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Ống dùng cho nồi hơi:',
    Unit: '',
  },
  {
    HsCode: '73065011',
    Description: '- - - Với đường kính ngoài dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73065019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73065091',
    Description: '- - - Với đường kính ngoài dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73065099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, được hàn, có mặt cắt ngang không phải là hình tròn:',
    Unit: '',
  },
  {
    HsCode: '730661',
    Description: '- - Mặt cắt ngang hình vuông hoặc hình chữ nhật:',
    Unit: '',
  },
  {
    HsCode: '73066110',
    Description: '- - - Có đường chéo ngoài của mặt cắt ngang dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73066190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730669',
    Description: '- - Loại khác, có mặt cắt ngang không phải là hình tròn:',
    Unit: '',
  },
  {
    HsCode: '73066910',
    Description: '- - - Có đường chéo ngoài của mặt cắt ngang dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73066990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Ống và ống dẫn hàn đồng (cooper brazed):',
    Unit: '',
  },
  {
    HsCode: '73069011',
    Description: '- - - Có đường chéo ngoài của mặt cắt ngang dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73069019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73069091',
    Description:
      '- - - Ống dẫn chịu áp lực cao có giới hạn chảy (1) không nhỏ hơn 42.000 psi, đường kính trong nhỏ hơn 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73069094',
    Description: '- - - Các loại ống dẫn chịu áp lực cao khác, có đường kính ngoài nhỏ hơn 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73069095',
    Description: '- - - Các loại ống dẫn chịu áp lực cao khác, có đường kính ngoài từ 12,5 mm trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '73069096',
    Description: '- - - Loại khác, có đường chéo ngoài của mặt cắt ngang dưới 12,5 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73069097',
    Description:
      '- - - Loại khác, có đường kính trong trên 12,5 mm, đường kính ngoài nhỏ hơn 140 mm và chứa dưới 0,45% carbon tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '73069099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7307',
    Description: 'Phụ kiện ghép nối cho ống hoặc ống dẫn (ví dụ, khớp nối đôi, khuỷu, măng sông), bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Phụ kiện dạng đúc:',
    Unit: '',
  },
  {
    HsCode: '730711',
    Description: '- - Bằng gang đúc không dẻo:',
    Unit: '',
  },
  {
    HsCode: '73071110',
    Description: '- - - Phụ kiện của ống hoặc ống dẫn không có đầu nối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73071190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73071900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '730721',
    Description: '- - Mặt bích:',
    Unit: '',
  },
  {
    HsCode: '73072110',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73072190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730722',
    Description: '- - Ống khuỷu, khuỷu nối ống và măng sông, loại có ren để ghép nối:',
    Unit: '',
  },
  {
    HsCode: '73072210',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73072290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730723',
    Description: '- - Loại hàn giáp mối:',
    Unit: '',
  },
  {
    HsCode: '73072310',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73072390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730729',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73072910',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73072990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '730791',
    Description: '- - Mặt bích:',
    Unit: '',
  },
  {
    HsCode: '73079110',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73079190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730792',
    Description: '- - Ống khuỷu, khuỷu nối ống và măng sông, loại có ren để ghép nối:',
    Unit: '',
  },
  {
    HsCode: '73079210',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73079290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730793',
    Description: '- - Loại hàn giáp mối:',
    Unit: '',
  },
  {
    HsCode: '73079310',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73079390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730799',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73079910',
    Description: '- - - Có đường kính trong dưới 15 cm',
    Unit: 'kg',
  },
  {
    HsCode: '73079990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7308',
    Description:
      'Các kết cấu (trừ nhà lắp ghép thuộc nhóm 94.06) và các bộ phận rời của các kết cấu (ví dụ, cầu và nhịp cầu, cửa cống, tháp, cột lưới, mái nhà, khung mái, cửa ra vào, cửa sổ, và các loại khung cửa, ngưỡng cửa ra vào, cửa chớp, lan can, cột trụ và các loại cột khác), bằng sắt hoặc thép; tấm, thanh, góc, khuôn, hình, ống và các loại tương tự, đã được gia công để dùng làm kết cấu, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '730810',
    Description: '- Cầu và nhịp cầu:',
    Unit: '',
  },
  {
    HsCode: '73081010',
    Description: '- - Dạng cấu kiện tiền chế được lắp ráp bằng các khớp nối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73081090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730820',
    Description: '- Tháp và cột lưới (kết cấu giàn):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Tháp:',
    Unit: '',
  },
  {
    HsCode: '73082011',
    Description: '- - - Dạng cấu kiện tiền chế được lắp ráp bằng các khớp nối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73082019',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Cột lưới (kết cấu giàn):',
    Unit: '',
  },
  {
    HsCode: '73082021',
    Description: '- - - Dạng cấu kiện tiền chế được lắp ráp bằng các khớp nối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73082029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730830',
    Description: '- Cửa ra vào, cửa sổ và các loại khung cửa và ngưỡng cửa ra vào:',
    Unit: '',
  },
  {
    HsCode: '73083010',
    Description: '- - Cửa ra vào, có độ dày từ 6 mm đến 8 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73083090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730840',
    Description: '- Thiết bị dùng cho giàn giáo, ván khuôn, vật chống hoặc cột trụ chống hầm lò:',
    Unit: '',
  },
  {
    HsCode: '73084010',
    Description: '- - Dạng cấu kiện tiền chế được lắp ráp bằng các khớp nối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73084090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '730890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73089020',
    Description: '- - Dạng cấu kiện tiền chế được lắp ráp bằng các khớp nối (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73089040',
    Description:
      '- - Tấm hoặc lá mạ kẽm được làm lượn sóng và uốn cong dùng trong ống dẫn, cống ngầm hoặc đường hầm (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73089050',
    Description: '- - Khung ray dùng để vận chuyển công-ten-nơ trên tàu thủy (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73089060',
    Description: '- - Máng đỡ cáp điện có lỗ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73089092',
    Description: '- - - Lan can bảo vệ',
    Unit: 'kg',
  },
  {
    HsCode: '73089099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7309',
    Description:
      'Các loại bể chứa, két, bình chứa và các thùng chứa tương tự dùng để chứa mọi loại vật liệu (trừ khí nén hoặc khí hóa lỏng), bằng sắt hoặc thép, có dung tích trên 300 lít, đã hoặc chưa được lót hoặc được cách nhiệt, nhưng chưa được lắp ráp với thiết bị cơ khí hoặc thiết bị nhiệt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Loại sử dụng trong vận chuyển hoặc đóng gói hàng hóa:',
    Unit: '',
  },
  {
    HsCode: '73090011',
    Description: '- - Được lót hoặc được cách nhiệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73090019',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73090091',
    Description: '- - Được lót hoặc được cách nhiệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73090099',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7310',
    Description:
      'Các loại đồ chứa dạng két, thùng phuy, thùng hình trống, hình hộp, lon và các loại đồ chứa tương tự, dùng để chứa mọi loại vật liệu (trừ khí nén hoặc khí hóa lỏng), bằng sắt hoặc thép, dung tích không quá 300 lít, đã hoặc chưa được lót hoặc được cách nhiệt, nhưng chưa được ghép với thiết bị cơ khí hoặc thiết bị nhiệt',
    Unit: '',
  },
  {
    HsCode: '731010',
    Description: '- Có dung tích từ 50 lít trở lên:',
    Unit: '',
  },
  {
    HsCode: '73101010',
    Description: '- - Được tráng thiếc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73101091',
    Description: '- - - Được đúc, rèn hoặc dập, ở dạng thô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73101099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Có dung tích dưới 50 lít:',
    Unit: '',
  },
  {
    HsCode: '731021',
    Description: '- - Lon, hộp được đóng kín bằng cách hàn hoặc gấp nếp (vê mép):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có dung tích dưới 1 lít:',
    Unit: '',
  },
  {
    HsCode: '73102111',
    Description: '- - - - Được tráng thiếc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73102119',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73102191',
    Description: '- - - - Được tráng thiếc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73102199',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '731029',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có dung tích dưới 1 lít:',
    Unit: '',
  },
  {
    HsCode: '73102911',
    Description: '- - - - Được tráng thiếc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73102919',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73102991',
    Description: '- - - - Được tráng thiếc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73102992',
    Description: '- - - - Được đúc, rèn hoặc dập khác, ở dạng thô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73102999',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7311',
    Description: 'Các loại thùng chứa khí nén hoặc khí hóa lỏng, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dạng hình trụ bằng thép đúc liền:',
    Unit: '',
  },
  {
    HsCode: '73110021',
    Description:
      '- - Có dung tích từ 30 lít trở lên, nhưng dưới 110 lít, dùng để chứa khí tự nhiên nén (CNG) hoặc khí tự nhiên hóa lỏng (LNG) cho ô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110022',
    Description:
      '- - Có dung tích từ 110 lít trở lên, dùng để chứa khí tự nhiên nén (CNG) hoặc khí tự nhiên hóa lỏng (LNG) cho ô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110023',
    Description: '- - Có dung tích dưới 30 lít, dùng để chứa khí dầu mỏ hóa lỏng (LPG)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110024',
    Description: '- - Có dung tích từ 30 lít trở lên nhưng dưới 110 lít, dùng để chứa khí dầu mỏ hóa lỏng (LPG)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110025',
    Description: '- - Loại khác, dùng để chứa khí dầu mỏ hóa lỏng (LPG)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110026',
    Description: '- - Loại khác, có dung tích dưới 30 lít',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110027',
    Description: '- - Loại khác, có dung tích từ 30 lít trở lên nhưng dưới 110 lít',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110029',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73110091',
    Description: '- - Có dung tích không quá 7,3 lít',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110092',
    Description: '- - Có dung tích trên 7,3 lít nhưng dưới 30 lít',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110094',
    Description: '- - Có dung tích từ 30 lít trở lên nhưng dưới 110 lít',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73110099',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7312',
    Description: 'Dây bện tao, thừng, cáp, băng tết, dây treo và các loại tương tự, bằng sắt hoặc thép, chưa cách điện',
    Unit: '',
  },
  {
    HsCode: '731210',
    Description: '- Dây bện tao, thừng và cáp:',
    Unit: '',
  },
  {
    HsCode: '73121010',
    Description: '- - Cuộn dây bện tao kiểu bọc, dây tao dẹt và dây cáp chống xoắn(1) (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '73121020',
    Description: '- - Loại được phủ, mạ hoặc tráng bằng đồng thau và có đường kính không quá 3 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73121030',
    Description: '- - Được phủ, mạ hoặc tráng nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73121091',
    Description: '- - - Dây thép bện tao cho bê tông dự ứng lực',
    Unit: 'kg',
  },
  {
    HsCode: '73121099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73129000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73130000',
    Description:
      'Dây gai bằng sắt hoặc thép; dây đai xoắn hoặc dây đơn dẹt, có gai hoặc không có gai, và dây đôi xoắn, dùng làm hàng rào, bằng sắt hoặc thép',
    Unit: 'kg',
  },
  {
    HsCode: '7314',
    Description:
      'Tấm đan (kể cả đai liền), phên, lưới và rào, làm bằng dây sắt hoặc thép; sản phẩm dạng lưới được tạo hình bằng phương pháp đột dập và kéo giãn thành lưới bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tấm đan dệt thoi:',
    Unit: '',
  },
  {
    HsCode: '73141200',
    Description: '- - Loại đai liền dùng cho máy móc, bằng thép không gỉ',
    Unit: 'kg',
  },
  {
    HsCode: '73141400',
    Description: '- - Tấm đan dệt thoi khác, bằng thép không gỉ ',
    Unit: 'kg',
  },
  {
    HsCode: '731419',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73141910',
    Description: '- - - Đai liền dùng cho máy móc, trừ loại bằng thép không gỉ',
    Unit: 'kg',
  },
  {
    HsCode: '73141990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73142000',
    Description:
      '- Phên, lưới và rào, được hàn ở mắt nối, bằng dây với kích thước mặt cắt ngang tối đa từ 3 mm trở lên và có cỡ mắt lưới từ 100 cm2 trở lên',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Các loại phên, lưới và rào khác, được hàn ở các mắt nối:',
    Unit: '',
  },
  {
    HsCode: '73143100',
    Description: '- - Được phủ, mạ hoặc tráng kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '73143900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Tấm đan (cloth), phên, lưới và rào khác:',
    Unit: '',
  },
  {
    HsCode: '73144100',
    Description: '- - Được phủ, mạ hoặc tráng kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '73144200',
    Description: '- - Được phủ plastic',
    Unit: 'kg',
  },
  {
    HsCode: '73144900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73145000',
    Description: '- Sản phẩm dạng lưới được tạo hình bằng phương pháp đột dập và kéo giãn thành lưới ',
    Unit: 'kg',
  },
  {
    HsCode: '7315',
    Description: 'Xích và các bộ phận của xích, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Xích gồm nhiều mắt được nối bằng khớp dạng bản lề và các bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '731511',
    Description: '- - Xích con lăn:',
    Unit: '',
  },
  {
    HsCode: '73151110',
    Description: '- - - Xích xe đạp hoặc xích xe mô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73151191',
    Description: '- - - - Xích truyền, có độ dài mắt xích từ 6 mm đến 32 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73151199',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '731512',
    Description: '- - Xích khác:',
    Unit: '',
  },
  {
    HsCode: '73151210',
    Description: '- - - Xích xe đạp hoặc xích xe mô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73151290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '731519',
    Description: '- - Các bộ phận:',
    Unit: '',
  },
  {
    HsCode: '73151910',
    Description: '- - - Xích xe đạp hoặc xích xe mô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73151990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73152000',
    Description: '- Xích trượt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Xích khác:',
    Unit: '',
  },
  {
    HsCode: '73158100',
    Description: '- - Nối bằng chốt có ren hai đầu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73158200',
    Description: '- - Loại khác, ghép nối bằng mối hàn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '731589',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73158910',
    Description: '- - - Xích xe đạp hoặc xích xe mô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73158990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '731590',
    Description: '- Các bộ phận khác:',
    Unit: '',
  },
  {
    HsCode: '73159020',
    Description: '- - Xích xe đạp hoặc xích xe mô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73159090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73160000',
    Description: 'Neo tàu (1), neo móc và các bộ phận của chúng, bằng sắt hoặc thép',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7317',
    Description:
      'Đinh, đinh bấm, đinh ấn (đinh rệp), đinh gấp, ghim dập (trừ các sản phẩm thuộc nhóm 83.05) và các sản phẩm tương tự, bằng sắt hoặc thép, có hoặc không có đầu bằng vật liệu khác, nhưng trừ loại có đầu bằng đồng',
    Unit: '',
  },
  {
    HsCode: '73170010',
    Description: '- Đinh dây',
    Unit: 'kg',
  },
  {
    HsCode: '73170020',
    Description: '- Ghim dập',
    Unit: 'kg',
  },
  {
    HsCode: '73170030',
    Description: '- Đinh giữ ray cho tà vẹt đường ray; bàn chông',
    Unit: 'kg',
  },
  {
    HsCode: '73170090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7318',
    Description:
      'Vít, bu lông, đai ốc, vít đầu vuông, vít treo, đinh tán, chốt hãm, chốt định vị, vòng đệm (kể cả vòng đệm lò xo vênh) và các sản phẩm tương tự, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Các sản phẩm đã được ren:',
    Unit: '',
  },
  {
    HsCode: '73181100',
    Description: '- - Vít đầu vuông',
    Unit: 'kg',
  },
  {
    HsCode: '731812',
    Description: '- - Vít khác dùng cho gỗ:',
    Unit: '',
  },
  {
    HsCode: '73181210',
    Description: '- - - Đường kính ngoài của thân không quá 16 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73181290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73181300',
    Description: '- - Đinh móc và đinh vòng',
    Unit: 'kg',
  },
  {
    HsCode: '731814',
    Description: '- - Vít tự hãm:',
    Unit: '',
  },
  {
    HsCode: '73181410',
    Description: '- - - Đường kính ngoài của thân không quá 16 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73181490',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '731815',
    Description: '- - Đinh vít và bu lông khác, có hoặc không có đai ốc hoặc vòng đệm:',
    Unit: '',
  },
  {
    HsCode: '73181510',
    Description: '- - - Đường kính ngoài của thân không quá 16 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73181590',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '731816',
    Description: '- - Đai ốc:',
    Unit: '',
  },
  {
    HsCode: '73181610',
    Description: '- - - Cho bu lông có đường kính ngoài của thân không quá 16 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73181690',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '731819',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73181910',
    Description: '- - - Đường kính ngoài của thân không quá 16 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73181990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Các sản phẩm không có ren:',
    Unit: '',
  },
  {
    HsCode: '73182100',
    Description: '- - Vòng đệm lò xo vênh và vòng đệm hãm khác',
    Unit: 'kg',
  },
  {
    HsCode: '73182200',
    Description: '- - Vòng đệm khác',
    Unit: 'kg',
  },
  {
    HsCode: '731823',
    Description: '- - Đinh tán:',
    Unit: '',
  },
  {
    HsCode: '73182310',
    Description: '- - - Đường kính ngoài không quá 16 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73182390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '73182400',
    Description: '- - Chốt hãm và chốt định vị',
    Unit: 'kg',
  },
  {
    HsCode: '731829',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73182910',
    Description: '- - - Đường kính ngoài của thân không quá 16 mm',
    Unit: 'kg',
  },
  {
    HsCode: '73182990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7319',
    Description:
      'Kim khâu, kim đan, cái xỏ dây, kim móc, kim thêu và các loại tương tự, để sử dụng bằng tay, bằng sắt hoặc thép; ghim băng và các loại ghim khác bằng sắt hoặc thép, chưa được ghi hoặc chi tiết ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '731940',
    Description: '- Ghim băng và các loại ghim khác:',
    Unit: '',
  },
  {
    HsCode: '73194010',
    Description: '- - Ghim băng',
    Unit: 'kg',
  },
  {
    HsCode: '73194020',
    Description: '- - Các loại ghim khác',
    Unit: 'kg',
  },
  {
    HsCode: '731990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73199010',
    Description: '- - Kim khâu, kim mạng hoặc kim thêu',
    Unit: 'kg',
  },
  {
    HsCode: '73199090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7320',
    Description: 'Lò xo và lá lò xo, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '732010',
    Description: '- Lò xo lá và các lá lò xo:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho xe có động cơ hoặc các loại máy thuộc nhóm 84.29 hoặc 84.30:',
    Unit: '',
  },
  {
    HsCode: '73201011',
    Description: '- - - Dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73201012',
    Description: '- - - Dùng cho xe có động cơ khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73201019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73201090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732020',
    Description: '- Lò xo cuộn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho xe có động cơ hoặc các loại máy thuộc nhóm 84.29 hoặc 84.30:',
    Unit: '',
  },
  {
    HsCode: '73202011',
    Description: ' - - - Dùng cho xe có động cơ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73202012',
    Description: ' - - - Dùng cho máy làm đất',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73202019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73202090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73209010',
    Description: '- - Dùng cho xe có động cơ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73209090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7321',
    Description:
      'Bếp, bếp có lò nướng, vỉ lò, bếp nấu (kể cả loại có nồi hơi phụ dùng cho hệ thống gia nhiệt trung tâm), vỉ nướng, lò nướng, lò ga hình vòng, dụng cụ hâm nóng dạng tấm và các loại đồ dùng gia đình không dùng điện tương tự, và các bộ phận của chúng, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ nấu và dụng cụ hâm nóng dạng tấm:',
    Unit: '',
  },
  {
    HsCode: '73211100',
    Description: '- - Loại dùng nhiên liệu khí hoặc dùng cả khí và nhiên liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '73211200',
    Description: '- - Loại dùng nhiên liệu lỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '732119',
    Description: '- - Loại khác, kể cả dụng cụ dùng nhiên liệu rắn:',
    Unit: '',
  },
  {
    HsCode: '73211910',
    Description: '- - - Loại dùng nhiên liệu rắn',
    Unit: 'chiếc',
  },
  {
    HsCode: '73211990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '73218100',
    Description: '- - Loại dùng nhiên liệu khí hoặc dùng cả khí và nhiên liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '73218200',
    Description: '- - Loại dùng nhiên liệu lỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '73218900',
    Description: '- - Loại khác, kể cả dụng cụ dùng nhiên liệu rắn',
    Unit: 'chiếc',
  },
  {
    HsCode: '732190',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '73219010',
    Description: '- - Của bếp dầu hỏa (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của dụng cụ nấu và dụng cụ hâm nóng dạng tấm dùng nhiên liệu khí (SEN):',
    Unit: '',
  },
  {
    HsCode: '73219021',
    Description: '- - - Đầu đốt (burner); các bộ phận được sản xuất bằng quá trình dập hoặc ép(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73219029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73219090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7322',
    Description:
      'Lò sưởi của hệ thống nhiệt trung tâm không dùng điện và các bộ phận của chúng, bằng sắt hoặc thép; thiết bị làm nóng không khí và bộ phận phân phối khí nóng (kể cả loại có khả năng phân phối không khí tự nhiên hoặc khí điều hòa), không làm nóng bằng điện, có lắp quạt hoặc quạt thổi chạy bằng mô tơ, và bộ phận của chúng, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Lò sưởi và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '73221100',
    Description: '- - Bằng gang đúc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73221900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73229000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7323',
    Description:
      'Bộ đồ ăn, đồ nhà bếp hoặc các loại đồ gia dụng khác và các bộ phận của chúng, bằng sắt hoặc thép; bùi nhùi bằng sắt hoặc thép; miếng cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '73231000',
    Description: '- Bùi nhùi bằng sắt hoặc thép; miếng cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '732391',
    Description: '- - Bằng gang đúc, chưa tráng men:',
    Unit: '',
  },
  {
    HsCode: '73239110',
    Description: '- - - Đồ dùng nhà bếp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239120',
    Description: '- - - Gạt tàn thuốc lá ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239200',
    Description: '- - Bằng gang đúc, đã tráng men',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732393',
    Description: '- - Bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '73239310',
    Description: '- - - Đồ dùng nhà bếp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239320',
    Description: '- - - Gạt tàn thuốc lá ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239400',
    Description: '- - Bằng sắt (trừ gang đúc) hoặc thép, đã tráng men',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732399',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73239910',
    Description: '- - - Đồ dùng nhà bếp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239920',
    Description: '- - - Gạt tàn thuốc lá ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73239990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7324',
    Description: 'Thiết bị vệ sinh và các bộ phận của chúng, bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '732410',
    Description: '- Chậu rửa và bồn rửa, bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '73241010',
    Description: '- - Bồn rửa nhà bếp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73241090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Bồn tắm:',
    Unit: '',
  },
  {
    HsCode: '732421',
    Description: '- - Bằng gang đúc, đã hoặc chưa được tráng men:',
    Unit: '',
  },
  {
    HsCode: '73242110',
    Description: '- - - Bồn tắm có hình dạng bên trong là hình chữ nhật hoặc hình thuôn (oblong)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73242190',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732429',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73242910',
    Description: '- - - Bồn tắm có hình dạng bên trong là hình chữ nhật hoặc hình thuôn (oblong)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73242990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732490',
    Description: '- Loại khác, kể cả các bộ phận:',
    Unit: '',
  },
  {
    HsCode: '73249010',
    Description: '- - Dùng cho bệ xí hoặc bệ đi tiểu giật nước (loại cố định)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73249091',
    Description: '- - - Bộ phận của bồn rửa nhà bếp hoặc bồn tắm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73249093',
    Description: '- - - Bộ phận của bệ xí hoặc bệ đi tiểu giật nước (loại cố định)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73249099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7325',
    Description: 'Các sản phẩm đúc khác bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '732510',
    Description: '- Bằng gang đúc không dẻo:',
    Unit: '',
  },
  {
    HsCode: '73251020',
    Description: '- - Nắp cống, lưới che cống và khung của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73251030',
    Description: '- - Máng và chén để thu mủ cao su',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73251090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73259100',
    Description: '- - Bi nghiền và các hàng hóa tương tự dùng cho máy nghiền',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73259920',
    Description: '- - - Nắp cống, lưới che cống và khung của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73259990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7326',
    Description: 'Các sản phẩm khác bằng sắt hoặc thép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đã được rèn hoặc dập, nhưng chưa được gia công tiếp:',
    Unit: '',
  },
  {
    HsCode: '73261100',
    Description: '- - Bi nghiền và các hàng hóa tương tự dùng cho máy nghiền',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73261900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732620',
    Description: '- Sản phẩm bằng dây sắt hoặc thép:',
    Unit: '',
  },
  {
    HsCode: '73262050',
    Description: '- - Lồng nuôi gia cầm và loại tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73262060',
    Description: '- - Bẫy chuột',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73262070',
    Description: '- - Rèm (blinds) và rèm lưới',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73262090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '732690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73269010',
    Description: '- - Bánh lái tàu thủy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73269020',
    Description: '- - Máng và chén để thu mủ cao su',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73269030',
    Description:
      '- - Bộ kẹp bằng thép không gỉ đã lắp với măng sông cao su dùng cho các ống không có đầu nối và phụ kiện ghép nối bằng gang đúc(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73269060',
    Description: '- - Đèn Bunsen',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73269070',
    Description: '- - Móng ngựa; mấu, gai, đinh móc lắp trên giày để thúc ngựa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '73269091',
    Description: '- - - Hộp đựng thuốc lá điếu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '73269099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 197-1:2014, TCVN 8855-1:2011 và TCVN 5905:1995',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 74',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đồng và các sản phẩm bằng đồng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (a) Đồng tinh luyện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Kim loại có ít nhất 99,85% tính theo khối lượng là đồng; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Kim loại có ít nhất 97,5% tính theo khối lượng là đồng, với điều kiện hàm lượng các tạp chất không vượt quá giới hạn ghi trong bảng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 4',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hợp kim đồng',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Vật liệu kim loại trừ đồng chưa tinh luyện trong đó hàm lượng đồng tính theo khối lượng lớn hơn so với từng nguyên tố khác, với điều kiện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) hàm lượng của ít nhất một trong các nguyên tố khác phải lớn hơn giới hạn đã nêu trong bảng trên; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) tổng hàm lượng của các nguyên tố khác lớn hơn 2,5%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các hợp kim đồng chủ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Hợp kim chứa các nguyên tố khác với hàm lượng đồng lớn hơn 10% tính theo khối lượng, không có tính rèn và thường sử dụng như chất phụ gia trong sản xuất các hợp kim khác hoặc như tác nhân khử ô-xi, khử lưu huỳnh hoặc tác dụng tương tự trong ngành luyện kim màu. Tuy nhiên, đồng photphua (phosphor copper) có hàm lượng phospho trên 15% tính theo khối lượng phải xếp vào nhóm 28.53.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hợp kim đồng-kẽm (đồng thau)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hợp kim đồng và kẽm, có hoặc không có các nguyên tố khác. Khi có các nguyên tố khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- hàm lượng kẽm trội hơn so với hàm lượng của mỗi nguyên tố khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- hàm lượng niken dưới 5% tính theo khối lượng (xem phần hợp kim đồng-niken-kẽm (bạc niken)); và',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- hàm lượng thiếc dưới 3% tính theo khối lượng (xem phần hợp kim đồng-thiếc (đồng thanh)).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hợp kim đồng-thiếc (đồng thanh)',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Hợp kim đồng và thiếc, có hoặc không có các nguyên tố khác. Trường hợp có nguyên tố khác, hàm lượng thiếc phải trội hơn so với hàm lượng của từng nguyên tố khác, trừ trường hợp khi thành phần thiếc từ 3% trở lên thì hàm lượng kẽm có thể cao hơn thiếc nhưng phải dưới 10% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Hợp kim đồng-niken-kẽm (bạc niken)',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Hợp kim đồng, niken và kẽm, có hoặc không có các nguyên tố khác. Hàm lượng niken từ 5% tính theo khối lượng trở lên (xem phần hợp kim đồng kẽm (đồng thau)).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Hợp kim đồng-niken',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Hợp kim đồng và niken, có hoặc không có các nguyên tố khác nhưng trong mọi trường hợp, hàm lượng kẽm chứa trong đó không quá 1% tính theo khối lượng. Trong trường hợp có nguyên tố khác, hàm lượng của niken phải trội hơn so với hàm lượng của từng nguyên tố khác.',
    Unit: '',
  },
  {
    HsCode: '7401',
    Description: 'Sten đồng; đồng xi măng hóa (đồng kết tủa)',
    Unit: '',
  },
  {
    HsCode: '74010010',
    Description: '- Sten đồng',
    Unit: 'kg',
  },
  {
    HsCode: '74010020',
    Description: '- Đồng xi măng hóa (đồng kết tủa)',
    Unit: 'kg',
  },
  {
    HsCode: '7402',
    Description: 'Đồng chưa tinh luyện; a-nốt đồng dùng cho điện phân tinh luyện',
    Unit: '',
  },
  {
    HsCode: '74020010',
    Description: '- Đồng xốp (blister copper)',
    Unit: 'kg',
  },
  {
    HsCode: '74020090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7403',
    Description: 'Đồng tinh luyện và hợp kim đồng, chưa gia công',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đồng tinh luyện:',
    Unit: '',
  },
  {
    HsCode: '74031100',
    Description: '- - Ca-tốt và các phần của ca-tốt',
    Unit: 'kg',
  },
  {
    HsCode: '74031200',
    Description: '- - Thanh để kéo dây',
    Unit: 'kg',
  },
  {
    HsCode: '74031300',
    Description: '- - Que',
    Unit: 'kg',
  },
  {
    HsCode: '74031900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Hợp kim đồng:',
    Unit: '',
  },
  {
    HsCode: '74032100',
    Description: '- - Hợp kim đồng-kẽm (đồng thau)',
    Unit: 'kg',
  },
  {
    HsCode: '74032200',
    Description: '- - Hợp kim đồng - thiếc (đồng thanh)',
    Unit: 'kg',
  },
  {
    HsCode: '74032900',
    Description: '- - Hợp kim đồng khác (trừ các loại hợp kim đồng chủ thuộc nhóm 74.05)',
    Unit: 'kg',
  },
  {
    HsCode: '74040000',
    Description: 'Phế liệu và mảnh vụn của đồng',
    Unit: 'kg',
  },
  {
    HsCode: '74050000',
    Description: 'Hợp kim đồng chủ',
    Unit: 'kg',
  },
  {
    HsCode: '7406',
    Description: 'Bột và vảy đồng',
    Unit: '',
  },
  {
    HsCode: '74061000',
    Description: '- Bột không có cấu trúc lớp',
    Unit: 'kg',
  },
  {
    HsCode: '74062000',
    Description: '- Bột có cấu trúc lớp; vảy đồng',
    Unit: 'kg',
  },
  {
    HsCode: '7407',
    Description: 'Đồng ở dạng thanh, que và dạng hình',
    Unit: '',
  },
  {
    HsCode: '740710',
    Description: '- Bằng đồng tinh luyện:',
    Unit: '',
  },
  {
    HsCode: '74071030',
    Description: '- - Dạng hình',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Dạng thanh và que:',
    Unit: '',
  },
  {
    HsCode: '74071041',
    Description: '- - - Mặt cắt ngang hình vuông hoặc hình chữ nhật',
    Unit: 'kg',
  },
  {
    HsCode: '74071049',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bằng hợp kim đồng:',
    Unit: '',
  },
  {
    HsCode: '74072100',
    Description: '- - Bằng hợp kim đồng - kẽm (đồng thau)',
    Unit: 'kg',
  },
  {
    HsCode: '74072900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7408',
    Description: 'Dây đồng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng đồng tinh luyện:',
    Unit: '',
  },
  {
    HsCode: '740811',
    Description: '- - Có kích thước mặt cắt ngang lớn nhất trên 6 mm:',
    Unit: '',
  },
  {
    HsCode: '74081120',
    Description: '- - - Có kích thước mặt cắt ngang lớn nhất không quá 8 mm',
    Unit: 'kg',
  },
  {
    HsCode: '74081130',
    Description: '- - - Có kích thước mặt cắt ngang lớn nhất trên 8 mm nhưng không quá 14 mm',
    Unit: 'kg',
  },
  {
    HsCode: '74081190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '740819',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '74081910',
    Description: '- - - Được phủ, mạ hoặc tráng vàng hoặc bạc (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '74081990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bằng hợp kim đồng:',
    Unit: '',
  },
  {
    HsCode: '74082100',
    Description: '- - Bằng hợp kim đồng - kẽm (đồng thau)',
    Unit: 'kg',
  },
  {
    HsCode: '74082200',
    Description: '- - Bằng hợp kim đồng - niken (đồng kền) hoặc hợp kim đồng - niken - kẽm (bạc-niken)',
    Unit: 'kg',
  },
  {
    HsCode: '740829',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '74082910',
    Description: '- - - Hợp kim đồng-thiếc (đồng thanh)(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '74082990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7409',
    Description: 'Đồng ở dạng tấm, lá và dải, có chiều dày trên 0,15 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng đồng tinh luyện:',
    Unit: '',
  },
  {
    HsCode: '74091100',
    Description: '- - Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '74091900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bằng hợp kim đồng - kẽm (đồng thau):',
    Unit: '',
  },
  {
    HsCode: '74092100',
    Description: '- - Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '74092900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bằng hợp kim đồng - thiếc (đồng thanh):',
    Unit: '',
  },
  {
    HsCode: '74093100',
    Description: '- - Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '74093900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '74094000',
    Description: '- Bằng hợp kim đồng-niken (đồng kền) hoặc hợp kim đồng - niken - kẽm (bạc niken)',
    Unit: 'kg',
  },
  {
    HsCode: '74099000',
    Description: '- Bằng hợp kim đồng khác',
    Unit: 'kg',
  },
  {
    HsCode: '7410',
    Description:
      'Đồng lá mỏng (đã hoặc chưa in hoặc bồi trên giấy, bìa, plastic hoặc vật liệu bồi tương tự), với chiều dày (không kể phần bồi) không quá 0,15 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa được bồi:',
    Unit: '',
  },
  {
    HsCode: '741011',
    Description: '- - Bằng đồng tinh luyện:',
    Unit: '',
  },
  {
    HsCode: '74101110',
    Description: '- - - Tấm ép lớp được dát phủ đồng dùng làm tấm mạch in (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '74101190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '74101200',
    Description: '- - Bằng hợp kim đồng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Đã được bồi:',
    Unit: '',
  },
  {
    HsCode: '74102100',
    Description: '- - Bằng đồng tinh luyện',
    Unit: 'kg',
  },
  {
    HsCode: '74102200',
    Description: '- - Bằng hợp kim đồng',
    Unit: 'kg',
  },
  {
    HsCode: '7411',
    Description: 'Các loại ống và ống dẫn bằng đồng',
    Unit: '',
  },
  {
    HsCode: '74111000',
    Description: '- Bằng đồng tinh luyện',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bằng hợp kim đồng:',
    Unit: '',
  },
  {
    HsCode: '74112100',
    Description: '- - Bằng hợp kim đồng - kẽm (đồng thau)',
    Unit: 'kg',
  },
  {
    HsCode: '74112200',
    Description: '- - Bằng hợp kim đồng - niken (đồng kền) hoặc hợp kim đồng - niken - kẽm (bạc niken)',
    Unit: 'kg',
  },
  {
    HsCode: '74112900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7412',
    Description: 'Phụ kiện để ghép nối của ống hoặc ống dẫn bằng đồng (ví dụ, khớp nối đôi, nối khuỷu, măng sông)',
    Unit: '',
  },
  {
    HsCode: '74121000',
    Description: '- Bằng đồng tinh luyện',
    Unit: 'kg',
  },
  {
    HsCode: '741220',
    Description: '- Bằng hợp kim đồng:',
    Unit: '',
  },
  {
    HsCode: '74122020',
    Description: '- - Phụ kiện ghép nối cho ống vòi(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '74122091',
    Description: '- - - Bằng hợp kim đồng - kẽm (đồng thau)',
    Unit: 'kg',
  },
  {
    HsCode: '74122099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7413',
    Description: 'Dây bện tao, cáp, dây tết và các loại tương tự, bằng đồng, chưa được cách điện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có đường kính không quá 28,28 mm:',
    Unit: '',
  },
  {
    HsCode: '74130011',
    Description: '- - Cáp',
    Unit: 'kg',
  },
  {
    HsCode: '74130019',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '74130091',
    Description: '- - Cáp',
    Unit: 'kg',
  },
  {
    HsCode: '74130099',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7415',
    Description:
      'Đinh, đinh bấm, đinh ấn, ghim dập (trừ các loại thuộc nhóm 83.05) và các sản phẩm tương tự, bằng đồng hoặc bằng sắt hoặc thép có đầu bịt đồng; đinh vít, bu lông, đai ốc, đinh móc, đinh tán, chốt hãm, chốt định vị, vòng đệm (kể cả vòng đệm lò xo vênh) và các sản phẩm tương tự, bằng đồng',
    Unit: '',
  },
  {
    HsCode: '741510',
    Description: '- Đinh và đinh bấm, đinh ấn, ghim dập và các sản phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '74151010',
    Description: '- - Đinh',
    Unit: 'kg',
  },
  {
    HsCode: '74151020',
    Description: '- - Ghim dập',
    Unit: 'kg',
  },
  {
    HsCode: '74151090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, chưa được ren:',
    Unit: '',
  },
  {
    HsCode: '74152100',
    Description: '- - Vòng đệm (kể cả vòng đệm lò xo vênh)',
    Unit: 'kg',
  },
  {
    HsCode: '74152900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác, đã được ren:',
    Unit: '',
  },
  {
    HsCode: '741533',
    Description: '- - Đinh vít; bu lông và đai ốc:',
    Unit: '',
  },
  {
    HsCode: '74153310',
    Description: '- - - Đinh vít',
    Unit: 'kg',
  },
  {
    HsCode: '74153320',
    Description: '- - - Bu lông và đai ốc',
    Unit: 'kg',
  },
  {
    HsCode: '74153900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7418',
    Description:
      'Bộ đồ ăn, đồ nhà bếp hoặc đồ gia dụng khác và các bộ phận của chúng, bằng đồng; miếng cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự, bằng đồng; đồ trang bị trong nhà vệ sinh và các bộ phận của chúng, bằng đồng',
    Unit: '',
  },
  {
    HsCode: '741810',
    Description:
      '- Bộ đồ ăn, đồ nhà bếp hoặc các đồ gia dụng khác và các bộ phận của chúng; miếng cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '74181010',
    Description: '- - Miếng cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74181030',
    Description:
      '- - Thiết bị nấu hoặc đun nóng dùng trong gia đình, không dùng điện và các bộ phận của các sản phẩm này',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74181090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74182000',
    Description: '- Đồ trang bị trong nhà vệ sinh và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7419',
    Description: 'Các sản phẩm khác bằng đồng',
    Unit: '',
  },
  {
    HsCode: '741920',
    Description: '- Đã được đúc, đúc khuôn, rập hoặc rèn nhưng chưa được gia công thêm:',
    Unit: '',
  },
  {
    HsCode: '74192010',
    Description: '- - Xích và các bộ phận của xích',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74192020',
    Description:
      '- - Thùng, két, bể chứa và các loại đồ chứa đựng tương tự không gắn các thiết bị cơ khí hoặc thiết bị gia nhiệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74192090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '741980',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '74198010',
    Description: '- - Xích và các bộ phận của xích',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Tấm đan (kể cả đai liền), phên và lưới, bằng dây đồng; sản phẩm dạng lưới bằng đồng được tạo hình bằng phương pháp đột dập và kéo giãn thành lưới:',
    Unit: '',
  },
  {
    HsCode: '74198021',
    Description: '- - - Đai liền cho máy móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198030',
    Description: '- - Lò xo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198040',
    Description: '- - Hộp đựng thuốc lá điếu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198050',
    Description: '- - Thiết bị nấu hoặc đun nóng, trừ loại dùng trong gia đình, và các bộ phận của các sản phẩm này',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198060',
    Description: '- - Các vật dụng được thiết kế riêng sử dụng trong nghi lễ tôn giáo (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198070',
    Description: '- - A-nốt mạ điện; dụng cụ đo dung tích (trừ loại gia dụng)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198080',
    Description:
      '- - Thùng, két, bể chứa và các loại đồ chứa đựng tương tự có dung tích từ 300 lít trở xuống không gắn thiết bị cơ khí hoặc thiết bị gia nhiệt ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '74198090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 75',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Niken và các sản phẩm bằng niken',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Niken không hợp kim',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' Là kim loại có tổng hàm lượng niken và coban tối thiểu 99% tính theo khối lượng, với điều kiện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) hàm lượng coban không quá 1,5% tính theo khối lượng, và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) hàm lượng tính theo khối lượng của bất kỳ nguyên tố nào khác không vượt quá giới hạn nêu trong bảng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 5 - BẢNG CÁC NGUYÊN TỐ KHÁC ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hợp kim niken',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Vật liệu kim loại mà trong đó hàm lượng tính theo khối lượng của niken trội hơn so với hàm lượng tính theo khối lượng của từng nguyên tố khác với điều kiện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) hàm lượng của coban trên 1,5% tính theo khối lượng,',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) hàm lượng tính theo khối lượng của ít nhất 1 trong các nguyên tố khác trong thành phần vượt quá giới hạn nêu ở bảng trên, hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) tổng hàm lượng của các nguyên tố khác trừ niken và coban lớn hơn 1% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Mặc dù đã quy định tại Chú giải 9 (c) của Phần XV, theo mục đích của phân nhóm 7508.10, khái niệm “dây"" chỉ áp dụng cho các sản phẩm, dù ở dạng cuộn hoặc không ở dạng cuộn, có hình dạng mặt cắt ngang bất kỳ, nhưng kích thước mặt cắt ngang không quá 6 mm.',
    Unit: '',
  },
  {
    HsCode: '7501',
    Description: 'Sten niken, oxit niken thiêu kết và các sản phẩm trung gian khác của quá trình luyện niken',
    Unit: '',
  },
  {
    HsCode: '75011000',
    Description: '- Sten niken',
    Unit: 'kg',
  },
  {
    HsCode: '75012000',
    Description: '- Oxit niken thiêu kết và các sản phẩm trung gian khác của quá trình luyện niken',
    Unit: 'kg',
  },
  {
    HsCode: '7502',
    Description: 'Niken chưa gia công',
    Unit: '',
  },
  {
    HsCode: '75021000',
    Description: '- Niken, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '75022000',
    Description: '- Hợp kim niken',
    Unit: 'kg',
  },
  {
    HsCode: '75030000',
    Description: 'Phế liệu và mảnh vụn niken',
    Unit: 'kg',
  },
  {
    HsCode: '75040000',
    Description: 'Bột và vảy niken',
    Unit: 'kg',
  },
  {
    HsCode: '7505',
    Description: 'Niken ở dạng thanh, que, hình và dây',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Thanh, que và hình:',
    Unit: '',
  },
  {
    HsCode: '75051100',
    Description: '- - Bằng niken, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '75051200',
    Description: '- - Bằng hợp kim niken',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Dây:',
    Unit: '',
  },
  {
    HsCode: '75052100',
    Description: '- - Bằng niken, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '75052200',
    Description: '- - Bằng hợp kim niken',
    Unit: 'kg',
  },
  {
    HsCode: '7506',
    Description: 'Niken ở dạng tấm, lá, dải và lá mỏng',
    Unit: '',
  },
  {
    HsCode: '75061000',
    Description: '- Bằng niken, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '75062000',
    Description: '- Bằng hợp kim niken',
    Unit: 'kg',
  },
  {
    HsCode: '7507',
    Description:
      'Các loại ống, ống dẫn và các phụ kiện để ghép nối của ống hoặc ống dẫn bằng niken (ví dụ, khớp nối đôi, khuỷu, măng sông)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Các loại ống và ống dẫn:',
    Unit: '',
  },
  {
    HsCode: '75071100',
    Description: '- - Bằng niken, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '75071200',
    Description: '- - Bằng hợp kim niken',
    Unit: 'kg',
  },
  {
    HsCode: '75072000',
    Description: '- Phụ kiện để ghép nối của ống hoặc ống dẫn',
    Unit: 'kg',
  },
  {
    HsCode: '7508',
    Description: 'Sản phẩm khác bằng niken',
    Unit: '',
  },
  {
    HsCode: '75081000',
    Description: '- Tấm đan, phên và lưới, bằng dây niken',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '750890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '75089030',
    Description: '- - Bu lông và đai ốc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '75089050',
    Description: '- - Các sản phẩm mạ điện a-nốt, bao gồm cả các sản phẩm sản xuất bằng phương pháp điện phân',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '75089090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 76',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nhôm và các sản phẩm bằng nhôm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Nhôm, không hợp kim',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là kim loại có hàm lượng nhôm chiếm ít nhất 99% tính theo khối lượng, với điều kiện hàm lượng của bất kỳ nguyên tố nào khác không vượt quá giới hạn nêu trong bảng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 6',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(1) Các nguyên tố khác, ví dụ Cr, Cu, Mg, Mn, Ni, Zn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) Tỷ lệ đồng cho phép lớn hơn 0,1%, nhưng không quá 0,2%, với điều kiện hàm lượng crôm cũng như hàm lượng mangan đều không quá 0,05%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hợp kim nhôm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Vật liệu kim loại mà trong đó hàm lượng nhôm lớn hơn so với hàm lượng của mỗi nguyên tố khác tính theo khối lượng, với điều kiện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) hàm lượng của ít nhất 1 trong các nguyên tố khác hoặc tổng hàm lượng của sắt với silic phải lớn hơn giới hạn nêu ở bảng trên; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) tổng hàm lượng của các nguyên tố khác trong thành phần vượt quá 1% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Mặc dù đã quy định tại Chú giải 9 (c) của Phần XV, theo mục đích của phân nhóm 7616.91, khái niệm ""dây"" chỉ áp dụng cho các sản phẩm ở dạng cuộn hoặc không ở dạng cuộn, mặt cắt ngang ở hình dạng bất kỳ, kích thước mặt cắt ngang không quá 6 mm.',
    Unit: '',
  },
  {
    HsCode: '7601',
    Description: 'Nhôm chưa gia công',
    Unit: '',
  },
  {
    HsCode: '76011000',
    Description: '- Nhôm, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '76012000',
    Description: '- Hợp kim nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '76020000',
    Description: 'Phế liệu và mảnh vụn nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '7603',
    Description: 'Bột và vảy nhôm',
    Unit: '',
  },
  {
    HsCode: '76031000',
    Description: '- Bột không có cấu trúc lớp',
    Unit: 'kg',
  },
  {
    HsCode: '760320',
    Description: '- Bột có cấu trúc lớp; vảy nhôm:',
    Unit: '',
  },
  {
    HsCode: '76032010',
    Description: '- - Vảy nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '76032020',
    Description: '- - Bột có cấu trúc lớp',
    Unit: 'kg',
  },
  {
    HsCode: '7604',
    Description: 'Nhôm ở dạng thanh, que và hình',
    Unit: '',
  },
  {
    HsCode: '760410',
    Description: '- Bằng nhôm, không hợp kim:',
    Unit: '',
  },
  {
    HsCode: '76041010',
    Description: '- - Dạng thanh và que',
    Unit: 'kg',
  },
  {
    HsCode: '76041090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bằng hợp kim nhôm:',
    Unit: '',
  },
  {
    HsCode: '760421',
    Description: '- - Dạng hình rỗng:',
    Unit: '',
  },
  {
    HsCode: '76042110',
    Description: '- - - Loại phù hợp để làm dàn lạnh của máy điều hòa không khí cho xe có động cơ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '76042120',
    Description: '- - - Thanh đệm bằng nhôm cho cửa ra vào và cửa sổ (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '76042190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '760429',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76042910',
    Description: '- - - Dạng thanh và que được ép đùn',
    Unit: 'kg',
  },
  {
    HsCode: '76042930',
    Description: '- - - Dạng hình chữ Y dùng cho dây khóa kéo, ở dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '76042990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7605',
    Description: 'Dây nhôm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bằng nhôm, không hợp kim:',
    Unit: '',
  },
  {
    HsCode: '76051100',
    Description: '- - Kích thước mặt cắt ngang lớn nhất trên 7 mm',
    Unit: 'kg',
  },
  {
    HsCode: '760519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76051910',
    Description: '- - - Đường kính không quá 0,0508 mm',
    Unit: 'kg',
  },
  {
    HsCode: '76051990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Bằng hợp kim nhôm:',
    Unit: '',
  },
  {
    HsCode: '76052100',
    Description: '- - Kích thước mặt cắt ngang lớn nhất trên 7 mm',
    Unit: 'kg',
  },
  {
    HsCode: '760529',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76052910',
    Description: '- - - Có đường kính không quá 0,254 mm',
    Unit: 'kg',
  },
  {
    HsCode: '76052990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7606',
    Description: 'Nhôm ở dạng tấm, lá và dải, chiều dày trên 0,2 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Hình chữ nhật (kể cả hình vuông):',
    Unit: '',
  },
  {
    HsCode: '760611',
    Description: '- - Bằng nhôm, không hợp kim:',
    Unit: '',
  },
  {
    HsCode: '76061110',
    Description: '- - - Được dát phẳng hoặc tạo hình bằng phương pháp cán hoặc ép, nhưng chưa xử lý bề mặt',
    Unit: 'kg',
  },
  {
    HsCode: '76061190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '760612',
    Description: '- - Bằng hợp kim nhôm:',
    Unit: '',
  },
  {
    HsCode: '76061220',
    Description: '- - - Đế bản nhôm, chưa nhạy, dùng trong công nghệ in ',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Dạng lá:',
    Unit: '',
  },
  {
    HsCode: '76061232',
    Description: '- - - - Để làm lon (kể cả đáy lon và nắp lon), dạng cuộn(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '76061233',
    Description: '- - - - Loại khác, bằng hợp kim nhôm 5082 hoặc 5182, có chiều rộng trên 1m, dạng cuộn (SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '76061234',
    Description: '- - - - Loại khác, tấm litô hợp kim HA 1052 độ cứng H19 và hợp kim HA 1050 độ cứng H18',
    Unit: 'kg',
  },
  {
    HsCode: '76061235',
    Description: '- - - - Loại khác, đã gia công bề mặt',
    Unit: 'kg',
  },
  {
    HsCode: '76061239',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '76061290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76069100',
    Description: '- - Bằng nhôm, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '76069200',
    Description: '- - Bằng hợp kim nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '7607',
    Description:
      'Nhôm lá mỏng (đã hoặc chưa in hoặc bồi trên giấy, bìa, plastic hoặc vật liệu bồi tương tự) có chiều dày (trừ phần bồi) không quá 0,2 mm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chưa được bồi:',
    Unit: '',
  },
  {
    HsCode: '76071100',
    Description: '- - Đã được cán nhưng chưa gia công thêm',
    Unit: 'kg',
  },
  {
    HsCode: '76071900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '760720',
    Description: '- Đã được bồi:',
    Unit: '',
  },
  {
    HsCode: '76072010',
    Description: '- - Nhôm lá mỏng cách nhiệt(SEN)',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76072091',
    Description: '- - - Được in hình mẫu, hoặc màu vàng hoặc màu bạc',
    Unit: 'kg',
  },
  {
    HsCode: '76072099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7608',
    Description: 'Các loại ống và ống dẫn bằng nhôm',
    Unit: '',
  },
  {
    HsCode: '76081000',
    Description: '- Bằng nhôm, không hợp kim',
    Unit: 'kg/m',
  },
  {
    HsCode: '76082000',
    Description: '- Bằng hợp kim nhôm',
    Unit: 'kg/m',
  },
  {
    HsCode: '76090000',
    Description: 'Các phụ kiện để ghép nối của ống hoặc ống dẫn (ví dụ, khớp nối đôi, nối khuỷu, măng sông) bằng nhôm',
    Unit: 'kg',
  },
  {
    HsCode: '7610',
    Description:
      'Các kết cấu bằng nhôm (trừ nhà lắp ghép thuộc nhóm 94.06) và các bộ phận của các kết cấu bằng nhôm (ví dụ, cầu và nhịp cầu, tháp, cột lưới, mái nhà, khung mái, cửa ra vào và cửa sổ và các loại khung cửa và ngưỡng cửa ra vào, cửa chớp, lan can, cột trụ và các loại cột); tấm, thanh, dạng hình, ống và các loại tương tự bằng nhôm, đã được gia công để sử dụng làm kết cấu',
    Unit: '',
  },
  {
    HsCode: '761010',
    Description: '- Cửa ra vào, cửa sổ và các loại khung cửa và ngưỡng cửa ra vào:',
    Unit: '',
  },
  {
    HsCode: '76101010',
    Description: '- - Cửa ra vào và các loại khung cửa ra vào và ngưỡng cửa ra vào',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '76101090',
    Description: '- - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '761090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76109030',
    Description: '- - Mái phao trong hoặc mái phao ngoài dùng cho bể chứa xăng dầu(SEN)',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76109091',
    Description: '- - - Cầu và nhịp cầu; tháp hoặc cột lưới',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '76109099',
    Description: '- - - Loại khác',
    Unit: 'm2/chiếc',
  },
  {
    HsCode: '76110000',
    Description:
      'Các loại bể chứa, két, bình chứa và các loại tương tự, dùng để chứa các loại vật liệu (trừ khí nén hoặc khí hóa lỏng), có dung tích trên 300 lít, bằng nhôm, đã hoặc chưa lót hoặc cách nhiệt, nhưng chưa lắp ghép với thiết bị cơ khí hoặc thiết bị nhiệt',
    Unit: 'chiếc',
  },
  {
    HsCode: '7612',
    Description:
      'Thùng phuy, thùng hình trống, lon, hộp và các loại đồ chứa tương tự (kể cả các loại thùng chứa hình ống cứng hoặc có thể xếp lại được), dùng để chứa mọi loại vật liệu (trừ khí nén hoặc khí hóa lỏng), dung tích không quá 300 lít, đã hoặc chưa lót hoặc cách nhiệt, nhưng chưa lắp ghép với thiết bị cơ khí hoặc thiết bị nhiệt',
    Unit: '',
  },
  {
    HsCode: '76121000',
    Description: '- Thùng chứa hình ống có thể xếp lại được',
    Unit: 'chiếc',
  },
  {
    HsCode: '761290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76129010',
    Description: '- - Đồ chứa được đúc liền phù hợp dùng để đựng sữa tươi (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '76129090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '76130000',
    Description: 'Các loại thùng chứa khí nén hoặc khí hóa lỏng bằng nhôm',
    Unit: 'chiếc',
  },
  {
    HsCode: '7614',
    Description: 'Dây bện tao, cáp, băng tết và các loại tương tự, bằng nhôm, chưa cách điện',
    Unit: '',
  },
  {
    HsCode: '761410',
    Description: '- Có lõi thép:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Cáp:',
    Unit: '',
  },
  {
    HsCode: '76141011',
    Description: '- - - Có đường kính không quá 25,3 mm',
    Unit: 'kg/m',
  },
  {
    HsCode: '76141012',
    Description: '- - - Có đường kính trên 25,3 mm nhưng không quá 28,28 mm',
    Unit: 'kg/m',
  },
  {
    HsCode: '76141019',
    Description: '- - - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '76141090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '761490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Cáp:',
    Unit: '',
  },
  {
    HsCode: '76149011',
    Description: '- - - Có đường kính không quá 25,3 mm',
    Unit: 'kg/m',
  },
  {
    HsCode: '76149012',
    Description: '- - - Có đường kính trên 25,3 mm nhưng không quá 28,28 mm',
    Unit: 'kg/m',
  },
  {
    HsCode: '76149019',
    Description: '- - - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '76149090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '7615',
    Description:
      'Bộ đồ ăn, đồ nhà bếp hoặc các loại đồ gia dụng khác và các bộ phận của chúng, bằng nhôm; miếng dùng để cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự bằng nhôm; đồ trang bị trong nhà vệ sinh và các bộ phận của chúng, bằng nhôm',
    Unit: '',
  },
  {
    HsCode: '761510',
    Description:
      '- Bộ đồ ăn, đồ nhà bếp hoặc các đồ gia dụng khác và các bộ phận của chúng; miếng cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '76151010',
    Description: '- - Miếng cọ nồi và cọ rửa hoặc đánh bóng, găng tay và các loại tương tự',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76151090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '761520',
    Description: '- Đồ trang bị trong nhà vệ sinh và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '76152020',
    Description: '- - Bô để giường bệnh, bô đi tiểu hoặc bô để phòng ngủ ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76152090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '7616',
    Description: 'Các sản phẩm khác bằng nhôm',
    Unit: '',
  },
  {
    HsCode: '761610',
    Description:
      '- Đinh, đinh bấm, ghim dập (trừ các loại thuộc nhóm 83.05), đinh vít, bu lông, đai ốc, đinh móc, đinh tán, chốt hãm, chốt định vị, vòng đệm và các sản phẩm tương tự:',
    Unit: '',
  },
  {
    HsCode: '76161010',
    Description: '- - Đinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76161020',
    Description: '- - Ghim dập và đinh móc; bulông và đai ốc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76161090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76169100',
    Description: '- - Tấm đan, phên, lưới và rào, bằng dây nhôm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '761699',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '76169920',
    Description: '- - - Nhôm bịt đầu ống loại phù hợp dùng để sản xuất bút chì (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76169930',
    Description: '- - - Đồng xèng, hình tròn, kích thước chiều dày trên 1/10 đường kính (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76169940',
    Description: '- - - Ống chỉ, lõi suốt, guồng quay tơ và sản phẩm tương tự dùng cho sợi dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Rèm:',
    Unit: '',
  },
  {
    HsCode: '76169951',
    Description: '- - - - Rèm chớp lật(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76169959',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76169960',
    Description: '- - - Máng và chén để hứng mủ cao su',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76169970',
    Description: '- - - Hộp đựng thuốc lá điếu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76169980',
    Description: '- - - Sản phẩm dạng lưới được tạo hình bằng phương pháp đột dập và kéo giãn thành lưới ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '76169990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 77',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(Dự phòng cho việc phân loại tiếp theo trong tương lai của Hệ thống hài hòa)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 78',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chì và các sản phẩm bằng chì',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này khái niệm “chì tinh luyện"" có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là kim loại có hàm lượng chì chiếm ít nhất 99,9% tính theo khối lượng, với điều kiện hàm lượng của bất kỳ nguyên tố nào khác trong thành phần không vượt quá giới hạn nêu trong bảng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 7 - BẢNG CÁC NGUYÊN TỐ KHÁC',
    Unit: '',
  },
  {
    HsCode: '7801',
    Description: 'Chì chưa gia công',
    Unit: '',
  },
  {
    HsCode: '78011000',
    Description: '- Chì tinh luyện',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '78019100',
    Description:
      '- - Có hàm lượng antimon tính theo khối lượng là lớn nhất so với hàm lượng của các nguyên tố khác ngoài chì',
    Unit: 'kg',
  },
  {
    HsCode: '78019900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '78020000',
    Description: 'Phế liệu và mảnh vụn chì',
    Unit: 'kg',
  },
  {
    HsCode: '7804',
    Description: 'Chì ở dạng tấm, lá, dải và lá mỏng; bột và vảy chì',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Chì ở dạng tấm, lá, dải và lá mỏng:',
    Unit: '',
  },
  {
    HsCode: '780411',
    Description: '- - Lá, dải và lá mỏng có chiều dày (trừ phần bồi) không quá 0,2 mm:',
    Unit: '',
  },
  {
    HsCode: '78041110',
    Description: '- - - Chiều dày không quá 0,15 mm',
    Unit: 'kg',
  },
  {
    HsCode: '78041190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '78041900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '78042000',
    Description: '- Bột và vảy chì',
    Unit: 'kg',
  },
  {
    HsCode: '7806',
    Description: 'Các sản phẩm khác bằng chì',
    Unit: '',
  },
  {
    HsCode: '78060020',
    Description: '- Thanh, que, dạng hình và dây',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '78060030',
    Description:
      '- Các loại ống, ống dẫn và phụ kiện để ghép nối của ống hoặc ống dẫn (ví dụ, khớp nối đôi, nối khuỷu, măng sông)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '78060040',
    Description: '- Len chì; vòng đệm; tấm điện a-nốt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '78060090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 79',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Kẽm và các sản phẩm bằng kẽm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Kẽm, không hợp kim',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Là kim loại có hàm lượng kẽm ít nhất 97,5% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hợp kim kẽm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Vật liệu kim loại mà trong đó hàm lượng của kẽm lớn hơn so với hàm lượng của mỗi nguyên tố khác, với điều kiện tổng hàm lượng tính theo khối lượng của các nguyên tố khác trên 2,5%.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Bụi kẽm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Bụi thu được từ quá trình ngưng tụ hơi kẽm, bao gồm những hạt hình cầu nhỏ hơn bột kẽm. Có tối thiểu 80% tính theo khối lượng các hạt lọt qua mắt sàng 63 micromét (microns). Hàm lượng của kẽm ít nhất là 85% tính theo khối lượng.',
    Unit: '',
  },
  {
    HsCode: '7901',
    Description: 'Kẽm chưa gia công',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Kẽm, không hợp kim:',
    Unit: '',
  },
  {
    HsCode: '79011100',
    Description: '- - Có hàm lượng kẽm từ 99,99% trở lên tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '79011200',
    Description: '- - Có hàm lượng kẽm dưới 99,99% tính theo khối lượng ',
    Unit: 'kg',
  },
  {
    HsCode: '79012000',
    Description: '- Hợp kim kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '79020000',
    Description: 'Phế liệu và mảnh vụn kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '7903',
    Description: 'Bột, bụi và vảy kẽm',
    Unit: '',
  },
  {
    HsCode: '79031000',
    Description: '- Bụi kẽm',
    Unit: 'kg',
  },
  {
    HsCode: '79039000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '79040000',
    Description: 'Kẽm ở dạng thanh, que, hình và dây',
    Unit: 'kg',
  },
  {
    HsCode: '7905',
    Description: 'Kẽm ở dạng tấm, lá, dải và lá mỏng',
    Unit: '',
  },
  {
    HsCode: '79050040',
    Description: '- Chiều dày không quá 0,15 mm',
    Unit: 'kg',
  },
  {
    HsCode: '79050090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '7907',
    Description: 'Các sản phẩm khác bằng kẽm',
    Unit: '',
  },
  {
    HsCode: '79070030',
    Description: '- Ống máng, mái nhà, khung cửa sổ của mái nhà và các cấu kiện xây dựng khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '79070040',
    Description:
      '- Các loại ống, ống dẫn và phụ kiện để ghép nối của ống hoặc ống dẫn (ví dụ, khớp nối đôi, nối khuỷu, măng sông)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '79070091',
    Description: '- - Hộp đựng thuốc lá điếu; gạt tàn thuốc lá',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '79070092',
    Description: '- - Các sản phẩm gia dụng khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '79070093',
    Description:
      '- - Tấm điện a-nốt; khuôn tô (stencil plates); đinh, đinh bấm, đai ốc, bu lông, đinh vít, đinh tán và những sản phẩm chốt tương tự; miếng kẽm hình lục giác dùng để sản xuất pin(SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '79070099',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 80',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Thiếc và các sản phẩm bằng thiếc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Trong Chương này các khái niệm sau có nghĩa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Thiếc, không hợp kim',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Là kim loại có hàm lượng thiếc chiếm ít nhất 99% tính theo khối lượng, với điều kiện hàm lượng của bismut hoặc đồng phải thấp hơn giới hạn ở bảng sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XEM BẢNG 8',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Hợp kim thiếc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Vật liệu kim loại mà trong đó hàm lượng thiếc lớn hơn so với hàm lượng của mỗi nguyên tố khác tính theo khối lượng, với điều kiện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) tổng hàm lượng các nguyên tố khác trên 1% tính theo khối lượng; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) hàm lượng của bismut hoặc đồng phải bằng hoặc lớn hơn giới hạn nêu ở bảng trên.',
    Unit: '',
  },
  {
    HsCode: '8001',
    Description: 'Thiếc chưa gia công',
    Unit: '',
  },
  {
    HsCode: '80011000',
    Description: '- Thiếc, không hợp kim',
    Unit: 'kg',
  },
  {
    HsCode: '80012000',
    Description: '- Hợp kim thiếc',
    Unit: 'kg',
  },
  {
    HsCode: '80020000',
    Description: 'Phế liệu và mảnh vụn thiếc',
    Unit: 'kg',
  },
  {
    HsCode: '8003',
    Description: 'Thiếc ở dạng thanh, que, dạng hình và dây',
    Unit: '',
  },
  {
    HsCode: '80030010',
    Description: '- Thanh và que hàn',
    Unit: 'kg',
  },
  {
    HsCode: '80030090',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '8007',
    Description: 'Các sản phẩm khác bằng thiếc',
    Unit: '',
  },
  {
    HsCode: '80070020',
    Description: '- Dạng tấm, lá và dải, có chiều dày trên 0,2 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '80070030',
    Description:
      '- Lá mỏng (đã hoặc chưa in hoặc bồi trên giấy, bìa, plastic hoặc vật liệu bồi tương tự), có chiều dày (trừ phần bồi) không quá 0,2 mm; dạng bột và vảy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '80070040',
    Description:
      '- Các loại ống, ống dẫn và phụ kiện để ghép nối của ống hoặc ống dẫn (ví dụ, khớp nối đôi, nối khuỷu, măng sông)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '80070091',
    Description: '- - Hộp đựng thuốc lá điếu; gạt tàn thuốc lá',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '80070092',
    Description: '- - Các sản phẩm gia dụng khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '80070093',
    Description: '- - Ống có thể gấp lại được (collapsible tubes)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '80070099',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 81',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Kim loại cơ bản khác; gốm kim loại; các sản phẩm của chúng',
    Unit: '',
  },
  {
    HsCode: '8101',
    Description: 'Vonfram và các sản phẩm làm từ vonfram, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '81011000',
    Description: '- Bột',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81019400',
    Description: '- - Vonfram chưa gia công, kể cả thanh và que thu được từ quá trình thiêu kết',
    Unit: 'kg',
  },
  {
    HsCode: '81019600',
    Description: '- - Dây',
    Unit: 'kg',
  },
  {
    HsCode: '81019700',
    Description: '- - Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '810199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81019910',
    Description: '- - - Thanh và que, trừ các loại thu được từ quá trình thiêu kết; dạng hình, lá, dải và lá mỏng',
    Unit: 'kg',
  },
  {
    HsCode: '81019990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8102',
    Description: 'Molypđen và các sản phẩm làm từ molypđen, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '81021000',
    Description: '- Bột',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81029400',
    Description: '- - Molypđen chưa gia công, kể cả thanh và que thu được từ quá trình thiêu kết',
    Unit: 'kg',
  },
  {
    HsCode: '81029500',
    Description: '- - Thanh và que, trừ các loại thu được từ quá trình thiêu kết, dạng hình, tấm, lá, dải và lá mỏng',
    Unit: 'kg',
  },
  {
    HsCode: '81029600',
    Description: '- - Dây',
    Unit: 'kg',
  },
  {
    HsCode: '81029700',
    Description: '- - Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81029900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8103',
    Description: 'Tantan và các sản phẩm làm từ tantan, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '81032000',
    Description: '- Tantan chưa gia công, kể cả thanh và que thu được từ quá trình thiêu kết; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81033000',
    Description: '- Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81039100',
    Description: '- - Chén nung (crucible)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '81039900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8104',
    Description: 'Magiê và các sản phẩm của magiê, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Magiê chưa gia công:',
    Unit: '',
  },
  {
    HsCode: '81041100',
    Description: '- - Có chứa hàm lượng magiê ít nhất 99,8% tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '81041900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '81042000',
    Description: '- Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81043000',
    Description: '- Mạt giũa, phoi tiện và hạt, đã được phân loại theo kích cỡ; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81049000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8105',
    Description:
      'Coban sten và các sản phẩm trung gian khác từ luyện coban; coban và các sản phẩm bằng coban, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '810520',
    Description: '- Coban sten và các sản phẩm trung gian khác từ luyện coban; coban chưa gia công; bột:',
    Unit: '',
  },
  {
    HsCode: '81052010',
    Description: '- - Coban chưa gia công',
    Unit: 'kg',
  },
  {
    HsCode: '81052090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '81053000',
    Description: '- Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81059000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8106',
    Description: 'Bismut và các sản phẩm làm từ bismut, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '810610',
    Description: '- Chứa hàm lượng bismut trên 99,99%, tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '81061010',
    Description: '- - Bismut chưa gia công; phế liệu và mảnh vụn; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81061090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '810690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81069010',
    Description: '- - Bismut chưa gia công; phế liệu và mảnh vụn; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81069090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8108',
    Description: 'Titan và các sản phẩm làm từ titan, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '81082000',
    Description: '- Titan chưa gia công; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81083000',
    Description: '- Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81089000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8109',
    Description: 'Zircon và các sản phẩm làm từ zircon, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Zircon chưa gia công; bột:',
    Unit: '',
  },
  {
    HsCode: '81092100',
    Description: '- - Chứa hàm lượng hafini trong zircon dưới 1/500 tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '81092900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Phế liệu và mảnh vụn:',
    Unit: '',
  },
  {
    HsCode: '81093100',
    Description: '- - Chứa hàm lượng hafini trong zircon dưới 1/500 tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '81093900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81099100',
    Description: '- - Chứa hàm lượng hafini trong zircon dưới 1/500 tính theo khối lượng',
    Unit: 'kg',
  },
  {
    HsCode: '81099900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8110',
    Description: 'Antimon và các sản phẩm làm từ antimon, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '81101000',
    Description: '- Antimon chưa gia công; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81102000',
    Description: '- Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81109000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8111',
    Description: 'Mangan và các sản phẩm làm từ mangan, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '81110010',
    Description: '- Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81110090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8112',
    Description:
      'Beryli, crôm, hafini, reni, tali, cađimi, germani, vanadi, gali, indi và niobi (columbi), và các sản phẩm từ các kim loại này, kể cả phế liệu và mảnh vụn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Beryli:',
    Unit: '',
  },
  {
    HsCode: '81121200',
    Description: '- - Chưa gia công; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81121300',
    Description: '- - Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81121900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Crôm:',
    Unit: '',
  },
  {
    HsCode: '81122100',
    Description: '- - Chưa gia công; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81122200',
    Description: '- - Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81122900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Hafini:',
    Unit: '',
  },
  {
    HsCode: '81123100',
    Description: '- - Chưa gia công; phế liệu và mảnh vụn; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81123900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Reni:',
    Unit: '',
  },
  {
    HsCode: '81124100',
    Description: '- - Chưa gia công; phế liệu và mảnh vụn; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81124900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Tali:',
    Unit: '',
  },
  {
    HsCode: '81125100',
    Description: '- - Chưa gia công; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81125200',
    Description: '- - Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '81125900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Cađimi:',
    Unit: '',
  },
  {
    HsCode: '81126100',
    Description: '- - Phế liệu và mảnh vụn',
    Unit: 'kg',
  },
  {
    HsCode: '811269',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81126910',
    Description: '- - - Chưa gia công; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81126990',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '81129200',
    Description: '- - Chưa gia công; phế liệu và mảnh vụn; bột',
    Unit: 'kg',
  },
  {
    HsCode: '81129900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '81130000',
    Description: 'Gốm kim loại và các sản phẩm làm từ gốm kim loại, kể cả phế liệu và mảnh vụn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 82',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Dụng cụ, đồ nghề, dao, kéo, bộ đồ ăn làm từ kim loại cơ bản; các bộ phận của chúng làm từ kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Ngoài các mặt hàng như đèn hàn, bộ bệ rèn xách tay, các loại đá mài có lắp gá đỡ, bộ sửa móng tay hoặc móng chân, và các mặt hàng thuộc nhóm 82.09, Chương này chỉ bao gồm các sản phẩm có lưỡi, cạnh sắc, bề mặt làm việc hoặc bộ phận làm việc khác bằng: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Kim loại cơ bản;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Carbua kim loại hoặc gốm kim loại;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo) gắn với nền kim loại cơ bản, carbua kim loại hoặc gốm kim loại; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Vật liệu mài gắn với nền kim loại cơ bản, với điều kiện các sản phẩm đó có răng cắt, đường máng, đường rãnh soi hoặc tương tự, bằng kim loại cơ bản, các sản phẩm đó vẫn giữ được đặc tính và chức năng sau khi được gắn với vật liệu mài.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Các bộ phận bằng kim loại cơ bản của các sản phẩm thuộc Chương này được phân loại theo các sản phẩm đó, trừ các bộ phận được chi tiết riêng và tay cầm cho các loại dụng cụ cầm tay (nhóm 84.66). Tuy nhiên, các bộ phận có công dụng chung nêu trong Chú giải 2 Phần XV được loại trừ khỏi Chương này trong mọi trường hợp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Đầu, lưỡi dao và lưỡi cắt của máy cạo râu dùng điện hoặc tông đơ cắt tóc dùng điện được xếp vào nhóm 85.10.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Bộ gồm một hoặc nhiều dao thuộc nhóm 82.11 và ít nhất là một số lượng tương đương các sản phẩm thuộc nhóm 82.15 được phân loại trong nhóm 82.15.',
    Unit: '',
  },
  {
    HsCode: '8201',
    Description:
      'Dụng cụ cầm tay, gồm: mai, xẻng, cuốc chim, cuốc, dụng cụ xới và làm tơi đất, chĩa và cào; rìu, câu liêm và các dụng cụ tương tự dùng để cắt chặt; kéo cắt cây và kéo cắt tỉa các loại; hái, liềm, dao cắt cỏ, kéo tỉa xén hàng rào, cái nêm gỗ và các dụng cụ khác dùng trong nông nghiệp, làm vườn hoặc trong lâm nghiệp',
    Unit: '',
  },
  {
    HsCode: '82011000',
    Description: '- Mai và xẻng',
    Unit: 'chiếc',
  },
  {
    HsCode: '820130',
    Description: '- Cuốc chim, cuốc, dụng cụ xới, làm tơi đất và cào đất:',
    Unit: '',
  },
  {
    HsCode: '82013010',
    Description: '- - Dụng cụ xới, làm tơi đất và cào đất',
    Unit: 'chiếc',
  },
  {
    HsCode: '82013090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '82014000',
    Description: '- Rìu, câu liêm và các dụng cụ tương tự dùng để cắt chặt',
    Unit: 'chiếc',
  },
  {
    HsCode: '82015000',
    Description:
      '- Kéo cắt cây và kéo cắt tỉa và kéo để tỉa loại lớn tương tự, loại sử dụng một tay (kể cả kéo cắt gia cầm)',
    Unit: 'chiếc',
  },
  {
    HsCode: '82016000',
    Description: '- Kéo tỉa xén hàng rào, kéo tỉa xén sử dụng hai tay và các loại kéo tương tự loại sử dụng hai tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '82019000',
    Description: '- Dụng cụ cầm tay khác thuộc loại sử dụng trong nông nghiệp, làm vườn hoặc lâm nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '8202',
    Description: 'Cưa tay; lưỡi cưa các loại (kể cả các loại lưỡi rạch, lưỡi khía răng cưa hoặc lưỡi cưa không răng)',
    Unit: '',
  },
  {
    HsCode: '82021000',
    Description: '- Cưa tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '82022000',
    Description: '- Lưỡi cưa vòng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Lưỡi cưa đĩa (kể cả các loại lưỡi cưa đã rạch hoặc khía):',
    Unit: '',
  },
  {
    HsCode: '82023100',
    Description: '- - Với bộ phận làm việc bằng thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '82023900',
    Description: '- - Loại khác, kể cả các bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '82024000',
    Description: '- Lưỡi cưa xích',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Lưỡi cưa khác:',
    Unit: '',
  },
  {
    HsCode: '82029100',
    Description: '- - Lưỡi cưa thẳng, để gia công kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '820299',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '82029910',
    Description: '- - - Lưỡi cưa thẳng',
    Unit: 'chiếc',
  },
  {
    HsCode: '82029990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8203',
    Description:
      'Giũa, nạo, kìm (kể cả kìm cắt), panh, nhíp, kéo cắt kim loại, dụng cụ cắt ống, xén bu lông, mũi đột lỗ và các dụng cụ cầm tay tương tự',
    Unit: '',
  },
  {
    HsCode: '82031000',
    Description: '- Giũa, nạo và các dụng cụ tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '82032000',
    Description: '- Kìm (kể cả kìm cắt), panh, nhíp và dụng cụ tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '82033000',
    Description: '- Kéo cắt kim loại và dụng cụ tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '82034000',
    Description: '- Dụng cụ cắt ống, xén bu lông, mũi đột lỗ và các dụng cụ tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '8204',
    Description:
      'Cờ lê và thanh vặn ốc (bu lông) và đai ốc loại vặn bằng tay (kể cả cờ lê định lực nhưng trừ thanh vặn tarô); đầu cờ lê có thể thay đổi được, có hoặc không có tay vặn',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Cờ lê và thanh vặn ốc (bu lông) và đai ốc, loại vặn bằng tay:',
    Unit: '',
  },
  {
    HsCode: '82041100',
    Description: '- - Không điều chỉnh được',
    Unit: 'chiếc',
  },
  {
    HsCode: '82041200',
    Description: '- - Điều chỉnh được',
    Unit: 'chiếc',
  },
  {
    HsCode: '82042000',
    Description: '- Đầu cờ lê có thể thay đổi được, có hoặc không có tay vặn',
    Unit: 'chiếc',
  },
  {
    HsCode: '8205',
    Description:
      'Dụng cụ cầm tay (kể cả đầu nạm kim cương để cắt kính), chưa được ghi hoặc chi tiết ở nơi khác; đèn xì; mỏ cặp, bàn cặp và các đồ nghề tương tự, trừ các loại phụ kiện và các bộ phận phụ trợ của máy công cụ hoặc máy cắt bằng tia nước; đe; bộ bệ rèn xách tay; bàn mài quay hoạt động bằng tay hoặc chân',
    Unit: '',
  },
  {
    HsCode: '82051000',
    Description: '- Dụng cụ để khoan, ren hoặc ta rô',
    Unit: 'chiếc',
  },
  {
    HsCode: '82052000',
    Description: '- Búa và búa tạ',
    Unit: 'chiếc',
  },
  {
    HsCode: '82053000',
    Description: '- Bào, đục, đục máng và dụng cụ cắt tương tự cho việc chế biến gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '82054000',
    Description: '- Tuốc nơ vít',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ cầm tay khác (kể cả đầu nạm kim cương để cắt kính):',
    Unit: '',
  },
  {
    HsCode: '82055100',
    Description: '- - Dụng cụ dùng trong gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '82055900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '82056000',
    Description: '- Đèn hàn',
    Unit: 'chiếc',
  },
  {
    HsCode: '82057000',
    Description: '- Mỏ cặp, bàn cặp và các đồ nghề tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '82059000',
    Description: '- Loại khác, bao gồm bộ dụng cụ của hai phân nhóm trở lên thuộc nhóm này',
    Unit: 'bộ',
  },
  {
    HsCode: '82060000',
    Description: 'Bộ dụng cụ từ hai nhóm trở lên thuộc các nhóm từ 82.02 đến 82.05, đã đóng bộ để bán lẻ',
    Unit: 'bộ',
  },
  {
    HsCode: '8207',
    Description:
      'Các dụng cụ có thể thay đổi được dùng cho các dụng cụ cầm tay, có hoặc không hoạt động bằng điện, hoặc dùng cho máy công cụ (ví dụ, để ép, dập, đục lỗ, ta rô, ren, khoan, chuốt, phay, cán, tiện hoặc bắt, đóng vít), kể cả khuôn kéo để kéo hoặc ép đùn kim loại, và các loại dụng cụ để khoan đá hoặc khoan đất',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ để khoan đá hoặc khoan đất:',
    Unit: '',
  },
  {
    HsCode: '82071300',
    Description: '- - Có bộ phận làm việc bằng gốm kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '82071900',
    Description: '- - Loại khác, kể cả các bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '82072000',
    Description: '- Khuôn dùng để kéo hoặc ép đùn kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '82073000',
    Description: '- Dụng cụ để ép, dập hoặc đục lỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '82074000',
    Description: '- Dụng cụ để tarô hoặc ren',
    Unit: 'chiếc',
  },
  {
    HsCode: '82075000',
    Description: '- Dụng cụ để khoan, trừ các loại để khoan đá',
    Unit: 'chiếc',
  },
  {
    HsCode: '82076000',
    Description: '- Dụng cụ để doa hoặc chuốt',
    Unit: 'chiếc',
  },
  {
    HsCode: '82077000',
    Description: '- Dụng cụ để cán',
    Unit: 'chiếc',
  },
  {
    HsCode: '82078000',
    Description: '- Dụng cụ để tiện',
    Unit: 'chiếc',
  },
  {
    HsCode: '82079000',
    Description: '- Các dụng cụ có thể thay đổi được khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8208',
    Description: 'Dao và lưỡi cắt, dùng cho máy hoặc dụng cụ cơ khí',
    Unit: '',
  },
  {
    HsCode: '82081000',
    Description: '- Để gia công kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '82082000',
    Description: '- Để gia công gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '82083000',
    Description: '- Dùng cho dụng cụ nhà bếp hoặc cho máy dùng trong công nghiệp thực phẩm',
    Unit: 'chiếc',
  },
  {
    HsCode: '82084000',
    Description: '- Dùng cho máy nông nghiệp, làm vườn hoặc lâm nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '82089000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '82090000',
    Description:
      'Chi tiết hình đĩa, thanh cỡ nhỏ, mũi chóp và các chi tiết tương tự cho dụng cụ, chưa được gắn vào dụng cụ, làm bằng gốm kim loại',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '82100000',
    Description:
      'Đồ dùng cơ khí hoạt động bằng tay, nặng 10 kg trở xuống, dùng để chế biến, pha chế hoặc phục vụ đồ ăn hoặc đồ uống',
    Unit: 'chiếc',
  },
  {
    HsCode: '8211',
    Description:
      'Dao có lưỡi cắt, có hoặc không có răng cưa (kể cả dao tỉa), trừ loại dao thuộc nhóm 82.08, và lưỡi của nó',
    Unit: '',
  },
  {
    HsCode: '82111000',
    Description: '- Bộ sản phẩm tổ hợp',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '82119100',
    Description: '- - Dao ăn có lưỡi cố định',
    Unit: 'chiếc',
  },
  {
    HsCode: '821192',
    Description: '- - Dao khác có lưỡi cố định:',
    Unit: '',
  },
  {
    HsCode: '82119250',
    Description: '- - - Loại phù hợp dùng trong nông nghiệp, làm vườn hoặc lâm nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '82119292',
    Description: '- - - - Dao thợ săn, dao thợ lặn và dao đa năng ',
    Unit: 'chiếc',
  },
  {
    HsCode: '82119299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '821193',
    Description: '- - Dao khác, trừ loại có lưỡi cố định:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại sử dụng trong nông nghiệp, làm vườn hoặc lâm nghiệp:',
    Unit: '',
  },
  {
    HsCode: '82119321',
    Description: '- - - - Có cán bằng kim loại cơ bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '82119329',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '82119330',
    Description: '- - - Dao bấm tự động hoặc dao gấp; dao nhíp có lưỡi dao dài 15 cm trở lên ',
    Unit: 'chiếc',
  },
  {
    HsCode: '82119390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '821194',
    Description: '- - Lưỡi dao:',
    Unit: '',
  },
  {
    HsCode: '82119410',
    Description: '- - - Loại phù hợp dùng trong nông nghiệp, làm vườn hoặc lâm nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '82119490',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '82119500',
    Description: '- - Cán dao bằng kim loại cơ bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '8212',
    Description: 'Dao cạo và lưỡi dao cạo (kể cả lưỡi dao cạo chưa hoàn thiện ở dạng dải)',
    Unit: '',
  },
  {
    HsCode: '82121000',
    Description: '- Dao cạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '821220',
    Description: '- Lưỡi dao cạo an toàn, kể cả lưỡi dao cạo chưa hoàn thiện ở dạng dải:',
    Unit: '',
  },
  {
    HsCode: '82122010',
    Description: '- - Lưỡi dao cạo kép',
    Unit: 'chiếc',
  },
  {
    HsCode: '82122090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '82129000',
    Description: '- Các bộ phận khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '82130000',
    Description: 'Kéo, kéo thợ may và các loại kéo tương tự, và lưỡi của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '8214',
    Description:
      'Đồ dao kéo khác (ví dụ, tông đơ cắt tóc, dao pha dùng cho cửa hàng thịt hoặc làm bếp, dao bầu và dao băm, dao rọc giấy); bộ đồ và dụng cụ cắt sửa móng tay hoặc móng chân (kể cả dũa móng)',
    Unit: '',
  },
  {
    HsCode: '82141000',
    Description: '- Dao rọc giấy, dao mở thư, dao cào giấy, vót bút chì và lưỡi của các loại dao đó',
    Unit: 'chiếc',
  },
  {
    HsCode: '82142000',
    Description: '- Bộ đồ và dụng cụ cắt sửa móng tay hoặc móng chân (kể cả dũa móng)',
    Unit: 'bộ',
  },
  {
    HsCode: '82149000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8215',
    Description:
      'Thìa, dĩa, muôi, thìa hớt kem, hớt bọt, đồ xúc bánh, dao ăn cá, dao cắt bơ, kẹp gắp đường và các loại đồ dùng nhà bếp hoặc bộ đồ ăn tương tự',
    Unit: '',
  },
  {
    HsCode: '82151000',
    Description: '- Bộ sản phẩm có ít nhất một thứ đã được mạ kim loại quý',
    Unit: 'bộ',
  },
  {
    HsCode: '82152000',
    Description: '- Bộ sản phẩm tổ hợp khác',
    Unit: 'bộ',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '82159100',
    Description: '- - Được mạ kim loại quý',
    Unit: 'chiếc',
  },
  {
    HsCode: '82159900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 83',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Hàng tạp hóa làm từ kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của Chương này, các bộ phận của sản phẩm bằng kim loại cơ bản được phân loại theo sản phẩm gốc. Tuy nhiên, các sản phẩm bằng sắt hoặc thép thuộc nhóm 73.12, 73.15, 73.17, 73.18 hoặc 73.20, hoặc các sản phẩm tương tự bằng kim loại cơ bản khác (Chương 74 đến 76 và 78 đến 81) sẽ không được xem như các bộ phận của các sản phẩm thuộc Chương này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của nhóm 83.02, thuật ngữ “bánh xe đẩy (castor)"" có nghĩa là loại bánh xe có đường kính không quá 75 mm (kể cả lốp, tại các phân nhóm tương ứng) hoặc bánh xe có đường kính trên 75 mm (kể cả lốp, tại các phân nhóm tương ứng) với điều kiện là bánh xe hoặc lốp lắp vào đó có chiều rộng dưới 30 mm.',
    Unit: '',
  },
  {
    HsCode: '8301',
    Description:
      'Khóa móc và ổ khóa (loại mở bằng chìa, số hoặc điện), bằng kim loại cơ bản; móc cài và khung có móc cài, đi cùng ổ khóa, bằng kim loại cơ bản; chìa của các loại khóa trên, bằng kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '83011000',
    Description: '- Khóa móc',
    Unit: 'chiếc',
  },
  {
    HsCode: '83012000',
    Description: '- Ổ khóa thuộc loại sử dụng cho xe có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '83013000',
    Description: '- Ổ khóa thuộc loại sử dụng cho đồ nội thất',
    Unit: 'chiếc',
  },
  {
    HsCode: '830140',
    Description: '- Khóa loại khác:',
    Unit: '',
  },
  {
    HsCode: '83014010',
    Description: '- - Còng, xích tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '83014020',
    Description: '- - Khóa cửa',
    Unit: 'chiếc',
  },
  {
    HsCode: '83014090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '83015000',
    Description: '- Móc cài và khung có móc cài, đi cùng với ổ khóa',
    Unit: 'chiếc',
  },
  {
    HsCode: '83016000',
    Description: '- Các bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '83017000',
    Description: '- Chìa rời',
    Unit: 'chiếc',
  },
  {
    HsCode: '8302',
    Description:
      'Giá, khung, phụ kiện và các sản phẩm tương tự bằng kim loại cơ bản dùng cho đồ nội thất, cho cửa ra vào, cầu thang, cửa sổ, mành che, thân xe (coachwork), yên cương, rương, hòm hoặc các loại tương tự; giá để mũ, mắc mũ, chân giá đỡ và các loại giá cố định tương tự bằng kim loại cơ bản; bánh xe đẩy (castor) có giá đỡ bằng kim loại cơ bản; cơ cấu đóng cửa tự động bằng kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '83021000',
    Description: '- Bản lề (Hinges)',
    Unit: 'chiếc',
  },
  {
    HsCode: '830220',
    Description: '- Bánh xe đẩy (castor):',
    Unit: '',
  },
  {
    HsCode: '83022010',
    Description: '- - Loại có đường kính (bao gồm cả lốp) trên 100 mm nhưng không quá 250 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '83022090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '830230',
    Description: '- Giá, khung, phụ kiện và các sản phẩm tương tự khác dùng cho xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '83023010',
    Description: '- - Bản lề để móc khóa (Hasps)(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '83023090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Giá, khung, phụ kiện và các sản phẩm tương tự khác:',
    Unit: '',
  },
  {
    HsCode: '830241',
    Description: '- - Phù hợp cho xây dựng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Bản lề để móc khóa và đinh kẹp dùng cho cửa ra vào; móc và mắt cài khóa; chốt cửa:',
    Unit: '',
  },
  {
    HsCode: '83024131',
    Description: '- - - - Bản lề để móc khóa (Hasps)(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '83024139',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '83024190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '830242',
    Description: '- - Loại khác, phù hợp cho đồ nội thất:',
    Unit: '',
  },
  {
    HsCode: '83024220',
    Description: '- - - Bản lề để móc khóa (Hasps)(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '83024290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '830249',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83024910',
    Description: '- - - Loại phù hợp cho yên cương',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83024991',
    Description: '- - - - Bản lề để móc khóa (Hasps)(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '83024999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '83025000',
    Description: '- Giá để mũ, mắc mũ, chân giá đỡ và các loại giá cố định tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '83026000',
    Description: '- Cơ cấu đóng cửa tự động',
    Unit: 'chiếc',
  },
  {
    HsCode: '83030000',
    Description:
      'Két an toàn đã được bọc thép hoặc gia cố, két bạc và cửa bọc thép và két để đồ an toàn có khóa dùng cho phòng bọc thép, hòm để tiền hoặc tủ đựng chứng từ tài liệu và các loại tương tự, bằng kim loại cơ bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '8304',
    Description:
      'Tủ đựng hồ sơ, tủ đựng bộ phiếu thư mục, khay để giấy tờ, giá kẹp giấy, khay để bút, giá để con dấu văn phòng và các loại đồ dùng văn phòng hoặc các đồ dùng để bàn tương tự, bằng kim loại cơ bản, trừ đồ nội thất văn phòng thuộc nhóm 94.03',
    Unit: '',
  },
  {
    HsCode: '83040010',
    Description: '- Tủ đựng hồ sơ và tủ đựng bộ phiếu thư mục ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83040091',
    Description: '- - Bằng nhôm',
    Unit: 'chiếc',
  },
  {
    HsCode: '83040092',
    Description: '- - Bằng niken',
    Unit: 'chiếc',
  },
  {
    HsCode: '83040099',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8305',
    Description:
      'Các chi tiết ghép nối dùng cho cặp giữ tờ rời hoặc hồ sơ tài liệu rời, cái kẹp thư, để thư, kẹp giấy, kẹp phiếu mục lục và các vật phẩm văn phòng tương tự, bằng kim loại cơ bản; ghim dập dạng băng (ví dụ, dùng cho văn phòng, dùng cho công nghệ làm đệm, đóng gói), bằng kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '830510',
    Description: '- Các chi tiết ghép nối dùng cho cặp giữ tờ rời hoặc hồ sơ tài liệu rời:',
    Unit: '',
  },
  {
    HsCode: '83051010',
    Description: '- - Dùng cho bìa gáy xoắn',
    Unit: 'chiếc',
  },
  {
    HsCode: '83051090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '830520',
    Description: '- Ghim dập dạng băng:',
    Unit: '',
  },
  {
    HsCode: '83052010',
    Description: '- - Loại sử dụng cho văn phòng',
    Unit: 'chiếc',
  },
  {
    HsCode: '83052020',
    Description: '- - Loại khác, bằng sắt hoặc thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '83052090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '830590',
    Description: '- Loại khác, kể cả các bộ phận:',
    Unit: '',
  },
  {
    HsCode: '83059010',
    Description: '- - Kẹp giấy',
    Unit: 'chiếc',
  },
  {
    HsCode: '83059090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8306',
    Description:
      'Chuông, chuông đĩa và các loại tương tự, không dùng điện, bằng kim loại cơ bản; tượng nhỏ và đồ trang trí khác, bằng kim loại cơ bản; khung ảnh, khung tranh hoặc các loại khung tương tự, bằng kim loại cơ bản; gương bằng kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '83061000',
    Description: '- Chuông, chuông đĩa và các loại tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tượng nhỏ và đồ trang trí khác:',
    Unit: '',
  },
  {
    HsCode: '83062100',
    Description: '- - Được mạ bằng kim loại quý',
    Unit: 'chiếc',
  },
  {
    HsCode: '830629',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83062910',
    Description: '- - - Bằng đồng hoặc chì',
    Unit: 'chiếc',
  },
  {
    HsCode: '83062920',
    Description: '- - - Bằng niken',
    Unit: 'chiếc',
  },
  {
    HsCode: '83062930',
    Description: '- - - Bằng nhôm',
    Unit: 'chiếc',
  },
  {
    HsCode: '83062990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '830630',
    Description: '- Khung ảnh, khung tranh hoặc các loại khung tương tự; gương:',
    Unit: '',
  },
  {
    HsCode: '83063010',
    Description: '- - Bằng đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83063091',
    Description: '- - - Gương kim loại phản chiếu hình ảnh giao thông đặt tại các giao lộ hoặc các góc đường',
    Unit: 'chiếc',
  },
  {
    HsCode: '83063099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8307',
    Description: 'Ống dễ uốn bằng kim loại cơ bản, có hoặc không có phụ kiện để ghép nối',
    Unit: '',
  },
  {
    HsCode: '83071000',
    Description: '- Bằng sắt hoặc thép',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83079000',
    Description: '- Bằng kim loại cơ bản khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8308',
    Description:
      'Móc cài, khóa móc cài, khóa thắt lưng, cài thắt lưng, khóa có chốt, mắt cài khóa, khoen và các loại tương tự, bằng kim loại cơ bản, dùng cho quần áo hoặc phụ kiện quần áo, giày dép, trang sức, đồng hồ đeo tay, sách, bạt che, đồ da, hàng du lịch hoặc yên cương hoặc cho các sản phẩm hoàn thiện khác; đinh tán hình ống hoặc đinh tán có chân xòe, bằng kim loại cơ bản; hạt trang trí và trang kim, bằng kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '83081000',
    Description: '- Khóa có chốt, mắt cài khóa và khoen',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83082000',
    Description: '- Đinh tán hình ống hoặc đinh tán có chân xòe',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '830890',
    Description: '- Loại khác, kể cả các bộ phận:',
    Unit: '',
  },
  {
    HsCode: '83089010',
    Description: '- - Hạt trang trí',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '83089020',
    Description: '- - Trang kim',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83089090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8309',
    Description:
      'Nút chai lọ, nút bịt và nắp đậy (kể cả nắp hình vương miện, nút xoáy và nút một chiều), bao thiếc bịt nút chai, nút thùng có ren, tấm đậy lỗ thoát của thùng, dụng cụ niêm phong và bộ phận đóng gói khác, bằng kim loại cơ bản',
    Unit: '',
  },
  {
    HsCode: '83091000',
    Description: '- Nắp hình vương miện',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '830990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83099010',
    Description: '- - Bao thiếc bịt nút chai',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83099020',
    Description: '- - Nắp của hộp (lon) nhôm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83099060',
    Description: '- - Nắp bình phun xịt, bằng thiếc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83099070',
    Description: '- - Nắp hộp khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, bằng nhôm:',
    Unit: '',
  },
  {
    HsCode: '83099081',
    Description: '- - - Nút chai; nút xoáy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83099089',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83099091',
    Description: '- - - Nút chai; nút xoáy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83099092',
    Description:
      '- - - Nắp (bung) cho thùng kim loại; chụp nắp thùng (bung covers); dụng cụ niêm phong; bịt (bảo vệ) các góc hòm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83099099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '83100000',
    Description:
      'Biển chỉ dẫn, ghi tên, ghi địa chỉ và các loại biển báo tương tự, chữ số, chữ và các loại biểu tượng khác, bằng kim loại cơ bản, trừ các loại thuộc nhóm 94.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '8311',
    Description:
      'Dây, que, ống, tấm, điện cực và các sản phẩm tương tự, bằng kim loại cơ bản hoặc carbua kim loại, được bọc, phủ hoặc có lõi bằng chất trợ dung, loại dùng để hàn xì, hàn hơi, hàn điện hoặc bằng cách ngưng tụ kim loại hoặc carbua kim loại; dây và que, từ bột kim loại cơ bản được kết tụ, sử dụng trong phun kim loại',
    Unit: '',
  },
  {
    HsCode: '831110',
    Description: '- Điện cực bằng kim loại cơ bản, đã được phủ chất trợ dung, để hàn hồ quang điện:',
    Unit: '',
  },
  {
    HsCode: '83111010',
    Description: '- - Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '83111090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '831120',
    Description: '- Dây hàn bằng kim loại cơ bản, có lõi là chất trợ dung, dùng để hàn hồ quang điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Dây hàn bằng thép hợp kim, có lõi là chất trợ dung chứa hàm lượng carbon từ 4,5% trở lên và hàm lượng crôm từ 20% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '83112021',
    Description: '- - - Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '83112029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '83112090',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '831130',
    Description:
      '- Que hàn được phủ, bọc và dây hàn có lõi, bằng kim loại cơ bản, dùng để hàn chảy, hàn hơi hoặc hàn bằng ngọn lửa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Dây hàn bằng thép hợp kim, có lõi là chất trợ dung chứa hàm lượng carbon từ 4,5% trở lên và hàm lượng crôm từ 20% trở lên tính theo khối lượng:',
    Unit: '',
  },
  {
    HsCode: '83113021',
    Description: '- - - Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '83113029',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '83113091',
    Description: '- - - Dạng cuộn',
    Unit: 'kg',
  },
  {
    HsCode: '83113099',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '83119000',
    Description: '- Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XVI',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'MÁY VÀ CÁC TRANG THIẾT BỊ CƠ KHÍ; THIẾT BỊ ĐIỆN; CÁC BỘ PHẬN CỦA CHÚNG; THIẾT BỊ GHI VÀ TÁI TẠO ÂM THANH, THIẾT BỊ GHI VÀ TÁI TẠO HÌNH ẢNH, ÂM THANH TRUYỀN HÌNH VÀ CÁC BỘ PHẬN VÀ PHỤ KIỆN CỦA CÁC THIẾT BỊ TRÊN',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Phần này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Băng truyền hoặc băng tải hoặc dây đai, bằng plastic thuộc Chương 39, hoặc bằng cao su lưu hóa (nhóm 40.10), hoặc các sản phẩm khác sử dụng cho máy móc hoặc thiết bị cơ khí hoặc thiết bị điện hoặc sản phẩm sử dụng cho mục đích kỹ thuật khác, bằng cao su lưu hóa trừ loại cao su cứng (nhóm 40.16);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các sản phẩm bằng da thuộc hoặc bằng da thuộc tổng hợp (nhóm 42.05) hoặc bằng da lông (nhóm 43.03), sử dụng trong các máy móc hoặc thiết bị cơ khí hoặc các mục đích kỹ thuật khác;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Ống chỉ, trục cuốn, ống lõi sợi con, lõi ống sợi, lõi ống côn, lõi guồng hoặc các loại lõi tương tự, bằng vật liệu bất kỳ (ví dụ, Chương 39, 40, 44 hoặc 48 hoặc Phần XV);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Thẻ đục lỗ dùng cho máy Jacquard hoặc các máy tương tự (ví dụ, Chương 39 hoặc 48 hoặc Phần XV); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Băng truyền hoặc băng tải hoặc dây curoa bằng vật liệu dệt (nhóm 59.10) hoặc các sản phẩm khác bằng vật liệu dệt dùng cho các mục đích kỹ thuật (nhóm 59.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo) thuộc các nhóm 71.02 đến 71.04, hoặc các sản phẩm làm toàn bộ bằng loại đá này thuộc nhóm 71.16, trừ đá saphia và kim cương đã được gia công nhưng chưa gắn để làm đầu kim đĩa hát (nhóm 85.22);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Các bộ phận có công dụng chung, như đã định nghĩa trong Chú giải 2 Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các hàng hóa tương tự bằng plastic (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Ống khoan (nhóm 73.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Đai liên tục bằng dây hoặc dải kim loại (Phần XV);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các sản phẩm thuộc Chương 82 hoặc 83;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các sản phẩm thuộc Phần XVII;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Các sản phẩm thuộc Chương 90;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Đồng hồ thời gian, đồng hồ cá nhân hoặc các sản phẩm khác thuộc Chương 91;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(o) Các dụng cụ có thể thay đổi thuộc nhóm 82.07 hoặc bàn chải sử dụng như các bộ phận của máy (nhóm 96.03); những dụng cụ có thể thay đổi tương tự được phân loại theo vật liệu cấu thành bộ phận làm việc của chúng (ví dụ, trong Chương 40, 42, 43, 45 hoặc Chương 59 hoặc nhóm 68.04 hoặc 69.09);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(p) Các sản phẩm thuộc Chương 95; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(q) Ruy băng máy chữ hoặc ruy băng tương tự, đã hoặc chưa cuộn vào lõi hoặc nằm trong vỏ (được phân loại theo vật liệu cấu thành, hoặc trong nhóm 96.12 nếu chúng đã nạp mực hoặc được chuẩn bị khác để dùng cho in ấn), hoặc chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các mặt hàng tương tự, thuộc nhóm 96.20.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo Chú giải 1 của Phần này, Chú giải 1 Chương 84 và Chú giải 1 Chương 85, các bộ phận của máy (ngoài các bộ phận của hàng hóa thuộc nhóm 84.84, 85.44, 85.45, 85.46 hoặc 85.47) được phân loại theo các quy tắc sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các bộ phận đã được chi tiết tại nhóm nào đó của Chương 84 hoặc 85 (trừ các nhóm 84.09, 84.31, 84.48, 84.66, 84.73, 84.87, 85.03, 85.22, 85.29, 85.38 và 85.48) được phân loại vào nhóm đó trong mọi trường hợp;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các bộ phận khác, nếu phù hợp để chỉ sử dụng hoặc chủ yếu sử dụng cho các loại máy cụ thể, hoặc cho một số loại máy cùng nhóm (kể cả máy thuộc nhóm 84.79 hoặc 85.43) được phân loại theo nhóm của các máy cụ thể đó hoặc vào nhóm thích hợp như 84.09, 84.31, 84.48, 84.66, 84.73, 85.03, 85.22, 85.29 hoặc 85.38. Tuy nhiên, bộ phận phù hợp để chủ yếu sử dụng cho các hàng hóa thuộc các nhóm 85.17 và 85.25 đến 85.28 được phân loại vào nhóm 85.17; và bộ phận phù hợp để chỉ sử dụng hoặc chủ yếu sử dụng cho hàng hóa của nhóm 85.24 được phân loại vào nhóm 85.29; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Tất cả các bộ phận khác được phân loại trong nhóm thích hợp như 84.09, 84.31, 84.48, 84.66, 84.73, 85.03, 85.22, 85.29 hoặc 85.38 hoặc, nếu không thì, phân loại vào nhóm 84.87 hoặc 85.48.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Trừ khi có yêu cầu cụ thể khác, các loại máy liên hợp gồm hai hoặc nhiều máy lắp ghép với nhau thành một thiết bị đồng bộ và các loại máy khác được thiết kế để thực hiện hai hoặc nhiều chức năng khác nhau hoặc bổ trợ lẫn nhau được phân loại theo bộ phận chính hoặc máy thực hiện chức năng chính.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Khi một máy (kể cả một tổ hợp máy) gồm các bộ phận cấu thành riêng lẻ (dù tách biệt hoặc được liên kết với nhau bằng ống, bằng bộ phận truyền, bằng cáp điện hoặc bằng bộ phận khác) để thực hiện một chức năng được xác định rõ đã quy định chi tiết tại một trong các nhóm của Chương 84 hoặc Chương 85, thì toàn bộ máy đó phải được phân loại vào nhóm phù hợp với chức năng xác định của máy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của Chú giải này, khái niệm “máy” có nghĩa là bất kỳ máy, máy móc, thiết bị, bộ dụng cụ hoặc dụng cụ đã nêu trong các nhóm của Chương 84 hoặc 85.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. (A) Trong toàn bộ Danh mục này, khái niệm “phế liệu và phế thải điện và điện tử” có nghĩa là các cụm linh kiện điện và điện tử, tấm mạch in, và các sản phẩm điện hoặc điện tử:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) đã bị làm cho không sử dụng được cho các mục đích ban đầu của chúng do bị vỡ, cắt hoặc các quá trình khác hoặc không phù hợp về mặt kinh tế để sửa chữa, tân trang hoặc cải tạo để làm cho chúng phù hợp với mục đích ban đầu của chúng; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) được đóng gói hoặc vận chuyển theo cách không nhằm mục đích bảo vệ các mặt hàng riêng lẻ khỏi bị hư hại trong quá trình vận chuyển, xếp và dỡ hàng hóa.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Các lô hàng hỗn hợp gồm “phế liệu và phế thải điện và điện tử” và phế liệu và phế thải khác được phân loại vào nhóm 85.49.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(C) Phần này không bao gồm rác thải đô thị, như được định nghĩa trong Chú giải 4 của Chương 38.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 84',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Lò phản ứng hạt nhân, nồi hơi, máy và thiết bị cơ khí; các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Đá nghiền, đá mài hoặc các sản phẩm khác của Chương 68;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Máy móc hoặc thiết bị (ví dụ, bơm) bằng vật liệu gốm sứ và các bộ phận bằng gốm sứ của máy hoặc thiết bị bằng vật liệu bất kỳ (Chương 69);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Đồ thủy tinh dùng cho phòng thí nghiệm (nhóm 70.17); máy, thiết bị hoặc các sản phẩm khác dùng cho các mục đích kỹ thuật hoặc các bộ phận của chúng, bằng thủy tinh (nhóm 70.19 hoặc 70.20);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Các sản phẩm thuộc nhóm 73.21 hoặc 73.22 hoặc các sản phẩm tương tự bằng kim loại cơ bản khác (từ Chương 74 đến Chương 76 hoặc từ Chương 78 đến Chương 81);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Máy hút bụi thuộc nhóm 85.08;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Thiết bị cơ điện gia dụng thuộc nhóm 85.09; camera kỹ thuật số thuộc nhóm 85.25; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Bộ tản nhiệt cho các hàng hóa của Phần XVII; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Máy quét sàn cơ khí thao tác bằng tay, không có động cơ (nhóm 96.03).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo nội dung của Chú giải 3 Phần XVI và Chú giải 11 của Chương này, máy móc hoặc thiết bị thỏa mãn mô tả của một hoặc nhiều nhóm từ 84.01 đến 84.24, hoặc nhóm 84.86 và đồng thời thỏa mãn mô tả của một hoặc nhiều nhóm từ 84.25 đến 84.80 thì được phân loại vào một nhóm thích hợp trong số các nhóm từ 84.01 đến 84.24 hoặc 84.86, mà không được phân loại vào một trong các nhóm 84.25 đến 84.80.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(A) Tuy nhiên, nhóm 84.19 không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Máy ươm hạt giống, máy ấp trứng gia cầm và thiết bị sưởi ấm gia cầm mới nở (nhóm 84.36);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Máy làm ẩm hạt (nhóm 84.37);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) Máy khuếch tán dùng để chiết xuất đường quả (nhóm 84.38);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iv) Máy để xử lý nhiệt đối với sợi dệt, vải hoặc các sản phẩm dệt (nhóm 84.51); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(v) Thiết bị cho phòng thí nghiệm hoặc máy móc, thiết bị, được thiết kế để hoạt động bằng cơ khí, trong đó sự thay đổi nhiệt cho dù cần thiết chỉ là phần bổ sung.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Nhóm 84.22 không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Máy khâu dùng để đóng túi hoặc các loại bao bì tương tự (nhóm 84.52); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Máy văn phòng thuộc nhóm 84.72.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(C) Nhóm 84.24 không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Máy in phun mực (nhóm 84.43); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Máy cắt bằng tia nước (nhóm 84.56).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Máy công cụ để gia công loại vật liệu nào đó mà nó đáp ứng mô tả trong nhóm 84.56 và đồng thời đáp ứng mô tả trong nhóm 84.57, 84.58, 84.59, 84.60, 84.61, 84.64 hoặc 84.65 được phân loại trong nhóm 84.56.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 84.57 chỉ áp dụng cho máy công cụ để gia công kim loại, trừ máy tiện (kể cả trung tâm gia công tiện), loại này có thể thực hiện được các kiểu thao tác gia công khác nhau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) bằng cách thay đổi công cụ công tác tự động từ hộp đựng hoặc tương tự theo đúng chương trình gia công (trung tâm gia công),',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) bằng việc sử dụng tự động, đồng thời hoặc tuần tự các đầu gia công tổ hợp khác nhau để tác động lên một vật gia công ở vị trí cố định (máy kết cấu nguyên khối (một vị trí gia công)), hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) bằng cách di chuyển tự động vật gia công đến các đầu gia công tổ hợp khác nhau (máy gia công chuyển dịch đa vị trí).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của nhóm 84.62, một ""dây chuyền xẻ cuộn"" dùng cho các sản phẩm dạng phẳng là một dây chuyền xử lý gồm một máy xả cuộn, một máy làm phẳng cuộn, một máy xẻ và máy quấn cuộn. Một “dây chuyền cắt xén thành đoạn” dùng cho các sản phẩm dạng phẳng là một dây chuyền xử lý bao gồm một máy xả cuộn, một máy làm phẳng cuộn và một máy cắt xén.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '6. (A) Theo mục đích của nhóm 84.71, khái niệm ""máy xử lý dữ liệu tự động"" là máy có khả năng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) Lưu trữ chương trình xử lý hoặc các chương trình và ít nhất lưu trữ các dữ liệu cần ngay cho việc thực hiện chương trình; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) Lập chương trình tùy theo yêu cầu của người sử dụng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) Thực hiện các phép tính số học tùy theo yêu cầu của người sử dụng; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iv) Thực hiện một chương trình xử lý đòi hỏi máy phải biến đổi quy trình thực hiện, bằng các quyết định lôgíc trong quá trình chạy chương trình, mà không có sự can thiệp của con người.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Máy xử lý dữ liệu tự động có thể ở dạng hệ thống bao gồm một số lượng biến thiên các khối chức năng riêng biệt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(C) Theo đoạn (D) và (E) dưới đây, khối chức năng được xem như là bộ phận của một hệ thống xử lý dữ liệu tự động nếu thỏa mãn tất cả các điều kiện sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Là loại chỉ sử dụng hoặc chủ yếu sử dụng trong hệ thống xử lý dữ liệu tự động;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) Có khả năng kết nối với bộ xử lý trung tâm hoặc trực tiếp hoặc thông qua một hoặc nhiều khối chức năng khác; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iii) Có khả năng tiếp nhận hoặc cung cấp dữ liệu theo định dạng (mã hoặc tín hiệu) mà hệ thống có thể sử dụng được. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Những khối chức năng riêng biệt của máy xử lý dữ liệu tự động được phân loại vào nhóm 84.71.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, bàn phím, thiết bị nhập tọa độ X-Y và bộ (ổ) lưu trữ dạng đĩa đáp ứng các điều kiện của đoạn (C) (ii) và (C) (iii) ở trên, trong mọi trường hợp đều được phân loại như khối chức năng của nhóm 84.71. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(D) Nhóm 84.71 không bao gồm các hàng hóa sau đây khi nó được trình bày riêng rẽ, ngay cả khi nó đáp ứng tất cả các điều kiện trong Chú giải 6 (C) nêu trên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) Máy in, máy photocopy, máy fax, có hoặc không liên hợp;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) Thiết bị truyền dẫn hoặc nhận âm thanh, hình ảnh hoặc dữ liệu khác, kể cả thiết bị để giao tiếp trong mạng có dây hoặc không dây (như mạng nội bộ hoặc diện rộng);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iii) Loa và micro;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(iv) Camera truyền hình, camera kỹ thuật số và camera ghi hình ảnh;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(v) Màn hình và máy chiếu, không kết hợp thiết bị thu truyền hình.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(E) Các máy kết hợp hoặc làm việc kết hợp với một máy xử lý dữ liệu tự động và thực hiện một chức năng riêng biệt không phải là chức năng xử lý dữ liệu được phân loại vào nhóm thích hợp với chức năng tương ứng của chúng hoặc, nếu không, sẽ được phân loại vào các nhóm còn lại.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Không kể những đề cập khác, nhóm 84.82 áp dụng cho các bi thép đã đánh bóng, có dung sai lớn nhất và nhỏ nhất so với đường kính danh nghĩa không quá 1% hoặc không quá 0,05 mm, theo mức dung sai nhỏ hơn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các loại bi thép khác được xếp vào nhóm 73.26.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Theo yêu cầu của phân loại, máy móc sử dụng cho hai mục đích trở lên được phân loại theo mục đích sử dụng chính và mục đích này được coi như mục đích sử dụng duy nhất. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Theo Chú giải 2 của Chương này và Chú giải 3 của Phần XVI, loại máy mà công dụng chính của nó không được mô tả trong bất kỳ nhóm nào hoặc không có công dụng nào là chủ yếu, nếu nội dung nhóm không có yêu cầu khác, thì được phân loại vào nhóm 84.79.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nhóm 84.79 cũng bao gồm các máy để làm thừng hoặc cáp (ví dụ, máy bện, xoắn hoặc đánh cáp) bằng dây kim loại, sợi dệt hoặc bất kỳ vật liệu nào khác hoặc bằng các vật liệu kết hợp.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Theo nội dung của nhóm 84.70, khái niệm “loại bỏ túi” chỉ áp dụng cho máy móc có kích thước không quá 170 mm x 100 mm x 45 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '10. Theo mục đích của nhóm 84.85, khái niệm “công nghệ sản xuất bồi đắp” (còn được gọi là công nghệ in 3D) có nghĩa là sự hình thành các vật thể hữu hình, dựa trên mô hình kỹ thuật số, bằng cách đắp chồng và tạo lớp liên tiếp, và hợp nhất (consolidation) và đóng rắn (solidification), từ vật liệu (ví dụ, kim loại, nhựa hoặc gốm).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Theo Chú giải 1 Phần XVI và Chú giải 1 Chương 84, các máy đáp ứng mô tả của nhóm 84.85 được phân loại trong nhóm này và không phân loại vào nhóm khác của Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '11. (A) Chú giải 9 (a) và 9 (b) Chương 85 cũng áp dụng đối với khái niệm “thiết bị bán dẫn” và “mạch điện tử tích hợp”, như đã được sử dụng trong Chú giải này và trong nhóm 84.86. Tuy nhiên, theo mục đích của Chú giải này và của nhóm 84.86, khái niệm “thiết bị bán dẫn” cũng bao gồm các thiết bị bán dẫn nhạy sáng (cảm quang) và đi-ốt phát quang (LED).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Theo mục đích của Chú giải này và của nhóm 84.86, khái niệm “sản xuất màn hình dẹt” bao gồm chế tạo các lớp nền (các lớp cơ bản cấu thành) của tấm dẹt màn hình. Nó không bao gồm công đoạn gia công, sản xuất kính hoặc lắp ráp các tấm mạch in hoặc các bộ phận điện tử khác vào tấm dẹt màn hình. Khái niệm “màn hình dẹt” không áp dụng cho loại sử dụng công nghệ ống đèn hình ca-tốt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(C) Nhóm 84.86 cũng bao gồm những máy móc và thiết bị chỉ sử dụng hoặc chủ yếu sử dụng để:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(i) sản xuất hoặc sửa chữa màn (khuôn in) và lưới quang; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ii) lắp ghép thiết bị bán dẫn hoặc các mạch điện tử tích hợp;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iii) bộ phận nâng, giữ, xếp vào hoặc tháo dỡ khối bán dẫn, tấm bán dẫn mỏng, thiết bị bán dẫn, mạch điện tử tích hợp và màn hình dẹt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(D) Theo Chú giải 1 Phần XVI và Chú giải 1 Chương 84, máy móc và thiết bị đáp ứng mô tả của nhóm 84.86 được phân loại trong nhóm này và không phân loại vào nhóm khác của Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của phân nhóm 8465.20, khái niệm ""trung tâm gia công"" chỉ áp dụng cho máy công cụ để gia công gỗ, lie, xương, cao su cứng, plastic cứng hoặc các vật liệu cứng tương tự, có thể thực hiện các kiểu hoạt động nguyên công khác nhau bằng việc thay đổi dao cụ tự động từ hộp/khay đựng hoặc loại tương tự theo đúng chương trình gia công.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của phân nhóm 8471.49, khái niệm “hệ thống” nghĩa là các máy xử lý dữ liệu tự động mà các khối chức năng của nó đáp ứng các điều kiện nêu tại Chú giải 6 (C) Chương 84 và nó gồm ít nhất một bộ xử lý trung tâm, một bộ nhập (ví dụ, bàn phím hoặc bộ quét), và một bộ xuất (ví dụ, một bộ hiển thị hoặc máy in).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của phân nhóm 8481.20, khái niệm ""van dùng trong truyền động dầu thủy lực hoặc truyền động khí nén"" là van chuyên dụng truyền động ""thủy lực/khí nén"" (1) trong hệ thủy lực hoặc khí nén mà từ đó nguồn năng lượng được cung cấp dưới dạng chất lưu nén (chất lỏng hoặc khí). Các van này có thể là loại bất kỳ (ví dụ, van giảm áp, van kiểm tra). Phân nhóm 8481.20 được ưu tiên xem xét trước trong các phân nhóm thuộc nhóm 84.81.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Phân nhóm 8482.40 chỉ áp dụng cho ổ bi đũa có đường kính đồng nhất của các bi đũa không quá 5 mm và có chiều dài ít nhất gấp 3 lần đường kính. Hai đầu bi đũa có thể được tiện tròn.',
    Unit: '',
  },
  {
    HsCode: '8401',
    Description:
      'Lò phản ứng hạt nhân; các bộ phận chứa nhiên liệu (cartridges), không bị bức xạ, dùng cho các lò phản ứng hạt nhân; máy và thiết bị để tách chất đồng vị',
    Unit: '',
  },
  {
    HsCode: '84011000',
    Description: '- Lò phản ứng hạt nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '84012000',
    Description: '- Máy và thiết bị để tách chất đồng vị, và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84013000',
    Description: '- Bộ phận chứa nhiên liệu (cartridges), không bị bức xạ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84014000',
    Description: '- Các bộ phận của lò phản ứng hạt nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '8402',
    Description:
      'Nồi hơi tạo ra hơi nước hoặc tạo ra hơi khác (trừ các nồi hơi đun nóng nước trung tâm có khả năng sản xuất ra hơi với áp suất thấp); nồi hơi nước quá nhiệt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Nồi hơi tạo ra hơi nước hoặc tạo ra hơi khác:',
    Unit: '',
  },
  {
    HsCode: '840211',
    Description: '- - Nồi hơi dạng ống nước với công suất hơi nước trên 45 tấn/giờ:',
    Unit: '',
  },
  {
    HsCode: '84021110',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84021120',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '840212',
    Description: '- - Nồi hơi dạng ống nước với công suất hơi nước không quá 45 tấn/giờ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84021211',
    Description: '- - - - Nồi hơi với công suất hơi nước trên 15 tấn/giờ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84021219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84021221',
    Description: '- - - - Nồi hơi với công suất hơi nước trên 15 tấn/giờ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84021229',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840219',
    Description: '- - Nồi hơi tạo ra hơi khác, kể cả loại nồi hơi kiểu lai ghép:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84021911',
    Description: '- - - - Nồi hơi với công suất hơi trên 15 tấn/giờ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84021919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84021921',
    Description: '- - - - Nồi hơi với công suất hơi trên 15 tấn/giờ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84021929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840220',
    Description: '- Nồi hơi nước quá nhiệt:',
    Unit: '',
  },
  {
    HsCode: '84022010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84022020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '840290',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84029010',
    Description: '- - Thân, vỏ hoặc vỏ bọc nồi hơi (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84029090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8403',
    Description: 'Nồi hơi nước sưởi trung tâm trừ các loại thuộc nhóm 84.02',
    Unit: '',
  },
  {
    HsCode: '84031000',
    Description: '- Nồi hơi',
    Unit: 'chiếc',
  },
  {
    HsCode: '840390',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84039010',
    Description: '- - Thân, vỏ hoặc vỏ bọc nồi hơi (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84039090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8404',
    Description:
      'Thiết bị phụ trợ dùng cho các loại nồi hơi thuộc nhóm 84.02 hoặc 84.03 (ví dụ, bộ tiết kiệm nhiên liệu, thiết bị quá nhiệt, máy cạo rửa nồi hơi, thiết bị thu hồi chất khí); thiết bị ngưng tụ dùng cho các tổ máy động lực hơi nước hoặc hơi khác',
    Unit: '',
  },
  {
    HsCode: '840410',
    Description: '- Thiết bị phụ trợ dùng cho các loại nồi hơi thuộc nhóm 84.02 hoặc 84.03:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho nồi hơi thuộc nhóm 84.02:',
    Unit: '',
  },
  {
    HsCode: '84041011',
    Description: '- - - Thiết bị loại bỏ muội (máy thổi muội)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84041019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84041020',
    Description: '- - Dùng cho nồi hơi thuộc nhóm 84.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '84042000',
    Description: '- Thiết bị ngưng tụ dùng cho tổ máy động lực hơi nước hoặc hơi khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840490',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của hàng hóa thuộc phân nhóm 8404.10.11 và 8404.10.19:',
    Unit: '',
  },
  {
    HsCode: '84049011',
    Description: '- - - Thân, vỏ hoặc vỏ bọc nồi hơi (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84049019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của hàng hóa thuộc phân nhóm 8404.10.20:',
    Unit: '',
  },
  {
    HsCode: '84049021',
    Description: '- - - Thân, vỏ hoặc vỏ bọc nồi hơi (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84049029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84049090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8405',
    Description:
      'Máy sản xuất chất khí hoặc hơi nước, có hoặc không kèm theo bộ lọc; máy sản xuất khí axetylen và các loại máy sản xuất chất khí theo quy trình xử lý bằng nước tương tự, có hoặc không kèm theo bộ lọc',
    Unit: '',
  },
  {
    HsCode: '84051000',
    Description:
      '- Máy sản xuất chất khí hoặc hơi nước, có hoặc không kèm theo bộ lọc; máy sản xuất khí axetylen và các loại máy sản xuất chất khí theo quy trình xử lý bằng nước tương tự, có hoặc không kèm theo bộ lọc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84059000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8406',
    Description: 'Tua bin hơi nước và các loại tua bin hơi khác',
    Unit: '',
  },
  {
    HsCode: '84061000',
    Description: '- Tua bin dùng cho máy thủy',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tua bin loại khác:',
    Unit: '',
  },
  {
    HsCode: '84068100',
    Description: '- - Công suất đầu ra trên 40 MW',
    Unit: 'chiếc',
  },
  {
    HsCode: '840682',
    Description: '- - Công suất đầu ra không quá 40 MW:',
    Unit: '',
  },
  {
    HsCode: '84068210',
    Description: '- - - Công suất đầu ra không quá 5 MW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84068290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84069000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8407',
    Description:
      'Động cơ đốt trong kiểu piston chuyển động tịnh tiến hoặc kiểu piston chuyển động quay đốt cháy bằng tia lửa điện',
    Unit: '',
  },
  {
    HsCode: '84071000',
    Description: '- Động cơ phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Động cơ máy thủy:',
    Unit: '',
  },
  {
    HsCode: '840721',
    Description: '- - Động cơ gắn ngoài:',
    Unit: '',
  },
  {
    HsCode: '84072110',
    Description: '- - - Công suất không quá 22,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84072190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840729',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84072920',
    Description: '- - - Công suất không quá 22,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84072990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Động cơ đốt trong kiểu piston chuyển động tịnh tiến dùng để tạo động lực cho các loại xe thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '84073100',
    Description: '- - Dung tích xi lanh không quá 50 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '840732',
    Description: '- - Dung tích xi lanh trên 50 cc nhưng không quá 250 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dung tích xilanh trên 50 cc nhưng không quá 110 cc:',
    Unit: '',
  },
  {
    HsCode: '84073211',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073212',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dung tích xilanh trên 110 cc nhưng không quá 250 cc:',
    Unit: '',
  },
  {
    HsCode: '84073222',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073229',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840733',
    Description: '- - Dung tích xi lanh trên 250 cc nhưng không quá 1.000 cc:',
    Unit: '',
  },
  {
    HsCode: '84073310',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073320',
    Description: '- - - Dùng cho xe thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840734',
    Description: '- - Dung tích xi lanh trên 1.000 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '84073440',
    Description: '- - - - Dùng cho máy kéo cầm tay, dung tích xi lanh không quá 1.100 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073450',
    Description: '- - - - Dùng cho xe khác thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073460',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84073471',
    Description: '- - - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073472',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073473',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84073491',
    Description: '- - - - Dùng cho máy kéo cầm tay, dung tích xi lanh không quá 1.100 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073492',
    Description: '- - - - Dùng cho xe khác thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073493',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84073494',
    Description: '- - - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073495',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84073499',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '840790',
    Description: '- Động cơ khác:',
    Unit: '',
  },
  {
    HsCode: '84079010',
    Description: '- - Công suất không quá 18,65 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84079020',
    Description: '- - Công suất trên 18,65 kW nhưng không quá 22,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84079090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8408',
    Description: 'Động cơ đốt trong kiểu piston cháy do nén (động cơ diesel hoặc bán diesel)',
    Unit: '',
  },
  {
    HsCode: '840810',
    Description: '- Động cơ máy thủy:',
    Unit: '',
  },
  {
    HsCode: '84081010',
    Description: '- - Công suất không quá 22,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84081020',
    Description: '- - Công suất trên 22,38 kW nhưng không quá 100 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84081030',
    Description: '- - Công suất trên 100 kW nhưng không quá 750 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84081090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840820',
    Description: '- Động cơ dùng để tạo động lực cho các loại xe thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '84082010',
    Description: '- - - Dùng cho xe thuộc phân nhóm 8701.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84082021',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84082022',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84082023',
    Description: '- - - - Dung tích xi lanh trên 3.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84082093',
    Description: '- - - Dùng cho xe thuộc phân nhóm 8701.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84082094',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84082095',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84082096',
    Description: '- - - - Dung tích xi lanh trên 3.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '840890',
    Description: '- Động cơ khác:',
    Unit: '',
  },
  {
    HsCode: '84089010',
    Description: '- - Công suất không quá 18,65 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Công suất trên 100 kW:',
    Unit: '',
  },
  {
    HsCode: '84089051',
    Description: '- - - Của loại sử dụng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84089052',
    Description: '- - - Của loại sử dụng cho đầu máy di chuyển trên đường sắt hoặc tàu điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84089059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84089091',
    Description: '- - - Dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84089092',
    Description: '- - - Của loại sử dụng cho đầu máy di chuyển trên đường sắt hoặc tàu điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84089099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8409',
    Description: 'Các bộ phận chỉ dùng hoặc chủ yếu dùng cho các loại động cơ thuộc nhóm 84.07 hoặc 84.08',
    Unit: '',
  },
  {
    HsCode: '84091000',
    Description: '- Dùng cho động cơ phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '840991',
    Description: '- - Chỉ dùng hoặc chủ yếu dùng cho động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho máy thuộc nhóm 84.29 hoặc 84.30:',
    Unit: '',
  },
  {
    HsCode: '84099111',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099112',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099113',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099114',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099115',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099116',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099117',
    Description: '- - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099118',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01:',
    Unit: '',
  },
  {
    HsCode: '84099121',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099122',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099123',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099124',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099126',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099128',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe của nhóm 87.11:',
    Unit: '',
  },
  {
    HsCode: '84099131',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099132',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099134',
    Description: '- - - - Ống xi lanh',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099135',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099137',
    Description: '- - - - Piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099138',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099139',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe khác thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '84099141',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099142',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099143',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099144',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099145',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099146',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099147',
    Description: '- - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099148',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099149',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho tàu thuyền thuộc Chương 89:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Cho động cơ máy thủy công suất không quá 22,38 kW:',
    Unit: '',
  },
  {
    HsCode: '84099151',
    Description: '- - - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099152',
    Description: '- - - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099153',
    Description: '- - - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099154',
    Description: '- - - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099155',
    Description: '- - - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099159',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Cho động cơ máy thủy công suất trên 22,38 kW:',
    Unit: '',
  },
  {
    HsCode: '84099161',
    Description: '- - - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099162',
    Description: '- - - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099163',
    Description: '- - - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099164',
    Description: '- - - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099169',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ khác:',
    Unit: '',
  },
  {
    HsCode: '84099171',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099172',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099173',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099174',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099176',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099177',
    Description: '- - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099178',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099179',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '840999',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ của máy thuộc nhóm 84.29 hoặc 84.30:',
    Unit: '',
  },
  {
    HsCode: '84099911',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099912',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099913',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099914',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099915',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099916',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099917',
    Description: '- - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099918',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ của xe thuộc nhóm 87.01:',
    Unit: '',
  },
  {
    HsCode: '84099921',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099922',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099923',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099924',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099925',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099926',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099927',
    Description: '- - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ của xe thuộc nhóm 87.11:',
    Unit: '',
  },
  {
    HsCode: '84099931',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099932',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099934',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099936',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099937',
    Description:
      '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm; piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099938',
    Description: '- - - - Ống xi lanh và piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099939',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ của xe khác thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '84099941',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099942',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099943',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099944',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099945',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099946',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099947',
    Description: '- - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099948',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099949',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ tàu thuyền thuộc Chương 89:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Cho động cơ máy thủy công suất không quá 22,38 kW:',
    Unit: '',
  },
  {
    HsCode: '84099951',
    Description: '- - - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099952',
    Description: '- - - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099953',
    Description: '- - - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099954',
    Description: '- - - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099955',
    Description: '- - - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099959',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Cho động cơ máy thủy công suất trên 22,38 kW:',
    Unit: '',
  },
  {
    HsCode: '84099961',
    Description: '- - - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099962',
    Description: '- - - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099963',
    Description: '- - - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099964',
    Description: '- - - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099965',
    Description: '- - - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099969',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ khác:',
    Unit: '',
  },
  {
    HsCode: '84099971',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099972',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099973',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099974',
    Description: '- - - - Ống xi lanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099975',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099976',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099977',
    Description: '- - - - Piston khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099978',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: 'chiếc',
  },
  {
    HsCode: '84099979',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8410',
    Description: 'Tua bin thủy lực, bánh xe guồng nước, và các bộ điều chỉnh của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tua bin thủy lực và bánh xe guồng nước:',
    Unit: '',
  },
  {
    HsCode: '84101100',
    Description: '- - Công suất không quá 1.000 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84101200',
    Description: '- - Công suất trên 1.000 kW nhưng không quá 10.000 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84101300',
    Description: '- - Công suất trên 10.000 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84109000',
    Description: '- Bộ phận, kể cả bộ điều chỉnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '8411',
    Description: 'Tua bin phản lực, tua bin cánh quạt và các loại tua bin khí khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tua bin phản lực:',
    Unit: '',
  },
  {
    HsCode: '84111100',
    Description: '- - Có lực đẩy không quá 25 kN',
    Unit: 'chiếc',
  },
  {
    HsCode: '84111200',
    Description: '- - Có lực đẩy trên 25 kN',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tua bin cánh quạt:',
    Unit: '',
  },
  {
    HsCode: '84112100',
    Description: '- - Công suất không quá 1.100 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84112200',
    Description: '- - Công suất trên 1.100 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các loại tua bin khí khác:',
    Unit: '',
  },
  {
    HsCode: '84118100',
    Description: '- - Công suất không quá 5.000 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84118200',
    Description: '- - Công suất trên 5.000 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84119100',
    Description: '- - Của tua bin phản lực hoặc tua bin cánh quạt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84119900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8412',
    Description: 'Động cơ và mô tơ khác',
    Unit: '',
  },
  {
    HsCode: '84121000',
    Description: '- Động cơ phản lực trừ tua bin phản lực',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Động cơ và mô tơ thủy lực:',
    Unit: '',
  },
  {
    HsCode: '84122100',
    Description: '- - Chuyển động tịnh tiến (xi lanh)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84122900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Động cơ và mô tơ dùng khí nén:',
    Unit: '',
  },
  {
    HsCode: '84123100',
    Description: '- - Chuyển động tịnh tiến (xi lanh)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84123900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84128000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841290',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84129010',
    Description: '- - Của động cơ thuộc phân nhóm 8412.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84129020',
    Description: '- - Của động cơ/mô tơ dùng cho tổ máy động lực hơi nước hoặc hơi khác gắn với nồi hơi ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84129090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8413',
    Description: 'Bơm chất lỏng, có hoặc không lắp thiết bị đo; máy đẩy chất lỏng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bơm có lắp hoặc thiết kế để lắp thiết bị đo:',
    Unit: '',
  },
  {
    HsCode: '84131100',
    Description: '- - Bơm phân phối nhiên liệu hoặc dầu bôi trơn, loại dùng cho trạm đổ xăng hoặc cho gara',
    Unit: 'chiếc',
  },
  {
    HsCode: '841319',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84131910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84131920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '841320',
    Description: '- Bơm tay, trừ loại thuộc phân nhóm 8413.11 hoặc 8413.19:',
    Unit: '',
  },
  {
    HsCode: '84132010',
    Description: '- - Bơm nước',
    Unit: 'chiếc',
  },
  {
    HsCode: '84132020',
    Description: '- - Bơm hút sữa',
    Unit: 'chiếc',
  },
  {
    HsCode: '84132090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841330',
    Description: '- Bơm nhiên liệu, dầu bôi trơn hoặc bơm chất làm mát, dùng cho động cơ đốt trong kiểu piston:',
    Unit: '',
  },
  {
    HsCode: '84133030',
    Description: '- - Bơm nhiên liệu loại sử dụng cho động cơ của xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '84133040',
    Description:
      '- - Bơm nước loại được sử dụng cho động cơ của xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04 (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, loại ly tâm:',
    Unit: '',
  },
  {
    HsCode: '84133051',
    Description: '- - - Với đường kính cửa hút không quá 200 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84133052',
    Description: '- - - Với đường kính cửa hút trên 200 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84133090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84134000',
    Description: '- Bơm bê tông',
    Unit: 'chiếc',
  },
  {
    HsCode: '841350',
    Description: '- Bơm hoạt động kiểu piston chuyển động tịnh tiến khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bơm nước, với lưu lượng không quá 8.000 m³/h:',
    Unit: '',
  },
  {
    HsCode: '84135031',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84135032',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84135040',
    Description: '- - Bơm nước, với lưu lượng trên 8.000 m³/h nhưng không quá 13.000 m³/h',
    Unit: 'chiếc',
  },
  {
    HsCode: '84135090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841360',
    Description: '- Bơm hoạt động kiểu piston quay khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bơm nước, với lưu lượng không quá 8.000 m³/h:',
    Unit: '',
  },
  {
    HsCode: '84136031',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84136032',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84136040',
    Description: '- - Bơm nước, với lưu lượng trên 8.000 m³/h nhưng không quá 13.000 m³/h',
    Unit: 'chiếc',
  },
  {
    HsCode: '84136090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841370',
    Description: '- Bơm ly tâm khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Bơm nước một tầng, một chiều hút, trục ngang được truyền động bằng dây đai hoặc khớp nối trực tiếp, trừ loại bơm đồng trục với động cơ:',
    Unit: '',
  },
  {
    HsCode: '84137011',
    Description: '- - - Với đường kính cửa hút không quá 200 mm (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84137019',
    Description: '- - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bơm nước được thiết kế đặt chìm dưới nước:',
    Unit: '',
  },
  {
    HsCode: '84137031',
    Description: '- - - Với đường kính cửa hút không quá 200 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84137039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bơm nước khác, với lưu lượng không quá 8.000 m³/h:',
    Unit: '',
  },
  {
    HsCode: '84137042',
    Description: '- - - Với đường kính cửa hút không quá 200 mm, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84137043',
    Description: '- - - Với đường kính cửa hút không quá 200 mm, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84137049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bơm nước khác, với lưu lượng trên 8.000 m³/h nhưng không quá 13.000 m³/h:',
    Unit: '',
  },
  {
    HsCode: '84137051',
    Description: '- - - Với đường kính cửa hút không quá 200 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84137059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84137091',
    Description: '- - - Với đường kính cửa hút không quá 200 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84137099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bơm khác; máy đẩy chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '841381',
    Description: '- - Bơm:',
    Unit: '',
  },
  {
    HsCode: '84138113',
    Description: '- - - Bơm nước, với lưu lượng không quá 8.000 m³/h, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84138114',
    Description: '- - - Bơm nước, với lưu lượng không quá 8.000 m³/h, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84138115',
    Description: '- - - Bơm nước, với lưu lượng trên 8.000 m³/h nhưng không quá 13.000 m³/h',
    Unit: 'chiếc',
  },
  {
    HsCode: '84138119',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841382',
    Description: '- - Máy đẩy chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '84138210',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84138220',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '841391',
    Description: '- - Của bơm:',
    Unit: '',
  },
  {
    HsCode: '84139110',
    Description: '- - - Của bơm thuộc phân nhóm 8413.20.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84139120',
    Description: '- - - Của bơm thuộc phân nhóm 8413.20.20 và 8413.20.90',
    Unit: 'chiếc',
  },
  {
    HsCode: '84139130',
    Description: '- - - Của bơm thuộc phân nhóm 8413.70.11 và 8413.70.19',
    Unit: 'chiếc',
  },
  {
    HsCode: '84139140',
    Description: '- - - Của bơm ly tâm khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84139190',
    Description: '- - - Của bơm khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84139200',
    Description: '- - Của máy đẩy chất lỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '8414',
    Description:
      'Bơm không khí hoặc bơm chân không, máy nén không khí hoặc chất khí khác và quạt; nắp chụp hút tuần hoàn gió hoặc thông gió có kèm theo quạt, có hoặc không lắp bộ phận lọc; tủ an toàn sinh học kín khí, có hoặc không lắp bộ phận lọc',
    Unit: '',
  },
  {
    HsCode: '84141000',
    Description: '- Bơm chân không',
    Unit: 'chiếc',
  },
  {
    HsCode: '841420',
    Description: '- Bơm không khí điều khiển bằng tay hoặc chân:',
    Unit: '',
  },
  {
    HsCode: '84142010',
    Description: '- - Bơm xe đạp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84142090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841430',
    Description: '- Máy nén sử dụng trong thiết bị làm lạnh:',
    Unit: '',
  },
  {
    HsCode: '84143040',
    Description:
      '- - Có công suất làm lạnh trên 21,10 kW, hoặc có dung tích làm việc trên một chu kỳ từ 220 cc trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '84143090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84144000',
    Description: '- Máy nén không khí lắp trên khung có bánh xe di chuyển',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Quạt:',
    Unit: '',
  },
  {
    HsCode: '841451',
    Description:
      '- - Quạt bàn, quạt sàn, quạt tường, quạt cửa sổ, quạt trần hoặc quạt mái, có động cơ điện gắn liền với công suất không quá 125 W:',
    Unit: '',
  },
  {
    HsCode: '84145110',
    Description: '- - - Quạt bàn và quạt dạng hộp',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84145191',
    Description: '- - - - Có lưới bảo vệ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84145199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841459',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Công suất không quá 125 kW:',
    Unit: '',
  },
  {
    HsCode: '84145910',
    Description:
      '- - - - Quạt, loại chỉ sử dụng hoặc chủ yếu sử dụng để làm mát bộ vi xử lý, thiết bị viễn thông, máy xử lý dữ liệu tự động hoặc các khối chức năng của máy xử lý dữ liệu tự động (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84145920',
    Description: '- - - - Quạt gió phòng nổ, loại sử dụng trong hầm lò',
    Unit: 'chiếc',
  },
  {
    HsCode: '84145930',
    Description: '- - - - Máy thổi khí (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84145941',
    Description: '- - - - - Có lưới bảo vệ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84145949',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84145950',
    Description: '- - - - Máy thổi khí (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84145991',
    Description: '- - - - - Có lưới bảo vệ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84145992',
    Description: '- - - - - Quạt gió phòng nổ, loại sử dụng trong hầm lò',
    Unit: 'chiếc',
  },
  {
    HsCode: '84145999',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841460',
    Description: '- Nắp chụp hút có kích thước chiều ngang tối đa không quá 120 cm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Đã lắp bộ phận lọc:',
    Unit: '',
  },
  {
    HsCode: '84146011',
    Description: '- - - Tủ hút, lọc không khí sử dụng trong phòng thí nghiệm (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84146019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Chưa lắp bộ phận lọc:',
    Unit: '',
  },
  {
    HsCode: '84146091',
    Description: '- - - Phù hợp dùng trong công nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84146099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841470',
    Description: '- Tủ an toàn sinh học kín khí:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Đã lắp bộ phận lọc:',
    Unit: '',
  },
  {
    HsCode: '84147011',
    Description: '- - - Có nắp chụp hút với kích thước chiều ngang tối đa không quá 120 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84147019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Chưa lắp bộ phận lọc:',
    Unit: '',
  },
  {
    HsCode: '84147021',
    Description: '- - - Có nắp chụp hút với kích thước chiều ngang tối đa không quá 120 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84147029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841480',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Nắp chụp hút có kích thước chiều ngang tối đa trên 120 cm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Đã lắp bộ phận lọc:',
    Unit: '',
  },
  {
    HsCode: '84148011',
    Description: '- - - - Tủ hút, lọc không khí sử dụng trong phòng thí nghiệm (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148019',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Chưa lắp bộ phận lọc:',
    Unit: '',
  },
  {
    HsCode: '84148021',
    Description: '- - - - Phù hợp dùng trong công nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148030',
    Description: '- - Thiết bị tạo gió có cơ cấu piston dịch chuyển tự do dùng cho tua bin khí',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Máy nén trừ loại thuộc phân nhóm 8414.30 hoặc 8414.40:',
    Unit: '',
  },
  {
    HsCode: '84148041',
    Description: '- - - Mô-đun nén khí sử dụng trong khoan dầu mỏ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148042',
    Description: '- - - Dùng cho máy điều hòa không khí của ô tô (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148043',
    Description: '- - - Dạng kín dùng cho máy điều hòa không khí (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148050',
    Description: '- - Máy bơm không khí',
    Unit: 'chiếc',
  },
  {
    HsCode: '84148090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841490',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của quạt:',
    Unit: '',
  },
  {
    HsCode: '84149021',
    Description: '- - - Của hàng hóa thuộc nhóm 84.15, 84.18, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149022',
    Description: '- - - Của máy thổi khí',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của nắp chụp hút:',
    Unit: '',
  },
  {
    HsCode: '84149031',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8414.60',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149032',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8414.80',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của hàng hóa thuộc phân nhóm 8414.10:',
    Unit: '',
  },
  {
    HsCode: '84149041',
    Description: '- - - Dùng cho máy hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149042',
    Description: '- - - Dùng cho máy không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149050',
    Description: '- - Của hàng hóa thuộc phân nhóm 8414.20',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149060',
    Description: '- - Của hàng hóa thuộc phân nhóm 8414.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149070',
    Description: '- - Của hàng hóa thuộc phân nhóm 8414.40',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149080',
    Description: '- - Của hàng hóa thuộc phân nhóm 8414.70',
    Unit: 'chiếc',
  },
  {
    HsCode: '84149090',
    Description: '- - Của máy bơm hoặc máy nén khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8415',
    Description:
      'Máy điều hòa không khí, gồm có một quạt chạy bằng mô tơ và các bộ phận làm thay đổi nhiệt độ và độ ẩm, kể cả loại máy không điều chỉnh độ ẩm một cách riêng biệt',
    Unit: '',
  },
  {
    HsCode: '841510',
    Description:
      '- Loại thiết kế để lắp vào cửa sổ, tường, trần hoặc sàn, kiểu một khối (lắp liền trong cùng một vỏ, một cục) hoặc ""hệ thống nhiều khối chức năng"" (cục nóng, cục lạnh tách biệt) :',
    Unit: '',
  },
  {
    HsCode: '84151020',
    Description: '- - Công suất làm mát không quá 21,10 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84151030',
    Description: '- - Công suất làm mát trên 21,10 kW nhưng không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84151090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841520',
    Description: '- Loại sử dụng cho người, trong xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '84152010',
    Description: '- - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84152090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '841581',
    Description: '- - Kèm theo một bộ phận làm lạnh và một van đảo chiều chu trình nóng/lạnh (bơm nhiệt có đảo chiều):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại sử dụng cho phương tiện bay:',
    Unit: '',
  },
  {
    HsCode: '84158111',
    Description: '- - - - Công suất làm mát không quá 21,10 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158112',
    Description:
      '- - - - Công suất làm mát trên 21,10 kW và có lưu lượng không khí của mỗi dàn bay hơi trên 67,96 m3/phút (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho phương tiện giao thông đường sắt:',
    Unit: '',
  },
  {
    HsCode: '84158121',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho xe có động cơ (trừ loại thuộc phân nhóm 8415.20):',
    Unit: '',
  },
  {
    HsCode: '84158131',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158139',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84158195',
    Description:
      '- - - - Công suất làm mát trên 21,10 kW nhưng không quá 26,38 kW và có lưu lượng khí của mỗi dàn bay hơi trên 67,96 m3/phút (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158196',
    Description:
      '- - - - Công suất làm mát trên 26,38 kW và có lưu lượng khí của mỗi dàn bay hơi trên 67,96 m3/phút (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84158197',
    Description: '- - - - - Công suất làm mát không quá 21,10 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158198',
    Description: '- - - - - Công suất làm mát trên 21,10 kW nhưng không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158199',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841582',
    Description: '- - Loại khác, có kèm theo bộ phận làm lạnh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại sử dụng cho phương tiện bay:',
    Unit: '',
  },
  {
    HsCode: '84158211',
    Description:
      '- - - - Công suất làm mát trên 21,10 kW và có lưu lượng không khí của mỗi dàn bay hơi trên 67,96 m3/phút (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho phương tiện giao thông đường sắt:',
    Unit: '',
  },
  {
    HsCode: '84158221',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158229',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho xe có động cơ (trừ loại thuộc phân nhóm 8415.20):',
    Unit: '',
  },
  {
    HsCode: '84158231',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158239',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84158291',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841583',
    Description: '- - Không gắn kèm bộ phận làm lạnh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại sử dụng cho phương tiện bay:',
    Unit: '',
  },
  {
    HsCode: '84158311',
    Description:
      '- - - - Công suất làm mát trên 21,10 kW và có lưu lượng không khí của mỗi dàn bay hơi trên 67,96 m3/phút (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158319',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho phương tiện giao thông đường sắt:',
    Unit: '',
  },
  {
    HsCode: '84158321',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158329',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho xe có động cơ (trừ loại thuộc phân nhóm 8415.20):',
    Unit: '',
  },
  {
    HsCode: '84158331',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158339',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84158391',
    Description: '- - - - Công suất làm mát không quá 26,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84158399',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841590',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của máy có công suất làm mát không quá 21,10 kW:',
    Unit: '',
  },
  {
    HsCode: '84159013',
    Description: '- - - Loại dùng cho phương tiện bay hoặc phương tiện giao thông đường sắt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159014',
    Description: '- - - Thiết bị làm bay hơi hoặc ngưng tụ dùng cho máy điều hòa không khí lắp trên xe có động cơ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159015',
    Description: '- - - Khung vỏ, đã hàn và sơn, trừ loại thuộc phân nhóm 8415.90.13',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy có công suất làm mát trên 21,10 kW nhưng không quá 26,38 kW:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có lưu lượng không khí của mỗi dàn bay hơi trên 67,96 m3/phút (1):',
    Unit: '',
  },
  {
    HsCode: '84159024',
    Description: '- - - - Loại dùng cho phương tiện bay hoặc phương tiện giao thông đường sắt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159025',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84159026',
    Description: '- - - - Loại dùng cho phương tiện bay hoặc phương tiện giao thông đường sắt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy có công suất làm mát trên 26,38 kW nhưng không quá 52,75 kW:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có lưu lượng không khí của mỗi dàn bay hơi trên 67,96 m3/phút (1):',
    Unit: '',
  },
  {
    HsCode: '84159034',
    Description: '- - - - Loại dùng cho phương tiện bay hoặc phương tiện giao thông đường sắt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159035',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84159036',
    Description: '- - - - Loại dùng cho phương tiện bay hoặc phương tiện giao thông đường sắt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159039',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy có công suất làm mát trên 52,75 kW:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có lưu lượng không khí của mỗi dàn bay hơi trên 67,96 m3/phút (1):',
    Unit: '',
  },
  {
    HsCode: '84159044',
    Description: '- - - - Loại dùng cho phương tiện bay hoặc phương tiện giao thông đường sắt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159045',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84159046',
    Description: '- - - - Loại dùng cho phương tiện bay hoặc phương tiện giao thông đường sắt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84159049',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8416',
    Description:
      'Đầu đốt dùng cho lò luyện, nung sử dụng nhiên liệu lỏng, nhiên liệu rắn dạng bột hoặc nhiên liệu khí; máy nạp nhiên liệu cơ khí, kể cả ghi lò, bộ phận xả tro xỉ và các bộ phận tương tự của chúng',
    Unit: '',
  },
  {
    HsCode: '84161000',
    Description: '- Đầu đốt cho lò luyện, nung sử dụng nhiên liệu lỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84162000',
    Description: '- Đầu đốt cho lò luyện, nung khác, kể cả lò luyện, nung dùng nhiên liệu kết hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84163000',
    Description: '- Máy nạp nhiên liệu cơ khí, kể cả ghi lò, bộ phận xả tro xỉ và các bộ phận tương tự của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84169000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8417',
    Description:
      'Lò luyện, nung và lò dùng trong công nghiệp hoặc trong phòng thí nghiệm, kể cả lò thiêu, không dùng điện',
    Unit: '',
  },
  {
    HsCode: '84171000',
    Description:
      '- Lò luyện, nung và lò dùng để nung, nấu chảy hoặc xử lý nhiệt các loại quặng, quặng pirit hoặc kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '84172000',
    Description: '- Lò nướng bánh, kể cả lò nướng bánh quy',
    Unit: 'chiếc',
  },
  {
    HsCode: '841780',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84178010',
    Description: '- - Lò đốt rác thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '84178090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84179000',
    Description: ' - Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8418',
    Description:
      'Tủ lạnh, tủ kết đông (1) và thiết bị làm lạnh hoặc kết đông khác, loại dùng điện hoặc loại khác; bơm nhiệt trừ máy điều hòa không khí thuộc nhóm 84.15',
    Unit: '',
  },
  {
    HsCode: '841810',
    Description:
      '- Tủ kết đông lạnh (1) liên hợp (dạng thiết bị có buồng làm đá và làm lạnh riêng biệt), có các cửa mở riêng biệt hoặc ngăn kéo ngoài riêng biệt, hoặc dạng kết hợp của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Chỉ có các cửa mở riêng biệt:',
    Unit: '',
  },
  {
    HsCode: '84181031',
    Description: '- - - Loại gia dụng, có dung tích không quá 230 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '84181032',
    Description: '- - - Loại gia dụng khác, có dung tích trên 230 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '84181039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84181040',
    Description: '- - Loại khác, phù hợp dùng trong y tế, phẫu thuật hoặc phòng thí nghiệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84181091',
    Description: '- - - Quầy hàng, tủ bày hàng và các loại tương tự, có lắp thiết bị làm lạnh, dung tích trên 200 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '84181099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tủ lạnh (1), loại sử dụng trong gia đình:',
    Unit: '',
  },
  {
    HsCode: '841821',
    Description: '- - Loại sử dụng máy nén:',
    Unit: '',
  },
  {
    HsCode: '84182110',
    Description: '- - - Dung tích không quá 230 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '84182190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84182900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841830',
    Description: '- Tủ kết đông (1), loại cửa trên, dung tích không quá 800 lít:',
    Unit: '',
  },
  {
    HsCode: '84183010',
    Description: '- - Dung tích không quá 200 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '84183090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841840',
    Description: '- Tủ kết đông (1), loại cửa trước, dung tích không quá 900 lít:',
    Unit: '',
  },
  {
    HsCode: '84184010',
    Description: '- - Dung tích không quá 200 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '84184090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841850',
    Description:
      '- Loại có kiểu dáng nội thất khác (tủ, tủ ngăn, quầy hàng, tủ bày hàng và loại tương tự) để bảo quản và trưng bày, có lắp thiết bị làm lạnh hoặc kết đông:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Quầy hàng, tủ bày hàng và các loại tương tự, có lắp thiết bị làm lạnh, dung tích trên 200 lít:',
    Unit: '',
  },
  {
    HsCode: '84185011',
    Description: '- - - Loại phù hợp dùng trong y tế, phẫu thuật hoặc phòng thí nghiệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84185019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84185091',
    Description: '- - - Loại phù hợp dùng trong y tế, phẫu thuật hoặc phòng thí nghiệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84185099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thiết bị làm lạnh hoặc kết đông khác; bơm nhiệt:',
    Unit: '',
  },
  {
    HsCode: '84186100',
    Description: '- - Bơm nhiệt trừ loại máy điều hòa không khí của nhóm 84.15',
    Unit: 'chiếc',
  },
  {
    HsCode: '841869',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84186910',
    Description: '- - - Thiết bị làm lạnh đồ uống ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84186930',
    Description: '- - - Thiết bị cấp nước lạnh (cold water dispenser)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Thiết bị làm lạnh nước có công suất làm lạnh trên 21,10 kW:',
    Unit: '',
  },
  {
    HsCode: '84186941',
    Description: '- - - - Dùng cho máy điều hòa không khí',
    Unit: 'chiếc',
  },
  {
    HsCode: '84186949',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84186950',
    Description: '- - - Thiết bị sản xuất đá vảy',
    Unit: 'chiếc',
  },
  {
    HsCode: '84186990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84189100',
    Description: '- - Có kiểu dáng nội thất được thiết kế để lắp đặt thiết bị làm lạnh hoặc kết đông',
    Unit: 'chiếc',
  },
  {
    HsCode: '841899',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84189910',
    Description: '- - - Thiết bị làm bay hơi hoặc ngưng tụ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84189940',
    Description:
      '- - - Panel nhôm cán-ghép dùng cho hàng hóa thuộc phân nhóm 8418.10.31, 8418.10.32, 8418.21.10, 8418.21.90 hoặc 8418.29.00 (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84189990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8419',
    Description:
      'Thiết bị cho phòng thí nghiệm hoặc máy, thiết bị, gia nhiệt bằng điện hoặc không bằng điện (trừ lò luyện, nung, sấy và các thiết bị khác thuộc nhóm 85.14) để xử lý các loại vật liệu bằng quá trình thay đổi nhiệt như làm nóng, nấu, rang, chưng cất, tinh cất, sát trùng, thanh trùng, phun hơi nước, sấy, làm bay hơi, làm khô, ngưng tụ hoặc làm mát trừ các loại máy hoặc thiết bị dùng cho gia đình; thiết bị đun nước nóng nhanh hoặc thiết bị đun nước nóng có dự trữ (1), không dùng điện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Thiết bị đun nước nóng nhanh hoặc thiết bị đun nước nóng có dự trữ (1), không dùng điện: ',
    Unit: '',
  },
  {
    HsCode: '841911',
    Description: '- - Thiết bị đun nước nóng nhanh bằng ga:',
    Unit: '',
  },
  {
    HsCode: '84191110',
    Description: '- - - Loại sử dụng trong gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '84191190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84191200',
    Description: '- - Thiết bị đun nước nóng bằng năng lượng mặt trời',
    Unit: 'chiếc',
  },
  {
    HsCode: '841919',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84191910',
    Description: '- - - Loại sử dụng trong gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '84191990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84192000',
    Description: '- Thiết bị khử trùng trong y tế, phẫu thuật hoặc phòng thí nghiệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy làm khô:',
    Unit: '',
  },
  {
    HsCode: '841933',
    Description: '- - Máy đông khô, khô lạnh và máy làm khô kiểu phun:',
    Unit: '',
  },
  {
    HsCode: '84193310',
    Description: '- - - Dùng cho các sản phẩm nông nghiệp; cho gỗ, bột giấy, giấy hoặc bìa',
    Unit: 'chiếc',
  },
  {
    HsCode: '84193390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841934',
    Description: '- - Loại khác, dùng cho các sản phẩm nông nghiệp: ',
    Unit: '',
  },
  {
    HsCode: '84193410',
    Description: '- - - Thiết bị làm bay hơi ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84193420',
    Description: '- - - Loại khác, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84193490',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841935',
    Description: '- - Loại khác, dùng cho gỗ, bột giấy, giấy hoặc bìa: ',
    Unit: '',
  },
  {
    HsCode: '84193510',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84193520',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '841939',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84193930',
    Description:
      '- - - Máy xử lý vật liệu bằng quá trình gia nhiệt, để sản xuất tấm mạch in, tấm dây in hoặc mạch in đã lắp ráp, hoạt động bằng điện ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84193990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '841940',
    Description: '- Thiết bị chưng cất hoặc tinh cất:',
    Unit: '',
  },
  {
    HsCode: '84194010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84194020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '841950',
    Description: '- Bộ phận trao đổi nhiệt:',
    Unit: '',
  },
  {
    HsCode: '84195010',
    Description: '- - Tháp làm mát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84195020',
    Description:
      '- - Bộ trao đổi nhiệt làm bằng các ống fluoropolyme, với ống đầu vào và ống ra có đường kính trong từ 3 cm trở xuống (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84195091',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84195092',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84196000',
    Description: '- Máy hóa lỏng không khí hoặc các loại chất khí khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy và thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '841981',
    Description: '- - Để làm nóng đồ uống hoặc nấu hoặc hâm nóng thực phẩm:',
    Unit: '',
  },
  {
    HsCode: '84198110',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84198120',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '841989',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84198913',
    Description:
      '- - - - Máy xử lý vật liệu bằng quá trình gia nhiệt, để sản xuất tấm mạch in, tấm dây in hoặc mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84198919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84198920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '841990',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của thiết bị hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84199012',
    Description:
      '- - - Của máy xử lý vật liệu bằng quá trình gia nhiệt, để sản xuất tấm mạch in, tấm dây in hoặc mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84199013',
    Description: '- - - Vỏ của tháp làm mát',
    Unit: 'chiếc',
  },
  {
    HsCode: '84199019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của thiết bị không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84199022',
    Description: '- - - Của thiết bị đun nước nóng nhanh bằng ga, loại sử dụng trong gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '84199029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8420',
    Description:
      'Các loại máy cán là hoặc máy cán ép phẳng kiểu trục lăn khác, trừ các loại máy dùng để cán, ép kim loại hoặc thủy tinh, và các loại trục cán của chúng',
    Unit: '',
  },
  {
    HsCode: '842010',
    Description: '- Máy cán là hoặc máy cán ép phẳng kiểu trục lăn khác:',
    Unit: '',
  },
  {
    HsCode: '84201010',
    Description: '- - Loại chỉ sử dụng hoặc chủ yếu sử dụng để sản xuất tấm nền mạch in hoặc mạch in',
    Unit: 'chiếc',
  },
  {
    HsCode: '84201020',
    Description: '- - Máy là hoặc máy vắt phù hợp sử dụng cho gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '84201090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '842091',
    Description: '- - Trục cán:',
    Unit: '',
  },
  {
    HsCode: '84209110',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8420.10.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84209190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842099',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84209910',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8420.10.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84209990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8421',
    Description: 'Máy ly tâm, kể cả máy làm khô bằng ly tâm; máy và thiết bị lọc hoặc tinh chế chất lỏng hoặc chất khí',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy ly tâm, kể cả máy làm khô bằng ly tâm:',
    Unit: '',
  },
  {
    HsCode: '84211100',
    Description: '- - Máy tách kem',
    Unit: 'chiếc',
  },
  {
    HsCode: '84211200',
    Description: '- - Máy làm khô quần áo',
    Unit: 'chiếc',
  },
  {
    HsCode: '842119',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84211910',
    Description: '- - - Loại sử dụng trong sản xuất đường',
    Unit: 'chiếc',
  },
  {
    HsCode: '84211990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy và thiết bị lọc hoặc tinh chế chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '842121',
    Description: '- - Để lọc hoặc tinh chế nước:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Công suất lọc không quá 500 lít/giờ:',
    Unit: '',
  },
  {
    HsCode: '84212111',
    Description: '- - - - Máy và thiết bị lọc sử dụng trong gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Công suất lọc trên 500 lít/giờ:',
    Unit: '',
  },
  {
    HsCode: '84212122',
    Description: '- - - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212123',
    Description: '- - - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '842122',
    Description: '- - Để lọc hoặc tinh chế đồ uống trừ nước:',
    Unit: '',
  },
  {
    HsCode: '84212230',
    Description: '- - - Hoạt động bằng điện, công suất trên 500 lít/giờ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842123',
    Description: '- - Bộ lọc dầu hoặc xăng cho động cơ đốt trong:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho máy thuộc nhóm 84.29 hoặc 84.30:',
    Unit: '',
  },
  {
    HsCode: '84212311',
    Description: '- - - - Bộ lọc dầu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212319',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe có động cơ thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '84212321',
    Description: '- - - - Bộ lọc dầu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212329',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84212391',
    Description: '- - - - Bộ lọc dầu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212399',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842129',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84212910',
    Description: '- - - Loại phù hợp dùng trong y tế, phẫu thuật hoặc phòng thí nghiệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212920',
    Description: '- - - Loại sử dụng trong sản xuất đường',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212930',
    Description: '- - - Loại sử dụng trong hoạt động khoan dầu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212940',
    Description: '- - - Loại khác, bộ lọc xăng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212950',
    Description: '- - - Loại khác, bộ lọc dầu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212960',
    Description:
      '- - - Loại khác, bằng fluoropolyme và có màng lọc hoặc màng tinh chế với độ dày không quá 140 micron (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84212990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy và thiết bị lọc hoặc tinh chế các loại khí:',
    Unit: '',
  },
  {
    HsCode: '842131',
    Description: '- - Bộ lọc khí nạp cho động cơ đốt trong:',
    Unit: '',
  },
  {
    HsCode: '84213110',
    Description: '- - - Dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84213120',
    Description: '- - - Dùng cho xe có động cơ thuộc Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '84213190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84213200',
    Description:
      '- - Bộ chuyển đổi xúc tác hoặc bộ lọc hạt, có hoặc không kết hợp, để tinh chế hoặc lọc khí thải từ động cơ đốt trong',
    Unit: 'chiếc',
  },
  {
    HsCode: '842139',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84213920',
    Description: '- - - Máy lọc không khí',
    Unit: 'chiếc',
  },
  {
    HsCode: '84213930',
    Description:
      '- - - Loại khác, có vỏ bằng thép không gỉ và có lỗ ống đầu vào và đầu ra có đường kính trong không quá 1,3 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84213990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '842191',
    Description: '- - Của máy ly tâm, kể cả máy làm khô bằng ly tâm:',
    Unit: '',
  },
  {
    HsCode: '84219110',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.12.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219120',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.19.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219190',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.11.00 hoặc 8421.19.90',
    Unit: 'chiếc',
  },
  {
    HsCode: '842199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Lõi lọc của thiết bị lọc thuộc phân nhóm 8421.23:',
    Unit: '',
  },
  {
    HsCode: '84219921',
    Description: '- - - - Của phân nhóm 8421.23.11 hoặc 8421.23.21 (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219929',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219930',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.31',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219940',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.29.50',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219950',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.29.60',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219960',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.39.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219970',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.39.90',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84219991',
    Description: '- - - - Của hàng hóa thuộc phân nhóm 8421.29.20',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219994',
    Description: '- - - - Của hàng hóa thuộc phân nhóm 8421.21.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219996',
    Description: '- - - - Của hàng hóa thuộc phân nhóm 8421.23.11 hoặc 8421.23.91',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219997',
    Description: '- - - - Của hàng hóa thuộc phân nhóm 8421.23.19 hoặc 8421.23.99',
    Unit: 'chiếc',
  },
  {
    HsCode: '84219999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8422',
    Description:
      'Máy rửa bát đĩa; máy làm sạch hoặc làm khô chai lọ hoặc các loại đồ chứa khác; máy rót, đóng kín, gắn xi, đóng nắp hoặc dán nhãn vào các chai, lon, hộp, túi hoặc đồ chứa khác; máy bọc chai lọ, ống và các loại đồ chứa tương tự; máy đóng gói hoặc bao gói khác (kể cả máy bọc màng co nhiệt); máy nạp ga cho đồ uống',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy rửa bát đĩa:',
    Unit: '',
  },
  {
    HsCode: '84221100',
    Description: '- - Loại sử dụng trong gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '84221900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84222000',
    Description: '- Máy làm sạch hoặc làm khô chai lọ hoặc các loại đồ chứa khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84223000',
    Description:
      '- Máy rót, đóng kín, đóng nắp, làm kín hoặc dán nhãn vào các chai, lon, hộp, túi hoặc đồ chứa khác; máy bọc chai, lọ, ống và các đồ chứa tương tự; máy nạp ga cho đồ uống',
    Unit: 'chiếc',
  },
  {
    HsCode: '84224000',
    Description: '- Máy đóng gói khác hoặc bao gói khác (kể cả máy bọc màng co nhiệt)',
    Unit: 'chiếc',
  },
  {
    HsCode: '842290',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84229010',
    Description: '- - Của các máy thuộc phân nhóm 8422.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84229090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8423',
    Description:
      'Cân (trừ loại cân đo có độ nhạy 5 cg hoặc nhạy hơn), kể cả máy đếm hoặc máy kiểm tra, hoạt động bằng nguyên lý cân; các loại quả cân',
    Unit: '',
  },
  {
    HsCode: '842310',
    Description: '- Cân người, kể cả cân trẻ em; cân sử dụng trong gia đình:',
    Unit: '',
  },
  {
    HsCode: '84231010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84231020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '842320',
    Description: '- Cân băng tải:',
    Unit: '',
  },
  {
    HsCode: '84232010',
    Description: '- - Cân điện tử',
    Unit: 'chiếc',
  },
  {
    HsCode: '84232090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842330',
    Description:
      '- Cân trọng lượng cố định và cân dùng cho việc đóng gói vật liệu với trọng lượng xác định trước vào bao túi hoặc đồ chứa, kể cả cân phễu:',
    Unit: '',
  },
  {
    HsCode: '84233010',
    Description: '- - Cân điện tử',
    Unit: 'chiếc',
  },
  {
    HsCode: '84233090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Cân trọng lượng khác:',
    Unit: '',
  },
  {
    HsCode: '842381',
    Description: '- - Có khả năng cân tối đa không quá 30 kg:',
    Unit: '',
  },
  {
    HsCode: '84238110',
    Description: '- - - Cân điện tử',
    Unit: 'chiếc',
  },
  {
    HsCode: '84238190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842382',
    Description: '- - Có khả năng cân tối đa trên 30 kg nhưng không quá 5.000 kg:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' - - - Có khả năng cân tối đa không quá 1.000 kg: ',
    Unit: '',
  },
  {
    HsCode: '84238231',
    Description: '- - - - Cân điện tử, dùng để cân xe có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84238232',
    Description: '- - - - Cân điện tử khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84238239',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Có khả năng cân tối đa trên 1.000 kg: ',
    Unit: '',
  },
  {
    HsCode: '84238241',
    Description: '- - - - Cân điện tử, dùng để cân xe có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84238242',
    Description: '- - - - Cân điện tử khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84238249',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842389',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84238910',
    Description: '- - - Cân điện tử',
    Unit: 'chiếc',
  },
  {
    HsCode: '84238990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842390',
    Description: '- Quả cân của các loại cân; các bộ phận của cân:',
    Unit: '',
  },
  {
    HsCode: '84239010',
    Description: '- - Quả cân',
    Unit: 'chiếc',
  },
  {
    HsCode: '84239030',
    Description: '- - Bộ phận của cân điện tử, trừ loại sử dụng để cân xe có động cơ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84239040',
    Description: '- - Bộ phận của các loại cân khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8424',
    Description:
      'Thiết bị cơ khí (hoạt động bằng tay hoặc không) để phun bắn, phun rải hoặc phun áp lực các chất lỏng hoặc chất bột; bình dập lửa, đã hoặc chưa nạp; súng phun và các thiết bị tương tự; máy phun bắn hơi nước hoặc cát và các loại máy phun bắn tia tương tự',
    Unit: '',
  },
  {
    HsCode: '842410',
    Description: '- Bình dập lửa, đã hoặc chưa nạp:',
    Unit: '',
  },
  {
    HsCode: '84241010',
    Description: '- - Loại sử dụng cho phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '84241090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842420',
    Description: '- Súng phun và các thiết bị tương tự:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84242011',
    Description: '- - - Dùng trong nông nghiệp hoặc làm vườn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84242019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84242021',
    Description: '- - - Dùng trong nông nghiệp hoặc làm vườn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84242029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84243000',
    Description: '- Máy phun bắn hơi nước hoặc cát và các loại máy phun bắn tia tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thiết bị phun dùng trong nông nghiệp hoặc làm vườn:',
    Unit: '',
  },
  {
    HsCode: '842441',
    Description: '- - Thiết bị phun xách tay:',
    Unit: '',
  },
  {
    HsCode: '84244110',
    Description: '- - - Thiết bị phun thuốc trừ sâu hoạt động bằng tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '84244120',
    Description: '- - - Loại khác, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84244190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842449',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84244910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84244920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '842482',
    Description: '- - Dùng trong nông nghiệp hoặc làm vườn:',
    Unit: '',
  },
  {
    HsCode: '84248210',
    Description: '- - - Hệ thống tưới kiểu nhỏ giọt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84248220',
    Description: '- - - Loại khác, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84248230',
    Description: '- - - Loại khác, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '842489',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84248910',
    Description: '- - - Thiết bị phun, xịt hoạt động bằng tay sử dụng trong gia đình có dung tích không quá 3 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '84248920',
    Description: '- - - Đầu bình phun, xịt có gắn vòi',
    Unit: 'chiếc',
  },
  {
    HsCode: '84248940',
    Description:
      '- - - Loại khác, chỉ sử dụng hoặc chủ yếu sử dụng để sản xuất tấm nền của tấm mạch in hoặc tấm dây in hoặc linh kiện của chúng ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84248950',
    Description: '- - - Loại khác, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84248990',
    Description: '- - - Loại khác, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '842490',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84249010',
    Description: '- - Của bình dập lửa',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của súng phun và các thiết bị tương tự:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84249021',
    Description: '- - - - Của hàng hóa thuộc phân nhóm 8424.20.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84249023',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84249024',
    Description: '- - - - Của hàng hóa thuộc phân nhóm 8424.20.21',
    Unit: 'chiếc',
  },
  {
    HsCode: '84249029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84249030',
    Description: '- - Của máy phun bắn hơi nước hoặc cát và các loại máy phun bắn tia tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '84249093',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8424.82.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84249094',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8424.41.10, 8424.41.20, 8424.49.20 hoặc 8424.82.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84249095',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8424.41.90, 8424.49.10 hoặc 8424.82.20',
    Unit: 'chiếc',
  },
  {
    HsCode: '84249096',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8424.89.40',
    Unit: 'chiếc',
  },
  {
    HsCode: '84249099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8425',
    Description:
      'Hệ ròng rọc và hệ tời trừ tời nâng kiểu gầu nâng (trục tải thùng kíp); tời ngang và tời dọc; kích các loại',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Hệ ròng rọc và hệ tời trừ tời nâng kiểu gầu nâng (trục tải thùng kíp) hoặc hệ tời dùng để nâng xe:',
    Unit: '',
  },
  {
    HsCode: '84251100',
    Description: '- - Loại chạy bằng động cơ điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84251900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tời ngang; tời dọc:',
    Unit: '',
  },
  {
    HsCode: '84253100',
    Description: '- - Loại chạy bằng động cơ điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84253900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Kích; tời nâng xe:',
    Unit: '',
  },
  {
    HsCode: '84254100',
    Description: '- - Hệ thống kích tầng dùng trong ga ra',
    Unit: 'chiếc',
  },
  {
    HsCode: '842542',
    Description: '- - Loại kích và tời khác, dùng thủy lực:',
    Unit: '',
  },
  {
    HsCode: '84254210',
    Description: '- - - Kích nâng dùng cho cơ cấu tự đổ của xe tải',
    Unit: 'chiếc',
  },
  {
    HsCode: '84254290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842549',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84254910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84254920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '8426',
    Description:
      'Cổng trục của tàu thủy; cần trục, kể cả cần trục cáp; khung nâng di động, xe chuyên chở kiểu khung đỡ cột chống và xe công xưởng có lắp cần cẩu',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Cần trục trượt trên giàn trượt (cần trục cổng di động), cần trục vận tải, cổng trục, cầu trục, khung nâng di động và xe chuyên chở kiểu khung đỡ cột chống:',
    Unit: '',
  },
  {
    HsCode: '84261100',
    Description: '- - Cần trục cầu di chuyển trên đế cố định',
    Unit: 'chiếc',
  },
  {
    HsCode: '84261200',
    Description: '- - Khung nâng di động bằng bánh lốp xe và xe chuyên chở kiểu khung đỡ cột chống',
    Unit: 'chiếc',
  },
  {
    HsCode: '842619',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84261920',
    Description: '- - - Cầu trục ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84261930',
    Description: '- - - Cổng trục',
    Unit: 'chiếc',
  },
  {
    HsCode: '84261990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84262000',
    Description: '- Cần trục tháp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84263000',
    Description: '- Cần trục cổng hoặc cần trục cánh xoay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy khác, loại tự hành:',
    Unit: '',
  },
  {
    HsCode: '84264100',
    Description: '- - Chạy bánh lốp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84264900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84269100',
    Description: '- - Thiết kế để nâng xe cơ giới đường bộ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84269900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8427',
    Description:
      'Xe nâng hạ xếp tầng hàng bằng cơ cấu càng nâng; các loại xe công xưởng khác có lắp thiết bị nâng hạ hoặc xếp hàng',
    Unit: '',
  },
  {
    HsCode: '84271000',
    Description: '- Xe tự hành chạy bằng mô tơ điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84272000',
    Description: '- Xe tự hành khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84279000',
    Description: '- Các loại xe khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8428',
    Description:
      'Máy nâng hạ, giữ, xếp hoặc dỡ hàng khác (ví dụ, thang máy (lift), thang cuốn, băng tải, thùng cáp treo)',
    Unit: '',
  },
  {
    HsCode: '842810',
    Description: '- Thang máy (lift) và tời nâng kiểu gầu nâng (trục tải thùng kíp):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Thang máy (lift):',
    Unit: '',
  },
  {
    HsCode: '84281031',
    Description: '- - - Để chở người',
    Unit: 'chiếc',
  },
  {
    HsCode: '84281039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84281040',
    Description: '- - Tời nâng kiểu gầu nâng (trục tải thùng kíp)',
    Unit: 'chiếc',
  },
  {
    HsCode: '842820',
    Description: '- Máy nâng hạ và băng tải dùng khí nén:',
    Unit: '',
  },
  {
    HsCode: '84282010',
    Description: '- - Loại sử dụng trong nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84282090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy nâng hạ và băng tải hoạt động liên tục khác, để vận tải hàng hóa hoặc vật liệu:',
    Unit: '',
  },
  {
    HsCode: '84283100',
    Description: '- - Thiết kế chuyên sử dụng dưới lòng đất ',
    Unit: 'chiếc',
  },
  {
    HsCode: '842832',
    Description: '- - Loại khác, dạng gàu:',
    Unit: '',
  },
  {
    HsCode: '84283210',
    Description: '- - - Loại sử dụng trong nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84283290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842833',
    Description: '- - Loại khác, dạng băng tải:',
    Unit: '',
  },
  {
    HsCode: '84283310',
    Description: '- - - Loại sử dụng trong nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84283390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '842839',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84283910',
    Description: '- - - Loại sử dụng trong nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84283990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84284000',
    Description: '- Thang cuốn và băng tải tự động dùng cho người đi bộ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84286000',
    Description:
      '- Thùng cáp treo, ghế treo, cơ cấu kéo người trượt tuyết lên cao dùng trong môn trượt tuyết; cơ cấu kéo dùng cho đường sắt leo núi',
    Unit: 'chiếc',
  },
  {
    HsCode: '84287000',
    Description: '- Rô bốt công nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '842890',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84289020',
    Description: '- - Máy tự động để dịch chuyển, xử lý và kẹp giữ tấm mạch in, tấm dây in hoặc mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84289030',
    Description:
      '- - Thiết bị đẩy xe goòng trong hầm mỏ, thanh ngang xe goòng hoặc đầu máy, thiết bị lật toa xe và các thiết bị giữ xe goòng chạy đường sắt tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '84289090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8429',
    Description:
      'Máy ủi đất lưỡi thẳng, máy ủi đất lưỡi nghiêng, máy san đất, máy cạp đất, máy xúc, máy đào đất, máy chuyển đất bằng gàu tự xúc, máy đầm và xe lu lăn đường, loại tự hành',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy ủi đất lưỡi thẳng và máy ủi đất lưỡi nghiêng:',
    Unit: '',
  },
  {
    HsCode: '84291100',
    Description: '- - Loại bánh xích',
    Unit: 'chiếc',
  },
  {
    HsCode: '84291900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84292000',
    Description: '- Máy san đất',
    Unit: 'chiếc',
  },
  {
    HsCode: '84293000',
    Description: '- Máy cạp đất',
    Unit: 'chiếc',
  },
  {
    HsCode: '842940',
    Description: '- Máy đầm và xe lu lăn đường:',
    Unit: '',
  },
  {
    HsCode: '84294030',
    Description: '- - Máy đầm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84294040',
    Description: '- - Xe lu rung, với lực rung của trống không quá 20 tấn tính theo khối lượng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84294050',
    Description: '- - Các loại xe lu rung lăn đường khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84294090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy xúc, máy đào đất và máy chuyển đất bằng gàu tự xúc:',
    Unit: '',
  },
  {
    HsCode: '84295100',
    Description: '- - Máy chuyển đất bằng gàu tự xúc lắp phía trước',
    Unit: 'chiếc',
  },
  {
    HsCode: '84295200',
    Description: '- - Máy có cơ cấu phần trên quay được 360o',
    Unit: 'chiếc',
  },
  {
    HsCode: '84295900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8430',
    Description:
      'Các máy ủi xúc dọn, cào, san, cạp, đào, đầm, nén, bóc tách hoặc khoan khác dùng trong công việc về đất, khoáng hoặc quặng; máy đóng cọc và nhổ cọc; máy xới tuyết và dọn tuyết',
    Unit: '',
  },
  {
    HsCode: '84301000',
    Description: '- Máy đóng cọc và nhổ cọc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84302000',
    Description: '- Máy xới và dọn tuyết',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy đào đường hầm và máy cắt vỉa than hoặc đá:',
    Unit: '',
  },
  {
    HsCode: '84303100',
    Description: '- - Loại tự hành',
    Unit: 'chiếc',
  },
  {
    HsCode: '84303900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy khoan hoặc máy đào sâu khác:',
    Unit: '',
  },
  {
    HsCode: '84304100',
    Description: '- - Loại tự hành',
    Unit: 'chiếc',
  },
  {
    HsCode: '843049',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84304910',
    Description: '- - - Bệ giàn khoan có các mô-đun tích hợp phù hợp sử dụng trong vận hành khoan (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84304990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84305000',
    Description: '- Máy khác, loại tự hành',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy khác, loại không tự hành:',
    Unit: '',
  },
  {
    HsCode: '84306100',
    Description: '- - Máy đầm hoặc máy nén',
    Unit: 'chiếc',
  },
  {
    HsCode: '84306900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8431',
    Description: 'Các bộ phận chỉ sử dụng hoặc chủ yếu sử dụng cho các loại máy thuộc các nhóm từ 84.25 đến 84.30',
    Unit: '',
  },
  {
    HsCode: '843110',
    Description: '- Của máy thuộc nhóm 84.25:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của máy hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84311013',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8425.11.00, 8425.31.00 hoặc 8425.49.10 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84311019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84311022',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8425.19.00, 8425.39.00, 8425.41.00, 8425.42.10 hoặc 8425.42.90',
    Unit: 'chiếc',
  },
  {
    HsCode: '84311029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '843120',
    Description: '- Của máy thuộc nhóm 84.27:',
    Unit: '',
  },
  {
    HsCode: '84312010',
    Description: '- - Thuộc phân nhóm 8427.10 hoặc 8427.20',
    Unit: 'chiếc',
  },
  {
    HsCode: '84312090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Của máy thuộc nhóm 84.28:',
    Unit: '',
  },
  {
    HsCode: '843131',
    Description: '- - Của thang máy (lift), tời nâng kiểu gầu nâng (trục tải thùng kíp) hoặc thang cuốn:',
    Unit: '',
  },
  {
    HsCode: '84313110',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8428.10.39 hoặc 8428.10.40',
    Unit: 'chiếc',
  },
  {
    HsCode: '84313120',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8428.10.31 hoặc 8428.40.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '843139',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84313910',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8428.20.10, 8428.32.10, 8428.33.10 hoặc 8428.39.10 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84313940',
    Description:
      '- - - Của máy tự động dùng để dịch chuyển, xử lý và kẹp giữ các tấm mạch in, tấm dây in hoặc mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84313950',
    Description: '- - - Loại khác, của hàng hóa thuộc phân nhóm 8428.90',
    Unit: 'chiếc',
  },
  {
    HsCode: '84313990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Của máy thuộc nhóm 84.26, 84.29 hoặc 84.30:',
    Unit: '',
  },
  {
    HsCode: '843141',
    Description: '- - Gầu xúc, xẻng xúc, gầu ngoạm và gầu kẹp:',
    Unit: '',
  },
  {
    HsCode: '84314110',
    Description: '- - - Dùng cho máy thuộc nhóm 84.26',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314200',
    Description: '- - Lưỡi của máy ủi đất lưỡi thẳng hoặc máy ủi đất lưỡi nghiêng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314300',
    Description: '- - Bộ phận của máy khoan hoặc máy đào sâu thuộc phân nhóm 8430.41 hoặc 8430.49',
    Unit: 'chiếc',
  },
  {
    HsCode: '843149',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84314910',
    Description: '- - - Bộ phận của máy thuộc nhóm 84.26',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314920',
    Description: '- - - Lưỡi cắt hoặc mũi lưỡi cắt dùng cho máy cào, máy san hoặc máy cạp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314940',
    Description: '- - - Lưỡi cắt hoặc mũi lưỡi cắt dùng cho máy ủi đất lưỡi thẳng hoặc máy ủi đất lưỡi nghiêng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314950',
    Description: '- - - Của xe lu lăn đường',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314960',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8430.20.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84314990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8432',
    Description:
      'Máy nông nghiệp, làm vườn hoặc lâm nghiệp dùng cho việc làm đất hoặc trồng trọt; máy cán cho bãi cỏ hoặc cho sân chơi thể thao',
    Unit: '',
  },
  {
    HsCode: '84321000',
    Description: '- Máy cày',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Máy bừa, máy cào, máy xới đất từ dưới lên (cultivators), máy làm cỏ và máy xới đất từ trên xuống (hoes):',
    Unit: '',
  },
  {
    HsCode: '84322100',
    Description: '- - Bừa đĩa',
    Unit: 'chiếc',
  },
  {
    HsCode: '84322900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy gieo hạt, máy trồng cây và máy cấy:',
    Unit: '',
  },
  {
    HsCode: '84323100',
    Description: '- - Máy gieo hạt, máy trồng cây và máy cấy trực tiếp không cần xới đất (no-till)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84323900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy rải phân hữu cơ và máy rắc phân bón:',
    Unit: '',
  },
  {
    HsCode: '84324100',
    Description: '- - Máy rải phân hữu cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84324200',
    Description: '- - Máy rắc phân bón',
    Unit: 'chiếc',
  },
  {
    HsCode: '843280',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84328010',
    Description: '- - Dùng trong nông nghiệp hoặc làm vườn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84328020',
    Description: '- - Máy cán cho bãi cỏ hoặc cho sân chơi thể thao',
    Unit: 'chiếc',
  },
  {
    HsCode: '84328090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '843290',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84329010',
    Description: '- - Của máy thuộc phân nhóm 8432.80.90',
    Unit: 'chiếc',
  },
  {
    HsCode: '84329020',
    Description: '- - Của máy cán cho bãi cỏ hoặc cho sân chơi thể thao',
    Unit: 'chiếc',
  },
  {
    HsCode: '84329090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8433',
    Description:
      'Máy thu hoạch hoặc máy đập, kể cả máy đóng bó, bánh (kiện) rơm hoặc cỏ khô; máy cắt cỏ tươi hoặc cỏ khô; máy làm sạch, phân loại hoặc lựa chọn trứng, hoa quả hoặc nông sản khác, trừ các loại máy thuộc nhóm 84.37',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy cắt cỏ dùng cho các bãi cỏ, công viên hoặc sân chơi thể thao:',
    Unit: '',
  },
  {
    HsCode: '84331100',
    Description: '- - Chạy bằng động cơ, với chi tiết cắt quay trên mặt phẳng ngang',
    Unit: 'chiếc',
  },
  {
    HsCode: '843319',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84331910',
    Description: '- - - Không dùng động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84331990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84332000',
    Description: '- Máy cắt cỏ khác, kể cả các thanh cắt lắp vào máy kéo',
    Unit: 'chiếc',
  },
  {
    HsCode: '84333000',
    Description: '- Máy dọn cỏ khô khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84334000',
    Description: '- Máy đóng bó, bánh (kiện) rơm hoặc cỏ khô, kể cả máy nâng (thu dọn) các kiện đã được đóng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy thu hoạch khác; máy đập:',
    Unit: '',
  },
  {
    HsCode: '84335100',
    Description: '- - Máy gặt đập liên hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84335200',
    Description: '- - Máy đập khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84335300',
    Description: '- - Máy thu hoạch sản phẩm củ hoặc rễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '843359',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84335920',
    Description: '- - - Máy hái bông (cotton)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84335990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '843360',
    Description: '- Máy làm sạch, phân loại hoặc chọn trứng, hoa quả hoặc nông sản khác:',
    Unit: '',
  },
  {
    HsCode: '84336010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84336020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '843390',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84339010',
    Description:
      '- - Bánh xe đẩy (castor), có đường kính (gồm cả lốp) trên 100 mm nhưng không quá 250 mm, với điều kiện là bánh xe hoặc lốp lắp vào đó có chiều rộng trên 30 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84339020',
    Description: '- - Loại khác, của hàng hóa thuộc phân nhóm 8433.11.00 hoặc 8433.19.90',
    Unit: 'chiếc',
  },
  {
    HsCode: '84339030',
    Description: '- - Loại khác, của hàng hóa thuộc phân nhóm 8433.19.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84339090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8434',
    Description: 'Máy vắt sữa và máy chế biến sữa',
    Unit: '',
  },
  {
    HsCode: '84341000',
    Description: '- Máy vắt sữa',
    Unit: 'chiếc',
  },
  {
    HsCode: '84342000',
    Description: '- Máy chế biến sữa',
    Unit: 'chiếc',
  },
  {
    HsCode: '84349000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8435',
    Description:
      'Máy ép, máy nghiền và các loại máy tương tự dùng trong sản xuất rượu vang, rượu táo, nước trái cây hoặc các loại đồ uống tương tự',
    Unit: '',
  },
  {
    HsCode: '843510',
    Description: '- Máy: ',
    Unit: '',
  },
  {
    HsCode: '84351010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84351020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '843590',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84359010',
    Description: '- - Của máy hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84359020',
    Description: '- - Của máy không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '8436',
    Description:
      'Các loại máy khác dùng trong nông nghiệp, làm vườn, lâm nghiệp, chăn nuôi gia cầm hoặc nuôi ong, kể cả máy ươm hạt giống có lắp thiết bị cơ khí hoặc thiết bị nhiệt; máy ấp trứng gia cầm và thiết bị sưởi ấm gia cầm mới nở',
    Unit: '',
  },
  {
    HsCode: '843610',
    Description: '- Máy chế biến thức ăn cho động vật:',
    Unit: '',
  },
  {
    HsCode: '84361010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84361020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy chăm sóc gia cầm; máy ấp trứng gia cầm và thiết bị sưởi ấm gia cầm mới nở:',
    Unit: '',
  },
  {
    HsCode: '843621',
    Description: '- - Máy ấp trứng gia cầm và thiết bị sưởi ấm gia cầm mới nở:',
    Unit: '',
  },
  {
    HsCode: '84362110',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84362120',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '843629',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84362910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84362920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '843680',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84368011',
    Description: '- - - Dùng trong nông nghiệp hoặc làm vườn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84368019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84368021',
    Description: '- - - Dùng trong nông nghiệp hoặc làm vườn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84368029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84369100',
    Description: '- - Của máy chăm sóc gia cầm hoặc máy ấp trứng gia cầm và thiết bị sưởi ấm gia cầm mới nở',
    Unit: 'chiếc',
  },
  {
    HsCode: '843699',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Của máy và thiết bị hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84369911',
    Description: '- - - - Dùng trong nông nghiệp hoặc làm vườn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84369919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Của máy và thiết bị không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84369921',
    Description: '- - - - Dùng trong nông nghiệp hoặc làm vườn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84369929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8437',
    Description:
      'Máy làm sạch, tuyển chọn hoặc phân loại hạt giống, hạt hoặc các loại rau đậu đã được làm khô; máy dùng trong công nghiệp xay sát hoặc dùng cho chế biến ngũ cốc hoặc rau đậu đã được làm khô, trừ các loại máy nông nghiệp',
    Unit: '',
  },
  {
    HsCode: '843710',
    Description: '- Máy làm sạch, tuyển chọn hoặc phân loại hạt giống, hạt hoặc các loại rau đậu đã được làm khô:',
    Unit: '',
  },
  {
    HsCode: '84371010',
    Description:
      '- - Dùng cho các loại hạt, hoạt động bằng điện; máy sàng sảy hạt và các loại máy làm sạch tương tự, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84371020',
    Description:
      '- - Dùng cho các loại hạt, không hoạt động bằng điện; máy sàng sảy hạt và các loại máy làm sạch tương tự, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84371030',
    Description: '- - Loại khác, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84371040',
    Description: '- - Loại khác, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '843780',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84378010',
    Description: '- - Máy bóc vỏ trấu và máy xát gạo hình côn, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84378020',
    Description: '- - Máy bóc vỏ trấu và máy xát gạo hình côn, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84378030',
    Description: '- - Máy nghiền cà phê và ngô dạng công nghiệp, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84378040',
    Description: '- - Máy nghiền cà phê và ngô dạng công nghiệp, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84378051',
    Description: '- - - Máy đánh bóng gạo, máy giần, sàng, rây, máy làm sạch cám và máy bóc vỏ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84378059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84378061',
    Description: '- - - Máy đánh bóng gạo, máy giần, sàng, rây, máy làm sạch cám và máy bóc vỏ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84378069',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '843790',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của máy hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84379011',
    Description: '- - - Của máy thuộc phân nhóm 8437.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84379019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84379021',
    Description: '- - - Của máy thuộc phân nhóm 8437.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84379029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8438',
    Description:
      'Máy chế biến công nghiệp hoặc sản xuất thực phẩm hoặc đồ uống, chưa được ghi hoặc chi tiết ở nơi khác trong Chương này, trừ các loại máy để chiết xuất hoặc chế biến dầu hoặc mỡ động vật hoặc dầu hoặc chất béo không bay hơi của thực vật hoặc vi sinh vật',
    Unit: '',
  },
  {
    HsCode: '84381000',
    Description: '- Máy làm bánh và máy để sản xuất mỳ macaroni, spaghetti hoặc các sản phẩm tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '843820',
    Description: '- Máy sản xuất mứt kẹo, ca cao hoặc sô cô la:',
    Unit: '',
  },
  {
    HsCode: '84382010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84382020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '843830',
    Description: '- Máy sản xuất đường:',
    Unit: '',
  },
  {
    HsCode: '84383010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84383020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84384000',
    Description: '- Máy sản xuất bia',
    Unit: 'chiếc',
  },
  {
    HsCode: '84385000',
    Description: '- Máy chế biến thịt gia súc hoặc gia cầm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84386000',
    Description: '- Máy chế biến hoa quả, quả hạch hoặc rau',
    Unit: 'chiếc',
  },
  {
    HsCode: '843880',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Máy xát vỏ cà phê:',
    Unit: '',
  },
  {
    HsCode: '84388011',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84388012',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Máy chế biến cá, động vật giáp xác hoặc động vật thân mềm:',
    Unit: '',
  },
  {
    HsCode: '84388021',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84388022',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84388091',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84388092',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '843890',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của máy hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84389011',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8438.30.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84389012',
    Description: '- - - Của máy xát vỏ cà phê',
    Unit: 'chiếc',
  },
  {
    HsCode: '84389019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84389021',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8438.30.20',
    Unit: 'chiếc',
  },
  {
    HsCode: '84389022',
    Description: '- - - Của máy xát vỏ cà phê',
    Unit: 'chiếc',
  },
  {
    HsCode: '84389029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8439',
    Description:
      'Máy chế biến bột giấy từ vật liệu sợi xenlulô hoặc máy dùng cho quá trình sản xuất hoặc hoàn thiện giấy hoặc bìa',
    Unit: '',
  },
  {
    HsCode: '84391000',
    Description: '- Máy chế biến bột giấy từ vật liệu sợi xenlulô',
    Unit: 'chiếc',
  },
  {
    HsCode: '84392000',
    Description: '- Máy dùng sản xuất giấy hoặc bìa',
    Unit: 'chiếc',
  },
  {
    HsCode: '84393000',
    Description: '- Máy dùng để hoàn thiện giấy hoặc bìa',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84399100',
    Description: '- - Của máy chế biến bột giấy từ vật liệu sợi xenlulô',
    Unit: 'chiếc',
  },
  {
    HsCode: '84399900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8440',
    Description: 'Máy đóng sách, kể cả máy khâu sách',
    Unit: '',
  },
  {
    HsCode: '844010',
    Description: '- Máy: ',
    Unit: '',
  },
  {
    HsCode: '84401010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84401020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84409000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8441',
    Description: 'Các máy khác dùng để sản xuất bột giấy, giấy hoặc bìa, kể cả máy cắt xén các loại',
    Unit: '',
  },
  {
    HsCode: '844110',
    Description: '- Máy cắt xén các loại:',
    Unit: '',
  },
  {
    HsCode: '84411010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84411020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84412000',
    Description: '- Máy làm túi, bao hoặc phong bì',
    Unit: 'chiếc',
  },
  {
    HsCode: '84413000',
    Description:
      '- Máy làm thùng bìa, hộp, hòm, thùng hình ống, hình trống hoặc đồ chứa tương tự, trừ loại máy sử dụng phương pháp đúc khuôn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84414000',
    Description: '- Máy làm các sản phẩm từ bột giấy, giấy hoặc bìa bằng phương pháp đúc khuôn',
    Unit: 'chiếc',
  },
  {
    HsCode: '844180',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84418010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84418020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84419000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8442',
    Description:
      'Máy, thiết bị và dụng cụ (trừ loại máy thuộc các nhóm từ 84.56 đến 84.65) dùng để đúc chữ hoặc chế bản, làm khuôn in (bát chữ), ống in và các bộ phận in ấn khác; khuôn in (bát chữ), ống in và các bộ phận in khác; khuôn in, ống in và đá in ly tô, được chuẩn bị cho các mục đích in (ví dụ, đã được làm phẳng, nổi vân hạt hoặc đánh bóng)',
    Unit: '',
  },
  {
    HsCode: '84423000',
    Description: '- Máy, thiết bị và dụng cụ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84424000',
    Description: '- Bộ phận của các máy, thiết bị hoặc dụng cụ kể trên',
    Unit: 'chiếc',
  },
  {
    HsCode: '84425000',
    Description:
      '- Khuôn in (bát chữ), ống in và các bộ phận in khác; khuôn in, ống in và đá in ly tô, được chuẩn bị cho các mục đích in (ví dụ, đã được làm phẳng, nổi vân hạt hoặc đánh bóng) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '8443',
    Description:
      'Máy in sử dụng các bộ phận in như khuôn in (bát chữ), ống in và các bộ phận in khác của nhóm 84.42; máy in khác, máy copy (copying machines) và máy fax, có hoặc không kết hợp với nhau; bộ phận và các phụ kiện của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Máy in sử dụng các bộ phận in như khuôn in (bát chữ), ống in và các bộ phận in khác thuộc nhóm 84.42:',
    Unit: '',
  },
  {
    HsCode: '84431100',
    Description: '- - Máy in offset, in cuộn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84431200',
    Description:
      '- - Máy in offset, in theo tờ, loại sử dụng trong văn phòng (sử dụng giấy với kích thước giấy ở dạng không gấp một chiều không quá 22 cm và chiều kia không quá 36 cm) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84431300',
    Description: '- - Máy in offset khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84431400',
    Description: '- - Máy in letterpress, in cuộn, trừ loại máy in flexo(1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84431500',
    Description: '- - Máy in letterpress, trừ loại in cuộn, trừ loại máy in flexo(1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84431600',
    Description: '- - Máy in flexo(1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84431700',
    Description: '- - Máy in ống đồng(1) (*)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84431900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy in khác, máy copy và máy fax, có hoặc không kết hợp với nhau:',
    Unit: '',
  },
  {
    HsCode: '844331',
    Description:
      '- - Máy kết hợp hai hoặc nhiều chức năng in, copy hoặc fax, có khả năng kết nối với máy xử lý dữ liệu tự động hoặc kết nối mạng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Máy in-copy, in bằng công nghệ in phun:',
    Unit: '',
  },
  {
    HsCode: '84433111',
    Description: '- - - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Máy in-copy, in bằng công nghệ laser:',
    Unit: '',
  },
  {
    HsCode: '84433121',
    Description: '- - - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Máy in-copy-fax kết hợp:',
    Unit: '',
  },
  {
    HsCode: '84433131',
    Description: '- - - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433139',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84433191',
    Description: '- - - - Máy in-copy-scan-fax kết hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '844332',
    Description: '- - Loại khác, có khả năng kết nối với máy xử lý dữ liệu tự động hoặc kết nối mạng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Máy in kim:',
    Unit: '',
  },
  {
    HsCode: '84433211',
    Description: '- - - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Máy in phun:',
    Unit: '',
  },
  {
    HsCode: '84433221',
    Description: '- - - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433229',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Máy in laser:',
    Unit: '',
  },
  {
    HsCode: '84433231',
    Description: '- - - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433239',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433240',
    Description: '- - - Máy fax',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433250',
    Description: '- - - Máy in kiểu lưới dùng để sản xuất các tấm mạch in hoặc tấm dây in',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433260',
    Description: '- - - Máy vẽ (Plotters)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '844339',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84433910',
    Description:
      '- - - Máy photocopy tĩnh điện, hoạt động bằng cách tái tạo hình ảnh gốc trực tiếp lên bản sao (quá trình tái tạo trực tiếp)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433920',
    Description:
      '- - - Máy photocopy tĩnh điện, hoạt động bằng cách tái tạo hình ảnh gốc lên bản sao thông qua bước trung gian (quá trình tái tạo gián tiếp)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433930',
    Description: '- - - Máy photocopy khác kết hợp hệ thống quang học',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433940',
    Description: '- - - Máy in phun',
    Unit: 'chiếc',
  },
  {
    HsCode: '84433990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '84439100',
    Description:
      '- - Bộ phận và phụ kiện của máy in sử dụng các bộ phận in như khuôn in (bát chữ), ống in và các bộ phận in khác của nhóm 84.42',
    Unit: 'chiếc',
  },
  {
    HsCode: '844399',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84439910',
    Description: '- - - Của máy in kiểu lưới dùng để sản xuất tấm mạch in hoặc tấm dây in',
    Unit: 'chiếc',
  },
  {
    HsCode: '84439920',
    Description: '- - - Hộp mực in đã có mực in',
    Unit: 'chiếc',
  },
  {
    HsCode: '84439930',
    Description: '- - - Bộ phận cung cấp giấy; bộ phận sắp xếp giấy',
    Unit: 'chiếc',
  },
  {
    HsCode: '84439990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8444',
    Description: 'Máy ép đùn, kéo chuỗi, tạo dún hoặc máy cắt vật liệu dệt nhân tạo',
    Unit: '',
  },
  {
    HsCode: '84440010',
    Description: '- Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84440020',
    Description: '- Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '8445',
    Description:
      'Máy chuẩn bị xơ sợi dệt; máy kéo sợi, máy đậu sợi hoặc máy xe sợi và các loại máy khác dùng cho sản xuất sợi dệt; máy guồng hoặc máy đánh ống sợi dệt (kể cả máy đánh suốt sợi ngang) và các loại máy chuẩn bị sợi dệt dùng cho máy thuộc nhóm 84.46 hoặc 84.47',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy chuẩn bị xơ sợi dệt:',
    Unit: '',
  },
  {
    HsCode: '844511',
    Description: '- - Máy chải thô:',
    Unit: '',
  },
  {
    HsCode: '84451110',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84451120',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84451200',
    Description: '- - Máy chải kỹ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84451300',
    Description: '- - Máy ghép cúi hoặc máy sợi thô',
    Unit: 'chiếc',
  },
  {
    HsCode: '844519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84451930',
    Description: '- - - Máy tách hạt bông',
    Unit: 'chiếc',
  },
  {
    HsCode: '84451940',
    Description: '- - - Loại khác, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84451950',
    Description: '- - - Loại khác, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '844520',
    Description: '- Máy kéo sợi:',
    Unit: '',
  },
  {
    HsCode: '84452010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84452020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '844530',
    Description: '- Máy đậu hoặc máy xe sợi:',
    Unit: '',
  },
  {
    HsCode: '84453010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84453020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '844540',
    Description: '- Máy đánh ống (kể cả máy đánh suốt sợi ngang) hoặc máy guồng sợi:',
    Unit: '',
  },
  {
    HsCode: '84454010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84454020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '844590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84459010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84459020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '8446',
    Description: 'Máy dệt',
    Unit: '',
  },
  {
    HsCode: '844610',
    Description: '- Cho vải dệt có khổ rộng không quá 30 cm:',
    Unit: '',
  },
  {
    HsCode: '84461010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84461020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Cho vải dệt có khổ rộng trên 30 cm, loại dệt thoi:',
    Unit: '',
  },
  {
    HsCode: '84462100',
    Description: '- - Máy dệt khung cửi có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84462900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84463000',
    Description: '- Cho vải dệt có khổ rộng trên 30 cm, loại dệt không thoi',
    Unit: 'chiếc',
  },
  {
    HsCode: '8447',
    Description:
      'Máy dệt kim, máy khâu đính và máy tạo sợi quấn, sản xuất vải tuyn, ren, thêu, trang trí, dây tết hoặc lưới và máy tạo búi',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy dệt kim tròn:',
    Unit: '',
  },
  {
    HsCode: '84471100',
    Description: '- - Có đường kính trục cuốn không quá 165 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84471200',
    Description: '- - Có đường kính trục cuốn trên 165 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '844720',
    Description: '- Máy dệt kim phẳng; máy khâu đính:',
    Unit: '',
  },
  {
    HsCode: '84472010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84472020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '844790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84479010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84479020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '8448',
    Description:
      'Máy phụ trợ dùng với các máy thuộc nhóm 84.44, 84.45, 84.46 hoặc 84.47 (ví dụ, đầu tay kéo, đầu Jacquard, cơ cấu tự dừng, cơ cấu thay thoi); các bộ phận và phụ kiện phù hợp để chỉ dùng hoặc chủ yếu dùng cho các máy thuộc nhóm này hoặc của nhóm 84.44, 84.45, 84.46 hoặc 84.47 (ví dụ, cọc sợi và gàng, kim chải, lược chải kỹ, phễu đùn sợi, thoi, go và khung go, kim dệt)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy phụ trợ dùng cho các loại máy thuộc nhóm 84.44, 84.45, 84.46 hoặc 84.47:',
    Unit: '',
  },
  {
    HsCode: '844811',
    Description:
      '- - Đầu tay kéo và đầu Jacquard; máy thu nhỏ bìa, máy sao bìa, máy đục lỗ hoặc các máy ghép bìa được sử dụng cho mục đích trên:',
    Unit: '',
  },
  {
    HsCode: '84481110',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84481120',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '844819',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84481910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84481920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84482000',
    Description: '- Bộ phận và phụ kiện của máy thuộc nhóm 84.44 hoặc các máy phụ trợ của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện của máy thuộc nhóm 84.45 hoặc các máy phụ trợ của chúng:',
    Unit: '',
  },
  {
    HsCode: '84483100',
    Description: '- - Kim chải',
    Unit: 'chiếc',
  },
  {
    HsCode: '84483200',
    Description: '- - Của máy chuẩn bị xơ sợi dệt, trừ kim chải',
    Unit: 'chiếc',
  },
  {
    HsCode: '84483300',
    Description: '- - Cọc sợi, gàng, nồi và khuyên',
    Unit: 'chiếc',
  },
  {
    HsCode: '84483900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện của máy dệt (khung cửi) hoặc máy phụ trợ của chúng:',
    Unit: '',
  },
  {
    HsCode: '84484200',
    Description: '- - Lược dệt, go và khung go',
    Unit: 'chiếc',
  },
  {
    HsCode: '844849',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Thoi:',
    Unit: '',
  },
  {
    HsCode: '84484911',
    Description: '- - - - Dùng cho máy hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84484912',
    Description: '- - - - Dùng cho máy không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84484990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện của máy thuộc nhóm 84.47 hoặc máy phụ trợ của chúng:',
    Unit: '',
  },
  {
    HsCode: '84485100',
    Description: '- - Platin tạo vòng (sinker), kim dệt và các chi tiết tạo vòng khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84485900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84490000',
    Description:
      'Máy dùng để sản xuất hoặc hoàn tất phớt hoặc các sản phẩm không dệt dạng mảnh hoặc dạng hình, kể cả máy làm mũ phớt; cốt làm mũ',
    Unit: 'chiếc',
  },
  {
    HsCode: '8450',
    Description: 'Máy giặt gia đình hoặc trong hiệu giặt, kể cả máy giặt có chức năng sấy khô',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy giặt, có sức chứa không quá 10 kg vải khô một lần giặt:',
    Unit: '',
  },
  {
    HsCode: '845011',
    Description: '- - Máy tự động hoàn toàn:',
    Unit: '',
  },
  {
    HsCode: '84501110',
    Description: '- - - Loại có sức chứa không quá 6 kg vải khô một lần giặt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84501190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '845012',
    Description: '- - Máy giặt khác, có chức năng sấy ly tâm:',
    Unit: '',
  },
  {
    HsCode: '84501210',
    Description: '- - - Loại có sức chứa không quá 6 kg vải khô một lần giặt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84501290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '845019',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84501911',
    Description: '- - - - Loại có sức chứa không quá 6 kg vải khô một lần giặt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84501919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84501991',
    Description: '- - - - Loại có sức chứa không quá 6 kg vải khô một lần giặt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84501999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84502000',
    Description: '- Máy giặt, có sức chứa trên 10 kg vải khô một lần giặt',
    Unit: 'chiếc',
  },
  {
    HsCode: '845090',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84509010',
    Description: '- - Của máy thuộc phân nhóm 8450.20.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84509020',
    Description: '- - Của máy thuộc phân nhóm 8450.11, 8450.12 hoặc 8450.19',
    Unit: 'chiếc',
  },
  {
    HsCode: '8451',
    Description:
      'Các loại máy (trừ máy thuộc nhóm 84.50) dùng để giặt, làm sạch, vắt, sấy, là hơi, ép (kể cả ép mếch), tẩy trắng, nhuộm, hồ bóng, hoàn tất, tráng phủ hoặc ngâm tẩm sợi, vải dệt hoặc hàng dệt đã hoàn thiện và các máy dùng để phết hồ lên lớp vải đế hoặc lớp vải nền khác, dùng trong sản xuất hàng trải sàn như vải sơn lót sàn; máy để quấn, tở (xả), gấp, cắt hoặc cắt hình răng cưa vải dệt',
    Unit: '',
  },
  {
    HsCode: '84511000',
    Description: '- Máy giặt khô',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy sấy:',
    Unit: '',
  },
  {
    HsCode: '84512100',
    Description: '- - Sức chứa không quá 10 kg vải khô mỗi lần sấy',
    Unit: 'chiếc',
  },
  {
    HsCode: '84512900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '845130',
    Description: '- Máy là và là hơi ép (kể cả ép mếch):',
    Unit: '',
  },
  {
    HsCode: '84513010',
    Description: '- - Máy là trục đơn, loại gia dụng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84513090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84514000',
    Description: '- Máy giặt, tẩy trắng hoặc nhuộm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84515000',
    Description: '- Máy để quấn, tở (xả), gấp, cắt hoặc cắt hình răng cưa vải dệt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84518000',
    Description: '- Máy khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '845190',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84519010',
    Description: '- - Của máy có sức chứa không quá 10 kg vải khô mỗi lượt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84519090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8452',
    Description:
      'Máy khâu, trừ các loại máy khâu sách thuộc nhóm 84.40; bàn, tủ, chân máy và nắp thiết kế chuyên dùng cho các loại máy khâu; kim máy khâu',
    Unit: '',
  },
  {
    HsCode: '84521000',
    Description: '- Máy khâu dùng cho gia đình',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy khâu khác:',
    Unit: '',
  },
  {
    HsCode: '84522100',
    Description: '- - Loại tự động',
    Unit: 'chiếc',
  },
  {
    HsCode: '84522900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84523000',
    Description: '- Kim máy khâu',
    Unit: 'chiếc',
  },
  {
    HsCode: '845290',
    Description: '- Bàn, tủ, chân máy và nắp cho máy khâu và các bộ phận của chúng; bộ phận khác của máy khâu:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của máy thuộc phân nhóm 8452.10.00:',
    Unit: '',
  },
  {
    HsCode: '84529011',
    Description:
      '- - - Thân trên và thân dưới máy; đế đỡ có hoặc không có khung; bánh đà; bộ phận che chắn dây đai; bàn đạp các loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '84529012',
    Description: '- - - Bàn, tủ, chân máy và nắp và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84529019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84529091',
    Description:
      '- - - Thân trên và thân dưới máy; đế đỡ có hoặc không có khung; bánh đà; bộ phận che chắn dây đai; bàn đạp các loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '84529092',
    Description: '- - - Bàn, tủ, chân máy và nắp và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84529099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8453',
    Description:
      'Máy dùng để sơ chế, thuộc da hoặc chế biến da sống hoặc da thuộc hoặc máy để sản xuất hoặc sửa chữa giày dép hoặc các sản phẩm khác từ da sống hoặc da thuộc, trừ các loại máy khâu',
    Unit: '',
  },
  {
    HsCode: '845310',
    Description: '- Máy dùng để sơ chế, thuộc da hoặc chế biến da sống hoặc da thuộc:',
    Unit: '',
  },
  {
    HsCode: '84531010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84531020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '845320',
    Description: '- Máy để sản xuất hoặc sửa chữa giày dép:',
    Unit: '',
  },
  {
    HsCode: '84532010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84532020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '845380',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84538010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84538020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84539000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8454',
    Description: 'Lò thổi, nồi rót, khuôn đúc thỏi và máy đúc, dùng trong luyện kim hoặc đúc kim loại',
    Unit: '',
  },
  {
    HsCode: '84541000',
    Description: '- Lò thổi',
    Unit: 'chiếc',
  },
  {
    HsCode: '84542000',
    Description: '- Khuôn đúc thỏi và nồi rót',
    Unit: 'chiếc',
  },
  {
    HsCode: '84543000',
    Description: '- Máy đúc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84549000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8455',
    Description: 'Máy cán kim loại và trục cán của nó',
    Unit: '',
  },
  {
    HsCode: '84551000',
    Description: '- Máy cán ống',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy cán khác:',
    Unit: '',
  },
  {
    HsCode: '84552100',
    Description: '- - Máy cán nóng hoặc máy cán nóng và nguội kết hợp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84552200',
    Description: '- - Máy cán nguội',
    Unit: 'chiếc',
  },
  {
    HsCode: '84553000',
    Description: '- Trục cán dùng cho máy cán',
    Unit: 'chiếc',
  },
  {
    HsCode: '84559000',
    Description: '- Bộ phận khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8456',
    Description:
      'Máy công cụ để gia công mọi loại vật liệu bằng cách bóc tách vật liệu, bằng các quy trình sử dụng tia laser hoặc tia sáng khác hoặc chùm phô-tông, siêu âm, phóng điện, điện hóa, chùm tia điện tử, chùm tia i-on hoặc quá trình xử lý plasma hồ quang; máy cắt bằng tia nước',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Hoạt động bằng tia laser hoặc tia sáng khác hoặc chùm phô-tông:',
    Unit: '',
  },
  {
    HsCode: '845611',
    Description: '- - Hoạt động bằng tia laser:',
    Unit: '',
  },
  {
    HsCode: '84561110',
    Description:
      '- - - Loại chỉ sử dụng hoặc chủ yếu sử dụng để sản xuất mạch in, mạch in đã lắp ráp, bộ phận của nhóm 85.17, hoặc bộ phận của máy xử lý dữ liệu tự động ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84561190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845612',
    Description: '- - Hoạt động bằng tia sáng khác hoặc chùm phô-tông:',
    Unit: '',
  },
  {
    HsCode: '84561210',
    Description:
      '- - - Loại chỉ sử dụng hoặc chủ yếu sử dụng để sản xuất mạch in, mạch in đã lắp ráp, bộ phận của nhóm 85.17, hoặc bộ phận của máy xử lý dữ liệu tự động ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84561290',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84562000',
    Description: '- Hoạt động bằng phương pháp siêu âm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84563000',
    Description: '- Hoạt động bằng phương pháp phóng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845640',
    Description: '- Hoạt động bằng quá trình xử lý plasma hồ quang:',
    Unit: '',
  },
  {
    HsCode: '84564010',
    Description:
      '- - Máy công cụ, điều khiển số, để gia công vật liệu bằng phương pháp bóc tách vật liệu, bằng quá trình xử lý plasma hồ quang, để sản xuất các tấm mạch in hoặc tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84564020',
    Description:
      '- - Máy làm sạch bằng plasma sử dụng để loại bỏ các chất ô nhiễm hữu cơ khỏi các mẫu kính hiển vi điện tử và giá đỡ mẫu',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84564090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84565000',
    Description: '- Máy cắt bằng tia nước',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84569020',
    Description:
      '- - Thiết bị gia công ướt ứng dụng công nghệ nhúng dung dịch điện hóa, dùng để tách vật liệu trên các tấm mạch in hoặc tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84569090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8457',
    Description:
      'Trung tâm gia công, máy kết cấu nguyên khối (một vị trí gia công) và máy gia công chuyển dịch đa vị trí để gia công kim loại',
    Unit: '',
  },
  {
    HsCode: '845710',
    Description: '- Trung tâm gia công:',
    Unit: '',
  },
  {
    HsCode: '84571010',
    Description: '- - Của loại có công suất trục (spindle power) không quá 4 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84571090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84572000',
    Description: '- Máy kết cấu nguyên khối (một vị trí gia công)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84573000',
    Description: '- Máy gia công chuyển dịch đa vị trí',
    Unit: 'chiếc',
  },
  {
    HsCode: '8458',
    Description: 'Máy tiện (kể cả trung tâm gia công tiện) để bóc tách kim loại',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy tiện ngang:',
    Unit: '',
  },
  {
    HsCode: '845811',
    Description: '- - Điều khiển số:',
    Unit: '',
  },
  {
    HsCode: '84581110',
    Description: '- - - Của loại có công suất trục (spindle power) không quá 4 kW',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84581190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845819',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84581910',
    Description: '- - - Có bán kính gia công tiện không quá 300 mm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84581990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy tiện khác:',
    Unit: '',
  },
  {
    HsCode: '84589100',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845899',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84589910',
    Description: '- - - Có bán kính gia công tiện không quá 300 mm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84589990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8459',
    Description:
      'Máy công cụ (kể cả đầu gia công tổ hợp có thể di chuyển được) dùng để khoan, doa, phay, ren hoặc ta rô bằng phương pháp bóc tách kim loại, trừ các loại máy tiện (kể cả trung tâm gia công tiện) thuộc nhóm 84.58',
    Unit: '',
  },
  {
    HsCode: '84591000',
    Description: '- Đầu gia công tổ hợp có thể di chuyển được',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy khoan khác:',
    Unit: '',
  },
  {
    HsCode: '84592100',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845929',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84592910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84592920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy doa-phay khác:',
    Unit: '',
  },
  {
    HsCode: '84593100',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845939',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84593910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84593920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy doa khác:',
    Unit: '',
  },
  {
    HsCode: '84594100',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845949',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84594910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84594920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy phay, kiểu công xôn:',
    Unit: '',
  },
  {
    HsCode: '84595100',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845959',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84595910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84595920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy phay khác:',
    Unit: '',
  },
  {
    HsCode: '84596100',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845969',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84596910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84596920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '845970',
    Description: '- Máy ren hoặc máy ta rô khác:',
    Unit: '',
  },
  {
    HsCode: '84597010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84597020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8460',
    Description:
      'Máy công cụ dùng để mài bavia, mài sắc, mài nhẵn, mài khôn, mài rà, đánh bóng hoặc bằng cách khác để gia công hoàn thiện kim loại hoặc gốm kim loại bằng các loại đá mài, vật liệu mài hoặc các chất đánh bóng, trừ các loại máy cắt răng, mài răng hoặc gia công hoàn thiện bánh răng thuộc nhóm 84.61',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy mài phẳng:',
    Unit: '',
  },
  {
    HsCode: '84601200',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84601900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy mài khác:',
    Unit: '',
  },
  {
    HsCode: '84602200',
    Description: '- - Máy mài không tâm, loại điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84602300',
    Description: '- - Máy mài trụ khác, loại điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84602400',
    Description: '- - Loại khác, điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846029',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84602910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84602920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy mài sắc (mài dụng cụ làm việc hoặc lưỡi cắt):',
    Unit: '',
  },
  {
    HsCode: '846031',
    Description: '- - Điều khiển số:',
    Unit: '',
  },
  {
    HsCode: '84603110',
    Description:
      '- - - Máy công cụ, điều khiển số, có bộ phận đồ gá kẹp mẫu gia công hình ống lắp cố định và có công suất không quá 0,74 kW, để mài sắc các mũi khoan bằng vật liệu carbua với đường kính chuôi không quá 3,175 mm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84603190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846039',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84603910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84603920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84604000',
    Description: '- Máy mài khôn hoặc máy mài rà',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84609010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84609020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8461',
    Description:
      'Máy bào, máy bào ngang, máy xọc, máy chuốt, máy cắt bánh răng, mài hoặc máy gia công răng lần cuối, máy cưa, máy cắt đứt và các loại máy công cụ khác gia công bằng cách bóc tách kim loại hoặc gốm kim loại, chưa được ghi hoặc chi tiết ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '84612000',
    Description: '- Máy bào ngang hoặc máy xọc',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84613000',
    Description: '- Máy chuốt',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84614000',
    Description: '- Máy cắt bánh răng, mài hoặc gia công răng lần cuối',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84615000',
    Description: '- Máy cưa hoặc máy cắt đứt',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84619020',
    Description: '- - Máy bào',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84619090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8462',
    Description:
      'Máy công cụ (kể cả máy ép) dùng để gia công kim loại bằng cách rèn, gò hoặc dập khuôn (trừ máy cán kim loại); máy công cụ (kể cả máy ép, dây chuyền xẻ cuộn và dây chuyền cắt xén thành đoạn) để gia công kim loại bằng cách uốn, gấp, kéo thẳng, dát phẳng, cắt xén, đột dập, cắt rãnh hoặc cắt dập liên tục (trừ các loại máy kéo kim loại); máy ép để gia công kim loại hoặc carbua kim loại chưa được chi tiết ở trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy tạo hình nóng để rèn, dập khuôn (kể cả máy ép) và búa máy nóng:',
    Unit: '',
  },
  {
    HsCode: '84621100',
    Description: '- - Máy rèn khuôn kín ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84621900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy uốn, gấp, kéo thẳng hoặc dát phẳng (kể cả máy chấn) cho các sản phẩm phẳng:',
    Unit: '',
  },
  {
    HsCode: '846222',
    Description: '- - Máy định hình (Profile forming machines):',
    Unit: '',
  },
  {
    HsCode: '84622210',
    Description: '- - - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84622290',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84622300',
    Description: '- - Máy chấn điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84622400',
    Description: '- - Máy uốn bảng điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84622500',
    Description: '- - Máy uốn định hình lăn điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84622600',
    Description: '- - Các máy uốn, gấp, kéo thẳng hoặc dát phẳng điều khiển số khác ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84622900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description:
      '- Dây chuyền xẻ cuộn, dây chuyền cắt xén thành đoạn và các máy cắt xén khác (trừ máy ép) dùng cho các sản phẩm phẳng, trừ loại máy cắt xén và đột dập kết hợp: ',
    Unit: '',
  },
  {
    HsCode: '846232',
    Description: '- - Dây chuyền xẻ cuộn, dây chuyền cắt xén thành đoạn:',
    Unit: '',
  },
  {
    HsCode: '84623210',
    Description: '- - - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84623290',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84623300',
    Description: '- - Máy cắt xén điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84623900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description:
      '- Máy đột dập, máy cắt rãnh theo hình hoặc máy cắt dập liên tục (trừ máy ép) dùng cho các sản phẩm phẳng kể cả loại máy cắt xén và đột dập kết hợp: ',
    Unit: '',
  },
  {
    HsCode: '84624200',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84624900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy gia công ống, ống dẫn, dạng hình rỗng và dạng thanh (trừ máy ép):',
    Unit: '',
  },
  {
    HsCode: '84625100',
    Description: '- - Điều khiển số',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84625900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy gia công ép nguội kim loại: ',
    Unit: '',
  },
  {
    HsCode: '846261',
    Description: '- - Máy ép thuỷ lực: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Điều khiển số:',
    Unit: '',
  },
  {
    HsCode: '84626111',
    Description: '- - - - Máy rèn khuôn hoặc máy dập khuôn ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84626119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84626191',
    Description: '- - - - Máy rèn khuôn hoặc máy dập khuôn ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84626199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846262',
    Description: '- - Máy ép cơ khí: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Điều khiển số:',
    Unit: '',
  },
  {
    HsCode: '84626211',
    Description: '- - - - Máy rèn khuôn hoặc máy dập khuôn ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84626219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84626291',
    Description: '- - - - Máy rèn khuôn hoặc máy dập khuôn ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84626299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846263',
    Description: '- - Máy ép Servo: ',
    Unit: '',
  },
  {
    HsCode: '84626310',
    Description: '- - - Máy rèn khuôn hoặc máy dập khuôn ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84626390',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846269',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84626910',
    Description: '- - - Máy rèn khuôn hoặc máy dập khuôn ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84626990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84629010',
    Description: '- - Máy rèn khuôn hoặc máy dập khuôn ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84629090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8463',
    Description: 'Máy công cụ khác để gia công kim loại hoặc gốm kim loại, không cần bóc tách vật liệu',
    Unit: '',
  },
  {
    HsCode: '846310',
    Description: '- Máy kéo thanh, ống, hình, dây hoặc loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '84631010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84631020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846320',
    Description: '- Máy lăn ren:',
    Unit: '',
  },
  {
    HsCode: '84632010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84632020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846330',
    Description: '- Máy gia công dây:',
    Unit: '',
  },
  {
    HsCode: '84633010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84633020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84639010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84639020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8464',
    Description:
      'Máy công cụ để gia công đá, gốm, bê tông, xi măng - amiăng hoặc các loại khoáng vật tương tự hoặc máy dùng để gia công nguội thủy tinh',
    Unit: '',
  },
  {
    HsCode: '846410',
    Description: '- Máy cưa:',
    Unit: '',
  },
  {
    HsCode: '84641010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84641020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846420',
    Description: '- Máy mài hoặc máy đánh bóng:',
    Unit: '',
  },
  {
    HsCode: '84642010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84642020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84649010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84649020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8465',
    Description:
      'Máy công cụ (kể cả máy đóng đinh, đóng ghim, dán hoặc lắp ráp bằng cách khác) dùng để gia công gỗ, lie, xương, cao su cứng, plastic cứng hoặc các vật liệu cứng tương tự',
    Unit: '',
  },
  {
    HsCode: '84651000',
    Description:
      '- Máy có thể thực hiện các nguyên công gia công cơ khác nhau mà không cần thay dụng cụ giữa các nguyên công',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84652000',
    Description: '- Trung tâm gia công ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '846591',
    Description: '- - Máy cưa:',
    Unit: '',
  },
  {
    HsCode: '84659110',
    Description:
      '- - - Để khắc vạch lên các tấm mạch in hoặc tấm dây in hoặc tấm nền của tấm mạch in hoặc tấm nền của tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846592',
    Description: '- - Máy bào, máy phay hoặc máy tạo khuôn (bằng phương pháp cắt):',
    Unit: '',
  },
  {
    HsCode: '84659210',
    Description:
      '- - - Để khắc vạch lên các tấm mạch in hoặc tấm dây in hoặc tấm nền của tấm mạch in hoặc tấm nền của tấm dây in, có thể lắp vừa được mũi khắc có đường kính chuôi không quá 3,175 mm, dùng để khắc vạch lên các tấm mạch in hoặc tấm dây in hoặc tấm nền của tấm mạch in hoặc tấm nền của tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659290',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846593',
    Description: '- - Máy mài, máy chà nhám hoặc máy đánh bóng:',
    Unit: '',
  },
  {
    HsCode: '84659310',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659320',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659400',
    Description: '- - Máy uốn hoặc máy lắp ráp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846595',
    Description: '- - Máy khoan hoặc đục mộng:',
    Unit: '',
  },
  {
    HsCode: '84659510',
    Description:
      '- - - Máy khoan để sản xuất các tấm mạch in hoặc tấm dây in, có tốc độ quay trên 50.000 vòng/phút và có thể lắp vừa được mũi khoan có đường kính chuôi không quá 3,175 mm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659590',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659600',
    Description: '- - Máy xẻ, lạng hoặc máy bóc tách',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '846599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84659910',
    Description: '- - - Máy tiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659950',
    Description:
      '- - - Máy để đẽo bavia bề mặt của tấm mạch in hoặc tấm dây in trong quá trình sản xuất; máy để khắc vạch lên tấm mạch in hoặc tấm dây in hoặc tấm nền của tấm mạch in hoặc tấm nền của tấm dây in; máy ép lớp mỏng để sản xuất tấm mạch in hoặc tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659960',
    Description: '- - - Loại khác, hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84659990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8466',
    Description:
      'Bộ phận và phụ kiện chỉ dùng hoặc chủ yếu dùng với các máy thuộc các nhóm từ 84.56 đến 84.65, kể cả bộ phận kẹp sản phẩm hoặc kẹp dụng cụ, đầu cắt ren tự mở, đầu chia độ và những bộ phận phụ trợ chuyên dùng khác dùng cho các máy này; bộ phận kẹp dụng cụ dùng cho mọi loại dụng cụ cầm tay',
    Unit: '',
  },
  {
    HsCode: '846610',
    Description: '- Bộ phận kẹp dụng cụ và đầu cắt ren tự mở:',
    Unit: '',
  },
  {
    HsCode: '84661010',
    Description:
      '- - Dùng cho máy thuộc phân nhóm 8456.40.10, 8456.90.20, 8460.31.10, 8465.91.10, 8465.92.10, 8465.95.10 hoặc 8465.99.50',
    Unit: 'chiếc',
  },
  {
    HsCode: '84661090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '846620',
    Description: '- Bộ phận kẹp sản phẩm:',
    Unit: '',
  },
  {
    HsCode: '84662010',
    Description:
      '- - Dùng cho máy thuộc phân nhóm 8456.40.10, 8456.90.20, 8460.31.10, 8465.91.10, 8465.92.10, 8465.95.10 hoặc 8465.99.50',
    Unit: 'chiếc',
  },
  {
    HsCode: '84662090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84663000',
    Description: '- Đầu chia độ và những bộ phận phụ trợ chuyên dùng khác dùng cho máy',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84669100',
    Description: '- - Dùng cho máy thuộc nhóm 84.64',
    Unit: 'chiếc',
  },
  {
    HsCode: '84669200',
    Description: '- - Dùng cho máy thuộc nhóm 84.65',
    Unit: 'chiếc',
  },
  {
    HsCode: '846693',
    Description: '- - Dùng cho máy thuộc các nhóm từ 84.56 đến 84.61:',
    Unit: '',
  },
  {
    HsCode: '84669330',
    Description:
      '- - - Dùng cho máy thuộc các phân nhóm 8456.11.10, 8456.12.10, 8456.20.00, hoặc 8456.30.00, loại chỉ sử dụng hoặc chủ yếu sử dụng để sản xuất mạch in, mạch in đã lắp ráp, bộ phận của máy móc thuộc nhóm 85.17, hoặc bộ phận của máy xử lý dữ liệu tự động của nhóm 84.71; dùng cho máy thuộc các phân nhóm 8457.10, 8458.91.00, 8459.21.00, 8459.29.10, 8459.61.00, 8459.69.10, hoặc 8461.50.00, loại chỉ được sử dụng hoặc chủ yếu để sản xuất bộ phận của máy móc thuộc nhóm 85.17, hoặc bộ phận của máy xử lý dữ liệu tự động của nhóm 84.71',
    Unit: 'chiếc',
  },
  {
    HsCode: '84669340',
    Description: '- - - Dùng cho máy thuộc phân nhóm 8456.50.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84669390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84669400',
    Description: '- - Dùng cho máy thuộc nhóm 84.62 hoặc 84.63',
    Unit: 'chiếc',
  },
  {
    HsCode: '8467',
    Description: 'Dụng cụ cầm tay, hoạt động bằng khí nén, thủy lực hoặc có gắn động cơ dùng điện hoặc không dùng điện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Hoạt động bằng khí nén:',
    Unit: '',
  },
  {
    HsCode: '84671100',
    Description: '- - Dạng quay (kể cả dạng kết hợp chuyển động quay và chuyển động va đập)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84671900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Có động cơ điện gắn liền:',
    Unit: '',
  },
  {
    HsCode: '84672100',
    Description: '- - Khoan các loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '84672200',
    Description: '- - Cưa',
    Unit: 'chiếc',
  },
  {
    HsCode: '84672900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '84678100',
    Description: '- - Cưa xích',
    Unit: 'chiếc',
  },
  {
    HsCode: '84678900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '846791',
    Description: '- - Của cưa xích:',
    Unit: '',
  },
  {
    HsCode: '84679110',
    Description: '- - - Của loại cơ điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84679190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84679200',
    Description: '- - Của dụng cụ hoạt động bằng khí nén',
    Unit: 'chiếc',
  },
  {
    HsCode: '846799',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84679910',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8467.21.00, 8467.22.00 hoặc 8467.29.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84679990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8468',
    Description:
      'Thiết bị và dụng cụ dùng để hàn thiếc, hàn đồng hoặc hàn khác, có hoặc không có khả năng cắt, trừ các loại thuộc nhóm 85.15; máy và thiết bị dùng để tôi bề mặt sử dụng khí ga',
    Unit: '',
  },
  {
    HsCode: '84681000',
    Description: '- Ống xì cầm tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '846820',
    Description: '- Thiết bị và dụng cụ sử dụng khí ga khác:',
    Unit: '',
  },
  {
    HsCode: '84682010',
    Description: '- - Dụng cụ hàn hoặc cắt kim loại sử dụng khí ga, điều khiển bằng tay (loại không cầm tay)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84682090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84688000',
    Description: '- Máy và thiết bị khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '846890',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84689020',
    Description: '- - Của hàng hóa thuộc phân nhóm 8468.20.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84689090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8470',
    Description:
      'Máy tính và các máy ghi, tái tạo và hiển thị dữ liệu loại bỏ túi có chức năng tính toán; máy kế toán, máy đóng dấu bưu phí, máy bán vé và các loại máy tương tự, có gắn bộ phận tính toán; máy tính tiền',
    Unit: '',
  },
  {
    HsCode: '84701000',
    Description:
      '- Máy tính điện tử có thể hoạt động không cần nguồn điện ngoài và máy ghi, tái tạo và hiển thị dữ liệu, loại bỏ túi có chức năng tính toán',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy tính điện tử khác:',
    Unit: '',
  },
  {
    HsCode: '84702100',
    Description: '- - Có gắn bộ phận in',
    Unit: 'chiếc',
  },
  {
    HsCode: '84702900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84703000',
    Description: '- Máy tính khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84705000',
    Description: '- Máy tính tiền',
    Unit: 'chiếc',
  },
  {
    HsCode: '847090',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84709010',
    Description: '- - Máy đóng dấu bưu phí',
    Unit: 'chiếc',
  },
  {
    HsCode: '84709090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8471',
    Description:
      'Máy xử lý dữ liệu tự động và các khối chức năng của chúng; đầu đọc từ tính hoặc đầu đọc quang học, máy truyền dữ liệu lên các phương tiện truyền dữ liệu dưới dạng mã hóa và máy xử lý những dữ liệu này, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '847130',
    Description:
      '- Máy xử lý dữ liệu tự động loại xách tay, có khối lượng không quá 10 kg, gồm ít nhất một đơn vị xử lý dữ liệu trung tâm, một bàn phím và một màn hình:',
    Unit: '',
  },
  {
    HsCode: '84713020',
    Description: '- - Máy tính xách tay kể cả notebook và subnotebook ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84713090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy xử lý dữ liệu tự động khác:',
    Unit: '',
  },
  {
    HsCode: '847141',
    Description:
      '- - Chứa trong cùng một vỏ có ít nhất một đơn vị xử lý trung tâm, một đơn vị nhập và một đơn vị xuất, kết hợp hoặc không kết hợp với nhau:',
    Unit: '',
  },
  {
    HsCode: '84714110',
    Description: '- - - Máy tính cá nhân trừ máy tính loại xách tay của phân nhóm 8471.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84714190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847149',
    Description: '- - Loại khác, ở dạng hệ thống:',
    Unit: '',
  },
  {
    HsCode: '84714910',
    Description: '- - - Máy tính cá nhân trừ máy tính loại xách tay của phân nhóm 8471.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84714990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847150',
    Description:
      '- Bộ xử lý trừ loại của phân nhóm 8471.41 hoặc 8471.49, có hoặc không chứa trong cùng vỏ của một hoặc hai thiết bị sau: bộ lưu trữ, bộ nhập, bộ xuất:',
    Unit: '',
  },
  {
    HsCode: '84715010',
    Description: '- - Bộ xử lý dùng cho máy tính cá nhân (kể cả loại máy xách tay)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84715090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847160',
    Description: '- Bộ nhập hoặc bộ xuất, có hoặc không chứa bộ lưu trữ trong cùng một vỏ:',
    Unit: '',
  },
  {
    HsCode: '84716030',
    Description: '- - Bàn phím máy tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '84716040',
    Description:
      '- - Thiết bị nhập theo tọa độ X-Y, kể cả chuột, bút quang, cần điều khiển, bi xoay, và màn hình cảm ứng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84716090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847170',
    Description: '- Bộ lưu trữ:',
    Unit: '',
  },
  {
    HsCode: '84717020',
    Description: '- - Ổ đĩa cứng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84717030',
    Description: '- - Ổ băng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84717040',
    Description: '- - Ổ đĩa quang, kể cả ổ CD-ROM, ổ DVD và ổ CD có thể ghi được (CD-R) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84717090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847180',
    Description: '- Các bộ khác của máy xử lý dữ liệu tự động:',
    Unit: '',
  },
  {
    HsCode: '84718010',
    Description: '- - Bộ điều khiển và bộ thích ứng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84718070',
    Description: '- - Card âm thanh hoặc card hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '84718090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84719010',
    Description: '- - Máy đọc mã vạch',
    Unit: 'chiếc',
  },
  {
    HsCode: '84719030',
    Description: '- - Hệ thống nhận dạng vân tay điện tử',
    Unit: 'chiếc',
  },
  {
    HsCode: '84719040',
    Description: '- - Máy đọc ký tự quang học khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84719090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8472',
    Description:
      'Máy văn phòng khác (ví dụ, máy nhân bản in keo hoặc máy nhân bản sử dụng giấy sáp, máy ghi địa chỉ, máy rút tiền giấy tự động, máy phân loại tiền kim loại, máy đếm hoặc đóng gói tiền kim loại, máy gọt bút chì, máy đột lỗ hoặc máy dập ghim)',
    Unit: '',
  },
  {
    HsCode: '84721000',
    Description: '- Máy nhân bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '84723000',
    Description:
      '- Máy phân loại hoặc gấp thư hoặc cho thư vào phong bì hoặc băng giấy, máy mở, gấp hoặc gắn kín và máy đóng dán tem hoặc hủy tem bưu chính',
    Unit: 'chiếc',
  },
  {
    HsCode: '847290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84729010',
    Description: '- - Máy thanh toán tiền tự động',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Máy chữ trừ các loại máy in thuộc nhóm 84.43:',
    Unit: '',
  },
  {
    HsCode: '84729041',
    Description: '- - - Tự động',
    Unit: 'chiếc',
  },
  {
    HsCode: '84729049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84729050',
    Description: '- - Máy xử lý văn bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '84729060',
    Description: '- - Loại khác, hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84729090',
    Description: '- - Loại khác, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '8473',
    Description:
      'Bộ phận và phụ kiện (trừ vỏ, hộp đựng và các loại tương tự) chỉ dùng hoặc chủ yếu dùng với các máy thuộc các nhóm từ 84.70 đến 84.72',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện của máy thuộc nhóm 84.70:',
    Unit: '',
  },
  {
    HsCode: '84732100',
    Description: '- - Của máy tính điện tử thuộc phân nhóm 8470.10.00, 8470.21.00 hoặc 8470.29.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84732900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847330',
    Description: '- Bộ phận và phụ kiện của máy thuộc nhóm 84.71:',
    Unit: '',
  },
  {
    HsCode: '84733010',
    Description: '- - Tấm mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84733090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84734000',
    Description: '- Bộ phận và phụ kiện của máy thuộc nhóm 84.72',
    Unit: 'chiếc',
  },
  {
    HsCode: '847350',
    Description:
      '- Bộ phận và phụ kiện thích hợp dùng cho máy thuộc hai hoặc nhiều nhóm của các nhóm từ 84.70 đến 84.72:',
    Unit: '',
  },
  {
    HsCode: '84735010',
    Description: '- - Thích hợp dùng cho máy thuộc nhóm 84.71',
    Unit: 'chiếc',
  },
  {
    HsCode: '84735090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8474',
    Description:
      'Máy dùng để phân loại, sàng lọc, phân tách, rửa, nghiền, xay, trộn hoặc nhào đất, đá, quặng hoặc các khoáng vật khác, dạng rắn (kể cả dạng bột hoặc dạng nhão); máy dùng để đóng khối, tạo hình hoặc đúc khuôn các nhiên liệu khoáng rắn, bột gốm nhão, xi măng chưa đông cứng, thạch cao hoặc các sản phẩm khoáng khác ở dạng bột hoặc dạng nhão; máy để tạo khuôn đúc bằng cát',
    Unit: '',
  },
  {
    HsCode: '847410',
    Description: '- Máy phân loại, sàng lọc, phân tách hoặc rửa:',
    Unit: '',
  },
  {
    HsCode: '84741010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84741020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '847420',
    Description: '- Máy nghiền hoặc xay:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84742011',
    Description: '- - - Dùng cho đá',
    Unit: 'chiếc',
  },
  {
    HsCode: '84742019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84742021',
    Description: '- - - Dùng cho đá',
    Unit: 'chiếc',
  },
  {
    HsCode: '84742029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy trộn hoặc nhào:',
    Unit: '',
  },
  {
    HsCode: '847431',
    Description: '- - Máy trộn bê tông hoặc nhào vữa:',
    Unit: '',
  },
  {
    HsCode: '84743110',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84743120',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '847432',
    Description: '- - Máy trộn khoáng vật với bi-tum:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84743211',
    Description: '- - - - Có công suất không quá 80 tấn/giờ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84743219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84743221',
    Description: '- - - - Có công suất không quá 80 tấn/giờ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84743229',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847439',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84743910',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84743920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '847480',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84748010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84748020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84749000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8475',
    Description:
      'Máy để lắp ráp đèn điện hoặc đèn điện tử, đèn ống hoặc đèn điện tử chân không hoặc đèn flash, với vỏ bọc bằng thủy tinh; máy để chế tạo hoặc gia công nóng thủy tinh hoặc đồ thủy tinh',
    Unit: '',
  },
  {
    HsCode: '84751000',
    Description:
      '- Máy để lắp ráp đèn điện hoặc đèn điện tử, đèn ống hoặc đèn điện tử chân không hoặc đèn flash, với vỏ bọc bằng thủy tinh',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy để chế tạo hoặc gia công nóng thủy tinh hoặc đồ thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '84752100',
    Description: '- - Máy sản xuất sợi quang học và phôi tạo hình trước của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84752900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '847590',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84759030',
    Description: '- - Của máy móc thuộc phân nhóm 8475.21.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84759090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8476',
    Description:
      'Máy bán hàng hóa tự động (ví dụ, máy bán tem bưu điện, máy bán thuốc lá, máy bán thực phẩm hoặc đồ uống), kể cả máy đổi tiền',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy bán đồ uống tự động:',
    Unit: '',
  },
  {
    HsCode: '84762100',
    Description: '- - Có lắp thiết bị làm nóng hoặc làm lạnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '84762900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84768100',
    Description: '- - Có lắp thiết bị làm nóng hoặc làm lạnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '847689',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84768910',
    Description: '- - - Máy đổi tiền',
    Unit: 'chiếc',
  },
  {
    HsCode: '84768990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '847690',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84769010',
    Description: '- - Của máy đổi tiền',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84769090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8477',
    Description:
      'Máy dùng để gia công cao su hoặc plastic hoặc dùng trong việc sản xuất các sản phẩm từ những vật liệu trên, chưa được chi tiết hoặc ghi ở nơi khác trong Chương này',
    Unit: '',
  },
  {
    HsCode: '847710',
    Description: '- Máy đúc phun:',
    Unit: '',
  },
  {
    HsCode: '84771010',
    Description: '- - Để đúc cao su',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - Để đúc plastic:',
    Unit: '',
  },
  {
    HsCode: '84771031',
    Description: '- - - Máy đúc phun sản phẩm poly(vinyl chloride) (PVC)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84771039',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '847720',
    Description: '- Máy đùn:',
    Unit: '',
  },
  {
    HsCode: '84772010',
    Description: '- - Để đùn cao su',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84772020',
    Description: '- - Để đùn plastic',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84773000',
    Description: '- Máy đúc thổi',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '847740',
    Description: '- Máy đúc chân không và các loại máy đúc nhiệt khác:',
    Unit: '',
  },
  {
    HsCode: '84774010',
    Description: '- - Để đúc hoặc tạo hình cao su',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84774020',
    Description: '- - Để đúc hoặc tạo hình plastic',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy đúc hoặc tạo hình khác:',
    Unit: '',
  },
  {
    HsCode: '84775100',
    Description: '- - Để đúc hoặc đắp lại lốp hơi hoặc để đúc hoặc tạo hình loại săm khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '847759',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84775910',
    Description: '- - - Dùng cho cao su',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84775920',
    Description: '- - - Dùng cho plastic',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '847780',
    Description: '- Máy khác:',
    Unit: '',
  },
  {
    HsCode: '84778010',
    Description: '- - Để gia công cao su hoặc sản xuất các sản phẩm từ cao su, hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84778020',
    Description: '- - Để gia công cao su hoặc sản xuất các sản phẩm từ cao su, không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - Để gia công plastic hoặc sản xuất các sản phẩm từ plastic, hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84778031',
    Description: '- - - Máy ép lớp mỏng dùng để sản xuất tấm mạch in hoặc tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84778039',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84778040',
    Description: '- - Để gia công plastic hoặc sản xuất các sản phẩm từ plastic, không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '847790',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84779010',
    Description: '- - Của máy gia công cao su hoặc sản xuất các sản phẩm từ cao su hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84779020',
    Description: '- - Của máy để gia công cao su hoặc sản xuất các sản phẩm từ cao su không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - Của máy gia công plastic hoặc sản xuất các sản phẩm từ plastic hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84779032',
    Description: '- - - Bộ phận của máy ép lớp mỏng dùng để sản xuất tấm mạch in hoặc tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84779039',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '84779040',
    Description: '- - Của máy để gia công plastic hoặc các sản phẩm từ plastic, không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '8478',
    Description: 'Máy chế biến hoặc đóng gói thuốc lá, chưa được chi tiết hoặc ghi ở nơi khác trong Chương này',
    Unit: '',
  },
  {
    HsCode: '847810',
    Description: '- Máy: ',
    Unit: '',
  },
  {
    HsCode: '84781010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84781020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84789000',
    Description: '- Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '8479',
    Description:
      'Máy và thiết bị cơ khí có chức năng riêng biệt, chưa được chi tiết hoặc ghi ở nơi khác thuộc Chương này',
    Unit: '',
  },
  {
    HsCode: '847910',
    Description: '- Máy dùng cho các công trình công cộng, công trình xây dựng hoặc các mục đích tương tự:',
    Unit: '',
  },
  {
    HsCode: '84791010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84791020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '847920',
    Description:
      '- Máy dùng để chiết xuất hoặc chế biến dầu hoặc mỡ động vật hoặc dầu hoặc chất béo không bay hơi của thực vật hoặc vi sinh vật:',
    Unit: '',
  },
  {
    HsCode: '84792010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84792020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84793000',
    Description:
      '- Máy ép dùng để sản xuất tấm, ván ép từ xơ sợi hoặc dăm gỗ hoặc từ các vật liệu bằng gỗ khác và các loại máy khác dùng để xử lý gỗ hoặc lie',
    Unit: 'chiếc',
  },
  {
    HsCode: '847940',
    Description: '- Máy sản xuất dây cáp hoặc dây chão:',
    Unit: '',
  },
  {
    HsCode: '84794010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84794020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84795000',
    Description: '- Rô bốt công nghiệp, chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84796000',
    Description: '- Máy làm mát không khí bằng bay hơi',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Cầu vận chuyển hành khách:',
    Unit: '',
  },
  {
    HsCode: '84797100',
    Description: '- - Loại sử dụng ở sân bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '84797900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy và thiết bị cơ khí khác:',
    Unit: '',
  },
  {
    HsCode: '847981',
    Description: '- - Để gia công kim loại, kể cả máy cuộn ống dây điện:',
    Unit: '',
  },
  {
    HsCode: '84798110',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84798120',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '847982',
    Description:
      '- - Máy trộn, máy nhào, máy xay, máy nghiền, máy sàng, máy rây, máy trộn đồng hóa, máy tạo nhũ tương hoặc máy khuấy:',
    Unit: '',
  },
  {
    HsCode: '84798210',
    Description: '- - - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84798220',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '84798300',
    Description: '- - Máy ép đẳng nhiệt lạnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '847989',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84798910',
    Description:
      '- - - Thiết bị tự động sắp đặt hoặc loại bỏ các linh kiện hoặc phần tử tiếp xúc chỉ sử dụng hoặc chủ yếu sử dụng để sản xuất mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84798950',
    Description:
      '- - - Máy lắp ráp các bo mạch nhánh của bộ xử lý trung tâm (CPU) vào các vỏ hoặc hộp đựng bằng plastic; thiết bị tái sinh dung dịch hóa học dùng trong sản xuất tấm mạch in hoặc tấm dây in; thiết bị làm sạch cơ học bề mặt của tấm mạch in hoặc tấm dây in trong sản xuất; thiết bị đồng chỉnh tấm mạch in hoặc tấm dây in hoặc mạch in đã lắp ráp trong quá trình sản xuất',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '84798961',
    Description: '- - - - Máy bán hàng dịch vụ tự động (Automatic service-vending machines)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84798969',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84798970',
    Description: '- - - Loại khác, không hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '847990',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84799010',
    Description: '- - Của hàng hóa thuộc phân nhóm 8479.89.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '84799050',
    Description: '- - Của hàng hóa thuộc phân nhóm 8479.89.50',
    Unit: 'chiếc',
  },
  {
    HsCode: '84799090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8480',
    Description:
      'Hộp khuôn đúc kim loại; đế khuôn; mẫu làm khuôn; khuôn dùng cho kim loại (trừ khuôn đúc thỏi), carbua kim loại, thủy tinh, khoáng vật, cao su hoặc plastic',
    Unit: '',
  },
  {
    HsCode: '84801000',
    Description: '- Hộp khuôn đúc kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '84802000',
    Description: '- Đế khuôn',
    Unit: 'chiếc',
  },
  {
    HsCode: '848030',
    Description: '- Mẫu làm khuôn:',
    Unit: '',
  },
  {
    HsCode: '84803010',
    Description: '- - Bằng đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84803090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Khuôn dùng để đúc kim loại hoặc carbua kim loại: ',
    Unit: '',
  },
  {
    HsCode: '84804100',
    Description: '- - Loại phun hoặc nén',
    Unit: 'chiếc',
  },
  {
    HsCode: '84804900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84805000',
    Description: '- Khuôn đúc thủy tinh',
    Unit: 'chiếc',
  },
  {
    HsCode: '84806000',
    Description: '- Khuôn đúc khoáng vật',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Khuôn đúc cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '848071',
    Description: '- - Loại phun hoặc nén:',
    Unit: '',
  },
  {
    HsCode: '84807110',
    Description: '- - - Khuôn làm đế giày, dép',
    Unit: 'chiếc',
  },
  {
    HsCode: '84807190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848079',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84807910',
    Description: '- - - Khuôn làm đế giày, dép',
    Unit: 'chiếc',
  },
  {
    HsCode: '84807990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8481',
    Description:
      'Vòi, van và các thiết bị tương tự dùng cho đường ống, thân nồi hơi, bể chứa hoặc các loại tương tự, kể cả van giảm áp và van điều chỉnh bằng nhiệt',
    Unit: '',
  },
  {
    HsCode: '848110',
    Description: '- Van giảm áp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bằng sắt hoặc thép:',
    Unit: '',
  },
  {
    HsCode: '84811011',
    Description:
      '- - - Van cổng điều khiển bằng tay có đường kính trong cửa nạp hoặc cửa thoát trên 5 cm nhưng không quá 40 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84811019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bằng đồng hoặc hợp kim đồng:',
    Unit: '',
  },
  {
    HsCode: '84811021',
    Description: '- - - Có đường kính trong không quá 2,5 cm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84811022',
    Description: '- - - Có đường kính trong trên 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84811091',
    Description: '- - - Bằng plastic, có đường kính trong từ 1cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84811099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848120',
    Description: '- Van dùng trong truyền động dầu thủy lực hoặc khí nén:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Van cổng điều khiển bằng tay có đường kính trong cửa nạp hoặc cửa thoát trên 5 cm nhưng không quá 40 cm: ',
    Unit: '',
  },
  {
    HsCode: '84812011',
    Description: '- - - Bằng sắt hoặc thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '84812019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84812020',
    Description:
      '- - Bằng đồng hoặc hợp kim đồng, có đường kính trong không quá 2,5 cm, hoặc bằng plastic, có đường kính trong từ 1 cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84812090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848130',
    Description: '- Van kiểm tra (van một chiều):',
    Unit: '',
  },
  {
    HsCode: '84813010',
    Description: '- - Van cản, bằng gang đúc, có đường kính trong cửa nạp từ 4 cm đến 60 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84813020',
    Description: '- - Bằng đồng hoặc hợp kim đồng, có đường kính trong từ 2,5 cm trở xuống',
    Unit: 'chiếc',
  },
  {
    HsCode: '84813040',
    Description: '- - Bằng plastic, có đường kính trong từ 1cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84813090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848140',
    Description: '- Van an toàn hoặc van xả:',
    Unit: '',
  },
  {
    HsCode: '84814010',
    Description: '- - Bằng đồng hoặc hợp kim đồng, có đường kính trong từ 2,5 cm trở xuống',
    Unit: 'chiếc',
  },
  {
    HsCode: '84814030',
    Description: '- - Bằng plastic, có đường kính trong từ 1cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84814090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848180',
    Description: '- Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Van dùng cho săm:',
    Unit: '',
  },
  {
    HsCode: '84818011',
    Description: '- - - Bằng đồng hoặc hợp kim đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818012',
    Description: '- - - Bằng vật liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Van dùng cho lốp không săm:',
    Unit: '',
  },
  {
    HsCode: '84818013',
    Description: '- - - Bằng đồng hoặc hợp kim đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818014',
    Description: '- - - Bằng vật liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Van xi lanh khí dầu mỏ hóa lỏng (LPG) bằng đồng hoặc hợp kim đồng:',
    Unit: '',
  },
  {
    HsCode: '84818021',
    Description: '- - - Có đường kính cửa nạp hoặc cửa thoát không quá 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818022',
    Description: '- - - Có đường kính cửa nạp hoặc cửa thoát trên 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818030',
    Description:
      '- - Van, đã hoặc chưa lắp bộ phận đánh lửa điện từ, dùng cho các bếp nấu hoặc bếp có lò nướng bằng ga',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Van cho chai chất lỏng có ga; van cho bộ phận rót bia hoạt động bằng ga:',
    Unit: '',
  },
  {
    HsCode: '84818041',
    Description: '- - - Bằng plastic, có đường kính trong từ 1cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Van và vòi có khoang pha trộn lưu chất:',
    Unit: '',
  },
  {
    HsCode: '84818051',
    Description: '- - - Bằng plastic, có đường kính trong từ 1cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Van đường ống nước:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - - Van cổng, bằng gang đúc, có đường kính trong từ 4 cm trở lên; van bướm, bằng gang đúc, có đường kính trong từ 8 cm trở lên:',
    Unit: '',
  },
  {
    HsCode: '84818061',
    Description: '- - - - Van cổng điều khiển bằng tay có đường kính trong trên 5 cm nhưng không quá 40 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818062',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818063',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Núm uống nước dùng cho lợn:',
    Unit: '',
  },
  {
    HsCode: '84818064',
    Description: '- - - Bằng plastic và có đường kính trong từ 1 cm đến 2,5 cm (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818065',
    Description: '- - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Van nối có núm:',
    Unit: '',
  },
  {
    HsCode: '84818066',
    Description: '- - - Bằng plastic và có đường kính trong từ 1 cm đến 2,5 cm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818067',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Van bi:',
    Unit: '',
  },
  {
    HsCode: '84818071',
    Description: '- - - - Bằng plastic và có đường kính trong từ 1 cm đến 2,5 cm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818072',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Van cổng, điều khiển bằng tay, bằng sắt hoặc thép:',
    Unit: '',
  },
  {
    HsCode: '84818073',
    Description: '- - - - Có đường kính trong cửa nạp và cửa thoát trên 5 cm nhưng không quá 40 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818074',
    Description: '- - - - Có đường kính trong cửa nạp và cửa thoát trên 40 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818077',
    Description: '- - - - Có đường kính trong cửa nạp và cửa thoát không quá 5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Van nhiều cửa:',
    Unit: '',
  },
  {
    HsCode: '84818078',
    Description: '- - - - Bằng plastic và có đường kính trong từ 1 cm đến 2,5 cm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818079',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Van điều khiển bằng khí nén:',
    Unit: '',
  },
  {
    HsCode: '84818081',
    Description: '- - - - Bằng plastic và có đường kính trong từ 1 cm đến 2,5 cm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818082',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818083',
    Description:
      '- - - Van ngắt nhiên liệu bằng plastic dùng cho xe thuộc nhóm 87.02, 87.03 hoặc 87.04, với đường kính trong cửa nạp dưới 1cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818084',
    Description:
      '- - - Van ngắt nhiên liệu bằng plastic dùng cho xe thuộc nhóm 87.02, 87.03 hoặc 87.04, với đường kính trong cửa nạp từ 1cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818093',
    Description: '- - - Van ngắt nhiên liệu bằng vật liệu khác dùng cho xe thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Van plastic khác:',
    Unit: '',
  },
  {
    HsCode: '84818094',
    Description: '- - - - Có đường kính trong cửa nạp dưới 1 cm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818095',
    Description: '- - - - Có đường kính trong cửa nạp từ 1 cm đến 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818096',
    Description: '- - - - Có đường kính trong cửa nạp trên 2,5 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818097',
    Description:
      '- - - Loại khác, điều khiển bằng tay, khối lượng dưới 3 kg, đã được xử lý bề mặt hoặc làm bằng thép không gỉ hoặc niken',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84818098',
    Description: '- - - - Vòi nước bằng đồng hoặc hợp kim đồng, có đường kính trong từ 2,5 cm trở xuống',
    Unit: 'chiếc',
  },
  {
    HsCode: '84818099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848190',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84819010',
    Description:
      '- - Vỏ của van cổng hoặc van cống có đường kính trong cửa nạp hoặc cửa thoát trên 50 mm nhưng không quá 400 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Dùng cho vòi, van các loại (trừ van dùng cho săm và lốp không săm) và các thiết bị tương tự có đường kính trong từ 25 mm trở xuống:',
    Unit: '',
  },
  {
    HsCode: '84819021',
    Description: '- - - Thân, dùng cho vòi nước',
    Unit: 'chiếc',
  },
  {
    HsCode: '84819022',
    Description: '- - - Thân, dùng cho van xi lanh khí dầu mỏ hoá lỏng (LPG) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84819023',
    Description: '- - - Thân, loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84819029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Thân hoặc đầu van của săm hoặc lốp không săm:',
    Unit: '',
  },
  {
    HsCode: '84819031',
    Description: '- - - Bằng đồng hoặc hợp kim đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84819039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Lõi van của săm hoặc lốp không săm:',
    Unit: '',
  },
  {
    HsCode: '84819041',
    Description: '- - - Bằng đồng hoặc hợp kim đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84819049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84819090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8482',
    Description: 'Ổ bi hoặc ổ đũa',
    Unit: '',
  },
  {
    HsCode: '84821000',
    Description: '- Ổ bi',
    Unit: 'chiếc',
  },
  {
    HsCode: '84822000',
    Description: '- Ổ đũa côn, kể cả cụm linh kiện vành côn và đũa côn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84823000',
    Description: '- Ổ đũa cầu ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84824000',
    Description: '- Ổ đũa kim, kể cả lồng (cage) và đũa kim đã lắp ráp ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84825000',
    Description: '- Các loại ổ đũa hình trụ khác, kể cả lồng (cage) và đũa đã lắp rắp ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84828000',
    Description: '- Loại khác, kể cả ổ kết hợp bi cầu/bi đũa',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84829100',
    Description: '- - Bi, kim và đũa ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84829900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8483',
    Description:
      'Trục truyền động (kể cả trục cam và trục khuỷu) và tay biên; thân ổ và gối đỡ trục dùng ổ trượt; bánh răng và cụm bánh răng; vít bi hoặc vít đũa; hộp số và các cơ cấu điều tốc khác, kể cả bộ biến đổi mô men xoắn; bánh đà và ròng rọc, kể cả khối puli; ly hợp và khớp nối trục (kể cả khớp nối vạn năng)',
    Unit: '',
  },
  {
    HsCode: '848310',
    Description: '- Trục truyền động (kể cả trục cam và trục khuỷu) và tay biên:',
    Unit: '',
  },
  {
    HsCode: '84831010',
    Description: '- - Dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Trục cam và trục khuỷu dùng cho động cơ xe của Chương 87:',
    Unit: '',
  },
  {
    HsCode: '84831024',
    Description: '- - - Dùng cho xe thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84831025',
    Description: '- - - - Cho xe có dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84831026',
    Description: '- - - - Cho xe có dung tích xi lanh trên 2.000 cc đến 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84831027',
    Description: '- - - - Cho xe có dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho động cơ máy thủy:',
    Unit: '',
  },
  {
    HsCode: '84831031',
    Description: '- - - Công suất không quá 22,38 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '84831039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84831090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848320',
    Description: '- Thân ổ, lắp ổ bi hoặc ổ đũa:',
    Unit: '',
  },
  {
    HsCode: '84832020',
    Description: '- - Dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84832030',
    Description: '- - Dùng cho động cơ xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '84832090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848330',
    Description: '- Thân ổ, không lắp ổ bi hoặc ổ đũa; gối đỡ trục dùng ổ trượt:',
    Unit: '',
  },
  {
    HsCode: '84833030',
    Description: '- - Dùng cho động cơ xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '84833090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848340',
    Description:
      '- Bộ bánh răng và cụm bánh răng ăn khớp, trừ bánh xe có răng, đĩa xích và các bộ phận truyền chuyển động ở dạng riêng biệt; vít bi hoặc vít đũa; hộp số và các cơ cấu điều tốc khác, kể cả bộ biến đổi mô men xoắn:',
    Unit: '',
  },
  {
    HsCode: '84834020',
    Description: '- - Dùng cho tàu thuyền',
    Unit: 'chiếc',
  },
  {
    HsCode: '84834030',
    Description: '- - Dùng cho máy thuộc nhóm 84.29 hoặc 84.30',
    Unit: 'chiếc',
  },
  {
    HsCode: '84834040',
    Description: '- - Dùng cho động cơ của xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '84834090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84835000',
    Description: '- Bánh đà và ròng rọc, kể cả khối puli',
    Unit: 'chiếc',
  },
  {
    HsCode: '84836000',
    Description: '- Ly hợp và khớp nối trục (kể cả khớp nối vạn năng)',
    Unit: 'chiếc',
  },
  {
    HsCode: '848390',
    Description: '- Bánh xe có răng, đĩa xích và các bộ phận truyền chuyển động riêng biệt; các bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận của hàng hóa thuộc phân nhóm 8483.10:',
    Unit: '',
  },
  {
    HsCode: '84839011',
    Description: '- - - Dùng cho xe kéo thuộc phân nhóm 8701.10 hoặc 8701.91 đến 8701.95',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839013',
    Description: '- - - Dùng cho xe kéo khác thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839014',
    Description: '- - - Dùng cho hàng hóa thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839015',
    Description: '- - - Dùng cho hàng hóa khác thuộc Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84839091',
    Description: '- - - Dùng cho hàng hóa thuộc phân nhóm 8701.10 hoặc 8701.91 đến 8701.95',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839093',
    Description: '- - - Dùng cho xe kéo khác thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839094',
    Description: '- - - Dùng cho hàng hóa thuộc nhóm 87.11',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839095',
    Description: '- - - Dùng cho hàng hóa khác thuộc Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '84839099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8484',
    Description:
      'Đệm và gioăng tương tự làm bằng tấm kim loại mỏng kết hợp với các vật liệu khác hoặc bằng hai hoặc nhiều lớp kim loại; bộ hoặc một số chủng loại đệm và gioăng tương tự, thành phần khác nhau, được đóng trong các túi, bao hoặc đóng gói tương tự; bộ làm kín kiểu cơ khí',
    Unit: '',
  },
  {
    HsCode: '84841000',
    Description:
      '- Đệm và gioăng tương tự làm bằng tấm kim loại mỏng kết hợp với các vật liệu khác hoặc bằng hai hoặc nhiều lớp kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '84842000',
    Description: '- Bộ làm kín kiểu cơ khí',
    Unit: 'chiếc',
  },
  {
    HsCode: '84849000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8485',
    Description: 'Máy móc sử dụng công nghệ sản xuất bồi đắp',
    Unit: '',
  },
  {
    HsCode: '84851000',
    Description: '- Bằng lắng đọng kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '84852000',
    Description: '- Bằng lắng đọng plastic hoặc cao su',
    Unit: 'chiếc',
  },
  {
    HsCode: '848530',
    Description: '- Bằng lắng đọng thạch cao, xi măng, gốm hoặc thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '84853010',
    Description: '- - Bằng lắng đọng thủy tinh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84853090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84858000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848590',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '84859010',
    Description: '- - Của phân nhóm 8485.20.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '84859090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8486',
    Description:
      'Máy và thiết bị chỉ sử dụng hoặc chủ yếu sử dụng để sản xuất các khối bán dẫn hoặc tấm bán dẫn mỏng, linh kiện bán dẫn, mạch điện tử tích hợp hoặc màn hình dẹt; máy và thiết bị nêu ở Chú giải 11 (C) của Chương này; bộ phận và phụ kiện',
    Unit: '',
  },
  {
    HsCode: '848610',
    Description: '- Máy và thiết bị để sản xuất khối hoặc tấm bán dẫn mỏng:',
    Unit: '',
  },
  {
    HsCode: '84861010',
    Description: '- - Thiết bị làm nóng nhanh tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84861020',
    Description: '- - Máy làm khô bằng phương pháp quay ly tâm để chế tạo tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84861030',
    Description:
      '- - Máy công cụ để gia công mọi vật liệu bằng cách bóc tách vật liệu, bằng quy trình sử dụng tia laser hoặc tia sáng khác hoặc chùm phô-tông trong sản xuất tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84861040',
    Description:
      '- - Máy và thiết bị để cắt khối bán dẫn đơn tinh thể thành các lớp, hoặc cưa miếng mỏng đơn tinh thể thành các chip',
    Unit: 'chiếc',
  },
  {
    HsCode: '84861050',
    Description: '- - Máy mài, đánh bóng và phủ dùng trong chế tạo tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84861060',
    Description: '- - Thiết bị làm phát triển hoặc kéo khối bán dẫn đơn tinh thể',
    Unit: 'chiếc',
  },
  {
    HsCode: '84861090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848620',
    Description: '- Máy và thiết bị để sản xuất linh kiện bán dẫn hoặc mạch điện tử tích hợp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị tạo lớp màng mỏng:',
    Unit: '',
  },
  {
    HsCode: '84862011',
    Description: '- - - Thiết bị kết tủa khí hóa dùng cho ngành sản xuất bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862012',
    Description:
      '- - - Máy kết tủa epitaxi dùng cho các tấm bán dẫn mỏng; thiết bị để tạo lớp phủ nhũ tương in ảnh lên các tấm bán dẫn mỏng bằng phương pháp quay',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862013',
    Description:
      '- - - Thiết bị tạo kết tủa vật lý bằng phương pháp phun phủ lên các tấm bán dẫn mỏng; thiết bị lắng đọng vật lý dùng cho sản xuất bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị tạo hợp kim hóa:',
    Unit: '',
  },
  {
    HsCode: '84862021',
    Description: '- - - Máy cấy ghép ion cho quá trình xử lý vật liệu bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị tẩy rửa và khắc axit:',
    Unit: '',
  },
  {
    HsCode: '84862031',
    Description:
      '- - - Máy dùng tia sáng để làm sạch và tẩy rửa chất bẩn bám trên các đầu chân dẫn điện kim loại của các cụm linh kiện bán dẫn trước khi tiến hành điện phân; dụng cụ phun dùng để khắc axit, tẩy rửa hoặc làm sạch các tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862032',
    Description: '- - - Thiết bị sử dụng cho các bản mẫu khắc khô trên vật liệu bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862033',
    Description: '- - - Thiết bị để khắc axit ướt, hiện ảnh, tẩy rửa hoặc làm sạch các tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị in ly tô:',
    Unit: '',
  },
  {
    HsCode: '84862041',
    Description: '- - - Thiết bị in trực tiếp lên tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862042',
    Description: '- - - Thiết bị hiệu chỉnh vị trí mẫu theo bước và lặp lại ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị xử lý các tấm bán dẫn mỏng đã được phơi sáng hiện ảnh:',
    Unit: '',
  },
  {
    HsCode: '84862051',
    Description: '- - - Thiết bị khắc để đánh dấu hoặc khắc vạch lên các tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '84862091',
    Description: '- - - Máy cắt laser để cắt các đường tiếp xúc bằng các chùm tia laser trong các sản phẩm bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862092',
    Description: '- - - Máy uốn, gấp và làm thẳng các đầu chân dẫn điện của bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862093',
    Description:
      '- - - Lò sấy và lò luyện dùng điện trở sử dụng trong sản xuất linh kiện bán dẫn trên các tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862094',
    Description:
      '- - - Lò sấy và lò luyện hoạt động bằng hiệu ứng cảm ứng điện hoặc điện môi dùng cho sản xuất linh kiện bán dẫn trên các tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862095',
    Description:
      '- - - Máy tự động dịch chuyển hoặc bóc tách các linh kiện hoặc phần tiếp xúc trên các nguyên liệu bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84862099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848630',
    Description: '- Máy và thiết bị dùng để sản xuất màn hình dẹt:',
    Unit: '',
  },
  {
    HsCode: '84863010',
    Description: '- - Thiết bị khắc axit bằng phương pháp khô lên tấm nền của màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84863020',
    Description: '- - Thiết bị khắc axit bằng phương pháp ướt, hiện ảnh, tẩy rửa hoặc làm sạch màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84863030',
    Description:
      '- - Thiết bị kết tủa và bay hơi dùng để sản xuất màn hình dẹt; thiết bị để tạo phủ lớp nhũ tương cảm quang lên các nền của màn hình dẹt bằng phương pháp quay; thiết bị tạo kết tủa vật lý lên các lớp nền của màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84863090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848640',
    Description: '- Máy và thiết bị nêu tại Chú giải 11 (C) Chương này:',
    Unit: '',
  },
  {
    HsCode: '84864010',
    Description:
      '- - Máy phay bằng chùm tia ion hội tụ để sản xuất hoặc sửa chữa màn và lưới quang của các định dạng trên linh kiện bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84864020',
    Description:
      '- - Thiết bị gắn khuôn, nối băng tự động, nối dây và bọc nhựa để lắp ráp các chất bán dẫn; máy tự động để dịch chuyển, xử lý và kẹp giữ các tấm bán dẫn mỏng, các khung miếng mỏng, hộp mỏng và vật liệu khác dùng cho thiết bị bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84864030',
    Description: '- - Khuôn để sản xuất linh kiện bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84864040',
    Description:
      '- - Kính hiển vi quang học soi nổi được lắp với thiết bị chuyên dùng để kẹp giữ và dịch chuyển tấm bán dẫn bản mỏng hoặc tấm lưới bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84864050',
    Description:
      '- - Kính hiển vi chụp ảnh được lắp với thiết bị chuyên dùng để kẹp giữ và dịch chuyển tấm bán dẫn bản mỏng hoặc tấm lưới bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84864060',
    Description:
      '- - Kính hiển vi điện tử được lắp với thiết bị chuyên dùng để kẹp giữ và dịch chuyển tấm bán dẫn bản mỏng hoặc tấm lưới bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84864070',
    Description:
      '- - Thiết bị tạo mẫu dùng cho quá trình tạo màn (khuôn in) hoặc lưới quang trên các tấm nền phủ lớp cản quang trong quá trình khắc',
    Unit: 'chiếc',
  },
  {
    HsCode: '84864090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '848690',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của máy móc và thiết bị để sản xuất khối hoặc tấm bán dẫn mỏng:',
    Unit: '',
  },
  {
    HsCode: '84869011',
    Description: '- - - Của thiết bị nung nóng nhanh tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869012',
    Description: '- - - Của thiết bị làm khô bằng phương pháp quay dùng cho quá trình gia công tấm bản mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869013',
    Description:
      '- - - Của máy công cụ để gia công mọi vật liệu bằng cách bóc tách vật liệu, bằng quy trình sử dụng tia laser hoặc tia sáng khác hoặc chùm phô-tông trong sản xuất tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Của máy dùng để cắt khối bán dẫn đơn tinh thể thành các lớp, hoặc cưa miếng mỏng đơn tinh thể thành các chip:',
    Unit: '',
  },
  {
    HsCode: '84869014',
    Description:
      '- - - - Bộ phận kẹp dụng cụ và đầu cắt ren tự mở; bộ phận kẹp sản phẩm; đầu chia độ và những bộ phận phụ trợ đặc biệt khác dùng cho máy công cụ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869015',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869016',
    Description: '- - - Của máy mài, đánh bóng và mài rà dùng cho quá trình sản xuất tấm bán dẫn mỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869017',
    Description: '- - - Của thiết bị làm phát triển hoặc kéo dài khối bán dẫn đơn tinh thể',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy và thiết bị sản xuất linh kiện bán dẫn hoặc mạch điện tử tích hợp:',
    Unit: '',
  },
  {
    HsCode: '84869021',
    Description: '- - - Của thiết bị kết tủa và bay hơi dùng cho sản xuất bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869022',
    Description:
      '- - - Của máy kết tủa epitaxi dùng cho các tấm bán dẫn mỏng; của thiết bị để tạo lớp phủ nhũ tương cảm quang in ảnh lên các tấm bán dẫn mỏng bằng phương pháp quay',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869023',
    Description:
      '- - - Của máy cấy ghép ion cho quá trình xử lý vật liệu bán dẫn; của thiết bị tạo kết tủa vật lý bằng phương pháp phun phủ lên tấm bán dẫn mỏng; của thiết bị để lắng đọng vật lý cho sản xuất bán dẫn; của thiết bị ghi trực tiếp lên tấm bán dẫn mỏng, thiết bị hiệu chỉnh vị trí mẫu theo bước và lặp lại và thiết bị in ly tô khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Của dụng cụ phun dùng để khắc axit, tẩy rửa hoặc làm sạch các tấm bán dẫn mỏng; của thiết bị để khắc axit ướt, hiện ảnh, tẩy rửa hoặc làm sạch các tấm bán dẫn mỏng; của các bản mẫu khắc khô trên vật liệu bán dẫn:',
    Unit: '',
  },
  {
    HsCode: '84869024',
    Description:
      '- - - - Bộ phận kẹp dụng cụ và đầu cắt ren tự mở; bộ phận kẹp sản phẩm; đầu chia độ và những bộ phận phụ trợ đặc biệt khác dùng cho máy công cụ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869025',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Của máy khắc để đánh dấu hoặc khắc vạch lên các tấm bán dẫn mỏng; của máy cắt laser để cắt các đường tiếp xúc bằng các chùm tia laser trong các sản phẩm bán dẫn; của máy uốn, gấp và làm thẳng các đầu chân dẫn điện của bán dẫn:',
    Unit: '',
  },
  {
    HsCode: '84869026',
    Description:
      '- - - - Bộ phận kẹp dụng cụ và đầu cắt ren tự mở; bộ phận kẹp sản phẩm; đầu chia độ và những bộ phận phụ trợ đặc biệt khác dùng cho máy công cụ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869027',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869028',
    Description:
      '- - - Của lò sấy và lò luyện dùng điện trở sử dụng trong sản xuất linh kiện bán dẫn trên tấm bán dẫn mỏng; của lò sấy và lò luyện hoạt động bằng hiệu ứng cảm ứng điện hoặc điện môi dùng trong sản xuất linh kiện bán dẫn trên tấm bán dẫn mỏng ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy và thiết bị sản xuất màn hình dẹt:',
    Unit: '',
  },
  {
    HsCode: '84869031',
    Description: '- - - Của thiết bị để khắc axit bằng phương pháp khô lên các tấm nền của màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Của thiết bị khắc axit bằng phương pháp ướt, máy điện ảnh, thiết bị tẩy rửa hoặc làm sạch màn hình dẹt:',
    Unit: '',
  },
  {
    HsCode: '84869032',
    Description:
      '- - - - Bộ phận kẹp dụng cụ và đầu cắt ren tự mở; bộ phận kẹp sản phẩm; đầu chia độ và những bộ phận phụ trợ đặc biệt khác dùng cho máy công cụ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869033',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869034',
    Description: '- - - Của thiết bị kết tủa và bay hơi dùng để sản xuất màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869035',
    Description: '- - - Của thiết bị để tạo lớp phủ nhũ tương cảm quang lên tấm nền của màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869036',
    Description: '- - - Của thiết bị để tạo kết tủa vật lý lên các đế của màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của máy hoặc thiết bị nêu tại Chú giải 11 (C) của Chương này:',
    Unit: '',
  },
  {
    HsCode: '84869041',
    Description:
      '- - - Của máy phay bằng chùm tia ion hội tụ để sản xuất hoặc sửa chữa màn và lưới quang của các định dạng trên linh kiện bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869042',
    Description: '- - - Của thiết bị gắn khuôn, nối băng tự động, nối dây và bọc nhựa để lắp ráp các chất bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869043',
    Description:
      '- - - Của máy tự động để dịch chuyển, xử lý và kẹp giữ các tấm bán dẫn mỏng, các khung miếng mỏng, hộp mỏng và vật liệu khác dùng cho thiết bị bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869044',
    Description:
      '- - - Của kính hiển vi quang học soi nổi và kính hiển vi chụp ảnh được lắp với thiết bị chuyên dùng để kẹp giữ và dịch chuyển tấm bán dẫn bản mỏng hoặc tấm lưới bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869045',
    Description:
      '- - - Của kính hiển vi điện tử được lắp với thiết bị chuyên dùng để kẹp giữ và dịch chuyển tấm bán dẫn bản mỏng hoặc tấm lưới bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869046',
    Description:
      '- - - Của thiết bị tạo mẫu dùng cho quá trình tạo màn (khuôn in) hoặc lưới quang trên các đế phủ lớp cản quang trong quá trình khắc, kể cả mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '84869049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8487',
    Description:
      'Phụ tùng máy móc, không bao gồm đầu nối điện, màng ngăn, cuộn, công tắc điện hoặc các phụ tùng điện khác, không được ghi hoặc chi tiết ở nơi khác trong Chương này',
    Unit: '',
  },
  {
    HsCode: '84871000',
    Description: '- Chân vịt của tàu hoặc thuyền và cánh của chân vịt',
    Unit: 'chiếc',
  },
  {
    HsCode: '84879000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN5699-2-21, TCVN 2144:2008, TCVN 7828:2016 và TCVN 11917-1:2017;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' (1): Theo Nghị định số 86/2012/NĐ-CP của Chính phủ; Nghị định số 13/2022/NĐ-CP của Chính phủ; Thông tư số 22/2018/TT-BTTTT của Bộ Thông tin và truyền thông. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (*) Máy in ống đồng được hiểu là máy in lõm (in chìm).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 85',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Máy điện và thiết bị điện và các bộ phận của chúng; máy ghi và tái tạo âm thanh, máy ghi và tái tạo hình ảnh và âm thanh truyền hình, bộ phận và phụ kiện của các loại máy trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Chăn, đệm giường, bao ủ chân hoặc các sản phẩm tương tự sưởi ấm bằng điện; quần áo, giày dép hoặc đệm lót tai hoặc các mặt hàng khác sưởi ấm bằng điện để mặc hoặc sử dụng cho người;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các sản phẩm thủy tinh thuộc nhóm 70.11;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Máy và thiết bị của nhóm 84.86;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Thiết bị hút chân không sử dụng trong lĩnh vực y tế, phẫu thuật, nha khoa hoặc thú y (nhóm 90.18); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Đồ nội thất được gia nhiệt bằng điện thuộc Chương 94.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Các nhóm từ 85.01 đến 85.04 không áp dụng cho các loại hàng hóa đã mô tả trong nhóm 85.11, 85.12, 85.40, 85.41 hoặc 85.42.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tuy nhiên, thiết bị chỉnh lưu hồ quang thủy ngân vỏ kim loại vẫn được xếp vào nhóm 85.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo mục đích của nhóm 85.07, khái niệm ""ắc quy điện"" bao gồm cả các loại ắc qui có thành phần phụ trợ đóng góp vào chức năng lưu điện và cấp điện hoặc bảo vệ ắc qui khỏi sự hư hại, như đầu nối điện, thiết bị kiểm soát nhiệt độ (ví dụ, điện trở nhiệt) và thiết bị bảo vệ mạch điện. Chúng cũng có thể gồm phần vỏ bảo vệ của hàng hóa mà trong đó chúng được sử dụng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 85.09 chỉ gồm những máy cơ điện loại thông thường được sử dụng cho mục đích gia dụng sau đây:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Máy đánh bóng sàn, máy nghiền và trộn thực phẩm, và máy ép rau hoặc quả, với khối lượng bất kỳ;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các loại máy khác có khối lượng không quá 20 kg.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, nhóm này không bao gồm quạt hoặc nắp chụp hút tuần hoàn gió hoặc thông gió có kèm theo quạt, có hoặc không lắp bộ phận lọc (nhóm 84.14), máy làm khô quần áo bằng ly tâm (nhóm 84.21), máy rửa bát đĩa (nhóm 84.22), máy giặt gia đình (nhóm 84.50), các loại máy cán hoặc máy là khác (nhóm 84.20 hoặc 84.51), máy khâu (nhóm 84.52), kéo điện (nhóm 84.67) hoặc các dụng cụ nhiệt điện (nhóm 85.16).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của nhóm 85.17, thuật ngữ ""điện thoại thông minh"" có nghĩa là điện thoại dùng cho mạng di động tế bào, được trang bị hệ điều hành di động được thiết kế để thực hiện các chức năng của máy xử lý dữ liệu tự động như tải xuống và chạy nhiều ứng dụng đồng thời, kể cả ứng dụng của bên thứ ba, và có hoặc không tích hợp các tính năng khác như camera kỹ thuật số và hệ thống hỗ trợ điều hướng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '6. Theo mục đích của nhóm 85.23:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) ""Các thiết bị lưu trữ bền vững, thể rắn (sản phẩm lưu trữ bán dẫn không bị xóa dữ liệu khi không còn nguồn điện cung cấp)"" (ví dụ, “thẻ nhớ flash (flash memory cards)” hoặc “thẻ lưu trữ điện tử flash (flash electronic storage cards)”) là thiết bị lưu trữ gắn với đầu kết nối (đầu cắm nối), có chứa trong cùng một vỏ một hoặc nhiều linh kiện nhớ flash (ví dụ, “FLASH E2PROM”) dưới dạng mạch tích hợp lắp ghép trên tấm mạch in. Chúng có thể gồm phần điều khiển dưới dạng mạch tích hợp và phần tử thụ động riêng biệt, như tụ điện và điện trở; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Khái niệm “thẻ thông minh” nghĩa là thẻ được gắn bên trong một hoặc nhiều mạch điện tử tích hợp (bộ vi xử lý, bộ nhớ truy cập ngẫu nhiên (RAM) hoặc bộ nhớ chỉ đọc (ROM)) ở dạng các chip. Các thẻ này có thể bao gồm các tiếp điểm, dải từ tính hoặc ăng ten gắn bên trong nhưng không chứa bất cứ phần tử chủ động hoặc thụ động nào khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '7. Theo mục đích của nhóm 85.24, “mô-đun màn hình dẹt” dùng để chỉ các thiết bị hoặc dụng cụ để hiển thị thông tin, được trang bị tối thiểu một màn hiển thị, được thiết kế để lắp vào các sản phẩm thuộc các nhóm khác trước khi sử dụng. Màn hình hiển thị cho các mô-đun màn hình dẹt bao gồm, nhưng không giới hạn ở dạng dẹt, cong, linh hoạt, ở dạng có thể gập lại hoặc co giãn. Mô-đun màn hình dẹt có thể kết hợp thêm các chi tiết, bao gồm cả những chi tiết cần thiết để nhận tín hiệu video và phân bổ các tín hiệu đó đến các điểm ảnh trên màn hiển thị. Tuy nhiên, nhóm 85.24 không bao gồm các mô-đun hiển thị được trang bị các thành phần để chuyển đổi tín hiệu video (ví dụ, IC điều chỉnh tỷ lệ, IC giải mã hoặc bộ xử lý ứng dụng) hoặc mang đặc tính của hàng hóa thuộc các nhóm khác. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Để phân loại mô-đun màn hình dẹt được định nghĩa trong Chú giải này, nhóm 85.24 sẽ được ưu tiên hơn bất kỳ nhóm nào khác trong Danh mục. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '8. Theo mục đích của nhóm 85.34 “mạch in” là mạch được tạo ra trên một tấm cách điện bằng một quy trình in mạch nào đó (ví dụ, rập nổi, mạ, khắc axit) hoặc bằng kỹ thuật tạo “mạch điện màng”, các phần tử dẫn điện, các tiếp điểm hoặc các thành phần dùng cho mạch in khác (ví dụ, cuộn cảm, điện trở, tụ điện) đơn lẻ hoặc được liên kết theo sơ đồ mẫu đã thiết kế trước, trừ các phần tử có thể phát (tạo ra), chỉnh lưu, điều biến hoặc khuếch đại tín hiệu điện (ví dụ, các phần tử bán dẫn).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Thuật ngữ “mạch in” không bao gồm mạch đã tổ hợp với các phần tử trừ các phần tử đã được hình thành trong quá trình in mạch, và cũng không bao gồm điện trở, tụ điện hoặc cuộn cảm đơn lẻ hoặc bố trí ghép nối riêng biệt. Tuy nhiên, những mạch in này có thể gắn với phần tử kết nối không qua quá trình in mạch.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Mạch điện màng mỏng hoặc dày bao gồm phần tử chủ động và thụ động hợp thành trong cùng một quy trình công nghệ được phân loại trong nhóm 85.42.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '9. Theo mục đích của nhóm 85.36, “đầu nối dùng cho sợi quang, bó sợi quang hoặc cáp quang” nghĩa là đầu nối chỉ dùng để ghép nối đối đầu một cách cơ học các sợi quang trong hệ thống đường truyền kỹ thuật số. Chúng không có chức năng khác, như khuếch đại, tái tạo hoặc biến đổi tín hiệu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '10. Nhóm 85.37 không gồm các thiết bị không dây hồng ngoại dùng cho điều khiển từ xa của máy thu truyền hình hoặc các thiết bị điện khác (nhóm 85.43).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '11. Theo mục đích của nhóm 85.39, khái niệm “nguồn sáng đi-ốt phát quang (LED)” bao gồm: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) “Mô-đun đi-ốt phát quang (LED)” là nguồn sáng điện dựa trên các đi-ốt phát quang (LED) được bố trí trong các mạch điện và chứa thêm các phần tử như phần tử điện, cơ, nhiệt hoặc quang học. Chúng cũng chứa các phần tử chủ động riêng biệt, phần tử thụ động riêng biệt, hoặc các sản phẩm thuộc nhóm 85.36 hoặc 85.42 nhằm mục đích cung cấp nguồn điện hoặc điều khiển nguồn điện. Mô-đun đi-ốt phát quang (LED) không có đầu đèn được thiết kế để cho phép dễ dàng lắp đặt hoặc thay thế trong đèn điện và đảm bảo tiếp xúc cơ và điện. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) “Đèn đi-ốt phát quang (LED)” là nguồn sáng điện gồm một hoặc nhiều mô-đun LED có chứa thêm các phần tử như phần tử điện, cơ, nhiệt hoặc quang học. Sự khác biệt giữa mô-đun đi-ốt phát quang (LED) và bóng đèn đi-ốt phát quang (LED) là đèn (lamp) có đầu đèn được thiết kế để cho phép dễ dàng lắp đặt hoặc thay thế trong đèn (luminaire) và đảm bảo tiếp xúc cơ và điện. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '12. Theo mục đích của nhóm 85.41 và 85.42:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) (i) “Thiết bị bán dẫn” là các thiết bị bán dẫn mà hoạt động của nó phụ thuộc vào sự thay đổi của điện trở suất khi áp dụng điện trường hoặc các bộ chuyển đổi dựa trên chất bán dẫn. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các thiết bị bán dẫn cũng có thể bao gồm nhiều phần tử được lắp ráp, có hoặc không được trang bị các chức năng phụ trợ của thiết bị chủ động và thụ động. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Theo mục đích của định nghĩa này, “Bộ chuyển đổi dựa trên chất bán dẫn” là cảm biến dựa trên chất bán dẫn, bộ truyền động dựa trên chất bán dẫn, bộ cộng hưởng dựa trên chất bán dẫn và bộ tạo dao động dựa trên chất bán dẫn, là các loại thiết bị dựa trên chất bán dẫn riêng biệt, thực hiện một chức năng theo bản chất của nó, có thể chuyển đổi bất kỳ loại hiện tượng vật lý hoặc hóa học hoặc hoạt động nào thành tín hiệu điện hoặc tín hiệu điện thành bất kỳ loại hiện tượng vật lý hoặc hoạt động nào. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tất cả các phần tử trong bộ chuyển đổi dựa trên chất bán dẫn được kết hợp một cách không thể tách rời và cũng có thể bao gồm các vật liệu cần thiết được gắn một cách không thể tách rời, nó đảm bảo cho cấu trúc hoặc chức năng của chúng. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các thuật ngữ sau đây có nghĩa là: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(1) “Dựa trên chất bán dẫn” có nghĩa là được chế tạo hoặc sản xuất trên nền bán dẫn hoặc làm bằng vật liệu bán dẫn, được sản xuất bằng công nghệ bán dẫn, trong đó chất nền hoặc vật liệu bán dẫn đóng vai trò quan trọng và không thể thay thế đối với chức năng và hiệu suất của bộ chuyển đổi và hoạt động của nó dựa trên các đặc tính bán dẫn bao gồm các đặc tính vật lý, điện, hóa học và quang học. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(2) “Đại lượng vật lý hoặc đại lượng hóa học” liên quan đến các đại lượng như áp suất, sóng âm thanh, gia tốc, rung, chuyển động, phương hướng, sức căng, cường độ từ trường, cường độ điện trường, ánh sáng, phóng xạ, độ ẩm, dòng chảy, nồng độ hóa chất…',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(3) “Cảm biến dựa trên chất bán dẫn” là một loại thiết bị bán dẫn, bao gồm các cấu trúc vi điện tử hoặc cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt chất bán dẫn và có chức năng dò tìm đại lượng vật lý hoặc hóa học và chuyển đổi thành các tín hiệu điện nhờ vào kết quả của sự thay đổi những thuộc tính điện hoặc sự dịch chuyển một cấu trúc cơ khí. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(4) “Cơ cấu chấp hành dựa trên chất bán dẫn” là một loại thiết bị bán dẫn, bao gồm các cấu trúc vi điện tử hoặc cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt chất bán dẫn và có chức năng chuyển đổi tín hiệu điện thành chuyển động vật lý. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(5) “Thiết bị cộng hưởng dựa trên chất bán dẫn” là một loại thiết bị bán dẫn, bao gồm các cấu trúc vi điện tử hoặc cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt chất bán dẫn và chúng có chức năng tạo ra dao động điện hoặc dao động cơ với một tần số xác định trước phụ thuộc vào hình dạng vật lý của các cấu trúc đó để đáp ứng với một đầu vào bên ngoài. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(6) “Thiết bị dao động dựa trên chất bán dẫn” là một loại thiết bị bán dẫn, bao gồm bao gồm các cấu trúc vi điện tử hoặc cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt chất bán dẫn và chúng có chức năng tạo ra dao động điện hoặc dao động cơ với một tần số xác định trước phụ thuộc vào hình dạng vật lý của các cấu trúc đó. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) “Đi-ốt phát quang (LED)” là thiết bị bán dẫn dựa trên vật liệu bán dẫn chuyển đổi năng lượng điện thành các tia có thể nhìn thấy, tia hồng ngoại hoặc tia cực tím, có hoặc không kết nối điện với nhau và có hoặc không kết hợp với đi-ốt bảo vệ. Đi-ốt phát quang (LED) thuộc nhóm 85.41 không kết hợp các phần tử với mục đích cung cấp nguồn hoặc điều khiển nguồn; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) “Mạch điện tử tích hợp” là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(i) Mạch tích hợp đơn khối trong đó các phần tử của mạch điện (đi-ốt, tranzito, điện trở, tụ điện, cuộn cảm...) được tạo (chủ yếu) trong khối đó và trên bề mặt của một vật liệu bán dẫn hoặc vật liệu bán dẫn kết hợp (ví dụ, silic đã kích tạp, gali asenua, silic-germani, indi photphua) và ở dạng liên kết chặt chẽ (không tách biệt);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ii) Mạch tích hợp lai trong đó các phần tử thụ động (điện trở, tụ điện, cuộn cảm...), được tạo thành từ công nghệ màng mỏng hoặc màng dày và các phần tử chủ động (đi-ốt, tranzito, mạch tích hợp đơn khối ...), được tạo thành nhờ công nghệ bán dẫn, các phần tử này được kết nối không tách rời, bằng việc gắn kết với nhau hoặc bằng dây liên kết, trên một tấm cách điện đơn (thủy tinh, gốm sứ...). Những mạch này cũng có thể kể cả các linh kiện bố trí riêng biệt;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iii) Mạch tích hợp đa chíp bao gồm hai hoặc nhiều mạch tích hợp đơn khối gắn với nhau không tách rời, có hoặc không gắn một hoặc nhiều tấm cách điện, có hoặc không có khung dây, nhưng không gắn phần tử mạch chủ động hoặc thụ động khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(iv) Mạch tích hợp đa thành phần (MCOs): sự kết hợp một hoặc nhiều mạch tích hợp đơn khối, lai hoặc đa chíp có ít nhất một trong những thành phần sau: cảm biến, cơ cấu chấp hành, bộ dao động, bộ cộng hưởng nền silic, hoặc kết hợp giữa chúng, hoặc các thành phần thực hiện chức năng của hàng hóa thuộc nhóm 85.32, 85.33, 85.41, hoặc cuộn cảm thuộc nhóm 85.04, được định hình với mọi mục đích thành một khối duy nhất không thể tách rời giống một bảng mạch tích hợp, như thành phần được lắp ráp trên tấm mạch in (PCB) hoặc trên vật mang khác, thông qua việc kết nối các chân cắm, dây dẫn (leads), khớp nối cầu (balls), dải nối (lands), mấu nối (bumps), hoặc đế/đệm nối (pads).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Theo mục đích của định nghĩa này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. “Thành phần” có thể riêng biệt, được chế tạo độc lập, sau đó được lắp ráp lên mạch tích hợp đa thành phần (MCO), hoặc được tích hợp trong những thành phần khác.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. “Nền silic” nghĩa là được đặt trên một nền silic, hoặc được làm bằng vật liệu silic, hoặc được chế tạo trên khuôn mạch tích hợp (integrated circuit die).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. (a) “Cảm biến nền silic” bao gồm những cấu trúc vi điện tử hoặc cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt bán dẫn và chúng có chức năng dò tìm đại lượng vật lý hoặc đại lượng hóa học và chuyển đổi thành những tín hiệu điện nhờ vào kết quả của sự thay đổi những thuộc tính điện hoặc sự dịch chuyển một cấu trúc cơ khí. “Đại lượng vật lý hoặc đại lượng hóa học” liên quan đến các đại lượng như áp suất, sóng âm thanh, gia tốc, rung, chuyển động, phương hướng, sức căng, cường độ từ trường, cường độ điện trường, ánh sáng, phóng xạ, độ ẩm, dòng chảy, nồng độ hóa chất...',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) “Cơ cấu chấp hành nền silic” bao gồm những cấu trúc vi điện tử và cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt chất bán dẫn và chúng có chức năng chuyển đổi tín hiệu điện thành chuyển động vật lý.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) “Bộ cộng hưởng nền silic” là thành phần bao gồm những cấu trúc vi điện tử hoặc cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt chất bán dẫn và chúng có chức năng tạo ra dao động điện hoặc dao động cơ với một tần số xác định trước phụ thuộc vào hình dạng vật lý của các cấu trúc đó để đáp ứng với một đầu vào bên ngoài.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) “Bộ dao động nền silic” là thành phần chủ động bao gồm những cấu trúc vi điện tử hoặc cấu trúc cơ khí được chế tạo trong khối hoặc trên bề mặt chất bán dẫn và chúng có chức năng tạo ra sự dao động điện hoặc dao động cơ với một tần số xác định trước phụ thuộc vào hình dạng vật lý của các cấu trúc đó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Để phân loại những mặt hàng được định nghĩa trong Chú giải này, các nhóm 85.41 và 85.42 được ưu tiên xem xét trước hết so với bất kỳ nhóm nào khác trong Danh mục hàng hóa, trừ các mặt hàng thuộc nhóm 85.23.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Phân nhóm 8525.81 chỉ bao gồm camera truyền hình, camera kỹ thuật số và camera ghi hình ảnh tốc độ cao có một hoặc nhiều đặc điểm sau: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- tốc độ ghi trên 0,5 mm mỗi micro giây; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- độ phân giải thời gian từ 50 nano giây trở xuống;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- tốc độ khung hình trên 225.000 khung hình mỗi giây. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Đối với phân nhóm 8525.82, camera truyền hình, camera kỹ thuật số và camera ghi hình ảnh được làm cứng bức xạ hoặc chịu bức xạ được thiết kế hoặc được che chắn để có thể hoạt động trong môi trường bức xạ cao. Các camera này được thiết kế để chịu được tổng lượng bức xạ ít nhất là 50 × 103Gy (silic) (5 × 106 RAD (silic)) mà không bị suy giảm hoạt động. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Phân nhóm 8525.83 bao gồm camera truyền hình, camera kỹ thuật số và camera ghi hình ảnh nhìn ban đêm sử dụng một ca-tốt quang điện (photocathode) để chuyển đổi ánh sáng có sẵn thành các điện tử (electrons), có thể được khuếch đại và chuyển đổi để mang lại hình ảnh nhìn thấy được. Phân nhóm này loại trừ camera ảnh nhiệt (thường thuộc phân nhóm 8525.89). ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Phân nhóm 8527.12 chỉ gồm các loại máy cát sét có bộ khuếch đại lắp sẵn, không có loa lắp sẵn, có khả năng hoạt động không cần nguồn điện ngoài và kích thước không quá 170 mm x 100 mm x 45 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Theo mục đích của phân nhóm 8549.11 đến 8549.19, “các loại pin, bộ pin và ắc qui điện đã sử dụng hết” là các loại pin và ắc qui không sử dụng được nữa do bị hỏng, bị vỡ, cắt phá, mòn hoặc do các nguyên nhân khác, cũng không có khả năng nạp lại.',
    Unit: '',
  },
  {
    HsCode: '8501',
    Description: 'Động cơ điện và máy phát điện (trừ tổ máy phát điện)',
    Unit: '',
  },
  {
    HsCode: '850110',
    Description: '- Động cơ có công suất không quá 37,5 W:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Động cơ một chiều:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Động cơ bước:',
    Unit: '',
  },
  {
    HsCode: '85011021',
    Description: '- - - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85011022',
    Description: '- - - - Loại khác, công suất không quá 5 W',
    Unit: 'chiếc',
  },
  {
    HsCode: '85011029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85011030',
    Description: '- - - Động cơ hướng trục (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85011041',
    Description: '- - - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85011049',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Động cơ khác, kể cả động cơ vạn năng (một chiều/xoay chiều):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Động cơ bước:',
    Unit: '',
  },
  {
    HsCode: '85011051',
    Description: '- - - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85011059',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85011060',
    Description: '- - - Động cơ hướng trục (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85011091',
    Description: '- - - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85011099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850120',
    Description: '- Động cơ vạn năng một chiều/xoay chiều có công suất trên 37,5 W:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Công suất không quá 1 kW:',
    Unit: '',
  },
  {
    HsCode: '85012012',
    Description: '- - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85012019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Công suất trên 1 kW:',
    Unit: '',
  },
  {
    HsCode: '85012021',
    Description: '- - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85012029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Động cơ một chiều khác; máy phát điện một chiều, trừ máy phát quang điện:',
    Unit: '',
  },
  {
    HsCode: '850131',
    Description: '- - Công suất không quá 750 W:',
    Unit: '',
  },
  {
    HsCode: '85013130',
    Description: '- - - Động cơ loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013160',
    Description: '- - - Động cơ dùng cho xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013170',
    Description: '- - - Động cơ khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013180',
    Description: '- - - Máy phát điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '850132',
    Description: '- - Công suất trên 750 W nhưng không quá 75 kW:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Công suất trên 750 W nhưng không quá 37,5 kW:',
    Unit: '',
  },
  {
    HsCode: '85013221',
    Description: '- - - - Động cơ loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013224',
    Description: '- - - - Động cơ dùng cho xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013225',
    Description: '- - - - Động cơ khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013226',
    Description: '- - - - Máy phát điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Công suất trên 37,5 kW nhưng không quá 75 kW:',
    Unit: '',
  },
  {
    HsCode: '85013231',
    Description: '- - - - Động cơ loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18 hoặc 84.50',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013232',
    Description: '- - - - Động cơ khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013233',
    Description: '- - - - Máy phát điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '850133',
    Description: '- - Công suất trên 75 kW nhưng không quá 375 kW:',
    Unit: '',
  },
  {
    HsCode: '85013310',
    Description: '- - - Loại dùng cho xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85013400',
    Description: '- - Công suất trên 375 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '850140',
    Description: '- Động cơ xoay chiều khác, một pha: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Công suất không quá 1 kW:',
    Unit: '',
  },
  {
    HsCode: '85014011',
    Description: '- - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85014019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Công suất trên 1 kW:',
    Unit: '',
  },
  {
    HsCode: '85014021',
    Description: '- - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85014029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Động cơ xoay chiều khác, đa pha:',
    Unit: '',
  },
  {
    HsCode: '850151',
    Description: '- - Công suất không quá 750 W:',
    Unit: '',
  },
  {
    HsCode: '85015111',
    Description: '- - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015119',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850152',
    Description: '- - Công suất trên 750 W nhưng không quá 75 kW:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Công suất không quá 1 kW:',
    Unit: '',
  },
  {
    HsCode: '85015211',
    Description: '- - - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015212',
    Description: '- - - - Loại dùng cho xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Công suất trên 1 kW nhưng không quá 37,5 kW:',
    Unit: '',
  },
  {
    HsCode: '85015221',
    Description: '- - - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015222',
    Description: '- - - - Loại dùng cho xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015229',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Công suất trên 37,5 kW nhưng không quá 75 kW:',
    Unit: '',
  },
  {
    HsCode: '85015231',
    Description: '- - - - Loại dùng cho hàng hóa thuộc nhóm 84.15, 84.18 hoặc 84.50',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015232',
    Description: '- - - - Loại dùng cho xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015239',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850153',
    Description: '- - Công suất trên 75 kW:',
    Unit: '',
  },
  {
    HsCode: '85015310',
    Description: '- - - Loại dùng cho xe của Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85015390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy phát điện xoay chiều (máy dao điện), trừ máy phát quang điện:',
    Unit: '',
  },
  {
    HsCode: '850161',
    Description: '- - Công suất không quá 75 kVA:',
    Unit: '',
  },
  {
    HsCode: '85016110',
    Description: '- - - Công suất không quá 12,5 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85016120',
    Description: '- - - Công suất trên 12,5 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '850162',
    Description: '- - Công suất trên 75 kVA nhưng không quá 375 kVA:',
    Unit: '',
  },
  {
    HsCode: '85016210',
    Description: '- - - Công suất trên 75 kVA nhưng không quá 150 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85016220',
    Description: '- - - Công suất trên 150 kVA nhưng không quá 375 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85016300',
    Description: '- - Công suất trên 375 kVA nhưng không quá 750 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85016400',
    Description: '- - Công suất trên 750 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy phát quang điện một chiều:',
    Unit: '',
  },
  {
    HsCode: '85017100',
    Description: '- - Công suất không quá 50 W',
    Unit: 'chiếc',
  },
  {
    HsCode: '850172',
    Description: '- - Công suất trên 50 W:',
    Unit: '',
  },
  {
    HsCode: '85017210',
    Description: '- - - Công suất không quá 750 W',
    Unit: 'chiếc',
  },
  {
    HsCode: '85017220',
    Description: '- - - Công suất trên 750 W nhưng không quá 37,5 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '85017230',
    Description: '- - - Công suất trên 37,5 kW nhưng không quá 75 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '85017240',
    Description: '- - - Công suất trên 75 kW',
    Unit: 'chiếc',
  },
  {
    HsCode: '850180',
    Description: '- Máy phát quang điện xoay chiều:',
    Unit: '',
  },
  {
    HsCode: '85018010',
    Description: '- - Công suất không quá 75 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85018020',
    Description: '- - Công suất trên 75 kVA nhưng không quá 375 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85018030',
    Description: '- - Công suất trên 375 kVA nhưng không quá 750 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85018040',
    Description: '- - Công suất trên 750 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '8502',
    Description: 'Tổ máy phát điện và máy biến đổi điện quay',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tổ máy phát điện với động cơ đốt trong kiểu piston cháy do nén (động cơ diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '85021100',
    Description: '- - Công suất không quá 75 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '850212',
    Description: '- - Công suất trên 75 kVA nhưng không quá 375 kVA:',
    Unit: '',
  },
  {
    HsCode: '85021210',
    Description: '- - - Công suất trên 75 kVA nhưng không quá 125 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85021220',
    Description: '- - - Công suất trên 125 kVA nhưng không quá 375 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '850213',
    Description: '- - Công suất trên 375 kVA:',
    Unit: '',
  },
  {
    HsCode: '85021320',
    Description: '- - - Công suất từ 12.500 kVA trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85021390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850220',
    Description: '- Tổ máy phát điện với động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện:',
    Unit: '',
  },
  {
    HsCode: '85022010',
    Description: '- - Công suất không quá 75 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85022020',
    Description: '- - Công suất trên 75 kVA nhưng không quá 100 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85022030',
    Description: '- - Công suất trên 100 kVA nhưng không quá 10.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Công suất trên 10.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '85022042',
    Description: '- - - Công suất từ 12.500 kVA trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85022049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tổ máy phát điện khác:',
    Unit: '',
  },
  {
    HsCode: '850231',
    Description: '- - Chạy bằng sức gió:',
    Unit: '',
  },
  {
    HsCode: '85023110',
    Description: '- - - Công suất không quá 10.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85023120',
    Description: '- - - Công suất trên 10.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '850239',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85023910',
    Description: '- - - Công suất không quá 10 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85023920',
    Description: '- - - Công suất trên 10 kVA nhưng không quá 10.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Công suất trên 10.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '85023932',
    Description: '- - - - Công suất từ 12.500 kVA trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85023939',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85024000',
    Description: '- Máy biến đổi điện quay',
    Unit: 'chiếc',
  },
  {
    HsCode: '8503',
    Description: 'Các bộ phận chỉ dùng hoặc chủ yếu dùng cho các loại máy thuộc nhóm 85.01 hoặc 85.02',
    Unit: '',
  },
  {
    HsCode: '85030020',
    Description:
      '- Bộ phận của máy phát điện (kể cả tổ máy phát điện) thuộc nhóm 85.01 hoặc 85.02, công suất từ 12.500 kVA trở lên',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85030090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8504',
    Description:
      'Máy biến điện (máy biến áp và máy biến dòng), máy biến đổi điện tĩnh (ví dụ, bộ chỉnh lưu) và cuộn cảm',
    Unit: '',
  },
  {
    HsCode: '85041000',
    Description: '- Chấn lưu dùng cho đèn phóng hoặc ống phóng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy biến điện sử dụng điện môi lỏng:',
    Unit: '',
  },
  {
    HsCode: '850421',
    Description: '- - Có công suất danh định không quá 650 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - - Máy ổn áp từng nấc (biến áp tự ngẫu); máy biến đổi đo lường (1) có công suất danh định không quá 5 kVA:',
    Unit: '',
  },
  {
    HsCode: '85042111',
    Description:
      '- - - - Máy biến đổi đo lường (1) loại công suất danh định không quá 1 kVA và đầu điện áp cao từ 110 kV trở lên (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042119',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85042192',
    Description: '- - - - Có công suất danh định trên 10 kVA và đầu điện áp cao từ 110 kV trở lên (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042193',
    Description:
      '- - - - Có công suất danh định trên 10 kVA và đầu điện áp cao từ 66 kV trở lên, nhưng dưới 110 kV (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850422',
    Description: '- - Có công suất danh định trên 650 kVA nhưng không quá 10.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Máy ổn áp từng nấc (biến áp tự ngẫu):',
    Unit: '',
  },
  {
    HsCode: '85042211',
    Description: '- - - - Đầu điện áp cao từ 66 kV trở lên (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042219',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85042292',
    Description: '- - - - Đầu điện áp cao từ 110 kV trở lên (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042293',
    Description: '- - - - Đầu điện áp cao từ 66 kV trở lên, nhưng dưới 110 kV (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850423',
    Description: '- - Có công suất danh định trên 10.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '85042310',
    Description: '- - - Có công suất danh định không quá 15.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Có công suất danh định trên 15.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '85042321',
    Description: '- - - - Không quá 20.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042322',
    Description: '- - - - Trên 20.000 kVA nhưng không quá 30.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85042329',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy biến điện khác:',
    Unit: '',
  },
  {
    HsCode: '850431',
    Description: '- - Có công suất danh định không quá 1 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Máy biến áp đo lường:',
    Unit: '',
  },
  {
    HsCode: '85043111',
    Description: '- - - - Điện áp từ 110 kV trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043112',
    Description: '- - - - Điện áp từ 66 kV trở lên, nhưng dưới 110 kV',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043113',
    Description: '- - - - Điện áp từ 1 kV trở lên, nhưng dưới 66 kV',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Máy biến dòng đo lường:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho đường dây có điện áp từ 110 kV trở lên:',
    Unit: '',
  },
  {
    HsCode: '85043121',
    Description: '- - - - - Máy biến dòng dạng vòng dùng cho đường dây có điện áp không quá 220 kV (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043122',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043123',
    Description: '- - - - Điện áp từ 66 kV trở lên, nhưng dưới 110 kV',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043124',
    Description: '- - - - Điện áp từ 1 kV trở lên, nhưng dưới 66 kV',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043130',
    Description: '- - - Máy biến áp quét về (biến áp tần số quét ngược) (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043140',
    Description: '- - - Máy biến điện trung tần',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85043191',
    Description: '- - - - Sử dụng cho đồ chơi, mô hình thu nhỏ hoặc các mô hình giải trí tương tự ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043192',
    Description: '- - - - Biến áp thích ứng khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043193',
    Description:
      '- - - - Máy biến áp tăng/giảm từng nấc (Step up/down transformers); máy điều chỉnh điện áp trượt (slide regulators) (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850432',
    Description: '- - Công suất danh định trên 1 kVA nhưng không quá 16 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Máy biến đổi đo lường(1) (biến áp và biến dòng) loại công suất danh định không quá 5 kVA:',
    Unit: '',
  },
  {
    HsCode: '85043211',
    Description: '- - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043219',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043220',
    Description: '- - - Loại khác, sử dụng cho đồ chơi, mô hình thu nhỏ hoặc các mô hình giải trí tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043230',
    Description: '- - - Loại khác, tần số tối thiểu 3 MHz',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, có công suất danh định không quá 10 kVA:',
    Unit: '',
  },
  {
    HsCode: '85043241',
    Description: '- - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043249',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, có công suất danh định trên 10 kVA:',
    Unit: '',
  },
  {
    HsCode: '85043251',
    Description: '- - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043259',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850433',
    Description: '- - Có công suất danh định trên 16 kVA nhưng không quá 500 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có đầu điện áp cao từ 66 kV trở lên:',
    Unit: '',
  },
  {
    HsCode: '85043311',
    Description: '- - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043319',
    Description: '- - - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85043391',
    Description: '- - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043399',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850434',
    Description: '- - Có công suất danh định trên 500 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có công suất danh định không quá 15.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Có công suất danh định trên 10.000 kVA và đầu điện áp cao từ 66 kV trở lên:',
    Unit: '',
  },
  {
    HsCode: '85043411',
    Description: '- - - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043412',
    Description: '- - - - - Máy biến áp loại khô phòng nổ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043413',
    Description: '- - - - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85043414',
    Description: '- - - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043415',
    Description: '- - - - - Máy biến áp loại khô phòng nổ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043419',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Có công suất danh định trên 15.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Có đầu điện áp cao từ 66 kV trở lên:',
    Unit: '',
  },
  {
    HsCode: '85043422',
    Description: '- - - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043423',
    Description: '- - - - - Máy biến áp loại khô phòng nổ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043424',
    Description: '- - - - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85043425',
    Description: '- - - - - Biến áp thích ứng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043426',
    Description: '- - - - - Máy biến áp loại khô phòng nổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85043429',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850440',
    Description: '- Máy biến đổi tĩnh điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho các máy xử lý dữ liệu tự động và các khối chức năng của chúng, và thiết bị viễn thông:',
    Unit: '',
  },
  {
    HsCode: '85044011',
    Description: '- - - Bộ nguồn cấp điện liên tục (UPS) (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85044019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85044020',
    Description: '- - Máy nạp ắc qui, pin có công suất danh định trên 100 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85044030',
    Description: '- - Bộ chỉnh lưu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85044040',
    Description: '- - Bộ nghịch lưu',
    Unit: 'chiếc',
  },
  {
    HsCode: '85044090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850450',
    Description: '- Cuộn cảm khác:',
    Unit: '',
  },
  {
    HsCode: '85045010',
    Description:
      '- - Cuộn cảm dùng cho bộ nguồn cấp điện của các thiết bị xử lý dữ liệu tự động và các khối chức năng của chúng, và thiết bị viễn thông',
    Unit: 'chiếc',
  },
  {
    HsCode: '85045020',
    Description: '- - Cuộn cảm cố định kiểu con chip (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85045093',
    Description: '- - - Có công suất danh định không quá 2.500 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85045094',
    Description: '- - - Có công suất danh định trên 2.500 kVA nhưng không quá 10.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '85045095',
    Description: '- - - Có công suất danh định trên 10.000 kVA',
    Unit: 'chiếc',
  },
  {
    HsCode: '850490',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85049010',
    Description: '- - Của hàng hóa thuộc phân nhóm 8504.10',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85049020',
    Description: '- - Mạch in đã lắp ráp dùng cho hàng hóa thuộc phân nhóm 8504.40.11, 8504.40.19 hoặc 8504.50.10',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho máy biến điện có công suất không quá 10.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '85049031',
    Description:
      '- - - Tấm, cụm tấm tản nhiệt; ống tản nhiệt đã lắp ráp thành dạng tấm phẳng, cụm tấm dùng cho biến áp phân phối và biến áp nguồn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85049039',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho máy biến điện có công suất trên 10.000 kVA:',
    Unit: '',
  },
  {
    HsCode: '85049041',
    Description:
      '- - - Tấm, cụm tấm tản nhiệt; ống tản nhiệt đã lắp ráp thành dạng tấm phẳng, cụm tấm dùng cho biến áp phân phối và biến áp nguồn',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85049049',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85049090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8505',
    Description:
      'Nam châm điện; nam châm vĩnh cửu và các mặt hàng được dùng làm nam châm vĩnh cửu sau khi từ hóa; bàn cặp, giá kẹp và các dụng cụ để giữ tương tự, hoạt động bằng nam châm điện hoặc nam châm vĩnh cửu; các khớp nối, ly hợp và phanh hoạt động bằng điện từ; đầu nâng hoạt động bằng điện từ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Nam châm vĩnh cửu và các mặt hàng được dùng làm nam châm vĩnh cửu sau khi từ hóa:',
    Unit: '',
  },
  {
    HsCode: '85051100',
    Description: '- - Bằng kim loại',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85051900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85052000',
    Description: '- Các khớp nối, ly hợp và phanh hoạt động bằng điện từ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '850590',
    Description: '- Loại khác, kể cả các bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85059010',
    Description:
      '- - Nam châm điện chỉ dùng hoặc chủ yếu dùng cho thiết bị chụp cộng hưởng từ, trừ nam châm điện thuộc nhóm 90.18 ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85059020',
    Description: '- - Đầu nâng điện từ ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85059090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8506',
    Description: 'Pin và bộ pin',
    Unit: '',
  },
  {
    HsCode: '850610',
    Description: '- Bằng dioxit mangan:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Có thể tích bên ngoài không quá 300 cm3: ',
    Unit: '',
  },
  {
    HsCode: '85061011',
    Description: '- - - Bằng kẽm-cacbon ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85061012',
    Description: '- - - Bằng kiềm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85061019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85061091',
    Description: '- - - Bằng kẽm-cacbon ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85061099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85063000',
    Description: '- Bằng oxit thủy ngân',
    Unit: 'chiếc',
  },
  {
    HsCode: '85064000',
    Description: '- Bằng oxit bạc',
    Unit: 'chiếc',
  },
  {
    HsCode: '85065000',
    Description: '- Bằng liti',
    Unit: 'chiếc',
  },
  {
    HsCode: '850660',
    Description: '- Bằng kẽm-khí:',
    Unit: '',
  },
  {
    HsCode: '85066010',
    Description: '- - Có thể tích ngoài không quá 300 cm3',
    Unit: 'chiếc',
  },
  {
    HsCode: '85066090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850680',
    Description: '- Pin và bộ pin khác:',
    Unit: '',
  },
  {
    HsCode: '85068030',
    Description: '- - Có thể tích ngoài không quá 300 cm3',
    Unit: 'chiếc',
  },
  {
    HsCode: '85068090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85069000',
    Description: '- Bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8507',
    Description: 'Ắc qui điện, kể cả tấm vách ngăn của nó, có hoặc không ở dạng hình chữ nhật (kể cả hình vuông)',
    Unit: '',
  },
  {
    HsCode: '850710',
    Description: '- Bằng axit - chì, loại dùng để khởi động động cơ piston:',
    Unit: '',
  },
  {
    HsCode: '85071010',
    Description: '- - Dùng cho máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Điện áp danh định 6 V hoặc 12 V, có dung lượng phóng điện không quá 200 Ah:',
    Unit: '',
  },
  {
    HsCode: '85071092',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) không quá 13 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85071095',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 13 cm nhưng không quá 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85071096',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85071097',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) không quá 13 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85071098',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 13 cm nhưng không quá 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85071099',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '850720',
    Description: '- Ắc qui axit - chì khác:',
    Unit: '',
  },
  {
    HsCode: '85072010',
    Description: '- - Dùng cho máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Điện áp danh định 6 V hoặc 12 V, có dung lượng phóng điện không quá 200 Ah:',
    Unit: '',
  },
  {
    HsCode: '85072094',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) không quá 13 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85072095',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 13 cm nhưng không quá 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85072096',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85072097',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) không quá 13 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85072098',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 13 cm nhưng không quá 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85072099',
    Description: '- - - - Chiều cao (không bao gồm đầu cực và tay cầm) trên 23 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '850730',
    Description: '- Bằng niken-cađimi:',
    Unit: '',
  },
  {
    HsCode: '85073010',
    Description: '- - Dùng cho máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85073090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850750',
    Description: '- Bằng niken - hydrua kim loại:',
    Unit: '',
  },
  {
    HsCode: '85075010',
    Description: '- - Dùng cho máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85075020',
    Description: '- - Dùng cho xe thuộc Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85075090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850760',
    Description: '- Bằng ion liti:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bộ pin (battery pack):',
    Unit: '',
  },
  {
    HsCode: '85076031',
    Description: '- - - Loại dùng cho máy tính xách tay kể cả loại notebook và subnotebook',
    Unit: 'chiếc',
  },
  {
    HsCode: '85076032',
    Description: '- - - Dùng cho máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85076033',
    Description: '- - - Dùng cho xe thuộc Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85076039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85076090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850780',
    Description: '- Ắc qui khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho máy bay:',
    Unit: '',
  },
  {
    HsCode: '85078011',
    Description: '- - - Bằng sắt-niken ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85078019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85078020',
    Description: '- - Loại dùng cho máy tính xách tay kể cả loại notebook và subnotebook',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85078091',
    Description: '- - - Bằng sắt-niken ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85078099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850790',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Các bản cực:',
    Unit: '',
  },
  {
    HsCode: '85079011',
    Description:
      '- - - Của hàng hóa thuộc phân nhóm 8507.10.92, 8507.10.95, 8507.10.96, 8507.10.97, 8507.10.98 hoặc 8507.10.99',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85079012',
    Description: '- - - Dùng cho máy bay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85079019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85079091',
    Description: '- - - Dùng cho máy bay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85079092',
    Description: '- - - Vách ngăn ắc qui, sẵn sàng để sử dụng, làm từ mọi vật liệu trừ poly(vinyl clorua)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85079093',
    Description:
      '- - - Loại khác, của hàng hóa thuộc phân nhóm 8507.10.92, 8507.10.95, 8507.10.96, 8507.10.97, 8507.10.98 hoặc 8507.10.99',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85079099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8508',
    Description: 'Máy hút bụi',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có động cơ điện gắn liền:',
    Unit: '',
  },
  {
    HsCode: '85081100',
    Description: '- - Công suất không quá 1.500 W và có túi hứng bụi hoặc đồ chứa khác với sức chứa không quá 20 lít',
    Unit: 'chiếc',
  },
  {
    HsCode: '850819',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85081910',
    Description: '- - - Loại phù hợp dùng cho mục đích gia dụng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85081990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85086000',
    Description: '- Máy hút bụi khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850870',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85087010',
    Description: '- - Của máy hút bụi thuộc phân nhóm 8508.11.00 hoặc 8508.19.10 ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85087090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8509',
    Description: 'Thiết bị cơ điện gia dụng có động cơ điện gắn liền, trừ máy hút bụi của nhóm 85.08',
    Unit: '',
  },
  {
    HsCode: '85094000',
    Description: '- Máy nghiền và trộn thức ăn; máy ép quả hoặc rau',
    Unit: 'chiếc',
  },
  {
    HsCode: '850980',
    Description: '- Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '85098010',
    Description: '- - Máy đánh bóng sàn nhà',
    Unit: 'chiếc',
  },
  {
    HsCode: '85098020',
    Description: '- - Thiết bị tiêu hủy chất thải nhà bếp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85098090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '850990',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85099010',
    Description: '- - Của hàng hóa thuộc phân nhóm 8509.80.10',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85099090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8510',
    Description: 'Máy cạo, tông đơ và các dụng cụ loại bỏ râu, lông, tóc, có động cơ điện gắn liền',
    Unit: '',
  },
  {
    HsCode: '85101000',
    Description: '- Máy cạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '85102000',
    Description: '- Tông đơ ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85103000',
    Description: '- Dụng cụ loại bỏ râu, lông, tóc',
    Unit: 'chiếc',
  },
  {
    HsCode: '85109000',
    Description: '- Bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8511',
    Description:
      'Thiết bị đánh lửa hoặc khởi động bằng điện loại dùng cho động cơ đốt trong đốt cháy bằng tia lửa điện hoặc cháy do nén (ví dụ, magneto đánh lửa, dynamo magneto, cuộn dây đánh lửa, bugi đánh lửa và bugi sấy, động cơ khởi động); máy phát điện (ví dụ, dynamo, alternator) và thiết bị ngắt mạch loại được sử dụng cùng các động cơ nêu trên',
    Unit: '',
  },
  {
    HsCode: '851110',
    Description: '- Bugi đánh lửa:',
    Unit: '',
  },
  {
    HsCode: '85111010',
    Description: '- - Loại dùng cho động cơ của phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85111020',
    Description: '- - Sử dụng cho động cơ ô tô',
    Unit: 'chiếc',
  },
  {
    HsCode: '85111090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851120',
    Description: '- Magneto đánh lửa; dynamo mangneto; bánh đà từ tính:',
    Unit: '',
  },
  {
    HsCode: '85112010',
    Description: '- - Loại dùng cho động cơ của phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Sử dụng cho động cơ ô tô:',
    Unit: '',
  },
  {
    HsCode: '85112021',
    Description: '- - - Loại chưa được lắp ráp ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85112029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85112091',
    Description: '- - - Loại chưa được lắp ráp ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85112099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851130',
    Description: '- Bộ phân phối điện; cuộn dây đánh lửa:',
    Unit: '',
  },
  {
    HsCode: '85113030',
    Description: '- - Loại dùng cho động cơ của phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Sử dụng cho động cơ ô tô:',
    Unit: '',
  },
  {
    HsCode: '85113041',
    Description: '- - - Loại chưa được lắp ráp ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85113049',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85113091',
    Description: '- - - Loại chưa được lắp ráp ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85113099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851140',
    Description: '- Động cơ khởi động và máy tổ hợp hai tính năng khởi động và phát điện:',
    Unit: '',
  },
  {
    HsCode: '85114010',
    Description: '- - Loại dùng cho động cơ của phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Động cơ khởi động khác chưa lắp ráp:',
    Unit: '',
  },
  {
    HsCode: '85114021',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '85114029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Động cơ khởi động đã lắp ráp sử dụng cho động cơ của xe thuộc các nhóm từ 87.01 đến 87.05:',
    Unit: '',
  },
  {
    HsCode: '85114031',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.01 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85114032',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '85114033',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85114091',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '85114099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851150',
    Description: '- Máy phát điện khác:',
    Unit: '',
  },
  {
    HsCode: '85115010',
    Description: '- - Loại dùng cho động cơ của phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Máy phát điện xoay chiều khác chưa lắp ráp:',
    Unit: '',
  },
  {
    HsCode: '85115021',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '85115029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Máy phát điện xoay chiều đã lắp ráp dùng cho động cơ của xe thuộc các nhóm từ 87.01 đến 87.05:',
    Unit: '',
  },
  {
    HsCode: '85115031',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.01 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85115032',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '85115033',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85115091',
    Description: '- - - Sử dụng cho động cơ của xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '85115099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851180',
    Description: '- Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '85118010',
    Description: '- - Loại dùng cho động cơ của phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85118020',
    Description: '- - Sử dụng cho động cơ ô tô',
    Unit: 'chiếc',
  },
  {
    HsCode: '85118090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851190',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85119010',
    Description: '- - Loại dùng cho động cơ của phương tiện bay',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85119020',
    Description: '- - Sử dụng cho động cơ ô tô',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85119090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8512',
    Description:
      'Thiết bị chiếu sáng hoặc thiết bị tín hiệu hoạt động bằng điện (trừ loại thuộc nhóm 85.39), gạt nước, gạt và chống tạo sương và tuyết trên kính chắn, loại dùng cho xe đạp hoặc xe có động cơ',
    Unit: '',
  },
  {
    HsCode: '85121000',
    Description: '- Thiết bị chiếu sáng hoặc tạo tín hiệu trực quan dùng cho xe đạp',
    Unit: 'chiếc',
  },
  {
    HsCode: '851220',
    Description: '- Thiết bị chiếu sáng hoặc tạo tín hiệu trực quan khác:',
    Unit: '',
  },
  {
    HsCode: '85122020',
    Description: '- - Thiết bị chiếu sáng hoặc tạo tín hiệu trực quan chưa lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85122091',
    Description: '- - - Dùng cho xe máy',
    Unit: 'chiếc',
  },
  {
    HsCode: '85122099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851230',
    Description: '- Thiết bị tín hiệu âm thanh:',
    Unit: '',
  },
  {
    HsCode: '85123010',
    Description: '- - Còi, đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85123020',
    Description: '- - Thiết bị tín hiệu âm thanh chưa lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85123091',
    Description: '- - - Thiết bị dò chướng ngại vật (cảnh báo) cho xe',
    Unit: 'chiếc',
  },
  {
    HsCode: '85123099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85124000',
    Description: '- Cái gạt nước, gạt và chống tạo sương và tuyết',
    Unit: 'chiếc',
  },
  {
    HsCode: '851290',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85129010',
    Description: '- - Của hàng hóa thuộc phân nhóm 8512.10',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85129020',
    Description: '- - Của hàng hóa thuộc phân nhóm 8512.20, 8512.30 hoặc 8512.40',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8513',
    Description:
      'Đèn điện xách tay được thiết kế để hoạt động bằng nguồn năng lượng riêng của nó (ví dụ, pin khô, ắc qui, magneto), trừ thiết bị chiếu sáng thuộc nhóm 85.12',
    Unit: '',
  },
  {
    HsCode: '851310',
    Description: '- Đèn:',
    Unit: '',
  },
  {
    HsCode: '85131030',
    Description: '- - Đèn gắn trên mũ thợ mỏ và đèn thợ khai thác đá ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85131090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851390',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85139010',
    Description: '- - Của đèn gắn trên mũ thợ mỏ hoặc của đèn thợ khai thác đá',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85139030',
    Description: '- - Bộ phản quang của đèn chớp; chi tiết trượt (gạt) bằng plastic dùng cho cơ cấu công tắc đèn chớp ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85139090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8514',
    Description:
      'Lò luyện, nung và lò sấy điện dùng trong công nghiệp hoặc trong phòng thí nghiệm (kể cả các loại hoạt động bằng cảm ứng điện hoặc tổn hao điện môi); các thiết bị khác dùng trong công nghiệp hoặc trong phòng thí nghiệm để xử lý nhiệt các vật liệu bằng cảm ứng điện hoặc tổn hao điện môi',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Lò luyện, nung và lò sấy gia nhiệt bằng điện trở:',
    Unit: '',
  },
  {
    HsCode: '85141100',
    Description: '- - Lò ép nóng đẳng tĩnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85141900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851420',
    Description: '- Lò luyện, nung và lò sấy hoạt động bằng cảm ứng điện hoặc tổn hao điện môi:',
    Unit: '',
  },
  {
    HsCode: '85142020',
    Description: '- - Lò luyện, nung hoặc lò sấy điện cho sản xuất tấm mạch in/tấm dây in hoặc mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85142090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Lò luyện, nung và lò sấy khác:',
    Unit: '',
  },
  {
    HsCode: '851431',
    Description: '- - Lò tia điện tử (tia electron): ',
    Unit: '',
  },
  {
    HsCode: '85143110',
    Description: '- - - Để sản xuất tấm mạch in/tấm dây in hoặc tấm mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85143190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851432',
    Description: '- - Lò hồ quang plasma và chân không: ',
    Unit: '',
  },
  {
    HsCode: '85143210',
    Description: '- - - Để sản xuất tấm mạch in/tấm dây in hoặc tấm mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85143290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851439',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85143910',
    Description: '- - - Để sản xuất tấm mạch in/tấm dây in hoặc tấm mạch in đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85143990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85144000',
    Description: '- Thiết bị khác để xử lý nhiệt các vật liệu bằng cảm ứng điện hoặc tổn hao điện môi',
    Unit: 'chiếc',
  },
  {
    HsCode: '851490',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85149020',
    Description:
      '- - Bộ phận của lò luyện, nung hoặc lò sấy điện dùng trong công nghiệp hoặc phòng thí nghiệm cho sản xuất tấm mạch in/tấm dây in hoặc mạch in đã lắp ráp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85149090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8515',
    Description:
      'Máy và thiết bị hàn các loại dùng điện (kể cả khí ga nung nóng bằng điện), dùng chùm tia laser hoặc chùm tia sáng khác hoặc chùm phô-tông, siêu âm, chùm electron, xung từ hoặc hồ quang, có hoặc không có khả năng cắt; máy và thiết bị dùng điện để xì nóng kim loại hoặc gốm kim loại',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Máy và thiết bị để hàn chảy (nguyên lý hàn thiếc, chỉ có phần nguyên liệu hàn được làm nóng chảy, đối tượng được hàn không bị nóng chảy):',
    Unit: '',
  },
  {
    HsCode: '85151100',
    Description: '- - Mỏ hàn sắt và súng hàn',
    Unit: 'chiếc',
  },
  {
    HsCode: '851519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Máy và thiết bị hàn các linh kiện trên tấm mạch in/tấm dây in: ',
    Unit: '',
  },
  {
    HsCode: '85151911',
    Description: '- - - - Máy hàn sóng ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85151919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85151990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy và thiết bị để hàn kim loại bằng nguyên lý điện trở:',
    Unit: '',
  },
  {
    HsCode: '85152100',
    Description: '- - Loại tự động hoàn toàn hoặc một phần',
    Unit: 'chiếc',
  },
  {
    HsCode: '85152900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy và thiết bị hàn hồ quang kim loại (kể cả hồ quang plasma):',
    Unit: '',
  },
  {
    HsCode: '85153100',
    Description: '- - Loại tự động hoàn toàn hoặc một phần',
    Unit: 'chiếc',
  },
  {
    HsCode: '851539',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85153910',
    Description: '- - - Máy hàn hồ quang dùng điện xoay chiều, kiểu biến thế',
    Unit: 'chiếc',
  },
  {
    HsCode: '85153990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851580',
    Description: '- Máy và thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '85158010',
    Description: '- - Máy và thiết bị điện để xì nóng kim loại hoặc carbua kim loại đã thiêu kết',
    Unit: 'chiếc',
  },
  {
    HsCode: '85158090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851590',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85159010',
    Description: '- - Của máy hàn hồ quang điện xoay chiều, kiểu biến thế',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Các bộ phận của máy và thiết bị để hàn các linh kiện trên tấm mạch in/tấm dây in: ',
    Unit: '',
  },
  {
    HsCode: '85159021',
    Description: '- - - Của máy hàn sóng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85159029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85159090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8516',
    Description:
      'Dụng cụ điện đun nước nóng tức thời hoặc đun nước nóng có dự trữ và đun nước nóng kiểu nhúng; dụng cụ điện làm nóng không gian và làm nóng đất; dụng cụ nhiệt điện làm tóc (ví dụ, máy sấy tóc, máy uốn tóc, dụng cụ kẹp uốn tóc) và máy sấy làm khô tay; bàn là điện; dụng cụ nhiệt điện gia dụng khác; các loại điện trở đốt nóng bằng điện, trừ loại thuộc nhóm 85.45',
    Unit: '',
  },
  {
    HsCode: '851610',
    Description: '- Dụng cụ điện đun nước nóng tức thời hoặc đun nước nóng có dự trữ và đun nước nóng kiểu nhúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại đun nước nóng tức thời hoặc đun nước nóng có dự trữ:',
    Unit: '',
  },
  {
    HsCode: '85161011',
    Description: '- - - Bình thủy điện (water dispenser) đun nước nóng, loại gia dụng (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85161019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85161030',
    Description: '- - Loại đun nước nóng kiểu nhúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ điện làm nóng không gian và làm nóng đất:',
    Unit: '',
  },
  {
    HsCode: '85162100',
    Description: '- - Loại bức xạ giữ nhiệt',
    Unit: 'chiếc',
  },
  {
    HsCode: '85162900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ làm tóc hoặc máy sấy làm khô tay nhiệt điện:',
    Unit: '',
  },
  {
    HsCode: '85163100',
    Description: '- - Máy sấy khô tóc',
    Unit: 'chiếc',
  },
  {
    HsCode: '85163200',
    Description: '- - Dụng cụ làm tóc khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85163300',
    Description: '- - Máy sấy làm khô tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '851640',
    Description: '- Bàn là điện:',
    Unit: '',
  },
  {
    HsCode: '85164010',
    Description: '- - Loại được thiết kế sử dụng hơi nước từ hệ thống nồi hơi công nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85164090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85165000',
    Description: '- Lò vi sóng',
    Unit: 'chiếc',
  },
  {
    HsCode: '851660',
    Description: '- Các loại lò khác; nồi nấu, bếp đun dạng tấm đun, vòng đun sôi, thiết bị kiểu vỉ nướng và lò nướng:',
    Unit: '',
  },
  {
    HsCode: '85166010',
    Description: '- - Nồi nấu cơm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85166090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ nhiệt điện khác:',
    Unit: '',
  },
  {
    HsCode: '85167100',
    Description: '- - Dụng cụ pha chè hoặc cà phê',
    Unit: 'chiếc',
  },
  {
    HsCode: '85167200',
    Description: '- - Lò nướng bánh (toasters)',
    Unit: 'chiếc',
  },
  {
    HsCode: '851679',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85167910',
    Description: '- - - Ấm đun nước',
    Unit: 'chiếc',
  },
  {
    HsCode: '85167990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851680',
    Description: '- Điện trở đốt nóng bằng điện:',
    Unit: '',
  },
  {
    HsCode: '85168010',
    Description: '- - Dùng cho máy đúc chữ hoặc máy sắp chữ; dùng cho lò công nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85168030',
    Description: '- - Dùng cho thiết bị gia dụng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85168090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851690',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của hàng hóa thuộc phân nhóm 8516.33, 8516.50, 8516.60, 8516.71 hoặc 8516.79.10:',
    Unit: '',
  },
  {
    HsCode: '85169021',
    Description: '- - - Các tấm toả nhiệt (sealed hotplates) dùng cho thiết bị gia dụng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85169029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85169030',
    Description: '- - Của hàng hóa thuộc phân nhóm 8516.10',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85169040',
    Description: '- - Của điện trở đốt nóng bằng điện dùng cho máy đúc chữ hoặc máy sắp chữ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85169090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8517',
    Description:
      'Bộ điện thoại, kể cả điện thoại thông minh và điện thoại khác cho mạng di động tế bào hoặc mạng không dây khác; thiết bị khác để truyền hoặc nhận tiếng, hình ảnh hoặc dữ liệu khác, kể cả các thiết bị viễn thông nối mạng hữu tuyến hoặc không dây (như loại sử dụng trong mạng nội bộ hoặc mạng diện rộng), trừ loại thiết bị truyền hoặc thu của nhóm 84.43, 85.25, 85.27 hoặc 85.28',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Bộ điện thoại, kể cả điện thoại thông minh và điện thoại khác cho mạng di động tế bào hoặc mạng không dây khác:',
    Unit: '',
  },
  {
    HsCode: '85171100',
    Description: '- - Bộ điện thoại hữu tuyến với điện thoại cầm tay không dây',
    Unit: 'chiếc',
  },
  {
    HsCode: '85171300',
    Description: '- - Điện thoại thông minh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85171400',
    Description: '- - Điện thoại khác cho mạng di động tế bào hoặc mạng không dây khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85171800',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Thiết bị khác để phát hoặc nhận tiếng, hình ảnh hoặc dữ liệu khác, kể cả thiết bị thông tin hữu tuyến hoặc không dây (như loại sử dụng trong mạng nội bộ hoặc mạng diện rộng):',
    Unit: '',
  },
  {
    HsCode: '85176100',
    Description: '- - Thiết bị trạm gốc',
    Unit: 'chiếc',
  },
  {
    HsCode: '851762',
    Description:
      '- - Máy thu, đổi và truyền hoặc tái tạo âm thanh, hình ảnh hoặc dạng dữ liệu khác, kể cả thiết bị chuyển mạch và thiết bị định tuyến:',
    Unit: '',
  },
  {
    HsCode: '85176210',
    Description:
      '- - - Thiết bị phát và thu sóng vô tuyến sử dụng cho phiên dịch trực tiếp tại các hội nghị sử dụng nhiều thứ tiếng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176230',
    Description: '- - - Thiết bị chuyển mạch điện báo hoặc điện thoại',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Thiết bị dùng cho hệ thống hữu tuyến sóng mang hoặc hệ thống hữu tuyến kỹ thuật số:',
    Unit: '',
  },
  {
    HsCode: '85176241',
    Description: '- - - - Bộ điều chế/giải điều chế (modems) kể cả loại sử dụng cáp nối và dạng thẻ cắm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176242',
    Description: '- - - - Bộ tập trung hoặc bộ dồn kênh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176243',
    Description:
      '- - - - Bộ điều khiển và bộ thích ứng (adaptor), kể cả cổng nối, cầu nối, bộ định tuyến và các thiết bị tương tự khác được thiết kế chỉ để kết nối với máy xử lý dữ liệu tự động thuộc nhóm 84.71 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176249',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Thiết bị truyền dẫn khác kết hợp với thiết bị thu:',
    Unit: '',
  },
  {
    HsCode: '85176251',
    Description: '- - - - Thiết bị mạng nội bộ không dây ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176252',
    Description: '- - - - Thiết bị phát và thu dùng cho phiên dịch trực tiếp tại các hội nghị sử dụng nhiều thứ tiếng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176253',
    Description: '- - - - Thiết bị phát khác dùng cho điện báo hoặc điện thoại truyền dẫn dưới dạng sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176259',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Thiết bị truyền dẫn khác:',
    Unit: '',
  },
  {
    HsCode: '85176261',
    Description: '- - - - Dùng cho điện báo hoặc điện thoại truyền dẫn dưới dạng sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176269',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85176291',
    Description:
      '- - - - Thiết bị thu xách tay để gọi, báo hiệu hoặc nhắn tin và thiết bị cảnh báo bằng tin nhắn, kể cả máy nhắn tin',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176292',
    Description: '- - - - Dùng cho điện báo hoặc điện thoại truyền dẫn dưới dạng sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85176900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85177100',
    Description: '- - Ăng ten và bộ phản xạ của ăng ten; các bộ phận sử dụng kèm',
    Unit: 'chiếc',
  },
  {
    HsCode: '851779',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85177910',
    Description: '- - - Của bộ điều khiển và bộ thích ứng (adaptor) kể cả cổng nối, cầu nối và bộ định tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Của thiết bị truyền dẫn, trừ loại dùng cho phát thanh sóng vô tuyến hoặc thiết bị phát truyền hình, hoặc của loại thiết bị thu xách tay để gọi, báo hiệu hoặc nhắn tin và thiết bị cảnh báo bằng tin nhắn, kể cả máy nhắn tin:',
    Unit: '',
  },
  {
    HsCode: '85177921',
    Description: '- - - - Của điện thoại di động (cellular telephones)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85177929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Tấm mạch in khác, đã lắp ráp:',
    Unit: '',
  },
  {
    HsCode: '85177931',
    Description: '- - - - Dùng cho điện thoại hoặc điện báo hữu tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85177932',
    Description: '- - - - Dùng cho điện báo hoặc điện thoại truyền dẫn dưới dạng sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85177939',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85177991',
    Description: '- - - - Dùng cho điện thoại hoặc điện báo hữu tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85177992',
    Description: '- - - - Dùng cho điện báo hoặc điện thoại truyền dẫn dưới dạng sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85177999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8518',
    Description:
      'Micro và giá đỡ micro; loa, đã hoặc chưa lắp ráp vào trong vỏ loa; tai nghe có khung chụp qua đầu và tai nghe không có khung chụp qua đầu, có hoặc không ghép nối với một micro, và các bộ gồm có một micro và một hoặc nhiều loa; thiết bị điện khuếch đại âm tần; bộ tăng âm điện',
    Unit: '',
  },
  {
    HsCode: '851810',
    Description: '- Micro và giá đỡ micro:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Micro:',
    Unit: '',
  },
  {
    HsCode: '85181011',
    Description:
      '- - - Micro có dải tần số từ 300 Hz đến 3.400 Hz, với đường kính không quá 10 mm và cao không quá 3 mm, dùng trong viễn thông',
    Unit: 'chiếc',
  },
  {
    HsCode: '85181019',
    Description: '- - - Micro khác, đã hoặc chưa lắp ráp cùng với giá đỡ micro',
    Unit: 'chiếc',
  },
  {
    HsCode: '85181090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loa, đã hoặc chưa lắp vào vỏ loa:',
    Unit: '',
  },
  {
    HsCode: '851821',
    Description: '- - Loa đơn, đã lắp vào vỏ loa:',
    Unit: '',
  },
  {
    HsCode: '85182110',
    Description: '- - - Loa thùng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85182190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851822',
    Description: '- - Bộ loa, đã lắp vào cùng một vỏ loa:',
    Unit: '',
  },
  {
    HsCode: '85182210',
    Description: '- - - Loa thùng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85182290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851829',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85182920',
    Description:
      '- - - Loa, không có vỏ, có dải tần số 300 Hz đến 3.400 Hz, với đường kính không quá 50 mm, dùng trong viễn thông',
    Unit: 'chiếc',
  },
  {
    HsCode: '85182990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851830',
    Description:
      '- Tai nghe có khung chụp qua đầu và tai nghe không có khung chụp qua đầu, có hoặc không nối với một micro, và các bộ gồm một micro và một hoặc nhiều loa:',
    Unit: '',
  },
  {
    HsCode: '85183010',
    Description: '- - Tai nghe có khung chụp qua đầu',
    Unit: 'chiếc',
  },
  {
    HsCode: '85183020',
    Description: '- - Tai nghe không có khung chụp qua đầu',
    Unit: 'chiếc',
  },
  {
    HsCode: '85183040',
    Description: '- - Tay cầm nghe - nói của điện thoại hữu tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bộ micro/loa kết hợp khác:',
    Unit: '',
  },
  {
    HsCode: '85183051',
    Description: '- - - Dùng cho hàng hóa thuộc phân nhóm 8517.13.00 và 8517.14.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '85183059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85183090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851840',
    Description: '- Thiết bị điện khuyếch đại âm tần:',
    Unit: '',
  },
  {
    HsCode: '85184020',
    Description: '- - Sử dụng như bộ lặp (nhắc lại) trong điện thoại hữu tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85184030',
    Description: '- - Sử dụng như bộ lặp (nhắc lại) trong điện thoại, trừ điện thoại hữu tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85184040',
    Description:
      '- - Loại khác, có từ 6 đường tín hiệu đầu vào trở lên, có hoặc không kết hợp với phần tử dùng cho khuếch đại công suất',
    Unit: 'chiếc',
  },
  {
    HsCode: '85184090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851850',
    Description: '- Bộ tăng âm điện:',
    Unit: '',
  },
  {
    HsCode: '85185010',
    Description: '- - Có dải công suất từ 240 W trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85185020',
    Description:
      '- - Loại khác, có loa, loại thích hợp cho phát sóng, có giải điện áp từ 50 V trở lên nhưng không quá 100 V',
    Unit: 'chiếc',
  },
  {
    HsCode: '85185090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851890',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85189010',
    Description:
      '- - Của hàng hóa thuộc phân nhóm 8518.10.11, 8518.29.20, 8518.30.40 hoặc 8518.40.20, kể cả mạch in đã lắp ráp ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85189020',
    Description: '- - Của hàng hóa thuộc phân nhóm 8518.40.40 ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85189030',
    Description: '- - Của hàng hóa thuộc phân nhóm 8518.21 hoặc 8518.22',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85189040',
    Description: '- - Của hàng hóa thuộc phân nhóm 8518.29.90',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85189090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8519',
    Description: 'Thiết bị ghi hoặc tái tạo âm thanh',
    Unit: '',
  },
  {
    HsCode: '851920',
    Description:
      '- Thiết bị hoạt động bằng tiền xu, tiền giấy, thẻ ngân hàng, token hoặc bằng phương tiện thanh toán khác:',
    Unit: '',
  },
  {
    HsCode: '85192010',
    Description: '- - Máy ghi hoạt động bằng đồng xu, token hoặc đĩa',
    Unit: 'chiếc',
  },
  {
    HsCode: '85192090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85193000',
    Description:
      '- Đầu quay đĩa (có thể có bộ phận lưu trữ) nhưng không có bộ phận khuếch đại và không có bộ phận phát âm thanh (loa)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '851981',
    Description: '- - Thiết bị truyền thông sử dụng công nghệ từ tính, quang học hoặc bán dẫn:',
    Unit: '',
  },
  {
    HsCode: '85198110',
    Description: '- - - Máy ghi âm cassette bỏ túi, kích thước không quá 170 mm x 100 mm x 45 mm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198120',
    Description:
      '- - - Máy ghi âm cassette, có bộ phận khuếch đại và một hoặc nhiều loa, hoạt động chỉ bằng nguồn điện ngoài ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198130',
    Description: '- - - Đầu đĩa compact',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Máy sao âm:',
    Unit: '',
  },
  {
    HsCode: '85198141',
    Description: '- - - - Loại dùng cho điện ảnh hoặc phát thanh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198149',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198150',
    Description: '- - - Máy ghi điều lọc (dictating machines), loại chỉ hoạt động bằng nguồn điện ngoài',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Máy ghi băng từ có gắn với thiết bị tái tạo âm thanh, loại âm thanh số:',
    Unit: '',
  },
  {
    HsCode: '85198161',
    Description: '- - - - Loại dùng cho điện ảnh hoặc phát thanh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198162',
    Description: '- - - - Máy trả lời điện thoại',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198169',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Thiết bị tái tạo âm thanh khác, kiểu cassette:',
    Unit: '',
  },
  {
    HsCode: '85198171',
    Description: '- - - - Loại dùng cho điện ảnh hoặc phát thanh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198179',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85198191',
    Description: '- - - - Loại dùng cho điện ảnh hoặc phát thanh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '851989',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85198910',
    Description: '- - - Máy tái tạo âm thanh dùng trong điện ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198920',
    Description: '- - - Máy quay đĩa (record players) có hoặc không có loa ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198930',
    Description: '- - - Loại dùng cho điện ảnh hoặc phát thanh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198940',
    Description: '- - - Thiết bị tái tạo âm thanh khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85198990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8521',
    Description: 'Thiết bị ghi hoặc tái tạo video, có hoặc không gắn bộ phận thu tín hiệu video',
    Unit: '',
  },
  {
    HsCode: '852110',
    Description: '- Loại dùng băng từ:',
    Unit: '',
  },
  {
    HsCode: '85211010',
    Description: '- - Loại dùng cho điện ảnh hoặc phát thanh truyền hình (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85211090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Đầu đĩa laser:',
    Unit: '',
  },
  {
    HsCode: '85219011',
    Description: '- - - Loại dùng cho điện ảnh hoặc phát thanh truyền hình (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85219019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85219091',
    Description: '- - - Loại dùng cho điện ảnh hoặc phát thanh truyền hình (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85219099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8522',
    Description: 'Bộ phận và phụ kiện chỉ dùng hoặc chủ yếu dùng cho các thiết bị của nhóm 85.19 hoặc 85.21',
    Unit: '',
  },
  {
    HsCode: '85221000',
    Description: '- Cụm đầu đọc - ghi',
    Unit: 'chiếc',
  },
  {
    HsCode: '852290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85229020',
    Description: '- - Tấm mạch in đã lắp ráp dùng cho máy trả lời điện thoại',
    Unit: 'chiếc',
  },
  {
    HsCode: '85229030',
    Description: '- - Tấm mạch in đã lắp ráp dùng cho máy ghi hoặc tái tạo âm thanh dùng trong lĩnh vực điện ảnh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85229040',
    Description: '- - Cơ cấu ghi hoặc đọc băng video hoặc audio và đĩa compact',
    Unit: 'chiếc',
  },
  {
    HsCode: '85229050',
    Description: '- - Đầu đọc hình hoặc âm thanh, kiểu từ tính; đầu và thanh xoá từ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85229091',
    Description: '- - - Bộ phận và phụ kiện khác của thiết bị ghi hoặc tái tạo âm thanh dùng trong điện ảnh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85229092',
    Description: '- - - Bộ phận khác của máy trả lời điện thoại',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85229093',
    Description: '- - - Bộ phận và phụ kiện khác của hàng hóa thuộc phân nhóm 8519.81 hoặc nhóm 85.21 ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85229099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8523',
    Description:
      'Đĩa, băng, các thiết bị lưu trữ bền vững, thể rắn, “thẻ thông minh” và các phương tiện lưu trữ thông tin khác để ghi âm thanh hoặc các nội dung, hình thức thể hiện khác, đã hoặc chưa ghi, kể cả bản khuôn mẫu và bản gốc để sản xuất băng đĩa, nhưng không bao gồm các sản phẩm của Chương 37',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Phương tiện lưu trữ thông tin từ tính:',
    Unit: '',
  },
  {
    HsCode: '852321',
    Description: '- - Thẻ có dải từ:',
    Unit: '',
  },
  {
    HsCode: '85232110',
    Description: '- - - Chưa ghi',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852329',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Băng từ, có chiều rộng không quá 4 mm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Loại chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85232911',
    Description: '- - - - - Băng máy tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232919',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85232921',
    Description: '- - - - - Băng video',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232929',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Băng từ, có chiều rộng trên 4 mm nhưng không quá 6,5 mm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Loại chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85232931',
    Description: '- - - - - Băng máy tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232933',
    Description: '- - - - - Băng video',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232939',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85232941',
    Description: '- - - - - Băng máy tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232942',
    Description: '- - - - - Loại dùng cho điện ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232943',
    Description: '- - - - - Băng video khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232949',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Băng từ, có chiều rộng trên 6,5 mm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Loại chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85232951',
    Description: '- - - - - Băng máy tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232952',
    Description: '- - - - - Băng video',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232959',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85232961',
    Description:
      '- - - - - Loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232962',
    Description: '- - - - - Loại dùng cho điện ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232963',
    Description: '- - - - - Băng video khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232969',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Đĩa từ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Loại chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85232971',
    Description: '- - - - - Đĩa cứng và đĩa mềm máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232979',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - - Loại để tái tạo các hiện tượng trừ âm thanh hoặc hình ảnh:',
    Unit: '',
  },
  {
    HsCode: '85232981',
    Description: '- - - - - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232982',
    Description: '- - - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232983',
    Description:
      '- - - - - Loại khác, loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232985',
    Description:
      '- - - - - Loại khác, chứa phim điện ảnh trừ phim thời sự, phim du lịch, phim kỹ thuật, phim khoa học, và phim tài liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232986',
    Description: '- - - - - Loại khác, dùng cho điện ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232989',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Loại chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85232991',
    Description: '- - - - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232992',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - - Loại để tái tạo các hiện tượng trừ âm thanh hoặc hình ảnh:',
    Unit: '',
  },
  {
    HsCode: '85232993',
    Description: '- - - - - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232994',
    Description: '- - - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232995',
    Description:
      '- - - - - Loại khác, loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85232999',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Phương tiện lưu trữ thông tin quang học:',
    Unit: '',
  },
  {
    HsCode: '852341',
    Description: '- - Loại chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85234110',
    Description: '- - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852349',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Đĩa dùng cho hệ thống đọc bằng laser:',
    Unit: '',
  },
  {
    HsCode: '85234911',
    Description: '- - - - Loại sử dụng để tái tạo các hiện tượng trừ âm thanh hoặc hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại chỉ sử dụng để tái tạo âm thanh:',
    Unit: '',
  },
  {
    HsCode: '85234912',
    Description: '- - - - - Đĩa chứa nội dung giáo dục, kỹ thuật, khoa học, lịch sử hoặc văn hóa',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234913',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234914',
    Description:
      '- - - - Loại khác, loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234915',
    Description:
      '- - - - Loại khác, chứa phim điện ảnh trừ phim thời sự, phim du lịch, phim kỹ thuật, phim khoa học, và phim tài liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234916',
    Description: '- - - - Loại khác, dùng cho điện ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85234991',
    Description: '- - - - Loại sử dụng để tái tạo các hiện tượng trừ âm thanh hoặc hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234992',
    Description: '- - - - Loại chỉ sử dụng để tái tạo âm thanh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234993',
    Description:
      '- - - - Loại khác, loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85234999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Phương tiện lưu trữ thông tin bán dẫn:',
    Unit: '',
  },
  {
    HsCode: '852351',
    Description: '- - Các thiết bị lưu trữ bền vững, thể rắn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85235111',
    Description: '- - - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Loại để tái tạo các hiện tượng trừ âm thanh hoặc hình ảnh:',
    Unit: '',
  },
  {
    HsCode: '85235121',
    Description: '- - - - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235129',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235130',
    Description:
      '- - - - Loại khác, loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85235191',
    Description:
      '- - - - - Loại khác, chứa phim điện ảnh trừ phim thời sự, phim du lịch, phim kỹ thuật, phim khoa học, và phim tài liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235192',
    Description: '- - - - - Loại khác, dùng cho điện ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235199',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235200',
    Description: '- - ""Thẻ thông minh""',
    Unit: 'chiếc',
  },
  {
    HsCode: '852359',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85235910',
    Description: '- - - Thẻ không tiếp xúc (dạng ""card"" và dạng ""tag"")(*)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85235921',
    Description: '- - - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85235930',
    Description: '- - - - Loại sử dụng để tái tạo các hiện tượng trừ âm thanh hoặc hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235940',
    Description:
      '- - - - Loại khác, loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85235990',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852380',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85238040',
    Description: '- - Đĩa ghi âm sử dụng kỹ thuật analog',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, chưa ghi:',
    Unit: '',
  },
  {
    HsCode: '85238051',
    Description: '- - - Loại dùng cho máy vi tính',
    Unit: 'chiếc',
  },
  {
    HsCode: '85238059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85238091',
    Description: '- - - Loại sử dụng để tái tạo các hiện tượng trừ âm thanh hoặc hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85238092',
    Description:
      '- - - Loại khác, loại sử dụng để tái tạo các tập lệnh, dữ liệu, âm thanh và hình ảnh, được ghi ở dạng mã nhị phân để máy có thể đọc được, và có thể thao tác hoặc có thể tương tác với người sử dụng, thông qua một máy xử lý dữ liệu tự động; phương tiện lưu trữ được định dạng riêng (đã ghi)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85238099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8524',
    Description: 'Mô-đun màn hình dẹt, có hoặc không tích hợp màn hình cảm ứng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Không có trình điều khiển (driver) hoặc mạch điều khiển:',
    Unit: '',
  },
  {
    HsCode: '85241100',
    Description: '- - Bằng tinh thể lỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85241200',
    Description: '- - Bằng đi-ốt phát quang hữu cơ (OLED)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85241900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85249100',
    Description: '- - Bằng tinh thể lỏng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85249200',
    Description: '- - Bằng đi-ốt phát quang hữu cơ (OLED)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85249900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8525',
    Description:
      'Thiết bị phát dùng cho phát thanh sóng vô tuyến hoặc truyền hình, có hoặc không gắn với thiết bị thu hoặc ghi hoặc tái tạo âm thanh; camera truyền hình, camera kỹ thuật số và camera ghi hình ảnh',
    Unit: '',
  },
  {
    HsCode: '85255000',
    Description: '- Thiết bị phát',
    Unit: 'chiếc',
  },
  {
    HsCode: '85256000',
    Description: '- Thiết bị phát có gắn với thiết bị thu',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Camera truyền hình, camera kỹ thuật số và camera ghi hình ảnh:',
    Unit: '',
  },
  {
    HsCode: '852581',
    Description: '- - Loại tốc độ cao nêu tại Chú giải Phân nhóm 1 của Chương này: ',
    Unit: '',
  },
  {
    HsCode: '85258110',
    Description: '- - - Camera ghi hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258120',
    Description: '- - - Camera truyền hình',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852582',
    Description: '- - Loại khác, được làm cứng bức xạ hoặc chịu bức xạ nêu tại Chú giải Phân nhóm 2 của Chương này: ',
    Unit: '',
  },
  {
    HsCode: '85258210',
    Description: '- - - Camera ghi hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258220',
    Description: '- - - Camera truyền hình',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852583',
    Description: '- - Loại khác, loại nhìn ban đêm nêu tại Chú giải Phân nhóm 3 của Chương này: ',
    Unit: '',
  },
  {
    HsCode: '85258310',
    Description: '- - - Camera ghi hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258320',
    Description: '- - - Camera truyền hình',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852589',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85258910',
    Description: '- - - Camera ghi hình ảnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258920',
    Description: '- - - Camera truyền hình',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258930',
    Description: '- - - Webcam',
    Unit: 'chiếc',
  },
  {
    HsCode: '85258990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8526',
    Description: 'Ra đa, các thiết bị dẫn đường bằng sóng vô tuyến và các thiết bị điều khiển từ xa bằng vô tuyến',
    Unit: '',
  },
  {
    HsCode: '852610',
    Description: '- Ra đa:',
    Unit: '',
  },
  {
    HsCode: '85261010',
    Description:
      '- - Ra đa, loại dùng trên mặt đất, hoặc trang bị trên máy bay dân dụng, hoặc chỉ dùng cho tàu thuyền đi biển',
    Unit: 'chiếc',
  },
  {
    HsCode: '85261090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '852691',
    Description: '- - Thiết bị dẫn đường bằng sóng vô tuyến:',
    Unit: '',
  },
  {
    HsCode: '85269110',
    Description:
      '- - - Thiết bị dẫn đường bằng sóng vô tuyến, loại dùng trên máy bay dân dụng, hoặc chỉ dùng cho tàu thuyền đi biển',
    Unit: 'chiếc',
  },
  {
    HsCode: '85269190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85269200',
    Description: '- - Thiết bị điều khiển từ xa bằng sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '8527',
    Description:
      'Thiết bị thu dùng cho phát thanh sóng vô tuyến, có hoặc không kết hợp với thiết bị ghi hoặc tái tạo âm thanh hoặc với đồng hồ, trong cùng một khối',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Máy thu thanh sóng vô tuyến có thể hoạt động không cần dùng nguồn điện ngoài:',
    Unit: '',
  },
  {
    HsCode: '85271200',
    Description: '- - Radio cát sét loại bỏ túi',
    Unit: 'chiếc',
  },
  {
    HsCode: '852713',
    Description: '- - Thiết bị khác kết hợp với thiết bị ghi hoặc tái tạo âm thanh:',
    Unit: '',
  },
  {
    HsCode: '85271310',
    Description: '- - - Loại xách tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85271390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852719',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85271920',
    Description: '- - - Loại xách tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85271990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Máy thu thanh sóng vô tuyến không thể hoạt động khi không có nguồn điện ngoài, loại dùng cho xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '852721',
    Description: '- - Kết hợp với thiết bị ghi hoặc tái tạo âm thanh:',
    Unit: '',
  },
  {
    HsCode: '85272110',
    Description: '- - - Có khả năng nhận và giải mã tín hiệu hệ thống dữ liệu vô tuyến kỹ thuật số ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85272190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85272900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '852791',
    Description: '- - Kết hợp với thiết bị ghi hoặc tái tạo âm thanh:',
    Unit: '',
  },
  {
    HsCode: '85279110',
    Description: '- - - Loại xách tay',
    Unit: 'chiếc',
  },
  {
    HsCode: '85279190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852792',
    Description: '- - Không kết hợp với thiết bị ghi hoặc tái tạo âm thanh nhưng gắn với đồng hồ:',
    Unit: '',
  },
  {
    HsCode: '85279220',
    Description: '- - - Hoạt động bằng nguồn điện lưới',
    Unit: 'chiếc',
  },
  {
    HsCode: '85279290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852799',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85279920',
    Description: '- - - Hoạt động bằng nguồn điện lưới',
    Unit: 'chiếc',
  },
  {
    HsCode: '85279990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8528',
    Description:
      'Màn hình và máy chiếu, không gắn với thiết bị thu dùng trong truyền hình; thiết bị thu dùng trong truyền hình, có hoặc không gắn với máy thu thanh sóng vô tuyến hoặc thiết bị ghi hoặc tái tạo âm thanh hoặc hình ảnh',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Màn hình sử dụng ống đèn hình tia ca-tốt:',
    Unit: '',
  },
  {
    HsCode: '85284200',
    Description:
      '- - Có khả năng kết nối trực tiếp và được thiết kế để dùng cho máy xử lý dữ liệu tự động thuộc nhóm 84.71',
    Unit: 'chiếc',
  },
  {
    HsCode: '852849',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85284910',
    Description: '- - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '85284920',
    Description: '- - - Loại đơn sắc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Màn hình khác:',
    Unit: '',
  },
  {
    HsCode: '85285200',
    Description:
      '- - Có khả năng kết nối trực tiếp và được thiết kế để dùng cho máy xử lý dữ liệu tự động thuộc nhóm 84.71',
    Unit: 'chiếc',
  },
  {
    HsCode: '852859',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85285910',
    Description: '- - - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '85285920',
    Description: '- - - Loại đơn sắc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Máy chiếu:',
    Unit: '',
  },
  {
    HsCode: '85286200',
    Description:
      '- - Có khả năng kết nối trực tiếp và được thiết kế để dùng cho máy xử lý dữ liệu tự động thuộc nhóm 84.71',
    Unit: 'chiếc',
  },
  {
    HsCode: '852869',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85286910',
    Description: '- - - Công suất chiếu lên màn ảnh có đường chéo từ 300 inch trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85286990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Thiết bị thu dùng trong truyền hình, có hoặc không gắn với thiết bị thu thanh sóng vô tuyến hoặc thiết bị ghi hoặc tái tạo âm thanh hoặc hình ảnh:',
    Unit: '',
  },
  {
    HsCode: '852871',
    Description: '- - Không thiết kế để gắn với thiết bị hiển thị video hoặc màn ảnh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Set top boxes có chức năng tương tác thông tin:',
    Unit: '',
  },
  {
    HsCode: '85287111',
    Description: '- - - - Hoạt động bằng nguồn điện lưới',
    Unit: 'chiếc',
  },
  {
    HsCode: '85287119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85287191',
    Description: '- - - - Hoạt động bằng nguồn điện lưới',
    Unit: 'chiếc',
  },
  {
    HsCode: '85287199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '852872',
    Description: '- - Loại khác, màu:',
    Unit: '',
  },
  {
    HsCode: '85287210',
    Description: '- - - Hoạt động bằng pin',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85287291',
    Description: '- - - - Loại sử dụng ống đèn hình tia ca-tốt ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85287292',
    Description: '- - - - Loại màn hình tinh thể lỏng (LCD), đi-ốt phát quang (LED) và màn hình dẹt khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85287299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85287300',
    Description: '- - Loại khác, đơn sắc',
    Unit: 'chiếc',
  },
  {
    HsCode: '8529',
    Description: 'Bộ phận chỉ dùng hoặc chủ yếu dùng với các thiết bị thuộc các nhóm từ 85.24 đến 85.28',
    Unit: '',
  },
  {
    HsCode: '852910',
    Description: '- Ăng-ten và bộ phản xạ của ăng ten; các bộ phận sử dụng kèm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Chảo phản xạ của ăng ten parabol sử dụng cho hệ phát trực tiếp đa phương tiện và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '85291021',
    Description: '- - - Dùng cho máy thu truyền hình',
    Unit: 'chiếc',
  },
  {
    HsCode: '85291029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85291030',
    Description:
      '- - Ăng ten vệ tinh, ăng ten lưỡng cực và các loại ăng ten roi (rabbit antennae) sử dụng cho máy thu hình hoặc máy thu thanh',
    Unit: 'chiếc',
  },
  {
    HsCode: '85291040',
    Description: '- - Bộ lọc và tách tín hiệu ăng ten',
    Unit: 'chiếc',
  },
  {
    HsCode: '85291060',
    Description: '- - Loa hoặc phễu tiếp sóng (ống dẫn sóng)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85291093',
    Description: '- - - Loại dùng với thiết bị truyền dẫn để phát sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85291094',
    Description: '- - - Loại dùng với thiết bị truyền dẫn truyền hình ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85291099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '852990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85299020',
    Description: '- - Dùng cho bộ giải mã',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85299040',
    Description: '- - Dùng cho máy camera số hoặc camera ghi hình ảnh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Tấm mạch in khác, đã lắp ráp:',
    Unit: '',
  },
  {
    HsCode: '85299051',
    Description: '- - - Dùng cho hàng hóa thuộc phân nhóm 8525.50 hoặc 8525.60',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85299052',
    Description: '- - - Dùng cho hàng hóa thuộc phân nhóm 8527.13, 8527.19, 8527.21, 8527.29, 8527.91 hoặc 8527.99',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho hàng hóa thuộc nhóm 85.28:',
    Unit: '',
  },
  {
    HsCode: '85299053',
    Description: '- - - - Dùng cho màn hình dẹt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85299054',
    Description: '- - - - Loại khác, dùng cho máy thu truyền hình',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85299055',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85299059',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85299091',
    Description: '- - - Dùng cho máy thu truyền hình',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85299094',
    Description: '- - - Loại khác, dùng cho màn hình dẹt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85299099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8530',
    Description:
      'Thiết bị điện phát tín hiệu thông tin, bảo đảm an toàn hoặc điều khiển giao thông, dùng cho đường sắt, đường tàu điện, đường bộ, đường thủy nội địa, điểm dừng đỗ, cảng hoặc sân bay (trừ loại thuộc nhóm 86.08)',
    Unit: '',
  },
  {
    HsCode: '85301000',
    Description: '- Thiết bị dùng cho đường sắt hoặc đường tàu điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '85308000',
    Description: '- Thiết bị khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85309000',
    Description: '- Bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8531',
    Description:
      'Thiết bị báo hiệu bằng âm thanh hoặc hình ảnh (ví dụ, chuông, còi báo, bảng chỉ báo, báo động chống trộm hoặc báo cháy), trừ các thiết bị thuộc nhóm 85.12 hoặc 85.30',
    Unit: '',
  },
  {
    HsCode: '853110',
    Description: '- Báo động chống trộm hoặc báo cháy và các thiết bị tương tự:',
    Unit: '',
  },
  {
    HsCode: '85311010',
    Description: '- - Báo trộm',
    Unit: 'chiếc',
  },
  {
    HsCode: '85311020',
    Description: '- - Báo cháy',
    Unit: 'chiếc',
  },
  {
    HsCode: '85311030',
    Description: '- - Báo khói; chuông báo động cá nhân bỏ túi (còi rú)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85311090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85312000',
    Description: '- Bảng chỉ báo có gắn màn hình tinh thể lỏng (LCD) hoặc đi-ốt phát quang (LED)',
    Unit: 'chiếc',
  },
  {
    HsCode: '853180',
    Description: '- Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Chuông điện tử và các thiết bị báo hiệu bằng âm thanh khác: ',
    Unit: '',
  },
  {
    HsCode: '85318011',
    Description: '- - - Chuông cửa và các thiết bị báo hiệu bằng âm thanh khác dùng cho cửa ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85318019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Màn hình dẹt (kể cả loại công nghệ quang điện tử, plasma và công nghệ khác):',
    Unit: '',
  },
  {
    HsCode: '85318021',
    Description: '- - - Màn hình sử dụng huỳnh quang chân không',
    Unit: 'chiếc',
  },
  {
    HsCode: '85318029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85318090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853190',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85319010',
    Description: '- - Bộ phận kể cả mạch in đã lắp ráp của phân nhóm 8531.20, 8531.80.21 hoặc 8531.80.29 ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85319020',
    Description: '- - Của chuông cửa hoặc các thiết bị báo hiệu bằng âm thanh khác dùng cho cửa ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85319030',
    Description: '- - Của chuông hoặc các thiết bị báo hiệu bằng âm thanh khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85319090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8532',
    Description: 'Tụ điện, loại có điện dung cố định, biến đổi hoặc điều chỉnh được (theo mức định trước)',
    Unit: '',
  },
  {
    HsCode: '85321000',
    Description:
      '- Tụ điện cố định được thiết kế dùng trong mạch có tần số 50/60 Hz và có giới hạn công suất phản kháng cho phép không dưới 0,5 kvar (tụ nguồn)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Tụ điện cố định khác:',
    Unit: '',
  },
  {
    HsCode: '85322100',
    Description: '- - Tụ tantan (tantalum)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85322200',
    Description: '- - Tụ nhôm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85322300',
    Description: '- - Tụ gốm, một lớp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85322400',
    Description: '- - Tụ gốm, nhiều lớp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85322500',
    Description: '- - Tụ giấy hoặc plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85322900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85323000',
    Description: '- Tụ điện biến đổi hoặc tụ điện điều chỉnh được (theo mức định trước)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85329000',
    Description: '- Bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8533',
    Description: 'Điện trở (kể cả biến trở và chiết áp), trừ điện trở nung nóng',
    Unit: '',
  },
  {
    HsCode: '853310',
    Description: '- Điện trở than cố định, dạng kết hợp hoặc dạng màng:',
    Unit: '',
  },
  {
    HsCode: '85331010',
    Description: '- - Điện trở dán',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85331090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Điện trở cố định khác:',
    Unit: '',
  },
  {
    HsCode: '85332100',
    Description: '- - Có công suất danh định không quá 20 W',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85332900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Điện trở biến đổi kiểu dây quấn, kể cả biến trở và chiết áp:',
    Unit: '',
  },
  {
    HsCode: '85333100',
    Description: '- - Có công suất danh định không quá 20 W',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85333900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85334000',
    Description: '- Điện trở biến đổi khác, kể cả biến trở và chiết áp ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85339000',
    Description: '- Bộ phận ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8534',
    Description: 'Mạch in',
    Unit: '',
  },
  {
    HsCode: '85340010',
    Description: '- Một mặt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85340020',
    Description: '- Hai mặt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85340030',
    Description: '- Nhiều lớp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85340090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8535',
    Description:
      'Thiết bị điện để đóng ngắt mạch hoặc bảo vệ mạch điện, hoặc dùng để đấu nối hoặc lắp trong mạch điện (ví dụ, cầu dao, công tắc, cầu chì, bộ chống sét, bộ khống chế điện áp, bộ triệt xung điện, phích cắm và đầu nối khác, hộp đấu nối), dùng cho điện áp trên 1.000 V',
    Unit: '',
  },
  {
    HsCode: '85351000',
    Description: '- Cầu chì',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ ngắt mạch tự động:',
    Unit: '',
  },
  {
    HsCode: '853521',
    Description: '- - Có điện áp dưới 72,5 kV:',
    Unit: '',
  },
  {
    HsCode: '85352110',
    Description: '- - - Loại hộp đúc',
    Unit: 'chiếc',
  },
  {
    HsCode: '85352120',
    Description: '- - - Bộ ngắt mạch chống dòng rò tiếp (chạm) đất ELCB',
    Unit: 'chiếc',
  },
  {
    HsCode: '85352190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853529',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85352910',
    Description: '- - - Bộ ngắt mạch chống dòng rò tiếp (chạm) đất ELCB',
    Unit: 'chiếc',
  },
  {
    HsCode: '85352990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853530',
    Description: '- Cầu dao cách ly và thiết bị đóng - ngắt điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho điện áp trên 1 kV nhưng không quá 40 kV:',
    Unit: '',
  },
  {
    HsCode: '85353011',
    Description: '- - - Thiết bị ngắt có điện áp dưới 36 kV',
    Unit: 'chiếc',
  },
  {
    HsCode: '85353019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85353020',
    Description: '- - Dùng cho điện áp từ 66 kV trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85353090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85354000',
    Description: '- Bộ chống sét, bộ khống chế điện áp và bộ triệt xung điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '853590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85359010',
    Description:
      '- - Đầu nối đã lắp ráp và bộ chuyển đổi theo nấc dùng cho máy biến áp phân phối hoặc biến áp nguồn (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85359020',
    Description: '- - Công tắc đảo chiều (change-over switches) loại dùng khởi động động cơ điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '85359090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8536',
    Description:
      'Thiết bị điện để đóng ngắt mạch hoặc bảo vệ mạch điện, hoặc dùng để đấu nối hoặc lắp trong mạch điện (ví dụ, cầu dao, rơ le, công tắc, chi tiết đóng ngắt mạch, cầu chì, bộ triệt xung điện, phích cắm, ổ cắm, đui đèn và các đầu nối khác, hộp đấu nối), dùng cho điện áp không quá 1.000V; đầu nối dùng cho sợi quang, bó sợi quang hoặc cáp quang',
    Unit: '',
  },
  {
    HsCode: '853610',
    Description: '- Cầu chì:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Cầu chì nhiệt; cầu chì thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '85361011',
    Description: '- - - Thích hợp dùng cho quạt điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '85361012',
    Description: '- - - Loại khác, dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85361013',
    Description: '- - - Khối cầu chì, loại sử dụng cho xe có động cơ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85361019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85361091',
    Description: '- - - Thích hợp dùng cho quạt điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '85361092',
    Description: '- - - Loại khác, dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85361093',
    Description: '- - - Khối cầu chì, loại sử dụng cho xe có động cơ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85361099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853620',
    Description: '- Bộ ngắt mạch tự động:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại hộp đúc:',
    Unit: '',
  },
  {
    HsCode: '85362011',
    Description: '- - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85362012',
    Description: '- - - Dòng điện từ 16 A trở lên nhưng không quá 32 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85362013',
    Description: '- - - Dòng điện trên 32 A nhưng không quá 1.000 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85362019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85362020',
    Description: '- - Để lắp ghép với thiết bị nhiệt điện gia dụng thuộc nhóm 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85362091',
    Description: '- - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85362099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853630',
    Description: '- Thiết bị bảo vệ mạch điện khác:',
    Unit: '',
  },
  {
    HsCode: '85363010',
    Description: '- - Bộ chống sét',
    Unit: 'chiếc',
  },
  {
    HsCode: '85363020',
    Description: '- - Loại sử dụng trong các thiết bị sóng vô tuyến hoặc quạt điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '85363090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Rơ le:',
    Unit: '',
  },
  {
    HsCode: '853641',
    Description: '- - Dùng cho điện áp không quá 60 V:',
    Unit: '',
  },
  {
    HsCode: '85364110',
    Description: '- - - Rơ le kỹ thuật số',
    Unit: 'chiếc',
  },
  {
    HsCode: '85364120',
    Description: '- - - Của loại sử dụng trong các thiết bị sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85364130',
    Description: '- - - Của loại sử dụng cho quạt điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '85364140',
    Description: '- - - Loại khác, dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85364191',
    Description: '- - - - Rơ le bán dẫn hoặc rơ le điện từ, điện áp không quá 28 V',
    Unit: 'chiếc',
  },
  {
    HsCode: '85364199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853649',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85364910',
    Description: '- - - Rơ le kỹ thuật số',
    Unit: 'chiếc',
  },
  {
    HsCode: '85364990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853650',
    Description: '- Thiết bị đóng ngắt mạch khác:',
    Unit: '',
  },
  {
    HsCode: '85365020',
    Description: '- - Loại tự động ngắt mạch khi có hiện tượng rò điện và quá tải',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Loại ngắt mạch khi có sốc dòng điện cao (SEN); loại đảo mạch dùng cho bếp và bếp có lò nướng(SEN); công tắc micro; công tắc nguồn cho máy thu truyền hình hoặc thu sóng vô tuyến; công tắc cho quạt điện; công tắc xoay, công tắc trượt, công tắc bập bênh và công tắc từ cho máy điều hòa không khí:',
    Unit: '',
  },
  {
    HsCode: '85365032',
    Description: '- - - Của loại thích hợp dùng cho quạt điện hoặc thiết bị sóng vô tuyến ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85365033',
    Description: '- - - Loại khác, dùng cho dòng điện danh định dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85365039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85365040',
    Description: '- - Công tắc mini thích hợp dùng cho nồi cơm điện hoặc lò nướng (toaster ovens)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Công tắc điện tử dùng cho mạch xoay chiều gồm mạch vào và mạch ra kết nối điều khiển bằng quang học (công tắc dùng cho mạch xoay chiều điều khiển bằng thyristor); công tắc điện tử, kể cả công tắc bảo vệ quá nhiệt điện tử gồm một tranzito và một chip logic (công nghệ chip-on-chip) dùng cho điện áp không quá 1.000 V; công tắc điện cơ bật nhanh dùng cho dòng điện không quá 11 A:',
    Unit: '',
  },
  {
    HsCode: '85365051',
    Description: '- - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85365059',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Loại khác, loại đóng ngắt mạch dùng trong mạng điện gia dụng điện áp không quá 500 V và giới hạn dòng danh định không quá 20 A:',
    Unit: '',
  },
  {
    HsCode: '85365061',
    Description: '- - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85365069',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85365095',
    Description:
      '- - - Công tắc đảo chiều (change-over switches) loại dùng khởi động động cơ điện; thiết bị đóng cắt có cầu chì (fuse switches)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85365096',
    Description: '- - - Loại khác, dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85365099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đui đèn, phích cắm và ổ cắm:',
    Unit: '',
  },
  {
    HsCode: '853661',
    Description: '- - Đui đèn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho đèn com-pắc hoặc đèn halogen:',
    Unit: '',
  },
  {
    HsCode: '85366111',
    Description: '- - - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85366119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85366191',
    Description: '- - - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85366199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853669',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85366910',
    Description: '- - - Phích cắm điện thoại',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Đầu cắm tín hiệu hình/tiếng và đầu cắm ống đèn tia ca-tốt dùng cho máy thu hình hoặc máy thu thanh:',
    Unit: '',
  },
  {
    HsCode: '85366923',
    Description: '- - - - Dòng điện không quá 1,5 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85366924',
    Description: '- - - - Dòng điện trên 1,5 A nhưng dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85366929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Đầu cắm và phích cắm cho cáp đồng trục và mạch in:',
    Unit: '',
  },
  {
    HsCode: '85366932',
    Description: '- - - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85366939',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85366992',
    Description: '- - - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85366999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853670',
    Description: '- Đầu nối dùng cho sợi quang, bó sợi quang hoặc cáp quang: ',
    Unit: '',
  },
  {
    HsCode: '85367010',
    Description: '- - Bằng gốm ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85367020',
    Description: '- - Bằng đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85367090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853690',
    Description: '- Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Đầu nối và các bộ phận dùng để nối cho dây dẫn và cáp; đầu dò tiếp xúc dùng cho tấm wafer:',
    Unit: '',
  },
  {
    HsCode: '85369012',
    Description: '- - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85369019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Hộp đấu nối:',
    Unit: '',
  },
  {
    HsCode: '85369022',
    Description: '- - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85369029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - Đầu nối cáp gồm một phích cắm kiểu giắc, đầu cuối có hoặc không có chân cắm, đầu nối, hoặc bộ thích ứng (adaptor) sử dụng cho cáp đồng trục:',
    Unit: '',
  },
  {
    HsCode: '85369032',
    Description: '- - - Dòng điện dưới 16 A',
    Unit: 'chiếc',
  },
  {
    HsCode: '85369039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85369040',
    Description: '- - Kẹp pin dùng cho xe có động cơ thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dòng điện dưới 16 A:',
    Unit: '',
  },
  {
    HsCode: '85369093',
    Description: '- - - - Phiến đầu nối và khối đấu nối cáp điện thoại ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85369094',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85369099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8537',
    Description:
      'Bảng, panel, giá đỡ, bàn, tủ và các loại hộp và đế khác, được lắp với hai hoặc nhiều thiết bị thuộc nhóm 85.35 hoặc 85.36, dùng để điều khiển hoặc phân phối điện, kể cả các loại trên có lắp các dụng cụ hoặc thiết bị thuộc Chương 90, và các thiết bị điều khiển số, trừ các thiết bị chuyển mạch thuộc nhóm 85.17',
    Unit: '',
  },
  {
    HsCode: '853710',
    Description: '- Dùng cho điện áp không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bảng chuyển mạch và bảng điều khiển:',
    Unit: '',
  },
  {
    HsCode: '85371011',
    Description: '- - - Bảng điều khiển của loại thích hợp sử dụng cho hệ thống điều khiển phân tán',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371012',
    Description: '- - - Bảng điều khiển có trang bị bộ xử lý lập trình (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371013',
    Description:
      '- - - Bảng điều khiển khác của loại thích hợp dùng cho hàng hóa của nhóm 84.15, 84.18, 84.50, 85.08, 85.09 hoặc 85.16 (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371020',
    Description:
      '- - Bảng phân phối (gồm cả panel đỡ và tấm đỡ phẳng) chỉ dùng hoặc chủ yếu dùng với các hàng hóa thuộc nhóm 84.71, 85.17 hoặc 85.25',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371030',
    Description:
      '- - Bộ điều khiển logic có khả năng lập trình cho máy tự động để dịch chuyển, xử lý và kẹp giữ khuôn cho thiết bị bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371040',
    Description:
      '- - Bộ điều khiển động cơ có điện áp đầu ra từ 24V đến 120VDC và có cường độ dòng điện từ 300A đến 500A ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85371091',
    Description: '- - - Loại sử dụng trong các thiết bị sóng vô tuyến hoặc quạt điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371092',
    Description: '- - - Loại phù hợp sử dụng cho hệ thống điều khiển phân tán',
    Unit: 'chiếc',
  },
  {
    HsCode: '85371099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853720',
    Description: '- Dùng cho điện áp trên 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bảng chuyển mạch:',
    Unit: '',
  },
  {
    HsCode: '85372011',
    Description: '- - - Gắn với thiết bị điện để ngắt, nối hoặc bảo vệ mạch điện có điện áp từ 66 kV trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85372019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bảng điều khiển:',
    Unit: '',
  },
  {
    HsCode: '85372021',
    Description: '- - - Gắn với thiết bị điện để ngắt, nối hoặc bảo vệ mạch điện có điện áp từ 66 kV trở lên',
    Unit: 'chiếc',
  },
  {
    HsCode: '85372029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85372090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8538',
    Description: 'Bộ phận chỉ dùng hoặc chủ yếu dùng với các thiết bị thuộc nhóm 85.35, 85.36 hoặc 85.37.',
    Unit: '',
  },
  {
    HsCode: '853810',
    Description:
      '- Bảng, panel, giá đỡ, bàn, tủ và các loại hộp và đế khác dùng cho các mặt hàng thuộc nhóm 85.37, chưa được lắp đặt các thiết bị của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho điện áp không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '85381011',
    Description:
      '- - - Bộ phận của bộ điều khiển logic có khả năng lập trình cho máy tự động để dịch chuyển, xử lý và kẹp giữ khuôn cho thiết bị bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '85381012',
    Description: '- - - Của loại sử dụng trong các thiết bị sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85381019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho điện áp trên 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '85381021',
    Description:
      '- - - Bộ phận của bộ điều khiển logic có khả năng lập trình cho máy tự động để dịch chuyển, xử lý và kẹp giữ khuôn cho thiết bị bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '85381022',
    Description: '- - - Của loại sử dụng trong các thiết bị sóng vô tuyến',
    Unit: 'chiếc',
  },
  {
    HsCode: '85381029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho điện áp không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '85389011',
    Description: '- - - Bộ phận của hàng hóa thuộc phân nhóm 8536.69.10, 8536.90.12 hoặc 8536.90.19',
    Unit: 'chiếc',
  },
  {
    HsCode: '85389012',
    Description: '- - - Bộ phận của hàng hóa thuộc phân nhóm 8536.50.51, 8536.50.59, 8536.69.32 hoặc 8536.69.39',
    Unit: 'chiếc',
  },
  {
    HsCode: '85389013',
    Description: '- - - Bộ phận của hàng hóa thuộc phân nhóm 8537.10.20 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85389019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85389020',
    Description: '- - Điện áp trên 1.000 V',
    Unit: 'chiếc',
  },
  {
    HsCode: '8539',
    Description:
      'Bóng đèn dây tóc hoặc bóng đèn phóng điện, kể cả đèn pha gắn kín và bóng đèn tia cực tím hoặc tia hồng ngoại; bóng đèn hồ quang; nguồn sáng đi-ốt phát quang (LED)',
    Unit: '',
  },
  {
    HsCode: '853910',
    Description: '- Đèn pha gắn kín (sealed beam lamp units):',
    Unit: '',
  },
  {
    HsCode: '85391010',
    Description: '- - Dùng cho xe có động cơ thuộc Chương 87',
    Unit: 'chiếc',
  },
  {
    HsCode: '85391090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các loại bóng đèn dây tóc khác, trừ bóng đèn tia cực tím hoặc tia hồng ngoại:',
    Unit: '',
  },
  {
    HsCode: '853921',
    Description: '- - Bóng đèn ha-lo-gien vonfram:',
    Unit: '',
  },
  {
    HsCode: '85392120',
    Description: '- - - Dùng cho thiết bị y tế',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392130',
    Description: '- - - Dùng cho xe có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392140',
    Description: '- - - Bóng đèn phản xạ khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853922',
    Description: '- - Loại khác, có công suất không quá 200 W và điện áp trên 100 V:',
    Unit: '',
  },
  {
    HsCode: '85392220',
    Description: '- - - Dùng cho thiết bị y tế',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Bóng đèn phản xạ khác:',
    Unit: '',
  },
  {
    HsCode: '85392231',
    Description: '- - - - Loại dùng trong chiếu sáng trang trí, công suất không quá 60 W',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392232',
    Description: '- - - - Loại dùng trong chiếu sáng trang trí, công suất trên 60 W',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392233',
    Description: '- - - - Loại khác, dùng cho chiếu sáng gia dụng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392239',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85392291',
    Description: '- - - - Loại dùng trong chiếu sáng trang trí, công suất không quá 60 W',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392293',
    Description: '- - - - Loại khác, dùng cho chiếu sáng gia dụng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392299',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853929',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85392910',
    Description: '- - - Dùng cho thiết bị y tế',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392920',
    Description: '- - - Dùng cho xe có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392930',
    Description: '- - - Bóng đèn phản xạ khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Bóng đèn flash; bóng đèn hiệu cỡ nhỏ, danh định đến 2,25 V:',
    Unit: '',
  },
  {
    HsCode: '85392941',
    Description: '- - - - Loại thích hợp dùng cho thiết bị y tế',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392949',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392950',
    Description: '- - - Loại khác, có công suất trên 200 W nhưng không quá 300 W và điện áp trên 100 V',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392960',
    Description: '- - - Loại khác, có công suất không quá 200 W và điện áp không quá 100 V',
    Unit: 'chiếc',
  },
  {
    HsCode: '85392990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bóng đèn phóng, trừ đèn tia cực tím:',
    Unit: '',
  },
  {
    HsCode: '853931',
    Description: '- - Bóng đèn huỳnh quang, ca-tốt nóng:',
    Unit: '',
  },
  {
    HsCode: '85393110',
    Description: '- - - Ống huỳnh quang dùng cho đèn huỳnh quang com-pắc',
    Unit: 'chiếc',
  },
  {
    HsCode: '85393120',
    Description: '- - - Loại khác, dạng ống thẳng dùng cho đèn huỳnh quang khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85393130',
    Description: '- - - Bóng đèn huỳnh quang com-pắc đã có chấn lưu lắp liền (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85393190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85393200',
    Description: '- - Bóng đèn hơi thủy ngân hoặc natri; bóng đèn ha-lo-gien kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '853939',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85393910',
    Description: '- - - Ống huỳnh quang dùng cho đèn huỳnh quang com-pắc',
    Unit: 'chiếc',
  },
  {
    HsCode: '85393920',
    Description: '- - - Bóng đèn huỳnh quang ca-tốt lạnh (CCFLs) để chiếu sáng từ phía sau của màn hình dẹt',
    Unit: 'chiếc',
  },
  {
    HsCode: '85393940',
    Description: '- - - Bóng đèn huỳnh quang ca-tốt lạnh khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85393990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bóng đèn tia cực tím hoặc bóng đèn tia hồng ngoại; bóng đèn hồ quang:',
    Unit: '',
  },
  {
    HsCode: '85394100',
    Description: '- - Bóng đèn hồ quang',
    Unit: 'chiếc',
  },
  {
    HsCode: '85394900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Nguồn sáng đi-ốt phát quang (LED):',
    Unit: '',
  },
  {
    HsCode: '85395100',
    Description: '- - Mô-đun đi-ốt phát quang (LED)',
    Unit: 'chiếc',
  },
  {
    HsCode: '853952',
    Description: '- - Bóng đèn đi-ốt phát quang (LED):',
    Unit: '',
  },
  {
    HsCode: '85395210',
    Description: '- - - Loại đầu đèn ren xoáy',
    Unit: 'chiếc',
  },
  {
    HsCode: '85395290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '853990',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85399010',
    Description: '- - Nắp bịt nhôm dùng cho bóng đèn huỳnh quang; đui xoáy nhôm dùng cho bóng đèn dây tóc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85399020',
    Description: '- - Loại khác, dùng cho đèn của xe có động cơ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85399030',
    Description: '- - Loại khác, của phân nhóm 8539.51.00',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85399090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8540',
    Description:
      'Đèn điện tử và ống điện tử dùng nhiệt điện tử, ca-tốt lạnh hoặc ca-tốt quang điện (ví dụ, đèn điện tử và ống đèn chân không hoặc nạp khí hoặc hơi, đèn và ống điện tử chỉnh lưu hồ quang thủy ngân, ống điện tử dùng tia ca-tốt, ống điện tử camera truyền hình)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ống đèn hình vô tuyến dùng tia ca-tốt, kể cả ống đèn hình của màn hình video dùng tia ca-tốt:',
    Unit: '',
  },
  {
    HsCode: '85401100',
    Description: '- - Loại màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '85401200',
    Description: '- - Loại đơn sắc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85402000',
    Description:
      '- Ống camera truyền hình; bộ chuyển đổi hình ảnh và bộ tăng cường hình ảnh; ống đèn ca-tốt quang điện khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '854040',
    Description:
      '- Ống hiển thị dữ liệu/đồ họa, đơn sắc; ống hiển thị/đồ họa, loại màu, với điểm lân quang có bước nhỏ hơn 0,4 mm:',
    Unit: '',
  },
  {
    HsCode: '85404010',
    Description: '- - Ống hiển thị dữ liệu/đồ họa, loại màu, của loại dùng cho hàng hóa thuộc nhóm 85.25',
    Unit: 'chiếc',
  },
  {
    HsCode: '85404090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85406000',
    Description: '- Ống tia ca-tốt khác ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Ống đèn vi sóng (ví dụ, magnetrons, klystrons, ống đèn sóng lan truyền, carcinotrons), trừ ống đèn điều khiển lưới:',
    Unit: '',
  },
  {
    HsCode: '85407100',
    Description: '- - Magnetrons',
    Unit: 'chiếc',
  },
  {
    HsCode: '85407900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đèn điện tử và ống điện tử khác: ',
    Unit: '',
  },
  {
    HsCode: '85408100',
    Description: '- - Đèn điện tử và ống điện tử của máy thu hoặc máy khuếch đại',
    Unit: 'chiếc',
  },
  {
    HsCode: '85408900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85409100',
    Description: '- - Của ống đèn tia ca-tốt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85409900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8541',
    Description:
      'Thiết bị bán dẫn (ví dụ, đi-ốt, bóng bán dẫn (tranzito), bộ chuyển đổi dựa trên chất bán dẫn); thiết bị bán dẫn cảm quang, kể cả tế bào quang điện đã hoặc chưa lắp ráp thành các mảng mô-đun hoặc thành bảng; đi-ốt phát quang (LED), đã hoặc chưa lắp ráp với đi-ốt phát quang (LED) khác; tinh thể áp điện đã lắp ráp',
    Unit: '',
  },
  {
    HsCode: '85411000',
    Description: '- Đi-ốt, trừ đi-ốt cảm quang hoặc đi-ốt phát quang (LED)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tranzito, trừ tranzito cảm quang:',
    Unit: '',
  },
  {
    HsCode: '85412100',
    Description: '- - Có tỷ lệ tiêu tán năng lượng dưới 1 W',
    Unit: 'chiếc',
  },
  {
    HsCode: '85412900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85413000',
    Description: '- Thyristors, diacs và triacs, trừ thiết bị cảm quang',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Thiết bị bán dẫn cảm quang, kể cả tế bào quang điện đã hoặc chưa lắp ráp thành các mảng mô-đun hoặc thành bảng; đi-ốt phát quang (LED):',
    Unit: '',
  },
  {
    HsCode: '85414100',
    Description: '- - Đi-ốt phát quang (LED)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85414200',
    Description: '- - Tế bào quang điện chưa lắp ráp thành các mảng mô-đun hoặc thành bảng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85414300',
    Description: '- - Tế bào quang điện đã lắp ráp thành các mảng mô-đun hoặc thành bảng',
    Unit: 'chiếc',
  },
  {
    HsCode: '85414900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thiết bị bán dẫn khác:',
    Unit: '',
  },
  {
    HsCode: '85415100',
    Description: '- - Thiết bị chuyển đổi dựa trên chất bán dẫn',
    Unit: 'chiếc',
  },
  {
    HsCode: '85415900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85416000',
    Description: '- Tinh thể áp điện đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '85419000',
    Description: '- Bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8542',
    Description: 'Mạch điện tử tích hợp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Mạch điện tử tích hợp:',
    Unit: '',
  },
  {
    HsCode: '85423100',
    Description:
      '- - Đơn vị xử lý và đơn vị điều khiển, có hoặc không kết hợp với bộ nhớ, bộ chuyển đổi, mạch logic, khuếch đại, đồng hồ thời gian và mạch định giờ, hoặc các mạch khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85423200',
    Description: '- - Bộ nhớ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85423300',
    Description: '- - Mạch khuếch đại',
    Unit: 'chiếc',
  },
  {
    HsCode: '85423900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85429000',
    Description: '- Bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8543',
    Description: 'Máy và thiết bị điện, có chức năng riêng, chưa được chi tiết hoặc ghi ở nơi khác trong Chương này',
    Unit: '',
  },
  {
    HsCode: '85431000',
    Description: '- Máy gia tốc hạt',
    Unit: 'chiếc',
  },
  {
    HsCode: '85432000',
    Description: '- Máy phát tín hiệu ',
    Unit: 'chiếc',
  },
  {
    HsCode: '854330',
    Description: '- Máy và thiết bị dùng trong mạ điện, điện phân hoặc điện di:',
    Unit: '',
  },
  {
    HsCode: '85433020',
    Description:
      '- - Thiết bị xử lý ướt bằng phương pháp nhúng trong dung dịch hóa chất hoặc điện hóa, để tách hoặc không tách vật liệu gia công trên nền (tấm đế) của bản mạch PCB/PWBs(1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85433091',
    Description: '- - - Máy mạ điện và điện phân chỉ dùng hoặc chủ yếu dùng để sản xuất mạch in ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85433099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85434000',
    Description: '- Thiết bị điện tử dùng cho thuốc lá điện tử và các thiết bị điện hóa hơi cá nhân tương tự ',
    Unit: 'chiếc',
  },
  {
    HsCode: '854370',
    Description: '- Máy và thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '85437010',
    Description: '- - Máy cung cấp năng lượng cho hàng rào điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị điều khiển từ xa, trừ thiết bị điều khiển từ xa bằng sóng radio:',
    Unit: '',
  },
  {
    HsCode: '85437021',
    Description:
      '- - - Thiết bị điều khiển từ xa hồng ngoại không dây dùng cho máy chơi trò chơi điện tử video, trừ bộ điều khiển trò chơi thuộc nhóm 95.04 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85437029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '85437030',
    Description: '- - Máy, thiết bị điện có chức năng phiên dịch hoặc từ điển',
    Unit: 'chiếc',
  },
  {
    HsCode: '85437040',
    Description:
      '- - Máy tách bụi hoặc khử tĩnh điện trong quá trình chế tạo tấm mạch PCB/PWBs(1) hoặc PCAs(1); máy làm đóng rắn vật liệu bằng tia cực tím dùng trong sản xuất tấm mạch PCB/PWBs(1) hoặc PCAs(1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85437050',
    Description:
      '- - Bộ khuếch đại vi sóng; máy ghi dữ liệu chuyến bay kỹ thuật số; đầu đọc điện tử di động hoạt động bằng pin để ghi và tái tạo văn bản, hình ảnh tĩnh hoặc tệp âm thanh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '85437060',
    Description:
      '- - Thiết bị xử lý tín hiệu kỹ thuật số có khả năng kết nối với mạng có dây hoặc không dây để trộn âm thanh; các sản phẩm được thiết kế đặc biệt để kết nối với thiết bị hoặc dụng cụ điện tín hoặc điện thoại hoặc với mạng điện tín hoặc điện thoại ',
    Unit: 'chiếc',
  },
  {
    HsCode: '85437090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '854390',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '85439010',
    Description: '- - Của hàng hóa thuộc phân nhóm 8543.10 hoặc 8543.20',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85439020',
    Description: '- - Của hàng hóa thuộc phân nhóm 8543.30.20 ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85439030',
    Description: '- - Của hàng hóa thuộc phân nhóm 8543.70.30 ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85439040',
    Description: '- - Của hàng hóa thuộc phân nhóm 8543.70.40',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85439090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8544',
    Description:
      'Dây điện, cáp điện (kể cả cáp đồng trục) có cách điện (kể cả loại đã tráng men cách điện hoặc mạ lớp cách điện) và các vật dẫn(*) có cách điện khác, đã hoặc chưa gắn với đầu nối; cáp sợi quang, làm bằng các bó sợi đơn có vỏ bọc riêng biệt từng sợi, có hoặc không gắn với dây dẫn điện hoặc gắn với đầu nối',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dây đơn dạng cuộn:',
    Unit: '',
  },
  {
    HsCode: '854411',
    Description: '- - Bằng đồng:',
    Unit: '',
  },
  {
    HsCode: '85441120',
    Description: '- - - Có một lớp phủ ngoài hoặc bọc bằng giấy, vật liệu dệt hoặc poly(vinyl clorua)',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85441130',
    Description: '- - - Có lớp phủ ngoài bằng dầu bóng (lacquer)',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85441140',
    Description: '- - - Có lớp phủ ngoài bằng men tráng (enamel)',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85441190',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85441900',
    Description: '- - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '854420',
    Description: '- Cáp đồng trục và các dây dẫn điện đồng trục khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Cáp cách điện đã gắn với đầu nối, dùng cho điện áp không quá 66 kV:',
    Unit: '',
  },
  {
    HsCode: '85442011',
    Description: '- - - Cách điện bằng cao su hoặc plastic',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85442019',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Cáp cách điện chưa gắn với đầu nối, dùng cho điện áp không quá 66 kV:',
    Unit: '',
  },
  {
    HsCode: '85442021',
    Description: '- - - Cách điện bằng cao su hoặc plastic',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85442029',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Cáp cách điện đã gắn với đầu nối, dùng cho điện áp trên 66 kV:',
    Unit: '',
  },
  {
    HsCode: '85442031',
    Description: '- - - Cách điện bằng cao su hoặc plastic',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85442039',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Cáp cách điện chưa gắn với đầu nối, dùng cho điện áp trên 66 kV:',
    Unit: '',
  },
  {
    HsCode: '85442041',
    Description: '- - - Cách điện bằng cao su hoặc plastic',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85442049',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '854430',
    Description: '- Bộ dây đánh lửa và bộ dây khác loại sử dụng cho xe, phương tiện bay hoặc tàu thuyền:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bộ dây điện cho xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Cách điện bằng cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '85443012',
    Description: '- - - - Loại dùng cho xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85443013',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85443014',
    Description: '- - - - Loại dùng cho xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85443019',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85443091',
    Description: '- - - Cách điện bằng cao su hoặc plastic',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85443099',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- Các vật dẫn điện khác(*), dùng cho điện áp không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '854442',
    Description: '- - Đã lắp với đầu nối điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho viễn thông, điện áp không quá 80 V:',
    Unit: '',
  },
  {
    HsCode: '85444211',
    Description:
      '- - - - Cáp điện thoại ngầm dưới biển; cáp điện báo ngầm dưới biển; cáp chuyển tiếp vô tuyến ngầm dưới biển',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444213',
    Description: '- - - - Loại khác, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444219',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho viễn thông, điện áp trên 80 V nhưng không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '85444221',
    Description:
      '- - - - Cáp điện thoại ngầm dưới biển; cáp điện báo ngầm dưới biển; cáp chuyển tiếp vô tuyến ngầm dưới biển',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444223',
    Description: '- - - - Loại khác, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444229',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Cáp ắc qui:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Cách điện bằng cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '85444232',
    Description: '- - - - - Cho xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444233',
    Description: '- - - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85444234',
    Description: '- - - - - Cho xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444239',
    Description: '- - - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85444291',
    Description: '- - - - Cáp dữ liệu dẹt có hai sợi hoặc hơn, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444292',
    Description: '- - - - Cáp dữ liệu dẹt có hai sợi hoặc hơn, loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444294',
    Description: '- - - - Cáp điện cách điện bằng plastic, trong đó mỗi lõi có đường kính không quá 5 mm ',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444295',
    Description:
      '- - - - Cáp điện cách điện bằng plastic, trong đó mỗi lõi có đường kính trên 5 mm nhưng không quá 19,5 mm ',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444296',
    Description: '- - - - Cáp điện cách điện bằng plastic khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444297',
    Description: '- - - - Cáp điện cách điện bằng cao su hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444299',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '854449',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho viễn thông, điện áp không quá 80 V:',
    Unit: '',
  },
  {
    HsCode: '85444911',
    Description:
      '- - - - Cáp điện thoại ngầm dưới biển; cáp điện báo ngầm dưới biển; cáp chuyển tiếp vô tuyến ngầm dưới biển',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444913',
    Description: '- - - - Loại khác, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444919',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại không dùng cho viễn thông, điện áp không quá 80 V:',
    Unit: '',
  },
  {
    HsCode: '85444921',
    Description: '- - - - Dây đơn có vỏ bọc (chống nhiễu) dùng trong sản xuất bó dây dẫn điện của ô tô',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85444922',
    Description: '- - - - - Cáp điện cách điện bằng plastic, trong đó mỗi lõi có đường kính không quá 19,5 mm ',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444923',
    Description: '- - - - - Cáp điện cách điện bằng plastic khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444924',
    Description: '- - - - - Loại khác, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444929',
    Description: '- - - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho viễn thông, điện áp trên 80 V nhưng không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '85444931',
    Description:
      '- - - - Cáp điện thoại ngầm dưới biển; cáp điện báo ngầm dưới biển; cáp chuyển tiếp vô tuyến ngầm dưới biển',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444932',
    Description: '- - - - Loại khác, cách điện bằng plastic',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444933',
    Description: '- - - - Loại khác, cách điện bằng cao su hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444939',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại không dùng cho viễn thông, điện áp trên 80 V nhưng không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '85444941',
    Description: '- - - - Cáp bọc cách điện bằng plastic',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444942',
    Description: '- - - - Loại khác, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85444949',
    Description: '- - - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '854460',
    Description: '- Các vật dẫn điện khác(*), dùng cho điện áp trên 1000 V:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho điện áp trên 1 kV nhưng không quá 36 kV:',
    Unit: '',
  },
  {
    HsCode: '85446011',
    Description: '- - - Cáp bọc cách điện bằng plastic, trong đó mỗi lõi có đường kính dưới 22,7 mm ',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85446012',
    Description: '- - - Loại khác, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85446019',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho điện áp trên 36 kV nhưng không quá 66 kV:',
    Unit: '',
  },
  {
    HsCode: '85446021',
    Description: '- - - Cáp bọc cách điện bằng plastic, trong đó mỗi lõi có đường kính dưới 22,7 mm ',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85446022',
    Description: '- - - Loại khác, cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85446029',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho điện áp trên 66 kV:',
    Unit: '',
  },
  {
    HsCode: '85446031',
    Description: '- - - Cách điện bằng cao su, plastic hoặc giấy',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85446039',
    Description: '- - - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '854470',
    Description: '- Cáp sợi quang:',
    Unit: '',
  },
  {
    HsCode: '85447010',
    Description:
      '- - Cáp điện thoại ngầm dưới biển; cáp điện báo ngầm dưới biển; cáp chuyển tiếp vô tuyến ngầm dưới biển',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '85447090',
    Description: '- - Loại khác',
    Unit: 'm/chiếc',
  },
  {
    HsCode: '8545',
    Description:
      'Điện cực than, chổi than, carbon cho chế tạo bóng đèn, carbon cho chế tạo pin, ắc qui và các sản phẩm khác làm bằng graphit hoặc carbon khác, có hoặc không có thành phần kim loại, dùng cho kỹ thuật điện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Điện cực:',
    Unit: '',
  },
  {
    HsCode: '85451100',
    Description: '- - Dùng cho lò nung, luyện',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85451900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85452000',
    Description: '- Chổi than',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85459000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8546',
    Description: 'Vật cách điện làm bằng vật liệu bất kỳ',
    Unit: '',
  },
  {
    HsCode: '85461000',
    Description: '- Bằng thuỷ tinh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '854620',
    Description: '- Bằng gốm, sứ:',
    Unit: '',
  },
  {
    HsCode: '85462010',
    Description:
      '- - Cách điện xuyên của máy biến điện (máy biến áp và máy biến dòng) và cách điện của thiết bị ngắt mạch',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85462090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85469000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8547',
    Description:
      'Phụ kiện cách điện dùng cho máy điện, dụng cụ điện hoặc thiết bị điện, được làm hoàn toàn bằng vật liệu cách điện trừ một số phụ kiện thứ yếu bằng kim loại (ví dụ, phần ống có ren) đã làm sẵn khi đúc chủ yếu để lắp, trừ cách điện thuộc nhóm 85.46; ống dẫn dây điện và các khớp nối của chúng, bằng kim loại cơ bản được lót lớp vật liệu cách điện',
    Unit: '',
  },
  {
    HsCode: '85471000',
    Description: '- Phụ kiện cách điện bằng gốm sứ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85472000',
    Description: '- Phụ kiện cách điện bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '854790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85479010',
    Description:
      '- - Ống dẫn dây điện và các khớp nối của chúng, làm bằng kim loại cơ bản được lót bằng vật liệu cách điện',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85479090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '85480000',
    Description: 'Các bộ phận điện của máy móc hoặc thiết bị, chưa được chi tiết hoặc ghi ở nơi khác trong Chương này',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8549',
    Description: 'Phế liệu và phế thải điện và điện tử',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Phế liệu và phế thải của các loại pin, bộ pin và ắc qui điện; các loại pin, bộ pin và ắc qui điện đã sử dụng hết:',
    Unit: '',
  },
  {
    HsCode: '854911',
    Description: '- - Phế liệu và phế thải của ắc qui axit chì; ắc qui axit chì đã sử dụng hết: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Phế thải pin, ắc qui axit chì, đã hoặc chưa tháo dung dịch axit:',
    Unit: '',
  },
  {
    HsCode: '85491111',
    Description: '- - - - Của loại dùng cho máy bay',
    Unit: 'kg',
  },
  {
    HsCode: '85491112',
    Description: '- - - - Loại khác, của phân nhóm 8507.10.92, 8507.10.95, 8507.20.94 hoặc 8507.20.95',
    Unit: 'kg',
  },
  {
    HsCode: '85491119',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '85491120',
    Description: '- - - Phế liệu và phế thải chủ yếu chứa sắt',
    Unit: 'kg',
  },
  {
    HsCode: '85491130',
    Description: '- - - Phế liệu và phế thải chủ yếu chứa đồng',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85491191',
    Description: '- - - - Của loại dùng cho máy bay',
    Unit: 'kg',
  },
  {
    HsCode: '85491192',
    Description: '- - - - Loại khác, của phân nhóm 8507.10.92, 8507.10.95, 8507.20.94 hoặc 8507.20.95',
    Unit: 'kg',
  },
  {
    HsCode: '85491199',
    Description: '- - - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '854912',
    Description: '- - Loại khác, có chứa chì, cađimi hoặc thủy ngân:',
    Unit: '',
  },
  {
    HsCode: '85491210',
    Description: '- - - Của pin và bộ pin',
    Unit: 'kg',
  },
  {
    HsCode: '85491220',
    Description: '- - - Của ắc qui điện loại dùng cho máy bay',
    Unit: 'kg',
  },
  {
    HsCode: '85491290',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '854913',
    Description: '- - Được phân loại theo loại hóa chất và không chứa chì, cađimi hoặc thủy ngân: ',
    Unit: '',
  },
  {
    HsCode: '85491310',
    Description: '- - - Của pin và bộ pin',
    Unit: 'kg',
  },
  {
    HsCode: '85491320',
    Description: '- - - Của ắc qui điện loại dùng cho máy bay',
    Unit: 'kg',
  },
  {
    HsCode: '85491390',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '854914',
    Description: '- - Chưa được phân loại và không chứa chì, cađimi hoặc thủy ngân:',
    Unit: '',
  },
  {
    HsCode: '85491410',
    Description: '- - - Của pin và bộ pin',
    Unit: 'kg',
  },
  {
    HsCode: '85491420',
    Description: '- - - Của ắc qui điện loại dùng cho máy bay',
    Unit: 'kg',
  },
  {
    HsCode: '85491490',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '854919',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '85491910',
    Description: '- - - Của pin và bộ pin',
    Unit: 'kg',
  },
  {
    HsCode: '85491920',
    Description: '- - - Của ắc qui điện loại dùng cho máy bay',
    Unit: 'kg',
  },
  {
    HsCode: '85491990',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại được sử dụng chủ yếu để thu hồi kim loại quý: ',
    Unit: '',
  },
  {
    HsCode: '85492100',
    Description:
      '- - Chứa pin, bộ pin, ắc qui điện, công tắc thủy ngân, thủy tinh từ ống tia ca-tốt hoặc thủy tinh hoạt hóa khác, hoặc các thành phần điện hoặc điện tử có chứa cađimi, thủy ngân, chì hoặc polychlorinated biphenyls (PCB) ',
    Unit: 'kg',
  },
  {
    HsCode: '85492900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Các cụm điện và điện tử đã lắp ráp và tấm mạch in khác: ',
    Unit: '',
  },
  {
    HsCode: '85493100',
    Description:
      '- - Chứa pin, bộ pin, ắc qui điện, công tắc thủy ngân, thủy tinh từ ống tia ca-tốt hoặc thủy tinh hoạt hóa khác, hoặc các thành phần điện hoặc điện tử có chứa cadmium, thủy ngân, chì hoặc polychlorinated biphenyls (PCB) ',
    Unit: 'kg',
  },
  {
    HsCode: '85493900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '854991',
    Description:
      '- - Chứa pin, bộ pin, ắc qui điện, công tắc thủy ngân, thủy tinh từ ống tia ca-tốt hoặc thủy tinh hoạt hóa khác, hoặc các thành phần điện hoặc điện tử có chứa cadmium, thủy ngân, chì hoặc polychlorinated biphenyls (PCB): ',
    Unit: '',
  },
  {
    HsCode: '85499110',
    Description: '- - - Thủy tinh từ ống tia ca-tốt hoặc thủy tinh hoạt hóa khác',
    Unit: 'kg',
  },
  {
    HsCode: '85499190',
    Description: '- - - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '85499900',
    Description: '- - Loại khác',
    Unit: 'kg',
  },
  {
    HsCode: '',
    Description: '(1): Tham khảo TCVN 7697-2:2007',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(*): Thẻ không tiếp xúc (proximity card) và thẻ HMTL (Tag) thuộc mã này khi không đáp ứng điều kiện chú giải 6(b) chương này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(*): Các vật dẫn điện khác (ví dụ, dây bện, dải, thanh) được sử dụng như dây dẫn trong máy móc, thiết bị hoặc hệ thống lắp đặt điện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XVII',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'XE CỘ, PHƯƠNG TIỆN BAY, TÀU THUYỀN VÀ CÁC THIẾT BỊ VẬN TẢI LIÊN HỢP',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Phần này không bao gồm các mặt hàng thuộc các nhóm 95.03 hoặc 95.08, hoặc xe trượt băng, xe trượt tuyết hoặc loại tương tự thuộc nhóm 95.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Khái niệm ""bộ phận"" và ""bộ phận và phụ kiện"" không áp dụng cho các sản phẩm sau đây, dù chúng có hoặc không được nhận biết như những bộ phận dùng cho hàng hóa thuộc Phần này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Tấm đệm, vòng đệm hoặc loại tương tự bằng vật liệu bất kỳ (được phân loại theo vật liệu cấu thành hoặc được xếp vào nhóm 84.84) hoặc các sản phẩm khác bằng cao su lưu hóa trừ cao su cứng (nhóm 40.16);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các bộ phận có công dụng chung, như đã định nghĩa trong Chú giải 2 Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các hàng hóa tương tự bằng plastic (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các mặt hàng thuộc Chương 82 (dụng cụ các loại); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Các mặt hàng thuộc nhóm 83.06;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Máy móc hoặc thiết bị thuộc nhóm 84.01 đến 84.79, hoặc các bộ phận của chúng, trừ các bộ tản nhiệt dùng cho các mặt hàng thuộc Phần này; các mặt hàng thuộc nhóm 84.81 hoặc 84.82 hoặc, các mặt hàng thuộc nhóm 84.83 với điều kiện là chúng cấu thành các bộ phận bên trong của động cơ hoặc mô tơ;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Máy điện hoặc thiết bị điện (Chương 85); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Các mặt hàng thuộc Chương 90;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Các mặt hàng thuộc Chương 91;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Vũ khí (Chương 93);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Đèn (luminaires) và bộ đèn và các bộ phận của chúng thuộc nhóm 94.05; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Bàn chải loại được sử dụng như là bộ phận của các phương tiện (nhóm 96.03).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Khi đề cập đến các Chương từ Chương 86 đến Chương 88 khái niệm “bộ phận” hoặc ""phụ kiện"" không áp dụng cho loại bộ phận hoặc phụ kiện không phù hợp để chỉ dùng hoặc chủ yếu dùng với các mặt hàng thuộc các Chương này. Một loại bộ phận hoặc phụ kiện đáp ứng mô tả trong hai hoặc nhiều nhóm thuộc các Chương này phải phân loại vào nhóm tương ứng với công dụng chủ yếu của loại bộ phận hoặc phụ kiện ấy.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '4. Theo mục đích của Phần này:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các phương tiện được thiết kế đặc biệt để chạy cả trên đường bộ và đường ray được phân loại vào nhóm thích hợp của Chương 87;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Xe lội nước có động cơ được phân loại vào nhóm thích hợp của Chương 87;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Phương tiện bay được thiết kế đặc biệt để cũng có thể sử dụng như loại phương tiện đường bộ được phân loại vào nhóm thích hợp của Chương 88.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Các phương tiện chạy trên đệm không khí được phân loại trong Phần này cùng với các phương tiện giống chúng nhất cụ thể như sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Xếp vào Chương 86 nếu chúng được thiết kế để chạy trên đường ray dẫn (tàu hỏa chạy trên đệm không khí);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Xếp vào Chương 87 nếu chúng được thiết kế để chạy trên đất hoặc trên cả đất và nước;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Xếp vào Chương 89 nếu chúng được thiết kế để chạy trên nước, có hoặc không có khả năng đỗ xuống bờ hoặc bến tàu hoặc cũng có thể chạy trên băng.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Bộ phận và phụ kiện của các phương tiện chạy trên đệm không khí được phân loại theo cách phân loại các phương tiện chạy trên đệm không khí đã quy định trên đây.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các bộ phận cố định và ghép nối của đường ray tàu chạy trên đệm không khí được phân loại như các bộ phận cố định và ghép nối của đường ray đường sắt, và thiết bị tín hiệu, an toàn hoặc điều khiển giao thông dùng cho hệ thống vận tải trên đệm không khí, được phân loại như thiết bị tín hiệu, an toàn hoặc điều khiển giao thông của đường sắt.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 86',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Đầu máy, các phương tiện di chuyển trên đường sắt hoặc đường tàu điện và các bộ phận của chúng; các bộ phận cố định và ghép nối đường ray đường sắt hoặc tàu điện và bộ phận của chúng; thiết bị tín hiệu giao thông bằng cơ khí (kể cả cơ điện) các loại',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Tà vẹt đường ray đường sắt hoặc tàu điện bằng gỗ hoặc bằng bê tông, hoặc đường ray dẫn bằng bê tông của tàu chạy trên đệm không khí (nhóm 44.06 hoặc 68.10);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Vật liệu xây dựng đường ray đường sắt hoặc tàu điện bằng sắt hoặc thép thuộc nhóm 73.02; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Thiết bị tín hiệu, an toàn hoặc điều khiển giao thông bằng điện thuộc nhóm 85.30.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '2. Nhóm 86.07, không kể những đề cập khác, áp dụng cho:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Trục, bánh xe, bộ trục bánh xe(1) (bánh răng truyền động), đai bánh xe(1), mâm bánh và moay ơ và các bộ phận khác của bánh xe;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Khung, bệ xe(1), giá chuyển hướng và trục bitxen;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Hộp trục(1); cơ cấu hãm(1);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Bộ giảm chấn đầu đấm cho các phương tiện di chuyển trên đường ray; móc và các bộ phận ghép nối khác và các phần nối ở hành lang toa xe;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Thân toa.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Theo các quy định của Chú giải 1 trên đây, không kể những đề cập khác, nhóm 86.08 áp dụng cho:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Đường ray đã lắp ghép, bàn quay, thanh đệm chắn va ở bậc thềm đường sắt, khổ giới hạn vận dụng;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Cột tín hiệu, đĩa tín hiệu cơ, thiết bị điều khiển chỗ chắn tàu, dụng cụ chỉ đường và đánh tín hiệu, và các thiết bị điều khiển giao thông, tín hiệu hoặc an toàn bằng cơ học (kể cả cơ điện) khác, đã hoặc chưa lắp đặt cho hệ thống điện chiếu sáng, cho đường sắt, đường tàu điện, đường bộ, đường thủy nội địa, bến đỗ, cảng hoặc sân bay.',
    Unit: '',
  },
  {
    HsCode: '8601',
    Description: 'Đầu máy di chuyển trên đường ray chạy bằng nguồn điện bên ngoài hoặc bằng ắc qui điện',
    Unit: '',
  },
  {
    HsCode: '86011000',
    Description: '- Loại chạy bằng nguồn điện bên ngoài',
    Unit: 'chiếc',
  },
  {
    HsCode: '86012000',
    Description: '- Loại chạy bằng ắc qui điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '8602',
    Description: 'Đầu máy di chuyển trên đường ray khác; toa tiếp liệu đầu máy',
    Unit: '',
  },
  {
    HsCode: '86021000',
    Description: '- Đầu máy diesel truyền động điện (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '86029000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8603',
    Description:
      'Toa xe khách, toa xe hàng và toa xe hành lý, loại tự hành dùng trên đường sắt hoặc đường tàu điện, trừ loại thuộc nhóm 86.04',
    Unit: '',
  },
  {
    HsCode: '86031000',
    Description: '- Loại chạy bằng nguồn điện bên ngoài',
    Unit: 'chiếc',
  },
  {
    HsCode: '86039000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '86040000',
    Description:
      'Xe bảo dưỡng hoặc phục vụ dùng trong đường sắt hoặc đường tàu điện, loại tự hành hoặc không (ví dụ, toa xưởng, xe gắn cần cẩu, máy chèn đường, máy đặt ray, toa xe thử nghiệm(1) và xe kiểm tra đường ray)',
    Unit: 'chiếc',
  },
  {
    HsCode: '86050000',
    Description:
      'Toa xe chở khách(1) không tự hành dùng cho đường sắt hoặc đường tàu điện; toa xe hành lý(1), toa xe bưu vụ(1) và toa xe chuyên dùng khác cho đường sắt hoặc đường tàu điện, không tự hành (trừ các loại thuộc nhóm 86.04)',
    Unit: 'chiếc',
  },
  {
    HsCode: '8606',
    Description: 'Toa xe hàng và toa goòng dùng trên đường sắt hoặc đường tàu điện, không tự hành',
    Unit: '',
  },
  {
    HsCode: '86061000',
    Description: '- Toa xe xi téc và các loại toa tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '86063000',
    Description: '- Toa chở hàng và toa goòng tự dỡ tải, trừ các loại thuộc phân nhóm 8606.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '86069100',
    Description: '- - Loại có nắp đậy và đóng kín',
    Unit: 'chiếc',
  },
  {
    HsCode: '86069200',
    Description: '- - Loại mở, với các thành bên không thể tháo rời có chiều cao trên 60 cm',
    Unit: 'chiếc',
  },
  {
    HsCode: '86069900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8607',
    Description: 'Các bộ phận của đầu máy hoặc của phương tiện di chuyển trên đường sắt hoặc đường tàu điện',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Giá chuyển hướng, trục bitxen, trục và bánh xe và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '86071100',
    Description: '- - Giá chuyển hướng và trục bitxen của đầu máy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '86071200',
    Description: '- - Giá chuyển hướng và trục bitxen khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '86071900',
    Description: '- - Loại khác, kể cả các bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Hãm(1) và các phụ tùng hãm(1):',
    Unit: '',
  },
  {
    HsCode: '86072100',
    Description: '- - Hãm gió ép(1) và phụ tùng hãm gió ép(1)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '86072900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '86073000',
    Description: '- Móc nối và các dụng cụ ghép nối khác, bộ đệm giảm chấn (bộ đỡ đấm), và phụ tùng của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '86079100',
    Description: '- - Của đầu máy',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '86079900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8608',
    Description:
      'Bộ phận cố định và ghép nối của đường ray đường sắt hoặc tàu điện; các thiết bị phát tín hiệu, an toàn hoặc điều khiển giao thông bằng cơ khí (kể cả cơ điện) dùng cho đường sắt, đường tàu điện, đường bộ, đường thủy nội địa, điểm dừng đỗ, cảng hoặc sân bay; các bộ phận của các loại trên',
    Unit: '',
  },
  {
    HsCode: '86080020',
    Description: '- Thiết bị cơ điện',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '86080090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '8609',
    Description:
      'Công-ten-nơ (kể cả công-ten-nơ dùng vận chuyển chất lỏng) được thiết kế và trang bị đặc biệt để vận chuyển theo một hoặc nhiều phương thức',
    Unit: '',
  },
  {
    HsCode: '86090010',
    Description: '- Bằng kim loại cơ bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '86090090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '     (1): Tham khảo TCVN 8546:2010, TCVN 9134:2012, TCVN 9135:2012',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 87',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Xe trừ phương tiện chạy trên đường sắt hoặc đường tàu điện, và các bộ phận và phụ kiện của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Chương này không bao gồm phương tiện chạy trên đường sắt hoặc đường tàu điện được thiết kế chỉ để chạy trên đường ray.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của Chương này, ""xe kéo"" có nghĩa là phương tiện được thiết kế chủ yếu để kéo hoặc đẩy một phương tiện, một thiết bị hoặc một vật nặng khác, có hoặc không bao gồm bộ phận phụ trợ để vận chuyển các loại công cụ, hạt giống (seeds), phân bón hoặc hàng hoá khác, phù hợp với mục đích sử dụng chính của xe kéo.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Máy móc và công cụ làm việc được thiết kế để gắn vào xe kéo của nhóm 87.01 mà các thiết bị này có thể thay đổi (tháo lắp) thì vẫn được phân loại vào các nhóm tương ứng của chúng ngay cả khi chúng đi kèm với xe kéo, và có hoặc không được gắn vào nó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Khung gầm có động cơ gắn với cabin xếp ở các nhóm từ 87.02 đến 87.04, và không thuộc nhóm 87.06.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 87.12 bao gồm tất cả xe đạp trẻ em các loại. Các loại xe trẻ em khác được xếp trong nhóm 95.03.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Phân nhóm 8708.22 bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) kính chắn gió phía trước (kính chắn gió), kính cửa phía sau và các kính cửa khác, có khung; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) kính chắn gió phía trước (kính chắn gió), kính cửa phía sau và các kính cửa khác, có hoặc không có khung, có gắn thiết bị sưởi hoặc các thiết bị điện hoặc điện tử khác,',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'khi chỉ dùng hoặc chủ yếu dùng cho các xe có động cơ thuộc các nhóm từ 87.01 đến 87.05.',
    Unit: '',
  },
  {
    HsCode: '8701',
    Description: 'Xe kéo (trừ xe kéo thuộc nhóm 87.09)',
    Unit: '',
  },
  {
    HsCode: '870110',
    Description: '- Máy kéo trục đơn: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Công suất không quá 22,5 kW, có hoặc không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '87011011',
    Description: '- - - Dùng cho nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87011019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87011091',
    Description: '- - - Dùng cho nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87011099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Ô tô đầu kéo dùng để kéo sơ mi rơ moóc (1):',
    Unit: '',
  },
  {
    HsCode: '870121',
    Description: '- - Loại chỉ sử dụng động cơ đốt trong kiểu piston cháy do nén (diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '87012110',
    Description: '- - - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87012190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870122',
    Description:
      '- - Loại kết hợp động cơ đốt trong kiểu piston cháy do nén (diesel hoặc bán diesel) và động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '87012210',
    Description: '- - - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87012290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870123',
    Description:
      '- - Loại kết hợp động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện và động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '87012310',
    Description: '- - - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87012390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870124',
    Description: '- - Loại chỉ sử dụng động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '87012410',
    Description: '- - - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87012490',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870129',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87012910',
    Description: '- - - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87012990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87013000',
    Description: '- Xe kéo bánh xích',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác, có công suất máy:',
    Unit: '',
  },
  {
    HsCode: '870191',
    Description: '- - Không quá 18 kW:',
    Unit: '',
  },
  {
    HsCode: '87019110',
    Description: '- - - Máy kéo nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87019190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870192',
    Description: '- - Trên 18 kW nhưng không quá 37 kW:',
    Unit: '',
  },
  {
    HsCode: '87019210',
    Description: '- - - Máy kéo nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87019290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870193',
    Description: '- - Trên 37 kW nhưng không quá 75 kW:',
    Unit: '',
  },
  {
    HsCode: '87019310',
    Description: '- - - Máy kéo nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87019390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870194',
    Description: '- - Trên 75 kW nhưng không quá 130 kW:',
    Unit: '',
  },
  {
    HsCode: '87019410',
    Description: '- - - Máy kéo nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87019490',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870195',
    Description: '- - Trên 130 kW:',
    Unit: '',
  },
  {
    HsCode: '87019510',
    Description: '- - - Máy kéo nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87019590',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8702',
    Description: 'Xe có động cơ chở 10 người trở lên, kể cả lái xe',
    Unit: '',
  },
  {
    HsCode: '870210',
    Description: '- Loại chỉ sử dụng động cơ đốt trong kiểu piston cháy do nén (diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87021010',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses):',
    Unit: '',
  },
  {
    HsCode: '87021041',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) từ 6 tấn trở lên nhưng không quá 18 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021042',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021049',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021050',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87021060',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chở từ 30 người trở lên và được thiết kế đặc biệt để sử dụng trong sân bay (SEN):',
    Unit: '',
  },
  {
    HsCode: '87021071',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) từ 6 tấn trở lên nhưng không quá 18 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021072',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021079',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87021081',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) từ 6 tấn trở lên nhưng không quá 18 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021082',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021089',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87021091',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87021099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870220',
    Description:
      '- Loại kết hợp động cơ đốt trong kiểu piston cháy do nén (diesel hoặc bán diesel) và động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87022010',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chở từ 30 người trở lên:',
    Unit: '',
  },
  {
    HsCode: '87022022',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) từ 6 tấn trở lên nhưng không quá 18 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022023',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87022031',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) từ 6 tấn trở lên nhưng không quá 18 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022032',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022039',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87022041',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022049',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87022050',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chở từ 30 người trở lên và được thiết kế đặc biệt để sử dụng trong sân bay (SEN):',
    Unit: '',
  },
  {
    HsCode: '87022061',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) từ 6 tấn trở lên nhưng không quá 18 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022062',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022069',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87022071',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) từ 6 tấn trở lên nhưng không quá 18 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022072',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022079',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87022091',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87022099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870230',
    Description:
      '- Loại kết hợp động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện và động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87023010',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chở từ 30 người trở lên:',
    Unit: '',
  },
  {
    HsCode: '87023021',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87023029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87023031',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87023039',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87023041',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87023049',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87023050',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chở từ 30 người trở lên và được thiết kế đặc biệt để sử dụng trong sân bay (SEN):',
    Unit: '',
  },
  {
    HsCode: '87023061',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87023069',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87023071',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87023079',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87023091',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87023099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870240',
    Description: '- Loại chỉ sử dụng động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87024010',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chở từ 30 người trở lên:',
    Unit: '',
  },
  {
    HsCode: '87024021',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87024029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87024031',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87024039',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87024041',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87024049',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87024050',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, chở từ 30 người trở lên và được thiết kế đặc biệt để sử dụng trong sân bay (SEN):',
    Unit: '',
  },
  {
    HsCode: '87024061',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87024069',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87024071',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87024079',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87024091',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87024099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87029010',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses), chở từ 30 người trở lên:',
    Unit: '',
  },
  {
    HsCode: '87029021',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029029',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87029031',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029032',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029033',
    Description: '- - - Loại khác, có khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87029040',
    Description:
      '- - - Ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách, chở từ 30 người trở lên và được thiết kế đặc biệt để sử dụng trong sân bay (SEN):',
    Unit: '',
  },
  {
    HsCode: '87029051',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029059',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác, chở từ 30 người trở lên:',
    Unit: '',
  },
  {
    HsCode: '87029061',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029069',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô khách (1) (motor coaches, buses hoặc minibuses) khác:',
    Unit: '',
  },
  {
    HsCode: '87029071',
    Description: '- - - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029079',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029080',
    Description: '- - - Loại khác, có khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87029090',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8703',
    Description:
      'Ô tô và các loại xe khác có động cơ được thiết kế chủ yếu để chở người (trừ các loại thuộc nhóm 87.02), kể cả ô tô chở người có khoang hành lý chung (station wagons) và ô tô đua',
    Unit: '',
  },
  {
    HsCode: '870310',
    Description: '- Xe được thiết kế đặc biệt để đi trên tuyết; xe chơi gôn (golf car) và các loại xe tương tự:',
    Unit: '',
  },
  {
    HsCode: '87031010',
    Description: '- - Xe chơi gôn (kể cả xe golf buggies) và các loại xe tương tự ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87031090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại xe khác, chỉ sử dụng động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện:',
    Unit: '',
  },
  {
    HsCode: '870321',
    Description: '- - Dung tích xi lanh không quá 1.000 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87032111',
    Description: '- - - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032112',
    Description: '- - - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032113',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032114',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032115',
    Description: '- - - - Ô tô kiểu Sedan (SEN)(1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87032121',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032129',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032131',
    Description: '- - - - - Xe 3 bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032139',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032141',
    Description: '- - - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032142',
    Description: '- - - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032143',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032144',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032145',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87032151',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032159',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032191',
    Description: '- - - - - Xe 3 bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032199',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870322',
    Description: '- - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87032211',
    Description: '- - - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032212',
    Description: '- - - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032213',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032214',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032215',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032216',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032217',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87032221',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032229',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032230',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032241',
    Description: '- - - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032242',
    Description: '- - - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032243',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032244',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032245',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032246',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032247',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87032251',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032259',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032290',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870323',
    Description: '- - Dung tích xilanh trên 1.500 cc nhưng không quá 3.000 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87032311',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032312',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032313',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032314',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87032321',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032322',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032323',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032324',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87032331',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032332',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032333',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032334',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87032335',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032336',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032341',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032342',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032351',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032352',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032353',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032354',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87032355',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032356',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032357',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032358',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87032361',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032362',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032363',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032364',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87032365',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032366',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032367',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032368',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032371',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032372',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032373',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032374',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '870324',
    Description: '- - Dung tích xi lanh trên 3.000 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87032411',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032412',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032413',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032414',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032415',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87032421',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032429',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032430',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032441',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032442',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032443',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032444',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87032445',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032449',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87032451',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032459',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87032461',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87032469',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Xe khác, loại chỉ sử dụng động cơ đốt trong kiểu piston cháy do nén (diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '870331',
    Description: '- - Dung tích xi lanh không quá 1.500 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87033111',
    Description: '- - - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033112',
    Description: '- - - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033113',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033114',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033115',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033116',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033117',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87033121',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033129',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87033131',
    Description: '- - - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033139',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87033141',
    Description: '- - - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033142',
    Description: '- - - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033143',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033144',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033145',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033146',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033147',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van):',
    Unit: '',
  },
  {
    HsCode: '87033151',
    Description: '- - - - - Loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033159',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87033191',
    Description: '- - - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033199',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870332',
    Description: '- - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.500 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87033211',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033212',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033213',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033214',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87033221',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033222',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033223',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87033231',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033232',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033233',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87033234',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033235',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033236',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87033241',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033242',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033243',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87033251',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033252',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033253',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033254',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87033261',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033262',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033263',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87033271',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033272',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033273',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87033274',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033275',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033276',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87033281',
    Description: '- - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033282',
    Description: '- - - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033283',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '870333',
    Description: '- - Dung tích xi lanh trên 2.500 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87033311',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033312',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033313',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033314',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87033321',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033322',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87033331',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033332',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87033333',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033334',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033340',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87033351',
    Description: '- - - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033352',
    Description: '- - - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033353',
    Description: '- - - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033354',
    Description: '- - - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87033361',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033362',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87033371',
    Description: '- - - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033372',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033380',
    Description:
      '- - - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động',
    Unit: 'chiếc',
  },
  {
    HsCode: '87033390',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870340',
    Description:
      '- Xe khác, loại kết hợp động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện và động cơ điện để tạo động lực, trừ loại có khả năng nạp điện từ nguồn điện bên ngoài:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87034011',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034012',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034013',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034014',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034015',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87034016',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034017',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87034018',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034019',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034021',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87034022',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034023',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034024',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87034025',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034026',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87034027',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034028',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87034031',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles):',
    Unit: '',
  },
  {
    HsCode: '87034032',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034033',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô cứu thương:',
    Unit: '',
  },
  {
    HsCode: '87034034',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034035',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034036',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô tang lễ:',
    Unit: '',
  },
  {
    HsCode: '87034041',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034042',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034043',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034044',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034045',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034046',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034047',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô chở phạm nhân:',
    Unit: '',
  },
  {
    HsCode: '87034051',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034052',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034053',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034054',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034055',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87034056',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034057',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034058',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87034061',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034062',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034063',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034064',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034065',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034066',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034067',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034068',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, không phải loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87034071',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034072',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034073',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034074',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034075',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034076',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034077',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87034081',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034082',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034083',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034084',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034085',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034086',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034087',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87034091',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034092',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034093',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034094',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034095',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034096',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034097',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87034098',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, không phải loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '870350',
    Description:
      '- Xe khác, loại kết hợp động cơ đốt trong kiểu piston đốt cháy do nén (diesel hoặc bán diesel) và động cơ điện để tạo động lực, trừ loại có khả năng nạp điện từ nguồn điện bên ngoài:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87035011',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035012',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035013',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035014',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035015',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87035016',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035017',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87035018',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035019',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035021',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87035022',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035023',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035024',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87035025',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035026',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87035027',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035028',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87035031',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles):',
    Unit: '',
  },
  {
    HsCode: '87035032',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035033',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô cứu thương:',
    Unit: '',
  },
  {
    HsCode: '87035034',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035035',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035036',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô tang lễ:',
    Unit: '',
  },
  {
    HsCode: '87035041',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035042',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035043',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035044',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035045',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035046',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035047',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô chở phạm nhân:',
    Unit: '',
  },
  {
    HsCode: '87035051',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035052',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035053',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035054',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035055',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87035056',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035057',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035058',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87035061',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035062',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035063',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035064',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035065',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035066',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035067',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87035071',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035072',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035073',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035074',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035075',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035076',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035077',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87035081',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035082',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035083',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035084',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035085',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035086',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035087',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87035091',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035092',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035093',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035094',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035095',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035096',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87035097',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '870360',
    Description:
      '- Xe khác, loại kết hợp động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện và động cơ điện để tạo động lực, có khả năng nạp điện từ nguồn điện bên ngoài:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87036011',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036012',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036013',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036014',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036015',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87036016',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036017',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87036018',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036019',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036021',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87036022',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036023',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036024',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87036025',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036026',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87036027',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036028',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87036031',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles):',
    Unit: '',
  },
  {
    HsCode: '87036032',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036033',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô cứu thương:',
    Unit: '',
  },
  {
    HsCode: '87036034',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036035',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036036',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô tang lễ:',
    Unit: '',
  },
  {
    HsCode: '87036041',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036042',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036043',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036044',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036045',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036046',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036047',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô chở phạm nhân:',
    Unit: '',
  },
  {
    HsCode: '87036051',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036052',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036053',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036054',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036055',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87036056',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036057',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036058',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87036061',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036062',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036063',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036064',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036065',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036066',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036067',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036068',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, không phải loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87036071',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036072',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036073',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036074',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036075',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036076',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036077',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87036081',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036082',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036083',
    Description: ' - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036084',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036085',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036086',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036087',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87036091',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036092',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036093',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036094',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036095',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036096',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036097',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87036098',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc, không phải loại bốn bánh chủ động ',
    Unit: 'chiếc',
  },
  {
    HsCode: '870370',
    Description:
      '- Xe khác, loại kết hợp động cơ đốt trong kiểu piston đốt cháy do nén (diesel hoặc bán diesel) và động cơ điện để tạo động lực, có khả năng nạp điện từ nguồn điện bên ngoài:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87037011',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037012',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037013',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037014',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037015',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87037016',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037017',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87037018',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037019',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037021',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87037022',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037023',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037024',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87037025',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037026',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87037027',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037028',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87037031',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles):',
    Unit: '',
  },
  {
    HsCode: '87037032',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037033',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô cứu thương:',
    Unit: '',
  },
  {
    HsCode: '87037034',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037035',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037036',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô tang lễ:',
    Unit: '',
  },
  {
    HsCode: '87037041',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037042',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037043',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037044',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037045',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037046',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037047',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô chở phạm nhân:',
    Unit: '',
  },
  {
    HsCode: '87037051',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037052',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037053',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037054',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037055',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes):',
    Unit: '',
  },
  {
    HsCode: '87037056',
    Description: '- - - - Dung tích xi lanh không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037057',
    Description: ' - - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037058',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1):',
    Unit: '',
  },
  {
    HsCode: '87037061',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037062',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037063',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037064',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037065',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037066',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037067',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87037071',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037072',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037073',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037074',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037075',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037076',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037077',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van), không phải loại bốn bánh chủ động:',
    Unit: '',
  },
  {
    HsCode: '87037081',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037082',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037083',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037084',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037085',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037086',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037087',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87037091',
    Description: '- - - - Dung tích xi lanh không quá 1.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037092',
    Description: '- - - - Dung tích xi lanh trên 1.000 cc nhưng không quá 1.500 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037093',
    Description: '- - - - Dung tích xi lanh trên 1.500 cc nhưng không quá 1.800 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037094',
    Description: '- - - - Dung tích xi lanh trên 1.800 cc nhưng không quá 2.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037095',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 2.500 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037096',
    Description: '- - - - Dung tích xi lanh trên 2.500 cc nhưng không quá 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '87037097',
    Description: '- - - - Dung tích xi lanh trên 3.000 cc',
    Unit: 'chiếc',
  },
  {
    HsCode: '870380',
    Description: '- Xe khác, loại chỉ sử dụng động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87038011',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038012',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038013',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038014',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038015',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038016',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038017',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038018',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87038091',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038092',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038093',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038094',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038095',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038096',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038097',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038098',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87038099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87039011',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039012',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039013',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039014',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039015',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039016',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039017',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039018',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87039091',
    Description: '- - - Xe đua cỡ nhỏ (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039092',
    Description: '- - - Xe địa hình ATV (All-Terrain Vehicles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039093',
    Description: '- - - Ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039094',
    Description: '- - - Ô tô tang lễ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039095',
    Description: '- - - Ô tô chở phạm nhân',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039096',
    Description: '- - - Ô tô nhà ở lưu động (có nội thất được thiết kế như căn hộ) (Motor-homes)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039097',
    Description: '- - - Ô tô kiểu Sedan (SEN) (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039098',
    Description:
      '- - - Ô tô khác (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87039099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8704',
    Description: 'Xe có động cơ dùng để chở hàng',
    Unit: '',
  },
  {
    HsCode: '870410',
    Description: '- Xe tự đổ được thiết kế để sử dụng trên các loại đường không phải đường quốc lộ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87041013',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041014',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041015',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 10 tấn nhưng không quá 20 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041016',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn nhưng không quá 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041017',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn nhưng không quá 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041018',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87041031',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041032',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041033',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 10 tấn nhưng không quá 20 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041034',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn nhưng không quá 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041035',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn nhưng không quá 38 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041036',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 38 tấn nhưng không quá 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87041037',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Loại khác, loại chỉ sử dụng động cơ đốt trong kiểu piston đốt cháy do nén (diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '870421',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87042111',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042112',
    Description: '- - - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042113',
    Description: '- - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87042121',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042122',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042123',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042124',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042125',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042126',
    Description: '- - - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042127',
    Description: '- - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870422',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn nhưng không quá 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 6 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87042211',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042219',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87042221',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042222',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042223',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042224',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042225',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042229',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87042231',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042239',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87042241',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042242',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042243',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042245',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042246',
    Description:
      '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị, khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042247',
    Description:
      '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị, khối lượng toàn bộ theo thiết kế (SEN) trên 10 tấn nhưng không quá 20 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87042251',
    Description: '- - - - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042259',
    Description: '- - - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870423',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 24 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87042311',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042319',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87042321',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042322',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042323',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042324',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042325',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042329',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn nhưng không quá 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87042351',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042359',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87042361',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042362',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042363',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042364',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042365',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042366',
    Description: '- - - - - Ô tô tự đổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042369',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87042371',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042379',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87042381',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042382',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042384',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042385',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042386',
    Description: '- - - - - Ô tô tự đổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87042389',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác, chỉ sử dụng động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện:',
    Unit: '',
  },
  {
    HsCode: '870431',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87043111',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043112',
    Description: '- - - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043113',
    Description: '- - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87043121',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043122',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043123',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043124',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043125',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043126',
    Description: '- - - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043127',
    Description: '- - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870432',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 6 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87043211',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043219',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87043221',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043222',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043223',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043224',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043225',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043229',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87043231',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043239',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87043241',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043242',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043243',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043244',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043245',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043248',
    Description: '- - - - - Loại khác, khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043249',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn nhưng không quá 24 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87043251',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043259',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87043261',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043262',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043263',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043264',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043265',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043269',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn nhưng không quá 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87043272',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043279',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87043281',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043282',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043283',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043284',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043285',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043286',
    Description: '- - - - - Ô tô tự đổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043289',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87043291',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043292',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87043293',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043294',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043295',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043296',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043297',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043298',
    Description: '- - - - - Ô tô tự đổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87043299',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Loại khác, kết hợp động cơ đốt trong kiểu piston cháy do nén (diesel hoặc bán diesel) và động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '870441',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87044111',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87044121',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044122',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044123',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044124',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044125',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044126',
    Description: '- - - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044127',
    Description: '- - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870442',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn nhưng không quá 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 6 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87044211',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044219',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87044221',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044222',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044223',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044224',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044225',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044229',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87044231',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044239',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87044241',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044242',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044243',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044245',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044246',
    Description:
      '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị, khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044247',
    Description:
      '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị, khối lượng toàn bộ theo thiết kế (SEN) trên 10 tấn nhưng không quá 20 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87044251',
    Description: '- - - - - - Khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044259',
    Description: '- - - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870443',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 24 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87044311',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044319',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87044321',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044322',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044323',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044324',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044325',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044329',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn nhưng không quá 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87044351',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044359',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87044361',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044362',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044363',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044364',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044365',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044369',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87044371',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044379',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87044381',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044386',
    Description: '- - - - - Ô tô tự đổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87044389',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Loại khác, kết hợp động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện và động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '870451',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87045111',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87045121',
    Description: '- - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045122',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045123',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045124',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045125',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045126',
    Description: '- - - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045127',
    Description: '- - - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045129',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870452',
    Description: '- - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 6 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87045211',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045219',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87045221',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045222',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045223',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045224',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045225',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045229',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 20 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87045231',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045239',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87045241',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045242',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045243',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045244',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045245',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045248',
    Description: '- - - - - Loại khác, khối lượng toàn bộ theo thiết kế (SEN) trên 6 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045249',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn nhưng không quá 24 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87045251',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045259',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87045261',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045262',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045263',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045264',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045265',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045269',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn nhưng không quá 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87045272',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045279',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87045281',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045282',
    Description: '- - - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045283',
    Description: '- - - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045284',
    Description: '- - - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045285',
    Description: '- - - - - Ô tô chở bùn có thùng rời nâng hạ được(SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045286',
    Description: '- - - - - Ô tô tự đổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045289',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87045291',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045292',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87045293',
    Description: '- - - - - Ô tô tải đông lạnh (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045294',
    Description: '- - - - - Ô tô tự đổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87045299',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870460',
    Description: '- Loại khác, chỉ sử dụng động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87046011',
    Description: '- - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87046012',
    Description: '- - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87046019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác, khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn:',
    Unit: '',
  },
  {
    HsCode: '87046021',
    Description: '- - - Ô tô pick-up (1)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87046022',
    Description: '- - - Xe ba bánh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87046029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87046091',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87046092',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 10 tấn nhưng không quá 20 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87046093',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn nhưng không quá 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87046094',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '870490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87049010',
    Description: '- - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87049091',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) không quá 5 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87049092',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 5 tấn nhưng không quá 10 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87049093',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 10 tấn nhưng không quá 20 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87049094',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 20 tấn nhưng không quá 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87049095',
    Description: '- - - Khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '8705',
    Description:
      'Xe chuyên dùng có động cơ, trừ các loại được thiết kế chủ yếu dùng để chở người hoặc hàng hóa (ví dụ, xe cứu hộ, xe cần cẩu, xe chữa cháy, xe trộn bê tông, xe quét đường, xe phun tưới, xe sửa chữa lưu động, xe chụp X-quang)',
    Unit: '',
  },
  {
    HsCode: '87051000',
    Description: '- Xe cần cẩu',
    Unit: 'chiếc',
  },
  {
    HsCode: '87052000',
    Description: '- Xe cần trục khoan',
    Unit: 'chiếc',
  },
  {
    HsCode: '87053000',
    Description: '- Xe chữa cháy',
    Unit: 'chiếc',
  },
  {
    HsCode: '87054000',
    Description: '- Xe trộn bê tông',
    Unit: 'chiếc',
  },
  {
    HsCode: '870590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87059050',
    Description: '- - Xe làm sạch đường; xe hút bùn, bể phốt; xe y tế lưu động; xe phun tưới các loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '87059060',
    Description: '- - Xe điều chế chất nổ di động (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87059090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8706',
    Description: 'Khung gầm đã lắp động cơ, dùng cho xe có động cơ thuộc các nhóm từ 87.01 đến 87.05',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Dùng cho xe thuộc nhóm 87.01:',
    Unit: '',
  },
  {
    HsCode: '87060011',
    Description: '- - Dùng cho máy kéo nông nghiệp thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060019',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dùng cho xe thuộc nhóm 87.02:',
    Unit: '',
  },
  {
    HsCode: '87060021',
    Description:
      '- - Dùng cho ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060022',
    Description: '- - Loại khác, dùng cho xe có khối lượng toàn bộ theo thiết kế (SEN) không quá 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060023',
    Description: '- - Loại khác, dùng cho xe có khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '87060031',
    Description: '- - Dùng cho xe đua cỡ nhỏ và xe chơi gôn (kể cả xe golf buggies) và các loại xe tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060032',
    Description: '- - Dùng cho ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060033',
    Description:
      '- - Dùng cho ô tô (bao gồm cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô thể thao, nhưng không kể ô tô van)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060034',
    Description: '- - Dùng cho xe được thiết kế đặc biệt để đi trên tuyết (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060039',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dùng cho xe thuộc nhóm 87.04:',
    Unit: '',
  },
  {
    HsCode: '87060041',
    Description: '- - Dùng cho xe có khối lượng toàn bộ theo thiết kế (SEN) không quá 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060042',
    Description: '- - Dùng cho xe có khối lượng toàn bộ theo thiết kế (SEN) trên 24 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87060050',
    Description: '- Dùng cho xe thuộc nhóm 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '8707',
    Description: 'Thân xe (kể cả ca-bin), dùng cho xe có động cơ thuộc các nhóm từ 87.01 đến 87.05',
    Unit: '',
  },
  {
    HsCode: '870710',
    Description: '- Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '87071010',
    Description: '- - Dùng cho xe đua cỡ nhỏ và xe chơi gôn (kể cả xe golf buggies) và các loại xe tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '87071020',
    Description: '- - Dùng cho ô tô cứu thương',
    Unit: 'chiếc',
  },
  {
    HsCode: '87071030',
    Description: '- - Dùng cho xe được thiết kế đặc biệt để đi trên tuyết',
    Unit: 'chiếc',
  },
  {
    HsCode: '87071090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho xe thuộc nhóm 87.01:',
    Unit: '',
  },
  {
    HsCode: '87079011',
    Description: '- - - Cabin lái dùng cho xe thuộc phân nhóm 8701.21, 8701.22, 8701.23, 8701.24 hoặc 8701.29',
    Unit: 'chiếc',
  },
  {
    HsCode: '87079019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho xe thuộc nhóm 87.02:',
    Unit: '',
  },
  {
    HsCode: '87079021',
    Description:
      '- - - Dùng cho ô tô (bao gồm cả ô tô limousine kéo dài nhưng không bao gồm ô tô khách (1) (coaches, buses, minibuses) hoặc ô tô van) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87079029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87079030',
    Description: '- - Dùng cho xe thuộc nhóm 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '87079040',
    Description:
      '- - Cabin lái dùng cho xe tự đổ được thiết kế để sử dụng trên các loại đường không phải đường quốc lộ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87079090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8708',
    Description: 'Bộ phận và phụ kiện của xe có động cơ thuộc các nhóm từ 87.01 đến 87.05',
    Unit: '',
  },
  {
    HsCode: '870810',
    Description: '- Thanh chắn chống va đập (ba đờ xốc) và bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '87081010',
    Description: '- - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87081090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện khác của thân xe (kể cả ca-bin):',
    Unit: '',
  },
  {
    HsCode: '87082100',
    Description: '- - Dây đai an toàn',
    Unit: 'chiếc',
  },
  {
    HsCode: '870822',
    Description:
      '- - Kính chắn gió phía trước (kính chắn gió), kính cửa phía sau và các kính cửa khác được mô tả tại Chú giải Phân nhóm 1 Chương này:',
    Unit: '',
  },
  {
    HsCode: '87082210',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082220',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082230',
    Description: '- - - Dùng cho xe thuộc nhóm 87.02, 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '870829',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Các bộ phận của cửa xe:',
    Unit: '',
  },
  {
    HsCode: '87082911',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082915',
    Description: '- - - - Đỡ tay trên cửa xe dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082916',
    Description: '- - - - Loại khác, dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082917',
    Description: '- - - - Dùng cho xe thuộc phân nhóm 8704.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082918',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc xe khác thuộc nhóm 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082920',
    Description: '- - - Bộ phận của dây đai an toàn',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87082992',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '87082993',
    Description: '- - - - - Phụ kiện trang trí nội thất (SEN); chắn bùn ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082994',
    Description: '- - - - - Thanh chống nắp ca pô',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082995',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04:',
    Unit: '',
  },
  {
    HsCode: '87082996',
    Description: '- - - - - Phụ kiện trang trí nội thất (SEN); chắn bùn ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082997',
    Description: '- - - - - Thanh chống nắp ca pô',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082998',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87082999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870830',
    Description: '- Phanh và trợ lực phanh; bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '87083010',
    Description: '- - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '87083021',
    Description: '- - - Phanh trống, phanh đĩa hoặc phanh hơi ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87083029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87083030',
    Description: '- - Phanh trống, phanh đĩa hoặc phanh hơi cho xe thuộc nhóm 87.02 hoặc 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '87083090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870840',
    Description: '- Hộp số và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Hộp số, chưa lắp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '87084011',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084013',
    Description: '- - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084014',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Hộp số, đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '87084025',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084026',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084027',
    Description: '- - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '87084091',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084092',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87084099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870850',
    Description:
      '- Cầu chủ động có vi sai, có hoặc không kèm theo chi tiết truyền lực khác, và các cầu bị động; các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Chưa lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '87085011',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085013',
    Description: '- - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085015',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '87085025',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085026',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085027',
    Description: '- - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01:',
    Unit: '',
  },
  {
    HsCode: '87085091',
    Description: '- - - - Bánh răng vành khăn và bánh răng quả dứa (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085092',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '87085094',
    Description: '- - - - Bánh răng vành khăn và bánh răng quả dứa (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085095',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87085096',
    Description: '- - - - Bánh răng vành khăn và bánh răng quả dứa (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87085099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870870',
    Description: '- Cụm bánh xe và bộ phận và phụ kiện của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Ốp đầu trục bánh xe:',
    Unit: '',
  },
  {
    HsCode: '87087015',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087016',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04:',
    Unit: '',
  },
  {
    HsCode: '87087017',
    Description: '- - - - Dùng cho xe thuộc phân nhóm 8704.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087018',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bánh xe đã được lắp lốp:',
    Unit: '',
  },
  {
    HsCode: '87087021',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087022',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087023',
    Description: '- - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04 (trừ phân nhóm 8704.10)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bánh xe chưa được lắp lốp:',
    Unit: '',
  },
  {
    HsCode: '87087031',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087032',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087033',
    Description:
      '- - - Dùng cho xe tự đổ được thiết kế để sử dụng trên các loại đường không phải đường quốc lộ có khối lượng toàn bộ theo thiết kế (SEN) trên 45 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087034',
    Description: '- - - Dùng cho xe thuộc nhóm 87.02 hoặc xe khác thuộc nhóm 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87087095',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087096',
    Description: '- - - Dùng cho thuộc nhóm 87.02 hoặc 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087097',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87087099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870880',
    Description: '- Hệ thống giảm chấn và bộ phận của nó (kể cả giảm sóc): ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Hệ thống giảm chấn: ',
    Unit: '',
  },
  {
    HsCode: '87088015',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87088016',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87088017',
    Description: '- - - Dùng cho xe thuộc phân nhóm 8704.10 hoặc nhóm 87.05 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87088019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '87088091',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87088092',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87088099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Các bộ phận và phụ kiện khác:',
    Unit: '',
  },
  {
    HsCode: '870891',
    Description: '- - Két nước làm mát và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Két nước làm mát:',
    Unit: '',
  },
  {
    HsCode: '87089115',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089116',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04:',
    Unit: '',
  },
  {
    HsCode: '87089117',
    Description: '- - - - - Dùng cho xe thuộc phân nhóm 8704.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089118',
    Description: '- - - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089119',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '87089191',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089193',
    Description: '- - - - Nút xả(SEN) dùng cho xe thuộc nhóm 87.03 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089194',
    Description: '- - - - Nút xả(SEN) dùng cho xe thuộc nhóm 87.02 hoặc 87.04 (trừ loại thuộc phân nhóm 8704.10)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089195',
    Description: '- - - - Loại khác, dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870892',
    Description: '- - Ống xả và bộ giảm thanh; bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '87089210',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089220',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc phân nhóm 8704.10: ',
    Unit: '',
  },
  {
    HsCode: '87089251',
    Description: '- - - - Ống xả và bộ giảm thanh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089252',
    Description: '- - - - Bộ phận (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.02 hoặc xe khác thuộc nhóm 87.04: ',
    Unit: '',
  },
  {
    HsCode: '87089261',
    Description: '- - - - Ống xả và bộ giảm thanh ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089262',
    Description: '- - - - Bộ phận (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870893',
    Description: '- - Bộ ly hợp và các bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '87089350',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089360',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089370',
    Description: '- - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089390',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870894',
    Description: '- - Vô lăng, trụ lái và cơ cấu lái; bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Vô lăng lắp với túi khí hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '87089411',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089419',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87089494',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.01',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089495',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089499',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '870895',
    Description: '- - Túi khí an toàn lắp với hệ thống bơm phồng; bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '87089510',
    Description: '- - - Túi khí an toàn lắp với hệ thống bơm phồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089590',
    Description: '- - - Bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '870899',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.01:',
    Unit: '',
  },
  {
    HsCode: '87089911',
    Description: '- - - - Thùng nhiên liệu chưa lắp ráp; khung giá đỡ động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.02, 87.03 hoặc 87.04:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Thùng nhiên liệu và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '87089921',
    Description: '- - - - - Thùng nhiên liệu',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089924',
    Description:
      '- - - - - Nửa dưới của thùng nhiên liệu; nắp đậy thùng nhiên liệu; ống dẫn của thùng nhiên liệu (filler pipes); ống dẫn hướng có đầu nối với bình nhiên liệu (filler hose); đai giữ bình nhiên liệu (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089925',
    Description: '- - - - - Các bộ phận khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089930',
    Description: '- - - - Chân ga (bàn đạp ga), chân phanh (bàn đạp phanh) hoặc chân côn (bàn đạp côn)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089940',
    Description: '- - - - Giá đỡ hoặc khay đỡ bình ắc quy và khung của nó',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089950',
    Description: '- - - - Tấm hướng luồng khí tản nhiệt (SEN) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - - Khung xe hoặc các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '87089961',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.02',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089962',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089963',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089970',
    Description: '- - - - Khung giá đỡ động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089980',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87089991',
    Description: '- - - - Thùng nhiên liệu chưa lắp ráp; khung giá đỡ động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87089999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8709',
    Description:
      'Xe vận chuyển, loại tự hành, không lắp kèm thiết bị nâng hạ hoặc cặp giữ, thuộc loại dùng trong nhà máy, kho hàng, bến cảng hoặc sân bay để vận chuyển hàng hóa trong phạm vi hẹp; xe kéo loại sử dụng trong sân ga, ke ga đường sắt (*); bộ phận của các loại xe kể trên',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Xe:',
    Unit: '',
  },
  {
    HsCode: '87091100',
    Description: '- - Loại chạy điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '87091900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87099000',
    Description: '- Các bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '87100000',
    Description:
      'Xe tăng và các loại xe chiến đấu bọc thép khác, loại cơ giới, có hoặc không lắp kèm vũ khí, và bộ phận của các loại xe này',
    Unit: 'chiếc',
  },
  {
    HsCode: '8711',
    Description:
      'Mô tô (kể cả xe gắn máy có bàn đạp (moped)) và xe đạp có gắn động cơ phụ trợ, có hoặc không có thùng xe bên cạnh; thùng xe có bánh (side-cars)',
    Unit: '',
  },
  {
    HsCode: '871110',
    Description: '- Loại sử dụng động cơ đốt trong kiểu piston dung tích xi lanh không quá 50 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87111012',
    Description: '- - - Xe gắn máy có bàn đạp (moped) hoặc xe đạp có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87111014',
    Description: '- - - Xe ""powered kick scooter""; xe “pocket motorcycle” (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87111015',
    Description: '- - - Xe mô tô và xe scooter khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87111019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87111092',
    Description: '- - - Xe gắn máy có bàn đạp (moped) hoặc xe đạp có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87111094',
    Description: '- - - Xe ""powered kick scooter""; xe “pocket motorcycle” (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87111095',
    Description: '- - - Xe mô tô và xe scooter khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87111099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871120',
    Description: '- Loại sử dụng động cơ đốt trong kiểu piston dung tích xi lanh trên 50 cc nhưng không quá 250 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87112011',
    Description: '- - - Xe mô tô địa hình (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112012',
    Description: '- - - Xe gắn máy có bàn đạp (moped) hoặc xe đạp có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112013',
    Description: '- - - Xe “pocket motorcycle” (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Xe mô tô khác (có hoặc không có thùng xe bên cạnh), kể cả xe scooter:',
    Unit: '',
  },
  {
    HsCode: '87112014',
    Description: '- - - - Dung tích xi lanh trên 150 cc nhưng không quá 200 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112015',
    Description: '- - - - Dung tích xi lanh trên 200 cc nhưng không quá 250 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112016',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87112091',
    Description: '- - - Xe mô tô địa hình (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112092',
    Description: '- - - Xe gắn máy có bàn đạp (moped) hoặc xe đạp có động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112093',
    Description: '- - - Xe “pocket motorcycle” (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Xe mô tô khác (có hoặc không có thùng xe bên cạnh), kể cả xe scooter:',
    Unit: '',
  },
  {
    HsCode: '87112094',
    Description: '- - - - Dung tích xi lanh trên 150 cc nhưng không quá 200 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112095',
    Description: '- - - - Dung tích xi lanh trên 200 cc nhưng không quá 250 cc ',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112096',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87112099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871130',
    Description: '- Loại sử dụng động cơ đốt trong kiểu piston dung tích xi lanh trên 250 cc nhưng không quá 500 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Xe mô tô địa hình (SEN): ',
    Unit: '',
  },
  {
    HsCode: '87113011',
    Description: '- - - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87113019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87113030',
    Description: '- - Loại khác, dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87113090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871140',
    Description: '- Loại sử dụng động cơ đốt trong kiểu piston dung tích xi lanh trên 500 cc nhưng không quá 800 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Xe mô tô địa hình (SEN): ',
    Unit: '',
  },
  {
    HsCode: '87114011',
    Description: '- - - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87114019',
    Description: '- - - Loại khác (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87114020',
    Description: '- - Loại khác, dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87114090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871150',
    Description: '- Loại sử dụng động cơ đốt trong kiểu piston dung tích xi lanh trên 800 cc:',
    Unit: '',
  },
  {
    HsCode: '87115020',
    Description: '- - Dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87115090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871160',
    Description: '- Loại dùng động cơ điện để tạo động lực:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dạng CKD (SEN):',
    Unit: '',
  },
  {
    HsCode: '87116011',
    Description: '- - - Xe đạp',
    Unit: 'chiếc',
  },
  {
    HsCode: '87116012',
    Description: '- - - Xe ""kick scooter""; xe tự cân bằng ""self-balancing cycle""; xe ""pocket motorcycle” (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87116013',
    Description: '- - - Xe mô tô khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87116019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87116092',
    Description: '- - - Xe ""kick scooter""; xe tự cân bằng ""self-balancing cycle""; xe ""pocket motorcycle” (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87116093',
    Description: '- - - Xe mô tô khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87116094',
    Description: '- - - Xe đạp, có động cơ điện phụ trợ không quá 250 W và tốc độ tối đa không quá 25 km/h',
    Unit: 'chiếc',
  },
  {
    HsCode: '87116095',
    Description: '- - - Xe đạp khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87116099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87119040',
    Description: '- - Thùng xe có bánh (side-cars)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87119060',
    Description: '- - Loại khác, dạng CKD (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87119090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8712',
    Description: 'Xe đạp hai bánh và xe đạp khác (kể cả xe xích lô ba bánh chở hàng), không lắp động cơ',
    Unit: '',
  },
  {
    HsCode: '87120010',
    Description: '- Xe đạp đua',
    Unit: 'chiếc',
  },
  {
    HsCode: '87120020',
    Description: '- Xe đạp được thiết kế dành cho trẻ em (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '87120030',
    Description: '- Xe đạp khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87120090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8713',
    Description: 'Các loại xe dành cho người tàn tật, có hoặc không lắp động cơ hoặc cơ cấu đẩy cơ khí khác',
    Unit: '',
  },
  {
    HsCode: '87131000',
    Description: '- Loại không có cơ cấu đẩy cơ khí',
    Unit: 'chiếc',
  },
  {
    HsCode: '87139000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8714',
    Description: 'Bộ phận và phụ kiện của xe thuộc các nhóm từ 87.11 đến 87.13',
    Unit: '',
  },
  {
    HsCode: '871410',
    Description: '- Của mô tô (kể cả xe gắn máy có bàn đạp (moped)):',
    Unit: '',
  },
  {
    HsCode: '87141010',
    Description: '- - Yên xe',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87141020',
    Description: '- - Nan hoa và ốc bắt đầu nan hoa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87141030',
    Description: '- - Khung và càng xe kể cả càng xe cơ cấu ống lồng, giảm xóc và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87141040',
    Description: '- - Bánh răng (gearing), hộp số, bộ ly hợp và các thiết bị truyền động khác và bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87141050',
    Description: '- - Vành bánh xe',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87141060',
    Description: '- - Phanh và bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87141070',
    Description: '- - Giảm thanh (muffers) và các bộ phận của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87141090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '871420',
    Description: '- Của xe dành cho người tàn tật:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bánh xe đẩy (castor):',
    Unit: '',
  },
  {
    HsCode: '87142011',
    Description:
      '- - - Có đường kính (kể cả lốp) trên 75 mm nhưng không quá 100 mm, với điều kiện là chiều rộng của bánh xe hoặc lốp lắp vào không dưới 30 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87142012',
    Description:
      '- - - Có đường kính (kể cả lốp) trên 100 mm nhưng không quá 250 mm, với điều kiện là chiều rộng của bánh xe hoặc lốp lắp vào không dưới 30 mm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87142019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87142090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '871491',
    Description: '- - Khung xe và càng xe, và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '87149110',
    Description: '- - - Dùng cho xe đạp thuộc phân nhóm 8712.00.20 (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87149191',
    Description: '- - - - Bộ phận của càng xe đạp',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149199',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '871492',
    Description: '- - Vành bánh xe và nan hoa:',
    Unit: '',
  },
  {
    HsCode: '87149210',
    Description: '- - - Dùng cho xe đạp thuộc phân nhóm 8712.00.20 (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149290',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '871493',
    Description: '- - Moay ơ, trừ moay ơ kèm phanh (coaster braking hub) và phanh moay ơ, và líp xe:',
    Unit: '',
  },
  {
    HsCode: '87149310',
    Description: '- - - Dùng cho xe đạp thuộc phân nhóm 8712.00.20 (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149390',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '871494',
    Description: '- - Phanh, kể cả moay ơ kèm phanh (coaster braking hub) và phanh moay ơ, và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '87149410',
    Description: '- - - Dùng cho xe đạp thuộc phân nhóm 8712.00.20 (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149490',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '871495',
    Description: '- - Yên xe:',
    Unit: '',
  },
  {
    HsCode: '87149510',
    Description: '- - - Dùng cho xe đạp thuộc phân nhóm 8712.00.20 (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149590',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '871496',
    Description: '- - Pê đan và đùi đĩa, và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '87149610',
    Description: '- - - Dùng cho xe đạp thuộc phân nhóm 8712.00.20 (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149690',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '871499',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe đạp thuộc phân nhóm 8712.00.20:',
    Unit: '',
  },
  {
    HsCode: '87149911',
    Description:
      '- - - - Tay lái, cọc lái, chắn bùn, tấm phản quang (1), giá đỡ, cáp điều khiển, giá hoặc vấu bắt đèn; phụ kiện khác (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149912',
    Description: '- - - - Bánh xích và trục khuỷu; bộ phận khác (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87149991',
    Description:
      '- - - - Tay lái, cọc lái, chắn bùn, tấm phản quang (1), giá đỡ, cáp điều khiển, giá hoặc vấu bắt đèn; phụ kiện khác (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149993',
    Description: '- - - - Ốc bắt đầu nan hoa',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87149994',
    Description: '- - - - Bánh xích và trục khuỷu; bộ phận khác (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '87150000',
    Description: 'Xe đẩy trẻ em và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '8716',
    Description: 'Rơ-moóc và sơ mi rơ-moóc; xe khác, không có cơ cấu đẩy cơ khí; các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '87161000',
    Description: '- Rơ-moóc và sơ mi rơ-moóc loại nhà lưu động, dùng làm nhà ở hoặc cắm trại',
    Unit: 'chiếc',
  },
  {
    HsCode: '87162000',
    Description: '- Rơ-moóc và sơ mi rơ-moóc loại tự bốc hoặc dỡ hàng dùng trong nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Rơ-moóc và sơ mi rơ-moóc khác dùng để vận chuyển hàng hóa:',
    Unit: '',
  },
  {
    HsCode: '87163100',
    Description: '- - Rơ-moóc và sơ mi rơ-moóc gắn xi téc',
    Unit: 'chiếc',
  },
  {
    HsCode: '871639',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87163940',
    Description: '- - - Rơ-moóc và sơ mi rơ-moóc dùng trong nông nghiệp',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '87163991',
    Description: '- - - - Có khối lượng hàng chuyên chở theo thiết kế (payload) trên 200 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '87163999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '87164000',
    Description: '- Rơ-moóc và sơ mi rơ-moóc khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871680',
    Description: '- Xe khác:',
    Unit: '',
  },
  {
    HsCode: '87168010',
    Description:
      '- - Xe kéo và xe đẩy, xe kéo, xe đẩy bằng tay để chở hàng và các loại xe tương tự được vận hành bằng tay sử dụng trong các nhà máy hoặc phân xưởng, trừ xe cút kít',
    Unit: 'chiếc',
  },
  {
    HsCode: '87168020',
    Description: '- - Xe cút kít',
    Unit: 'chiếc',
  },
  {
    HsCode: '87168090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '871690',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho xe rơ-moóc và sơ mi rơ-moóc:',
    Unit: '',
  },
  {
    HsCode: '87169013',
    Description: '- - - Dùng cho hàng hóa thuộc phân nhóm 8716.20',
    Unit: 'chiếc',
  },
  {
    HsCode: '87169019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Của xe thuộc phân nhóm 8716.80.10 hoặc 8716.80.20: ',
    Unit: '',
  },
  {
    HsCode: '87169021',
    Description:
      '- - - Bánh xe đẩy (castor), có đường kính (kể cả lốp) trên 100 mm nhưng không quá 250 mm với điều kiện là chiều rộng của bánh xe hoặc lốp lắp vào không nhỏ hơn 30 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '87169022',
    Description:
      '- - - Bánh xe đẩy (castor), có đường kính (kể cả lốp) trên 75 mm nhưng không quá 100 mm, hoặc hơn 250 mm với điều kiện là chiều rộng của bánh xe hoặc lốp lắp vào không nhỏ hơn 30 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '87169023',
    Description: '- - - Loại khác, dùng cho các loại xe thuộc phân nhóm 8716.80.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '87169024',
    Description: '- - - Loại khác, dùng cho các loại xe thuộc phân nhóm 8716.80.20',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho các loại xe khác:',
    Unit: '',
  },
  {
    HsCode: '87169094',
    Description: '- - - Nan hoa và ốc bắt đầu nan hoa',
    Unit: 'chiếc',
  },
  {
    HsCode: '87169095',
    Description:
      '- - - Bánh xe đẩy (castor), có đường kính (kể cả lốp) trên 100 mm nhưng không quá 250 mm với điều kiện là chiều rộng của bánh xe hoặc lốp lắp vào không nhỏ hơn 30 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '87169096',
    Description:
      '- - - Bánh xe đẩy (castor), có đường kính (kể cả lốp) trên 75 mm nhưng không quá 100 mm, hoặc hơn 250 mm với điều kiện là chiều rộng của bánh xe hoặc lốp lắp vào không nhỏ hơn 30 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '87169099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '(1): Tham khảo TCVN 6211: 2003, TCVN 7271:2003, TCVN 7271:2003/SĐ 1:2007, 7271:2003/SĐ 2:2010, TCVN 6903:2020',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(*): Xe kéo loại sử dụng trong sân ga, ke ga đường sắt được thiết kế chủ yếu để kéo hoặc đẩy các xe khác, ví dụ như các xe moóc nhỏ. Chúng không tự vận chuyển hàng và thường nhẹ hơn và có công suất nhỏ hơn các xe kéo thuộc nhóm 87.01. Xe kéo loại này cũng có thể được sử dụng ở cầu cảng, nhà kho…',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 88',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Phương tiện bay, tàu vũ trụ, và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của Chương này, khái niệm “phương tiện bay không người lái” có nghĩa là bất kỳ phương tiện bay nào, trừ các phương tiện bay thuộc nhóm 88.01, được thiết kế để bay mà không có người lái trên phương tiện bay. Chúng có thể được thiết kế để mang trọng tải hoặc được trang bị camera kỹ thuật số tích hợp vĩnh viễn hoặc các thiết bị khác cho phép chúng thực hiện các chức năng sử dụng thực tế trong suốt chuyến bay.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, cụm từ “phương tiện bay không người lái” không bao gồm đồ chơi bay, được thiết kế chỉ cho mục đích giải trí (nhóm 95.03).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Theo mục đích của các phân nhóm từ 8802.11 đến 8802.40, khái niệm ""trọng lượng không tải"" nghĩa là trọng lượng của máy móc ở chế độ bay bình thường, không bao gồm trọng lượng của tổ bay và trọng lượng của nhiên liệu và thiết bị trừ các thiết bị được gắn cố định.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo mục đích của các phân nhóm 8806.21 đến 8806.24 và 8806.91 đến 8806.94, khái niệm ""trọng lượng cất cánh tối đa"" có nghĩa là trọng lượng tối đa của phương tiện bay ở chế độ bay bình thường, khi cất cánh, kể cả trọng lượng của trọng tải, thiết bị và nhiên liệu. ',
    Unit: '',
  },
  {
    HsCode: '88010000',
    Description:
      'Khí cầu và khinh khí cầu điều khiển được; tàu lượn, tàu lượn treo và các phương tiện bay khác không dùng động cơ',
    Unit: 'chiếc',
  },
  {
    HsCode: '8802',
    Description:
      'Phương tiện bay khác (ví dụ, trực thăng, máy bay); trừ phương tiện bay không người lái thuộc nhóm 88.06; tàu vũ trụ (kể cả vệ tinh) và tàu bay trong quĩ đạo và phương tiện đẩy để phóng tàu vũ trụ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Trực thăng:',
    Unit: '',
  },
  {
    HsCode: '88021100',
    Description: '- - Trọng lượng không tải không quá 2.000 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '88021200',
    Description: '- - Trọng lượng không tải trên 2.000 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '880220',
    Description: '- Máy bay và phương tiện bay khác, có trọng lượng không tải không quá 2.000 kg:',
    Unit: '',
  },
  {
    HsCode: '88022010',
    Description: '- - Máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '88022090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '880230',
    Description: '- Máy bay và phương tiện bay khác, có trọng lượng không tải trên 2.000 kg nhưng không quá 15.000 kg:',
    Unit: '',
  },
  {
    HsCode: '88023010',
    Description: '- - Máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '88023090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '880240',
    Description: '- Máy bay và phương tiện bay khác, có trọng lượng không tải trên 15.000kg:',
    Unit: '',
  },
  {
    HsCode: '88024010',
    Description: '- - Máy bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '88024090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '88026000',
    Description: '- Tàu vũ trụ (kể cả vệ tinh) và tàu bay trong quỹ đạo và phương tiện đẩy để phóng tàu vũ trụ',
    Unit: 'chiếc',
  },
  {
    HsCode: '8804',
    Description: 'Dù (kể cả dù điều khiển được và dù lượn) và dù xoay; các bộ phận và phụ kiện của chúng',
    Unit: '',
  },
  {
    HsCode: '88040010',
    Description: '- Dù xoay và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '88040090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8805',
    Description:
      'Thiết bị phóng dùng cho phương tiện bay; dàn hãm tốc độ dùng cho tàu sân bay hoặc thiết bị tương tự; thiết bị huấn luyện bay mặt đất; các bộ phận của các thiết bị trên',
    Unit: '',
  },
  {
    HsCode: '88051000',
    Description:
      '- Thiết bị phóng dùng cho phương tiện bay và các bộ phận của chúng; dàn hãm tốc độ dùng cho tàu sân bay hoặc thiết bị tương tự và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thiết bị huấn luyện bay mặt đất và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '88052100',
    Description: '- - Thiết bị mô phỏng tập trận trên không và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '880529',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '88052910',
    Description: '- - - Thiết bị huấn luyện bay mặt đất',
    Unit: 'chiếc',
  },
  {
    HsCode: '88052990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8806',
    Description: 'Phương tiện bay không người lái',
    Unit: '',
  },
  {
    HsCode: '88061000',
    Description: '- Được thiết kế để vận chuyển hành khách',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác, chỉ sử dụng cho chuyến bay được điều khiển từ xa:',
    Unit: '',
  },
  {
    HsCode: '88062100',
    Description: '- - Trọng lượng cất cánh tối đa không quá 250 g',
    Unit: 'chiếc',
  },
  {
    HsCode: '88062200',
    Description: '- - Trọng lượng cất cánh tối đa trên 250 g nhưng không quá 7 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '88062300',
    Description: '- - Trọng lượng cất cánh tối đa trên 7 kg nhưng không quá 25 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '88062400',
    Description: '- - Trọng lượng cất cánh tối đa trên 25 kg nhưng không quá 150 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '88062900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '88069100',
    Description: '- - Trọng lượng cất cánh tối đa không quá 250 g',
    Unit: 'chiếc',
  },
  {
    HsCode: '88069200',
    Description: '- - Trọng lượng cất cánh tối đa trên 250 g nhưng không quá 7 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '88069300',
    Description: '- - Trọng lượng cất cánh tối đa trên 7 kg nhưng không quá 25 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '88069400',
    Description: '- - Trọng lượng cất cánh tối đa trên 25 kg nhưng không quá 150 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '88069900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8807',
    Description: 'Các bộ phận của các mặt hàng thuộc nhóm 88.01, 88.02 hoặc 88.06',
    Unit: '',
  },
  {
    HsCode: '88071000',
    Description: '- Cánh quạt và rôto và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '88072000',
    Description: '- Bộ càng- bánh và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '88073000',
    Description: '- Các bộ phận khác của máy bay, trực thăng hoặc phương tiện bay không người lái',
    Unit: 'chiếc',
  },
  {
    HsCode: '88079000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 89',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Tàu thủy, thuyền và các kết cấu nổi',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '1. Thân tàu, tàu chưa hoàn chỉnh hoặc chưa hoàn thiện, đã lắp ráp, hoặc chưa lắp ráp hoặc tháo rời, hoặc tàu hoàn chỉnh chưa lắp ráp hoặc tháo rời, được phân loại vào nhóm 89.06 nếu không có đặc trưng cơ bản của một loại tàu cụ thể. ',
    Unit: '',
  },
  {
    HsCode: '8901',
    Description:
      'Tàu thủy chở khách, thuyền, xuồng du lịch, phà, tàu thủy chở hàng, xà lan và các tàu thuyền tương tự để vận chuyển người hoặc hàng hóa',
    Unit: '',
  },
  {
    HsCode: '890110',
    Description:
      '- Tàu thủy chở khách, thuyền, xuồng du lịch và các tàu thuyền tương tự được thiết kế chủ yếu để vận chuyển người; phà các loại:',
    Unit: '',
  },
  {
    HsCode: '89011010',
    Description: '- - Tổng dung tích (gross tonnage) không quá 26 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89011020',
    Description: '- - Tổng dung tích (gross tonnage) trên 26 nhưng không quá 500 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89011060',
    Description: '- - Tổng dung tích (gross tonnage) trên 500 nhưng không quá 1.000 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89011070',
    Description: '- - Tổng dung tích (gross tonnage) trên 1.000 nhưng không quá 4.000 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89011080',
    Description: '- - Tổng dung tích (gross tonnage) trên 4.000 nhưng không quá 5.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89011090',
    Description: '- - Tổng dung tích (gross tonnage) trên 5.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '890120',
    Description: '- Tàu chở chất lỏng hoặc khí hóa lỏng:',
    Unit: '',
  },
  {
    HsCode: '89012050',
    Description: '- - Tổng dung tích (gross tonnage) không quá 5.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Tổng dung tích (gross tonnage) trên 5.000 nhưng không quá 50.000:',
    Unit: '',
  },
  {
    HsCode: '89012071',
    Description: '- - - Tổng dung tích (gross tonnage) trên 5.000 nhưng không quá 20.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89012072',
    Description: '- - - Tổng dung tích (gross tonnage) trên 20.000 nhưng không quá 30.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89012073',
    Description: '- - - Tổng dung tích (gross tonnage) trên 30.000 nhưng không quá 50.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89012080',
    Description: '- - Tổng dung tích (gross tonnage) trên 50.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '890130',
    Description: '- Tàu thuyền đông lạnh, trừ loại thuộc phân nhóm 8901.20:',
    Unit: '',
  },
  {
    HsCode: '89013050',
    Description: '- - Tổng dung tích (gross tonnage) không quá 5.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89013070',
    Description: '- - Tổng dung tích (gross tonnage) trên 5.000 nhưng không quá 50.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89013080',
    Description: '- - Tổng dung tích (gross tonnage) trên 50.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '890190',
    Description: '- Tàu thuyền khác để vận chuyển hàng hóa và tàu thuyền khác để vận chuyển cả người và hàng hóa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Không có động cơ đẩy:',
    Unit: '',
  },
  {
    HsCode: '89019011',
    Description: '- - - Tổng dung tích (gross tonnage) không quá 26 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019012',
    Description: '- - - Tổng dung tích (gross tonnage) trên 26 nhưng không quá 500 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019014',
    Description: '- - - Tổng dung tích (gross tonnage) trên 500',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Có động cơ đẩy:',
    Unit: '',
  },
  {
    HsCode: '89019031',
    Description: '- - - Tổng dung tích (gross tonnage) không quá 26 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019032',
    Description: '- - - Tổng dung tích (gross tonnage) trên 26 nhưng không quá 500 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019033',
    Description: '- - - Tổng dung tích (gross tonnage) trên 500 nhưng không quá 1.000 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019034',
    Description: '- - - Tổng dung tích (gross tonnage) trên 1.000 nhưng không quá 4.000 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019035',
    Description: '- - - Tổng dung tích (gross tonnage) trên 4.000 nhưng không quá 5.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019036',
    Description: '- - - Tổng dung tích (gross tonnage) trên 5.000 nhưng không quá 50.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89019037',
    Description: '- - - Tổng dung tích (gross tonnage) trên 50.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '8902',
    Description:
      'Tàu thuyền đánh bắt thủy sản; tàu chế biến và các loại tàu khác dùng cho chế biến hoặc bảo quản thủy sản đánh bắt',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Tàu thuyền đánh bắt thủy sản:',
    Unit: '',
  },
  {
    HsCode: '89020031',
    Description: '- - Tổng dung tích (gross tonnage) không quá 26 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020032',
    Description: '- - Tổng dung tích (gross tonnage) trên 26 nhưng dưới 40 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020033',
    Description: '- - Tổng dung tích (gross tonnage) từ 40 trở lên nhưng không quá 101',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020034',
    Description: '- - Tổng dung tích (gross tonnage) trên 101 nhưng không quá 250 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020035',
    Description: '- - Tổng dung tích (gross tonnage) trên 250 nhưng không quá 1.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020036',
    Description: '- - Tổng dung tích (gross tonnage) trên 1.000 nhưng không quá 4.000 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020037',
    Description: '- - Tổng dung tích (gross tonnage) trên 4.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '89020041',
    Description: '- - Tổng dung tích (gross tonnage) không quá 26 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020042',
    Description: '- - Tổng dung tích (gross tonnage) trên 26 nhưng dưới 40 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020043',
    Description: '- - Tổng dung tích (gross tonnage) từ 40 trở lên nhưng không quá 101',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020044',
    Description: '- - Tổng dung tích (gross tonnage) trên 101 nhưng không quá 250 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020045',
    Description: '- - Tổng dung tích (gross tonnage) trên 250 nhưng không quá 1.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020046',
    Description: '- - Tổng dung tích (gross tonnage) trên 1.000 nhưng không quá 4.000 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89020047',
    Description: '- - Tổng dung tích (gross tonnage) trên 4.000',
    Unit: 'chiếc',
  },
  {
    HsCode: '8903',
    Description:
      'Du thuyền hạng nhẹ và các loại tàu thuyền khác phục vụ nghỉ ngơi, giải trí hoặc thể thao; thuyền dùng mái chèo và canô',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Thuyền, xuồng có thể bơm hơi (bao gồm cả thuyền, xuồng thân cứng có thể bơm hơi):',
    Unit: '',
  },
  {
    HsCode: '89031100',
    Description:
      '- - Được trang bị hoặc thiết kế để lắp với động cơ, trọng lượng không tải (tịnh) (không bao gồm động cơ) không quá 100 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '89031200',
    Description: '- - Không được thiết kế để sử dụng với động cơ và trọng lượng không tải (tịnh) không quá 100 kg',
    Unit: 'chiếc',
  },
  {
    HsCode: '89031900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thuyền buồm, trừ loại có thể bơm hơi, có hoặc không có động cơ phụ trợ:',
    Unit: '',
  },
  {
    HsCode: '89032100',
    Description: '- - Có chiều dài không quá 7,5 m',
    Unit: 'chiếc',
  },
  {
    HsCode: '89032200',
    Description: '- - Có chiều dài trên 7,5 m nhưng không quá 24 m',
    Unit: 'chiếc',
  },
  {
    HsCode: '89032300',
    Description: '- - Có chiều dài trên 24 m',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Thuyền máy, trừ loại có thể bơm hơi, không bao gồm thuyền máy có gắn máy bên ngoài:',
    Unit: '',
  },
  {
    HsCode: '89033100',
    Description: '- - Có chiều dài không quá 7,5 m',
    Unit: 'chiếc',
  },
  {
    HsCode: '89033200',
    Description: '- - Có chiều dài trên 7,5 m nhưng không quá 24 m',
    Unit: 'chiếc',
  },
  {
    HsCode: '89033300',
    Description: '- - Có chiều dài trên 24 m',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '89039300',
    Description: '- - Có chiều dài không quá 7,5 m',
    Unit: 'chiếc',
  },
  {
    HsCode: '89039900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8904',
    Description: 'Tàu kéo và tàu đẩy',
    Unit: '',
  },
  {
    HsCode: '89040010',
    Description: '- Tổng dung tích (gross tonnage) không quá 26 ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Tổng dung tích (gross tonnage) trên 26:',
    Unit: '',
  },
  {
    HsCode: '89040032',
    Description: '- - Công suất không quá 1.200 hp',
    Unit: 'chiếc',
  },
  {
    HsCode: '89040033',
    Description: '- - Công suất trên 1.200 hp nhưng không quá 3.200 hp',
    Unit: 'chiếc',
  },
  {
    HsCode: '89040034',
    Description: '- - Công suất trên 3.200 hp nhưng không quá 4.000 hp',
    Unit: 'chiếc',
  },
  {
    HsCode: '89040035',
    Description: '- - Công suất trên 4.000 hp',
    Unit: 'chiếc',
  },
  {
    HsCode: '8905',
    Description:
      'Tàu đèn hiệu, tàu cứu hỏa, tàu hút nạo vét (tàu cuốc), cần cẩu nổi và tàu thuyền khác mà tính năng di động trên mặt nước chỉ nhằm bổ sung cho chức năng chính của các tàu thuyền này; ụ nổi sửa chữa tàu; dàn khoan hoặc dàn sản xuất nổi hoặc nửa nổi nửa chìm',
    Unit: '',
  },
  {
    HsCode: '89051000',
    Description: '- Tàu hút nạo vét (tàu cuốc) ',
    Unit: 'chiếc',
  },
  {
    HsCode: '89052000',
    Description: '- Dàn khoan hoặc dàn sản xuất nổi hoặc nửa nổi nửa chìm',
    Unit: 'chiếc',
  },
  {
    HsCode: '890590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '89059010',
    Description: '- - Ụ nổi sửa chữa tàu',
    Unit: 'chiếc',
  },
  {
    HsCode: '89059090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8906',
    Description: 'Tàu thuyền khác, kể cả tàu chiến và xuồng cứu sinh trừ thuyền dùng mái chèo',
    Unit: '',
  },
  {
    HsCode: '89061000',
    Description: '- Tàu chiến',
    Unit: 'chiếc',
  },
  {
    HsCode: '890690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '89069010',
    Description: '- - Có lượng giãn nước không quá 30 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '89069020',
    Description: '- - Có lượng giãn nước trên 30 tấn nhưng không quá 300 tấn',
    Unit: 'chiếc',
  },
  {
    HsCode: '89069090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '8907',
    Description:
      'Kết cấu nổi khác (ví dụ, bè mảng, thùng chứa chất lỏng, ketxon giếng kín (coffer-dams), cầu lên bờ, các loại phao nổi và mốc hiệu)',
    Unit: '',
  },
  {
    HsCode: '89071000',
    Description: '- Bè mảng có thể bơm hơi',
    Unit: 'chiếc',
  },
  {
    HsCode: '890790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '89079010',
    Description: '- - Các loại phao nổi (buoys)',
    Unit: 'chiếc',
  },
  {
    HsCode: '89079090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '89080000',
    Description: 'Tàu thuyền và kết cấu nổi khác để phá dỡ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: 'PHẦN XVIII',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'DỤNG CỤ VÀ THIẾT BỊ QUANG HỌC, NHIẾP ẢNH, ĐIỆN ẢNH, ĐO LƯỜNG, KIỂM TRA, CHÍNH XÁC, Y TẾ HOẶC PHẪU THUẬT; ĐỒNG HỒ THỜI GIAN VÀ ĐỒNG HỒ CÁ NHÂN; NHẠC CỤ; CÁC BỘ PHẬN VÀ PHỤ KIỆN CỦA CHÚNG',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 90',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Dụng cụ và thiết bị quang học, nhiếp ảnh, điện ảnh, đo lường, kiểm tra, chính xác, y tế hoặc phẫu thuật; các bộ phận và phụ kiện của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các mặt hàng sử dụng trong máy, thiết bị hoặc cho các mục đích kỹ thuật khác, bằng cao su lưu hóa trừ cao su cứng (nhóm 40.16), bằng da thuộc hoặc da tổng hợp (nhóm 42.05) hoặc bằng vật liệu dệt (nhóm 59.11);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Băng, đai hoặc các sản phẩm trợ giúp khác bằng vật liệu dệt, có tính đàn hồi được dùng làm dụng cụ để nâng hoặc giữ bộ phận cơ thể (ví dụ, đai nâng dùng cho phụ nữ có thai, băng nâng ngực, băng giữ bụng, băng nẹp khớp hoặc cơ) (Phần XI);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Hàng hóa chịu lửa của nhóm 69.03; đồ gốm sứ dùng trong phòng thí nghiệm, cho ngành hóa chất hoặc các mục đích kỹ thuật khác, thuộc nhóm 69.09;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Gương thủy tinh, chưa gia công quang học, thuộc nhóm 70.09, hoặc gương bằng kim loại cơ bản hoặc gương bằng kim loại quý, không phải là bộ phận quang học (nhóm 83.06 hoặc Chương 71);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Hàng hóa thuộc nhóm 70.07, 70.08, 70.11, 70.14, 70.15 hoặc 70.17;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Các bộ phận có công dụng chung, như đã định nghĩa trong Chú giải 2 của Phần XV, bằng kim loại cơ bản (Phần XV) hoặc các loại hàng hóa tương tự bằng plastic (Chương 39); tuy nhiên, các mặt hàng được thiết kế đặc biệt để sử dụng riêng cho cấy ghép trong y tế, phẫu thuật, nha khoa hoặc thú y được xếp vào nhóm 90.21; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Bơm có gắn các thiết bị đo lường, thuộc nhóm 84.13; máy đếm hoặc máy kiểm tra hoạt động bằng nguyên lý cân, hoặc cân thăng bằng (nhóm 84.23); máy nâng hoặc hạ (từ nhóm 84.25 đến 84.28); các loại máy cắt xén giấy hoặc bìa (nhóm 84.41); bộ phận điều chỉnh hoặc dụng cụ lắp trên máy công cụ hoặc máy cắt bằng tia nước, thuộc nhóm 84.66, bao gồm các bộ phận có lắp các dụng cụ quang học để đọc thang đo (ví dụ, đầu chia độ ""quang học"") nhưng thực tế chúng không phải là dụng cụ quang học (ví dụ, kính thiên văn thẳng); máy tính (nhóm 84.70); van hoặc các thiết bị khác thuộc nhóm 84.81; máy và các thiết bị (kể cả các thiết bị dùng để chiếu hoặc vẽ mạch điện lên vật liệu bán dẫn có độ nhạy) thuộc nhóm 84.86;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Đèn pha hoặc đèn rọi loại dùng cho xe đạp hoặc xe có động cơ (nhóm 85.12); đèn điện xách tay thuộc nhóm 85.13; máy ghi âm, máy tái tạo âm thanh hoặc máy ghi lại âm thanh dùng trong điện ảnh (nhóm 85.19); đầu từ ghi âm (nhóm 85.22); camera truyền hình, camera kỹ thuật số và camera ghi hình ảnh (nhóm 85.25); thiết bị ra đa, các thiết bị dẫn đường bằng sóng vô tuyến hoặc các thiết bị điều khiển từ xa bằng sóng vô tuyến (nhóm 85.26); đầu nối sợi quang, bó hoặc cáp sợi quang (nhóm 85.36); các thiết bị điều khiển số thuộc nhóm 85.37; đèn pha gắn kín của nhóm 85.39; cáp sợi quang thuộc nhóm 85.44;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Đèn pha hoặc đèn rọi thuộc nhóm 94.05;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các mặt hàng thuộc Chương 95;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(l) Chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các mặt hàng tương tự của nhóm 96.20;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Dụng cụ đo dung tích, được phân loại theo vật liệu cấu thành chúng; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(n) Suốt chỉ, ống hoặc các loại lõi tương tự (được phân loại theo vật liệu cấu thành của chúng, ví dụ, nhóm 39.23 hoặc Phần XV).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Theo Chú giải 1 ở trên, các bộ phận và phụ kiện cho các máy, thiết bị, dụng cụ hoặc các mặt hàng của Chương này được phân loại theo các nguyên tắc sau:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các bộ phận và phụ kiện là hàng hóa thuộc nhóm bất kỳ trong Chương này hoặc Chương 84, 85 hoặc 91 (trừ các nhóm 84.87, 85.48 hoặc 90.33) trong mọi trường hợp được phân loại vào các nhóm riêng của chúng; ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các bộ phận và phụ kiện khác, nếu chỉ dùng hoặc chủ yếu dùng cho một loại máy, thiết bị hoặc dụng cụ riêng biệt, hoặc với một số loại máy, thiết bị hoặc dụng cụ trong cùng một nhóm (kể cả máy, thiết bị hoặc dụng cụ của nhóm 90.10, 90.13 hoặc 90.31) được phân loại theo máy, thiết bị hoặc dụng cụ cùng loại đó;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Tất cả các bộ phận và phụ kiện khác được phân loại trong nhóm 90.33.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '3. Các quy định trong Chú giải 3 và 4 của Phần XVI cũng áp dụng đối với Chương này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 90.05 không áp dụng cho kính ngắm dùng để lắp vào vũ khí, kính ngắm tiềm vọng để lắp vào tàu ngầm hoặc xe tăng, hoặc kính viễn vọng dùng cho máy, thiết bị, dụng cụ hoặc đồ dùng của Chương này hoặc của Phần XVI; kính ngắm và kính viễn vọng như vậy được phân loại vào nhóm 90.13.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Tuy nhiên, theo Chú giải này, những dụng cụ, thiết bị hoặc máy quang học dùng để đo hoặc kiểm tra, có thể phân loại ở cả 2 nhóm 90.13 và 90.31 thì phải xếp vào nhóm 90.31. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Theo mục đích của nhóm 90.21, thuật ngữ ""các dụng cụ chỉnh hình"" được hiểu là các dụng cụ dùng để:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ngăn ngừa hoặc điều chỉnh một số biến dạng của cơ thể; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Trợ giúp hoặc nẹp giữ các bộ phận cơ thể sau khi bị bệnh, phẫu thuật hoặc bị thương.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Các dụng cụ chỉnh hình bao gồm cả giày, dép và các đế đặc biệt bên trong của giày, dép được thiết kế để cố định tình trạng sau khi chỉnh hình, với điều kiện là chúng hoặc (1) được làm theo số đo hoặc (2) được sản xuất hàng loạt, dưới hình thức độc dạng và không theo đôi và được thiết kế để dùng cho cả hai chân. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '7. Nhóm 90.32 chỉ áp dụng với:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các thiết bị và dụng cụ dùng để điều khiển tự động dòng chảy, mức, áp suất hoặc các biến số khác của chất lỏng hoặc chất khí, hoặc dùng để điều khiển tự động nhiệt độ, cho dù các hoạt động của chúng có hoặc không phụ thuộc vào sự biến đổi của một hiện tượng điện mà hiện tượng điện đó biến đổi theo yếu tố được điều khiển tự động, chúng được thiết kế để điều chỉnh, và duy trì yếu tố này ở một giá trị mong muốn, được thiết lập để chống lại các yếu tố bất thường, thông qua việc định kỳ hoặc thường xuyên đo lường giá trị thực của nó; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các thiết bị điều chỉnh tự động các đại lượng điện, và các thiết bị hoặc dụng cụ điều khiển tự động các đại lượng phi điện, mà hoạt động của chúng phụ thuộc vào một hiện tượng điện biến đổi theo yếu tố được điều khiển, chúng được thiết kế để điều chỉnh và duy trì yếu tố này ở một giá trị mong muốn, được thiết lập để chống lại các yếu tố bất thường thông qua việc định kỳ hoặc thường xuyên đo lường giá trị thực của nó.',
    Unit: '',
  },
  {
    HsCode: '9001',
    Description:
      'Sợi quang và bó sợi quang; cáp sợi quang trừ các loại thuộc nhóm 85.44; vật liệu phân cực dạng tấm và lá; thấu kính (kể cả thấu kính áp tròng), lăng kính, gương và các bộ phận quang học khác, bằng vật liệu bất kỳ, chưa lắp ráp, trừ loại bằng thủy tinh chưa gia công về mặt quang học',
    Unit: '',
  },
  {
    HsCode: '900110',
    Description: '- Sợi quang, bó sợi quang và cáp sợi quang:',
    Unit: '',
  },
  {
    HsCode: '90011010',
    Description: '- - Sử dụng cho viễn thông và cho ngành điện khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '90011090',
    Description: '- - Loại khác',
    Unit: 'kg/m',
  },
  {
    HsCode: '90012000',
    Description: '- Vật liệu phân cực dạng tấm và lá',
    Unit: 'kg/m/chiếc',
  },
  {
    HsCode: '90013000',
    Description: '- Thấu kính áp tròng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90014000',
    Description: '- Thấu kính thủy tinh làm kính đeo mắt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90015000',
    Description: '- Thấu kính bằng vật liệu khác làm kính đeo mắt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '900190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90019010',
    Description: '- - Sử dụng cho máy ảnh hoặc máy quay phim hoặc máy chiếu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90019090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9002',
    Description:
      'Thấu kính, lăng kính, gương và các bộ phận quang học khác, bằng vật liệu bất kỳ, đã lắp ráp, là các bộ phận hoặc phụ kiện để lắp ráp cho các dụng cụ hoặc thiết bị, trừ loại làm bằng thủy tinh chưa được gia công quang học',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Vật kính:',
    Unit: '',
  },
  {
    HsCode: '90021100',
    Description: '- - Dùng cho camera, máy chiếu hoặc máy phóng to hoặc máy thu nhỏ ảnh',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90021900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '900220',
    Description: '- Kính lọc ánh sáng:',
    Unit: '',
  },
  {
    HsCode: '90022010',
    Description: '- - Dùng cho máy chiếu phim',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90022020',
    Description: '- - Dùng cho máy quay phim, máy ảnh và máy chiếu khác ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90022090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '900290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90029020',
    Description: '- - Dùng cho máy chiếu phim',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90029030',
    Description: '- - Dùng cho máy quay phim, máy ảnh và máy chiếu khác ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '90029090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9003',
    Description: 'Khung và gọng cho kính đeo, kính bảo hộ hoặc các loại tương tự, và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Khung và gọng:',
    Unit: '',
  },
  {
    HsCode: '90031100',
    Description: '- - Bằng plastic',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90031900',
    Description: '- - Bằng vật liệu khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90039000',
    Description: '- Các bộ phận',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9004',
    Description: 'Kính đeo, kính bảo hộ và các loại tương tự, kính thuốc, kính bảo vệ mắt hoặc loại khác',
    Unit: '',
  },
  {
    HsCode: '90041000',
    Description: '- Kính râm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '900490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90049010',
    Description: '- - Kính thuốc',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90049050',
    Description: '- - Kính bảo hộ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90049090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9005',
    Description:
      'Ống nhòm loại hai mắt, ống nhòm đơn, kính viễn vọng quang học khác, và khung giá của các loại trên; các dụng cụ thiên văn khác và khung giá của chúng, trừ các dụng cụ dùng cho thiên văn học vô tuyến',
    Unit: '',
  },
  {
    HsCode: '90051000',
    Description: '- Ống nhòm loại hai mắt',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '900580',
    Description: '- Dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '90058010',
    Description: '- - Dụng cụ thiên văn, trừ dụng cụ thiên văn vô tuyến',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90058090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '900590',
    Description: '- Bộ phận và phụ kiện (kể cả khung giá):',
    Unit: '',
  },
  {
    HsCode: '90059010',
    Description: '- - Dùng cho dụng cụ thiên văn, trừ dụng cụ thiên văn vô tuyến',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90059090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9006',
    Description:
      'Máy ảnh (trừ máy quay phim); thiết bị đèn chớp và đèn flash máy ảnh trừ đèn phóng điện thuộc nhóm 85.39',
    Unit: '',
  },
  {
    HsCode: '90063000',
    Description:
      '- Máy ảnh được thiết kế đặc biệt để dùng dưới nước, để thám không (aerial survey) hoặc dùng trong y tế hoặc trong phẫu thuật nội tạng; máy ảnh đối chiếu dùng cho ngành pháp y hoặc khoa học hình sự',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90064000',
    Description: '- Máy chụp lấy ảnh ngay',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Máy ảnh loại khác:',
    Unit: '',
  },
  {
    HsCode: '900653',
    Description: '- - Sử dụng phim cuộn khổ rộng 35 mm: ',
    Unit: '',
  },
  {
    HsCode: '90065310',
    Description: '- - - Có bộ ngắm qua thấu kính (kính phản chiếu đơn (SLR)) ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90065390',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '900659',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng phim cuộn khổ rộng dưới 35 mm:',
    Unit: '',
  },
  {
    HsCode: '90065911',
    Description: '- - - - Có bộ ngắm qua thấu kính (kính phản chiếu đơn (SLR)) ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90065919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, dùng để chuẩn bị khuôn in hoặc ống in: ',
    Unit: '',
  },
  {
    HsCode: '90065921',
    Description: '- - - - Máy vẽ ảnh laser',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90065929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90065930',
    Description: '- - - Loại khác, máy vẽ ảnh laser hoặc máy tạo dựng ảnh với bộ xử lý ảnh mành',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90065990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Thiết bị đèn chớp và đèn flash máy ảnh:',
    Unit: '',
  },
  {
    HsCode: '90066100',
    Description: '- - Thiết bị đèn flash loại dùng đèn phóng điện (""điện tử"")',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90066900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '900691',
    Description: '- - Sử dụng cho máy ảnh:',
    Unit: '',
  },
  {
    HsCode: '90069110',
    Description: '- - - Sử dụng cho máy vẽ ảnh laser thuộc phân nhóm 9006.59.21 ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90069140',
    Description: '- - - Loại khác, dùng cho máy ảnh của phân nhóm 9006.40 hoặc 9006.53',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90069150',
    Description: '- - - Loại khác, dùng cho máy ảnh của phân nhóm 9006.59.11 hoặc 9006.59.19',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90069190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '900699',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90069910',
    Description: '- - - Sử dụng cho thiết bị đèn chớp máy ảnh',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90069990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9007',
    Description: 'Máy quay phim và máy chiếu phim, có hoặc không kèm thiết bị ghi hoặc tái tạo âm thanh',
    Unit: '',
  },
  {
    HsCode: '90071000',
    Description: '- Máy quay phim',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '900720',
    Description: '- Máy chiếu phim:',
    Unit: '',
  },
  {
    HsCode: '90072010',
    Description: '- - Dùng cho phim khổ rộng dưới 16 mm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90072090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90079100',
    Description: '- - Dùng cho máy quay phim',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90079200',
    Description: '- - Dùng cho máy chiếu phim',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9008',
    Description: 'Máy chiếu hình ảnh, trừ máy chiếu phim; máy phóng và máy thu nhỏ ảnh (trừ máy chiếu phim)',
    Unit: '',
  },
  {
    HsCode: '900850',
    Description: '- Máy chiếu hình ảnh, máy phóng và máy thu nhỏ ảnh: ',
    Unit: '',
  },
  {
    HsCode: '90085010',
    Description: '- - Máy đọc vi phim, vi thẻ hoặc vi bản khác, có hoặc không có khả năng sao chép ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90085090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '900890',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90089020',
    Description: '- - Của máy phóng và máy thu nhỏ ảnh (trừ máy chiếu phim)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90089090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9010',
    Description:
      'Máy và thiết bị dùng trong phòng làm ảnh (kể cả điện ảnh), chưa được ghi hoặc chi tiết ở nơi nào khác thuộc Chương này; máy xem âm bản; màn ảnh của máy chiếu',
    Unit: '',
  },
  {
    HsCode: '90101000',
    Description:
      '- Máy và thiết bị sử dụng cho việc tráng tự động phim hoặc giấy ảnh (kể cả điện ảnh) dạng cuộn hoặc dùng cho việc phơi sáng tự động các phim đã tráng lên các cuộn giấy ảnh',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901050',
    Description: '- Máy và thiết bị khác sử dụng trong phòng làm ảnh (kể cả điện ảnh); máy xem âm bản:',
    Unit: '',
  },
  {
    HsCode: '90105010',
    Description: '- - Máy để chiếu hoặc vẽ mạch lên các chất nền có độ nhạy sáng để sản xuất tấm mạch in/tấm dây in ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90105020',
    Description:
      '- - Thiết bị biên tập và ghi tựa đề điện ảnh; thiết bị đọc X quang; máy chụp quang sử dụng cho quá trình tách màu ra phim; máy plate maker tự động',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90105090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901060',
    Description: '- Màn ảnh của máy chiếu:',
    Unit: '',
  },
  {
    HsCode: '90106010',
    Description: '- - Của loại từ 300 inch trở lên',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90106090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901090',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90109020',
    Description: '- - Của hàng hóa thuộc phân nhóm 9010.10.00',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90109030',
    Description:
      '- - Bộ phận và phụ kiện của máy để chiếu hoặc vẽ mạch lên các chất nền có độ nhạy sáng để sản xuất tấm mạch in/tấm dây in ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90109090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9011',
    Description: 'Kính hiển vi quang học phức hợp, kể cả loại để xem vi ảnh, vi phim quay hoặc vi chiếu',
    Unit: '',
  },
  {
    HsCode: '90111000',
    Description: '- Kính hiển vi soi nổi',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90112000',
    Description: '- Kính hiển vi khác để xem vi ảnh, vi phim quay hoặc vi chiếu',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90118000',
    Description: '- Các loại kính hiển vi khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90119000',
    Description: '- Bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9012',
    Description: 'Kính hiển vi trừ kính hiển vi quang học; thiết bị nhiễu xạ',
    Unit: '',
  },
  {
    HsCode: '90121000',
    Description: '- Kính hiển vi trừ kính hiển vi quang học; thiết bị nhiễu xạ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90129000',
    Description: '- Bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9013',
    Description:
      'Thiết bị tạo tia laser, trừ đi-ốt laser; các thiết bị và dụng cụ quang học khác, chưa được nêu hoặc chi tiết ở nơi nào khác trong Chương này',
    Unit: '',
  },
  {
    HsCode: '901310',
    Description:
      '- Kính ngắm để lắp vào vũ khí; kính tiềm vọng; kính viễn vọng được thiết kế là bộ phận của máy, thiết bị, dụng cụ hoặc bộ dụng cụ của Chương này hoặc Phần XVI:',
    Unit: '',
  },
  {
    HsCode: '90131010',
    Description: '- - Kính ngắm để lắp vào vũ khí; kính tiềm vọng',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90131020',
    Description:
      '- - Kính viễn vọng được thiết kế là bộ phận của máy, thiết bị, dụng cụ hoặc bộ dụng cụ của Chương này hoặc Phần XVI',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90132000',
    Description: '- Thiết bị tạo tia laser, trừ đi-ốt laser',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901380',
    Description: '- Các bộ phận, thiết bị và dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '90138010',
    Description: '- - Thiết bị quang học để xác định và sửa lỗi cho tấm mạch in/tấm dây in và mạch in đã lắp ráp ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90138090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901390',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90139010',
    Description: '- - Của hàng hóa thuộc phân nhóm 9013.20.00',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90139060',
    Description: '- - Của hàng hóa thuộc phân nhóm 9013.80.10',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90139070',
    Description: '- - Của hàng hóa thuộc phân nhóm 9013.10.10',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90139090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9014',
    Description: 'La bàn xác định phương hướng; các thiết bị và dụng cụ dẫn đường khác',
    Unit: '',
  },
  {
    HsCode: '90141000',
    Description: '- La bàn xác định phương hướng',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90142000',
    Description: '- Thiết bị và dụng cụ dẫn đường hàng không hoặc hàng hải (trừ la bàn)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901480',
    Description: '- Thiết bị và dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng trên tàu thuyền, kết hợp hoặc hoạt động cùng với máy xử lý dữ liệu tự động:',
    Unit: '',
  },
  {
    HsCode: '90148011',
    Description: '- - - Máy dò siêu âm (Sonar) hoặc máy đo sâu hồi âm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90148019',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90148090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901490',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90149010',
    Description: '- - Của thiết bị và dụng cụ, dùng trên tàu thuyền, hoạt động cùng với máy xử lý dữ liệu tự động ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90149090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9015',
    Description:
      'Thiết bị và dụng cụ dùng để quan trắc (kể cả quan trắc ảnh), dùng cho thủy văn học, hải dương học, thủy học, khí tượng học hoặc địa lý học, trừ la bàn; máy đo xa',
    Unit: '',
  },
  {
    HsCode: '901510',
    Description: '- Máy đo xa:',
    Unit: '',
  },
  {
    HsCode: '90151010',
    Description: '- - Dùng trong việc chụp ảnh hoặc quay phim',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90151090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90152000',
    Description: '- Máy kinh vĩ và tốc kế góc (máy toàn đạc -tacheometers)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90153000',
    Description: '- Dụng cụ đo cân bằng (levels)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90154000',
    Description: '- Thiết bị và dụng cụ quan trắc ảnh',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901580',
    Description: '- Thiết bị và dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '90158010',
    Description: '- - Thiết bị quan trắc sóng vô tuyến và gió điện từ ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90158090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90159000',
    Description: '- Bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90160000',
    Description: 'Cân với độ nhậy 5cg (50mg) hoặc chính xác hơn, có hoặc không có quả cân',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9017',
    Description:
      'Dụng cụ vẽ, vạch mức dấu hoặc dụng cụ tính toán toán học (ví dụ, máy vẽ phác, máy vẽ truyền, thước đo góc, bộ đồ vẽ, thước logarit, bàn tính dùng đĩa); dụng cụ đo chiều dài, dùng tay (ví dụ, thước thanh và thước dây, thước micromet, thước cặp), chưa được chi tiết hoặc ghi ở nơi khác trong Chương này',
    Unit: '',
  },
  {
    HsCode: '901710',
    Description: '- Bảng và máy vẽ phác, tự động hoặc không tự động:',
    Unit: '',
  },
  {
    HsCode: '90171010',
    Description: '- - Máy vẽ (Plotters)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90171090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901720',
    Description: '- Dụng cụ vẽ, vạch mức hoặc dụng cụ tính toán toán học khác:',
    Unit: '',
  },
  {
    HsCode: '90172010',
    Description: '- - Thước',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90172040',
    Description: '- - Máy vẽ ảnh để sản xuất tấm mạch in/tấm dây in',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90172050',
    Description: '- - Máy vẽ khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90172090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90173000',
    Description: '- Thước micromet, thước cặp và dụng cụ đo có thể điều chỉnh được',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90178000',
    Description: '- Các dụng cụ khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901790',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90179030',
    Description: '- - Bộ phận và phụ kiện của máy vẽ ảnh để sản xuất tấm mạch in/tấm dây in ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90179040',
    Description: '- - Bộ phận và phụ kiện, kể cả mạch in đã lắp ráp, của máy vẽ khác ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90179090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9018',
    Description:
      'Thiết bị và dụng cụ dùng cho ngành y, phẫu thuật, nha khoa hoặc thú y, kể cả thiết bị ghi biểu đồ nhấp nháy, thiết bị điện y học khác và thiết bị kiểm tra thị lực',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Thiết bị điện chẩn đoán (kể cả thiết bị kiểm tra thăm dò chức năng hoặc kiểm tra thông số sinh lý):',
    Unit: '',
  },
  {
    HsCode: '90181100',
    Description: '- - Thiết bị điện tim',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90181200',
    Description: '- - Thiết bị siêu âm',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90181300',
    Description: '- - Thiết bị chụp cộng hưởng từ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90181400',
    Description: '- - Thiết bị ghi biểu đồ nhấp nháy',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90181900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90182000',
    Description: '- Thiết bị tia cực tím hoặc tia hồng ngoại',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Bơm tiêm, kim tiêm, ống thông, ống dẫn lưu và loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '901831',
    Description: '- - Bơm tiêm, có hoặc không có kim tiêm:',
    Unit: '',
  },
  {
    HsCode: '90183110',
    Description: '- - - Bơm tiêm dùng một lần',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90183190',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90183200',
    Description: '- - Kim tiêm bằng kim loại và kim khâu vết thương',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901839',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90183910',
    Description: '- - - Ống thông',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90183990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Các thiết bị và dụng cụ khác, dùng trong nha khoa:',
    Unit: '',
  },
  {
    HsCode: '90184100',
    Description: '- - Khoan dùng trong nha khoa, có hoặc không gắn liền cùng một giá đỡ với thiết bị nha khoa khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90184900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90185000',
    Description: '- Thiết bị và dụng cụ nhãn khoa khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901890',
    Description: '- Thiết bị và dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '90189010',
    Description: '- - Đèn sợi quang loại dùng đeo trên đầu, được thiết kế dùng trong y học(SEN)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90189020',
    Description: '- - Bộ theo dõi tĩnh mạch ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - Dụng cụ và thiết bị điện tử: ',
    Unit: '',
  },
  {
    HsCode: '90189031',
    Description: '- - - Dụng cụ và thiết bị phẫu thuật điện tử hoặc y học điện tử',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90189039',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90189090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9019',
    Description:
      'Thiết bị trị liệu cơ học; máy xoa bóp; máy thử nghiệm trạng thái tâm lý; máy trị liệu bằng ôzôn, bằng oxy, bằng xông, máy hô hấp nhân tạo hoặc máy hô hấp trị liệu khác',
    Unit: '',
  },
  {
    HsCode: '901910',
    Description: '- Máy trị liệu cơ học; máy xoa bóp; máy thử nghiệm trạng thái tâm lý:',
    Unit: '',
  },
  {
    HsCode: '90191010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90191090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '901920',
    Description: '- Máy trị liệu bằng ôzôn, bằng oxy, bằng xông, máy hô hấp nhân tạo hoặc máy hô hấp trị liệu khác: ',
    Unit: '',
  },
  {
    HsCode: '90192010',
    Description: '- - Máy trợ thở xâm lấn (SEN)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90192020',
    Description: '- - Máy trợ thở khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90192090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9020',
    Description:
      'Thiết bị thở và mặt nạ phòng khí khác, trừ các mặt nạ bảo hộ mà không có bộ phận cơ khí hoặc không có phin lọc có thể thay thế được',
    Unit: '',
  },
  {
    HsCode: '90200010',
    Description: '- Mặt nạ khuôn mặt (full face) chống hóa học, sinh học, phóng xạ và hạt nhân (CBRN) ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90200090',
    Description: '- Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9021',
    Description:
      'Dụng cụ chỉnh hình, kể cả nạng, băng dùng trong phẫu thuật và băng cố định; nẹp và các dụng cụ cố định vết gẫy khác; các bộ phận nhân tạo của cơ thể người; thiết bị trợ thính và dụng cụ khác được lắp hoặc mang theo, hoặc cấy vào cơ thể, để bù đắp khuyết tật hoặc sự suy giảm của một bộ phận cơ thể',
    Unit: '',
  },
  {
    HsCode: '902110',
    Description: '- Các dụng cụ chỉnh hình hoặc nẹp xương:',
    Unit: '',
  },
  {
    HsCode: '90211010',
    Description: '- - Bằng sắt hoặc thép',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90211090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Răng giả và chi tiết gắn dùng trong nha khoa:',
    Unit: '',
  },
  {
    HsCode: '90212100',
    Description: '- - Răng giả',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902129',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90212910',
    Description: '- - - Bằng sắt hoặc thép',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90212920',
    Description: '- - - Bằng titan',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90212990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: ' - Các bộ phận nhân tạo khác của cơ thể:',
    Unit: '',
  },
  {
    HsCode: '90213100',
    Description: '- - Khớp giả',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90213900',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90214000',
    Description: '- Thiết bị trợ thính, trừ các bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90215000',
    Description: '- Thiết bị điều hòa nhịp tim dùng cho việc kích thích cơ tim, trừ các bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90219000',
    Description: '- Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9022',
    Description:
      'Thiết bị sử dụng tia X hoặc tia phóng xạ alpha, beta, gamma hoặc các bức xạ ion hóa khác, có hoặc không dùng cho mục đích y học, phẫu thuật, nha khoa hoặc thú y, kể cả thiết bị chụp hoặc thiết bị điều trị bằng các tia đó, ống phát tia X và thiết bị tạo tia X khác, thiết bị tạo tia cường độ cao, bảng và bàn điều khiển, màn hình, bàn, ghế và các loại tương tự, dùng để khám hoặc điều trị',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Thiết bị sử dụng tia X, có hoặc không dùng cho mục đích y học, phẫu thuật, nha khoa hoặc thú y, kể cả thiết bị chụp hoặc thiết bị điều trị bằng tia X:',
    Unit: '',
  },
  {
    HsCode: '90221200',
    Description: '- - Thiết bị chụp cắt lớp điều khiển bằng máy tính',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90221300',
    Description: '- - Loại khác, sử dụng trong nha khoa',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90221400',
    Description: '- - Loại khác, sử dụng cho mục đích y học, phẫu thuật hoặc thú y',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902219',
    Description: '- - Cho các mục đích khác:',
    Unit: '',
  },
  {
    HsCode: '90221910',
    Description: '- - - Thiết bị sử dụng tia X để kiểm tra vật lý các điểm hàn nối trên tấm mạch in/tấm dây in ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90221990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description:
      '- Thiết bị sử dụng tia phóng xạ alpha, beta, gamma hoặc các bức xạ ion hóa khác, có hoặc không dùng cho mục đích y học, phẫu thuật, nha khoa hoặc thú y, kể cả thiết bị chụp hoặc thiết bị điều trị bằng các tia đó:',
    Unit: '',
  },
  {
    HsCode: '90222100',
    Description: '- - Dùng cho mục đích y học, phẫu thuật, nha khoa hoặc thú y',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90222900',
    Description: '- - Dùng cho các mục đích khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90223000',
    Description: '- Ống phát tia X',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902290',
    Description: '- Loại khác, kể cả bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90229010',
    Description:
      '- - Bộ phận và phụ kiện của thiết bị sử dụng tia X để kiểm tra vật lý các điểm hàn nối trên mạch in đã lắp ráp ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90229020',
    Description: '- - Bộ phận và phụ kiện của thiết bị khác sử dụng tia X',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90229090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90230000',
    Description:
      'Các dụng cụ, máy và mô hình, được thiết kế cho mục đích trưng bày (ví dụ, dùng trong giáo dục hoặc triển lãm), không sử dụng được cho các mục đích khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9024',
    Description:
      'Máy và thiết bị thử độ cứng, độ bền, độ nén, độ đàn hồi hoặc các tính chất cơ học khác của vật liệu (ví dụ, kim loại, gỗ, hàng dệt, giấy, plastic).',
    Unit: '',
  },
  {
    HsCode: '902410',
    Description: '- Máy và thiết bị thử kim loại:',
    Unit: '',
  },
  {
    HsCode: '90241010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90241020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902480',
    Description: '- Máy và thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '90248010',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90248020',
    Description: '- - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90249000',
    Description: '- Bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9025',
    Description:
      'Tỷ trọng kế và các dụng cụ đo dạng nổi tương tự, nhiệt kế, hỏa kế, khí áp kế, ẩm kế, có hoặc không ghi, và tổ hợp của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Nhiệt kế và hỏa kế, không kết hợp với các dụng cụ khác:',
    Unit: '',
  },
  {
    HsCode: '90251100',
    Description: '- - Chứa chất lỏng, để đọc trực tiếp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '90251911',
    Description: '- - - - Máy đo nhiệt độ dùng cho xe có động cơ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90251919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90251920',
    Description: '- - - Không hoạt động bằng điện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90258000',
    Description: '- Dụng cụ khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90259000',
    Description: '- Bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9026',
    Description:
      'Dụng cụ và máy đo hoặc kiểm tra lưu lượng, mức, áp suất hoặc biến số khác của chất lỏng hoặc chất khí (ví dụ, dụng cụ đo lưu lượng, dụng cụ đo mức, áp kế, nhiệt kế), trừ các dụng cụ và thiết bị thuộc nhóm 90.14, 90.15, 90.28 hoặc 90.32',
    Unit: '',
  },
  {
    HsCode: '902610',
    Description: '- Để đo hoặc kiểm tra lưu lượng hoặc mức của chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '90261050',
    Description: '- - Dụng cụ đo mức sử dụng cho xe có động cơ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90261090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902620',
    Description: '- Để đo hoặc kiểm tra áp suất: ',
    Unit: '',
  },
  {
    HsCode: '90262050',
    Description: '- - Máy đo áp suất sử dụng cho xe có động cơ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90262090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90268000',
    Description: '- Thiết bị hoặc dụng cụ khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90269000',
    Description: '- Bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9027',
    Description:
      'Dụng cụ và thiết bị phân tích lý hoặc hóa học (ví dụ, máy đo phân cực, đo khúc xạ, quang phổ kế, máy phân tích khí hoặc khói); dụng cụ và thiết bị đo hoặc kiểm tra độ nhớt, độ xốp, độ giãn nở, sức căng bề mặt hoặc các loại tương tự; dụng cụ và thiết bị đo hoặc kiểm tra nhiệt lượng, âm lượng hoặc ánh sáng (kể cả máy đo độ phơi sáng); thiết bị vi phẫu',
    Unit: '',
  },
  {
    HsCode: '90271000',
    Description: '- Thiết bị phân tích khí hoặc khói',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90272000',
    Description: '- Máy sắc ký và điện di',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90273000',
    Description:
      '- Quang phổ kế, ảnh phổ và quang phổ ký sử dụng bức xạ quang học (tia cực tím, tia có thể nhìn thấy được, tia hồng ngoại)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90275000',
    Description:
      '- Dụng cụ và thiết bị khác sử dụng bức xạ quang học (tia cực tím, tia có thể nhìn thấy được, tia hồng ngoại)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ và thiết bị khác: ',
    Unit: '',
  },
  {
    HsCode: '90278100',
    Description: '- - Khối phổ kế',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902789',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90278910',
    Description: '- - - Lộ sáng kế',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90278990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90279000',
    Description: '- Thiết bị vi phẫu; các bộ phận và phụ kiện',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9028',
    Description:
      'Thiết bị đo khí, chất lỏng hoặc lượng điện được sản xuất hoặc cung cấp, kể cả thiết bị kiểm định các thiết bị trên',
    Unit: '',
  },
  {
    HsCode: '902810',
    Description: '- Thiết bị đo khí:',
    Unit: '',
  },
  {
    HsCode: '90281010',
    Description: '- - Thiết bị đo khí loại lắp trên bình ga',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90281090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902820',
    Description: '- Thiết bị đo chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '90282020',
    Description: '- - Công tơ nước',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90282090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902830',
    Description: '- Công tơ điện:',
    Unit: '',
  },
  {
    HsCode: '90283010',
    Description: '- - Máy đếm ki-lô-oát giờ ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90283090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902890',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90289010',
    Description: '- - Vỏ hoặc thân của công tơ nước',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90289090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9029',
    Description:
      'Máy đếm vòng quay, máy đếm sản lượng, máy đếm cây số để tính tiền taxi, máy đếm dặm, máy đo bước và máy tương tự; đồng hồ chỉ tốc độ và máy đo tốc độ góc, trừ các loại máy thuộc nhóm 90.14 hoặc 90.15; máy hoạt nghiệm',
    Unit: '',
  },
  {
    HsCode: '902910',
    Description:
      '- Máy đếm vòng quay, máy đếm sản lượng, máy đếm cây số để tính tiền taxi, máy đếm dặm, máy đo bước và máy tương tự:',
    Unit: '',
  },
  {
    HsCode: '90291020',
    Description: '- - Máy đếm cây số để tính tiền taxi',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90291090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902920',
    Description: '- Đồng hồ chỉ tốc độ và máy đo tốc độ góc; máy hoạt nghiệm:',
    Unit: '',
  },
  {
    HsCode: '90292010',
    Description: '- - Đồng hồ tốc độ dùng cho xe có động cơ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90292020',
    Description: '- - Máy đo tốc độ góc cho xe có động cơ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90292090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '902990',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90299010',
    Description: '- - Của hàng hóa thuộc phân nhóm 9029.10; của máy hoạt nghiệm thuộc phân nhóm 9029.20',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90299020',
    Description: '- - Của đồng hồ chỉ tốc độ và máy đo tốc độ góc của phân nhóm 9029.20',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9030',
    Description:
      'Máy hiện sóng, máy phân tích phổ và các dụng cụ và thiết bị khác để đo hoặc kiểm tra đại lượng điện, trừ các loại máy thuộc nhóm 90.28; các thiết bị và dụng cụ đo hoặc phát hiện tia alpha, beta, gamma, tia X, bức xạ vũ trụ hoặc các bức xạ ion hóa khác',
    Unit: '',
  },
  {
    HsCode: '90301000',
    Description: '- Dụng cụ và thiết bị để đo hoặc phát hiện các bức xạ ion hóa',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90302000',
    Description: '- Máy hiện sóng và máy ghi dao động',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description:
      '- Dụng cụ và thiết bị khác, để đo hoặc kiểm tra điện áp, dòng điện, điện trở hoặc công suất (trừ những dụng cụ và thiết bị để đo hoặc kiểm tra các vi mạch hoặc linh kiện bán dẫn):',
    Unit: '',
  },
  {
    HsCode: '90303100',
    Description: '- - Máy đo đa năng không bao gồm thiết bị ghi',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90303200',
    Description: '- - Máy đo đa năng bao gồm thiết bị ghi',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903033',
    Description: '- - Loại khác, không bao gồm thiết bị ghi:',
    Unit: '',
  },
  {
    HsCode: '90303310',
    Description:
      '- - - Dụng cụ và thiết bị để đo hoặc kiểm tra điện áp, dòng điện, điện trở hoặc công suất của tấm mạch in/tấm dây in hoặc mạch in đã lắp ráp ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90303320',
    Description:
      '- - - Dụng cụ và thiết bị đo trở kháng được thiết kế để cảnh báo dưới dạng hình ảnh và/hoặc dưới dạng âm thanh trong môi trường phóng tĩnh điện mà có thể làm hỏng mạch điện tử; dụng cụ kiểm tra thiết bị điều khiển tĩnh điện và thiết bị tĩnh điện nối đất/cố định ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90303330',
    Description: '- - - Ampe kế và vôn kế dùng cho xe có động cơ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90303340',
    Description: '- - - Dụng cụ đo điện trở khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90303390',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90303900',
    Description: '- - Loại khác, có gắn thiết bị ghi',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90304000',
    Description:
      '- Thiết bị và dụng cụ khác, chuyên dụng cho viễn thông (ví dụ máy đo xuyên âm, thiết bị đo độ khuếch đại, máy đo hệ số biến dạng âm thanh, máy đo tạp âm)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ và thiết bị khác: ',
    Unit: '',
  },
  {
    HsCode: '903082',
    Description: '- - Để đo hoặc kiểm tra các vi mạch hoặc linh kiện bán dẫn (kể cả mạch tích hợp): ',
    Unit: '',
  },
  {
    HsCode: '90308210',
    Description: '- - - Máy kiểm tra vi mạch tích hợp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90308290',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903084',
    Description: '- - Loại khác, có kèm thiết bị ghi:',
    Unit: '',
  },
  {
    HsCode: '90308410',
    Description:
      '- - - Dụng cụ và thiết bị để đo hoặc kiểm tra đại lượng điện của tấm mạch in/tấm dây in và mạch in đã lắp ráp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90308490',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903089',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90308910',
    Description:
      '- - - Dụng cụ và thiết bị, không kèm thiết bị ghi, dùng để đo hoặc kiểm tra đại lượng điện của tấm mạch in/tấm dây in và mạch in đã lắp ráp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90308990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903090',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90309010',
    Description:
      '- - Bộ phận và phụ kiện (kể cả mạch in đã lắp ráp) của các hàng hóa thuộc phân nhóm 9030.40 hoặc 9030.82',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90309030',
    Description:
      '- - Bộ phận và phụ kiện của dụng cụ và thiết bị quang học để đo hoặc kiểm tra tấm mạch in/tấm dây in và mạch in đã lắp ráp ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90309040',
    Description:
      '- - Bộ phận và phụ kiện của dụng cụ và thiết bị dùng để đo hoặc kiểm tra đại lượng điện của tấm mạch in/tấm dây in và mạch in đã lắp ráp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90309090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9031',
    Description:
      'Máy, thiết bị và dụng cụ đo lường hoặc kiểm tra, chưa được ghi hoặc chi tiết ở nơi khác trong Chương này; máy chiếu biên dạng',
    Unit: '',
  },
  {
    HsCode: '90311000',
    Description: '- Máy đo để cân chỉnh các bộ phận cơ khí',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90312000',
    Description: '- Bàn kiểm tra',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Các thiết bị và dụng cụ quang học khác:',
    Unit: '',
  },
  {
    HsCode: '90314100',
    Description:
      '- - Để kiểm tra các vi mạch hoặc linh kiện bán dẫn (kể cả mạch tích hợp) hoặc kiểm tra mạng che quang hoặc lưới carô sử dụng trong sản xuất các linh kiện bán dẫn (kể cả mạch tích hợp)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903149',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90314910',
    Description: '- - - Dụng cụ và thiết bị quang học để đo độ nhiễm bẩn bề mặt của các vi mạch bán dẫn',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90314920',
    Description: '- - - Thiết bị quang học để xác định và sửa lỗi cho tấm mạch in/tấm dây in và mạch in đã lắp ráp ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90314930',
    Description:
      '- - - Dụng cụ và thiết bị quang học khác để đo hoặc kiểm tra tấm mạch in/tấm dây in và mạch in đã lắp ráp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90314990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903180',
    Description: '- Dụng cụ, thiết bị và máy khác:',
    Unit: '',
  },
  {
    HsCode: '90318010',
    Description: '- - Thiết bị kiểm tra cáp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90318090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903190',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90319030',
    Description:
      '- - Bộ phận và phụ kiện kể cả mạch in đã lắp ráp dùng cho các dụng cụ và thiết bị quang học để kiểm tra các vi mạch hoặc linh kiện bán dẫn hoặc kiểm tra mặt nạ, mặt nạ quang hoặc lưới ca rô sử dụng trong sản xuất các linh kiện bán dẫn; bộ phận và phụ kiện của các thiết bị đo độ nhiễm bẩn bề mặt của vi mạch bán dẫn',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90319040',
    Description: '- - Của thiết bị quang học xác định và sửa lỗi cho tấm mạch in/tấm dây in và mạch in đã lắp ráp ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90319050',
    Description:
      '- - Của dụng cụ và thiết bị quang học khác để đo hoặc kiểm tra tấm mạch in/tấm dây in và mạch in đã lắp ráp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90319090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '9032',
    Description: 'Dụng cụ và thiết bị điều chỉnh hoặc điều khiển tự động',
    Unit: '',
  },
  {
    HsCode: '90321000',
    Description: '- Bộ ổn nhiệt',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90322000',
    Description: '- Bộ điều chỉnh áp lực',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- Dụng cụ và thiết bị khác: ',
    Unit: '',
  },
  {
    HsCode: '90328100',
    Description: '- - Loại dùng thủy lực hoặc khí nén',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903289',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '90328910',
    Description:
      '- - - Dụng cụ và thiết bị đi kèm hoặc hoạt động cùng với máy xử lý dữ liệu tự động, để điều chỉnh hoặc điều khiển tự động hệ thống đẩy, giữ thăng bằng hoặc xếp hàng hóa của tàu thuyền ',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90328920',
    Description:
      '- - - Dụng cụ và thiết bị tự động để điều chỉnh hoặc điều khiển dung dịch hóa chất hoặc điện hóa trong sản xuất tấm mạch in/tấm dây in hoặc mạch in đã lắp ráp',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '90328931',
    Description: '- - - - Bộ điều chỉnh điện áp tự động (ổn áp)',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90328939',
    Description: '- - - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90328990',
    Description: '- - - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '903290',
    Description: '- Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '90329010',
    Description: '- - Của hàng hóa thuộc phân nhóm 9032.89.10',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90329020',
    Description: '- - Của hàng hóa thuộc phân nhóm 9032.89.20',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90329090',
    Description: '- - Loại khác',
    Unit: 'chiếc/bộ',
  },
  {
    HsCode: '90330000',
    Description:
      'Bộ phận và phụ kiện (chưa được chi tiết hoặc ghi ở các nhóm khác thuộc Chương này) dùng cho máy, thiết bị, dụng cụ hoặc đồ dùng thuộc Chương 90',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 91',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đồng hồ thời gian, đồng hồ cá nhân và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Mặt kính đồng hồ thời gian hoặc đồng hồ cá nhân hoặc quả lắc đồng hồ (được phân loại theo vật liệu cấu thành);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Dây đeo đồng hồ cá nhân (tùy theo từng trường hợp, thuộc nhóm 71.13 hoặc 71.17);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Các bộ phận có công dụng chung đã định nghĩa trong Chú giải 2 Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các hàng hóa tương tự bằng plastic (Chương 39) hoặc bằng kim loại quý hoặc kim loại dát phủ kim loại quý (thường thuộc nhóm 71.15); tuy nhiên, lò xo đồng hồ thời gian hoặc đồng hồ cá nhân, được phân loại như các bộ phận của các loại đồng hồ đó (nhóm 91.14);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Bi của ổ bi (tùy theo từng trường hợp, thuộc nhóm 73.26 hoặc 84.82);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Các mặt hàng thuộc nhóm 84.12 được cấu tạo để làm việc mà không có bộ phận điều tốc;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Ổ bi (nhóm 84.82); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Các mặt hàng thuộc Chương 85, chưa lắp ráp với nhau hoặc với các bộ phận cấu thành khác để làm thành máy của đồng hồ cá nhân hoặc đồng hồ thời gian khác hoặc làm thành các mặt hàng chỉ dùng hoặc chủ yếu dùng như các bộ phận của máy đồng hồ (Chương 85).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 91.01 chỉ bao gồm loại đồng hồ cá nhân có vỏ làm hoàn toàn bằng kim loại quý hoặc kim loại dát phủ kim loại quý, hoặc bằng cùng loại vật liệu kết hợp với ngọc trai thiên nhiên hoặc ngọc trai nuôi cấy, hoặc với đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo) thuộc các nhóm từ 71.01 đến 71.04. Đồng hồ cá nhân với vỏ bằng kim loại khảm kim loại quý xếp vào nhóm 91.02.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Trong Chương này, khái niệm ""máy đồng hồ cá nhân"" (bộ phận chuyển động của đồng hồ) được hiểu là các bộ phận được điều chỉnh bằng con lắc và dây tóc, tinh thể thạch anh hoặc bất kỳ một hệ thống nào khác có khả năng xác định khoảng cách thời gian, có kèm theo mặt hiển thị hoặc hệ thống có mặt hiển thị bằng cơ học. Máy đồng hồ cá nhân như vậy có chiều dày không quá 12 mm và chiều rộng, chiều dài hoặc đường kính không quá 50 mm.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Ngoài các quy định đã nêu trong Chú giải 1, máy đồng hồ và các bộ phận khác sử dụng cho cả hai loại đồng hồ thời gian hoặc đồng hồ cá nhân và dùng cho các mặt hàng khác (ví dụ, thiết bị chính xác) được phân loại trong Chương này.',
    Unit: '',
  },
  {
    HsCode: '9101',
    Description:
      'Đồng hồ đeo tay, đồng hồ bỏ túi và các loại đồng hồ cá nhân khác, kể cả đồng hồ bấm giờ, với vỏ làm bằng kim loại quý hoặc kim loại dát phủ kim loại quý',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đồng hồ đeo tay, hoạt động bằng điện, có hoặc không lắp kèm bộ phận bấm giờ:',
    Unit: '',
  },
  {
    HsCode: '91011100',
    Description: '- - Chỉ có mặt hiển thị bằng cơ học',
    Unit: 'chiếc',
  },
  {
    HsCode: '91011900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đồng hồ đeo tay khác, có hoặc không lắp kèm bộ phận bấm giờ:',
    Unit: '',
  },
  {
    HsCode: '91012100',
    Description: '- - Có bộ phận lên giây tự động',
    Unit: 'chiếc',
  },
  {
    HsCode: '91012900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '91019100',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '91019900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9102',
    Description:
      'Đồng hồ đeo tay, đồng hồ bỏ túi và các loại đồng hồ cá nhân khác, kể cả đồng hồ bấm giờ, trừ các loại thuộc nhóm 91.01',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đồng hồ đeo tay, hoạt động bằng điện, có hoặc không lắp kèm bộ phận bấm giờ:',
    Unit: '',
  },
  {
    HsCode: '91021100',
    Description: '- - Chỉ có mặt hiển thị bằng cơ học',
    Unit: 'chiếc',
  },
  {
    HsCode: '91021200',
    Description: '- - Chỉ có mặt hiển thị bằng quang điện tử',
    Unit: 'chiếc',
  },
  {
    HsCode: '91021900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đồng hồ đeo tay khác, có hoặc không lắp kèm bộ phận bấm giờ:',
    Unit: '',
  },
  {
    HsCode: '91022100',
    Description: '- - Có bộ phận lên giây tự động',
    Unit: 'chiếc',
  },
  {
    HsCode: '91022900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '91029100',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '91029900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9103',
    Description: 'Đồng hồ thời gian có lắp máy đồng hồ cá nhân, trừ các loại đồng hồ thời gian thuộc nhóm 91.04',
    Unit: '',
  },
  {
    HsCode: '91031000',
    Description: '- Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '91039000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9104',
    Description:
      'Đồng hồ thời gian lắp trên bàn điều khiển phương tiện và các loại đồng hồ thời gian tương tự dùng cho xe cộ, phương tiện bay, tàu vũ trụ hoặc tàu thủy',
    Unit: '',
  },
  {
    HsCode: '91040010',
    Description: '- Dùng cho xe cộ',
    Unit: 'chiếc',
  },
  {
    HsCode: '91040090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9105',
    Description: 'Đồng hồ thời gian khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đồng hồ báo thức:',
    Unit: '',
  },
  {
    HsCode: '91051100',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '91051900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đồng hồ treo tường:',
    Unit: '',
  },
  {
    HsCode: '91052100',
    Description: '- - Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '91052900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '910591',
    Description: '- - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '91059110',
    Description: '- - - Đồng hồ hàng hải đo thời gian ',
    Unit: 'chiếc',
  },
  {
    HsCode: '91059190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '910599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '91059910',
    Description: '- - - Đồng hồ hàng hải đo thời gian ',
    Unit: 'chiếc',
  },
  {
    HsCode: '91059990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9106',
    Description:
      'Thiết bị ghi thời gian và các thiết bị dùng để đo, ghi hoặc biểu thị các khoảng thời gian bằng cách khác, kèm theo máy đồng hồ thời gian và đồng hồ cá nhân hoặc kèm theo động cơ đồng bộ (ví dụ, thiết bị chấm công (time-registers), thiết bị ghi thời gian)',
    Unit: '',
  },
  {
    HsCode: '91061000',
    Description: '- Thiết bị chấm công (time-registers), thiết bị ghi thời gian',
    Unit: 'chiếc',
  },
  {
    HsCode: '910690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '91069010',
    Description: '- - Dụng cụ đo thời gian đậu xe',
    Unit: 'chiếc',
  },
  {
    HsCode: '91069090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '91070000',
    Description:
      'Thiết bị đóng ngắt định giờ (time switches) có máy đồng hồ cá nhân hoặc đồng hồ thời gian hoặc có động cơ đồng bộ',
    Unit: 'chiếc',
  },
  {
    HsCode: '9108',
    Description: 'Máy đồng hồ cá nhân, hoàn chỉnh và đã lắp ráp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '91081100',
    Description: '- - Chỉ có mặt hiển thị bằng cơ học hoặc có một thiết bị được kết hợp với mặt hiển thị bằng cơ học',
    Unit: 'chiếc',
  },
  {
    HsCode: '91081200',
    Description: '- - Chỉ có mặt hiển thị bằng quang điện tử',
    Unit: 'chiếc',
  },
  {
    HsCode: '91081900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '91082000',
    Description: '- Có bộ phận lên giây tự động',
    Unit: 'chiếc',
  },
  {
    HsCode: '91089000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9109',
    Description: 'Máy đồng hồ thời gian, hoàn chỉnh và đã lắp ráp',
    Unit: '',
  },
  {
    HsCode: '91091000',
    Description: '- Hoạt động bằng điện',
    Unit: 'chiếc',
  },
  {
    HsCode: '91099000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9110',
    Description:
      'Máy đồng hồ thời gian hoặc đồng hồ cá nhân đủ bộ, chưa lắp ráp hoặc đã lắp ráp từng phần (cụm máy); máy đồng hồ thời gian hoặc máy đồng hồ cá nhân chưa đủ bộ, đã lắp ráp; máy đồng hồ thời gian hoặc máy đồng hồ cá nhân chưa đủ bộ, chưa lắp ráp',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Của đồng hồ cá nhân:',
    Unit: '',
  },
  {
    HsCode: '91101100',
    Description: '- - Máy đồng hồ đủ bộ, chưa lắp ráp hoặc đã lắp ráp từng phần (cụm máy)',
    Unit: 'chiếc',
  },
  {
    HsCode: '91101200',
    Description: '- - Máy đồng hồ chưa đủ bộ, đã lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '91101900',
    Description: '- - Máy đồng hồ chưa đủ bộ, chưa lắp ráp',
    Unit: 'chiếc',
  },
  {
    HsCode: '91109000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9111',
    Description: 'Vỏ đồng hồ cá nhân và các bộ phận của nó',
    Unit: '',
  },
  {
    HsCode: '91111000',
    Description: '- Vỏ đồng hồ bằng kim loại quý hoặc bằng kim loại dát phủ kim loại quý',
    Unit: 'chiếc',
  },
  {
    HsCode: '91112000',
    Description: '- Vỏ đồng hồ bằng kim loại cơ bản, đã hoặc chưa được mạ vàng hoặc mạ bạc',
    Unit: 'chiếc',
  },
  {
    HsCode: '91118000',
    Description: '- Vỏ đồng hồ loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '91119000',
    Description: '- Các bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '9112',
    Description:
      'Vỏ đồng hồ thời gian và vỏ của các loại tương tự dùng cho các mặt hàng khác của Chương này, và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '91122000',
    Description: '- Vỏ',
    Unit: 'chiếc',
  },
  {
    HsCode: '91129000',
    Description: '- Các bộ phận',
    Unit: 'chiếc',
  },
  {
    HsCode: '9113',
    Description: 'Dây đeo, quai đeo và vòng đeo đồng hồ cá nhân, và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '91131000',
    Description: '- Bằng kim loại quý hoặc kim loại dát phủ kim loại quý',
    Unit: 'chiếc',
  },
  {
    HsCode: '91132000',
    Description: '- Bằng kim loại cơ bản, đã hoặc chưa mạ vàng hoặc bạc',
    Unit: 'chiếc',
  },
  {
    HsCode: '91139000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9114',
    Description: 'Các bộ phận khác của đồng hồ thời gian hoặc đồng hồ cá nhân',
    Unit: '',
  },
  {
    HsCode: '91143000',
    Description: '- Mặt số',
    Unit: 'chiếc',
  },
  {
    HsCode: '91144000',
    Description: '- Mâm và trục',
    Unit: 'chiếc',
  },
  {
    HsCode: '91149000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 92',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' Nhạc cụ; các bộ phận và phụ kiện của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Các bộ phận có công dụng chung, như đã định nghĩa trong Chú giải 2 Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các hàng hóa tương tự bằng plastic (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Micro, amply, loa phóng thanh, tai nghe, công tắc, dụng cụ quan sát hoạt động máy (máy hoạt nghiệm) hoặc thiết bị phụ trợ khác, máy hoặc thiết bị của Chương 85 hoặc 90, sử dụng phối hợp nhưng không lắp hoặc ghép vào cùng một vỏ như các thiết bị của Chương này;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Dụng cụ hoặc nhạc cụ đồ chơi (nhóm 95.03);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Chổi để làm sạch nhạc cụ (nhóm 96.03); hoặc chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các mặt hàng tương tự (nhóm 96.20); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Bộ sưu tập hoặc đồ cổ (nhóm 97.05 hoặc 97.06). ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Cần kéo và que và các dụng cụ tương tự để chơi nhạc cụ thuộc nhóm 92.02 hoặc 92.06 đi kèm những loại nhạc cụ đó với số lượng kèm theo thông thường và được xác định rõ là sử dụng cùng với nhạc cụ, được phân loại cùng nhóm với nhạc cụ liên quan. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Thẻ, đĩa và trục quay thuộc nhóm 92.09 đi kèm với nhạc cụ được xem là một mặt hàng riêng biệt và không phải là bộ phận cấu thành nhạc cụ đó.',
    Unit: '',
  },
  {
    HsCode: '9201',
    Description: 'Đàn piano, kể cả piano tự động; đàn clavecin (hapsichords) và các loại đàn dây có phím bấm khác',
    Unit: '',
  },
  {
    HsCode: '92011000',
    Description: '- Đàn piano loại đứng',
    Unit: 'chiếc',
  },
  {
    HsCode: '92012000',
    Description: '- Đại dương cầm (grand piano)',
    Unit: 'chiếc',
  },
  {
    HsCode: '92019000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9202',
    Description: 'Các nhạc cụ có dây khác (ví dụ, ghi ta, vi-ô-lông, đàn hạc)',
    Unit: '',
  },
  {
    HsCode: '92021000',
    Description: '- Loại sử dụng cần kéo ',
    Unit: 'chiếc',
  },
  {
    HsCode: '92029000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9205',
    Description:
      'Nhạc cụ hơi (ví dụ, các loại đàn organ ống có phím, đàn accordion, clarinet, trumpet, kèn túi), trừ các loại đàn organ phiên chợ, đàn quay gió của người hát rong trên đường phố',
    Unit: '',
  },
  {
    HsCode: '92051000',
    Description: '- Các loại kèn đồng',
    Unit: 'chiếc',
  },
  {
    HsCode: '920590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '92059010',
    Description:
      '- - Các loại đàn organ ống có phím; đàn đạp hơi (harmonium) và loại nhạc cụ có phím tương tự có bộ phận lưỡi gà không bằng kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '92059090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '92060000',
    Description: 'Nhạc cụ thuộc bộ gõ (ví dụ, trống, mộc cầm, chũm chọe, castanet, chuông gõ (maracas))',
    Unit: 'chiếc',
  },
  {
    HsCode: '9207',
    Description:
      'Nhạc cụ, mà âm thanh được tạo ra, hoặc phải khuếch đại, bằng điện (ví dụ, đàn organ, ghi ta, accordion)',
    Unit: '',
  },
  {
    HsCode: '92071000',
    Description: '- Nhạc cụ có phím bấm, trừ accordion',
    Unit: 'chiếc',
  },
  {
    HsCode: '92079000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9208',
    Description:
      'Hộp nhạc, đàn organ phiên chợ, đàn quay gió của người hát rong trên đường phố, nhạc cụ cơ khí tạo tiếng chim hót, đàn kéo và các nhạc cụ khác không thuộc bất kỳ nhóm nào khác của Chương này; dụng cụ tạo âm thanh để nhử mồi các loại; còi, tù và và dụng cụ thổi bằng miệng khác để tạo âm thanh',
    Unit: '',
  },
  {
    HsCode: '92081000',
    Description: '- Hộp nhạc',
    Unit: 'chiếc',
  },
  {
    HsCode: '920890',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '92089010',
    Description: '- - Dụng cụ tạo âm thanh để nhử mồi, tù và và các dụng cụ thổi bằng miệng khác để tạo âm thanh',
    Unit: 'chiếc',
  },
  {
    HsCode: '92089090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9209',
    Description:
      'Các bộ phận (ví dụ, bộ phận cơ cho hộp nhạc) và các phụ kiện (ví dụ, thẻ (card), đĩa và trục quay dùng cho nhạc cụ cơ học) của nhạc cụ; máy nhịp, thanh mẫu, tiêu và sáo các loại',
    Unit: '',
  },
  {
    HsCode: '92093000',
    Description: '- Dây nhạc cụ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '920991',
    Description: '- - Bộ phận và phụ kiện của đàn piano:',
    Unit: '',
  },
  {
    HsCode: '92099110',
    Description: '- - - Tấm đỡ đàn dây, đàn phím và khung kim loại của đàn piano loại đứng',
    Unit: 'chiếc',
  },
  {
    HsCode: '92099190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '92099200',
    Description: '- - Bộ phận và phụ kiện cho nhạc cụ thuộc nhóm 92.02',
    Unit: 'chiếc',
  },
  {
    HsCode: '92099400',
    Description: '- - Bộ phận và phụ kiện cho nhạc cụ thuộc nhóm 92.07',
    Unit: 'chiếc',
  },
  {
    HsCode: '92099900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: 'PHẦN XIX',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'VŨ KHÍ VÀ ĐẠN; CÁC BỘ PHẬN VÀ PHỤ KIỆN CỦA CHÚNG',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 93',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Vũ khí và đạn; các bộ phận và phụ kiện của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Hàng hóa thuộc Chương 36 (ví dụ, ngòi nổ, kíp nổ, pháo hiệu);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Các bộ phận có công dụng chung, như đã định nghĩa trong Chú giải 2 Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các hàng hóa tương tự bằng plastic (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Xe chiến đấu bọc thép (nhóm 87.10);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Kính ngắm hoặc thiết bị quang học khác phù hợp để sử dụng với vũ khí, trừ loại đã được lắp với súng cầm tay hoặc loại được thiết kế phù hợp và đi cùng với súng cầm tay (Chương 90);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(e) Cung, tên, kiếm bịt đầu hoặc đồ chơi (Chương 95); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Bộ sưu tập hoặc đồ cổ (nhóm 97.05 hoặc 97.06).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong nhóm 93.06, khái niệm ""bộ phận của chúng"" không bao gồm thiết bị vô tuyến hoặc ra đa thuộc nhóm 85.26.',
    Unit: '',
  },
  {
    HsCode: '9301',
    Description: 'Vũ khí quân sự, trừ súng lục ổ quay, súng lục và các loại vũ khí thuộc nhóm 93.07',
    Unit: '',
  },
  {
    HsCode: '93011000',
    Description: '- Vũ khí pháo binh (ví dụ, súng, súng cối và súng moóc trê)',
    Unit: 'chiếc',
  },
  {
    HsCode: '93012000',
    Description:
      '- Bệ phóng tên lửa; súng phun lửa; súng phóng lựu đạn; ống phóng ngư lôi và các loại súng phóng tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '93019000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '93020000',
    Description: 'Súng lục ổ quay và súng lục, trừ các loại thuộc nhóm 93.03 hoặc 93.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '9303',
    Description:
      'Súng cầm tay (firearm) khác và các loại tương tự hoạt động bằng cách đốt cháy lượng thuốc nổ đã nạp (ví dụ, súng shotgun thể thao và súng trường thể thao, súng cầm tay nạp đạn phía nòng, súng lục bắn pháo hiệu và các loại khác được thiết kế chỉ để phóng pháo hiệu, súng lục và súng lục ổ quay để bắn đạn giả, súng bắn gia súc giết mổ, súng phóng dây)',
    Unit: '',
  },
  {
    HsCode: '93031000',
    Description: '- Súng cầm tay nạp đạn phía nòng',
    Unit: 'chiếc',
  },
  {
    HsCode: '930320',
    Description:
      '- Súng shotgun thể thao, súng shotgun săn hoặc súng shotgun bắn bia khác, kể cả súng trường - shotgun kết hợp (combination shotgun-rifles): ',
    Unit: '',
  },
  {
    HsCode: '93032010',
    Description: '- - Súng shotgun săn (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '93032090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '930330',
    Description: '- Súng trường thể thao, súng trường săn hoặc súng trường bắn bia khác:',
    Unit: '',
  },
  {
    HsCode: '93033010',
    Description: '- - Súng trường săn',
    Unit: 'chiếc',
  },
  {
    HsCode: '93033090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '930390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '93039010',
    Description:
      '- - Súng lục bắn pháo hiệu và các loại khác được thiết kế chỉ để phóng pháo hiệu có cỡ nòng từ 20 mm trở lên nhưng trừ cỡ 23 mm và 26,5 mm',
    Unit: 'chiếc',
  },
  {
    HsCode: '93039090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9304',
    Description:
      'Vũ khí khác (ví dụ, súng và súng lục sử dụng lò xo, hơi hoặc khí ga, dùi cui), trừ các loại thuộc nhóm 93.07',
    Unit: '',
  },
  {
    HsCode: '93040010',
    Description: '- Súng hơi, hoạt động với áp suất dưới 7 kgf/cm2',
    Unit: 'chiếc',
  },
  {
    HsCode: '93040090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9305',
    Description: 'Bộ phận và phụ kiện của các loại vũ khí thuộc các nhóm từ 93.01 đến 93.04',
    Unit: '',
  },
  {
    HsCode: '93051000',
    Description: '- Của súng lục ổ quay hoặc súng lục',
    Unit: 'chiếc',
  },
  {
    HsCode: '93052000',
    Description: '- Của súng shotgun hoặc súng trường thuộc nhóm 93.03',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '930591',
    Description: '- - Của vũ khí quân sự thuộc nhóm 93.01:',
    Unit: '',
  },
  {
    HsCode: '93059110',
    Description: '- - - Bằng da thuộc hoặc vật liệu dệt (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '93059190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '930599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Của hàng hóa thuộc phân nhóm 9304.00.90:',
    Unit: '',
  },
  {
    HsCode: '93059911',
    Description: '- - - - Bằng da thuộc hoặc vật liệu dệt (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '93059919',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '93059991',
    Description: '- - - - Bằng da thuộc hoặc vật liệu dệt (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '93059999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9306',
    Description:
      'Bom, lựu đạn, ngư lôi, mìn, tên lửa và các loại đạn dược tương tự và bộ phận của chúng; đạn cát tút (cartridge) và các loại đạn khác và đầu đạn và các bộ phận của chúng, kể cả nùi đạn gém và nùi đạn cát tút (cartridge)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đạn cát tút (cartridge) cho súng shotgun và các bộ phận của chúng; đạn viên cho súng hơi:',
    Unit: '',
  },
  {
    HsCode: '93062100',
    Description: '- - Đạn cát tút (cartridge)',
    Unit: 'chiếc',
  },
  {
    HsCode: '93062900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '930630',
    Description: '- Đạn cát tút (cartridge) khác và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Dùng cho súng lục ổ quay và súng lục của nhóm 93.02:',
    Unit: '',
  },
  {
    HsCode: '93063011',
    Description: '- - - Đạn cỡ .22',
    Unit: 'chiếc',
  },
  {
    HsCode: '93063019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '93063020',
    Description:
      '- - Đạn dùng cho súng bắn đinh hoặc dụng cụ tương tự hoặc cho súng bắn gia súc giết mổ và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '93063030',
    Description: '- - Dùng cho súng thể thao, súng săn hoặc súng bắn bia, súng trường và súng cacbin, trừ súng shotgun',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '93063091',
    Description: '- - - Đạn cỡ 22',
    Unit: 'chiếc',
  },
  {
    HsCode: '93063099',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '930690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '93069010',
    Description: '- - Bom, lựu đạn, ngư lôi, mìn, tên lửa và các loại đạn dược tương tự và bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '93069090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '93070000',
    Description: 'Kiếm, đoản kiếm, lưỡi lê, giáo và các loại vũ khí tương tự và bộ phận của chúng, vỏ và bao cho chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XX',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'CÁC MẶT HÀNG KHÁC',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 94',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Đồ nội thất; bộ đồ giường, đệm, khung đệm, nệm và các đồ dùng nhồi tương tự; đèn (luminaires) và bộ đèn, chưa được chi tiết hoặc ghi ở nơi khác; biển hiệu được chiếu sáng, biển đề tên được chiếu sáng và các loại tương tự; nhà lắp ghép',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Đệm, gối hoặc nệm, bằng hơi hoặc nước, thuộc Chương 39, 40 hoặc 63;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Gương được thiết kế để đặt trên sàn hoặc nền (ví dụ, gương đứng ngả được (gương quay)) thuộc nhóm 70.09;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Các mặt hàng thuộc Chương 71;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Các bộ phận có công dụng chung như đã định nghĩa trong Chú giải 2 của Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các loại hàng hóa tương tự bằng plastic (Chương 39), hoặc két an toàn thuộc nhóm 83.03;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Đồ nội thất được thiết kế đặc biệt như bộ phận của các thiết bị làm lạnh hoặc kết đông thuộc nhóm 84.18; đồ nội thất được thiết kế đặc biệt dùng cho máy khâu (nhóm 84.52);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(f) Đèn hoặc nguồn sáng và bộ phận của chúng thuộc Chương 85;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Đồ nội thất được thiết kế đặc biệt như bộ phận của các thiết bị thuộc nhóm 85.18 (nhóm 85.18), thuộc nhóm 85.19 hoặc 85.21 (nhóm 85.22) hoặc thuộc các nhóm từ 85.25 đến 85.28 (nhóm 85.29);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Các mặt hàng thuộc nhóm 87.14;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(ij) Ghế nha khoa có gắn kèm các dụng cụ chữa răng thuộc nhóm 90.18 hoặc ống nhổ dùng trong nha khoa (nhóm 90.18);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các mặt hàng thuộc Chương 91 (ví dụ, đồng hồ thời gian và vỏ đồng hồ thời gian);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(l) Đồ nội thất có tính chất đồ chơi hoặc đèn (luminaires) và bộ đèn đồ chơi (nhóm 95.03), bàn bi-a hoặc đồ nội thất khác được cấu tạo đặc biệt dùng cho các trò chơi (nhóm 95.04), đồ nội thất cho trò ảo thuật hoặc để trang trí (trừ các loại dây đèn) như đèn lồng Trung Quốc (nhóm 95.05); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(m) Chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các mặt hàng tương tự (nhóm 96.20).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Các mặt hàng (trừ các bộ phận) thuộc các nhóm từ 94.01 đến 94.03 được phân loại trong các nhóm này chỉ khi chúng được thiết kế để đặt trên nền hoặc sàn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Tuy nhiên, các đồ vật sau đây được phân loại vào các nhóm kể trên dù là chúng được thiết kế để treo, gắn vào tường hoặc để đứng trên 1 đồ vật khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Tủ có ngăn, giá sách, đồ nội thất có ngăn khác (bao gồm kệ đơn với các bộ phận phụ trợ để cố định chúng trên tường) và đồ nội thất một khối;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' (b) Ghế và giường.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. (A) Bộ phận của hàng hóa trong các nhóm từ 94.01 đến 94.03 không bao gồm các dạng tấm hoặc phiến (đã hoặc chưa cắt theo hình mẫu nhưng không kết hợp với các bộ phận khác) bằng thủy tinh (kể cả gương), bằng đá hoa (marble) hoặc đá khác hoặc bằng loại vật liệu bất kỳ khác thuộc Chương 68 hoặc 69. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(B) Hàng hóa mô tả trong nhóm 94.04, dưới hình thức tách biệt, thì không phân loại như bộ phận của các hàng hóa trong nhóm 94.01, 94.02 hoặc 94.03.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo mục đích của nhóm 94.06, khái niệm ""nhà lắp ghép"" có nghĩa là nhà đã hoàn chỉnh trong nhà máy hoặc đóng gói như các bộ phận nhà đi kèm với nhau, để lắp ghép tại chỗ, như nhà ở hoặc nhà làm việc, văn phòng, trường học, cửa hàng, lán trại, gara hoặc các loại nhà tương tự. ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Nhà lắp ghép bao gồm ""các khối mô-đun xây dựng"" bằng thép, thường được trình bày với kích thước và hình dạng của một công-ten-nơ vận chuyển tiêu chuẩn, nhưng được lắp sẵn một phần hoặc toàn bộ bên trong. Các khối mô-đun xây dựng như vậy thường được thiết kế để lắp ráp lại với nhau tạo thành các nhà vĩnh cửu.',
    Unit: '',
  },
  {
    HsCode: '9401',
    Description:
      'Ghế ngồi (trừ các loại thuộc nhóm 94.02), có hoặc không chuyển được thành giường, và bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '94011000',
    Description: '- Ghế dùng cho phương tiện bay',
    Unit: 'chiếc',
  },
  {
    HsCode: '940120',
    Description: '- Ghế dùng cho xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '94012010',
    Description: '- - Của xe thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: 'chiếc',
  },
  {
    HsCode: '94012090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Ghế quay có điều chỉnh độ cao:',
    Unit: '',
  },
  {
    HsCode: '94013100',
    Description: '- - Bằng gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94013900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Ghế có thể chuyển thành giường, trừ ghế trong vườn hoặc đồ cắm trại:',
    Unit: '',
  },
  {
    HsCode: '94014100',
    Description: '- - Bằng gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94014900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Ghế bằng mây, liễu gai, tre hoặc các vật liệu tương tự:',
    Unit: '',
  },
  {
    HsCode: '94015200',
    Description: '- - Bằng tre',
    Unit: 'chiếc',
  },
  {
    HsCode: '94015300',
    Description: '- - Bằng song, mây',
    Unit: 'chiếc',
  },
  {
    HsCode: '94015900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Ghế khác, có khung bằng gỗ:',
    Unit: '',
  },
  {
    HsCode: '94016100',
    Description: '- - Đã nhồi đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '940169',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94016910',
    Description: '- - - Có tựa lưng và/hoặc phần để ngồi làm bằng song, mây (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94016990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Ghế khác, có khung bằng kim loại:',
    Unit: '',
  },
  {
    HsCode: '94017100',
    Description: '- - Đã nhồi đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '940179',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94017910',
    Description: '- - - Có tựa lưng và/hoặc phần để ngồi làm bằng song, mây (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94017990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94018000',
    Description: '- Ghế khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '94019100',
    Description: '- - Bằng gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '940199',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94019910',
    Description: '- - - Của ghế thuộc phân nhóm 9401.10.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Của ghế thuộc phân nhóm 9401.20:',
    Unit: '',
  },
  {
    HsCode: '94019921',
    Description: '- - - - Miếng tựa đầu và tay vịn của ghế thuộc phân nhóm 9401.20.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '94019929',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94019930',
    Description: '- - - Của ghế thuộc phân nhóm 9401.31.00 và 9401.39.00',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94019991',
    Description: '- - - - Bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '94019999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9402',
    Description:
      'Đồ nội thất trong ngành y, giải phẫu, nha khoa hoặc thú y (ví dụ, bàn mổ, bàn khám, giường bệnh có lắp các bộ phận cơ khí, ghế nha khoa); ghế cắt tóc và các loại ghế tương tự, có thể xoay, ngả và nâng hạ; bộ phận của các mặt hàng trên',
    Unit: '',
  },
  {
    HsCode: '940210',
    Description: '- Ghế nha khoa, ghế cắt tóc hoặc các loại ghế tương tự và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '94021010',
    Description: '- - Ghế nha khoa và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '94021030',
    Description: '- - Ghế cắt tóc hoặc các loại ghế tương tự và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '940290',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Đồ nội thất được thiết kế đặc biệt để dùng trong ngành y, giải phẫu hoặc thú y và các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '94029011',
    Description: '- - - Ghế vệ sinh dành cho người bệnh (Commodes) (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94029012',
    Description: '- - - Bàn mổ, hoạt động bằng điện (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94029013',
    Description: '- - - Bàn mổ, không hoạt động bằng điện (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94029014',
    Description: '- - - Bàn khám (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94029015',
    Description: '- - - Giường bệnh (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94029019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94029090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9403',
    Description: 'Đồ nội thất khác và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '94031000',
    Description: '- Đồ nội thất bằng kim loại được sử dụng trong văn phòng',
    Unit: 'chiếc',
  },
  {
    HsCode: '940320',
    Description: '- Đồ nội thất bằng kim loại khác:',
    Unit: '',
  },
  {
    HsCode: '94032010',
    Description: '- - Tủ hút hơi độc (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94032090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94033000',
    Description: '- Đồ nội thất bằng gỗ loại sử dụng trong văn phòng',
    Unit: 'chiếc',
  },
  {
    HsCode: '94034000',
    Description: '- Đồ nội thất bằng gỗ loại sử dụng trong nhà bếp',
    Unit: 'chiếc',
  },
  {
    HsCode: '94035000',
    Description: '- Đồ nội thất bằng gỗ loại sử dụng trong phòng ngủ',
    Unit: 'chiếc',
  },
  {
    HsCode: '940360',
    Description: '- Đồ nội thất bằng gỗ khác:',
    Unit: '',
  },
  {
    HsCode: '94036010',
    Description: '- - Tủ hút hơi độc (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94036090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940370',
    Description: '- Đồ nội thất bằng plastic:',
    Unit: '',
  },
  {
    HsCode: '94037010',
    Description: '- - Xe tập đi cho trẻ em',
    Unit: 'chiếc',
  },
  {
    HsCode: '94037020',
    Description: '- - Tủ hút hơi độc (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94037090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đồ nội thất bằng vật liệu khác, kể cả mây, liễu gai, tre hoặc các vật liệu tương tự:',
    Unit: '',
  },
  {
    HsCode: '94038200',
    Description: '- - Bằng tre',
    Unit: 'chiếc',
  },
  {
    HsCode: '94038300',
    Description: '- - Bằng song, mây',
    Unit: 'chiếc',
  },
  {
    HsCode: '940389',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94038910',
    Description: '- - - Tủ hút hơi độc (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94038990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '94039100',
    Description: '- - Bằng gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '940399',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94039910',
    Description: '- - - Của phân nhóm 9403.70.10',
    Unit: 'chiếc',
  },
  {
    HsCode: '94039990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9404',
    Description:
      'Khung đệm; các mặt hàng thuộc bộ đồ giường và các loại tương tự (ví dụ, đệm, chăn quilt, chăn nhồi lông, nệm, đệm ghế loại dài và gối) có gắn lò xo hoặc nhồi hoặc lắp bên trong bằng vật liệu bất kỳ hoặc bằng cao su xốp hoặc plastic xốp, có hoặc không bọc',
    Unit: '',
  },
  {
    HsCode: '94041000',
    Description: '- Khung đệm',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đệm:',
    Unit: '',
  },
  {
    HsCode: '940421',
    Description: '- - Bằng cao su xốp hoặc bằng plastic xốp, đã hoặc chưa bọc:',
    Unit: '',
  },
  {
    HsCode: '94042110',
    Description: '- - - Bằng cao su xốp, đã hoặc chưa bọc',
    Unit: 'chiếc',
  },
  {
    HsCode: '94042120',
    Description: '- - - Bằng plastic xốp, đã hoặc chưa bọc',
    Unit: 'chiếc',
  },
  {
    HsCode: '940429',
    Description: '- - Bằng các loại vật liệu khác:',
    Unit: '',
  },
  {
    HsCode: '94042910',
    Description: '- - - Đệm lò xo',
    Unit: 'chiếc',
  },
  {
    HsCode: '94042920',
    Description: '- - - Loại khác, làm nóng/làm mát (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94042990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94043000',
    Description: '- Túi ngủ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94044000',
    Description: '- Chăn quilt, chăn phủ giường (bedspreads), chăn nhồi lông và chăn nhồi bông (comforters)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94049000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9405',
    Description:
      'Đèn (luminaires) và bộ đèn kể cả đèn pha và đèn rọi và bộ phận của chúng, chưa được chi tiết hoặc ghi ở nơi khác; biển hiệu được chiếu sáng, biển đề tên được chiếu sáng và các loại tương tự, có nguồn sáng cố định thường xuyên, và bộ phận của chúng chưa được chi tiết hoặc ghi ở nơi khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- Bộ đèn chùm và đèn điện trần hoặc đèn điện tường khác, trừ các loại được sử dụng ở các không gian mở công cộng hoặc đường phố lớn:',
    Unit: '',
  },
  {
    HsCode: '940511',
    Description: '- - Được thiết kế chỉ để sử dụng với các nguồn sáng đi-ốt phát quang (LED): ',
    Unit: '',
  },
  {
    HsCode: '94051110',
    Description: '- - - Đèn cho phòng mổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94051191',
    Description: '- - - - Đèn rọi',
    Unit: 'chiếc',
  },
  {
    HsCode: '94051199',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940519',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94051910',
    Description: '- - - Đèn cho phòng mổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94051991',
    Description: '- - - - Đèn rọi',
    Unit: 'chiếc',
  },
  {
    HsCode: '94051992',
    Description: '- - - - Đèn gắn bóng đèn huỳnh quang',
    Unit: 'chiếc',
  },
  {
    HsCode: '94051999',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đèn bàn, đèn giường hoặc đèn cây dùng điện :',
    Unit: '',
  },
  {
    HsCode: '940521',
    Description: ' - - Được thiết kế chỉ để sử dụng với các nguồn sáng đi-ốt phát quang (LED): ',
    Unit: '',
  },
  {
    HsCode: '94052110',
    Description: '- - - Đèn cho phòng mổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94052190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940529',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94052910',
    Description: '- - - Đèn cho phòng mổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94052990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Dây đèn dùng cho cây Nô-en: ',
    Unit: '',
  },
  {
    HsCode: '94053100',
    Description: '- - Được thiết kế chỉ để sử dụng với các nguồn sáng đi-ốt phát quang (LED)',
    Unit: 'chiếc',
  },
  {
    HsCode: '94053900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Đèn và bộ đèn điện khác:',
    Unit: '',
  },
  {
    HsCode: '940541',
    Description: '- - Loại quang điện, được thiết kế chỉ để sử dụng với các nguồn sáng đi-ốt phát quang (LED): ',
    Unit: '',
  },
  {
    HsCode: '94054110',
    Description: '- - - Đèn pha',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054120',
    Description: '- - - Đèn rọi khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054130',
    Description:
      '- - - Đèn tín hiệu không nhấp nháy dùng cho sân bay; đèn dùng cho phương tiện giao thông đường sắt, đầu máy, tàu thủy, phương tiện bay, hoặc hải đăng, bằng kim loại cơ bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054140',
    Description: '- - - Loại khác, được sử dụng ở nơi công cộng hoặc đường phố lớn; loại chiếu sáng bên ngoài khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940542',
    Description: ' - - Loại khác, được thiết kế chỉ để sử dụng với các nguồn sáng đi-ốt phát quang (LED): ',
    Unit: '',
  },
  {
    HsCode: '94054210',
    Description: '- - - Đèn pha',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054220',
    Description: '- - - Đèn rọi khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054230',
    Description:
      '- - - Đèn tín hiệu không nhấp nháy dùng cho sân bay; đèn dùng cho phương tiện giao thông đường sắt, đầu máy, tàu thủy, phương tiện bay, hoặc hải đăng, bằng kim loại cơ bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054240',
    Description: '- - - Đèn báo hiệu dùng cho thiết bị nhiệt điện gia dụng thuộc nhóm 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054250',
    Description: '- - - Loại khác, được sử dụng ở nơi công cộng hoặc đường phố lớn',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054260',
    Description: '- - - Loại chiếu sáng bên ngoài khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940549',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94054910',
    Description: '- - - Đèn pha',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054920',
    Description: '- - - Đèn rọi khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054930',
    Description:
      '- - - Đèn tín hiệu không nhấp nháy dùng cho sân bay; đèn dùng cho phương tiện giao thông đường sắt, đầu máy, tàu thủy, phương tiện bay, hoặc hải đăng, bằng kim loại cơ bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054940',
    Description: '- - - Đèn báo hiệu dùng cho thiết bị nhiệt điện gia dụng thuộc nhóm 85.16',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054950',
    Description: '- - - Loại khác, được sử dụng ở nơi công cộng hoặc đường phố lớn',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054960',
    Description: '- - - Loại chiếu sáng bên ngoài khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94054990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940550',
    Description: '- Đèn và bộ đèn không hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại đốt bằng dầu:',
    Unit: '',
  },
  {
    HsCode: '94055011',
    Description: '- - - Bằng đồng sử dụng trong nghi lễ tôn giáo',
    Unit: 'chiếc',
  },
  {
    HsCode: '94055019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94055040',
    Description: '- - Đèn bão',
    Unit: 'chiếc',
  },
  {
    HsCode: '94055050',
    Description: '- - Đèn thợ mỏ và đèn thợ khai thác đá',
    Unit: 'chiếc',
  },
  {
    HsCode: '94055090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Biển hiệu được chiếu sáng, biển đề tên được chiếu sáng và các loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '940561',
    Description: '- - Được thiết kế chỉ để sử dụng với các nguồn sáng đi-ốt phát quang (LED): ',
    Unit: '',
  },
  {
    HsCode: '94056110',
    Description: '- - - Biển cảnh báo, biển tên đường phố, biển báo giao thông và đường bộ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94056190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940569',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94056910',
    Description: '- - - Biển cảnh báo, biển tên đường phố, biển báo giao thông và đường bộ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94056990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '940591',
    Description: '- - Bằng thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '94059110',
    Description: '- - - Dùng cho đèn phòng mổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059120',
    Description: '- - - Dùng cho đèn rọi',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059140',
    Description: '- - - Chao đèn hình cầu hoặc thông phong đèn',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059150',
    Description: '- - - Dùng cho đèn pha',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940592',
    Description: '- - Bằng plastic:',
    Unit: '',
  },
  {
    HsCode: '94059210',
    Description: '- - - Dùng cho đèn phòng mổ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059220',
    Description: '- - - Dùng cho đèn rọi',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059230',
    Description: '- - - Dùng cho đèn pha',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059290',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940599',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '94059910',
    Description: '- - - Chụp đèn bằng vật liệu dệt',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059920',
    Description: '- - - Chụp đèn bằng vật liệu khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059930',
    Description: '- - - Của đèn thuộc phân nhóm 9405.50.11 hoặc 9405.50.19',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059940',
    Description: '- - - Dùng cho đèn pha hoặc đèn rọi ',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059950',
    Description: '- - - Loại khác, bằng gốm, sứ hoặc kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '94059990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9406',
    Description: 'Nhà lắp ghép',
    Unit: '',
  },
  {
    HsCode: '940610',
    Description: '- Bằng gỗ:',
    Unit: '',
  },
  {
    HsCode: '94061010',
    Description: '- - Nhà trồng cây được gắn với thiết bị cơ khí hoặc thiết bị nhiệt',
    Unit: 'chiếc',
  },
  {
    HsCode: '94061090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940620',
    Description: '- Các khối mô-đun xây dựng, bằng thép:',
    Unit: '',
  },
  {
    HsCode: '94062010',
    Description: '- - Nhà trồng cây được gắn với thiết bị cơ khí hoặc thiết bị nhiệt',
    Unit: 'chiếc',
  },
  {
    HsCode: '94062090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '940690',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Nhà trồng cây được gắn với thiết bị cơ khí hoặc thiết bị nhiệt:',
    Unit: '',
  },
  {
    HsCode: '94069011',
    Description: '- - - Bằng sắt hoặc bằng thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '94069012',
    Description: '- - - Bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '94069019',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '94069020',
    Description: '- - Loại khác, bằng plastic hoặc bằng nhôm',
    Unit: 'chiếc',
  },
  {
    HsCode: '94069030',
    Description: '- - Loại khác, bằng sắt hoặc thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '94069040',
    Description: '- - Loại khác, bằng xi măng, bằng bê tông hoặc đá nhân tạo',
    Unit: 'chiếc',
  },
  {
    HsCode: '94069090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 95',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Đồ chơi, thiết bị trò chơi và dụng cụ, thiết bị thể thao; các bộ phận và phụ kiện của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Nến (nhóm 34.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Pháo hoa hoặc các sản phẩm pháo khác thuộc nhóm 36.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Sợi, monofilament, dây bện hoặc dây cước hoặc các loại dây tương tự dùng cho đánh bắt thủy sản, đã cắt thành từng đoạn nhưng chưa làm thành dây câu, thuộc Chương 39, nhóm 42.06 hoặc Phần XI;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(d) Túi thể thao hoặc bao bì khác thuộc nhóm 42.02, 43.03 hoặc 43.04;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Quần áo hhóa trang, bằng vải dệt, thuộc Chương 61 hoặc 62; quần áo thể thao và các mặt hàng may mặc đặc biệt bằng vải dệt, thuộc Chương 61 hoặc 62, có hoặc không gắn với các thành phần bảo vệ như miếng lót hoặc phần lót ở khuỷu tay, đầu gối hoặc phần háng (ví dụ, quần áo dùng cho môn đấu kiếm hoặc áo cho thủ môn bóng đá); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Cờ hoặc cờ đuôi nheo bằng vải dệt, hoặc buồm dùng cho thuyền, ván lướt hoặc ván trượt, của Chương 63;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(g) Giày dép thể thao (trừ loại đã gắn lưỡi trượt băng hoặc trượt patanh) thuộc Chương 64, hoặc mũ và các vật đội đầu thể thao thuộc Chương 65;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(h) Gậy chống để đi bộ, roi, roi điều khiển súc vật hoặc các mặt hàng tương tự (nhóm 66.02), hoặc các bộ phận của chúng (nhóm 66.03);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Mắt thủy tinh chưa lắp dùng cho búp bê hoặc đồ chơi khác, thuộc nhóm 70.18;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(k) Các bộ phận có công dụng chung, như đã định nghĩa trong Chú giải 2 Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các hàng hóa tương tự bằng plastic (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Chuông, cồng chiêng hoặc các loại tương tự thuộc nhóm 83.06;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(m) Bơm chất lỏng (nhóm 84.13), thiết bị lọc hoặc tinh chế chất lỏng hoặc chất khí (nhóm 84.21), động cơ điện (nhóm 85.01), máy biến điện (nhóm 85.04), đĩa, băng, các thiết bị lưu trữ bền vững, thể rắn (các thiết bị bán dẫn không mất dữ liệu khi không có nguồn điện cung cấp), “thẻ thông minh” và các phương tiện lưu trữ thông tin khác để ghi âm hoặc ghi các hiện tượng khác, đã hoặc chưa ghi (nhóm 85.23), thiết bị điều khiển từ xa bằng sóng vô tuyến (nhóm 85.26) hoặc thiết bị điều khiển từ xa không dây bằng tia hồng ngoại (nhóm 85.43);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(n) Xe thể thao (trừ xe trượt băng, xe trượt tuyết và các loại xe tương tự) thuộc Phần XVII;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(o) Xe đạp trẻ em (nhóm 87.12);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(p) Phương tiện bay không người lái (nhóm 88.06);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(q) Tàu thuyền thể thao như ca nô và xuồng nhỏ (Chương 89), hoặc phương tiện đẩy của chúng (Chương 44 dùng cho các mặt hàng bằng gỗ);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(r) Kính đeo, kính bảo hộ hoặc các loại kính tương tự, dùng cho thể thao hoặc cho các trò chơi ngoài trời (nhóm 90.04);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(s) Dụng cụ tạo âm thanh để nhử mồi hoặc còi (nhóm 92.08);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(t) Vũ khí hoặc các mặt hàng khác thuộc Chương 93;',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(u) Dây đèn các loại (nhóm 94.05);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(v) Chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các mặt hàng tượng tự (nhóm 96.20);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(w) Dây căng vợt, lều bạt hoặc các đồ phục vụ cắm trại khác, hoặc găng tay, găng tay hở ngón và găng bao tay (phân loại theo vật liệu cấu thành); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(x) Bộ đồ ăn, bộ đồ bếp, vật dụng toilet, thảm và tấm trải sàn bằng vải dệt khác, đồ thêu, vỏ ga, vỏ gối, khăn trải giường (bed linen), khăn trải bàn, khăn vệ sinh, khăn nhà bếp và các vật dụng tương tự có chức năng sử dụng thực tế (được phân loại theo vật liệu cấu thành).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Chương này bao gồm cả các sản phẩm trong đó có ngọc trai thiên nhiên hoặc nuôi cấy, đá quý hoặc đá bán quý (thiên nhiên, tổng hợp hoặc tái tạo), kim loại quý hoặc kim loại dát phủ kim loại quý chỉ là thành phần thứ yếu.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo Chú giải 1 ở trên, các bộ phận và phụ kiện chỉ dùng hoặc chủ yếu dùng với các mặt hàng của Chương này được phân loại cùng với các mặt hàng đó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Theo nội dung Chú giải 1 nêu trên, không kể những đề cập khác, nhóm 95.03 được áp dụng cho các sản phẩm của nhóm này đã được kết hợp với một hoặc nhiều chi tiết, không được xem như là một bộ theo điều kiện của quy tắc 3 (b) của Nguyên tắc phân loại chung, và nếu trình bày riêng lẻ, thì sẽ được phân loại vào nhóm khác, với điều kiện là các sản phẩm được đóng thành bộ để bán lẻ và sự kết hợp tạo nên đặc trưng cơ bản của đồ chơi.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. Nhóm 95.03 không bao gồm các mặt hàng mà, theo thiết kế, hình dáng hoặc vật liệu cấu thành, có thể xác định chúng chỉ được sử dụng riêng cho động vật, ví dụ, ""đồ chơi cho vật nuôi cảnh trong gia đình"" (phân loại vào nhóm thích hợp của chúng).',
    Unit: '',
  },
  {
    HsCode: '',
    Description: ' 6. Theo nội dung của nhóm 95.08:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Khái niệm ""trò chơi di chuyển trong công viên giải trí"" (amusement park rides) có nghĩa là một thiết bị hoặc tổ hợp các thiết bị hoặc máy để mang, vận chuyển hoặc dẫn một người hoặc nhiều người đi trên hoặc đi qua một tuyến đường cố định hoặc giới hạn, bao gồm cả đường nước, hoặc trong một khu vực xác định cho mục đích chính là vui chơi hoặc giải trí. Các thiết bị trò chơi này có thể được kết hợp trong công viên giải trí, công viên theo chủ đề (theme park), công viên nước hoặc khu hội chợ. Các thiết bị trò chơi di chuyển này không bao gồm các thiết bị loại thường được lắp đặt trong các khu dân cư hoặc sân chơi;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Khái niệm “trò chơi công viên nước” (water park amusements) có nghĩa là một thiết bị hoặc tổ hợp các thiết bị hoặc máy có đặc trưng bởi một khu vực nước cụ thể, không bao gồm đường đi (no purposes built path). Thiết bị vui chơi giải trí trong công viên nước chỉ bao gồm các thiết bị được thiết kế đặc biệt cho công viên nước; và',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Khái niệm “trò chơi hội chợ” (fairground amusements) có nghĩa là các trò chơi may mắn, sức mạnh hoặc kỹ năng, thường có người điều hành hoặc người phục vụ và có thể được lắp đặt trong các tòa nhà kiên cố hoặc quầy nhượng quyền độc lập (independent concession stalls). Các trò chơi hội chợ không bao gồm thiết bị thuộc nhóm 95.04.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Nhóm này không bao gồm thiết bị đã được quy định chi tiết hơn ở nơi khác trong Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải phân nhóm',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Phân nhóm 9504.50 bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Thiết bị trò chơi điện tử video mà hình ảnh được tái tạo trên một bộ thu truyền hình, một màn hình hoặc bề mặt bên ngoài khác; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các máy trò chơi video loại có màn hình video, loại xách tay hoặc không.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      'Phân nhóm này không bao gồm các máy hoặc thiết bị trò chơi điện tử video hoạt động bằng đồng xu, tiền giấy, thẻ ngân hàng, token hoặc bất kỳ phương tiện thanh toán khác (phân nhóm 9504.30).',
    Unit: '',
  },
  {
    HsCode: '9503',
    Description:
      'Xe đạp ba bánh, xe đẩy, xe có bàn đạp và đồ chơi tương tự có bánh; xe của búp bê; búp bê; đồ chơi khác; mẫu thu nhỏ theo tỷ lệ (""scale"") và các mẫu đồ chơi giải trí tương tự, có hoặc không vận hành; các loại đồ chơi đố trí (puzzles)',
    Unit: '',
  },
  {
    HsCode: '95030010',
    Description: '- Xe đạp ba bánh, xe đẩy, xe có bàn đạp và đồ chơi tương tự có bánh; xe của búp bê',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Búp bê:',
    Unit: '',
  },
  {
    HsCode: '95030021',
    Description: '- - Búp bê, có hoặc không có trang phục',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận và phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '95030022',
    Description: '- - - Quần áo và phụ kiện quần áo; giầy và mũ',
    Unit: 'chiếc',
  },
  {
    HsCode: '95030029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '95030030',
    Description: '- Xe điện, kể cả đường ray, đèn hiệu và các phụ kiện khác của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '95030040',
    Description:
      '- Các mô hình thu nhỏ theo tỷ lệ (""scale"") và các mô hình giải trí tương tự, có hoặc không vận hành',
    Unit: 'chiếc',
  },
  {
    HsCode: '95030050',
    Description: '- Đồ chơi và bộ đồ chơi xây dựng khác, bằng mọi loại vật liệu trừ plastic',
    Unit: 'chiếc/bộ/hộp',
  },
  {
    HsCode: '95030060',
    Description: '- Đồ chơi hình con vật hoặc sinh vật không phải hình người',
    Unit: 'chiếc/bộ/hộp',
  },
  {
    HsCode: '95030070',
    Description: '- Các loại đồ chơi đố trí (puzzles)',
    Unit: 'chiếc',
  },
  {
    HsCode: '95030080',
    Description: '- Thiết bị giáo dục điện tử tương tác cầm tay được thiết kế chủ yếu cho trẻ em (SEN)',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '95030091',
    Description:
      '- - Đồ chơi, xếp khối hoặc cắt rời hình chữ số, chữ cái hoặc hình con vật; bộ xếp chữ; bộ đồ chơi tạo chữ và tập nói; bộ đồ chơi in hình; bộ đồ chơi đếm (abaci); máy may đồ chơi; máy chữ đồ chơi',
    Unit: 'chiếc/bộ/hộp',
  },
  {
    HsCode: '95030092',
    Description: '- - Dây nhảy',
    Unit: 'chiếc',
  },
  {
    HsCode: '95030093',
    Description: '- - Hòn bi',
    Unit: 'kg/viên',
  },
  {
    HsCode: '95030094',
    Description: '- - Các đồ chơi khác, bằng cao su',
    Unit: 'chiếc',
  },
  {
    HsCode: '95030099',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9504',
    Description:
      'Các máy và thiết bị trò chơi điện tử video, các trò chơi trên bàn hoặc trong phòng khách, kể cả trò chơi bắn bi (pin-table), bi-a, bàn chuyên dụng dùng cho trò chơi ở sòng bạc và thiết bị chơi bowling tự động, máy giải trí hoạt động bằng đồng xu, tiền giấy, thẻ ngân hàng, token hoặc bất kỳ phương tiện thanh toán khác',
    Unit: '',
  },
  {
    HsCode: '950420',
    Description: '- Các mặt hàng và phụ kiện dùng cho trò chơi bi-a:',
    Unit: '',
  },
  {
    HsCode: '95042020',
    Description: '- - Bàn bi-a các loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '95042030',
    Description: '- - Phấn xoa đầu gậy bi-a',
    Unit: 'kg',
  },
  {
    HsCode: '95042090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '950430',
    Description:
      '- Máy trò chơi khác, hoạt động bằng đồng xu, tiền giấy, thẻ ngân hàng, token hoặc các loại tương tự, trừ các thiết bị dùng cho đường chạy bowling tự động:',
    Unit: '',
  },
  {
    HsCode: '95043030',
    Description:
      '- - Trò chơi may rủi ngẫu nhiên với giải thưởng nhận ngay bằng tiền mặt; các bộ phận và phụ kiện của chúng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '95043040',
    Description: '- - Máy trò chơi bắn bi (pintables) hoặc các máy trò chơi tự động (có khe nhét xèng hoặc xu) khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '95043050',
    Description: '- - Loại khác, bộ phận bằng gỗ, giấy hoặc plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '95043090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '95044000',
    Description: '- Bộ bài',
    Unit: 'bộ',
  },
  {
    HsCode: '950450',
    Description: '- Các máy và thiết bị trò chơi điện tử video, trừ loại thuộc phân nhóm 9504.30:',
    Unit: '',
  },
  {
    HsCode: '95045010',
    Description: '- - Loại sử dụng với một bộ thu truyền hình',
    Unit: 'chiếc',
  },
  {
    HsCode: '95045090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '950490',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '95049010',
    Description: '- - Các loại đồ phụ trợ để chơi bowling',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Đồ chơi ném phi tiêu và các bộ phận và phụ kiện của chúng:',
    Unit: '',
  },
  {
    HsCode: '95049021',
    Description: '- - - Bằng gỗ, giấy hoặc plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '95049029',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị chơi bạc và đồ phụ trợ đi kèm:',
    Unit: '',
  },
  {
    HsCode: '95049032',
    Description: '- - - Bàn thiết kế để chơi bạc bằng gỗ hoặc plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '95049033',
    Description: '- - - Loại bàn khác thiết kế để chơi bạc',
    Unit: 'chiếc',
  },
  {
    HsCode: '95049034',
    Description: '- - - Quân bài Mạt chược bằng gỗ, giấy hoặc plastic',
    Unit: 'bộ',
  },
  {
    HsCode: '95049035',
    Description: '- - - Quân bài Mạt chược khác',
    Unit: 'bộ',
  },
  {
    HsCode: '95049036',
    Description: '- - - Loại khác, bằng gỗ, giấy hoặc plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '95049039',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Bàn thiết kế để chơi trò chơi:',
    Unit: '',
  },
  {
    HsCode: '95049092',
    Description: '- - - - Bằng gỗ hoặc bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '95049093',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '95049095',
    Description: '- - - - Bằng gỗ, giấy hoặc plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '95049099',
    Description: '- - - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9505',
    Description:
      'Đồ dùng trong lễ hội, hội hóa trang hoặc trong các trò chơi giải trí khác, kể cả các mặt hàng dùng cho trò chơi ảo thuật và trò vui cười',
    Unit: '',
  },
  {
    HsCode: '95051000',
    Description: '- Đồ dùng trong lễ Nô-en',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '95059000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9506',
    Description:
      'Dụng cụ và thiết bị dùng cho tập luyện thể chất nói chung, thể dục, điền kinh, các môn thể thao khác (kể cả bóng bàn) hoặc trò chơi ngoài trời, chưa được chi tiết hoặc ghi ở nơi khác trong Chương này; bể bơi và bể bơi nông (paddling pools)',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Ván trượt tuyết và các thiết bị trượt tuyết khác:',
    Unit: '',
  },
  {
    HsCode: '95061100',
    Description: '- - Ván trượt tuyết',
    Unit: 'chiếc',
  },
  {
    HsCode: '95061200',
    Description: '- - Dây buộc ván trượt',
    Unit: 'chiếc',
  },
  {
    HsCode: '95061900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Ván trượt nước, ván lướt sóng, ván buồm và các thiết bị thể thao dưới nước khác:',
    Unit: '',
  },
  {
    HsCode: '95062100',
    Description: '- - Ván buồm',
    Unit: 'chiếc',
  },
  {
    HsCode: '95062900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Gậy chơi gôn và các thiết bị chơi gôn khác:',
    Unit: '',
  },
  {
    HsCode: '95063100',
    Description: '- - Gậy, bộ gậy chơi gôn',
    Unit: 'chiếc',
  },
  {
    HsCode: '95063200',
    Description: '- - Bóng',
    Unit: 'quả',
  },
  {
    HsCode: '95063900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '950640',
    Description: '- Dụng cụ và thiết bị cho môn bóng bàn:',
    Unit: '',
  },
  {
    HsCode: '95064010',
    Description: '- - Bàn',
    Unit: 'chiếc',
  },
  {
    HsCode: '95064090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Vợt tennis, vợt cầu lông hoặc các vợt tương tự khác, đã hoặc chưa căng lưới:',
    Unit: '',
  },
  {
    HsCode: '95065100',
    Description: '- - Vợt tennis, đã hoặc chưa căng lưới',
    Unit: 'chiếc',
  },
  {
    HsCode: '95065900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bóng, trừ bóng chơi gôn và bóng chơi bóng bàn:',
    Unit: '',
  },
  {
    HsCode: '95066100',
    Description: '- - Bóng tennis',
    Unit: 'quả',
  },
  {
    HsCode: '95066200',
    Description: '- - Bóng có thể bơm hơi',
    Unit: 'quả',
  },
  {
    HsCode: '95066900',
    Description: '- - Loại khác',
    Unit: 'quả',
  },
  {
    HsCode: '95067000',
    Description: '- Lưỡi giày trượt băng và lưỡi trượt có bánh xe, kể cả giày trượt có gắn lưỡi trượt',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '95069100',
    Description: '- - Các mặt hàng và thiết bị cho tập luyện thể chất nói chung, thể dục hoặc điền kinh',
    Unit: 'chiếc',
  },
  {
    HsCode: '950699',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '95069910',
    Description: '- - - Cung (kể cả nỏ) và mũi tên',
    Unit: 'chiếc',
  },
  {
    HsCode: '95069920',
    Description: '- - - Lưới (*), đệm bảo vệ ống chân và tấm ốp bảo vệ ống chân ',
    Unit: 'chiếc',
  },
  {
    HsCode: '95069930',
    Description: '- - - Quả cầu lông',
    Unit: 'chiếc',
  },
  {
    HsCode: '95069990',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9507',
    Description:
      'Cần câu, lưỡi câu và các loại dây câu khác; vợt lưới bắt cá, vợt lưới bắt bướm và các loại lưới tương tự; chim giả làm mồi (trừ các loại thuộc nhóm 92.08 hoặc 97.05) và các dụng cụ cần thiết dùng cho săn hoặc bắn tương tự',
    Unit: '',
  },
  {
    HsCode: '95071000',
    Description: '- Cần câu',
    Unit: 'chiếc',
  },
  {
    HsCode: '95072000',
    Description: '- Lưỡi câu, có hoặc không có dây cước',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '95073000',
    Description: '- Bộ cuộn dây câu',
    Unit: 'cuộn',
  },
  {
    HsCode: '95079000',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9508',
    Description:
      'Rạp xiếc lưu động và bầy thú xiếc lưu động; trò chơi di chuyển trong công viên giải trí (amusement park rides) và trò chơi công viên nước (water park amusements); trò chơi hội chợ (fairground amusements), kể cả khu bắn súng giải trí; rạp lưu động',
    Unit: '',
  },
  {
    HsCode: '95081000',
    Description: '- Rạp xiếc lưu động và bầy thú xiếc lưu động',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Trò chơi di chuyển trong công viên giải trí và trò chơi công viên nước: ',
    Unit: '',
  },
  {
    HsCode: '95082100',
    Description: '- - Tàu lượn siêu tốc',
    Unit: 'chiếc',
  },
  {
    HsCode: '95082200',
    Description: '- - Đu quay, đánh đu và vòng quay ngựa gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '95082300',
    Description: '- - Trò chơi xe đụng ',
    Unit: 'chiếc',
  },
  {
    HsCode: '95082400',
    Description: '- - Thiết bị mô phỏng chuyển động và rạp mô phỏng chuyển động',
    Unit: 'chiếc',
  },
  {
    HsCode: '95082500',
    Description: '- - Cầu trượt nước',
    Unit: 'chiếc',
  },
  {
    HsCode: '95082600',
    Description: '- - Trò chơi công viên nước',
    Unit: 'chiếc',
  },
  {
    HsCode: '95082900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '95083000',
    Description: '- Trò chơi hội chợ',
    Unit: 'chiếc',
  },
  {
    HsCode: '95084000',
    Description: '- Rạp lưu động',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      ' (*) Lưới dùng cho các trò chơi như tennis, cầu lông, bóng truyền, bóng đá, bóng rổ, trừ lưới hàng rào, túi lưới đựng bóng đá, bóng tennis và các loại tương tự thuộc nhóm 56.08.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 96',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các mặt hàng khác',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(a) Bút chì dùng để trang điểm (Chương 33);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(b) Các mặt hàng thuộc Chương 66 (ví dụ, các bộ phận của ô hoặc gậy chống);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(c) Đồ trang sức làm bằng chất liệu khác (nhóm 71.17);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(d) Các bộ phận có công dụng chung, như đã định nghĩa trong Chú giải 2 Phần XV, bằng kim loại cơ bản (Phần XV), hoặc các hàng hóa tương tự bằng plastic (Chương 39);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(e) Dao kéo hoặc các mặt hàng khác thuộc Chương 82 có cán hoặc các bộ phận khác được khảm hoặc đúc; tuy nhiên, cán hoặc các bộ phận khác được khảm hoặc đúc của các mặt hàng này được trình bày riêng lẻ thuộc nhóm 96.01 hoặc 96.02;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(f) Các mặt hàng thuộc Chương 90 (ví dụ, gọng kính đeo (nhóm 90.03), bút vẽ toán học (nhóm 90.17), bàn chải chuyên dùng trong nha khoa hoặc y tế, phẫu thuật hoặc thú y (nhóm 90.18));',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(g) Các mặt hàng thuộc Chương 91 (ví dụ, vỏ đồng hồ cá nhân hoặc vỏ đồng hồ thời gian);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(h) Nhạc cụ hoặc bộ phận hoặc phụ kiện của chúng (Chương 92);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(ij) Các mặt hàng thuộc Chương 93 (vũ khí và bộ phận của chúng); ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(k) Các sản phẩm thuộc Chương 94 (ví dụ, đồ nội thất, đèn (luminaires) và bộ đèn);',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(l) Các sản phẩm thuộc Chương 95 (đồ chơi, trò chơi, dụng cụ, thiết bị thể thao); hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(m) Tác phẩm nghệ thuật, đồ sưu tầm hoặc đồ cổ (Chương 97).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Trong nhóm 96.02 khái niệm ""vật liệu chạm khắc có nguồn gốc thực vật hoặc khoáng chất"" có nghĩa là:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Hạt cứng, hột, vỏ quả và quả hạch và vật liệu từ thực vật tương tự dùng để chạm khắc (ví dụ, quả dừa ngà và cọ dum);',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Hổ phách, đá bọt, hổ phách kết tụ và đá bọt kết tụ, hạt huyền và chất khoáng thay thế cho hạt huyền này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Trong nhóm 96.03 khái niệm ""túm và búi đã làm sẵn để làm chổi hoặc bàn chải"" chỉ bao gồm những túm và búi chưa được buộc bằng lông động vật, xơ thực vật hoặc vật liệu khác, đã chuẩn bị sẵn để bó mà không phân biệt là để làm chổi hoặc làm bàn chải, hoặc mới chỉ gia công đơn giản hơn như cắt tỉa để tạo dáng ở ngọn, cho đủ điều kiện để bó.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Các mặt hàng thuộc Chương này, trừ các nhóm từ 96.01 đến 96.06 hoặc 96.15, vẫn được phân loại vào Chương này dù có hoặc không chứa một phần hoặc toàn bộ kim loại quý hoặc kim loại dát phủ kim loại quý, ngọc trai thiên nhiên hoặc nuôi cấy, hoặc đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo). Tuy nhiên, các nhóm từ 96.01 đến 96.06 và 96.15 kể cả các mặt hàng có chứa ngọc trai thiên nhiên hoặc nuôi cấy, đá quý hoặc đá bán quý (tự nhiên, tổng hợp hoặc tái tạo), kim loại quý hoặc kim loại dát phủ kim loại quý nhưng những chất liệu này chỉ là thành phần phụ.',
    Unit: '',
  },
  {
    HsCode: '9601',
    Description:
      'Ngà, xương, đồi mồi, sừng, nhánh gạc, san hô, xà cừ và các vật liệu chạm khắc có nguồn gốc động vật khác đã gia công, và các mặt hàng làm từ các vật liệu này (kể cả các sản phẩm đúc)',
    Unit: '',
  },
  {
    HsCode: '960110',
    Description: '- Ngà đã gia công và các vật phẩm bằng ngà:',
    Unit: '',
  },
  {
    HsCode: '96011010',
    Description: '- - Hộp đựng xì gà hoặc thuốc lá điếu, bình đựng thuốc lá; đồ trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96011090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '960190',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Xà cừ hoặc đồi mồi đã gia công và các sản phẩm làm từ chúng:',
    Unit: '',
  },
  {
    HsCode: '96019011',
    Description: '- - - Hộp đựng xì gà hoặc thuốc lá điếu, bình đựng thuốc lá; đồ trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96019012',
    Description: '- - - Nhân nuôi cấy ngọc trai (SEN)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96019019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Vỏ điệp giấy đã gia công và các sản phẩm làm từ chúng:',
    Unit: '',
  },
  {
    HsCode: '96019021',
    Description: ' - - - Đồ trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96019029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96019091',
    Description: '- - - Hộp đựng xì gà hoặc thuốc lá điếu, bình đựng thuốc lá; đồ trang trí',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96019099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9602',
    Description:
      'Vật liệu khảm có nguồn gốc thực vật hoặc khoáng đã được gia công và các sản phẩm làm từ các loại vật liệu này; các vật liệu đúc hoặc chạm khắc bằng sáp, bằng stearin, bằng gôm tự nhiên hoặc nhựa tự nhiên hoặc bằng bột nhão làm mô hình, và sản phẩm được đúc hoặc chạm khắc khác, chưa được chi tiết hoặc ghi ở nơi khác; gelatin đã chế biến nhưng chưa đóng cứng (trừ gelatin thuộc nhóm 35.03) và các sản phẩm làm bằng gelatin chưa đóng cứng',
    Unit: '',
  },
  {
    HsCode: '96020010',
    Description: '- Vỏ viên nhộng bằng gelatin dùng cho dược phẩm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96020020',
    Description: '- Hộp đựng xì gà hoặc thuốc lá điếu, bình đựng thuốc lá; đồ trang trí',
    Unit: 'chiếc',
  },
  {
    HsCode: '96020090',
    Description: '- Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9603',
    Description:
      'Chổi, bàn chải (kể cả các loại bàn chải là những bộ phận của máy, dụng cụ hoặc xe), dụng cụ cơ học vận hành bằng tay để quét sàn, không có động cơ, giẻ lau sàn và chổi bằng lông vũ; túm và búi đã làm sẵn để làm chổi hoặc bàn chải; miếng thấm và con lăn để sơn hoặc vẽ; chổi cao su (trừ con lăn bằng cao su)',
    Unit: '',
  },
  {
    HsCode: '960310',
    Description:
      '- Chổi và bàn chải, gồm các cành nhỏ hoặc các chất liệu từ thực vật khác buộc lại với nhau, có hoặc không có cán:',
    Unit: '',
  },
  {
    HsCode: '96031010',
    Description: '- - Bàn chải',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96031020',
    Description: '- - Chổi',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Bàn chải đánh răng, chổi xoa bọt cạo râu, bàn chải chải tóc, chổi sơn móng tay, chải mi mắt và bàn chải vệ sinh khác dùng cho người, kể cả bàn chải tương tự là bộ phận của các dụng cụ:',
    Unit: '',
  },
  {
    HsCode: '96032100',
    Description: '- - Bàn chải đánh răng, kể cả bàn chải dùng cho răng mạ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96032900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96033000',
    Description: '- Bút lông vẽ, bút lông để viết và bút lông tương tự dùng cho việc trang điểm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96034000',
    Description:
      '- Chổi quét sơn, chổi quét keo, chổi quét vecni hoặc các loại chổi tương tự (trừ các loại bút lông thuộc phân nhóm 9603.30); miếng thấm và con lăn để quét sơn hoặc vẽ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96035000',
    Description: '- Các loại bàn chải khác là các bộ phận của máy móc, dụng cụ hoặc xe',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '960390',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96039010',
    Description: '- - Túm và búi đã làm sẵn để làm chổi hoặc bàn chải',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96039020',
    Description: '- - Dụng cụ cơ học vận hành bằng tay để quét sàn, không có động cơ',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96039040',
    Description: '- - Bàn chải khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96039090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9604',
    Description: 'Giần và sàng tay',
    Unit: '',
  },
  {
    HsCode: '96040010',
    Description: '- Bằng kim loại',
    Unit: 'chiếc',
  },
  {
    HsCode: '96040090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '96050000',
    Description: 'Bộ đồ du lịch dùng cho vệ sinh cá nhân, bộ đồ khâu hoặc bộ đồ làm sạch giày dép hoặc quần áo',
    Unit: 'kg/chiếc/ bộ',
  },
  {
    HsCode: '9606',
    Description:
      'Khuy, khuy dập, khuy bấm và khuy tán bấm, lõi khuy (loại khuy bọc) và các bộ phận khác của các mặt hàng này; khuy chưa hoàn chỉnh (button blank)',
    Unit: '',
  },
  {
    HsCode: '960610',
    Description: '- Khuy dập, khuy bấm và khuy tán bấm và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '96061010',
    Description: '- - Bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96061090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Khuy:',
    Unit: '',
  },
  {
    HsCode: '96062100',
    Description: '- - Bằng plastic, không bọc vật liệu dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96062200',
    Description: '- - Bằng kim loại cơ bản, không bọc vật liệu dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96062900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '960630',
    Description: '- Lõi khuy và các bộ phận khác của khuy; khuy chưa hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '96063010',
    Description: '- - Bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96063090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9607',
    Description: 'Khóa kéo và các bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Khóa kéo:',
    Unit: '',
  },
  {
    HsCode: '96071100',
    Description: '- - Có răng bằng kim loại cơ bản',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96071900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96072000',
    Description: '- Bộ phận',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9608',
    Description:
      'Bút bi; bút phớt và bút phớt có ruột khác và bút đánh dấu; bút máy, bút máy ngòi ống và các loại bút khác; bút viết giấy nhân bản (duplicating stylos); các loại bút chì bấm hoặc bút chì đẩy; quản bút mực, quản bút chì và các loại quản bút tương tự; bộ phận (kể cả nắp và kẹp bút) của các loại bút kể trên, trừ các loại thuộc nhóm 96.09',
    Unit: '',
  },
  {
    HsCode: '960810',
    Description: '- Bút bi:',
    Unit: '',
  },
  {
    HsCode: '96081010',
    Description: '- - Có thân chủ yếu bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '96081090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '96082000',
    Description: '- Bút phớt và bút phớt có ruột khác và bút đánh dấu',
    Unit: 'chiếc',
  },
  {
    HsCode: '960830',
    Description: '- Bút máy, bút máy ngòi ống và các loại bút khác:',
    Unit: '',
  },
  {
    HsCode: '96083020',
    Description: '- - Bút máy',
    Unit: 'chiếc',
  },
  {
    HsCode: '96083090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '96084000',
    Description: '- Bút chì bấm hoặc bút chì đẩy',
    Unit: 'chiếc',
  },
  {
    HsCode: '96085000',
    Description: '- Bộ vật phẩm có từ hai mặt hàng trở lên thuộc các phân nhóm trên',
    Unit: 'chiếc',
  },
  {
    HsCode: '960860',
    Description: '- Ruột thay thế của bút bi, gồm cả bi và ống mực:',
    Unit: '',
  },
  {
    HsCode: '96086010',
    Description: '- - Bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '96086090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '960891',
    Description: '- - Ngòi bút và bi ngòi:',
    Unit: '',
  },
  {
    HsCode: '96089110',
    Description: '- - - Bằng vàng hoặc mạ vàng',
    Unit: 'chiếc',
  },
  {
    HsCode: '96089190',
    Description: '- - - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '960899',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96089910',
    Description: '- - - Bút viết giấy nhân bản',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96089991',
    Description: '- - - - Bộ phận của bút bi, bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96089999',
    Description: '- - - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9609',
    Description:
      'Bút chì (trừ các loại bút chì thuộc nhóm 96.08), bút màu, ruột chì, phấn màu, than vẽ, phấn vẽ hoặc viết và phấn thợ may',
    Unit: '',
  },
  {
    HsCode: '960910',
    Description: '- Bút chì và bút chì màu, có lõi chì trong vỏ:',
    Unit: '',
  },
  {
    HsCode: '96091010',
    Description: '- - Bút chì đen',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96091090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96092000',
    Description: '- Ruột chì, đen hoặc màu',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '960990',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96099010',
    Description: '- - Bút chì bằng đá phiến (slate) dùng trong trường học',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96099030',
    Description: '- - Bút chì và bút màu khác trừ loại thuộc phân nhóm 9609.10',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96099091',
    Description: '- - - Phấn vẽ hoặc phấn viết',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96099099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9610',
    Description: 'Bảng đá phiến (slate) và bảng, có bề mặt dùng để viết hoặc vẽ, có hoặc không có khung',
    Unit: '',
  },
  {
    HsCode: '96100010',
    Description: '- Bảng đá phiến (slate) dùng trong trường học',
    Unit: 'chiếc',
  },
  {
    HsCode: '96100090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '96110000',
    Description:
      'Con dấu ngày, con dấu niêm phong hoặc con dấu đánh số, và loại tương tự (kể cả dụng cụ để in hoặc dập nổi nhãn hiệu), được thiết kế để sử dụng thủ công; con dấu đóng để sử dụng thủ công và các bộ in bằng tay kèm theo các con dấu đó',
    Unit: 'chiếc',
  },
  {
    HsCode: '9612',
    Description:
      'Ruy băng máy chữ hoặc tương tự, đã phủ mực hoặc làm sẵn bằng cách khác để in ấn, đã hoặc chưa cuộn vào lõi hoặc nằm trong vỏ; tấm mực dấu, đã hoặc chưa tẩm mực, có hoặc không có hộp',
    Unit: '',
  },
  {
    HsCode: '961210',
    Description: '- Ruy băng:',
    Unit: '',
  },
  {
    HsCode: '96121010',
    Description: '- - Bằng vật liệu dệt',
    Unit: 'chiếc/m',
  },
  {
    HsCode: '96121090',
    Description: '- - Loại khác',
    Unit: 'chiếc/m',
  },
  {
    HsCode: '96122000',
    Description: '- Tấm mực dấu',
    Unit: 'chiếc/m',
  },
  {
    HsCode: '9613',
    Description:
      'Bật lửa châm thuốc lá và các bật lửa khác, có hoặc không dùng cơ hoặc điện, và các bộ phận của chúng trừ đá lửa và bấc',
    Unit: '',
  },
  {
    HsCode: '961310',
    Description: '- Bật lửa bỏ túi, dùng ga, không thể nạp lại:',
    Unit: '',
  },
  {
    HsCode: '96131010',
    Description: '- - Bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '96131090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '961320',
    Description: '- Bật lửa bỏ túi, dùng ga, có thể nạp lại:',
    Unit: '',
  },
  {
    HsCode: '96132010',
    Description: '- - Bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '96132090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '961380',
    Description: '- Bật lửa khác:',
    Unit: '',
  },
  {
    HsCode: '96138010',
    Description: '- - Bật lửa áp điện dùng cho lò và bếp',
    Unit: 'chiếc',
  },
  {
    HsCode: '96138020',
    Description: '- - Bật lửa châm thuốc lá hoặc bật lửa để bàn, bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '96138030',
    Description: '- - Bật lửa châm thuốc lá hoặc bật lửa để bàn, trừ loại bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '96138090',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '961390',
    Description: '- Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '96139010',
    Description:
      '- - Ống chứa hoặc bộ phận chứa khác có thể nạp lại của bật lửa cơ khí, có chứa nhiên liệu lỏng hoặc khí hóa lỏng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96139090',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9614',
    Description: 'Tẩu thuốc (kể cả điếu bát) và đót xì gà hoặc đót thuốc lá, và bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '96140010',
    Description: '- Gỗ hoặc rễ cây đã tạo dáng thô để làm tẩu thuốc',
    Unit: 'chiếc',
  },
  {
    HsCode: '96140090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9615',
    Description:
      'Lược, trâm cài tóc và loại tương tự; ghim cài tóc, cặp uốn tóc, kẹp uốn tóc, lô cuộn tóc và loại tương tự, trừ các loại thuộc nhóm 85.16, và bộ phận của chúng',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Lược, trâm cài tóc và loại tương tự:',
    Unit: '',
  },
  {
    HsCode: '961511',
    Description: '- - Bằng cao su cứng hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '96151120',
    Description: '- - - Bằng cao su cứng',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96151130',
    Description: '- - - Bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96151900',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '961590',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Ghim cài tóc trang trí:',
    Unit: '',
  },
  {
    HsCode: '96159011',
    Description: '- - - Bằng nhôm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159012',
    Description: '- - - Bằng sắt hoặc thép',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159013',
    Description: '- - - Bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159019',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '96159021',
    Description: '- - - Bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159022',
    Description: '- - - Bằng sắt hoặc thép',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159023',
    Description: '- - - Bằng nhôm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159029',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96159091',
    Description: '- - - Bằng nhôm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159092',
    Description: '- - - Bằng sắt hoặc thép',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159093',
    Description: '- - - Bằng plastic',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96159099',
    Description: '- - - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9616',
    Description:
      'Bình, lọ xịt nước hoa hoặc bình, lọ xịt để trang điểm tương tự, và các bộ phận gá lắp và đầu xịt của chúng; miếng và tấm để xoa và chấm mỹ phẩm hoặc các sản phẩm trang điểm',
    Unit: '',
  },
  {
    HsCode: '961610',
    Description:
      '- Bình, lọ xịt nước hoa và bình, lọ xịt để trang điểm tương tự, và các bộ phận gá lắp và đầu xịt của chúng:',
    Unit: '',
  },
  {
    HsCode: '96161010',
    Description: '- - Bình, lọ xịt nước hoa và bình, lọ xịt trang điểm tương tự',
    Unit: 'chiếc',
  },
  {
    HsCode: '96161020',
    Description: '- - Các bộ phận gá lắp và đầu xịt',
    Unit: 'chiếc',
  },
  {
    HsCode: '96162000',
    Description: '- Miếng và tấm để xoa và chấm mỹ phẩm hoặc các sản phẩm trang điểm',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9617',
    Description: 'Phích chân không và các loại bình chân không khác, hoàn chỉnh; bộ phận của chúng trừ ruột thủy tinh',
    Unit: '',
  },
  {
    HsCode: '96170010',
    Description: '- Phích chân không và các loại bình chân không khác, hoàn chỉnh',
    Unit: 'chiếc',
  },
  {
    HsCode: '96170020',
    Description: '- Các bộ phận, trừ ruột thủy tinh',
    Unit: 'chiếc',
  },
  {
    HsCode: '96180000',
    Description:
      'Ma-nơ-canh dùng trong ngành may và các mô hình cơ thể khác; mô hình tự động và các vật trưng bày cử động được khác dùng cho cửa hàng may mặc',
    Unit: 'chiếc',
  },
  {
    HsCode: '9619',
    Description:
      'Băng vệ sinh (miếng) và băng vệ sinh dạng ống (tampon), khăn (bỉm), tã lót và các vật phẩm tương tự, bằng mọi vật liệu',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Các sản phẩm dùng một lần: ',
    Unit: '',
  },
  {
    HsCode: '96190011',
    Description: '- - Lõi thấm hút bằng vật liệu dệt',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96190012',
    Description:
      '- - Băng vệ sinh (miếng) và băng vệ sinh dạng ống (tampons) từ giấy, bột giấy, tấm xơ sợi xenlulo hoặc màng xơ sợi xenlulo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96190013',
    Description: '- - Bỉm và miếng lót vệ sinh trẻ em từ giấy, bột giấy, tấm xơ sợi xenlulo hoặc màng xơ sợi xenlulo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96190014',
    Description: '- - Loại khác, từ giấy, bột giấy, tấm xơ sợi xenlulo hoặc màng xơ sợi xenlulo',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96190019',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '96190092',
    Description: '- - Băng vệ sinh (miếng)',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96190093',
    Description: '- - Loại khác, dệt kim hoặc móc',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '96190099',
    Description: '- - Loại khác',
    Unit: 'kg/chiếc',
  },
  {
    HsCode: '9620',
    Description: 'Chân đế loại một chân (monopod), hai chân (bipod), ba chân (tripod) và các mặt hàng tương tự',
    Unit: '',
  },
  {
    HsCode: '96200010',
    Description: '- Bằng plastic',
    Unit: 'chiếc',
  },
  {
    HsCode: '96200020',
    Description: '- Bằng carbon và graphit',
    Unit: 'chiếc',
  },
  {
    HsCode: '96200030',
    Description: '- Bằng sắt và thép',
    Unit: 'chiếc',
  },
  {
    HsCode: '96200040',
    Description: '- Bằng nhôm',
    Unit: 'chiếc',
  },
  {
    HsCode: '96200050',
    Description: '- Bằng gỗ',
    Unit: 'chiếc',
  },
  {
    HsCode: '96200090',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '(SEN): Tham khảo Chú giải bổ sung (SEN) của Danh mục Thuế quan hài hòa ASEAN (AHTN) 2022',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'PHẦN XXI',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'CÁC TÁC PHẨM NGHỆ THUẬT, ĐỒ SƯU TẦM VÀ ĐỒ CỔ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chương 97',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Các tác phẩm nghệ thuật, đồ sưu tầm và đồ cổ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'Chú giải',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '1. Chương này không bao gồm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(a) Tem bưu chính hoặc tem thuế chưa sử dụng, bưu thiếp (đã đóng sẵn tem) hoặc loại tương tự, thuộc nhóm 49.07;',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(b) Phông sân khấu, phông trường quay hoặc loại tương tự, bằng vải canvas đã sơn vẽ (nhóm 59.07) trừ khi chúng có thể phân loại được vào nhóm 97.06; hoặc',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '(c) Ngọc trai, tự nhiên hoặc nuôi cấy, hoặc đá quý hoặc đá bán quý (thuộc các nhóm từ 71.01 đến 71.03).',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '2. Nhóm 97.01 không áp dụng cho tranh khảm được tái sản xuất hàng loạt, đồ đúc hoặc tác phẩm thủ công thông thường có tính chất thương mại, ngay cả khi các sản phẩm này được thiết kế hoặc tạo ra bởi các nghệ sĩ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '3. Theo nội dung của nhóm 97.02, khái niệm “bản khắc, bản in và bản in lytô nguyên bản” có nghĩa là các bản in được in trực tiếp, đen và trắng hoặc in màu, bằng một hoặc một vài bản kẽm do các nghệ nhân thực hiện hoàn toàn bằng tay, không phân biệt quy trình hoặc loại nguyên liệu mà họ sử dụng, nhưng không bao gồm quá trình cơ học hoặc quang cơ.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '4. Nhóm 97.03 không áp dụng đối với các phiên bản hoặc tác phẩm được sản xuất hàng loạt bằng kỹ năng thông thường phục vụ cho mục đích thương mại, cho dù mặt hàng này được thiết kế hoặc tạo ra bởi các nghệ nhân.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '5. (A) Theo các Chú giải từ 1 đến 4 nêu trên, các mặt hàng thuộc Chương này phải được phân loại vào Chương này và không được phân loại vào bất kỳ Chương nào khác của Danh mục.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '(B) Nhóm 97.06 không áp dụng cho những mặt hàng thuộc các nhóm trên đây của Chương này.',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '6. Khung của các bức tranh vẽ, tranh phác họa, tranh bột màu, hình ghép nghệ thuật hoặc phù điêu trang trí tương tự, bản khắc, bản in hoặc bản in lytô được phân loại cùng với các vật phẩm này, với điều kiện là chúng cùng chủng loại và có giá trị tương ứng với các mặt hàng này. Các loại khung mà không cùng chủng loại hoặc không có giá trị tương ứng với các vật phẩm được đề cập trong Chú giải này được phân loại riêng. ',
    Unit: '',
  },
  {
    HsCode: '9701',
    Description:
      'Tranh vẽ, tranh phác họa và tranh bột màu, được vẽ hoàn toàn bằng tay, trừ các loại tranh phác họa thuộc nhóm 49.06 và trừ các tác phẩm vẽ hoặc trang trí bằng tay; các tác phẩm ghép, tranh khảm và phù điêu trang trí tương tự',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Có tuổi trên 100 năm:',
    Unit: '',
  },
  {
    HsCode: '97012100',
    Description: '- - Tranh vẽ, tranh phác họa và tranh bột màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '97012200',
    Description: '- - Tranh khảm',
    Unit: 'chiếc',
  },
  {
    HsCode: '97012900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '97019100',
    Description: '- - Tranh vẽ, tranh phác họa và tranh bột màu',
    Unit: 'chiếc',
  },
  {
    HsCode: '97019200',
    Description: '- - Tranh khảm',
    Unit: 'chiếc',
  },
  {
    HsCode: '97019900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9702',
    Description: 'Nguyên bản các bản khắc, bản in và bản in lytô',
    Unit: '',
  },
  {
    HsCode: '97021000',
    Description: '- Có tuổi trên 100 năm',
    Unit: 'chiếc',
  },
  {
    HsCode: '97029000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9703',
    Description: 'Nguyên bản tác phẩm điêu khắc và tượng tạc, làm bằng mọi loại vật liệu',
    Unit: '',
  },
  {
    HsCode: '97031000',
    Description: '- Có tuổi trên 100 năm',
    Unit: 'chiếc',
  },
  {
    HsCode: '97039000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '97040000',
    Description:
      'Tem bưu chính hoặc tem thuế, dấu in cước thay tem bưu chính, phong bì có tem đóng dấu ngày phát hành đầu tiên, các ấn phẩm bưu chính (ấn phẩm có in sẵn tem), và các ấn phẩm tương tự, đã hoặc chưa sử dụng, trừ loại thuộc nhóm 49.07',
    Unit: 'chiếc',
  },
  {
    HsCode: '9705',
    Description:
      'Bộ sưu tập và các vật phẩm sưu tập chủng loại khảo cổ, dân tộc học, sử học, động vật, thực vật, khoáng vật, giải phẫu học, cổ sinh vật học hoặc các loại tiền',
    Unit: '',
  },
  {
    HsCode: '97051000',
    Description: '- Bộ sưu tập và các vật phẩm sưu tập chủng loại khảo cổ, dân tộc học hoặc sử học',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description:
      '- Bộ sưu tập và các vật phẩm sưu tập chủng loại động vật, thực vật, khoáng vật, giải phẫu học hoặc cổ sinh vật học:',
    Unit: '',
  },
  {
    HsCode: '97052100',
    Description: '- - Tiêu bản người và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '97052200',
    Description: '- - Các loài đã tuyệt chủng hoặc có nguy cơ tuyệt chủng và các bộ phận của chúng',
    Unit: 'chiếc',
  },
  {
    HsCode: '97052900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: '- Bộ sưu tập và các vật phẩm sưu tập các loại tiền:',
    Unit: '',
  },
  {
    HsCode: '97053100',
    Description: '- - Có tuổi trên 100 năm',
    Unit: 'chiếc',
  },
  {
    HsCode: '97053900',
    Description: '- - Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '9706',
    Description: 'Đồ cổ có tuổi trên 100 năm',
    Unit: '',
  },
  {
    HsCode: '97061000',
    Description: '- Có tuổi trên 250 năm',
    Unit: 'chiếc',
  },
  {
    HsCode: '97069000',
    Description: '- Loại khác',
    Unit: 'chiếc',
  },
  {
    HsCode: '',
    Description: 'Chương 98',
    Unit: '',
  },
  {
    HsCode: '',
    Description: 'QUY ĐỊNH MÃ HÀNG VÀ MỨC THUẾ SUẤT THUẾ NHẬP KHẨU ƯU ĐÃI RIÊNG ĐỐI VỚI MỘT SỐ NHÓM MẶT HÀNG, MẶT HÀNG',
    Unit: 'Mã hàng tương ứng',
  },
  {
    HsCode: '',
    Description: '3. Danh mục hàng hóa và mức thuế suất thuế nhập khẩu ưu đãi',
    Unit: '',
  },
  {
    HsCode: '9804',
    Description: 'Động vật giáp xác, đã được hun khói.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Đông lạnh:',
    Unit: '',
  },
  {
    HsCode: '98041500',
    Description: '- - Tôm hùm Na Uy (Nephrops norvegicus)',
    Unit: '03061500',
  },
  {
    HsCode: '98041600',
    Description: '- - Tôm shrimps và tôm prawn nước lạnh (Pandalus spp., Crangon crangon)',
    Unit: '03061600',
  },
  {
    HsCode: '980417',
    Description: '- - Tôm shrimps và tôm prawn khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Tôm sú (Penaeus monodon):',
    Unit: '',
  },
  {
    HsCode: '98041711',
    Description: '- - - - Đã bỏ đầu',
    Unit: '03061711',
  },
  {
    HsCode: '98041719',
    Description: '- - - - Loại khác',
    Unit: '03061719',
  },
  {
    HsCode: '',
    Description: '- - - Tôm thẻ chân trắng (Litopenaeus vannamei):',
    Unit: '',
  },
  {
    HsCode: '98041721',
    Description: '- - - - Đã bỏ đầu, còn đuôi',
    Unit: '03061721',
  },
  {
    HsCode: '98041722',
    Description: '- - - - Đã bỏ đầu, bỏ đuôi',
    Unit: '03061722',
  },
  {
    HsCode: '98041729',
    Description: '- - - - Loại khác',
    Unit: '03061729',
  },
  {
    HsCode: '98041730',
    Description: '- - - Tôm càng xanh (Macrobrachium rosenbergii)',
    Unit: '03061730',
  },
  {
    HsCode: '98041790',
    Description: '- - - Loại khác',
    Unit: '03061790',
  },
  {
    HsCode: '98041900',
    Description:
      '- - Loại khác, kể cả bột thô, bột mịn và viên của động vật giáp xác, thích hợp dùng làm thức ăn cho người',
    Unit: '03061900',
  },
  {
    HsCode: '98041900',
    Description:
      '- - Loại khác, kể cả bột thô, bột mịn và viên của động vật giáp xác, thích hợp dùng làm thức ăn cho người',
    Unit: '03099012',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '980428',
    Description: '- - Tôm shrimps và tôm prawn:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Đóng bao bì kín khí để bán lẻ:',
    Unit: '',
  },
  {
    HsCode: '98042811',
    Description: '- - - - Còn vỏ, đã hấp chín hoặc luộc chín trong nước',
    Unit: '03069521',
  },
  {
    HsCode: '98042819',
    Description: '- - - - Loại khác',
    Unit: '03069529',
  },
  {
    HsCode: '98042890',
    Description: '- - - Loại khác',
    Unit: '03069530',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12073000',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12074090',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12075000',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12076000',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12077000',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12079100',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12079940',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12079950',
  },
  {
    HsCode: '98050000',
    Description: 'Quả và hạt có dầu để làm giống.',
    Unit: '12079990',
  },
  {
    HsCode: '9808',
    Description:
      'Vải dệt đã được ngâm tẩm, tráng, phủ hoặc ép với plastic, trừ các loại thuộc nhóm 59.02, dùng làm lớp cốt cho vải tráng phủ cao su.',
    Unit: '',
  },
  {
    HsCode: '9808',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98080010',
    Description: '- - Vải canvas đã được ngâm tẩm, tráng, phủ hoặc ép với nylon hoặc các polyamit khác',
    Unit: '59039010',
  },
  {
    HsCode: '98080090',
    Description: '- - Loại khác',
    Unit: '59039090',
  },
  {
    HsCode: '9810',
    Description: 'Sắt hoặc thép để sản xuất tanh lốp xe.',
    Unit: '',
  },
  {
    HsCode: '98100010',
    Description:
      '- Sắt hoặc thép không hợp kim, dạng thanh và que, ở dạng cuộn cuốn không đều, được cán nóng, có đường kính mặt cắt ngang hình tròn dưới 14 mm để sản xuất tanh lốp xe',
    Unit: '72139130',
  },
  {
    HsCode: '98100010',
    Description:
      '- Sắt hoặc thép không hợp kim, dạng thanh và que, ở dạng cuộn cuốn không đều, được cán nóng, có đường kính mặt cắt ngang hình tròn dưới 14 mm để sản xuất tanh lốp xe',
    Unit: '72139190',
  },
  {
    HsCode: '98100090',
    Description: '- Sản phẩm bằng dây sắt hoặc thép để sản xuất tanh lốp xe',
    Unit: '73262090',
  },
  {
    HsCode: '9811',
    Description:
      'Thép hợp kim có chứa nguyên tố Bo và/hoặc Crôm và/hoặc Titan trừ chủng loại thép cán phẳng được cán nóng.',
    Unit: '',
  },
  {
    HsCode: '98110010',
    Description:
      '- Thép hợp kim có chứa nguyên tố Bo và/hoặc Crôm và/hoặc Titan trừ chủng loại thép cán phẳng được cán nóng',
    Unit: '7224',
  },
  {
    HsCode: '98110010',
    Description:
      '- Thép hợp kim có chứa nguyên tố Bo và/hoặc Crôm và/hoặc Titan trừ chủng loại thép cán phẳng được cán nóng',
    Unit: '7225',
  },
  {
    HsCode: '98110010',
    Description:
      '- Thép hợp kim có chứa nguyên tố Bo và/hoặc Crôm và/hoặc Titan trừ chủng loại thép cán phẳng được cán nóng',
    Unit: '7226',
  },
  {
    HsCode: '98110010',
    Description:
      '- Thép hợp kim có chứa nguyên tố Bo và/hoặc Crôm và/hoặc Titan trừ chủng loại thép cán phẳng được cán nóng',
    Unit: '7227',
  },
  {
    HsCode: '98110010',
    Description:
      '- Thép hợp kim có chứa nguyên tố Bo và/hoặc Crôm và/hoặc Titan trừ chủng loại thép cán phẳng được cán nóng',
    Unit: '7228',
  },
  {
    HsCode: '98110090',
    Description:
      '- Thép hợp kim có chứa nguyên tố Bo và/hoặc Crôm và/hoặc Titan trừ chủng loại thép cán phẳng được cán nóng',
    Unit: '7229',
  },
  {
    HsCode: '9812',
    Description:
      'Động cơ đốt trong kiểu piston cháy do nén (động cơ diesel hoặc bán diesel), dùng cho xe thuộc nhóm 87.01 có công suất trên 60kW.',
    Unit: '',
  },
  {
    HsCode: '98120010',
    Description: '- Đã lắp ráp hoàn chỉnh',
    Unit: '84082010',
  },
  {
    HsCode: '98120010',
    Description: '- Đã lắp ráp hoàn chỉnh',
    Unit: '84082021',
  },
  {
    HsCode: '98120010',
    Description: '- Đã lắp ráp hoàn chỉnh',
    Unit: '84082022',
  },
  {
    HsCode: '98120010',
    Description: '- Đã lắp ráp hoàn chỉnh',
    Unit: '84082023',
  },
  {
    HsCode: '98120090',
    Description: '- Loại khác, dùng cho xe thuộc phân nhóm 8701.10',
    Unit: '84082093',
  },
  {
    HsCode: '98160000',
    Description: 'Khung đèn thợ mỏ hoặc thợ khai thác đá; bộ phận khác dùng cho đèn thợ mỏ, đèn mổ.',
    Unit: '94059950',
  },
  {
    HsCode: '98160000',
    Description: 'Khung đèn thợ mỏ hoặc thợ khai thác đá; bộ phận khác dùng cho đèn thợ mỏ, đèn mổ.',
    Unit: '94059990',
  },
  {
    HsCode: '9817',
    Description:
      'Các mặt hàng máy móc, thiết bị phòng nổ chuyên dùng khai thác than trong hầm lò và một số vật tư, thiết bị để sản xuất, lắp ráp các máy móc, thiết bị phòng nổ chuyên dùng cho khai thác than trong hầm lò.',
    Unit: '',
  },
  {
    HsCode: '98172000',
    Description: '- Khoan búa cầm tay phòng nổ dùng trong hầm lò',
    Unit: '84672100',
  },
  {
    HsCode: '981730',
    Description:
      '- Máy biến thế khô, trạm biến áp khô trọn bộ đi động, phòng nổ trong hầm lò, loại có công suất danh định trên 16 kVA và đầu điện áp cao từ 66 kV trở lên:',
    Unit: '',
  },
  {
    HsCode: '98173010',
    Description: '- - Biến áp thích ứng',
    Unit: '85043311',
  },
  {
    HsCode: '98173010',
    Description: '- - Biến áp thích ứng',
    Unit: '85043411',
  },
  {
    HsCode: '98173010',
    Description: '- - Biến áp thích ứng',
    Unit: '85043414',
  },
  {
    HsCode: '98173010',
    Description: '- - Biến áp thích ứng',
    Unit: '85043422',
  },
  {
    HsCode: '98173090',
    Description: '- - Loại khác',
    Unit: '85043319',
  },
  {
    HsCode: '98173090',
    Description: '- - Loại khác',
    Unit: '85043413',
  },
  {
    HsCode: '98173090',
    Description: '- - Loại khác',
    Unit: '85043419',
  },
  {
    HsCode: '98173090',
    Description: '- - Loại khác',
    Unit: '85043424',
  },
  {
    HsCode: '9818',
    Description: 'Các mặt hàng phục vụ cho việc đóng mới, sửa chữa, bảo dưỡng đầu máy, toa xe.',
    Unit: '',
  },
  {
    HsCode: '981811',
    Description: '- Các sản phẩm bằng cao su xốp:',
    Unit: '',
  },
  {
    HsCode: '98181110',
    Description: '- - Nắp chụp cách điện',
    Unit: '40169953',
  },
  {
    HsCode: '98181190',
    Description: '- - Loại khác',
    Unit: '40169959',
  },
  {
    HsCode: '981812',
    Description:
      '- Sắt hoặc thép không hợp kim dạng hình chữ U hoặc chữ I, chưa được gia công quá mức cán nóng, kéo nóng hoặc ép đùn, có chiều cao từ 80 mm trở lên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Hình chữ U:',
    Unit: '',
  },
  {
    HsCode: '98181211',
    Description: '- - - Có hàm lượng carbon từ 0,6% trở lên tính theo trọng lượng',
    Unit: '72163110',
  },
  {
    HsCode: '98181219',
    Description: '- - - Loại khác',
    Unit: '72163190',
  },
  {
    HsCode: '',
    Description: '- - Hình chữ I:',
    Unit: '',
  },
  {
    HsCode: '98181291',
    Description: '- - - Chiều dày từ 5 mm trở xuống',
    Unit: '72163210',
  },
  {
    HsCode: '98181299',
    Description: '- - - Loại khác',
    Unit: '72163290',
  },
  {
    HsCode: '981813',
    Description:
      '- Vít, bu lông, đai ốc, vít đầu vuông, vít treo, đinh tán, chốt hãm, chốt định vị, vòng đệm (kể cả vòng đệm lò xo vênh) và các sản phẩm tương tự, bằng sắt hoặc thép:',
    Unit: '',
  },
  {
    HsCode: '98181310',
    Description: '- - Vít cho kim loại',
    Unit: '73181510',
  },
  {
    HsCode: '98181310',
    Description: '- - Vít cho kim loại',
    Unit: '73181590',
  },
  {
    HsCode: '98181390',
    Description: '- - Chốt hãm và chốt định vị',
    Unit: '73182400',
  },
  {
    HsCode: '981814',
    Description: '- Lò xo và lá lò xo, bằng sắt hoặc thép:',
    Unit: '',
  },
  {
    HsCode: '98181410',
    Description: '- - Lò xo lá và các lá lò xo',
    Unit: '73201090',
  },
  {
    HsCode: '98181490',
    Description: '- - Lò xo cuộn',
    Unit: '73202090',
  },
  {
    HsCode: '98181500',
    Description:
      '- Thiết bị làm nóng không khí và bộ phận phân phối khí nóng (kể cả loại có khả năng phân phối không khí tự nhiên hoặc khí điều hoà), không sử dụng năng lượng điện, có lắp quạt chạy bằng mô tơ hoặc quạt gió, và bộ phận của chúng, bằng sắt hoặc thép',
    Unit: '73229000',
  },
  {
    HsCode: '98181600',
    Description: '- Các sản phẩm đúc khác bằng sắt hoặc thép, trừ loại bằng gang đúc không dẻo',
    Unit: '73259990',
  },
  {
    HsCode: '98181700',
    Description: '- Cơ cấu đóng cửa tự động',
    Unit: '83026000',
  },
  {
    HsCode: '981819',
    Description:
      '- Các bộ phận chỉ dùng hoặc chủ yếu dùng cho các loại động cơ khác thuộc nhóm 84.07 hoặc 84.08, trừ: loại dùng cho động cơ của máy thuộc nhóm 84.29, 84.30; loại dùng cho động cơ của xe thuộc nhóm 87.01, 87.11 và xe khác thuộc chương 87; loại dùng cho động cơ của tàu thuyền thuộc chương 89:',
    Unit: '',
  },
  {
    HsCode: '98181910',
    Description: '- - Chế hòa khí và bộ phận của chúng',
    Unit: '84099971',
  },
  {
    HsCode: '98181920',
    Description: '- - Thân động cơ (cylinder block)',
    Unit: '84099972',
  },
  {
    HsCode: '98181930',
    Description: '- - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: '84099973',
  },
  {
    HsCode: '98181940',
    Description: '- - Ống xi lanh khác',
    Unit: '84099974',
  },
  {
    HsCode: '98181950',
    Description: '- - Quy lát và nắp quy lát',
    Unit: '84099975',
  },
  {
    HsCode: '98181960',
    Description: '- - Piston, có đường kính ngoài từ 50 mm trở lên nhưng không quá 155 mm',
    Unit: '84099976',
  },
  {
    HsCode: '98181970',
    Description: '- - Piston khác',
    Unit: '84099977',
  },
  {
    HsCode: '98181990',
    Description: '- - Loại khác',
    Unit: '84099979',
  },
  {
    HsCode: '981820',
    Description: '- Bơm chất lỏng, không lắp dụng cụ đo lường:',
    Unit: '',
  },
  {
    HsCode: '98182010',
    Description: '- - Bơm nước bằng tay',
    Unit: '84132010',
  },
  {
    HsCode: '',
    Description: '- Bơm nước hoặc bơm nhiên liệu, dùng cho động cơ đốt trong kiểu piston:',
    Unit: '',
  },
  {
    HsCode: '98182021',
    Description: '- - Loại ly tâm',
    Unit: '84133051',
  },
  {
    HsCode: '98182021',
    Description: '- - Loại ly tâm',
    Unit: '84133052',
  },
  {
    HsCode: '98182029',
    Description: '- - Loại khác, trừ loại chuyển động tịnh tiến hoặc chuyển động quay',
    Unit: '84133090',
  },
  {
    HsCode: '',
    Description: '- Bơm ly tâm khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Bơm nước một tầng, một chiều hút, trục ngang được truyền động bằng dây đai hoặc khớp nối trực tiếp, trừ loại bơm đồng trục với động cơ:',
    Unit: '',
  },
  {
    HsCode: '98182031',
    Description: '- - - Với đường kính cửa hút không quá 200mm',
    Unit: '84137011',
  },
  {
    HsCode: '98182039',
    Description: '- - - Loại khác',
    Unit: '84137019',
  },
  {
    HsCode: '98182090',
    Description: '- Bộ phận của bơm thuộc phân nhóm 8413.70.11 và 8413.70.19',
    Unit: '84139130',
  },
  {
    HsCode: '981821',
    Description:
      '- Quạt khác có công suất trên 125kW, trừ quạt bàn, quạt sàn, quạt tường, quạt cửa sổ, quạt trần hoặc quạt mái, có động cơ điện gắn liền với công suất không quá 125 W:',
    Unit: '',
  },
  {
    HsCode: '98182110',
    Description: '- - Máy thổi khí',
    Unit: '84145950',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98182121',
    Description: '- - - Có lưới bảo vệ',
    Unit: '84145991',
  },
  {
    HsCode: '98182129',
    Description: '- - - Loại khác',
    Unit: '84145999',
  },
  {
    HsCode: '981822',
    Description: '- Máy điều hoà không khí, sử dụng cho xe chạy trên đường ray:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Kèm theo các bộ phận làm lạnh và một van đảo chiều chu trình nóng lạnh (bơm nhiệt có đảo chiều):',
    Unit: '',
  },
  {
    HsCode: '98182211',
    Description: '- - - Công suất làm mát không quá 26,38 kW',
    Unit: '84158121',
  },
  {
    HsCode: '98182219',
    Description: '- - - Loại khác',
    Unit: '84158129',
  },
  {
    HsCode: '98182220',
    Description:
      '- - Máy điều hoà không khí, loại khác, có kèm theo bộ phận làm lạnh, sử dụng cho xe chạy trên đường ray, có công suất làm mát trên 26,38 kW',
    Unit: '84158229',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98182231',
    Description:
      '- - - Của máy có công suất làm lạnh trên 26,38 kW nhưng không quá 52,75 kW, có tốc độ dòng khí đi qua mối dàn bay hơi không quá 67,96 m3/phút',
    Unit: '84159036',
  },
  {
    HsCode: '98182239',
    Description:
      '- - - Của máy có công suất làm lạnh trên 52,75 kW, có tốc độ dòng khí đi qua mối dàn bay hơi không quá 67,96 m3/phút',
    Unit: '84159046',
  },
  {
    HsCode: '98182300',
    Description:
      '- Thiết bị làm lạnh hoặc đông lạnh khác, trừ: thiết bị làm lạnh đồ uống, nước uống, thiết bị làm lạnh nước có công suất trên 21,10 kW và thiết bị sản xuất đá vảy',
    Unit: '84186990',
  },
  {
    HsCode: '981824',
    Description: '- Bộ phận trao đổi nhiệt, trừ tháp làm mát:',
    Unit: '',
  },
  {
    HsCode: '98182410',
    Description: '- - - Hoạt động bằng điện',
    Unit: '84195091',
  },
  {
    HsCode: '98182410',
    Description: '- - - Hoạt động bằng điện',
    Unit: '84195020',
  },
  {
    HsCode: '98182490',
    Description: '- - - Không hoạt động bằng điện',
    Unit: '84195020',
  },
  {
    HsCode: '98182490',
    Description: '- - - Không hoạt động bằng điện',
    Unit: '84195092',
  },
  {
    HsCode: '98182500',
    Description: '- Máy xử lý dữ liệu tự động khác, trừ máy tính cá nhân, máy tính xách tay',
    Unit: '84714190',
  },
  {
    HsCode: '',
    Description: '- Van an toàn hay van xả:',
    Unit: '',
  },
  {
    HsCode: '98182610',
    Description: '- - Bằng plastic, có đường kính trong từ 1cm đến 2,5 cm',
    Unit: '84814030',
  },
  {
    HsCode: '98182690',
    Description: '- - Loại khác, trừ loại bằng đồng hoặc hợp kim đồng với đường kính trong từ 2,5 cm trở xuống',
    Unit: '84814090',
  },
  {
    HsCode: '98182700',
    Description: '- Ổ đũa côn, kể cả cụm linh kiện vành côn và đũa côn',
    Unit: '84822000',
  },
  {
    HsCode: '98182800',
    Description: '- Trục truyền động (kể cả trục cam và trục khuỷu) và tay biên khác',
    Unit: '84831090',
  },
  {
    HsCode: '98182900',
    Description:
      '- Bộ bánh răng và cụm bánh răng ăn khớp khác, trừ bánh xe có răng, đĩa xích và các bộ phận truyền chuyển động ở dạng riêng biệt; vít bi hoặc vít đũa; hộp số và các cơ cấu điều tốc khác, kể cả bộ biến đổi mô men xoắn',
    Unit: '84834090',
  },
  {
    HsCode: '98183000',
    Description:
      '- Đệm và gioăng tương tự làm bằng tấm kim loại mỏng kết hợp với các vật liệu dệt khác hoặc bằng hai hay nhiều lớp kim loại',
    Unit: '84841000',
  },
  {
    HsCode: '98183100',
    Description:
      '- Tổ máy phát điện với động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện, công suất trên 100 kVA nhưng không quá 10.000 kVA',
    Unit: '85022030',
  },
  {
    HsCode: '98183200',
    Description:
      '- Bộ phận kể cả tấm mạch in đã lắp ráp của phích cắm điện thoại; đầu nối và các bộ phận dùng để nối cho dây và cáp; đầu dò dẹt dưới dạng miếng lát nhiều lớp (wafer prober), dùng cho điện áp không quá 1.000 V',
    Unit: '85389011',
  },
  {
    HsCode: '98183200',
    Description:
      '- Bộ phận kể cả tấm mạch in đã lắp ráp của phích cắm điện thoại; đầu nối và các bộ phận dùng để nối cho dây và cáp; đầu dò dẹt dưới dạng miếng lát nhiều lớp (wafer prober), dùng cho điện áp không quá 1.000 V',
    Unit: '85423100',
  },
  {
    HsCode: '98183200',
    Description:
      '- Bộ phận kể cả tấm mạch in đã lắp ráp của phích cắm điện thoại; đầu nối và các bộ phận dùng để nối cho dây và cáp; đầu dò dẹt dưới dạng miếng lát nhiều lớp (wafer prober), dùng cho điện áp không quá 1.000 V',
    Unit: '85423200',
  },
  {
    HsCode: '98183200',
    Description:
      '- Bộ phận kể cả tấm mạch in đã lắp ráp của phích cắm điện thoại; đầu nối và các bộ phận dùng để nối cho dây và cáp; đầu dò dẹt dưới dạng miếng lát nhiều lớp (wafer prober), dùng cho điện áp không quá 1.000 V',
    Unit: '85423900',
  },
  {
    HsCode: '98183300',
    Description: '- Chổi than',
    Unit: '85452000',
  },
  {
    HsCode: '98190000',
    Description: 'Vật tư, thiết bị nhập khẩu để sản xuất sản phẩm cơ khí trọng điểm.',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '98200000',
    Description: 'Các phụ tùng và vật tư dùng cho máy bay.',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '9821',
    Description: 'Bộ linh kiện CKD và bộ linh kiện không đồng bộ của ô tô.',
    Unit: '',
  },
  {
    HsCode: '982130',
    Description: '- Của xe có động cơ dùng để chở hàng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của xe tự đổ được thiết kế để sử dụng trên các loại đường không phải đường quốc lộ:',
    Unit: '',
  },
  {
    HsCode: '98213014',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87041034',
  },
  {
    HsCode: '98213015',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87041035',
  },
  {
    HsCode: '98213015',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87041036',
  },
  {
    HsCode: '',
    Description: '- - Của loại khác, có động cơ đốt trong kiểu piston cháy do nén (diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '98213025',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87042329',
  },
  {
    HsCode: '98213025',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87044329',
  },
  {
    HsCode: '98213026',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87042366',
  },
  {
    HsCode: '98213026',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87042369',
  },
  {
    HsCode: '98213026',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87044369',
  },
  {
    HsCode: '',
    Description: '- - Của loại khác, có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện:',
    Unit: '',
  },
  {
    HsCode: '98213035',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87043269',
  },
  {
    HsCode: '98213035',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87045269',
  },
  {
    HsCode: '98213036',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87043286',
  },
  {
    HsCode: '98213036',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87045286',
  },
  {
    HsCode: '98213036',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87043289',
  },
  {
    HsCode: '98213036',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87045289',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98213094',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87049094',
  },
  {
    HsCode: '98213094',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87046093',
  },
  {
    HsCode: '9822',
    Description:
      'Vật tư, thiết bị phục vụ dự án chế tạo thiết bị sản xuất vật liệu xây không nung nhẹ và sản xuất gạch xi măng-cốt liệu công suất từ 10 triệu viên quy tiêu chuẩn/năm trở lên.',
    Unit: '',
  },
  {
    HsCode: '98221000',
    Description: '- Xe nâng, xe xúc lật',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '98222000',
    Description: '- Máy ép thủy lực',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '98223000',
    Description: '- Máy cắt',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '98224000',
    Description: '- Thiết bị chưng áp',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '98225000',
    Description: '- Máy dỡ gạch',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '98229000',
    Description: '- Bột nhôm (hàm lượng Al>85%)',
    Unit: 'Tùy theo bản chất mặt hàng để phân loại vào mã hàng ghi tại các chương khác nhau quy định tại Mục I Phụ lục II',
  },
  {
    HsCode: '98230000',
    Description: 'Xe thiết kế chở tiền.',
    Unit: '87059090',
  },
  {
    HsCode: '98250000',
    Description: 'Chất làm đầy da; Kem hỗ trợ chức năng bảo vệ da, gel làm giảm sẹo.',
    Unit: '33049990',
  },
  {
    HsCode: '98260000',
    Description: 'Vải mành nylon 1680 D/2 và 1890 D/2.',
    Unit: '59021091',
  },
  {
    HsCode: '9827',
    Description:
      'Ống chống và ống khai thác bằng thép đúc có ren, đường kính 2 – 3/8 inch đến 20 inch, sử dụng cho khoan dầu hoặc khí.',
    Unit: '',
  },
  {
    HsCode: '982710',
    Description: '- Loại bằng thép không gỉ:',
    Unit: '',
  },
  {
    HsCode: '98271010',
    Description: '- - - Ống chống và ống có giới hạn chảy dưới 80.000 psi và không có ren ở đầu ống',
    Unit: '73042410',
  },
  {
    HsCode: '98271090',
    Description: '- - - Loại khác',
    Unit: '73042420',
  },
  {
    HsCode: '98271090',
    Description: '- - - Loại khác',
    Unit: '73042430',
  },
  {
    HsCode: '982790',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98279010',
    Description: '- - - Ống chống và ống có giới hạn chảy dưới 80.000 psi và không có ren ở đầu ống',
    Unit: '73042910',
  },
  {
    HsCode: '98279090',
    Description: '- - - Loại khác',
    Unit: '73042920',
  },
  {
    HsCode: '98279090',
    Description: '- - - Loại khác',
    Unit: '73042930',
  },
  {
    HsCode: '98280000',
    Description:
      'Ống chống bằng thép hàn có đầu nối đi kèm, đường kính từ 20 inch đến 36 inch, sử dụng cho khoan dầu hoặc khí.',
    Unit: '73052000',
  },
  {
    HsCode: '9829',
    Description:
      'Ống chống và ống khai thác bằng thép hàn có ren, đường kính 2 – 3/8 inch đến 20 inch, sử dụng cho khoan dầu hoặc khí.',
    Unit: '',
  },
  {
    HsCode: '98291000',
    Description: '- Hàn, bằng thép không gỉ',
    Unit: '73062100',
  },
  {
    HsCode: '98292000',
    Description: '- Loại khác',
    Unit: '73062900',
  },
  {
    HsCode: '98300000',
    Description: 'Dây đồng có kích thước mặt cắt ngang tối đa trên 6 mm nhưng không quá 8mm.',
    Unit: '74081120',
  },
  {
    HsCode: '9832',
    Description: 'Xe cần cẩu thủy lực.',
    Unit: '',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87042129',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87044129',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87042229',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87044229',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87042251',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87044251',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87042259',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87044259',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87042329',
  },
  {
    HsCode: '98321000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel)',
    Unit: '87044329',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87043129',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87045129',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87043229',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87045229',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87043248',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87045248',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87043249',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87045249',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87043269',
  },
  {
    HsCode: '98329000',
    Description: '- Loại có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện',
    Unit: '87045269',
  },
  {
    HsCode: '98330000',
    Description: 'Bộ quầy tủ để làm thủ tục tại sân bay, nhà ga.',
    Unit: '94036090',
  },
  {
    HsCode: '9834',
    Description:
      'Nguyên liệu, vật tư, linh kiện và bộ phận phụ trợ sản xuất, lắp ráp sản phẩm công nghệ thông tin trọng điểm.',
    Unit: '',
  },
  {
    HsCode: '98341100',
    Description: '- Bàn phím làm bằng cao su, khắc lase, phủ PU, nhiệt độ chịu đựng 150 độ C',
    Unit: '40169999',
  },
  {
    HsCode: '983412',
    Description: '- Các sản phẩm khác bằng sắt hoặc thép:',
    Unit: '',
  },
  {
    HsCode: '98341210',
    Description: '- - Ốc vít bằng thép, INOX 304, ren tiêu chuẩn 1, mạ chống rỉ, đường kính dưới 1.4 mm',
    Unit: '73181510',
  },
  {
    HsCode: '98341210',
    Description: '- - Ốc vít bằng thép, INOX 304, ren tiêu chuẩn 1, mạ chống rỉ, đường kính dưới 1.4 mm',
    Unit: '73181590',
  },
  {
    HsCode: '98341290',
    Description: '- - Nguyên liệu, vật tư, linh kiện và bộ phận phụ trợ để sản xuất các sản phẩm CNTT trọng điểm',
    Unit: '73269020',
  },
  {
    HsCode: '98341290',
    Description: '- - Nguyên liệu, vật tư, linh kiện và bộ phận phụ trợ để sản xuất các sản phẩm CNTT trọng điểm',
    Unit: '73269099',
  },
  {
    HsCode: '98341300',
    Description: '- Thiếc hàn dạng thanh, không chì, phù hợp với tiêu chuẩn RoHS',
    Unit: '80030010',
  },
  {
    HsCode: '98341400',
    Description:
      '- Thiếc hàn dạng dây, không chì, có lõi bằng chất dễ chảy, đường kính nhỏ hơn hoặc bằng 1.2 mm, phù hợp với tiêu chuẩn RoHS',
    Unit: '83119000',
  },
  {
    HsCode: '98341500',
    Description: '- Môtơ rung cho điện thoại, đường kính ≤ 30mm, công suất ≤ 0.5 W',
    Unit: '85011049',
  },
  {
    HsCode: '98341600',
    Description: '- Mô tơ, công suất không quá 5 W',
    Unit: '85011022',
  },
  {
    HsCode: '98341600',
    Description: '- Mô tơ, công suất không quá 5 W',
    Unit: '85011029',
  },
  {
    HsCode: '98341700',
    Description: '- Cuộn biến áp dùng cho các thiết bị điện tử, viễn thông, công suất danh định nhỏ hơn 0,1 kVA',
    Unit: '85043193',
  },
  {
    HsCode: '98341700',
    Description: '- Cuộn biến áp dùng cho các thiết bị điện tử, viễn thông, công suất danh định nhỏ hơn 0,1 kVA',
    Unit: '85043199',
  },
  {
    HsCode: '98341800',
    Description: '- Pin lithium dày ≤ 7 mm, dài ≤ 100 mm, rộng ≤ 100 mm',
    Unit: '85065000',
  },
  {
    HsCode: '98341900',
    Description: '- Màn hiển thị tinh thể có kích thước ≤ 7 inch',
    Unit: '85177932',
  },
  {
    HsCode: '98342000',
    Description: '- Đầu kết nối USB mini, sử dụng cho nạp pin và truyền dữ liệu từ điện thoại đến máy tính PC',
    Unit: '85369093',
  },
  {
    HsCode: '98342100',
    Description: '- Cầu chì dùng cho các thiết bị điện tử, có giới hạn dòng danh định không quá 1,5 A',
    Unit: '85361012',
  },
  {
    HsCode: '98342100',
    Description: '- Cầu chì dùng cho các thiết bị điện tử, có giới hạn dòng danh định không quá 1,5 A',
    Unit: '85361092',
  },
  {
    HsCode: '98342200',
    Description: '- Rơ le bán dẫn/Rơle điện từ có điện áp không quá 28 V',
    Unit: '85364191',
  },
  {
    HsCode: '98342300',
    Description:
      '- Đầu cắm tín hiệu hình, tiếng, đầu cắm đèn chân không dùng cho máy thu hình, có dòng điện nhỏ hơn hoặc bằng 1,5 A',
    Unit: '85366923',
  },
  {
    HsCode: '98342400',
    Description: '- Giắc cắm, đầu nối cổng nối USB, giắc mạng để nối dây dẫn tín hiệu',
    Unit: '85369099',
  },
  {
    HsCode: '98342400',
    Description: '- Giắc cắm, đầu nối cổng nối USB, giắc mạng để nối dây dẫn tín hiệu',
    Unit: '85423900',
  },
  {
    HsCode: '98342500',
    Description: '- Dây nguồn điện bọc nhựa có đầu nối, có đường kính lõi nhỏ hơn hoặc bằng 5 mm',
    Unit: '85444294',
  },
  {
    HsCode: '98342600',
    Description: '- Cáp nối truyền dữ liệu dạng dẹt từ 2 sợi trở lên',
    Unit: '85444291',
  },
  {
    HsCode: '98342600',
    Description: '- Cáp nối truyền dữ liệu dạng dẹt từ 2 sợi trở lên',
    Unit: '85444292',
  },
  {
    HsCode: '98342700',
    Description: '- Cáp dùng cho máy thông tin',
    Unit: '85444929',
  },
  {
    HsCode: '98342800',
    Description: '- Biến áp nguồn, biến áp tín hiệu, biến áp cao áp để sản xuất các sản phẩm CNTT trọng điểm',
    Unit: '85043429',
  },
  {
    HsCode: '98342900',
    Description: '- Pin và bộ pin bằng liti để sản xuất các sản phẩm CNTT trọng điểm',
    Unit: '85065000',
  },
  {
    HsCode: '98343000',
    Description: '- Nguyên liệu, vật tư, linh kiện và bộ phận phụ trợ để sản xuất các sản phẩm CNTT trọng điểm',
    Unit: '85366999',
  },
  {
    HsCode: '98343100',
    Description: '- Bàn điều khiển camera',
    Unit: '85371019',
  },
  {
    HsCode: '98343200',
    Description: '- Cáp nguồn đã gắn đấu nối cho mạch điện tử 220 V, bọc plastic',
    Unit: '85444296',
  },
  {
    HsCode: '98343300',
    Description:
      '- Bộ phận khác, trừ bộ phận của bộ điều khiển, bộ thích ứng, thiết bị truyền dẫn, tấm mạch in, ăng ten',
    Unit: '85177992',
  },
  {
    HsCode: '98343300',
    Description:
      '- Bộ phận khác, trừ bộ phận của bộ điều khiển, bộ thích ứng, thiết bị truyền dẫn, tấm mạch in, ăng ten',
    Unit: '85423100',
  },
  {
    HsCode: '98343300',
    Description:
      '- Bộ phận khác, trừ bộ phận của bộ điều khiển, bộ thích ứng, thiết bị truyền dẫn, tấm mạch in, ăng ten',
    Unit: '85423200',
  },
  {
    HsCode: '98343300',
    Description:
      '- Bộ phận khác, trừ bộ phận của bộ điều khiển, bộ thích ứng, thiết bị truyền dẫn, tấm mạch in, ăng ten',
    Unit: '85423300',
  },
  {
    HsCode: '98343300',
    Description:
      '- Bộ phận khác, trừ bộ phận của bộ điều khiển, bộ thích ứng, thiết bị truyền dẫn, tấm mạch in, ăng ten',
    Unit: '85423900',
  },
  {
    HsCode: '98343400',
    Description: '- Micro',
    Unit: '85181090',
  },
  {
    HsCode: '98343500',
    Description: '- Tai nghe có hoặc không nối với một bộ micro, và các bộ gồm một micro và một hoặc nhiều loa',
    Unit: '85183020',
  },
  {
    HsCode: '98343600',
    Description: '- Bộ micro, loa kết hợp trừ của điện thoại di động hoặc điện thoại dùng cho mạng không dây khác',
    Unit: '85183059',
  },
  {
    HsCode: '98343700',
    Description: '- Đèn Flash',
    Unit: '90066900',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219041',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219042',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219043',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219049',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219050',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219060',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219070',
  },
  {
    HsCode: '98343800',
    Description: '- Tấm, phiến, màng, lá và dải khác, bằng plastic dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39219090',
  },
  {
    HsCode: '98343900',
    Description:
      '- Các sản phẩm bằng plastic và các sản phẩm bằng các vật liệu khác của các nhóm từ 39.01 đến 39.14 dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '39269059',
  },
  {
    HsCode: '98344000',
    Description: '- Dây bện tao, thừng và cáp dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '73121030',
  },
  {
    HsCode: '98344000',
    Description: '- Dây bện tao, thừng và cáp dùng để sản xuất sản phẩm công nghệ thông tin',
    Unit: '73121099',
  },
  {
    HsCode: '98344100',
    Description:
      '- Các loại đầu nối, giắc nối có 1 đầu là các chân cắm được dùng để cắm trực tiếp lên tấm mạch in, 1 đầu là đầu chờ để tiếp nối với các dây dẫn tín hiệu, dùng cho dòng điện dưới 16A',
    Unit: '85366992',
  },
  {
    HsCode: '98350000',
    Description: 'Natri hydroxit dạng dung dịch nước có hàm lượng NaOH > 42%.',
    Unit: '28151200',
  },
  {
    HsCode: '9836',
    Description:
      'Khung gầm đã gắn động cơ, có buồng lái (xe ô tô sát xi, có buồng lái), có tổng trọng lượng có tải trên 20 tấn nhưng không quá 45 tấn.',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- Của xe có động cơ dùng để vận tải hàng hóa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Của xe tự đổ được thiết kế để sử dụng trên các loại đường không phải đường quốc lộ:',
    Unit: '',
  },
  {
    HsCode: '98361010',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn',
    Unit: '87041034',
  },
  {
    HsCode: '98361090',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87041035',
  },
  {
    HsCode: '98361090',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn',
    Unit: '87041036',
  },
  {
    HsCode: '',
    Description: '- - Của loại khác, có động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '983620',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn:',
    Unit: '',
  },
  {
    HsCode: '98362010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87042321',
  },
  {
    HsCode: '98362010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87044321',
  },
  {
    HsCode: '98362020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87042322',
  },
  {
    HsCode: '98362020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87044322',
  },
  {
    HsCode: '98362030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87042323',
  },
  {
    HsCode: '98362030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87044323',
  },
  {
    HsCode: '98362040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87042324',
  },
  {
    HsCode: '98362040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87044324',
  },
  {
    HsCode: '98362050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87042325',
  },
  {
    HsCode: '98362050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87044325',
  },
  {
    HsCode: '98362090',
    Description: '- - - - Loại khác',
    Unit: '87042329',
  },
  {
    HsCode: '98362090',
    Description: '- - - - Loại khác',
    Unit: '87044329',
  },
  {
    HsCode: '983630',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '98363010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87042361',
  },
  {
    HsCode: '98363010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87044361',
  },
  {
    HsCode: '98363020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87042362',
  },
  {
    HsCode: '98363020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87044362',
  },
  {
    HsCode: '98363030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87042363',
  },
  {
    HsCode: '98363030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87044363',
  },
  {
    HsCode: '98363040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87042364',
  },
  {
    HsCode: '98363040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87044364',
  },
  {
    HsCode: '98363050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87042365',
  },
  {
    HsCode: '98363050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87044365',
  },
  {
    HsCode: '98363060',
    Description: '- - - - Ô tô tự đổ',
    Unit: '87042366',
  },
  {
    HsCode: '98363060',
    Description: '- - - - Ô tô tự đổ',
    Unit: '87044369',
  },
  {
    HsCode: '98363090',
    Description: '- - - - Loại khác',
    Unit: '87042369',
  },
  {
    HsCode: '98363090',
    Description: '- - - - Loại khác',
    Unit: '87044369',
  },
  {
    HsCode: '',
    Description: '- - Của loại khác, có động cơ đốt trong kiểu piston đốt cháy bằng tia lửa điện:',
    Unit: '',
  },
  {
    HsCode: '983640',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 24 tấn:',
    Unit: '',
  },
  {
    HsCode: '98364010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87043261',
  },
  {
    HsCode: '98364010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87045261',
  },
  {
    HsCode: '98364020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87043262',
  },
  {
    HsCode: '98364020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87045262',
  },
  {
    HsCode: '98364030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87043263',
  },
  {
    HsCode: '98364030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87045263',
  },
  {
    HsCode: '98364040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87043264',
  },
  {
    HsCode: '98364040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87045264',
  },
  {
    HsCode: '98364050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87043265',
  },
  {
    HsCode: '98364050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87045265',
  },
  {
    HsCode: '98364090',
    Description: '- - - - Loại khác',
    Unit: '87043269',
  },
  {
    HsCode: '98364090',
    Description: '- - - - Loại khác',
    Unit: '87045269',
  },
  {
    HsCode: '983650',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 24 tấn nhưng không quá 45 tấn:',
    Unit: '',
  },
  {
    HsCode: '98365010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87043281',
  },
  {
    HsCode: '98365010',
    Description: '- - - - Ô tô đông lạnh',
    Unit: '87045281',
  },
  {
    HsCode: '98365020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87043282',
  },
  {
    HsCode: '98365020',
    Description: '- - - - Ô tô thu gom phế thải có bộ phận nén phế thải',
    Unit: '87045282',
  },
  {
    HsCode: '98365030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87043283',
  },
  {
    HsCode: '98365030',
    Description: '- - - - Ô tô xi téc; ô tô chở xi măng kiểu bồn',
    Unit: '87045283',
  },
  {
    HsCode: '98365040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87043284',
  },
  {
    HsCode: '98365040',
    Description: '- - - - Ô tô bọc thép để chở hàng hóa có giá trị',
    Unit: '87045284',
  },
  {
    HsCode: '98365050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87043285',
  },
  {
    HsCode: '98365050',
    Description: '- - - - Ô tô chở bùn có thùng rời nâng hạ được',
    Unit: '87045285',
  },
  {
    HsCode: '98365060',
    Description: '- - - - Ô tô tự đổ',
    Unit: '87043286',
  },
  {
    HsCode: '98365060',
    Description: '- - - - Ô tô tự đổ',
    Unit: '87045286',
  },
  {
    HsCode: '98365090',
    Description: '- - - - Loại khác',
    Unit: '87043289',
  },
  {
    HsCode: '98365090',
    Description: '- - - - Loại khác',
    Unit: '87045289',
  },
  {
    HsCode: '',
    Description: '- - Của loại khác:',
    Unit: '',
  },
  {
    HsCode: '98369000',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 45 tấn',
    Unit: '87049094',
  },
  {
    HsCode: '98369000',
    Description: '- - - Khối lượng toàn bộ theo thiết kế trên 20 tấn nhưng không quá 45 tấn',
    Unit: '87046093',
  },
  {
    HsCode: '98370000',
    Description: 'Hạt nhựa PolyPropylene dạng nguyên sinh.',
    Unit: '39021040',
  },
  {
    HsCode: '98370000',
    Description: 'Hạt nhựa PolyPropylene dạng nguyên sinh.',
    Unit: '39021090',
  },
  {
    HsCode: '98380000',
    Description: 'Dầu diesel sinh học (B5, B10).',
    Unit: '27102000',
  },
  {
    HsCode: '9839',
    Description: 'Thép không hợp kim, dạng thanh và dạng cuộn cuốn không đều, được cán nóng.',
    Unit: '',
  },
  {
    HsCode: '98391000',
    Description: '- Dạng thanh và cuộn, loại có đường kính mặt cắt ngang hình tròn dưới 14 mm',
    Unit: '72139190',
  },
  {
    HsCode: '98392000',
    Description: '- Dạng thanh và cuộn, loại khác',
    Unit: '72139990',
  },
  {
    HsCode: '9840',
    Description: 'Nguyên liệu, vật tư, linh kiện nhập khẩu để sản xuất các sản phẩm cao su kỹ thuật.',
    Unit: '',
  },
  {
    HsCode: '98401100',
    Description: '- Lõi kim loại thép Metal segment (14x24) mm',
    Unit: '73182910',
  },
  {
    HsCode: '98401100',
    Description: '- Lõi kim loại thép Metal segment (14x24) mm',
    Unit: '73182990',
  },
  {
    HsCode: '98401200',
    Description: '- Vòng kim loại thép',
    Unit: '73182200',
  },
  {
    HsCode: '98401300',
    Description: '- Vòng đệm bằng đồng dùng gắn vào sản phẩm cao su (10,13x0,8) mm',
    Unit: '74152100',
  },
  {
    HsCode: '98401400',
    Description: '- Lõi nhôm lót trong mạ niken (85x38) mm dùng trong công nghiệp sản xuất chuỗi cách điện',
    Unit: '76169990',
  },
  {
    HsCode: '98401500',
    Description: '- Keo dán adhesive chemlok 205',
    Unit: '35069900',
  },
  {
    HsCode: '98401600',
    Description: '- Vòng đệm thép #304 dùng để gắn vào sản phẩm cao su',
    Unit: '73182200',
  },
  {
    HsCode: '98401700',
    Description: '- Vòng đệm vênh bằng thép #304-S dùng để gắn vào sản phẩm cao su',
    Unit: '73182100',
  },
  {
    HsCode: '98401800',
    Description: '-Vòng nhôm 3077 (Mini) (99,71x84,73x14,30) mm dùng sản xuất sản phẩm cao su',
    Unit: '76169990',
  },
  {
    HsCode: '98401900',
    Description: '- Axit stearic',
    Unit: '38231100',
  },
  {
    HsCode: '98402000',
    Description: '- Cao lanh (bột đá) dùng sản xuất sản phẩm cao su',
    Unit: '68041000',
  },
  {
    HsCode: '98402100',
    Description: '- Đệm cao su 0-35 b dùng gắn vào chụp đệm cao su dùng trên mái nhà',
    Unit: '40169390',
  },
  {
    HsCode: '98402200',
    Description: '- Cao su lưu hóa dạng tấm',
    Unit: '40081900',
  },
  {
    HsCode: '98402300',
    Description: '- Muội carbon black N330,770,660 dạng hạt, dùng để sản xuất sản phẩm cao su',
    Unit: '28030090',
  },
  {
    HsCode: '98402400',
    Description: '- Tấm lưới bằng nhôm phủ cao su (410x490) mm',
    Unit: '76169990',
  },
  {
    HsCode: '98402500',
    Description: '- Dầu Parafin - Paraffinic Oil dùng trong sản xuất sản phẩm cao su',
    Unit: '27101990',
  },
  {
    HsCode: '98402600',
    Description: '- Hợp chất hóa dẻo Sunthene 480 dạng lỏng dùng để sản xuất sản phẩm cao su',
    Unit: '38122000',
  },
  {
    HsCode: '98402700',
    Description: '- Oxít Kẽm Zinc Oxide white seal 99.8% (ZNO) dạng bột màu trắng dùng để sản xuất sản phẩm cao su',
    Unit: '28170020',
  },
  {
    HsCode: '98402800',
    Description: '- Vòng kim lọai thép (129.8x2.8) mm dùng để gắn vào sản phẩm cao su',
    Unit: '73182200',
  },
  {
    HsCode: '9841',
    Description: 'Nhà lắp ghép trồng cây dùng cho sản xuất nông nghiệp.',
    Unit: '',
  },
  {
    HsCode: '98411000',
    Description: '- Bằng gỗ',
    Unit: '94061090',
  },
  {
    HsCode: '98412000',
    Description: '- Bằng sắt hoặc thép',
    Unit: '94069030',
  },
  {
    HsCode: '98412000',
    Description: '- Bằng sắt hoặc thép',
    Unit: '94062090',
  },
  {
    HsCode: '98413000',
    Description: '- Bằng plastic hoặc bằng nhôm',
    Unit: '94069020',
  },
  {
    HsCode: '98414000',
    Description: '- Bằng xi măng, bằng bê tông hoặc đá nhân tạo',
    Unit: '94069040',
  },
  {
    HsCode: '98415000',
    Description: '- Loại khác',
    Unit: '94069090',
  },
  {
    HsCode: '98420000',
    Description: 'Dung môi N-Hexan dùng trong sản xuất khô dầu đậu tương và dầu thực vật, cám gạo trích ly và dầu cám.',
    Unit: '27101270',
  },
  {
    HsCode: '9844',
    Description:
      'Bộ linh kiện rời động bộ và không đồng bộ của ô tô đầu kéo và của rơ-moóc, sơ mi rơ-moóc để sản xuất, lắp ráp ô tô đầu kéo và rơ-moóc, sơ mi rơ-moóc.',
    Unit: '',
  },
  {
    HsCode: '984410',
    Description: '- Của máy kéo (trừ máy kéo thuộc nhóm 87.09):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98441090',
    Description: '- - - Loại khác',
    Unit: '87019190',
  },
  {
    HsCode: '98441090',
    Description: '- - - Loại khác',
    Unit: '87019290',
  },
  {
    HsCode: '98441090',
    Description: '- - - Loại khác',
    Unit: '87019390',
  },
  {
    HsCode: '98441090',
    Description: '- - - Loại khác',
    Unit: '87019490',
  },
  {
    HsCode: '98441090',
    Description: '- - - Loại khác',
    Unit: '87019590',
  },
  {
    HsCode: '984420',
    Description: '- Của rơ-moóc và sơ mi rơ-moóc:',
    Unit: '',
  },
  {
    HsCode: '98442010',
    Description: '- - Rơ-moóc và sơ mi rơ-moóc loại nhà lưu động, dùng làm nhà ở hoặc cắm trại',
    Unit: '87161000',
  },
  {
    HsCode: '98442020',
    Description: '- - Rơ-moóc và sơ mi rơ-moóc loại tự bốc hoặc dỡ hàng dùng trong nông nghiệp',
    Unit: '87162000',
  },
  {
    HsCode: '',
    Description: '- - Rơ-moóc và sơ mi rơ-moóc khác dùng để vận chuyển hàng hóa:',
    Unit: '',
  },
  {
    HsCode: '98442031',
    Description: '- - - Rơ-moóc và sơ mi rơ-moóc gắn xi téc',
    Unit: '87163100',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98442032',
    Description: '- - - - Rơ-moóc và sơ mi rơ-moóc dùng trong nông nghiệp',
    Unit: '87163940',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98442033',
    Description: '- - - - - Có khối lượng hàng chuyên chở theo thiết kế (payload) trên 200 tấn',
    Unit: '87163991',
  },
  {
    HsCode: '98442039',
    Description: '- - - - - Loại khác',
    Unit: '87163999',
  },
  {
    HsCode: '98442090',
    Description: '- - Rơ-moóc và sơ mi rơ-moóc khác',
    Unit: '87164000',
  },
  {
    HsCode: '9845',
    Description:
      'Phụ tùng, linh kiện ô tô nhập khẩu để triển khai Quyết định số 229/QĐ-TTg ngày 4/2/2016 về cơ chế, chính sách thực hiện Chiến lược và Quy hoạch phát triển ngành công nghiệp ô tô.',
    Unit: '',
  },
  {
    HsCode: '98452000',
    Description:
      '- Kính an toàn, kính dán an toàn (dày từ 6 đến 12 mm/kính dán nhiều lớp để tăng độ bền, có thể hạn chế tia UV), loại dùng cho ô tô tải và ô tô chở khách thuộc nhóm 87.02 và 87.04',
    Unit: '70072110',
  },
  {
    HsCode: '98453000',
    Description: '- Ống xi lanh sử dụng cho dẫn dầu hộp số ô tô',
    Unit: '84099944',
  },
  {
    HsCode: '98454000',
    Description: '- Bạc nhíp dùng cho xe từ 1,25 tấn trở lên',
    Unit: '84833030',
  },
  {
    HsCode: '98455000',
    Description:
      '- Bạc phụ tùng dùng cho bộ bánh răng và cụm bánh răng khớp, trừ bánh xe có răng, đĩa xích và các bộ phận truyền chuyển động ở dạng riêng biệt; vít bi hoặc vít đũa; hộp số và các cơ cấu điều tốc khác, kể cả bộ biến đổi mô men xoắn',
    Unit: '84834040',
  },
  {
    HsCode: '98456000',
    Description: '- Ống dầu phanh',
    Unit: '87083029',
  },
  {
    HsCode: '98458000',
    Description: '- Hộp giảm tốc trục vít - bánh vít',
    Unit: '87084029',
  },
  {
    HsCode: '',
    Description: '- Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98459010',
    Description: '- - Ống dẫn sử dụng cho dẫn dầu hộp số ô tô',
    Unit: '87084092',
  },
  {
    HsCode: '98459020',
    Description: '- - Vành bánh xe',
    Unit: '87087032',
  },
  {
    HsCode: '98459090',
    Description: '- - Ống xả',
    Unit: '87089220',
  },
  {
    HsCode: '9846',
    Description: 'Set-top-boxes.',
    Unit: '',
  },
  {
    HsCode: '98460010',
    Description: '- Hoạt động bằng nguồn điện lưới',
    Unit: '85287191',
  },
  {
    HsCode: '98460090',
    Description: '- Loại khác',
    Unit: '85287199',
  },
  {
    HsCode: '98470000',
    Description: 'Ô ngăn hình mạng làm từ vật liệu Nano-composite Polymeric Alloy (Neoweb).',
    Unit: '39269099',
  },
  {
    HsCode: '98480000',
    Description: 'Kính quang học, chưa được gia công về mặt quang học.',
    Unit: '70053010',
  },
  {
    HsCode: '98480000',
    Description: 'Kính quang học, chưa được gia công về mặt quang học.',
    Unit: '70053090',
  },
  {
    HsCode: '9849',
    Description: 'Các mặt hàng linh kiện ô tô nhập khẩu để thực hiện Chương trình ưu đãi thuế.',
    Unit: '',
  },
  {
    HsCode: '984911',
    Description:
      '- Các loại ống, ống dẫn và ống vòi, bằng cao su lưu hóa trừ cao su cứng, có hoặc không kèm theo các phụ kiện để ghép nối (ví dụ, các đoạn nối, khớp, khuỷu, vành đệm):',
    Unit: '',
  },
  {
    HsCode: '98491110',
    Description:
      '- - Ống dẫn nhiên liệu, ống dẫn nhiệt và ống dẫn nước, dùng cho xe gắn động cơ thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.11',
    Unit: '40093191',
  },
  {
    HsCode: '984912',
    Description: '- Lốp loại bơm hơi bằng cao su, chưa qua sử dụng:',
    Unit: '',
  },
  {
    HsCode: '98491210',
    Description:
      '- - Loại sử dụng cho ô tô con (motor car) (kể cả loại ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua)',
    Unit: '40111000',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng cho ô tô khách (buses) hoặc ô tô chở hàng (lorries):',
    Unit: '',
  },
  {
    HsCode: '98491211',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: '40112011',
  },
  {
    HsCode: '98491211',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: '40112012',
  },
  {
    HsCode: '98491211',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: '40112013',
  },
  {
    HsCode: '98491211',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: '40112019',
  },
  {
    HsCode: '98491219',
    Description: '- - - Loại khác',
    Unit: '40112090',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98491290',
    Description: '- - - Loại dùng cho xe thuộc Chương 87',
    Unit: '40119010',
  },
  {
    HsCode: '',
    Description: '- Lốp đắp lại: ',
    Unit: '',
  },
  {
    HsCode: '98491310',
    Description:
      '- - Loại sử dụng cho ô tô con (motor car) (kể cả loại ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua)',
    Unit: '40121100',
  },
  {
    HsCode: '',
    Description: '- - Loại dùng cho ô tô khách hoặc ô tô chở hàng:',
    Unit: '',
  },
  {
    HsCode: '98491311',
    Description: '- - - Chiều rộng không quá 450 mm',
    Unit: '40121210',
  },
  {
    HsCode: '98491319',
    Description: '- - - Loại khác',
    Unit: '40121290',
  },
  {
    HsCode: '984914',
    Description: '- Săm các loại, bằng cao su:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Loại dùng cho ô tô con (motor car) (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua), ô tô khách hoặc ô tô chở hàng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - - Loại dùng cho ô tô con (kể cả ô tô chở người có khoang chở hành lý chung (station wagons) và ô tô đua):',
    Unit: '',
  },
  {
    HsCode: '98491411',
    Description: '- - - - Dùng cho loại lốp có chiều rộng không quá 450 mm',
    Unit: '40131011',
  },
  {
    HsCode: '98491419',
    Description: '- - - - Dùng cho loại lốp có chiều rộng trên 450 mm',
    Unit: '40131019',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng cho ô tô khách hoặc ô tô chở hàng:',
    Unit: '',
  },
  {
    HsCode: '98491421',
    Description: '- - - - Dùng cho loại lốp có chiều rộng không quá 450 mm',
    Unit: '40131021',
  },
  {
    HsCode: '98491429',
    Description: '- - - - Dùng cho loại lốp có chiều rộng trên 450 mm',
    Unit: '40131029',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Loại dùng cho các xe khác thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '98491431',
    Description: '- - - - Phù hợp lắp cho loại lốp có chiều rộng không quá 450 mm',
    Unit: '40139031',
  },
  {
    HsCode: '98491439',
    Description: '- - - - Phù hợp lắp cho loại lốp có chiều rộng trên 450 mm',
    Unit: '40139039',
  },
  {
    HsCode: '984915',
    Description: '- Các sản phẩm khác bằng cao su lưu hóa trừ cao su cứng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Miếng đệm, vòng đệm và các miếng chèn khác:',
    Unit: '',
  },
  {
    HsCode: '98491510',
    Description: '- - - Miếng đệm và vành khung tròn, loại dùng cho xe có động cơ thuộc nhóm 87.02, 87.03, 87.04 ',
    Unit: '40169320',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Bộ phận và phụ kiện dùng cho xe thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '98491521',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.02, 87.03, 87.04 hoặc 87.05, trừ viền cao su quanh kính cửa',
    Unit: '40169911',
  },
  {
    HsCode: '98491529',
    Description: '- - - - - Viền cao su quanh kính cửa, dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '40169913',
  },
  {
    HsCode: '984916',
    Description: '- Kính an toàn, bao gồm kính tôi hoặc kính dán nhiều lớp (laminated glass):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Kính an toàn cứng (đã tôi):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có kích cỡ và hình dạng phù hợp với từng loại xe, máy bay, tàu vũ trụ hoặc tàu thuyền:',
    Unit: '',
  },
  {
    HsCode: '98491610',
    Description: '- - - - Phù hợp dùng cho xe thuộc Chương 87',
    Unit: '70071110',
  },
  {
    HsCode: '',
    Description: '- - Kính dán an toàn nhiều lớp:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Có kích cỡ và hình dạng phù hợp với từng loại xe, máy bay, tàu vũ trụ hoặc tàu thuyền:',
    Unit: '',
  },
  {
    HsCode: '98491690',
    Description: '- - - - Phù hợp dùng cho xe thuộc Chương 87',
    Unit: '70072110',
  },
  {
    HsCode: '984917',
    Description: '- Gương thuỷ tinh, có hoặc không có khung, kể cả gương chiếu hậu:',
    Unit: '',
  },
  {
    HsCode: '9849170 ',
    Description: '- - Gương chiếu hậu dùng cho xe',
    Unit: '70091000',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '9849170 ',
    Description: '- - - Chưa có khung',
    Unit: '70099100',
  },
  {
    HsCode: '9849170 ',
    Description: '- - - Có khung',
    Unit: '70099200',
  },
  {
    HsCode: '984918',
    Description: '- Lò xo và lá lò xo, bằng sắt hoặc thép:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Lò xo lá và các lá lò xo:',
    Unit: '',
  },
  {
    HsCode: '98491810',
    Description: '- - - Dùng cho xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '73201011',
  },
  {
    HsCode: '98491820',
    Description: '- - - Dùng cho xe có động cơ khác',
    Unit: '73201012',
  },
  {
    HsCode: '',
    Description: '- - Lò xo cuộn:',
    Unit: '',
  },
  {
    HsCode: '98491830',
    Description: '- - - Dùng cho xe có động cơ',
    Unit: '73202011',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98491890',
    Description: '- - - Dùng cho xe có động cơ',
    Unit: '73209010',
  },
  {
    HsCode: '984919',
    Description:
      '- Khóa móc và ổ khoá (loại mở bằng chìa, số hoặc điện), bằng kim loại cơ bản; móc cài và khung có móc cài, đi cùng ổ khoá, bằng kim loại cơ bản; chìa của các loại khóa trên, bằng kim loại cơ bản:',
    Unit: '',
  },
  {
    HsCode: '9849190 ',
    Description: '- - Ổ khoá thuộc loại sử dụng cho xe có động cơ',
    Unit: '83012000',
  },
  {
    HsCode: '984920',
    Description:
      '- Động cơ đốt trong kiểu piston chuyển động tịnh tiến hoặc kiểu piston chuyển động quay đốt cháy bằng tia lửa điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - Động cơ đốt trong kiểu piston chuyển động tịnh tiến dùng để tạo động lực cho các loại xe thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '98492011',
    Description: '- - - Dung tích xi lanh không quá 50 cc',
    Unit: '84073100',
  },
  {
    HsCode: '',
    Description: '- - - Dung tích xi lanh trên 50 cc nhưng không quá 250 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dung tích xilanh trên 50 cc nhưng không quá 110 cc:',
    Unit: '',
  },
  {
    HsCode: '98492012',
    Description: '- - - - - Loại khác',
    Unit: '84073219',
  },
  {
    HsCode: '',
    Description: '- - - - Dung tích xilanh trên 110 cc nhưng không quá 250 cc:',
    Unit: '',
  },
  {
    HsCode: '98492013',
    Description: '- - - - - Loại khác',
    Unit: '84073229',
  },
  {
    HsCode: '',
    Description: '- - - Dung tích xi lanh trên 250 cc nhưng không quá 1.000 cc:',
    Unit: '',
  },
  {
    HsCode: '98492014',
    Description: '- - - - Loại khác',
    Unit: '84073390',
  },
  {
    HsCode: '',
    Description: '- - - Dung tích xi lanh trên 1.000 cc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492015',
    Description: '- - - - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: '84073471',
  },
  {
    HsCode: '98492016',
    Description: '- - - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.000 cc',
    Unit: '84073472',
  },
  {
    HsCode: '98492017',
    Description: '- - - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: '84073473',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492018',
    Description: '- - - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: '84073494',
  },
  {
    HsCode: '98492019',
    Description: '- - - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.000 cc',
    Unit: '84073495',
  },
  {
    HsCode: '98492020',
    Description: '- - - - - Dung tích xi lanh trên 3.000 cc',
    Unit: '84073499',
  },
  {
    HsCode: '984921',
    Description: '- Động cơ đốt trong kiểu piston đốt cháy bằng sức nén (diesel hoặc bán diesel):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Động cơ dùng để tạo động lực cho các loại xe thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '98492111',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: '84082021',
  },
  {
    HsCode: '98492112',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.500 cc',
    Unit: '84082022',
  },
  {
    HsCode: '98492113',
    Description: '- - - - Dung tích xi lanh trên 3.500 cc',
    Unit: '84082023',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492114',
    Description: '- - - - Dung tích xi lanh không quá 2.000 cc',
    Unit: '84082094',
  },
  {
    HsCode: '98492115',
    Description: '- - - - Dung tích xi lanh trên 2.000 cc nhưng không quá 3.500 cc',
    Unit: '84082095',
  },
  {
    HsCode: '98492119',
    Description: '- - - - Dung tích xi lanh trên 3.500 cc',
    Unit: '84082096',
  },
  {
    HsCode: '984922',
    Description: '- Các bộ phận chỉ dùng hoặc chủ yếu dùng cho các loại động cơ thuộc nhóm 84.07 hoặc 84.08:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Chỉ dùng hoặc chủ yếu dùng cho động cơ đốt trong kiểu đốt cháy bằng tia lửa điện: ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe khác thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '98492211',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: '84099141',
  },
  {
    HsCode: '98492212',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: '84099142',
  },
  {
    HsCode: '98492213',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: '84099143',
  },
  {
    HsCode: '98492214',
    Description: '- - - - Ống xi lanh khác ',
    Unit: '84099144',
  },
  {
    HsCode: '98492215',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: '84099145',
  },
  {
    HsCode: '98492216',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: '84099146',
  },
  {
    HsCode: '98492217',
    Description: '- - - - Piston khác',
    Unit: '84099147',
  },
  {
    HsCode: '98492218',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: '84099148',
  },
  {
    HsCode: '98492219',
    Description: '- - - - Loại khác',
    Unit: '84099149',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho động cơ của xe khác thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '98492221',
    Description: '- - - - Chế hòa khí và bộ phận của chúng',
    Unit: '84099941',
  },
  {
    HsCode: '98492222',
    Description: '- - - - Thân động cơ (cylinder block); hộp trục khuỷu',
    Unit: '84099942',
  },
  {
    HsCode: '98492223',
    Description: '- - - - Ống xi lanh, có đường kính trong từ 50 mm trở lên, nhưng không quá 155 mm ',
    Unit: '84099943',
  },
  {
    HsCode: '98492224',
    Description: '- - - - Ống xi lanh khác ',
    Unit: '84099944',
  },
  {
    HsCode: '98492225',
    Description: '- - - - Quy lát và nắp quy lát',
    Unit: '84099945',
  },
  {
    HsCode: '98492226',
    Description: '- - - - Piston, có đường kính ngoài từ 50 mm trở lên, nhưng không quá 155 mm',
    Unit: '84099946',
  },
  {
    HsCode: '98492227',
    Description: '- - - - Piston khác',
    Unit: '84099947',
  },
  {
    HsCode: '98492228',
    Description: '- - - - Bạc piston và chốt piston',
    Unit: '84099948',
  },
  {
    HsCode: '98492229',
    Description: '- - - - Loại khác',
    Unit: '84099949',
  },
  {
    HsCode: '984923',
    Description: '- Bơm chất lỏng, có hoặc không lắp thiết bị đo; máy đẩy chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bơm nhiên liệu, dầu bôi trơn hoặc bơm chất làm mát, dùng cho động cơ đốt trong kiểu piston:',
    Unit: '',
  },
  {
    HsCode: '98492311',
    Description: '- - - Bơm nhiên liệu loại sử dụng cho động cơ của xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '84133030',
  },
  {
    HsCode: '98492319',
    Description: '- - - Bơm nước loại được sử dụng cho động cơ của xe có động cơ thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '84133040',
  },
  {
    HsCode: '984924',
    Description:
      '- Bơm không khí hoặc bơm chân không, máy nén không khí hay chất khí khác và quạt; nắp chụp hút tuần hoàn gió hoặc thông gió có kèm theo quạt, có hoặc không lắp bộ phận lọc:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Máy nén trừ loại thuộc phân nhóm 8414.30 hoặc 8414.40:',
    Unit: '',
  },
  {
    HsCode: '98492400',
    Description: '- - - Dùng cho máy điều hoà không khí của ô tô',
    Unit: '84148042',
  },
  {
    HsCode: '984925',
    Description:
      '- Máy điều hòa không khí, gồm có một quạt chạy bằng mô tơ và các bộ phận làm thay đổi nhiệt độ và độ ẩm, kể cả loại máy không điều chỉnh độ ẩm một cách riêng biệt:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại sử dụng cho người, trong xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '98492511',
    Description: '- - - Công suất làm mát không quá 26,38 kW',
    Unit: '84152010',
  },
  {
    HsCode: '98492519',
    Description: '- - - Loại khác',
    Unit: '84152090',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description:
      '- - - Kèm theo một bộ phận làm lạnh và một van đảo chiều chu trình nóng/lạnh (bơm nhiệt có đảo chiều):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Sử dụng cho xe có động cơ (trừ loại thuộc phân nhóm 8415.20):',
    Unit: '',
  },
  {
    HsCode: '98492521',
    Description: '- - - - - Công suất làm mát không quá 26,38 kW',
    Unit: '84158131',
  },
  {
    HsCode: '98492529',
    Description: '- - - - - Loại khác',
    Unit: '84158139',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác, có kèm theo bộ phận làm lạnh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Sử dụng cho xe có động cơ (trừ loại thuộc phân nhóm 8415.20):',
    Unit: '',
  },
  {
    HsCode: '98492531',
    Description: '- - - - - Công suất làm mát không quá 26,38 kW',
    Unit: '84158231',
  },
  {
    HsCode: '98492539',
    Description: '- - - - - Loại khác',
    Unit: '84158239',
  },
  {
    HsCode: '',
    Description: '- - - Không gắn kèm bộ phận làm lạnh:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Sử dụng cho xe có động cơ (trừ loại thuộc phân nhóm 8415.20):',
    Unit: '',
  },
  {
    HsCode: '98492541',
    Description: '- - - - - Công suất làm mát không quá 26,38 kW',
    Unit: '84158331',
  },
  {
    HsCode: '98492549',
    Description: '- - - - - Loại khác',
    Unit: '84158339',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Của máy có công suất làm mát không quá 21,10 kW:',
    Unit: '',
  },
  {
    HsCode: '98492590',
    Description: '- - - - Thiết bị làm bay hơi hoặc ngưng tụ dùng cho máy điều hoà không khí lắp trên xe có động cơ ',
    Unit: '84159014',
  },
  {
    HsCode: '984926',
    Description:
      '- Máy ly tâm, kể cả máy làm khô bằng ly tâm; máy và thiết bị lọc hay tinh chế chất lỏng hoặc chất khí:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Máy và thiết bị lọc hoặc tinh chế chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Bộ lọc dầu hoặc xăng cho động cơ đốt trong:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe có động cơ thuộc Chương 87:',
    Unit: '',
  },
  {
    HsCode: '98492611',
    Description: '- - - - - Bộ lọc dầu',
    Unit: '84212321',
  },
  {
    HsCode: '98492619',
    Description: '- - - - - Loại khác',
    Unit: '84212329',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492621',
    Description: '- - - - Loại khác, thiết bị lọc xăng',
    Unit: '84212940',
  },
  {
    HsCode: '98492629',
    Description: '- - - - Loại khác, thiết bị lọc dầu',
    Unit: '84212950',
  },
  {
    HsCode: '',
    Description: '- - Máy và thiết bị lọc hoặc tinh chế các loại khí:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Bộ lọc khí nạp cho động cơ đốt trong:',
    Unit: '',
  },
  {
    HsCode: '98492630',
    Description: '- - - - Dùng cho xe có động cơ thuộc Chương 87',
    Unit: '84213120',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98492640',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8421.31',
    Unit: '84219930',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492690',
    Description: '- - - - Loại khác',
    Unit: '84219999',
  },
  {
    HsCode: '98492690',
    Description: '- - - - Loại khác',
    Unit: '84219950',
  },
  {
    HsCode: '984927',
    Description:
      '- Vòi, van và các thiết bị tương tự dùng cho đường ống, thân nồi hơi, bể chứa hay các loại tương tự, kể cả van giảm áp và van điều chỉnh bằng nhiệt:',
    Unit: '',
  },
  {
    HsCode: '98492710',
    Description: '- - Van ngắt nhiên liệu dùng cho xe thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '84818083',
  },
  {
    HsCode: '98492790',
    Description: '- - Van ngắt nhiên liệu dùng cho xe thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '84818093',
  },
  {
    HsCode: '984928',
    Description:
      '- Trục truyền động (kể cả trục cam và trục khuỷu) và tay biên; gối đỡ trục dùng ổ lăn và gối đỡ trục dùng ổ trượt; bánh răng và cụm bánh răng; vít bi hoặc vít đũa; hộp số và các cơ cấu điều tốc khác, kể cả bộ biến đổi mô men xoắn; bánh đà và ròng rọc, kể cả khối puli; ly hợp và khớp nối trục (kể cả khớp nối vạn năng):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Trục truyền động (kể cả trục cam và trục khuỷu) và tay biên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Trục cam và trục khuỷu dùng cho động cơ xe của Chương 87:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492811',
    Description: '- - - - - Cho xe có dung tích xi lanh không quá 2.000 cc',
    Unit: '84831025',
  },
  {
    HsCode: '98492812',
    Description: '- - - - - Cho xe có dung tích xi lanh trên 2.000 cc đến 3.000 cc',
    Unit: '84831026',
  },
  {
    HsCode: '98492813',
    Description: '- - - - - Cho xe có dung tích xi lanh trên 3.000 cc',
    Unit: '84831027',
  },
  {
    HsCode: '',
    Description: '- - Thân ổ, lắp ổ bi hoặc ổ đũa:',
    Unit: '',
  },
  {
    HsCode: '98492820',
    Description: '- - - Dùng cho động cơ xe của Chương 87',
    Unit: '84832030',
  },
  {
    HsCode: '',
    Description: '- - Thân ổ, không lắp ổ bi hay ổ đũa; gối đỡ trục dùng ổ trượt:',
    Unit: '',
  },
  {
    HsCode: '98492830',
    Description: '- - - Dùng cho động cơ xe của Chương 87',
    Unit: '84833030',
  },
  {
    HsCode: '',
    Description:
      '- - Bộ bánh răng và cụm bánh răng ăn khớp, trừ bánh xe có răng, đĩa xích và các bộ phận truyền chuyển động ở dạng riêng biệt; vít bi hoặc vít đũa; hộp số và các cơ cấu điều tốc khác, kể cả bộ biến đổi mô men xoắn:',
    Unit: '',
  },
  {
    HsCode: '98492840',
    Description: '- - - Dùng cho xe thuộc Chương 87',
    Unit: '84834040',
  },
  {
    HsCode: '98492850',
    Description: '- - Bánh đà và ròng rọc, kể cả khối pu li',
    Unit: '84835000',
  },
  {
    HsCode: '98492860',
    Description: '- - Ly hợp và khớp nối trục (kể cả khớp nối vạn năng)',
    Unit: '84836000',
  },
  {
    HsCode: '',
    Description: '- - Bánh xe có răng, đĩa xích và các bộ phận truyền chuyển động riêng biệt; các bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98492891',
    Description: '- - - Dùng cho hàng hóa khác thuộc Chương 87',
    Unit: '84839015',
  },
  {
    HsCode: '98492899',
    Description: '- - - Dùng cho hàng hóa khác thuộc Chương 87',
    Unit: '84839095',
  },
  {
    HsCode: '984929',
    Description:
      '- Thiết bị đánh lửa hoặc khởi động bằng điện loại dùng cho động cơ đốt trong đốt cháy bằng tia lửa điện hoặc cháy do nén (ví dụ, magneto đánh lửa, dynamo magneto, cuộn dây đánh lửa, bugi đánh lửa và bugi sấy, động cơ khởi động); máy phát điện (ví dụ, dynamo, alternator) và thiết bị ngắt mạch loại được sử dụng cùng các động cơ nêu trên:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bugi đánh lửa:',
    Unit: '',
  },
  {
    HsCode: '98492910',
    Description: '- - - Sử dụng cho động cơ ô tô',
    Unit: '85111020',
  },
  {
    HsCode: '',
    Description: '- - Magneto đánh lửa; dynamo mangneto; bánh đà từ tính:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho động cơ ô tô:',
    Unit: '',
  },
  {
    HsCode: '98492921',
    Description: '- - - - Loại chưa được lắp ráp ',
    Unit: '85112021',
  },
  {
    HsCode: '98492929',
    Description: '- - - - Loại khác',
    Unit: '85112029',
  },
  {
    HsCode: '',
    Description: '- - Bộ phân phối điện; cuộn dây đánh lửa:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Sử dụng cho động cơ ô tô:',
    Unit: '',
  },
  {
    HsCode: '98492931',
    Description: '- - - - Loại chưa được lắp ráp',
    Unit: '85113041',
  },
  {
    HsCode: '98492939',
    Description: '- - - - Loại khác',
    Unit: '85113049',
  },
  {
    HsCode: '',
    Description: '- - Động cơ khởi động và máy tổ hợp hai tính năng khởi động và phát điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Động cơ khởi động khác chưa lắp ráp:',
    Unit: '',
  },
  {
    HsCode: '98492941',
    Description: '- - - - Sử dụng cho động cơ của nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: '85114021',
  },
  {
    HsCode: '',
    Description: '- - - Động cơ khởi động đã lắp ráp sử dụng cho động cơ của xe thuộc các nhóm từ 87.01 đến 87.05:',
    Unit: '',
  },
  {
    HsCode: '98492942',
    Description: '- - - - Sử dụng cho động cơ của các nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '85114032',
  },
  {
    HsCode: '98492943',
    Description: '- - - - Sử dụng cho động cơ của nhóm 87.05',
    Unit: '85114033',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492949',
    Description: '- - - - Sử dụng cho động cơ của các nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: '85114091',
  },
  {
    HsCode: '',
    Description: '- - Máy phát điện khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Máy phát điện xoay chiều khác chưa lắp ráp:',
    Unit: '',
  },
  {
    HsCode: '98492950',
    Description: '- - - - Sử dụng cho động cơ của các nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: '85115021',
  },
  {
    HsCode: '',
    Description: '- - - Máy phát điện xoay chiều đã lắp ráp dùng cho động cơ của xe thuộc các nhóm từ 87.01 đến 87.05:',
    Unit: '',
  },
  {
    HsCode: '98492961',
    Description: '- - - - Sử dụng cho động cơ của các nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '85115032',
  },
  {
    HsCode: '98492969',
    Description: '- - - - Sử dụng cho động cơ của nhóm 87.05',
    Unit: '85115033',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98492971',
    Description: '- - - - Sử dụng cho động cơ của nhóm 87.02, 87.03, 87.04 hoặc 87.05',
    Unit: '85115091',
  },
  {
    HsCode: '98492979',
    Description: '- - - - Loại khác',
    Unit: '85115099',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị khác:',
    Unit: '',
  },
  {
    HsCode: '98492980',
    Description: '- - - Sử dụng cho động cơ ô tô',
    Unit: '85118020',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98492990',
    Description: '- - - Sử dụng cho động cơ ô tô',
    Unit: '85119020',
  },
  {
    HsCode: '984930',
    Description:
      '- Thiết bị chiếu sáng hoặc thiết bị tín hiệu hoạt động bằng điện (trừ loại thuộc nhóm 85.39), gạt nước, gạt và chống tạo sương và tuyết trên kính chắn, loại dùng cho xe đạp hoặc xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị chiếu sáng hoặc tạo tín hiệu trực quan khác:',
    Unit: '',
  },
  {
    HsCode: '98493010',
    Description: '- - - Thiết bị chiếu sáng hoặc tạo tín hiệu trực quan chưa lắp ráp',
    Unit: '85122020',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493020',
    Description: '- - - Loại khác',
    Unit: '85122099',
  },
  {
    HsCode: '',
    Description: '- - Thiết bị tín hiệu âm thanh:',
    Unit: '',
  },
  {
    HsCode: '98493031',
    Description: '- - - Còi, đã lắp ráp',
    Unit: '85123010',
  },
  {
    HsCode: '98493032',
    Description: '- - - Thiết bị tín hiệu âm thanh chưa lắp ráp',
    Unit: '85123020',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493033',
    Description: '- - - - Thiết bị dò chướng ngại vật (cảnh báo) cho xe cộ',
    Unit: '85123091',
  },
  {
    HsCode: '98493039',
    Description: '- - - - Loại khác',
    Unit: '85123099',
  },
  {
    HsCode: '98493040',
    Description: '- - Cái gạt nước, gạt và chống tạo sương và tuyết',
    Unit: '85124000',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98493050',
    Description: '- - - Của hàng hóa thuộc phân nhóm 8512.20, 8512.30 hoặc 8512.40',
    Unit: '85129020',
  },
  {
    HsCode: '984931',
    Description:
      '- Thiết bị điện để đóng ngắt mạch hay bảo vệ mạch điện, hoặc dùng để đấu nối hay lắp trong mạch điện (ví dụ, cầu dao, rơ le, công tắc, chi tiết đóng ngắt mạch, cầu chì, bộ triệt xung điện, phích cắm, ổ cắm, đui đèn và các đầu nối khác, hộp đấu nối), dùng cho điện áp không quá 1.000 V; đầu nối dùng cho sợi quang, bó sợi quang hoặc cáp quang:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Cầu chì:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Cầu chì nhiệt; cầu chì thủy tinh:',
    Unit: '',
  },
  {
    HsCode: '98493110',
    Description: '- - - - Khối cầu chì, loại sử dụng cho xe có động cơ ',
    Unit: '85361013',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493190',
    Description: '- - - - Khối cầu chì, loại sử dụng cho xe có động cơ',
    Unit: '85361093',
  },
  {
    HsCode: '984932',
    Description:
      '- Bóng đèn dây tóc hoặc bóng đèn phóng điện, kể cả đèn pha gắn kín và bóng đèn tia cực tím hoặc tia hồng ngoại; bóng đèn hồ quang; đèn đi-ốt phát quang (LED):',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Đèn pha gắn kín (sealed beam lamp units):',
    Unit: '',
  },
  {
    HsCode: '98493210',
    Description: '- - - Dùng cho xe có động cơ thuộc Chương 87',
    Unit: '85391010',
  },
  {
    HsCode: '',
    Description: '- - Các loại bóng đèn dây tóc khác, trừ bóng đèn tia cực tím hoặc tia hồng ngoại:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Bóng đèn ha-lo-gien vonfram:',
    Unit: '',
  },
  {
    HsCode: '98493220',
    Description: '- - - - Dùng cho xe có động cơ',
    Unit: '85392130',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493230',
    Description: '- - - - Dùng cho xe có động cơ',
    Unit: '85392920',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98493290',
    Description: '- - - Loại khác, dùng cho đèn của xe có động cơ',
    Unit: '85399020',
  },
  {
    HsCode: '98493290',
    Description: '- - - Loại khác, dùng cho đèn của xe có động cơ',
    Unit: '85395100',
  },
  {
    HsCode: '984933',
    Description:
      '- Dây điện, cáp điện (kể cả cáp đồng trục) có cách điện (kể cả loại đã tráng men cách điện hoặc mạ lớp cách điện) và dây dẫn có cách điện khác, đã hoặc chưa gắn với đầu nối; cáp sợi quang, làm bằng các bó sợi đơn có vỏ bọc riêng biệt từng sợi, có hoặc không gắn với dây dẫn điện hoặc gắn với đầu nối:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Bộ dây đánh lửa và bộ dây khác loại sử dụng cho xe, phương tiện bay hoặc tàu thuyền:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Bộ dây điện cho xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Cách điện bằng cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '98493310',
    Description: '- - - - - Loại dùng cho xe thuộc nhóm 87.02, 87.03, 87.04 ',
    Unit: '85443012',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493320',
    Description: '- - - - - Loại dùng cho xe thuộc nhóm 87.02, 87.03, 87.04 ',
    Unit: '85443014',
  },
  {
    HsCode: '',
    Description: '- - Dây dẫn điện khác, dùng cho điện áp không quá 1.000 V:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Đã lắp với đầu nối điện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Cáp ắc quy:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - - Cách điện bằng cao su hoặc plastic:',
    Unit: '',
  },
  {
    HsCode: '98493330',
    Description: '- - - - - - Cho xe thuộc nhóm 87.02, 87.03, 87.04 ',
    Unit: '85444232',
  },
  {
    HsCode: '',
    Description: '- - - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493340',
    Description: '- - - - - - Cho xe thuộc nhóm 87.02, 87.03, 87.04 ',
    Unit: '85444234',
  },
  {
    HsCode: '984934',
    Description: '- Bộ phận và phụ kiện của xe có động cơ thuộc các nhóm từ 87.01 đến 87.05:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Thanh chắn chống va đập (ba đờ xốc) và bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '98493411',
    Description: '- - - Loại khác',
    Unit: '87081090',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận và phụ kiện khác của thân xe (kể cả ca-bin):',
    Unit: '',
  },
  {
    HsCode: '98493412',
    Description: '- - - Dây đai an toàn',
    Unit: '87082100',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Các bộ phận của cửa xe:',
    Unit: '',
  },
  {
    HsCode: '98493413',
    Description: '- - - - - Đỡ tay trên cửa xe dùng cho xe thuộc nhóm 87.03',
    Unit: '87082915',
  },
  {
    HsCode: '98493414',
    Description: '- - - - - Loại khác, dùng cho xe thuộc nhóm 87.03',
    Unit: '87082916',
  },
  {
    HsCode: '98493415',
    Description: '- - - - - Dùng cho xe thuộc phân nhóm 8704.10',
    Unit: '87082917',
  },
  {
    HsCode: '98493416',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.02 hoặc xe khác thuộc nhóm 87.04',
    Unit: '87082918',
  },
  {
    HsCode: '98493417',
    Description: '- - - - - Loại khác',
    Unit: '87082919',
  },
  {
    HsCode: '98493418',
    Description: '- - - - Bộ phận của dây đai an toàn',
    Unit: '87082920',
  },
  {
    HsCode: '',
    Description: '- - - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '98493419',
    Description: '- - - - - - Phụ kiện trang trí nội thất; chắn bùn',
    Unit: '87082993',
  },
  {
    HsCode: '98493420',
    Description: '- - - - - - Thanh chống nắp ca pô',
    Unit: '87082994',
  },
  {
    HsCode: '98493421',
    Description: '- - - - - - Loại khác',
    Unit: '87082995',
  },
  {
    HsCode: '98493421',
    Description: '- - - - - - Loại khác',
    Unit: '87082220',
  },
  {
    HsCode: '',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04:',
    Unit: '',
  },
  {
    HsCode: '98493422',
    Description: '- - - - - - Phụ kiện trang trí nội thất; chắn bùn',
    Unit: '87082996',
  },
  {
    HsCode: '98493423',
    Description: '- - - - - - Thanh chống nắp ca pô',
    Unit: '87082997',
  },
  {
    HsCode: '98493424',
    Description: '- - - - - - Loại khác',
    Unit: '87082998',
  },
  {
    HsCode: '98493424',
    Description: '- - - - - - Loại khác',
    Unit: '87082230',
  },
  {
    HsCode: '98493429',
    Description: '- - - - - Loại khác',
    Unit: '87082999',
  },
  {
    HsCode: '98493429',
    Description: '- - - - - Loại khác',
    Unit: '87082230',
  },
  {
    HsCode: '',
    Description: '- - Phanh và trợ lực phanh; bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '98493431',
    Description: '- - - - Phanh trống, phanh đĩa hoặc phanh hơi ',
    Unit: '87083021',
  },
  {
    HsCode: '98493432',
    Description: '- - - - Loại khác',
    Unit: '87083029',
  },
  {
    HsCode: '98493433',
    Description: '- - - Phanh trống, phanh đĩa hoặc phanh hơi cho xe thuộc nhóm 87.02 hoặc 87.04',
    Unit: '87083030',
  },
  {
    HsCode: '98493439',
    Description: '- - - Loại khác',
    Unit: '87083090',
  },
  {
    HsCode: '',
    Description: '- - Hộp số và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hộp số, chưa lắp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '98493441',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87084011',
  },
  {
    HsCode: '98493442',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: '87084013',
  },
  {
    HsCode: '98493443',
    Description: '- - - - Loại khác',
    Unit: '87084019',
  },
  {
    HsCode: '',
    Description: '- - - Hộp số, đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '98493444',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87084026',
  },
  {
    HsCode: '98493445',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: '87084027',
  },
  {
    HsCode: '98493446',
    Description: '- - - - Loại khác',
    Unit: '87084029',
  },
  {
    HsCode: '',
    Description: '- - - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98493447',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87084092',
  },
  {
    HsCode: '98493449',
    Description: '- - - - Loại khác',
    Unit: '87084099',
  },
  {
    HsCode: '',
    Description:
      '- - Cầu chủ động có vi sai, có hoặc không kèm theo chi tiết khác của cụm hộp số, và các cầu bị động; các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Chưa lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '98493451',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87085011',
  },
  {
    HsCode: '98493452',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.04 hoặc nhóm 87.05',
    Unit: '87085013',
  },
  {
    HsCode: '98493453',
    Description: '- - - - Loại khác',
    Unit: '87085019',
  },
  {
    HsCode: '',
    Description: '- - - Đã lắp ráp hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '98493454',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87085026',
  },
  {
    HsCode: '98493455',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.04 hoặc nhóm 87.05',
    Unit: '87085027',
  },
  {
    HsCode: '98493456',
    Description: '- - - - Loại khác',
    Unit: '87085029',
  },
  {
    HsCode: '',
    Description: '- - - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03:',
    Unit: '',
  },
  {
    HsCode: '98493457',
    Description: '- - - - - Bánh răng vành khăn và bánh răng quả dứa ',
    Unit: '87085094',
  },
  {
    HsCode: '98493458',
    Description: '- - - - - Loại khác',
    Unit: '87085095',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493459',
    Description: '- - - - Bánh răng vành khăn và bánh răng quả dứa ',
    Unit: '87085096',
  },
  {
    HsCode: '98493460',
    Description: '- - - - Loại khác',
    Unit: '87085099',
  },
  {
    HsCode: '',
    Description: '- - Cụm bánh xe và bộ phận và phụ kiện của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Ốp đầu trục bánh xe:',
    Unit: '',
  },
  {
    HsCode: '98493471',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87087016',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04:',
    Unit: '',
  },
  {
    HsCode: '98493472',
    Description: '- - - - - Dùng cho xe thuộc phân nhóm 8704.10',
    Unit: '87087017',
  },
  {
    HsCode: '98493473',
    Description: '- - - - - Loại khác',
    Unit: '87087018',
  },
  {
    HsCode: '98493474',
    Description: '- - - - Loại khác',
    Unit: '87087019',
  },
  {
    HsCode: '',
    Description: '- - - Bánh xe đã được lắp lốp:',
    Unit: '',
  },
  {
    HsCode: '98493475',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87087022',
  },
  {
    HsCode: '98493476',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04 (trừ phân nhóm 8704.10)',
    Unit: '87087023',
  },
  {
    HsCode: '98493477',
    Description: '- - - - Loại khác',
    Unit: '87087029',
  },
  {
    HsCode: '',
    Description: '- - - Bánh xe chưa được lắp lốp:',
    Unit: '',
  },
  {
    HsCode: '98493478',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87087032',
  },
  {
    HsCode: '98493479',
    Description:
      '- - - - Dùng cho xe tự đổ được thiết kế để sử dụng trên các loại đường không phải đường quốc lộ có khối lượng toàn bộ theo thiết kế trên 45 tấn',
    Unit: '87087033',
  },
  {
    HsCode: '98493480',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc xe khác thuộc nhóm 87.04 ',
    Unit: '87087034',
  },
  {
    HsCode: '98493481',
    Description: '- - - - Loại khác',
    Unit: '87087039',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493482',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04',
    Unit: '87087096',
  },
  {
    HsCode: '98493483',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87087097',
  },
  {
    HsCode: '98493489',
    Description: '- - - - Loại khác',
    Unit: '87087099',
  },
  {
    HsCode: '',
    Description: '- - Hệ thống giảm chấn và bộ phận của nó (kể cả giảm sóc): ',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hệ thống giảm chấn: ',
    Unit: '',
  },
  {
    HsCode: '98493491',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87088016',
  },
  {
    HsCode: '98493492',
    Description: '- - - - Dùng cho xe thuộc phân nhóm 8704.10 hoặc nhóm 87.05 ',
    Unit: '87088017',
  },
  {
    HsCode: '98493493',
    Description: '- - - - Loại khác',
    Unit: '87088019',
  },
  {
    HsCode: '',
    Description: '- - - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98493494',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87088092',
  },
  {
    HsCode: '98493499',
    Description: '- - - - Loại khác',
    Unit: '87088099',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận khác và các phụ kiện:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Két nước làm mát và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Két nước làm mát:',
    Unit: '',
  },
  {
    HsCode: '98493510',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87089116',
  },
  {
    HsCode: '',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.02 hoặc 87.04: ',
    Unit: '',
  },
  {
    HsCode: '98493521',
    Description: '- - - - - - Dùng cho xe thuộc phân nhóm 8704.10',
    Unit: '87089117',
  },
  {
    HsCode: '98493522',
    Description: '- - - - - - Loại khác',
    Unit: '87089118',
  },
  {
    HsCode: '98493529',
    Description: '- - - - - Loại khác',
    Unit: '87089119',
  },
  {
    HsCode: '',
    Description: '- - - - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98493531',
    Description: '- - - - - Nút xả dùng cho xe thuộc nhóm 87.03 ',
    Unit: '87089193',
  },
  {
    HsCode: '98493532',
    Description: '- - - - - Nút xả dùng cho xe thuộc nhóm 87.02 hoặc 87.04 (trừ loại thuộc phân nhóm 8704.10)',
    Unit: '87089194',
  },
  {
    HsCode: '98493533',
    Description: '- - - - - Loại khác, dùng cho xe thuộc nhóm 87.03',
    Unit: '87089195',
  },
  {
    HsCode: '98493539',
    Description: '- - - - - Loại khác',
    Unit: '87089199',
  },
  {
    HsCode: '',
    Description: '- - - Ống xả và bộ giảm thanh; bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '98493541',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87089220',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe thuộc phân nhóm 8704.10: ',
    Unit: '',
  },
  {
    HsCode: '98493542',
    Description: '- - - - - Ống xả và bộ giảm thanh ',
    Unit: '87089251',
  },
  {
    HsCode: '98493543',
    Description: '- - - - - Bộ phận ',
    Unit: '87089252',
  },
  {
    HsCode: '',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.02 hoặc xe khác thuộc nhóm 87.04: ',
    Unit: '',
  },
  {
    HsCode: '98493544',
    Description: '- - - - - Ống xả và bộ giảm thanh ',
    Unit: '87089261',
  },
  {
    HsCode: '98493545',
    Description: '- - - - - Bộ phận ',
    Unit: '87089262',
  },
  {
    HsCode: '98493549',
    Description: '- - - - Loại khác',
    Unit: '87089290',
  },
  {
    HsCode: '',
    Description: '- - Bộ ly hợp và các bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '98493551',
    Description: '- - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87089360',
  },
  {
    HsCode: '98493552',
    Description: '- - - Dùng cho xe thuộc nhóm 87.04 hoặc 87.05',
    Unit: '87089370',
  },
  {
    HsCode: '98493559',
    Description: '- - - Loại khác',
    Unit: '87089390',
  },
  {
    HsCode: '',
    Description: '- - Vô lăng, trụ lái và cơ cấu lái; bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Vô lăng lắp với túi khí hoàn chỉnh:',
    Unit: '',
  },
  {
    HsCode: '98493561',
    Description: '- - - - Loại khác',
    Unit: '87089419',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493562',
    Description: '- - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87089495',
  },
  {
    HsCode: '98493569',
    Description: '- - - - Loại khác',
    Unit: '87089499',
  },
  {
    HsCode: '',
    Description: '- - Túi khí an toàn lắp với hệ thống bơm phồng; bộ phận của nó:',
    Unit: '',
  },
  {
    HsCode: '98493571',
    Description: '- - - Túi khí an toàn lắp với hệ thống bơm phồng',
    Unit: '87089510',
  },
  {
    HsCode: '98493579',
    Description: '- - - Bộ phận',
    Unit: '87089590',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Dùng cho xe thuộc nhóm 87.02, 87.03 hoặc 87.04:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - - Thùng nhiên liệu và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '98493581',
    Description: '- - - - - Thùng nhiên liệu',
    Unit: '87089921',
  },
  {
    HsCode: '98493582',
    Description:
      '- - - - - Nửa dưới của thùng nhiên liệu; nắp đậy thùng nhiên liệu; ống dẫn của thùng nhiên liệu (filler pipes); ống dẫn hướng có đầu nối với bình nhiên liệu (filler hose); đai giữ bình nhiên liệu ',
    Unit: '87089924',
  },
  {
    HsCode: '98493583',
    Description: '- - - - - Các bộ phận khác ',
    Unit: '87089925',
  },
  {
    HsCode: '98493584',
    Description: '- - - - Chân ga (bàn đạp ga), chân phanh (bàn đạp phanh) hoặc chân côn (bàn đạp côn)',
    Unit: '87089930',
  },
  {
    HsCode: '98493585',
    Description: '- - - - Giá đỡ hoặc khay đỡ bình ắc qui và khung của nó',
    Unit: '87089940',
  },
  {
    HsCode: '98493586',
    Description: '- - - - Tấm hướng luồng khí tản nhiệt ',
    Unit: '87089950',
  },
  {
    HsCode: '',
    Description: '- - - - Khung xe hoặc các bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '98493587',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.02',
    Unit: '87089961',
  },
  {
    HsCode: '98493588',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.03',
    Unit: '87089962',
  },
  {
    HsCode: '98493589',
    Description: '- - - - - Dùng cho xe thuộc nhóm 87.04 ',
    Unit: '87089963',
  },
  {
    HsCode: '98493590',
    Description: '- - - - Khung giá đỡ động cơ',
    Unit: '87089970',
  },
  {
    HsCode: '98493591',
    Description: '- - - - Loại khác',
    Unit: '87089980',
  },
  {
    HsCode: '',
    Description: '- - - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '98493592',
    Description: '- - - - Thùng nhiên liệu chưa lắp ráp; khung giá đỡ động cơ',
    Unit: '87089991',
  },
  {
    HsCode: '98493599',
    Description: '- - - - Loại khác',
    Unit: '87089999',
  },
  {
    HsCode: '984936',
    Description:
      '- Tỷ trọng kế và các dụng cụ đo dạng nổi tương tự, nhiệt kế, hoả kế, khí áp kế, ẩm kế, có hoặc không ghi, và tổ hợp của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Loại khác:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - - Hoạt động bằng điện:',
    Unit: '',
  },
  {
    HsCode: '98493600',
    Description: '- - - - Máy đo nhiệt độ dùng cho xe có động cơ',
    Unit: '90251911',
  },
  {
    HsCode: '984937',
    Description:
      '- Dụng cụ và máy đo hoặc kiểm tra lưu lượng, mức, áp suất hoặc biến số khác của chất lỏng hoặc chất khí (ví dụ, dụng cụ đo lưu lượng, dụng cụ đo mức, áp kế, nhiệt kế), trừ các dụng cụ và thiết bị thuộc nhóm 90.14, 90.15, 90.28 hoặc 90.32:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Để đo hoặc kiểm tra lưu lượng hoặc mức của chất lỏng:',
    Unit: '',
  },
  {
    HsCode: '98493711',
    Description: '- - - Dụng cụ đo mức sử dụng cho xe có động cơ, hoạt động bằng điện',
    Unit: '90261050',
  },
  {
    HsCode: '98493719',
    Description: '- - - Dụng cụ đo mức sử dụng cho xe có động cơ, không hoạt động bằng điện',
    Unit: '90261050',
  },
  {
    HsCode: '',
    Description: '- - Để đo hoặc kiểm tra áp suất: ',
    Unit: '',
  },
  {
    HsCode: '98493790',
    Description: '- - - Máy đo áp suất sử dụng cho xe có động cơ, không hoạt động bằng điện',
    Unit: '90262050',
  },
  {
    HsCode: '984938',
    Description:
      '- Máy đếm vòng quay, máy đếm sản lượng, máy đếm cây số để tính tiền taxi, máy đếm dặm, máy đo bước và máy tương tự; đồng hồ chỉ tốc độ và máy đo tốc độ góc, trừ các loại máy thuộc nhóm 90.14 hoặc 90.15; máy hoạt nghiệm:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Đồng hồ chỉ tốc độ và máy đo tốc độ góc; máy hoạt nghiệm:',
    Unit: '',
  },
  {
    HsCode: '98493810',
    Description: '- - - Đồng hồ tốc độ dùng cho xe có động cơ',
    Unit: '90292010',
  },
  {
    HsCode: '98493810',
    Description: '- - - Đồng hồ tốc độ dùng cho xe có động cơ',
    Unit: '',
  },
  {
    HsCode: '98493890',
    Description: '- - - Máy đo tốc độ góc cho xe có động cơ',
    Unit: '90292020',
  },
  {
    HsCode: '98493890',
    Description: '- - - Máy đo tốc độ góc cho xe có động cơ',
    Unit: '',
  },
  {
    HsCode: '984939',
    Description:
      '- Đồng hồ thời gian lắp trên bàn điều khiển phương tiện và các loại đồng hồ thời gian tương tự dùng cho xe cộ, phương tiện bay, tàu vũ trụ hoặc tàu thủy:',
    Unit: '',
  },
  {
    HsCode: '98493910',
    Description: '- - Dùng cho xe cộ',
    Unit: '91040010',
  },
  {
    HsCode: '98493990',
    Description: '- - Loại khác',
    Unit: '91040090',
  },
  {
    HsCode: '984940',
    Description:
      '- Ghế ngồi (trừ các loại thuộc nhóm 94.02), có hoặc không chuyển được thành giường, và bộ phận của chúng:',
    Unit: '',
  },
  {
    HsCode: '',
    Description: '- - Ghế dùng cho xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '98494011',
    Description: '- - - Của xe thuộc nhóm 87.02, 87.03 hoặc 87.04',
    Unit: '94012010',
  },
  {
    HsCode: '98494019',
    Description: '- - - Loại khác',
    Unit: '94012090',
  },
  {
    HsCode: '',
    Description: '- - Bộ phận:',
    Unit: '',
  },
  {
    HsCode: '98494021',
    Description: '- - - Miếng lót cho tựa đầu của ghế thuộc phân nhóm 9401.20.10',
    Unit: '94019921',
  },
  {
    HsCode: '98494022',
    Description: '- - - Loại khác',
    Unit: '94019921',
  },
  {
    HsCode: '98494022',
    Description: '- - - Loại khác',
    Unit: '94019929',
  },
  {
    HsCode: '984941',
    Description:
      '- Máy thu thanh sóng vô tuyến không thể hoạt động khi không có nguồn điện ngoài, loại dùng cho xe có động cơ:',
    Unit: '',
  },
  {
    HsCode: '98494110',
    Description: '- - Kết hợp với thiết bị ghi hoặc tái tạo âm thanh',
    Unit: '85272110',
  },
  {
    HsCode: '98494110',
    Description: '- - Kết hợp với thiết bị ghi hoặc tái tạo âm thanh',
    Unit: '85272190',
  },
  {
    HsCode: '98494190',
    Description: '- - Loại khác',
    Unit: '85272900',
  },
  {
    HsCode: '984942',
    Description:
      '- Động cơ điện thuộc nhóm 85.01, trừ loại dùng cho các mặt hàng thuộc nhóm 84.15, 84.18, 84.50, 85.09 hoặc 85.16:',
    Unit: '',
  },
  {
    HsCode: '98494210',
    Description: '- - Động cơ vạn năng một chiều/xoay chiều có công suất trên 1 kW',
    Unit: '85012029',
  },
  {
    HsCode: '',
    Description: '- - Động cơ một chiều khác:',
    Unit: '',
  },
  {
    HsCode: '98494221',
    Description: '- - - Công suất trên 750 W nhưng không quá 37,5 kW',
    Unit: '85013224',
  },
  {
    HsCode: '98494222',
    Description: '- - - Công suất trên 37,5 kW nhưng không quá 75 kW',
    Unit: '85013232',
  },
  {
    HsCode: '98494230',
    Description: '- - Động cơ xoay chiều khác, một pha, công suất trên 1 kW',
    Unit: '85014029',
  },
  {
    HsCode: '98494240',
    Description: '- - Động cơ xoay chiều khác, đa pha, công suất trên 1 kW nhưng không quá 37,5 kW',
    Unit: '85015222',
  },
  {
    HsCode: '984943',
    Description: '- Pin và bộ pin:',
    Unit: '',
  },
  {
    HsCode: '98494310',
    Description: '- - Bằng liti',
    Unit: '85065000',
  },
  {
    HsCode: '984943',
    Description:
      '- - Loại khác (trừ loại thuộc phân nhóm 8506.10, 8506.30, 8506.40, 8506.50, 8506.60), trừ loại bằng kẽm carbon:',
    Unit: '',
  },
  {
    HsCode: '98494321',
    Description: '- - - Có thể tích ngoài không quá 300 cm3',
    Unit: '85068030',
  },
  {
    HsCode: '98494321',
    Description: '- - - Có thể tích ngoài không quá 300 cm3',
    Unit: '',
  },
  {
    HsCode: '98494329',
    Description: '- - - Loại khác',
    Unit: '85068090',
  },
  {
    HsCode: '98494390',
    Description: '- - Bộ phận',
    Unit: '85069000',
  },
  {
    HsCode: '98494400',
    Description:
      '- Phụ kiện lắp trên thân xe (coachwork) trừ các mặt hàng vít, bu lông, đai ốc, vít đầu vuông, vít treo, đinh tán, chốt hãm, chốt định vị, vòng đệm bằng plastic',
    Unit: '39263000',
  },
  {
    HsCode: '98494500',
    Description: '- Bản lề để móc khóa (Hasps) dùng cho xe có động cơ',
    Unit: '83023010',
  },
  {
    HsCode: '98494600',
    Description: '- Engine ECU và các bộ điều khiển khác, được sử dụng cho xe có động cơ',
    Unit: '85371099',
  },
  {
    HsCode: '98494600',
    Description: '- Engine ECU và các bộ điều khiển khác, được sử dụng cho xe có động cơ',
    Unit: '85371040',
  },
  {
    HsCode: '98500000',
    Description: 'Thuốc trị mụn trứng cá.',
    Unit: '30049099',
  },
  {
    HsCode: '98510000',
    Description: 'Trứng cá dùng làm giống.',
    Unit: '05119190',
  },
  {
    HsCode: '98520000',
    Description: 'Bột đậu nành lên men.',
    Unit: '23099020',
  },
];
