import { CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { MdOutlineClear } from 'react-icons/md';
import { db } from '../../server/firebase';
import './CreateShipment.css';
import * as XLSX from 'xlsx';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function CreateShipment({ handleCloseShipment, user }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [accountInfo, setAccountInfo] = useState('');
  const [sender, setSender] = useState('');
  const [getUserSuccess, setGetUserSuccess] = useState(false);
  const [shipmentType, setShipmentType] = useState('');
  const [shipmentService, setShipmentService] = useState('');
  const [eamService, setEamService] = useState('');
  const [shipmentReason, setShipmentReason] = useState('');
  const [shipmentStatus, setShipmentStatus] = useState('');
  const [shipmentDate, setShipmentDate] = useState('');
  const [text, setText] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [shipments, setShipments] = useState([
    {
      customer: '',
      mawb: '',
      service: '',
      hawb: '',
      mawb: '',
      makh: '',
      company: '',
      contact: '',
      type: '',
      status: '',
      address: '',
      address2: '',
      address3: '',
      from: '',
      country: '',
      town: '',
      city: '',
      tellNo: '',
      postal: '',
      value: '',
      weight: '',
      pcs: '',
      descrip: '',
      controller: '',
      rate: 0,
      addCorrect: 0,
      remote: 0,
      fsc: 0,
      vat: 0,
      plan: '',
    },
  ]);
  const onGetUser = (e) => {
    e.preventDefault();
    setLoading(true);
    db.collection('users')
      .doc(accountInfo)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setLoading(false);
          setSender(doc.data());
          setGetUserSuccess(true);
        } else {
          setGetUserSuccess(false);
          setLoading(false);
          alert('You have enter incorrect email');
        }
      });
  };
  const inputArray = text.split(/\r?\n/);
  const importer = inputArray[1]?.trim();
  const address = inputArray[2]?.trim();
  const address2 = inputArray[3]?.trim();
  const address3 = inputArray[4]?.trim();
  const town = inputArray[5]?.trim();
  const city = inputArray[6]?.trim();
  const state = inputArray[7]?.trim();
  const postal = inputArray[8]?.trim();
  const country = inputArray[0]?.trim();
  const phone = inputArray[9]?.trim();
  const contact = inputArray[10]?.trim();
  const email = inputArray[11]?.trim();
  const weightInput = inputArray[12]?.trim();
  const pcsInput = inputArray[13]?.trim();
  const valueInput = inputArray[14]?.trim();
  const handleExportExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(excelExport);
    XLSX.utils.book_append_sheet(wb, ws, 'skd_create_template');
    XLSX.writeFile(wb, 'skd_create_template.xls');
  };
  const handleChangeService = (e) => {
    if (e.target.value === 'DHL') {
      setEamService('INT');
    }
    if (e.target.value === 'FIP') {
      setEamService('FIP');
    }
    setShipmentService(e.target.value);
  };
  const excelExport = shipments.map((s) => {
    return {
      CUSTOMER: s.customer || '',
      MAKH: s.makh || '',
      SERVICE: '',
      HAWB: '',
      MAWB: '',
      COMPANY: '',
      CONTACT: '',
      TYPE: '',
      STATUS: '',
      ADDRESS: '',
      ADDRESS2: '',
      ADDRESS3: '',
      FROM: '',
      COUNTRY: '',
      TOWN: '',
      CITY: '',
      TELNO: '',
      POSTCODE: '',
      VALUE: '',
      WEIGHT: '',
      PCS: '',
      DESCRIP: '',
      CONTROLLER: '',
      RATE: 0,
      ADDCORRECT: 0,
      REMOTE: 0,
      FSC: 0,
      VAT: 0,
      PLAN: '',
    };
  });

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleCancelShipment = () => {
    if (fileName === '') {
      handleCloseShipment();
    } else {
      window.location.reload();
    }
  };
  const handleCreate = () => {
    setLoading(true);
    if (pathname === '/shipments') {
      shipments.forEach((s) => {
        const refNumber = 'SKD';
        const newDates = new Date();
        const dates = (newDates.getDate() < 10 ? '0' : '') + newDates.getDate();
        const months = (newDates.getMonth() + 1 < 10 ? '0' : '') + (newDates.getMonth() + 1);
        const years = newDates.getFullYear().toString().charAt(2);
        const yearLength = newDates.getFullYear().toString().charAt(3);
        const max = 900000;
        const wrapper = `${years}${yearLength}${months}${dates}`;
        const txt = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
        const hawb = txt;
        axios.post('https://test.skydart.com.vn/api/create/shipment', {
          account: '786009',
          currency: 'USD',
          service: s.SERVICE,
          date: shipmentDate,
          hawb: method === 'DIS' ? s.HAWB : hawb,
          mawb: s.MAWB,
          makh: s.ACCOUNT,
          company: s.COMPANY,
          contact: s.CONTACT,
          otherTerm: 'Null',
          type: s.TYPE,
          status: s.STATUS,
          term: 'Null',
          time: ':00',
          address: s.ADDRESS,
          address2: s.ADDRESS2,
          address3: s.ADDRESS3,
          city: s.TOWN,
          country: s.COUNTRY,
          code: s.COUNTRY,
          phone: s.TELNO,
          phone2: 'Null',
          postal: s.POSTCODE,
          state: 'Pending',
          value: s.VALUE,
          town: s.TOWN,
          chargeable: s.WEIGHT.toString(),
          amount: s.PCS,
          totalItem: s.PCS,
          transport: 'Null',
          description: s.DESCRIP,
          controller: s.CONTROLLER,
          eamFormat: s.TYPE,
          dateTime: new Date(),
          airline: 'Pending',
          reweight: s.WEIGHT.toString(),
          etd: 'Pending',
          agent: method === 'DIS' ? 'DIS' : 'Pending',
          subStatus: 'Pending',
          sin: 'Null',
          sender: s.CUSTOMER,
          note: 'Null',
          perform: 'Null',
          fromCity: s.FROM,
          fromEmail: 'Attach',
          totalValue: '0.00',
          rate: s.RATE || '0.00',
          comestic: '0.00',
          addressCorrection: s.ADDCORRECT || '0.00',
          remote: s.REMOTE || '0.00',
          surcharge: '0.00',
          surcharge2: '0.00',
          surcharge3: '0.00',
          fsc: s.FSC || 0,
          fscValue: '0.00',
          vat: s.VAT || 0,
          vatValue: '0.00',
          paymentPickup: 'CRE',
          paymentAcc: 'CRE',
          paymentCompany: 'CRE',
          paymentStatus: 'Un Paid',
          sellNote: 'Pending',
          com: '0.00',
          sellNote: 'Pending',
          paymentSubStatus: 'Rebook',
          pack: 'Pending',
          plan: s.PLAN,
        });
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 3000);
      });
    } else {
      shipments.forEach((s) => {
        const refNumber = 'SKD';
        const newDates = new Date();
        const dates = (newDates.getDate() < 10 ? '0' : '') + newDates.getDate();
        const months = (newDates.getMonth() + 1 < 10 ? '0' : '') + (newDates.getMonth() + 1);
        const years = newDates.getFullYear().toString().charAt(2);
        const yearLength = newDates.getFullYear().toString().charAt(3);
        const max = 900000;
        const wrapper = `${years}${yearLength}${months}${dates}`;
        const txt = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
        const hawb = txt;
        axios.post('https://test.skydart.com.vn/api/create/shipment', {
          account: '786009',
          currency: 'USD',
          service: s.SERVICE,
          date: shipmentDate,
          hawb: method === 'DIS' ? s.HAWB : hawb,
          mawb: s.MAWB,
          makh: s.ACCOUNT,
          company: s.COMPANY,
          contact: s.CONTACT,
          otherTerm: 'Null',
          type: s.TYPE,
          status: s.STATUS,
          term: 'Null',
          time: ':00',
          address: s.ADDRESS,
          address2: s.ADDRESS2,
          address3: s.ADDRESS3,
          city: s.TOWN,
          country: s.COUNTRY,
          code: s.COUNTRY,
          phone: s.TELNO,
          phone2: 'Null',
          postal: s.POSTCODE,
          state: 'Pending',
          value: s.VALUE,
          town: s.TOWN,
          chargeable: s.WEIGHT.toString(),
          amount: s.PCS,
          totalItem: s.PCS,
          transport: 'Null',
          description: s.DESCRIP,
          controller: s.CONTROLLER,
          eamFormat: s.TYPE,
          dateTime: new Date(),
          airline: 'Pending',
          reweight: s.WEIGHT.toString(),
          etd: 'Pending',
          agent: method === 'DIS' ? 'DIS' : 'Pending',
          subStatus: 'Pending',
          sin: 'Null',
          sender: s.CUSTOMER,
          note: 'Null',
          perform: 'Null',
          fromCity: s.FROM,
          fromEmail: 'Attach',
          totalValue: '0.00',
          rate: s.RATE || '0.00',
          comestic: '0.00',
          addressCorrection: s.ADDCORRECT || '0.00',
          remote: s.REMOTE || '0.00',
          surcharge: '0.00',
          surcharge2: '0.00',
          surcharge3: '0.00',
          fsc: s.FSC || 0,
          fscValue: '0.00',
          vat: s.VAT || 0,
          vatValue: '0.00',
          paymentPickup: 'CRE',
          paymentAcc: 'CRE',
          paymentCompany: 'CRE',
          paymentStatus: 'Un Paid',
          sellNote: 'Pending',
          com: '0.00',
          sellNote: 'Pending',
          paymentSubStatus: 'Rebook',
          pack: 'Pending',
          plan: s.PLAN,
        });
        setTimeout(() => {
          setLoading(false);
          navigate('/shipments');
        }, 3000);
      });
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
      setFileName(e.dataTransfer.files[0].name);
      setFileSize(e.dataTransfer.files[0].size);
      setFileType(e.dataTransfer.files[0].type);
      readExcel(e.dataTransfer.files[0]);
    }
  };
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      // at least one file has been selected so do something
      // handleFiles(e.target.files);
      setFileName(e.target.files[0].name);
      setFileSize(e.target.files[0].size);
      setFileType(e.target.files[0].type);
      readExcel(e.target.files[0]);
    }
  };
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((item) => {
      setShipments(item);
    });
  };
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  return (
    <div className="manifest-create-shipment">
      <div className="manifest-create-shipment-container" style={loading ? { overflow: 'hidden' } : {}}>
        {loading && (
          <div className="manifest-create-loading">
            <CircularProgress />
            <p style={{ marginLeft: 12 }}>Processing shipment file...</p>
          </div>
        )}
        <div className="manifest-create-shipment-header">
          <h3>Create / Add shipments</h3>
          <div className="manifest-create-shipment-header-right">
            <select value={method} onChange={(e) => setMethod(e.target.value)}>
              <option value="">{method === '' ? 'Attaching a file ?' : 'Create by text'}</option>
              <option value="DIS">Discrepency & Modify</option>
              <option value="BILL">New shipment file</option>
            </select>
            <div
              className="manifest-create-shipment-clear"
              onClick={() => {
                if (fileName === '') {
                  handleCloseShipment();
                } else {
                  window.location.reload();
                }
              }}
            >
              <MdOutlineClear size={23} />
            </div>
          </div>
        </div>
        {method === '' && (
          <div className="manifest-create-shipment-body">
            <div className="manifest-create-shipment-body-left">
              <form className="manifest-create-shipment-account-info" onSubmit={onGetUser}>
                <h5>Account info</h5>
                <input
                  type="text"
                  required
                  placeholder="Enter shipper's account (by email)"
                  value={accountInfo}
                  onChange={(e) => setAccountInfo(e.target.value)}
                />
                <button type="submit" style={{ display: 'none' }}>
                  Enter
                </button>
                {!getUserSuccess && (
                  <p style={{ fontSize: 11, marginTop: 3, color: 'red', fontWeight: 600 }}>
                    You have to enter sender information before you can create any shipment
                  </p>
                )}
                {getUserSuccess && (
                  <p style={{ fontSize: 11, marginTop: 3, color: 'green', fontWeight: 600 }}>
                    You are craeting this shipment for {sender.exporter} / {sender.makh}, now start your booking or view
                    sender's info{' '}
                    <strong
                      style={{
                        fontSize: 12.5,
                        cursor: 'pointer',
                        color: 'darkblue',
                        textDecoration: 'underline',
                        userSelect: 'none',
                      }}
                    >
                      here
                    </strong>
                  </p>
                )}
              </form>
              {getUserSuccess && (
                <div className="manifest-create-shipment-shipping-info">
                  <div className="manifest-create-shipment-type">
                    <small style={{ marginBottom: 2 }}>Type</small>
                    <select value={shipmentType} onChange={(e) => setShipmentType(e.target.value)}>
                      <option value="">Select shipment type</option>
                      <option value="WPX">WPX</option>
                      <option value="DOX">DOX</option>
                    </select>
                    <small style={{ marginBottom: 2, marginTop: 5 }}>Services</small>
                    <select value={shipmentService} onChange={handleChangeService}>
                      <option value="">Select shipment service</option>
                      <option value="SKD">SKD</option>
                      <option value="DHL">SDH</option>
                      <option value="FIP">SIP</option>
                      <option value="UPS">SUPS</option>
                      <option value="DHL-VN">VDH</option>
                      <option value="Fedex-VN">VIP</option>
                      <option value="UPS-VN">VUPS</option>
                      <option value="DUS">DUS</option>
                    </select>
                    <small style={{ marginBottom: 2, marginTop: 5 }}>Shipping reason</small>
                    <select value={shipmentReason} onChange={(e) => setShipmentReason(e.target.value)}>
                      <option value="">Select shipping reason</option>
                      <option value="GIFT">THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE</option>
                      <option value="E-COMMERCE SELLING">THE GOODS ARE E-COMMERCE SELLING</option>
                      <option value="RETURNED FOR MAINTENANCE">THE GOODS ARE RETURNED FOR MAINTENANCE</option>
                      <option value="SAMPLE">THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE</option>
                      <option value="COMMERCIAL">THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED</option>
                    </select>
                  </div>
                </div>
              )}
              {getUserSuccess && (
                <div className="manifest-create-shipment-shipping-info">
                  <h5 style={{ fontWeight: 600, color: 'gray' }}>Text (shipment's info)</h5>
                  <div className="manifest-create-shipment-textarea">
                    <textarea type="text" placeholder="#" value={text} onChange={(e) => setText(e.target.value)} />
                  </div>
                  <i style={{ fontWeight: 600, fontSize: 12, marginTop: 3 }}>
                    Order: country * company * address * address2 * address3 * town * city * state * postal code *
                    telephone * contact name * email address * weight (kg) * pcs * value{' '}
                    <strong style={{ textDecoration: 'underline', color: 'darkblue' }}>(seperated by ENTER)</strong>{' '}
                  </i>
                </div>
              )}
            </div>
            {getUserSuccess && (
              <div className="manifest-create-shipment-body-right">
                <div className="manifest-create-shipment-body-right-header">
                  <h5>Create option</h5>
                </div>
                <div className="manifest-create-shipment-body-right-body">
                  <small style={{ padding: 10, fontSize: 11, color: 'gray' }}>Status</small>
                  <div className="manifest-create-status-option" onClick={() => setShipmentStatus('PENDING')}>
                    <input
                      type="radio"
                      id="pending"
                      name="radio-group"
                      checked={shipmentStatus === 'PENDING' ? true : false}
                    />
                    <label style={{ display: 'flex', alignItems: 'center' }} for="pending">
                      <p style={{ fontSize: 12, marginLeft: 5 }}>PENDING</p>
                    </label>
                  </div>
                  <div className="manifest-create-status-option" onClick={() => setShipmentStatus('IN TRANSIT')}>
                    <input
                      type="radio"
                      id="transit"
                      name="radio-group"
                      checked={shipmentStatus === 'IN TRANSIT' ? true : false}
                    />
                    <label style={{ display: 'flex', alignItems: 'center' }} for="transit">
                      <p style={{ fontSize: 12, marginLeft: 5 }}>IN TRANSIT</p>
                    </label>
                  </div>
                  <div className="manifest-create-status-option" onClick={() => setShipmentStatus('RETURN')}>
                    <input
                      type="radio"
                      id="return"
                      name="radio-group"
                      checked={shipmentStatus === 'RETURN' ? true : false}
                    />
                    <label style={{ display: 'flex', alignItems: 'center' }} for="return">
                      <p style={{ fontSize: 12, marginLeft: 5 }}>RETURN</p>
                    </label>
                  </div>
                  <div className="manifest-create-status-option" onClick={() => setShipmentStatus('Delivered')}>
                    <input
                      type="radio"
                      id="delivered"
                      name="radio-group"
                      checked={shipmentStatus === 'Delivered' ? true : false}
                    />
                    <label style={{ display: 'flex', alignItems: 'center' }} for="delivered">
                      <p style={{ fontSize: 12, marginLeft: 5 }}>DELIVERED</p>
                    </label>
                  </div>
                  <small style={{ padding: 10, fontSize: 11, color: 'gray' }}>Schedule shipment date</small>
                  <input
                    type="date"
                    className="manifest-create-date"
                    value={shipmentDate}
                    onChange={(e) => setShipmentDate(e.target.value)}
                  />
                  <p style={{ color: 'gray', padding: 10, fontSize: 12, lineHeight: 2 }}>
                    shipment: {`{`}
                    {`[`}country:{' '}
                    <strong>
                      {`"`}
                      {`${country || '?'}`}
                      {`"`}
                    </strong>
                    , company:{' '}
                    <strong>
                      {`"`}
                      {`${importer || '?'}`}
                      {`"`}
                    </strong>
                    , address:{' '}
                    <strong>
                      {`"`}
                      {`${address || '?'}`}
                      {`"`}
                    </strong>
                    , address2:{' '}
                    <strong>
                      {`"`}
                      {`${address2 || '?'}`}
                      {`"`}
                    </strong>
                    , address3:{' '}
                    <strong>
                      {`"`}
                      {`${address3 || '?'}`}
                      {`"`}
                    </strong>
                    , town:{' '}
                    <strong>
                      {`"`}
                      {`${town || '?'}`}
                      {`"`}
                    </strong>
                    , city:{' '}
                    <strong>
                      {`"`}
                      {`${city || '?'}`}
                      {`"`}
                    </strong>
                    , state:{' '}
                    <strong>
                      {`"`}
                      {`${state || '?'}`}
                      {`"`}
                    </strong>
                    , postal_code:{' '}
                    <strong>
                      {`"`}
                      {`${postal || '?'}`}
                      {`"`}
                    </strong>
                    , phone_number:{' '}
                    <strong>
                      {`"`}
                      {`${phone || '?'}`}
                      {`"`}
                    </strong>
                    , contact_name:{' '}
                    <strong>
                      {`"`}
                      {`${contact || '?'}`}
                      {`"`}
                    </strong>
                    , email_address:{' '}
                    <strong>
                      {`"`}
                      {`${email || '?'}`}
                      {`"`}
                    </strong>
                    , chargeable_weight:{' '}
                    <strong>
                      {`"`}
                      {`${weightInput || '?'}`}
                      {`"`}
                    </strong>
                    , amount_pcs:{' '}
                    <strong>
                      {`"`}
                      {`${pcsInput || '?'}`}
                      {`"`}
                    </strong>
                    , shipment_value:{' '}
                    <strong>
                      {`"`}
                      {`${valueInput || '?'}`}
                      {`"`}
                    </strong>
                    {`]`}
                    {`}`}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        {method !== '' && (
          <div>
            <div className="form-upload-header">
              <div className="form-upload-header-option">
                <BsInfoCircleFill size={25} />
                <p>
                  Warning: If you select <strong style={{ textDecoration: 'underline' }}>Discrepency & Modify</strong>,
                  AWB will be taken from your file.
                </p>
              </div>
              <div className="form-upload-header-option">
                <BsInfoCircleFill size={25} />
                <p>
                  Warning: If you select <strong style={{ textDecoration: 'underline' }}>New shipment file</strong>, AWB
                  will be generated randomly.
                </p>
              </div>
              {fileName === '' && (
                <p
                  onClick={handleExportExcel}
                  className="download-format-manifest"
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    width: 'fit-content',
                    fontWeight: 600,
                    color: 'blue',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Download format
                </p>
              )}
            </div>
            {fileName === '' && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 35,
                  marginBottom: 50,
                }}
              >
                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="input-file-upload"
                    onChange={handleChange}
                  />
                  <label id="label-file-upload" for="input-file-upload" className={dragActive ? 'drag-active' : ''}>
                    <div>
                      <p style={{ marginBottom: 20 }}>Drag and drop your file here or</p>
                      <span className="upload-button">Upload a file</span>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </div>
            )}
            {fileName !== '' && (
              <div className="manifest-attaching-content">
                <div>
                  <div className="manifest-attaching-content-option">
                    <h5>
                      -{' '}
                      <strong
                        style={{ fontWeight: 450, background: '#f8fafc', padding: 3, border: '1px solid #cbd5e1' }}
                      >
                        File name:
                      </strong>{' '}
                      {fileName}
                    </h5>
                  </div>
                  <div className="manifest-attaching-content-option">
                    <h5>
                      -{' '}
                      <strong
                        style={{ fontWeight: 450, background: '#f8fafc', padding: 3, border: '1px solid #cbd5e1' }}
                      >
                        File size:
                      </strong>{' '}
                      {niceBytes(fileSize).toString()}
                    </h5>
                  </div>
                  <div className="manifest-attaching-content-option">
                    <h5>
                      -{' '}
                      <strong
                        style={{ fontWeight: 450, background: '#f8fafc', padding: 3, border: '1px solid #cbd5e1' }}
                      >
                        File type:
                      </strong>{' '}
                      {fileType}
                    </h5>
                  </div>
                  <div className="manifest-attaching-content-option">
                    <h5>
                      -{' '}
                      <strong
                        style={{ fontWeight: 450, background: '#f8fafc', padding: 3, border: '1px solid #cbd5e1' }}
                      >
                        Total:
                      </strong>{' '}
                      {shipments.length} shipments
                    </h5>
                  </div>
                  <div className="manifest-attaching-content-option">
                    <h5>
                      -{' '}
                      <strong
                        style={{ fontWeight: 450, background: '#f8fafc', padding: 3, border: '1px solid #cbd5e1' }}
                      >
                        Uploaded from:
                      </strong>{' '}
                      {user?.displayName}
                    </h5>
                  </div>
                  <button type="button" onClick={() => window.location.reload()}>
                    Reset attachment
                  </button>
                </div>
                <input
                  type="date"
                  style={{ margin: 10, marginLeft: 105 }}
                  className="scheduling-date"
                  value={shipmentDate}
                  onChange={(e) => setShipmentDate(e.target.value)}
                />
              </div>
            )}
          </div>
        )}
        {fileName === '' && method !== '' && (
          <div className="form-upload-header-option" style={{ alignItems: 'flex-start', marginBottom: 20 }}>
            <p style={{ lineHeight: 1.7, marginLeft: 0 }}>
              <strong style={{ padding: 5, borderRadius: 20, marginRight: 3, background: 'rgb(240,240,240)' }}>
                ?
              </strong>{' '}
              Format:{' '}
              <strong style={{ fontWeight: 600, color: 'darkblue', marginLeft: 5 }}>
                [CUSTOMER / MAKH / SERVICE / HAWB (remove if create new shipment) / MAWB / MAKH / COMPANY / CONTACT /
                TYPE / STATUS / ADDRESS / ADDRESS2 / ADDRESS3 / FROM / COUNTRY / TOWN / CITY / TELLNO / POSTCODE / VALUE
                / WEIGHT / PCS / DESCRIP / CONTROLLER / RATE / ADDCORRECT / REMOTE / FSC / VAT / PLAN]
              </strong>
            </p>
          </div>
        )}
        <div className="manifest-create-bottom">
          <div className="manifest-create-bottom-container">
            <button type="button" className="manifest-cancel-button" onClick={handleCancelShipment}>
              Cancel
            </button>
            {method === '' && (
              <button type="button" className="manifest-post-button font-semibold px-3">
                Create shipment
              </button>
            )}
            {method !== '' && fileName !== '' && (
              <button type="button" className="manifest-post-button" onClick={handleCreate}>
                Create shipment
              </button>
            )}
            {method !== '' && fileName === '' && (
              <button style={{ opacity: 0.6, cursor: 'not-allowed' }} type="button" className="manifest-post-button">
                Create shipment
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
