import { Checkbox, CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FaCalendar, FaDownload, FaPrint, FaSortDown, FaSortUp } from 'react-icons/fa6';
import { GoPackage, GoPackageDependencies, GoPackageDependents } from 'react-icons/go';
import { LuFactory } from 'react-icons/lu';
import { RiPriceTag3Line } from 'react-icons/ri';
import WorkSpace from '../../components/WorkSpace';
import InvoicesGrid from './InvoicesGrid';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ReactToExcel from 'react-html-table-to-excel';
import { db } from '../../../server/firebase';
import Inv2 from '../../../components/Inv2';
import { CiSearch } from 'react-icons/ci';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import InvoicePerShipment from './InvoicePerShipment';
import { IoIosArrowForward, IoIosGitMerge } from 'react-icons/io';
import ExportInvoice from './ExportInvoice';
import { VscWholeWord } from 'react-icons/vsc';
import MergeAll from './MergeAll';
import InvoicesTable from './InvoicesTable';
export default function Invoices({ user }) {
  const [loading, setLoading] = useState(false);
  const [includeHawb, setIncludeHawb] = useState(false);
  const [displayInvoice, setDisplayInvoice] = useState(false);
  const [displayInvoicePerShipment, setDisplayInvoicePerShipment] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [mawbInput, setMawbInput] = useState('');
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [displayPrint, setDisplayPrint] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [displayExport, setDisplayExport] = useState(false);
  const [displayExportScreen, setDisplayExportScreen] = useState(false);
  const [displayMergeAll, setDisplayMergeAll] = useState(false);
  const [exportStatus, setExportStatus] = useState('');

  const refreshMawb = () => {
    setLoading(true);
    db.collection('shipments')
      .where('mawb', '==', mawbInput)
      .orderBy('timestamp', 'desc')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          alert('Không tìm thấy Shipments trong MAWB này');
          return;
        } else {
          const data = snapshot.docs.map((doc) => doc.data());
          setShipments(
            data.map((s, i) => {
              return {
                hawb: s.hawb,
                select: false,
                show: true,
                sin: s.sin,
                shipmentType: s.shipmentType,
                pack: s.pack,
                lastInvoiceUpdate:
                  s.lastInvoiceUpdate?.toDate().toLocaleString('vi-VN', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }) || '',
                updateInvoiceBy: s.updateInvoiceBy,
                invoiceValue: s.invoiceValue,
                dateTime: s.timestamp.toDate().toLocaleString('vi-VN', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }),
                pickupBy: s.pickupBy,
              };
            })
          );
          setLoading(false);
        }
      });
  };

  const getAlphabetLabel = (invoiceIndex) => {
    const letters = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ];
    let label = '';
    let remainder = invoiceIndex;
    let quotient = 0;

    while (remainder >= 0) {
      quotient = Math.floor(remainder / 26);
      label = letters[remainder % 26] + label;
      remainder = quotient - 1;
    }

    return label;
  };
  let invoiceRef = useRef();
  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const finalShipment = shipments?.filter((s) => {
    if (searchInput !== '') {
      return s.hawb.toLowerCase().includes(searchInput.toLowerCase());
    } else {
      return s;
    }
  });
  const selected = shipments.filter((s) => {
    return s.select;
  });

  const groupedData = useMemo(() => {
    return finalShipment?.reduce((acc, item) => {
      const { pack } = item;
      if (!acc[pack]) {
        acc[pack] = [];
      }
      acc[pack].push(item);
      return acc;
    }, {});
  }, [finalShipment]);

  const sortedPacks = Object.keys(groupedData).sort((a, b) => {
    const [aPart, aDecimal] = a.split('.').map(Number);
    const [bPart, bDecimal] = b.split('.').map(Number);

    if (aPart !== bPart) {
      return aPart - bPart;
    } else {
      return aDecimal - bDecimal;
    }
  });

  return (
    <div className="w-full">
      <table className="bg-white h-[90vh] overflow-auto hidden" id="invoice-table">
        {sortedPacks.map((packing) => (
          <tr>
            <tr>
              <td style={{ border: '1px solid' }}>Bag: {packing}</td>
            </tr>
            <tr>
              <td style={{ border: '1px solid' }}>Tên mặt hàng</td>
              <td style={{ border: '1px solid' }}>Mã số hàng hoá</td>
              <td style={{ border: '1px solid' }}>Lượng hàng</td>
              <td style={{ border: '1px solid' }}>Đơn vị</td>
            </tr>
            {groupedData[packing]
              .filter((s) => {
                return s.shipmentType === 'WPX';
              })
              .map((element, index) => (
                <>
                  {includeHawb && <tr>
                    <td style={{ border: '1px solid', color: 'white', backgroundColor: 'darkblue' }}>{element.hawb}</td>
                  </tr>}
                  {element?.invoiceValue.map((item) => (
                    <tr>
                      <td style={{ border: '1px solid' }}>{item.itemVietnamCommonity}</td>
                      <td style={{ border: '1px solid' }}>{item.itemHsCode}</td>
                      <td style={{ border: '1px solid' }}>{item.itemQuantity}</td>
                      <td style={{ border: '1px solid' }}>{item.itemUnit}</td>
                    </tr>
                  ))}
                </>
              ))}
          </tr>
        ))}
      </table>
      {displayInvoicePerShipment && (
        <InvoicePerShipment
          shipments={selected}
          setDisplayInvoicePerShipment={() => setDisplayInvoicePerShipment(false)}
        />
      )}
      {displayExportScreen && (
        <ExportInvoice
          returnFunction={() => setDisplayExportScreen(false)}
          exportStatus={exportStatus}
          shipments={exportStatus !== 'Export tổng' ? selected : shipments}
          includeHawb={includeHawb}
          setIncludeHawb={setIncludeHawb}
        />
      )}
      {displayMergeAll && <MergeAll />}
      {displayInvoice && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-white z-[9999] h-screen overflow-auto">
          <div className="invoice3" ref={invoiceRef}>
            <div className="p-2 flex items-center font-semibold">
              <p>HẢI QUAN VIỆT NAM</p>
              <p className="ml-auto">PHỤ LỤC TỜ KHAI HÀNG HOÁ XUẤT KHẨU</p>
              <p className="font-normal text-[13px] italic ml-2">HQ/2015-PLXK</p>
            </div>
            <div className=" mt-2 border-solid border-[1px] border-black" style={{ borderBottom: 'none' }}>
              <div style={{ borderBottom: '1px solid' }} className="p-2 py-[2px]">
                <div className="flex items-center ">
                  <p className="text-[13px] w-[210px]">Chi cục Hải quan đăng ký tờ khai:</p>
                  <p className="font-semibold text-[15px] w-[25%]">SB. Tân Sơn Nhất</p>
                  <p className="text-[13px] w-[26%]">
                    Phụ lục số: <span className="font-bold ml-2 text-[15px]">01</span>
                  </p>
                  <p className="text-[13px]">Số tờ khai:</p>
                </div>
                <div className="flex mt-1">
                  <p className="text-[13px] w-[210px]">Chi cục Hải quan cửa khẩu xuất:</p>
                  <p className="font-semibold w-[25%] text-[15px]" style={{ borderTop: '1px solid' }}>
                    SB. Tân Sơn Nhất
                  </p>
                  <p className="text-[13px] w-[26%]">Ngày, giờ đăng ký:</p>
                  <p className="text-[13px]">Loại hình:</p>
                  <p className="font-semibold">H21</p>
                </div>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[6%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>Số TT</p>
                </div>
                <div
                  className="w-[80%] break-words p-1 text-[12px] px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>15. Mô tả hàng hoá</p>
                </div>
                <div
                  className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>16. Mã số hàng hoá</p>
                </div>
                <div
                  className="w-[19%]  p-1 text-[12px] text-center px-[1px] "
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>17. Xuất xứ</p>
                </div>
                <div
                  className="w-[25%]  p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>18. Lượng hàng</p>
                </div>
                <div
                  className="w-[19%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>19. Đơn vị tính</p>
                </div>
                <div
                  className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{
                    boxSizing: 'border-box',
                  }}
                >
                  <p>20. Đơn giá nguyên tệ</p>
                </div>
                <div
                  className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                >
                  <p>21. Trị giá nguyên tệ</p>
                </div>
              </div>
              {sortedPacks.map((packing) => (
                <div key={packing}>
                  <div className="flex" style={{ borderBottom: '1px solid' }}>
                    <div
                      className="w-[6%] break-words p-1 px-[1px] text-[12px] text-center"
                      style={{
                        borderRight: '1px solid',
                        boxSizing: 'border-box',
                      }}
                    >
                      <p>B</p>
                    </div>
                    <div
                      className="w-[80%] break-words p-1 text-[12px] px-[1px]"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p className="ml-1 font-semibold text-slate-600">
                        {packing === 'undefined' ? 'Chưa đóng bao' : packing}
                      </p>
                    </div>

                    <div
                      className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    ></div>

                    <div
                      className="w-[19%]  p-1 text-[12px] text-center px-[1px] whitespace-nowrap"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p>VN</p>
                    </div>
                    <div
                      className="w-[25%] whitespace-nowrap p-1 text-[12px] text-center px-[1px]"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      1
                    </div>
                    <div
                      className="w-[19%] break-words text-[12px] text-center px-[1px] flex items-center justify-center"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      #
                    </div>
                    <div
                      className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                      style={{ boxSizing: 'border-box' }}
                    >
                      {/* <p>{Number(el.itemUnitPrice).toFixed(2)}</p> */}
                      <p>#</p>
                    </div>
                    <div
                      className="w-[30%] break-words text-[12px] text-center px-[1px] flex"
                      style={{ boxSizing: 'border-box', borderLeft: '1px solid' }}
                    >
                      <div className="w-[60%] flex p-1">
                        {/* <p className="ml-auto">{el.itemValue?.toFixed(2)}</p> */}
                        <p className="ml-auto">#</p>
                      </div>
                      <div className="w-[40%] p-1" style={{ borderLeft: '1px solid' }}>
                        <p>#</p>
                      </div>
                    </div>
                  </div>
                  {groupedData[packing].map((item, index) => {
                    if (item.shipmentType === 'WPX') {
                      const mergedData = item.invoiceValue?.reduce((acc, curr) => {
                        const existingItem = acc.find(
                          (item) => item.itemVietnamCommonity === curr.itemVietnamCommonity
                        );
                        if (existingItem) {
                          existingItem.itemUnitPrice = +existingItem.itemUnitPrice + +curr.itemUnitPrice;
                          existingItem.itemQuantity = +existingItem.itemQuantity + +curr.itemQuantity;
                          existingItem.itemValue = +existingItem.itemValue + +curr.itemValue;
                        } else {
                          acc.push({ ...curr });
                        }
                        return acc;
                      }, []);
                      return mergedData?.map((el, i) => {
                        return (
                          <div className="flex" style={{ borderBottom: '1px solid' }}>
                            <div
                              className="w-[6%] break-words p-1 px-[1px] text-[12px] text-center"
                              style={{
                                borderRight: '1px solid',
                                boxSizing: 'border-box',
                              }}
                            >
                              <p>{i === 0 ? index + 1 : ''}</p>
                            </div>
                            <div
                              className="w-[80%] break-words p-1 text-[12px] px-[1px]"
                              style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                            >
                              <p className="ml-1">
                                <span>{i + 1}. </span>
                                {el.itemVietnamCommonity || el.itemCommonity}
                              </p>
                            </div>

                            <div
                              className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                              style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                            >
                              <p>{el.itemHsCode}</p>
                            </div>

                            <div
                              className="w-[19%]  p-1 text-[12px] text-center px-[1px] whitespace-nowrap"
                              style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                            >
                              <p>VN</p>
                            </div>
                            <div
                              className="w-[25%] whitespace-nowrap p-1 text-[12px] text-center px-[1px]"
                              style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                            >
                              <p>{el.itemQuantity}</p>
                            </div>
                            <div
                              className="w-[18.9%] break-words text-[12px] text-center flex items-center justify-center px-[1.5px]"
                              style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                            >
                              <p className="unit-after-edit">{el.itemUnit}</p>
                              <input
                                type="text"
                                className="w-[90%] p-1 text-center h-full outline-none"
                                value={el.itemUnit}
                                name="itemUnit"
                                onChange={(e) => {
                                  setSelectedShipment(
                                    shipments.map((sd7) => {
                                      if (sd7.hawb === item.hawb) {
                                        return {
                                          ...sd7,
                                          invoiceValue: sd7.invoiceValue.map((element1, index) => {
                                            if (index === i) {
                                              element1.itemUnit = e.target.value;
                                            }
                                            return element1;
                                          }),
                                        };
                                      }
                                      return sd7;
                                    })
                                  );
                                }}
                              />
                            </div>
                            <div
                              className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                              style={{ boxSizing: 'border-box' }}
                            >
                              <p>{Number(el.itemUnitPrice).toFixed(2)}</p>
                            </div>
                            <div
                              className="w-[30%] break-words text-[12px] text-center px-[1px] flex"
                              style={{ boxSizing: 'border-box', borderLeft: '1px solid' }}
                            >
                              <div className="w-[60%] flex p-1">
                                <p className="ml-auto">{el.itemValue?.toFixed(2)}</p>
                              </div>
                              <div className="w-[40%] p-1" style={{ borderLeft: '1px solid' }}>
                                <p>USD</p>
                              </div>
                            </div>
                          </div>
                        );
                      });
                    } else {
                      return (
                        <div className="flex" style={{ borderBottom: '1px solid' }}>
                          <div
                            className="w-[6%] break-words p-1 px-[1px] text-[12px] text-center"
                            style={{
                              borderRight: '1px solid',
                              boxSizing: 'border-box',
                            }}
                          >
                            <p>{index + 1}</p>
                          </div>
                          <div
                            className="w-[80%] break-words p-1 text-[12px] px-[1px]"
                            style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                          >
                            <p className="ml-1">
                              <span>a. </span>
                              Chứng từ
                            </p>
                          </div>

                          <div
                            className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                            style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                          ></div>

                          <div
                            className="w-[19%]  p-1 text-[12px] text-center px-[1px] whitespace-nowrap"
                            style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                          >
                            <p>VN</p>
                          </div>
                          <div
                            className="w-[25%] whitespace-nowrap p-1 text-[12px] text-center px-[1px]"
                            style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                          >
                            1
                          </div>
                          <div
                            className="w-[19%] break-words text-[12px] text-center px-[1px] flex items-center justify-center"
                            style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                          >
                            DOX
                          </div>
                          <div
                            className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                            style={{ boxSizing: 'border-box' }}
                          >
                            {/* <p>{Number(el.itemUnitPrice).toFixed(2)}</p> */}
                            <p>#</p>
                          </div>
                          <div
                            className="w-[30%] break-words text-[12px] text-center px-[1px] flex"
                            style={{ boxSizing: 'border-box', borderLeft: '1px solid' }}
                          >
                            <div className="w-[60%] flex p-1">
                              {/* <p className="ml-auto">{el.itemValue?.toFixed(2)}</p> */}
                              <p className="ml-auto">#</p>
                            </div>
                            <div className="w-[40%] p-1" style={{ borderLeft: '1px solid' }}>
                              <p>USD</p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              ))}
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div className="w-[6%] break-words p-1 px-[1px] text-[11px] text-center">
                  <p>#</p>
                </div>
                <div
                  className="w-[100%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>Hàng mới 100%</p>
                </div>
                <div
                  className="w-[200px] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  Cộng
                </div>

                <div
                  className="w-[200px] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                ></div>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>Số TT</p>
                </div>
                <div className="w-full">
                  <div className="flex" style={{ borderBottom: '1px solid' }}>
                    <div
                      className="w-[100%] break-words p-1 text-[12px] px-[1px] flex items-cneter justify-center"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p>22. Thuế xuất nhập khẩu</p>
                    </div>
                    <div
                      className="w-[400px] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                      style={{ boxSizing: 'border-box' }}
                    >
                      <p>23. Thu khác</p>
                    </div>
                  </div>
                  <div className="flex w-[100%]">
                    <div
                      className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p>Trị giá tính thuế</p>
                    </div>
                    <div
                      className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p>Thuế xuất (%)</p>
                    </div>
                    <div
                      className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      Tiền thuế
                    </div>
                    <div
                      className="w-[400px]  text-[12px] text-center px-[1px] flex"
                      style={{ boxSizing: 'border-box' }}
                    >
                      <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}>
                        <p>tính thu</p>
                      </div>
                      <div className="w-[220px] p-1">
                        <p>Tỷ lệ (%)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>1</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div
                        className="w-[50px] flex items-center justify-center p-1"
                        style={{ borderRight: '1px solid' }}
                      >
                        <p className="opacity-0">.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>2</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div
                        className="w-[50px] flex items-center justify-center p-1"
                        style={{ borderRight: '1px solid' }}
                      >
                        <p className="opacity-0">.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                    borderBottom: '1px solid',
                  }}
                >
                  <p>3</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  ></div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div
                        className="w-[50px] flex items-center justify-center p-1"
                        style={{ borderRight: '1px solid' }}
                      >
                        <p className="opacity-0">.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid transparent',
                    boxSizing: 'border-box',
                    borderBottom: '1px solid',
                  }}
                >
                  <p className="opacity-0">.</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-end px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  >
                    <p>Cộng:</p>
                  </div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div className="w-[50px] flex items-center justify-center p-1" style={{ border: '1px solid' }}>
                        <p>Cộng:</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center p-1" style={{ borderBottom: '1px solid' }}>
                <p className="text-[12px]">25. Lượng hàng, số hiệu container</p>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>Số TT</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p>a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p>b. Số lượng kiện trong container</p>
                  </div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    >
                      <p>Lượng hàng</p>
                    </div>
                    <div className="w-[125px] p-1 flex items-center justify-center">
                      <p>Địa điẻm đóng phí</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>1</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p>Kiện</p>
                  </div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    ></div>
                    <div className="w-[125px] p-1 flex items-center justify-center"></div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>2</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    ></div>
                    <div className="w-[125px] p-1 flex items-center justify-center"></div>
                  </div>
                </div>
              </div>
              <div className="flex h-[50px]" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>3</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    ></div>
                    <div className="w-[125px] p-1 flex items-center justify-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex">
              <div className="ml-auto w-[200px] break-words text-center mr-[50px] mt-2 text-[12px]">
                <p>
                  27. Tôi xin cam đoan, chịu trách nhiệm trước pháp luật về nội dung khai trên tờ khai <br /> Ngày tháng
                  năm 2024 <br /> (Người khai ký, ghi rõ họ tên, đóng dấu)
                </p>
              </div>
            </div>
          </div>

          <div className="sticky bottom-0 bg-white z-10 p-2" style={{ borderTop: '1px solid' }}>
            <div className="flex items-center">
              <div
                className="p-1.5 rounded-[7px] bg-slate-200 hover:bg-slate-300 cursor-pointer px-3.5 font-semibold active:bg-slate-400 select-none"
                onClick={() => {
                  setDisplayInvoice(false);
                }}
              >
                <p>Quay trở lại</p>
              </div>
              <div
                className="p-1.5 rounded-[7px] bg-slate-200 hover:bg-slate-300 cursor-pointer px-3.5 flex items-center ml-1.5 active:bg-slate-400 select-none font-semibold"
                onClick={printInvoice}
              >
                <FaPrint size={20} />
                <p className="ml-1.5">In tờ khai</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <WorkSpace
        refreshShipment={refreshMawb}
        loading={loading}
        // lastUpdateStatus={lastUpdateStatus}
        finalShipment={finalShipment}
        systemStatus="Very Good"
        currentRoute="Invoices"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          refreshMawb();
        }}
      >
        <TextField
          label="Mã chuyến bay"
          value={mawbInput}
          required
          onChange={(e) => setMawbInput(e.target.value)}
          variant="filled"
          style={{ margin: 12, width: '98%' }}
          placeholder="Nhập số MAWB cần tìm"
        />
      </form>
      {!loading && shipments.length !== 0 && (
        <div className="m-[12px] mt-0">
          <div className="flex">
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayPrint(false);
              }}
            >
              <div className="relative">
                <div
                  className="flex items-center p-[7px] font-semibold text-white bg-sky-600 rounded-full w-fit px-3.5 cursor-pointer hover:bg-sky-700 active:bg-sky-800 select-none active:scale-95"
                  onClick={() => {
                    setDisplayPrint(!displayPrint);
                  }}
                >
                  <FaPrint size={20} />
                  <p className="mx-1.5">In tờ khai</p>
                  <FaSortDown size={20} className="mt-[-10px]" />
                </div>
                {displayPrint && (
                  <div className="absolute bg-white mt-2 w-[230px] z-10 p-1 rounded-[7px] shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]">
                    <div
                      className="p-1 rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer px-3 font-medium"
                      onClick={() => {
                        setDisplayPrint(false);
                        setDisplayInvoice(true);
                      }}
                    >
                      <p>In tất cả</p>
                    </div>
                    <div
                      className="p-1 rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer px-3 font-medium"
                      onClick={() => {
                        setDisplayPrint(false);
                        setDisplayInvoicePerShipment(true);
                      }}
                    >
                      <p>In shipment được chọn</p>
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
            <div className="relative">
              <CiSearch size={23} className="absolute left-3 top-2" />
              <input
                type="text"
                placeholder="Tìm shipment"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="p-[7px] bg-slate-200 rounded-full px-[35px] w-[280px] newBooking-search focus:bg-white ml-1.5"
              />
              {searchInput !== '' && (
                <MdOutlineClear
                  size={23}
                  className="absolute right-3 top-2 rounded-full cursor-pointer hover:bg-slate-200 active:scale-90"
                  onClick={() => setSearchInput('')}
                />
              )}
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayExport(false);
              }}
            >
              <div className="relative">
                <div
                  className="flex items-center p-[7px] font-semibold bg-slate-200 rounded-full w-fit px-3.5 cursor-pointer hover:bg-slate-300 active:bg-slate-400 ml-1.5 select-none active:scale-95 text-slate-700"
                  onClick={() => {
                    setDisplayExport(!displayExport);
                  }}
                >
                  <FaDownload size={20} />
                  <p className="mx-1.5">Export file</p>
                  <FaSortDown size={23} className="mt-[-10px]" />
                </div>
                {displayExport && (
                  <div className="absolute bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] p-1 mx-1.5 w-[280px] mt-1 rounded-[7px]">
                    <div
                      className="p-1 bg-slate-100 rounded-[5px] mt-1 px-2.5 font-medium cursor-pointer hover:bg-slate-200 active:bg-slate-300 select-none flex items-center"
                      onClick={() => {
                        setDisplayExportScreen(true);
                        setExportStatus('Export tổng');
                      }}
                    >
                      <p>Export tổng</p>
                      <IoIosArrowForward size={20} className="ml-auto" />
                    </div>
                    <div
                      className="p-1 bg-slate-100 rounded-[5px] mt-1 px-2.5 font-medium cursor-pointer hover:bg-slate-200 active:bg-slate-300 select-none flex items-center"
                      onClick={() => {
                        setDisplayExportScreen(true);
                        setExportStatus(`Export ${selected.length} shipments`);
                      }}
                    >
                      <p>Export shipment được chọn</p>
                      <IoIosArrowForward size={20} className="ml-auto" />
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
            {/* <div className="flex items-center p-[7px] font-semibold bg-slate-200 rounded-full w-fit px-3.5 cursor-pointer hover:bg-slate-300 active:bg-slate-400 ml-1.5 select-none active:scale-95 text-slate-700">
              <IoIosGitMerge size={20} />
              <p className="mx-1.5">Gộp hàng tổng</p>
            </div> */}
            <div className="flex items-center p-[7px] font-semibold bg-emerald-50 text-emerald-500 rounded-full w-fit px-3.5 cursor-pointer hover:bg-emerald-100 active:bg-emerald-200 ml-auto select-none active:scale-95 mr-1">
              <p className="mx-1.5">Đang chọn {selected.length} lô</p>
              <FaSortDown size={23} className="mt-[-10px]" />
            </div>
          </div>

          {sortedPacks.map((packing) => (
            <div key={packing}>
              <div className="p-1 px-2.5  text-lg bg-amber-100 text-amber-600 w-fit my-2  rounded-full flex items-center">
                <GoPackage size={25} />
                <p className="mx-1.5">
                  Bag:{' '}
                  <span className="text-black font-semibold">
                    {packing === 'undefined' ? 'Danh sách hàng hoá chưa đóng bao' : packing}
                  </span>
                </p>
              </div>
              {groupedData[packing].map((element, index) => (
                <div className="my-1.5 ">
                  <div
                    className="flex items-center border-solid border-slate-300 border-b-[1px] border-t-0 border-r-0 border-l-0 bg-slate-200 hover:bg-slate-300 cursor-pointer active:bg-slate-400"
                    onClick={() => {
                      setSelectedShipment(
                        shipments.map((sd) => {
                          if (sd.hawb === element.hawb) {
                            sd.show = !sd.show;
                          }
                          return sd;
                        })
                      );
                    }}
                  >
                    <div className="p-1.5 w-full">
                      <div className="flex items-center w-full">
                        <Checkbox
                          checked={element.select}
                          style={{ padding: 7, backgroundColor: 'white', marginRight: 7 }}
                          onChange={(e) => {
                            e.stopPropagation();
                            setSelectedShipment(
                              shipments.map((sd) => {
                                if (sd.hawb === element.hawb) {
                                  sd.select = e.target.checked;
                                }
                                return sd;
                              })
                            );
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                        <div className="w-[25px] h-[25px] rounded-full flex items-center justify-center text-slate-600 font-semibold bg-slate-100">
                          <p>{index + 1}</p>
                        </div>
                        <div className="ml-2 font-medium text-[darkblue] bg-white px-2.5 rounded-[5px] shadow-[rgba(0,0,0,0.1)_0px_1px_3px_0px,rgba(0,0,0,0.06)_0px_1px_2px_0px]">
                          <p>{element.hawb}</p>
                        </div>
                        <div className="ml-2 font-medium">
                          <p>/ {element.sin} /</p>
                        </div>
                        <div className="ml-2 font-medium text-slate-600">
                          <p>Pick up: {element.pickupBy}</p>
                        </div>
                        <div className="ml-auto font-medium text-slate-600 flex items-center gap-1.5">
                          <FaCalendar size={20} />
                          <p>Giao nhận: {element.dateTime}</p>
                        </div>
                        <div className="w-[32px] h-[32px] rounded-full bg-slate-100 flex items-center justify-center cursor-pointer hover:bg-slate-200 active:bg-slate-300 ml-auto">
                          {element.show ? (
                            <FaSortDown size={28} className="mt-[-10px]" />
                          ) : (
                            <FaSortUp size={28} className="mt-[10px]" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {element.show && (
                    <div className="mt-1">
                      <div className="bg-white w-full flex">
                        <div className="p-1.5 w-[50%] flex items-center border-solid border-slate-300 border-[1px] text-slate-600">
                          <GoPackageDependents size={25} />
                          <p className="ml-2">Mô tả hàng hoá</p>
                        </div>
                        <div className="p-1.5 w-[23%] flex items-center border-solid border-slate-300 border-[1px] text-slate-600">
                          <p className="ml-0.5">Mã số hàng hoá</p>
                        </div>
                        <div className="p-1.5 w-[17%] flex items-center border-solid border-slate-300 border-[1px] text-slate-600">
                          <LuFactory size={25} />
                          <p className="ml-2">Xuất xứ</p>
                        </div>
                        <div className="p-1.5 w-[17%] flex items-center border-solid border-slate-300 border-[1px] text-slate-600">
                          <p className="ml-0.5">Lượng hàng</p>
                        </div>
                        <div className="p-1.5 w-[17%] flex items-center border-solid border-slate-300 border-[1px] text-slate-600">
                          <p className="ml-0.5">Đơn vị</p>
                        </div>
                        <div className="p-1.5 w-[17%] flex items-center border-solid border-slate-300 border-[1px] text-slate-600">
                          <p className="ml-0.5">Đơn giá</p>
                        </div>
                        <div className="p-1.5 w-[17%] flex items-center border-solid border-slate-300 border-[1px] text-slate-600">
                          <RiPriceTag3Line size={25} />
                          <p className="ml-2">Trị giá</p>
                        </div>
                      </div>
                      <InvoicesGrid
                        invoiceValue={element?.invoiceValue}
                        shipmentType={element?.shipmentType}
                        hawb={element.hawb}
                        user={user}
                        lastInvoiceUpdate={element?.lastInvoiceUpdate}
                        updateInvoiceBy={element?.updateInvoiceBy}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {loading && (
        <div className="flex items-center justify-center">
          <CircularProgress size={25} />
          <p className="mx-2.5">Loading...</p>
        </div>
      )}
    </div>
  );
}
