import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
export default function StaffBill2({
  type,
  exportSelected,
  description,
  invoice,
  toEmail,
  pickupBy,
  usage,
  eori,
  totalInvoiceValue,
  invoiceValue,
  weight,
  uid,
  grossWeight,
  fromPhone2,
  toPhone2,
  chargeable,
  fromEmail,
  amount,
  hawb,
  date,
  summaryGood,
  exporter,
  fromCountry,
  fromCity,
  fromState,
  fromTown,
  fromAddress,
  fromAddress2,
  fromAddress3,
  fromPostal,
  fromPhone,
  fromName,
  importer,
  toCountry,
  toCity,
  toState,
  toTown,
  toAddress,
  toAddress2,
  toAddress3,
  toPostal,
  toPhone,
  toName,
  skdAccount,
}) {
  const [itemValue, setItemValue] = useState([]);

  return (
    <>
      <div className="bill leading-5">
        <div className="hawb-bill">
          <div className="hawb-bill-content">
            <div className="hawb-bill-left">
              <div className="hawb-bill-left-header leading-5 font-semibold text-[14px]">
                <h5>From (Exporter)</h5>
              </div>
              <div className="hawb-bill-address-info">
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Name / Company:</h5>
                  <p>{exporter}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Address:</h5>
                  <div>
                    <p>{fromAddress}</p>
                    <p style={{ height: '1.85vh' }}>{fromAddress2}</p>
                    <p style={{ height: '1.85vh' }}>{fromAddress3}</p>
                  </div>
                </div>
                {fromCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">Ward:</h5>
                    <p>{fromState}</p>
                  </div>
                )}
                {fromCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">District:</h5>
                    <p>{fromTown}</p>
                  </div>
                )}
                {fromCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">City:</h5>
                    <p>{fromCity}</p>
                  </div>
                )}
                {fromCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">City:</h5>
                    <p>{fromCity}</p>
                  </div>
                )}
                {fromCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">Town:</h5>
                    <p>{fromTown}</p>
                  </div>
                )}
                {fromCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">State:</h5>
                    <p>{fromState}</p>
                  </div>
                )}
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Postal Code:</h5>
                  <p>{fromPostal}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Country:</h5>
                  <p>{fromCountry}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Telephone:</h5>
                  <p>{fromPhone}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Contact Name:</h5>
                  <p>{fromName}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Email Address:</h5>
                  <p>{fromEmail}</p>
                </div>
              </div>
              <div className="hawb-bill-left-header leading-5 font-semibold text-[14px]">
                <h5>To (Importer)</h5>
              </div>
              <div className="hawb-bill-address-info">
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Name / Company:</h5>
                  <p>{importer}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Address:</h5>
                  <div>
                    <p>{toAddress}</p>
                    <p style={{ height: '1.85vh' }}>{toAddress2}</p>
                    <p style={{ height: '1.85vh' }}>{toAddress3}</p>
                  </div>
                </div>
                {toCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">Ward:</h5>
                    <p>{toState}</p>
                  </div>
                )}
                {toCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">District:</h5>
                    <p>{toTown}</p>
                  </div>
                )}
                {toCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">City:</h5>
                    <p>{toCity}</p>
                  </div>
                )}
                {toCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">Town:</h5>
                    <p>{toTown}</p>
                  </div>
                )}
                {toCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">City:</h5>
                    <p>{toCity}</p>
                  </div>
                )}
                {toCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail leading-5">
                    <h5 className="font-bold">State:</h5>
                    <p>{toState}</p>
                  </div>
                )}
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Postal Code:</h5>
                  <p>{toPostal}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-bold">Country:</h5>
                  <p>{toCountry}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-semibold">Telephone:</h5>
                  <p>{toPhone}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-semibold">Contact Name:</h5>
                  <p>{toName}</p>
                </div>
                <div className="hawb-bill-address-detail leading-5">
                  <h5 className="font-semibold">Email Address:</h5>
                  <p>{toEmail}</p>
                </div>
              </div>
            </div>
            <div className="hawb-bill-right">
              <div className="hawb-bill-right-primary-option">
                <div className="hawb-bill-little-helper">
                  <div className="hawb-bill-right-logo">
                    <img src="https://i.imgur.com/L6PnhXq.png" alt="" />
                    <div className="hawb-bill-right-primary-text">
                      <h3>SKYDART</h3>
                      <h3>EXPRESS</h3>
                    </div>
                  </div>
                  <p>Document and parcel worldwide express</p>
                </div>
                <div className="hawb-bill-right-qrcode">
                  <QRCode
                    value={`https://skydart-booking-app.web.app/scan/${hawb}`}
                    size={130}
                    imageSettings={{
                      src: 'https://i.imgur.com/L6PnhXq.png',
                      excavate: true,
                      size: 100,
                    }}
                    level={'H'}
                    includeMargin={true}
                  />
                </div>
              </div>
              <center>
                <h5 style={{ color: 'darkblue', fontWeight: 600, fontSize: 15 }}>https://www.skydart.com.vn</h5>
              </center>
              <div className="hawb-bill-number" style={{ borderBottom: 'none' }}>
                <div className="hawb-bill-number-left" style={{ borderBottom: '1px solid' }}>
                  <h5 style={{ marginTop: 5, marginLeft: 17 }}>Waybill</h5>
                </div>
                <div className="hawb-bill-number-right">
                  <h4>{hawb}</h4>
                </div>
              </div>
              <div className="hawb-bill-package-info">
                <div className="hawb-bill-package-header">
                  <div className="hawb-bill-package-header-option">
                    <h5>Total number of packages</h5>
                  </div>
                  <div className="hawb-bill-package-header-option">
                    <h5>Gross Weight</h5>
                  </div>
                  <div className="hawb-bill-package-header-option">
                    <h5>Chargeable Weight</h5>
                  </div>
                </div>
                <div className="hawb-bill-package-body">
                  <div className="hawb-bill-package-body-option" style={{ borderBottom: '1px solid' }}>
                    <h5>{amount}</h5>
                  </div>
                  <div className="hawb-bill-package-body-option" style={{ borderBottom: '1px solid' }}>
                    <h5>{(Math.round(weight * 100) / 100).toFixed(2)} KG</h5>
                  </div>
                  <div className="hawb-bill-package-body-option" style={{ borderBottom: '1px solid' }}>
                    <h5>{(Math.round(chargeable * 100) / 100).toFixed(2)} KG</h5>
                  </div>
                </div>
              </div>
              <div className="hawb-bill-package-description">
                <h5>Full description of goods</h5>
              </div>
              <div className="hawb-bill-items">
                {type === 'WPX' && (
                  <div className="hawb-bill-items-option">
                    {exportSelected ? (
                      <div>
                        <h5>{description}</h5>
                      </div>
                    ) : (
                      invoiceValue.map((s, index) => (
                        <div key={index}>
                          <h5>{s.itemCommonity + ',' || ''}</h5>
                        </div>
                      ))
                    )}
                  </div>
                )}
                {type === 'DOX' && (
                  <div className="hawb-bill-items-option">
                    <center>
                      <h5 style={{ textAlign: 'center' }}>Document</h5>
                    </center>
                  </div>
                )}
                {type === 'ECS' && (
                  <div className="hawb-bill-items-option1">
                    {invoiceValue.map((s, index) => (
                      <div key={index}>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Commodity:</h5>
                          <h5>{s.itemCommonity}</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Quantity:</h5>
                          <h5>
                            {s.itemQuantity} {s.itemUnit}
                          </h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Unit Price:</h5>
                          <h5>{(Math.round(s.itemUnitPrice * 100) / 100).toFixed(2)} USD</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Amount:</h5>
                          <h5>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5>{usage}</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5>Consignee IOSS / TIN / EORI NUMBER / ID Tax Number: </h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5>{eori}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hawb-bill-footer">
            <div className="hawb-bill-footer-left">
              <div>
                <div className="hawb-bill-footer-left-option">
                  <h5 className="whitespace-nowrap leading-3">Shipper's Account</h5>
                  <p style={{ fontSize: 9 }}>{fromEmail}</p>
                </div>
                <div className="hawb-bill-footer-pay-option">
                  <h5>Credit</h5>
                  <input type="checkbox" />
                </div>
                <div className="hawb-bill-footer-pay-option">
                  <h5>Prepaid</h5>
                  <input type="checkbox" />
                </div>
              </div>
              <div className="hawb-bill-footer-agreement-option">
                <h5 className="text-[14px] leading-3">Shipper's Agreement And Signature</h5>
              </div>
            </div>
            <div className="hawb-bill-footer-right">
              <div>
                <div className="hawb-bill-footer-right-option">
                  <h5>Picked up by: {pickupBy}</h5>
                </div>
                <div
                  className="hawb-bill-footer-shipment-date"
                  style={{
                    border: 'none',
                    marginLeft: -1,
                    borderLeft: '1px solid',
                    background: 'white',
                  }}
                >
                  <h5>Shipment Date: {date}</h5>
                </div>
              </div>
              <div
                className="hawb-bill-footer-right-shipment-type flex items-center justify-center"
                style={{ borderTop: '1px solid' }}
              >
                <center>
                  <h1 className="font-bold text-3xl">{type}</h1>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
