import React, { useState, useEffect, useRef } from 'react';
import { Package } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import axios from 'axios';
import './Scanning.css';
import { FcHighPriority, FcOk } from 'react-icons/fc';
import { db } from '../../../server/firebase';
import { Alert, CircularProgress } from '@mui/material';
export default function Scanning({ userData, user }) {
  const [loading, setLoading] = useState(false);
  const [shipmentPickUp, setShipmentPickUp] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [traceTime, setTraceTime] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [exactWeightInput, setExactWeightInput] = useState('');
  const [shipmentData, setShipmentData] = useState([]);
  const [flights, setFlights] = useState([]);
  const [flightName, setFlightName] = useState('');
  const [mawb, setMawb] = useState('');
  const [note, setNote] = useState('');
  const [toggle, setToggle] = useState(false);
  const [noteInput, setNoteInput] = useState('');
  const [current, setCurrent] = useState([]);
  const { hawb } = useParams();
  const navigate = useNavigate();
  const [shipmentInvoice, setShipmentInvoice] = useState([]);
  const [shipmentPackage, setShipmentPackage] = useState([]);
  const [shipmentRepack, setShipmentRepack] = useState([]);
  const [consoleText, setConsoleText] = useState('');
  const [eamService, setEamService] = useState('');
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [shipmentImage, setShipmentImage] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [rate, setRate] = useState(0);
  const [doxRate, setDoxRate] = useState(0);
  const [dhlFsc, setDhlFsc] = useState(0);
  const [fedexFsc, setFedexFsc] = useState(0);
  const [upsFsc, setUpsFsc] = useState(0);
  const [skdFsc, setSkdFsc] = useState(0);
  const [fsc, setFsc] = useState(0);
  const [validPickup, setValidPickup] = useState(false);
  useEffect(() => {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const monthName = months[newDate.getMonth()];
    setTraceTime(`${year}-${month}-${date}`);
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    setDateTime(`${hour}:${min}`);
  }, []);

  useEffect(() => {
    if (images.length < 1) {
      return;
    } else {
      const newImageUrls = [];
      images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
      setImageURLs(newImageUrls);
    }
  }, [images]);
  // let flightRef = useRef();
  // useEffect(() => {
  //     let handler = (e) => {
  //         if (!flightRef.current.contains(e.target)) {
  //             setToggle(false);

  //         }
  //     }
  //     document.addEventListener("mousedown", handler);
  //     return () => {
  //         document.removeEventListener("mousedown", handler);
  //     }
  // }, [])

  // if (userData.employee === "no") {
  //     navigate(`/shipment/tracking/${hawb}`)
  // }
  useEffect(() => {
    db.collection('shipments')
      .doc(hawb)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          setShipmentData(snapshot.data());
          if (snapshot.data().shipmentType === 'WPX') {
            setShipmentInvoice(snapshot.data().invoiceValue);
            setShipmentPackage(snapshot.data().packageValue);
            setShipmentRepack(snapshot.data().repack || snapshot.data().packageValue);
            setShipmentImage(snapshot.data()?.imageUrls);
          } else {
            setExactWeightInput(snapshot.data().weight);
            setShipmentPackage([]);
            setShipmentRepack([]);
            setShipmentInvoice([]);
            setShipmentImage([]);
          }
        } else {
          setShipmentData([]);
          setShipmentPackage([]);
          setShipmentInvoice([]);
          setShipmentImage([]);
          setShipmentRepack([]);
        }
      });
    axios.get('https://test.skydart.com.vn/api/flight/info').then((res) => {
      setFlights(res.data);
    });
  }, []);
  useEffect(() => {
    if (shipmentData.service === 'DHL') {
      setEamService('SDH');
    }
    if (shipmentData.service === 'FIP') {
      setEamService('FIP');
    }
  }, [shipmentData.service]);
  const doxWeight = exactWeightInput >= 30 ? Math.ceil(exactWeightInput) : Math.ceil(exactWeightInput / 0.5) * 0.5;
  const rebookShipment = () => {
    let arr = [];
    if (shipmentData.shipmentType === 'WPX') {
      shipmentInvoice.map((s) => {
        arr.push(s.itemCommonity + ',');
      });
      axios
        .post('https://test.skydart.com.vn/api/create/shipment', {
          account: '786009',
          currency: 'USD',
          service: shipmentData.service,
          direct: shipmentData.direct,
          date: traceTime,
          hawb: hawb,
          mawb: 'Pending',
          makh: shipmentData.makh,
          company: shipmentData.importer,
          contact: shipmentData.toName,
          otherTerm: shipmentData.otherTerm,
          type: shipmentData.shipmentType,
          status: 'Picked Up',
          term: shipmentData.term,
          time: dateTime,
          labelUrl: shipmentData.labelUrl,
          address: shipmentData.toAddress,
          address2: shipmentData.toAddress2 || '.',
          address3: shipmentData.toAddress3,
          city: shipmentData.toCity,
          country: shipmentData.toCountry,
          code: shipmentData.code,
          phone: shipmentData.toPhone,
          phone2: shipmentData.toPhone2,
          postal: shipmentData.toPostal,
          state: shipmentData.toState,
          value: shipmentData.totalInvoiceValue,
          town: shipmentData.toTown,
          chargeable: shipmentData.totalCharge,
          amount: shipmentData.totalPackage,
          totalItem: shipmentData.totalItem,
          transport: shipmentData.transportation,
          description: arr.toString(),
          controller: shipmentData.controller || 'Pending',
          eamFormat: 'NDX',
          dateTime: new Date(),
          airline: 'Pending',
          reweight: exactWeight,
          etd: 'Pending',
          agent: 'Pending',
          subStatus: 'Pending',
          sin: shipmentData.sin || 'Null',
          sender: shipmentData.exporter,
          note: 'Null',
          perform: 'Null',
          fromEmail: shipmentData.fromEmail,
          fromCity: shipmentData.fromCity,
          totalValue: '0.00',
          rate: rate,
          comestic: '0.00',
          addressCorrection: '0.00',
          remote: '0.00',
          surcharge: '0.00',
          surcharge2: '0.00',
          surcharge3: '0.00',
          fsc: fsc,
          fscValue: shipmentData.fscValue || '0.00',
          vat: '0',
          vatValue: '0.00',
          paymentPickup: 'CRE',
          paymentAcc: 'CRE',
          paymentCompany: 'CRE',
          paymentStatus: 'Un Paid',
          pickupBy: userData.displayName,
          collectBy: 'Pending',
          rateUpdate: shipmentData.createAt,
          sellNote: 'Pending',
          com: '0.00',
          sellNote: 'Pending',
          paymentSubStatus: 'Rebook',
          eamService: shipmentData.eamService || eamService,
          usage: shipmentData.usage,
          pack: 'Pending',
          plan: shipmentData.plan || 'Pending',
          exactWeight: exactWeightDus,
          pickupNote: noteInput || 'Null',
          gnNote: 'Pending',
          exactCharge: exactWeight,
        })
        .then(() => {
          setCheckStatus(true);
        })
        .catch((err) => {
          setCheckStatus(false);
        });
    } else if (shipmentData.shipmentType === 'DOX') {
      axios
        .post('https://test.skydart.com.vn/api/create/shipment', {
          account: '786009',
          currency: 'USD',
          service: shipmentData.service,
          date: traceTime,
          hawb: hawb,
          mawb: 'Pending',
          makh: shipmentData.makh,
          company: shipmentData.importer,
          direct: shipmentData.direct,
          contact: shipmentData.toName,
          otherTerm: shipmentData.otherTerm,
          type: shipmentData.shipmentType,
          status: 'Picked Up',
          term: shipmentData.term,
          labelUrl: shipmentData.labelUrl,
          time: dateTime,
          address: shipmentData.toAddress,
          address2: shipmentData.toAddress2 || '.',
          address3: shipmentData.toAddress3,
          city: shipmentData.toCity,
          country: shipmentData.toCountry,
          code: shipmentData.code,
          phone: shipmentData.toPhone,
          phone2: shipmentData.toPhone2,
          postal: shipmentData.toPostal,
          state: shipmentData.toState,
          value: shipmentData.totalInvoiceValue,
          town: shipmentData.toTown,
          chargeable: shipmentData.totalCharge,
          amount: shipmentData.totalPackage,
          totalItem: shipmentData.totalItem,
          transport: shipmentData.transportation,
          description: 'Document',
          controller: shipmentData.controller || 'Pending',
          eamFormat: 'DOX',
          dateTime: new Date(),
          airline: 'Pending',
          reweight: exactWeight,
          etd: 'Pending',
          agent: 'Pending',
          subStatus: 'Pending',
          sin: shipmentData.sin || 'Null',
          sender: shipmentData.exporter,
          note: 'Null',
          perform: 'Null',
          fromEmail: shipmentData.fromEmail,
          fromCity: shipmentData.fromCity,
          totalValue: '0.00',
          rate: doxRate,
          comestic: '0.00',
          addressCorrection: '0.00',
          remote: '0.00',
          surcharge: '0.00',
          surcharge2: '0.00',
          surcharge3: '0.00',
          fsc: fsc,
          fscValue: shipmentData.fscValue || '0.00',
          vat: '0',
          vatValue: '0.00',
          paymentPickup: 'CRE',
          paymentAcc: 'CRE',
          paymentCompany: 'CRE',
          paymentStatus: 'Un Paid',
          pickupBy: userData.displayName,
          collectBy: 'Pending',
          rateUpdate: shipmentData.createAt,
          sellNote: 'Pending',
          com: '0.00',
          sellNote: 'Pending',
          paymentSubStatus: 'Rebook',
          eamService: shipmentData.eamService || eamService,
          usage: shipmentData.usage,
          pack: 'Pending',
          plan: shipmentData.plan || 'Pending',
          exactWeight: exactWeightDus,
          pickupNote: noteInput || 'Null',
          exactCharge: exactWeight,
          gnNote: 'Pending',
        })
        .then(() => {
          setCheckStatus(true);
        })
        .catch((err) => {
          setCheckStatus(false);
        });
    }
  };
  const test = () => {
    // db.collection("shipments").doc(hawb).update({
    //     status: shipmentData.status,
    // })
    let arr = [];
    if (shipmentData.shipmentType === 'WPX') {
      shipmentInvoice.map((s) => {
        arr.push(s.itemCommonity + ',');
      });
      axios.post('https://test.skydart.com.vn/api/create/shipment', {
        account: '786009',
        currency: 'USD',
        service: shipmentData.service,
        date: traceTime,
        hawb: hawb,
        mawb: 'Pending',
        makh: shipmentData.makh,
        company: shipmentData.importer,
        contact: shipmentData.toName,
        otherTerm: shipmentData.otherTerm,
        type: shipmentData.shipmentType,
        status: 'Pending',
        term: shipmentData.term,
        time: dateTime,
        address: shipmentData.toAddress,
        address2: shipmentData.toAddress2 || '.',
        address3: shipmentData.toAddress3,
        city: shipmentData.toCity,
        country: shipmentData.toCountry,
        code: shipmentData.code,
        phone: shipmentData.toPhone,
        phone2: shipmentData.toPhone2,
        postal: shipmentData.toPostal,
        state: shipmentData.toState,
        value: shipmentData.totalInvoiceValue,
        town: shipmentData.toTown,
        chargeable: shipmentData.totalCharge,
        amount: shipmentData.totalPackage,
        totalItem: shipmentData.totalItem,
        transport: shipmentData.transportation,
        description: arr.toString(),
        controller: shipmentData.controller || 'Pending',
        eamFormat: 'NDX',
        dateTime: new Date(),
        airline: 'Pending',
        reweight: exactWeight,
        etd: 'Pending',
        agent: 'Pending',
        subStatus: 'Pending',
        sin: 'Null',
        sender: shipmentData.exporter,
        note: 'Null',
        perform: 'Null',
        fromEmail: shipmentData.fromEmail,
        fromCity: shipmentData.fromCity,
        totalValue: '0.00',
        rate: shipmentData.rate || '0.00',
        comestic: '0.00',
        addressCorrection: '0.00',
        remote: '0.00',
        surcharge: '0.00',
        surcharge2: '0.00',
        surcharge3: '0.00',
        fsc: shipmentData.fsc || '0',
        fscValue: shipmentData.fscValue || '0.00',
        vat: '0',
        vatValue: '0.00',
        paymentPickup: 'CRE',
        paymentAcc: 'CRE',
        paymentCompany: 'CRE',
        paymentStatus: 'Un Paid',
        pickupBy: userData.displayname,
        collectBy: 'Pending',
        rateUpdate: shipmentData.createAt,
        sellNote: 'Pending',
        com: '0.00',
        sellNote: 'Pending',
        paymentSubStatus: 'Rebook',
        eamService: shipmentData.eamService || eamService,
        usage: shipmentData.usage,
        pack: 'Pending',
        plan: shipmentData.plan || 'Pending',
        exactWeight: exactWeight,
      });
    } else if (shipmentData.shipmentType === 'DOX') {
      axios.post('https://test.skydart.com.vn/api/create/shipment', {
        account: '786009',
        currency: 'USD',
        service: shipmentData.service,
        date: shipmentData.createAt,
        hawb: hawb,
        mawb: 'Pending',
        makh: shipmentData.makh,
        company: shipmentData.importer,
        contact: shipmentData.toName,
        otherTerm: shipmentData.otherTerm,
        type: shipmentData.shipmentType,
        status: shipmentData.status,
        term: shipmentData.term,
        time: dateTime,
        address: shipmentData.toAddress,
        address2: shipmentData.toAddress2 || '.',
        address3: shipmentData.toAddress3,
        city: shipmentData.toCity,
        country: shipmentData.toCountry,
        code: shipmentData.code,
        phone: shipmentData.toPhone,
        phone2: shipmentData.toPhone2,
        postal: shipmentData.toPostal,
        state: shipmentData.toState,
        value: shipmentData.totalInvoiceValue,
        town: shipmentData.toTown,
        chargeable: shipmentData.totalCharge,
        amount: shipmentData.totalPackage,
        totalItem: shipmentData.totalItem,
        transport: shipmentData.transportation,
        description: 'Document',
        controller: shipmentData.controller || 'Pending',
        eamFormat: 'DOX',
        dateTime: new Date(),
        airline: 'Pending',
        reweight: shipmentData.totalCharge,
        etd: 'Pending',
        agent: 'Pending',
        subStatus: 'Pending',
        sin: 'Null',
        sender: shipmentData.exporter,
        note: 'Null',
        perform: 'Null',
        fromEmail: shipmentData.fromEmail,
        fromCity: shipmentData.fromCity,
        totalValue: '0.00',
        rate: shipmentData.rate || '0.00',
        comestic: '0.00',
        addressCorrection: '0.00',
        remote: '0.00',
        surcharge: '0.00',
        surcharge2: '0.00',
        surcharge3: '0.00',
        fsc: shipmentData.fsc || '0',
        fscValue: shipmentData.fscValue || '0.00',
        vat: '0',
        vatValue: '0.00',
        paymentPickup: 'CRE',
        paymentAcc: 'CRE',
        paymentCompany: 'CRE',
        paymentStatus: 'Un Paid',
        pickupBy: userData.displayname,
        collectBy: 'Pending',
        rateUpdate: shipmentData.createAt,
        sellNote: 'Pending',
        com: '0.00',
        sellNote: 'Pending',
        paymentSubStatus: 'Rebook',
        eamService: shipmentData.eamService || eamService,
        usage: shipmentData.usage,
        pack: 'Pending',
        plan: shipmentData.plan || 'Pending',
        exactWeight: exactWeight,
      });
    }

    alert('Succeed');
  };

  // const pickupShipment = () => {
  //     setLoading(true);
  //     if (current.length !== 1) {
  //         db.collection("shipments").doc(hawb).update({
  //             status: "Picked Up",
  //             desc: firebase.firestore.FieldValue.arrayUnion(({
  //                 time: traceTime + "T" + dateTime + ":00",
  //                 location: "VIETNAM - VIETNAM - HCM",
  //                 stat: "Shipment picked up by Skydart",
  //             })),
  //             pickupBy: userData.displayName,
  //         })
  //         rebookShipment();
  //         setTimeout(() => {
  //             setLoading(false);
  //             setShipmentPickUp(true);
  //             setTimeout(() => {
  //                 setShipmentPickUp(false);
  //             }, 1000)
  //         }, 500)
  //     } else if (current.length === 1) {
  //         db.collection("shipments").doc(hawb).update({
  //             status: "Picked Up",
  //             desc: firebase.firestore.FieldValue.arrayUnion(({
  //                 time: traceTime + "T" + dateTime + ":00",
  //                 location: "VIETNAM - VIETNAM - HCM",
  //                 stat: "Shipment picked up by Skydart",
  //             })),
  //             pickupBy: userData.displayName,
  //         })
  //         axios.put("https://test.skydart.com.vn/api/update/pickup", { hawb: hawb, status: "Picked Up" }).then(() => {
  //             setTimeout(() => {
  //                 setLoading(false);
  //                 setShipmentPickUp(true);
  //                 setTimeout(() => {
  //                     setShipmentPickUp(false);
  //                 }, 1000)
  //             }, 500)
  //         })
  //     }
  // }
  useEffect(() => {
    db.collection('surcharge')
      .doc('skd-surcharge')
      .onSnapshot((doc) => {
        if (doc.exists) {
          setDhlFsc(doc.data().dhl);
          setFedexFsc(doc.data().fedex);
          setUpsFsc(doc.data().ups);
          setSkdFsc(doc.data().skd);
        }
      });
  }, []);
  // const exactWeight = exactWeightInput >= 30 ? Math.ceil(exactWeightInput) : Math.ceil(exactWeightInput / 0.5) * 0.5;
  // const exactWeightDUS = exactWeightInput;
  const getRate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (exactWeightDus < shipmentData.totalCharge) {
      alert('Hãy nhập số KG trên bill');
      setLoading(false);
    } else if (exactWeight < shipmentData.totalCharge) {
      if (shipmentData.plan === 'FWD') {
        if (shipmentData.service === 'DHL') {
          setFsc(dhlFsc);
          db.collection('dhl')
            .doc(shipmentData.toCountry)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
                if (shipmentData.shipmentType === 'DOX') {
                  if (doxWeight === 0) {
                    setDoxRate(0);
                  } else if (doxWeight === 0.5) {
                    setDoxRate(doc.data().doxHalf);
                  } else if (doxWeight === 1) {
                    setDoxRate(doc.data().doxOne);
                  } else if (doxWeight === 1.5) {
                    setDoxRate(doc.data().doxOneHalf);
                  } else if (doxWeight === 2) {
                    setDoxRate(doc.data().doxTwo);
                  } else {
                    alert('Số kg không phù hợp');
                    setValidPickup(false);
                  }
                } else if (shipmentData.shipmentType === 'WPX') {
                  if (exactWeight === 0) {
                    setRate(0);
                  } else if (exactWeight === 0.5) {
                    setRate(doc.data().half);
                  } else if (exactWeight === 1) {
                    setRate(doc.data().one);
                  } else if (exactWeight === 1.5) {
                    setRate(doc.data().oneHalf);
                  } else if (exactWeight === 2) {
                    setRate(doc.data().two);
                  } else if (exactWeight === 2.5) {
                    setRate(doc.data().twoHalf);
                  } else if (exactWeight === 3) {
                    setRate(doc.data().three);
                  } else if (exactWeight === 3.5) {
                    setRate(doc.data().threeHalf);
                  } else if (exactWeight === 4) {
                    setRate(doc.data().four);
                  } else if (exactWeight === 4.5) {
                    setRate(doc.data().fourHalf);
                  } else if (exactWeight === 5) {
                    setRate(doc.data().five);
                  } else if (exactWeight === 5.5) {
                    setRate(doc.data().fiveHalf);
                  } else if (exactWeight === 6) {
                    setRate(doc.data().six);
                  } else if (exactWeight === 6.5) {
                    setRate(doc.data().sixHalf);
                  } else if (exactWeight === 7) {
                    setRate(doc.data().seven);
                  } else if (exactWeight === 7.5) {
                    setRate(doc.data().sevenHalf);
                  } else if (exactWeight === 8) {
                    setRate(doc.data().eight);
                  } else if (exactWeight === 8.5) {
                    setRate(doc.data().eightHalf);
                  } else if (exactWeight === 9) {
                    setRate(doc.data().nine);
                  } else if (exactWeight === 9.5) {
                    setRate(doc.data().nineHalf);
                  } else if (exactWeight === 10) {
                    setRate(doc.data().ten);
                  } else if (exactWeight === 10.5) {
                    setRate(doc.data().tenHalf);
                  } else if (exactWeight === 11) {
                    setRate(doc.data().eleven);
                  } else if (exactWeight === 11.5) {
                    setRate(doc.data().elevenHalf);
                  } else if (exactWeight === 12) {
                    setRate(doc.data().twelve);
                  } else if (exactWeight === 12.5) {
                    setRate(doc.data().twelveHalf);
                  } else if (exactWeight === 13) {
                    setRate(doc.data().thirteen);
                  } else if (exactWeight === 13.5) {
                    setRate(doc.data().thirteenHalf);
                  } else if (exactWeight === 14) {
                    setRate(doc.data().fourteen);
                  } else if (exactWeight === 14.5) {
                    setRate(doc.data().fourteenHalf);
                  } else if (exactWeight === 15) {
                    setRate(doc.data().fifteen);
                  } else if (exactWeight === 15.5) {
                    setRate(doc.data().fifteenHalf);
                  } else if (exactWeight === 16) {
                    setRate(doc.data().sixteen);
                  } else if (exactWeight === 16.5) {
                    setRate(doc.data().sixteenHalf);
                  } else if (exactWeight === 17) {
                    setRate(doc.data().seventeen);
                  } else if (exactWeight === 17.5) {
                    setRate(doc.data().seventeenHalf);
                  } else if (exactWeight === 18) {
                    setRate(doc.data().eightteen);
                  } else if (exactWeight === 18.5) {
                    setRate(doc.data().eightteenHalf);
                  } else if (exactWeight === 19) {
                    setRate(doc.data().nineteen);
                  } else if (exactWeight === 19.5) {
                    setRate(doc.data().nineteenHalf);
                  } else if (exactWeight === 20) {
                    setRate(doc.data().twenty);
                  } else if (exactWeight === 20.5) {
                    setRate(doc.data().twentyHalf);
                  } else if (exactWeight === 21) {
                    setRate(doc.data().twentyone);
                  } else if (exactWeight === 21.5) {
                    setRate(doc.data().twentyoneHalf);
                  } else if (exactWeight === 22) {
                    setRate(doc.data().twentytwo);
                  } else if (exactWeight === 22.5) {
                    setRate(doc.data().twentytwoHalf);
                  } else if (exactWeight === 23) {
                    setRate(doc.data().twentythree);
                  } else if (exactWeight === 23.5) {
                    setRate(doc.data().twentythreeHalf);
                  } else if (exactWeight === 24) {
                    setRate(doc.data().twentyfour);
                  } else if (exactWeight === 24.5) {
                    setRate(doc.data().twentyfourHalf);
                  } else if (exactWeight === 25) {
                    setRate(doc.data().twentyfive);
                  } else if (exactWeight === 25.5) {
                    setRate(doc.data().twentyfiveHalf);
                  } else if (exactWeight === 26) {
                    setRate(doc.data().twentysix);
                  } else if (exactWeight === 26.5) {
                    setRate(doc.data().twentysixHalf);
                  } else if (exactWeight === 27) {
                    setRate(doc.data().twentyseven);
                  } else if (exactWeight === 27.5) {
                    setRate(doc.data().twentysevenHalf);
                  } else if (exactWeight === 28) {
                    setRate(doc.data().twentyeight);
                  } else if (exactWeight === 28.5) {
                    setRate(doc.data().twentyeightHalf);
                  } else if (exactWeight === 29) {
                    setRate(doc.data().twentynine);
                  } else if (exactWeight === 29.5) {
                    setRate(doc.data().twentynineHalf);
                  } else if (exactWeight === 30) {
                    setRate(doc.data().thirty);
                  } else if (exactWeight > 30 && exactWeight < 71) {
                    setRate(doc.data().small * exactWeight);
                  } else if (exactWeight > 70 && exactWeight < 301) {
                    setRate(doc.data().medium * exactWeight);
                  } else if (exactWeight > 300 && exactWeight < 1000) {
                    setRate(doc.data().large * exactWeight);
                  }
                }
              } else {
                setRate(0);
                setFsc(0);
                setDoxRate(0);
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
              }
            });
        } else if (shipmentData.service === 'FIP') {
          setFsc(fedexFsc);
          db.collection('fedex')
            .doc(shipmentData.toCountry)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
                if (shipmentData.shipmentType === 'DOX') {
                  if (exactWeight === 0) {
                    setDoxRate(0);
                  } else if (exactWeight === 0.5) {
                    setDoxRate(doc.data().doxHalf);
                  } else if (exactWeight === 1) {
                    setDoxRate(doc.data().doxOne);
                  } else if (exactWeight === 1.5) {
                    setDoxRate(doc.data().doxOneHalf);
                  } else if (exactWeight === 2) {
                    setDoxRate(doc.data().doxTwo);
                  }
                } else if (shipmentData.shipmentType === 'WPX') {
                  if (exactWeight === 0) {
                  } else if (exactWeight === 0.5) {
                    setRate(doc.data().half);
                  } else if (exactWeight === 1) {
                    setRate(doc.data().one);
                  } else if (exactWeight === 1.5) {
                    setRate(doc.data().oneHalf);
                  } else if (exactWeight === 2) {
                    setRate(doc.data().two);
                  } else if (exactWeight === 2.5) {
                    setRate(doc.data().twoHalf);
                  } else if (exactWeight === 3) {
                    setRate(doc.data().three);
                  } else if (exactWeight === 3.5) {
                    setRate(doc.data().threeHalf);
                  } else if (exactWeight === 4) {
                    setRate(doc.data().four);
                  } else if (exactWeight === 4.5) {
                    setRate(doc.data().fourHalf);
                  } else if (exactWeight === 5) {
                    setRate(doc.data().five);
                  } else if (exactWeight === 5.5) {
                    setRate(doc.data().fiveHalf);
                  } else if (exactWeight === 6) {
                    setRate(doc.data().six);
                  } else if (exactWeight === 6.5) {
                    setRate(doc.data().sixHalf);
                  } else if (exactWeight === 7) {
                    setRate(doc.data().seven);
                  } else if (exactWeight === 7.5) {
                    setRate(doc.data().sevenHalf);
                  } else if (exactWeight === 8) {
                    setRate(doc.data().eight);
                  } else if (exactWeight === 8.5) {
                    setRate(doc.data().eightHalf);
                  } else if (exactWeight === 9) {
                    setRate(doc.data().nine);
                  } else if (exactWeight === 9.5) {
                    setRate(doc.data().nineHalf);
                  } else if (exactWeight === 10) {
                    setRate(doc.data().ten);
                  } else if (exactWeight === 10.5) {
                    setRate(doc.data().tenHalf);
                  } else if (exactWeight === 11) {
                    setRate(doc.data().eleven);
                  } else if (exactWeight === 11.5) {
                    setRate(doc.data().elevenHalf);
                  } else if (exactWeight === 12) {
                    setRate(doc.data().twelve);
                  } else if (exactWeight === 12.5) {
                    setRate(doc.data().twelveHalf);
                  } else if (exactWeight === 13) {
                    setRate(doc.data().thirteen);
                  } else if (exactWeight === 13.5) {
                    setRate(doc.data().thirteenHalf);
                  } else if (exactWeight === 14) {
                    setRate(doc.data().fourteen);
                  } else if (exactWeight === 14.5) {
                    setRate(doc.data().fourteenHalf);
                  } else if (exactWeight === 15) {
                    setRate(doc.data().fifteen);
                  } else if (exactWeight === 15.5) {
                    setRate(doc.data().fifteenHalf);
                  } else if (exactWeight === 16) {
                    setRate(doc.data().sixteen);
                  } else if (exactWeight === 16.5) {
                    setRate(doc.data().sixteenHalf);
                  } else if (exactWeight === 17) {
                    setRate(doc.data().seventeen);
                  } else if (exactWeight === 17.5) {
                    setRate(doc.data().seventeenHalf);
                  } else if (exactWeight === 18) {
                    setRate(doc.data().eightteen);
                  } else if (exactWeight === 18.5) {
                    setRate(doc.data().eightteenHalf);
                  } else if (exactWeight === 19) {
                    setRate(doc.data().nineteen);
                  } else if (exactWeight === 19.5) {
                    setRate(doc.data().nineteenHalf);
                  } else if (exactWeight === 20) {
                    setRate(doc.data().twenty);
                  } else if (exactWeight === 20.5) {
                    setRate(doc.data().twentyHalf);
                  } else if (exactWeight > 20.5 && exactWeight < 45) {
                    setRate(0);
                  } else if (exactWeight > 44 && exactWeight < 71) {
                    setRate(0);
                  } else if (exactWeight > 70 && exactWeight < 100) {
                    setRate(0);
                  } else if (exactWeight > 99 && exactWeight < 300) {
                    setRate(0);
                  } else if (exactWeight >= 300) {
                    setRate(0);
                  }
                }
              } else {
                setRate(0);
                setFsc(0);
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
                setDoxRate(0);
              }
            });
        } else if (shipmentData.service === 'UPS') {
          setFsc(upsFsc);
          // setRate(0);
          // setDoxRate(0);
          // setValidPickup(true);
          // setLoading(false);
          // setShipmentPickUp(true);
          // setTimeout(() => {
          //     setShipmentPickUp(false);
          // }, 1000)
          db.collection('ups')
            .doc(shipmentData.toCountry)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
                if (exactWeight === 0) {
                  setRate(0);
                } else if (exactWeight === 0.5) {
                  setRate(doc.data().half);
                } else if (exactWeight === 1) {
                  setRate(doc.data().one);
                } else if (exactWeight === 1.5) {
                  setRate(doc.data().oneHalf);
                } else if (exactWeight === 2) {
                  setRate(doc.data().two);
                } else if (exactWeight === 2.5) {
                  setRate(doc.data().twoHalf);
                } else if (exactWeight === 3) {
                  setRate(doc.data().three);
                } else if (exactWeight === 3.5) {
                  setRate(doc.data().threeHalf);
                } else if (exactWeight === 4) {
                  setRate(doc.data().four);
                } else if (exactWeight === 4.5) {
                  setRate(doc.data().fourHalf);
                } else if (exactWeight === 5) {
                  setRate(doc.data().five);
                } else if (exactWeight === 5.5) {
                  setRate(doc.data().fiveHalf);
                } else if (exactWeight === 6) {
                  setRate(doc.data().six);
                } else if (exactWeight === 6.5) {
                  setRate(doc.data().sixHalf);
                } else if (exactWeight === 7) {
                  setRate(doc.data().seven);
                } else if (exactWeight === 7.5) {
                  setRate(doc.data().sevenHalf);
                } else if (exactWeight === 8) {
                  setRate(doc.data().eight);
                } else if (exactWeight === 8.5) {
                  setRate(doc.data().eightHalf);
                } else if (exactWeight === 9) {
                  setRate(doc.data().nine);
                } else if (exactWeight === 9.5) {
                  setRate(doc.data().nineHalf);
                } else if (exactWeight === 10) {
                  setRate(doc.data().ten);
                } else if (exactWeight === 10.5) {
                  setRate(doc.data().tenHalf);
                } else if (exactWeight === 11) {
                  setRate(doc.data().eleven);
                } else if (exactWeight === 11.5) {
                  setRate(doc.data().elevenHalf);
                } else if (exactWeight === 12) {
                  setRate(doc.data().twelve);
                } else if (exactWeight === 12.5) {
                  setRate(doc.data().twelveHalf);
                } else if (exactWeight === 13) {
                  setRate(doc.data().thirteen);
                } else if (exactWeight === 13.5) {
                  setRate(doc.data().thirteenHalf);
                } else if (exactWeight === 14) {
                  setRate(doc.data().fourteen);
                } else if (exactWeight === 14.5) {
                  setRate(doc.data().fourteenHalf);
                } else if (exactWeight === 15) {
                  setRate(doc.data().fifteen);
                } else if (exactWeight === 15.5) {
                  setRate(doc.data().fifteenHalf);
                } else if (exactWeight === 16) {
                  setRate(doc.data().sixteen);
                } else if (exactWeight === 16.5) {
                  setRate(doc.data().sixteenHalf);
                } else if (exactWeight === 17) {
                  setRate(doc.data().seventeen);
                } else if (exactWeight === 17.5) {
                  setRate(doc.data().seventeenHalf);
                } else if (exactWeight === 18) {
                  setRate(doc.data().eightteen);
                } else if (exactWeight === 18.5) {
                  setRate(doc.data().eightteenHalf);
                } else if (exactWeight === 19) {
                  setRate(doc.data().nineteen);
                } else if (exactWeight === 19.5) {
                  setRate(doc.data().nineteenHalf);
                } else if (exactWeight === 20) {
                  setRate(doc.data().twenty);
                } else if (exactWeight === 20.5) {
                  setRate(doc.data().twentyHalf);
                } else if (exactWeight > 20.5 && exactWeight < 45) {
                  setRate(0);
                } else if (exactWeight > 44 && exactWeight < 71) {
                  setRate(0);
                } else if (exactWeight > 70 && exactWeight < 100) {
                  setRate(0);
                } else if (exactWeight > 99 && exactWeight < 300) {
                  setRate(0);
                } else if (exactWeight >= 300) {
                  setRate(0);
                }
              } else {
                setRate(0);
                setFsc(0);
                setDoxRate(0);
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
              }
            });
        } else if (shipmentData.service === 'SKD') {
          setFsc(0);
          setLoading(false);
          setValidPickup(true);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
          setRate(0);
          setDoxRate(0);
        } else if (shipmentData.service === 'DUS') {
          setFsc(0);
          db.collection('dus')
            .doc('United States')
            .get()
            .then((doc) => {
              if (doc.exists) {
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
                if (shipmentData.shipmentType === 'DOX') {
                  if (exactWeight === 0) {
                    setDoxRate(0);
                  } else if (exactWeight === 0.5) {
                    setDoxRate(doc.data().doxHalf);
                  } else if (exactWeight === 1) {
                    setDoxRate(doc.data().doxOne);
                  } else if (exactWeight === 1.5) {
                    setDoxRate(doc.data().doxOneHalf);
                  } else if (exactWeight === 2) {
                    setDoxRate(doc.data().doxTwo);
                  }
                } else if (shipmentData.shipmentType === 'WPX') {
                  if (exactWeight === 0) {
                    setRate(0);
                  } else if (exactWeight === 0.5) {
                    setRate(doc.data().half);
                  } else if (exactWeight === 1) {
                    setRate(doc.data().one);
                  } else if (exactWeight === 1.5) {
                    setRate(doc.data().oneHalf);
                  } else if (exactWeight === 2) {
                    setRate(doc.data().two);
                  } else if (exactWeight === 2.5) {
                    setRate(doc.data().twoHalf);
                  } else if (exactWeight === 3) {
                    setRate(doc.data().three);
                  } else if (exactWeight === 3.5) {
                    setRate(doc.data().threeHalf);
                  } else if (exactWeight === 4) {
                    setRate(doc.data().four);
                  } else if (exactWeight === 4.5) {
                    setRate(doc.data().fourHalf);
                  } else if (exactWeight === 5) {
                    setRate(doc.data().five);
                  } else if (exactWeight === 5.5) {
                    setRate(doc.data().fiveHalf);
                  } else if (exactWeight === 6) {
                    setRate(doc.data().six);
                  } else if (exactWeight === 6.5) {
                    setRate(doc.data().sixHalf);
                  } else if (exactWeight === 7) {
                    setRate(doc.data().seven);
                  } else if (exactWeight === 7.5) {
                    setRate(doc.data().sevenHalf);
                  } else if (exactWeight === 8) {
                    setRate(doc.data().eight);
                  } else if (exactWeight === 8.5) {
                    setRate(doc.data().eightHalf);
                  } else if (exactWeight === 9) {
                    setRate(doc.data().nine);
                  } else if (exactWeight === 9.5) {
                    setRate(doc.data().nineHalf);
                  } else if (exactWeight === 10) {
                    setRate(doc.data().ten);
                  } else if (exactWeight === 10.5) {
                    setRate(doc.data().tenHalf);
                  } else if (exactWeight === 11) {
                    setRate(doc.data().eleven);
                  } else if (exactWeight === 11.5) {
                    setRate(doc.data().elevenHalf);
                  } else if (exactWeight === 12) {
                    setRate(doc.data().twelve);
                  } else if (exactWeight === 12.5) {
                    setRate(doc.data().twelveHalf);
                  } else if (exactWeight === 13) {
                    setRate(doc.data().thirteen);
                  } else if (exactWeight === 13.5) {
                    setRate(doc.data().thirteenHalf);
                  } else if (exactWeight === 14) {
                    setRate(doc.data().fourteen);
                  } else if (exactWeight === 14.5) {
                    setRate(doc.data().fourteenHalf);
                  } else if (exactWeight === 15) {
                    setRate(doc.data().fifteen);
                  } else if (exactWeight === 15.5) {
                    setRate(doc.data().fifteenHalf);
                  } else if (exactWeight === 16) {
                    setRate(doc.data().sixteen);
                  } else if (exactWeight === 16.5) {
                    setRate(doc.data().sixteenHalf);
                  } else if (exactWeight === 17) {
                    setRate(doc.data().seventeen);
                  } else if (exactWeight === 17.5) {
                    setRate(doc.data().seventeenHalf);
                  } else if (exactWeight === 18) {
                    setRate(doc.data().eightteen);
                  } else if (exactWeight === 18.5) {
                    setRate(doc.data().eightteenHalf);
                  } else if (exactWeight === 19) {
                    setRate(doc.data().nineteen);
                  } else if (exactWeight === 19.5) {
                    setRate(doc.data().nineteenHalf);
                  } else if (exactWeight === 20) {
                    setRate(doc.data().twenty);
                  } else if (exactWeight > 20 && exactWeight < 50) {
                    setRate(doc.data().small * exactWeight);
                  } else if (exactWeight > 49 && exactWeight < 69) {
                    setRate(doc.data().medium * exactWeight);
                  } else if (exactWeight > 68 && exactWeight < 301) {
                    setRate(doc.data().large * exactWeight);
                  }
                }
              } else {
                setRate(0);
                setFsc(0);
                setDoxRate(0);
                setValidPickup(true);
                setLoading(false);
                setShipmentPickUp(true);
                setTimeout(() => {
                  setShipmentPickUp(false);
                }, 1000);
              }
            });
        } else {
          setRate(0);
          setFsc(0);
          setDoxRate(0);
          setValidPickup(true);
          setLoading(false);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
        }
      } else {
        setRate(0);
        setFsc(0);
        setDoxRate(0);
        setValidPickup(true);
        setLoading(false);
        setShipmentPickUp(true);
        setTimeout(() => {
          setShipmentPickUp(false);
        }, 1000);
      }
    }
  };
  const pickupShipment = (e) => {
    e.preventDefault();
    setLoading(true);
    db.collection('shipments')
      .doc(hawb)
      .update({
        status: 'Picked Up',
        desc: firebase.firestore.FieldValue.arrayUnion({
          time: traceTime + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
          stat: 'Shipment picked up by Skydart',
        }),
        repack: packaging,
        pickupBy: userData.displayName,
        pickupAt: traceTime + '-' + dateTime,
        imageUrls: [],
        pickupNote: noteInput,
      })
      .then(() => {
        setLoading(false);
        rebookShipment();
      });
  };

  const holdReason = () => {
    setLoading(true);
    db.collection('shipments').doc(hawb).update({
      note: note,
    });
    axios
      .put('https://test.skydart.com.vn/api/update/note', {
        hawb: hawb,
        note: note,
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setNote('');
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 700);
        }, 500);
      });
  };
  const shipmentPaid = () => {
    setLoading(true);
    db.collection('shipments').doc(hawb).update({
      paymentStatus: 'Pay',
    });
    axios
      .put('https://test.skydart.com.vn/api/update/shipment-paid', {
        hawb: hawb,
        paymentStatus: 'Pay',
        paymentSubStatus: 'Paid To Pick Up',
        pickupBy: userData.displayName,
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 700);
        }, 500);
      });
  };
  const shipmentUnPaid = () => {
    setLoading(true);
    db.collection('shipments').doc(hawb).update({
      paymentStatus: 'Un Paid',
    });
    setTimeout(() => {
      setLoading(false);
      setShipmentPickUp(true);
      setTimeout(() => {
        setShipmentPickUp(false);
      }, 1000);
    }, 500);
  };
  const shipmentArrived = () => {
    setLoading(true);
    db.collection('shipments')
      .doc(hawb)
      .update({
        status: 'Arrived At Skydart Origin Depot',
        desc: firebase.firestore.FieldValue.arrayUnion({
          date: traceTime,
          time: traceTime + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
          stat: 'Shipment arrived at Skydart Origin Depot',
        }),
        arrivedBy: userData.displayName,
      });
    axios
      .put('https://test.skydart.com.vn/api/update/arrived', {
        hawb: hawb,
        status: 'Arrived At Skydart Origin Depot',
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
        }, 500);
      });
  };
  const processShipment = () => {
    setLoading(true);
    db.collection('shipments')
      .doc(hawb)
      .update({
        status: 'In Process',
        desc: firebase.firestore.FieldValue.arrayUnion({
          date: traceTime,
          time: traceTime + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
          stat: "Shipment processed at Skydart Express's warehouse",
        }),
        processBy: userData.displayName,
      });
    axios
      .put('https://test.skydart.com.vn/api/update/process', {
        hawb: hawb,
        status: 'In Process',
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
        }, 500);
      });
  };
  const clearShipment = () => {
    setLoading(true);
    db.collection('shipments')
      .doc(hawb)
      .update({
        status: 'In Clearance',
        desc: firebase.firestore.FieldValue.arrayUnion({
          time: traceTime + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
          stat: 'Shipment is in clearance at Tan Son Nhat airport',
        }),
        clearanceBy: userData.displayName,
      });
    axios
      .put('https://test.skydart.com.vn/api/update/clearance', {
        hawb: hawb,
        status: 'In Clearance',
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
        }, 500);
      });
  };
  const clearedShipment = () => {
    setLoading(true);
    db.collection('shipments')
      .doc(hawb)
      .update({
        status: 'Cleared For Export',
        desc: firebase.firestore.FieldValue.arrayUnion({
          time: traceTime + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
          stat: 'Shipment cleared for export',
        }),
        clearedBy: userData.displayName,
      });
    axios
      .put('https://test.skydart.com.vn/api/update/cleared', {
        hawb: hawb,
        status: 'Cleared For Export',
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
        }, 500);
      });
  };
  const holdShipment = () => {
    setLoading(true);
    db.collection('shipments')
      .doc(hawb)
      .update({
        status: 'On Hold',
        desc: firebase.firestore.FieldValue.arrayUnion({
          date: traceTime,
          time: traceTime + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
          stat: 'Shipment on hold',
        }),
        holdBy: userData.displayName,
      });
    axios
      .put('https://test.skydart.com.vn/api/update/hold', {
        hawb: hawb,
        status: 'On Hold',
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
        }, 500);
      });
  };
  const selectFlight = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put('https://test.skydart.com.vn/api/update/flight', {
        hawb: hawb,
        airline: flightName,
        mawb: mawb,
        pack: consoleText,
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setShipmentPickUp(true);
          setFlightName('');
          setMawb('');
          setConsoleText('');
          navigate('/');
          setTimeout(() => {
            setShipmentPickUp(false);
          }, 1000);
        }, 500);
      });
  };
  const handleWeightInput = () => {
    if (exactWeight !== '') {
      db.collection('shipments')
        .doc(hawb)
        .update({
          exactWeight: exactWeight,
        })
        .then(() => {
          alert('Exact weight has been updated');
          navigate('/');
        });
    } else {
      alert('Please enter exact weight for this shipment');
    }
  };
  function onImageChange(e) {
    setImages([...e.target.files]);
  }
  const packaging = shipmentPackage.map((item) => {
    let dimCharge = 0;

    if (shipmentData.service === 'DAU') {
      dimCharge = (item.packageWidth * item.packageHeight * item.packageLength) / 6000;
    } else if (shipmentData.service === 'DUS') {
      dimCharge = (item.packageWidth * item.packageHeight * item.packageLength) / 6000;
    } else {
      dimCharge = (item.packageWidth * item.packageHeight * item.packageLength) / 5000;
    }
    if (dimCharge < item.packageWeight) {
      item.packageCharge = item.packageWeight;
    }
    if (item.packageWeight < dimCharge) {
      item.packageCharge = dimCharge;
    }
    if (item.packageCharge >= 30) {
      item.packageCharge = Math.ceil(item.packageCharge);
    }
    if (item.packageCharge < 30) {
      item.packageCharge = Math.ceil(item.packageCharge / 0.5) * 0.5;
    }
    if (item.packageWeight === '' || 0) {
      item.packageCharge = 0;
    }
    return {
      packNo: item.packNo,
      dimention: dimCharge,
      packageWidth: item.packageWidth,
      packageHeight: item.packageHeight,
      packageLength: item.packageLength,
      packageCharge: item.packageCharge,
      packageWeight: item.packageWeight,
      pack: item.pack || '',
      packSin: item.packSin || '',
      packedBy: item.packedBy || '',
      confirm: item.confirm || false,
    };
  });
  const exactWeight =
    shipmentData.shipmentType === 'WPX' ? packaging.reduce((a, v) => (a = +a + +v.packageCharge), 0) : doxWeight;
  const exactWeightDus =
    shipmentData.shipmentType === 'WPX' ? packaging.reduce((a, v) => (a = +a + +v.packageWeight), 0) : exactWeightInput;
  let handleChangePack = (i, e) => {
    let newFormValues = [...shipmentPackage];
    newFormValues[i][e.target.name] = e.target.value;
    setShipmentPackage(newFormValues);
  };
  let handleChange = (i, e) => {
    let newFormValues = [...shipmentRepack];
    newFormValues[i][e.target.name] = e.target.value;
    setShipmentRepack(newFormValues);
  };

  const consoleBag = shipmentRepack.map((item) => {
    return {
      packNo: item.packNo || '',
      packageWidth: item.packageWidth || '',
      packageHeight: item.packageHeight || '',
      packageLength: item.packageLength || '',
      packageCharge: item.packageCharge || '',
      packageWeight: item.packageWeight || '',
      pack: item.pack || '',
      packSin: item.packSin || '',
      packedBy: item.packedBy || '',
      confirm: item.confirm || false,
      id: item.id || '',
    };
  });
  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <div className="scanning fixed top-0 right-0 bottom-0 left-0 z-[9999] bg-white">
      {loading && (
        <div className="price__loading">
          <div className="price__loading__container">
            <center>
              <CircularProgress size={45} />
            </center>
          </div>
        </div>
      )}
      {!loading && shipmentPickUp && (
        <div style={{ position: 'absolute', top: '40px', width: '100%' }}>
          <Alert variant="filled" severity="success">
            Shipment Updated
          </Alert>
        </div>
      )}
      <div className="scanning__container">
        <div className="scanning__container__left">
          <img src="https://i.imgur.com/L6PnhXq.png" alt="logo" onClick={() => navigate('/')} />
          <h3>SKYDART</h3>
          <h3>EXPRESS</h3>
        </div>
        <center>
          <h1>Shipment Information</h1>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '5px',
              color: 'darkblue',
            }}
          >
            <h1>Service</h1>
            {shipmentData.direct === 'us' && (
              <h3
                style={{
                  marginRight: '5px',
                  marginLeft: 5,
                  fontWeight: 700,
                  color: 'rgb(184, 48, 48)',
                }}
              >
                {shipmentData.service},
              </h3>
            )}
            {shipmentData.service === 'UPS' && (
              <h3
                style={{
                  marginRight: '5px',
                  marginLeft: 5,
                  fontWeight: 700,
                  color: '#005ca7',
                }}
              >
                {shipmentData.service},
              </h3>
            )}
            {shipmentData.service !== 'UPS' && shipmentData.direct !== 'us' && (
              <h3
                style={{
                  marginRight: '5px',
                  marginLeft: 5,
                  fontWeight: 700,
                  color: 'green',
                }}
              >
                {shipmentData.service},
              </h3>
            )}
            <h3 style={{ marginRight: '5px' }}>{shipmentData.shipmentType}</h3>
            <Package />
          </div>
        </center>
      </div>
      <div className="scanning__shipment">
        <div className="scanning__shipment__left">
          <div className="scanning__shipment__content">
            <h5>Shipment Date</h5>
            <p>{shipmentData.createAt}</p>
          </div>
          <div className="scanning__shipment__content">
            <h5>Shipment Reference </h5>
            <p>{hawb}</p>
          </div>
        </div>
        <div className="scanning__shipment__right">
          <div className="scanning__shipment__Content" style={{ display: 'grid' }}>
            <h5 style={{ marginRight: '10px' }}>Shipment Weight</h5>
            <p>{(Math.round(shipmentData.weight * 100) / 100).toFixed(2)} KG</p>
          </div>
          <div className="scanning__shipment__Content" style={{ display: 'grid' }}>
            <h5 style={{ marginBottom: '5px' }}>Shipment Value</h5>
            <p>{shipmentData.totalInvoiceValue} USD</p>
          </div>
          <div className="scanning__shipment__Content" style={{ display: 'grid' }}>
            <h5 style={{ marginBottom: '5px' }}>Chargeable Weight</h5>
            <p>{(Math.round(shipmentData.totalCharge * 100) / 100).toFixed(2)} KG</p>
          </div>
        </div>
      </div>
      <div className="scanning__button">
        {/* {userData.role === "CEO" && <button className="scan-btn" onClick={test}>HIHIIHIHIIHI</button>} */}
        <div className="scanning-for-pick-up">
          {shipmentData.status === 'Pending' && (
            <div className="scanning-for-pick-up">
              {!validPickup && (
                <form className="scanning-pickUp-form" onSubmit={getRate}>
                  {shipmentData.shipmentType === 'WPX' &&
                    packaging.map((s, index) => (
                      <div>
                        <p
                          style={{
                            fontSize: 15,
                            width: 'fit-content',
                            background: 'rgb(240,240,240)',
                            borderRadius: '10px',
                          }}
                        >
                          {index + 1} / {packaging.length}
                        </p>
                        <input
                          required
                          type="number"
                          name="packageWeight"
                          value={s.packageWeight}
                          onChange={(e) => handleChangePack(index, e)}
                        />
                        {shipmentData.service !== 'DUS' && (
                          <p>
                            {s.packageWidth} * {s.packageHeight} * {s.packageLength} / 5000 = {s.dimention}
                          </p>
                        )}
                        {shipmentData.service === 'DUS' && (
                          <p>
                            {s.packageWidth} * {s.packageHeight} * {s.packageLength} / 6000 = {s.dimention}
                          </p>
                        )}
                      </div>
                    ))}
                  <p>Current chargeable: {exactWeight.toFixed(2)}</p>
                  <p>Shipment weight: {shipmentData.totalCharge}</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>Status:</p>
                    {!checkStatus && <FcHighPriority size={25} style={{ marginLeft: 15 }} />}
                    {checkStatus && <FcOk size={25} style={{ marginLeft: 15 }} />}
                  </div>
                  {shipmentData.shipmentType === 'DOX' && (
                    <input
                      required
                      type="number"
                      name="packageWeight"
                      value={exactWeightInput}
                      onChange={(e) => setExactWeightInput(e.target.value)}
                    />
                  )}
                  <textarea
                    type="text"
                    placeholder="Enter pick up note here"
                    value={noteInput}
                    onChange={(e) => setNoteInput(e.target.value)}
                  />
                  {/* <input type="file" multiple accept="image/*" onChange={onImageChange} />
                            {imageURLS.map((imageSrc) => (
                                <img src={imageSrc} alt="not fount" width={"90px"} style={{ margin: 10 }} />
                            ))} */}
                  {exactWeight >= shipmentData.totalCharge && (
                    <button type="submit" className="scan-btn">
                      Xác nhận
                    </button>
                  )}
                </form>
              )}
              {validPickup && (
                <form className="scanning-pickUp-form" onSubmit={pickupShipment}>
                  {shipmentData.shipmentType === 'WPX' &&
                    packaging.map((s, index) => (
                      <div>
                        <p
                          style={{
                            fontSize: 15,
                            width: 'fit-content',
                            background: 'rgb(240,240,240)',
                            borderRadius: '10px',
                          }}
                        >
                          {index + 1} / {packaging.length}
                        </p>
                        <input
                          required
                          type="number"
                          disabled
                          name="packageWeight"
                          value={s.packageWeight}
                          onChange={(e) => handleChangePack(index, e)}
                        />
                      </div>
                    ))}
                  {shipmentData.shipmentType === 'DOX' && (
                    <input
                      required
                      type="number"
                      name="packageWeight"
                      value={exactWeightInput}
                      onChange={(e) => setExactWeightInput(e.target.value)}
                    />
                  )}
                  <textarea
                    type="text"
                    placeholder="Enter pick up note here"
                    value={noteInput}
                    onChange={(e) => setNoteInput(e.target.value)}
                  />
                  {/* <input type="file" multiple accept="image/*" onChange={onImageChange} />
                            {imageURLS.map((imageSrc) => (
                                <img src={imageSrc} alt="not fount" width={"90px"} style={{ margin: 10 }} />
                            ))} */}
                  <button type="submit" className="scan-btn">
                    PICK UP
                  </button>
                </form>
              )}
            </div>
          )}
          {shipmentData.status === 'Picked Up' && (
            <div className="scanning-for-arrived">
              <button type="button" className="scan-btn" onClick={shipmentArrived}>
                Stock In
              </button>
            </div>
          )}
          {shipmentData.status === 'Arrived At Skydart Origin Depot' && (
            <div className="scanning-for-pick-up">
              <button type="button" className="scan-btn" onClick={processShipment}>
                Pass
              </button>
              <button type="button" className="hold-btn2" onClick={holdShipment}>
                Hold
              </button>
            </div>
          )}
          {shipmentData.status === 'On Hold' && (
            <div className="scanning-for-arrived">
              <button type="button" className="scan-btn" onClick={processShipment}>
                Process
              </button>
              <textarea
                type="text"
                placeholder="Lý Do Hold"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                style={{ width: '200px' }}
              />
              <button type="button" className="scan-btn" style={{ marginTop: 5 }} onClick={holdReason}>
                Cập Nhật Lý Do
              </button>
            </div>
          )}
          {shipmentData.status === 'In Process' && shipmentData.shipmentType === 'WPX' && (
            <div className="scanning-for-arrived">
              <h3>Đóng bao</h3>
              {consoleBag.map((s, index) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    clearShipment();
                    let id = s.id || makeid(9);
                    let arr = [];
                    axios.put('https://test.skydart.com.vn/api/update/tracking', {
                      hawb: shipmentData.hawb,
                      sin: consoleBag[0].packSin,
                    });
                    if (s.packedBy === '') {
                      const packages = consoleBag.map((sd) => {
                        let value = userData.displayName;
                        if (sd.packNo === s.packNo) {
                          sd.packedBy = value;
                          sd.id = id;
                        }
                        return sd;
                      });
                      consoleBag.map((s) => {
                        arr.push(s.pack + ',');
                      });
                      axios.post('https://test.skydart.com.vn/api/shipment/packing', {
                        hawb: shipmentData.hawb,
                        sin: s.packSin || 'Pending',
                        description: shipmentData.description.toString() || 'Pending',
                        chargeable: s.packageCharge,
                        pack: s.pack,
                        mawb: mawb,
                        airline: flightName,
                        date: new Date(),
                        special: `${index + 1} | ${packaging.length}`,
                        packedBy: userData.displayName,
                        exactWeight: s.packageWeight,
                        pieceID: id,
                      });
                      axios
                        .put('https://test.skydart.com.vn/api/update/flight', {
                          hawb: shipmentData.hawb,
                          airline: flightName,
                          mawb: mawb,
                          pack: arr.toString(),
                        })
                        .then(() => {
                          db.collection('shipments').doc(shipmentData.hawb).update({
                            repack: packages,
                          });
                          setLoading(false);
                          alert('Success');
                        });
                    } else if (s.packedBy !== '' && s.packedBy === userData.displayName) {
                      const packages = consoleBag.map((sd) => {
                        let value = userData.displayName;
                        if (sd.packNo === s.packNo) {
                          sd.packedBy = value;
                          sd.id = id;
                        }
                        return sd;
                      });
                      setLoading(true);
                      consoleBag.map((s) => {
                        arr.push(s.pack);
                      });
                      axios.put('https://test.skydart.com.vn/api/update/flight', {
                        hawb: shipmentData.hawb,
                        airline: flightName,
                        mawb: mawb,
                        pack: arr.toString(),
                      });
                      db.collection('shipments')
                        .doc(hawb)
                        .update({
                          repack: packages,
                        })
                        .then(() => {
                          setLoading(false);
                          axios
                            .put('https://test.skydart.com.vn/api/update/packing', {
                              pieceID: s.id,
                              pack: s.pack,
                              mawb: mawb,
                              airline: flightName,
                              sin: s.packSin,
                            })
                            .then(() => {
                              alert('Success');
                            });
                        });
                    } else if (s.packedBy !== '' && s.packedBy !== userData.displayName) {
                      alert(
                        `KIỆN HÀNG ĐÃ ĐƯỢC ĐÓNG BỞI ${s.packedBy}, HÃY THỬ KHAI BÁO DỰA VÀO ĐÚNG SỐ KIỆN BẠN ĐANG XỬ LÝ`
                      );
                      setLoading(false);
                    }
                  }}
                  style={{
                    display: 'flex',
                    marginTop: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid gray',
                  }}
                >
                  <p style={{ fontSize: 15, background: 'rgb(240,240,240)' }}>
                    {index + 1} / {packaging.length}
                  </p>
                  <div style={{ width: '60%' }}>
                    <input
                      required
                      name="pack"
                      type="text"
                      className="scanning-pack-input"
                      placeholder="Nhập số bao"
                      value={s.pack || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                    <input
                      type="text"
                      name="packSin"
                      className="scanning-pack-input"
                      value={s.packSin}
                      placeholder="Nhập số REF trên nhãn"
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                  {flightName !== '' && (
                    <button type="submit" className="scanning-pack-declared">
                      {s.packedBy === '' && 'Khai Báo'}
                      {s.packedBy !== '' && 'Sửa'}
                    </button>
                  )}
                  {/* {flightName === "" && <button type="button" className="scanning-pack-declared" onClick={() => alert("Hãy nhập số chuyến bay và tên chuyến bay")}>
                                    {s.packedBy === "" && "Khai Báo"}
                                    {s.packedBy !== "" && "Sửa"}
                                </button>} */}
                </form>
              ))}
              <div className="scanning-flight" style={{ marginTop: 15 }}>
                <div className="scanning-flight-header">
                  <div className="scanning-flight-option">
                    <h5
                      style={{
                        fontWeight: 600,
                        color: 'gray',
                        textAlign: 'center',
                      }}
                    >
                      Flight
                    </h5>
                  </div>
                  <div className="scanning-flight-option">
                    <h5
                      style={{
                        fontWeight: 600,
                        color: 'gray',
                        textAlign: 'center',
                      }}
                    >
                      MAWB
                    </h5>
                  </div>
                </div>
                {flights.map((s, index) => (
                  <>
                    {flightName !== s.flight && mawb !== s.mawb && (
                      <div
                        className="scanning-flight-header"
                        onClick={() => {
                          setFlightName(s.flight);
                          setMawb(s.mawb);
                        }}
                        key={index}
                        style={{
                          background: 'whitesmoke',
                          borderBottom: '1px solid lightgray',
                        }}
                      >
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'black',
                              textAlign: 'center',
                            }}
                          >
                            {s.flight}
                          </h5>
                        </div>
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'black',
                              textAlign: 'center',
                            }}
                          >
                            {s.mawb}
                          </h5>
                        </div>
                      </div>
                    )}
                    {flightName === s.flight && mawb === s.mawb && (
                      <div
                        className="scanning-flight-header"
                        key={index}
                        style={{
                          background: 'rgb(91, 129, 235)',
                          color: 'white',
                          borderBottom: '1px solid lightgray',
                        }}
                      >
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'white',
                              textAlign: 'center',
                            }}
                          >
                            {s.flight}
                          </h5>
                        </div>
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'white',
                              textAlign: 'center',
                            }}
                          >
                            {s.mawb}
                          </h5>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
          {shipmentData.status === 'In Process' && shipmentData.shipmentType === 'DOX' && (
            <button
              onClick={clearShipment}
              type="button"
              style={{
                padding: 7,
                fontSize: 15,
                background: 'rgb(91, 129, 235)',
                color: 'white',
                borderRadius: 20,
              }}
            >
              Stock Out
            </button>
          )}
          {shipmentData.status === 'In Clearance' && shipmentData.shipmentType === 'WPX' && (
            <div className="scanning-for-arrived">
              <h3>Đóng bao</h3>
              {consoleBag.map((s, index) => (
                <form
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 10,
                    borderBottom: '1px solid gray',
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    let arr = [];
                    const packages = shipmentRepack.map((sd) => {
                      let value = userData.displayName;
                      if (sd.packNo === s.packNo) {
                        sd.packedBy = value;
                      }
                      return sd;
                    });
                    axios.put('https://test.skydart.com.vn/api/update/tracking', {
                      hawb: shipmentData.hawb,
                      sin: consoleBag[0].pack,
                    });
                    if (s.packedBy === '') {
                      consoleBag.map((s) => {
                        arr.push(s.pack);
                      });
                      axios
                        .put('https://test.skydart.com.vn/api/update/flight', {
                          hawb: shipmentData.hawb,
                          airline: flightName,
                          mawb: mawb,
                          pack: arr.toString(),
                        })
                        .then(() => {
                          db.collection('shipments').doc(shipmentData.hawb).update({
                            repack: packages,
                          });
                          setLoading(false);
                          axios.post('https://test.skydart.com.vn/api/shipment/packing', {
                            hawb: shipmentData.hawb,
                            sin: s.packSin || 'Pending',
                            description: shipmentData.description || 'Pending',
                            chargeable: s.packageCharge,
                            pack: s.pack,
                            mawb: mawb,
                            airline: flightName,
                            date: new Date(),
                            special: `${index + 1} | ${packaging.length}`,
                            packedBy: userData.displayName,
                            exactWeight: s.packageWeight,
                            pieceID: makeid(9),
                          });
                          alert('Success');
                        });
                    } else if (s.packedBy !== '' && s.packedBy === userData.displayName) {
                      setLoading(true);
                      consoleBag.map((s) => {
                        arr.push(s.pack);
                      });
                      axios.put('https://test.skydart.com.vn/api/update/flight', {
                        hawb: shipmentData.hawb,
                        airline: flightName,
                        mawb: mawb,
                        pack: arr.toString(),
                      });
                      db.collection('shipments')
                        .doc(hawb)
                        .update({
                          repack: packages,
                        })
                        .then(() => {
                          setLoading(false);
                          axios
                            .put('https://test.skydart.com.vn/api/update/packing', {
                              pieceID: s.id,
                              pack: s.pack,
                              mawb: mawb,
                              airline: flightName,
                              sin: s.packSin,
                            })
                            .then(() => {
                              alert('Success');
                            });
                        });
                    } else if (s.packedBy !== '' && s.packedBy !== userData.displayName) {
                      alert(
                        `KIỆN HÀNG ĐÃ ĐƯỢC ĐÓNG BỞI ${s.packedBy}, HÃY THỬ KHAI BÁO DỰA VÀO ĐÚNG SỐ KIỆN BẠN ĐANG XỬ LÝ`
                      );
                      setLoading(false);
                    }
                  }}
                >
                  <p style={{ fontSize: 15, background: 'rgb(240,240,240)' }}>
                    {index + 1} / {packaging.length}
                  </p>
                  <div style={{ width: '60%' }}>
                    <input
                      required
                      name="pack"
                      type="text"
                      className="scanning-pack-input"
                      placeholder="Nhập số bao"
                      value={s.pack || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                    <input
                      type="text"
                      className="scanning-pack-input"
                      name="packSin"
                      value={s.packSin}
                      placeholder="Nhập số REF trên nhãn"
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                  {flightName !== '' && (
                    <button type="submit" className="scanning-pack-declared">
                      {s.packedBy === '' && 'Khai Báo'}
                      {s.packedBy !== '' && 'Sửa'}
                    </button>
                  )}
                  {flightName === '' && (
                    <button
                      type="button"
                      className="scanning-pack-declared"
                      onClick={() => alert('Hãy nhập số chuyến bay và tên chuyến bay')}
                    >
                      {s.packedBy === '' && 'Khai Báo'}
                      {s.packedBy !== '' && 'Sửa'}
                    </button>
                  )}
                </form>
              ))}

              <div className="scanning-flight" style={{ marginTop: 15 }}>
                <div className="scanning-flight-header">
                  <div className="scanning-flight-option">
                    <h5
                      style={{
                        fontWeight: 600,
                        color: 'gray',
                        textAlign: 'center',
                      }}
                    >
                      Flight
                    </h5>
                  </div>
                  <div className="scanning-flight-option">
                    <h5
                      style={{
                        fontWeight: 600,
                        color: 'gray',
                        textAlign: 'center',
                      }}
                    >
                      MAWB
                    </h5>
                  </div>
                </div>
                {flights.map((s, index) => (
                  <>
                    {flightName !== s.flight && mawb !== s.mawb && (
                      <div
                        className="scanning-flight-header"
                        onClick={() => {
                          setFlightName(s.flight);
                          setMawb(s.mawb);
                        }}
                        key={index}
                        style={{
                          background: 'whitesmoke',
                          borderBottom: '1px solid lightgray',
                        }}
                      >
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'black',
                              textAlign: 'center',
                            }}
                          >
                            {s.flight}
                          </h5>
                        </div>
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'black',
                              textAlign: 'center',
                            }}
                          >
                            {s.mawb}
                          </h5>
                        </div>
                      </div>
                    )}
                    {flightName === s.flight && mawb === s.mawb && (
                      <div
                        className="scanning-flight-header"
                        key={index}
                        style={{
                          background: 'rgb(91, 129, 235)',
                          color: 'white',
                          borderBottom: '1px solid lightgray',
                        }}
                      >
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'white',
                              textAlign: 'center',
                            }}
                          >
                            {s.flight}
                          </h5>
                        </div>
                        <div className="scanning-flight-option">
                          <h5
                            style={{
                              fontWeight: 600,
                              color: 'white',
                              textAlign: 'center',
                            }}
                          >
                            {s.mawb}
                          </h5>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
